import React, { useEffect, useState } from "react";
import { _l } from "../../hooks/utilities";
import Form from "react-bootstrap/Form";
import {
  Buildings,
  CaretRight,
  NotePencil,
  UserGear,
  UsersThree,
  Wrench,
} from "phosphor-react";
import { Col } from "react-bootstrap";
import RectangleCheckIcon from "../../assets/icons/RectangleCheckIcon";
import { Row } from "reactstrap";
import OnboardingHeader from "../SpaceOnBoarding/OnboardingHeader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CUSTOM_ROLES } from "../../constants/constants";
import TopicSettingModal from "../Modals/TopicSettingModal";
import { useDispatch, useSelector } from "react-redux";
import { setProject } from "../../actions/customer";
import store from "../../store";
import customerServices from "../../services/customer-services";

const MainScreen = () => {
  const [spaceDetails, setSpaceDetails] = useState();
  const [settingModalData, setSettingModalData] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();
  const { virtualSpaceList} = useSelector((state) => state.customer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userType = localStorage.getItem("user_type");
  const virtualspaces = JSON.parse(localStorage.getItem("selectedVirtualSpace"))
  let spaces = virtualspaces && virtualspaces ? JSON.stringify(virtualSpaceList) : localStorage.getItem("spaces");
  const contact_role = localStorage.getItem("contact_role");
  let space_setup_pending = localStorage.getItem("space_setup_pending");
  let client_id = localStorage.getItem("client_id");
  let contact_id = localStorage.getItem("contact_id");
  const settingMenu = searchParams.get("setting-menu");
  const category = searchParams.get("category");

  const isSpecialCustomer =
    userType == "contact" && !CUSTOM_ROLES.includes(contact_role);
  // const isSpecialCustomer = CUSTOM_CLIENT_IDS.includes(client_id) && CUSTOM_ADMIN_ROLES[client_id] && !CUSTOM_ADMIN_ROLES[client_id].includes(contact_role);
  let projectId = Number(searchParams.get("space"));

  const showFeatureComponent = (value) => {
    searchParams.append("feature", value);
    setSearchParams(searchParams);
  };

  const closeSettingModal = () => {
    setSettingModalData({});
    searchParams.delete("setting-menu");
    searchParams.delete("section");
    searchParams.delete("category");
    setSearchParams(searchParams);
    if(virtualspaces)
    {
      navigate("/");
    }
  };

  useEffect(() => {
    const topics = store.getState().customer;

    switch (settingMenu) {
      case "providers":

        let categoryData = {};

        if (topics && topics.defaultTopics) {
          let categoryIndex = topics.defaultTopics.findIndex(
            (w) => w.main_key === "providers"
          );
          if (
            categoryIndex > -1 &&
            topics.defaultTopics[categoryIndex].categories &&
            topics.defaultTopics[categoryIndex].categories.length
          ) {
            categoryData = topics.defaultTopics[categoryIndex].categories.find(
              (w) => w.filter_key == category
            );
            categoryData = { ...categoryData, id: categoryData ? categoryData.filter_key : -1};
          } else {
            categoryData = {
              id: -1,
              title: _l("l_all"),
            };
          }
        } else {
          categoryData = {
            id: -1,
            title: _l("l_all"),
          };
        }
  
        setSettingModalData({
          main_key: "providers",
          id: categoryData.id,
          main_key_title: _l("l_services"),
          title: categoryData.title,
        });
        break;
      case "people":
        setSettingModalData({
          main_key: userType === "operator" ? "my_operator" : userType === "staff" ? "my_staff" : "my_contact",
          id: -1,
          title: _l("l_all"),
          main_key_title:
            userType === "operator" ? "l_operator" : "l_my_people",
        });
        break;
      case "equipments":
        setSettingModalData({
          main_key: "equipments",
          id: -1,
          title: _l("l_all"),
          main_key_title: _l("l_equipments"),
        });
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {

    if(virtualspaces && !settingMenu)
    {
      navigate("/");
    }
    else if (spaces && spaces.length > 0 && JSON.parse(space_setup_pending) != 1) {
      spaces = JSON.parse(spaces);
      if (spaces.length) {
        let space = spaces.find((x) => x.project_id == projectId);
        setSpaceDetails(space);
        dispatch(setProject(projectId));
      }
      else if(projectId > 0)
      {
        navigate(`/explore-features?space=${projectId}`);
      }
       else {
        navigate("/space-onboarding");
      }
    }
    else if(projectId > 0 && JSON.parse(space_setup_pending) == 1)
    {
      //To do

      customerServices.getCustomerSpaces(client_id,contact_id,"").then((res)=>{
        if(res.status == 1){
          const currentSpaceData = res.data.find((w) => w.project_id == projectId);
          if(currentSpaceData)
          {
            setSpaceDetails(currentSpaceData);
          }
        }

      }).catch(err => {
        console.log(err)
      });
    }

    return () => {};
  }, [projectId]);

  return spaceDetails && Object.keys(spaceDetails).length ? (
    <div className="onboarding-wrapper-main d-flex m-auto h-100 flex-column with-modified-width onboarding-with-bg">
      <OnboardingHeader
        description={"l_explore_feature"}
        title={"l_explore_feature_description"}
      />
      <div className="onboarding-content-scroll-wrapper overflow-hiiden-auto h-100">
        <div className="w-100">
          <Form.Label className="c-font f-14 title-fonts">
            {_l("l_my_space")}
          </Form.Label>
          <div className="select-input-cox p-35px w-100 align-items-center d-flex mb-32px comman_action_icon " onClick={() =>
                  navigate(`/space-onboarding?space=${projectId}&edit=1`)
                }>
            <input
              className="bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100 light-theme-border-transparent"
              name="group1"
              type="radio"
              checked
            />
            <a href="#/" className="check-image top-0 end-0 h-auto w-auto bg-transparent">
              <RectangleCheckIcon
                size={20}
                weight="fill"
                className="c-icons color-green"
              />
            </a>
            <div class="h50w50 d-flex active action_icon with_bg rounded-5">
              <Buildings size={35} weight="light" className="c-icons" />
            </div>
            <div class="w100minus100per text-truncate ps-3">
              <div class="c-font f-16 fw-semibold text-truncate title-fonts">
                {spaceDetails.office_title}
              </div>
              <div class="c-font color-white-60 f-12 pt-1 text-truncate">
                {spaceDetails.postal_address}
              </div>
            </div>
            <div className="align-items-center d-flex justify-content-between">
              <div className="with_separator_10 flex-grow-1 px-3">
                <div className="c-font f-16 fw-semibold title-fonts">
                  {spaceDetails.number_of_desk}
                </div>
                <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                  {_l("l_number_of_desk")}
                </div>
              </div>
              <div className="with_separator_10 flex-grow-1 px-3">
                <div className="c-font f-16 fw-semibold title-fonts">
                  {spaceDetails.superficies} (m²)
                </div>
                <div className="c-font color-white-60 f-12 pt-1">
                  {_l("l_superficy")}
                </div>
              </div>
              <div className="with_separator_10 flex-grow-1 px-3 before-dnone">
                <div className="c-font f-16 fw-semibold title-fonts">
                  {spaceDetails.floor_count || 0}
                </div>
                <div className="c-font color-white-60 f-12 pt-1">
                  {_l("l_floors")}
                </div>
              </div>
              {
                spaceDetails.is_paymented > 0
                ?
                <a href="#/"
                  className="h50w50 comman-round-box with-bg d-flex rounded-circle border-dashed-2px on-hover-bg-10 card-view-none me-3 no-hover"
                  onClick={() =>
                    navigate(`/space-onboarding?space=${projectId}&edit=1`)
                  }
                >
                  <NotePencil size={18} weight="light" className="c-icons" />
                </a>
                :
                <React.Fragment></React.Fragment>
              }
            </div>
          </div>
          <Row className="mb-4">
            <Form.Label className="c-font f-14 title-fonts">
              {_l("l_my_members_assets")}
            </Form.Label>
            {isSpecialCustomer ? (
              <React.Fragment></React.Fragment>
            ) : (
              <Col className="p-15 py-2" xs={4}>
                <div className="select-input-cox h-100 d-flex min-h-230px flex-column comman_action_icon">
                  <div className="d-flex flex-column h-100 justify-content-center p-20 position-relative ">
                    <div className="bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100 bg-transparent shadow-none" />
                    <div className="h50w50 d-flex action_icon with_bg h32w32 mb-5">
                      <UserGear
                        size={18}
                        weight="light"
                        className="c-icons m-auto"
                      />
                    </div>
                    <span className="c-font color-white f-16 fw-semibold">
                      {userType == "operator" ? (
                        <>{_l("l_connect") + " " + _l("l_customer")}</>
                      ) : (
                        _l("l_connect_provider")
                      )}
                    </span>
                    <span className="c-font color-white-60 f-14 pt-2">
                      {userType == "operator"
                        ? _l("l_connect_customer_description")
                        : _l("l_connect_provider_description")}
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    {/* <a href="#/"
                      className="d-flex align-items-center p-20"
                      onClick={() => showFeatureComponent("connect-provider")}
                    >
                      <div className="title-fonts text-truncate w100minus100per fw-medium">
                        {userType == "operator"
                          ? _l("l_add_customer")
                          : _l("l_add_provider")}
                      </div>
                      <CaretRight
                        size={18}
                        weight="light"
                        className="c-icons mx-0"
                      />
                    </a> */}
                    <a href="#/"
                      className={`d-flex align-items-center p-20 border-top ${
                        userType === "contact" ? "" : "for-disabled"
                      }`}
                      onClick={() =>
                        setSettingModalData({
                          main_key: "providers",
                          id: -1,
                          main_key_title: _l("l_services"),
                          title: _l("l_all"),
                        })
                      }
                    >
                      <div className="title-fonts text-truncate w100minus100per fw-medium">
                        {_l("l_provider_setting")}
                      </div>
                      <CaretRight
                        size={18}
                        weight="light"
                        className="c-icons mx-0"
                      />
                    </a>
                  </div>
                </div>
              </Col>
            )}
            <Col className="p-15 py-2" xs={isSpecialCustomer ? 6 : 4}>
              <div className="select-input-cox h-100 d-flex min-h-230px flex-column comman_action_icon">
                <div className="d-flex flex-column h-100 justify-content-center p-20 position-relative">
                  <div className="bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100 bg-transparent shadow-none" />
                  <div className="h50w50 d-flex action_icon with_bg h32w32 mb-5">
                  <UsersThree
                    size={18}
                    weight="light"
                    className="c-icons m-auto"
                  />
                  </div>
                  <span className="c-font color-white f-16 fw-semibold">
                    {userType === "operator"
                      ? _l("l_connect_employees")
                      : _l("l_connect_tenets")}
                    {/* {userType == "operator" ?  <>{_l("l_connect")  }</>:_l("l_connect_contact")} */}
                  </span>
                  <span className="c-font color-white-60 f-14 pt-2">
                    {_l("l_connect_tenets_subtitle")}
                  </span>
                </div>
                <div className="d-flex flex-column justify-content-center">
                  {/* <a href="#/"
                    className="d-flex align-items-center p-20"
                    onClick={() => {
                      //
                      navigate(
                        userType === "operator"
                          ? `/employees?space=${projectId}`
                          : `/tenants?space=${projectId}`
                      );
                    }}
                  >
                    <div className="title-fonts text-truncate w100minus100per fw-medium">
                      {userType === "operator"
                        ? _l("l_add_employee")
                        : _l("l_add_tenants")}
                    </div>
                    <CaretRight
                      size={18}
                      weight="light"
                      className="c-icons mx-0"
                    />
                  </a> */}
                  <a href="#/"
                    className="d-flex align-items-center p-20 border-top"
                    onClick={() =>
                      setSettingModalData({
                        main_key:
                          userType === "operator"
                            ? "my_operator"
                            : "my_contact",
                        id: -1,
                        title: _l("l_all"),
                        main_key_title:
                          userType === "operator"
                            ? "l_operator"
                            : "l_my_people",
                      })
                    }
                  >
                    <div className="title-fonts text-truncate w100minus100per fw-medium">
                      {userType === "operator"
                        ? _l("l_employee_setting")
                        : _l("l_tenant_setting")}
                    </div>
                    <CaretRight
                      size={18}
                      weight="light"
                      className="c-icons mx-0"
                    />
                  </a>
                </div>
              </div>
            </Col>
            <Col className="p-15 py-2" xs={isSpecialCustomer ? 6 : 4}>
              <div className="select-input-cox h-100 d-flex min-h-230px flex-column comman_action_icon">
                <div className="d-flex flex-column h-100 justify-content-center p-20 position-relative">
                  <div className="bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100 bg-transparent shadow-none" />
                  <div className="h50w50 d-flex action_icon with_bg h32w32 mb-5">
                  <Wrench
                    size={18}
                    weight="light"
                    className="c-icons m-auto"
                  />
                  </div>
                  <span className="c-font color-white f-16 fw-semibold">
                    {_l("l_add_equipments")}
                  </span>
                  <span className="c-font color-white-60 f-14 pt-2">
                    {_l("l_add_equipments_subtitle")}
                  </span>
                </div>
                <div className="d-flex flex-column justify-content-center">
                  {/* <a href="#/"
                    className="d-flex align-items-center p-20"
                    onClick={() => navigate(`/equipments?space=${projectId}`)}
                  >
                    <div className="title-fonts title-fonts text-truncate w100minus100per fw-medium">{_l("l_add_equipments")}</div>
                    <CaretRight
                      size={18}
                      weight="light"
                      className="c-icons mx-0"
                    />
                  </a> */}
                  <a href="#/"
                    className="d-flex align-items-center p-20 border-top"
                    onClick={() =>
                      setSettingModalData({
                        main_key: "equipments",
                        id: -1,
                        title: _l("l_all"),
                        main_key_title: _l("l_equipments"),
                      })
                    }
                  >
                    <div className="title-fonts text-truncate w100minus100per fw-medium">
                      {_l("l_equipments_setting")}
                    </div>
                    <CaretRight
                      size={18}
                      weight="light"
                      className="c-icons mx-0"
                    />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          {/*  features section is temperory hiide to show section uncomment */}
          {/* <Row>
            <Form.Label className="c-font f-14 title-fonts ">
              {_l("l_features")}
            </Form.Label>
            <Col xs={3}>
              <div className="select-input-cox p-20 opacity-50">
                <input
                  className="bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100"
                  name="group1"
                  type="radio"
                />
                <div className="d-flex align-items-center justify-content-center h-100 flex-column p-20 py-3 my-3 text-center m-auto">
                  <Notepad
                    size={35}
                    weight="light"
                    className="c-icons m-0 mb-4"
                  />
                  <span className="c-font color-white f-16 fw-semibold">
                    {_l("l_task_for_other")}
                  </span>
                  <span className="c-font color-white-60 f-14 pt-2">
                    {_l("l_task_for_other_description")}
                  </span>
                </div>
              </div>
            </Col>
            <Col xs={3}>
              <div className="select-input-cox p-20 opacity-50">
                <input
                  className="bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100"
                  name="group1"
                  type="radio"
                />
                <div className="d-flex align-items-center justify-content-center h-100 flex-column p-20 py-3 my-3 text-center m-auto">
                  <Notepad
                    size={35}
                    weight="light"
                    className="c-icons m-0 mb-4"
                  />
                  <span className="c-font color-white f-16 fw-semibold">
                    {_l("l_request_for_other")}
                  </span>
                  <span className="c-font color-white-60 f-14 pt-2">
                    {_l("l_request_for_other_description")}
                  </span>
                </div>
              </div>
            </Col>
            <Col xs={3}>
              <div className="select-input-cox p-20 opacity-50">
                <input
                  className="bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100"
                  name="group1"
                  type="radio"
                />
                <div className="d-flex align-items-center justify-content-center h-100 flex-column p-20 py-3 my-3 text-center m-auto">
                  <Notepad
                    size={35}
                    weight="light"
                    className="c-icons m-0 mb-4"
                  />
                  <span className="c-font color-white f-16 fw-semibold">
                    {_l("l_create_chat")}
                  </span>
                  <span className="c-font color-white-60 f-14 pt-2">
                    {_l("l_create_chat_description")}
                  </span>
                </div>
              </div>
            </Col>
            <Col xs={3}>
              <div className="select-input-cox p-20 opacity-50">
                <input
                  className="bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100"
                  name="group1"
                  type="radio"
                />
                <div className="d-flex align-items-center justify-content-center h-100 flex-column p-20 py-3 my-3 text-center m-auto">
                  <Chats
                    size={35}
                    weight="light"
                    className="c-icons m-0 mb-4"
                  />
                  <span className="c-font color-white f-16 fw-semibold">
                    {_l("l_connect_provider")}
                  </span>
                  <span className="c-font color-white-60 f-14 pt-2">
                    {_l("l_connect_provider_description")}
                  </span>
                </div>
              </div>
            </Col>
          </Row> */}
          {/* features section end */}
        </div>
      </div>
      {Math.abs(settingModalData.id) > 0 ? (
        <TopicSettingModal
          data={{
            main_key: settingModalData.main_key,
            id: settingModalData.id,
            title: settingModalData.title,
            main_key_title: settingModalData.main_key_title,
          }}
          handleClose={closeSettingModal}
          show={Math.abs(settingModalData.id) > 0}
          title={settingModalData.main_key_title}
          titlePrefix={"l_settings"}
          project_id={projectId.toString()}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </div>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export default MainScreen;
