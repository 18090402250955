import React, { useEffect, useState, useRef } from "react";
import { X,ArrowRight } from "phosphor-react";
import CustomerServices from "../../services/customer-services";
import Toasts from "../Toasts/Toasts";
import { _l } from "../../hooks/utilities";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";

const LinkDropdown = ({
  openLinksDropdown,
  setOpenLinksDropdown,
  task_id,
  updateLinkCount,
  checklistId = 0,
}) => {
  const [linkList, setLinkList] = useState([]);
  const [newLink, setNewLink] = useState("");
  const [linkId, setLinkId] = useState(0);
  const [toastHeader, setToastHeader] = useState("");
  const [confirmButtonText, setConfirmButtonText] = useState("");
  const [cancelButtonText, setCancelButtonText] = useState("");
  const [toastClass, setToastClass] = useState("unsucess");
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const inputReference = useRef(null);

  const project_id = useSelector((state) => state.customer.selectedProject);

  const addNewLink = () => {
    let pattern = newLink.match(
      /^((http|https)\:\/\/)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (newLink.trim() && pattern) {
      CustomerServices.addLinkComment(
        task_id,
        project_id,
        newLink.trim(),
        1,
        checklistId
      ).then((res) => {
        if (res.status) {
          setLinkList((linkList) => [
            ...linkList,
            {
              content: newLink.trim(),
              id: res.data.comment_id,
              profile_image: localStorage.getItem("contact_image"),
            },
          ]);
          setNewLink("");
          updateLinkCount(linkList.length + 1);
        }
      });
    } else {
      setToastHeader(_l("l_error"));
      setToastMessage(_l("l_please_enter_valid_link"));
      setConfirmButtonText("");
      setCancelButtonText("");
      setToastClass("unsucess");
      setShowToast(true);
    }
  };

  const handleOutFocus = () => {
    if (linkId) {
      setNewLink("");
      setLinkId(0);
    }
  };

  useEffect(() => {
    setNewLink("");
    if (task_id && project_id && openLinksDropdown) {
      CustomerServices.getLinks(task_id, project_id, checklistId).then(
        (res) => {
          const links = res.data;
          setLinkList([]);
          links.forEach((item) => {
            setLinkList((linkList) => [...linkList, item]);
          });
        }
      );
    }
    return () => {
      //Comment this statement if it is not required to call an API everytime user clicks on the component
      if (setOpenLinksDropdown) setOpenLinksDropdown(false);
    };
  }, [openLinksDropdown, task_id, project_id]);

  return (
    <ul className="dropdown-menu" aria-labelledby="LinKsDropdown">
      <li>
        <div className="dropdown-header">
          <div className="d-flex align-items-center justify-content-between">
            <div className="c-list-detail title-fonts text-truncate color-white c-font f-13 fw-semibold">
            {_l("l_links")}
            </div>
            <a href="#/" className="right-image d-none" data-bs-dismiss="dropdown">
              <X size={18} className="c-icons" weight="light" />
            </a>
          </div>
        </div>
      </li>
      <li>
        <hr className="dropdown-divider mt-0" />
      </li>
      <div className="d-flex flex-wrap p-0  overflowscroll">
        {linkList.length ? (
          linkList.map((link, index) => {
            return (
              <li key={index} className="hr_sepretr">
                <a
                  href={link.content}
                  target={"_blank"}
                  rel="noopener noreferrer"
                  className="d-flex align-items-center dropdown-item "
                >
                  <div className="c-list-icon">
                    <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
                      <div
                        className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                        style={{
                          backgroundImage: `url('${link.profile_image}')`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="ps-2 w100minus20">
                    <div className="on-hover-color-green">{link.content}</div>
                  </div>
                </a>
              </li>
            );
          })
        ) : (
          <CommanPlaceholder imgType="no-link" placeholderText = {_l("l_no_links")} />
        )}
      </div>
      <li>
        <hr className="dropdown-divider my-0" />
      </li>
      <div className="comment-input-box bg-white-03">
        <div className="d-flex align-items-center position-relative form-control height-45 border-0 bg-transparent px-2">
          <div
            data-bs-toggle="tooltip"
            className="comman-image-box h25w25 rounded-circle"
          >
            <div
              className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
              style={{
                backgroundImage: `url('${localStorage.getItem(
                  "contact_image"
                )}')`,
              }}
            ></div>
          </div>
          <input
            className="form-control height-45 border-0 w100minus40"
            style={{ backgroundColor: "transparent" }}
            type="text"
            placeholder={`${_l("l_write_url")}`}
            value={newLink}
            onChange={(e) => {
              setNewLink(e.target.value);
            }}
            ref={inputReference}
            onBlur={handleOutFocus}
            aria-label="default input example"
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                addNewLink();
              }
            }}
          />
          <a href="#/" className="send-arrow-btn" onClick={addNewLink}>
          <ArrowRight size={18} className="c-icons" weight="light" />
          </a>
        </div>
      </div>
      {showToast ? (
        <Toasts
          delay={2000}
          handler={() => {
            setShowToast(false);
            // setLinkId(0);
          }}
          header={toastHeader}
          message={toastMessage}
          toastClass={toastClass}
          cancelButtonText={cancelButtonText}
          cancelButtonHandler={() => {
            // setShowToast(false);
            // setLinkId(0);
          }}
          confirmButtonText={confirmButtonText}
          confirmButtonHandler={() => {
            // handleDeleteLink(linkId);
            // setShowToast(false);
            // setLinkId(0);
          }}
        />
      ) : (
        <></>
      )}
      <ReactTooltip />
    </ul>
  );
};

export default LinkDropdown;
