import React, { useEffect, useState } from "react";
import CustomerServices from "../../services/customer-services";
import { _l } from "../../hooks/utilities";
import {X , Paperclip} from "phosphor-react";
import Lightbox from "yet-another-react-lightbox";
import ReactTooltip from "react-tooltip";
// import "react-fancybox/lib/fancybox.css";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { DropdownItem, DropdownMenu} from 'reactstrap';
import { setTaskAttchmentsForCreate } from "../../actions/customer";
import { useDispatch } from "react-redux";
const ImageUploadDropdown = ({
  setOpenAttachmentsDropdown,
  openAttachmentsDropdown,
  task_id,
  project_id,
  checklistId = 0,
  updateImageCount,
  attachmentCountUpdate,
  Request_task_converted = false,
  taskDetails,
  unContolList=false,
  pageName="",
  from ="",
  previewArray,
  handleFileUpload = () =>{},
  handleRemoveQuantityFile = () =>{}
}) => {
  const [taskAttachments, setTaskAttachments] = useState([]);
  const [openGallery, setOpenGallery] = useState(false);
  const [defaultTaskAttachments, setDefaultTaskAttachments] = useState([]);
  const [showTaskImage, setShowTaskImage] = useState(false);
  const [selectedAttachmentId, setSelectedAttachmentId] = useState(0);
  const [attachmentDetails, setAttachmentDetails] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (task_id && openAttachmentsDropdown && project_id && from != "addequipments") {
      CustomerServices.getAttachments(task_id, project_id, checklistId).then(
        (res) => {
          const attachments = res.data;
          setTaskAttachments([]);
          attachments && attachments.forEach((item) => {
            setTaskAttachments((taskAttachments) => [...taskAttachments, item]);
            setDefaultTaskAttachments((defaultTaskAttachments) => [
              ...defaultTaskAttachments,
              item,
            ]);
          });
        }
      );
    }

    // return () => {
    //   //Comment this statement if it is not required to call an API everytime user clicks on the component
    //   if (setOpenAttachmentsDropdown) setOpenAttachmentsDropdown(false);
    // };
  }, [task_id, openAttachmentsDropdown, project_id]);
  useEffect(() =>{
    if (previewArray) {
      setTaskAttachments(previewArray)
    }
  },[previewArray])
  useEffect(() => {
    return () => {
      //Comment this statement if it is not required to call an API everytime user clicks on the component
      if (setOpenAttachmentsDropdown) setOpenAttachmentsDropdown(false);
    };
  }, [openAttachmentsDropdown]);

  const setAttchmentOnTop = (id) => {
    const targetAttchement = defaultTaskAttachments.filter(
      (attachment) => attachment.id === id
    );
    const otherAttachments = defaultTaskAttachments.filter(
      (attachment) => attachment.id != id
    );
    setDefaultTaskAttachments(targetAttchement.concat(otherAttachments));
  };
  const imageDropdownContain = (
    <>
    <li>
          <div className="dropdown-header">
            <div className="d-flex align-items-center justify-content-between">
              <div className="c-list-detail title-fonts text-truncate color-white c-font f-13">
              {_l("l_attachments")}
              </div>
              <a href="#/" className="right-image d-none" data-bs-dismiss="dropdown">
                <X size={18} className="c-icons" weight="light" />
              </a>
            </div>
          </div>
        </li>
        <li>
          <hr className="dropdown-divider mt-0" />
        </li>
        <DropdownItem toggle={false} tag="div" className="no-hover p-0">
        <div className="d-flex flex-wrap p-0 py-0 pt-2 px-2 overflowscroll">
          {taskAttachments.length ? (
            taskAttachments.map((attachment, index) => {
              return (
                <div key={index} className="w-25 p-1">
                  <div className="c-list-icon w-100 upload-image-preview position-relative">
                    <a href="#/" className="h50w50 w-100 comman-round-box with-bg radius_3 with-react-fancybox"
                        data-tip={attachment.file_name}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                    >
                      {/* <ReactFancyBox
                      thumbnail={attachment.image_url}
                      image={attachment.image_url}
                      showCloseBtn
                    /> */}
                      <div
                        className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                        style={{
                          backgroundImage: `url('${attachment.image_url}')`,
                          // Image path will be added here
                        }}
                        onClick={() => {
                          if (from != "addequipments") {
                          // setOpenGallery(true);
                          // setAttchmentOnTop(attachment.id);
                          setShowTaskImage(true);
                          setAttachmentDetails(attachment);
                          setOpenAttachmentsDropdown(false);
                          dispatch(setTaskAttchmentsForCreate(attachment))
                          }
                        }}
                      >
                      </div>
                    </a>
                    {from == "addequipments" ? 
                      <a href="#/" className="upload-close"
                      onClick={() => { handleRemoveQuantityFile(index, attachment.file_id)}}
                      >
                      <X size={14} weight="light" className="c-icons" />
                    </a>
                    : 
                    <></>
                    }
                  </div>

                  <div className="c-font text-truncate f-12 color-white-60 text-center pt-2">
                    {attachment.file_name}
                  </div>
                  <ReactTooltip />
                </div>
                
              );
            })
          ) : (
            <CommanPlaceholder imgType="no-images" placeholderText = {_l("l_no_images")} />
          )}
        </div>
        </DropdownItem>
        <li>
          <hr className="dropdown-divider mb-0" />
        </li>
        <li className="input_box_main with_file_upload">
          <label className="upload_inputbox w-100 upload_relative border-0">
            <input
              type="file"
              className="absolute-input"
              multiple={from == "addequipments" ? true : false}
              onChange={(event) => { 
                if (from == "addequipments") {
                  handleFileUpload(event.target.files)
                }else{
                CustomerServices.addLinkComment(
                  task_id,
                  project_id,
                  "",
                  undefined,
                  checklistId,
                  undefined,
                  undefined,
                  event.target.files
                ).then((res) => {
                  if (res.status && res.data) {
                    if(res.data.files_list && res.data.files_list.length > 0){
                      updateImageCount(taskAttachments.length + 1);
                      attachmentCountUpdate = { task_id };
                      setTaskAttachments(
                        [
                          {
                            id: res.data.files_list[0].file_id,
                            image_url: res.data.image_url,
                            file_name: res.data.files_list[0].file_name,
                          },
                        ].concat(taskAttachments)
                      );
                      setDefaultTaskAttachments(
                        [
                          {
                            id: res.data.files_list[0].file_id,
                            image_url: res.data.files_list[0].image_url,
                            file_name: res.data.files_list[0].file_name,
                          },
                        ].concat(defaultTaskAttachments)
                      );
                    }
                  }
                });
              }
              }}
              disabled={Request_task_converted ? true : false}
            />
            <div className="custom_file">
              <div className="comman_action_icon upload_icon">
                <div className="action_icon with_bg on-hover-active-toggle-img light-theme-img">
                  <Paperclip size={16} className="c-icons " weight="light" />
                </div>
              </div>
            
              <div className="btn btn-sm btn-white-10 c-font f-13">
              {_l("l_upload_file")} 
              </div>
            </div>
          </label>
        </li>
        </>
  )

  return (
    <>
      <Lightbox
        open={openGallery}
        close={() => setOpenGallery(false)}
        slides={defaultTaskAttachments.map((img) => {
          return { src: img.image_url };
        })}
      />
      {
      unContolList ? (
        <DropdownMenu container="body" direction="down" className={`dropdown-menu `}>{imageDropdownContain}</DropdownMenu> 
      ) : (
        <ul className="dropdown-menu" aria-labelledby="imageLstAndUploadDropdown">{imageDropdownContain}</ul>
      )
    } 
      {showTaskImage && Object.keys(attachmentDetails).length > 0 ? 
        <CommanOffCanvas
          show={showTaskImage}
          handleClose={() => {
            setShowTaskImage(false);
          }}
          data={{
            id: attachmentDetails.id,
            taskId: task_id,
            projectId: project_id,
            attachmentDetails: attachmentDetails,
            taskDetails: taskDetails.taskDetails ? taskDetails.taskDetails : taskDetails,
            allAttachments: taskAttachments
          }}
          docType={"imageview"}
          setSelectedId={setSelectedAttachmentId}
          selectedTaskId={task_id}
          pageName={pageName}
          handleParentComponentAction={() => {
            
          }}
          isCommentsection={false}
        /> : <></>
      }
    </>
  );
};

export default ImageUploadDropdown;
