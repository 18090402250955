import React, { useState } from 'react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';

const RangeSlider = ({currentValue, verticalLabels, handleChangeVertical, sliderMaxValue, sliderMinValue}) => {

  const formatPc = (p) => p + '%';

  const verticalSliderStyles = {
    position: 'relative',
  };

  const labelsStyles = {
    position: 'absolute',
    top: 0,
    left: '-50px', // Adjust the left value as needed
    width: '50px', // Adjust the width as needed
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  };

  return (
    <div className='rangesliderhorizontal'>
    <div className='slider custom-labels'>
      <Slider
        value={currentValue}
        min={sliderMinValue}
        max={sliderMaxValue}
        labels={verticalLabels}
        format={formatPc}
        onChange={handleChangeVertical}
        tooltip={false}
        style={verticalSliderStyles}
        labelsStyle={labelsStyles}
      />
    </div>
    </div>
  );
};

export default RangeSlider;
