
import React, { useEffect, useState } from "react";

const LiteViewCanvas = ({data}) => {
  return (
    <>
      <div className="">LiteViewCanvas</div>
    </>
  );
};

export default LiteViewCanvas;
