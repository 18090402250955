import React from 'react';
// import CSS from '../css/icon_main.css';

function EraserIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"  className={`comman-icons ${CommanHW}`}>
<g clipPath="url(#clip0_15368_259102)">
<g clipPath="url(#clip1_15368_259102)">
<path d="M5.71875 6.21875L9.9625 10.4625"  strokeLinecap="round" strokeLinejoinstrokeLinejoin="round"/>
<path d="M13.5 13.4872H4.50625L2.1875 11.1685C2.09415 11.0761 2.02004 10.9661 1.96947 10.8448C1.91889 10.7236 1.89285 10.5936 1.89285 10.4622C1.89285 10.3309 1.91889 10.2008 1.96947 10.0796C2.02004 9.95837 2.09415 9.84838 2.1875 9.75597L9.25625 2.68722C9.34865 2.59387 9.45865 2.51977 9.57987 2.46919C9.7011 2.41862 9.83115 2.39258 9.9625 2.39258C10.0938 2.39258 10.2239 2.41862 10.3451 2.46919C10.4663 2.51977 10.5763 2.59387 10.6687 2.68722L13.5 5.51222C13.6859 5.70154 13.79 5.95627 13.79 6.2216C13.79 6.48692 13.6859 6.74165 13.5 6.93097L6.9375 13.4872"  strokeLinecap="round" strokeLinejoinstrokeLinejoin="round"/>
</g>
</g>
<defs>
<clipPath id="clip0_15368_259102">
<rect width="16" height="16" />
</clipPath>
<clipPath id="clip1_15368_259102">
<rect width="16" height="16" />
</clipPath>
</defs>
</svg>


		</React.Fragment>
	)
}

export default EraserIcon