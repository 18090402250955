import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { _l } from "../../../hooks/utilities";
import Select from "react-select";
import { CloudArrowUp, X } from "phosphor-react";
import benchmarkServices from "../../../services/benchmark-services";
import { useDispatch } from "react-redux";
import { showMessage } from "../../../actions/messages";

const AddCatalogItem = ({
  handleClose,
  rel_type,
  setBrandsItems,
  brandsItems,
  isFrom = '',
  editBrandItems = ''
}) => {
  let editBrandItem = editBrandItems
  
  const tax = [
    {
      value: "1",
      label: "0%",
      tax: "0",
    },
    {
      value: "2",
      label: "5%",
      tax: "5",
    },
    {
      value: "3",
      label: "10%",
      tax: "10",
    },
    {
      value: "4",
      label: "20%",
      tax: "20",
    },
  ];

    const [loader, setLoader] = useState(false)
    const [nameItem, setNameItem] = useState(editBrandItem.name ? editBrandItem.name :'');
    const [price, setPrice] = useState(editBrandItem.rate ? editBrandItem.rate :'');
    const [description, setDescription] = useState(editBrandItem.description ? editBrandItem.description :'');
    const [taxValue, setTaxValue] = useState(editBrandItem.tax ? tax.find((f) => f.tax == editBrandItem.tax)  :'');
    const [allAttachments, setAllAttachments] = useState([]);
    const [imagePreview, setimagePreview] = useState([])
    const [editImageIds, setEditImageIds] = useState([]);

    const dispatch = useDispatch();
   
    
    const uploadImage = (image) => {
      let  id = Math.random(5)
      let previewImage = {
        name: image.name,
        url: URL.createObjectURL(image),
        id
      }
      setAllAttachments([{image, id}].concat(allAttachments));

      setimagePreview([previewImage].concat(imagePreview));
    }

    const removeImage = (id) => {
      setAllAttachments(allAttachments.filter(item => item.id !== id));
      setimagePreview(imagePreview.filter(item => item.id !== id));
    }

    const fieldValidations = () => {
      let flag = true;
     
      if (nameItem.trim() == "") {
        dispatch(
          showMessage("unsucess", _l("l_error"), _l("l_item_name_is_required"))
        );
        flag = false;
      } else if (taxValue && taxValue.tax == '') {
        dispatch(
          showMessage("unsucess", _l("l_error"), _l("l_tax_is_required"))
        );
        flag = false;
      } else if (price.trim() == ''){
        flag = false;
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            _l("l_price_is_required")
          )
        );
      } else if (imagePreview && imagePreview.length == 0) {
        flag = false;
        dispatch(
          showMessage("unsucess", _l("l_error"), _l("l_attachments_is_required"))
        );
      } 
      return flag;
    };

    const addItems = () => {
      if (!fieldValidations()) {
        setLoader(false);
        return false;
      }
      try {
        setLoader(true)
        benchmarkServices.addSolarProposalBrands(nameItem, description, taxValue.tax, rel_type, price, allAttachments, editBrandItem && editBrandItem.id ? editBrandItem.id : 0).then((res) => {
          if (res.status) {
            dispatch(showMessage("sucess", _l("l_success"), _l(res.message)));
            
            if (editBrandItem && editBrandItem.id > 0) {
              const  brandsItemsUpdate = brandsItems.map((brand) => {
                if (brand.id == editBrandItem.id) {
                  brand.description= description
                  brand.document= res.data.attachment
                  brand.id= editBrandItem.id
                  brand.name= nameItem
                  brand.rate= price
                  brand.rel_type= rel_type
                  brand.tax= taxValue.tax
                }
                return brand;
              })
              setBrandsItems(brandsItemsUpdate);
            }else if (isFrom !== "directAddItems") {
              let update = {
                description: description,
                document: res.data.attachment,
                id: res.data.id,
                name: nameItem,
                rate: price,
                rel_type: rel_type,
                tax: taxValue.tax,
              }
              setBrandsItems([update].concat(brandsItems))
            }
            setLoader(false);
            handleClose();
          }else{
            dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
            setLoader(false);
          }
        })
      } catch (error) {
        console.error(error);
      }
    }
  const deleteCatelogDocumment = (fileID) => {
    try {
      benchmarkServices.deleteCatelogItemDocument(editBrandItem.id, fileID).then((res) => {
        if (res.status) {
          editBrandItem.document = editBrandItem.document.filter((c) => c.id != fileID);
          removeImage(fileID);
          dispatch(showMessage("sucess", _l("l_success"), _l(res.message)));
        }else{
          dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
        }
      })
    } catch (error) {
      console.log('error', error);
    }
  }
    
  useEffect(() => {
    if (editBrandItem !== '') {
    let editImapgePrivew = []
    let editId = []
    editBrandItem && editBrandItem.document.map((edit) => {
      editImapgePrivew.push({
        name: edit.file_name,
        url: edit.image_url,
        id: edit.id,
      })
      editId.push(edit.id)
    })
    setimagePreview(editImapgePrivew)
    setEditImageIds(editId)
    }
  }, [])

  return (
    <>
        <Modal
        show={true}
        onHide={handleClose}
        backdrop={"static"}
        keyboard={false}
        size="lg"
        centered
        className="custom-modal-style solarcommanmodal maxheight85vh"
        aria-labelledby="contained-modal-title-vcenter"
        >
        <Modal.Header className="p-3" closeButton>
            <Modal.Title>{_l("l_add_item")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-hiiden-auto p-20pximp">

        <div className="form-wrapper-main p-0">
          <div className="row">
            <Form.Group className="col-xl-4 c-input-box pb-20px position-relative">
              <Form.Label className="input-label no-asterisk ">
              {_l("l_item_name")}
              </Form.Label>
              <Form.Control
                placeholder="Panneaux solaire"
                // as="textarea"
                type="text"
                name=""
                value={nameItem}
                onChange={(e) => setNameItem(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="col-xl-4 c-input-box pb-20px position-relative">
              <Form.Label className="input-label no-asterisk ">
              Prix ​​de l'article
              </Form.Label>
              <Form.Control
                placeholder="€150"
                // as="textarea"
                type="number"
                name=""
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="col-xl-4 c-input-box pb-20px position-relative">
              <Form.Label className="input-label no-asterisk ">
              Taux de TVA
              </Form.Label>
              <Select
                      className="custom-select-menu w-100"
                      classNamePrefix="react-select"
                      placeholder={_l('l_select_vat_rate')}
                      closeMenuOnSelect={true}
                      options={tax}
                      value={taxValue}
                      onChange={(value) => {
                        setTaxValue(value);
                      }}
                    />
            </Form.Group>

            <Form.Group className="col-xl-6 c-input-box  position-relative">
              <Form.Label className="input-label no-asterisk ">
                {_l("l_description")}
              </Form.Label>
              <Form.Control
                placeholder={`${_l("l_description")}`}
                as="textarea"
                type="text"
                rows={4}
                name=""
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="col-6 c-input-box d-flex flex-column position-relative">
            <Form.Label className="input-label no-asterisk ">
                {_l("l_file_upload")}
              </Form.Label>
              <div className="d-flex flex-grow-1 gap10px">
								        <div className="form-control file-upload bgblackop002 shadow-none position-relative p-0 flex-grow-1">
                          <div className="d-flex align-items-center justify-content-between p-15">
                            <input 
                              className="z-index-3 hidden-input" 
                              type="file"
                              multiple
                              onChange={(e) => {
                                uploadImage(
                                  e.target.files[0]
                                );
                              }}
                            />
                            <div className="m-auto">
                              <CloudArrowUp size={18} className="c-icons"/>
                              <span className="c-font color-white-60 f-10 fw-medium ps-2"> {_l("l_click_to_upload")}</span>
                            </div>
                          </div>
                        </div>
                        <div className="upload-image-preview  d-flex flex-wrap">
                        {imagePreview && imagePreview.length > 0 && 
                            imagePreview.map((image) => {
                              return(
                          <div className="h60w60">
                            <div className="comman-image-box h50w50 radius_3 upload-image with-margin">
                              
                                <div
                                  className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 cursor-pointer"
                                  style={{
                                    backgroundImage: `url(${image.url})`,
                                  }}
                                  data-tip={_l("l_preview_attachment")}
                                  data-effect="solid"
                                  data-delay-show="1000"
                                  data-class="tooltip-main"
                                ></div>
                              
                              <a
                                href="#/"
                                className="upload-close"
                                onClick={() => {
                                  if (editImageIds.includes(image.id)) {
                                    deleteCatelogDocumment(image.id);
                                  }else{
                                    removeImage(image.id);
                                  }
                                }}
                              >
                                <X
                                  size={10}
                                  className="c-icons"
                                />
                              </a>
                            </div>
                            <div className="c-font color-white-60 f-10 line-clamp-3">
                             {image.name}
                            </div>
                  </div>)})}
                    </div>
                    </div>
							    	</Form.Group>
          </div>
        </div>
        
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" disabled={loader} onClick={() => addItems()}>
            {loader ? 
              <>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    className={`me-2 `}
                    aria-hidden="true"
                  />
                  {_l('l_please_wait')}
              </> 
              : _l("l_save")} 
            </Button>
        </Modal.Footer>
        </Modal>
    </>
  );
};

export default AddCatalogItem;
