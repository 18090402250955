import React, { } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import CalendarBlankIcon from "../../assets/icons/CalendarBlankIcon";
import { _l } from "../../hooks/utilities";

const AddEmailTemplatesModal = ({
  show,
  handleClose,
}) => {
  const SelectDemoOption = [
    { value: "1", label: "role - 1" },
    { value: "2", label: "role - 2" },
    { value: "3", label: "role - 3" },
  ];


  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l("l_add_email_templates")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="form-wrapper-main">
            <div className="row m-0 mb-3 border-bottom">
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3 ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_email_templates")}
                </Form.Label>
                <Select className="custom-select-menu" options={SelectDemoOption} classNamePrefix="react-select" />
              </Form.Group>
            </div>
            <div className="row m-0 border-bottom">
              <Form.Group className="col-xl-12 c-input-box position-relative  mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_subject")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_subject")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_alternate_email")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_alternate_email")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  type="email"
                />
              </Form.Group>
              <Form.Group className="col-12 c-input-box position-relative mb-3 border-bottom p-15">
              <Form.Label className="input-label no-asterisk ">
                  {_l("l_date_to_be_notified")}
                </Form.Label>
                  <div className="d-flex align-items-center">
                    <div
                      className="custom-datepicker"
                      data-bs-toggle="tooltip"
                      title="Aug, 8 2022"
                    >
                      <div className="d-flex align-items-center form-control  color-white-60 dropdown">
                        <CalendarBlankIcon className="HW18 opacity-60" />
                        <DatePicker
                          selected=""
                          onChange=""
                          disabledKeyboardNavigation
                          placeholderText={_l("l_date_to_be_notified")}
                          dateFormat="dd MMMM yyyy"
                          selectsStart
                          defaultMenuIsOpen
                          calendarStartDay={1}
                        ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                      </div>
                    </div>
                  </div>
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3 border-bottom pb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_staff")}
                </Form.Label>
                <Select className="custom-select-menu" options={SelectDemoOption} classNamePrefix="react-select" />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3 border-bottom pb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_operator_ambassador")}
                </Form.Label>
                <Select className="custom-select-menu" options={SelectDemoOption} classNamePrefix="react-select" />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3 border-bottom pb-3">
                <Form.Label className="input-label no-asterisk ">
                {_l("l_description_placeholder")}
                </Form.Label>
                <Form.Control as="textarea" placeholder={_l("l_description_placeholder")} rows={3} />
              </Form.Group>
              <Form.Group className="col-12 c-input-box position-relative mb-3 border-bottom pb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_preview_email_template")}
                </Form.Label>
                <div className="form-check with-span">
                  <div className="d-flex align-items-center">
                    <Form.Label className="form-check-label text_wrap">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <span>{_l("l_send_email")}</span>
                    </Form.Label>
                  </div>
                </div>
                <Form.Control as="textarea" placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." rows={3} />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_email_address_label")}
                </Form.Label>
                <div className="d-flex">
                  <Form.Control
                    placeholder={_l("l_email_address_label")}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    type="email"
                    className="flex-grow-1"
                  />
                  <a href="#/" className="form-control border-left-0 w-auto"
                  >
                    {_l("l_send")}
                  </a>
                </div>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l("l_close")}
          </Button>
          <Button variant="primary" size="sm">{_l("l_send")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddEmailTemplatesModal;
