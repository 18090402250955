import {
  ChatCircle,
  FileText,
  MapPin,
  ShieldCheck,
  Star,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import { _l, hideLeftPanelNew, openNewChatChannel, removeExpandedClassFromIntroOfficeList } from "../../hooks/utilities";
import { OverlayTrigger, Popover } from "react-bootstrap";
import jquery from "jquery";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { commanoffcanvasisOpen, leftPanelFilters, setSolarhubFilters } from "../../actions/customer";
import { fetchMessages, setChatGroupsList, setChatGroupsListDetails, setClearmessage, setShowAccordionVisibility, setactiveChatScreenId, updateExternalGroupListMessages } from "../../actions/chat";
import customerServices from "../../services/customer-services";
import chatServices from "../../services/chat-services";

const ProposalCard = ({
  proposalDetail,
  onClickHandler,
  emailAddress,
  setEmailAddress = () => {},
}) => {
  const {groupListExternal,chatGroups } = useSelector((state) => state.chat);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [unreadNotificationCouts, setUnreadNotificationCouts] = useState(0);

  useEffect(() => {

    if (groupListExternal && groupListExternal.length > 0) {
      groupListExternal.map((gd)=>{
        if (gd.extraData && gd.extraData.proposalId == proposalDetail.proposal_id) {
          setUnreadNotificationCouts(gd.unreadCount)
        }
      })
    }
  }, [JSON.stringify(groupListExternal)])
  

  const goToDiscussion = () => {
    let unreadindex = groupListExternal.findIndex((gd)=> gd && gd.extraData && gd.extraData && gd.extraData.proposalId == proposalDetail.proposal_id)  
        if (unreadindex > -1) {
          let tempData = groupListExternal[unreadindex]
          tempData.unreadCount = 0
          dispatch(setSolarhubFilters({
            main_key: tempData.extraData.mainKey,
            filter_key: tempData.extraData.filterKey,
            title: tempData.groupName,
            image: tempData.displayPicture,
            main_sub_key: tempData.extraData.mainSubKey,
            sub_category: [],
          }))
        
          let finalData = Object.values({ ...groupListExternal, [unreadindex]: tempData })
          dispatch(updateExternalGroupListMessages(finalData))
          
        }      
        // dispatch(leftPanelFilters("","","all"))
        localStorage.setItem("currentProposal", JSON.stringify(proposalDetail));
        dispatch(leftPanelFilters("","","all"))
        // navigate(`/aichat?proposal_id=${proposalDetail.proposal_id}`)
        openNewChatChannel(proposalDetail.proposal_id);
    // window.open(`/aichat?proposal_id=${proposalDetail.proposal_id}`, "_blank");
    
  };

  let star = [
    {
      value: 1,
      titte: "l_worst",
    },
    {
      value: 2,
      titte: "l_bad",
    },
    {
      value: 3,
      titte: "l_average",
    },
    {
      value: 4,
      titte: "l_good",
    },
    {
      value: 5,
      titte: "l_excellent",
    },
  ];
  //  document.addEventListener("click", () => {setEmailAddress("")});
  useEffect(() => {
    jquery(document).mouseup(function (e) {
      var container = jquery(".popOverCloseGoogleRating");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        setEmailAddress("");
      }
    });
  });

  return (
    <React.Fragment>
      <div className="task-list-card list-view schedule-card-main document-list-card comman-white-box-shadow border radius10imp shadow-none d-flex">
      { proposalDetail && proposalDetail.company_logo ? (
        <div className="h100w100 me-20px border comman-white-box-shadow radius_3 overflow-hidden d-flex">
          <img className="maxhw100 m-auto" src={proposalDetail.company_logo} alt=""/>
            {/* <div className="comman-image-box-bg  bg-style-contain"  style={{ backgroundImage: `url(${proposalDetail.company_logo})` }}></div> */}
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )} 
        <div className="w100minus100per">
        <div className=" d-flex  flex-column flex-sm-row justify-content-between flex-wrap gap-3">
          <div className="pb-3 pb-sm-0 d-flex align-items-center">
            {/* {proposalDetail && proposalDetail.company_logo ? (
              <img
                src={proposalDetail.company_logo}
                alt={"solarbox"}
                className="h50px me-10px w-120"
              /> ) : (
              <React.Fragment></React.Fragment>
            )}  */}
            <span className="fw-semibold title-fonts">
              {proposalDetail.company_name}
            </span>
            <span
              className={`ps-2 pe-15px ${
                proposalDetail.is_verified > 0 ? "opacity-1" : "opacity-0"
              }`}
            >
              <ShieldCheck
                size={18}
                weight="fill"
                className={"c-icons color-green"}
              />
            </span>
            {proposalDetail.compli_tasks > 0 ? (
              <span className="badge  badge-white rounded-pill fw-light h32wauto c-font f-12 ms-20px">
                {`${proposalDetail.compli_tasks} ${_l("l_tasks")}`}
              </span>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </div>
          <div className="d-flex justify-content-between justify-content-sm-end align-items-start align-items-sm-center  flex-sm-row">
            {proposalDetail.amount > 0 && proposalDetail.status == 4 ? (
              <></>
            ) : (
              <div className="with_separator_10 before-dnone ps-0"> 
                <span className={`badge badge-white rounded-pill bg-transparent borderblack10 fwmedium h32wauto c-font f-12 title-fonts
                  ${proposalDetail.status == 2
                    ? "color-redpill"
                    : proposalDetail.status == 3
                    ? "color-greenpill"
                    : "color-greenpill"} `}>
                  {proposalDetail.status == 2
                    ? _l("l_rejected")
                    : proposalDetail.status == 3
                    ? _l("l_accepted")
                    : _l("l_waiting_for_provider")}
                </span>
              </div>
            )}
            <div className="with_separator_10 c-font f-16 ps-1 fw-semibold text-center title-fonts pe-0">
              € {proposalDetail.proposal_item.reduce((accumulator, currentItem) => {
                  return accumulator + Number(currentItem.one_time_amount) + Number(currentItem.one_time_tax_amount);
                }, 0).toFixed(2)}
            </div>
          </div>
        </div>
        <div className="p-15 px-0">
          <div className="border-bottom"></div>
        </div>
        <div>
          <div className="d-flex flex-column flex-sm-row justify-content-between flex-wrap gap-3">
            <div className="d-flex flex-column flex-sm-row gap-2">
              <div className="c-font color-white-60 f-12 with_separator_10 d-sm-flex align-items-center ps-0">
                <MapPin size={18} weight="fill" className="c-icons" />
                <span className="ms-10px max-width-160px text-truncate">
                  {proposalDetail.address}
                </span>
              </div>
              <div
                className="with_separator_10 d-flex popOverCloseGoogleRating"
                onClick={() => {
                  if (emailAddress == proposalDetail.email) {
                    setEmailAddress("");
                  } else {
                    setEmailAddress(proposalDetail.email);
                  }
                }}
              >
                {["auto"].map((placement) => (
                  <OverlayTrigger
                    // trigger={[ 'focus', 'click']}
                    delay={{ show: 1500 }}
                    key={placement}
                    placement={placement}
                    show={emailAddress == proposalDetail.email ? true : false}
                    onEnter={() => {}}
                    overlay={
                      <Popover
                        id={`popover-positioned-${placement}`}
                        className={`w-500`}
                      >
                        <Popover.Body
                          className={`p-0 position-relative overflow-auto max-h50vh rating-popover comman-white-box-shadow radius_3 `}
                        >
                          <div className="title-font f13 position-sticky top-0 z-index-2 bg-white-03-solid-imp p-10 border-bottom">
                            <div className="c-font f-16 fw-semibold title-fonts">
                              {proposalDetail.company_name}
                            </div>
                            <div className="c-font color-white-60 f-10">
                              {proposalDetail.address}
                            </div>
                            <div className="d-flex align-items-center pt-1 pe-10px">
                              <div className="c-font f-18 color-white-60 d-flex align-items-center">
                                {proposalDetail.google_rating}
                              </div>
                              <div className="d-flex align-items-center">
                                {star.map((item, index) => {
                                  return (
                                    <>
                                      <div
                                        className={`rating-popover-items text-center d-flex flex-column px-1 py-0 ${
                                          index <
                                          Math.round(
                                            proposalDetail.company_rating
                                          )
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        {index <
                                        Math.round(
                                          proposalDetail.company_rating
                                        ) ? (
                                          <Star
                                            size={18}
                                            weight="fill"
                                            className="c-icons"
                                          />
                                        ) : (
                                          <Star size={18} className="c-icons" />
                                        )}
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          {proposalDetail.reviews &&
                            proposalDetail.reviews.map((item) => {
                              return (
                                <>
                                  <div className="popover-detail-wrapper padding_10 py-0">
                                    <div className="d-flex p-10 px-0 hr_sepretr">
                                      <div className="h32w32 rounded-5">
                                        <div
                                          className="com bg-style-cover h-100 w-100 rounded-circle comman-image-box"
                                          style={{
                                            backgroundImage: `url(${item.author_image})`,
                                          }}
                                        ></div>
                                      </div>
                                      <div className="d-flex flex-column w100minus100per text-truncate ms-10px ">
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div className="">
                                            <div className="text-truncate w-100">
                                              {item.author_name}
                                            </div>
                                            {/* <div className='c-font color-white-60 f-10'>2 reviews·12 photos</div> */}
                                          </div>
                                          <div className="text-end">
                                            <div className="d-flex align-items-center">
                                              {star.map((star, index) => {
                                                return (
                                                  <>
                                                    <div
                                                      className={`rating-popover-items text-center d-flex flex-column px-1 py-0 ${
                                                        index <
                                                        Math.round(item.rating)
                                                          ? "active"
                                                          : ""
                                                      }`}
                                                    >
                                                      {index <
                                                      Math.round(
                                                        item.rating
                                                      ) ? (
                                                        <Star
                                                          size={18}
                                                          weight="fill"
                                                          className="c-icons"
                                                        />
                                                      ) : (
                                                        <Star
                                                          size={18}
                                                          className="c-icons"
                                                        />
                                                      )}
                                                    </div>
                                                  </>
                                                );
                                              })}
                                            </div>
                                            <div className="c-font color-white-60 f-10 pt-1">
                                              {item.relative_time_description}
                                            </div>
                                          </div>
                                        </div>
                                        <p className="text-wrap color-white-60 c-font f-10 pt-10px mb-0">
                                          {item.text}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <div className="d-flex align-items-center cursor-pointer">
                      {proposalDetail.company_rating > 0 ? (
                        <div className="c-font color-white-60 f-12 fw-semibold">
                          {proposalDetail.company_rating}
                        </div>
                      ) : (
                        <div className="c-font color-white-60 f-12 fw-semibold">
                          0.0
                        </div>
                      )}
                      <div className="d-flex align-items-center ms-10px pe-10px">
                        {star.map((star, index) => {
                          return (
                            <>
                              <div
                                className={`star-rating-items text-center d-flex flex-column active p-0 ${
                                  index <
                                  Math.round(proposalDetail.company_rating)
                                    ? "active"
                                    : ""
                                }`}
                              >
                                {index <
                                Math.round(proposalDetail.company_rating) ? (
                                  <Star
                                    size={18}
                                    weight="fill"
                                    className="c-icons"
                                  />
                                ) : (
                                  <Star size={18} className="c-icons" />
                                )}
                              </div>
                            </>
                          );
                        })}
                        {/* <span className="star-rating-items text-center d-flex flex-column active p-0">
                      <Star
                        size={18}
                        weight={
                          proposalDetail.company_rating > 1 ? "fill" : "light"
                        }
                        className="c-icons"
                      />
                    </span>
                    <span className="star-rating-items text-center d-flex flex-column active p-0">
                      <Star
                        size={18}
                        weight={
                          proposalDetail.company_rating > 2 ? "fill" : "light"
                        }
                        className="c-icons"
                      />
                    </span>
                    <span className="star-rating-items text-center d-flex flex-column active p-0">
                      <Star
                        size={18}
                        weight={
                          proposalDetail.company_rating > 3 ? "fill" : "light"
                        }
                        className="c-icons"
                      />
                    </span>
                    <span className="star-rating-items text-center d-flex flex-column active p-0">
                      <Star
                        size={18}
                        weight={
                          proposalDetail.company_rating > 4 ? "fill" : "light"
                        }
                        className="c-icons"
                      />
                    </span>
                    <span className="star-rating-items text-center d-flex flex-column active p-0">
                      <Star
                        size={18}
                        weight={
                          proposalDetail.company_rating > 5 ? "fill" : "light"
                        }
                        className="c-icons"
                      />
                    </span> */}
                      </div>
                      {/* { 
                    proposalDetail.company_total_review > 0
                    ?
                    <div className="c-font color-white-60 f-12">
                        {proposalDetail.company_total_review} avis Google
                    </div>
                    :
                    <React.Fragment></React.Fragment>
                  } */}
                      <div className="c-font color-white-60 f-12">
                        {proposalDetail.company_total_review} avis Google
                      </div>
                    </div>
                  </OverlayTrigger>
                ))}
              </div>
            </div>
            <div className={`d-flex flex-column flex-sm-row align-items-start align-item-sm-center`}>
              <div className="d-flex align-items-center">
                <a
                  href="#/"
                  className="btn btn-white-05 position-relative fw-semibold"
                  onClick={() => {
                    // return
                    if (proposalDetail.proposalId == 0) {
                      
                    }else{
                      goToDiscussion()
                    }
                  }}
                >
                  <ChatCircle size={15} className="c-icons" weight="light" />
                  <span className="ms-10px c-font f-12">
                    {_l("l_discussion")}
                  </span>
                  {proposalDetail.unread_message_count > 0 ? (
                    <div className=" rounded-pill badge-custom  d-flex p-2 light-pill ">
                      1
                    </div>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  {unreadNotificationCouts > 0 ? <span class="rounded-pill badge-custom  d-flex p-2 light-pill border-1">{unreadNotificationCouts}</span> : <></>}
                </a>
                {/* <span className={`c-font f-12 d-flex justify-content-center ms-10px`}>0</span> */}
              </div>
              {proposalDetail.amount > 0 ? (
                <div className="d-flex align-items-center mt-3 mt-sm-0">
                  <div className="with_separator_10 me-10px px-0"></div>
                  <div className="d-flex align-items-center">
                    <div className="with_separator_10 pe-0">
                      <a
                        href="#/"
                        className="btn btn-primary position-relative yellowbg"
                        onClick={() => {
                          dispatch(setShowAccordionVisibility(false));
                          dispatch(commanoffcanvasisOpen(true));
                          removeExpandedClassFromIntroOfficeList();
                          hideLeftPanelNew();
                          onClickHandler(proposalDetail)
                        }}
                      >
                        <FileText
                          size={15}
                          className="c-icons"
                          weight="light"
                        />
                        <span className="ms-10px c-font f-12">
                          {_l("l_view_proposal")}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </div>
          </div>
        </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProposalCard;
