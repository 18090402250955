import React, { useEffect, useState } from "react";
import { _l, showError } from "../../hooks/utilities";
import CommanLoader from "../Loader/CommanLoader";
import DocumentsServices from "../../services/documents-services";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import CustomerServices from "../../services/customer-services";

const DocumentModal = ({ show, handleClose, documentData, type,fromTopicSetting=false,isExternalWithNoLeftPanel=false }) => {
  const selectedOffice = localStorage.getItem("selectedOffice");

  const [data, setData] = useState(documentData);
  const [loader, setLoader] = useState(true);

  useEffect(() => {

    const fetchDocumentDetails = async () => {
      try {
        let response;
        setLoader(true);

        switch (type) {
          case "subscription":
            response = await DocumentsServices.getSubscriptionDetail(selectedOffice, documentData.id, documentData.hash);
            break;
          case "invoice":
            response = await DocumentsServices.getInvoiceDetail(selectedOffice, documentData.id, documentData.hash, documentData.is_app_invoice);
            break;
          case "estimate":
            response = await DocumentsServices.getEstimateDetail(selectedOffice, documentData.id, documentData.hash);
            break;
          case "credit_note":
            response = await DocumentsServices.getCreditNoteDetail(selectedOffice, documentData.id, "", documentData.is_app_credit_note);
            break;
          case "spaceDocument":
            response = await CustomerServices.getSpaceDocument(documentData.floor_id);
            break;
          case "premiumEstimate":
            let responseEstimateId = await DocumentsServices.getPremiumEstimateDetail(documentData.id);
            if (responseEstimateId && responseEstimateId.status == 1) {
              let responseEstimate = responseEstimateId.data;
              if (responseEstimate.estimate && responseEstimate.estimate.subscription_id > 0) {
                response = await DocumentsServices.getSubscriptionDetail(documentData.id, responseEstimate.estimate.subscription_id, responseEstimate.estimate.subscription_hash);
              } else {
                response = await DocumentsServices.getEstimateDetail(documentData.id, responseEstimate.estimate.estimate_id);
              }
            } else {
              showError(responseEstimateId.message);
            }
            break;
            case "documents":
            case "mandate" :
            case "dp" :
            case "reference" :
              const { id, file_name, image_url, file }  = documentData;
              const docData = {...documentData,
                  id: id,
                  title: file_name,
                  description: "",
                  floor_name: null,
                  entry: null,
                  mandate_id : Number(documentData.id) > 0 ? documentData.id : 0,
                  attachments: [
                      {
                          id: id,
                          file_name: file_name ? file_name : file,
                          file_url: image_url
                      }
                  ]
              }
              setData(docData)
              break;
  
          default:
            break;
        }

        if (response && response.status == 1 && response.data) {
          setData(response.data);
        }
        
      } catch (e) {
        console.error(e);
      }
      finally {
        setLoader(false);
      }
    };

    fetchDocumentDetails();
  }, []);

  return (
    <>
      {loader ? (
            <CommanLoader />
          ) : (
            <React.Fragment>
                <CommanOffCanvas 
                data={data}
                setData={setData}
                docType={type}
                handleClose={handleClose}
                pinHandler={() => { }}
                setSelectedId={() => { }}
                show={show}
                isExternal={false}
                isFullScreen={true}
                disableFullScreenToggle={true}
                fromTopicSetting={fromTopicSetting}
                handleParentComponentAction={() => {}}
                isExternalWithNoLeftPanel={isExternalWithNoLeftPanel}
                />
            </React.Fragment>
          )}
    </>
  );
};

export default DocumentModal;
