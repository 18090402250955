import React from 'react';
// import CSS from '../css/icon_main.css';

function ChatIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<path d="M5.22813 12.6187L3.20937 14.3062C3.13653 14.3665 3.04809 14.4049 2.95431 14.417C2.86053 14.429 2.76526 14.4142 2.67955 14.3743C2.59383 14.3344 2.52119 14.271 2.47005 14.1915C2.41892 14.1119 2.39137 14.0195 2.39062 13.925V4C2.39062 3.86739 2.4433 3.74021 2.53707 3.64645C2.63084 3.55268 2.75802 3.5 2.89062 3.5H13.8906C14.0232 3.5 14.1504 3.55268 14.2442 3.64645C14.3379 3.74021 14.3906 3.86739 14.3906 4V12C14.3906 12.1326 14.3379 12.2598 14.2442 12.3536C14.1504 12.4473 14.0232 12.5 13.8906 12.5H5.54688C5.42981 12.5 5.31667 12.5422 5.22813 12.6187V12.6187Z" />
			</svg>
		</React.Fragment>
	)
}

export default ChatIcon