import React from 'react';
// import CSS from '../css/icon_main.css';

function AppointmentIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
		<svg width="192" height="192" viewBox="0 0 192 192" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M84 84H132M84 108H132M60 30V162M36 30H156C159.314 30 162 32.6863 162 36V156C162 159.314 159.314 162 156 162H36C32.6863 162 30 159.314 30 156V36C30 32.6863 32.6863 30 36 30Z" stroke-width="9" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


		</React.Fragment>
	)
}

export default AppointmentIcon