import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import LeftPanel from "../components/LeftPanel";
import { leftPanelMouseUpEvent } from "../hooks/utilities";
import { setOnboardingStepData } from "../actions/onboarding-actions/onboarding-actions";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import EquipmentMainScreen from "../components/ExploreFeatures/Equipments/EquipmentMainScreen";

const ConnectProviderView = () => {

  const [searchParams] = useSearchParams();

  const [projectId, setProjectId] = useState(searchParams.get("space"));
  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener("mouseup", leftPanelMouseUpEvent);
    dispatch(
      setOnboardingStepData({
        step: "space-onboarding",
      })
    );

    return () => {
      document.removeEventListener("mouseup", leftPanelMouseUpEvent);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="d-flex flex-column h-100">
        <EquipmentMainScreen/>
      </div>
    </React.Fragment>
  );
};

export default ConnectProviderView;
