import React from 'react'
const CalendarWeekboxSkeleton = () => {
  return (
    <>
      <div className="SkeletonMain WeekDetailbox calendar-box-bg radius_3">
        <a href="#/" className="WeekDetailHeader c-font f-12 d-block">
          <div className='flex-grow-1 d-flex' style={{ height: '18px' }}>
          <div className='radius_3 skeleton-animation m-auto' style={{ height: '8px', width: '70%' }}></div>
          </div>
        </a>
        <div className="p-15 radius_3">
          <div className="rounded-5 skeleton-animation h50w50 d-flex mx-auto">
          </div>
          <div className='d-flex flex-grow-1 me-10px mt-15px mx-auto radius_3 skeleton-animation' style={{ height: '8px', width: '50%' }}></div>
          <div className='d-flex flex-grow-1 mt-10 mx-auto radius_3 skeleton-animation' style={{ height: '6px' }}></div>
        <div className="d-flex align-items-center justify-content-center comman_action_icon mt-3">
          <div className='me-10px radius_3 skeleton-animation' style={{ height: '33px', width: '50px' }}></div>
          <div className='me-10px radius_3 skeleton-animation' style={{ height: '33px', width: '50px' }}></div>
        </div>
        </div>
      </div>
    </>
  )
}
export default CalendarWeekboxSkeleton