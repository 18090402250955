import React from 'react';
// import CSS from '../css/icon_main.css';

function ChatCircleIcons(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"  viewBox="0 0 256 256" className={`comman-icons with-fill ${CommanHW}`}><path d="M128,26A102,102,0,0,0,38.35,176.69L26.73,211.56a14,14,0,0,0,17.71,17.71l34.87-11.62A102,102,0,1,0,128,26Zm0,192a90,90,0,0,1-45.06-12.08,6.09,6.09,0,0,0-3-.81,6.2,6.2,0,0,0-1.9.31L40.65,217.88a2,2,0,0,1-2.53-2.53L50.58,178a6,6,0,0,0-.5-4.91A90,90,0,1,1,128,218Z"></path></svg>

		</React.Fragment>
	)
}

export default ChatCircleIcons