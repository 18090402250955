import React, { useEffect, useState } from "react";
import { _l } from "../../../hooks/utilities";
import Offcanvas from "react-bootstrap/Offcanvas";
import CommonHeaderBody from "../../TaskModalComponents/CommonHeaderBody";
import CommonHeaderModal from "../../TaskModalComponents/CommonHeaderModal";
import CommonFooter from "../../TaskModalComponents/CommonFooter";
import { CalendarBlank,DotsThreeVertical, ChatCircle} from "phosphor-react";
import FilesComponent from "./FilesComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TaskListStatusDropdown from "../../Dropdowns/TaskListStatusDropdown";
import InvoiceWalletCard from "./InvoiceWalletCard";
import CreditNoteWalletCard from "./CreditNoteWalletCard";
import WalletOffcanvasListCard from "./WalletOffcanvasListCard";
import WalletTaskCard from "./WalletTaskCard";
import CustomTextEditor from "../../CustomTextEditor/CustomTextEditor";

const WalletOffcanvas = ({show,handleClose}) => {
  const [activeTab, setActiveTab] = useState("details");
  return (
    <>
      <div className="tab-wrapper_main pb-3 res-overflow-auto">
          <div className="d-flex align-items-center justify-content-between border-bottom">
            <div className="comman-tab-wrapper pb-0">
              <div className="d-flex align-items-center">
                <div
                  className={`tab-wrapper  for-accessible ${activeTab === "details" ? "active" : ""
                      } `}>
                  <a href="#/"
                    className="tab-name"
                    onClick={() => {
                        setActiveTab("details");
                        
                    }}>
                      {_l("l_details")}
                  </a>
              </div>
              <div
                  className={`tab-wrapper  for-accessible ${activeTab === "files" ? "active" : ""
                      } `}>
                  <a href="#/"
                    className="tab-name"
                    onClick={() => {
                        setActiveTab("files");
                        
                    }}>
                      {_l("l_files")}
                  </a>
              </div>

              </div>
            </div>
          </div>
      </div>
      <div className="tast-detail-tab-wrapper res-height-auto mb-3">
        <div className={`${activeTab === "details" ? "" : "d-none"} h-100`}
          >
            {/* <CustomTextEditor /> */}
            <WalletTaskCard />
            <div className="d-none">
              <InvoiceWalletCard />
              <CreditNoteWalletCard />
            </div>
        </div>
        <div className={`${activeTab === "files" ? "" : "d-none"} h-100`}>
          <div className="waller-card-wrapper">
            <FilesComponent />
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletOffcanvas;