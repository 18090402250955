import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState } from "react";
import SubscriptionBasicdetails from "../../tabs/SubscriptionBasicdetails";
import InformationPaytabs from "../../tabs/PaytmTabs/InformationPaytabs";
import { _l } from "../../../../hooks/utilities";
import DocumentsServices from "../../../../services/documents-services";
import { useDispatch } from "react-redux";
import { showMessage } from "../../../../actions/messages";

const Subscriptions = ({ data, handleSubscription, handleCommonButtonToggle, handleSubscriptionBody }) => {
  const [selectedTab, setSelectedTab] = useState("basicdetails");
  const dispatch = useDispatch();
  const [selectedContactEmail, setSelectedContactEmail] = useState("");
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    // setSelectedTab(
    //   data && data.status == "not_subscribed" ? "information" : "basicdetails"
    // );
    if (data && data.contacts && data.contacts.length) {
      setContacts(data.contacts.map(item => ({ value: item.email, label: item.firstname + ' ' + item.lastname })));
    }
  }, [JSON.stringify(data)]);

  const subscribe = (date) => {
    if (selectedContactEmail == "") {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_select_contact"))
      );
    } else {
      DocumentsServices.subscribe(data.hash, date, selectedContactEmail, undefined, undefined, undefined, undefined, undefined, undefined, data.payment_mode).then((res) => {
        if (res.status && res.status == 1) {
          handleSubscription();
        } else {
          dispatch(
            showMessage("unsucess", _l("l_error"), _l("l_something_went_wrong"))
          );
        }
      });
    }
  };
  useEffect(() => {
    if (selectedTab == 'information') {
      handleCommonButtonToggle('commonHeaderBodyData', _l("l_subscribe"))
    } else {
      handleCommonButtonToggle('commonHeaderBodyData', '')
    }
  }, [selectedTab])
  return (
    <>
 <> <div className="tab-wrapper_main pb-3 res-overflow-auto">
        <div className="d-flex align-items-center justify-content-between border-bottom for-accessible">
          <div className="comman-tab-wrapper p-15 pt-0 pb-0">
            <div className="d-flex align-items-center">
              {/* <div
                className={`tab-wrapper task-detail-tab d-flex align-items-center ${
                  data && data.status != "active" ? "" : "d-none"
                } ${selectedTab === "items" ? "active" : ""}`}
              >
                <a href="#/"
                  className="tab-name"
                  onClick={() => {
                    setSelectedTab("items");
                  }}
                >
                  Items
                </a>
              </div> */}
              {/* <div
                className={`tab-wrapper task-detail-tab d-flex align-items-center ${data && data.status == "not_subscribed" ? "" : "d-none"
                  } ${selectedTab === "information" ? "active" : ""}`}
              >
                <a href="#/"
                  className="tab-name"
                  onClick={() => {
                    setSelectedTab("information");
                  }}
                >
                  {_l("l_information")}
                </a>
              </div> */}
              <div
                className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === "basicdetails" ? "active" : ""
                  }`}
              >
                <a href="#/"
                  className="tab-name"
                  onClick={() => {
                    setSelectedTab("basicdetails");
                  }}
                >
                  {_l("l_basic_details")}
                </a>
              </div>
            </div>
          </div>
          <div className="task-header-right comman-tab-wrapper p-0">
            {/* <div
              className={`d-flex align-items-center tab-wrapper justify-content-end ${data && data.status == "not_subscribed" ? "" : "d-none"
                }`}
            >
              <a href="#/"
                className="tab-name py-2"
                onClick={() => {
                  subscribe(data.date);
                }}
              >
                <button type="button" class="btn btn-primary btn-sm">
                  {_l("l_subscribe")}
                </button>
              </a>
            </div> */}
          </div>
        </div>
      </div>
      <div className="overflow-hiiden-auto comman_vertical_btn_h p-15">
        {selectedTab === "basicdetails" ? (
          <SubscriptionBasicdetails data={data} />
        ) : // : selectedTab === "items" ? (
          //   <SubscriptionItems />
          // )
          selectedTab === "information" ? (
            <InformationPaytabs data={data} contacts={contacts} selectedContactEmail={selectedContactEmail} setSelectedContactEmail={setSelectedContactEmail} handleSubscriptionBody={handleSubscriptionBody} />
          ) : (
            <React.Fragment></React.Fragment>
          )}
        {/* : (
         <Paytm />
        )} */}
      </div>
      </>
    </>
  );
};
export default Subscriptions;
