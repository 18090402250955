import React, { useEffect, useState } from "react";
import { _l } from "../../hooks/utilities";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { CaretRight, Hash, UserCircleGear } from "phosphor-react";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import PremiumEstimateIconCrown from "../../assets/icons/PremiumEstimateIconCrown";
import CategoryStaffList from "./CategoryStaffList";
import InviteProvider from "../Dropdowns/InviteProvider";
import { useDispatch, useSelector } from "react-redux";
import { createBenchmarkOrNot } from "../../actions/customer";
import store from "../../store";

const EquipmentsSubMenu = ({
  unContolList = false,
  subCategory,
  handler,
  item,
  loader,
  openAssigneeDropdown,
  taskId,
}) => {
  const dispatch = useDispatch();
  const { operatorListByCategory, benchmark_create, taskFilters } = useSelector(
    (state) => state.customer
  );
  const [myrStaffPast, setmyrStaffPast] = useState([]);
  const [myrStaff, setmyrStaff] = useState([]);
  const [openStaticDropdown, setOpenStaticDropdown] = useState([
    false,
    false,
    false,
  ]);
  const [stop, setStop] = useState(true);
  const [isParentMenuLoaded, setIsParentMenuLoaded] = useState(false);

  const user_type = localStorage.getItem("user_type");

  const openDropdown = (index) => {
    setOpenStaticDropdown(
      openStaticDropdown.map((w, i) => (w = w == true ? false : i == index))
    );
  };

  const setNewMyrStaffPast = (data) => {
    let newMyrStaffPast = myrStaffPast.concat(data.extra.invitee_list);
    setmyrStaffPast(newMyrStaffPast);
    let key = newMyrStaffPast.findIndex(
      (user) => user.staffid == data.data[0].staffid
    );
    if (key > -1) {
      handler(item.type, item.id, item, "tag", newMyrStaffPast[key]);
    }
  };

  const getPastProviderCount = () => {
    let count = 0;
    myrStaffPast.forEach((staff) => {
      count += staff.operators_count;
    });
    return count;
  }

  const EquipmentsSubMenuContain = (
    <>
      {item.type == "equipments" && subCategory && subCategory.length > 0 ? (
        subCategory
          .sort((a, b) => a.contact_role - b.contact_role)
          .map((sub) => {
            return (
              <UncontrolledDropdown className="" direction="left">
                <dropDownToggle
                  className="dropend w-100"
                  onClick={() => {
                    handler(item.type, item.id, item, "tag", sub);
                  }}
                >
                  <div
                    className={`d-flex align-items-center hr_sepretr dropdown-item custom-assigneesMain ${
                      sub.is_assigned == 1 ? "active with-tick" : ""
                    }`}
                  >
                    {sub.profile_image_url ? (
                      <div
                        className="h25w25 comman-round-box with-bg rounded-circle bg-white-05"
                        aria-expanded="false"
                      >
                        {sub.contact_role == 1 ? (
                          <span className="bg-transparent border-0 rounded-pill badge-custom d-flex p-2 light-pill bg-none">
                            <PremiumEstimateIconCrown className="stroke-transparent path-warning HW14 position-absolute" />
                          </span>
                        ) : sub.contact_role == 2 ? (
                          <span className="badge-custom bg-none bg-transparent border-0 d-flex light-pill p-1 rounded-pill">
                            <UserCircleGear
                              size={20}
                              color="#fca120"
                              weight="light"
                            />
                          </span>
                        ) : (
                          <></>
                        )}
                        <div
                          className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                          style={{
                            backgroundImage: `url('${sub.profile_image_url}')`,
                          }}
                        ></div>
                      </div>
                    ) : (
                      <div className="c-list-icon">
                        <Hash size={20} weight="light" className="c-icons" />
                      </div>
                    )}
                    <div className="ps-2 w100minus40 text_wrap">
                      <div className=" c-list-detail text-truncate ">
                        {sub.name}
                      </div>
                    </div>
                  </div>
                </dropDownToggle>
              </UncontrolledDropdown>
              // {/* </DropdownItem> */}
            );
          })
      ) : item.type == "providers" ? (
        <>
          {user_type == "staff" ? (
            <li className="w-100">
              <a
                href="#/"
                className="dropdown-item custom-assignees custom-class-active hr_sepretr w-100"
              >
                <div className="d-flex align-items-center">
                  <input
                    className="form-check-input ms-1"
                    type="checkbox"
                    onChange={(e) =>
                      dispatch(createBenchmarkOrNot(e.target.checked))
                    }
                    checked={benchmark_create}
                  />
                  <div className="ps-2 w100minus40 text_wrap">
                    {_l("l_create_benchmark") + "?"}
                  </div>
                </div>
              </a>
            </li>
          ) : (
            <></>
          )}
          <UncontrolledDropdown
            isOpen={openAssigneeDropdown && openStaticDropdown[0]}
            setActiveFromChild={true}
            direction="left"
            className="w-200"
          >
            <DropdownToggle
              onClick={() => openDropdown(0)}
              toggle="false"
              className="w-100 custom-btn-dropdown-items"
            >
              <a
                href="#/"
                className="dropdown-item custom-assignees custom-class-active hr_sepretr w-100"
              >
                <div className="d-flex align-items-center">
                  <div className="c-list-icon">
                    <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
                      <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle d-flex">
                        <Hash size={16} weight="light" className="c-icons" />
                      </div>
                    </div>
                  </div>
                  <div className="ps-2 w100minus40 text_wrap">
                    {_l("l_invite_provider")}
                  </div>
                  <div className="c-list-icon">
                    <CaretRight size={14} weight="light" className="c-icons" />
                  </div>
                </div>
              </a>
            </DropdownToggle>
            <InviteProvider
              item={item}
              openAssigneeDropdown={
                openAssigneeDropdown && openStaticDropdown[0]
              }
              setNewMyrStaffPast={setNewMyrStaffPast}
              taskId={taskId}
            />
          </UncontrolledDropdown>
          <UncontrolledDropdown
            isOpen={openAssigneeDropdown && openStaticDropdown[1]}
            setActiveFromChild={true}
            direction="left"
            className="w-200"
          >
            <DropdownToggle
              onClick={() => openDropdown(1)}
              toggle="false"
              className="w-100 custom-btn-dropdown-items"
            >
              <a
                href="#/"
                className="dropdown-item custom-assignees custom-class-active hr_sepretr w-100"
                onClick={() => {}}
              >
                <div className="d-flex align-items-center">
                  <div className="c-list-icon">
                    <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
                      <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle d-flex">
                        <Hash size={16} weight="light" className="c-icons" />
                      </div>
                    </div>
                  </div>
                  <div className="ps-2 w100minus40 text_wrap">
                    {_l("l_choose_from_past")}
                  </div>
                  {
                    getPastProviderCount() > 0
                    ?
                    <span className="rounded-circle green-text badge with-bg d-flex h20w20 p-0">
                      <span className="m-auto p-0">{getPastProviderCount()}</span>
                    </span>
                    :
                    <React.Fragment></React.Fragment>
                  }
                  <>
                    {subCategory &&
                    subCategory.filter(
                      (sc) => sc.is_existing == 1 || sc.is_existing == 0
                    ).length > 0 ? (
                      <span className="rounded-circle green-text badge with-bg d-flex h20w20 p-0">
                        {" "}
                        <span className="m-auto p-0">
                          {
                            subCategory.filter(
                              (sc) => sc.is_existing == 1 || sc.is_existing == 0
                            ).length
                          }
                        </span>
                      </span>
                    ) : (
                      ""
                    )}
                  </>
                  <div className="c-list-icon">
                    <CaretRight size={14} weight="light" className="c-icons" />
                  </div>
                </div>
              </a>
            </DropdownToggle>
            <CategoryStaffList
              handler={handler}
              item={item}
              type={"past"}
              list={myrStaffPast}
              subCategory={
                subCategory &&
                subCategory.filter(
                  (sc) => sc.is_existing == 1 || sc.is_existing == 0
                )
              }
              loader={loader}
              openAssigneeDropdown={
                openAssigneeDropdown && openStaticDropdown[1]
              }
              taskId={taskId}
            />
          </UncontrolledDropdown>
          <UncontrolledDropdown
            isOpen={openAssigneeDropdown && openStaticDropdown[2]}
            setActiveFromChild={true}
            direction="left"
            className="w-200"
          >
            <DropdownToggle
              onClick={() => openDropdown(2)}
              toggle="false"
              className="w-100 custom-btn-dropdown-items"
            >
              <a
                href="#/"
                className="dropdown-item custom-assignees custom-class-active hr_sepretr w-100"
                onClick={() => {}}
              >
                <div className="d-flex align-items-center">
                  <div className="c-list-icon">
                    <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
                      <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle d-flex">
                        <Hash size={16} weight="light" className="c-icons" />
                      </div>
                    </div>
                  </div>
                  <div className="ps-2 w100minus40 text_wrap">
                    {_l("l_myr_network")}
                  </div>
                  <>
                    {subCategory &&
                    subCategory.filter((sc) => sc.is_existing == 2).length >
                      0 ? (
                      <span className="rounded-circle green-text badge with-bg d-flex h20w20 p-0">
                        {" "}
                        <span className="m-auto p-0">
                          {
                            subCategory.filter((sc) => sc.is_existing == 2)
                              .length
                          }
                        </span>
                      </span>
                    ) : (
                      ""
                    )}
                  </>
                  <div className="c-list-icon">
                    <CaretRight size={14} weight="light" className="c-icons" />
                  </div>
                </div>
              </a>
            </DropdownToggle>
            <CategoryStaffList
              handler={handler}
              item={item}
              type={"myrProvider"}
              list={myrStaff}
              subCategory={
                subCategory && subCategory.filter((sc) => sc.is_existing == 2)
              }
              loader={loader}
              openAssigneeDropdown={
                openAssigneeDropdown && openStaticDropdown[2]
              }
              taskId={taskId}
            />
          </UncontrolledDropdown>
        </>
      ) : (
        <CommanPlaceholder
          imgWidth="h60w60"
          placeholderText={_l("l_no_data")}
        />
      )}
    </>
  );

  useEffect(() => {
    if (!openAssigneeDropdown) {
      setOpenStaticDropdown([false, false, false]);
    }
    else {
        //Timeout to get the parent menu load in order to avoid 
        setTimeout(() => {
          setIsParentMenuLoaded(true)
        });
    }
  }, [openAssigneeDropdown]);

  useEffect(() => {
    if (operatorListByCategory) {
      setmyrStaff(operatorListByCategory.extra.total_myr_providers_data);
      let assigndOperatorsCompanies = [];
      
      const { taskList } = store.getState().customer;

      const task = taskList.find((w) => w.id == taskId);
      if(task && task.schedule_assignees)
      { 
        assigndOperatorsCompanies = task.schedule_assignees.map((sa) => sa.userid);
      }
   
      setmyrStaffPast(operatorListByCategory.data.map((company) => {
        if(assigndOperatorsCompanies.includes(company.userid))
        {
          company.operators_count = assigndOperatorsCompanies.filter((ao) => ao == company.userid).length;
        }
        return company;
      }));
    }
  }, [JSON.stringify(operatorListByCategory)]);

  useEffect(() => {
    let tempcat = taskFilters.filter((topic) =>
      [
        "created_by_me",
        "assigned_to_me",
        "pinned",
        "overdue",
        "date_range_filter",
      ].includes(topic.filter_key)
    );
    if (
      taskFilters &&
      taskFilters.length > 0 &&
      stop &&
      tempcat.length == 0 &&
      openAssigneeDropdown
    ) {
      setStop(false);
      if(operatorListByCategory && operatorListByCategory.data && !operatorListByCategory.data.length)
      {
        openDropdown(0);
      }
      else {
        openDropdown(1);
      }
    }
  }, [taskFilters, openAssigneeDropdown]);

  return (
    <>
    {
      isParentMenuLoaded
      ?
      <React.Fragment>
        {unContolList ? (
          <DropdownMenu
            direction="right"
            className={`dropdown-menu w-200 overflowscroll AssigneeDropdownCloseOutside${taskId}`}
            aria-labelledby="EquipmentsSubMenu"
            container="body"
          >
            {EquipmentsSubMenuContain}
          </DropdownMenu>
        ) : (
          <ul className="dropdown-menu w-200" aria-labelledby="EquipmentsSubMenu">
            {EquipmentsSubMenuContain}
          </ul>
        )}
      </React.Fragment>
      :
      <React.Fragment>  
      </React.Fragment>
    }
    </>
  );
};

export default EquipmentsSubMenu;
