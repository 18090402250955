import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  isSameArrays,
  updateActionCounts,
  updateScheduleSlotKPICounts,
  _l,
} from "../../../hooks/utilities";
import Form from "react-bootstrap/Form";
import useScheduleSteps from "../../../hooks/useScheduleSteps";
import { ChatCircle } from "phosphor-react";
import ScheduleComments from "../../Comments/ScheduleComments";
import moment from "moment";
import HighPriorityActiveIcon from "../../../assets/icons/HighPriorityActiveIcon";
import HighPriorityIcon from "../../../assets/icons/HighPriorityIcon";
import { useDispatch, useSelector } from "react-redux";
import { showConfirmation, toastCloseAction } from "../../../actions/messages";
import BenchmarkServices from "../../../services/benchmark-services";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";
import ReactTooltip from "react-tooltip";

const NoItemsPlaceHolder = (
  <td colspan="17">
    <CommanPlaceholder
      imgType="todo-task"
      placeholderText={_l("l_no_checklist")}
    />
  </td>
);

const GeneralItemTableWithButton = forwardRef(
  (
    { checklist = [], floorId, getFloorIdOfItem, spaceId, spaceItemId, disableAssignment },
    ref
  ) => {
    const {
      currentSlot,
      operatorAvailability,
      setOperatorAvailability,
      calculationsCounts,
      setCalculationsCounts,
      assignedChecklistItems,
      setAssignedChecklistItems,
      handleSelectItem,
    } = useScheduleSteps();

    const { floorDetails } = useSelector((state) => state.benchmarkReducer);

    const [toggle, setToggle] = useState(false);

    const item_id = useRef(0);
    const dispatch = useDispatch();

    const contact_id = localStorage.getItem("contact_id");
    const staff_id = localStorage.getItem("staff_id");
    const full_name = localStorage.getItem("full_name");
    const contact_image = localStorage.getItem("contact_image");

    const keySelectorById = item => item.id;
    const typeOfSpaceItemsMap = new Map(floorDetails.type_of_space_items.map(item => [keySelectorById(item), item]));

    const handleSelectAll = (e, onlyCurrentSpaceItems, onlyPhotoRequired) => {
      const { days } = operatorAvailability[currentSlot];
      let masterList = assignedChecklistItems;
      let masterItemsIds = [];
      const checked = e.target.checked;
      const multiplier = checked ? -1 : 1;
      let currentKPICount = calculationsCounts.headerCounts;

      masterList.forEach((item) => {

        const spaceItemData = typeOfSpaceItemsMap.get(item.type_of_space_item_id);

        if(disableAssignment && spaceItemData && spaceItemData.skill_require_for_benchmark > 0)
        {
          return
        }
        
        //Skip other checklists when user selects items with only photo required
        if(onlyPhotoRequired && item.photo_required == 0)
        {
          return;
        }
        Object.keys(days).forEach((day_name) => {
          if (
            ((item.assigned_days &&
              (item.assigned_days[day_name] == -1 ||
                item.assigned_days[day_name] == currentSlot ||
                item.assigned_days[day_name] == undefined)) ||
              !item.assigned_days) &&
            days[day_name] &&
            item.days[day_name] &&
            ((onlyCurrentSpaceItems &&
              spaceId == item.room_id &&
              spaceItemId == item.type_of_space_item_id) ||
              (!onlyCurrentSpaceItems &&
                floorId == getFloorIdOfItem(item.type_of_space_item_id)))
          ) {
            masterItemsIds.push(item.id);
            if (!item.assigned_days) {
              item["assigned_days"] = {};
            }
            item["assigned_days"][day_name] = checked ? currentSlot : -1;
            currentKPICount[day_name] += multiplier * 1;
          }
        });
      });

      let allOperatorSlots = operatorAvailability;
      updateActionCounts(allOperatorSlots, masterList);

      let spaceAvaialbleChecklistItems = masterList.filter(
        (x) => checklistSlotsFilter(x.days) && !isSlotAssignedToOther(x)
      );

      if (checked) {
        allOperatorSlots[currentSlot].assigned_items = allOperatorSlots[
          currentSlot
        ].assigned_items.concat(spaceAvaialbleChecklistItems);
      } else {
        allOperatorSlots[currentSlot].assigned_items = allOperatorSlots[
          currentSlot
        ].assigned_items.filter(
          (x) =>
            spaceAvaialbleChecklistItems.findIndex((w) => w.id == x.id) == -1
        );
      }
      setCalculationsCounts(updateScheduleSlotKPICounts(masterList));
      setAssignedChecklistItems([...masterList]);
      setOperatorAvailability([...allOperatorSlots]);
    };

    const isSlotAssignedToOther = (checklistItem) => {
      let assignedSlots = 0;

      let daysOfSelectedSlots = [];
      let daysOfAssignedSlots = [];

      Object.keys(operatorAvailability[currentSlot].days).forEach(
        (day_name) => {
          if (
            operatorAvailability[currentSlot].days[day_name] &&
            checklistItem.days[day_name]
          ) {
            daysOfSelectedSlots.push(day_name);
          }
        }
      );
      if (
        checklistItem.assigned_days &&
        Object.keys(checklistItem.assigned_days).length
      ) {
        Object.keys(checklistItem.assigned_days).forEach((day) => {
          if (
            operatorAvailability[currentSlot].days[day] &&
            checklistItem.days[day] &&
            checklistItem.assigned_days[day] !== -1 &&
            checklistItem.assigned_days[day] !== currentSlot
          ) {
            assignedSlots++;
            daysOfAssignedSlots.push(day);
          }
        });
        if (assignedSlots > 0) {
          return isSameArrays(daysOfSelectedSlots, daysOfAssignedSlots);
        } else {
          return false;
        }
      } else {
        return false;
      }
    };

    const isSlotAssigned = (checklistItem) => {
      let assignedSlots = 0;
      if (
        checklistItem.assigned_days &&
        Object.keys(checklistItem.assigned_days).length
      ) {
        Object.keys(checklistItem.assigned_days).forEach((day) => {
          if (
            operatorAvailability[currentSlot].days[day] &&
            checklistItem.days[day] &&
            checklistItem.assigned_days[day] !== undefined &&
            checklistItem.assigned_days[day] !== -1 &&
            checklistItem.assigned_days[day] == currentSlot
          ) {
            assignedSlots++;
          }
        });
        if (assignedSlots > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    };

    const isDayChecked = (checklistItem, day) => {
      let flag = false;
      if (
        checklistItem.assigned_days &&
        checklistItem.assigned_days[day] == currentSlot &&
        checklistItem.days[day] &&
        operatorAvailability[currentSlot].days[day]
      ) {
        flag = true;
      } else if (
        (!checklistItem.assigned_days ||
          checklistItem.assigned_days[day] == undefined ||
          checklistItem.assigned_days[day] == -1 ||
          checklistItem.assigned_days[day] == currentSlot) &&
        operatorAvailability[currentSlot].days[day] &&
        checklistItem.days[day]
      ) {
        flag = true;
      }
      return flag;
    };

    const isAllItemsSelected = () => {
      let availableItemsToSpace = checklist.filter(
        (x) => checklistSlotsFilter(x.days) && !isSlotAssignedToOther(x)
      );
      let totalSlots = availableItemsToSpace.length;
      let selectedSlots = 0;

      if (totalSlots) {
        availableItemsToSpace.forEach((x) => {
          if (isSlotAssigned(x)) {
            selectedSlots++;
          }
        });
        return totalSlots == selectedSlots;
      }

      return false;
    };

    const checklistSlotsFilter = (days) => {
      let flag = false;
      let slotDays = operatorAvailability[currentSlot].days;

      Object.keys(slotDays).forEach((day_name) => {
        if (slotDays[day_name] && days[day_name]) {
          flag = true;
        }
      });
      return flag;
    };

    const addEditSlotComment = (slotId, comment, files, commentIndex) => {
      if (!comment && !files) {
        return;
      }
      const checklistItemIndex = assignedChecklistItems.findIndex(
        (w) => w.id == slotId
      );
      let formattedComment = {
        id: 0,
        date: moment().fromNow(),
        attachments: files ? Array.from(files) : [],
        content: comment,
        contact_id,
        staff_id,
        full_name,
        profile_url: contact_image,
      };

      if (assignedChecklistItems[checklistItemIndex].id > 0) {
        const commentId =
          commentIndex === -1
            ? 0
            : assignedChecklistItems[checklistItemIndex].item_comments[
                commentIndex
              ].id;
        BenchmarkServices.addEditChecklistComment(
          comment,
          files,
          assignedChecklistItems[checklistItemIndex].id,
          commentId
        ).then((res) => {
          formattedComment.id = res.data.comment_id;
          formattedComment.attachments = res.data.files_list;
          if (commentIndex > -1) {
            setAssignedChecklistItems(
              Object.values({
                ...assignedChecklistItems,
                [checklistItemIndex]: {
                  ...assignedChecklistItems[checklistItemIndex],
                  item_comments: Object.values({
                    ...assignedChecklistItems[checklistItemIndex].item_comments,
                    [commentIndex]: formattedComment,
                  }),
                },
              })
            );
          } else {
            setAssignedChecklistItems(
              Object.values({
                ...assignedChecklistItems,
                [checklistItemIndex]: {
                  ...assignedChecklistItems[checklistItemIndex],
                  item_comments: assignedChecklistItems[
                    checklistItemIndex
                  ].item_comments.concat([formattedComment]),
                },
              })
            );
          }
        });
      } else {
        if (commentIndex > -1) {
          setAssignedChecklistItems(
            Object.values({
              ...assignedChecklistItems,
              [checklistItemIndex]: {
                ...assignedChecklistItems[checklistItemIndex],
                item_comments: Object.values({
                  ...assignedChecklistItems[checklistItemIndex].item_comments,
                  [commentIndex]: formattedComment,
                }),
              },
            })
          );
        } else {
          setAssignedChecklistItems(
            Object.values({
              ...assignedChecklistItems,
              [checklistItemIndex]: {
                ...assignedChecklistItems[checklistItemIndex],
                item_comments: assignedChecklistItems[
                  checklistItemIndex
                ].item_comments.concat([formattedComment]),
              },
            })
          );
        }
      }
    };

    const confirmDeleteComment = (slotId, commentIndex) => {
      dispatch(
        showConfirmation(
          _l("l_are_you_sure"),
          _l("l_delete_comment"),
          _l("l_delete"),
          _l("l_cancel"),
          () => {},
          () => {
            deleteSlotCommnet(slotId, commentIndex);
            dispatch(toastCloseAction());
          }
        )
      );
    };

    const deleteSlotCommnet = (slotId, commentIndex) => {
      const checklistItemIndex = assignedChecklistItems.findIndex(
        (w) => w.id == slotId
      );

      if (assignedChecklistItems[checklistItemIndex].id > 0) {
        BenchmarkServices.deleteChecklistComment(
          assignedChecklistItems[checklistItemIndex].id,
          assignedChecklistItems[checklistItemIndex].item_comments[commentIndex]
            .id
        ).then((res) => {
          setAssignedChecklistItems(
            Object.values({
              ...assignedChecklistItems,
              [checklistItemIndex]: {
                ...assignedChecklistItems[checklistItemIndex],
                item_comments: assignedChecklistItems[
                  checklistItemIndex
                ].item_comments.filter((w, pos) => pos != commentIndex),
              },
            })
          );
        });
      } else {
        setAssignedChecklistItems(
          Object.values({
            ...assignedChecklistItems,
            [checklistItemIndex]: {
              ...assignedChecklistItems[checklistItemIndex],
              item_comments: assignedChecklistItems[
                checklistItemIndex
              ].item_comments.filter((w, pos) => pos != commentIndex),
            },
          })
        );
      }
    };

    const updateItemPriority = (id, priority) => {
      const checklistIndex = assignedChecklistItems.findIndex(
        (x) => x.id == id
      );
      setAssignedChecklistItems(
        Object.values({
          ...assignedChecklistItems,
          [checklistIndex]: {
            ...assignedChecklistItems[checklistIndex],
            priority: priority ? 1 : 0,
          },
        })
      );
    };

    useImperativeHandle(ref, () => ({
      handleSelectAll: handleSelectAll,
    }));

    return (
      <div className={`comman-data-table GeneralItemTable ${disableAssignment > 0 ? "for-disabled opacity-50" : ""}`}>
        <table className="dataTable comman-table smaller-table">
          <thead>
            <tr>
              <th className="c-font f-13" style={{ width: "2%" }}>
                <div className="d-flex justify-content-between">
                  <Form.Check
                    type="switch"
                    id=""
                    label=""
                    className="with-no-label-space"
                    checked={isAllItemsSelected()}
                    onClick={(e) => handleSelectAll(e, true)}
                  />
                </div>
              </th>
              <th className="c-font f-13" style={{ width: "1%" }}>
                #
              </th>
              <th className="c-font f-13" style={{ width: "30%" }}>
                {_l("l_item")}
              </th>
              <th className="c-font f-13">
              {_l("l_photo")}
              </th>
              <th className="c-font f-13 text-center" style={{ width: "5%" }}>
                {_l("l_priority")}
              </th>
              <th className="c-font f-13 text-center" style={{ width: "5%" }}>
                {_l("l_comments")}
              </th>
              <th className="c-font f-13 text-center">
                {_l("l_week_single_letter_monday")}
              </th>
              <th className="c-font f-13 text-center">
                {_l("l_week_single_letter_tuesday")}
              </th>
              <th className="c-font f-13 text-center">
                {_l("l_week_single_letter_wednesday")}
              </th>
              <th className="c-font f-13 text-center">
                {_l("l_week_single_letter_thrusday")}
              </th>
              <th className="c-font f-13 text-center">
                {_l("l_week_single_letter_friday")}
              </th>
              <th className="c-font f-13 text-center">
                {_l("l_week_single_letter_saturday")}
              </th>
              <th className="c-font f-13 text-center">
                {_l("l_week_single_letter_sunday")}
              </th>
              <th className="c-font f-13 text-center"></th>
              <th className="c-font f-13 text-center">
                {_l("l_single_letter_monthly")}
              </th>
              <th className="c-font f-13 text-center">
                {_l("l_single_letter_quaterly")}
              </th>
              <th className="c-font f-13 text-center">
                {_l("l_single_letter_semester")}
              </th>
              <th className="c-font f-13 text-center">
                {_l("l_single_letter_yearly")}
              </th>
            </tr>
          </thead>
          <tbody>
            {checklist && checklist.length ? (
              <>
                <div className="d-none">
                  {/* Reset ref on each re-render */}
                  {(item_id.current = 0)}
                </div>
                {checklist.map((checklistItem, index) => {
                  if (
                    checklistSlotsFilter(checklistItem.days) &&
                    !isSlotAssignedToOther(checklistItem)
                  ) {
                    item_id.current++;
                    return (
                      <tr key={index}>
                        <td>
                          <div className="d-flex justify-content-between">
                            <Form.Check
                              type="switch"
                              id=""
                              label=""
                              className="with-no-label-space"
                              checked={isSlotAssigned(checklistItem)}
                              onChange={(e) => {
                                handleSelectItem(
                                  checklistItem.id,
                                  e.target.checked
                                );
                              }}
                              disabled={isSlotAssignedToOther(checklistItem)}
                            />
                          </div>
                        </td>
                        <td>{item_id.current} </td>
                        <td
                          className="text-truncate max-width-100"
                          data-tip={checklistItem.item_name}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                          data-place="top"
                        >
                          {checklistItem.item_name}
                        </td>
                        <td>
                          <Form.Check
                            type="switch"
                            checked={checklistItem.photo_required > 0}
                            className="with-no-label-space"
                            disabled={true}
                          />
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="white-border-box d-flex align-items-center h25w25 action_icon with_bg">
                              <a href="#/"
                                className="priority-checkbox text_wrap position-relative"
                                data-tip={_l("l_priority")}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-className="tooltip-main"
                              >
                                <input
                                  type="checkbox"
                                  className="absolute-input"
                                  checked={checklistItem.priority > 0}
                                  onChange={(e) => {
                                    updateItemPriority(
                                      checklistItem.id,
                                      e.target.checked
                                    );
                                  }}
                                  disabled={true}
                                />
                                <div className="check_box">
                                  <HighPriorityActiveIcon className="HW18 check_span_img active" />
                                  <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a href="#/"
                            key={index}
                            className="comman_action_icon w-100 d-flex"
                            type="button"
                            id={`minified_comment_${index}`}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-bs-auto-close="outside"
                            onClick={() => {
                              setToggle(!toggle);
                            }}
                          >
                            <div className="d-flex align-items-center justify-content-center w-100">
                              <div className="action_icon with_bg  on-hover-active-toggle-img light-theme-img position-relative">
                                <ChatCircle
                                  size={18}
                                  className="c-icons"
                                  weight="light"
                                />
                              </div>
                              <span
                                className={`c-font f-12 d-flex justify-content-center ps-2 text-on-hover-active-green number-counter-toggle`}
                                data-cmt-cnt-id={index}
                              >
                                {checklistItem.item_comments &&
                                checklistItem.item_comments.length
                                  ? checklistItem.item_comments.length
                                  : ""}
                              </span>
                            </div>
                          </a>
                          <ScheduleComments
                            addEditSlotComment={(
                              comment,
                              files,
                              comment_index
                            ) => {
                              addEditSlotComment(
                                checklistItem.id,
                                comment,
                                files,
                                comment_index
                              );
                            }}
                            deleteSlotCommnet={(comment_index) => {
                              confirmDeleteComment(
                                checklistItem.id,
                                comment_index
                              );
                            }}
                            slotComments={checklistItem.item_comments}
                            toggle={toggle}
                          />
                        </td>
                        <td className="text-center  hr_1">
                          <label for="" className="">
                            <input
                              className="form-check-input bg-transparent"
                              type="checkbox"
                              checked={isDayChecked(checklistItem, "mon")}
                              disabled={true}
                            />
                          </label>
                        </td>
                        <td className="text-center  hr_1">
                          <label for="" className="">
                            <input
                              className="form-check-input bg-transparent"
                              type="checkbox"
                              checked={isDayChecked(checklistItem, "tue")}
                              disabled={true}
                            />
                          </label>
                        </td>
                        <td className="text-center  hr_1">
                          <label for="" className="">
                            <input
                              className="form-check-input bg-transparent"
                              type="checkbox"
                              checked={isDayChecked(checklistItem, "wed")}
                              disabled={true}
                            />
                          </label>
                        </td>
                        <td className="text-center  hr_1">
                          <label for="" className="">
                            <input
                              className="form-check-input bg-transparent"
                              type="checkbox"
                              checked={isDayChecked(checklistItem, "thu")}
                              disabled={true}
                            />
                          </label>
                        </td>
                        <td className="text-center  hr_1">
                          <label for="" className="">
                            <input
                              className="form-check-input bg-transparent"
                              type="checkbox"
                              checked={isDayChecked(checklistItem, "fri")}
                              disabled={true}
                            />
                          </label>
                        </td>
                        <td className="text-center  hr_1">
                          <label for="" className="">
                            <input
                              className="form-check-input bg-transparent"
                              type="checkbox"
                              checked={isDayChecked(checklistItem, "sat")}
                              disabled={true}
                            />
                          </label>
                        </td>
                        <td className="text-center  hr_1">
                          <label for="" className="">
                            <input
                              className="form-check-input bg-transparent"
                              type="checkbox"
                              checked={isDayChecked(checklistItem, "sun")}
                              disabled={true}
                            />
                          </label>
                        </td>
                        <td className="text-center  hr_1"></td>
                        <td className="text-center  hr_1">
                          <label for="" className="">
                            <input
                              className="form-check-input bg-transparent"
                              type="checkbox"
                              checked={isDayChecked(checklistItem, "monthly")}
                              disabled={true}
                            />
                          </label>
                        </td>
                        <td className="text-center  hr_1">
                          <label for="" className="">
                            <input
                              className="form-check-input bg-transparent"
                              type="checkbox"
                              checked={isDayChecked(checklistItem, "quarterly")}
                              disabled={true}
                            />
                          </label>
                        </td>
                        <td className="text-center  hr_1">
                          <label for="" className="">
                            <input
                              className="form-check-input bg-transparent"
                              type="checkbox"
                              checked={isDayChecked(
                                checklistItem,
                                "half_yearly"
                              )}
                              disabled={true}
                            />
                          </label>
                        </td>
                        <td className="text-center  hr_1">
                          <label for="" className="">
                            <input
                              className="form-check-input bg-transparent"
                              type="checkbox"
                              checked={isDayChecked(checklistItem, "yearly")}
                              disabled={true}
                            />
                          </label>
                        </td>
                        {/* <td className="text-center  hr_1"></td> */}
                        <ReactTooltip />
                      </tr>
                    );
                  }
                })}
                {item_id.current == 0 ? <>{NoItemsPlaceHolder}</> : <></>}
              </>
            ) : (
              <>{NoItemsPlaceHolder}</>
            )}
          </tbody>
        </table>
      </div>
    );
  }
);

export default GeneralItemTableWithButton;
