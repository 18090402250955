import React from 'react';
// import CSS from '../css/icon_main.css';

function UserSettingIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons`}>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.94684 15.4362C9.94684 9.15055 15.0423 4.05505 21.3279 4.05505C27.6136 4.05505 32.7091 9.15055 32.7091 15.4362C32.7091 21.7085 27.6351 26.7957 21.3678 26.8172C21.3546 26.8162 21.3414 26.8156 21.3279 26.8156C21.2757 26.8156 21.2235 26.8158 21.1713 26.8162C14.9579 26.7324 9.94684 21.6695 9.94684 15.4362ZM17.3998 27.1811C12.4874 25.5389 8.94684 20.901 8.94684 15.4362C8.94684 8.59827 14.49 3.05505 21.3279 3.05505C28.1658 3.05505 33.7091 8.59827 33.7091 15.4362C33.7091 22.2741 28.1658 27.8173 21.3279 27.8173C21.2747 27.8173 21.2215 27.8169 21.1685 27.8163C17.6613 27.8438 14.2201 28.78 11.1812 30.5347C8.09621 32.316 5.53454 34.878 3.75368 37.9632C3.61563 38.2024 3.30984 38.2844 3.07068 38.1463C2.83152 38.0083 2.74956 37.7025 2.8876 37.4633C4.75623 34.2261 7.44414 31.5378 10.6811 29.6687C12.7723 28.4612 15.045 27.6232 17.3998 27.1811Z" fill="currentColor" fill-opacity="0.7"/>
<path d="M41.25 50.1066C45.5493 50.1066 49.0346 46.6213 49.0346 42.322C49.0346 38.0227 45.5493 34.5374 41.25 34.5374C36.9506 34.5374 33.4653 38.0227 33.4653 42.322C33.4653 46.6213 36.9506 50.1066 41.25 50.1066Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M52.5053 34.6509C52.8957 35.2099 53.2376 35.8013 53.527 36.4187L57.7275 38.7541C58.252 41.1032 58.2575 43.5385 57.7437 45.89L53.527 48.2254C53.2376 48.8427 52.8957 49.4342 52.5053 49.9932L52.5864 54.8099C50.8062 56.4321 48.6993 57.6542 46.4073 58.3941L42.2717 55.9127C41.5914 55.9614 40.9086 55.9614 40.2283 55.9127L36.1089 58.3779C33.8097 57.6515 31.6958 56.434 29.9136 54.8099L29.9947 50.0094C29.6075 49.4426 29.2659 48.8461 28.973 48.2254L24.7725 45.89C24.248 43.5409 24.2425 41.1056 24.7563 38.7541L28.973 36.4187C29.2624 35.8013 29.6043 35.2099 29.9947 34.6509L29.9136 29.8342C31.6938 28.212 33.8007 26.9899 36.0927 26.25L40.2283 28.7314C40.9086 28.6827 41.5914 28.6827 42.2717 28.7314L46.3911 26.2662C48.6903 26.9926 50.8042 28.21 52.5864 29.8342L52.5053 34.6509Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

			{/* <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" >
				<g clip-path="url(#clip0_2414_49470)">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M9.94684 15.4362C9.94684 9.15055 15.0423 4.05505 21.3279 4.05505C27.6136 4.05505 32.7091 9.15055 32.7091 15.4362C32.7091 21.7085 27.6351 26.7957 21.3678 26.8172C21.3546 26.8162 21.3414 26.8156 21.3279 26.8156C21.2757 26.8156 21.2235 26.8158 21.1713 26.8162C14.9579 26.7324 9.94684 21.6695 9.94684 15.4362ZM17.3998 27.1811C12.4874 25.5389 8.94684 20.901 8.94684 15.4362C8.94684 8.59827 14.49 3.05505 21.3279 3.05505C28.1658 3.05505 33.7091 8.59827 33.7091 15.4362C33.7091 22.2741 28.1658 27.8173 21.3279 27.8173C21.2747 27.8173 21.2215 27.8169 21.1685 27.8163C17.6613 27.8438 14.2201 28.78 11.1812 30.5347C8.09621 32.316 5.53454 34.878 3.75368 37.9632C3.61563 38.2024 3.30984 38.2844 3.07068 38.1463C2.83152 38.0083 2.74956 37.7025 2.8876 37.4633C4.75623 34.2261 7.44414 31.5378 10.6811 29.6687C12.7723 28.4612 15.045 27.6232 17.3998 27.1811Z" fill="currentColor" fill-opacity="0.7" />
					<path d="M41.25 50.1066C45.5493 50.1066 49.0346 46.6213 49.0346 42.322C49.0346 38.0227 45.5493 34.5374 41.25 34.5374C36.9506 34.5374 33.4653 38.0227 33.4653 42.322C33.4653 46.6213 36.9506 50.1066 41.25 50.1066Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M52.5053 34.6509C52.8957 35.2099 53.2376 35.8013 53.527 36.4187L57.7275 38.7541C58.252 41.1032 58.2575 43.5385 57.7437 45.89L53.527 48.2254C53.2376 48.8427 52.8957 49.4342 52.5053 49.9932L52.5864 54.8099C50.8062 56.4321 48.6993 57.6542 46.4073 58.3941L42.2717 55.9127C41.5914 55.9614 40.9086 55.9614 40.2283 55.9127L36.1089 58.3779C33.8097 57.6515 31.6958 56.434 29.9136 54.8099L29.9947 50.0094C29.6075 49.4426 29.2659 48.8461 28.973 48.2254L24.7725 45.89C24.248 43.5409 24.2425 41.1056 24.7563 38.7541L28.973 36.4187C29.2624 35.8013 29.6043 35.2099 29.9947 34.6509L29.9136 29.8342C31.6938 28.212 33.8007 26.9899 36.0927 26.25L40.2283 28.7314C40.9086 28.6827 41.5914 28.6827 42.2717 28.7314L46.3911 26.2662C48.6903 26.9926 50.8042 28.21 52.5864 29.8342L52.5053 34.6509Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
				</g>
				<defs>
					<clipPath id="clip0_2414_49470">
						<rect width="60" height="60" fill="currentColor" />
					</clipPath>
				</defs>
			</svg> */}

		</React.Fragment>
	)
}

export default UserSettingIcon