import React from 'react'
import { Check } from 'phosphor-react'
import { _l } from '../../hooks/utilities'

const RadioButtonWithIcon = ({value, Icon, name, checked, title, description, onClick, onChange, disabled}) => {
  return (
    <div className="space-select-box select-input-cox mx-3 bg-transparent">
    <input
      className="bottom-0 end-0 form-check-input h_230px w-100 position-absolute start-0 top-0 bg-transparent"
      name={name}
      type="checkbox"
      checked={checked}
      value={value}
      onClick={onClick}
      onChange={onChange}
      disabled={disabled}
    />
    <a href="#/" className="check-image">
      <div className="check-image-box">
        <Check size={14} className="c-icons color-white-03-solid" />
      </div>
    </a>
    <div className=''>
      <div className="d-flex bg-white-03 m-auto h_230px radius_3">
        <Icon.name className={Icon.className} size={Icon.size} />
      </div>
      <div className="text-center p-10">
        <div className="text-center c-font f-16 p-10 px-0 fw-semibold">
          {_l(title)}
        </div>
        <div className="text-center c-font f-14 color-white-60 fw-light">
          {_l(description)}
        </div>
      </div>
    </div>
  </div>
  )
}

export default RadioButtonWithIcon