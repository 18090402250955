import React from 'react';
// import CSS from '../css/icon_main.css';

function NotePencilIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"  className={`comman-icons ${CommanHW}`}>
<path d="M12.854 9.63398V12.854C12.854 13.1739 12.7269 13.4806 12.5008 13.7068C12.2746 13.9329 11.9679 14.06 11.648 14.06H3.206C2.88615 14.06 2.5794 13.9329 2.35323 13.7068C2.12706 13.4806 2 13.1739 2 12.854V4.412C2 4.09215 2.12706 3.7854 2.35323 3.55923C2.5794 3.33306 2.88615 3.206 3.206 3.206H6.42602M11.648 2L14.06 4.412L8.03 10.442H5.618V8.03L11.648 2Z" />
</svg>


		</React.Fragment>
	)
}

export default NotePencilIcon