import React, { useEffect, useState } from "react";
import { _l, hour,minute, removeDuplicates, second,setCallTimer, updateelapsedTime, updatehours, updateminutes } from "../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import { ATTACH_BASE_URL, USR_IMG_PLACEHOLDER } from "../../actions/chat-action-type";
import jquery from "jquery";
import { getCommentsForAudiocall, setShowMicDisaply, updateedTaskChecklist } from "../../actions/customer";
import { setComments } from "../../actions/documents-actions/documents-actions";
import { MicrophoneSlash } from "phosphor-react";

const FeedchatInTaskModal = ({ }) => {

  const {
    callStatus
  } = useSelector((state) => state.chat);
  const {
    callRecordingActive,
    displayMic
  } = useSelector((state) => state.customer);
    
    const dispatch = useDispatch();
    const [callName, setCallName] = useState("");
    const [callImage, setCallImage] = useState(USR_IMG_PLACEHOLDER);
    const [isCallMute, setIsCallMute] = useState(false);


    useEffect(() => {
        setCallTimer();
    }, []);

    var startTime = 0;
    var elapsedTime = 0;
    let timer;
    let seconds = second, minutes = minute, hours = hour;
    let isTimerRunning = false;
    
    // Call timer functions
    useEffect(() => {
      if(!jquery.isEmptyObject(callStatus)){
        if(callStatus.status == "notResponding"){
    
        }else{
          if(callStatus.callRole == "caller"){
            if(callStatus.receiverInfo){
                if(callStatus.receiverInfo.displayPicture != ""){
                    setCallImage(encodeURI(ATTACH_BASE_URL+callStatus.receiverInfo.displayPicture));
                }else{
                    setCallImage(USR_IMG_PLACEHOLDER);
                }
                setCallName(callStatus.receiverInfo.fname+" "+callStatus.receiverInfo.lname);
                setIsCallMute(callStatus.receiverInfo.isMute ? true : false);
                dispatch(setShowMicDisaply(callStatus.receiverInfo.isMute ? true : false))
                // setIsOtherRecording(callStatus.receiverInfo.isRecording ? true : false);
            }
        }else{
            if(callStatus.callerInfo){
                if(callStatus.callerInfo && callStatus.callerInfo.displayPicture != ""){
                    setCallImage(encodeURI(ATTACH_BASE_URL+callStatus.callerInfo.displayPicture));
                }else{
                    setCallImage(USR_IMG_PLACEHOLDER);
                }
                setCallName(callStatus.callerInfo.fname+" "+callStatus.callerInfo.lname);
                setIsCallMute(callStatus.callerInfo.isMute ? true : false);
                dispatch(setShowMicDisaply(callStatus.callerInfo.isMute ? true : false))
                // setIsOtherRecording(callStatus.callerInfo.isRecording ? true : false);
            }
        }
        }
        // setCallName(callStatus.receiverInfo.fname+" "+callStatus.receiverInfo.lname);
        if (callStatus.checklistItems) {
          dispatch(updateedTaskChecklist(removeDuplicates(callStatus.checklistItems)))          
        }

        if (callStatus.commentsList) {
          dispatch(getCommentsForAudiocall(removeDuplicates(callStatus.commentsList)))          
        }
      }
    }, [JSON.stringify(callStatus)])
  return (
    <>
      <div className="feed-chat-in-task-modal">
        <div className="p-10 bg-white-03 flex-grow-1 d-flex flex-column justify-content-center align-items-center">
          <div className="p-15"></div>
          <div className="FeedchatContain ">
            <div className="d-flex flex-column justify-content-center align-items-center m-auto">
              <div className="d-flex align-items-center justify-content-center">
                <div className="CallerImg position-relative d-none">
                  <div className="comman-image-box multi-wrapper-border h40w40 rounded-circle">
                    <div
                      className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                      style={{
                        backgroundImage: `url(${callImage})`,
                      }}
                    ></div>
                  </div>
                  <span className="Commanwave wave1"></span>
                  <span className="Commanwave wave2"></span>
                  <span className="Commanwave wave3"></span>
                </div>
                <div className="CallerImg position-relative withMargin120px">
                  {displayMic && <div className="muteoption comman_action_icon position-absolute end-0">
                      <div className="action_icon h32w32 with_bg radius_3">
                        <MicrophoneSlash size={18} weight="light" className="c-icons m-auto" />
                      </div>
                  </div>}
                  <div className="comman-image-box multi-wrapper-border h120w120 rounded-circle">
                    <div
                      className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                      style={{
                        backgroundImage: `url(${callImage})`,
                      }}
                    ></div>
                  </div>
                  <span className="Commanwave wave1"></span>
                  <span className="Commanwave wave2"></span>
                  <span className="Commanwave wave3"></span>
                </div>
                <div className="CallerImg position-relative d-none">
                  <div className="comman-image-box multi-wrapper-border h40w40 rounded-circle">
                    <div
                      className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                      style={{
                        backgroundImage: `url(${callImage})`,
                      }}
                    ></div>
                  </div>
                  <span className="Commanwave wave1"></span>
                  <span className="Commanwave wave2"></span>
                  <span className="Commanwave wave3"></span>
                </div>
              </div>
              <div className="p-15"></div>
              <div className="pt-15">
                <div className="c-font f-24 title-fonts fw-semibold text-center">
                  {callName}
                </div>
                <div class="align-items-center badge c-font color-green d-flex f-12 h32wauto list-toggle-view-none ms-10px mt-2 rounded-pill text-nowrap justify-content-center">
                  {callRecordingActive ? (
                    <>
                      <span class="bg-green c-10 c-dots me-2 rounded-circle"></span>
                      <span class="c-font color-green f-13 p-0 text-truncate title-fonts">
                        <span
                          class="color-white fw-semibold"
                          id="recording-minute"
                        >
                          00:00
                        </span>
                        <span class="color-white-60">{" "} /</span> 
                      </span>
                    </>
                  ) : (
                    <></>
                  )}

                  <span
                    id="call-timer-full"
                    class="color-white fw-semibold ps-1"
                  >
                    00:00
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedchatInTaskModal;
