import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState } from "react";
import { CalendarBlank,Star,Phone, DotsNine, PhoneCall, UserCircleGear, CaretDown } from "phosphor-react";
import Form from "react-bootstrap/Form";
import ReactTooltip from "react-tooltip";
import GeneralListing from "../../../TaskModalComponents/GeneralListing";
import moment from "moment";
import { _l , getDatePickerLocale } from "../../../../hooks/utilities";
import { UncontrolledDropdown, DropdownToggle, DropdownItem } from "reactstrap";
import UncontrolledGeneralListing from "../../../TaskModalComponents/UncontrolledGeneralListing";
import TaskListStatusDropdown from "../../../Dropdowns/TaskListStatusDropdown";
import LeadCommanCard from "./LeadCommanCard";
import CommanPlaceholder from "../../../Placeholder/CommanPlaceholder";
const Leadcontact = ({ data }) => {
  const [leadContactData, setLeadContactData] = useState(data);
  

  useEffect(() => {
    setLeadContactData(data);
  }, [JSON.stringify(data)]);



  return (
    <div>
      <div className="p-10 py-0">
        <div className=" d-flex flex-column">
          <div className="comman-content-scroll-wrapper">
            <div className="comman-content-scroll">
              <div className="comman-data-table small-data-table with-horizontal-scroll">
                <div class="custom-accordian-main task-list-accordian  d-flex flex-column flex-grow-1">
                  
                    {leadContactData && leadContactData.length > 0 ?
                    <>
                    {
                      leadContactData.map((contact, index)=>{
                        return(<>
                         <LeadCommanCard  data={contact} type={'contact'}/>
                        </>)
                      })

                    }
                    </> 
                    :<>
                    <CommanPlaceholder imgType="appointme" mainWidth="m-auto" placeholderText = {_l("l_no_contact")} />
                    </>}
            
            
              </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip />
    </div>
  );
};
export default Leadcontact;
