import {
  FETCH_GROUPS,
  FETCH_MESSAGES,
  FETCH_UNREAD_MSG_COUNT,
  NEW_MESSAGE,
  UPLOAD_CHAT_MEDIA,
  UPDATE_UPLOADED_FILENAME,
  USER_ONLINE,
  USER_OFFLINE,
  UPDATE_USER_ONLINE,
  UPDATE_USER_OFFLINE,
  UPDATE_NEW_MESSAGE,
  UPDATE_UNREAD_MSG_COUNT,
  USER_READ_MSG,
  UPDATE_USER_READ_MSG,
  FETCH_VIEW_GROUPS,
  NEW_TASK_NOTI,
  ALL_PROJ_UNREAD_MSG_CNT,
  NEW_MSG_FOR_AM,
  UPDATE_NEW_MSG_FOR_AM,
  NEW_GROUP,
  EDIT_GROUP,
  ATTACH_BASE_URL,
  MULTI_USR_PLACEHOLDER,
  EDIT_MESSAGE,
  DELETE_MESSAGE,
  UPDATE_GENERAL_MSG_COUNT,
  OPEN_GENERAL_CHAT_GROUP,
  SET_CALL_STATUS,
  MUTE_CALL,
  UNMUTE_CALL,
  ACCEPT_CALL,
  CALL_NOT_RESPOND,
  SET_MY_STREAM,
  SET_REMOTE_STREAM,
  SET_UNREAD_MSG_COUNT,
  GET_GROUP_DETAILS,
  CALL_ACTION,
  CLEAR_MESSAGE_LIST,
  CALL_TASK_LIST,
  STOP_CHAT_SCROLL,
  SET_TYPING_MEMBERS,
  BURGER_MENU_TOPIC_TYPING,
  DISABLE_INPUT_CHAT,
  BURGER_MENU_TOPIC_ANALYSIS,
  CALL_IS_RECIVE,
  CONTACT_DIR_COUNT,
  FETCH_GROUPS_EXTERNAL,
  SELECTED_USER_DETAILS_FOR_STAFF,
  UPDATE_EXTERNAL_GROUP_LIST,
  CHAT_SCREEN_GROUPS_LIST,
  ACTIVE_CHAT_SCREEN_GROUPS_ID,
  CHAT_SCREEN_GROUPS_LIST_DETAILS,
  UPDATE_CHAT_SCREEN_GROUPS_LIST_DETAILS,
  UPDATE_CHAT_SCREEN_GROUPS_LIST,
  OPEN_ACCORDION_CHAT,
  SHOW_CHAT_SCREEN_ON_PAGE,
  LAST_CHAT_GROUP
} from "../actions/chat-action-type";
import { isSolarHubPlatform } from "../hooks/utilities";
import { ChatModel } from "../interfaces/chatModels";
import $ from "jquery";

const initialState = new ChatModel();

export default function (state = initialState, action) {
  const { type, payload } = action;
  let messageList = state.messageList;
  let unreadMsgCount = state.unreadMsgCount;
  let groupList = state.groupList;
  let generalMsgCount = state.generalMsgCount;
  let callStatus = state.callStatus;
  let typingMembers = state.typingMembers;
  switch (type) {
    case FETCH_GROUPS:
      return {
        ...state,
        groupList: (payload.data.result) ? payload.data.result : [],
      };
    case FETCH_MESSAGES:
      return {
        ...state,
        messageList: (payload.data.success) ? payload.data.result : [],
        otherUserStatus: (payload.data.success) ? payload.data.extra.otherUserStatus : 0,
        firstMsgDate : (payload.data.success) ? payload.data.extra.firstMsgDate : 0
      };
    case NEW_MESSAGE:
      var activeGroupId = localStorage.getItem("activeGroupId");
      var newState = {
        ...state,
        newMessage: payload.data,
      };
      if (activeGroupId == payload.data.group) {
        messageList.push(payload.data);
        newState.messageList = messageList.slice();
      }
      return newState;
    case UPLOAD_CHAT_MEDIA:
      return {
        ...state,
        uploadFileName: payload.data.data ? payload.data.data.filename : "",
      };
    case UPDATE_UPLOADED_FILENAME:
      return {
        ...state,
        uploadFileName: "",
      };
    case USER_ONLINE:
      let groupDetail = state.groupDetails ? state.groupDetails : {};
      if (Object.keys(groupDetail).length > 0) {
        let members = groupDetail.members
        members.map((user, i) => {
          if (user._id == payload.data.userId) {
            user.isOnline = "1";
          }
          return user;
        });
        groupDetail.members = members.slice() 
      }
      return {
        ...state,
        userOnline: payload.data,
        groupDetails : groupDetail,
      };
    case USER_OFFLINE:
      let groupDetailss = state.groupDetails ? state.groupDetails : {};
      if (Object.keys(groupDetailss).length > 0) {
        let members = groupDetailss.members
        members && members.map((user, i) => {
          if (user._id == payload.data.userId) {
            user.isOnline = "0";
          }
          return user;
        });
        groupDetailss.members = members.slice() 
      }
      return {
        ...state,
        userOffline: payload.data,
        groupDetails: groupDetailss,
      };
    case UPDATE_USER_ONLINE:
      return {
        ...state,
        userOnline: payload,
      };
    case UPDATE_USER_OFFLINE:
      return {
        ...state,
        userOffline: payload,
      };
    case UPDATE_NEW_MESSAGE:
      return {
        ...state,
        newMessage: payload,
      };
    case FETCH_UNREAD_MSG_COUNT:
      return {
        ...state,
        // unreadMsgCount: (payload.data.success) ? payload.data.result.unreadCount : 0,
        generalMsgCount: (payload.data.success) ? payload.data.result.generalGroupCount : 0,
      };
    case SET_UNREAD_MSG_COUNT:
      return {
        ...state,
        unreadMsgCount: payload,
      };
    case UPDATE_UNREAD_MSG_COUNT:
      unreadMsgCount = unreadMsgCount + parseInt(payload);
      return {
        ...state,
        unreadMsgCount: unreadMsgCount,
      };
    case USER_READ_MSG:
      return {
        ...state,
        readAllGrpMsgBy: payload.data,
      };
    case UPDATE_USER_READ_MSG:
      return {
        ...state,
        readAllGrpMsgBy: payload,
      };
    case FETCH_VIEW_GROUPS:
      return {
        ...state,
        viewGroupList: payload.data.result,
      };
    case NEW_TASK_NOTI:
      return {
        ...state,
        newTaskNoti: payload,
      };
    case ALL_PROJ_UNREAD_MSG_CNT:
      return {
        ...state,
        allProjUnreadMsgCnt: payload.data.result,
      };
    case NEW_MSG_FOR_AM:
      return {
        ...state,
        newMsgForAM: payload.data,
      };
    case UPDATE_NEW_MSG_FOR_AM:
      return {
        ...state,
        newMsgForAM: payload,
      };
    case NEW_GROUP:
      groupList.unshift(payload.data);
      return {
        ...state,
        groupList: groupList,
      };
    case EDIT_GROUP:
      var chatGroups = groupList;
      var groupData = payload.data;
      var index = groupList.findIndex(item => item.groupId == groupData._id);
      if(index > -1){
        chatGroups[index].groupName = groupData.groupName;
        chatGroups[index].displayPicture = groupData.displayPicture;
        var activeGroupId = localStorage.getItem("activeGroupId");
        var groupIcon = groupData.displayPicture != "" ? ATTACH_BASE_URL+groupData.displayPicture : MULTI_USR_PLACEHOLDER;
        if(activeGroupId == groupData._id){
          $("#group_name").text(groupData.groupName);
          $("#group_img").css('background-image', 'url(' + groupIcon + ')');
        }
      }
      return {
        ...state,
        groupList: chatGroups,
      };
    case EDIT_MESSAGE:
      var chatMessageList = messageList;
      var messageData = payload.data;
      var activeGroupId = localStorage.getItem("activeGroupId");
      if(activeGroupId == messageData.group){
        var index = messageList.findIndex(item => item._id == messageData._id);
        if(index > -1){
          chatMessageList[index].message = messageData.message;
          chatMessageList[index].isEdited = 1;
          if (!$.isEmptyObject(messageData.extraData)) {
            chatMessageList[index].extraData = messageData.extraData;
          }
        }
      }
      return {
        ...state,
        messageList: chatMessageList,
      };
    case DELETE_MESSAGE:
      var chatMessageList = messageList;
      var messageData = payload.data;
      var activeGroupId = localStorage.getItem("activeGroupId");
      if(window.location.pathname == "/aichat" || localStorage.getItem("currentView") == "AichatView"){
        chatMessageList = payload.data
      }else
      if(activeGroupId == messageData.group){
        var index = messageList.findIndex(item => item._id == messageData._id);
        if(index > -1){
          chatMessageList.splice(index, 1);
        }
      }
      return {
        ...state,
        messageList: chatMessageList,
      };
    case UPDATE_GENERAL_MSG_COUNT:
      generalMsgCount = generalMsgCount + parseInt(payload);
      return {
        ...state,
        generalMsgCount: generalMsgCount,
      };
    case OPEN_GENERAL_CHAT_GROUP:
      return {
        ...state,
        openGeneralChatGroup: payload,
      };
    case SET_CALL_STATUS:
      return {
        ...state,
        callStatus: payload,
      };
    case MUTE_CALL:
      var currentCallStatus = callStatus;
      if (!$.isEmptyObject(callStatus)) {
        if(callStatus.callerInfo && callStatus.callerInfo._id == payload.muteUserId){
          currentCallStatus.callerInfo.isMute = 1;
        }else if(callStatus.receiverInfo && callStatus.receiverInfo._id == payload.muteUserId){
          currentCallStatus.receiverInfo.isMute = 1;
        }
      }
      return {
        ...state,
        callStatus: currentCallStatus,
      };
    case UNMUTE_CALL:
      var currentCallStatus = callStatus;
      if (!$.isEmptyObject(callStatus)) {
        if(callStatus.callerInfo && callStatus.callerInfo._id == payload.muteUserId){
          currentCallStatus.callerInfo.isMute = 0;
        }else if(callStatus.receiverInfo && callStatus.receiverInfo._id == payload.muteUserId){
          currentCallStatus.receiverInfo.isMute = 0;
        }
      }
      return {
        ...state,
        callStatus: currentCallStatus,
      };
    case ACCEPT_CALL:
      var currentCallStatus = callStatus;
      if (!$.isEmptyObject(callStatus)) {
        if(payload.userId != "" && callStatus.callerInfo._id == payload.userId){
          currentCallStatus.status = "inProgress";
        }else if(payload.receiverId != "" && callStatus.receiverInfo._id == payload.receiverId){
          currentCallStatus.status = "inProgress";
        }
        currentCallStatus.ans = payload.ans;
      }
      return {
        ...state,
        callStatus: currentCallStatus,
      };
    case CALL_NOT_RESPOND:
      var currentCallStatus = callStatus;
      if (!$.isEmptyObject(callStatus)) {
        if(currentCallStatus.randomCallId == payload.randomCallId && currentCallStatus.status != "inProgress"){
          currentCallStatus.status = "notResponding"
        }
      }
      return {
        ...state,
        callStatus: currentCallStatus,
      };
    case SET_MY_STREAM:
      return {
        ...state,
        myStream: payload,
      };
    case SET_REMOTE_STREAM:
      return {
        ...state,
        remoteStream: payload,
      };
    case GET_GROUP_DETAILS:
      return {
        ...state,
        groupDetails: payload,
      }
    case CALL_ACTION:
      var currentCallStatus = callStatus;
      let task_id = "";
      if (!$.isEmptyObject(callStatus)) {
        // console.log("payload.actionKey",payload.actionKey ,"payload",payload)
        if(callStatus.callerInfo && callStatus.callerInfo._id == payload.actionUserId){
          if(payload.actionKey == "record"){
            currentCallStatus.callerInfo.isRecording = payload.actionVal == "start" ? 1 : 0;
          }
        }else if(callStatus.receiverInfo && callStatus.receiverInfo._id == payload.actionUserId){
          if(payload.actionKey == "record"){
            currentCallStatus.receiverInfo.isRecording = payload.actionVal == "start" ? 1 : 0;
          }
        }
        if (payload.actionKey == "NewTask") {
           task_id = payload.actionVal
           currentCallStatus = {...currentCallStatus, task_id: task_id}    
        }
        if (payload.actionKey == "newChecklist") {
          let checklist = payload.extraData && payload.extraData.checklist ? payload.extraData.checklist : []
          currentCallStatus ={...currentCallStatus, checklistItems: checklist}
        }
        if (payload.actionKey == "newComment") {
          let comments = payload.extraData && payload.extraData.comments ? payload.extraData.comments : []
          currentCallStatus ={...currentCallStatus, commentsList: comments}
        }
        if (payload.actionKey == "newAttachments") {
          let attachments = payload.extraData && payload.extraData.attachments ? payload.extraData.attachments : []
          currentCallStatus ={...currentCallStatus, attachmentsList: attachments}
        }
      }
      return {
        ...state,
        callStatus: currentCallStatus,
      };
      case CLEAR_MESSAGE_LIST:
        return{
          ...state,
          messageList: []
        }
      case CALL_TASK_LIST:
        return{
          ...state,
          callTaskAPI: payload
        }
      case STOP_CHAT_SCROLL:
        return{
          ...state,
          stopScrolling: payload
        }
      case SET_TYPING_MEMBERS:
        if(payload){
          typingMembers = typingMembers ? typingMembers : [];
          var uIndex = typingMembers.findIndex((item) => item.userId == payload.userId);
          if(payload.action == "add" && uIndex == -1){ // For add member in the typing list and if it not already in the list
            typingMembers.push({
              userId: payload.userId,
              fname: payload.fname,
              lname: payload.lname
            });
          }else if(payload.action == "remove" && uIndex > -1){ // For remove member from the typing list
            typingMembers = typingMembers.filter((item) => item.userId != payload.userId);
          }
        }
        return{
          ...state,
          typingMembers: typingMembers.slice()
        }
      case BURGER_MENU_TOPIC_TYPING :
        let setMessageTyping = state.burgerMenuTopicTyping ? state.burgerMenuTopicTyping : [];
        if (payload) {
          let {groupId, action} = payload
          if (action == "add") {
            setMessageTyping.push(groupId)
          }else if (action == "remove") {
            setMessageTyping = setMessageTyping.filter((item) => item != groupId)
          }
        }
        return{
          ...state,
          burgerMenuTopicTyping : setMessageTyping.slice()
        }
      case DISABLE_INPUT_CHAT:
        return{
          ...state,
          disableInputChat: payload
        }
      case CALL_IS_RECIVE:
        return{
          ...state,
          isCallRecived: payload
        }
      case BURGER_MENU_TOPIC_ANALYSIS :
        let bergerMTAnalysis = state.bergerMenuTopicAnalysis ? state.bergerMenuTopicAnalysis : [] ;
        if (payload) {
          let {groupId, disableInput} = payload
          if (disableInput == 0) {
            bergerMTAnalysis = bergerMTAnalysis.filter((item) => item != groupId)
          }else if (disableInput == 1) {
            bergerMTAnalysis.push(groupId)
          }
        }
        return{
          ...state,
          bergerMenuTopicAnalysis : bergerMTAnalysis.slice()
        }
      case CONTACT_DIR_COUNT:
        var groupListExternal = state.groupListExternal ? state.groupListExternal : [];
        var unreadCountTotal = state.contactDirMsgCount ? state.contactDirMsgCount : 0;
        const groupId = payload 
        groupListExternal = groupListExternal.map((group) => {
          if (groupId == ""){
            unreadCountTotal += group.unreadCount
          } else if (groupId == group.groupId) {
            unreadCountTotal -= group.unreadCount
            group.unreadCount = 0;
          }
          return group;
        })
        
        return {
          ...state,
          groupListExternal : groupListExternal && groupListExternal.length > 0 ? groupListExternal : [],
          contactDirMsgCount : unreadCountTotal
        }
        
      case FETCH_GROUPS_EXTERNAL:
        let groupListExternalFilterd = (payload.data.result) ? payload.data.result : [];
        groupListExternalFilterd = groupListExternalFilterd && groupListExternalFilterd.length > 0 && groupListExternalFilterd.filter((group) => { 
          if (isSolarHubPlatform()) {
            if (localStorage.getItem("user_type") == "staff") {
              return(
                group.extraData && group.extraData.mainKey && (group.extraData.mainKey == "contact_directory" || group.extraData.mainKey == "myr_support")
              )
            }else if (["operator", "contact"].includes(localStorage.getItem("user_type")) && ["my-clients", "my-spaces"].includes(localStorage.getItem("leftPanelTab"))) {
              return(
                group.extraData && group.extraData.mainKey && (group.extraData.mainKey == "providers" || group.extraData.mainKey == "providers")
              )
            } else {
              return(
                group.extraData && group.extraData.mainKey && (group.extraData.mainKey == "contact_directory")
              )
            }
          }else{
            return (
              group.extraData && group.extraData.mainKey && group.extraData.mainKey == "contact_directory"
            )
          }
         })
         const urlParams = new URLSearchParams(window.location.search);
         const id = urlParams.get('proposal_id');
         if (id && id > 0 && groupListExternalFilterd.length > 0) {
          let unreadindex = groupListExternalFilterd.findIndex((gd)=> gd && gd.extraData && gd.extraData && gd.extraData.proposalId == id)  
          if (unreadindex > -1) {
            let tempData = groupListExternalFilterd[unreadindex]
            tempData.unreadCount = 0
            let finalData = Object.values({ ...groupListExternalFilterd, [unreadindex]: tempData })
            groupListExternalFilterd = finalData
          }
         }
        return {
          ...state,
          groupListExternal: groupListExternalFilterd.length > 0 ? groupListExternalFilterd : [],
        };

        case SELECTED_USER_DETAILS_FOR_STAFF:
          return {
            ...state,
            userDetails : payload
          }
        case UPDATE_EXTERNAL_GROUP_LIST:
          return {
            ...state,
            groupListExternal: payload
          }
        case CHAT_SCREEN_GROUPS_LIST:
          let currentids = state.chatGroups;

          if (currentids.length < 4) {
            let currentid = payload;
            currentids = currentids.concat([currentid]);
          }else{
            let currentid = payload
            let temparr = currentids.concat([currentid]);
            currentids = temparr.slice(-4);
          }
          return {
            ...state,
            chatGroups: currentids
          }
        case CHAT_SCREEN_GROUPS_LIST_DETAILS:
          let currentGroups = state.chatGroupsDetails;

          if (currentGroups.length < 4) {
            currentGroups = currentGroups.concat([payload]);
          }else{
            let temparr = currentGroups.concat([payload]);
            currentGroups = temparr.slice(-4);
          }
          return {
            ...state,
            chatGroupsDetails: currentGroups
          }
        case ACTIVE_CHAT_SCREEN_GROUPS_ID:
            return {
              ...state,
              activeChatScreenID: payload
            }
        case UPDATE_CHAT_SCREEN_GROUPS_LIST_DETAILS:
            return {
              ...state,
              chatGroupsDetails: payload
            }
        case UPDATE_CHAT_SCREEN_GROUPS_LIST:
            return {
              ...state,
              chatGroups: payload
            }
        case OPEN_ACCORDION_CHAT:
            return {
              ...state,
              isChatOpen: payload
            }
        case SHOW_CHAT_SCREEN_ON_PAGE:
            return {
              ...state,
              displayChatScreen: payload
            }
        case LAST_CHAT_GROUP:
            return {
              ...state,
              lastChatGroup: payload
            }
    default:
      return state;
  }
}
