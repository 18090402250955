import React from 'react';
// import CSS from '../css/icon_main.css';

function NoChecklist(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

			<svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons h-100 w-100 ${CommanHW}`}>
				<path d="M56 111C86.3757 111 111 86.3757 111 56C111 25.6243 86.3757 1 56 1C25.6243 1 1 25.6243 1 56C1 86.3757 25.6243 111 56 111Z" strokeMiterlimit="10" />
				<path d="M38.9039 30.5376C38.9039 31.7633 38.8725 32.7796 38.9039 33.7905C38.8974 34.2712 38.9882 34.7481 39.1709 35.1928C39.3535 35.6374 39.6243 36.0405 39.9669 36.3777C40.3095 36.7149 40.7168 36.9793 41.1643 37.1549C41.6117 37.3305 42.0901 37.4137 42.5706 37.3995C48.8808 37.431 55.1944 37.431 61.5116 37.3995C63.6644 37.3995 65.1363 35.8542 65.1782 33.6857C65.1782 32.68 65.1782 31.669 65.1782 30.5795C67.1216 30.5795 68.9392 30.4643 70.7359 30.611C71.5902 30.7171 72.3748 31.1363 72.9379 31.7875C73.5011 32.4387 73.8027 33.2755 73.7844 34.1362C73.8316 35.87 73.7844 37.609 73.7844 39.3481V68.1576C67.813 68.3619 63.7325 71.0281 62.664 77.0624C61.8154 81.8605 64.1882 85.5796 69.4735 88.3872C69.002 88.4343 68.7087 88.4867 68.4259 88.4867C57.1535 88.4867 45.8811 88.4867 34.6087 88.4867C31.6073 88.4867 30.2559 87.1352 30.2559 84.1495V34.7857C30.2559 31.8733 31.6178 30.5272 34.5406 30.522L38.9039 30.5376Z" strokeMiterlimit="10" />
				<path d="M64.2392 44.6333H46.4297" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M64.2392 54.4233H46.4297" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M64.2392 64.2134H46.4297" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M81.7135 78.5553C81.7053 80.9145 80.7644 83.1745 79.0963 84.8427C77.4281 86.5109 75.1679 87.4518 72.8088 87.4601C71.6299 87.4615 70.4624 87.2299 69.3733 86.7786C68.2843 86.3273 67.2951 85.6652 66.4628 84.8304C65.6304 83.9956 64.9712 83.0046 64.5231 81.9142C64.075 80.8238 63.8468 79.6556 63.8516 78.4767C63.8599 76.1176 64.8008 73.8575 66.4689 72.1893C68.1371 70.5211 70.3973 69.5802 72.7564 69.5719C73.9368 69.5649 75.1069 69.7927 76.1985 70.242C77.2901 70.6913 78.2815 71.3533 79.1149 72.1892C79.9484 73.0251 80.6074 74.0184 81.0535 75.1113C81.4996 76.2042 81.724 77.3749 81.7135 78.5553V78.5553Z" strokeMiterlimit="10" />
				<path d="M47.8492 29.1234C47.7969 25.5144 49.3264 23.5396 52.003 23.5134C54.6797 23.4872 56.3297 25.5667 56.2302 29.1182C58.0373 29.1182 59.8445 29.1182 61.6516 29.1182C63.5426 29.1182 63.8778 29.4639 63.883 31.3444C63.883 32.1039 63.883 32.8687 63.883 33.6335C63.8202 35.0897 62.9454 36.0796 61.505 36.0901C55.1878 36.1285 48.8707 36.1285 42.5535 36.0901C41.1078 36.0901 40.2592 35.0792 40.2226 33.6072C40.1964 32.7168 40.2226 31.8262 40.2226 30.9358C40.2488 29.5581 40.6573 29.1391 42.0821 29.1234C43.9678 29.1025 45.864 29.1234 47.8492 29.1234ZM52.0397 29.1234C53.9516 29.1234 54.2397 28.9454 54.2397 27.7406C54.2384 27.4458 54.1786 27.1541 54.0639 26.8825C53.9492 26.6109 53.7818 26.3648 53.5714 26.1583C53.361 25.9518 53.1117 25.789 52.838 25.6794C52.5643 25.5698 52.2717 25.5155 51.9769 25.5197C51.4025 25.5413 50.8589 25.7848 50.4603 26.199C50.0618 26.6132 49.8393 27.1658 49.8397 27.7406C49.8345 28.9349 50.1278 29.1182 52.0397 29.1182V29.1234Z" strokeMiterlimit="10" />
				<path d="M52.0399 29.1181C50.128 29.1181 49.8347 28.9349 49.8399 27.7353C49.8395 27.1605 50.062 26.608 50.4605 26.1938C50.8591 25.7796 51.4027 25.5361 51.9771 25.5144C52.2719 25.5102 52.5645 25.5645 52.8382 25.6741C53.1119 25.7837 53.3612 25.9465 53.5716 26.153C53.782 26.3595 53.9494 26.6056 54.0641 26.8772C54.1788 27.1488 54.2386 27.4405 54.2399 27.7353C54.2399 28.9401 53.9518 29.1181 52.0399 29.1181Z" strokeMiterlimit="10" />
				<path d="M41.6385 63.0713L39.1976 65.5174C39.0666 65.6484 38.988 65.6484 38.8571 65.5174L37.8828 64.538" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M76.8897 75.7578L71.5625 81.0849C71.2796 81.3678 71.1068 81.373 70.8292 81.0849L68.6973 78.9531" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M41.6385 53.313L39.1976 55.7591C39.0666 55.8901 38.988 55.8901 38.8571 55.7591L37.8828 54.7796" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M41.6385 43.5488L39.1976 45.995C39.0666 46.1259 38.988 46.1259 38.8571 45.995L37.8828 45.0155" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M87.9787 11.251L22.7539 99.8167" strokeMiterlimit="10" />
			</svg>

		</React.Fragment>
	)
}

export default NoChecklist