import React from 'react'
import LogoSmallIconGreen from '../../assets/icons/LogoSmallIconGreen'

const AiChatListing = () => {
  return (
    <>
         <div className="ai-lchat-list text-center title-fonts">
          <div className="h70w70 m-auto">
            <div className="position-relative d-flex flex-column align-items-center">
              <div className="footer-mid-btn-animation ">
                <div> <span className="footer-mid-circle circle-1"></span></div>
                <div><span className="footer-mid-circle circle-2"></span></div>
                <div><span className="footer-mid-circle circle-3"></span></div>
                <div><span className="footer-mid-circle circle-4"></span></div>
                <span className="hello_box">
                  <LogoSmallIconGreen className="path-white stroke-transparent HW24 " />
                </span>
              </div>
            </div>
          </div>
          <div className="ai-text-chat lh-lg">Hello! How may I assist you today?</div>
          <div class="chat-date-seperator"><span class="seperator-line"></span><span class="date-text color-white-80 c-font f-12 title-fonts">30/03/2023</span><span class="seperator-line"></span></div>
        </div>
        <div className="ai-lchat-list text-center title-fonts">
          <div className="h50w50 m-auto">
          <div className="h50w50 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-5px">
                                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"style={{ backgroundImage: `url('https://source.unsplash.com/random')` }}></div>
                            </div>
          </div>
          <div className="ai-text-chat lh-lg">I need some help with my account.</div>
          <div class="chat-date-seperator"><span class="seperator-line"></span><span class="date-text color-white-80 c-font f-12 title-fonts">30/03/2023</span><span class="seperator-line"></span></div>
        </div>
        <div className="ai-lchat-list text-center title-fonts">
          <div className="h70w70 m-auto">
            <div className="position-relative d-flex flex-column align-items-center">
              <div className="footer-mid-btn-animation">
                <div> <span className="footer-mid-circle circle-1"></span></div>
                <div><span className="footer-mid-circle circle-2"></span></div>
                <div><span className="footer-mid-circle circle-3"></span></div>
                <div><span className="footer-mid-circle circle-4"></span></div>
                <span className="hello_box">
                  <LogoSmallIconGreen className="path-white stroke-transparent HW24 " />
                </span>
              </div>
            </div>
          </div>
          <div className="ai-text-chat lh-lg">Sure thing! Can you please provide me with your account details so I can assist you better? Sure thing! Can you please</div>
          <div class="chat-date-seperator"><span class="seperator-line"></span><span class="date-text color-white-80 c-font f-12 title-fonts">30/03/2023</span><span class="seperator-line"></span></div>
        </div>
        
    </>
  )
}

export default AiChatListing