import { CloudArrowUp } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { _l } from '../../../hooks/utilities'
import { useSelector } from 'react-redux';
import CommanPlaceholder from '../../Placeholder/CommanPlaceholder';
import Lightbox from "yet-another-react-lightbox";


const FIleUpload = () => {
  const task_attachments = useSelector((state) => state.customer.task_attachments);

  const [taskAttachments, setTaskAttachments] = useState([]);
  const [defaultTaskAttachments, setDefaultTaskAttachments] = useState([]);
  const [openGallery, setOpenGallery] = useState(false);
  const [previewSrc, setPreviewSrc] = useState([]);


  const handleImagePreview = (comment, index) => {
    let selectAttachment = taskAttachments.filter(
      (file, innerIndex) => innerIndex == index
    );
    let otherAttachment = taskAttachments.filter(
      (file, innerIndex) => innerIndex != index
    );
    setPreviewSrc(
      selectAttachment.concat(otherAttachment).map((src) => {
        return {
          src: src.image_url,
        };
      })
    );
      setOpenGallery(true);

  }


  useEffect(() => {
    if (task_attachments) {
      setTaskAttachments(task_attachments);
      setDefaultTaskAttachments(task_attachments);
  
    }
  }, [task_attachments])

  return (
    <>
          <Lightbox
            open={openGallery}
            close={() => setOpenGallery(false)}
            slides={previewSrc}
          />
        <div className='file-parent-box  h106px position-sticky bottom-0  mt-auto p-10 bottom67px z-index-12   '>
            <div className='align-items-center bottom-0 d-flex  '>
            <div className={`file-image-list  w130pxh100px pe10px pt-0 pb-0`}> 
          <label className="c-list-icon w-100 position-relative">
            <div className="uploadboxhw comman-round-box with-bg radius_3 text-center d-flex w-100 flex-column border upload-box justify-content-center">
              <input
                type="file"
                className="absolute-input "
                multiple
                
              />
              <CloudArrowUp size={32}  weight="light" className="c-icons my-0 opacity70"  />
              <div className="c-font f-10 color-white-60 mt-10"> {_l('l_upload_file')}</div>
            </div>
          </label>
        </div>
        <div className='file-list-wrapper task-checklist-wrapper d-flex flex-column h-100 flex-grow-1'>
            <div className='d-flex'>
                {taskAttachments && taskAttachments.length > 0 && taskAttachments.filter((a, index)=> index < 4).map((attachment, index)=>{
                  // console.log("attachment",attachment)
                    return(<>
                        <a href='#/' className='file-image-list pt-0 pb-0 ps-0  uploadimages w130pxh100px'
                        onClick={()=>{
                          handleImagePreview(attachment, index)
                        }}>
                            <div className='c-list-icon w-100 position-relative with-overlay dropend bg-white-05'>
                                <div className='uploadboxhw comman-round-box with-bg radius_3 w-100 with-react-fancybox'>
                                    <div className='comman-bg-img h-100 w-100 bg-style-cover radius_3 d-flex  ' style={{backgroundImage: `url(${attachment.image_url})`}}></div>
                                </div>
                            </div>
                        </a>
                    </>)
                })}
          {taskAttachments && taskAttachments.length > 0 && taskAttachments.filter((a, index)=> index > 3 && index < 5).length > 0 ? <>
          {taskAttachments.filter((a, index)=> index > 3  && index < 5).map((attachment, index)=>{
            return(<a href='#/' className='file-image-list pt-0 pb-0 ps-0  uploadimages flex-grow-1 w130pxh100px'
              onClick={()=>{
                handleImagePreview(attachment, index)
              }}>
                    <div className='c-list-icon w-100 position-relative with-overlay dropend bg-white-05'>
                        <div className='uploadboxhw comman-round-box with-bg radius_3 w-100 with-react-fancybox'>
                            <div className={`comman-bg-img h-100 w-100 bg-style-cover radius_3 d-flex ${taskAttachments.length > 5 ? "with-overlay-img" : "" }`} style={{backgroundImage: `url(${attachment.image_url})`}}>
                                                {taskAttachments.length > 5 ? <span className="m-auto position-relaive z-index-2 color-yellowtext  c-font fw-semibold title-fonts f-18 span-color">+{taskAttachments.length - 5 }</span> : <></>}
                                            </div>
                        </div>
                    </div>
                </a>)
          })}
          </> : <></>  }
          {taskAttachments && taskAttachments.length == 0 ? (
          <CommanPlaceholder imgWidth="small-image"  imgType="no-images" mainSpacing="m-auto" placeholderText = {_l('l_no_attachments')} />
        ) : (
          <></>
        )}
               
            </div>
        </div>
            </div>
        </div>
    </>
  )
}

export default FIleUpload