import React, { useEffect } from "react";
// import CelebrationIcon from "../../assets/icons/CelebrationIcon";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { _l } from "../../hooks/utilities";
import CelebrationIcon from "../../assets/images/certificateblue.svg";

export function Congratulation({navigation, congratulationMessage, removeExtraMargin, destination, buttonText, buttonOnclickHandler}) {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let edit = searchParams.get("edit");
  
  useEffect(() => {
    if(navigation)
    {
      if(edit)
      {
        navigate(destination);
      }
      else {
        setTimeout(() => {
          navigate(destination);          
        }, 3000);
      }
    }
  }, [])
  

  return (
    <React.Fragment>
      {
        !edit
        ?
          <div className="align-item-center d-flex flex-column justify-content-center text-center h-100 mb-5 pb-5">
            <div className={`${removeExtraMargin ? ""  : "mx-auto col-12 col-md-6 col-lg-4"}`}>
              <div className="mx-auto">
                <img src={CelebrationIcon} />
              </div>
              <div className="onboarding-header text-center p-20">
                <div className="c-font f-28 fw-semibold pt-20px title-fonts">
                  {_l("l_congratulation")}
                </div>
                <div className="c-font color-white-60 f-18 pt-20px title-fonts">
                  {_l(congratulationMessage)}
                </div>
              </div>
              <div className="pt-70px">
                <Button className="h45w45 w-auto px-4 z-2" onClick={buttonOnclickHandler}>
                  {_l(buttonText)}
                </Button>
              </div>
            </div>
          </div>
        :
          <React.Fragment></React.Fragment>
      }
    </React.Fragment>
  );
}
