import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { _l, getDatePickerLocale, isBlocked } from "../../../hooks/utilities";
import { CalendarBlank } from "phosphor-react";
import DatePicker from "react-datepicker";
import { addDays } from "@fullcalendar/react";
import moment from "moment";
import { FloorListing } from "../../SpaceOnBoarding/SpaceDetails/FloorListing";
import SpaceSummary from "../../SpaceOnBoarding/SpaceDetails/SpaceSummary";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ExternalBasicDetails = ({
  providerData,
  spaceDetail,
  setProviderData,
  setSpaceDetail,
  addEditFloor,
  onFloorSelect,
}) => {

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    budget: "",
    startDate: "",
    endDate: "",
    unKnownStartDate: false,
    unKnownBudget: false,
    documents: "",
    documentsPreviews: [],
    contracts: "",
    contractsPreview: [],
    description: "",
    needToHire: false,
    furnished: false,
    id: 0,
  });

  const userType = localStorage.getItem("user_type");

  const benchmarkDetail = useSelector(
    (state) => state.customer.benchmarkDetail
  );

  useEffect(() => {
    setProviderData({ ...providerData, ...formData });
  }, [formData]);

  useEffect(() => {
    if (benchmarkDetail && benchmarkDetail.id > 0) {
      let documentType = "";
      if(userType === "operator")
      {
        if(benchmarkDetail.proposal_id > 0)
        {
          documentType = "proposal";
        }
        else {
          documentType = "schedule";
        }
      }
      else if(userType === "contact")
      {
        if(benchmarkDetail.open_schedule_modal == 1 && benchmarkDetail.master_id > 0)
        {
          documentType = "schedule";
        }
        else if(benchmarkDetail.open_schedule_modal == 0 && benchmarkDetail.master_id > 0)
        {
          documentType = "benchmark";
        }
        else {
          documentType = benchmarkDetail.is_schedule > 0
          ? "schedule"
          : "benchmark"
        }
      }
      setFormData({
        budget: Number(benchmarkDetail.max_budget) > 0 ? Number(benchmarkDetail.max_budget) : "",
        contractsPreview: benchmarkDetail.attachments
          .filter((x) => x.is_contract_file == 1)
          .map((w) => {
            return {
              url: w.attachment_url,
              file_id: w.file_id,
              name: w.attachment_url.split("/").pop(),
            };
          }),
        description: benchmarkDetail.basic_information,
        documents: benchmarkDetail.attachments,
        documentsPreviews: benchmarkDetail.attachments
          .filter((x) => x.is_contract_file == 0)
          .map((w) => {
            return {
              url: w.attachment_url,
              file_id: w.file_id,
              name: w.attachment_url.split("/").pop(),
            };
          }),
        endDate: benchmarkDetail.contract_end_date,
        startDate: benchmarkDetail.startdate,
        unKnownBudget: benchmarkDetail.budget_is_empty > 0 || !Number(benchmarkDetail.recursive),
        unKnownStartDate: benchmarkDetail.start_date_is_empty > 0,
        id: benchmarkDetail.id,
        categoryId: benchmarkDetail.provider_id,
        categoryName: benchmarkDetail.category,
        type: documentType,
        invited_providers: benchmarkDetail.operator_details,
        sources: ["choose_from_past"],
        needToHire: benchmarkDetail.need_to_hire_people > 0,
        furnished: benchmarkDetail.is_furnished > 0,
        invited_customers: []
      });
    }
  }, [benchmarkDetail]);

  return (
    <>
      <Row className="justify-content-center">
        <Col xl={12}>
          <Row>
            <Form.Group className="col-xl-12 flex-grow-1 c-input-box position-relative mb-32px">
              <Form.Label className="input-label no-asterisk no-asterisk">
                {`${_l("l_space_title")}`}
              </Form.Label>
              <input
                className="form-control"
                placeholder={_l("l_space_title_placeholder")}
                type="text"
                name="spaceTitle"
                defaultValue={spaceDetail.title || spaceDetail.address}
                onChange={(e) => {
                  setSpaceDetail({ ...spaceDetail, title: e.target.value });
                }}
              />
            </Form.Group>
            <Form.Group className="col-xl-4 flex-grow-1 c-input-box mb-32px position-relative">
              <Form.Label className="input-label no-asterisk no-asterisk">
                {`${_l("l_add")} ${_l("l_budget")}`}
              </Form.Label>
              <input
                className="form-control "
                placeholder="Є100"
                type="number"
                name="budget"
                value={formData.budget}
                onChange={(e) => {
                  setFormData({ ...formData, budget: e.target.value });
                }}
                disabled={formData.unKnownBudget}
              />
               <div className="form-check with-span py-0">
                  <div className="d-flex align-items-center">
                    <Form.Label className="form-check-label mt-2 mb-0 w-100">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={formData.unKnownBudget}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            unKnownBudget: e.target.checked,
                          })
                        }
                      />
                      <span className="c-font f-12 ps-1">
                        {_l("l_approximate_i_dont_know_my")} {_l("l_budget")}
                      </span>
                    </Form.Label>
                  </div>
                </div>
            </Form.Group>
            <Form.Group className="col-xl-4 flex-grow-1 c-input-box mb-32px position-relative">
              <Form.Label className="input-label no-asterisk">
                {`${_l("l_service_start_date")}`}
              </Form.Label>
              <div className="custom-datepicker w-100">
                <div className="d-flex align-items-center form-control color-white-60 dropdown-center dropdown w-100 react-datepicker-wrapper-w-100 comman_action_icon h_53px">
                  <div className="action_icon h32w32 with_bg">
                    <CalendarBlank
                      size={18}
                      weight="light"
                      className="c-icons opacity-60"
                    />
                  </div>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    filterDate={isBlocked}
                    placeholderText={`${_l("l_select_date_placeholder")}`}
                    className="ps-2 w-100"
                    selected={
                      formData.startDate ? new Date(formData.startDate) : ""
                    }
                    minDate={addDays(new Date(), 10)}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        startDate: moment(e).format("YYYY-MM-DD"),
                      });
                    }}
                    readOnly={formData.unKnownStartDate}
                    locale={getDatePickerLocale()}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    calendarStartDay={1}
                  ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                </div>
              </div>
              <div className="form-check with-span py-0">
                  <div className="d-flex align-items-center">
                    <Form.Label className="form-check-label mt-2 mb-0 w-100">
                      <input className="form-check-input" type="checkbox"
                        checked={formData.unKnownStartDate}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            unKnownStartDate: e.target.checked,
                          })
                        }/>
                      <span className="c-font f-12 ps-1">
                        {_l("l_dont_know_my_service_start_date")}{" "}
                      </span>
                    </Form.Label>
                  </div>
                </div>
            </Form.Group>
          </Row>
          <SpaceSummary
            fullWidth={true}
            addEditFloor={addEditFloor}
            currentStep={""}
            setSpaceDetail={setSpaceDetail}
            spaceDetail={spaceDetail}
          />
          <FloorListing buttonText={""} setFloor={onFloorSelect} />
        </Col>
      </Row>
    </>
  );
};

export default ExternalBasicDetails;
