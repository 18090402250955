import React, { useState } from 'react'
import { DropdownMenu } from 'reactstrap';
import CommanPlaceholder from '../Placeholder/CommanPlaceholder';
import { _l } from '../../hooks/utilities';
import { installationFilters } from '../../actions/customer';
import { useDispatch, useSelector } from 'react-redux';

const InstalltionFilterDropDown = ({employeeData}) => {
    const {installationFilter} = useSelector((state) => state.customer);

    const dispatch = useDispatch();
    const setInstallationFilter = (id) => {
        let index = installationFilter.findIndex((i) => i == id);
        let installationIds = installationFilter
        if (index == -1) {
          installationIds = [Number(id)].concat(installationIds);
        }else{
          installationIds = installationIds.filter((item) => item != id);
        }
        dispatch(installationFilters(installationIds));
    }
  return (
    <>
    <DropdownMenu
        className={`dropdown-menu w-140 overflow-auto`}
        container="body"
        direction="down"
      >
        <ul>
              {employeeData && employeeData.length > 0 ? (
                employeeData.map((item, i) => {
                  return (
                    <>
                      <li className="" key={i}>
                        <a
                          href="#/"
                          className={`custom-assignees custom-class-active dropdown-item ${installationFilter.includes(Number(item.staff_id)) ? "active" : ''}`}
                        >
                          <div className="d-flex align-items-center text-truncate"
                            onClick={() => setInstallationFilter(item.staff_id)}
                          >
                            <div className="d-flex align-items-center w100minus100per text_wrap">
                              <div className="c-list-icon ">
                                <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
                                    <div
                                      className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                      style={{
                                        backgroundImage: `url(${item.profile_image})`,
                                      }}
                                    ></div>
    
                                </div>
                              </div>
                              <div className="ps-2 w100minus10 text_wrap">
                                <div className="c-list-detail text-truncate ">
                                  {_l(`${item.full_name}`)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li className="dropdown-divider my-0"></li>
                    </>
                  );
                })
              ) : (
                <CommanPlaceholder
                  imgWidth="h60w60"
                  mainSpacing="py-3"
                  placeholderText={_l("l_no_assignee")}
                />
              )}
        </ul>
      </DropdownMenu>
    </>
  )
}

export default InstalltionFilterDropDown