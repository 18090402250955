import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomerServices from "../services/customer-services";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../actions/messages";
import { validateEmail , _l , getDatePickerLocale, showError, countryOptionsForIBAN, validateIBAN, getAddressComponents } from "../hooks/utilities";
import { Info,X,Paperclip,CalendarBlank } from "phosphor-react";
import WalletSetting from "./Modals/WalletSetting";
import MessagePlaceHolderScreen from "./ExploreFeatures/ConnectProvider/MessagePlaceHolderScreen";
import Geocode from 'react-geocode';
import { DEFAULT_CITY_NAME, DEFAULT_COUNTRY_NAME_LONG, DEFAULT_COUNTRY_NAME_SHORT, DEFAULT_POSTAL_CODE, DEFAULT_REGION_ID, DEFAULT_REGION_NAME } from "../constants/constants";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_SECRET);

const PaymentPageMain = forwardRef(({insideModal, handleClose, autoSubScribe, categoryId, regionId, fromTopicSettingModal, callBack, setLoadingNextStep, setUnsavedWalletSettings, walletData}, ref) => {
  const [selectedTab, setSelectedTab] = useState(autoSubScribe ? "info-modal" : "wallet-settings");
  const [sirenNumber, setSirenNumber] = useState("");
  const [taxId, setTaxId] = useState("");
  const [birthDate, setBirthDate] = useState();
  const [bankCountry, setBankCountry] = useState();
  const [bankCurrency, setBankCurrency] = useState();
  const [iban, setIban] = useState("");
  const [walletSettingData, setWalletSettingData] = useState({});
  const [phoneNumber, setPhoneNumber] = useState(
    localStorage.getItem("phonenumber")
      ? localStorage.getItem("phonenumber")
      : ""
  );
  const [email, setEmail] = useState(
    localStorage.getItem("email") ? localStorage.getItem("email") : ""
  );
  const [company, setCompany] = useState(
    localStorage.getItem("company_name")
      ? localStorage.getItem("company_name")
      : ""
  );
  const [firstName, setFirstName] = useState(
    localStorage.getItem("firstname") ? localStorage.getItem("firstname") : ""
  );
  const [lastName, setLastName] = useState(
    localStorage.getItem("lastname") ? localStorage.getItem("lastname") : ""
  );
  const [frontSideImage, setFrontSideImage] = useState();
  const [backSideImage, setBackSideImage] = useState();
  const [frontSideImagePreviews, setFrontSideImagePreviews] = useState([]);
  const [backSideImagePreviews, setBackSideImagePreviews] = useState([]);
  const [formSubmitLoader, setFormSubmitLoader] = useState(false);
  const [operatorBasicDetailsAvailable, setOperatorBasicDetailsAvailable] =
    useState(true);
  const [providerAddress, setProviderAddress] = useState("");
  const [providerCountry, setproviderCountry] = useState();
  const [providerCountryLable, setProviderCountryLable] = useState();
  const [providerCity, setProviderCity] = useState("");
  const [providerPostalCode, setProviderPostalCode] = useState("");
  const [suggestedAddress, setSuggestedAddress] = useState("");
  const [suggestedCity, setSuggestedCity] = useState("");
  const [regionList, setRegionList] = useState([]);

  const countryAutoCompleteRef = useRef();
  const countryInputRef = useRef();
  const cityInputRef = useRef();
  const cityAutoCompleteRef = useRef();
  const walletPageRef = useRef(null);

  const { benchmarkDetail } = useSelector((state) => state.customer);

  const minYear = new Date().getFullYear() - 18;
  const minBirthDate = new Date();
  minBirthDate.setFullYear(minYear);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const staffId = localStorage.getItem("staff_id") || 0;
  const operatorProfileDetails = ["address", "postal_code", "city", "country"];
  const countryOptions = {
    componentRestrictions: {
      country: providerCountry ? providerCountry.label.toLowerCase() : "fr",
    },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["address"],
  };

  const cityOptions = {
    componentRestrictions: {
      country: providerCountry ? providerCountry.label.toLowerCase() : "fr",
    },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["(cities)"],
  };
  
  useEffect(() => {
    operatorProfileDetails.forEach((detail) => {
      const value = localStorage.getItem(detail);
      if (!value || value === "null") {
        setOperatorBasicDetailsAvailable(false);
      }
    });

    const prefillAddressFields = async() => {
      const companyAddress = localStorage.getItem("company_address");
      let postalCode = DEFAULT_POSTAL_CODE;
      let city = DEFAULT_CITY_NAME;
      let country = DEFAULT_COUNTRY_NAME_SHORT;
      let countryLongname = DEFAULT_COUNTRY_NAME_LONG;

      if (companyAddress) {
        try {
          const response = await Geocode.fromAddress(companyAddress);

          if (response.status === "OK") {
            const {address_components } = response.results[0];

            address_components.forEach((component) => {
              if (component.types.includes("postal_code")) {
                postalCode = component.short_name;
              } else if (component.types.includes("locality")) {
                city = component.long_name;
              } else if (component.types.includes("country")) {
                country = component.short_name;
                countryLongname = component.long_name;
              }
            });
          } else {
            // Handle error cases
            console.log("Geocoding API Error:", response.status);
          }
        } catch (error) {
          console.log("Error:", error);
        } finally {
            setProviderPostalCode(postalCode);
            setProviderCity(city);
            setSuggestedAddress(companyAddress);
            setSuggestedCity(city);
            setproviderCountry({label: country});
            setProviderCountryLable(country);
            setProviderAddress(companyAddress);
        }
      }
    }

    prefillAddressFields();

    getRegionList();

    if(walletData && walletData.id > 0)
    {
      setWalletSettingData({
        wallet_title: walletData.title,
        categories: walletData.selected_category,
        region_ids: walletData.selected_region,
        projects: walletData.selected_space,
        id: walletData.id,
        invoice_tax: walletData.invoice_tax
      });
    }

    return () => {
      frontSideImagePreviews.map((img) => {
        URL.revokeObjectURL(img.url);
      });
      backSideImagePreviews.map((img) => {
        URL.revokeObjectURL(img.url);
      });
    };
  }, []);

  useEffect(() => {
    if (
      providerAddress &&
      providerAddress.trim().length &&
      countryInputRef.current
    ) {
      countryAutoCompleteRef.current =
        new window.google.maps.places.Autocomplete(
          countryInputRef.current,
          countryOptions
        ).addListener("place_changed", async() => {
          const addressComponents = await getAddressComponents(countryInputRef.current.value);
          setProviderPostalCode(addressComponents.postalCode);
          setProviderCity(addressComponents.city);
          setSuggestedAddress(addressComponents.companyAddress);
          setSuggestedCity(addressComponents.city);
          setproviderCountry({label: addressComponents.country});
          setProviderCountryLable(addressComponents.country);
          setSuggestedAddress(countryInputRef.current.value);
          setProviderAddress(countryInputRef.current.value);
        });
    }
  }, [providerAddress, providerCountry]);

  useEffect(() => {
    if (providerCity && providerCity.trim().length && cityInputRef.current) {
      cityAutoCompleteRef.current = new window.google.maps.places.Autocomplete(
        cityInputRef.current,
        cityOptions
      ).addListener("place_changed", () => {
        setSuggestedCity(cityInputRef.current.value);
        setProviderCity(cityInputRef.current.value);
      });
    }
  }, [providerCity, providerCountry]);

  useEffect(() => {
    if(callBack)
    {
      callBack(selectedTab);
    }
  }, [selectedTab])
  

  const fieldValidations = () => {
    if (selectedTab === "payment-step-1" && !step1ValidFields()) {
      return false;
    } else if (selectedTab === "payment-step-1") {
      setSelectedTab("payment-step-2");
      return;
    }
    if (
      selectedTab === "payment-step-2" &&
      (!step1ValidFields() || !step2ValidFields())
    ) {
      return false;
    } else if (selectedTab === "payment-step-2") {
      setSelectedTab("payment-step-3");
      return;
    }
    if (
      selectedTab === "payment-step-3" &&
      (!step1ValidFields() || !step2ValidFields() || !step3ValidFields())
    ) {
      return false;
    } else if (selectedTab === "payment-step-3") {
      setSelectedTab("postal-fields");
      return;
    }
    if (
      (selectedTab === "postal-fields" &&
        (!step1ValidFields() || !step2ValidFields() || !step3ValidFields())) ||
      !providerBasicDetailsValidate()
    ) {
      return false;
    } else {
      handleSave();
    }
  };

  const step1ValidFields = () => {
    if (!sirenNumber || sirenNumber.trim().length !== 9) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_valid_siren_number"))
      );
      return false;
    } else if (!taxId || !taxId.trim().length) {
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_enter_tax_id")));
      return false;
    }
    return true;
  };

  const step2ValidFields = () => {
    if (!bankCountry) {
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_select_country")));
      return false;
    } else if (!bankCurrency) {
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_select_currency")));
      return false;
    } else if (!iban || !iban.trim().length) {
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_enter_iban")));
      return false;
    } else {
      return true;
    }
  };

  const step3ValidFields = () => {
    if (!birthDate) {
      dispatch(showMessage("unsucess", _l("l_error"),  _l("l_please_enter_birth_date")));
      return false;
    } else if (!frontSideImage) {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_upload_front_side_image_of_document")
        )
      );
      return false;
    } else if (!backSideImage) {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_upload_back_side_image_of_document")
        )
      );
      return false;
    } else {
      return true;
    }
  };

  const providerBasicDetailsValidate = () => {
    if (!providerCountry) {
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_enter_birth_country")));
      return false;
    } else if (
      !suggestedAddress.length ||
      !providerAddress.length ||
      suggestedAddress.toLowerCase() !== providerAddress.toLowerCase()
    ) {
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_enter_valid_address")));
      return false;
    } else if (!providerPostalCode || providerPostalCode.trim().length == 0) {
      dispatch(
        showMessage("unsucess", _l("l_error"),  _l("l_please_enter_valid_postal_code"))
      );
      return false;
    } else if (
      !suggestedCity.length ||
      !providerCity.length ||
      suggestedCity.toLowerCase() !== providerCity.toLowerCase()
    ) {
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_enter_valid_city")));
      return false;
    } else if (phoneNumber == "") {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_enter_valid_phone_number_with_country_code")
        )
      );
      return false;
    } else if (firstName == "") {
      dispatch(
        showMessage("unsucess", _l("l_error"),  _l("l_please_enter_valid_first_name"))
      );
      return false;
    } else if (lastName == "") {
      dispatch(
        showMessage("unsucess", _l("l_error"),  _l("l_please_enter_valid_last_name"))
      );
      return false;
    } else if (email == "" || !validateEmail(email)) {
      dispatch(showMessage("unsucess", _l("l_error"),_l("l_please_enter_valid_email")));
      return false;
    } else if (company == "") {
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_enter_valid_company_name")));
      return false;
    } else {
      return true;
    }
  };

  const fetchRegion = async() => {
    let region_id = DEFAULT_REGION_ID;
    let region_name = DEFAULT_REGION_NAME;
    try {
      const response = await Geocode.fromAddress(providerAddress);
      const { region_id, region_name } = selectSpaceRegion(
        response.results[0].address_components
      );
      return {region_id, region_name}
    }
    catch(e)
    {
      console.error(e);
      if(process.env !== "production")
      {
        return {region_id, region_name}
      }
    }
  };

  const getRegionList = () => {
    CustomerServices.getRegionList()
      .then((res) => {
        if (res.status == 1 && res.data) {
          setRegionList(
            res.data.map((data) => {
              return {
                label: data.name,
                value: data.id,
              };
            })
          );
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const selectSpaceRegion = (addressComponent) => {
   let regionId = 0;
    let regionName = "";
    let fetchedRegion = "";
    let index = addressComponent.findIndex(
      (add) => add.types && add.types.includes("administrative_area_level_2")
    );
    if (index === -1) {
      index = addressComponent.findIndex(
        (add) => add.types && add.types.includes("administrative_area_level_1")
      );
    }
    if (index > -1) {
      fetchedRegion = addressComponent[index].long_name;
    }
    regionList.forEach((region) => {
      if (
        region.label.toLowerCase().trim() == fetchedRegion.toLowerCase().trim()
      ) {
        regionId = region.value;
        regionName = region.label;
      }
    });
    if (regionId == 0) {
      setRegionList(regionList.concat([{ value: 0, label: fetchedRegion }]));
      return { region_id: 0, region_name: fetchedRegion };
    } else {
      return { region_id: regionId, region_name: regionName };
    }
  };

  const handleSave = async() => {
    const {region_id, region_name} = await fetchRegion();
    setFormSubmitLoader(true);
    
  
    if(setLoadingNextStep)
    {
      setLoadingNextStep(true);
    }

    CustomerServices.setupProviderWallet({
      account_iban: iban,
      company_name: company,
      email,
      categories: walletSettingData.categories,
      company_address: providerAddress,
      terms: walletSettingData.terms,
      vat_number: walletSettingData.vat_number,
      invoice_tax: walletSettingData.invoice_tax,
      tax_id: taxId,
      siren_number: sirenNumber,
      bank_country: bankCountry,
      bank_currency: bankCurrency,
      birthdate: moment(birthDate).format("YYYY-MM-DD"),
      country: providerCountryLable,
      city: providerCity,
      phone_number: phoneNumber,
      postal_code: providerPostalCode,
      first_name: firstName,
      last_name: lastName,
      region_id: region_id,
      region_name: region_name,
      front: Array.from(frontSideImage)[0],
      back: Array.from(backSideImage)[0],
      proposal_id: insideModal && benchmarkDetail && benchmarkDetail.proposal_id > 0 ? benchmarkDetail.proposal_id : 0,
      benchmark_project_id: insideModal && benchmarkDetail && benchmarkDetail.project_id > 0 ? benchmarkDetail.project_id: 0,
      region_ids: walletSettingData.region_ids,
      wallet_title: walletSettingData.wallet_title
    })
    .then((res) => {
      setFormSubmitLoader(false);
      if (res.status) {
        if(insideModal)
        {
          handleClose(true);
        }
        else {
          navigate("/mywallet");
        }
      } else {
        dispatch(showMessage("unsucess", _l("l_error"), res.message));
      }
    }).finally(() => {
      if(setLoadingNextStep)
      {
        setLoadingNextStep(false);
      }
    });
  };

  const countryListing = countryOptionsForIBAN;

  const currencyListing = [
    {
      value: "EUR",
      label: _l('l_euro'),
    },
    {
      value: "GBP",
      label: _l('l_gbp'),
    },
  ];

  const frontImagepreparePreview = (files) => {
    let previewArray = [];
    Array.from(files).map((img) => {
      previewArray.push({ name: img.name, url: URL.createObjectURL(img) });
    });
    setFrontSideImagePreviews(previewArray);
  };

  const backImagepreparePreview = (files) => {
    let previewArray = [];
    Array.from(files).map((img) => {
      previewArray.push({ name: img.name, url: URL.createObjectURL(img) });
    });
    setBackSideImagePreviews(previewArray);
  };

  const selectFrontFile = (event) => {
    setFrontSideImage(event.target.files);
    frontImagepreparePreview(event.target.files);
  };

  const selectBackFile = (event) => {
    setBackSideImage(event.target.files);
    backImagepreparePreview(event.target.files);
  };

  const removeImageSelection = (name, flag = "") => {
    if (flag != "") {
      setFrontSideImagePreviews(
        frontSideImagePreviews.filter((fr_img) => fr_img.name != name)
      );
      setFrontSideImage();
    } else {
      setBackSideImagePreviews(
        backSideImagePreviews.filter((backimg) => backimg.name != name)
      );
      setBackSideImage();
    }
  };

  const prefillIBANRelatedData = (e) => {
    let ibanValue = e.target.value;
    const regex = /^[a-zA-Z]{2}/;

    if(ibanValue && validateIBAN(ibanValue))
    {
      const countryCode = ibanValue.slice(0, 2);
      const indexOfCountry = countryListing.findIndex((w) => w.label === countryCode);
      if(indexOfCountry !== -1)
      {
        setBankCountry(countryListing[indexOfCountry].value);
      }
      else {
        setBankCountry("FR");
      }
      setBankCurrency("EUR");
    }
    else {
      showError("l_invalid_iban");
      setBankCountry("");
      setBankCurrency("");
    }
  };

  const moveToNextStep = () => {
    if(selectedTab === "wallet-settings" && walletPageRef.current)
    {
      walletPageRef.current.setWalletSettings();
    }
    else if(selectedTab === "info-modal")
    {
      setSelectedTab("wallet-settings");
    }
    else {
      fieldValidations();
    }
  }

  useImperativeHandle(ref, () => ({
    changeSelectedTabFromParent: setSelectedTab,
    moveToNextStep: moveToNextStep
  }));

  return (
    <>
      <div className="h-100 pe10per">
        <div className="row h-100">
          <div
            className={`${insideModal ? "col-xl-12" : "col-xl-8"} m-auto h-100`}
          >
            {insideModal ? (
              <React.Fragment></React.Fragment>
            ) : (
              <div className="task-list-accordian custom-accordian-main py-3">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="active to-do accordion-header">
                    <div className="accordion-button">
                      <div className="left-task-header">
                        <div className="d-flex align-items-center fw-semibold title-fonts c-font f-13">
                          <span className="task-title ps-2">
                            {_l("l_onboarding")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {
              fromTopicSettingModal
              ?
              <></>
              :
              <div className="d-flex align-items-center justify-content-between border-bottom">
                <div className="comman-tab-wrapper  pb-0">
                  <div className="d-flex align-items-center">
                    {autoSubScribe ? (
                      <div
                        className={`tab-wrapper d-flex align-items-center ${
                          selectedTab === "info-modal" ? "active" : ""
                        }`}
                        onClick={() => {
                          setSelectedTab("info-modal");
                        }}
                      >
                        <a href="#/" className="tab-name">{_l("l_information")}</a>
                        <a href="#/" className="info-icon">
                          <Info
                            size={16}
                            weight="light"
                            className="c-icons opacity-50"
                          />
                        </a>
                      </div>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    <div
                      className={`tab-wrapper d-flex align-items-center ${
                        selectedTab === "wallet-settings" ? "active" : ""
                      }`}
                      onClick={() => {
                        setSelectedTab("wallet-settings");
                      }}
                    >
                      <a href="#/" className="tab-name">{_l("l_wallet_setting")}</a>
                      <a href="#/" className="info-icon">
                        <Info
                          size={16}
                          weight="light"
                          className="c-icons opacity-50"
                        />
                      </a>
                    </div>
                    <div
                      className={`tab-wrapper d-flex align-items-center ${
                        selectedTab === "payment-step-1" ? "active" : ""
                      }`}
                      onClick={() => {
                        setSelectedTab("payment-step-1");
                      }}
                    >
                      <a href="#/" className="tab-name">{_l("l_business")}</a>
                      <a href="#/" className="info-icon">
                        <Info
                          size={16}
                          weight="light"
                          className="c-icons opacity-50"
                        />
                      </a>
                    </div>
                    <div
                      className={`tab-wrapper d-flex align-items-center ${
                        selectedTab === "payment-step-2" ? "active" : ""
                      }`}
                      onClick={() => {
                        if (step1ValidFields()) setSelectedTab("payment-step-2");
                      }}
                    >
                      <a href="#/" className="tab-name">{_l("l_benificiaries")}</a>
                      <a href="#/" className="info-icon">
                        <Info
                          size={16}
                          weight="light"
                          className="c-icons opacity-50"
                        />
                      </a>
                    </div>
                    <div
                      className={`tab-wrapper d-flex align-items-center ${
                        selectedTab === "payment-step-3" ? "active" : ""
                      }`}
                      onClick={() => {
                        if (step1ValidFields() && step2ValidFields())
                          setSelectedTab("payment-step-3");
                      }}
                    >
                      <a href="#/" className="tab-name">{_l("l_documents")}</a>
                      <a href="#/" className="info-icon">
                        <Info
                          size={16}
                          weight="light"
                          className="c-icons opacity-50"
                        />
                      </a>
                    </div>

                    <div
                      className={`tab-wrapper d-flex align-items-center ${
                        selectedTab === "postal-fields" ? "active" : ""
                      }`}
                      onClick={() => {
                        if (
                          step1ValidFields() &&
                          step2ValidFields() &&
                          step3ValidFields()
                        )
                          setSelectedTab("postal-fields");
                      }}
                    >
                      <a href="#/" className="tab-name">{_l("l_postal_details")}</a>
                      <a href="#/" className="info-icon">
                        <Info
                          size={16}
                          weight="light"
                          className="c-icons opacity-50"
                        />
                      </a>
                    </div>

                    <div
                      className={`tab-wrapper d-none d-flex align-items-center ${
                        selectedTab === "payment-step-4" ? "active" : ""
                      }`}
                      onClick={() => {
                        if (
                          step1ValidFields() &&
                          step2ValidFields() &&
                          step3ValidFields()
                        )
                          setSelectedTab("postal-fields");
                      }}
                    >
                      <a href="#/" className="tab-name">{_l("l_postal_details")}</a>
                      <a href="#/" className="info-icon">
                        <Info
                          size={16}
                          weight="light"
                          className="c-icons opacity-50"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div
              className={`${
                insideModal ? "" : "bg-white-03 p-15 onboarding-floor-bg"
              } ${fromTopicSettingModal ? "h_66" : " "} premium-sub-main-box`}
            >
                <div className={`comman-content-scroll-wrapper ${selectedTab !== "wallet-settings" ? "d-none" : ""}`}>
                  <div className="comman-content-scroll h-100 overflow-hiiden-auto res-overflow-visible d-flex flex-column">
                    <WalletSetting
                      ref={walletPageRef}
                      formView={true}
                      handleClose={() => {
                        if(walletData && walletData.id > 0)
                        {
                          handleClose();
                        }
                        else {
                          setSelectedTab("payment-step-1");
                        }
                      }}
                      setNextStep={setSelectedTab}
                      nextStep={"payment-step-1"}
                      walletSettingData={walletSettingData}
                      setWalletSettingData={setWalletSettingData}
                      setUnsavedWalletSettings={setUnsavedWalletSettings}
                      categoryId={categoryId}
                      regionId={regionId}
                      fromTopicSettingModal={fromTopicSettingModal}
                      isEdit={walletData && walletData.id > 0}
                      setLoadingNextStep={setLoadingNextStep}
                    />
                  </div>
                  {
                    fromTopicSettingModal
                    ?
                    <></>
                    :
                    <div className="comman-content-bottom-btn">
                      <div className="d-flex justify-content-between">
                        <div>
                        {autoSubScribe ? (
                          <a href="#/" className="btn btn-secondary btn-sm">{_l("l_previous")}</a>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                        </div>
                        <a href="#/"
                          className="btn btn-primary btn-sm"
                          onClick={() => {
                            if (walletPageRef.current) {
                              walletPageRef.current.setWalletSettings();
                            }
                          }}
                        >
                          {_l("l_next")}
                        </a>
                      </div>
                    </div>
                  }
                </div>
                <div className={`comman-content-scroll-wrapper ${selectedTab !== "payment-step-1" ? "d-none" : ""}`}>
                  <div className="comman-content-scroll h-100 overflow-hiiden-auto res-overflow-visible d-flex flex-column">
                    <div className="row justify-content-center">
                      <div className="col-xl-12">
                        <div className="row">
                          <Form.Group className="col-12 c-input-box pb-4 position-relative ">
                            <Form.Label className="input-label no-asterisk">
                              {_l("l_siren_number")}
                            </Form.Label>
                            <Form.Control
                              placeholder={`${_l(
                                "l_siren_number_placeholder"
                              )}`}
                              type="text"
                              name=""
                              value={sirenNumber}
                              onChange={(e) => {
                                const reg = new RegExp("^[0-9]+$");
                                const inputValue = e.target.value;
                                if (
                                  (reg.test(inputValue) || inputValue == "") &&
                                  inputValue.length <= 9
                                )
                                  setSirenNumber(e.target.value);
                              }}
                            />
                          </Form.Group>
                          <Form.Group className="col-12 c-input-box pb-4 position-relative ">
                            <Form.Label className="input-label no-asterisk">
                              {_l("l_tax_id")}
                            </Form.Label>
                            <Form.Control
                              placeholder={`${_l("l_tax_id_placeholder")}`}
                              type="text"
                              name=""
                              value={taxId}
                              onChange={(e) => {
                                const reg = new RegExp("^[A-Za-z0-9]+$");
                                const inputValue = e.target.value;
                                if (reg.test(inputValue) || inputValue == "")
                                  setTaxId(e.target.value);
                              }}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    fromTopicSettingModal
                    ?
                    <></>
                    :
                    <div className="comman-content-bottom-btn">
                      <div className="d-flex justify-content-between">
                        <a href="#/"
                          className="btn btn-secondary btn-sm"
                          onClick={() => {
                            setSelectedTab("wallet-settings");
                          }}
                        >
                          {_l("l_back")}
                        </a>
                        <a href="#/"
                          className="btn btn-primary btn-sm"
                          onClick={() => {
                            fieldValidations();
                          }}
                        >
                          {_l("l_next")}
                        </a>
                      </div>
                    </div>
                  }
                </div>
                <div className={`h-100 ${selectedTab !== "payment-step-2" ? "d-none" : ""}`}>
                  <div className="comman-content-scroll-wrapper">
                    <div className="comman-content-scroll h-100 overflow-hiiden-auto res-overflow-visible">
                      <div className="row justify-content-center">
                        <div className="col-xl-12">
                          <div className="row">
                            <Form.Group className="col-xl-12 c-input-box pb-4 position-relative ">
                              <Form.Label className="input-label no-asterisk">
                                {_l("l_iban_label")}
                              </Form.Label>
                              <Form.Control
                                placeholder={`${_l("l_iban_placeholder")}`}
                                type="text"
                                name=""
                                value={iban}
                                onChange={(e) => {
                                  const reg = new RegExp("^[a-zA-Z0-9 ]+$");
                                  const inputValue = e.target.value;
                                  if (reg.test(inputValue) || inputValue == "")
                                    setIban(e.target.value);
                                }}
                                onBlur={prefillIBANRelatedData}
                              />
                            </Form.Group>
                            <Form.Group className="col-xl-12 c-input-box pb-4 position-relative">
                              <Form.Label className="input-label no-asterisk ">
                                {_l("l_bank_country")}
                              </Form.Label>
                              <div className="d-flex flex-grow-1 align-items-center">
                                <Select
                                  placeholder={`${_l("l_select_bank_country")}`}
                                  className="custom-select-menu w-100"
                                  classNamePrefix="react-select"
                                  closeMenuOnSelect={true}
                                  options={countryListing}
                                  onChange={(val) => setBankCountry(val.value)}
                                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                  menuPortalTarget={document.body}
                                  value={
                                    bankCountry
                                      ? {
                                          label: countryListing.find(
                                            (x) => x.value === bankCountry
                                          ).label,
                                          value: countryListing.find(
                                            (x) => x.value === bankCountry
                                          ).value,
                                        }
                                      : []
                                  }
                                />
                              </div>
                            </Form.Group>
                            <Form.Group className="col-xl-12 c-input-box pb-4 position-relative">
                              <Form.Label className="input-label no-asterisk ">
                                {_l("l_bank_currency")}
                              </Form.Label>
                              <div className="d-flex flex-grow-1 align-items-center">
                                <Select
                                  placeholder={`${_l(
                                    "l_select_bank_currency"
                                  )}`}
                                  className="custom-select-menu w-100"
                                  classNamePrefix="react-select"
                                  closeMenuOnSelect={true}
                                  options={currencyListing}
                                  onChange={(val) => setBankCurrency(val.value)}
                                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                  menuPortalTarget={document.body}
                                  value={
                                    bankCurrency
                                      ? {
                                          value: bankCurrency,
                                          label: currencyListing.find(
                                            (x) => x.value === bankCurrency
                                          ).label,
                                        }
                                      : []
                                  }
                                />
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      fromTopicSettingModal
                      ?
                      <></>
                      :
                      <div className="comman-content-bottom-btn">
                        <div className="d-flex justify-content-between">
                          <a href="#/"
                            className="btn btn-secondary btn-sm"
                            onClick={() => {
                              setSelectedTab("payment-step-1");
                            }}
                          >
                            {_l("l_back")}
                          </a>
                          <a href="#/"
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                              fieldValidations();
                            }}
                          >
                            {_l("l_next")}
                          </a>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className={`comman-content-scroll-wrapper ${selectedTab !== "payment-step-3" ? "d-none" : ""}`}>
                  <div className="comman-content-scroll h-100 overflow-hiiden-auto res-overflow-visible">
                    <div className="row justify-content-center">
                      <div className="col-xl-12">
                        <div className="row">
                          <Form.Group className="col-xl-12 c-input-box pb-4 position-relative">
                            <Form.Label className="input-label no-asterisk">
                              {_l("l_birth_date")}
                            </Form.Label>
                            <div
                              className="custom-datepicker WithFixWidthAndSelectYear react-datepicker-wrapper-w-100"
                              data-bs-toggle="tooltip"
                              title={moment(birthDate).format("dd/mm/yyyy")}
                            >
                              <div className="d-flex align-items-center form-control  color-white-60 dropdown-center dropdown">
                                <CalendarBlank
                                  size={18}
                                  weight="light"
                                  className="c-icons opacity-60 m-0 me-2"
                                />
                                <DatePicker
                                  portalId="custom_datepicker"
                                  calendarClassName="custom-datepicker"
                                  selected={birthDate}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  locale={getDatePickerLocale()}
                                  onChange={(date) => setBirthDate(date)}
                                  placeholderText={`${_l(
                                    "l_birth_date_placeholder"
                                  )}`}
                                  dateFormat="dd/MM/yyyy"
                                  selectsStart
                                  showYearDropdown
                                  maxDate={minBirthDate}
                                  calendarStartDay={1}
                                ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                              </div>
                            </div>
                          </Form.Group>

                          <div className="col-12 c-input-box pb-3 position-relative">
                            <label className="input-label no-asterisk ">
                              {_l("l_front_side")}
                            </label>
                            <div className="upload-inputbox position-relative ">
                              <div className="d-flex align-items-center">
                                <div className="position-relative flex-grow-1">
                                  <input
                                    className="  hidden-input"
                                    onChange={selectFrontFile}
                                    type="file"
                                  />
                                  <input
                                    className="form-control"
                                    type="text"
                                    disabled
                                  />
                                  <Paperclip
                                    size={18}
                                    weight="light"
                                    className="c-icons clip-img"
                                  />
                                  <div className="uploadbox-text">
                                    {_l("l_upload_file")}
                                  </div>
                                </div>

                                <div className="upload-image-preview d-flex">
                                  {frontSideImagePreviews &&
                                  frontSideImagePreviews.length ? (
                                    frontSideImagePreviews.map(
                                      (preview, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="d-flex align-items-center p-2"
                                          >
                                            <div className="comman-image-box h45w45 radius_3 upload-image">
                                              <div
                                                className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                                                style={{
                                                  backgroundImage: `url('${preview.url}')`,
                                                }}
                                              ></div>
                                              <a href="#/"
                                                className="upload-close"
                                                onClick={() => {
                                                  removeImageSelection(
                                                    preview.name,
                                                    "init"
                                                  );
                                                }}
                                              >
                                                <X
                                                  size={14}
                                                  weight="light"
                                                  className="c-icons"
                                                />
                                              </a>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 c-input-box pb-3 position-relative">
                            <label className="input-label no-asterisk ">
                              {_l("l_back_side")}
                            </label>
                            <div className="upload-inputbox position-relative ">
                              <div className="d-flex align-items-center">
                                <div className="position-relative flex-grow-1">
                                  <input
                                    className="hidden-input"
                                    onChange={selectBackFile}
                                    type="file"
                                  />
                                  <input
                                    className="form-control"
                                    type="text"
                                    disabled
                                  />
                                  <Paperclip
                                    size={18}
                                    weight="light"
                                    className="c-icons clip-img"
                                  />
                                  <div className="uploadbox-text">
                                    {_l("l_upload_file")}
                                  </div>
                                </div>
                                <div className="upload-image-preview d-flex">
                                  {backSideImagePreviews &&
                                  backSideImagePreviews.length ? (
                                    backSideImagePreviews.map(
                                      (preview, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="d-flex align-items-center p-2"
                                          >
                                            <div className="comman-image-box h45w45 radius_3 upload-image">
                                              <div
                                                className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                                                style={{
                                                  backgroundImage: `url('${preview.url}')`,
                                                }}
                                              ></div>
                                              <a href="#/"
                                                className="upload-close"
                                                onClick={() => {
                                                  removeImageSelection(
                                                    preview.name
                                                  );
                                                }}
                                              >
                                                <X
                                                  size={14}
                                                  weight="light"
                                                  className="c-icons"
                                                />
                                              </a>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    fromTopicSettingModal
                    ?
                    <></>
                    :
                    <div className="comman-content-bottom-btn">
                      <div className="d-flex justify-content-between">
                        <a href="#/"
                          className="btn btn-secondary btn-sm"
                          onClick={() => {
                            setSelectedTab("payment-step-2");
                          }}
                        >
                          {_l("l_back")}
                        </a>

                        <a href="#/" className="btn btn-primary btn-sm" onClick={fieldValidations}>
                          {_l("l_next")}
                        </a>
                      </div>
                    </div>
                  }
                </div>
                <div className={`h-100 ${selectedTab !== "postal-fields" ? "d-none" : ""}`}>
                  <div className="comman-content-scroll-wrapper">
                    <div className="comman-content-scroll h-100 overflow-hiiden-auto res-overflow-visible">
                      <div className="row justify-content-center">
                        <div className="col-xl-12">
                          <div className="row">
                            <Form.Group className={`c-input-box pb-4 position-relative ${fromTopicSettingModal ? "col-xl-12" : "col-xl-6"}`}>
                              <Form.Label className="input-label no-asterisk">
                                {_l("l_address")}
                              </Form.Label>
                              <Form.Control
                                placeholder={`${_l("l_address_placeholder")}`}
                                type="text"
                                name=""
                                ref={countryInputRef}
                                disabled={!providerCountry}
                                value={providerAddress}
                                onChange={(e) => {
                                  setProviderAddress(e.target.value);
                                }}
                              />
                            </Form.Group>
                            <Form.Group className="col-xl-6 c-input-box pb-4 position-relative ">
                              <Form.Label className="input-label no-asterisk">
                                {_l("l_city")}
                              </Form.Label>
                              <Form.Control
                                placeholder={`${_l("l_city_placeholder")}`}
                                type="text"
                                name=""
                                value={providerCity}
                                ref={cityInputRef}
                                disabled={!providerCountry}
                                onChange={(e) => {
                                  const reg = new RegExp("^[a-zA-Z]+$");
                                  const inputValue = e.target.value;
                                  if (reg.test(inputValue) || inputValue === "")
                                    setProviderCity(e.target.value);
                                }}
                              />
                            </Form.Group>
                            <Form.Group className="col-xl-6 c-input-box pb-4 position-relative">
                              <Form.Label className="input-label no-asterisk">
                                {_l("l_postal_code")}
                              </Form.Label>
                              <Form.Control
                                placeholder={`${_l(
                                  "l_postal_address_placeholder"
                                )}`}
                                type="text"
                                name=""
                                value={providerPostalCode}
                                onChange={(e) => {
                                  const reg = new RegExp("^[0-9]+$");
                                  const inputValue = e.target.value;
                                  if (
                                    (reg.test(inputValue) ||
                                      inputValue == "") &&
                                    inputValue.length < 7
                                  )
                                    setProviderPostalCode(e.target.value);
                                }}
                              />
                            </Form.Group>
                            <Form.Group className="col-xl-6 c-input-box pb-4 position-relative">
                              <Form.Label className="input-label no-asterisk ">
                                {_l("l_country")}
                              </Form.Label>
                              <div className="d-flex flex-grow-1 align-items-center">
                                <Select
                                  placeholder={`${_l(
                                    "l_select_birth_country"
                                  )}`}
                                  className="custom-select-menu w-100"
                                  classNamePrefix="react-select"
                                  closeMenuOnSelect={true}
                                  options={countryListing}
                                  onChange={(val) => {
                                    setproviderCountry(val);
                                    setProviderCountryLable(val.value);
                                    setProviderCity("");
                                    setProviderAddress("");
                                  }}
                                  value={providerCountry}
                                />
                              </div>
                            </Form.Group>
                            <Form.Group className={` c-input-box pb-4 position-relative ${fromTopicSettingModal ? "col-xl-6" : "col-xl-12"}`}>
                              <Form.Label className="input-label no-asterisk">
                                {_l("l_phone_number")}
                              </Form.Label>
                              <Form.Control
                                placeholder={`${_l(
                                  "l_phone_number_placeholder"
                                )}`}
                                type="text"
                                name=""
                                value={phoneNumber}
                                onChange={(e) => {
                                  const reg = new RegExp("^[+0-9 ]+$");
                                  const inputValue = e.target.value;
                                  if (reg.test(inputValue) || inputValue == "")
                                    setPhoneNumber(e.target.value);
                                }}
                              />
                            </Form.Group>
                            <Form.Group className="col-xl-6 c-input-box pb-4 position-relative">
                              <Form.Label className="input-label no-asterisk">
                                {_l("l_first_name")}
                              </Form.Label>
                              <Form.Control
                                placeholder={`${_l(
                                  "l_first_name_placeholder"
                                )}`}
                                type="text"
                                name=""
                                value={firstName}
                                onChange={(e) => {
                                  setFirstName(e.target.value);
                                }}
                              />
                            </Form.Group>
                            <Form.Group className="col-xl-6 c-input-box pb-4 position-relative ">
                              <Form.Label className="input-label no-asterisk">
                                {_l("l_last_name")}
                              </Form.Label>
                              <Form.Control
                                placeholder={`${_l("l_last_name_placeholder")}`}
                                type="text"
                                name=""
                                value={lastName}
                                onChange={(e) => {
                                  setLastName(e.target.value);
                                }}
                              />
                            </Form.Group>
                            <Form.Group className="col-xl-6 c-input-box pb-4 position-relative ">
                              <Form.Label className="input-label no-asterisk">
                                {_l("l_email")}
                              </Form.Label>
                              <Form.Control
                                placeholder={`${_l("l_email_placeholder")}`}
                                type="text"
                                value={email}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  setEmail(inputValue);
                                }}
                              />
                            </Form.Group>
                            <Form.Group className="col-xl-6 c-input-box pb-4 position-relative ">
                              <Form.Label className="input-label no-asterisk">
                                {_l("l_company_name")}
                              </Form.Label>
                              <Form.Control
                                placeholder={`${_l(
                                  "l_company_name_placeholder"
                                )}`}
                                type="text"
                                name=""
                                value={company}
                                onChange={(e) => {
                                  setCompany(e.target.value);
                                }}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      fromTopicSettingModal
                      ?
                      <></>
                      :
                      <div className="comman-content-bottom-btn">
                        <div className="d-flex justify-content-between">
                          <a href="#/"
                            className={`btn btn-secondary btn-sm ${
                              formSubmitLoader ? "for-disabled" : ""
                            }`}
                            onClick={() => {
                              if (!formSubmitLoader) {
                                setSelectedTab("payment-step-3");
                              }
                            }}
                          >
                            {_l("l_back")}
                          </a>
                          <a href="#/"
                            className={`btn btn-primary btn-sm ${formSubmitLoader ? "btn-disabled" : ""}`}
                            onClick={() => {
                              fieldValidations();
                            }}
                          >
                            {formSubmitLoader ? (
                              <>
                                <span
                                  className="mx-1 spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                {_l("l_please_wait")}
                              </>
                            ) : (
                              autoSubScribe ? _l("l_create_wallet_and_upgrade_plan") :  _l("l_finish")
                            )}
                          </a>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              {selectedTab === "info-modal" ? (
                <React.Fragment>
                  <MessagePlaceHolderScreen description={"l_payment_setup_message_for_provider"} image={{name: Info}} title={"l_you_are_almost_done"} />
                  {
                    fromTopicSettingModal
                    ?
                    <></>
                    :
                    <div className="comman-content-bottom-btn">
                      <div className="d-flex justify-content-end">
                        <a href="#/" className="btn btn-primary btn-sm" onClick={() => {setSelectedTab("wallet-settings")}}>{_l("l_next")}</a>
                      </div>
                    </div>
                  }
                </React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default PaymentPageMain;
