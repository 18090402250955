import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MicrophoneSlash, Microphone, PhoneDisconnect, PhoneX, Phone, Voicemail, Square } from "phosphor-react";
import { _l } from "../../hooks/utilities";
import jquery from "jquery";
import ReactTooltip from "react-tooltip";
import { showMessage } from "../../actions/messages";
import peer from "../../actions/peer";
import ReactPlayer from "react-player";
import ChatServices from "../../services/chat-services";

import {
    endCall,
    muteCall,
    unMuteCall,
    acceptCall,
    setRemoteStream,
    negoNeeded,
    callNotRespond,
    callAction,
    sendMessage,
    sendCandidates
} from "../../actions/chat";

import {
    ATTACH_BASE_URL,
    USR_IMG_PLACEHOLDER,
} from "../../actions/chat-action-type";

const AudioCall = ({ callStatus }) => {
    const dispatch = useDispatch();
    const [isMute, setIsMute] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [isOtherRecording, setIsOtherRecording] = useState(false);
    const [isCallMute, setIsCallMute] = useState(false);
    const [callImage, setCallImage] = useState(USR_IMG_PLACEHOLDER);
    const [callName, setCallName] = useState("");
    const [myCallRole, setMyCallRole] = useState("");
    const [myCallStatus, setMyCallStatus] = useState("");
    const [timerInterval, setTimerInterval] = useState(0);
    const mediaRecorder = useRef(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const [audio, setAudio] = useState(null);
    const mimeType = "audio/wav";
    var recIceCandidates = [];

    var projectId = localStorage.getItem("selectedOffice");

    const { myStream, remoteStream } = useSelector((state) => state.chat);
    const { globalView } = useSelector((state) => state.customer);

    var startTime = 0;
    var elapsedTime = 0;

    useEffect(() => {
        peer.peer.addEventListener("track", async (ev) => {
            const remoteStreams = ev.streams;
            dispatch(setRemoteStream(remoteStreams[0]));
        });
        peer.peer.addEventListener("negotiationneeded", async () => {
            if(!jquery.isEmptyObject(callStatus)){
                // if(callStatus.status == "inProgress"){
                    dispatch(negoNeeded({
                        userId: callStatus.callerInfo._id,
                        receiverId: callStatus.receiverInfo._id,
                        callRole: callStatus.callRole
                    }));
                // }
            }
        });
        peer.peer.onicecandidate = evt => {
            var iceCandidate = evt.candidate;
            if(iceCandidate){
                recIceCandidates.push(iceCandidate);
            }
            setTimeout(() => {
                dispatch(sendCandidates({
                    userId: callStatus.callerInfo._id,
                    receiverId: callStatus.receiverInfo._id,
                    callRole: callStatus.callRole,
                    candidates: recIceCandidates
                }));
            }, 300);
        };
    }, [callStatus]);

    useEffect(() => {
        if(myStream && myStream.getTracks && peer){
            try{
                for (const track of myStream.getTracks()) {
                    peer.peer.addTrack(track, myStream);
                }
            }catch(e){
                console.error(e);
            }
        }
    }, [myStream]);

    useEffect(() => {
        if(!jquery.isEmptyObject(callStatus)){
            if(callStatus.status == "notResponding"){
                autoEndTheCall();
            }else{
                setMyCallStatus(callStatus.status);
                setMyCallRole(callStatus.callRole);
                if(callStatus.callRole == "caller"){
                    if(callStatus.receiverInfo){
                        if(callStatus.receiverInfo.displayPicture != ""){
                            setCallImage(encodeURI(ATTACH_BASE_URL+callStatus.receiverInfo.displayPicture));
                        }else{
                            setCallImage(USR_IMG_PLACEHOLDER);
                        }
                        setCallName(callStatus.receiverInfo.fname+" "+callStatus.receiverInfo.lname);
                        setIsCallMute(callStatus.receiverInfo.isMute ? true : false);
                        setIsOtherRecording(callStatus.receiverInfo.isRecording ? true : false);
                    }
                }else{
                    if(callStatus.callerInfo){
                        if(callStatus.callerInfo && callStatus.callerInfo.displayPicture != ""){
                            setCallImage(encodeURI(ATTACH_BASE_URL+callStatus.callerInfo.displayPicture));
                        }else{
                            setCallImage(USR_IMG_PLACEHOLDER);
                        }
                        setCallName(callStatus.callerInfo.fname+" "+callStatus.callerInfo.lname);
                        setIsCallMute(callStatus.callerInfo.isMute ? true : false);
                        setIsOtherRecording(callStatus.callerInfo.isRecording ? true : false);
                    }
                }
            }
        }else{
            if(isRecording){
                recordAction("stop");
            }
            if(isOtherRecording){
                setIsOtherRecording(false);
            }
        }
    }, [JSON.stringify(callStatus)]);

    const disconnectCall = () => {
        if(callStatus.status == "initCall" && callStatus.callRole == "caller"){
            autoEndTheCall(false);
        }else{
            dispatch(endCall({
                userId: callStatus.callerInfo._id,
                receiverId: callStatus.receiverInfo._id,
                callRole: callStatus.callRole
            }));
        }
        if(isRecording){
            recordAction("stop");
        }
        if(isOtherRecording){
            setIsOtherRecording(false);
        }
    };

    const muteTheCall = async () => {
        myStream.getAudioTracks()[0].enabled = false;
        setIsMute(true);
        dispatch(muteCall({
            userId: callStatus.callerInfo._id,
            receiverId: callStatus.receiverInfo._id,
            callRole: callStatus.callRole,
        }));
    };

    const unMuteTheCall = () => {
        myStream.getAudioTracks()[0].enabled = true;
        setIsMute(false);
        dispatch(unMuteCall({
            userId: callStatus.callerInfo._id,
            receiverId: callStatus.receiverInfo._id,
            callRole: callStatus.callRole,
        }));
    };

    const acceptTheCall = () => {
        dispatch(acceptCall({
            userId: callStatus.callerInfo._id,
            receiverId: callStatus.receiverInfo._id,
            offer: callStatus.offer
        }));
    };

    const autoEndTheCall = (showMsg = true) => {
        if(showMsg){
            dispatch(showMessage("unsucess", _l("l_call_status"),  _l("l_not_responding")));
        }
        dispatch(callNotRespond({
            userId: callStatus.callerInfo._id,
            receiverId: callStatus.receiverInfo._id,
            callRole: callStatus.callRole,
            projectId: projectId,
            groupId: callStatus.extraData.groupId
        }));
        if(isRecording){
            recordAction("stop");
        }
        if(isOtherRecording){
            setIsOtherRecording(false);
        }
    };

    let pageName = window.location.pathname
    const doCallAction = (actionKey, actionVal) => {
        dispatch(callAction({
            userId: callStatus.callerInfo._id,
            receiverId: callStatus.receiverInfo._id,
            callRole: callStatus.callRole,
            actionKey: actionKey,
            actionVal: actionVal
        }));
        if(actionKey == "record"){
            recordAction(actionVal);
        }
    };

    const recordAction = (actionVal) => {
        if(actionVal == "start"){
            startTime = Date.now() - elapsedTime;
            setTimerInterval(setInterval(updateStopwatch, 1000));
            // Record media
            const media = new MediaRecorder(remoteStream, { type: mimeType });
            mediaRecorder.current = media;
            mediaRecorder.current.start();
            let localAudioChunks = [];
            mediaRecorder.current.ondataavailable = (event) => {
                if (typeof event.data === "undefined") return;
                if (event.data.size === 0) return;
                localAudioChunks.push(event.data);
            };
            setAudioChunks(localAudioChunks);
        }else{
            clearInterval(timerInterval);
            startTime = 0;
            elapsedTime = 0;
            // Stop recording
            mediaRecorder.current.stop();
            mediaRecorder.current.onstop = () => {
                //creates a blob file from the audiochunks data
                const audioBlob = new Blob(audioChunks, { type: mimeType });
                //creates a playable URL from the blob file.
                const audioUrl = URL.createObjectURL(audioBlob);
                setAudio(audioUrl);
                setAudioChunks([]);
                if(callStatus && callStatus.extraData && callStatus.extraData.groupId != ""){
                    // Send this recorded audio to chat group
                    const currentTS = Math.floor(Date.now() / 1000);
                    const recordedFile = new File([audioBlob], "Call Record ("+callName+") - "+currentTS+".wav", { type: mimeType });
                    var callGroupId = callStatus.extraData.groupId;
                    ChatServices.uploadChatMedia(recordedFile).then((res) => {
                        if (res.status == 1) {
                          var msgData = {
                            groupId: callGroupId,
                            userId: localStorage.getItem("chatUserId"),
                            message: res.data.filename,
                            type: "audio",
                            caption: "⏺️ Call Recording ("+callName+")"
                          };
                          dispatch(sendMessage(msgData));
                        }else{
                          dispatch(showMessage("unsucess", _l("l_error"),res.message));
                        }
                    });
                }
            };
        }
        setIsRecording(actionVal == "start" ? true : false);
    };

    // Function to format time in HH:MM:SS format
    function formatTime(milliseconds) {
        // const hours = Math.floor(milliseconds / 3600000);
        const minutes = Math.floor((milliseconds % 3600000) / 60000);
        const seconds = Math.floor((milliseconds % 60000) / 1000);
        return (
            // (hours < 10 ? "0" : "") + hours + ":" +
            (minutes < 10 ? "0" : "") + minutes + ":" +
            (seconds < 10 ? "0" : "") + seconds
        );
    }

    // Function to update the stopwatch display
    function updateStopwatch() {
        const currentTime = Date.now();
        elapsedTime = currentTime - startTime;
        if(document.getElementById("stopwatch")){
            document.getElementById("stopwatch").textContent = formatTime(elapsedTime);
        }
    }

    return (
        <>
            <div className="audio-call-main box-shadow-2">
                {callStatus && callStatus.extraData && (pageName != "/aichat" && globalView != "AichatView") && (callStatus.extraData.projectName != "" || callStatus.extraData.taskName != "") ?
                    <div className="call-task mb-2 hr_1 pb-1" >
                        <div className={`task-name c-font f-12 fw-semibold title-fonts text-truncate mb-1 ${callStatus.extraData.projectName == "" ? "d-none" : ""}`}>{callStatus.extraData.projectName}</div>
                        <div className={`badge rounded-pill text-truncate ${callStatus.extraData.taskName == "" ? "d-none" : ""}`}>{callStatus.extraData.taskName}</div>
                    </div>
                    : callStatus && callStatus.receiverInfo && callStatus.receiverInfo._id && callStatus.callerInfo && callStatus.callerInfo._id ? <>
                    <div className="call-task mb-2 hr_1 pb-1" >
                        <div className={`task-name c-font f-12 fw-semibold title-fonts text-truncate mb-1`}>{callStatus.receiverInfo._id == localStorage.getItem("orgChatUserId") ? callStatus.callerInfo.fname + " " + callStatus.callerInfo.lname : callStatus.receiverInfo.fname + " " + callStatus.receiverInfo.lname  }</div>
                    </div>
                </> : <></>
                }
                <div className="h-100 audio-call-body">
                    <div className="call-section">
                        <div className="d-flex justify-content-center align-items-start">
                            <div className="align-items-center d-flex h30w30 mx-2 rounded-circle">
                                <div 
                                    className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle position-relative" 
                                    style={{ backgroundImage: `url(${callImage})` }}
                                    data-tip={callName}
                                    data-effect="solid"
                                    data-delay-show='1000'
                                    data-class="tooltip-main"
                                >
                                    {myCallStatus == "initCall" ?   
                                        <div class="call-ring-circle"></div>
                                    : <></>}
                                    {myCallStatus == "inProgress" ?   
                                    <span className="badge-custom bg-white-05 d-flex light-pill rounded-pill">
                                        {!isCallMute ?   
                                            <div className="position-absolute" >
                                                <Microphone size={14} weight="light" className="c-icons" />
                                            </div>
                                        :   <div className="position-absolute">
                                                <MicrophoneSlash size={14} weight="light" className="c-icons" />
                                            </div>
                                        }
                                    </span>
                                    : <></>}
                                </div>
                            </div>
                            <div className="align-items-center d-flex flex-column justify-content-center">
                            {myCallStatus == "inProgress" ?
                            <a href="#/" 
                                className={`align-items-center d-flex h35w35 comman-round-box with-bg rounded-circle mx-2 ${isRecording ? "bg-red" : "bg-white-05"}`}
                                onClick={() => { doCallAction("record", isRecording ? "stop" : "start"); }} 
                                data-tip={isRecording ? _l("l_stop_recording") : _l("l_start_recording")} data-effect="solid"
                                data-delay-show='1000' data-class="tooltip-main"
                            >
                                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle d-flex">
                                    {isRecording ? <Square size={14} weight="fill" className="c-icons" />
                                    : <Voicemail size={14} weight="light" className="c-icons" /> }
                                </div>
                            </a>
                            : <></> }
                             {isRecording ? <div className="c-font f-11 title-fonts fw-light mt-2" id="stopwatch"></div> : <></>}
                            </div>
                            <a href="#/" className={`align-items-center d-flex h35w35 comman-round-box with-bg rounded-circle  mx-2 ${isMute ? "bg-red" : "bg-white-05"}`}>
                                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle d-flex">
                                    {isMute ?   
                                        <div className="d-flex h-100 w-100" data-tip={_l("l_unmute_mic")} data-effect="solid"
                                        data-delay-show='1000' data-class="tooltip-main" onClick={() => { unMuteTheCall(); }} >
                                            <MicrophoneSlash size={18} weight="light" className="c-icons" />
                                        </div>
                                    :   <div className="d-flex h-100 w-100" data-tip={_l("l_mute_mic")} data-effect="solid"
                                    data-delay-show='1000' data-class="tooltip-main" onClick={() => { muteTheCall(); }} >
                                            <Microphone size={18} weight="light" className="c-icons" />
                                        </div>
                                    }
                                </div>
                            </a>
                            {myCallRole == "receiver" &&  myCallStatus == "initCall" ?
                                <a href="#/" className="align-items-center d-flex h35w35 comman-round-box with-bg rounded-circle bg-green mx-2" onClick={() => { acceptTheCall(); }}>
                                    <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle d-flex">
                                        <Phone size={18} weight="light" className="c-icons black-l-white lt-black" />
                                    </div>
                                </a>
                            :   <></>
                            }
                            {myCallStatus == "initCall" || myCallStatus == "inProgress" ?   
                                <a href="#/" className="align-items-center d-flex h35w35 comman-round-box with-bg rounded-circle bg-red  mx-2" onClick={() => { disconnectCall(); }}>
                                    <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle d-flex">
                                        <PhoneX size={18} weight="light" className="c-icons" />
                                    </div>
                                </a>
                            :   <a href="#/" className="align-items-center d-flex h35w35 comman-round-box with-bg rounded-circle bg-white-05 mx-2">
                                    <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle d-flex">
                                        <PhoneDisconnect size={18} weight="light" className="c-icons" />
                                    </div>
                                </a>   
                            }
                            {/* <a href="#/" className={`align-items-center d-flex h35w35 `}>
                                <X size={18} weight="light" className="c-icons" />
                            </a> */}
                            {myStream ? 
                                <ReactPlayer
                                    playing
                                    // muted
                                    url={remoteStream}
                                    controls={true}
                                    className="d-none"
                                />
                            : <></>}
                        </div>
                       
                        {isOtherRecording ? <div className="text-center c-font f-11 title-fonts fw-bold mt-2">{`${_l("l_this_call_is_recorded_by")}`} : {callName}</div> : <></>}
                    </div>
                </div>
            </div>
            <ReactTooltip />
        </>
    );
};
export default AudioCall;