import React, { useEffect, useState } from "react";
import { _l } from "../../hooks/utilities";
import ChecklistComponent from "./ChecklistComponent";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import Lightbox from "yet-another-react-lightbox";
import FileUploadComponent from "./FileUploadComponent";
import { useSelector } from "react-redux";
import { Funnel, MagnifyingGlass, X } from "phosphor-react";
function RequestTaskBodyCommonModal({ data, isExternal,showFullscreen=false, iscallModal}) {
    const [selectedTab, setSelectedTab] = useState("checklists");
    const [ShowCommentModal, setShowCommentModal] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [attachmentList, setAttachmentList] = useState([]);
    const [selectedTask, setSelectedTask] = useState("checklist");
    const [openGallery, setOpenGallery] = useState(false);
    const [taskId, setTaskId] = useState(0);
    const projectId = localStorage.getItem('selectedOffice')
    const [convertedTaskId, setConvertedTaskId] = useState(0);
    const [checklistFilter, setChecklistFilter] = useState(["all"]);
    const [actionMenuItems, setActionMenuItems] = useState([]);

    const {floorDetails} = useSelector((state) => state.benchmarkReducer);

    const setAttchmentOnTop = (id) => {
        const targetAttchement = attachmentList.filter(
            (attachment) => attachment.id === id
        );
        const otherAttachments = attachmentList.filter(
            (attachment) => attachment.id != id
        );
        setAttachmentList(targetAttchement.concat(otherAttachments));
    };

    const checkListFilter = (value) => {
        let filterArr = checklistFilter;
        let index = filterArr.findIndex((f) => f == value);
        if (index > -1) {
          filterArr = filterArr.filter((c) => c != value);
        } else {
          filterArr = [value];
        }
        if(!filterArr.length)
        {
          filterArr.push("all");
        }
        setChecklistFilter(filterArr.slice());
      }

    useEffect(() => {
        if (Object.keys(data).length) {
            // setTaskId(data.task_id ? data.task_id : 0)
            setTaskId(data.id ? data.id : data.task_id)
            setSelectedTask(data)
            setConvertedTaskId(data.request_task_id ? data.request_task_id : 0)
            setAttachmentList(data.atttachments)
        }
    }, [JSON.stringify(data)])

    useEffect(() => {
        let actionMenu = [
         // {
         //   actionName: _l("l_checked"),
         //   actionIcon: "CheckIcon",
         //   actionHandler: () => {checkListFilter("checked")},
         //   active : checklistFilter.includes("checked")
         // },
         // {
         //   actionName: _l("l_not_checked"),
         //   actionIcon: "SquareIcon",
         //   actionHandler: () => {checkListFilter("notchecked")},
         //   active : checklistFilter.includes("notchecked")
         // },
         {
           actionName: _l("l_todo"),
           actionIcon: "CircleFillTodoIcons",
           actionHandler: () => {checkListFilter("todo")},
           active : checklistFilter.includes("todo")
         },
         {
           actionName: _l("l_inprogress"),
           actionIcon: "CircleFillInprogressIcons",
           actionHandler: () => {checkListFilter("inprogress")},
           active : checklistFilter.includes("inprogress")
         },
         {
           actionName: _l("l_done"),
           actionIcon: "CircleFillDoneIcons",
           actionHandler: () => {checkListFilter("done")},
           active : checklistFilter.includes("done")
         },
         {
           actionName: _l("l_priority"),
           actionIcon: "HighPriorityActiveIcon",
           actionHandler: () => {checkListFilter("priority")},
           active : checklistFilter.includes("priority")
         }
       ];
   
       if(floorDetails.floor.length > 1)
       {
         actionMenu = [{
           actionName: _l("l_floor_filter"),
           actionIcon: "StepIcon",
           actionHandler: () => {checkListFilter("floor")},
           active : checklistFilter.includes("floor")
         }].concat(actionMenu);
       }
   
       actionMenu = [{
         actionName: _l("l_all"),
         actionIcon: "SelectionAll",
         actionHandler: () => {checkListFilter("all")},
         active : checklistFilter.includes("all")
       },].concat(actionMenu);
   
       setActionMenuItems(actionMenu);
     }, [checklistFilter]);

    return (
        <>
            {
                attachmentList && attachmentList.length ? 
                (<Lightbox
                    open={openGallery}
                    close={() => setOpenGallery(false)}
                    slides={attachmentList && attachmentList.map((img) => {
                        return { src: img.image_url };
                    })}
                />) : <></>
            }
            
            <div className="tab-wrapper_main pb-3 res-overflow-auto d-none">
                <div className="d-flex align-items-center justify-content-between border-bottom">
                    <div className="comman-tab-wrapper pb-0 w-100">
                    <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <div
                                    className={` tab-wrapper  for-accessible
                                    ${selectedTab == "checklists" ? "active" : ""
                                        } `}
                                >
                                    <a href="#/"
                                        className="tab-name"
                                        onClick={() => {
                                            setSelectedTab("checklists");
                                        }}
                                    >
                                        {_l("l_request")}
                                    </a>
                                </div>
                                <div
                                    className={`tab-wrapper  for-accessible
                                    ${selectedTab == "files" ? "active" : ""
                                        } `}
                                >
                                    <a href="#/"
                                        className="tab-name"
                                        onClick={() => {
                                            setSelectedTab("files");
                                        }}
                                    >
                                        {_l("l_files")}
                                    </a>
                                </div>
                            </div>
                            {selectedTab === "checklists" ? (
                                <React.Fragment>
                                    <div
                                        className="switch d-none"
                                        data-tip={`${_l("l_toggle_detail")}`}
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main"
                                    >
                                        <div className="d-flex align-items-center">
                                            <div className="c-font f-10 color-white-60 pe-2">
                                                {/* <div className="result">
                Above checkbox is
                {isChecked ? "checked" : "un-checked"}.
                </div> */}
                                            </div>
                                            <div className="label-text">
                                                <div className="d-flex align-items-center">
                                                    <div className="form-check form-switch d-flex justify-content-end align-items-center">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            role="switch"
                                                            checked={isChecked}
                                                            onChange={() => {
                                                            setIsChecked(!isChecked);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex radius_3 justify-content-between">
                                        <div className="dropstart">
                                            <a href="#/" className={`comman_action_icon  ${checklistFilter.length > 0 ? "iconactive" : ""}`}
                                            type="button"
                                            id="commentOptionDropdown"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            data-tip={_l("l_options")}
                                            data-effect="solid"
                                            data-delay-show='1000'
                                            data-class="tooltip-main"
                                            >
                                                <div className="d-flex align-items-center ">
                                                <div className="action_icon h25w25 on-hover-active-toggle-img">
                                                    <Funnel
                                                    size={14}
                                                    className="c-icons"
                                                    weight="light"
                                                    // color={`${checklistFilter.length > 0 ? "#00ff6a" : "#1d3545"}`}
                                                    />
                                                </div>
                                                </div>
                                            </a>
                                            <ActionDropdown
                                                actions={actionMenuItems}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`tast-detail-tab-wrapper res-height-auto d-flex flex-column ${ShowCommentModal ? "" : "h-125"}`}>
                <div className=" h-100 overflow-hiiden-auto d-flex flex-column">
                {selectedTab == "files" ? (
                    <div className="h-100  overflow-hiiden-auto">
                        {/* <ChecklistComponent
                            projectId={projectId}
                            task_id={taskId}
                            isChecked={isChecked}
                            setIsChecked={setIsChecked}
                            // setRequestChecklist={setRequestChecklist}
                            isSystemTask={
                                (selectedTask &&
                                    selectedTask.addedfrom == 0 &&
                                    selectedTask.schedule_id &&
                                    parseInt(selectedTask.schedule_id) > 0) ||
                                    convertedTaskId > 0
                                    ? true
                                    : null
                            }
                            isRequestTask="1"
                            toastClose={() => { }}
                            setCheckListCount={setCheckListCount}
                            updateRequestTitle={updateRequestTitle}
                        /> */}
                        {/* <div className="tast-detail-tab-wrapper h-auto res-height-auto d-flex flex-column "> */}
                        <div class="color-white custom-accordian-Header-sticky with-top-46px p-2   my-2 radius_3 d-none">{_l('l_attachment_list')}</div>
                        <div
                            className={` d-flex flex-wrap  `}
                        >
                            {/* <span className="color-white-80 c-font f-12">
                        {description}
                          </span> */}


                            {/* {attachmentList.length ? (
                                    attachmentList.map((attachment, index) => {
                                        return (
                                            <div className="file-image-list" key={index}>
                                                <div className="c-list-icon w-100 position-relative with-overlay">
                                                    <div
                                                        className="h130w130 comman-round-box with-bg radius_3 with-react-fancybox"
                                                        onClick={() => {
                                                            setOpenGallery(true);
                                                            setAttchmentOnTop(attachment.id);
                                                        }}
                                                    >
                                                        <div
                                                            className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                                                            style={{
                                                                backgroundImage: `url('${attachment.image_url}')`,
                                                            }}
                                                        ></div>
                                                    </div>

                            {attachmentList.length ? (
                                attachmentList.map((attachment, index) => {
                                    return (
                                        <div className="file-image-list" key={index}>
                                            <div className="c-list-icon w-100 position-relative with-overlay">
                                                <div
                                                    className="h130w130 comman-round-box with-bg radius_3 with-react-fancybox"
                                                    onClick={() => {
                                                        setOpenGallery(true);
                                                        setAttchmentOnTop(attachment.id);
                                                    }}
                                                >
                                                    <div
                                                        className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                                                        style={{
                                                            backgroundImage: `url('${attachment.image_url}')`,
                                                        }}
                                                    ></div>
                                                </div>

                                                <a href="#/"
                                                    className={`line-height-0 file-action-option ${selectedTask &&
                                                        parseInt(
                                                            selectedTask.is_allowed_to_edit
                                                        ) == 1
                                                        ? convertedTaskId > 0
                                                            ? "d-none"
                                                            : ""
                                                        : attachment.is_allowed_to_edit == 1
                                                            ? ""
                                                            : "d-none"
                                                        }`}
                                                    type="button"
                                                    id="DownloadDeleteDropdown"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <DotsThreeVerticalIcon className="HW18" />
                                                </a>
                                                <ActionDropdown
                                                    actions={[
                                                        {
                                                            actionName: "Delete",
                                                            actionHandler: () => {
                                                                // setToastHeader(
                                                                //     _l("l_are_you_sure")
                                                                // );
                                                                // setToastClass("toast-with-btn");
                                                                // setToastMessage(
                                                                //     _l(
                                                                //         "l_you_want_to_delete_this_attachment"
                                                                //     )
                                                                // );
                                                                // setConfirmButtonText(
                                                                //     _l("l_delete")
                                                                // );
                                                                // setCancelButtonText(
                                                                //     _l("l_cancel")
                                                                // );
                                                                // setShowToast(true);
                                                                // setAttachmentId({
                                                                //     id: attachment.id,
                                                                //     comment_id:
                                                                //         attachment.comment_file_id,
                                                                // });
                                                            },
                                                            actionIcon: deleteIcon,
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <></>
                            )}

                            {attachmentList.length == 0 ? (
                                <div className="comman-placeholder-wrapper w-100 ">
                                    <div className="d-flex flex-column justify-content-center h-100 align-items-center m-auto">
                                        <img
                                            src={placeholder}
                                            alt="placeholder"
                                            className="big-image"
                                        />
                                        <span className="color-white-80 py-2 title-fonts">
                                            {_l("l_no_files")}
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )} */}



                        </div>
                        <FileUploadComponent
                            projectId={projectId}
                            task_id={taskId}
                            attachmentCountUpdate={() => { }}
                            toastClose={() => { }}
                            closeAllTost={() => { }}
                            searchAttchment={''}
                            isExternal={isExternal}
                            readOnly={true}
                            isConvertedTask={selectedTask.request_task_id && parseInt(selectedTask.request_task_id) > 0 ? true : false}
                            isRequestTask={true}
                            taskDetails={selectedTask}
                        />

                        {/* <div className=" mt-auto ">

                            <div
                                className={`comment-input-box form-control d-flex border-0 ${attachmentList.length ||
                                    convertedTaskId > 0
                                    ? "d-none"
                                    : "d-none"
                                    }`}
                            >
                                <input
                                    className="bg-transparent p-0 flex-grow-1 text-truncate height-45 border-0 "
                                    type="text"
                                    placeholder={`${_l(
                                        "l_write_description_placeholder"
                                    )}`}
                                    // value={newDescription}
                                    onChange={(e) => {
                                        // setNewDescription(e.target.value);
                                        // if (e.key == "Enter") {
                                        //   addDescriptionOrComment();
                                        // }
                                    }}
                                    disabled={convertedTaskId > 0 ? true : false}
                                />
                                <div className="upload-image-preview p-0">
                                    <div className="d-flex align-items-center justify-content-start">
                                        {imageWithDescriptionPreviews.length ? (
                                            imageWithDescriptionPreviews.map(
                                                (preview, index) => {
                                                    return (
                                                        <div
                                                            className="comman-image-box h25w25 radius_3 upload-image with-margin"
                                                            key={index}
                                                        >
                                                            <div
                                                                className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                                                                style={{
                                                                    backgroundImage: `url('${preview.url}')`,
                                                                }}
                                                            ></div>
                                                            <a href="#/"
                                                                className="upload-close"
                                                                onClick={() => {
                                                                    removeImageSelection(
                                                                        preview.name,
                                                                        "withDescription"
                                                                    );
                                                                }}
                                                            >
                                                                <XIcon className="HW10" />
                                                            </a>
                                                        </div>
                                                    );
                                                }
                                            )
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>

                                <div
                                    className={`comman_action_icon paper-clip-file ${attachmentList.length ||
                                        convertedTaskId > 0
                                        ? "d-none"
                                        : ""
                                        }
                          `}
                                >
                                    <div className="d-flex align-items-center">
                                        <a href="#/"
                                            className="action_icon with_bg position-relative"
                                            data-class="tooltip-main"
                                        >
                                            <input
                                                type="file"
                                                className="absolute-input"
                                                multiple
                                                accept="image/*"
                                                onChange={(event) => {
                                                    // if (event.target.files.length > 5) {
                                                    //   setToastClass("unsuccess");
                                                    //   setToastHeader(_l("l_error"));
                                                    //   setToastMessage(
                                                    //     _l(
                                                    //       "l_maximum_5_files_are_allowed_at_a_time"
                                                    //     )
                                                    //   );
                                                    //   setShowToast(true);
                                                    //   return;
                                                    // }
                                                    // setImageWithDescriptionBuffer([
                                                    //   ...imageWithDescriptionBuffer,
                                                    //   ...event.target.files,
                                                    // ]);
                                                    // preparePreview(
                                                    //   event.target.files,
                                                    //   "fromDescription"
                                                    // );
                                                }}
                                                disabled={
                                                    convertedTaskId > 0 ? true : false
                                                }
                                            />

                                            <PaperclipIcon className="HW18" />
                                        </a>
                                    </div>
                                </div>
                                <a href="#/"
                                    className={`send-arrow-btn ${description != "" ||
                                        attachmentList.length ||
                                        convertedTaskId > 0
                                        ? "d-none"
                                        : ""
                                        }`}
                                // onClick={addDescriptionOrComment}
                                >
                                    <ArrowRightIcon className="HW18 lt-black" />
                                </a>
                            </div>
                        </div> */}
                        {/* </div> */}
                    </div>
                ) : ( <>
                    <ChecklistComponent
                        projectId={projectId}
                        task_id={taskId}
                        isChecked={isChecked}
                        setIsChecked={setIsChecked}
                        setRequestChecklist={() => { }}
                        isSystemTask={
                            (selectedTask &&
                                selectedTask.addedfrom == 0 &&
                                selectedTask.schedule_id &&
                                parseInt(selectedTask.schedule_id) > 0) ||
                                convertedTaskId > 0
                                ? true
                                : null
                        }
                        isRequestTask="1"
                        toastClose={() => { }}
                        setCheckListCount={() => { }}
                        updateRequestTitle={() => { }}
                        checklistFilter={checklistFilter}
                        showFullscreen={showFullscreen}
                        setChecklistFilter={setChecklistFilter}
                        actionMenuItems={actionMenuItems}
                        data={data}
                        iscallModal={iscallModal}
                    />
                    <FileUploadComponent
                            projectId={projectId}
                            task_id={taskId}
                            attachmentCountUpdate={() => { }}
                            toastClose={() => { }}
                            closeAllTost={() => { }}
                            searchAttchment={''}
                            isExternal={isExternal}
                            readOnly={true}
                            isConvertedTask={selectedTask.request_task_id && parseInt(selectedTask.request_task_id) > 0 ? true : false}
                            isRequestTask={true}
                            taskDetails={selectedTask}
                        />
                    </>
                )}
                </div>
            </div>
        </>


    )
}

export default RequestTaskBodyCommonModal