import React, { useEffect, useState } from "react";
import { _l } from "../../../hooks/utilities";
import Form from "react-bootstrap/Form";
import { X,CloudArrowUp,Info} from "phosphor-react";

const FilesComponent = () => {
  return (
    <>
        <Form.Group className=" c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                  {_l('l_invoice_upload')}
                  </Form.Label>
                  <div className="upload-inputbox position-relative with-bg-height">
                      <a href="#/" className="h-100 d-block">
                        <input className="  hidden-input" type="file" />
                        <div className="upload-box-center  h-100 d-flex flex-column justify-content-center align-items-center">
                          <div className="upload-box-icon pb-2">
                          <CloudArrowUp size={26} className="c-icons" weight="light" />
                          </div>
                          <div className="upload-box-title title-fonts c-font f-14 color-white pb-1">
                            <span className="fw-semibold"> {_l('l_click_to_upload')} </span>
                            <span> {_l('l_or_drag_and_drop')}</span>
                          </div>
                          <div className="c-font color-white-60 f-12 upload-box-subtitle"> {_l('l_svg_png_jpg_gif')}</div>
                        </div>
                      </a>
                      <div className="upload-image-preview d-none">
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="comman-image-box h40w40 radius_3 upload-image">
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                              style={{
                                backgroundImage: `url('https://source.unsplash.com/random')`,
                              }}
                            ></div>
                            <a href="#/" className="upload-close">
                            <X size={14} className="c-icons" weight="light" />
                            </a>
                          </div>
                        </div>
                      </div>
                  </div>
                </Form.Group>
                <div className="upload-alert-text">
                  <Info size={16} color="#fca120" weight="light" />
                  <span className="color-orange ps-2"> {_l('l_if_you_need_to_pay_please_uplad_the_invoice_here')}</span>
                </div>
    </>
  )
}

export default FilesComponent