import React, { useEffect, useState } from "react";
import LoaderVideo from "../../assets/images/myr.ai-loader.gif";
const ProgressBarSmallLoader = ({height, width}) => {
  // const LoaderClass 	= (props.className) ? props.className : "";
  return (
    <>
      {/* <div className={`main-loader-wrapper dropdown-loader comman-main-loader-wrapper full-screen with-opacity ${LoaderClass}`} > */}
          <div className={`w-${width} h-${height} d-flex`}>
            <div className="loader-video">
              <img height={height} width={width} src="https://i0.wp.com/www.inspirefm.org/wp-content/themes/onair2-child/images/loader.gif?w=1170&ssl=1" alt="https://i0.wp.com/www.inspirefm.org/wp-content/themes/onair2-child/images/loader.gif?w=1170&ssl=1" />
            {/* <video  autostart="true" autoPlay="true" loop="true" src={LoaderVideo} type={LoaderVideo}/> */}
            </div>
          </div>
      {/* </div> */}
    </>
  );
};

export default ProgressBarSmallLoader;


//https://gifimage.net/wp-content/uploads/2018/04/loading-gif-transparent-background-bergerak.gif