import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommanOffCanvas from "./Offcanvas/CommanOffCanvas";
import { getTaskAttechments, getTaskChecklist } from "../actions/customer";
import ChecklistComponent from "./TaskModalComponents/ChecklistComponent";
import FileUploadComponent from "./TaskModalComponents/FileUploadComponent";
import CommentSection from "./Comments/CommentSection";
import customerServices from "../services/customer-services";
import ExternalChecklist from "./SolarExternalPages/SolarComponents/ExternalChecklist";
import FIleUpload from "./SolarExternalPages/SolarComponents/FIleUpload";
import ExternalComment from "./SolarExternalPages/SolarComponents/ExternalComment";
import { setComments } from "../actions/documents-actions/documents-actions";

const SolarTaskDetails = () => {
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [commentsList, setCommentsList] = useState([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [showCommentSection, setShowCommentSection] = useState(false);

  const dispatch = useDispatch();

  const selectedTask = useSelector((state) => state.customer.selectedTask);

  const checklistStateData = useSelector(
    (state) => state.customer.taskChecklist
  );
  const { mentionList } = useSelector((state) => state.customer);

  useEffect(() => {
    if (selectedTask && Object.keys(selectedTask).length > 0) {
      dispatch(
        getTaskChecklist(
          selectedTask.id,
          selectedTask.projectId,
          selectedTask.hash
        )
      );
        dispatch(getTaskAttechments(selectedTask.id, selectedTask.projectId, '', selectedTask.hash));
      
      customerServices.getComments(
        selectedTask.id,
        selectedTask.projectId,
        0,  
        0,
        1,
        0,
        "",
        selectedTask.hash,
        1,
        0,
        0
      ).then((res)=>{
        if (res && res.status) {
          setShowCommentSection(true)
          setCommentsList(res.data)
        }
      })
    } else {
      setShowTaskModal(false);
    }
  }, [JSON.stringify(selectedTask)]);

  return (
    <>
      <div className="flex-grow-1 d-flex flex-column position-relative h-100 pt-20px ">
        <div className="registration-footer-content pe-0 flex-grow-1 h-100 row">
          <div className="w_1120px registration-footer-content mx-auto h-100">
            <div className="d-flex flex-grow-1 gap10px h-100 pb-20px">
              <div className="d-flex  flex-grow-1  flex-column gap10px border">
              <div className=" overflow-hiiden-auto d-flex flex-column h-100 flex-grow-1 radius_3">
                <ExternalChecklist/>
                </div>
                <div className="mt-auto">
                  <FIleUpload/>
                </div>
              </div>
              <div className="border radius_3">
                <ExternalComment 
                commentsList={commentsList}
                setCommentsList={setCommentsList}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SolarTaskDetails;
