import React, { useEffect, useState } from "react";
import User2 from "../../assets/images/users/user_9.jpg";
import { Link, Eye, File } from "phosphor-react";
import ReactTooltip from "react-tooltip";
import { _l } from "../../hooks/utilities";
import {
  UserCircleGear,
  CalendarBlank,
  CaretDown,
  ChatCircle,
  PushPin,
  DotsThreeVertical,
  FileText,
} from "phosphor-react";
import User_2 from "../../assets/images/users/user_2.jpg";
import UncontrolledGeneralListing from "../TaskModalComponents/UncontrolledGeneralListing";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { useSelector } from "react-redux";
import googleDriveServices from "../../services/google-drive-services";

const DocumentsActivity = (data) => {
  const [activityList, setActivityList] = useState([]);

  const { selectedProject: projectId } = useSelector((state) => state.customer);
  useEffect(() => {
      getAcitivityList();
  }, []);

  useEffect(() => {
    if(data.data.provider === 'one_drive')
    {
      setActivityList(data.data.activities);
      
    }
  }, [data])
  const getAcitivityList = () => {
    googleDriveServices
      .getAcitivityList(
        data.data.is_synced ? data.data.channel_id : data.data.id,
        projectId
      )
      .then((response) => {
        if (response.status == 1) {
          setActivityList(response.data);
        } else {
          setActivityList([]);
        }
      });
  };
  return (
    <div
      style={{
        overflowY: "scroll",
        height: "100%",
      }}
    >
      <div className="comman-data-table small-data-table d-none">
        <table className="dataTable">
          <thead className="bg-transparent">
            <tr className="bg-white-03">
              <th style={{ width: "20%" }}>{_l("l_name")}</th>
              <th>{_l("l_item")}</th>
              <th className="px-1" style={{ width: "23%" }}>
                {_l("l_date")}
              </th>
              <th style={{ width: "5%" }} className="text-center">
                {_l("l_links")}
              </th>
              <th style={{ width: "5%" }} className="text-center">
                {_l("l_permissions")}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="">
              <td className="max-width-100 text-truncate border-bottom ps-3">
                <div className="d-flex align-items-center">
                  <div
                    className="comman-image-box h20w20 rounded-circle"
                    data-effect="solid"
                    data-delay-show='1000'
                    data-tip="Johmasd"
                  >
                    <div
                      className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                      style={{ backgroundImage: `url('${User2}')` }}
                    ></div>
                  </div>
                  <div className="text-truncate ps-2 w100minus20">Johmasd</div>
                </div>
              </td>

              <td className="border-bottom align-top max-width-100">
                <div className="d-flex align-items-center">
                  <File size={16} className="c-icons" weight="light" />
                  <div
                    className="text-truncate ps-2 w100minus20"
                    data-tip="File name"
                    data-effect="solid"
                    data-delay-show='1000'
                  >
                    Item Name
                  </div>
                </div>
              </td>
              <td className=" border-bottom align-top px-1">
                {" "}
                07 - Feb - 2023 08:29 PM
              </td>
              <td className=" border-bottom text-center align-top">
                <Link
                  size={16}
                  className="c-icons"
                  weight="light"
                  data-tip="Link"
                  data-effect="solid"
                  data-delay-show='1000'
                />
              </td>
              <td className=" border-bottom text-center align-top">
                <Eye
                  size={16}
                  className="c-icons"
                  weight="light"
                  data-tip="can view"
                  data-effect="solid"
                  data-delay-show='1000'
                />
              </td>
            </tr>
          </tbody>
        </table>
        <ReactTooltip data-class="tooltip-main" />
      </div>
      {activityList.length > 0 &&
        activityList.map((activity, index) => {
          return (
            <div key={index} className="task-list-card list-view">
              <div className="task-list-wrapper">
                <div className="d-flex w-100 align-items-start">
                  <div className="d-flex task-list-detail">
                    <div className="task-left flex-grow-1 width100minus160 flex-wrap">
                      <div className="d-flex align-items-center flex-grow-1 task-left-part-toggle text-truncate">
                        <a href="#/" className="task-top-part text-truncate flex-grow-1 d-flex main-calc-width-task">
                          <div className="d-flex align-items-center w-100">
                            <div className="task-creator-img">
                              {data.data.provider === 'one_drive' ? 
                                <div
                                key={0}
                                className="comman-image-box h25w25 rounded-circle"
                              >
                                <div
                                  className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                  style={{
                                    backgroundImage: `url('${activity.actor_pic}')`,
                                  }}
                                ></div>
                              </div>
                              :
                              activity.actors.map((actor, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="comman-image-box h25w25 rounded-circle"
                                  >
                                    <div
                                      className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                      style={{
                                        backgroundImage: `url('${actor.user.knownUser.photoUrl}')`,
                                      }}
                                    ></div>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="title-fonts text-truncate d-flex">
                              <div className="me-2 ps-2 flex-grow-1 text-truncate">
                                {data.data.provider === 'one_drive' ? activity.actor_name : activity.actors[0].user.knownUser.displayName}
                              </div>
                              <div className="c-font f-12 fw-light color-white-60 text-truncate">
                                {data.data.provider === 'one_drive' ?
                                activity.activity_name === 'edit'
                                ? "edited an item"
                                : activity.activity_name === 'create'
                                ? "created an item"
                                : 
                                  activity.activity_name === 'upload'
                                ? "uploaded an item"
                                : activity.activity_name === 'share'
                                ? "shared an item"
                                : activity.activity_name === 'move'
                                ? "moved an item"
                                : activity.activity_name === 'rename'
                                ? "renamed an item"
                                : activity.activity_name === 'comment'
                                ? "comment on item"
                                : "unknown Action"
                              :
                              activity.primaryActionDetail.edit 
                                  ? "edited an item"
                                  : activity.primaryActionDetail.create &&
                                    activity.primaryActionDetail.create.new 
                                  ? "created an item"
                                  : activity.primaryActionDetail.create &&
                                    activity.primaryActionDetail.create.upload
                                  ? "uploaded an item"
                                  : activity.primaryActionDetail
                                      .permissionChange 
                                  ? "shared an item"
                                  : activity.primaryActionDetail.move
                                  ? "moved an item"
                                  : activity.primaryActionDetail.rename
                                  ? "renamed an item"
                                  : "unknown Action"}
                              </div>
                            </div>
                          </div>
                        </a>
                        <div className="task-mid-part w-460 d-flex res-flex-wrap-nowrap align-items-center  for-content-between comman_action_icon justify-content-end">
                          <div className="d-flex align-items-center justify-content-end res-space-start res-width-100">
                            <div className="with_separator_10">
                              <span className="color-white-60 c-font f-14">
                                {new Date(
                                  data.data.provider === 'one_drive' ? activity.time : activity.timestamp
                                ).toLocaleDateString() +
                                  " " +
                                  new Date(
                                    data.data.provider === 'one_drive' ? activity.time : activity.timestamp
                                  ).toLocaleTimeString()}
                              </span>
                            </div>
                            <div className="with_separator_10 p-0"></div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center task-bottom-part-full">
                        <div className="task-right-static-options flex-wrap">
                          <div className="d-flex align-items-center">
                            <div className="with_separator_10 d-flex">
                              <FileText
                                className="me-2"
                                size={18}
                                color="#518ef8"
                                weight="fill"
                              />
                              <span className="c-font color-white-60 f-14 text-truncate max-width200">
                                {data.data.provider === 'one_drive' ? data.data.name :activity.targets[0].driveItem.title}
                              </span>
                            </div>
                            {data.data.provider === 'one_drive'?
                            activity.activity_name === 'edit'  ||
                            activity.activity_name === 'create' ? (
                              ""
                            ) 
                            // : activity.primaryActionDetail.permissionChange &&
                            //   activity.primaryActionDetail.permissionChange
                            //     .addedPermissions ? (
                            //   activity.primaryActionDetail.permissionChange.addedPermissions.map(
                            //     (permission, index) => {
                            //       return (
                            //         <div key={index}>
                            //           {permission.anyone ? (
                            //             <div className="ps-3">
                            //               <b>
                            //                 Anyone on the internet with the link
                            //               </b>
                            //               <br />
                            //               <div className="ps-1">
                            //                 {permission.role}
                            //               </div>
                            //             </div>
                            //           ) : (
                            //             <div className="position-relative px-2 assignee-list-wrapper card-view-less-padding d-flex align-items-center">
                            //               <div className="multi_inner_wrapper with-min-width d-flex with_minus">
                            //                 <a href="#/" className="comman-image-box multi-wrapper-border h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding">
                            //                   <div
                            //                     className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                            //                     style={{
                            //                       backgroundImage: `url('${User_2}')`,
                            //                     }}
                            //                   ></div>
                            //                 </a>
                            //                 <UncontrolledDropdown>
                            //                   <DropdownToggle
                            //                     tag="a"
                            //                     className="comman-image-box h25w25 with_overlay d-flex rounded-circle dropdown team-list-dropdown"
                            //                   >
                            //                     <div
                            //                       className="unread_count m-auto title-fonts c-font f-12 fw-semibold"
                            //                       type="button"
                            //                       data-bs-toggle="dropdown"
                            //                       data-bs-auto-close="outside"
                            //                       aria-expanded="false"
                            //                       data-tip={`${_l("l_view_more")}`}
                            //                       data-effect="solid"
                            
                            //                       data-class="tooltip-main"
                            //                     >
                            //                       <div className="unread_count m-auto">
                            //                         +5
                            //                       </div>
                            //                     </div>
                            //                     <UncontrolledGeneralListing
                            //                       list={[1, 2, 3, 4].map(
                            //                         (index) => {
                            //                           return {
                            //                             name: "person name",
                            //                             image: User_2,
                            //                             id: index,
                            //                           };
                            //                         }
                            //                       )}
                            //                     />
                            //                   </DropdownToggle>
                            //                 </UncontrolledDropdown>
                            //               </div>
                            //               <div className="c-font f-10 fw-normal ms-2 color-white-60">
                            //                 - Can view
                            //               </div>
                            //             </div>
                            //           )}
                            //         </div>
                            //       );
                            //     }
                            //   )
                            // ) 
                            
                            : activity.activity_name === 'rename' ? (
                              <>
                                <span
                                  class="color-white p-0 ps-3 pe-3"
                                  style={{
                                    textDecoration: "line-through",
                                  }}
                                >
                                  {activity.old_file_name}
                                </span>
                                <a href="#/"
                                  class="badge  badge-white rounded-pill me-2"
                                >
                                  <span class="color-white p-0">
                                    {
                                      data.data.name
                                    }
                                  </span>
                                </a>
                              </>
                            ) : activity.activity_name === 'share' ? (
                              <>
                                <span
                                  class="color-white p-0 ps-3 pe-3"
                                  style={{
                                    textDecoration: "",
                                  }}
                                >
                                  Item shared
                                </span>
                                <a href="#/"
                                  class="badge  badge-white rounded-pill me-2"
                                >
                                  <span class="color-white p-0">
                                    {
                                      data.data.name
                                    }
                                  </span>
                                </a>
                              </>
                            ) : activity.activity_name === 'comment' ? (
                              <>
                                <span
                                  class="color-white p-0 ps-3 pe-3"
                                  style={{
                                    textDecoration: "",
                                  }}
                                >
                                  Commented on item
                                </span>
                                <a href="#/"
                                  class="badge  badge-white rounded-pill me-2"
                                >
                                  <span class="color-white p-0">
                                    {
                                      data.data.name
                                    }
                                  </span>
                                </a>
                              </>
                            ) 
                            // : activity.primaryActionDetail.permissionChange &&
                            //   activity.primaryActionDetail.permissionChange
                            //     .removedPermissions ? (
                            //   <b className="ps-3">
                            //     restricted access to an item
                            //   </b>
                            // ) : activity.primaryActionDetail.move ? (
                            //   <b className="ps-3">
                            //     moved an item to{" "}
                            //     {
                            //       activity.primaryActionDetail.move
                            //         .addedParents[0].driveItem.title
                            //     }
                            //   </b>
                            // ) 
                            : (
                              "unknown Action"
                            )
                          :
                          activity.primaryActionDetail.edit ||
                            activity.primaryActionDetail.create ? (
                              ""
                            ) : activity.primaryActionDetail.permissionChange &&
                              activity.primaryActionDetail.permissionChange
                                .addedPermissions ? (
                              activity.primaryActionDetail.permissionChange.addedPermissions.map(
                                (permission, index) => {
                                  return (
                                    <div key={index}>
                                      {permission.anyone ? (
                                        <div className="ps-3">
                                          <b>
                                            Anyone on the internet with the link
                                          </b>
                                          <br />
                                          <div className="ps-1">
                                            {permission.role}
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="position-relative px-2 assignee-list-wrapper card-view-less-padding d-flex align-items-center">
                                          <div className="multi_inner_wrapper with-min-width d-flex with_minus">
                                            <a href="#/" className="comman-image-box multi-wrapper-border h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding">
                                              <div
                                                className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                                style={{
                                                  backgroundImage: `url('${User_2}')`,
                                                }}
                                              ></div>
                                            </a>
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                tag="a"
                                                className="comman-image-box h25w25 with_overlay d-flex rounded-circle dropdown team-list-dropdown"
                                              >
                                                <div
                                                  className="unread_count m-auto title-fonts c-font f-12 fw-semibold"
                                                  type="button"
                                                  data-bs-toggle="dropdown"
                                                  data-bs-auto-close="outside"
                                                  aria-expanded="false"
                                                  data-tip={`${_l("l_view_more")}`}
                                                  data-effect="solid"
                                                  data-delay-show='1000'
                                                  data-class="tooltip-main"
                                                >
                                                  <div className="unread_count m-auto">
                                                    +5
                                                  </div>
                                                </div>
                                                <UncontrolledGeneralListing
                                                  list={[1, 2, 3, 4].map(
                                                    (index) => {
                                                      return {
                                                        name: "person name",
                                                        image: User_2,
                                                        id: index,
                                                      };
                                                    }
                                                  )}
                                                />
                                              </DropdownToggle>
                                            </UncontrolledDropdown>
                                          </div>
                                          <div className="c-font f-10 fw-normal ms-2 color-white-60">
                                            - Can view
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  );
                                }
                              )
                            ) : activity.primaryActionDetail.rename ? (
                              <>
                                <span
                                  class="color-white p-0 ps-3 pe-3"
                                  style={{
                                    textDecoration: "line-through",
                                  }}
                                >
                                  {activity.primaryActionDetail.rename.oldTitle}
                                </span>
                                <a href="#/"
                                  class="badge  badge-white rounded-pill me-2"
                                >
                                  <span class="color-white p-0">
                                    {
                                      activity.primaryActionDetail.rename
                                        .newTitle
                                    }
                                  </span>
                                </a>
                              </>
                            ) : activity.primaryActionDetail.permissionChange &&
                              activity.primaryActionDetail.permissionChange
                                .removedPermissions ? (
                              <b className="ps-3">
                                restricted access to an item
                              </b>
                            ) : activity.primaryActionDetail.move ? (
                              <b className="ps-3">
                                moved an item to{" "}
                                {
                                  activity.primaryActionDetail.move
                                    .addedParents[0].driveItem.title
                                }
                              </b>
                            ) : (
                              "unknown Action"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ReactTooltip />
              </div>
            </div>          
          );
        })}
    </div>
  );
};
export default DocumentsActivity;
