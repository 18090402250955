import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import ReactHtmlParser from "react-html-parser";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { _l } from "../../../hooks/utilities";

const SubscriptionBasicdetails = ({
  projectId,
  clientId,
  location,
  zoomLevel,
  data,
}) => {
  const [selectedTab, setSelectedTab] = useState("documents");

  const [startDate, setStartDate] = useState(new Date());
  const [amount, setAmount] = useState(0);
  const [orderNumber, setOrderNumber] = useState("");
  const [estimateDate, setEstimateDate] = useState("");
  const [billingPeriod, setBillingPeriod] = useState("");
  const [nextBillingDate, setNextBillingDate] = useState("");
  const [estimateNumber, setEstimateNumber] = useState("");
  const [billFrom, setBillFrom] = useState("");
  const [billTo, setBillTo] = useState("");
  // const [billTo, setBillTo] = useState({
  //   companyName: "",
  //   companyAddress: "",
  //   companyCity: "",
  //   companyState: "",
  //   companyCountryCode: "",
  //   companyZipCode: "",
  //   vat: "",
  //   phoneNumber: "",
  // });
  const [estimateItems, setEstimateItems] = useState([]);
  const [estimateSubTotal, setEstimateSubTotal] = useState(0);
  const [estimateTotal, setEstimateTotal] = useState(0);
  const [subscriptionTerms, setSubscriptionTerms] = useState("");
  const [totalTax, setTotalTax] = useState(0);

  useEffect(() => {
    if (data) {
      setAmount(data.total);
      setOrderNumber("0000-000000");
      setEstimateDate(moment(data.date).format("D MMM, YY"));
      setBillingPeriod("unknown");
      setNextBillingDate("unknown");
      setEstimateNumber(data.name);
      setBillFrom(data.bill_from);
      setBillTo(data.bill_to);
      // setBillTo({
      //   companyName: data.company_name,
      //   companyAddress: data.company_address,
      //   companyCity: data.company_city,
      //   companyState: data.company_state,
      //   companyCountryCode: data.company_country_code,
      //   companyZipCode: data.company_zip_code,
      //   vat: data.vat,
      //   phoneNumber: data.company_phone_number,
      // });
      setEstimateItems(data.items ? data.items : []);
      setSubscriptionTerms(data.terms);
      setEstimateSubTotal(data.subtotal);
      setEstimateTotal(data.total);
      setTotalTax(data.total_tax);
    }
  }, [JSON.stringify(data)]);
  return (
    <div>
      <table className="w-100 d-none">
        <thead></thead>
        <tbody className="">
          <tr className="w-100 border-bottom">
            <td className="p-2">
              <div className="d-flex ">
                <span className=""> {_l("l_amount")} : </span>
                <span className="color-white-80  ps-3">{amount}</span>
              </div>
            </td>
          </tr>
          <tr className="w-100 border-bottom">
            <td className=" p-2">
              <div className="d-flex ">
                <span className="">{_l("l_order_number")} : </span>
                <span className="color-white-80  ps-3">{orderNumber}</span>
              </div>
            </td>
          </tr>
          <tr className="w-100">
            <td className=" p-2">
              <div className="d-flex ">
                <span className="">{_l("l_subscription_date")} : </span>
                <span className="color-white-80  ps-3">Nov 20, 2017</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="bill-from w-100 d-flex">
        <div className="w-50">
          <div className="color-white-60  pb-2">{_l("l_bill_from")}</div>
          <span dangerouslySetInnerHTML={{ __html: billFrom }}></span>
          {/* <div className="">
            <span className="">8 ORBIT , 24 rue de Clichy</span>
          </div>
          <div className="">
            <span className="">75009 Paris , RCS :</span>
            <span className="color-white-80  ps-3"> 87889318900018 France</span>
          </div>
          <div className="">
            <span className="">VAT Number : </span>
            <span className="color-white-80  ps-3"> FR68878893189</span>
          </div> */}
        </div>
        <div className="w-50">
          <div className="color-white-60  pb-2">{_l("l_bill_to")}</div>
          <span dangerouslySetInnerHTML={{ __html: billTo }}></span>
          {/* <div className="">
            <span className="">DJS Avocats, 5 Rue Lincoln</span>
          </div>
          <div className="">
            <span className="">Paris, FR 75008</span>
          </div> */}
        </div>
      </div>
      <div className="">
        <div className=" d-flex flex-column pt-3">
          <div className="comman-content-scroll-wrapper">
            <div className="comman-content-scroll">
              <div className="comman-data-table GeneralItemTable">
                <table className="dataTable comman-table smaller-table">
                  <thead className="bg-transparent">
                    <tr className="bg-white-03">
                      <th style={{ width: "3%" }}>#</th>
                      <th>{_l("l_item")}</th>
                      <th style={{ width: "7%" }}>{_l("l_quantity")}</th>
                      <th style={{ width: "7%" }}>{_l("l_rate")}</th>
                      <th style={{ width: "18%" }}>{_l("l_tax")} (%)</th>
                      <th style={{ width: "8%" }}>{_l("l_amount")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data && data.items && data.items.length && data.items.map((i, no) => {
                        return (
                          <tr className="">
                            <td className="border-bottom align-top">{no + 1}</td>
                            <td className="max-width-100 text-truncate border-bottom">
                              <div className="color-white-60">
                                {i.description}
                              </div>
                              <div className="color-white-60">
                                {i.long_description}
                              </div>
                            </td>
                            <td className="border-bottom align-top ">{i.qty}</td>
                            <td className="border-bottom align-top ">{i.rate}</td>
                            <td className="border-bottom align-top ">{i.tax_rate}</td>
                            <td className="border-bottom align-top ">{i.amount}</td>
                          </tr>
                        )
                      })
                    }
                    <tr className="">
                      <td colSpan={5} className="text-end border-bottom ">
                        {_l("l_sub_total")}
                      </td>
                      <td className="border-bottom  color-white-60 ">
                        {data && data.estimate_subtotal ? data.estimate_subtotal : 0}
                      </td>
                    </tr>
                    <tr className="">
                      <td colSpan={5} className="text-end border-bottom  ">
                      {data && data.items && data.items.length ? data.items[0].tax_rate : `0% ${_l("l_tva")}`}
                      </td>
                      <td className="border-bottom  color-white-60">{data && data.total_tax}</td>
                    </tr>
                    <tr className="">
                      <td colSpan={5} className="text-end color-green ">
                        {_l("l_total")}
                      </td>
                      <td className="fw-semibold  color-green">{data && data.total ? data.total : ''}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="terms-condition">
          <div className="fw-semibold pb-2">{_l("l_terms_conditions")}:</div>
          <div className="color-white-60 pb-3">
            <span
              dangerouslySetInnerHTML={{
                __html: subscriptionTerms,
              }}
            ></span>
            {/* {ReactHtmlParser(subscriptionTerms) || _l("l_subscription_terms_text")} */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubscriptionBasicdetails;
