import React, { useEffect, useState } from "react";
import { leftPanelMouseUpEvent } from "../hooks/utilities";
import ConnectProvider from "../components/ExploreFeatures/ConnectProvider/ConnectProvider";
import { setOnboardingStepData } from "../actions/onboarding-actions/onboarding-actions";
import { useDispatch } from "react-redux";
import { ScheduleProvider } from "../context/ScheduleContext";
import { useSearchParams } from "react-router-dom";

const ConnectProviderView = () => {

  let spaces = localStorage.getItem("spaces");
  spaces = spaces ? JSON.parse(localStorage.getItem("spaces")) : [];
  const userType = localStorage.getItem("user_type");
  const [searchParams] = useSearchParams();

  const [projectId] = useState(searchParams.get("space"));
  const dispatch = useDispatch();
  const [pageName, setPageName] = useState();

  useEffect(() => {
    document.addEventListener("mouseup", leftPanelMouseUpEvent);
    dispatch(
      setOnboardingStepData({
        step: "space-onboarding",
      })
    );
    if (spaces.length) {
      let space = spaces.find((x) => x.project_id == projectId);
      if(space)
      {
        if(userType === "operator")
        {
          if(!space.is_onboard_completed_operator)
          {
            setPageName("SpaceOnBoarding");
          }
        }
        else {
          if(!space.is_onboard_completed_contact)
          {
            setPageName("SpaceOnBoarding");
          }
        }
      }
    }

  }, []);

  return (
    <React.Fragment>
      <ScheduleProvider >
        <ConnectProvider />
      </ScheduleProvider>
    </React.Fragment>
  );
};

export default ConnectProviderView;
