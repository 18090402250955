import React, { useEffect, useState } from "react";
import { X, UploadSimple, Plus, Check, FilePdf } from "phosphor-react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l, showLeftPanel, validateEmail } from "../../hooks/utilities";
import Select from "react-select";
import customerServices from "../../services/customer-services";
import { useDispatch, useSelector } from "react-redux";
import { getNewCustomer } from "../../actions/customer";
import { showMessage } from "../../actions/messages";
import { SUCCESS_FLAG } from "../../actions/action-type";

const AddNewClient = ({ projectId, show, handleClose, handleShow }) => {
  const [assignees, setAssignees] = useState([]);
  const [categories, setCategories] = useState([]);
  const [addDetails, setAddetails] = useState({
    assignee: '',
    category: ''
  });
  const [addDocumentDetails, setDocumentDetails] = useState({
    assignee: '',
    category: '',
    name: '',
    file: ''
  });
  const SelectDemoOption1 = [
    // { value: "1", label: "option - 1" },
    // { value: "2", label: "option - 2" },
    // { value: "3", label: "option - 3" },
  ];
  const SelectDemoOption2 = [
    // { value: "1", label: "option - 1" },
    // { value: "2", label: "option - 2" },
    // { value: "3", label: "option - 3" },
  ];
  const [defaultTopics, setDefaultTopics] = useState([]);
  const [addCategory, setAddCategory] = useState(true);
  const [addAssignees, setAddAssignees] = useState(true);
  const [customer_groups, setCustomer_groups] = useState([]);
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const { newCusData } = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const [company, setCompany] = useState("");
  const [vat, setVat] = useState("");
  const [website, setWebsite] = useState("");
  const [d_lang, setD_lang] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState();
  const [state, setState] = useState("");
  const [group, setGroup] = useState();
  const [currency, setCurrency] = useState("");
  const [phone, setPhone] = useState("");
  const OptCountry = [];
  const OptGroups = [];
  const OptCurrencies = [];

  const add_client = () => {
    if(company == "")
    {
        dispatch( showMessage('unsucess', _l('l_error'),  "Please Enter Company Name"));
    } else {
        customerServices.createNewClient(company, vat, website, state, country, group, phone, currency, zipCode, address, city, d_lang).then((res) => {
            if(res.status == 1)
            {
                handleClose()
                dispatch( showMessage('success', _l('l_success'),  "Client Create Successfully...!"));
                dispatch({
                  type: SUCCESS_FLAG,
                  payload: res.data,
                });
            } else if(res.status == 2)
            {
              dispatch( showMessage('warning', _l('l_success'),  "This name already exists...!"));
            } else {
              handleClose()
                dispatch( showMessage('error', _l('l_error'),  "Something went wrong...!"));
            }
        });
    }
  }
useEffect(() => {
    if(newCusData)
    {
      newCusData.customer_groups.forEach(element => {
        setCustomer_groups((customer_groups) => [...customer_groups, {value: element.id, label: element.name}])
      });
      newCusData.countries.forEach(element => {
        setCountries((countries) => [...countries, {value: element.id, label: element.name}])
      });
      newCusData.currencies.forEach(element => {
        setCurrencies((currencies) => [...currencies, {value: element.id, label: element.name+" "+element.symbol}])
      });
        // setCustomer_groups(newCusData.customer_groups);
        // setCountries(newCusData.countries);
        // setCurrencies(newCusData.currencies);
    }
}, [newCusData])

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title> {"Add New Client"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" py-0">
          <div className="form-wrapper-main">
            <div className="row">
              <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                <Form.Label className="input-label asterisk ">
                  {"Company"}
                </Form.Label>
                <Form.Control
                  placeholder={`${"Add comapny name"}`}
                  type="text"
                  name=""
                  onChange={(e) => {
                    setCompany(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {"Vat Number"}
                </Form.Label>
                <Form.Control
                  placeholder={`${"Vat number"}`}
                  type="text"
                  name=""
                  onChange={(e) => {
                    setVat(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {"Phone"}
                </Form.Label>
                <Form.Control
                  placeholder={`${"1234567890"}`}
                  type="text"
                  name=""
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {"Website"}
                </Form.Label>
                <Form.Control
                  placeholder={`${"Website"}`}
                  type="text"
                  name=""
                  onChange={(e) => {
                    setWebsite(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {"Address"}
                </Form.Label>
                <Form.Control
                  placeholder={`${"Address"}`}
                  type="text"
                  name=""
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {"City"}
                </Form.Label>
                <Form.Control
                  placeholder={`${"City"}`}
                  type="text"
                  name=""
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {"State"}
                </Form.Label>
                <Form.Control
                  placeholder={`${"State"}`}
                  type="text"
                  name=""
                  onChange={(e) => {
                    setState(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {"Zip Code"}
                </Form.Label>
                <Form.Control
                  placeholder={`${"Zip Code"}`}
                  type="text"
                  name=""
                  onChange={(e) => {
                    setZipCode(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {"Language"}
                </Form.Label>
                <Form.Control
                  placeholder={`${"Language"}`}
                  type="text"
                  name=""
                  onChange={(e) => {
                    setD_lang(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="col-12 c-input-box pb-3 position-relative comment-input-box">
                <Form.Label className="input-label no-asterisk ">
                    {"Customer Groups"}
                </Form.Label>
                <div className="position-relative">
                  <Select className="custom-select-menu w-100 form-control ps-0 py-0 wirh-border-none pe-0"
                    classNamePrefix="react-select"
                    options={customer_groups}
                    closeMenuOnSelect={true}
                    placeholder={`${_l("l_please_select")}`}
                    isMulti={true}
                    onChange={(e) => {
                      var values = Array.isArray(e) ? e.map(x => x.value) : [];
                      setGroup(values.toString());
                    }}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-12 c-input-box pb-3 position-relative comment-input-box">
                <Form.Label className="input-label no-asterisk ">
                    {"Currencies"}
                </Form.Label>
                <div className="position-relative">
                  <Select className="custom-select-menu w-100 form-control ps-0 py-0 wirh-border-none pe-0"
                    classNamePrefix="react-select"
                    options={currencies}
                    closeMenuOnSelect={true}
                    placeholder={`${_l("l_please_select")}`}
                    onChange={(e) => {
                      setCurrency(e.label);
                    }}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-12 c-input-box position-relative comment-input-box">
                <Form.Label className="input-label no-asterisk ">
                    {"Countries"}
                </Form.Label>
                <div className="position-relative">
                  <Select className="custom-select-menu drop-up w-100 form-control ps-0 py-0 wirh-border-none pe-0"
                    classNamePrefix="react-select"
                    options={countries}
                    closeMenuOnSelect={true}
                    menuPlacement="top"
                    placeholder={`${_l("l_please_select")}`}
                    onChange={(e) => {
                      setCategories(e.value);
                    }}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                  />
                </div>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l('l_close')}
          </Button>
          <Button onClick={() => {add_client();}} variant="primary" size="sm">{_l('l_save')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddNewClient;
