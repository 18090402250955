import React from 'react';
// import CSS from '../css/icon_main.css';

function FunnelIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M2.63125 3H13.3687C13.4657 3.00003 13.5606 3.02827 13.6418 3.08127C13.7231 3.13427 13.7871 3.20975 13.8262 3.29852C13.8653 3.38728 13.8777 3.48549 13.862 3.58119C13.8462 3.67689 13.803 3.76594 13.7375 3.8375L9.63125 8.35625C9.54579 8.44773 9.49879 8.56857 9.5 8.69375V12.2312C9.50076 12.3143 9.48054 12.3963 9.44121 12.4695C9.40188 12.5427 9.34471 12.6048 9.275 12.65L7.275 13.9812C7.19998 14.0307 7.11303 14.059 7.02329 14.0631C6.93356 14.0673 6.84435 14.0473 6.76505 14.0051C6.68575 13.9629 6.61929 13.9001 6.57265 13.8233C6.526 13.7465 6.50091 13.6586 6.5 13.5688V8.69375C6.50121 8.56857 6.45421 8.44773 6.36875 8.35625L2.2625 3.8375C2.19703 3.76594 2.15379 3.67689 2.13804 3.58119C2.1223 3.48549 2.13472 3.38728 2.1738 3.29852C2.21288 3.20975 2.27694 3.13427 2.35816 3.08127C2.43938 3.02827 2.53426 3.00003 2.63125 3Z"/>
</svg>


		</React.Fragment>
	)
}

export default FunnelIcon