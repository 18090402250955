import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useRef, useState } from "react";

 const CardDetailsPaytabs = ({ projectId, clientId, location, zoomLevel }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  const [startDate, setStartDate] = useState(new Date());
 return (
    <div>
        <div className="row">
            <Form.Group className="col-12 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk title-fonts color-white-60">
                IBAN
                </Form.Label>
                <Form.Control
                    placeholder="DE00 1234 5678 9000 0000"
                    type="text"
                    name=""
                    />
            </Form.Group>
            <Form.Group className="col-12 c-input-box position-relative">
                <Form.Label className="input-label no-asterisk title-fonts color-white-60">
                Emial
                </Form.Label>
                <Form.Control
                    placeholder="Emial@gmail.com"
                    type="text"
                    name=""
                    />
            </Form.Group>
            <Form.Group className="col-12 c-input-box position-relative">
                <Form.Label className="input-label no-asterisk title-fonts color-white-60">
                Full name
                </Form.Label>
                <Form.Control
                    placeholder="First and last name"
                    type="text"
                    name=""
                    />
            </Form.Group>
        </div>
    </div>
  );
 };
 export default CardDetailsPaytabs;