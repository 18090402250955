import { BENCHMARK_DETAILS } from "../constants/constants";

interface customerSpaces {
  id: string;
  office_title: string;
  postal_address: string;
  project_id: string;
  status: string;
}

let favDashboard = "AichatView";
if (localStorage.getItem("fav_dashboard") == "calendar") {
  favDashboard = "calendarView"
} else if(localStorage.getItem("fav_dashboard") == "my_spaces"){
  favDashboard = "spaceView"
}else if (localStorage.getItem("fav_dashboard") == "kanban_view"){
  favDashboard = "kanbanView"
}else if(localStorage.getItem("fav_dashboard") == "my_tasks"){
  favDashboard = "liteVersionView"
}else if(localStorage.getItem("fav_dashboard") == "AichatView"){
  favDashboard = "AichatView"
}else if (localStorage.getItem("currentView")){ 
  favDashboard = ""
}
export class CustomerModel {
  spaces: customerSpaces[] = [];
  calendarData: any;
  taskList: any[] = [];
  UnreadCommentTaskList: any;
  topicData: any;
  selectedTask: any;
  selectedProject: number | string =
    localStorage.getItem("selectedOffice") || 0;
  defaultTopics: any[]=[];
  defaultTaskAssignees: any;
  clientTeamData: any;
  taskChecklist: any[] = [];
  taskFilters: any[] = [];
  taskCounts: any;
  taskSearchString: string = "";
  benchmarkDetail: any;
  benchmarkSchedule: any;
  taskStatusFilter: any[] = [];
  mentionList: any[] = [];
  activeTaskStatusFilters: any[] = [];
  contactPermissions: any[] = [];
  walletDetails: any[] = [];
  toastClose: any;
  isAppliedUnreadComment: any = false;
  taskUnreadCmtCntAll: number = 0;
  isPremiumEstimateAccess: string = "";
  isIBANModalOpen: string = "";
  createSchduleDetails: any;
  benchmarkList: any[] = [];
  filterBenchmarkCategory: number = 0;
  calendarsDefaultTopics: any[] = [];
  virtualSpaceList: any[] = [];
  hideLoader: boolean = false;
  space_work_score: any[] = [];
  space_logo_url:  string = "";
  image_logo_url:  string = "";
  user_placeholder:  string = "";
  userTeams: any[] = [];
  accessOfficeData: any[] = [];
  modelViewerPoint: boolean = false;
  commanoffcanvasIsOpen: boolean = false;
  calendarCanvasIsOpen: boolean = false;
  attachmentsForTask: any;
  themeColor: string = localStorage.getItem("selectedTheme") || "" ;
  globalSearchString: string = "";
  favDashboard : string = "";
  selectedUserForChat: any;
  isBackdrop: boolean = false;
  isAllChatUserlistShow: boolean = false;
  generalUnreadCount: number = 0;
  searchStringForAllChatUser: string = "";
  isDayGridMonth : boolean = true;
  isDayGridWeek  : boolean = false;
  isDayGridDay : boolean = false; 
  isTasklistView : boolean = false;
  isAiChat : boolean = false;
  createTaskReqInputIcons:boolean=false;
  daterangeDayDifference : number = 0;
  daterangeAppliedFilters : string = "today";
  globalView : any =  localStorage.getItem("currentView") ? localStorage.getItem("currentView") : "listView" ;
  daterangeFilterApplied :boolean = false;
  calendarView : string = "this_week";
  benchmark_create : boolean = true;
  getSelectedFloorId : number = 0;
  pinDelayfilter : any[] = [];
  imageviewerisOpen : boolean = false;
  tasklistLoader : boolean = false;
  openChatAi : boolean = false;
  dateView : string = "dayView";
  lastChatSession : any[] = [];
  metricsDetails:object = {};
  filterfor3dPlan : any[] = [];
  scrollDate: any = new Date();
  dashBoradFilter : any[] = [];
  createTaskModalIsopen : boolean = false;
  dashBoradView : string = "spaceView";
  ChatAiDate : any = new Date();
  taskandMessages: any[] = [];
  lastTaskDate : any = new Date();
  chatcalendarData: any[] = [];
  displayThreeDays : boolean = true;
  weekCalNaviSide : string = "";
  calendarDateIndex : number = 0;
  chatTaskList : any[] = [];
  firstIdofMessage : any = "";
  preDataAvaliable : boolean = false;
  lastAppliedFilter : object = {};
  isCallFullscreen : boolean = false;
  callRecordingActive : boolean = false;
  callTask : object = {};
  callTaskDetails : any[] = [];
  callComments: any[] = [];
  callRecordingAction : string = "";
  displayMic : boolean = false;
  isEndCall : boolean = false;
  chatPlanTittle : object = {};
  solarFilters : any[] = [];
  // leftPanelTab : string = localStorage.getItem("user_type") == "staff" ? "my-clients" :"adminstrative-list"
  leftPanelTab : string =  "homePage"
  reminderList : any[] = [];
  propsalFilters : number = -1
  adminstrativeProposalList : any[] = [];
  installationProposalList : any[] = [];
  installationFilter : any[] = [];
  chatMessagesLoader : boolean = false;
  operatorProposalList : any[] =[];
  isTaskProposal : object = {};
  mandatedDocument : object = {};
  mandatedDocumentCoordinate : string = "";
  updateDocumentTemplate : object = {};
}
