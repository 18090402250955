import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l, getDatePickerLocale, showError, showSuccess } from "../../hooks/utilities";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { CalendarBlank } from "phosphor-react";
import DatePicker from "react-datepicker";
import benchmarkServices from "../../services/benchmark-services";
import { setReminderList } from "../../actions/customer";
import { updateExternalBenchmarkDetails } from "../../actions/benchmark-actions/benchmark-actions";
import moment from "moment";

const ReminderModal = ({ show, handleClose = () => {} }) => {

  const dispatch = useDispatch();

  const { benchmarkDetail } = useSelector((state) => state.customer);

  let proposal =
    benchmarkDetail.proposal_list && benchmarkDetail.proposal_list.length
      ? benchmarkDetail.proposal_list[0].proposals.find(
          (w) => w.proposal_id == benchmarkDetail.proposal_id
        )
      : {};
  const [description, setDescription] = useState("");
  const [loader, setLoader] = useState(false);
  const [reminderDate, setReminderDate] = useState();

  const handleSave = () => {
    if (!reminderDate) {
      showError("l_please_select_date");
    } else if (description.trim() === "") {
      showError("l_please_enter_valid_description");
    } else {
      try {
        benchmarkServices
          .addSolarReminders(
            "proposal",
            proposal.proposal_id,
            0,
            localStorage.getItem("staff_id"),
            description.trim(),
            reminderDate,
            benchmarkDetail.id
          )
          .then((res) => {
            if (res && res.status) {
              let reminder_updated = [{task_id : res.data.task_id, id : res.data.task_id, description : description.trim(), date : moment(reminderDate).format('YYYY-MM-DD HH:mm:ss')}].concat(benchmarkDetail.reminder)
              dispatch(setReminderList("proposal",0))
              dispatch(updateExternalBenchmarkDetails({...benchmarkDetail, reminder :  reminder_updated}))
              showSuccess(res.message)
              handleClose();
            }else{
                showError(res.message);
                handleClose();
            }
          });
      } catch (error) {
        console.log(error)
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop={"static"}
      keyboard={false}
      centered
      className="custom-modal-style solarcommanmodal "
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header className="p-3" closeButton>
        <Modal.Title>Ajouter un rappel</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-wrapper-main p-0">
          <div className="row">
            <Form.Group className="col-12 c-input-box pb-4 position-relative">
              <Form.Label className="input-label no-asterisk">
                {_l("l_remider_date")}
              </Form.Label>
              <div className="custom-datepicker calendarheadernone">
                <div className="d-flex align-items-center form-control p-15 color-white-60 dropdown-center dropdown comman_action_icon">
                  <div className="action_icon align-items-center d-flex">
                    <CalendarBlank
                      size={18}
                      weight="light"
                      className="c-icons c-icons m-0 "
                    />
                  </div>
                  <DatePicker
                    placeholderText={`${_l("l_remider_date")}`}
                    calendarStartDay={1}
                    selected={reminderDate}
                    locale={getDatePickerLocale()}
                    disabledKeyboardNavigation
                    dateFormat="dd/MM/yyyy HH:mm:ss"
                    selectsStart
                    defaultMenuIsOpen
                    showTimeSelect
                    minDate={new Date()}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    onChange={(date) => {
                      setReminderDate(date);
                    }}
                  >
                    {/* <div className="datepicker-label align-items-center">
                      Ajouter un rappel
                    </div> */}
                  </DatePicker>
                </div>
              </div>
            </Form.Group>
          </div>
          <div className="row">
            <Form.Group className="col-xl-12 c-input-box pb-20px position-relative">
              <Form.Label className="input-label no-asterisk ">
                {_l("l_description")}
              </Form.Label>
              <Form.Control
                placeholder={`${_l("l_description")}`}
                as="textarea"
                type="text"
                name=""
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </Form.Group>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-end">
        <Button variant="secondary" size="sm" onClick={handleClose}>
          {_l("l_close")}
        </Button>
        <Button
          variant="primary"
          size="sm"
          onClick={() => {
            if (!loader) {
              handleSave();
            }
          }}
          disabled={loader}
        >
          {loader ? _l("l_please_wait") : _l("l_save")}
          {loader ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              className={`ms-2 `}
              aria-hidden="true"
            />
          ) : (
            <></>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReminderModal;
