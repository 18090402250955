import React from 'react'
const ProceduresSkeleton = () => {
  return (
    <>
    {
      [0,1,2,3].map((s,i)=>{
      return <div className='SkeletonMain bg-white-03 comman-white-box-shadow d-flex radius_3' key={i}>
        <div className='radius_3 skeleton-animation w25per' style={{ height: '220px', width: '190px',margin: '5px' }}></div>
        <div className='p-20px flex-grow-1'>
          <div className="d-flex justify-content-between mb20px pb-1">
            <div className='flex-grow-1 d-flex flex-column justify-content-center'>
              <div className='mb-2 skeleton-animation radius_3' style={{ width: '40%', height: '15px' }}></div>
              <div className='skeleton-animation radius_3' style={{ width: '25%', height: '10px' }}></div>
            </div>
            <div className='radius_3 skeleton-animation' style={{ width: '250px', height: '15px'}}></div>
          </div>
          <div className='align-items-md-center align-items-start d-flex justify-content-between' style={{ gap: '24px' }}>
            <div className='d-flex flex-column justify-content-center flex-grow-1 gap10px'>
              <div className='skeleton-animation radius_3' style={{ width: '100px', height: '15px' }}></div>
              <div className='skeleton-animation radius_3' style={{ width: '100%', height: '36px' }}></div>
              <div className='skeleton-animation radius_3' style={{ width: '65px', height: '50px' }}></div>
            </div>
            <div className='d-flex flex-column justify-content-center flex-grow-1 gap10px'>
              <div className='skeleton-animation radius_3' style={{ width: '100px', height: '15px' }}></div>
              <div className='skeleton-animation radius_3' style={{ width: '100%', height: '36px' }}></div>
              <div className='skeleton-animation radius_3' style={{ width: '65px', height: '50px' }}></div>
            </div>
            <div className='d-flex flex-column justify-content-center flex-grow-1 gap10px'>
              <div className='skeleton-animation radius_3' style={{ width: '100px', height: '15px' }}></div>
              <div className='skeleton-animation radius_3' style={{ width: '100%', height: '36px' }}></div>
              <div className='skeleton-animation radius_3' style={{ width: '65px', height: '50px' }}></div>
            </div>
          </div>
        </div>
      </div>
      })
    }
    </>
  )
}
export default ProceduresSkeleton