import { ArrowsIn, ArrowsOut, CaretDown, CaretUp, DotsThree, Headphones, Headset, MagnifyingGlass, SlidersHorizontal, X } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { _l } from '../../../hooks/utilities'
import Accordion from "react-bootstrap/Accordion";
import SolarChatScreen from './SolarChatScreen';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGroupsExternal, groupOut, setChatGroupsList, setChatGroupsListDetails, setClearmessage, setLastmessageGroupDetails, setShowAccordionVisibility, setactiveChatScreenId, updateExternalGroupListMessages } from '../../../actions/chat';
import { USR_IMG_PLACEHOLDER } from '../../../actions/chat-action-type';
import moment from 'moment';
import { el } from 'date-fns/locale';
import customerServices from '../../../services/customer-services';
import CommanPlaceholder from '../../Placeholder/CommanPlaceholder';
import jquery from "jquery";


const SolarFooter = () => {
    const dispatch = useDispatch();

    const { groupListExternal,chatGroups, chatGroupsDetails, activeChatScreenID, isChatOpen, lastChatGroup, newMessage } = useSelector((state) => state.chat);
    const { benchmarkDetail } = useSelector((state) => state.customer);

    var chatUserId = localStorage.getItem("chatUserId");
    let userType = localStorage.getItem("user_type");
    let is_external = localStorage.getItem("is_external");
    let proposalListNew =
    benchmarkDetail && benchmarkDetail.proposal_list &&
    benchmarkDetail.proposal_list.length &&
    benchmarkDetail.proposal_list[0].proposals
      ? benchmarkDetail.proposal_list[0].proposals
      : [];
    const [searchString, setSearchString] = useState("");
    const [totalNotificationCounts, setTotalNotificationCounts] = useState(0);
    const [activeAccordianKeys, setActiveAccordianKeys] = useState([0]);
    const [proposalList, setProposalList] = useState([]);
    const [companyName, setCompanyName] = useState("");
    const [contactId, setcontactId] = useState("")

    const getProposalList = async () => {
        try {
          const response = await customerServices.getBenchmarks(
            0,
            0,
            "",
            0,
            0,
            0,
            1,
            10,
            1,
            [],
            -1
          );
          if (response.status) {
   
            setProposalList(response.data);
          }
        } catch (e) {
          console.error(e);
        } finally {
        }
      };
  
    useEffect(() => {
        getProposalList();
    }, [])
    
      
    useEffect(() => {
        let count = 0
      if (groupListExternal && groupListExternal.length > 0) {
          groupListExternal.map((gd)=>{
              count = count + gd.unreadCount  
          })
      }
      setTotalNotificationCounts(count)
    }, [JSON.stringify(groupListExternal)])

    useEffect(() => {
        if (activeChatScreenID && groupListExternal.length == 0) {
          dispatch(fetchGroupsExternal(chatUserId, 0, 1));
        }else if(groupListExternal.length > 0){
            let index = groupListExternal.findIndex((item) => item.groupId === activeChatScreenID);
            if (index > -1) {                
            }else if(!is_external){
                dispatch(fetchGroupsExternal(chatUserId, 0, 1));
            }
        }

    }, [activeChatScreenID])
    
    // ---------------------------------------------------------------- window lister ----------------------------------------------------------------

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    // Function to update window size
    const updateWindowSize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    };

    if (window.innerWidth < 730) {

        // console.log("windowSize",windowSize)        
    }

    useEffect(() => {
        // Add event listener to update window size state
        window.addEventListener('resize', updateWindowSize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateWindowSize);
        };
    }, []);
   
    useEffect(() => {
      const newCounts = groupListExternal.map((group) => {
        if (
          group.groupId === newMessage.group &&
          activeChatScreenID !== newMessage.group
        ) {
          return {
            ...group,
            unreadCount: (group.unreadCount || 0) + 1,
          };
        }
        return group;
      });
      dispatch(updateExternalGroupListMessages(newCounts));
    }, [newMessage]);

    // ---------------------------------------------------------------- window lister ----------------------------------------------------------------

  return (
    <div className='messaging-footer-main d-flex justify-content-between'>
        <div>
        </div>
        <div className='messaging-footer-right d-flex gap10px align-items-end w-100'>
        <div className='custom-accordian-main with-left-header-arrow with-right-arrow d-flex flex-grow-1 flex-column justify-content-end'>
            {windowSize.width > 767 ? <Accordion alwaysOpen defaultActiveKey={['0','1','2','3']} className="mt-0 d-flex gap10px align-items-end"  >
            {
                chatGroupsDetails && chatGroupsDetails.length > 0 && chatGroupsDetails.map((chatGroup, index) =>{

                    let conactID
                    let comapnyName = ""
                                    if (proposalList && proposalList.length > 0) {
                                    let index = proposalList.findIndex((proposal) => proposal.proposal_id == chatGroup.extraData.proposalId)
                                    if (index > -1) {
                                        conactID = proposalList[index].contact_id
                                        
                                    }}
                                    if (proposalListNew && proposalListNew.length > 0) {
                                    let index = proposalListNew.findIndex((proposal) => proposal.proposal_id == chatGroup.extraData.proposalId)
                                    if (index > -1) {
                                        comapnyName = proposalListNew[index].company_name
                                        
                                    }}
                    
                    return(<>
                    
                        <SolarChatScreen 
                        group={chatGroup} 
                        activeKey={activeAccordianKeys} 
                        setActiveKey={setActiveAccordianKeys}
                        index={index}
                        proposalList={proposalList}
                        conactID={conactID}
                        comapnyName={comapnyName}
                        />
                    </>)
                })
            }
            </Accordion> :<></>}
            </div>
            <div className='custom-accordian-main with-left-header-arrow with-right-arrow w-100'>
           {windowSize.width <= 767 && Object.keys(lastChatGroup).length ? <>
            <Accordion alwaysOpen defaultActiveKey={['0','1','2','3']} className="mt-0 d-flex gap10px align-items-end"  >
            {
                        <SolarChatScreen 
                        group={lastChatGroup} 
                        activeKey={activeAccordianKeys} 
                        setActiveKey={setActiveAccordianKeys}
                        index={"0"}
                        proposalList={proposalList}
                        conactID={contactId}
                        comapnyName={companyName}
                        />
                
            }
            </Accordion>
           </> : <></>}
           <Accordion className={`mt-0 ${windowSize.width <= 767 && Object.keys(lastChatGroup).length ? "d-none" : ""}`}>
                <Accordion.Item eventKey={0} className="mb-0 messaging-dropdown-main w_350 border radius_3 ">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border with-accordian-bg">
                        <a className='messaging-dropdown-header w-100 text-truncate'
                          onClick={()=>{
                            dispatch(setShowAccordionVisibility(!isChatOpen));
                            jquery('.accordion-collapse').removeClass('collapse');

                        }}>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className='d-flex align-items-center text-truncate'>
                                    <div className='c-list-icon '>
                                        <div className='h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 comman-image-box position-relative'>
                                            {userType == "operator" 
                                                ? <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"style={{backgroundImage: `url('${localStorage.getItem("company_logo")}')`}}></div>
                                                :  is_external ? <>
                                                <Headset
                                                    size={18}
                                                    weight="light"
                                                    className="c-icons"
                                                />
                                                </>:
                                                <div
                                                    className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{backgroundImage: `url('${localStorage.getItem("contact_image")}')`}}
                                                ></div>
                                            }
                                            <span className='d-flex light-pill headmemberstatus position-absolute rounded-pill '>
                                                <span className='c-dots c-7 done rounded-circle radius500imp'></span>
                                            </span>
                                        </div>
                                    </div>
                                        <div className="c-list-detail title-fonts text-truncate color-white c-font f-13 ms-10px">
                                        {is_external ? _l("l_support") :localStorage.getItem("full_name")}
                                    </div>
                                </div>
                                <div className=' d-flex align-items-center gap-2'>
                                    <div className='d-flex align-items-center h-100'>
                                        {totalNotificationCounts > 0 ? <span className='rounded-pill badge-custom  d-flex p-2 light-pill position-static'>{totalNotificationCounts}</span> : <></>}
                                    </div>
                                    {/* <a href="#/" className="right-image" data-bs-dismiss="dropdown">
                                        <DotsThree size={18} weight="light" className="c-icons opacity-60" />
                                    </a>
                                    <a href="#/" className="right-image" data-bs-dismiss="dropdown">
                                        <ArrowsIn size={18} weight="light" className="c-icons opacity-60" />
                                        <ArrowsOut size={18} weight="light" className="c-icons opacity-60" />
                                    </a> */}
                                    {/* <a href="#/" className="right-image" data-bs-dismiss="dropdown">
                                        <X size={18} weight="light" className="c-icons opacity-60" />
                                    </a> */}
                                </div>
                            </div>
                        </a>
                         <a
                        className="commanTaskCount"
                        onClick={()=>{
                            dispatch(setShowAccordionVisibility(!isChatOpen));
                        }}
                    >
                        {isChatOpen ? (
                            <>
                                <CaretDown size={18} weight="light" className="c-icons" />
                            </>
                        ) : (
                            <>
                                <CaretUp size={18} weight="light" className="c-icons opacity-60" />
                            </>
                        )}
                    </a>
                        {/* <Accordion.Header className="commanTaskCount">
                        </Accordion.Header> */}
                    </div>
                    <Accordion.Body className={`taskCountToggle shadow-none d-flex flex-grow-1 flex-column ${!isChatOpen ? "" : "transition-dropdown hide"} `}>
                        <div className='p-10 pt-0'>
                            <div className='position-sticky top-0 z-index-2 p-10 px-0 pb-0 bg-base-header-color'>
                                <div className='radius_3 light-theme-grey-light-bg-imp d-flex align-items-center  position-relative py-0'>
                                    <div className='comment-input-box flex-grow-1'>
                                        <input
                                        type="text"
                                        className="bg-transparent with-base-bg border-0 flex-grow-1 form-control pe-20pximp p-10 f-11 c-font shadow-none"
                                        placeholder={_l("l_hello_how_can_i_help_you")}
                                        value={searchString}
                                        onChange={(e)=>{
                                            setSearchString(e.target.value);
                                        }}
                                        />
                                    </div>
                                   
                                          {searchString == "" ?
                                              <a href='#/' className='comman_action_icon p-10 ps-0'>
                                                  <div className='action_icon h25w25 h-100'>
                                                      <MagnifyingGlass
                                                          size={18}
                                                          className="c-icons"
                                                          weight="light"
                                                      />       </div>
                                              </a>
                                              :
                                              <a href='#/' className='comman_action_icon p-10 ps-0'
                                              onClick={()=> setSearchString("") }>
                                                  <div className='action_icon h25w25 h-100'>
                                                      <X
                                                          size={18}
                                                          className="c-icons"
                                                          weight="light"
                                                      />
                                                  </div>
                                              </a>
                                          }

                                </div>
                            </div>
                            <div className='d-flex flex-column'>
                            {groupListExternal && groupListExternal.filter((gd)=> gd.groupName.toLowerCase().includes(searchString.toLowerCase())).length > 0  ? 
                            <>
                            {
                                groupListExternal.filter((gd)=> gd.groupName.toLowerCase().includes(searchString.toLowerCase())).sort((a,b)=>{
                                    return b.unreadCount - a.unreadCount
                                }).map((gd)=>{      
                                    let conactID
                                    let comapnyName = ""
                                    if (proposalList && proposalList.length > 0) {
                                    let index = proposalList.findIndex((proposal) => proposal.proposal_id == gd.extraData.proposalId)
                                    if (index > -1) {
                                        conactID = proposalList[index].contact_id
                                    }
                                    if (proposalListNew && proposalListNew.length > 0) {
                                        let index = proposalListNew.findIndex((proposal) => proposal.proposal_id == gd.extraData.proposalId)
                                        if (index > -1) {
                                            comapnyName = proposalListNew[index].company_name
                                            
                                        }}
                                }
                                    return(<>
                                    <a href='#/' className={`d-flex py-10px border-bottom messaging-dropdown-list-items ${gd.lastMessage == "" ? "align-items-center" : ""}`}
                                      onClick={()=>{
                                        dispatch(setactiveChatScreenId(gd.groupId));
                                        if (localStorage.getItem("is_external") == 1) {
                                            dispatch(setChatGroupsList(gd.groupId || gd._id));
                                            dispatch(setChatGroupsListDetails(gd));
                                            dispatch(setLastmessageGroupDetails(gd));
                                        }else if (chatGroups.includes(gd.groupId)) {
                                            
                                        }else{
                                            var socketDataOut = {
                                                groupId: localStorage.getItem("activeGroupId"),
                                                userId: chatUserId,
                                              };
                                            dispatch(groupOut(socketDataOut));
                                            dispatch(setClearmessage());
                                            dispatch(setChatGroupsList(gd.groupId || gd._id));
                                            dispatch(setChatGroupsListDetails(gd));
                                            dispatch(setLastmessageGroupDetails(gd));
                                            setcontactId(conactID)
                                            setCompanyName(comapnyName)
                                        }
                                    }}>
                                    <div className='c-list-icon0 '>
                                        <div className='h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 comman-image-box position-relative'>
                                            { userType == "contact" && gd.extraData && gd.extraData.mainKey && gd.extraData.mainKey == "myr_support" ?
                                                <Headset
                                                    size={18}
                                                    weight="light"
                                                    className="c-icons"
                                                /> :
                                            <div className='comman-bg-img h-100 w-100 bg-style-cover rounded-circle'  style={{ backgroundImage: `url(${gd.displayPicture && gd.displayPicture != "" ? gd.displayPicture : USR_IMG_PLACEHOLDER})` }}></div>
                                            }
                                        </div>
                                    </div>
                                    <div className=' d-flex justify-content-between flex-grow-1'>
                                        <div className='ms-10px title-fonts d-flex w100minus100per flex-column'>
                                            <div className="align-items-center c-list-detail color-white d-flex fw-semibold">
                                            <span className='text-truncate'> {userType == "contact"  ? comapnyName : gd.groupName} </span>
                                            {userType == "operator" && <span className='c-font color-white-60 f-10 text-break text-truncate mx-1'> #{conactID}</span>}
                                            {userType == "contact" && <span className='c-font color-white-60 f-10 text-break text-truncate'>{gd.extraData && gd.extraData.mainKey && gd.extraData.mainKey == "myr_support" ? "Blaz support" : gd.extraData.mainKey }</span>}
                                            </div>
                                            {!(gd.lastMessage) || gd.lastMessage == ""  ? <></> :
                                            <div className='d-flex'>
                                                <div className='c-font f-10 color-white-60 text-break line-clamp-2 fw-semibold'>{gd.lastMsgUserName == localStorage.getItem("full_name") ? "" : gd.lastMsgUserName + " : "}  </div>
                                               {gd.lastMessage ?  <div className='c-font f-10 color-white-60 text-break line-clamp-2'>{gd.lastMessage}</div> : <></>}
                                            </div>
                                            }
                                        </div>
                                        <div className='d-flex flex-column'>
                                       <span class={`rounded-pill badge-custom  d-flex p-2 light-pill position-static ms-auto ${gd.unreadCount > 0 ? "" : "d-none" }`}>{gd.unreadCount}</span>
                                        {gd.lastMessage == "" ? <></> : 
                                        <span className="with_separator_10 ps-2 pe-0 c-font f-10"> {moment(gd.lastMessageDate).format("DD/MM/YYYY")}</span>}
                                        </div>
                                    </div>
                                </a>
                                    </>)
                                })

                            }
                            </> : <>
                                <CommanPlaceholder imgType="no-user" mainWidth="m-auto" placeholderText={_l("l_no_user")} />
                            </>}
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            </div>
        </div>
    </div>
  )
}

export default SolarFooter