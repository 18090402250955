import React, { useEffect, useState} from "react";
import 'intro.js/introjs.css';
import "react-datepicker/dist/react-datepicker.css";
import { _l, showLeftPanelNew } from "../../hooks/utilities";
import Geocode from "react-geocode";
import { useNavigate } from "react-router-dom";
import { GoogleMap, Marker,  InfoWindow, } from "@react-google-maps/api";
import { MagnifyingGlass, X} from "phosphor-react";
import { useSelector} from "react-redux";
import MapOfficeDropdown from "../Dropdowns/MapOfficeDropdown";
import CustomerServices from "../../services/customer-services";
import jquery from "jquery";
import { mapStyle } from "../../assets/map/mapStyle";
import CustomMapPinGreen from "../../assets/images/CustomMapPinGreen.svg";
import { DEFAULT_LAT, DEFAULT_LNG } from "../../constants/constants";

const MapLeft = ({ }) => {
    // const [locationLetAndLng, setLocationLetAndLng] = useState({});
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_SECRET);
    const [address, setAddress] = useState([]);
    const [defaultAddress, setDefaultAddress] = useState([]);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [searchSpace, setSearchSpace] = useState('');
    const [locationLetAndLng, setLocationLetAndLng] = useState([]);
    const [selectedSpace, setSelectedSpace] = useState({});
    const [selectedLatLang, setSelectedLatLang] = useState({});

    const spaceState = useSelector((state) => state.customer.spaces);
    const onPagechangeToggle = () => {
        showLeftPanelNew();
      };
    const [activeMarker, setActiveMarker] = useState(null)
    useEffect(() => {
        if (spaceState && spaceState.length) {
            getLatitudeLongitude()
            setAddress(spaceState.filter((space) => space.status == 1 || space.status == 2));
            setDefaultAddress(spaceState.filter((space) => space.status == 1 || space.status == 2));
        }
    }, [spaceState])

    useEffect(() => {
        if (searchSpace != '') {
            setAddress(defaultAddress.filter((space) => (space &&
                space.postal_address &&
                space.postal_address
                    .toLowerCase()
                    .includes(searchSpace.toLowerCase()))
            ))
        } else {
            setAddress(defaultAddress)
        }
    }, [searchSpace])

    const getAddressForMapLocation = (tempAddress) => {
        return new Promise(function (myResolve, myReject) {
            Geocode.fromAddress(tempAddress).then(
                (response) => {
                    const { lat, lng } = response.results[0].geometry.location;
                    return myResolve({
                        lat: lat,
                        lng: lng,
                    });
                },
                (error) => {
                    console.log(error);
                    return myResolve({
                        lat: DEFAULT_LAT,
                        lng: DEFAULT_LNG,
                    });
                }
            );
        });

    };
    
    const getLatitudeLongitude = async () => {
        let newArr = [];
        let firstLatLang = {}
        for (let space of spaceState) {
            if (space.postal_address != '' && (space.status == 1 || space.status == 2)) {
                let latLng = await getAddressForMapLocation(space.postal_address)
                latLng['project_id'] = space.project_id
                latLng['show'] = false
                firstLatLang = latLng
                newArr.push(latLng)
            }
        }
        setSelectedLatLang(firstLatLang)
        setLocationLetAndLng(newArr)
    }
    const handleActiveMarker = (project_id) => {
        if (project_id === activeMarker) {
            return;
        }
        showModal(project_id)

    };
    const showModal = (project_id, is_hide = 0) => {

        CustomerServices.getSpaceCountDetails(project_id).then((res) => {
            if (res.status) {
                setSelectedSpace(res.data)
                setActiveMarker(project_id);
            }
        });

    }

    return (
        <>
            <div className="h-100 map-box position-relative">
                {
                    searchSpace != '' ?
                        <ul className="dropdown-menu dropstart w-200 overflowscroll show end-0 mt-5 ">
                            {address && address.length ? (
                                address.map((space) => (< li className="comman-list " onClick={async () => {
                                    let latLang = await getAddressForMapLocation(space.postal_address)
                                    setSelectedLatLang(latLang)
                                }}>
                                    <div className="d-flex align-items-center text-truncate c-list-detail text-truncate color-white-60">{space.postal_address}</div>
                                </li>))

                            )
                                : <></>
                            }</ul> : <></>
                }

                {/* <iframe className="map-box " src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15282225.79979123!2d73.7250245393691!3d20.750301298393563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30635ff06b92b791%3A0xd78c4fa1854213a6!2sIndia!5e0!3m2!1sen!2sin!4v1587818542745!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen
                    aria-hidden="false"
                    tabIndex="0">
                </iframe> */}

                {locationLetAndLng && locationLetAndLng.length && Object.keys(selectedLatLang).length ? (
                    <GoogleMap
                        // className="map-box "
                        options={{
                            streetViewControl: false,
                            scrollwheel: false,
                            styles: mapStyle
                        }}
                        onClick={() => { setActiveMarker(null); setSelectedSpace({}) }}
                        center={selectedLatLang}
                        zoom={8}
                    >
                        <div className="pill-wrapper search_with_absolute position-absolute">
                            <div className="comman_glass_effect">
                                <div className="d-flex align-items-center filter-lists-menu min-width-190 justify-content-end">
                                    <div className="d-flex commn-search radius_3 justify-content-between d-none">
                                        <div className="comment-input-box">
                                            <input type="text" placeholder="Search" autocomplete="off" className="form-control bg-transparent with-base-bg border-0 py-1 px-2 f-11 c-font" value={searchSpace} onChange={(e) => {
                                                setSearchSpace(e.target.value)
                                            }} />
                                        </div>
                                        <a href="#/" className="comman_action_icon comman_glass_effect">
                                            <div className="d-flex align-items-center ">
                                                <div className="action_icon h25w25 d-none">
                                                    <X size={14} className="c-icons filter-img" weight="light" />
                                                </div>
                                                <div className="action_icon h25w25">
                                                    <MagnifyingGlass size={14} className="c-icons" weight="light" />
                                                </div>
                                            </div>
                                        </a>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <a href="#/" className="comman_action_icon close_absolute comman_glass_effect" onClick={() => {
                    navigate("/dashboardpre");
                    onPagechangeToggle();
                  }}>
                                            <div className="d-flex align-items-center ">
                                                <div className="action_icon h35w35">
                                                    <X size={18} className="c-icons filter-img" weight="light" />
                                                </div>
                                                
                                            </div>
                                        </a>
                        {
                            locationLetAndLng.map((latLng, key) => {
                                return <Marker position={{
                                    lat: latLng.lat,
                                    lng: latLng.lng

                                }} onClick={
                                    () => {
                                        // showModal(latLng.project_id)
                                        handleActiveMarker(latLng.project_id)
                                    }

                                } key={key} icon={CustomMapPinGreen} >

                                    {activeMarker === latLng.project_id ? (
                                        <InfoWindow onCloseClick={() => setActiveMarker(null)} zIndex={234234234}>
                                            <MapOfficeDropdown data={selectedSpace} />
                                        </InfoWindow>
                                    ) : null}
                                </Marker>
                            })
                        }


                    </GoogleMap>
                ) : (
                    <></>
                )}

            </div>
        </>
    );
};
export default MapLeft;