import React, { useState } from 'react'
import { DropdownMenu } from 'reactstrap';
import CommanPlaceholder from '../Placeholder/CommanPlaceholder';
import { _l } from '../../hooks/utilities';

const InstallationAssigneeDropDown = ({employeeData, installAssignee, banchmarck, assigneeInstallationLoader}) => {
  return (
    <>
    <DropdownMenu
        className={`dropdown-menu w-140 overflow-auto`}
        container="body"
        direction="down"
      >
        <ul>
              {employeeData && employeeData.length > 0 ? (
                employeeData.map((item, i) => {
                  return (
                    <>
                      <li className="" key={i}>
                        <a
                          href="#/"
                          className={`custom-assignees custom-class-active dropdown-item 
                          ${assigneeInstallationLoader 
                            ? 'for-disabled' 
                            : banchmarck.solar_task_ids[0].assignee_id && item.staff_id == Number(banchmarck.solar_task_ids[0].assignee_id) 
                              ? '' 
                              : banchmarck.solar_task_ids[0].assignee_id 
                                ? 'for-disabled' 
                                : ''} 
                          ${item.staff_id == Number(banchmarck.solar_task_ids[0].assignee_id) ? "active" :''}
                             `}
                        >
                          <div className="d-flex align-items-center text-truncate"
                            onClick={() => installAssignee(item, banchmarck.solar_task_ids[0].assignee_id && item.staff_id == Number(banchmarck.solar_task_ids[0].assignee_id) ? "remove" : '')}
                          >
                            <div className="d-flex align-items-center w100minus100per text_wrap">
                              <div className="c-list-icon ">
                                <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
                                    <div
                                      className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                      style={{
                                        backgroundImage: `url(${item.profile_image})`,
                                      }}
                                    ></div>
    
                                </div>
                              </div>
                              <div className="ps-2 w100minus10 text_wrap">
                                <div className="c-list-detail text-truncate ">
                                  {_l(`${item.full_name}`)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li className="dropdown-divider my-0"></li>
                    </>
                  );
                })
              ) : (
                <CommanPlaceholder
                  imgWidth="h60w60"
                  mainSpacing="py-3"
                  placeholderText={_l("l_no_assignee")}
                />
              )}
        </ul>
      </DropdownMenu>
    </>
  )
}

export default InstallationAssigneeDropDown