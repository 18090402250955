import React, { useRef, useState, useEffect} from "react";
import { _l, getImagePreviewIcon, handleImageUploadPreviews } from "../../hooks/utilities";
import { X, ArrowRight, DotsThreeVertical, Paperclip } from "phosphor-react";
import { MentionsInput, Mention } from "react-mentions";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import ReactHtmlParser from "react-html-parser";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { useDispatch } from "react-redux";
import { showMessage } from "../../actions/messages";

const ScheduleComments = ({ slotComments, addEditSlotComment, deleteSlotCommnet, readOnlyMode, id, toggle }) => {

  const dispatch = useDispatch();

  const [imagePreviews, setImagePreviews] = useState([]);
  const [commentInput, setCommentInput] = useState("");
  const [files, setFiles] = useState();

  const editCommentId = useRef(-1);

  const contact_id = localStorage.getItem("contact_id");
  const staff_id = localStorage.getItem("staff_id");
  const userType = localStorage.getItem("user_type");
  const contact_image = localStorage.getItem("contact_image");

  const preparePreview = (files) => {
    let previrewArray = [];
    Array.from(files).map((img) => {
      let preview_url = getImagePreviewIcon(img);
      previrewArray.push({ name: img.name, url: preview_url });
    });
    setFiles(files);
    setImagePreviews(previrewArray);
  };

  const removeFileSelection = (preview) => {
    handleImageUploadPreviews(imagePreviews, setImagePreviews, preview, true);
    if (files) {
      setFiles(Array.from(files).filter((x) => x.url != preview.url));
    }
  };

  const addComment = () => {
    addEditSlotComment(commentInput, files, editCommentId.current);
    setCommentInput("");
    setImagePreviews([]);
    setFiles();
    editCommentId.current = -1;
  }

  const scrollToBottom = () => {
    var commentsAreaDiv = document.getElementById("conversation-area");
    try {
      if (commentsAreaDiv.scrollHeight && commentsAreaDiv.scrollHeight != null)
        commentsAreaDiv.scrollTop = commentsAreaDiv.scrollHeight
          ? commentsAreaDiv.scrollHeight
          : 0;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [toggle, slotComments]);

  return (
    <ul
      className="dropdown-menu h-auto"
      aria-labelledby={`minified_comment_${id}`}
      id={`commentDropDownOpen_${id}`}
    >

      <React.Fragment>
        <ul className="dropdown-head">
          <li>
            <div className="dropdown-header">
              <div className="d-flex align-items-center justify-content-between">
                <div className="c-list-detail title-fonts text-truncate color-white c-font f-13">
                  {_l("l_comments")}
                </div>
                <a href="#/" className="right-image d-none" data-bs-dismiss="dropdown">
                  <X size={18} weight="light" className="c-icons opacity-60" />
                </a>
              </div>
            </div>
          </li>
          <li>
            <hr className="dropdown-divider mt-0" />
          </li>
          <li></li>
        </ul>
        <ul className="dropdown-contain overflowscroll comment-dropdown-with-min-height-20vh" id="conversation-area">
          {slotComments && slotComments.length ? (
            slotComments.map((comment, index) => {
              const commentContent = comment.content;
              const dummyDiv = document.createElement("div");
              dummyDiv.innerHTML = commentContent;
              return (
                <div className="chat-wrapper">
                  <div
                    className={`chat-box max-w-100 ${(userType == "contact" &&
                      contact_id === comment.contact_id) ||
                      (userType == "staff" &&
                        staff_id == comment.staffid)
                      ? ""
                      : ""
                      }`}
                    key={index}
                  >
                    <div className="d-flex">
                      <div
                        className="h30w30 comman-image-box with-bg rounded-circle bg-white-05"
                        data-bs-toggle="tooltip"
                        aria-expanded="false"
                        data-tip={comment.full_name}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                      >
                        <div
                          className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                          style={{
                            backgroundImage: `url('${comment.profile_url}')`,
                          }}
                        ></div>
                      </div>
                      <div className="chat-msg">
                        <div className="chat-text-box">
                          <div className="image-upload-wrapper">
                            {comment.attachments &&
                              comment.attachments.length ? (
                              <div className="d-flex align-items-center flex-wrap ">
                                {comment.attachments.map(
                                  (image, index) => {
                                    if (index < 3) {
                                      return (
                                        <a href="#/"
                                          key={index}
                                          className="h60w60 comman-round-box  with-bg d-flex align-items-center bg-white-05 p-5px radius_3  m-1  with-top-overlay"
                                        >
                                          <div
                                            className="comman-bg-img h-100 w-100 bg-style-cover"
                                            style={{
                                              backgroundImage: `url('${image.image_url ? image.image_url : URL.createObjectURL(image)}')`,
                                            }}
                                          ></div>
                                        </a>
                                      );
                                    } else if (index === 3) {
                                      return (
                                        <a href="#/"
                                          className="h62w62 comman-round-box  with-bg d-flex align-items-center bg-white-05 p-5px radius_3 m-1 with_overlay"
                                          onClick={() => { }}
                                        >
                                          <div class="unread_count m-auto">
                                            +
                                            {comment.attachments
                                              .length - 3}
                                          </div>
                                        </a>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="chat-text c-font f-12">
                            {" "}
                            {ReactHtmlParser(comment.content)
                            }
                          </div>
                          <div
                            className={`dropdown chat-action-option ${comment.is_allowed_to_edit == "0" || readOnlyMode
                              ? "d-none"
                              : ""
                              }`}
                          >
                            <a href="#/"
                              className="line-height-0 "
                              type="button"
                              id="commentOptionDropdown"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              data-tip={_l("l_options")}
                              data-effect="solid"
                              data-delay-show='1000'
                              data-class="tooltip-main"
                            >
                              <DotsThreeVertical size={18} weight="light" className="c-icons" />
                            </a>
                            <ActionDropdown
                              actions={[
                                {
                                  actionName: _l("l_edit"),
                                  actionHandler: () => {
                                    editCommentId.current = index;
                                    setCommentInput(comment.content);
                                  },
                                  actionIcon: "PencilSimpleLineIcon",
                                },
                                {
                                  actionName: _l("l_delete"),
                                  actionHandler: () => {
                                    deleteSlotCommnet(index);
                                  },
                                  actionIcon: "TrashIcon",
                                },
                              ]}
                            />
                          </div>
                        </div>
                        <div className="chat-send-name c-font f-10 opacity-50">
                          <span>{comment.date}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <>
              <CommanPlaceholder imgType="no-comment" placeholderText={_l("l_no_comments")} />
            </>
          )}
        </ul>
        <ul className="dropdown-footer">
          <li>
            <hr className="dropdown-divider mb-0" />
          </li>
          <li className="">
            <React.Fragment>
              {imagePreviews.map((preview, index) => {
                return (
                  <div key={index} className="comman-image-box h40w40 radius_3 upload-image with-margin">
                    <div
                      className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                      style={{
                        backgroundImage: `url('${preview.url}')`,
                      }}
                    ></div>
                    <a href="#/"
                      className="upload-close"
                      onClick={() => removeFileSelection(preview)}
                    >
                      <X size={10} className="c-icons" weight="light" />
                    </a>
                  </div>
                );
              })}
            </React.Fragment>
            <div className={`chat-comment-box ${readOnlyMode ? "d-none" : ""}`}>
              <div className="comment-input-box with-mention-top">
                <div className="d-flex position-relative form-control  border-0 bg-transparent px-2 align-items-center">
                <div
                      data-bs-toggle="tooltip"
                      className="comman-image-box h25w25 rounded-circle me-2"
                    >
                      <div
                        className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                        style={{
                          backgroundImage: `url('${contact_image}')`,
                        }}
                      ></div>
                    </div>
                  <MentionsInput
                    className="mension-box with-calc-95px"
                    value={commentInput}
                    placeholder={`${_l("l_write_comment_placeholder")}`}
                    alwaysOpen={true}
                    onChange={(e) => {
                      setCommentInput(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 && !e.ctrlKey && !e.shiftKey) {
                        e.preventDefault();
                        addComment();
                      }
                    }}
                    id="comment_input"
                  >
                    <Mention
                      markup='<span style="font-weight: 600;"  contenteditable="false" data-mention-id="__id__"  data-mce-style="font-weight: 600;"  data-mce-selected="1">@__display__</span>'
                      trigger="@"
                      data={[]}
                      renderSuggestion={(
                        suggestion,
                        search,
                        highlightedDisplay
                      ) => {
                        return (
                          <div className="user d-flex align-items-center">
                            <div className="comman-image-box h25w25 rounded-circle mx-2">
                              <div
                                className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                style={{
                                  backgroundImage: `url('${suggestion.image}')`,
                                }}
                              ></div>
                            </div>
                            {highlightedDisplay}
                          </div>
                        );
                      }}
                    />
                  </MentionsInput>
                  <div className="d-flex">
                    <div className={`comman_action_icon with_separator_10 before-dnone`}>
                      <div className="d-flex align-items-center">
                        <a href="#/" className="action_icon with_bg no-hover"
                          data-tip={_l('l_choose_file')}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                        >
                          <input
                            type="file"
                            multiple="multiple"
                            className="absolute-input"
                            accept=".png,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.zip,.rar,.txt,.jpeg,.mp4,.m4r,.m4a,.mp3,.glb"
                            onChange={(event) => {
                              if (event.target.files.length > 4) {
                                dispatch(
                                  showMessage(
                                    "unsucess",
                                    _l("l_error"),
                                    _l(
                                      "l_maximum_4_files_are_allowed_at_a_time"
                                    )
                                  )
                                );
                                return;
                              }
                              preparePreview(event.target.files);
                            }}
                            onClick={(e) => {
                              e.target.value = "";
                            }}
                          />
                          <Paperclip size={16} weight="light" className="c-icons" />
                        </a>
                      </div>
                    </div>
                    <div className="comman_action_icon">
                      <a href="#/"
                        className="action_icon with_bg no-hover"
                        onClick={addComment}
                      >
                        <ArrowRight size={16} weight="light" className="c-icons lt-black" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </React.Fragment>
    </ul>
  );
};

export default ScheduleComments;
