import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { _l, getFileType, roundOffPowerValue, showminimizeChatmodal } from "../../hooks/utilities";
import moment from "moment";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import ProposalCard from "./ProposalCard";
import SolarDocumentPreview from "./SolarDocumentPreview";
import { ChatCircle, FileText } from "phosphor-react";
import MapIframe from "./MapIframe";
import SolarFooter from "./SolarComponents/SolarFooter";
import ExternalChecklist from "./SolarComponents/ExternalChecklist";
import ExternalComment from "./SolarComponents/ExternalComment";
import FIleUpload from "./SolarComponents/FIleUpload";
import EmailsInboxReply from "../MyEmail/EmailsInboxReply";

const ProposalListForCustomer = ({ onClickHandler }) => {
  const { benchmarkDetail, globalSearchString } = useSelector((state) => state.customer);
  const {groupListExternal } = useSelector((state) => state.chat);

  const [emailAddress, setEmailAddress] = useState("");
  const [lightBoxData, setLightBoxData] = useState([]);

  let proposalList =
  benchmarkDetail && benchmarkDetail.proposal_list &&
    benchmarkDetail.proposal_list.length &&
    benchmarkDetail.proposal_list[0].proposals
      ? benchmarkDetail.proposal_list[0].proposals
      : [];

  proposalList.sort(
    (a, b) =>
      b.amount - a.amount ||
      b.company_total_review - a.company_total_review ||
      Number(b.is_updated) - Number(a.is_updated)
  );
  let acceptedProposals = proposalList.filter((x) => x.status == 3);
  let rejectedProposals = proposalList.filter((x) => x.status == 2);
  let pendingProposals = proposalList.filter((x) => x.status == 4);
  proposalList = acceptedProposals.concat(pendingProposals, rejectedProposals);

  const [openLightBox, setOpenLightBox] = useState(false);

  useEffect(() => {
    let attachments = [];
    if (benchmarkDetail && benchmarkDetail.solar_map) {
      attachments.push({
        src: benchmarkDetail.solar_map,
        type: getFileType(benchmarkDetail.solar_map),
      });
    }

    if (benchmarkDetail && benchmarkDetail.attachments && benchmarkDetail.attachments.length) {
      benchmarkDetail.attachments.forEach((attachment) => {
        attachments.push({
          src: attachment.attachment_url,
          type: getFileType(attachment.attachment_url),
        });
      });
    }

    setLightBoxData(attachments);
  }, []);

  return (
    <>    
      <div className="d-flex flex-grow-1 bg-white h-100 pb-20px d-none">
        <div className="d-flex  flex-grow-1  flex-column gap10px">
        <div className=" overflow-hiiden-auto d-flex flex-column h-100 flex-grow-1">
          <EmailsInboxReply />
          </div>
        </div>
        <div className="border-left">
          <ExternalComment/>
        </div>
      </div>
         
      {benchmarkDetail && benchmarkDetail.id > 0 ? (
        <React.Fragment>
          {/* <div className="container h-100"> */}
          <div className="d-flex flex-column justify-content-between flex-grow-1 h-100 ps-0 ps-md-3 ps-xl-0">
            {/* contanr start  */}
            <div className="flex-grow-1 d-flex flex-column overflow-hiiden-auto">
              <div className="row mt-30px">
                {/* <div className="col-lg-2"></div> */}
                <div className="col-12 col-xl-9 col-xxl-9 registration-footer-content mx-auto">
                  <div className="border flex-column-reverse flex-sm-row radius10 row g-0 comman-white-box-shadow overflow-hidden mb-10 bg-white-03 shadow-none">
                    <div className="col-lg-8">
                      <div className="d-flex align-align-items-center h-100 flex-column pt-20px pb-20px ps-20pximp pe-10px resps10">
                        <div className="d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-between mb20px">
                          <div className="text-truncate  with_separator_10 before-dnone">
                            <div className="c-font f-16 fw-semibold text-truncate title-fonts">
                              {_l("l_solar_installation_title")}{" "}
                              {`${roundOffPowerValue(
                                benchmarkDetail.solar_items
                                  .electricity_production
                              )} Kwc`}
                            </div>
                            <div className="c-font color-white-60 f-12 pt-1 text-truncate">
                              {benchmarkDetail.address}
                            </div>
                          </div>
                          {proposalList.length ? (
                            <div className="with_separator_10 pe-0 pt-3 pt-sm-0">
                              <div className="btn btn-white-05 c-font f-12 fw-semibold position-relative rounded-5 btn-with-default-cursor">
                                <FileText
                                  size={15}
                                  className="c-icons"
                                  weight="light"
                                />
                                <span className="ms-10px c-font f-12">
                                  {proposalList.length}
                                </span>
                                <span className="ms-10px c-font f-12">
                                  {_l("l_proposals")}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                        </div>

                        <div className="align-items-start align-items-sm-center flex-wrap flex-sm-row  d-flex">
                          <div className="with_separator_10 flex-grow-1 ps-20pximp pe-20pximp">
                            <div className="c-font f-16 fw-semibold title-fonts">
                              {moment(benchmarkDetail.startdate).format(
                                "DD/MM/YYYY"
                              )}
                            </div>
                            <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                              {_l("l_start_date")}
                            </div>
                          </div>
                          <div className="with_separator_10 flex-grow-1 ps-20pximp py-sm-3 py-sm-0 pe-20pximp mb-3 mb-md-0">
                            <div className="c-font f-16 fw-semibold title-fonts">
                              {benchmarkDetail.solar_items
                                ? benchmarkDetail.solar_items.monthly_bill
                                : 0}{" "}
                              €
                            </div>
                            <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                              {_l("l_monthly_energy_bill")}
                            </div>
                          </div>
                          <div className="with_separator_10 flex-grow-1 ps-20pximp pe-20pximp d-none">
                            <div className="c-font f-16 fw-semibold title-fonts">
                              {benchmarkDetail.superficy}m²
                            </div>
                            <div className="c-font color-white-60 f-12 pt-1 text-wrap before-dnone">
                              {_l("l_superficy")}
                            </div>
                          </div>
                          <div className="with_separator_10 flex-grow-1 ps-20pximp pe-20pximp before-dnone ">
                            <div className="c-font f-16 fw-semibold title-fonts">
                              {benchmarkDetail.solar_items
                                ? benchmarkDetail.solar_items.no_of_plates
                                : 0}
                            </div>
                            <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                              {_l("l_solar_panels")}
                            </div>
                          </div>
                          <div className="with_separator_10 flex-grow-1 ps-20pximp pe-20pximp d-none">
                            <div className="c-font f-16 fw-semibold title-fonts">
                              {benchmarkDetail.no_of_hiring}
                            </div>
                            <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                              {_l("l_tenants")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {benchmarkDetail.solar_map ? (
                      <div
                        className="col-lg-4 d-flex flex-column border-start position-relative"
                        onClick={() => setOpenLightBox(true)}
                      >
                        {benchmarkDetail &&
                        benchmarkDetail.attachments &&
                        benchmarkDetail.attachments.length ? (
                          <div className="viewmorebox h60w60 radius10px title-fonts position-absolute z-1 bottom-0 ms-10px mb-10px align-items-center bgyellow d-flex flex-column  justify-content-center text-center text-black">
                            <div class="fs-3 fw-bold lh-1">
                              {benchmarkDetail.attachments.length}
                            </div>
                            <div class="f-12 c-font">{_l("l_images")}</div>
                          </div>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                        <img
                          className="h-100 w-100 object-fit-cover"
                          src={benchmarkDetail.solar_map}
                          alt={"Solar Map"}
                        />
                      </div>
                    ) : (
                      <MapIframe address={benchmarkDetail.address} />
                    )}
                  </div>
                </div>
                {/* <div className="col-lg-2"></div> */}
              </div>
              <div className="row flex-grow-1 overflow-auto">
                {/* <div className="col-lg-2"></div> */}
                <div className="col-12 col-xl-9 col-xxl-9 registration-footer-content mx-auto">
                  <div className="d-grid gap-2">
                    {proposalList.length ? (
                      proposalList.filter((p)=> Number(p.amount) > 0 && (p.company_name.toLowerCase().includes(globalSearchString.toLowerCase()) || p.address.toLowerCase().includes(globalSearchString.toLowerCase()))).sort((a,b)=>{
                        let countA = 0;
                        if (groupListExternal && groupListExternal.length) {
                          groupListExternal.map((gd)=>{
                            if (gd.extraData && gd.extraData.proposalId == a.proposal_id) {
                              countA = gd.unreadCount
                            }
                          })
                        }
                        let countB = 0;
                        if (groupListExternal && groupListExternal.length) {
                          groupListExternal.map((gd)=>{
                            if (gd.extraData && gd.extraData.proposalId == b.proposal_id) {
                              countB = gd.unreadCount
                            }
                          })
                        }
                        return countB - countA 
                      }).map((proposal, index) => {
                        return (
                          <ProposalCard
                            key={index}
                            proposalDetail={proposal}
                            onClickHandler={onClickHandler}
                            setEmailAddress={setEmailAddress}
                            emailAddress={emailAddress}
                          />
                        );
                      })
                    ) : (
                      <div className="bg-white-03 border pb-20px radius_5">
                        <CommanPlaceholder
                          altImgText={"l_waiting_for_proposals"}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="col-lg-2"></div> */}
              </div>
            </div>
            {/* contanr end  */}
          </div>
          {/* </div> */}
          <div className="d-flex align-items-center position-absolute bottom-20 right-20 d-none">
            <a
              href="#/"
              className="btn btn-primary fw-semibold light-theme-blue-bg position-relative"
              onClick={() => {
                window.open(`/aichat?proposal_id=${0}`, "_blank");
              }}
            >
              <ChatCircle size={15} className="c-icons" weight="light" />
              <span className="ms-10px c-font f-12">{"Blaz Support"}</span>
            </a>
          </div>
        </React.Fragment>
      ) : (
        <></>
      )}
      {openLightBox && lightBoxData.length ? (
        <SolarDocumentPreview
          open={openLightBox}
          setOpen={setOpenLightBox}
          slides={lightBoxData}
        />
      ) : (
        <></>
      )}
        {/* <SolarFooter /> */}
    </>
  );
};

export default ProposalListForCustomer;
