import React from 'react';
// import CSS from '../css/icon_main.css';

function StepIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<g clipPath="url(#clip0_14078_218841)">
<path d="M6.58203 15.7614L15.4354 6.7168M0.234375 15.6406L15.2977 0.251953M6.10547 3.00684L9.36953 6.34139M5.83984 9.88965L9.09053 13.2105M0.238281 9.03785L8.85039 0.239258M0.746094 15.2528V0.74707H14.9241V15.2528H0.746094Z"/>
</g>

</svg>




		</React.Fragment>
	)
}

export default StepIcon