import React from 'react';
// import CSS from '../css/icon_main.css';

function ImageIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
			<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M3.22811 11.0625C2.48366 9.80655 2.22327 8.32202 2.4958 6.88764C2.76834 5.45326 3.55506 4.16768 4.70829 3.27225C5.86151 2.37682 7.30192 1.93312 8.7591 2.02444C10.2163 2.11576 11.59 2.73583 12.6224 3.76823C13.6548 4.80064 14.2749 6.17438 14.3662 7.63156C14.4575 9.08874 14.0138 10.5292 13.1184 11.6824C12.223 12.8356 10.9374 13.6223 9.50302 13.8949C8.06864 14.1674 6.5841 13.907 5.32811 13.1625V13.1625L3.25311 13.75C3.1681 13.7749 3.07796 13.7764 2.99215 13.7545C2.90633 13.7325 2.82801 13.6879 2.76537 13.6253C2.70274 13.5627 2.65811 13.4843 2.63616 13.3985C2.61421 13.3127 2.61575 13.2226 2.64061 13.1375L3.22811 11.0625Z"/>
</svg>





	


		</React.Fragment>
	)
}

export default ImageIcon