import React from 'react';
// import CSS from '../css/icon_main.css';

function PremiumEstimateIconCrown(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

		<svg width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M8.74282 3.98546C8.74407 4.00781 8.74243 4.03056 8.73681 4.05316L8.23564 6.05784C8.21037 6.15883 8.11985 6.22984 8.01579 6.23039L5.00878 6.24558C5.00839 6.24558 5.00802 6.24558 5.00763 6.24558L2.00059 6.24558C1.89598 6.24558 1.80483 6.17432 1.77955 6.07281L1.27839 4.06054C1.27261 4.03734 1.27094 4.01395 1.2724 3.99102C1.07853 3.92993 0.9375 3.74851 0.9375 3.53471C0.9375 3.27091 1.15209 3.05632 1.41589 3.05632C1.67969 3.05632 1.89428 3.27091 1.89428 3.53471C1.89428 3.68327 1.82621 3.81618 1.71963 3.904L2.34709 4.5362C2.50567 4.69599 2.72573 4.7876 2.95086 4.7876C3.21706 4.7876 3.47086 4.66119 3.63069 4.44939L4.66188 3.08305C4.57526 2.99649 4.52162 2.87687 4.52162 2.74499C4.52162 2.48119 4.73621 2.2666 5 2.2666C5.2638 2.2666 5.47839 2.48119 5.47839 2.74499C5.47839 2.87292 5.42761 2.98904 5.34551 3.07497C5.34578 3.07534 5.34611 3.07564 5.34639 3.07603L6.37011 4.44614C6.52987 4.65997 6.78459 4.78763 7.05155 4.78763C7.27874 4.78763 7.49239 4.69915 7.65304 4.53848L8.28445 3.90706C8.17562 3.81928 8.10573 3.68509 8.10573 3.53471C8.10573 3.27091 8.32032 3.05632 8.58412 3.05632C8.84792 3.05632 9.06251 3.27091 9.06251 3.53471C9.06251 3.7428 8.92871 3.91979 8.74282 3.98546ZM8.18674 6.95936C8.18674 6.83355 8.08474 6.73156 7.95893 6.73156L2.06641 6.73156C1.9406 6.73156 1.8386 6.83355 1.8386 6.95936L1.8386 7.50609C1.8386 7.6319 1.9406 7.7339 2.06641 7.7339L7.95893 7.7339C8.08474 7.7339 8.18674 7.6319 8.18674 7.50609V6.95936Z" fill="url(#paint0_linear_1173_111418)"/>
<defs>
<linearGradient id="paint0_linear_1173_111418" x1="0.9375" y1="2.2666" x2="5.95489" y2="9.9118" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFC700"/>
<stop offset="1" stopColor="#FDD500"/>
</linearGradient>
</defs>
</svg>

		</React.Fragment>
	)
}

export default PremiumEstimateIconCrown