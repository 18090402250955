import React from 'react';
// import CSS from '../css/icon_main.css';

function UserCircleIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M8 10C9.38071 10 10.5 8.88071 10.5 7.5C10.5 6.11929 9.38071 5 8 5C6.61929 5 5.5 6.11929 5.5 7.5C5.5 8.88071 6.61929 10 8 10ZM8 10C7.16899 10 6.3542 10.2297 5.64596 10.6644C4.93772 11.0992 4.36369 11.7215 3.9875 12.4625M8 10C8.83101 10 9.6458 10.2297 10.354 10.6644C11.0623 11.0992 11.6363 11.7215 12.0125 12.4625M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z" />
</svg>


		</React.Fragment>
	)
}

export default UserCircleIcon