import React, { useEffect, useState} from "react";
import Tasks from "../components/Tasks";
import { useNavigate } from "react-router-dom";
import jquery from "jquery";
import { useDispatch, useSelector } from "react-redux";
import {   CardListScroll,  setPageMetaDataDescription, CardListFullHeight, DefaultLeftPanel, showLeftPanelNew, hideLeftPanelNew, formateDate } from "../hooks/utilities";
import { DEFAULT_METADATA_DESCRIPTION } from "../constants/constants";
import Customcalendar from "../components/Customcalendar";
import MySpaceNew from "./MySpaceNew";
import { SetSelectedTask, commanoffcanvasisOpen, getTaskCount, setSelectedGloablView } from "../actions/customer";
import moment from "moment";
import AiChat from "./AiChat";
import MyTask from "../components/SolarExternalPages/SolarComponents/HomePage";

const Dashboard = () => {
  const navigate = useNavigate();
  const {globalView, commanoffcanvasIsOpen, taskFilters, activeTaskStatusFilters, leftPanelTab} = useSelector((state) => state.customer);
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [isStatusCleared] = useState(false);
  const [taskModalClose] = useState(false);
  // const [ModalClose, setModalClose] = useState(false);

  const dispatch = useDispatch();
  
  const [IsKanbanView, setIsKanbanView] = useState(localStorage.getItem("fav_dashboard") == "kanban_view" ? true : false);
  const [selectedEventDate, setselectedEventDate] = useState("");
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const selectStartDate  = localStorage.getItem("fromDate");

  const client_id = localStorage.getItem("client_id");
  const contact_id = localStorage.getItem("contact_id");
  const contact_role = localStorage.getItem("contact_role");


  useEffect(()=>{
    localStorage.setItem("selectedDate", moment(new Date()).format("YYYY-MM-DD"));
  },[])

  useEffect(()=>{
    localStorage.setItem("selectedDate", moment(new Date()).format("YYYY-MM-DD"));
  },[])

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/login");
    } else {
      setIsLoggedin(true);
    }
    setPageMetaDataDescription(DEFAULT_METADATA_DESCRIPTION);
  }, []);
  useEffect(() => {
    // dispatch(commanoffcanvasisOpen(false));
    // dispatch(SetSelectedTask());
    if(globalView == " listView"){
      setIsKanbanView(false)
    }
    if(globalView === " spaceView"){
      CardListScroll();
    }
    if (globalView == "chat") {
      dispatch(setSelectedGloablView("liteVersionView"))
    }
    if(globalView ==="listView" || globalView ==="kanbanView" || globalView === "calendarView" || "my-clients" == leftPanelTab){
      showLeftPanelNew();
    }else{
      if (leftPanelTab != "my-staff") {
        DefaultLeftPanel(true);
      }
    }
    
    CardListFullHeight();
    if(globalView == "liteVersionView"){
      localStorage.setItem("tasklistView", "liteVersionView")
    }
    dispatch(getTaskCount(client_id, contact_id, projectId, formateDate(localStorage.getItem("selectedDate")), taskFilters, '', activeTaskStatusFilters, '', '', 0));
    return () => {
    }
    

  }, [globalView]);
  
  
  const spaces = localStorage.getItem("spaces") != null && localStorage.getItem("spaces") != undefined ? JSON.parse(localStorage.getItem("spaces")) : '';
  const savedProject = localStorage.getItem("selectedOffice");
  const [projectId] = useState(savedProject || "noData");
    

  useEffect(() => {
    if( window.location.pathname == "/"){
    if((projectId == 0 || projectId == null || projectId == undefined || projectId == "null") ) {
      jquery(document).mouseup(function (e) {
      var container = jquery(".fixed-left-panel");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        hideLeftPanelNew();
      }
    });
    } else if(commanoffcanvasIsOpen){
      hideLeftPanelNew();
    }else if((commanoffcanvasIsOpen == false && (projectId == 0 || projectId == null || projectId == undefined)) || contact_role == 3){
      hideLeftPanelNew();
    }else{
      showLeftPanelNew();
    }}
  })
 
  return (
    <>
{/* daily-schedule-main my-0 */}
      {isLoggedin ? (
        <div className={`d-flex flex-column h-100 ${globalView == "AichatView" ? "flex-grow-1 overflow-auto ai-chat-header position-relative" :""}`}>
         {
          leftPanelTab == "homePage" ? <>
          <MyTask/>
          </> 
          :
            globalView == "calendarView" ?
              <Customcalendar
                pageName="calendar"
                setselectedEventDate={setselectedEventDate}
                selectStartDate={selectStartDate}
                setDashboardStartDate={setstartDate}
                setDashboardEndDate={setEndDate}
              />
            : 
            globalView == "listView" || globalView == "liteVersionView" || globalView == "kanbanView"
            ? 
              <Tasks
                projectId={projectId}
                isStatusCleared={isStatusCleared}
                taskModalClose={taskModalClose}
                pageName="Dashboard"
                kanbanView={IsKanbanView} 
              />
            : globalView == "spaceView" ?
              <MySpaceNew />
            : globalView == "AichatView"?
              <AiChat />
            :
              <></>
            }
        </div>
      ) : (
        <></>
      )}
    </>
  );
}; 

export default Dashboard;
