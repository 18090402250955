import React, { useEffect, useState } from "react";
import { _l, getImagePreviewIcon, showError } from "../../../hooks/utilities";
import Select from "react-select";
import solarpanelsimg from "../../../assets/images/solarpanelsimg.svg";
import BenchmarkServices from "../../../services/benchmark-services";
import { Button, Form, Spinner } from "react-bootstrap";
import {
  showConfirmation,
  showMessage,
  toastCloseAction,
} from "../../../actions/messages";
import {
  Check,
  FilePlus,
  Paperclip,
  PencilLine,
  PencilSimpleLine,
  SelectionPlus,
  Trash,
  X,
} from "phosphor-react";
import { useDispatch } from "react-redux";
import EditProposalItem from "../Modals/EditProposalItem";
import ChooseCatalogItem from "../Modals/ChooseCatalogItem";
import AddCatalogItem from "../Modals/AddCatalogItem";
import ReactTooltip from "react-tooltip";
import PriceCatalogTabsSkeleton from "../../Skeleton/PriceCatalogTabsSkeleton";
import PriceCatalogDetailsProposalSkeleton from "../../Skeleton/PriceCatalogDetailsProposalSkeleton";
import PriceCatalogGeneralConditionsSkeleton from "../../Skeleton/PriceCatalogGeneralConditionsSkeleton";

const VATselectionOption = [
  { label: "0%", value: "0" },
  { label: "5%", value: "5" },
  { label: "10%", value: "10" },
  { label: "20%", value: "20" },
];

const paymentMethodOptions = [
  { label: _l("l_by_cheque"), value: "cheque" },
  { label: _l("l_by_bank_transfer"), value: "bank_transfer" },
  { label: _l("l_both"), value: "both" },
];

const ProviderPricingCatalog = () => {
  const [pricingTemplates, setPricingTemplates] = useState([]);
  const [allCatalogs, setAllCatalogs] = useState([]);
  const [activeCatalog, setActiveCatalog] = useState(0);
  const [isUpdateCatalogOngoing, setIsUpdateCatalogOngoing] = useState(false);
  const [defaultCatalog, setDefaultCatalog] = useState([]);
  const [currentIndexToEdit, setCurrentIndexToEdit] = useState(-1);
  const [catalogEditIndex, setCatalogEditIndex] = useState(-1);
  const [addCatalogItems, setAddCatalogItems] = useState(-1);
  const [addDirectItems, setAddDirectItems] = useState(-1);
  const [rel_type, setrel_type] = useState('');
  const [catlogDefaultKw, setCatlogDefaultKw] = useState(false);
  const [pricingTemplatesLoader, setPricingTemplatesloader] = useState({default : false, kwCatalog : false});

  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(-1);

  const getTempList = () => {
    setPricingTemplatesloader({...pricingTemplatesLoader, default: true });
    BenchmarkServices.getProviderCatalogTemplate().then((res) => {
      if (res && res.status) {
        setPricingTemplates(
          res.data.map((d, position) => {
            return {
              ...d,
              position,
              catlog_id: d.id,
              id: 0,
              value: 0,
              tax: 0,
            };
          })
        );
        setPricingTemplatesloader({...pricingTemplatesLoader, default: false });
      }else{
        setPricingTemplatesloader({...pricingTemplatesLoader, default: false });
      }
    });
  };
  const getCatalogPricing = async (catalogId, fetchDefaultTemplates) => {
    let defaultCatalogItems = defaultCatalog.slice();

    try {
      defaultCatalogItems.forEach((catalog) => {
        catalog.value = "";
        catalog.tax = "";
      });
      setPricingTemplatesloader({...pricingTemplatesLoader, kwCatalog: true });
      const response = await BenchmarkServices.getCatalogDetail(catalogId);
      if (response.status) {
        if (response.data && response.data.length) {
          setPricingTemplates(
            response.data.map((pt, position) => {
              pt.name = pt.item_name;
              pt.position = position;
              return pt;
            })
          );
        } else {
          if (
            defaultCatalogItems &&
            defaultCatalogItems.length > 0 &&
            !fetchDefaultTemplates
          ) {
            setPricingTemplates(
              defaultCatalogItems
            ); /* this line commented Bcz switching tab clear list some time */
          } else {
            getTempList();
          }
        }
        setPricingTemplatesloader({...pricingTemplatesLoader, kwCatalog: false });
      } else {
        if (defaultCatalogItems && defaultCatalogItems.length > 0) {
          setPricingTemplates(
            defaultCatalogItems
          ); /* this line commented Bcz switching tab clear list some time */
        } else {
          getTempList();
        }
        setPricingTemplatesloader({...pricingTemplatesLoader, kwCatalog: false });
      }
    } catch (e) {
      console.error(e);
      if (defaultCatalogItems && defaultCatalogItems.length > 0) {
        setPricingTemplates(
          defaultCatalogItems
        ); /* this line commented Bcz switching tab clear list some time */
      } else {
        getTempList();
      }
    }
  };

  const updatePriceCatalog = async () => {
    try {
      setIsUpdateCatalogOngoing(true);
      const response = await BenchmarkServices.updatePriceCatalog(
        pricingTemplates,
        activeCatalog
      );
      if (response.status) {
        await getCatalogPricing(activeCatalog);
        showMessage("l_details_saved");
        setIsUpdateCatalogOngoing(false);
      } else {
        setIsUpdateCatalogOngoing(false);
        showError("l_something_went_wrong");
      }
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const addNewPriceCatalog = () => {
    if (!allCatalogs.find((c) => c.capacity == "" || !c.capacity.length)) {
      setAllCatalogs(allCatalogs.concat([{ capacity: "", is_default: 0 }]));
      setCurrentIndexToEdit(allCatalogs.length);
      setActiveCatalog(-1);
    }
  };

  const savePriceCatalogInformation = async (index) => {
    let targetCapacityIndex = allCatalogs.findIndex((w, i) => i == index);
    if (targetCapacityIndex === -1) {
      showError("l_something_went_wrong");
      return false;
    } else {
      let isDuplicatePresent = false;
      allCatalogs.forEach((w, i) => {
        if (w.capacity == allCatalogs[index].capacity && i != index) {
          isDuplicatePresent = true;
        }
      });
      if (isDuplicatePresent) {
        showError("l_already_exists");
        return false;
      }
    }
    let targetCapacity = allCatalogs[targetCapacityIndex];
    if (targetCapacity) {
      let capacityVale = targetCapacity.capacity.replace(/\D/g, "");
      if (capacityVale > 0) {
        try {
          const response = await BenchmarkServices.saveCustomPriceCatalog(
            targetCapacity.id,
            capacityVale
          );
          if (response.status) {
            showMessage("l_added");
            setAllCatalogs(
              allCatalogs.map((w, i) => {
                if (i == index && (!w.id || w.id <= 0)) {
                  w.id = response.data;
                  w.is_default = 0;
                  w.capacity = capacityVale;
                }
                return w;
              })
            );
            setCurrentIndexToEdit(-1);
            setActiveCatalog(response.data);
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        showError("l_invalid_capacity_value");
      }
    }
  };

  const deleteCustomCatalog = (index) => {
    dispatch(
      showConfirmation(
        _l("l_are_you_sure"),
        _l("l_you_want_to_delete_price_catalog"),
        _l("l_yes"),
        _l("l_no"),
        () => {
          dispatch(toastCloseAction());
        },
        async () => {
          try {
            const catalogItemDetail = allCatalogs[index];
            if (catalogItemDetail.id > 0) {
              const response = await BenchmarkServices.deleteCustomPriceCatalog(
                catalogItemDetail.id
              );
              if (response.status) {
                setAllCatalogs(
                  allCatalogs.filter((w) => w.id != catalogItemDetail.id)
                );
                setActiveCatalog(allCatalogs[0].id);
                showMessage("l_catalog_deleted");
              } else {
                showError("l_something_went_wrong");
              }
            }
          } catch (e) {
            console.error(e);
          } finally {
            dispatch(toastCloseAction());
          }
        }
      )
    );
  };

  const saveCatalogFile = async (id, file) => {
    try {
      const response = await BenchmarkServices.saveCatalogAttachment(id, file);
      if (!response.status) {
        showError(response.message);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleUpdateAttachments = (e, accessory, position) => {
    const fileData = e.target.files[0];
    setPricingTemplates(
      pricingTemplates.map((catalog, i) => {
        if (i === position) {
          catalog.attachment = {
            file: fileData,
            previewURL: getImagePreviewIcon(fileData),
          };
        }
        return catalog;
      })
    );

    if (accessory.id > 0) {
      saveCatalogFile(accessory.id, fileData);
    }
  };

  useEffect(() => {
    const getPricingTemplate = async () => {
      try { 
        setCatlogDefaultKw(true);
        const response = await BenchmarkServices.getProviderPricingTemplate();
        if (response.status) {
          setAllCatalogs(
            response && response.data && response.data.map((w) => {
              return { ...w, capacity: w.k_w };
            })
          );
          setActiveCatalog(response.data[0].id);
          setCatlogDefaultKw(false);
        }else{
          setCatlogDefaultKw(false);
        }

      } catch (e) {
        console.error(e);
      }
    };

    const getCatalogTemplate = async () => {
      try {
        const response = await BenchmarkServices.getProviderCatalogTemplate();
        if (response.status) {
          setPricingTemplates(
            response.data.map((d, position) => {
              return {
                ...d,
                position,
                catlog_id: d.id,
                id: 0,
                value: 0,
                tax: 0,
              };
            })
          );
          setDefaultCatalog(
            response.data.map((d, position) => {
              return {
                ...d,
                position,
                catlog_id: d.id,
                id: 0,
                value: 0,
                tax: 0,
              };
            })
          );
        }
      } catch (e) {
        console.error(e);
      }
    };

    getPricingTemplate();
    getCatalogTemplate();
  }, []);

  useEffect(() => {
    if (activeCatalog > 0) {
      getCatalogPricing(activeCatalog, true);
    }
  }, [activeCatalog]);

  return (
    <div className="tab-contain-wrapper comman_vertical_btn_h pe10per z-index-12 overflow-hiiden-auto">
      <div className="comman_top_header  position-sticky top-0 z-index-4 p-0">
        <div className="d-flex justify-content-between align-items-center">
          <div className="comman-tab-wrapper pb-0 d-flex justify-content-between">
            <div className="tab-wrapper d-inline-flex align-items-center">
              <div className="tab-name title-fonts fw-semibold m-0 color-white-80 me-1">
                {_l("l_price_catalog ")}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center pe-10px">
            <a
              href="#/"
              class="btn btn-white-05 position-relative btn-sm"
              onClick={addNewPriceCatalog}
            >
              <span>{_l("l_add")}</span>
            </a>
            <Button
              variant="primary"
              size="sm"
              className="ms-10px"
              disabled={isUpdateCatalogOngoing}
              onClick={updatePriceCatalog}
            >
              {isUpdateCatalogOngoing ?
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`me-2`}
                  aria-hidden="true"
                />
               {_l("l_please_wait")} 
              </> 
              : _l("l_save")} 
            </Button>
          </div>
        </div>
      </div>
      <div className="comman_vertical_btn_h p-15 d-flex flex-column">
        <div className="comman-content-scroll d-flex overflow-hidden">
          <div className="left-content-part w200px p-0 res-width-100 h-100 bg-transparent">
            <ul className="h-100 comman-verticle-tab">
              <div className="comman-content-scroll-wrapper comman-contnet-wrapper h-100 radius-top-0">
                <div className="comman-content-scroll comman-verticle-tab bg-white">
                  {catlogDefaultKw ? 
                  <PriceCatalogTabsSkeleton /> 
                  :
                  allCatalogs.map((catalog, index) => {
                    return (
                      <li className="comman-list p-0" key={index}>
                        {currentIndexToEdit === index ? (
                          <Form.Group className="col-12 c-input-box pb-4 position-relative ">
                            <Form.Control
                              placeholder={"10K"}
                              type="text"
                              value={catalog.capacity}
                              onChange={(e) => {
                                setAllCatalogs(
                                  allCatalogs.map((ctl, i) => {
                                    if (index == i) {
                                      ctl.capacity = e.target.value;
                                    }
                                    return ctl;
                                  })
                                );
                              }}
                              onBlur={() => savePriceCatalogInformation(index)}
                              autoFocus
                            />
                          </Form.Group>
                        ) : (
                          <a
                            href="#/"
                            className={`w-100 position-relative tab_link title-fonts ${
                              activeCatalog === catalog.id ? "active" : ""
                            }`}
                            onClick={() => {
                              setActiveCatalog(catalog.id);
                            }}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="comman-list-left w100minus20">
                                <div className="d-flex align-items-center">
                                  <div className="c-list-detail w100minus20 text-truncate ps-2 fw-semibold">
                                    {catalog.capacity}K
                                  </div>
                                </div>
                              </div>
                              {catalog.is_default > 0 ? (
                                <React.Fragment></React.Fragment>
                              ) : (
                                <div className="d-flex align-items-center gap10px">
                                  <a
                                    href="javascript:;"
                                    className=""
                                    onClick={() => setCurrentIndexToEdit(index)}
                                  >
                                    <PencilLine
                                      size={18}
                                      className="c-icons"
                                      weight="light"
                                    />
                                  </a>
                                  <a
                                    href="javascript:;"
                                    className=""
                                    onClick={() => deleteCustomCatalog(index)}
                                  >
                                    <Trash
                                      size={18}
                                      className="c-icons"
                                      weight="light"
                                    />
                                  </a>
                                </div>
                              )}
                            </div>
                          </a>
                        )}
                      </li>
                    );
                  })}
                </div>
              </div>
            </ul>
          </div>
          <div className="right-content-part width-calc-300 with-margin h-100 overflow-hiiden-auto d-flex flex-column">
            <div className="align-items-center d-flex justify-content-between shadow-none pb-10px">
              <div class="c-font color-white-60 light-theme-color-white-70 f-14 title-fonts">
                Detail de la proposition{" "}
              </div>
            </div>
            <div className="border radius_5 bg-white-03">
              <div className=" d-flex justify-content-between align-items-center p-20 border-last-none border-bottom d-none">
                <div className="d-flex align-items-center">
                  <div className="h35w35 comman-round-box with-bg bg-white-05 me-20px">
                    <div
                      className="comman-bg-img h-100 w-100 bg-style-cover"
                      style={{
                        backgroundImage: `url('${solarpanelsimg}')`,
                      }}
                    ></div>
                  </div>
                  <div className="c-font f-14 fw-semibold title-fonts">
                    {_l("l_solar_installation_title")} Kwc
                  </div>
                </div>
                <div className=" c-font f-14 fw-semibold title-fonts">
                  {0} €
                </div>
              </div>
              <div>
                {(pricingTemplatesLoader.default || pricingTemplatesLoader.kwCatalog) ? 
                  <PriceCatalogDetailsProposalSkeleton /> 
                : pricingTemplates.map((accessory, index) => { 
                  if (accessory.is_accessory != 1) {
                    return <></>;
                  }
                  return (
                    <div
                      className={`d-flex justify-content-between align-items-center p-20 border-last-none border-bottom  ${
                        currentTab == index ? "z-index-21" : ""
                      }`}
                      onClick={() => {
                        setCurrentTab(index);
                      }}
                      key={index}
                    >
                      <div className="d-flex align-items-center flex-grow-1">
                        <div className="h35w35 comman-round-box with-bg bg-white-05 me-20px">
                          <div
                            className="comman-bg-img h-100 w-100 bg-style-cover"
                            style={{
                              backgroundImage: `url('${accessory.url ? accessory.url : solarpanelsimg}')`,
                            }}
                          ></div>
                        </div>
                        <div className="d-flex align-items-center flex-grow-1">
                          <div className="d-flex flex-column text-truncate w100minus100per">
                            <span className="c-font f-14 fw-semibold title-fonts text-truncate">
                              {accessory.name}
                            </span>
                            <span className="c-font f-12 text-truncate">
                              {accessory.description}
                            </span>
                          </div>
                          
                        </div>
                        {/* <div className="c-font f-14 fw-semibold title-fonts">
                            {0} € 
                        </div> */}
                      </div>
                      <div className="d-flex gap-3">
                        <Form.Control
                          placeholder={_l("l_onetime_price")}
                          className="fw-semibold color-white m-w100px"
                          type="number"
                          min={0}
                          value={accessory.value || ""}
                          onChange={(e) => {
                            setPricingTemplates(
                              pricingTemplates.map((pt) => {
                                if (pt.position == accessory.position) {
                                  pt.value = e.target.value;
                                }
                                return pt;
                              })
                            );
                          }}
                        />
                        <div className="d-flex align-items-center position-relative">
                          <Select
                            // menuIsOpen={true}
                            // placeholder={_l("l_payment_method")}
                            className="custom-select-menu drop-down f14 bg-white-05"
                            classNamePrefix="react-select"
                            options={VATselectionOption}
                            value={VATselectionOption.find(
                              (w) => w.value == accessory.tax
                            )}
                            onChange={(e) => {
                              setPricingTemplates(
                                pricingTemplates.map((pt) => {
                                  if (pt.position == accessory.position) {
                                    pt.tax = e.value;
                                  }
                                  return pt;
                                })
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="comman_action_icon ms-auto d-flex ps-3">
                        <a
                          className="action_icon h55w55 justify-content-center p-15 position-relative shadow-none with_bg"
                          data-tip={"Ajouter des articles"}
                          data-effect="solid"
                          data-delay-show="1000"
                          data-class="tooltip-main"
                          onClick={() => {
                            setAddCatalogItems(index)
                            setrel_type(
                              index == 0 
                                ? "electricity" 
                                : index == 1 
                                  ? "panel" 
                                  : "battery")
                          }}
                        >
                          <SelectionPlus size={24} className="c-icons"/>
                        </a>
                      </div>
                      <div className="comman_action_icon ms-auto d-flex ps-3">
                        <a
                          className="action_icon h55w55 justify-content-center p-15 position-relative shadow-none with_bg"
                          data-tip={_l("l_add_item")}
                          data-effect="solid"
                          data-delay-show="1000"
                          data-class="tooltip-main"
                          onClick={() => {
                            setAddDirectItems(index)
                            setrel_type(
                              index == 0 
                                ? "electricity" 
                                : index == 1 
                                  ? "panel" 
                                  : "battery")
                          }}
                        >
                          <FilePlus
                            size={24}
                            className="c-icons"
                          />
                        </a>
                      </div>
                      <ReactTooltip />
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <div class="align-items-center d-flex justify-content-between p-10 shadow-none pb-10px">
                <div class="c-font color-white-60 light-theme-color-white-70 f-14 title-fonts">
                  Conditions générales
                </div>
              </div>
              <div className="border radius_5 bg-white-03">
                <div>
                  {(pricingTemplatesLoader.default || pricingTemplatesLoader.kwCatalog) ? 
                    <PriceCatalogGeneralConditionsSkeleton /> 
                  : pricingTemplates.map((accessory, index) => {
                    if (accessory.is_after_sale_service != 1) {
                      return <></>;
                    }
                    return (
                      <div key={index} className=" radius10 bg-white-03 ">
                        <div className=" d-flex justify-content-between align-items-center p-20 border-last-none border-bottom flex-wrap flex-xl-nowrap gap-3">
                          <div className="d-flex align-items-center">
                            <div className="c-font f-14 fw-semibold title-fonts">
                              {accessory.name}
                            </div>
                          </div>
                          <div className="d-flex gap-3">
                            <Form.Control
                              placeholder="25 années"
                              className="fw-semibold color-white max-width-200px"
                              type="number"
                              min={0}
                              value={accessory.value || ""}
                              onChange={(e) => {
                                setPricingTemplates(
                                  pricingTemplates.map((pt) => {
                                    if (pt.position == accessory.position) {
                                      pt.value = e.target.value;
                                    }
                                    return pt;
                                  })
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div>
                  {pricingTemplates.map((accessory, index) => {
                    if (accessory.is_payment_method != 1) {
                      return <></>;
                    }
                    return (
                      <div
                        key={index}
                        className=" d-flex justify-content-between align-items-center p-20 border-last-none border-bottom flex-wrap flex-xl-nowrap gap-3"
                      >
                        <div className="d-flex align-items-center">
                          <div className="c-font f-14 fw-semibold title-fonts">
                            {_l("l_payment_method")}
                          </div>
                        </div>
                        <Select
                          placeholder={_l("l_by_cheque")}
                          className="custom-select-menu drop-up max-width-200px w200px f14 bg-white-05"
                          classNamePrefix="react-select"
                          options={paymentMethodOptions}
                          value={paymentMethodOptions.find(
                            (w) => w.value == accessory.value
                          )}
                          onChange={(e) => {
                            setPricingTemplates(
                              pricingTemplates.map((pt) => {
                                if (pt.position == accessory.position) {
                                  pt.value = e.value;
                                }
                                return pt;
                              })
                            );
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div className="pt-20px">
          {pricingTemplates.map((accessory, index) => {
            if (accessory.is_terms_and_condition != 1) {
              return <></>;
            }
            return (
              <div
                key={index}
                className="align-items-start bg-white border border-last-none d-flex justify-content-between p-20 radius_3"
              >
                <div className="d-flex align-items-center flex-grow-1 flex-column">
                  <Form.Control
                    style={{ background: "transparent" }}
                    className="border-0 ps-0 py-0 shadow-none "
                    as="textarea"
                    rows={5}
                    placeholder={`${_l("l_type_description_here")}`}
                    type="text"
                    value={accessory.value || ""}
                    onChange={(e) => {
                      setPricingTemplates(
                        pricingTemplates.map((pt) => {
                          if (pt.position == accessory.position) {
                            pt.value = e.target.value;
                          }
                          return pt;
                        })
                      );
                    }}
                  ></Form.Control>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {catalogEditIndex > -1 ? (
        <EditProposalItem
          fields={pricingTemplates}
          index={catalogEditIndex}
          keyName={"name"}
          handleClose={() => {
            setCatalogEditIndex(-1);
          }}
          setFields={(field, value, pos) => {
            setPricingTemplates(
              pricingTemplates.map((acc, i) => {
                if (i == pos) {
                  if (field === "name") {
                    acc["item_name"] = value;
                  }
                  acc[field] = value;
                }
                return acc;
              })
            );
          }}
        />
      ) : (
        <></>
      )}
      {addCatalogItems > -1 ? (
        <ChooseCatalogItem
          handleClose={() => {
            setAddCatalogItems(-1);
            setrel_type('');
          }}
          addCatalogItems = {addCatalogItems}
          rel_type = {rel_type}
          setPricingTemplates = {setPricingTemplates}
          pricingTemplates = {pricingTemplates}
        />
      ) : (
        <></>
      )}
      {addDirectItems > -1 ? (
        <AddCatalogItem
          handleClose={() => {
            setAddDirectItems(-1);
            setrel_type('');
          }}
          rel_type = {rel_type}
          isFrom = {"directAddItems"}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProviderPricingCatalog;
