import React, { useEffect, useState } from "react";
import { X, Paperclip, ArrowRight, DotsThreeVertical, MagnifyingGlass, UserPlus, At, CornersIn, CornersOut, Info, Hash, ShareNetwork, VideoCamera } from "phosphor-react";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import { MentionsInput, Mention } from "react-mentions";
import Lightbox from "yet-another-react-lightbox";
import CommanLoader from "../Loader/CommanLoader";
import ReactTooltip from "react-tooltip";
import {
  dropDownActiveClass,
  _l,
  TaskCommentModalOpen,
} from "../../hooks/utilities";
import {
  updateTaskStatus,
  changePriority,
  getTaskList,
  getSelectedTask,
  getTaskCount,
  SetSelectedTask,
  setTaskData,
  updateProjectTopics,
  requestTaskAssignees,
} from "../../actions/customer";
import CustomerServices from "../../services/customer-services";
import moment from "moment";
import Form from "react-bootstrap/Form";
import Toasts from "../Toasts/Toasts";
import jquery from "jquery";
import CategoryProviderList from "./CategoryProviderList";
import ChecklistComponent from "../TaskModalComponents/ChecklistComponent";
import RightSideComments from "../StandAlonePages/RightSideComments";
import { toastCloseAction } from "../../actions/messages";
import CommonHeaderModal from "../TaskModalComponents/CommonHeaderModal";
import CommonHeaderBody from "../TaskModalComponents/CommonHeaderBody";
import CommonFooter from "../TaskModalComponents/CommonFooter";
import TaskShareLinkModal from "./TaskShareLinkModal";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
const TaskRequestModal = ({
  taskId,
  projectId,
  show,
  handleClose,
  handler,
  convertRequestTaskId = 0,
  setConvertTask,
  isCreateTask = false,
}) => {
  const allCategoryListing = useSelector(
    (state) => state.customer.defaultTaskAssignees
  );
  const defaultTopics = useSelector((state) => state.customer.defaultTopics);
  const checklistStateData = useSelector(
    (state) => state.customer.taskChecklist
  );

  const [mentionList, setMentionList] = useState([]);
  const [requestChecklist, setRequestChecklist] = useState([]);
  const [toastHeader, setToastHeader] = useState("");
  const [confirmButtonText, setConfirmButtonText] = useState("");
  const [cancelButtonText, setCancelButtonText] = useState("");
  const [providerCategorylist, setProviderCategorylist] = useState([]);
  const [assigneeDropdownList, setAssigneeDropdownList] = useState([]);
  const [providerCategory, setProviderCategory] = useState(
    selectedTask && selectedTask.provider_id ? selectedTask.provider_id : 0
  );
  const [showLink, setShowLink] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryTitle, setCategoryTitle] = useState("");
  const [comment, setComment] = useState("");
  const [imagePreviews, setImagePreviews] = useState([]);
  const [imageWithDescriptionPreviews, setImageWithDescriptionPreviews] =
    useState([]);
  const [showToast, setShowToast] = useState(false);
  const [showFullscreen, setShowFullscreen] = useState(false);
  const [toastClass, setToastClass] = useState("unsucess");
  const [toastMessage, setToastMessage] = useState("");
  const [isImageUpload, setImageUpload] = useState(false);
  const [isDisabled, setisDisabled] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const client_id = localStorage.getItem("client_id");
  const contact_id = localStorage.getItem("contact_id");
  const savedDate = localStorage.getItem("selectedDate");
  const [commentContent, setCommentContent] = useState("");
  const [convertTask, setConvertTaskId] = useState({});
  const [showSearch, setshowSearch] = useState(false);
  const [showAddNewCategoryModal, setShowAddNewCategoryModal] = useState(false);
  const [ShowCommentModal, setShowCommentModal] = useState(false);
  const selectedTask = useSelector((state) => state.customer.selectedTask);
  const filterData = useSelector((state) => state.customer.taskFilters);
  const taskStatusFilter = useSelector(
    (state) => state.customer.activeTaskStatusFilters
  );
  const [commentCount, setCommentCount] = useState(0);
  const [unreadCommentCount, setUnreadCommentCount] = useState(0);
  var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?~]/;
  const [taskStatus, setTaskStatus] = useState(1);
  const [taskPriority, setTaskPriority] = useState(
    selectedTask ? (selectedTask.priority == "3" ? 3 : 2) : 2
  );
  const [initailChecklist, setInitailChecklist] = useState(false);
  const [taskPinShow, setTaskPinShow] = useState(false);
  const [addOrEditTaskId, setAddOrEditTaskId] = useState(
    selectedTask && taskId ? taskId : ""
  );
  const [startDate, setStartDate] = useState("");
  const [openGallery, setOpenGallery] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [previewSrc, setPreviewSrc] = useState([]);
  const [commentId, setCommentId] = useState(0);
  const [commentTab, setCommentTab] = useState(true);
  const [isCommentExist, setIsCommentExist] = useState(true);
  const [imageBuffer, setImageBuffer] = useState([]);
  const [imageWithDescriptionBuffer, setImageWithDescriptionBuffer] = useState(
    []
  );
  const [attachmentList, setAttachmentList] = useState([]);
  const [showData, setShowData] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [taskCreaterProfile, setTaskCreaterProfile] = useState(
    localStorage.getItem("contact_image")
  );
  const userType = localStorage.getItem("user_type");
  const staff_id = localStorage.getItem("staff_id");
  const [defaultComment, setdefaultComment] = useState([]);
  const mentionsList = useSelector((state) => state.customer.mentionList);
  const [attachmentId, setAttachmentId] = useState(0);
  const [description, setDescription] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [tempCommentId, setTempCommentId] = useState(0);
  const [openCheckList, setOpenCheckList] = useState(false);
  const [taskName, setTaskName] = useState("");
  const [todoTasks, setTodoTasks] = useState([]);
  const [convertedTaskId, setConvertedTaskId] = useState(
    convertRequestTaskId ? convertRequestTaskId : 0
  );
  const [selectedTab, setSelectedTab] = useState("request");
  const [checkListCount, setCheckListCount] = useState(0);
  const startDateTitle = startDate
    ? "Start Date: " + moment(startDate).format("DD/MM/YYYY hh:mm a")
    : "Start Date";
  const [isChecked, setIsChecked] = useState(false);
  const [openAssigneeDropdown, setOpenAssigneeDropdown] = useState(false);
  const [requestAssignee, setRequestAssignee] = useState([]);
  const [visibleToCustomer, setVisibleToCustomer] = useState(false);
  const [visibleToProvider, setVisibleToProvider] = useState(false);
  const [commonHeaderBodyData, setCommonHeaderBodyData] = useState({ requestAssignee: [] });
  const dispatch = useDispatch();
  const contact_image = localStorage.getItem("contact_image");
  const virtualSpaces = localStorage.getItem("selectedVirtualSpace");
  const selectedDate =
    localStorage.getItem("calendarData") &&
      localStorage.getItem("calendarData") != "undefined" &&
      JSON.parse(localStorage.getItem("calendarData")).length
      ? JSON.parse(localStorage.getItem("calendarData"))[3].date
      : new Date();
  const [commonHeaderData, setCommonHeaderData] = useState({
    creater_name: '',
    creater_profile: '',
    document_name: '',
    document_name_permission: '',
    document_id: ''
  });
  const imageView = ["myr_staff_task", "my_people"];
  const emptyHandleClose = () => {
    handleClose();
    //  jquery(".task-list-accordian").removeClass("list-view-toggle");
    setProviderCategory(0);
    setComment("");
    setSelectedCategory("");
    setCommentList([]);
    setCategoryTitle("");
    setImagePreviews([]);
    setAddOrEditTaskId("");
    setToastHeader("");
    setTaskName("");
    setTaskPinShow(false);
    setTaskStatus(1);
    setTaskPriority(2);
    setStartDate('')
    setCommonHeaderBodyData({});
    setCommonHeaderData({})
    // dispatch(getSelectedTask(undefined, undefined, 1));
    dispatch(SetSelectedTask(""));
  };
  const handleCloseShareLink = () => setShowLink(false);
  const handleShowShareLink = () => setShowLink(true);

  useEffect(() => {
    if (isImageUpload) {
      setImageUpload(false);
      setImagePreviews(imagePreviews);
    }
  }, [isImageUpload]);

  useEffect(() => {
    if (defaultTopics && defaultTopics.length) {
      const providerTopics = defaultTopics.filter(
        (x) =>
          x.main_key === (userType == "contact" ? "provider_task" : "proposals")
      );

      if (providerTopics.length)
        setProviderCategorylist(
          providerTopics.length
            ? providerTopics[0].categories.map((x) => {
              return { label: x.title, value: parseFloat(x.filter_key) };
            })
            : []
        );

      let requestAssigneeList = [];
      defaultTopics.forEach((topic) => {
        switch (topic.main_key) {
          case "provider_task":
            topic.categories.forEach((provider) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: provider.title,
                id: provider.filter_key,
                is_assigned: provider.is_assigned || 0,
                image: provider.image || "",
              });
            });
            break;

          case "my_people":
            topic.categories.forEach((provider) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: provider.title,
                id: provider.filter_key,
                is_assigned: provider.is_assigned || 0,
                image: provider.image || "",
              });
            });
            break;

          case "proposals":
            topic.categories.forEach((proposal) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: proposal.title,
                id: proposal.filter_key,
                is_assigned: proposal.is_assigned || 0,
                image: proposal.image || "",
              });
            });
            break;

          case "user_journey":
            topic.categories.forEach((proposal) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: proposal.title,
                id: proposal.filter_key,
                is_assigned: proposal.is_assigned || 0,
                image: proposal.image || "",
              });
            });
            break;

          case "myr_staff_task":
            topic.categories.forEach((proposal) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: proposal.title,
                id: proposal.filter_key,
                is_assigned: proposal.is_assigned || 0,
                image: proposal.image || "",
              });
            });
            break;
          case "task_topic":
            if (virtualSpaces && Object.keys(virtualSpaces).length) {
              topic.categories.forEach((proposal) => {
                requestAssigneeList.push({
                  main_key: topic.main_key,
                  type:
                    requestAssigneeList.findIndex(
                      (x) => x.type == topic.title
                    ) === -1
                      ? topic.title
                      : "",
                  name: proposal.title,
                  id: proposal.filter_key,
                  is_assigned: proposal.is_assigned || 0,
                  image: proposal.image || "",
                });
              });
            }
            break;

          default:
            break;
        }
      });
      setAssigneeDropdownList(requestAssigneeList);
    }
  }, [defaultTopics]);

  useEffect(() => {
    getTodoTasks();
    return () => {
      imagePreviews.map((img) => {
        URL.revokeObjectURL(img.url);
      });
    };
  }, []);

  useEffect(() => {
    let tempAssignees = selectedTask && selectedTask.assignees && selectedTask.assignees.length
      ? selectedTask.assignees
      : []
    setRequestAssignee(tempAssignees);

  }, [selectedTask]);
  useEffect(() => {
    setSearchString("");
    setIsChecked(false);
    setshowSearch(false);
    setAttachmentList([]);
    setImageBuffer([]);
    setPreviewSrc([]);
    setCommentContent("");

    if (
      selectedTask &&
      Object.keys(selectedTask).length &&
      taskId &&
      taskId != ""
    ) {
      setAddOrEditTaskId(taskId);
      if (taskId && isCommentExist) {
        getTaskComments();
        setIsCommentExist(false);
      }
      setCommentList(
        selectedTask && selectedTask.comments ? selectedTask.comments : []
      );
      // setVisibleToCustomer(selectedTask && selectedTask.visible_to_client == 1 ? true : false);

      setTaskName(selectedTask && selectedTask.name ? selectedTask.name : "");
      let category = providerCategorylist.filter(
        (provider) => provider.value == selectedTask.provider_id
      );
      setProviderCategory(category && category.length ? category[0].value : "");
      // if (category && category.length) {
      // getTodoTasks();
      // }
      setCategoryTitle(category && category.length ? category[0].label : "");
      if (selectedTask.startdate != null) {
        setStartDate(new Date(selectedTask.startdate));
      }
      setTaskPinShow(parseInt(selectedTask.is_pinned) > 0);
      if (selectedTask.task_creator_image) {
        setTaskCreaterProfile(selectedTask.task_creator_image);
      }
      if (selectedTask.status) {
        setTaskStatus(selectedTask.status);
      }
      if (selectedTask.priority) {
        setTaskPriority(selectedTask.priority == 3 ? 3 : 2);
      }
      if (selectedTask.name) {
        setTaskName(selectedTask.name);
      }
      setSelectedTab("checklists");
      setSelectedTab("request");
      if (selectedTask.checklist && !initailChecklist) {
        setCheckListCount(selectedTask.checklist.length);
        setInitailChecklist(true);
      }
      setDescription("");
      setImagePreviews([]);
      setAttachmentList([]);

      if (
        selectedTask.description != "" &&
        selectedTask.description != "null"
      ) {
        setDescription(selectedTask.description);
      }
      if (selectedTask.atttachments && selectedTask.atttachments.length > 0) {
        setAttachmentList(
          selectedTask.atttachments.map((img) => {
            return img;
          })
        );
        setPreviewSrc(
          selectedTask.atttachments.map((img) => {
            return img;
          })
        );
      }
      let assigneedRequest = []
      if (
        selectedTask.assignees_type &&
        Object.keys(selectedTask.assignees_type).length > 0
      ) {
        let isAssigneesExist = assigneeDropdownList.filter(
          (taskAssignees) =>
            taskAssignees.id == selectedTask.assignees_type.assginee_id &&
            taskAssignees.main_key === selectedTask.assignees_type.assginee_type
        );

        setRequestAssignee([]);
        if (isAssigneesExist.length) {
          let tempPush = [];
          tempPush = [
            {
              main_key: selectedTask.assignees_type.assginee_type,
              id: isAssigneesExist[0].id,
              image: isAssigneesExist[0].image || "",
              name: isAssigneesExist[0].name || "",
            },
          ];
          dropDownActiveClass(
            true,
            `${selectedTask.assignees_type.assginee_type}_${selectedTask.assignees_type.assginee_id}`
          );
          setRequestAssignee(tempPush);
          assigneedRequest = tempPush
        }
      }

      setUnreadCommentCount(selectedTask.unread_comment_count);

      setConvertedTaskId(convertRequestTaskId ? convertRequestTaskId : 0);
      setCommonHeaderData({
        creater_name: selectedTask ? selectedTask.task_creator_name : localStorage.getItem("full_name"),
        creater_profile: selectedTask.task_creator_image ? selectedTask.task_creator_image : '',
        document_name: selectedTask.name ? selectedTask.name : '',
        document_name_permission: true,
        document_id: addOrEditTaskId,
        task_redirect_btn_permission: selectedTask && selectedTask.request_task_id > 0,
        task_redirect_btn_text: _l("l_view_task"),
        document_type: _l("l_request"),
        show_full_screen: showFullscreen,
        follow_btn_text: _l("l_follow"),
        show_task_myr_total: false,
        myr_total: 0,
        myr_total_permission: false
      })

      setCommonHeaderBodyData({
        ...commonHeaderBodyData,
        date_tooltip: _l("l_start_date"),
        date: selectedTask.startdate ? new Date(selectedTask.startdate) : '',
        date_permission: ((selectedTask && selectedTask.is_allowed_to_edit != 1) || convertedTaskId > 0),
        is_pinned: parseInt(selectedTask.is_pinned) > 0 ? 1 : 0,
        status_class: selectedTask.status == 1
          ? "in-progress-white"
          : selectedTask.status == 4
            ? "in-progress-blue"
            : "done",
        status_permission: selectedTask && selectedTask.request_task_id > 0 ? false : selectedTask && selectedTask.is_allowed_to_edit == 1,
        priority: selectedTask.priority == "3" ? 1 : 0,
        priority_permission: selectedTask
          ? selectedTask.request_task_id > 0 || parseInt(selectedTask.is_allowed_to_edit) < 1
          : false,
        common_permission: selectedTask && selectedTask.request_task_id > 0 ? false : selectedTask && selectedTask.is_allowed_to_edit == 1,
        project_rel_id: false,
        recurring_data: {},
        document_id: addOrEditTaskId,
        tag_id: selectedTask.tags && selectedTask.tags.length ? selectedTask.tags[0].id : 0,
        assignee_providers: selectedTask && selectedTask.task_assignee && selectedTask.task_assignee.providers,
        assignee_equipments: selectedTask && selectedTask.task_assignee && selectedTask.task_assignee.equipments,
        visible_to_client: selectedTask && selectedTask.visible_to_client == 1 ? true : false,
        visible_to_provider: selectedTask && selectedTask.visible_to_provider == 1 ? true : false,
        convertedTaskId: selectedTask && selectedTask.request_task_id || convertedTaskId,
        assigneeList: assigneeDropdownList,
        requestAssignee: assigneedRequest

      })

    } else {
      setStartDate(new Date());
      setTaskStatus(1);
    }
  }, [JSON.stringify(selectedTask), providerCategorylist]);
  useEffect(() => {
    if (selectedTask) {
      setVisibleToCustomer(
        selectedTask && selectedTask.visible_to_client == 1 ? true : false
      );
      setVisibleToProvider(
        selectedTask && selectedTask.visible_to_provider == 1 ? true : false
      );
    }
  }, [JSON.stringify(selectedTask)]);
  useEffect(() => {
    if (projectId && show) {
      // dispatch(getDefaultTaskAssignee(projectId));
    }
    setDescription("");
    setProviderCategory(0);
    setCategoryTitle("");
    if (show && taskId == 0 && !addOrEditTaskId && addOrEditTaskId == "") {
      setAttachmentList([]);
      setImageBuffer([]);
      setPreviewSrc([]);
      setTaskStatus(1);
      CustomerServices.createNewTask(projectId, 1).then((res) => {
        if (res.status) {
          setAddOrEditTaskId(res.data.task_id);
          updateTaskData(moment().format("YYYY-MM-DD"), res.data.task_id);
        }
      });
    }
  }, [show]);

  useEffect(() => {
    if (searchString != "") {
      setCommentList(() =>
        defaultComment.filter(
          (comment) =>
            comment.content &&
            comment.content.toLowerCase().includes(searchString.toLowerCase())
        )
      );
      scrollToBottom();
    } else {
      setCommentList(defaultComment);
    }
  }, [searchString]);

  useEffect(() => {
    if (mentionsList) {
      setMentionList(
        mentionsList.map((name) => {
          return {
            display: name.name,
            id: name.id,
            image: name.profile_image || "",
          };
        })
      );
    }
  }, [mentionsList]);
  useEffect(() => {
    jquery(`.custom-assignees`).removeClass("active with-tick");
    jquery(`.custom-assigneesMain`).removeClass("active with-tick");
    if (requestAssignee.length) {
      requestAssignee.forEach((assigne, index) => {
        jquery(
          `a[data-id='${assigne.main_key}_${assigne.id}']`
        ).addClass("active with-tick");
        if (index == 0) {
          jquery(
            `.custom-assigneesMain[data-assigneeMain=${assigne.main_key}]`
          ).addClass("active with-tick");
        }
      });
    } else {
    }
  }, [requestAssignee]);

  const getTodoTasks = (provider_id = 0, assignDetails = []) => {
    setTodoTasks([]);
    CustomerServices.getTodoTaskList(provider_id).then((res) => {
      if (res.status && res.data) {
        setTodoTasks(res.data);
        if (assignDetails.length) {
          setCommonHeaderBodyData({ ...commonHeaderBodyData, ['toDoTask']: res.data, ['requestAssignee']: assignDetails.length ? assignDetails : requestAssignee })
        }


      }
    });
  };
  const deleteTask = () => {
    setShowLoader(true);
    CustomerServices.deleteTask(addOrEditTaskId, projectId).then((res) => {
      if (res.status === 1) {
        setShowLoader(false);
        dispatch(getTaskCount(client_id, contact_id, projectId, selectedDate));
        emptyHandleClose();
      } else {
        setShowLoader(false);
        setShowToast(false);
        setToastClass("unsucess");
        setToastHeader(_l("l_error"));
        setToastMessage(res.message);
        setShowToast(true);
      }
    });
  };
  const hideTaskModal = () => {
    if (
      requestAssignee.length == 0 &&
      convertedTaskId == 0 &&
      ((selectedTask && selectedTask.is_allowed_to_edit == 1) ||
        isCreateTask == true)
    ) {
      setToastHeader(_l("l_are_you_sure"));
      setToastClass("toast-with-btn");
      setToastMessage(_l("l_do_you_want_to_save_the_task_as_draft"));
      setConfirmButtonText(_l("l_save"));
      setCancelButtonText(_l("l_delete"));
      setShowToast(true);
      return;
    }
    emptyHandleClose();
  };

  const saveTask = (category = 0, title = "") => {
    let taskCreaterName = selectedTask
      ? selectedTask.task_creator_name
      : localStorage.getItem("full_name");
    if (addOrEditTaskId) {
      if (1) {
        getTodoTasks();
        CustomerServices.setTaskAssignee(
          projectId,
          addOrEditTaskId,
          "myr_network",
          category,
          moment(startDate).format("YYYY-MM-DD"),
          1
        ).then((res) => {
          if (res.status == "1") {
            setisDisabled("");

            CustomerServices.setTaskData(
              projectId,
              addOrEditTaskId,
              "Request by " + taskCreaterName + " - " + title,
              moment(startDate).format("YYYY-MM-DD"),
              "",
              0,
              description
            ).then(() => {
              setTaskName("Request by " + taskCreaterName + " - " + title);
              dispatch(
                getTaskList(
                  localStorage.getItem("client_id"),
                  localStorage.getItem("contact_id"),
                  projectId,
                  moment(localStorage.getItem("selectedDate")).format(
                    "YYYY-MM-DD"
                  ),
                  1,
                  taskStatusFilter ? taskStatusFilter : [],
                  filterData ? filterData : []
                )
              );
            });
          }
          setTimeout(() => {
            setisDisabled("");
          }, 5000);
        });

        localStorage.setItem("request_task", 1);
      }
    }
  };

  const handleEditComment = (id) => {
    const targetComment = commentList.filter((comment) => comment.id === id);
    if (targetComment.length) {
      setCommentId(id);
      jquery("#comment_input").focus();
      setCommentContent(targetComment[0].content);
    }
  };

  const deleteComment = (id) => {
    CustomerServices.removeComment(projectId, id).then((res) => {
      if (res.status)
        setCommentList(commentList.filter((comment) => comment.id !== id));
      dispatch(toastCloseAction());
      //Acknowledgement for deletion if required
    });
  };

  const removeImageSelection = (name, flag = "") => {
    if (flag != "") {
      imageWithDescriptionPreviews.forEach((thumbnail) => {
        URL.revokeObjectURL(thumbnail.url);
      });
      const updatedFileList = Array.from(imageWithDescriptionBuffer).filter(
        (img) => img.name != name
      );

      setImageWithDescriptionBuffer(updatedFileList);
      preparePreview(updatedFileList, "removeImage");
    } else {
      imagePreviews.forEach((thumbnail) => {
        URL.revokeObjectURL(thumbnail.url);
      });
      const updatedFileList = Array.from(imageBuffer).filter(
        (img) => img.name !== name
      );
      setImageBuffer(updatedFileList);
      preparePreview(updatedFileList);
    }
  };

  const preparePreview = (files, flag = "") => {
    let previrewArray = [];
    if (flag != "") {
      previrewArray = imageWithDescriptionPreviews.length
        ? imageWithDescriptionPreviews
        : [];
    } else {
      // previrewArray = imagePreviews.length ? imagePreviews : [];
    }
    if (flag == "removeImage") {
      previrewArray = [];
    }
    Array.from(files).map((img, index) => {
      previrewArray.push({
        id: index,
        name: img.name,
        url: URL.createObjectURL(img),
      });
    });
    if (flag != "") {
      setImageWithDescriptionPreviews(previrewArray);
    } else {
      setImagePreviews(previrewArray);
    }
  };

  const addNewComment = () => {
    // var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?~]/;
    if (imagePreviews.length || (commentContent && commentContent.trim())) {
      if (commentId) {
        CustomerServices.editLinkComment(
          addOrEditTaskId,
          projectId,
          commentContent.trim(),
          undefined,
          commentId,
          undefined,
          undefined,
          imageBuffer
        ).then((res) => {
          if (res.status) {
            commentList.map((comment) => {
              if (comment.id === commentId) {
                comment.content = commentContent.trim();
              }
            });
            setCommentList(commentList);
            setCommentContent("");
            setCommentId(0);
            setImagePreviews([]);
          }
          // setTimeout(() => {
          //   scrollToBottom();
          // }, 1000);
        });
      } else {
        const comment = commentContent.trim();
        imagePreviews.map((img) => {
          URL.revokeObjectURL(img.url);
        });
        setImagePreviews([]);
        setCommentContent("");
        CustomerServices.addLinkComment(
          addOrEditTaskId,
          projectId,
          comment,
          undefined,
          undefined,
          undefined,
          undefined,
          imageBuffer
        ).then((res) => {
          if (res.status) {
            setCommentList((commentList) => [
              ...commentList,
              {
                id: res.data.comment_id,
                contact_id,
                dateadded: new Date().toISOString(),
                contact_full_name: localStorage.getItem("full_name"),
                content: comment,
                time: res.data.time,
                addedfrom_details: {
                  profile_url: localStorage.getItem("contact_image"),
                },

                attachments: res.data.files_list
                  ? res.data.files_list.map((img) => {
                    return { file_name: img.image_url, id: img.file_id };
                  })
                  : [],
              },
            ]);

            let tempImage = [];
            res.data.files_list.map((img) => {
              tempImage.push({
                id: img.file_id,
                image_url: img.image_url,
                is_allowed_to_edit: 1,
              });
            });
            setCommentCount(commentCount + 1)
            setAttachmentList(tempImage.concat(attachmentList));
            setImageBuffer([]);
          }
        });
      }
    } else {
      setShowToast(false);
      setToastClass("unsucess");
      setToastHeader(_l("l_error"));
      setToastMessage(_l("l_please_enter_valid_comment"));
      setShowToast(true);
    }
  };

  const getMentionData = () => {
    setShowData(showData == 0 ? 1 : 0);
    setCommentContent(showData == 0 ? "@" : "");
    if (!showData) {
      jquery("#comment_input").focus();
    } else {
      setCommentContent("");
    }
  };

  const updateTaskData = (start_date = undefined, taskId = 0, visibleToCustomer, visibleToProvider) => {
    dispatch(
      setTaskData(
        projectId,
        addOrEditTaskId != "" ? addOrEditTaskId : taskId,
        taskName,
        moment(start_date || startDate).format("YYYY-MM-DD"),
        undefined,
        0,
        1,
        0,
        0,
        "",
        description,
        visibleToCustomer,
        visibleToProvider
      )
    );

  };

  const changeTaskStatus = (statusId) => {
    dispatch(updateTaskStatus(addOrEditTaskId, projectId, statusId, 1));
    setTaskStatus(statusId);
    dispatch(getTaskCount(client_id, contact_id, projectId, selectedDate));
    let tempClass = statusId == 1
      ? "in-progress-white"
      : statusId == 4
        ? "in-progress-blue"
        : "done"
    setCommonHeaderBodyData({ ...commonHeaderBodyData, ['status_class']: tempClass })
  };

  const changeTaskPriority = () => {
    dispatch(changePriority(addOrEditTaskId, projectId, taskPriority, selectedTask.provider)).then(
      () => {
        setTaskPriority(taskPriority == 3 ? 2 : 3);
      }
    );
  };

  const handlePinTask = () => {
    CustomerServices.pinTask(addOrEditTaskId, projectId, selectedTask.provider).then(() => {
      setTaskPinShow(!taskPinShow);
      dispatch(
        setTaskData(
          projectId,
          addOrEditTaskId,
          taskName,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          "pinnedFlag"
        )
      );
    });

  };

  const handleSearch = (e) => {
    const searchStringInput = e.target.value;
    setSearchString(searchStringInput);
  };

  const getTaskComments = (pending = 0) => {
    CustomerServices.getComments(taskId, projectId, undefined, pending, 1).then(
      (res) => {
        const comments = res.data;
        setCommentList([]);
        setdefaultComment([]);
        setCommentCount(comments.length);
        comments.forEach((item) => {
          setCommentList((commentList) => [...commentList, item]);
          setdefaultComment((defaultComment) => [...defaultComment, item]);
        });
      }
    );
  };
  const deleteImage = (id, comment_id) => {
    CustomerServices.deleteTaskDocument(projectId, comment_id, 1, id).then(
      (res) => {
        if (res && res.status)
          setAttachmentList(
            attachmentList.filter((attachment) => attachment.id !== id)
          );
        setCommentList((commentList) =>
          commentList.map((comment) => {
            let TempAttchment = comment.attachments;

            let attachmentsTemp = TempAttchment.filter((img) => img.id != id);
            return {
              ...comment,
              attachments: attachmentsTemp,
            };
          })
        );
      }
    );
  };
  const addDescriptionOrComment = () => {
    if (newDescription != "") {
      CustomerServices.setTaskData(
        projectId,
        addOrEditTaskId,
        taskName,
        moment(startDate).format("YYYY-MM-DD"),
        undefined,
        undefined,
        newDescription
      ).then((res) => {
        if (res.status) {
          setDescription(newDescription);
          setNewDescription("");
        }
      });
    }
    if (imageWithDescriptionPreviews.length) {
      setImagePreviews([]);
      setCommentContent("");
      CustomerServices.addLinkComment(
        addOrEditTaskId,
        projectId,
        "",
        undefined,
        undefined,
        undefined,
        1,
        imageWithDescriptionBuffer
      ).then((res) => {
        if (res.status) {
          if (res.data.files_list && res.data.files_list.length) {
            let tempImage = attachmentList.length ? attachmentList : [];
            res.data.files_list.map((img) => {
              tempImage.push({
                id: img.file_id,
                image_url: img.image_url,
              });
            });
            setAttachmentList(tempImage);
            setPreviewSrc(tempImage);
            setImageWithDescriptionBuffer([]);
            setImageWithDescriptionPreviews([]);
          }
        }
      });
    }
  };
  const setAttchmentOnTop = (id) => {
    const targetAttchement = previewSrc.filter(
      (attachment) => attachment.id === id
    );
    const otherAttachments = previewSrc.filter(
      (attachment) => attachment.id != id
    );
    setPreviewSrc(targetAttchement.concat(otherAttachments));
  };
  const scrollToBottom = () => {
    var msgListDiv = document.getElementById("comment-list");
    msgListDiv.scrollBottom = msgListDiv.scrollHeight;
  };

  const handleConvertTask = (task_Id = "", flag = "") => {
    if (checkListCount > 0) {
      let task_id = task_Id;
      CustomerServices.convertRequestTask(
        flag,
        "",
        addOrEditTaskId,
        task_id
      ).then((res) => {
        if (res.status && res.data) {
          if (flag == "new_task") {
            task_id = res.data.id;
            dispatch(getTaskCount(client_id, contact_id, projectId, savedDate));
          }
          dispatch(getSelectedTask(projectId, task_id));
          handler(task_id, "show", null, taskId);

          dispatch(
            setTaskData(
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              addOrEditTaskId,
              task_id
            )
          );
          if (!isCreateTask) {
            setConvertTask();
          }
          emptyHandleClose();
          setDescription("");
        }
      });
    } else {
      setShowToast(false);
      setToastClass("unsucess");
      setToastHeader(_l("l_error"));
      setToastMessage(_l("l_please_enter_valid_checklist"));
      setShowToast(true);
    }
  };

  if (commentList.length && show) {
    setTimeout(() => {
      var msgListDiv = document.getElementById("comment-list");
      try {
        if (msgListDiv.scrollHeight != null) {
          msgListDiv.scrollTop = msgListDiv.scrollHeight;
        }
      } catch (error) {
        console.log(error);
      }
    }, 100);
  }

  const handleAddCategory = (category) => {
    setProviderCategorylist(
      Object.values({
        ...providerCategorylist,
        [providerCategorylist.length]: {
          label: category.name,
          value: category.provider_id,
        },
      })
    );
    dispatch(
      updateProjectTopics(
        {
          filter_key: category.provider_id,
          title: category.name,
          custom_keys: [],
        },
        "provider_task"
      )
    );
  };

  const updateRequestTitle = (assignee, checklist) => {
    let newTitle = ``;
    if (checklist) {
      newTitle = selectedTask.name.split(" - ");
      if (newTitle.length > 1) {
        newTitle[1] = checklist;
        newTitle = newTitle.join(" - ");
      }
    } else {
      newTitle = `${assignee}`;
      if (requestChecklist && requestChecklist.length) {
        newTitle += ` - ${requestChecklist[0].description}`;
      }
    }
    if (newTitle.length) {
      dispatch(setTaskData(projectId, selectedTask.task_id, newTitle));
    }
  };

  const handleRequestAssignee = (assigneeDetail) => {
    let selectedAssignees = {
      main_key: assigneeDetail.main_key,
      filter_key: assigneeDetail.id,
      image: assigneeDetail.image || "",
      name: assigneeDetail.name || "",
      is_assigned: 1,
    };
    if (
      requestAssignee.findIndex(
        (x) =>
          x.main_key == assigneeDetail.main_key &&
          x.filter_key == assigneeDetail.id
      ) !== -1
    ) {
      return;
    } else {
      setRequestAssignee([selectedAssignees]);
      dropDownActiveClass(
        true,
        `${assigneeDetail.main_key}_${assigneeDetail.id}`
      );
    }

    CustomerServices.setRequestTaskAssignee(taskId, [selectedAssignees]).then(
      (res) => {
        if (res.status && res.data && res.data.schedule_assignees) {
          if (requestAssignee.length) {
            setRequestAssignee([selectedAssignees]);
            dispatch(requestTaskAssignees([selectedAssignees]));
            updateRequestTitle(selectedAssignees.name);
            if (selectedAssignees.main_key == "provider_task") {
              getTodoTasks(selectedAssignees.filter_key, [selectedAssignees]);
            }

          } else {
            
            dispatch(requestTaskAssignees([]));
          }
        } 
      }
    );
  };
  const handleCommonAction = (type, data) => {
    if (type == 'nameChangeValue') {
      return;
    } else if (type == 'nameUpdate') {
      return;
    } else if (type == 'modalClose') {
      if (requestAssignee.length == 0) {
        hideTaskModal();
      } else {
        emptyHandleClose();
      }
    } else if (type == 'taskMoveBtn') {
      dispatch(
        getSelectedTask(
          localStorage.getItem("selectedOffice"),
          selectedTask && selectedTask.request_task_id,
        )
      );

      handler(
        selectedTask && selectedTask.request_task_id,
        "show",
        null,
        selectedTask && selectedTask.task_id
      );
    } else if (type == 'shareNetwork') {
      // setShow(true);
    } else if (type == 'showFullscreen') {
      setShowFullscreen(data)
    } else if (type == 'follow') {
      setShowLink(true);
    } else if (type == 'setMyr_total') {
      return 1;
    } else if (type == 'myrTotalUpdate') {
      return;
    } else if (type == 'totalRef') {
      return;
    }

  }
  const handleCommonHeaderBodyAction = (type, data, taskConvetId = '') => {
    if (type == 'dateUpdate') {
      setStartDate(data);
      updateTaskData(data);

    } else if (type == 'pin') {
      handlePinTask()
    } else if (type == 'status') {
      changeTaskStatus(data)
    } else if (type == 'priority') {
      changeTaskPriority();
    } else if (type == 'project') {
      return;

    } else if (type == 'recurring') {
      return;
    } else if (type == 'assignees') {
      handleRequestAssignee(data)
    } else if (type == 'tag') {
    } else if (type == 'handleTaskAssigneeData') {
      // handleTaskAssigneeData()
    } else if (type == 'visible_to_client') {
      updateTaskData(undefined, undefined, data ,visibleToProvider);
    } else if (type == 'visible_to_provider') {
      updateTaskData(undefined, undefined, visibleToCustomer, data);
    } else if (type == 'requestTaskConvert') {
      if (
        selectedTask &&
        selectedTask.is_allowed_to_edit == 1
      ) {
        if (convertedTaskId == 0) {
          if (checkListCount == 0) {
            setShowToast(false);
            setToastClass("unsucess");
            setToastHeader(_l("l_error"));
            setToastMessage(
              _l("l_please_enter_valid_checklist")
            );
            setShowToast(true);
          } else {
            setToastHeader(_l("l_are_you_sure"));
            setToastClass("toast-with-btn");
            setToastMessage(
              _l("l_only_one_action_perform_at_a_time")
            );
            setConfirmButtonText(_l("l_convert"));
            setCancelButtonText(_l("l_cancel"));
            setShowToast(true);
            setConvertTaskId({
              type: data,
              id: taskConvetId,
            });
          }
        }
      }
    }
  }
  const handleCommonFooter = (type, data) => {
    if (type == 'addComment') {
      addNewComment();
      setShowCommentModal(!ShowCommentModal);
      TaskCommentModalOpen();

    } else if (type == 'commentValueChange') {
      setCommentContent(data)
    } else if (type == 'removePreview') {
      removeImageSelection(data);
    } else if (type == 'fileUpload') {
      if (data.target.files.length > 5) {
        setToastClass("unsucess");
        setToastHeader(_l("l_error"));
        setToastMessage(
          _l(
            "l_maximum_5_files_are_allowed_at_a_time"
          )
        );
        setShowToast(true);
        return;
      }
      setImageBuffer(data.target.files);
      preparePreview(data.target.files);
    } else if (type == 'getComment') {
      setShowCommentModal(!ShowCommentModal);
      TaskCommentModalOpen();
      getTaskComments();
    }
  }
  return (
    <>
      {showToast ? (
        <Toasts
          delay={2000}
          handler={() => {
            setShowToast(false);
            setConvertTaskId({});
            // emptyHandleClose()
          }}
          header={toastHeader}
          message={toastMessage}
          toastClass={toastClass}
          cancelButtonText={cancelButtonText}
          cancelButtonHandler={() => {
            if (
              attachmentId == 0 &&
              tempCommentId == 0 &&
              Object.keys(convertTask).length == 0
            ) {
              deleteTask();
            }
            setShowToast(false);
            setTaskName("");
            setAttachmentId(0);
            setTempCommentId(0);
            setConvertTaskId({});
          }}
          confirmButtonText={confirmButtonText}
          confirmButtonHandler={() => {
            if (attachmentId != 0) {
              deleteImage(attachmentId.id, attachmentId.comment_id);
              setAttachmentId(0);
              setShowToast(false);
            } else if (tempCommentId) {
              deleteComment(tempCommentId);
              setTempCommentId(0);
              setShowToast(false);
              setTaskName("");
            } else if (Object.keys(convertTask).length) {
              handleConvertTask(convertTask.id, convertTask.type);
              setShowToast(false);
            } else {
              if (requestAssignee.length > 0) {
                // saveTask();
              } else {
                setShowToast(false);
                setToastClass("unsucess");
                setToastHeader(_l("l_error"));
                setToastMessage(_l("l_please_select_assignees"));
                setShowToast(true);
              }
            }
          }}
        />
      ) : (
        <></>
      )}

      <div
        className="custom-modal-style task-detail-modal"
        style={{ display: "none" }}
      >
        <Offcanvas
          show={show}
          onHide={emptyHandleClose}
          className="taskDetailModal custom-offcanvas-modal custom-modal-style"
          placement="end"
          backdrop={false}
        >
          <Offcanvas.Header className="">
            <Offcanvas.Title>{_l("l_add_new_task_request")}</Offcanvas.Title>
            <Form.Check
              className={`d-flex justify-content-end flex-grow-1 pe-2 ${userType == "staff" && !virtualSpaces ? "" : "d-none"
                }`}
              type="checkbox"
              id=""
              label="Visible to customer"
              checked={visibleToCustomer}
              onChange={(e) => {
                setVisibleToCustomer(e.target.checked);
                updateTaskData(undefined, undefined, e.target.checked);
              }}
            />
            <a href="#/"
              type="button"
              className="modal-close with_separator_10 pe-0 position-relaive  z-index-5"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={() => {
                if (
                  providerCategory == 0 &&
                  providerCategory == "" &&
                  convertedTaskId == 0
                ) {
                  hideTaskModal();
                } else {
                  emptyHandleClose();
                }
              }}
            // ref={closeButtonRef}
            >
              <X size={18} className="c-icons" weight="light" />
            </a>
          </Offcanvas.Header>
          <div
            className={`custom with-overlay-blur  ${isDisabled != "" ? "with-overlay-blur" : "d-none"
              } `}
          >
            {_l("l_please_wait")}
          </div>
          <Offcanvas.Body className="">
            <div className="form-wrapper-main h-100 pt-0">
              <div className="row h-100 ">
                <div className="col-xl-6 h-100">
                  <Form.Group className="c-input-box pb-4 position-relative d-none">
                  </Form.Group>
                  <Form.Group className="c-input-box pb-4 position-relative d-none">
                    <Select
                      className="custom-select-menu"
                      classNamePrefix="react-select"
                      options={providerCategorylist}
                      disabled={convertedTaskId > 0 ? true : false}
                      onChange={(value) => {
                      }}
                      value={
                        providerCategory != ""
                          ? { value: providerCategory, label: categoryTitle }
                          : ""
                      }
                      placeHolder={`${_l("l_select_provider_category")}`}
                    />
                  </Form.Group>

                  <Form.Group className="c-input-box pb-4 position-relative">
                    <div className="form-control file-upload position-relative">
                      <div className="d-flex align-items-center justify-content-center">
                        <input
                          className="  hidden-input"
                          type="file"
                          multiple
                          style={{ zIndex: "0" }}
                          onChange={(e) => {
                            if (addOrEditTaskId) {
                              if (e.target.files.length > 5) {
                                setToastClass("unsucess");
                                setToastHeader(_l("l_error"));
                                setToastMessage(
                                  _l("l_maximum_5_files_are_allowed_at_a_time")
                                );
                                setShowToast(true);
                                return;
                              } else {
                                CustomerServices.addLinkComment(
                                  addOrEditTaskId,
                                  projectId,
                                  null,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  e.target.files
                                ).then((res) => {
                                  if (res.status && res.data) {
                                    let tempImage = imagePreviews
                                      ? imagePreviews
                                      : [];
                                    if (
                                      res.data.files_list &&
                                      res.data.files_list.length
                                    ) {
                                      res.data.files_list.map((img) => {
                                        tempImage.push({
                                          id: img.file_id,
                                          image_url: img.image_url,
                                        });
                                      });
                                    }
                                    setImageUpload(true);
                                    setImagePreviews(tempImage);
                                  }
                                });
                              }
                            }
                          }}
                          disabled={convertedTaskId > 0 ? true : false}
                        />
                        <Paperclip size={18} className="c-icons" weight="light" />
                        <span className="color-white-60 ps-2">
                          {_l("l_drag_and_drop_or_browse")}
                        </span>
                      </div>
                    </div>
                    <div className="upload-image-preview mt-4">
                      <div className="d-flex flex-wrap align-items-center">
                        {imagePreviews.length ? (
                          imagePreviews.map((file, index) => {
                            return (
                              <div
                                key={index}
                                className="comman-image-box h40w40 radius_3 upload-image me-4"
                              >
                                <div
                                  className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                                  style={{
                                    backgroundImage: `url(${file.url ? file.url : file.image_url
                                      })`,
                                  }}
                                ></div>
                                <a href="#/"
                                  className="upload-close"
                                  onClick={() => {
                                    if (
                                      addOrEditTaskId &&
                                      addOrEditTaskId != ""
                                    ) {
                                      CustomerServices.deleteTaskDocument(
                                        projectId,
                                        file.id,
                                        1
                                      ).then((res) => {
                                        if (res && res.status)
                                          setImagePreviews(
                                            imagePreviews.filter(
                                              (imagePreview) =>
                                                imagePreview.id != file.id
                                            )
                                          );
                                      });
                                    }
                                  }}
                                >
                                  <X size={14} className="c-icons" weight="light" />
                                </a>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-xl-6 h-100">
                  <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk  d-none">
                      {_l("l_request_comment_detail")}
                    </Form.Label>
                    <div className="comment-input-box">
                      <div className="input-with-button">
                        <Form.Control
                          rows={1}
                          placeholder={`${_l("l_write_comment_placeholder")}`}
                          type="text"
                          value={comment}
                          onChange={(e) => {
                            setComment(e.target.value);

                            if (e.key == "Enter") {
                            }
                          }}
                          name=""
                          disabled={convertedTaskId > 0 ? true : false}
                        ></Form.Control>
                      </div>
                      <a href="#/"
                        className={`send-arrow-btn`}
                        onClick={() => {
                        }}
                      >
                        <ArrowRight size={18} className="c-icons" weight="light" />
                      </a>
                    </div>
                  </Form.Group>
                  <ul className="comment-list-wrapper comman_vertical_btn_h overflow-hiiden-auto">
                    {commentList && commentList.length ? (
                      commentList.map((comment, index) => {
                        if (
                          comment.content == "" &&
                          comment.attachments.length == 0
                        ) {
                          return;
                        }
                        return (
                          <li
                            className="notification-box w-100 border-bottom p-3 ps-0"
                            key={index}
                          >
                            <div className="d-flex justify-content-between w-100">
                              <div className="left-notification w-100">
                                <div className="d-flex align-items-start">
                                  <div className="user-imge">
                                    <div className="h30w30 comman-round-box with-bg d-flex align-items-center rounded-circle">
                                      <div
                                        className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                        style={{
                                          backgroundImage: `url('${comment.addedfrom_details &&
                                            comment.addedfrom_details
                                              .profile_url
                                            ? comment.addedfrom_details
                                              .profile_url
                                            : ""
                                            }')`,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="notification-detail w100minus40">
                                    <div>
                                      <span className="title-fonts text-truncate">
                                        {comment.addedfrom_details &&
                                          comment.addedfrom_details.full_name
                                          ? comment.addedfrom_details.full_name
                                          : ""}
                                      </span>
                                      <span className="px-2 c-font f-12 color-white-60 "></span>
                                      <span className="badge badge-white rounded-pill badge badge-white rounded-pill fw-light color-white-80">
                                        {comment.time ? comment.time : ""}
                                      </span>
                                    </div>
                                    <div className="c-font f-12 color-white-60 pt-1">
                                      {comment.content ? comment.content : ""}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </Offcanvas.Body>
          <div className="border-0 d-flex justify-content-end p-15 d-none">
            <Button variant="secondary me-3" onClick={emptyHandleClose}>
              {_l("l_close")}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                // saveTask();
              }}
            >
              {taskId ? _l("l_edit") : _l("l_save")}
            </Button>
          </div>
        </Offcanvas>
      </div>
      <Lightbox
        key={galleryIndex}
        open={openGallery}
        close={() => setOpenGallery(false)}
        slides={previewSrc.map((img) => {
          return { src: img.image_url };
        })}
      />
      <div className="custom-modal-style task-detail-modal ">
        <Offcanvas
          show={show}
          onHide={emptyHandleClose}
          className={`taskDetailModal custom-offcanvas-modal custom-modal-style ${showFullscreen ? "modal-full" : ""
            }`}
          placement="end"
          backdrop={false}
          scroll={false}
        >
          <Offcanvas.Header className="d-none">
            <Offcanvas.Title>
              {" "}
              {taskId > 0 ? _l("l_edit") : _l("l_add_new")}{" "}
              {_l("l_task_request")}
            </Offcanvas.Title>
            <div className="d-flex justify-content-between flex-wrap h-100 ">
              <a href="#/"
                type="button"
                className="modal-close p-0 position-relaive  z-index-5 modal-close-button task-detail-left-main"
                data-bs-dismiss="offcanvas"
                aria-label="close"
                onClick={() => {
                  if (requestAssignee.length == 0 && convertedTaskId == 0) {
                    hideTaskModal();
                  } else {
                    emptyHandleClose();
                  }
                }}
              // ref={closeButtonRef}
              >
                <X size={18} className="c-icons" weight="light" />
              </a>
            </div>
          </Offcanvas.Header>
          <div
            className={`custom with-overlay-blur  ${isDisabled != "" ? "with-overlay-blur" : "d-none"
              } `}
          >
            {_l("l_please_wait")}
          </div>
          <Offcanvas.Body className="overflow-hiiden-auto p-0">
            <div className="form-wrapper-main h-100 pt-0">
              <div className="d-flex justify-content-between flex-wrap h-100 ">
                <div className="task-detail-left-main h-100 res-width-100 d-flex flex-column flex-grow-1">
                  <CommonHeaderModal data={commonHeaderData} type={'requestTask'} handleAction={handleCommonAction} showTotal={false} />
                  <CommonHeaderBody data={commonHeaderBodyData} type={'requestTask'} handleAction={handleCommonHeaderBodyAction} checkListCounts={checkListCount} />
                  <div className="tab-wrapper_main pb-3 res-overflow-auto">
                    <div className="d-flex align-items-center justify-content-between border-bottom">
                      <div className="comman-tab-wrapper pb-0">
                        <div className="d-flex align-items-center">
                          <div
                            className={`tab-wrapper task-detail-tab ${selectedTab == "request" ? "active" : ""
                              } d-flex align-items-center`}
                          >
                            <a href="#/"
                              className="tab-name"
                              onClick={() => {
                                setSelectedTab("request");
                              }}
                            >
                              {_l("l_request")}
                            </a>
                          </div>
                          <div
                            className={`d-none tab-wrapper task-detail-tab ${selectedTab == "checklists" ? "active" : ""
                              } d-flex align-items-center`}
                          >
                            <a href="#/"
                              className="tab-name"
                              onClick={() => {
                                setSelectedTab("checklists");
                              }}
                            >
                              {_l("l_checklists")}
                            </a>
                          </div>
                          {selectedTab === "checklists" ? (
                            <div
                              className="switch"
                              data-tip={`${_l("l_toggle_detail")}`}
                              data-effect="solid"
                              data-delay-show='1000'
                              data-class="tooltip-main"
                            >
                              <div className="d-flex align-items-center">
                                <div className="c-font f-10 color-white-60 pe-2">
                                 
                                </div>
                                <div className="label-text d-none">
                                  <div className="d-flex align-items-center">
                                    <div className="form-check form-switch d-flex justify-content-end align-items-center">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        checked={isChecked}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className={`task-header-right d-none`}>
                        <div className="d-flex align-items-center justify-content-end">
                          <div className=" position-relative btn-secondary btn btn-sm">
                            <span className="">{_l("l_file_upload")}</span>
                            <input
                              type="file"
                              className="absolute-input"
                              multiple
                              onChange={(event) => {
                                CustomerServices.addLinkComment(
                                  addOrEditTaskId,
                                  projectId,
                                  "",
                                  undefined,
                                  undefined,
                                  undefined,
                                  1,
                                  event.target.files
                                ).then((res) => {
                                  if (res.status && res.data) {
                                    let tempImage = [];
                                    res.data.files_list.map((img) => {
                                      tempImage.push({
                                        id: img.file_id,
                                        image_url: img.image_url,
                                        contact_full_name:
                                          localStorage.getItem("full_name"),
                                      });
                                    });
                                    setAttachmentList(
                                      tempImage.concat(attachmentList)
                                    );
                                  }
                                });
                              }}
                              disabled={
                                convertedTaskId > 0 ||
                                  (selectedTask &&
                                    selectedTask.is_allowed_to_edit != 1)
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tast-detail-tab-wrapper res-height-auto">
                    {selectedTab == "request" ? (
                      <div className="h-100  overflow-hiiden-auto">
                        <ChecklistComponent
                          projectId={projectId}
                          task_id={taskId}
                          isChecked={isChecked}
                          setIsChecked={setIsChecked}
                          setRequestChecklist={setRequestChecklist}
                          isSystemTask={
                            (selectedTask &&
                              selectedTask.addedfrom == 0 &&
                              selectedTask.schedule_id &&
                              parseInt(selectedTask.schedule_id) > 0) ||
                              convertedTaskId > 0
                              ? true
                              : null
                          }
                          isRequestTask="1"
                          toastClose={() => { }}
                          setCheckListCount={setCheckListCount}
                          updateRequestTitle={updateRequestTitle}
                        />
                        <div class="color-white custom-accordian-Header-sticky with-top-46px p-2   my-2 radius_3">{_l('l_attachment_list')}</div>
                        <div
                          className={` d-flex flex-column  `}
                        >
                         
                          <div className=" custom-modal-section-sticky d-none">
                            <textarea
                              className="form-control "
                              placeholder={`${_l(
                                "l_task_description_placeholder"
                              )}`}
                              defaultValue={description}
                              onChange={(e) => {
                              }}
                              onBlur={() => {
                               
                              }}
                            />
                          </div>
                          <div className="d-flex flex-wrap">
                            {attachmentList.length ? (
                              attachmentList.map((attachment, index) => {
                                return (
                                  <div className="file-image-list" key={index}>
                                    <div className="c-list-icon w-100 position-relative with-overlay">
                                      <div
                                        className="h130w130 comman-round-box with-bg radius_3 with-react-fancybox"
                                        onClick={() => {
                                          setOpenGallery(true);
                                          setAttchmentOnTop(attachment.id);
                                        }}
                                      >
                                        <div
                                          className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                                          style={{
                                            backgroundImage: `url('${attachment.image_url}')`,
                                          }}
                                        ></div>
                                      </div>

                                      <a href="#/"
                                        className={`line-height-0 file-action-option ${selectedTask &&
                                          parseInt(
                                            selectedTask.is_allowed_to_edit
                                          ) == 1
                                          ? convertedTaskId > 0
                                            ? "d-none"
                                            : ""
                                          : attachment.is_allowed_to_edit == 1
                                            ? ""
                                            : "d-none"
                                          }`}
                                        type="button"
                                        id="DownloadDeleteDropdown"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        data-tip={_l("l_options")}
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main"
                                      >
                                        <DotsThreeVertical size={18} className="c-icons" weight="light" />
                                      </a>
                                      <ActionDropdown
                                        actions={[
                                          {
                                            actionName: "Delete",
                                            actionHandler: () => {
                                              setToastHeader(
                                                _l("l_are_you_sure")
                                              );
                                              setToastClass("toast-with-btn");
                                              setToastMessage(
                                                _l(
                                                  "l_you_want_to_delete_this_attachment"
                                                )
                                              );
                                              setConfirmButtonText(
                                                _l("l_delete")
                                              );
                                              setCancelButtonText(
                                                _l("l_cancel")
                                              );
                                              setShowToast(true);
                                              setAttachmentId({
                                                id: attachment.id,
                                                comment_id:
                                                  attachment.comment_file_id,
                                              });
                                            },
                                            actionIcon: "deleteIcon",
                                          },
                                        ]}
                                      />
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </div>
                          {attachmentList.length == 0 ? (
                            <CommanPlaceholder imgType="no-images" placeholderText = {_l("l_no_attachments")} />
                          ) : (
                            <></>
                          )}
                        </div>

                        <div className=" mt-auto ">
                          <div
                            className={`comment-input-box form-control d-flex border-0 ${description != "" ||
                              attachmentList.length ||
                              convertedTaskId > 0
                              ? "d-none"
                              : "d-none"
                              }`}
                          >
                            <input
                              className="bg-transparent p-0 flex-grow-1 text-truncate height-45 border-0 "
                              type="text"
                              placeholder={`${_l(
                                "l_write_description_placeholder"
                              )}`}
                              value={newDescription}
                              onChange={(e) => {
                               
                              }}
                              disabled={convertedTaskId > 0 ? true : false}
                            />
                            <div className="upload-image-preview p-0">
                              <div className="d-flex align-items-center justify-content-start">
                                {imageWithDescriptionPreviews.length ? (
                                  imageWithDescriptionPreviews.map(
                                    (preview, index) => {
                                      return (
                                        <div
                                          className="comman-image-box h25w25 radius_3 upload-image with-margin"
                                          key={index}
                                        >
                                          <div
                                            className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                                            style={{
                                              backgroundImage: `url('${preview.url}')`,
                                            }}
                                          ></div>
                                          <a href="#/"
                                            className="upload-close"
                                            onClick={() => {
                                              removeImageSelection(
                                                preview.name,
                                                "withDescription"
                                              );
                                            }}
                                          >
                                            <X size={10} className="c-icons" weight="light" />
                                          </a>
                                        </div>
                                      );
                                    }
                                  )
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>

                            <div
                              className={`comman_action_icon paper-clip-file ${description != "" ||
                                attachmentList.length ||
                                convertedTaskId > 0
                                ? "d-none"
                                : ""
                                }
                          `}
                            >
                              <div className="d-flex align-items-center">
                                <a href="#/"
                                  className="action_icon with_bg position-relative"
                                  data-class="tooltip-main"
                                >
                                  <input
                                    type="file"
                                    className="absolute-input"
                                    multiple
                                    accept="image/*"
                                    onChange={(event) => {
                                    }}
                                    disabled={
                                      convertedTaskId > 0 ? true : false
                                    }
                                  />

                                  <Paperclip size={18} className="c-icons" weight="light" />
                                </a>
                              </div>
                            </div>
                            <a href="#/"
                              className={`send-arrow-btn ${description != "" ||
                                attachmentList.length ||
                                convertedTaskId > 0
                                ? "d-none"
                                : ""
                                }`}
                            >
                              <ArrowRight size={18} className="c-icons" weight="light" />
                            </a>
                          </div>
                        </div>
                        
                      </div>
                    ) : (
                      
                      ""
                    )}
                  </div>
                  <CommonFooter data={{ comment: commentContent, preview: imagePreviews, unreadCommentCount: unreadCommentCount }} mentionList={mentionList} count={commentCount} type={'task'} handleAction={handleCommonFooter} isAllowToEdit={convertedTaskId == 0 ? selectedTask && selectedTask.is_allowed_to_edit == 1 : false} />
                </div>
                <div className="task-detail-right-main h-100 d-flex flex-column res-width-100">
                  <div className="right-modal-task-header border-bottom  p-15">
                    <div className="d-flex align-items-center">
                      <div className="right-task-header with_separator_10 flex-grow-1 px-0">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="task-header-left-options">
                            <div className="d-flex align-items-center justify-content-between">
                              
                            </div>
                          </div>
                          <div className="task-header-right-option">
                            <div className="d-flex align-items-center justify-content-between">
                              {showFullscreen ? (
                                <a href="#/"
                                  className="action_icon ml-10"
                                  onClick={() => setShowFullscreen(false)}
                                  data-tip={`${_l("l_reset_screen")}`}
                                  data-effect="solid"
                                  data-delay-show='1000'
                                  data-class="tooltip-main"
                                >
                                  <CornersIn size={18} className="c-icons zoom_in" weight="light" />
                                </a>
                              ) : (
                                <a href="#/"
                                  className="action_icon ml-10"
                                  id="modal_zoom_out"
                                  data-tip={`${_l("l_full_screen")}`}
                                  data-effect="solid"
                                  data-delay-show='1000'
                                  data-class="tooltip-main"
                                >
                                  <CornersOut size={18} className="c-icons zoom_out" weight="light" />
                                </a>
                              )}
                              <a href="#/" className="btn btn-white-10 btn-sm line-height-1 action_icon mx-2">
                                {_l("l_follow")}
                              </a>
                              <a href="#/" className="action_icon ml-10 d-none">
                                <VideoCamera size={18} className="c-icons" weight="light" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a href="#/"
                        type="button"
                        className="modal-close with_separator_10 pe-0 position-relaive  z-index-5"
                        data-bs-dismiss="offcanvas"
                        aria-label="close"
                        onClick={() => {
                          if (requestAssignee.length == 0) {
                            hideTaskModal();
                          } else {
                            emptyHandleClose();
                          }
                        }}
                      >
                        <X size={18} className="c-icons" weight="light" />
                      </a>
                    </div>
                  </div>
                  <div className="comman-tab-wrapper pb-0">
                    <div className="d-flex align-items-center w-100">
                      <div className="tab-wrapper d-flex align-items-center justify-content-center flex-grow-1 d-none">
                        <a href="#/" className="tab-name">{_l("l_comments")}</a>
                        <a href="#/" className="info-icon">
                          <Info size={16} className="c-icons" weight="light" />
                        </a>
                      </div>
                      <div className="active d-flex align-items-center justify-content-center flex-grow-1 d-none">
                        <a href="#/" className="tab-name">{_l("l_internal_rating")}</a>
                        <a href="#/" className="info-icon">
                          <Info size={16} className="c-icons" weight="light" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="right-chat-wrapper position-relative d-flex flex-column">
                    {showSearch ? (
                      <div className="search-with-close">
                        <div className="d-flex align-items-center ">
                          <div className="comment-input-box bg-base-header-color pb-0 radius_3  w-100">
                            <input
                              className="bg-base-header-color border-0 c-font f-12 form-control p-2 radius_3"
                              placeholder={`${_l("l_search")}`}
                              value={searchString}
                              onChange={(e) => handleSearch(e)}
                              disabled={convertedTaskId > 0 ? true : false}
                            />
                          </div>
                          <a href="#/" className="bg-base-header-color border-left d-flex h35w35 line-height-0">
                            <div
                              className="img-width-18 m-auto"
                              onClick={() => {
                                if (convertedTaskId == 0) {
                                  setshowSearch(false);
                                  setSearchString("");
                                }
                              }}
                            >
                              <X size={16} className="c-icons" weight="light" />
                            </div>
                          </a>
                        </div>
                        <div className="pill-wrapper d-none">
                          <a href="#/"
                            className="comman_action_icon filter-list-view d-none"
                            id="filter-show-list"
                          >
                            <div className="action_icon with_bg_03 h35w35">
                              <div
                                className="img-width-18 m-auto"
                                onClick={() => {
                                  setshowSearch(false);
                                  setSearchString("");
                                }}
                              >
                                <X size={16} className="c-icons" weight="light" />
                              </div>
                            </div>
                          </a>
                          <div
                            id="show-filter-list"
                            className="filter-list-toggle"
                          >
                            <div className="d-flex align-items-center filter-lists-menu min-width-190 justify-content-end">
                              <div className="d-flex commn-search radius_3 w-100 justify-content-between">
                                <div className="comment-input-box">
                                  <input
                                    type="text"
                                    id="task-search-string"
                                    className="form-control bg-transparent with-base-bg border-0 py-1 px-2 f-11 c-font"
                                    placeholder={`${_l("l_search")}`}
                                    data-bs-toggle="tooltip"
                                  />
                                </div>
                                <a href="#/" className="comman_action_icon">
                                  <div className="d-flex align-items-center ">
                                    <div className="action_icon h25w25">
                                      <MagnifyingGlass size={14} className="c-icons" weight="light" />
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {ShowCommentModal ? (
                      <RightSideComments
                        list={commentList}
                        handleDelete={deleteComment}
                        handleEditComment={handleEditComment}
                        convertedTaskId={convertedTaskId}
                        imagePreviews={imagePreviews}
                        removeImageSelection={removeImageSelection}
                        addNewComment={addNewComment}
                        mentionList={mentionList}
                        isAllowToEdit={
                          selectedTask && selectedTask.is_allowed_to_edit != 1 || convertedTaskId > 0
                        }
                        imageBuffer={imageBuffer}
                        showSearch={showSearch}
                        setImageBuffer={setImageBuffer}
                        preparePreview={preparePreview}
                        commentContent={commentContent}
                        setCommentContent={setCommentContent}
                        getMentionData={getMentionData}
                        setShowCommentModal={setShowCommentModal}
                        ShowCommentModal={ShowCommentModal}
                      />
                    ) : (
                      <></>
                    )}

                    <div className="chat-main-box flex-grow-1 comman_vertical_btn_h d-none">
                      <div className="d-flex flex-column h-100">
                        <div className="c-font f-12 py-1 px-2  border-bottom d-none">
                          {_l("l_comments")}
                        </div>
                        <div className="chat-wrapper" id="comment-list">
                          {commentList && commentList.length ? (
                            commentList.map((comment, index) => {
                              const commentContent = comment.content;
                              const dummyDiv = document.createElement("div");
                              dummyDiv.innerHTML = commentContent;
                              return (
                                <div
                                  key={index}
                                  className={`chat-box ${(userType == "contact" &&
                                    contact_id === comment.contact_id) ||
                                    (userType == "staff" &&
                                      staff_id == comment.staffid)
                                    ? "right-side-msg"
                                    : ""
                                    }`}
                                >
                                  <div className="d-flex">
                                    <div
                                      className="h30w30 comman-image-box with-bg rounded-circle bg-white-05"
                                      data-bs-toggle="tooltip"
                                      aria-expanded="false"
                                      data-tip={comment.contact_full_name}
                                      data-effect="solid"
                                      data-delay-show='1000'
                                      data-class="tooltip-main"
                                    >
                                      <div
                                        className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                        style={{
                                          backgroundImage: `url('${comment.addedfrom_details.profile_url}')`,
                                        }}
                                      ></div>
                                    </div>
                                    <div className="chat-msg">
                                      <div className="chat-text-box">
                                        <div className="image-upload-wrapper">
                                          {comment.attachments &&
                                            comment.attachments.length ? (
                                            <div className="d-flex align-items-center flex-wrap ">
                                              {comment.attachments.map(
                                                (image, index) => {
                                                  if (index < 3) {
                                                    return (
                                                      <a href="#/"
                                                        key={index}
                                                        className="h70w70 comman-round-box with-bg d-flex align-items-center bg-white-05 p-5px radius_3 m-auto mb-2"
                                                        onClick={() => {
                                                          setOpenGallery(true);
                                                          setGalleryIndex(
                                                            index
                                                          );
                                                          setPreviewSrc(
                                                            comment.attachments.map(
                                                              (
                                                                src,
                                                                innerIndex
                                                              ) => {
                                                                return {
                                                                  id: innerIndex,
                                                                  src: src.file_name,
                                                                };
                                                              }
                                                            )
                                                          );
                                                        }}
                                                      >
                                                        <div
                                                          className="comman-bg-img h-100 w-100 bg-style-cover"
                                                          style={{
                                                            backgroundImage: `url('${image.file_name}')`,
                                                          }}
                                                        ></div>
                                                      </a>
                                                    );
                                                  } else if (index === 3) {
                                                    return (
                                                      <a href="#/"
                                                        className="h70w70 comman-round-box with-bg d-flex align-items-center bg-white-05 p-5px radius_3 m-auto mb-2 with_overlay"
                                                        onClick={() => {
                                                          setOpenGallery(true);
                                                          setGalleryIndex(
                                                            index
                                                          );
                                                          setPreviewSrc(
                                                            comment.attachments.map(
                                                              (src) => {
                                                                return {
                                                                  src: src.file_name,
                                                                };
                                                              }
                                                            )
                                                          );
                                                        }}
                                                      >
                                                        <div class="unread_count m-auto">
                                                          +
                                                          {comment.attachments
                                                            .length - 3}
                                                        </div>
                                                      </a>
                                                    );
                                                  }
                                                }
                                              )}
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      
                                        <div className="chat-text">
                                          {/* {dummyDiv.innerText} */}
                                          {comment.content != "null" &&
                                            comment.content != "undefined"
                                            ? ReactHtmlParser(comment.content)
                                            : ""}
                                          {comment.content.match(
                                            "data-mention-id"
                                          ) && (
                                              <div className="radius_3 mension-detail-box p-1  radius_3 c-font f-12 title-fonts">
                                                <div className="mension-detail-wrapper p-1">
                                                  <div className="d-flex align-items-center">
                                                    <div className="h30w30 comman-round-box with-bg d-flex align-items-center">
                                                      <div
                                                        className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                                        style={{
                                                          backgroundImage: `url('https://source.unsplash.com/random')`,
                                                        }}
                                                      ></div>
                                                    </div>
                                                    <div className="mension-details color-white ps-2 text-truncate">
                                                      <div className="text-truncate">
                                                        John Smith
                                                      </div>
                                                      <div className="color-white-60">
                                                        Manager
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                        <div
                                          className={`dropdown chat-action-option ${comment.is_allowed_to_edit == "0" ||
                                            convertedTaskId > 0
                                            ? "d-none"
                                            : ""
                                            }`}
                                        >
                                          <a href="#/"
                                            className="line-height-0 "
                                            type="button"
                                            id="commentOptionDropdown"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            data-tip={_l("l_options")}
                                            data-effect="solid"
                                            data-delay-show='1000'
                                            data-class="tooltip-main"
                                          >
                                            <DotsThreeVertical size={18} className="c-icons" weight="light" />
                                          </a>
                                          {convertedTaskId > 0 ? (
                                            <></>
                                          ) : (
                                            <ActionDropdown
                                              key={index}
                                              actions={[
                                                {
                                                  actionName: _l("l_edit"),
                                                  actionHandler: () => {
                                                    handleEditComment(
                                                      comment.id
                                                    );
                                                  },
                                                  actionIcon: "PencilSimpleLineIcon",
                                                },
                                                {
                                                  actionName: _l("l_delete"),
                                                  actionHandler: () => {
                                                    setToastHeader(
                                                      _l("l_are_you_sure")
                                                    );
                                                    setToastClass(
                                                      "toast-with-btn"
                                                    );
                                                    setToastMessage(
                                                      _l(
                                                        "l_you_want_to_delete_this_commment"
                                                      )
                                                    );
                                                    setConfirmButtonText(
                                                      _l("l_delete")
                                                    );
                                                    setCancelButtonText(
                                                      _l("l_cancel")
                                                    );
                                                    setShowToast(true);
                                                    setTempCommentId(
                                                      comment.id
                                                    );
                                                  },
                                                  actionIcon: "TrashIcon",
                                                },
                                              ]}
                                            />
                                          )}

                                          
                                        </div>
                                      </div>
                                      <div className="chat-send-name c-font f-10 opacity-50">
                                        <span>{comment.time}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <>
                              <CommanPlaceholder imgType="no-comment" placeholderText = {_l("l_no_comments")} />
                            </>
                          )}
                        </div>
                        {commentTab ? (
                          <React.Fragment>
                            {imagePreviews.length ? (
                              <div className="upload-image-preview p-2">
                                <div className="d-flex align-items-center justify-content-start">
                                  {imagePreviews.map((preview, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="comman-image-box h40w40 radius_3 upload-image with-margin"
                                      >
                                        <div
                                          className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                                          style={{
                                            backgroundImage: `url(
                                      '${preview.url}'
                                    )`,
                                          }}
                                        ></div>
                                        <a href="#/"
                                          className="upload-close"
                                          onClick={() => {
                                            removeImageSelection(preview.name);
                                          }}
                                        >
                                          <X size={10} className="c-icons" weight="light" />
                                        </a>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}

                            <div className="chat-comment-box">
                              <div className="comment-input-box border-bottom">
                                <div className="d-flex align-items-center position-relative">
                                  <div
                                    className="h30w30 comman-image-box rounded-circle"
                                    data-bs-toggle="tooltip"
                                    title={localStorage.getItem("full_name")}
                                    aria-expanded="false"
                                    data-tip={localStorage.getItem("full_name")}
                                    data-effect="solid"
                                    data-delay-show='1000'
                                    data-class="tooltip-main"
                                  >
                                    <div
                                      className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                      style={{
                                        backgroundImage: `url('${contact_image}')`,
                                      }}
                                    ></div>
                                  </div>

                                  <MentionsInput
                                    value={commentContent}
                                    className="mension-box"
                                    placeholder={`${_l(
                                      "l_write_comment_placeholder"
                                    )}`}
                                    alwaysOpen={true}
                                    onChange={(e) =>
                                      setCommentContent(e.target.value)
                                    }
                                    onKeyDown={(e) => {
                                      if (e.keyCode === 13 && !e.ctrlKey && !e.shiftKey) {
                                        e.preventDefault();
                                        addNewComment();
                                      }
                                    }}
                                    disabled={
                                      convertedTaskId > 0 ||
                                        (selectedTask &&
                                          selectedTask.is_allowed_to_edit != 1)
                                        ? true
                                        : false
                                    }
                                 
                                  >
                                    <Mention
                                      markup='<span style="font-weight: 600;"  contenteditable="false" data-mention-id="__id__"  data-mce-style="font-weight: 600;"  data-mce-selected="1">@__display__</span>'
                                      trigger="@"
                                      data={mentionList}
                                      renderSuggestion={(
                                        suggestion,
                                        search,
                                        highlightedDisplay
                                      ) => {
                                        return (
                                          <div className="user d-flex align-items-center">
                                            <div className="comman-image-box h25w25 rounded-circle mx-2">
                                              <div
                                                className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                                style={{
                                                  backgroundImage: `url('${suggestion.image}')`,
                                                }}
                                              ></div>
                                            </div>
                                            {highlightedDisplay}
                                          </div>
                                        );
                                      }}
                                    />
                                  </MentionsInput>

                                  <a href="#/"
                                    className="send-arrow-btn with_position_right_zero"
                                    onClick={() => {
                                      if (
                                        convertedTaskId == 0 ||
                                        (selectedTask &&
                                          selectedTask.is_allowed_to_edit != 1)
                                      ) {
                                        addNewComment();
                                      }
                                    }}
                                  >
                                    <ArrowRight size={18} className="c-icons" weight="light" />
                                  </a>
                                </div>
                              </div>
                              <div className="comment-bottom-option">
                                <div className="d-flex align-items-center">
                                  <div className="comman_action_icon with_separator_10 ps-0">
                                    <div className="d-flex align-items-center">
                                      <a href="#/"
                                        className="action_icon with_bg"
                                        aria-expanded="false"
                                        data-tip={`${_l("l_mention_list")}`}
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main"
                                      >
                                        <div
                                          className="img-width-18"
                                          onClick={() => {
                                            if (convertedTaskId == 0) {
                                              getMentionData();
                                            }
                                          }}
                                        >
                                          <At size={18} className="c-icons" weight="light" />
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="comman_action_icon with_separator_10">
                                    <div className="d-flex align-items-center">
                                      <a href="#/"
                                        className="action_icon with_bg"
                                        aria-expanded="false"
                                        data-tip={`${_l("l_add_attachment")}`}
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main"
                                      >
                                        <input
                                          type="file"
                                          className="absolute-input"
                                          multiple
                                          accept="image/*"
                                          onChange={(event) => {
                                            if (event.target.files.length > 5) {
                                              setToastClass("unsucess");
                                              setToastHeader(_l("l_error"));
                                              setToastMessage(
                                                _l(
                                                  "l_maximum_5_files_are_allowed_at_a_time"
                                                )
                                              );
                                              setShowToast(true);
                                              return;
                                            }
                                            setImageBuffer([
                                              ...imageBuffer,
                                              ...event.target.files,
                                            ]);
                                            preparePreview(event.target.files);
                                            
                                          }}
                                          onClick={(e) => {
                                            e.target.value = "";
                                          }}
                                          disabled={
                                            convertedTaskId > 0 ||
                                              (selectedTask &&
                                                selectedTask.is_allowed_to_edit !=
                                                1)
                                              ? true
                                              : false
                                          }
                                        />
                                        <Paperclip size={18} className="c-icons" weight="light" />
                                      </a>
                                    </div>
                                  </div>
                                  <div className="comman_action_icon with_separator_10 d-none">
                                    <div className="d-flex align-items-center">
                                      <a href="#/"
                                        className="action_icon with_bg"
                                        aria-expanded="false"
                                        data-tip={`${_l("l_task_list")}`}
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main"
                                      >
                                        <Hash size={18} className="c-icons" weight="light" />
                                      </a>
                                    </div>
                                  </div>

                                  <div className="comman_action_icon with_separator_10">
                                    <div className="d-flex align-items-center">
                                      <a href="#/"
                                        className="action_icon with_bg"
                                        aria-expanded="false"
                                        data-tip={`${_l("l_search")}`}
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main"
                                        onClick={() => {
                                          setshowSearch(!showSearch);
                                        }}
                                      >
                                        {showSearch ? (
                                          <X size={18} className="c-icons" weight="light" />
                                        ) : (
                                          <MagnifyingGlass size={18} className="c-icons" weight="light" />
                                        )}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className=" overflow-auto d-none">
                              {showData == 1 && convertedTaskId == 0 ? (
                                <div className="mension-box-chet-box">
                                  {mentionList.map((e) => {
                                    return (
                                      <div>
                                        <p
                                          className="my-0 py-2"
                                        // onClick={hideMentionData}
                                        >
                                          {e.display}
                                        </p>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </React.Fragment>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div >
            <ReactTooltip />
          </Offcanvas.Body >
          <div className="border-0 d-flex justify-content-end p-15 d-none">
            <Button variant="secondary me-3" onClick={emptyHandleClose}>
              {_l("l_close")}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                // saveTask();
              }}
            >
              {taskId ? _l("l_edit") : _l("l_save")}
            </Button>
          </div>
        </Offcanvas >
      </div >
      {showLoader ? <CommanLoader /> : <></>}
      {
        showAddNewCategoryModal ? (
          <CategoryProviderList
            handleClose={() => {
              setShowAddNewCategoryModal(false);
            }}
            projectId={projectId}
            show={showAddNewCategoryModal}
            handleAddCategory={handleAddCategory}
          />
        ) : (
          <></>
        )
      }
      {
        show ? (
          <TaskShareLinkModal
            show={showLink}
            handleClose={handleCloseShareLink}
            handleShow={handleShowShareLink}
          />
        ) : (
          <></>
        )
      }
    </>
  );
};

export default TaskRequestModal;
