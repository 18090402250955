import React from 'react';
// import CSS from '../css/icon_main.css';

function ClockClockwiseIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"  className={`comman-icons ${CommanHW}`}>
<g clipPath="url(#clip0_15165_255274)">
<g clipPath="url(#clip1_15165_255274)">
<path d="M8 5V8" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.6 9.5L8 8" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.5125 6.23145H14.0125V3.73145" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.8875 11.8875C11.1185 12.6571 10.1385 13.1814 9.07147 13.394C8.00446 13.6066 6.89836 13.4979 5.8931 13.0818C4.88785 12.6656 4.0286 11.9607 3.42405 11.0561C2.81951 10.1515 2.49683 9.08799 2.49683 8C2.49683 6.91201 2.81951 5.84847 3.42405 4.9439C4.0286 4.03933 4.88785 3.33439 5.8931 2.91824C6.89836 2.50209 8.00446 2.39343 9.07147 2.60601C10.1385 2.81859 11.1185 3.34287 11.8875 4.1125L14.0125 6.23125" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</g>
<defs>
<clipPath id="clip0_15165_255274">
<rect width="16" height="16" />
</clipPath>
<clipPath id="clip1_15165_255274">
<rect width="16" height="16" />
</clipPath>
</defs>
</svg>


		</React.Fragment>
	)
}

export default ClockClockwiseIcon