
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useRef, useState } from "react";

const Reminders = ({ projectId, clientId, location, zoomLevel }) => {
    const [selectedTab, setSelectedTab] = useState("documents");


    const [startDate, setStartDate] = useState(new Date());
    return (
            <div>
            </div>
    );
};
export default Reminders;