import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { _l, accessPremiumFeature, saveDocumentDataToIDB } from "../hooks/utilities";
import {
  getSelectedTask,
  getBenchmarkWeekSchedule, getBenchmarkDetail, setCreateTaskModalIsopen,
  setMandatedDocumentData,
  setMandatedDocumentCoordinat
} from "../actions/customer";
import jquery, { data } from "jquery";
import AddScheduleIcon from "../assets/icons/AddScheduleIcon";
import AddTaskIcon from "../assets/icons/AddTaskIcon";
import CreateBenchmarkIcon from "../assets/icons/CreateBenchmarkIcon";
import SelectProviderCategory from "./Modals/SelectProviderCategory";
import CreateTaskOffcanvas from "./Offcanvas/CreateTaskOffcanvas";
import CreateTaskRequestOffcanvas from "./Offcanvas/CreateTaskRequestOffcanvas";
import CreateVirtualSpace from "./Modals/CreateVirtualSpace";
import AddLeadModal from "./Modals/AddLeadModal";
import CommanOffCanvas from "./Offcanvas/CommanOffCanvas";
import CreateScheduleOffcanvas from "./Offcanvas/CreateScheduleOffcanvas";
import NewSchedule from "./Offcanvas/NewSchedule/NewSchedule";
import { createScheduleDetails, updateTypeOfSpaceList } from "../actions/benchmark-actions/benchmark-actions";
import { Plus } from "phosphor-react";
import AddNewDocument from "./Modals/AddNewDocument";
import AddNewClient from "./Modals/AddNewClient";
import { CUSTOM_ROLES } from "../constants/constants";
import ImageDetailOffcanvas from "./Offcanvas/ImageDetailOffcanvas";
import { Spinner } from "react-bootstrap";
import documentsServices from "../services/documents-services";
import DocumentTemplateModal from "./Modals/DocumentTemplateModal";
import customerServices from "../services/customer-services";

const CommanFooterPlus = ({ projectId, pageName, id = "", toggleId = "",docType = "",bottomMenuClass="",MainClass="" , data = {} }) => {
  const { defaultTopics, taskTypeFilter, benchmarkDetail, globalView, mandatedDocumentCoordinate, leftPanelTab } = useSelector((state) => state.customer);
  const { floorDetails } = useSelector((state) => state.benchmarkReducer);
  const userType = localStorage.getItem("user_type");
  const project_id = useSelector((state) => state.customer.selectedProject);
  const [randomID, setRandomID] = useState(String(Math.random()));
  const [MyspaceActiveClass, SetMyspaceActiveClass] = useState(false);
  const [MyTaskActiveClass, SetMyTaskActiveClass] = useState(false);
  const [newTaskId, setNewTaskId] = useState(0);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showToolsModal, setShowToolsModal] = useState(false);
  const [showLeadModal, setShowLeadModal] = useState(false);
  const [showCreateScheduleForCustomer, setCreateScheduleForCustomer] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [categoryListing, setCategoryListing] = useState([]);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showBenchmarkModal, setShowBenchmarkModal] = useState(false);
  const [showVirtualSpace, setShowVirtualSpace] = useState(false);
  const [selectedVirtualSpace, setSelectedVirtualSpace] = useState(false);
  const [taskType, setTaskType] = useState('2');
  const [hideInEmail, setHideInEmail] = useState(false);
  const [commonModalData, setCommonModalData] = useState();
  const [NewScheduleFooter, setNewScheduleFooter] = useState(false);
  const [isBenchmark, setIsBenchmark] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const selectedTask = useSelector((state) => state.customer.selectedTask);
  const isPremiumEstimateAccess = useSelector(
    (state) => state.customer.isPremiumEstimateAccess
  );
  const isLeadPage = pageName == 'leadPage' ? 1 : 0
  const handleBenchmark = () => setShowBenchmarkModal(!showBenchmarkModal);
  const location = useLocation();
  const DrivePageTab = ["/my-drive"];

  const handleSchedules = (is_benchmark = false) => {
    if (!NewScheduleFooter && benchmarkDetail) {
      dispatch(
        getBenchmarkDetail(benchmarkDetail.id, localStorage.getItem("client_id"), "all")
      );
      dispatch(createScheduleDetails({}));
    }
    setNewScheduleFooter(!NewScheduleFooter);
    setIsBenchmark(is_benchmark);
    // setShowScheduleModal(!showScheduleModal)
  }
  const [selectedTaskId, setSelectedTaskId] = useState(0);
  const [task_type, setTask_type] = useState('');
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);
  const [openCreatClientModal, setOpenCreatClientModal] = useState(false);
  const [convertTaskId, setConvertTaskId] = useState(0);
  const client_id = localStorage.getItem("client_id");
  const contact_role = localStorage.getItem("contact_role");

  const [mandateDocumentToggle, setMandateDocumentToggle] = useState(false)
  const [isLoading, setisLoading] = useState(false);
  const [documentTemplateModal, setDocumentTemplateModal] = useState({doc_type : '', modal : false})
  const API_URL_CALLBACK = process.env.REACT_APP_PDF_CALLBACK;
  const API_URL_PDF = process.env.REACT_APP_PDF_MODULE_URL;


  let noPluseButtonPages = [
    "/dashboardpre",
    "/premiumsubscription",
    "/companyprofile",
    "/myprofile",
    "/chat",
    "/proposals",
    "/create-benchmark",
    "/liteversion",
    "/dashboard"
  ];
  useEffect(() => {
    jquery(`#${id}`).on("click", function () {
      jquery(`#${toggleId}`).toggleClass("show-menu-link");
      jquery(".rotate-image").toggleClass("plus-rotate-image");
    });
    jquery(document).mouseup(function (e) {
      var container = jquery(".footer-add-btn");

      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        jquery(`#${toggleId}`).removeClass("show-menu-link");
        jquery(`#${id} .rotate-image`).removeClass("plus-rotate-image");
      }
    });
  }, []);
  useEffect(() => {
    if (defaultTopics && defaultTopics.length > 0) {
      var main_key = userType == "contact" ? "provider_task" : "proposals";
      defaultTopics.map((item) => {
        if (item.main_key == main_key) {
          setCategoryListing(
            item.categories.map((item1) => {
              return {
                value: parseInt(item1.filter_key),
                label: item1.title,
                is_default: item1.is_default,
              };
            })
          );
        }
      });
    }
  }, [defaultTopics]);
  useEffect(() => {
    setTaskType(2)
    if (taskTypeFilter && taskTypeFilter != '') {
      setTaskType(taskTypeFilter == 'requestTask' ? 1 : taskTypeFilter == 'Task' ? 0 : 2)
    }
  }, [taskTypeFilter])


  const dispatch = useDispatch();


  const [preEstiPageDisable, setPreEstiPageDisable] = useState("");
  useEffect(() => {
    setPreEstiPageDisable(false);
    if (isPremiumEstimateAccess == true) {
      setPreEstiPageDisable(true);
    }
  }, [JSON.stringify(isPremiumEstimateAccess)]);


  useEffect(() => {
    if (window.location.pathname === "/") {
      SetMyTaskActiveClass(true);
    }
    if (
      window.location.pathname === "/myspacenew" ||
      window.location.pathname === "/new3DSpace"
    ) {
      SetMyspaceActiveClass(true);
    }
    if (window.location.pathname === "/MyEmail") {
      setHideInEmail(true);
    }
  }, [projectId]);

  useEffect(() => {
    setSelectedVirtualSpace(false)
    if (localStorage.getItem('selectedVirtualSpace') && JSON.parse(localStorage.getItem('selectedVirtualSpace'))) {
      let selectedVirutalSpace = JSON.parse(localStorage.getItem('selectedVirtualSpace'))
      if (Object.keys(selectedVirutalSpace).length) {
        setSelectedVirtualSpace(true)
      }

    }
  }, [localStorage.getItem('selectedVirtualSpace')])

  useEffect(() => {

    if (selectedTask && Object.keys(selectedTask).length) {
      setTask_type(selectedTask.is_requested && selectedTask.is_requested == 1 ? 'request-task' : 'task')
      setCommonModalData(selectedTask)
      if (pageName == "Dashboard" && showEditTaskModal) {
        jquery(".task-list-accordian").addClass("list-view-toggle");
      }

      let task_id = selectedTask.is_requested && selectedTask.is_requested == 1 ? selectedTask.task_id : selectedTask.id

      setTimeout(() => {

        if (selectedTask && pageName == "Dashboard" && showEditTaskModal) {

          if (typeof task_id == 'string') {
            jquery("#task_" + task_id.replace(new RegExp("=", "g"), '')).addClass("active");
          } else {

            jquery("#task_" + task_id).addClass("active");
          }

        }


      }, 100)

    } else if (task_type == 'task' || task_type == 'request-task') {
      jquery(".task-list-accordian").removeClass("list-view-toggle");

      setShowEditTaskModal(false);
      // setIsRequestTask()
      setCommonModalData();
      setSelectedTaskId(0);
      setTask_type('')
    }
    jquery(document).ready(function () {
      jquery("input").change(function () {
        // setUnsavedChanges(true);
      });
    });

    return () => {
      // imagePreviews.map((img) => {
      //   URL.revokeObjectURL(img.url);
      // });
      jquery(document).unbind("ready");
    };
  }, [JSON.stringify(selectedTask)]);

  useEffect(() => {
    if (benchmarkDetail) {
      // setIsBenchmark(benchmarkDetail.is_schedule == 0 ? true : false)
      setCommonModalData(benchmarkDetail);
      // setBenchmarkType(benchmarkDetail.is_schedule == 0 ? "benchmark" : "schedule")
    }
  }, [benchmarkDetail])

  if (isLeadPage) {
    jquery('.link-center-box-toggle').addClass('d-none')
  }



  const handleSelectedTask = (
    taskId = 0,
    show = "",
    isRequestedTask = null,
    convertRequestTaskId = 0
  ) => {
    setShowEditTaskModal(false);
    setSelectedTaskId(taskId);
    setConvertTaskId(convertRequestTaskId);
    localStorage.setItem("SelectedTask", taskId);
    localStorage.setItem(
      "TaskType",
      isRequestedTask && isRequestedTask != null ? isRequestedTask : 0
    );
    if (isRequestedTask || taskId) {

      setShowEditTaskModal(show);
    }
    jquery('#task_' + taskId).addClass('active')
    jquery('#list_view').addClass('list-view-toggle')
  };
  
  const scrollToBottom = () => {
    var msgListDiv = document.getElementById("message-list");
    if(msgListDiv){
      msgListDiv.scrollTop = msgListDiv.scrollHeight;
    }
  };

  const isAllowedToCreateMultiSpaceBenchmark = () => {
    let flag = false;
    if(location.pathname == "/dashboard" && userType == 'contact' && ![2, 3].includes(Number(contact_role)))
    {
      flag = true;
    }
    return flag;
  };

  const createBenchmark = () => {
    accessPremiumFeature(project_id, () => {
      // handleSchedules();
      if(location.pathname === "/dashboard")
      {
        navigate(`/create-dpgf-benchmark`);
      }
      else {
        navigate(`/connect-provider?space=${project_id}&provider-type=benchmark`);
      }
    });
    
  }
  const handlesetPDFFields = async(url,document_type,document_id, cordinates) =>{
    let option_rel_type = [];
    let option_menu = [];
    setisLoading(true);
    let response = await customerServices.getOptionList();
    if (response && response.status) {
      option_menu = response.data.option_menu;
      option_rel_type = response.data.option_rel_type;
    }
    let document_details = [{
      "document_id" : document_id,
      "document_type" : document_type,
      "staff_id" : localStorage.getItem("staff_id"),
      "userType" : localStorage.getItem("user_type"),
      "cordinates" : mandatedDocumentCoordinate && mandatedDocumentCoordinate.length > 0 ? JSON.parse(mandatedDocumentCoordinate) : []
  }]
    try {
      const form = new FormData();
      // form.append("call_back_url", "http://localhost/myrai-web/web/orb/Custom_actions/update_coordinates");
      const pdfCallbackURL = API_URL_CALLBACK;
      form.append("call_back_url", pdfCallbackURL);
      form.append("document_url", url);
      form.append("document_details", JSON.stringify(document_details));
      form.append("document_type", document_type);
      form.append("option_rel_type",option_rel_type.length > 0 ?  JSON.stringify(option_rel_type) : []);
      form.append("option_menu", option_menu.length > 0 ? JSON.stringify(option_menu) : []);

      // const res = await fetch('http://localhost/pdf-module-api/index.php?mod=general&act=upload_document', {
      const pdfURL = API_URL_PDF + "mod=general&act=upload_document"
      const res = await fetch(pdfURL, {
        method: 'POST',
        body: form,
    });
    if (!res.ok) {
      // If the response is not OK, throw an error
      setisLoading(false);
      // throw new Error(`HTTP error! status: ${res.status}`);

    }
      const response = await res.json();
      // console.log("data",data)
      if (response && response.data) {
        setisLoading(false);
        dispatch(setMandatedDocumentData({"data" : data, docType : document_type, hash : response.data}))
        setMandateDocumentToggle(true);
      }
   } catch (error) {
      console.log(error);
      throw error; // Rethrow the error for further handling if necessary

    }
  }

  const mandatedCoordinates = async() => {
    const userHashId =localStorage.getItem("user_hash")
    try {
      const newDocumentsResponse = await documentsServices.getDocumentsForDashboard(
        1,
        userHashId,
        1,
        "other_documents",
      );
      if(newDocumentsResponse.status)
      {
        
        const cordinates = newDocumentsResponse.data && newDocumentsResponse.data[`${docType}`] && newDocumentsResponse.data[`${docType}`][0].cordinates
                
        saveDocumentDataToIDB(newDocumentsResponse.data,()=>{}, false, "other_documents");
        dispatch(setMandatedDocumentCoordinat(cordinates));
      }
    } catch (error) {
      
    }
  }

  return (
    <React.Fragment>
      <div className={` footer-add-btn ms-auto ${MainClass}
       ${(noPluseButtonPages.includes(location.pathname) && !isAllowedToCreateMultiSpaceBenchmark()) || (contact_role == 3 && userType != "staff" && (localStorage.getItem("selectedOffice") == 0 || localStorage.getItem("selectedOffice") == undefined)) ? 'd-none' : ''}`}
        id={id}>
        { window.location.pathname == "/clients" || globalView != "liteVersionView" || globalView == "liteVersionView" ? 
        <a href="#/"
          className={`btn btnyellow comman-round-box d-flex h40w40 p-0 rounded-5 yellowbg z-index-3`}
          data-tip={`${_l("l_create")}`}
          data-effect="solid"
          data-delay-show='1000'
          data-place='left'
          data-for={randomID}
          data-class="tooltip-main"
        >
          {/* rotate-image remove from below div */}
          <div id="footer-plus-icon" className="comman-bg-img d-flex m-auto">
            <Plus size={22} weight="light" className="c-icons black-l-white" />
          </div>
          {/* <div className="ms-2 text-uppercase">{_l("l_create")}</div> */}
        </a> : <></>}
        {showDetails ? (
          <div className={`bottom-link-menu-options ${isLoading ? 'show-menu-link' : ''} ${bottomMenuClass} `} id={toggleId}>
            {
              pageName == 'leadPage' ?
                <a href="#/"
                  className="bottom-menu-link-one z-index-3 bottom-menu-links on-hover-active-toggle-img"
                  id="Add_Lead"
                  onClick={() => {
                    // handleCreateTask();
                    setShowLeadModal(true);
                    // setShow(true);
                  }}
                >
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                      {_l("l_add_lead")}
                    </div>
                    <div className="h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                      <div className="action_icon with_bg  d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                        <AddTaskIcon className="HW18 " />
                      </div>
                    </div>
                  </div>
                </a>
                :
                pageName == "Proposals"
                  ?
                  <a href="#/"
                    className={`bottom-menu-link-two z-index-3 bottom-menu-links on-hover-active-toggle-img`}
                    id="Create_Offer"
                    onClick={() => {
                      handleSchedules(true);
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                        {_l("l_create_offer")}
                      </div>
                      <div className="h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                        <div className="action_icon with_bg  d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                          <CreateBenchmarkIcon className="HW18 fill-transparent" />
                        </div>
                      </div>
                    </div>
                  </a>
                  : window.location.pathname == "/clients"
                  ?
                  <a href="#/"
                    className={`bottom-menu-link-two z-index-3 bottom-menu-links on-hover-active-toggle-img`}
                    id="Create_Offer"
                    onClick={() => {
                      setOpenCreatClientModal(true);
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                        {"Create New Client"}
                      </div>
                      <div className="h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                        <div className="action_icon with_bg  d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                          <CreateBenchmarkIcon className="HW18 fill-transparent" />
                        </div>
                      </div>
                    </div>
                  </a>
                  :
                  <>
                  {
                    isAllowedToCreateMultiSpaceBenchmark()
                    ?
                    <React.Fragment></React.Fragment>
                    :
                    <React.Fragment>
                      {
                       !DrivePageTab.includes(location.pathname) ? userType == "contact" && ![1, 2].includes(Number(contact_role))
                        ?
                        // Temporary Patch
                        <a href="#/"
                          className="bottom-menu-link-one z-index-3 bottom-menu-links on-hover-active-toggle-img"
                          id="Add_task"
                          onClick={() => {
                            // handleCreateTask();
                            setIsRequest(true);
                            setShowTaskModal(true);
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                              {_l("l_create_request")}
                            </div>
                            <div className="h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                              <div className="action_icon with_bg  d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                                <AddTaskIcon className="HW18 " />
                              </div>
                            </div>
                          </div>
                        </a>
                        :
                        <a href="#/"
                          className="bottom-menu-link-one z-index-3 bottom-menu-links on-hover-active-toggle-img"
                          id="Add_task"
                          onClick={() => {
                            // handleCreateTask();
                            if (pageName == "ChatAi" || globalView == "AichatView"){
                              setShowTaskModal(true);
                              dispatch(setCreateTaskModalIsopen(true))

                            } else if (( globalView == "liteVersionView" || globalView == "listView") ) 
                            {
                              dispatch(setCreateTaskModalIsopen(true))
                            }else{
                              setShowTaskModal(true);
                            }
                            setIsRequest(false);
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                              {_l("l_create_task")}/{_l("l_request")}
                            </div>
                            <div className="h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                              <div className="action_icon with_bg  d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                                <AddTaskIcon className="HW18 " />
                              </div>
                            </div>
                          </div>
                        </a>
                        : <></>
                      }
                    </React.Fragment>
                  }

                    {pageName=="LiteVersion" || docType == "imageview" || (userType == "contact" && !CUSTOM_ROLES.includes(contact_role)) ?
                    <></>
                    :
                    <>
                    {!DrivePageTab.includes(location.pathname) ? userType != "staff"  && ((userType == "contact" || userType == "operator") && contact_role != 3) && !isAllowedToCreateMultiSpaceBenchmark()?
                    <a href="#/"
                      className={`bottom-menu-link-one z-index-3 bottom-menu-links on-hover-active-toggle-img ${selectedVirtualSpace  ? 'd-none' : ''}`}
                      id="Add_schedule"
                      onClick={() => {
                        accessPremiumFeature(project_id, () => {
                          // handleSchedules();
                          navigate(`/connect-provider?space=${project_id}&provider-type=schedule`);
                        });
                      }}
                    >
                      <div className="d-flex align-items-center justify-content-end">
                        <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                          {_l("l_create_schedule")}
                        </div>
                        <div className="h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                          <div className="action_icon with_bg  d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                            <AddScheduleIcon className="HW18 " />
                          </div>
                        </div>
                      </div>
                    </a> : <></>
                     : <></>}
                     {!DrivePageTab.includes(location.pathname) ? 
                    <a href="#/"
                      className={`bottom-menu-link-two z-index-3 bottom-menu-links on-hover-active-toggle-img ${selectedVirtualSpace || userType == "operator" ? 'd-none' : ''}`}
                      id="Add_Benchmark"
                      onClick={createBenchmark}
                    >
                      <div className="d-flex align-items-center justify-content-end">
                        <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                          {/* {userType == "operator" ? _l("l_create")+" " + _l("l_proposal") : _l("l_create_benchmark")} */}
                          {_l("l_create_benchmark")}
                        </div>
                        <div className="h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                          <div className="action_icon with_bg  d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                            <CreateBenchmarkIcon className="HW18 fill-transparent" />
                          </div>
                        </div>
                      </div>
                    </a>
                    : <></>}
                    </> }
                  </>

            }
            <div className={`footer-add-btn-bg align-content-end ${docType ? "pdfplus" : "bgtransparent"}`}>
              { ["mandate", "reference", "dp"].includes(docType) ?  
              <div className=" d-flex flex-lg-column  ">
                    <a href="#/"
                      className={`bottom-menu-link-two z-index-3 bottom-menu-links on-hover-active-toggle-img `}
                      id="Add_Benchmark"
                      onClick={() => {
                        !isLoading && handlesetPDFFields(data.image_url, docType, data.id, data.cordinates)
                      }}
                    >
                      <div className="d-flex align-items-center justify-content-end">
                        <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                          {/* {userType == "operator" ? _l("l_create")+" " + _l("l_proposal") : _l("l_create_benchmark")} */}
                          {_l("l_edit")}
                        </div>
                        <div className="h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                          <div className="action_icon with_bg  d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                          {isLoading ?
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            className={`m-auto`}
                            aria-hidden="true"
                          /> : 
                          <CreateBenchmarkIcon className="HW18 fill-transparent" />}
                            
                          </div>
                        </div>
                      </div>
                    </a>
              </div>
              : <></>}
              {leftPanelTab == "my-drive" && docType == '' ? 
                <div className=" d-flex flex-lg-column  ">
                  <a href="#/"
                    className={`bottom-menu-link-two z-index-3 bottom-menu-links on-hover-active-toggle-img `}
                    id="Add_Benchmark"
                    onClick={() => {
                      setDocumentTemplateModal({doc_type : 'add_doc_template', modal : true})
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                        {/* {userType == "operator" ? _l("l_create")+" " + _l("l_proposal") : _l("l_create_benchmark")} */}
                        {_l("l_add_template")}
                      </div>
                      <div className="h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                        <div className="action_icon with_bg  d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                        {isLoading ?
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          className={`m-auto`}
                          aria-hidden="true"
                        /> : 
                        <CreateBenchmarkIcon className="HW18 fill-transparent" />}
                          
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              : <></>}
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>

      {
        show ? (
          <CreateTaskRequestOffcanvas
            show={show}
            handleClose={(taskId) => {
              setShow(false);
              setShowDetails(true);
              if (taskId) {
                setNewTaskId(taskId);
                dispatch(
                  getSelectedTask(
                    localStorage.getItem("selectedOffice"),
                    taskId,
                    1
                  )
                );
                setShowEditTaskModal(true)
                // setShowEditRequest(true);
              }
            }}
            pageName={pageName}
          />
        ) : (
          <></>
        )
      }
      {
        showCreateScheduleForCustomer ? (
          <CreateScheduleOffcanvas
            show={showCreateScheduleForCustomer}
            handleClose={() => {
              setCreateScheduleForCustomer(false);

            }}

          />
        ) : (
          <></>
        )
      }

      {
        showEditTaskModal ? <CommanOffCanvas
          show={showEditTaskModal}
          handleClose={() => {
            jquery(".task-list-accordian").removeClass("list-view-toggle");

            setShowEditTaskModal(false);
            // setIsRequestTask()
            setCommonModalData();
            setSelectedTaskId(0);
            setTask_type('')
          }}
          pinHandler={() => {
            // handlePinUnPin(id, type, flag);
          }}
          data={commonModalData}
          docType={task_type}
          setSelectedId={setSelectedTaskId}
          selectedTaskId={selectedTaskId}
          handleParentComponentAction={(type, taskData) => {
            if (task_type == 'task') {
              if (type == 'attachmentCountUpdate') {
                // updateAttchmentChecklistCount(taskData.task_id, taskData.flag)
                return;
              } else if (type == 'handleSelectedTask') {
                handleSelectedTask(taskData.task_id, taskData.flag)
                return;
              } else if (type == 'convertedTaskAction') {
                handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
                return;
              }
            } else if (task_type == 'request-task') {

              if (type == 'convertedTaskAction') {
                handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
                return;
              }
            }

          }}
          isCreateBenchmark={isBenchmark}
          isEdit={benchmarkDetail && Object.keys(benchmarkDetail).length ? true : false}
        /> : <></>
      }
      {
        mandateDocumentToggle ? <CommanOffCanvas
          show={mandateDocumentToggle}
          handleClose={() => {
            mandatedCoordinates();
            console.log("close");
            jquery(".task-list-accordian").removeClass("list-view-toggle");
            setMandateDocumentToggle(false);
          }}
          pinHandler={() => {
            // handlePinUnPin(id, type, flag);
          }}
          data={data}
          docType={"imageview"}
        /> : <></>
      }
      {
        showVirtualSpace ? (
          <CreateVirtualSpace
            projectId={projectId}
            show={showVirtualSpace}
            handleClose={() => {
              setShowVirtualSpace(false)
            }}

          />
        ) : (
          <></>
        )
      }
      {
        showScheduleModal ? (
          <SelectProviderCategory
            show={showScheduleModal}
            handleClose={() => {
              handleSchedules();
            }}
            handleShow={() => {
              handleSchedules();
            }}
            categoryListing={categoryListing}
            projectId={project_id}
            isCreateBenchmark={false}
          />
        ) : (
          ""
        )
      }
      {
        showBenchmarkModal ? (
          <SelectProviderCategory
            show={showBenchmarkModal}
            handleClose={() => {
              handleBenchmark();
            }}
            handleShow={() => {
              handleBenchmark();
            }}
            categoryListing={categoryListing}
            projectId={project_id}
            isCreateBenchmark={true}
          />
        ) : (
          ""
        )
      }
      {
        // const [NewScheduleFooter, setNewScheduleFooter] = useState(false);
        NewScheduleFooter ? (
          <NewSchedule
            show={NewScheduleFooter}
            handleClose={(id) => {
              setNewScheduleFooter(false);
              dispatch(updateTypeOfSpaceList(floorDetails.type_of_space.map((x) => x.id), false));
              if (id) {
                dispatch(getBenchmarkWeekSchedule(id));
                dispatch(getBenchmarkDetail(id, localStorage.getItem("client_id")));
                if (location.pathname == "/schedules") {
                  if (isBenchmark) {
                    setTask_type('benchmark')
                  }
                  else {
                    setTask_type('schedule')
                  }
                  setCommonModalData(benchmarkDetail);
                  setSelectedTaskId(id)
                  setShowEditTaskModal(true);
                }
                else {
                  navigate("/schedules", {
                    state: {
                      openDetailModal: true
                    }
                  });
                }
              }
              else {
                dispatch(createScheduleDetails({}));
                dispatch(getBenchmarkWeekSchedule(0, "all"));
              }
            }}
            isCreateBenchmark={isBenchmark}
          />
        ) : (
          <></>
        )
      }
      {
        showTaskModal ? (

          <CreateTaskOffcanvas
            show={showTaskModal}
            handleClose={(taskId) => {
              setShowTaskModal(false);
              scrollToBottom();
              // if(pageName != "ChatAi"){
                setShowDetails(true);
                if (taskId) {
                  setNewTaskId(taskId);
                  dispatch(
                    getSelectedTask(
                      localStorage.getItem("selectedOffice"),
                      taskId
                    )
                  );
                  setShowEditTaskModal(true)
                  // setShowEditRequest(true);
                }
              // }
            }}
            isRequestTask={isRequest}
            pageName={pageName}
          />
        ) : (
          <></>
        )
      }
      {
        showLeadModal ?
          <AddLeadModal
            show={showLeadModal}
            handleClose={() => {
              setShowLeadModal(false);
            }}
          />
          :
          <></>
      }
      {
        openCreatClientModal ?
          <AddNewClient
            show={openCreatClientModal}
            handleClose={() => {
              setOpenCreatClientModal(false);
            }}
          />
          :
          <></>
      }
      
      {
        showToolsModal ?
          <AddNewDocument
            show={showToolsModal}
            handleClose={() => {
              setShowToolsModal(false);
            }}
          />
          :
          <></>
      }
      {
        documentTemplateModal.doc_type == "add_doc_template" && documentTemplateModal.modal ? 
          <DocumentTemplateModal 
            docType = {documentTemplateModal.doc_type}
            show = {documentTemplateModal.modal}
            handleClose = {() => setDocumentTemplateModal({doc_type : '', modal : false})} 
          /> 
        :  <></>
      }
      <ReactTooltip id={randomID} />
    </React.Fragment>

  );
};

export default CommanFooterPlus;
