import React, { useEffect, useState } from "react";
import { _l, addExpandedClasstoIntroOfficeList, getFileType, openNewChatChannel, removeExpandedClassFromIntroOfficeList, roundOffPowerValue, showLeftPanelNew } from "../../hooks/utilities";
import moment from "moment";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { CalendarBlank, CaretDown, ChatCircle, Clock, Money, SquareHalf, Trash, Wallet } from "phosphor-react";
import SolarDocumentPreview from "./SolarDocumentPreview";
import MapIframe from "./MapIframe";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { Button, Spinner } from "react-bootstrap";
import { commanoffcanvasisOpenfromCalendar, getSelectedTask, leftPanelFilters } from "../../actions/customer";
import { useNavigate } from "react-router-dom";
import SignatureModal from "./SignatureModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { updateExternalGroupListMessages } from "../../actions/chat";
import CRMSkeleton from "../Skeleton/CRMSkeleton";
import NoMapPlaceholder from "../../assets/images/NoMapPlaceholder.svg";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import TaskListStatusDropdown from "../Dropdowns/TaskListStatusDropdown";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import jquery, { isNumeric } from "jquery";
import { showConfirmation } from "../../actions/messages";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const OperatorProposalList = ({ allProposals, onClick, isExternal, setProposalList =()=>{}, changeTaskStatus = () => {}, deleteProposalHandler= () => {}, setOpenNoteModal =() =>{}, showTaskDetails= ()=>{}}) => {
  const [proposalStatusFilter, setProposalStatusFilter] = useState(localStorage.getItem("crmProposalStatus") || "0");
  const {groupListExternal } = useSelector((state) => state.chat); 
  const {propsalFilters, commanoffcanvasIsOpen, selectedTask } = useSelector((state) => state.customer); 

  const [openLightBox, setOpenLightBox] = useState(false);
  const [lightBoxData, setLightBoxData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [proposalFilterValue, setProposalFilterValue] = useState(-1);
  const [currentProposal, setCurrentProposal] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSckeleton, setShowSckeleton] = useState(true);
  const [loadedImages, setLoadedImages] = useState({});
  const [proposals, setProposals] = useState([]);
  const [statusChangeLoader, setStatusChangeLoader] = useState(false);
  const [currentProposalId, setCurrentProposalId] = useState(0);
  const [rappleToggle, setRappleToggle] = useState(false);
  const [actionToggle, setActionToggle] = useState(-1);
  const [statusIndex, setStatusIndex] = useState(-1);
  useEffect(() => {
    setProposals(allProposals.filter((proposal) => {
      switch (proposalFilterValue) {
        case -1:
          return proposal;
        case 0:
          if (proposal.is_proposal_created_by_current_operator == 1) {
            return proposal;
          }
          break;
        case 1:
          if (proposal.is_proposal_created_by_current_operator != 1) {
            return proposal;
          }
          break;
        default:
          return proposal;
      }
    }));
  }, [JSON.stringify(allProposals)])

  const showImagePreview = (preview) => {
    setLightBoxData([{ src: preview, type: getFileType(preview) }]);
    setOpenLightBox(true);
  };

  const redirectToSpace = (proposal) => {
    //Select Solar category
    dispatch(
      leftPanelFilters({
        main_key: "providers",
        filter_key: "1",
        title: "Blaz",
        image: "",
        main_sub_key: "providers",
        sub_category: [],
      })
    );
    navigate(
      `/?main_key=providers&filter_key=1&main_sub_key=providers&space=${proposal.project_id}`
    );
  };

  const passedSearchFilter = (proposal) => {
    if (!searchText || !searchText.trim().length) {
      return true;
    } else {
      if (
        proposal.address &&
        proposal.address
          .trim()
          .toLowerCase()
          .includes(searchText.trim().toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  };

  const goToProposalDiscussion = (proposal) => {
    dispatch(leftPanelFilters("", "", "all"))

    const isProviderHasPlan = localStorage.getItem(
      "is_solar_provider_plan_purchased"
    );
    if (isProviderHasPlan && isProviderHasPlan.length) {
      let unreadindex = groupListExternal.findIndex((gd) => gd && gd.extraData && gd.extraData && gd.extraData.proposalId == proposal.proposal_id)
      if (unreadindex > -1) {
        let tempData = groupListExternal[unreadindex]
        tempData.unreadCount = 0
        let finalData = Object.values({ ...groupListExternal, [unreadindex]: tempData })
        dispatch(updateExternalGroupListMessages(finalData))

      }
      localStorage.setItem("currentProposal", JSON.stringify(proposal));
      openNewChatChannel(proposal.proposal_id);
    } else {
      setCurrentProposal(proposal);
    }
  };

  const getNotificationcount = (tab) =>{

    let notification  = 0
    switch (tab) {
      case "new":
            let templist1 =  proposals.filter(
          (w) =>
            passedSearchFilter(w) &&
            w. proposal_status == 4 &&
            (isNaN(Number(w.proposal_amount)) ||
              !Number(w.proposal_amount))
        )
        if (templist1.length > 0) {
          templist1.map((proposal)=>{
            groupListExternal.map((gd) =>{
              if (gd.extraData && gd.extraData.proposalId == proposal.proposal_id) {
                notification = notification + gd.unreadCount
              }
            })
          })
        }
        break;
      case "pendding":
            let templist2 =  proposals.filter(
          (w) =>
            passedSearchFilter(w) &&
            w. proposal_status == 4 &&
            !isNaN(Number(w.proposal_amount)) &&
            Number(w.proposal_amount) > 0
        )
        if (templist2.length > 0) {
          templist2.map((proposal)=>{
            groupListExternal.map((gd) =>{
              if (gd.extraData && gd.extraData.proposalId == proposal.proposal_id) {
                notification = notification + gd.unreadCount
              }
            })
          })
        }
        break;
      case "accepted":
            let templist3 =  proposals.filter(
          (w) => passedSearchFilter(w) && w. proposal_status == 3
        )    
        if (templist3.length > 0) {
          templist3.map((proposal)=>{
            groupListExternal.map((gd) =>{
              if (gd.extraData && gd.extraData.proposalId == proposal.proposal_id) {
                notification = notification + gd.unreadCount
              }
            })
          })
        }
        break;
      case "declined":
            let templist4 =  proposals.filter(
          (w) => passedSearchFilter(w) && w. proposal_status == 2
        )
        if (templist4.length > 0) {
          templist4.map((proposal)=>{
            groupListExternal.map((gd) =>{
              if (gd.extraData && gd.extraData.proposalId == proposal.proposal_id) {
                notification = notification + gd.unreadCount
              }
            })
          })
        }
        break;
    
      default:
        break;
    }
    return notification
  }
  

  useEffect(() => {
    setShowSckeleton(true);

    setProposalFilterValue(propsalFilters)
    setTimeout(() => {
      setShowSckeleton(false);
    }, 500);
  }, [propsalFilters])


  useEffect(() => {
    setShowSckeleton(true);
    setTimeout(() => {
      setShowSckeleton(false);
    }, 500);
  }, [proposalStatusFilter])
  
  const handleImageLoad = (index) => {
    setLoadedImages((prevState) => ({ ...prevState, [index]: true }));
  };

  // Handle image error event (optional, for broken images)
  const handleImageError = (index) => {
    setLoadedImages((prevState) => ({ ...prevState, [index]: true }));
  };


  return (
    <React.Fragment>
      <div className="flex-grow-1 d-flex flex-column h-100">
       
          <div className="registration-footer-content  flex-grow-1 overflow-hiiden-auto">
            <div className="position-sticky top-0  z-index-2 d-flex flex-column gap-3 white-shadow shadow-none pt-40px bgpatternCommanImg">
              {isExternal ? (
                <React.Fragment></React.Fragment>
              ) : (
                <div className="align-items-center justify-content-between horizontaltab btn-white-05 bg-solar d-flex p-1 mb-20 radius_5 text-uppercase shadow-none">
                  <label
                    className={`row-btn-label w-25 d-flex`}
                    for="flexRadioDefault1"
                  >
                    
                    <input
                      type="radio"
                      className="hidden-input"
                      name="proposal_status"
                      value="0"
                      checked={proposalStatusFilter == 0}
                      onChange={(e) => {
                        localStorage.setItem("crmProposalStatus", e.target.value);
                        setProposalStatusFilter(e.target.value);
                      }}
                    />
                    <div className="row-btn-box c-font f-12 p-2 fw-semibold title-fonts cblue w-100">
                      <span class="c-dots  c-8 bg-black me-2"></span>
                      <span>{_l("l_new_proposal")}</span>
                      <span className="ps-1">
                        - (
                        {
                          proposals.filter(
                            (w) =>
                              passedSearchFilter(w) &&
                              w. proposal_status == 4 &&
                              (isNaN(Number(w.proposal_amount)) ||
                                !Number(w.proposal_amount))
                          ).length
                        }
                        )
                      </span>
                      {getNotificationcount("new")  > 0 ? <span  class={`rounded-pill badge-custom  d-flex p-2 light-pill fw-semibold position-relative top-0 ${proposalStatusFilter == 0 ? "  " : ""}`}>{getNotificationcount("new")}</span> : <></>}
                    </div>
                  </label>
                  <label
                    className={`row-btn-label w-25`}
                    for="flexRadioDefault2"
                  >
                    <input
                      type="radio"
                      className="hidden-input"
                      name="proposal_status"
                      value="4"
                      checked={proposalStatusFilter == 4}
                      onChange={(e) => {
                        localStorage.setItem("crmProposalStatus", e.target.value);
                        setProposalStatusFilter(e.target.value);
                      }}
                    />
                    <div className="row-btn-box c-font f-12 p-2 fw-semibold title-fonts cblue w-100">
                    <span class="c-dots  c-8 bgyellow me-2"></span>
                      <span>{_l("l_pending")}</span>
                      <span className="ps-1">
                        - (
                        {
                          proposals.filter(
                            (w) =>
                              passedSearchFilter(w) &&
                              w. proposal_status == 4 &&
                              !isNaN(Number(w.proposal_amount)) &&
                              Number(w.proposal_amount) > 0
                          ).length
                        }
                        )
                      </span>
                      {getNotificationcount("pendding") > 0 ?<span  class={`rounded-pill badge-custom  d-flex p-2 light-pill fw-semibold position-relative top-0 ${proposalStatusFilter == 4 ? "  " : ""}`}>{getNotificationcount("pendding")}</span> : <></>}
                    </div>
                  </label>
                  <label
                    className={`row-btn-label w-25`}
                    for="flexRadioDefault3"
                  >
                    <input
                      type="radio"
                      className="hidden-input"
                      name="proposal_status"
                      value="3"
                      checked={proposalStatusFilter == 3}
                      onChange={(e) => {
                        localStorage.setItem("crmProposalStatus", e.target.value);
                        setProposalStatusFilter(e.target.value);
                      }}
                    />
                    <div className="row-btn-box c-font f-12 p-2 fw-semibold title-fonts cblue w-100">
                    <span class="c-dots  c-8 lightgreen me-2"></span>
                      <span>{_l("l_accepted")}</span>
                      <span className="ps-1">
                        - (
                        {
                          proposals.filter(
                            (w) => passedSearchFilter(w) && w. proposal_status == 3
                          ).length
                        }
                        )
                      </span>
                      {getNotificationcount("accepted") > 0 ?<span  class={`rounded-pill badge-custom  d-flex p-2 light-pill fw-semibold position-relative top-0 ${proposalStatusFilter == 3 ? "  " : ""}`}>{getNotificationcount("accepted")}</span> : <></>}
                    </div>
                  </label>
                  <label
                    className={`row-btn-label w-25 d-flex`}
                    for="flexRadioDefault4"
                  >
                    <input
                      type="radio"
                      className="hidden-input"
                      name="proposal_status"
                      value="2"
                      checked={proposalStatusFilter == 2}
                      onChange={(e) => {
                        localStorage.setItem("crmProposalStatus", e.target.value);
                        setProposalStatusFilter(e.target.value);
                      }}
                    />
                    <div className="row-btn-box c-font f-12 p-2 fw-semibold title-fonts cblue w-100">
                    <span class="c-dots  c-8 bg-red me-2"></span>
                      <span>{_l("l_declined")}</span>
                      <span className="ps-1">
                        - (
                        {
                          proposals.filter(
                            (w) => passedSearchFilter(w) && w. proposal_status == 2
                          ).length
                        }
                        )
                      </span>
                      {getNotificationcount("declined") > 0 ?<span  class={`rounded-pill badge-custom  d-flex p-2 light-pill fw-semibold position-relative top-0 ${proposalStatusFilter == 2 ? "  " : ""}`}>{getNotificationcount("declined")}</span> : <></>}
                    </div>
                  </label>
                </div>
              )}
            </div>
            {showSckeleton ? <CRMSkeleton /> :proposals.filter(
              (proposal) =>
                (proposalStatusFilter == 4 &&
                  proposal. proposal_status == proposalStatusFilter &&
                  Number(proposal.proposal_amount) > 0) ||
                (proposalStatusFilter == 3 &&
                  proposal. proposal_status == proposalStatusFilter) ||
                (proposalStatusFilter == 2 &&
                  proposal. proposal_status == proposalStatusFilter) ||
                (proposalStatusFilter == 0 &&
                  proposal. proposal_status == 4 &&
                  Number(proposal.proposal_amount) <= 0)
            ).length ? (
              <React.Fragment>
                {/* Proposal Cards Section start */}
                {proposals.length ? (
                  <div className="mx-auto d-grid gap-3  padding-bottom-80px">
                    {proposals.sort((a,b)=>{
                      let countA = 0;
                      if (groupListExternal && groupListExternal.length) {
                        groupListExternal.map((gd)=>{
                          if (gd.extraData && gd.extraData.proposalId == a.proposal_id) {
                            countA=gd.unreadCount
                          }
                        })
                      }
                      let countB = 0;
                      if (groupListExternal && groupListExternal.length) {
                        groupListExternal.map((gd)=>{
                          if (gd.extraData && gd.extraData.proposalId == b.proposal_id) {
                            countB = gd.unreadCount
                          }
                        })
                      }
                      return countB - countA 
                    }).map((proposal, index) => {
                      if (!passedSearchFilter(proposal)) {
                        return <></>;
                      }
                      if (
                        (proposalStatusFilter == 4 &&
                          proposal. proposal_status == proposalStatusFilter &&
                          Number(proposal.proposal_amount) > 0) ||
                        (proposalStatusFilter == 3 &&
                          proposal. proposal_status == proposalStatusFilter) ||
                        (proposalStatusFilter == 2 &&
                          proposal. proposal_status == proposalStatusFilter) ||
                        (proposalStatusFilter == 0 &&
                          proposal. proposal_status == 4 &&
                          Number(proposal.proposal_amount) <= 0)
                      ) {
                        return (
                          <div
                            key={index}
                            className="radius_3 d-flex comman-white-box-shadow overflow-hiiden-auto bg-white-03  flex-column-reverse flex-sm-row"
                          >
                            <div className="flex-grow-1 order-1">
                              <a
                                onClick={() => {
                                  onClick(proposal);
                                }}
                                className="d-flex align-align-items-center h-100 flex-column p-20pximp gap-4 gap-md-0 proposalListing p10pxresponsive"
                              >
                                <div className="d-flex justify-content-between flex-column flex-sm-row">
                                  <div className="d-flex flex-column flex-grow-1">
                                    <div className="d-flex align-items-center justify-content-between  listingtopwrapper">
                                      <div className="text-truncate mb20px">
                                        <div className="c-font f-16 fw-semibold text-truncate title-fonts">
                                          {proposal.title &&
                                          proposal.title !== ""
                                            ? proposal.title
                                            : `${_l(
                                                "l_solar_installation_title"
                                              )} ${roundOffPowerValue(
                                                proposal.solar_items
                                                  .electricity_production
                                              )} Kwc`}
                                        </div>
                                        <span class="c-font color-white-60 f-12 pt-1">
                                          {/* {`#${proposal.contact_id} - `} */}
                                          {`#${proposal.id} => `}{`#${proposal.contact_id} - `}
                                        </span>
                                        <span className="c-font color-white-60 f-12 pt-1 text-truncate">
                                          {proposal.address}
                                        </span>
                                      </div>
                                      <div className="d-flex align-items-center gap10px actionbtnswrapper mb20px">
                                        <div className={` dropstart dropdown-center comman_action_icon card-view-less-padding p-0 d-flex action_icon with_bg h32wauto on-hover-active-toggle-img  justify-content-center `}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setStatusIndex(Number(statusIndex) == Number(proposal.id) ? -1  : proposal.id);
                                        }}>
                                          <div
                                            disabled= {statusChangeLoader}
                                            className={`BigPill c-font close-toast d-flex f-12 fw-medium me-0 text-black text-uppercase w-100 commanbgwithborder`}
                                            type="button"
                                            id="TaskListStatusDropdown"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            data-tip={`${_l("l_status")}`}
                                            data-effect="solid"
                                            data-delay-show='1000'
                                            data-class="tooltip-main"
                                          >
                                            <div className="d-flex align-items-center w-100 justify-content-center gap10px">
                                            {statusChangeLoader && currentProposalId === proposal.id
                                              ? <></> 
                                              :
                                              <span
                                                className={`c-dots c-10 ${ proposal. proposal_status == 4 && Number(proposal.proposal_amount) <= 0
                                                  ? "bg-black"
                                                  :  proposal.proposal_status == 4
                                                    ? "bgyellow"
                                                    :  proposal.proposal_status == 3
                                                      ? "lightgreen"
                                                      : "bg-red"
                                                  }`}
                                              ></span>
                                              }
                                              <span className="ps-0">
                                                {statusChangeLoader && currentProposalId === proposal.id ? _l('l_please_wait')
                                                  : (proposal.proposal_status == 4 && Number(proposal.proposal_amount) <= 0
                                                  ? _l("l_new_proposal")
                                                  :  proposal.proposal_status == 4
                                                    ? _l("l_pending")
                                                    :  proposal.proposal_status == 3 ?
                                                    _l("l_accepted")
                                                      : _l("l_declined"))
                                                }
                                                {statusChangeLoader && currentProposalId === proposal.id
                                                  ? <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    className={`ms-2 `}
                                                    aria-hidden="true"
                                                  />
                                                  : <></>
                                                }
                                              </span>
                                              <CaretDown
                                                size={14}
                                                className="c-icons ms-auto me-0"
                                              />
                                              {Number(statusIndex) == Number(proposal.id) ? 
                                              <TaskListStatusDropdown
                                                setTaskStatus={(statusId) => {
                                                  changeTaskStatus(statusId,proposal,false, 0, setStatusChangeLoader);
                                                }}
                                                is_task = {proposal.id}
                                                isFrom = {"crm_list"}
                                                isShow = {true}
                                                isdisabled = {proposalStatusFilter == 0 
                                                                ? [4]
                                                                : proposalStatusFilter == 4 
                                                                  ? [4] 
                                                                  : proposalStatusFilter == 3
                                                                   ? [3]
                                                                   : [2]
                                                              }
                                                setCurrentProposalId = {setCurrentProposalId}
                                              />
                                              : <></>}
                                            </div>
                                          </div>
                                          <ReactTooltip />
                                        </div>
                                        <Button
                                          variant=""
                                          className="btn btn-white-05 position-relative d-flex align-items-center gap10px"
                                        >
                                          {proposal.proposal_status == 3 ? (
                                            <span
                                              onClick={() => {
                                                dispatch(
                                                  leftPanelFilters(
                                                    "",
                                                    "",
                                                    "all"
                                                  )
                                                );
                                                redirectToSpace(proposal);
                                              }}
                                            >
                                              {_l("l_access_to_space")}
                                            </span>
                                          ) : (
                                            <>
                                              <ChatCircle
                                                size={18}
                                                className="c-icons"
                                              />
                                              <span
                                                onClick={() => {
                                                  goToProposalDiscussion(
                                                    proposal
                                                  );
                                                }}
                                              >
                                                {_l("l_discuss")}
                                              </span>
                                              {groupListExternal.map((gd) => {
                                                if (
                                                  gd.extraData &&
                                                  gd.extraData.proposalId ==
                                                    proposal.proposal_id
                                                ) {
                                                  return (
                                                    <>
                                                      {gd.unreadCount > 0 ? (
                                                        <span
                                                          id="proposal-badge-count"
                                                          class="rounded-pill badge-custom  d-flex p-2 light-pill border-1"
                                                        >
                                                          {gd.unreadCount}
                                                        </span>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </>
                                                  );
                                                }
                                              })}
                                            </>
                                          )}
                                        </Button>
                                        <UncontrolledDropdown 
                                          isOpen={actionToggle == index}
                                          setActiveFromChild={true} 
                                          key={index}
                                          className="position-relative light-theme-blue-bg radius_5" onClick={(e) => e.stopPropagation()}>
                                          <DropdownToggle
                                            tag="a"
                                            className="btn btn-primary dropdown d-flex align-items-center gap-1 dropdown-center team-list-dropdown"
                                            onClick={() => {
                                              setActionToggle(actionToggle == index ? -1 : index);
                                              setRappleToggle(false);
                                            }}
                                          >
                                             {_l("l_action")}
                                          </DropdownToggle>
                                          {
                                            actionToggle == index ? 
                                            <DropdownMenu
                                              container="body"
                                              direction="down"
                                              className={`dropdown-menu w-140`}
                                            >
                                              <li className="hr_sepretr l_50px">
                                                <DropdownItem className="text-truncate" toggle={false}>
                                                  
                                                    <UncontrolledDropdown
                                                     isOpen={rappleToggle}
                                                     setActiveFromChild={true}
                                                     key={0}
                                                     direction="left"
                                                      className=" position-relative light-theme-blue-bg radius_5" onClick={(e) => e.stopPropagation()}>
                                                      <DropdownToggle
                                                        tag="a"
                                                        className=" dropdown d-flex align-items-center gap-1 dropdown-center team-list-dropdown"
                                                        onClick={() => setRappleToggle(true)}
                                                      >
                                                        <div className="c-list-icon">
                                                          <div className="align-items-center border comman-round-box d-flex h32w32 rounded-circle">
                                                            <Clock
                                                              size={18}
                                                              weight="light"
                                                              className="c-icons"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="ps-2 w100minus20 text_wrap">
                                                          <div className="c-list-detail text-truncate color-white">
                                                          Rappel {`(${proposal && proposal.reminder.length > 0 ? proposal.reminder.length : 0})`}
                                                          </div>
                                                        </div>
                                                       
                                                      </DropdownToggle>
                                                      {
                                                        rappleToggle ? 
                                                        <DropdownMenu
                                                          container="body"
                                                          direction="left"
                                                          className={`dropdown-menu `}
                                                        >
                                                            {proposal && proposal.reminder.length > 0 ? proposal.reminder.map((item) => {
                                                            return(
                                                              <li className="hr_sepretr l_50px">
                                                              <DropdownItem className="text-truncate"  toggle={false}>
                                                                <div className="d-flex align-items-center" onClick={() => {
                                                                  showTaskDetails(item.task_id);
                                                                  setActionToggle(-1);
                                                                  setRappleToggle(false);
                                                                  }}>
                                                                  <div className="ps-2 w100minus20 text_wrap">
                                                                    <div className="c-list-detail text-truncate color-white">
                                                                      { item.description}
                                                                    </div>
                                                                  </div>
                                                                    <div className="align-items-center d-flex">
                                                                      {moment(item.date).format("DD/MM/YYYY")}
                                                                    </div>
                                                                </div>
                                                              </DropdownItem>
                                                            </li>
                                                            )
                                                          }) : <CommanPlaceholder imgType="NoDoneTask-task" mainWidth="m-auto" placeholderText={"Aucun rappel trouvé"} />}
                                                        </DropdownMenu>
                                                        : <></>
                                                      }
                                                    </UncontrolledDropdown>
                                                </DropdownItem>
                                              </li>
                                              <li className="hr_sepretr l_50px">
                                                <DropdownItem className="text-truncate">
                                                  <div className="d-flex align-items-center" 
                                                  onClick={() => {
                                                    setActionToggle(-1);
                                                    setRappleToggle(false);
                                                    dispatch(
                                                      showConfirmation(
                                                        _l(
                                                          "l_are_you_sure"
                                                        ),
                                                        _l(
                                                          "l_you_want_to_delete_proposal"
                                                        ),
                                                        _l("l_yes"),
                                                        _l("l_no"),
                                                        undefined,
                                                        () => {
                                                          deleteProposalHandler(proposal);
                                                        }
                                                      )
                                                    );
                                                  }}>
                                                    <div className="c-list-icon">
                                                      <div className="align-items-center border comman-round-box d-flex h32w32 rounded-circle">
                                                        <Trash
                                                          size={18}
                                                          weight="light"
                                                          className="c-icons"
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="ps-2 w100minus20 text_wrap">
                                                      <div className="c-list-detail text-truncate color-white">
                                                        {_l("l_delete")}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </DropdownItem>
                                              </li>
                                             
                                             
                                            </DropdownMenu>
                                            : <></>
                                          }
                                        </UncontrolledDropdown>
                                        {proposal.proposal_status == 3 ? (
                                          <Button
                                            onClick={(e) => e.stopPropagation()}
                                            className="btn btn-primary position-relative radius_5"
                                            data-tip={_l("l_coming_soon")}
                                            data-effect="solid"
                                            data-delay-show="1000"
                                            data-class="tooltip-main"
                                            data-place="top"
                                          >
                                            <span>
                                              {_l("l_find_my_technician")}
                                            </span>
                                          </Button>
                                        ) : (
                                          <React.Fragment></React.Fragment>
                                        )}
                                        <ReactTooltip />
                                      </div>
                                    </div>
                                    <div className="align-items-start align-items-md-center  justify-content-between flex-wrap d-flex  flex-md-row gap-2">
                                      <div className="">
                                        <div className="d-flex align-items-center gap10px">
                                          <CalendarBlank
                                            size={18}
                                            className="c-icons m-0"
                                          />
                                          <div className="c-font f-15 fw-semibold title-fonts">
                                            {moment(proposal.start_date).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </div>
                                        </div>
                                        <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                                          {_l("l_start_date")}
                                        </div>
                                      </div>
                                      <div className="with_separator_10 p-0"></div>
                                      <div className=" ">
                                        <div className="d-flex align-items-center gap10px">
                                          <SquareHalf
                                            size={18}
                                            className="c-icons m-0"
                                          />
                                          <div className="c-font f-16 fw-semibold title-fonts">
                                            {proposal.solar_items
                                              ? proposal.solar_items
                                                  .no_of_plates
                                              : 0}
                                          </div>
                                        </div>
                                        <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                                          {_l("l_solar_panels")}
                                        </div>
                                      </div>
                                      <div className="with_separator_10 p-0"></div>
                                      <div className=" ">
                                        <div className="d-flex align-items-center gap10px">
                                          <Wallet
                                            size={18}
                                            className="c-icons m-0"
                                          />
                                          <div class="c-font f-16 fw-semibold title-fonts">
                                            {proposal.solar_items
                                              ? proposal.solar_items
                                                  .monthly_bill
                                              : "0"}{" "}
                                            €
                                          </div>
                                        </div>
                                        <div class="c-font color-white-60 f-12 pt-1 text-wrap light-theme-color-white-70">
                                          {_l("l_monthly_energy_bill")}
                                        </div>
                                      </div>

                                      {proposal.proposal_amount > 0 ? (
                                        <>
                                          <div className="with_separator_10 p-0"></div>
                                          <div className="color-greenpill ">
                                            <div className="d-flex align-items-center gap10px">
                                              <Money
                                                size={18}
                                                className="c-icons m-0"
                                              />
                                              <div className="c-font f-16 fw-bold title-fonts">
                                                {proposal.proposal_amount} €
                                              </div>
                                            </div>
                                            <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                                              {_l("l_bid")}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <React.Fragment></React.Fragment>
                                      )}

                                      <div className="with_separator_10 flex-grow-1 ps-20pximp pe-20pximp d-none">
                                        <div className="c-font f-16 fw-semibold title-fonts">
                                          {proposal.superficy}m²
                                        </div>
                                        <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                                          {_l("l_superficy")}
                                        </div>
                                      </div>
                                      <div className="with_separator_10 flex-grow-1 ps-20pximp d-none">
                                        <div className="c-font f-16 fw-semibold title-fonts">
                                          {proposal.no_of_hiring}
                                        </div>
                                        <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                                          {_l("l_tenants")}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div className="d-flex flex-column">
                              <div
                                className="w200px d-flex p-5px flex-grow-1 min-h-140 position-relative"
                                onClick={() => {
                                  if (proposal.solar_map) {
                                    showImagePreview(proposal.solar_map);
                                  }
                                }}
                              >
                                {proposal.is_proposal_created_by_current_operator >
                                0 ? (
                                  <div className="Proposalmappill comman-white-box-shadow">
                                    {_l("l_my_proposal")}
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {!loadedImages[index] && (
                                  <img
                                    src={NoMapPlaceholder} // Your placeholder image
                                    alt="placeholder"
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                                {proposal.solar_map ? (
                                  <img
                                    className={`h-100 w-100 object-fit-cover min-h-140`}
                                    src={proposal.solar_map}
                                    alt={"Solar Map"}
                                    style={{
                                      display: loadedImages[index]
                                        ? "block"
                                        : "none",
                                    }}
                                    onLoad={() => handleImageLoad(index)}
                                    onError={() => handleImageError(index)}
                                  />
                                ) : (
                                  <MapIframe
                                    fullWidth
                                    address={proposal.address}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
                {/* Proposal Cards Section End */}
              </React.Fragment>
            ) : (
              <CommanPlaceholder />
            )}
          </div>
          {/* <div className="col-lg-2"></div> */}
        {/* div chat dropdown start */}
        <div className="messaging-dropdown-main">

        </div>
        {/* div chat dropdown end */}
      </div>
      
      {/* <SolarFooter /> */}
      {openLightBox && lightBoxData.length ? (
        <SolarDocumentPreview
          open={openLightBox}
          setOpen={setOpenLightBox}
          slides={lightBoxData}
        />
      ) : (
        <></>
      )}
      {currentProposal && Object.keys(currentProposal).length ? (
        <Elements stripe={stripePromise}>
          <SignatureModal
            handleClose={() => {
              setCurrentProposal({});
            }}
            isProviderEstimate={true}
            proposalDetails={currentProposal}
          />
        </Elements>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  );
};

export default OperatorProposalList;
