import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  getBenchmarkDetail,
  setProject,
  setProjectTheme,
  setSelectedLanguage,
} from "../../../actions/customer";
import customerServices from "../../../services/customer-services";
import {
  CardListFullHeight,
  _l,
  getFileType,
  getImagePreviewIcon,
  hideSolairHubleftPanel,
  roundOffPowerValue,
  showError,
} from "../../../hooks/utilities";
import MapIframe from "../../SolarExternalPages/MapIframe";
import solarpanelsimg from "../../../assets/images/solar_panel.svg";
import accomptecalender from "../../../assets/images/accomptecalender.svg";
import { Button, Form } from "react-bootstrap";
import moment from "moment";
import {
  Check,
  CurrencyEur,
  FileText,
  Paperclip,
  PencilSimpleLine,
  Plus,
  Trash,
  X,
} from "phosphor-react";
import html2canvas from "html2canvas";
import {
  showConfirmation,
  showMessage,
  toastCloseAction,
} from "../../../actions/messages";
import BenchmarkServices from "../../../services/benchmark-services";
import Select from "react-select";
import SolarDocumentPreview from "../../SolarExternalPages/SolarDocumentPreview";
import CommanLoader from "../../Loader/CommanLoader";
import OnboardingHeader from "../../SpaceOnBoarding/OnboardingHeader";
import { DEFAULT_PLACEHOLDER } from "../../../actions/action-type";
import ExternalHeader from "../../ExternalHeader";
import CustomerProposalDetail from "../../SolarExternalPages/CustomerProposalDetail";
import ProviderProposalDetail from "../../SolarExternalPages/ProviderProposalDetail";

const ProposalDetails = ({ handleNextStep = () => {} }) => {
  const { benchmarkDetail, isLoadingBenchmarkInfo } = useSelector(
    (state) => state.customer
  );
  let userType = localStorage.getItem("user_type")

  const [searchParams, setSearchParams] = useSearchParams();
  const benchmark_id = searchParams.get("benchmark_id");
  const project_id = searchParams.get("project_id");
  const proposalId = searchParams.get("proposal_id");

  let proposal = {};

  const [accessoriesList, setAccessoriesList] = useState(
    proposal && proposal.proposal_item && proposal.proposal_item.length
      ? proposal.proposal_item
          .map((w) => {
            w.title = w.name || "";
            w.one_time_price =
              !isNaN(Number(w.one_time_amount)) && Number(w.one_time_amount) > 0
                ? Number(w.one_time_amount)
                : "";
            w.previewURL = "";
            w.provider_tax = w.provider_tax ? Number(w.provider_tax) : 0;
            return w;
          })
          .sort((a, b) => (b.government_help == 1) - (a.government_help == 1))
      : [
          {
            is_goverment: 0,
            title: _l("l_installation_and_labour"),
            one_time_amount: 0,
            one_time_price: 0,
            attachment_key: _l("l_installation_and_labour")
              .toLowerCase()
              .replace(/\s/g, "_"),
            previewURL: "",
            provider_tax: 0,
          },
        ]
  );

  const [mainLoader, setMainLoader] = useState(true);
  const [attachments, setAttachments] = useState({});


  const [financialAid, setFinancialAid] = useState(
    accessoriesList.length && accessoriesList[0].is_goverment > 0
      ? accessoriesList[0]
      : {}
  );

  const [proposalDetails, setProposalDetails] = useState()

  const [showLoading, setShowLoading] = useState(false);

  const paymentMethodOptions = [
    { label: _l("l_by_cheque"), value: "cheque" },
    { label: _l("l_by_bank_transfer"), value: "bank_transfer" },
  ];
  const VATselectionOption = [
    { label: "0%", value: "0" },
    { label: "5%", value: "5" },
    { label: "10%", value: "10" },
    { label: "20%", value: "20" },
  ];

  let lightBoxData = [];

  const languageSelect = [
    { value: "english", label: _l("l_english") },
    { value: "french", label: _l("l_french") },
  ];
  const [language, setlanguage] = useState(localStorage.getItem("language"));
  const setLanguage = (language) => {
    dispatch(setSelectedLanguage(language));
    localStorage.setItem("language", language);
  };

  const dispatch = useDispatch();

  const rejectProposal = () => {
    dispatch(
      showConfirmation(
        _l("l_are_you_sure"),
        _l("l_reject_proposal"),
        _l("l_reject"),
        _l("l_cancel"),
        () => {},
        async () => {
          try {
            setShowLoading(true);
            const response = await BenchmarkServices.rejectSolarProposal(
              benchmarkDetail.project_id,
              benchmarkDetail.id,
              proposalDetails.proposal_id
            );
            if (response.status) {
              dispatch(getBenchmarkDetail(benchmarkDetail.id));
            } else {
              showError(response.message);
            }
          } catch (e) {
            console.error(e);
            showError("l_something_went_wrong");
          } finally {
            setShowLoading(false);
            dispatch(toastCloseAction());
          }
        }
      )
    );
  };

  useEffect(() => {
    localStorage.setItem("selectedTheme", "light");
    dispatch(setProjectTheme("light"));
    document.body.classList.remove("with-left-penel");

    const element = document.getElementById("left-panel-main");
    if (element) {
      element.classList.add("d-none");
    }

    let attachmentPreviews = {};
    let financialAidPreview = "";

    if (proposal.proposal_file) {
      attachmentPreviews = {
        ...attachmentPreviews,
        contract: { previewURL: proposal.proposal_file },
      };
    }

    if (proposal.proposal_item && proposal.proposal_item.length) {
      proposal.proposal_item.forEach((item) => {
        if (item.attachment_url && item.attachment_url.length) {
          attachmentPreviews = {
            ...attachmentPreviews,
            [`${item.name.toLowerCase().replace(/\s/g, "_")}`]: {
              previewURL: item.attachment_url,
            },
          };
        }
        if (
          item.is_goverment > 0 &&
          item.attachment_url &&
          item.attachment_url.length
        ) {
          financialAidPreview = item.attachment_url;
          setFinancialAid({ ...financialAid, previewURL: item.attachment_url });
        }
      });
    }

    setAttachments(attachmentPreviews);

    return () => {
      if (element) {
        element.classList.remove("d-none");
        setMainLoader(true);
      }
    };
  }, []);

  useEffect(() => {
    if (benchmark_id && benchmark_id > 0 && project_id > 0) {
      dispatch(setProject(project_id));
      dispatch(getBenchmarkDetail(benchmark_id, project_id));
    }
}, [benchmark_id, project_id]);

  useEffect(() => {
    if (benchmarkDetail) {
      setMainLoader(false);
      proposal =
        benchmarkDetail.proposal_list && benchmarkDetail.proposal_list.length
          ? benchmarkDetail.proposal_list[0].proposals.find(
              (w) => w.proposal_id == benchmarkDetail.proposal_id
            )
          : {};
          let proposalList =
              benchmarkDetail.proposal_list &&
              benchmarkDetail.proposal_list.length &&
              benchmarkDetail.proposal_list[0].proposals
                ? benchmarkDetail.proposal_list[0].proposals
                : [];
          let propsal = proposalList.filter((p) => p.proposal_id == proposalId)
          setProposalDetails(propsal.length ? propsal[0] : {})
      lightBoxData = [
        {
          src: benchmarkDetail.solar_map,
          type: getFileType(benchmarkDetail.solar_map),
        },
      ];
    }
  }, [JSON.stringify(benchmarkDetail)]);

  useEffect(() => {
    CardListFullHeight();
    hideSolairHubleftPanel();

  });

  return (
    <>
      {isLoadingBenchmarkInfo || !benchmarkDetail ? (
        <>
          <CommanLoader className="main-loader-wrapper full-screen" />
        </>
      ) : (
        <>
          <ExternalHeader
            profileImageURL={DEFAULT_PLACEHOLDER}
            hideLeftPanel={true}
            setlanguage={setlanguage}
            language={language}
            setLanguage={setLanguage}
            languageSelect={languageSelect}
            pageName={"proposaldetails"}
            stepperProgress={{}}
          />
          <div className="RegNewFlow">   
         {userType == 'contact' ?
         <>
          {proposalDetails && 
          <CustomerProposalDetail
            proposal={proposalDetails}
            rejectProposal={rejectProposal}
            handleNextStep={() => {}}
          />}
         </> 
         : 
         <ProviderProposalDetail handleNextStep={() => {}}/>
          }
          </div>
        </>
      )}
    </>
  );
};

export default ProposalDetails;
