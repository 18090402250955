
import React from 'react';
// import CSS from '../css/icon_main.css';

function GlobeIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M2.34375 6H13.6562M2.34375 10H13.6562M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM10.5 8C10.5 11.224 9.38071 13.8375 8 13.8375C6.61929 13.8375 5.5 11.224 5.5 8C5.5 4.77604 6.61929 2.1625 8 2.1625C9.38071 2.1625 10.5 4.77604 10.5 8Z" />
</svg>


		</React.Fragment>
	)
}

export default GlobeIcon