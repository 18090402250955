import { Check, User, Users, UsersThree } from "phosphor-react";
import React from "react";
import { _l } from "../../hooks/utilities";
import { Form } from "react-bootstrap";

const getPhospherIcon = {
  0: <User className={"c-icons stroke-width-5px h60w60 my-0 resmx0"} size={26} />,
  1: <Users className={"c-icons stroke-width-5px h60w60 my-0 resmx0"} size={26} />,
  2: <UsersThree className={"c-icons stroke-width-5px h60w60 my-0 resmx0"} size={26} />,
};

const PeopleSelection = ({ name, people, setPeople, handleNextStep }) => {
  return (
    <div className="col-12 col-lg-10 col-xxl-7 flex-grow-1 mx-auto row">
      <div className="col-lg-2"></div>
      <div className="col-lg-12 mx-auto">
        <div className="d-flex flex-column flex-grow-1 align-items-center h-100 mb-70 occupants_select">
          <div className="row my-0 g-lg-4 justify-content-center flex-column flex-md-row g-3">
            {Array.from({ length: 3 }).map((element, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="space-select-box select-input-cox border mx-md-3 mx-md-1 bg-transparent w_200px px-0 radius10imp overflow-hidden borderfull">
                    <input
                      className="bottom-0 end-0 form-check-input h_150px w-100 position-absolute start-0 top-0 bg-transparent radius10imp overflow-hidden"
                      name={name}
                      type="radio"
                      checked={people == index + 1}
                      value={index + 1}
                      onChange={(e) => setPeople(e.target.value, true)}
                    />
                    <a href="#/" className="check-image">
                      <div className="check-image-box">
                        <Check size={14} className="c-icons color-white-03-solid" />
                      </div>
                    </a>
                    <div className="color-white-60">
                      <div className="d-flex bg-white-03 m-auto h_150px radius_3 flex-md-column align-items-center justify-content-center">
                        {getPhospherIcon[index]}
                        <div className="text-center p-10 py-0">
                          <div className="text-center c-font f-20 p-10 px-0 fw-semibold title-fonts">
                            0{_l(index + 1)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
            <div className="row my-0 g-lg-4 g-0 justify-content-center">
              <div className="space-select-box select-input-cox border mx-md-3 mt-3  mx-md-1 bg-transparent w_200px px-0 radius10imp overflow-hidden borderfull" onClick={() => {setPeople(4, true)}}>
                <input
                  className="bottom-0 end-0 form-check-input w-100 position-absolute start-0 top-0 bg-transparent h-100 radius10imp overflow-hidden"
                  name={name}
                  type="radio"
                  value={4}
                  checked={people == 4}
                  onChange={(e) => setPeople(e.target.value, true)}
                />
                <a href="#/" className="check-image">
                  <div className="check-image-box">
                    <Check size={14} className="c-icons color-white-03-solid" />
                  </div>
                </a>
                <div className="color-white-60">
                  <div className="d-flex bg-white-03 m-auto  radius_3 flex-lg-column align-items-center justify-content-center">
                    <div className="text-center p-10 py-0">
                      <div className="text-center c-font f-20 p-10 px-0 fw-semibold title-fonts">
                        0{_l(4)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-select-box select-input-cox border mx-md-3  mt-3 mx-md-1 bg-transparent w_200px px-0 radius10imp overflow-hidden borderfull" onClick={() => {setPeople(5, true)}}>
                <input
                  className="bottom-0 end-0 form-check-input w-100 position-absolute start-0 top-0 bg-transparent h-100 radius10imp overflow-hidden"
                  name={name}
                  type="radio"
                  value={5}
                  checked={people == 5}
                  onChange={(e) => setPeople(e.target.value, true)}
                />
                <a href="#/" className="check-image">
                  <div className="check-image-box">
                    <Check size={14} className="c-icons color-white-03-solid" />
                  </div>
                </a>
                <div className="color-white-60">
                  <div className="d-flex bg-white-03 m-auto  radius_3 flex-lg-column align-items-center justify-content-center">
                    <div className="text-center p-10 py-0">
                      <div className="text-center c-font f-20 p-10 px-0 fw-semibold title-fonts">
                        0{_l(5)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`space-select-box select-input-cox border mx-md-3  mt-3 mx-md-1 w_200px px-0 full borderfull ${people > 5 ? "active" : ""}`}>
              <a href="#/" className="check-image z-index-2">
                  <div className="check-image-box">
                    <Check size={14} className="c-icons color-white-03-solid" />
                  </div>
                </a>
                <div className="d-flex align-items-center form-check-input w-100 h-100  shadow-none">
                  <div className="stand-alone-top p-0 w-100">
                    <Form.Group
                      className={`c-input-box position-relative text-center `}
                    >
                      <div className="inputWrapper d-flex pb-0 border-0">
                        <Form.Control
                          placeholder={"Plus +"}
                          className="regFormInput text-center"
                          type={"text"}
                          value={people > 5 ? people : ""}
                          onChange={(e) => setPeople(e.target.value)}
                          onKeyUp={(e) => {
                            if (e.keyCode === 13) {
                              handleNextStep();
                            }
                          }}
                          autoFocus
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-4 pt-sm-5">
          <button type="button" class="h45w45 w-auto px-4 btn btn-primary" onClick={handleNextStep}>{_l("l_next")}</button>
          </div>
        </div>
      </div>
      <div className="col-lg-2"></div>
    </div>
  );
};

export default PeopleSelection;
