import React from 'react';
// import CSS from '../css/icon_main.css';

function LocationCustomIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
			<svg xmlns="http://www.w3.org/2000/svg" width="52" height="66" viewBox="0 0 52 66" fill="#00ff6a">
                        <path d="M26 0.773438C19.3202 0.781191 12.9161 3.43818 8.19279 8.16154C3.46943 12.8849 0.812441 19.2889 0.804688 25.9688C0.804688 35.0244 5.00586 44.6426 12.9541 53.7832C16.5415 57.9291 20.5791 61.663 24.9922 64.916C25.2876 65.1228 25.6394 65.2336 26 65.2336C26.3606 65.2336 26.7124 65.1228 27.0078 64.916C31.4209 61.663 35.4585 57.9291 39.0459 53.7832C46.9941 44.6426 51.1953 35.0332 51.1953 25.9688C51.1876 19.2889 48.5306 12.8849 43.8072 8.16154C39.0839 3.43818 32.6798 0.781191 26 0.773438ZM26 61.2744C21.6055 57.8819 4.32031 43.4356 4.32031 25.9688C4.32031 20.2189 6.60441 14.7046 10.6701 10.6389C14.7359 6.57317 20.2502 4.28906 26 4.28906C31.7498 4.28906 37.2641 6.57317 41.3299 10.6389C45.3956 14.7046 47.6797 20.2189 47.6797 25.9688C47.6797 43.4356 30.3945 57.8819 26 61.2744Z" fill="#00ff6a" fill-opacity="0.8" />
                    </svg>

		</React.Fragment>
	)
}

export default LocationCustomIcon