import React, { useEffect } from "react";
import { _l } from "../../../hooks/utilities";
import {
  CalendarPlus,
  Check,
  CheckCircle,
  EnvelopeSimpleOpen,
  Note,
} from "phosphor-react";
import { useNavigate, useSearchParams } from "react-router-dom";

let PROVIDER_TYPES = [
  {
    key: "l_schedule",
    description: "l_schedule_description",
    icon: CalendarPlus,
  },
  { key: "l_benchmark", description: "l_benchmark_description", icon: Note },
  {
    key: "l_invite_provider",
    description: "l_invite_provider_description",
    icon: EnvelopeSimpleOpen,
  },
];

const ProviderType = ({
  providerData,
  setProviderData,
  setStep,
  spaceDetails,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const userType = localStorage.getItem("user_type");
  const selectedOffice = localStorage.getItem("selectedOffice");

  if (userType === "operator") {
    const client_id = localStorage.getItem("client_id");
    if (
      spaceDetails &&
      (spaceDetails.is_added_from_operator != 0 &&
        spaceDetails.client_id == client_id)
    ) {
      PROVIDER_TYPES = [
        {
          key: "l_schedule",
          description: "l_schedule_description",
          icon: CalendarPlus,
        },
        {
          key: "l_invite_provider",
          description: "l_invite_customer_description",
          icon: EnvelopeSimpleOpen,
        },
        {
          key: "l_proposal",
          description: "l_proposal_description",
          icon: CalendarPlus,
        }
      ];
    }
    else 
    {
      PROVIDER_TYPES = [
        {
          key: "l_schedule",
          description: "l_schedule_description",
          icon: CalendarPlus,
        },
        {
          key: "l_proposal",
          description: "l_proposal_description",
          icon: CalendarPlus,
        },
      ];
    }
  }

  const setProviderType = (e) => {
    let providerType = e.target.value;

    if(providerType == "benchmark" || providerType == "schedule")
    {
      navigate(`/connect-provider?space=${selectedOffice}&provider-type=${providerType}`);
    }
    else {
      setProviderData({
        ...providerData,
        type: e.target.value,
      });
      searchParams.set("provider-type", e.target.value);
      setSearchParams(searchParams);
      if(userType == "operator")
      {
        setStep("select-provider");
      }
      else {
        setStep("provider-category");
      }
    }
  };

  return (
    <React.Fragment>
      {PROVIDER_TYPES.map((provider, key) => {
        let title = userType == "operator" && provider.key == "l_invite_provider" ? "l_invite_customer" : provider.key;
        return (
          <div key={key} className="space-select-box select-input-cox mx-3 bg-transparent">
            <input
              className="bottom-0 end-0 form-check-input h_230px position-absolute start-0 top-0 w-100 bg-transparent"
              name="provider-type"
              type="radio"
              value={provider.key.replace("l_", "")}
              checked={providerData.type === provider.key.replace("l_", "")}
              onClick={setProviderType}
              disabled = {provider.key == 'l_proposal'}
            />
            <a href="#/" className="check-image">
              <div className="check-image-box">
                <Check size={14} className="c-icons color-white-03-solid" />
              </div>
            </a>
            <div className="">
            <div className="d-flex bg-white-03 m-auto h_230px radius_3">
              <provider.icon size={56} weight="light" className="c-icons stroke-width-3px" />
            </div>
            <div className="text-center p-10">
              <div className="text-center c-font f-16 p-10 px-0 fw-semibold">
                {_l(title)}
              </div>
              <div className="text-center c-font f-14 color-white-60 fw-light">
                {_l(provider.description)}
              </div>
            </div>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default ProviderType;
