import React, { useEffect, useState, useRef } from "react";
import Calendar from "../../components/Calendar";
import 'intro.js/introjs.css';
import "react-datepicker/dist/react-datepicker.css";
import { _l, dashboardFilterByDefault } from "../../hooks/utilities";
import jquery from "jquery";
import MapLeft from "./MapLeft";
import MapMainCardList from "./MapMainCardList";
import MapTaskRequestList from "./MapTaskRequestList";
import CustomerServices from "../../services/customer-services";
import { useSelector, useDispatch } from "react-redux";
import { leftPanelFilters } from "../../actions/customer";
const MapPageCard = ({ }) => {
    const {
        calendarData,
        taskFilters,
        // taskSearchString,
        activeTaskStatusFilters,
    } = useSelector((state) => state.customer);
    const dispatch = useDispatch();
    useEffect(() => {
        // let activeOffice;
        // jquery(".office-detail").addClass("d-none");
        // jquery("body").addClass("pb-0");
        // jquery("footer").addClass("d-none");
        // jquery(".office-box").each((index, el) => {
        //     if (jquery(el).hasClass("active")) {
        //         activeOffice = el;
        //         jquery(activeOffice).removeClass("active");
        //     }
        // });
        // jquery("#HeaderCalendar").addClass("d-none");
        // jquery("#HeaderDocumentLinkDropdown").addClass("d-none");

        // return () => {
        //     jquery(".office-detail").removeClass("d-none");
        //     jquery("body").addClass("pb-0");
        //     jquery("footer").removeClass("d-none");
        //     // jquery(activeOffice).addClass('active');
        //     jquery("#HeaderCalendar").removeClass("d-none");
        //     jquery("#HeaderDocumentLinkDropdown").removeClass("d-none");
        // };
    });
    const [showLoader, setShowLoader] = useState(false);
    const [refetch, setRefetch] = useState(true);
    const [mailDate, seteMaildate] = useState(false);
    const [taskModalClose, setTaskModalClose] = useState(false);
    const [isStatusCleared, setIsStatusCleared] = useState(false);
    const [dateRange, setDateRange] = useState("init");
    const spaces = JSON.parse(localStorage.getItem("spaces"));
    const savedProject = localStorage.getItem("selectedOffice");
    const [dashboardDetails, setDashboardDetails] = useState({});
    const [projectId, setProjectId] = useState(
        savedProject && savedProject != null
            ? savedProject
            : spaces && spaces.length
                ? spaces[0].project_id
                : 0
    );
    const clearStatusFilter = (flag = true) => {
        setIsStatusCleared(flag);
    };
    const ModalClose = () => {
        setTaskModalClose(false)
        setTaskModalClose(true)
    }

    useEffect(() => {
        getDashboardDetails(activeTaskStatusFilters ? activeTaskStatusFilters : [], taskFilters)
    }, [localStorage.getItem("selectedOffice"), JSON.stringify(taskFilters), JSON.stringify(activeTaskStatusFilters), calendarData])
    const getDashboardDetails = (status, taskFilters) => {
        CustomerServices.getDashboardDetails(status, taskFilters).then((res) => {
            if (res.status) {
                if (res.data) {
                    setDashboardDetails({
                        step_2: {
                            request_percentage: res.data.request_percentage ? res.data.request_percentage : 0,
                            todo_percentage: res.data.todo_percentage ? res.data.todo_percentage : 0,
                            inprogress_percentage: res.data.inprogress_percentage ? res.data.inprogress_percentage : 0,
                            done_percentage: res.data.done_percentage ? res.data.done_percentage : 0,
                            completed_task: res.data.completed_task ? res.data.completed_task : 0,
                            on_going_task: res.data.on_going_task ? res.data.on_going_task : 0,
                            not_started_task: res.data.not_started_task ? res.data.not_started_task : 0,
                            requested_task: res.data.requested_task ? res.data.requested_task : 0,
                            workspace_score: res.data.workspace_score ? res.data.workspace_score : 0,
                        },
                        step_3: {
                            total_area: res.data.total_area ? res.data.total_area : 0,
                            area: res.data.area ? res.data.area : 0,
                            equipments_count: res.data.equipments_count ? res.data.equipments_count : 0,
                            equipments: res.data.equipments ? res.data.equipments : 0,
                            operator_count: res.data.operator_count ? res.data.operator_count : 0,
                            operators: res.data.operators ? res.data.operators : 0,
                            employee_count: res.data.employee_count ? res.data.employee_count : 0,
                            employees: res.data.employees ? res.data.employees : 0,
                            property_count: res.data.property_count ? res.data.property_count : 0,
                        }
                    })
                    localStorage.setItem('overallWorkSpaceScore', res.data.workspace_score ? res.data.workspace_score : 0)
                }

            }
        });
    }


    return (
        <>
            <div className="d-flex flex-column h-100">
                {/* <Calendar
                    projectId={projectId}
                    clearStatusFilter={clearStatusFilter} setTaskModalClose={ModalClose} seteMaildate={seteMaildate} setRefetch={setRefetch} pageName={'MyEmail'} setShowLoader={setShowLoader}
                /> */}
                <div className="map-main w-100">
                    {/* <div className="map-left">
                        <MapLeft />
                    </div> */}
                    <div className="map-right w-100">
                        <div className="w-100 map-right-main d-flex h-100">
                            <div className="map-right-task-request py-0 ps-0 p-10  flex-grow-1">
                                <MapTaskRequestList dashBoardDetails={dashboardDetails.step_2 ? dashboardDetails.step_2 : {}} />
                            </div>
                            <div className="main-card-list h-100 d-flex flex-column">
                                <MapMainCardList dashBoardDetails={dashboardDetails.step_3 ? dashboardDetails.step_3 : {}} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};
export default MapPageCard;