import { Button, Form, Modal } from "react-bootstrap";
import {
  _l,
  createCSVFile,
  csvOnLoadHandler,
  showError,
} from "../../hooks/utilities";
import { Paperclip } from "phosphor-react";
import { useState } from "react";
import React from "react";
import { BULK_EQUIPMENTS_IMPORT_SAMPLE_HEADER } from "../../constants/constants";
import { useSelector } from "react-redux";
import EquipmentsServices from "../../services/equipments-services";
const CSVParser = require("papaparse");

const AddBulkEquipments = ({ show, handleClose, setShow, projectId }) => {
  const [csvFile, setCsvFile] = useState("");
  const [processedCSV, setProcessedCSV] = useState("");

  const topics = useSelector((state) => state.customer.defaultTopics);
  const { type_of_space } = useSelector(
    (state) => state.benchmarkReducer.floorDetails
  );

  const addBulkEquipments = async (equipments) => {
    try {
      const response = await EquipmentsServices.addBulkEquipments(
        projectId,
        equipments
      );
      if (!response.status) {
        showError(response.message);
      }
    } catch (e) {
      console.error(e);
    }
    finally {
        handleClose();
    }
  };

  const handleImport = async () => {
    setCsvFile("");

    let equipments = [];

    let operatorData = topics
      ? topics.find((w) => w.main_key === "my_operator")
      : [];
    operatorData =
      operatorData && operatorData.categories
        ? operatorData.categories
            .filter((w) => w.main_sub_key !== "team")
            .map((w) => {
              return { email: w.email, value: w.filter_key };
            })
        : [];

    let contactData = topics
      ? topics.find((w) => w.main_key === "my_contact")
      : [];
    contactData =
      contactData && contactData.categories
        ? contactData.categories
            .filter((w) => w.main_sub_key !== "team")
            .map((w) => {
              return { email: w.email, value: w.filter_key };
            })
        : [];

    try {
      if (processedCSV) {
        CSVParser.parse(processedCSV, {
          complete: function (results) {
            const rows = results.data.slice(1); //Remove Header
            for (const row of rows) {
              /**Skip the record if it does not have required information */
              if (
                row[0].trim().length &&
                row[1].trim().length &&
                row[2].trim().length &&
                row[3].trim().length
              ) {
                let managerData = contactData.find((w) => w.email === row[2]);
                let providerData = operatorData.find((w) => w.email === row[3]);

                let equipmentData = {
                  equipment_name: row[0],
                  quantity: Number(row[1]),
                  customer_assignee: managerData ? managerData.value : 0,
                  provider_assignee: providerData ? providerData.value : 0,
                  quantity_item: [],
                };
                /**Get data of every equipment Items */
                for (
                  let providerItemIndex = 4;
                  providerItemIndex < row.length;
                  providerItemIndex += 6
                ) {
                  let employeeData = contactData.find(
                    (w) => w.email === row[providerItemIndex + 2]
                  );
                  let typeOfSpaceItemId = 0;
                  let floor_id = 0;

                  type_of_space.forEach((space) => {
                    space.details.forEach((item) => {
                      if (item.title === row[providerItemIndex + 4]) {
                        typeOfSpaceItemId = item.id;
                        floor_id = item.floor_id;
                      }
                    });
                  });

                  equipmentData.quantity_item.push({
                    quantity_name: row[providerItemIndex] || "",
                    device_id: row[providerItemIndex + 1] || "",
                    contact_assignee: employeeData ? employeeData.value : 0,
                    type_of_space_id: typeOfSpaceItemId,
                    floor_id,
                    file_link: row[providerItemIndex + 5] || "",
                  });
                }
                equipments.push(equipmentData);
              }
            }
            if (equipments.length) {
              addBulkEquipments(equipments);
            }
          },
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setShow(false);
    }
  };

  const downloadSample = () => {
    let csvToArray = [BULK_EQUIPMENTS_IMPORT_SAMPLE_HEADER];
    /**
     * Dummy columns for employees
     */
    for (let employee = 0; employee < 10; employee++) {
      csvToArray[0] = csvToArray[0].concat([
        _l("l_item_name"),
        _l("l_device_id"),
        `${_l("l_contact")} ${_l("l_email")}`,
        _l("l_floor_title"),
        _l("l_type_of_space_label"),
        _l("l_equipment_image_url"),
      ]);
    }
    createCSVFile(csvToArray, `${_l("l_template")}.csv`);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="custom-modal-style"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>{_l("l_import_items")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className=" pt-0">
        <div className="form-wrapper-main">
          <div className="row pb-3">
            <ol start={1} className="ps-4">
              <li className="with-listing-count pb-1">
                {_l("l_you_csv_data_string")}
                <b> UTF-8 </b> {_l("l_to_avoid_unnecessary")}{" "}
                <b>{_l("l_encoding_problems")}</b>
              </li>
            </ol>
            <div className="comman-data-table" style={{ overflow: "auto" }}>
              <table className="dataTable GeneralItemTable">
                <thead className="WithHeaderbg c-font f-13 text-center">
                  <tr>
                    <th className="">{_l("l_equipment_name")}</th>
                    <th className="">{_l("l_quantity")}</th>
                    <th className="">{`${_l("l_manager_assignee")} ${_l(
                      "l_email"
                    )}`}</th>
                    <th className="">{`${_l("l_provider_assignee")} ${_l(
                      "l_email"
                    )}`}</th>
                    <th className="">{_l("l_item_name")}</th>
                    <th className="">{_l("l_device_id")}</th>
                    <th className="">{`${_l("l_contact")} ${_l(
                      "l_email"
                    )}`}</th>
                    <th className="">{_l("l_floor_title")}</th>
                    <th className="">{_l("l_type_of_space_label")}</th>
                    <th className="">{_l("l_equipment_image_url")}</th>
                    <th className="">{_l("l_item_name")}</th>
                    <th className="">{_l("l_device_id")}</th>
                    <th className="">{`${_l("l_contact")} ${_l(
                      "l_email"
                    )}`}</th>
                    <th className="">{_l("l_floor_title")}</th>
                    <th className="">{_l("l_type_of_space_label")}</th>
                    <th className="">{_l("l_equipment_image_url")}</th>
                    <th className="">{_l("l_item_name")}</th>
                    <th className="">{_l("l_device_id")}</th>
                    <th className="">{`${_l("l_contact")} ${_l(
                      "l_email"
                    )}`}</th>
                    <th className="">{_l("l_floor_title")}</th>
                    <th className="">{_l("l_type_of_space_label")}</th>
                    <th className="">{_l("l_equipment_image_url")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="c-font f-13 text-center text-nowrap">
                    <td className="c-font f-13 text-nowrap">Robot</td>
                    <td className="c-font f-13 text-nowrap">2</td>
                    <td className="c-font f-13 text-center text-nowrap">
                      manager2@gmail.com
                    </td>
                    <td className="c-font f-13 text-center text-nowrap">
                      provider2@gmail.com
                    </td>
                    <td className="c-font f-13 text-center text-nowrap">
                      Robot 1
                    </td>
                    <td className="c-font f-13 text-center text-nowrap">
                      HSJAAAGHHAB
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      customer1@gmail.com
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      {_l("l_floor_system_generated_title")} 1
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      {_l("l_meeting_room")} 1
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      www.freeimages.org/image1.png
                    </td>
                    <td className="c-font f-13 text-center text-nowrap">
                      Robot 2
                    </td>
                    <td className="c-font f-13 text-center text-nowrap">
                      HSJAAAGHHAB
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      customer2@gmail.com
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      {_l("l_floor_system_generated_title")} 1
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      {_l("l_meeting_room")} 1
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      www.freeimages.org/image2.png
                    </td>
                  </tr>
                  <tr className="c-font f-13 text-center text-nowrap">
                    <td className="c-font f-13 text-nowrap">Mobile</td>
                    <td className="c-font f-13 text-nowrap">3</td>
                    <td className="c-font f-13 text-center text-nowrap">
                      manager1@gmail.com
                    </td>
                    <td className="c-font f-13 text-center text-nowrap">
                      provider1@gmail.com
                    </td>
                    <td className="c-font f-13 text-center text-nowrap">
                      Mobile 1
                    </td>
                    <td className="c-font f-13 text-center text-nowrap">
                      HSJAAAGHHAB
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      customer1@gmail.com
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      {_l("l_floor_system_generated_title")} 1
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      {_l("l_meeting_room")} 1
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      www.freeimages.org/image1.png
                    </td>
                    <td className="c-font f-13 text-center text-nowrap">
                      Mobile 2
                    </td>
                    <td className="c-font f-13 text-center text-nowrap">
                      HSJAAAGHHAB
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      customer2@gmail.com
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      {_l("l_floor_system_generated_title")} 1
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      {_l("l_meeting_room")} 1
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      www.freeimages.org/image2.png
                    </td>
                    <td className="c-font f-13 text-center text-nowrap">
                      Mobile 3
                    </td>
                    <td className="c-font f-13 text-center text-nowrap">
                      HSJAAAGHHAB
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      customer2@gmail.com
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      {_l("l_floor_system_generated_title")} 1
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      {_l("l_meeting_room")} 1
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      www.freeimages.org/image2.png
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row align-items-end pb-3">
            <Form.Group className="col-12  c-input-box  position-relative">
              <Form.Label className="input-label no-asterisk ">
                {_l("l_choose_csv_file")}
              </Form.Label>
              <div className="d-flex align-items-center">
                <div className="comment-input-box flex-grow-1 me-2">
                  <div
                    className="input_box_main with_file_upload"
                    data-tip={`${_l("l_upload_file")}`}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main"
                  >
                    <label className="upload_inputbox w-100 upload_relative form-control height-45 border-0 p-0 d-flex align-items-center ">
                      <Form.Control
                        className="  hidden-input"
                        type="file"
                        onInput={(e) =>
                          csvOnLoadHandler(e, setCsvFile, setProcessedCSV)
                        }
                        accept=".csv"
                      />
                      <div className="custom_file">
                        <Paperclip
                          size={18}
                          className="upload_icon c-icons"
                          weight="light"
                        />
                        <div className="btn btn-sm btn-white-10 border-0">
                          <span className="c-font f-10 color-white-60">
                            {" "}
                            {_l("l_upload_file")}
                          </span>
                        </div>
                        <span className="px-1 c-font f-13 color-white-60">
                          {csvFile && csvFile[0] ? csvFile[0].name : ""}
                        </span>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="text-nowrap h_45">
                  <Button
                    variant="primary"
                    className="h-100"
                    size="sm"
                    onClick={downloadSample}
                  >{`${_l("l_download")} ${_l("l_template")}`}</Button>
                </div>
              </div>
            </Form.Group>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-end">
        <Button variant="secondary" size="sm" onClick={handleClose}>
          {_l("l_close")}
        </Button>
        <Button variant="primary" size="sm" onClick={handleImport}>
          {_l("l_import")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddBulkEquipments;
