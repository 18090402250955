import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import PremiumSpaceCard from "./PremiumSpaceCard";
import {
  getCustomerSpaces,
} from "../../actions/customer";
import { _l } from "../../hooks/utilities";
import MySpcePlan from "./MySpcePlan";
import Spinner from "react-bootstrap/Spinner";
import SignatureConfirmationIdentity from "../Modals/SignatureConfirmationIdentity";
import $ from "jquery";
import CustomerServices from "../../services/customer-services";
import { useSelector, useDispatch } from "react-redux";
import { showMessage } from "../../actions/messages";
import { Plus, CaretDoubleDown, DotsThreeVertical } from "phosphor-react";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import TaskShareLinkModal from '../Modals/TaskShareLinkModal';
import { useNavigate } from "react-router-dom";
import DocumentModal from "../Modals/DocumentModal";
import { Button } from "react-bootstrap";

const PremiumMyspace = forwardRef(({
  projectId,
  PremiumEstimatDetails,
  handler,
  estimate,
  categoryListing = [],
  handlerDeleteOrCancelSpace,
  setPremiumEstimatDetails,
  setEstimate,
  contacts,
  fromSettingModal
}, ref) => {
  const [newSpaces, setNewSpaces] = useState([]);
  const [curSpaces, setCurSpaces] = useState([]);
  const [showSignaturePad, setShowSignaturePad] = useState(false);
  const [hideButtons, setHideButtons] = useState(false);
  const [copyToolTip, setCopyToolTip] = useState("Copy");
  const [curSpacePage, setCurSpacePage] = useState(1);
  const [newSpacePage, setNewSpacePage] = useState(1);
  const [acceptEstimate, setAcceptEstimate] = useState(false);
  const [spaceDetails, setSpaceDetails] = useState({});
  const [showShareModal, setShowShareModal] = useState(false);
  const [documentData, setDocumentData] = useState({});
  const [loder, setLoder] = useState({
    newLoder: false,
    currentLoder: false,
  });
  const spaceState = useSelector((state) => state.customer.spaces);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const estimateProjectId = useRef(0);

  const client_id = localStorage.getItem("client_id");
  const contact_id = localStorage.getItem("contact_id");
  const userType = localStorage.getItem("user_type");

  const estimateId =
    PremiumEstimatDetails && PremiumEstimatDetails.estimate
      ? PremiumEstimatDetails.estimate.estimate_id
      : 0;

  const getEstimate = () => {
    CustomerServices.getPremiumEstimateDetails().then((res) => {
      if (res && res.status && res.data) {
        setEstimate(res.data);
        handlerDeleteOrCancelSpace();
        getPremiumEstimate(res.data.premium_type_flag, estimateProjectId.current);
      }
    });
  };

  const getSpaces = () => {
    dispatch(getCustomerSpaces(client_id, contact_id)).then(() => {
      var spaces = [];
      if (localStorage.getItem("spaces") != "") {
        spaces = JSON.parse(localStorage.getItem("spaces"));

        if (spaces) {

          spaces = spaces.reverse();

          let newSpaces = spaces.filter((office) => office.status == 0);
          let curSpaces = spaces.filter((office) => office.status == 1 || office.status == 2);
          if (userType == "operator") {
            newSpaces = newSpaces.filter(
              (office) =>
                office.is_added_from_operator == 1 &&
                office.added_from_client_id == client_id
            );
            curSpaces = curSpaces.filter(
              (office) =>
                office.is_added_from_operator == 1 &&
                office.added_from_client_id == client_id
            );
          }
          setNewSpaces(newSpaces);
          setCurSpaces(curSpaces);
        }
      }
      handler();
    });
  };

  const copyLink = () => {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($("#copy_link").val()).select();
    document.execCommand("copy");
    $temp.remove();
  };

  const scroll = (status) => {
    let page;
    if (status == 0) {
      page = newSpacePage + 1;
      setNewSpacePage(page);
    } else if (status == 1) {
      page = curSpacePage + 1;
      setCurSpacePage(page);
    }
    if (status == 0) {
      setLoder({ ...loder, ["newLoder"]: false });
    } else if (status == 1) {
      setLoder({ ...loder, ["currentLoder"]: false });
    }
  };

  const AddSpace = () => {
    return (
      <Button variant="primary" size="sm"
        className="ms-auto"
        onClick={() => {
          navigate("/space-onboarding");
        }}
        data-tip={_l("l_new_space")}
        data-effect="solid"
        data-delay-show='1000'
        data-class="tooltip-main"
      >
        <Plus size={14} weight="light" className="c-icons me-2 black-l-white lt-black" />
        <span>{_l("l_new_space")}</span>
      </Button>
    );
  };

  const deleteSpace = (id) => {
    const tempNewSpaces = newSpaces.filter((office) => office.id != id);
    setNewSpaces(tempNewSpaces);
    handlerDeleteOrCancelSpace(id);
  };

  const handleClient = (id, projectId) => {
    setCurSpaces(
      curSpaces.map((space) => {
        if (space.project_id == projectId) {
          space["is_onboard_completed_operator"] = 1;
          return space;
        }
        return space;
      })
    );
  };

  const getPremiumEstimate = (premium_type_flag, project_id) => {
    if (premium_type_flag) {
      let selectedProject =  0;
      if(premium_type_flag == 2)
      {
        estimateProjectId.current = project_id;
        selectedProject = project_id;
      }
      CustomerServices.premiumEstimatePlan(selectedProject).then((res) => {
        if (res.status && res.data) {
          setPremiumEstimatDetails(res.data);
        }
      });
    }
  };

  const updatePremiumEstimate = () => {
    if (PremiumEstimatDetails.estimate.is_upgrade_need > 0 && PremiumEstimatDetails.estimate.status == 4 ) {
      var packageId = userType == "operator" ? 5 : 2;
      CustomerServices.upgradePremiumEstimate(estimateId, packageId).then(
        (res) => {
          if (res.status == 1) {
            localStorage.setItem(
              "membership_package_id",
              res.data && res.data.package_id && res.data.package_id != ""
                ? res.data.package_id
                : 0
            );
            getSpaces();
            getEstimate();
            setHideButtons(true);
            dispatch(showMessage("sucess", _l("l_success"), res.message));
          } else {
            dispatch(showMessage("unsucess", _l("l_error"), res.message));
          }
        }
      );
    } else if ([1, 2].includes(Number(PremiumEstimatDetails.estimate.status))) {
      if (acceptEstimate) {
        setShowSignaturePad(true);
      } else {
        dispatch(
          showMessage("unsucess", _l("l_error"), "Please accept estimate")
        );
      }
    }
  };

  useImperativeHandle(ref, () => ({
    acceptEstimate: updatePremiumEstimate,
  }));

  useEffect(() => {
    if (spaceState || projectId) {
      getSpaces();
    }
  }, [projectId]);

  useEffect(() => {
    if(estimate && spaceState.length)
    {
      let reversedSpaceList = spaceState.reverse();
      let newSpaceList = reversedSpaceList.filter((office) => office.status == 0);
      let currentSpaceList = reversedSpaceList.filter((office) => office.status == 1 || office.status == 2
      );
      let firstSpaceProjectId = newSpaceList.length ? newSpaceList[0].project_id : currentSpaceList.length ? currentSpaceList[0].project_id : 0;
      if(estimateProjectId.current == 0 && firstSpaceProjectId > 0)
      {
        getPremiumEstimate(estimate.premium_type_flag, firstSpaceProjectId);
      }
    }
    setHideButtons(false);
  }, [estimate]);

  return (
    <>
      <div className="row g-0">
        {
          fromSettingModal
          ?
          <React.Fragment></React.Fragment>
          :
          <div className="col-xl-8 overflow-hiiden-auto h-100">
            <div className={`space-wrapper pb-0 pe-3 mb-2`}>
              <div className="d-flex align-items-center custom-accordian-Header-sticky radius_3 with-bg-comman-transparent">
                <div className="color-white py-2 px-0 flex-grow-1">
                  # {_l("l_new_space")}
                </div>
                {newSpaces && newSpaces.length ? AddSpace() : <></>}
              </div>
              {newSpaces && newSpaces.length ? (
                newSpaces
                  .slice(
                    0,
                    newSpacePage > 1 ? newSpaces.length : newSpacePage * 3
                  )
                  .map((space, index) => (
                    <div
                      className={`task-list-card-parent ${
                        estimateProjectId.current == space.project_id &&
                        estimate &&
                        estimate.premium_type_flag == 2
                          ? "active"
                          : ""
                      }`}
                      key={index}
                      onClick={() => {
                        getPremiumEstimate(
                          estimate && estimate.premium_type_flag
                            ? estimate.premium_type_flag
                            : 0,
                          space.project_id
                        );
                      }}
                    >
                      <PremiumSpaceCard
                        space={space}
                        handlerDeleteOrCancelSpace={deleteSpace}
                        estimateId={estimateId}
                        setSpaceDetails={setSpaceDetails}
                      />
                    </div>
                  ))
              ) : (
                <>
                  <CommanPlaceholder
                    imgType="no-space"
                    placeholderText={AddSpace()}
                  />
                </>
              )}
              {/* (newSpaces.length >= (((newSpacePage - 1) * 3) + 3) && newSpaces.length != (((newSpacePage - 1) * 3) + 3)) */}
              {(newSpaces.length > 3 && newSpacePage == 1) || loder.newLoder ? (
                <a href="#/"
                  className="btn c-font f-12 py-2 w-100 view-more-btn btn-white-03"
                  onClick={() => {
                    setLoder({ ...loder, ["newLoder"]: true });
                    scroll(0);
                  }}
                >
                  <div className="d-flex align-item-center justify-content-center">
                    <span className="title-fonts">
                      {_l("l_view_all")} ({newSpaces.length - 3})
                    </span>
                    <CaretDoubleDown
                      size={14}
                      weight="light"
                      className="c-icons mx-1"
                    />
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      className={`ms-2 ${
                        loder.newLoder ? "opacity-1" : "opacity-0"
                      }`}
                      aria-hidden="true"
                    />
                  </div>
                </a>
              ) : (
                <></>
              )}
            </div>

            <div className={`space-wrapper pt-0 pe-3`}>
              <div className="color-white custom-accordian-Header-sticky py-2 px-0 radius_3 with-bg-comman-transparent">
                # {_l("l_current_space")}
              </div>
              {curSpaces && curSpaces.length ? (
                curSpaces
                  .slice(
                    0,
                    curSpacePage > 1 ? curSpaces.length : curSpacePage * 3
                  )
                  .map((space, index) => (
                    <div
                      className={`task-list-card-parent ${
                        estimateProjectId.current == space.project_id &&
                        estimate &&
                        estimate.premium_type_flag == 2
                          ? "active"
                          : ""
                      }`}
                      key={index}
                      onClick={() => {
                        getPremiumEstimate(
                          estimate && estimate.premium_type_flag
                            ? estimate.premium_type_flag
                            : 0,
                          space.project_id
                        );
                      }}
                    >
                      <PremiumSpaceCard
                        space={space}
                        key={index}
                        name="active"
                        categoryListing={categoryListing}
                        handlerDeleteOrCancelSpace={handlerDeleteOrCancelSpace}
                        estimateId={estimateId}
                        handleClient={handleClient}
                        setSpaceDetails={setSpaceDetails}
                      />
                    </div>
                  ))
              ) : (
                <>
                  <CommanPlaceholder
                    imgType="no-space"
                    placeholderText={_l("l_no_active_space")}
                  />
                </>
              )}
              {/* (curSpaces.length >= (((curSpacePage - 1) * 3) + 3) && curSpaces.length != (((curSpacePage - 1) * 3) + 3)) */}
              {(curSpaces.length > 3 && curSpacePage == 1) ||
              loder.currentLoder ? (
                <a href="#/"
                  className="btn c-font f-12 py-2 w-100 view-more-btn btn-white-03"
                  onClick={() => {
                    setLoder({ ...loder, ["currentLoder"]: true });
                    scroll(1);
                  }}
                >
                  <div className="d-flex align-item-center justify-content-center">
                    <span className="title-fonts">
                      {_l("l_view_all")} ({curSpaces.length - 3})
                    </span>
                    <CaretDoubleDown
                      size={14}
                      weight="light"
                      className="c-icons mx-1"
                    />
                    {loder.currentLoder ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        className={`ms-2 ${
                          loder.currentLoder ? "opacity-1" : "opacity-0"
                        }`}
                        aria-hidden="true"
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </a>
              ) : (
                <></>
              )}
            </div>
          </div>
        }
        <div className={`${fromSettingModal ? "col-xl-12" : "col-xl-4"} h-100 premium-sub-right-part pe10per`}>
          <div className=" h-100">
            {(PremiumEstimatDetails && PremiumEstimatDetails.length == 0) || (!newSpaces.length && !curSpaces.length) ? (
              <>
                <CommanPlaceholder
                  mainSpacing="m-auto h-100"
                  imgType="no-space"
                  placeholderText={_l("l_no_premium_estimat_details")}
                />
              </>
            ) : (
              <>
                <div class="d-flex align-items-center flex-column justify-content-between   position-relative h-100">
                  <div className="d-flex align-items-center justify-content-between p-15 w-100 bg-white-05 light-theme-white-bg">
                    <div className="c-font f-14 fw-semibold">{_l("l_total_amount")}</div>
                    <div className=" c-font f-12 fw-semibold  radius_3 title-fonts ">
                      <div class="fw-normal ms-1 me-2 title-fonts c-font f-12">
                        <a href="#/"
                          class="btn btn-secondary btn-sm me-2 d-none"
                          data-tip={copyToolTip}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                          onClick={() => {
                            copyLink();
                            setCopyToolTip("Copied");
                          }}
                        >
                          {_l("l_copy_link")}
                        </a>
                        <a href="#/"
                          className={`action_icon with_bg d-flex`}
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-tip={_l("l_action")}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                          onClick={() => {}}
                        >
                          <DotsThreeVertical
                            size={16}
                            weight="light"
                            className="c-icons"
                          />
                        </a>
                        <ActionDropdown
                          actions={[
                            {
                              actionName: _l("l_copy_link"),
                              actionHandler: () => {
                                navigator.clipboard.writeText(
                                  process.env.REACT_APP_MYR_WEB_URL +
                                    "/estimate/" +
                                    PremiumEstimatDetails.estimate.hash
                                );
                                dispatch(
                                  showMessage(
                                    "sucess",
                                    _l("l_success"),
                                    _l("l_link_copied")
                                  )
                                );
                              },
                              actionIcon: "CopyIcon",
                            },
                            {
                              actionName: _l("l_send_to_email"),
                              actionHandler: () => {
                                setShowShareModal(true);
                              },
                              actionIcon: "EnvelopeSimpleIcon",
                            },
                            {
                              actionName: _l("l_open_link"),
                              actionHandler: () => {
                                setDocumentData({
                                  id: PremiumEstimatDetails.estimate
                                    .subscription_id,
                                  hash: PremiumEstimatDetails.estimate
                                    .subscription_hash,
                                  type: "subscription",
                                });
                                // window.open(process.env.REACT_APP_MYR_WEB_URL+"/estimate/"+PremiumEstimatDetails.estimate.hash, "_blank");
                              },
                              actionIcon: "LinkSimpleIcon",
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  <ul class="list-group py-0 pt-0 px-0 h-100 w-100">
                    {/* {curSpaces && curSpaces.length ? (
                curSpaces
                  .slice(
                    0,
                    curSpacePage > 1 ? curSpaces.length : curSpacePage * 3
                  )
                  .map((space, index) => ( */}
                    <MySpcePlan
                      StandAlonePlatform={
                        PremiumEstimatDetails.estimate &&
                        PremiumEstimatDetails.estimate
                          ? PremiumEstimatDetails.estimate
                          : null
                      }
                      handler={handler}
                      hideButtons={hideButtons}
                      setHideButtons={setHideButtons}
                      fromSettingModal={fromSettingModal}
                    />

                    {/* )) */}
                    {/* ) : (
                <div className="comman-placeholder-wrapper h-100 overflow-hidden  w-100">
                    <div className="d-flex flex-column align-items-center m-auto">
                      <img
                        src={NoSpaceListing}
                        alt="NoSpaceListing"
                        className="big-image"
                      />
                      <span className="color-white-80 py-2 title-fonts">
                        {_l("l_no_active_space")} Listing
                      </span>
                    </div>
                  </div>
              )} */}
                    {PremiumEstimatDetails &&
                    PremiumEstimatDetails.estimate &&
                    ([1, 2].includes(
                      Number(PremiumEstimatDetails.estimate.status)) ||
                        PremiumEstimatDetails.estimate.is_upgrade_need == 1)
                    &&
                    (curSpaces.length > 0 || newSpaces.length > 0) ? (
                      <li
                        className={`list-group-item border-separator w-100 px-0 ${
                          hideButtons ? "d-none" : ""
                        }`}
                      >
                        {[1, 2].includes(
                          Number(PremiumEstimatDetails.estimate.status)
                        ) ? (
                          <div className="d-flex align-items-center">
                            <label className="form-check-label  me-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={`default`}
                                // defaultChecked={true}
                                label="I Accept this Estimate"
                                onChange={() => {
                                  setAcceptEstimate(!acceptEstimate);
                                }}
                                checked={acceptEstimate}
                              />
                            </label>
                            <a href="#/"
                              className="color-white-80"
                              onClick={() => {
                                setAcceptEstimate(!acceptEstimate);
                              }}
                            >
                              {_l("l_i_accept_this_estimate")}
                            </a>
                            <a
                              className="color-green ms-1"
                              href={"https://myr.ai/cgsc.html"}
                              target="_blank"
                            >
                              CGSC
                            </a>
                            <a
                              className="color-green ms-1"
                              href={"https://myr.ai/cgsp.html"}
                              target="_blank"
                            >
                              CGSP
                            </a>
                            <span className="m-1">&</span>
                            <a
                              className="color-green ms-1"
                              href={"https://myr.ai/privacy-policy.html"}
                              target="_blank"
                            >
                              RGPD
                            </a>
                          </div>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}

                        <a href="#/"
                          className="btn btn-primary btn-sm w-100"
                          onClick={() => {
                            updatePremiumEstimate();
                          }}
                        >
                          <span>
                            {PremiumEstimatDetails.estimate.is_upgrade_need == 1
                              ? _l("l_upgrade_plan")
                              : _l("l_accept_estimate")}
                          </span>
                        </a>

                        <a href="#/" className="btn btn-white-10 btn-sm w-100 mt-2 d-none">
                          {_l("l_cancel_my_subscription")}
                        </a>
                      </li>
                    ) : 
                      PremiumEstimatDetails &&
                        PremiumEstimatDetails.estimate &&
                        PremiumEstimatDetails.estimate.subscription_status === "not_subscribed"
                        ?
                       (
                        <li
                        className={`list-group-item border-separator w-100`}
                      >
                        <a href="#/"
                          className="btn btn-primary btn-sm w-100"
                          onClick={() => {setDocumentData({id: PremiumEstimatDetails.estimate.subscription_id, hash: PremiumEstimatDetails.estimate.subscription_hash, type: "subscription"})}}
                        >
                          {_l("l_subscription")}
                        </a>
                      </li>
                      )
                      :
                      <React.Fragment></React.Fragment>
                    }
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>

        {showSignaturePad ? (
          <SignatureConfirmationIdentity
            show={showSignaturePad}
            projectId={projectId}
            type="estimate"
            estimate={PremiumEstimatDetails && PremiumEstimatDetails.estimate}
            handleClose={() => {
              setShowSignaturePad(false);
            }}
            callBack={() => {
              getSpaces();
              setHideButtons(true);
              getEstimate();
            }}
          />
        ) : (
          <></>
        )}
      </div>
      {showShareModal && (
        <TaskShareLinkModal
          show={showShareModal}
          handleClose={() => {
            setShowShareModal(false);
          }}
          title={_l("l_subscription")}
          link={
            process.env.REACT_APP_MYR_WEB_URL +
            "/estimate/" +
            PremiumEstimatDetails.estimate.hash
          }
          action="send_subscription_link"
          data={{ id: PremiumEstimatDetails.estimate_id, contacts: contacts }}
          docType={"subscription"}
        />
      )}
      {documentData.id > 0 ? (
        <DocumentModal
          documentData={documentData}
          handleClose={() => setDocumentData({})}
          show={documentData.id > 0}
          type={documentData.type}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </>
  );
});

export default PremiumMyspace;
