import React from 'react';
// import CSS from '../css/icon_main.css';

function NoLink(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>


			<svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons h-100 w-100 ${CommanHW}`}>
				<path d="M1.33398 1C10.6599 10.3059 19.9784 19.6194 29.2893 28.9404L70.3527 70" strokeMiterlimit="10" />
				<path d="M35.6068 56.311C31.7685 59.9994 28.26 63.8077 24.2792 67.0613C20.6583 70.0038 15.3431 69.524 11.8346 66.3866C9.18281 64.0121 6.66027 61.4972 4.27792 58.8524C0.761946 54.9466 0.67577 49.0055 4.31544 45.1859C9.43571 39.8145 14.8371 34.6904 20.291 29.3228" strokeMiterlimit="10" />
				<path d="M34.7402 15.4277C38.2674 11.6793 41.4611 7.82224 45.172 4.53117C48.8041 1.30757 54.4492 1.66367 58.0813 4.90601C60.7305 7.28345 63.2494 9.80232 65.6268 12.4515C68.9216 16.1212 69.3752 21.8224 66.0841 25.3459C60.8139 30.9909 55.0414 36.1675 49.4189 41.6026" strokeMiterlimit="10" />
				<path d="M30.4785 22.8494C33.942 21.8111 37.5742 23.7565 41.8173 28.8093" strokeMiterlimit="10" />
				<path d="M39.5153 48.4505C35.5833 49.3126 31.9586 47.326 28.1016 42.4531" strokeMiterlimit="10" />
			</svg>


		</React.Fragment>
	)
}

export default NoLink