
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useRef, useState } from "react";
import { CalendarBlank } from "phosphor-react";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { _l, getDatePickerLocale } from "../../../../hooks/utilities";
import CommanPlaceholder from "../../../Placeholder/CommanPlaceholder";
import LeadCommanCard from "./LeadCommanCard";
const LeadEmailTracking = ({ projectId, clientId, location, zoomLevel, data }) => {
    const [selectedTab, setSelectedTab] = useState("documents");


    const [startDate, setStartDate] = useState(new Date());
    const [leadEmails, setLeadEmails] = useState(data);

    useEffect(() => {
        setLeadEmails(data);
    }, [JSON.stringify(data)]);
    return (
        <div className="d-flex flex-column flex-grow-1 p-10 py-0">
            <div className="d-flex flex-column flex-grow-1">
                <div className="d-flex flex-column flex-grow-1">
                    <div className="comman-content-scroll-wrapper d-flex flex-column flex-grow-1">
                        <div className="comman-content-scroll d-flex flex-column flex-grow-1">
                            <div className="comman-data-table small-data-table d-flex flex-column flex-grow-1">
                                {leadEmails && leadEmails.length > 0 ?
                                    <>
                                        {leadEmails.map((leadEmail) => {
                                            return (<>
                                            <LeadCommanCard data={leadEmail} type={'email'}/>
                                            </>)
                                        })

                                        }
                                    </> :
                                    <>
                                        <CommanPlaceholder imgType="email" mainWidth="m-auto" placeholderText={_l('l_no_tracked_emails_sent')} />
                                    </>}
                            </div>
                        </div>
                    </div >
                </div >
            </div >
            <ReactTooltip />
        </div >
    );
};
export default LeadEmailTracking;