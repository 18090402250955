import React, { useEffect, useState } from "react";
import PilotOnboardingTenant from "./PilotOnboardingTenant";
import PilotOnboardingFacility from "./PilotOnboardingFacility";
import PilotOnboardingProviders from "./PilotOnboardingProviders";
import PilotOnboardingSummary from "./PilotOnboardingSummary";
import { useDispatch, useSelector } from "react-redux";
import documentsServices from "../../../services/documents-services";
import { _l } from "../../../hooks/utilities";
import { showMessage } from "../../../actions/messages";
import { getOnboardProviderList, updatePilotOnboardingDocumentDetail, updatedOnboardDocumentList } from "../../../actions/documents-actions/documents-actions";
import { getCompanyEmployee } from "../../../actions/customer";
const PilotOnboardingMain = ({ data ,projectId}) => {
  const toolsList = [
    {
      value: "1",
      label: "WhatsApp",
    },
    {
      value: "2",
      label: "Gmail",
    },
    {
      value: "3",
      label: "Slack",
    }
  ];
  const InternalDropdawn = [
    {
      value: "1",
      label: "Internal",
      key:"internal"
    },
    {
      value: "2",
      label: "External",
      key:"external"
    }
  ];
  const RoleList = [
    {
      value: 1,
      label: _l('l_admin'),
    },
    {
      value: 2,
      label: _l('l_manager'),
    },
    {
      value: 3,
      label: _l('l_employee'),
    },
  ];
  const onBoardDocumentList = useSelector((state) => state.documentsReducer.onBoardDocumentList) || [];
  const userType = localStorage.getItem("user_type");
  const [selectedTab, setSelectedTab] = useState(userType == "staff" ? "tenant" : "summary");
  const [isExternal, setIsExternal] = useState(false);
  const [loder, setLoder] = useState(false);
  const dispatch = useDispatch();
  const handleSave = (projectId, document_id, documentType, companyName, totalArea, tenants, cost, visible, meeting, toolsItem, selectedPeople, saving, facility) => {
    setLoder(true);
      documentsServices.addPilotOnboardingDocumentDetail(projectId, document_id, documentType, companyName, totalArea, tenants, cost, visible, meeting, toolsItem, selectedPeople, saving, facility).then((res) => {
        setLoder(false);
        if (res.status) {
          let update = data ? data : {}
          update.tools = toolsItem;
          update.saving = saving;
          update.document_id =  res.data.id ? res.data.id : document_id;
          update.people = selectedPeople;
          update.visibility = visible;
          update.tenants = tenants;
          update.facility_manager= facility || {};
          update.meeting = meeting;
          update.total_area =totalArea;
          update.document_type = documentType
          update.document_providers= [];
          update.cost= cost;
          update.company_name = companyName;
          dispatch(updatePilotOnboardingDocumentDetail(update));
          let docsArr = onBoardDocumentList;
          if (onBoardDocumentList.length > 0) { 
            for (let j = 0; j < docsArr.length; j++) {
              if (docsArr[j].document_id == res.data.id) {
                docsArr[j] = update
              }
            }
          } else {
            docsArr.push(update);
          }
          dispatch(updatedOnboardDocumentList(docsArr));
          // dispatch(showMessage("sucess", _l("l_success"), res.message));
          if (documentType == "external") {
            setSelectedTab("facility");
          }else{
            setSelectedTab("providers");
          }
          if (selectedTab == "facility") {
            setSelectedTab("providers")
          }
        }
      })
  }
  useEffect(() => {
    if (data.document_id > 0) { 
      dispatch(getOnboardProviderList(projectId, data.document_id));
    }
  },[data.document_id])
  useEffect(() => {
    // dispatch(getOnboardProviderList(projectId, data.document_id));
    let is_pilot_onboarding = 1;
    dispatch(getCompanyEmployee(is_pilot_onboarding));
  },[])
  return (
    <div className="comman_vertical_btn_h vertical_scroll110">
      <div className="tab-wrapper_main pb-3 res-overflow-auto">
        <div className="d-flex align-items-center justify-content-between border-bottom for-accessible">
          <div className="comman-tab-wrapper pb-0">
            <div className="d-flex align-items-center">
              {
                userType == "staff" ?
                <> 
              <div
                className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === "tenant" ? "active" : ""
                  }`}
              >
                <a href="#/"
                  className="tab-name"
                  onClick={() => {
                    setSelectedTab("tenant");
                  }}
                >
                  Tenant
                </a>
              </div>
              <div
                className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === "facility" ? "active" : ""
                  } ${isExternal ? "" : "d-none"}`}
              >
                <a href="#/"
                  className="tab-name"
                  onClick={() => {
                    setSelectedTab("facility");
                  }}
                >
                  Facility manager
                </a>
              </div>
              <div
                className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === "providers" ? "active" : ""
                  }`}
              >
                <a href="#/"
                  className="tab-name"
                  onClick={() => {
                    setSelectedTab("providers");
                  }}
                >
                  Providers
                </a>
              </div>
            </>
                :
                <></>
              }
              <div
                className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === "summary" ? "active" : ""
                  }`}
              >
                <a href="#/"
                  className="tab-name"
                  onClick={() => {
                    setSelectedTab("summary");
                  }}
                >
                  Summary
                </a>
              </div>
            </div>
          </div>
          <div className="task-header-right comman-tab-wrapper p-0">
           
          </div>
        </div>
      </div>
      <div className="d-flex flex-column comman_vertical_btn_h">
        {selectedTab === "tenant" ? (
          <PilotOnboardingTenant data={data} projectId={projectId} setIsExternal={setIsExternal} setSelectedTab={setSelectedTab} toolsList={toolsList} InternalDropdawn={InternalDropdawn} handleSave={handleSave} loder={loder} />
        ) : 
          selectedTab === "facility" ? (
            <PilotOnboardingFacility data={data} projectId={projectId} setSelectedTab={setSelectedTab} toolsList={toolsList} RoleList={RoleList} handleSave={handleSave} loder={loder} />
          ) :
          selectedTab === "providers" ? (
            <PilotOnboardingProviders data={data} toolsList={toolsList} setSelectedTab={setSelectedTab} projectId={projectId} isExternal={isExternal} />
          ) :
          selectedTab === "summary" ? (
            <PilotOnboardingSummary data={data} projectId={projectId} setSelectedTab={setSelectedTab}/>
          ) :
           (
            <React.Fragment></React.Fragment>
          )}
      </div>
    </div>
  );
};


export default PilotOnboardingMain;

