import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { _l } from "../../hooks/utilities";
import Toasts from "../Toasts/Toasts";
import CustomDiscordIcon from "../../assets/icons/CustomDiscordIcon";
import CustomSlackIcon from "../../assets/icons/CustomSlackIcon";

const SlackDiscordModal = ({ chatShow, chatHandleClose, slackRevokeBtn }) => {
  const [toastClass, setToastClass] = useState("unsucess");
  const [header, setHeader] = useState("");
  const [message, setMessage] = useState("");
  const [validationFalse, setValidationFalse] = useState(false);
  const [showModal, setShowModal] = useState();
  const [radioBtnType, setRadioBtnType] = useState(0);

  const closeModal = () => {
    setShowModal(chatHandleClose);
  };

  function openPopUpslack(w, h) {
    let url =
      "https://slack.com/oauth/v2/authorize?user_scope=channels:read,groups:read,mpim:read,im:read,identify&response_type=code&state=slack&client_id=4504174479890.4533152097168";
    var left = (window.screen.width - w) / 2;
    var top = (window.screen.height - h) / 4;
    window.open(
      url,
      "center window",
      "resizable = yes, width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        top +
        ", left=" +
        left
    );
  }

  function openPopUpdiscord(w, h) {
    let url = "https://discord.com/api/oauth2/authorize?client_id=1065554211674280027&permissions=8&redirect_uri=" + encodeURIComponent(window.origin) + "&response_type=code&scope=identify%20guilds%20email%20connections%20messages.read%20bot%20applications.commands";
    var left = (window.screen.width - w) / 2;
    var top = (window.screen.height - h) / 4;
    window.open(
      url,
      "center window",
      "resizable = yes, width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        top +
        ", left=" +
        left
    );
  }

  return (
    <>
      <div>
        <Modal
          show={chatShow}
          onHide={chatHandleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{_l("l_chat_modal")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-3">
            {/* <div className="form-wrapper-main">
              <div className="row">
                <div className="col-xl-4">
                  <Form.Label className="input-label no-asterisk title-fonts color-white-60">
                    {_l("l_select_your_chat")}
                  </Form.Label>
                </div>

                <Form.Group className="col-4 c-input-box pb-3 position-relative">
                  <Form.Label className="d-flex c-font f-13 color-white-60 ">
                    <div className="form-check form-check-inline ">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="type"
                        value={radioBtnType}
                        onChange={() => {
                          setRadioBtnType(1);
                        }}
                        checked={radioBtnType == 1}
                      />
                    </div>
                    <div className="text-wrap">{_l("l_slack")} </div>
                  </Form.Label>
                </Form.Group>
                <Form.Group className="col-4 c-input-box pb-3 position-relative">
                  <Form.Label className="d-flex c-font f-13 color-white-60 ">
                    <div className="form-check form-check-inline ">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="type"
                        value={radioBtnType}
                        onChange={() => {
                          setRadioBtnType(2);
                        }}
                        checked={radioBtnType == 2}
                      />
                    </div>
                    <div className="text-wrap">{_l("l_discord")}</div>
                  </Form.Label>
                </Form.Group>
              </div>
            </div> */}
            <div className={`platform-card task-list-card list-view p-4 no-hover `}>
                <div className="d-flex justify-content-between">
                  <div className="h30w30">
                    <CustomSlackIcon />
                  </div>
                  <div className="w100minus30 ps-4">
                    <div className="title-fonts fw-semibold">{_l("l_slack")}</div>
                    <div className="c-font f-12 color-white-60">{_l("l_gmail_subtext")}</div>
                    <div className="card-btn-wrapper mt-4">
                      <div className="d-flex align-items-center">
                      <Button size="sm" variant="primary" className="position-relative">
                      <input
                        className="absolute-input"
                        type="radio"
                        name="type"
                        value={radioBtnType}
                        onChange={() => {
                          setRadioBtnType(1);
                        }}
                        checked={radioBtnType == 1}
                      />
                      {_l("l_slack")}
                      </Button>
                      </div>
                    </div>  
                  </div>
                </div>
            </div>
            <div className={`platform-card task-list-card list-view p-4 no-hover `}>
                <div className="d-flex justify-content-between">
                  <div className="h30w30">
                  <CustomDiscordIcon />
                  </div>
                  <div className="w100minus30 ps-4">
                    <div className="title-fonts fw-semibold">{_l("l_discord")}</div>
                    <div className="c-font f-12 color-white-60">{_l("l_gmail_subtext")}</div>
                    <div className="card-btn-wrapper mt-4">
                      <div className="d-flex align-items-center">
                      <Button size="sm" variant="primary" className="position-relative">
                      <input
                        className="absolute-input"
                        type="radio"
                        name="type"
                        value={radioBtnType}
                        onChange={() => {
                          setRadioBtnType(2);
                        }}
                        checked={radioBtnType == 2}
                      />
                      {_l("l_discord")}
                      </Button>
                      </div>
                    </div>  
                  </div>
                </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end pt-0">
            <Button variant="secondary" onClick={chatHandleClose}>
              {_l("l_close")}
            </Button>
            {radioBtnType == 1 ? (
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  openPopUpslack(600, 600);
                  closeModal();
                }}
              >
                {_l("l_connect_to_slack")}
              </Button>
            ) : radioBtnType == 2 ? (
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  openPopUpdiscord(600, 600);
                  closeModal();
                }}
              >
                {_l("l_connect_to_discord")}
              </Button>
            ) : (
              <></>
            )}

            {slackRevokeBtn == true ? (
              <Button
                variant="danger"
                size="sm"
                onClick={() => {
                  "";
                }}
              >
                {_l("l_revoke_slack")}
              </Button>
            ) : (
              ""
            )}
          </Modal.Footer>
        </Modal>
      </div>
      {validationFalse ? (
        <Toasts
          message={message}
          header={header}
          toastClass={toastClass}
          delay={10000}
          handler={() => {
            setValidationFalse(false);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default SlackDiscordModal;
