import React, { useEffect, useRef, useState } from "react";
import { X } from "phosphor-react";
import { Button } from "react-bootstrap";
import { _l } from "../../hooks/utilities";

const OfficeDirectionDropdown = ({showDirectionPoint,POIstepUpdate }) => {


  return (
    <>
    <ul className="dropdown-menu w-350 show">
      <div className="d-flex justify-content-between pb-3  p-15">
        <div className="c-font f-16 fw-semibold title-fonts">{
          showDirectionPoint ? _l("l_set_direction") : _l("l_set_POI")
        }</div>
        <a href="#/" className="h25w25 d-flex">
          <X size={16} weight="light" className="c-icons" />
        </a>
      </div>
      <div className="c-font color-white-80 f-14 p-15 pt-0">
        {
          showDirectionPoint ? _l("l_please_set_direction_first") : POIstepUpdate ? (_l("l_set_POI_set")) : _l("l_set_POI_description")
        }
        
      </div>
      <div className="comman_action_icon justify-content-between d-flex p-15 border-top d-none">
        <div className="color-white-60 c-font f-15 d-flex align-items-center">1 of 3</div>
        <Button variant="primary">Next</Button>
      </div>

    </ul>
    <div className="">
        Animation
     </div>
     </>
  );
};

export default React.memo(OfficeDirectionDropdown);