import React, {  } from "react";
import { X } from "phosphor-react";
import { _l } from "../../hooks/utilities";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";

const FollowersDropdown = ({
  openFollowersDropdown,
  setOpenFollowersDropdown,
  task_id,
  checklistId,
  mainClassName="",
  handler = () => { }, checklistAssigneesList, is_task_checklist = 0, selectedId = 0 ,type ="",handleSubscriptionBody ,setSelectedContact
}) => {

  return (
    <ul className={`dropdown-menu w-200 ${mainClassName}`} aria-labelledby="FolloWersDropdown" id="FolloWersDropdown">
      {/* {openFollowersDropdown == true ?  */}
      <li>
        <div className="dropdown-header">
          <div className="d-flex align-items-center justify-content-between">
            <div className="c-list-detail title-fonts text-truncate color-white c-font f-13">
              {type === "subscription" ? <> {_l("l_contacts")}</>:<> {_l("l_assignees")}</>}
            </div>
            <a href="#/" className="right-image d-none">
              <X size={18} className="c-icons" weight="light" />
            </a>
          </div>
        </div>
      </li> 
      {/* : <></>} */}
      <li>
        <hr className="dropdown-divider m-0" />
      </li>
      {/* {openFollowersDropdown == true ?  */}
      <div className="overflowscroll">
        {checklistAssigneesList.sort((a,b)=>a.full_name ? a.full_name.localeCompare(b.full_name) : a.name.localeCompare(b.name)).map((user, index) => {
          return (
            <>
              <li>
                <a href="#/"
                  key={index}
                  className={`dropdown-item  text_wrap ${selectedId != 0 && user.id == selectedId ? 'active with-tick' : ''}`}
                  onClick={() => {
                    // type === "subscription" ? handleSubscriptionBody("ContactEmail" ,user)  setSelectedContact (user):  handler(user, checklistId)
                    if (type === "subscription") {
                      handleSubscriptionBody("ContactEmail" ,user);
                      setSelectedContact (user)
                    } else {
                      handler(user, checklistId)
                    }
                    }}
                >
                  <div className="d-flex align-items-center">
                    <div className="c-list-icon">
                      <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
                        <div
                          className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                          style={{
                            backgroundImage: `url('${user.user_image || user.profile_image || user.profile_image_url}')`,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div
                      className="ps-2 w100minus20 text_wrap"

                    >
                      <div onClick={() => setOpenFollowersDropdown(false)} className="c-list-detail text-truncate color-white">
                        {user.full_name || user.name}
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li><hr className="dropdown-divider my-0" /></li>
            </>
          );
        })}
      </div> 
      {/* : <></>} */}
      {checklistAssigneesList && checklistAssigneesList.length == 0 ? 
      <CommanPlaceholder imgWidth="h60w60" mainSpacing="py-3" placeholderText = {_l("l_no_assignee")} />
      :
      <></>}
    </ul>
  );
};

export default FollowersDropdown;
