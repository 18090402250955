import React, { useEffect, useState } from "react";
import { X, NotePencil } from "phosphor-react";
import { _l } from "../../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";

const OnboardingAssigned = () => {
  const { accessOfficeData } = useSelector((state) => state.customer);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedTab, setSelectedTab] = useState("documents");
  return (
    <>
      <div
        className="dropdown-menu w-240px radius_5 "
        aria-labelledby="OnboardingAssigned"
      >
        <div className="comman-contnet-wrapper h-100">
          <div className="d-flex align-items-center justify-content-between border-bottom">
            <div className="comman-tab-wrapper w-100 pb-0">
              <div className="d-flex align-items-center">
                <div
                  className={`tab-wrapper d-flex align-items-center flex-grow-1 ${selectedTab === "documents" ? "active" : ""
                    }`}
                  onClick={() => {
                    setSelectedTab("documents");
                  }}
                >
                  <a href="#/" className="tab-name c-font f-12">Un-assigned</a>

                </div>
                <div
                  className={`tab-wrapper d-flex align-items-center flex-grow-1 ${selectedTab === "estimates" ? "active" : ""
                    }`}
                  id="documents_tab_head_2"
                >
                  <a href="#/"
                    className="tab-name c-font f-12"
                    onClick={() => {
                      setSelectedTab("estimates");
                    }}
                  >
                    Assigned
                  </a>

                </div>
              </div>
            </div>
            <div className="right-tabing-part">

            </div>
          </div>
          <div className="document-content-wrapper p-1 overflowscroll">
            <div className="comman-data-table">
              {selectedTab === "documents" ? (
                <>
                <div className="position-relative task-sub-list w-100 ">Reception Area</div>
                <div className="position-relative task-sub-list w-100 ">Lobby</div>
                <div className="position-relative task-sub-list w-100 ">Break Room</div>
                <div className="position-relative task-sub-list w-100 ">Meeting Room</div>
                <div className="position-relative task-sub-list w-100 ">Reception Area</div>
                <div className="position-relative task-sub-list w-100 ">Reception Area</div>
                <div className="position-relative task-sub-list w-100 ">Reception Area</div>
                <div className="position-relative task-sub-list w-100 ">Reception Area</div>
                </>
              ) : selectedTab === "estimates" ? (
                <>
                <div className="position-relative task-sub-list w-100 ">Reception Area</div>
                <div className="position-relative task-sub-list w-100 ">Reception Area</div>
                <div className="position-relative task-sub-list w-100 ">Reception Area</div>
                <div className="position-relative task-sub-list w-100 ">Reception Area</div>
                <div className="position-relative task-sub-list w-100 ">Lobby</div>
                <div className="position-relative task-sub-list w-100 ">Break Room</div>
                <div className="position-relative task-sub-list w-100 ">Meeting Room</div>
                <div className="position-relative task-sub-list w-100 ">Reception Area</div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

      </div>

    </>
  );
};

export default OnboardingAssigned;
