import {  useSelector } from "react-redux";
import React from "react";
import $ from "jquery";

import {
  ATTACH_BASE_URL,
  USR_IMG_PLACEHOLDER,
} from "../../actions/chat-action-type";

const ViewChannelCard = ({ group, openViewGroup }) => {
  const { activeChannelId } = useSelector((state) => state.chat);
  var chatUserId = localStorage.getItem("chatUserId");
  const capitalize = (str) => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  };

  const getInitials = (str) => {
    var parts = str.split(" ");
    var initials = "";
    parts.forEach(function (part) {
      if (part.length > 0 && part !== "") {
        initials += capitalize(part[0]);
      }
    });
    return initials;
  };

  var lastMsg = '';
  if(!$.isEmptyObject(group.lastMsgData)){
      // Check last message user
      lastMsg = group.lastMsgData.message;
      var lastMsgSender = group.lastMsgData.user;
      if(group.members[0]._id == lastMsgSender){
          lastMsg = group.members[0].fname+': '+lastMsg;
      }else if(group.members[1]._id == lastMsgSender){
          lastMsg = group.members[1].fname+': '+lastMsg;
      }

  }

  return (
    <li 
      className="comman-list p-0" 
      id={`chat_group_div_${group._id}`}
      onClick={() => {
        openViewGroup(group._id, group.members[0]._id, group.members[1]._id);
      }}
    >
      <a href="#/" className="w-100 position-relative tab_link left-chat" id={`group_card_unread_count_display_${group._id}`}>
        <div className="d-flex align-items-center">
          <div className="c-list-icon">
            <div className="multi_inner_wrapper d-flex">
              <div className="h35w35 comman-image-box comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-5px">
                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                  style={{
                    backgroundImage: `url('${group.members[0].displayPicture != "" ? ATTACH_BASE_URL+group.members[0].displayPicture : USR_IMG_PLACEHOLDER }')`,
                  }}
                ></div>
              </div>
              <div className="h35w35 comman-image-box comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-5px">
                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                  style={{
                    backgroundImage: `url('${group.members[1].displayPicture != "" ? ATTACH_BASE_URL+group.members[1].displayPicture : USR_IMG_PLACEHOLDER }')`,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="c-list-detail ps-2 w100minus70">
            <div className="top-part pb-1">
              <div className="d-flex align-items-center justify-content-between">
                <div className="chat-u-name text-truncate" id={`group_member_name_1_${group._id}`} title={capitalize(group.members[0].fname+' '+group.members[0].lname)}>
                  {capitalize(group.members[0].fname+' '+group.members[0].lname)}
                </div>
                <div className="right-toggling">
                  <div className="user-type">
                    <div className="badge badge-white rounded-pill badge  badge-white rounded-pill fw-light me-1">
                      <span className="color-white">{getInitials(group.members[0].myrUserType2)}</span>
                    </div>
                  </div>
                </div>
                <div className="chat-u-name text-truncate" id={`group_member_name_2_${group._id}`} title={capitalize(group.members[1].fname+' '+group.members[1].lname)}>
                  {capitalize(group.members[1].fname+' '+group.members[1].lname)}
                </div>
                <div className="right-toggling">
                  <div className="user-type">
                    <div className="badge badge-white rounded-pill badge  badge-white rounded-pill fw-light me-1">
                      <span className="color-white">{getInitials(group.members[1].myrUserType2)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-part color-white-60">
              <div className="d-flex align-items-center justify-content-between">
                <div className="lst-msg text-truncate w-100 ">{lastMsg}</div>
                </div>
              </div>
          </div>
        </div>
      </a>
    </li>
  );
};

export default ViewChannelCard;
