import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import User_2 from "../../assets/images/users/user_2.jpg";
import Form from 'react-bootstrap/Form';
import { TextBolder, TextItalic, TextUnderline, ListDashes, TextAlignRight, TextAlignJustify, TextAlignLeft, Paperclip, LinkSimple, ListNumbers } from "phosphor-react";

const CustomTextEditor = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [DivLoop, setDivLoop] = useState(['1', '2', '3']);

    const [startDate, setStartDate] = useState(new Date());
    return (
        <div className="h-100 res-width-100 d-flex flex-column bg-white-03 p-2 my-2">
            <div className=" h_50 overflow-auto">
                <div className="custom-modal-section-sticky">
                    <div className="font-effect d-flex bg-white-03 pb-2 comman_action_icon">
                        <div className="d-flex align-items-center with_separator_10 ps-0">
                            <div className="comman_action_icon me-2">
                                <a href="#/" class="action_icon with_bg">
                                    <TextBolder size={20} weight="light" className="c-icons " />
                                </a>
                            </div>
                            <a href="#/" class="action_icon me-2">
                                <TextItalic size={20} weight="light" className="c-icons " />
                            </a>
                            <a href="#/" class="action_icon me-2">
                                <TextUnderline size={20} weight="light" className="c-icons " />
                            </a>
                        </div>
                        <div className="d-flex align-items-center with_separator_10">
                            <a href="#/" class="action_icon me-2">
                                <ListDashes size={20} weight="light" className="c-icons " />
                            </a>
                            <a href="#/" class="action_icon me-2">
                                <ListNumbers size={20} weight="light" className="c-icons " />
                            </a>
                        </div>
                        <div className="d-flex align-items-center with_separator_10">
                            <a href="#/" class="action_icon me-2">
                                <TextAlignRight size={20} weight="light" className="c-icons " />
                            </a>
                            <a href="#/" class="action_icon me-2">
                                <TextAlignJustify size={20} weight="light" className="c-icons " />
                            </a>
                            <a href="#/" class="action_icon me-2">
                                <TextAlignLeft size={20} weight="light" className="c-icons " />
                            </a>
                        </div>
                        <div className="d-flex align-items-center with_separator_10">
                            <a href="#/" class="action_icon me-2">
                                <Paperclip size={20} weight="light" className="c-icons " />
                            </a>
                            <a href="#/" class="action_icon me-2">
                                <LinkSimple size={20} weight="light" className="c-icons " />
                            </a>
                        </div>
                    </div>
                </div>
                <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                    <Form.Control as="textarea" rows={3} className="bg-transparent border-0 form-control px-0" placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." />
                </Form.Group>
                <div className="d-flex flex-wrap py-2">
                    {DivLoop.length > 0 && DivLoop.map((item) => {
                        return (
                            <div className="file-image-list pt-2 mt-1 pe-2 me-1">
                                <div className="c-list-icon w-100 position-relative with-overlay">
                                    <div className="h50w50 comman-round-box with-bg radius_3 with-react-fancybox">
                                        <div className="comman-bg-img h-100 w-100 bg-style-cover radius_3" style={{
                                            backgroundImage: `url('${User_2}')`,
                                        }}>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                    <a href="#/" className="color-green mt-3 text-decoration-underline">
                        https://dribble.com/shots/5505852-Content-editor-modal
                    </a>
                    <Form.Control as="textarea" rows={3} className="bg-transparent border-0 form-control px-0" placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." />
                </Form.Group>
            </div>
            <div className="p-15 d-flex justify-content-end mt-auto pb-0 px-0">
                <Button variant="primary " size="sm">Submit </Button>
            </div>
        </div>
    );
};
export default CustomTextEditor;