import React from 'react'
const ClientDataSkeleton = () => {
  return (
    <ul className='office-list-main mt-0 pb-0 pt-0'>
      {
          [0, 1, 2,3,4,5,6,7,8,9,10,11,12].map((s, index) => {
            return (<>
      <li className='p-10 SkeletonMain d-flex align-items-center justify-content-between '>
        <div className='d-flex align-items-center flex-grow-1'>
          <div className='h45w45 rounded-5 skeleton-animation'></div>
          <div className='ms-10px flex-grow-1'>
            <div className='mb-1 skeleton-animation radius_3' style={{ width: '95%', height: '15px' }}></div>
            <div className='skeleton-animation radius_3' style={{ width: '65%', height: '10px' }}></div>
          </div>
        </div>
      </li>
      </>)
          })
        }
    </ul>
  )
}
export default ClientDataSkeleton