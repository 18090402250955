import React from 'react';
// import CSS from '../css/icon_main.css';

function CallsplaceholderIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

			<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<path d="M55.6445 32.8135C58.4151 33.557 60.9413 35.0162 62.9698 37.0447C64.9982 39.0731 66.4574 41.5993 67.2009 44.3699" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M53.9219 39.1172C55.5882 39.5597 57.1079 40.4349 58.327 41.654C59.5462 42.8731 60.4213 44.3929 60.8639 46.0592" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M41.9693 50.1291C43.6501 53.6001 46.4568 56.3996 49.9322 58.0716C50.1885 58.1929 50.4721 58.2455 50.7549 58.224C51.0377 58.2025 51.3101 58.1078 51.5452 57.9491L56.6496 54.5393C56.8751 54.3863 57.1357 54.293 57.4071 54.268C57.6784 54.243 57.9517 54.2872 58.2013 54.3964L67.7568 58.5003C68.0834 58.6363 68.3562 58.8758 68.5333 59.182C68.7104 59.4882 68.7819 59.8441 68.7369 60.195C68.434 62.5588 67.2802 64.7313 65.4914 66.306C63.7025 67.8806 61.4012 68.7495 59.018 68.75C51.6535 68.75 44.5906 65.8244 39.3831 60.6169C34.1756 55.4094 31.25 48.3465 31.25 40.982C31.2505 38.5988 32.1194 36.2975 33.694 34.5087C35.2687 32.7198 37.4412 31.566 39.805 31.2631C40.1559 31.2181 40.5118 31.2896 40.818 31.4667C41.1242 31.6438 41.3637 31.9166 41.4997 32.2432L45.6036 41.8191C45.7103 42.0648 45.755 42.333 45.7336 42.6001C45.7123 42.8671 45.6256 43.1248 45.4811 43.3504L42.0714 48.5365C41.9196 48.7711 41.8305 49.0406 41.8126 49.3195C41.7947 49.5983 41.8487 49.8771 41.9693 50.1291V50.1291Z" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M50 87.5C70.7107 87.5 87.5 70.7107 87.5 50C87.5 29.2893 70.7107 12.5 50 12.5C29.2893 12.5 12.5 29.2893 12.5 50C12.5 70.7107 29.2893 87.5 50 87.5Z" strokeLinecap="round" strokeLinejoin="round" />
			</svg>


		</React.Fragment>
	)
}

export default CallsplaceholderIcon