import React, { useEffect, useState } from "react";
import ExternalHeader from "../components/ExternalHeader";
import { DEFAULT_PLACEHOLDER } from "../actions/action-type";
import { useDispatch } from "react-redux";
import Leads from "../components/Leads/Leads";
import { _l } from "../hooks/utilities";
import { setSelectedLanguage } from "../actions/customer";
import RadioButtonWithIcon from "../components/Leads/RadioButton";

const RadioButtonArray = ({ data, name, onChange, onClick, value }) => {
    return (
        <div className="d-flex justify-content-center flex-wrap">
        {data.map((values, key) => {
            return (
            <RadioButtonWithIcon
                key={key}
                {...values}
                onChange={onChange}
                onClick={onClick}
                name={name}
                checked={value === values.value}
            />
            );
        })}
        </div>
    );
};

const LeadsIntegration = () => {
  const dispatch = useDispatch();
  const languageSelect = [
    { value: "english", label: _l("l_english") },
    { value: "french", label: _l("l_french") },
  ];
  const [language, setlanguage] = useState(localStorage.getItem("language"));

  const setLanguage = (language) => {
    dispatch(setSelectedLanguage(language));
    localStorage.setItem("language", language);
  };

  useEffect(() => {
    document.body.classList.remove('with-left-penel');
    document.body.classList.add('ps-0');
    return () => {
        document.body.classList.add('with-left-penel');
        // document.body.classList.remove('');
    }
}, []);
  

  return (
    <React.Fragment>
      <ExternalHeader
        profileImageURL={DEFAULT_PLACEHOLDER}
        hideLeftPanel={true}
        setlanguage={setlanguage}
        language={language}
        setLanguage={setLanguage}
        languageSelect={languageSelect}
      />
      <div className="d-flex flex-column h-100">
        <Leads language={language} />
      </div>
    </React.Fragment>
  );
};

export default LeadsIntegration;
