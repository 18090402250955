import React from 'react';
// import CSS from '../css/icon_main.css';

function ThumbsUpIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
			
<svg width="17" height="16" viewBox="0 0 17 16" fill="none"  xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M5.39062 6.5H2.39062C2.25802 6.5 2.13084 6.55268 2.03707 6.64645C1.9433 6.74021 1.89062 6.86739 1.89062 7V12.5C1.89063 12.6326 1.9433 12.7598 2.03707 12.8536C2.13084 12.9473 2.25802 13 2.39062 13H5.39062M5.39062 6.5V13M5.39062 6.5L7.89062 1.5C8.42106 1.5 8.92977 1.71071 9.30484 2.08579C9.67991 2.46086 9.89062 2.96957 9.89062 3.5V5H13.7594C13.9012 4.99965 14.0414 5.02965 14.1706 5.08798C14.2999 5.14631 14.4151 5.23162 14.5087 5.33818C14.6022 5.44475 14.6719 5.57009 14.713 5.70581C14.754 5.84152 14.7656 5.98445 14.7469 6.125L13.9969 12.125C13.9665 12.3659 13.8496 12.5875 13.6679 12.7485C13.4862 12.9095 13.2521 12.9989 13.0094 13H5.39062" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
		</React.Fragment>
	)
}

export default ThumbsUpIcon