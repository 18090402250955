import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { _l, isSolarHubPlatform, showError, showSuccessMessage } from "../hooks/utilities";
import AuthServices from "../services/auth-services";
import LogoGreenDark from "../assets/icons/LogoGreenDark";
import LogoGreenLight from "../assets/icons/LogoGreenLight";
import { useDispatch } from "react-redux";
import { setSelectedGloablView } from "../actions/customer";
import LogoSolarHub from "../assets/images/blazlogoblack.svg";


const ForgotPassword = () => {
  const [emailAddress, setEmailAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [LightTheme] = useState(localStorage.getItem('selectedTheme') == "light" ? true : false);

  const navigate = useNavigate();

  const handlePasswordReset = async() => {
    if(emailAddress.length)
    {
      try {
        setIsLoading(true);
        const response = await AuthServices.requestPasswordResetLink(emailAddress.trim());
        if(response.status)
        {
          showSuccessMessage("l_link_sent");
          setEmailAddress("");
        }
        else {
          showError(response.message);
        }
      }
      catch(e)
      {
        showError("l_something_went_wrong");
      }
      finally {
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      navigate("/");
      dispatch(setSelectedGloablView("AichatView"));;
    }
  }, [])  

  return (
    <div>
      <div className="login_page">
        <div className="login_box">
        <div className="login-logo">
            {isSolarHubPlatform() ? (
              <>
                <img className="h60px" src={LogoSolarHub} alt="" />
              </>
            ) : (
              <>
                {LightTheme ? (
                  <LogoGreenLight className="header-logo-img " />
                ) : (
                  <LogoGreenDark className="header-logo-img" />
                )}
              </>
            )}            
          </div>
          <div className="comman_title_white">{_l("l_forgot_your_password")}</div>
          <div className="custom_input_box_main">
            <div className="custom_input_box  with_before">
              <label className="input_box_label">
                <div className="title_text color-white-60">
                {_l("l_email_address_label")}
                  <span className="star_img">*</span>
                </div>
              </label>
              <input
                type="text"
                name="email"
                id="email"
                className="input_box  autofill_off"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                placeholder={`${_l("l_email_placeholder")}`}
                autoComplete="off"
              />
            </div>
            <div className="mtop20"></div>
            <div className="login_btn d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-primary me-4"
                  onClick={handlePasswordReset}
                  disabled={isLoading}
                >
                   {isLoading ? _l("l_please_wait") : _l("l_reset")}
                </button>
                <a href="#/" className="color-green title-fonts" onClick={() => {navigate("/login")}}>{_l("l_back_to_login")}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
