import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TopicSettingModal from "../components/Modals/TopicSettingModal";
import { _l } from "../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import { setShowAccordionVisibility, setactiveChatScreenId } from "../actions/chat";

const EditProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { chatGroups} = useSelector((state) => state.chat);

  const [settingModalData, setSettingModalData] = useState({});

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/login");
    } else {
      setSettingModalData({
        main_key: "edit_profile",
        id: -1,
        main_key_title: _l("l_edit_profile"),
        title: "l_all",
      });
    }
    dispatch(setShowAccordionVisibility(false));
    dispatch(setactiveChatScreenId(0));

  }, []);

  useEffect(() => {
    if(!settingModalData)
    {
      navigate(-1);
    }
  }, [settingModalData])
  

  return (
    <div>
      {settingModalData && Math.abs(settingModalData.id) > 0 ? (
        <TopicSettingModal
          data={{
            main_key: settingModalData.main_key,
            id: settingModalData.id,
            title: settingModalData.title,
            main_key_title: settingModalData.main_key_title,
          }}
          handleClose={() => {
            dispatch(setShowAccordionVisibility(true));
            if(window.opener)
            {
              window.close();
            }
            else {
              navigate(-2);
            }
          }}
          hideSearchArea={true}
          hideFilterArea={true}
          show={Math.abs(settingModalData.id) > 0}
          title={settingModalData.main_key_title}
          titlePrefix={""}
          project_id={0}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </div>
  );
};

export default EditProfile;
