import {
    CaretDown,
    CaretUp,
    Headset,
    X,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import {  _l, isSolarHubPlatform } from "../../../hooks/utilities";
import Accordion from "react-bootstrap/Accordion";
import { USR_IMG_PLACEHOLDER } from "../../../actions/chat-action-type";
import { useDispatch, useSelector } from "react-redux";
import { UpdateChatGroupsListDetails, fetchMessages, groupIn, groupOut, setClearmessage, setLastmessageGroupDetails, setUpdatedChatGroupsList, setactiveChatScreenId, updateExternalGroupListMessages, } from "../../../actions/chat";
import SolarMessageScreen from "../../Chat/SolarMessageScreen";


const SolarChatScreen = ({ group = {}, activeKey, setActiveKey, index,conactID, comapnyName }) => {

    const dispatch = useDispatch();
    let pageName = "proposalList"
    let projectId = 0; 

    var chatUserId = localStorage.getItem("chatUserId");
    var fileTypes = {
        image: ["jpg", "jpeg", "png", "gif", "svg", "ico"],
        video: ["mp4", "3gp", "ts", "mov", "avi", "wmv", "mkv", "webm", "flv", "avchd"],
        audio: ["mp3", "aac", "wav", "aiff", "ogg", "wma", "flac", "alac", "m4a"]
    }

    const {
        messageList,
        activeChatScreenID,
        chatGroupsDetails,
        groupDetails,
        groupListExternal,
        chatGroups,
        otherUserStatus
    } = useSelector((state) => state.chat);

    let userType = localStorage.getItem("user_type");

    const [messageloadLoader, setmessageloadLoader] = useState(false);
    const [isVirtualSpace, setIsVirtualSpace] = useState(localStorage.getItem("selectedVirtualSpace") ? true : false);
    const [show, setShow] = useState(false);
    const [isGroupAdmin, setIsGroupAdmin] = useState(false);
    const [searchStringRightBoard, setSearchStringRightBoard] = useState("");
    const clearLeftchannelsearch =()=>{
    }
    const [msgloader, setmsgloader] = useState(false)
    const [mentionList, setMentionList] = useState([]);
    const [shareContacts, setShareContacts] = useState([]);
    const [id, setId] = useState('');
    const [loder, setLoder] = useState(isSolarHubPlatform() ? false : true);


    const scrollToBottom = () => {
        if (messageList.length) {
          var cmntListDiv = document.getElementById(
            "scrollableDiv-solar-" + activeChatScreenID
          );
          try {
            if (cmntListDiv.scrollHeight && cmntListDiv.scrollHeight != null)
              cmntListDiv.scrollTop = cmntListDiv.scrollHeight
                ? cmntListDiv.scrollHeight
                : 0;
          } catch (err) {
            // console.log(err);
          }
        }
      };

    useEffect(() => {
        if (group.groupId == activeChatScreenID) {
            setmessageloadLoader(true)

            localStorage.setItem("activeGroupId",activeChatScreenID)
            var socketDataIn = {
                groupId: activeChatScreenID,
                userId: chatUserId,
              };
            dispatch(groupIn(socketDataIn));
            let unreadindex = groupListExternal.findIndex((gd)=> gd.groupId == activeChatScreenID)  
        if (unreadindex > -1) {
          let tempData = groupListExternal[unreadindex]
          tempData.unreadCount = 0        
          let finalData = Object.values({ ...groupListExternal, [unreadindex]: tempData })
          dispatch(updateExternalGroupListMessages(finalData))
          
        } 

            dispatch(fetchMessages(
                localStorage.getItem("chatUserId"),
                activeChatScreenID,
                0,
                setmessageloadLoader,
                1,
            ));
        }
    }, [activeChatScreenID])

    useEffect(() => {
        scrollToBottom();
    }, [JSON.stringify(messageList),activeChatScreenID])

    useEffect(() => {
        if (!messageloadLoader) {
            scrollToBottom();
        }
      
    }, [messageloadLoader])


    return (
        // <div className='custom-accordian-main with-left-header-arrow with-right-arrow d-flex flex-grow-1 flex-column justify-content-end'>
        //     <Accordion alwaysOpen defaultActiveKey={activeChatScreenID} className="mt-0 d-flex gap10px align-items-end"  >
        //     {/* {
        //         chatGroupsDetails && chatGroupsDetails.length > 0 && chatGroupsDetails.map((group) =>{
        //             return(<> */}

        //             {/* </>)
        //         })
        //     } */}

        //         </Accordion>

        //         </div>
        <>
            <Accordion.Item
                eventKey={`${index}`}
                className="d-flex flex-column mb-0 w_350 messaging-dropdown-main border radius_3 h-100"
                id={`${index}`}
            >
                <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border with-accordian-bg">
                    <div className="messaging-dropdown-header w-100">
                        <div className="d-flex align-items-center justify-content-between">
                            <a className="d-flex align-items-center w100minus100per" onClick={()=>{
                               if (activeChatScreenID == group.groupId) {
                                dispatch(setactiveChatScreenId(0));
                                var socketDataOut = {
                                    groupId: localStorage.getItem("activeGroupId"),
                                    userId: chatUserId,
                                  };
                                dispatch(groupOut(socketDataOut));

                            } else {
                                dispatch(setClearmessage());
                                var socketDataIn = {
                                    groupId: activeChatScreenID,
                                    userId: chatUserId,
                                  };
                                dispatch(groupIn(socketDataIn));
                                dispatch(setactiveChatScreenId(group.groupId));
                            }
                            }}>
                                <div className="c-list-icon ">
                                    <div className="h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 comman-image-box position-relative">
                                        { userType == "contact" && group.extraData && group.extraData.mainKey && group.extraData.mainKey == "myr_support" ?
                                            <Headset
                                                size={18}
                                                weight="light"
                                                className="c-icons"
                                            /> :
                                        <div
                                            className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                            style={{
                                                backgroundImage: `url(${group.displayPicture && group.displayPicture != ""
                                                        ? ""
                                                        : USR_IMG_PLACEHOLDER
                                                    })`,
                                            }}
                                        ></div>
                                        }
                                        <span className="d-flex light-pill headmemberstatus position-absolute rounded-pill ">
                                            <span className="c-dots c-7 done rounded-circle radius500imp"></span>
                                        </span>
                                    </div>
                                </div>
                                <div className="c-list-detail title-fonts text-truncate color-white c-font f-13 ms-10px flex-grow-1">
                                    {userType == "contact" ? group.extraData && group.extraData.mainKey && group.extraData.mainKey == "myr_support" ? "Blaz support" : group.extraData.mainKey : group.groupName}
                                    {userType == "operator" && <span className="c-font f-10 color-white-60 text-break line-clamp-2">#{conactID}</span>}
                                    {userType == "contact" && <span className="c-font f-10 color-white-60 text-break line-clamp-2"># {comapnyName}</span>}
                                </div>
                            </a>
                            <div className=" d-flex align-items-center gap-2">
                                <div className="d-flex align-items-center h-100">
                                    {group.unreadCount > 0 ? (
                                        <span className="rounded-pill badge-custom  d-flex p-2 light-pill position-static">
                                            {group.unreadCount}
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                {/* <a href="#/" className="right-image" data-bs-dismiss="dropdown">
                                    <DotsThree
                                        size={18}
                                        weight="light"
                                        className="c-icons opacity-60"
                                    />
                                </a> */}

                                <a href="#/" className="right-image" data-bs-dismiss="dropdown"
                                onClick={()=>{
                                    dispatch(UpdateChatGroupsListDetails( chatGroupsDetails.filter((gd)=> gd.groupId != group.groupId)));
                                    dispatch(setUpdatedChatGroupsList(chatGroups.filter((gd)=> gd != group.groupId)));
                                    dispatch(setLastmessageGroupDetails({}));

                                    
                                    if (group.groupId == activeChatScreenID ) {
                                        dispatch(setactiveChatScreenId(0));
                                        var socketDataOut = {
                                            groupId: localStorage.getItem("activeGroupId"),
                                            userId: chatUserId,
                                          };
                                        dispatch(groupOut(socketDataOut));
                                    }

                                }}>
                                    <X size={18} weight="light" className="c-icons opacity-60" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <a
                        className="commanTaskCount"
                        onClick={() => {
                            if (activeChatScreenID == group.groupId) {
                                dispatch(setactiveChatScreenId(0));
                                var socketDataOut = {
                                    groupId: localStorage.getItem("activeGroupId"),
                                    userId: chatUserId,
                                  };
                                dispatch(groupOut(socketDataOut));

                            } else {
                                dispatch(setClearmessage());
                                var socketDataIn = {
                                    groupId: activeChatScreenID,
                                    userId: chatUserId,
                                  };
                                dispatch(groupIn(socketDataIn));
                                dispatch(setactiveChatScreenId(group.groupId));
                            }
                        }}
                    >
                        {activeChatScreenID == group.groupId ? (
                            <>
                                <CaretDown size={18} weight="light" className="c-icons opacity-60" />
                            </>
                        ) : (
                            <>
                                <CaretUp size={18} weight="light" className="c-icons opacity-60" />
                            </>
                        )}
                    </a>
                </div>

                {/* {activeChatScreenID == group.groupId ? ( */}
                    <Accordion.Body className={`taskCountToggle shadow-none d-flex flex-grow-1 flex-column  ${activeChatScreenID == group.groupId ? "" : "transition-dropdown hide"}`} id={`scrollableDiv-solar-${group.groupId}`}>
                        <div className="comman_vertical_btn_h h-100 scroll_60 border-0  comman-contnet-wrapper chat-main d-flex flex-column">
                         <SolarMessageScreen
                            messageList={messageList}
                            otherUserStatus={otherUserStatus}
                            isVirtualSpace={isVirtualSpace}
                            groupDetails={groupDetails}
                            setShow={setShow}
                            setViewGroupDetails={()=>{}}
                            isGroupAdmin={isGroupAdmin}
                            searchStringRightBoard={searchStringRightBoard}
                            setSearchStringRightBoard={setSearchStringRightBoard}
                            clearLeftchannelsearch={clearLeftchannelsearch}
                            mentionList={mentionList}
                            shareContacts={shareContacts}
                            chatUserId={id}
                            loader={loder}
                            pageName={"proposal_list"}
                            chatAIgroupdeatils={groupDetails}
                            setLoder={setLoder}
                            setshowDate={()=>{}}
                            msgloader={msgloader}
                        />
                        </div>
                    </Accordion.Body>
                {/* ) : (
                    <></>
                )} */}
            </Accordion.Item>
        </>
    );
};

export default SolarChatScreen;
