
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { DropdownItem, DropdownMenu } from "reactstrap";
import Select from "react-select";
import { showMessage } from "../../actions/messages";
import { _l } from "../../hooks/utilities";
import benchmarkServices from "../../services/benchmark-services";
import Spinner from "react-bootstrap/Spinner";

const InviteProvider = ({ item, setNewMyrStaffPast, taskId}) => {
    const dispach = useDispatch();
    const [email, setEmail] = useState("");
    const [loader, setLoader] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [phonenumber, setPhonenumber] = useState("");
    const [countryCode, setCountryCode] = useState({
        value: "+33",
        label: "+33 (FR)",
    });
    const [countryCodes, setCountryCodes] = useState([]);
    const [name, setName] = useState("");
    const project_id = localStorage.getItem("selectedOffice");
    const {country_codes} = useSelector((state) => state.customer);
    useEffect(() => {
        if (country_codes && country_codes.length > 0) {
            setCountryCodes(
                country_codes.map((code) => {
                    return {
                        value: code.code_value,
                        label: code.country_code,
                    };
                })
            );
        }
    }, [country_codes])
    const handleSubmit = () => {
        if (email == "") {
            dispach(showMessage("unsucess", _l("l_error"), _l("l_please_enter_email")));
        } else if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            dispach(showMessage("unsucess", _l("l_error"), _l("l_please_enter_valid_email")));
        } else if (name == "") {
            dispach(showMessage("unsucess", _l("l_error"), _l("l_please_enter_name")));
        } else if (countryCode == "") {
            dispach(showMessage("unsucess", _l("l_error"), _l("l_select_country_code")));
        } else if (phonenumber == "") {
            dispach(showMessage("unsucess", _l("l_error"), _l("l_please_enter_mobile")));
        } else {
            let providerArray = [{
                email: email,
                phonenumber: countryCode.value + phonenumber,
                name: name,
            }]
            const return_invitee_list = 1
            setLoader(true);
            benchmarkServices.addBulkProvidersToCategory(project_id, item.id, providerArray,return_invitee_list).then((res) => {
                setLoader(false);
                if (res.status == 1) {
                    setToggle(true);
                    setNewMyrStaffPast(res)
                    clearFormData()
                    dispach(showMessage("sucess", _l("l_sucess"), _l(res.message)));
                } else {
                    dispach(showMessage("unsucess", _l("l_error"), _l(res.message)));
                }

            })
        }
    }
    const clearFormData = () => {
        setName('');
        setPhonenumber("");
        setEmail('');
        setCountryCode({
            value: "+33",
            label: "+33 (FR)",
        });
    }
    const dropdownDemoForm =
        <>
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>{_l("l_provider_email")}</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder={_l("l_email_placeholder")}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>{_l("l_provider_name_placeholder")}</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={_l("l_full_name_placeholder")}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3 position-relative">
                    <Form.Label>{_l("l_mobile_number")}</Form.Label>
                    <div className="row">
                        <Form.Group className="col-lg-4 c-input-box position-relative pe-0">
                            <Select
                                className="custom-select-menu"
                                options={countryCodes}
                                value={countryCode}
                                onChange={(e) => { setCountryCode(e) }}
                                classNamePrefix="react-select"
                            />
                        </Form.Group>
                        <Form.Group className="col-lg-8">
                            <Form.Control
                                type="number"
                                placeholder={_l("l_mobile_number_placeholder")}
                                value={phonenumber}
                                onChange={(e) => setPhonenumber(e.target.value)}
                            />
                        </Form.Group>
                    </div>
                </Form.Group>
            </Form>
            <Button variant="primary" className={`${loader ? "for-disabled" : ""}`} size="sm" onClick={() => { handleSubmit() }}>
                {loader
                    ? _l('l_please_wait')
                    : _l("l_submit")

                }
                {loader ? <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    className={`ms-2 `}
                    aria-hidden="true"
                />
                    : <></>
                }
                    </Button>
        </>
        ;

    return (
        <>
            <DropdownMenu  className={`w-250 overflowscroll AssigneeDropdownCloseOutside${taskId}`} container="body">
                <DropdownItem toggle={false} className="no-hover">
                   
                    {dropdownDemoForm}
                </DropdownItem>
            </DropdownMenu>
        </>
    );
};

export default InviteProvider;
