import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../components/Header";
import LeftPanel from "../components/LeftPanel";
import Footer from "../components/Footer";
import Calendar from "../components/Calendar";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  CardListFullHeight,
  CardListScroll,
  DefaultLeftPanel,
  addExpandedClasstoIntroOfficeList,
  addExpandedClasstoOfficeList,
  hideLeftPanelNew,
  hideSolairHubleftPanel,
  isSolarHubPlatform,
  removeExpandedClasstoOfficeList,
  setPageMetaDataDescription,
  showLeftPanelNew,
  showSolairHubleftPanel,
  showSolairHubleftPanelForSearch,
} from "../hooks/utilities";
import { DEFAULT_METADATA_DESCRIPTION } from "../constants/constants";
import {
  getTaskList,
  projectTaskCounts,
  setSelectedDateForChatai,
  setSelectedGloablView,
  setTasklistLoader,
} from "../actions/customer";
import jquery from "jquery";
import { callTaskListForRealtime, setIDofFirstMessage } from "../actions/chat";
import jQuery from "jquery";

const PAGENAME_PROP_VALUE_FOR_PATH = {
  "/dashboard": "dashboardnew",
  "/provider-reporting": "operatorListReporting",
  "/employee-reporting": "operatorListReporting",
  "/equipment-reporting": "equipmentListReporting",
  "/liteversion": "LiteVersion",
  "/calendar": "calendar",
  "/aichat": "ChatAi",
  "/space-onboarding": "SpaceOnBoardingNew",
  "/premiumsubscription": "PremiumSubscription",
  "/explore-features": "SpaceOnBoarding",
  "/connect-provider": "SpaceOnBoarding",
  "/tenants": "SpaceOnBoarding",
  "/employees": "SpaceOnBoarding",
  "/providers": "SpaceOnBoarding",
  "/equipments": "SpaceOnBoarding",
  "/leads": "leadPage",
  "/dashboardpre": "dashboard",
  "/clients": "client",
  "/staff": "staff",
  "/credit_notes": "credit_notes",
  "/benchmarks": "SpaceOnBoarding",
  "/equipmentListing": "_equipments",
  "/myprofile": "MyProfile",
  "/create-dpgf-benchmark" : "SpaceOnBoarding",
  "/view-dpgf-proposal" : "SpaceOnBoarding",
  "/proposals" : "Proposals",
  "/provider-documents": "SolarProvierDocuments",
  "/proposal-details" : "proposal_details",
};

const PAGES_WITH_NO_FOOTER = [
  "/space-onboarding",
  "/explore-features",
  "/connect-provider",
  "/tenants",
  "/employees",
  "/providers",
  "/equipments",
  "/create-dpgf-benchmark",
  "/view-dpgf-proposal",
];
const PAGES_WITH_NO_CALENDAR = [
  "/space-onboarding",
  "/explore-features",
  "/connect-provider",
  "/tenants",
  "/employees",
  "/providers",
  "/equipments",
  "/create-dpgf-benchmark",
  "/view-dpgf-proposal",
];

const CONNECT_PAGES = [
  "page=create-benchmark",
  "page=register-provider",
  "page=proposals",
];

const Layout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const {
    globalView,
    commanoffcanvasIsOpen,
    taskFilters,
    activeTaskStatusFilters,
    getSelectedCalendarDate,
    isDayGridDay,
    isAppliedUnreadComment,
    leftPanelTab
  } = useSelector((state) => state.customer);
  const { callTaskAPI } = useSelector((state) => state.chat);
  const { isCallRecived } = useSelector((state) => state.chat);

  const savedProject = localStorage.getItem("selectedOffice");
  const client_id = localStorage.getItem("client_id");
  const contact_id = localStorage.getItem("contact_id");
  const authToken = localStorage.getItem("accessToken");
 let userType = localStorage.getItem("user_type");
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const space = searchParams.get("space");
  const type = searchParams.get("type");
  const id = searchParams.get("id");
  const proposal_id = searchParams.get("proposal_id");

  const [isStatusCleared, setIsStatusCleared] = useState(false);
  const [IsKanbanView, setIsKanbanView] = useState(
    localStorage.getItem("fav_dashboard") == "kanban_view" ? true : false
  );
  const [startDate] = useState( localStorage.getItem("fromDate") || new Date());
  const [endDate] = useState(localStorage.getItem("toDate") || new Date());
  const [projectId, setProjectId] = useState(savedProject || 0);
  const [pageName, setPageName] = useState(
    PAGENAME_PROP_VALUE_FOR_PATH[location.pathname]
  );
  const [callTaskApi, setCallTaskApi] = useState(0);
  let contact_role = localStorage.getItem("contact_role");
  let callAPI = localStorage.getItem("callTaskAPi");
  const token = localStorage.getItem("accessToken");

  const clearStatusFilter = (flag = true) => {
    setIsStatusCleared(flag);
  };

  const hideLeftPanel = () => {
    let flag = false;
    if (CONNECT_PAGES.find(x => window.location.search.toLowerCase().includes(x))) {
      if(token && ["register-provider", "proposals", "create-benchmark"].find(x => window.location.search.toLowerCase().includes(x)))
      {
        flag = false;
      }
      else {
        flag = true;
      }
    }
    else if(["SolarProvierDocuments"].includes(pageName))
    {
      flag = true;
    }
    return flag;
  };

  const hideHeader = () => {
    let flag = false;
    if (CONNECT_PAGES.find(x => window.location.search.toLowerCase().includes(x))) {
      flag = true;
    }
    else if (["invite-customer-for-benchmark"].find(x => window.location.search.toLowerCase().includes(x)))
    {
      flag = true;
    }
    else if(["SolarProvierDocuments","proposal_details"].includes(pageName))
    {
      flag = true;
    }else if (["homePage","my-documents"].includes(leftPanelTab) ){
      flag = true;
    }
    return flag;
  };

  const hideCalendar = () => {
    let flag = false;
    if (CONNECT_PAGES.find(x => window.location.search.toLowerCase().includes(x))) {
      flag = true;
    }
    else if(["SolarProvierDocuments"].includes(pageName))
    {
      flag = true;
    }else if(leftPanelTab == "my-drive"){
      flag = true;
    }
    return flag;
  };

  const hideFooter = () => {
    let flag = false;
    if (CONNECT_PAGES.find(x => window.location.search.toLowerCase().includes(x))) {
      flag = true;
    }
    else if(["invite-customer-for-benchmark"].find(x => window.location.search.toLowerCase().includes(x)))
    {
      flag = true;
    }
    else if(["SolarProvierDocuments"].includes(pageName))
    {
      flag = true;
    }
    else if (["homePage", "my-calendar"].includes(leftPanelTab) ){
      flag = true;
    }
    return flag;
  };

  useEffect(() => {
    localStorage.setItem(
      "selectedDate",
      moment(new Date()).format("YYYY-MM-DD")
    );
    setPageMetaDataDescription(DEFAULT_METADATA_DESCRIPTION);
  }, []);

  useEffect(() => {
    if (globalView != "AichatView") {
      dispatch(setIDofFirstMessage(""));
      dispatch(setSelectedDateForChatai(new Date()));
    }

    if (globalView == "listView") {
      setIsKanbanView(false);
    }
    if (globalView === "spaceView") {
      CardListScroll();
    }
    if (globalView == "chat") {
      dispatch(setSelectedGloablView("liteVersionView"));
    }
    if (
      [
        "listView",
        "kanabanView",
        "liteVersionView",
        "calendarView",
        "spaceView",
      ].includes(globalView)
    ) {
    
    }else if((page == "create-benchmark" && space > 0 && type == "benchmark" && id > 0) || (proposal_id && proposal_id > 0)){
      showSolairHubleftPanel();
    } else if(isSolarHubPlatform() && userType == "staff"){
      showSolairHubleftPanel();
      addExpandedClasstoOfficeList();
    }else if (pageName != "leadPage") {
      if (pageName != "ChatAi" && !isSolarHubPlatform()) {
        DefaultLeftPanel(true);
      }
    }

    CardListFullHeight();
    if (globalView == "liteVersionView") {
      localStorage.setItem("tasklistView", "liteVersionView");
    }
    return () => {};
  }, [globalView]);

  useEffect(() => {
    if (window.location.pathname == "/" &&  page !== "proposals") {
      if (
        projectId == 0 ||
        projectId == null ||
        projectId == undefined ||
        projectId == "null"
      ) {
       
      } else if (commanoffcanvasIsOpen) {
        hideLeftPanelNew();
      } else if (
        (commanoffcanvasIsOpen == false &&
          (projectId == 0 || projectId == null || projectId == undefined)) ||
        contact_role == 3
      ) {
        hideLeftPanelNew();
      } else if(!["register-provider", "invite-customer-for-benchmark","proposal-deatils"].find(x => window.location.search.toLowerCase().includes(x)) && commanoffcanvasIsOpen == false) {
        showLeftPanelNew();
      }
    }
    if (isSolarHubPlatform() && userType == "staff" && !jquery("#intro-office-list").hasClass("expanded")) {
      // addExpandedClasstoIntroOfficeList(false);
    }
    if (isSolarHubPlatform() && proposal_id == 0) {
      // hideSolairHubleftPanel();
      // removeExpandedClasstoOfficeList();
    }
  });

  useEffect(() => {
    const savedDate = localStorage.getItem("selectedDate");
    const isTaskCreationOngoing = sessionStorage.getItem(
      "isTaskCreationOngoing"
    );
    if (
      // taskFilters &&
      // taskFilters.length &&
      globalView != "AichatView" && 
      !isTaskCreationOngoing &&
      !commanoffcanvasIsOpen &&  !["homePage", "my-drive"].includes(leftPanelTab)
    ) {
      dispatch(setTasklistLoader(true));
      dispatch(
        getTaskList(
          client_id,
          contact_id,
          ["my-request", 'my-calendar'].includes(leftPanelTab) ? 0 : projectId,
          moment(savedDate).format("YYYY-MM-DD"),
          activeTaskStatusFilters.length ? 2 : 1,
          activeTaskStatusFilters,
          taskFilters,
          "",
          0,
          isDayGridDay && !isAppliedUnreadComment ? 1 : 0,
          isDayGridDay
            ? moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD")
            : moment(startDate).format("YYYY-MM-DD"),
          isDayGridDay
            ? moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD")
            : moment(endDate).format("YYYY-MM-DD")
        )
      );

      dispatch(projectTaskCounts(["my-request", 'my-calendar'].includes(leftPanelTab) ? 0 : projectId, taskFilters, "", false, pageName));
    }
  }, [
    JSON.stringify(taskFilters),
    JSON.stringify(activeTaskStatusFilters),
    projectId,
    getSelectedCalendarDate,
    callAPI,
    JSON.stringify(callTaskApi),
    isCallRecived,
  ]);

  useEffect(() => {
    const pageNameForPath =
      PAGENAME_PROP_VALUE_FOR_PATH[location.pathname] || "Dashboard"; //As Dashboard component is being configured as default route (*) in App.js, "Dashboard" is used as page name in that component so using the same logic here...
    setPageName(pageNameForPath);

    if (location.pathname == "/" && window.location.pathname == "/dashboard") {
      navigate("/dashboard");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (callTaskAPI && Object.keys(callTaskAPI).length > 0) {
      let selectedTopic = JSON.parse(localStorage.getItem("selectedTopic"));
      let assignedTopics =
        callTaskAPI && callTaskAPI.assignedTopics
          ? callTaskAPI.assignedTopics
          : [];
      if (assignedTopics.length > 0 && selectedTopic) {
        let topic =
          assignedTopics &&
          assignedTopics.length > 0 &&
          assignedTopics.filter((fil) => {
            if (fil.mainKey == "my_task") {
              return (
                fil.mainKey == selectedTopic.main_key &&
                callTaskAPI.projectId == projectId
              );
            } else {
              return (
                fil.mainKey == selectedTopic.main_key &&
                fil.filterKey == selectedTopic.filter_key &&
                fil.mainSubKey == selectedTopic.main_sub_key &&
                callTaskAPI.projectId == projectId
              );
            }
          });

        if (topic.length > 0) {
          setCallTaskApi(callTaskAPI.taskId);
        }

        dispatch(callTaskListForRealtime({}));
      }
    }
  }, [callTaskAPI]);
  
  useEffect(() => {
    if (["homePage","my-request"].includes(leftPanelTab) ) {
      showSolairHubleftPanelForSearch();
    }
  }, [leftPanelTab])
  

  return (
    <>
      <div className="d-flex flex-column h-100">
        {authToken ? (
          <React.Fragment>
            {hideHeader() ? (
              <React.Fragment></React.Fragment>
            ) : (
              <Header projectId={projectId} pageName={pageName} />
            )}
            {/* {PAGES_WITH_NO_CALENDAR.includes(location.pathname) ||
            hideCalendar() ? (
              <React.Fragment></React.Fragment>
            ) : (
              <Calendar
                projectId={projectId}
                clearStatusFilter={clearStatusFilter}
                setTaskModalClose={() => {}}
                pageName={pageName}
              />
            )} */}
            {!hideLeftPanel() ? (
              <LeftPanel
                projectId={projectId}
                setSelectedProject={setProjectId}
                pageName={pageName}
                clearStatusFilter={clearStatusFilter}
              />
            ) : (
              <></>
            )}
            <Outlet />
            {PAGES_WITH_NO_FOOTER.includes(location.pathname) || (leftPanelTab == "my-clients" && userType == "staff") ||
            hideFooter() ? (
              <React.Fragment></React.Fragment>
            ) : (
              <Footer pageName={pageName} />
            )}
          </React.Fragment>
        ) : (
          <Outlet />
        )}
      </div>
    </>
  );
};

export default Layout;
