import React, { useEffect, useState } from 'react'
import { Sun, Check, MapPinLine, GearSix, ChatCircle, DesktopTower, VideoCamera, House, Umbrella, UserCircleGear, MapPin, PushPin, ArrowRight, DotsThreeVertical, CalendarBlank, X } from "phosphor-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getAttendance, getLockScreenTaskList, getTaskCount, getTaskList, leftPanelFilters, projectTaskCounts } from '../../actions/customer';
import customerServices from '../../services/customer-services';
import { COUNT_LOCK_TASKS } from '../../actions/action-type';
import { _l, getDatePickerLocale } from '../../hooks/utilities';
import { useNavigate } from "react-router-dom";
import User_2 from "../../assets/images/users/user_2.jpg";
import jQuery from 'jquery';
import { DropdownItem, DropdownToggle, Spinner, UncontrolledDropdown } from 'reactstrap';
import UncontrolledGeneralListing from '../TaskModalComponents/UncontrolledGeneralListing';
import AddAssigneeMainDropdown from '../TaskModalComponents/AddAssigneeMainDropdown';
import HighPriorityActiveIcon from '../../assets/icons/HighPriorityActiveIcon';
import HighPriorityIcon from '../../assets/icons/HighPriorityIcon';
import TaskListStatusDropdown from '../Dropdowns/TaskListStatusDropdown';
import CommentMinified from '../Comments/CommentMinified';
import Calendar from '../Calendar';
import SpaceListDropdown from '../Dropdowns/SpaceListDropdown';
import jquery from "jquery";
import DeleteDropdown from '../Dropdowns/DeleteDropdown';
import ReactTooltip from 'react-tooltip';
import FollowersDropdown from '../Dropdowns/FollowersDropdown';
import CommentDropdown from '../Dropdowns/CommentDropdown';
import { showConfirmation, showMessage, toastCloseAction } from '../../actions/messages';
import { Button } from 'react-bootstrap';
import CustomerServices from "../../services/customer-services";


const LockScreenHeader = ({
    setShowLoader,
    taskCount,
    filterTask,
    displayAttendance,
    setDisplayAttendance,
    get_attendance,
    setSelectedTab,
    pageName,
    taskPinned,
    setTaskPinned
}) => {
    const spaces = localStorage.getItem("spaces") != null && localStorage.getItem("spaces") != undefined ? JSON.parse(localStorage.getItem("spaces")) : '';
    const spaceState = useSelector((state) => state.customer.spaces);
    const savedProject = localStorage.getItem("selectedOffice");
    const [projectId, setProjectId] = useState(savedProject && (savedProject != null ? savedProject : (spaces && spaces.length ? spaces[0].project_id : 0)));
    const client_id = localStorage.getItem("client_id");
    const dispatch = useDispatch();
    const contact_id = localStorage.getItem("contact_id");
    const { taskFilters, activeTaskStatusFilters, lockTaskList, lockCountTask , isBackdrop } = useSelector((state) => state.customer);
    const savedDate = localStorage.getItem("selectedDate");
    const [leftPanelActiveFilters, setLeftActivePanelFilters] = useState([]);
    const [date, setDate] = useState(localStorage.getItem('__selectedDate') != undefined ? new Date(localStorage.getItem('__selectedDate')) : new Date());
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const lockTaskCounter = useSelector((state) => state.customer.getLockTaskCount);
    const [filter, setFilter] = useState([]);
    const navigate = useNavigate();
    const { defaultTopics,} = useSelector((state) => state.customer);
    const [openSpaceListDropdown, setOpenSpaceListDropdown] = useState(false)
    const [selectedSpace, setSelectedSpace] = useState({})
    const [openAssigneeDropdown, setOpenAssigneeDropdown] = useState(false);
    const [commentsList, setCommentsList] = useState([]);
    const [taskStatus, setTaskStatus] = useState(1);
    const [taskPriority, setTaskPriority] = useState(2)
    const [selectedAssigneeIds, setSelectedAssigneeIds] = useState([]);
    const [selectedAssignee, setSelectedAssignee] = useState([]);
    const [dropDownToggle, setDropDownToggle] = useState(false);
    const [addChecklistItem, setAddChecklistItem] = useState("");
    const [taskTitle, setTaskTitle] = useState("");
    const [checklistItems, setChecklistItems] = useState([]);
    const [checkListTexts, setCheckListTexts] = useState([]);
    const [openFollowersDropdown, setOpenFollowersDropdown] = useState(false);
    const [openCommentTab, setOpenCommentTab] = useState(false);
    const [checklistStatus, setChecklistStatus] = useState(0);
    const [checklistItemAssigneeList, setChecklistItemAssigneeList] = useState([]);
    const [disableOnClickEvent, setDisableOnClickEvent] = useState(false);
    const [addChecklistItemEvent, setAddChecklistItemEvent] = useState("");
    const [commentCount, setCommentCount] = useState([]);
    const [taskAssignee, setTaskAssignee] = useState([]);
    const [loder, setLoder] = useState(false)
    const [CommentFile, setCommentFile] = useState()
    const [selectedAssigneeIdsForTask, setselectedAssigneeIdsForTask] = useState([])
    const [startDate, setStartDate] = useState(new Date());

    useEffect(() => {
        // localStorage.setItem('__selectedDate', date);
        // dispatch(
        //     getTaskList(
        //         client_id,
        //         contact_id,
        //         projectId,
        //         activeTaskStatusFilters.length
        //             ? ""
        //             : moment(date).format("YYYY-MM-DD"),
        //         activeTaskStatusFilters.length ? 2 : 1,
        //         activeTaskStatusFilters,
        //         taskFilters,
        //         ""
        //     )
        // );
    }, [date])

    useEffect(() => {
        let task_assignee = [];
        let task_assignee_ids = [];
        let task_checklist_assignee = [];
      let task_assignee_pills = [];
        if (selectedAssignee.length > 0) {
            selectedAssignee.map((ta) => {
              ta.categories && ta.categories.map((taskAssign) => {
                task_assignee_ids.push({
                  name: ta.main_key,
                  id: taskAssign.filter_key,
                })
              if (
                ta.main_key == "myr_staff_task" ||
                ta.main_key == "my_people" ||
                ta.main_key == "task_topic"
              ) {
                defaultTopics &&
                  defaultTopics.forEach((topic) => {
                    if (topic.main_key == ta.main_key) {
                      topic.categories.forEach((provider) => {
                        if (taskAssign.filter_key == provider.filter_key) {
                          task_assignee.push({
                            name: provider.title,
                            profile_image: provider.image,
                            id: provider.filter_key,
                          });
                        }
                      });
                    }
                  });
              } else {
                defaultTopics &&
                  defaultTopics.forEach((topic) => {
                    if (topic.main_key == ta.main_key) {
                      topic.categories.forEach((provider) => {
                        if (taskAssign.filter_key == provider.filter_key) {
                          if (topic.main_key != "equipments") {
                            task_assignee_pills.push({
                              name: provider.title,
                              id: provider.filter_key,
                              main_key:topic.main_key
                            });
                          }
  
                          let subCategory = []
                          provider.sub_category && provider.sub_category.forEach((sc) => {
                            taskAssign.sub_category && taskAssign.sub_category.forEach((dd) => {
                              if (sc.id == dd.id) {
                                  let title = taskTitle
                                  let tempTitle = title.split('-')
                                  if (tempTitle[1]) {
                                    title = taskAssign.sub_category[taskAssign.sub_category.length - 1].name + " - " + tempTitle[1]
                                  }else{
                                    if(!taskTitle.toLocaleLowerCase().includes(dd.name.toLocaleLowerCase())){
                                        title =  taskAssign.sub_category[taskAssign.sub_category.length - 1].name + `${taskTitle ? " - " : "" }` + taskTitle
                                      }
                                  }
                                  // if(taskAssign.sub_category.length == 1){
                                  //   title =  dd.name + `${taskTitle ? "-" : "" }` + taskTitle
                                  // } else if (taskAssign.sub_category.length > 1 && !taskTitle.toLocaleLowerCase().includes(provider.title.toLocaleLowerCase())) {
                                  //   title = provider.title + " - " + taskTitle
                                  // }
                                  setTaskTitle(title)
                                subCategory.push(dd.id);
                                task_assignee_pills.push({
                                  name: sc.name,
                                  id: sc.id,
                                  main_key:topic.main_key
                                });
                              }
                            })
                          });
                          if (subCategory.length == 0) {
                            task_assignee_ids = task_assignee_ids.filter((tas) => tas.name != "equipments")
                          }
                          task_assignee_ids.push({
                            id: provider.filter_key,
                            name: provider.title,
                            sub_category: subCategory
                          })
                        }
                      });
                    }
                  });
              }
            });
          })
        }
      setTaskAssignee(task_assignee);
      setSelectedAssigneeIds(task_assignee_ids);
      let ids = [];
      task_assignee_ids.map((data)=>{
       ids.push(data.id)
      })
      setselectedAssigneeIdsForTask(ids)

  }, [JSON.stringify(selectedAssignee)]);

    useEffect(() => {
      if (disableOnClickEvent == "" && addChecklistItemEvent != "") {
        handleAddChecklistItem(addChecklistItemEvent);
      }
    }, [disableOnClickEvent]);

    const is_active_class = (key) => {
        let activeClassTask = false;
        filter.forEach(element => {
            if (element == key) {
                activeClassTask = true;
            }
        });
        return activeClassTask;
    }

    const filterTaskList = (type) => {
        const taskPosition = filter.findIndex(x => x == type)
        if (taskPosition == -1) {
            setFilter([...filter, type])
        }
        else {
            setFilter(filter.filter((t) => t != type))
        }
    }

    const handleAddEditComment = async (content, files, id) => {

        const staffid = localStorage.getItem("staff_id");
        const profile_url = localStorage.getItem("contact_image");
        const full_name = localStorage.getItem("full_name");
        let attachments = [];

        if (files) {
          let commentsfiles = [];
          commentsfiles.push(files)
          setCommentFile(commentsfiles)
            Array.from(files).map((file) => {
                attachments.push({
                    file_url: URL.createObjectURL(file),
                    file_name: file.name
                })
            });
        }

        setCommentsList(commentsList.concat([
            {
                id: "0",
                content: content,
                contact_id: contact_id,
                staffid: staffid,
                full_name: full_name,
                profile_url: profile_url,
                attachments: attachments,
                date: moment().fromNow()
            }
        ]));
    }

    const handleDeleteComment = (comment) => {
        setCommentsList(commentsList.filter(x => x.content != comment.content));
    }

    const changeTaskStatus = (statusId) => {
        if (statusId != "task_convert_to_request") {
            setTaskStatus(statusId);
        }
    };

    const handleRequestAssignee = (assigneeDetail) => {
        let taskAssign = selectedAssignee
        let taskAssignIds = selectedAssigneeIds
        setDropDownToggle(assigneeDetail.main_key);
        if (taskAssign && taskAssign.length > 0) {
            if (assigneeDetail.main_key == "equipments") {
                let Index = taskAssign.findIndex((e) => e.main_key == assigneeDetail.main_key);
                if (Index > -1) {
                    let IndexT = taskAssign[Index]['categories'].findIndex((taskassign) => taskassign.filter_key == assigneeDetail.filter_key)
                    if (IndexT > -1) {
                        let IndexU = taskAssign[Index]['categories'][IndexT].sub_category.findIndex((taskassign) => taskassign.id == assigneeDetail.sub_category[0].id)
                        if (IndexU > -1) {
                            taskAssign[Index]['categories'][IndexT].sub_category = taskAssign[Index]['categories'][IndexT].sub_category.filter((taskassign) => taskassign.id != assigneeDetail.sub_category[0].id)
                            // taskAssignIds = taskAssignIds.sub_category.filter(sa => sa.id != assigneeDetail.filter_key)
                        } else {
                            taskAssign[Index]['categories'][IndexT].sub_category.push(assigneeDetail.sub_category[0])
                        }
                    } else {
                        taskAssign[Index]['categories'] = [{ filter_key: assigneeDetail.filter_key, sub_category: assigneeDetail.sub_category }]
                    }
                } else {
                    taskAssign.push({
                        main_key: assigneeDetail.main_key,
                        categories: [{ filter_key: assigneeDetail.filter_key, sub_category: assigneeDetail.sub_category }]
                    })
                }
            } else {
                var index = taskAssign.findIndex((e) => e.main_key == assigneeDetail.main_key);
                if (index > -1) {
                    let isExist = taskAssign[index] && taskAssign[index].categories.findIndex((taskassign) => taskassign.filter_key == assigneeDetail.filter_key)
                    if (isExist > -1) {
                        taskAssign[index]['categories'] = taskAssign[index]['categories'].filter((taskassign) => taskassign.filter_key != assigneeDetail.filter_key)
                        taskAssignIds = taskAssignIds.filter(sa => sa.id != assigneeDetail.filter_key)
                    } else {
                        if (assigneeDetail.main_key == "provider_task") {
                            const indexIds = taskAssignIds.findIndex(id => id.name == "provider_task")
                            const index = taskAssign.findIndex(id => id.main_key == "provider_task")
                            if (indexIds > -1) {
                                taskAssignIds[indexIds] = {
                                    name: assigneeDetail.main_key,
                                    id: assigneeDetail.filter_key
                                }
                            }
                            if (index > -1) {
                                taskAssign[index].categories = [{ filter_key: assigneeDetail.filter_key }]
                            }
                        } else {
                            taskAssign[index]['categories'].push({ filter_key: assigneeDetail.filter_key })
                            taskAssignIds.push({
                                name: assigneeDetail.main_key,
                                id: assigneeDetail.filter_key
                            })
                        }
                    }
                } else {
                    taskAssign.push({
                        main_key: assigneeDetail.main_key,
                        categories: [{ filter_key: assigneeDetail.filter_key }]
                    })
                    taskAssignIds.push({
                        name: assigneeDetail.main_key,
                        id: assigneeDetail.filter_key
                    })
                }
            }
        } else {
            if (assigneeDetail.main_key == "equipments") {
                taskAssign = [{
                    main_key: assigneeDetail.main_key,
                    categories: [{ filter_key: assigneeDetail.filter_key, sub_category: assigneeDetail.sub_category }]
                }]
                taskAssignIds = [{
                    name: assigneeDetail.main_key,
                    id: assigneeDetail.filter_key,
                    sub_category: assigneeDetail.sub_category
                }]
            } else {
                taskAssign = [{
                    main_key: assigneeDetail.main_key,
                    categories: [{ filter_key: assigneeDetail.filter_key }]
                }]
                taskAssignIds = [{
                    name: assigneeDetail.main_key,
                    id: assigneeDetail.filter_key
                }]
            }
        }
        setSelectedAssignee(taskAssign.slice())
        setSelectedAssigneeIds(taskAssignIds)

    };

    const handleAddChecklistItem = (e) => {
        let checklistArr = [];
        if (e.type == "paste") {
            checklistArr = e.clipboardData.getData("Text").split("\n");
        }
        checklistArr = e.target.value.split("\n");
        let newArr = [];
        for (let i = 0; i < checklistArr.length; i++) {
            if (checklistArr[i].trim().length) {
                newArr.push({ description: checklistArr[i], finished: 0 });
            }
        }
       
        let checklistText = checkListTexts;
        let newList = [];
        newList.push({
            addedfrom: contact_id,
            description: (e.target.value).trim(),
            finished: "0",
            id: checklistItems.length + 1,
            is_added_from_contact: "1",
            list_order: "0",
            req_photo: "0",
            comments_count: 0,
            links_count: 0,
            attachments_count: 0,
            is_allowed_to_edit: 1,
            status: 0,
            comments:[],
            assignee_id:0
        });
        setAddChecklistItem('')
        let arr = checklistItems.concat(newList)
        setChecklistItems(checklistItems.concat(newList))

        arr.forEach((item) => {
            checklistText[item.id] = (item.description).trim();
          });
        setCheckListTexts(checklistText);

    };

    const handleEditCheckList = (e, id) => {
        setCheckListTexts({
          ...checkListTexts,
          [`${id}`]: e.target.value,
        });
      };

      const itemPriority = (checklistId, isFinished, item, priority) => {
            const index = checklistItems.findIndex(
              (item) => item.id == checklistId
            );
            let updatedChecklist = checklistItems[index];
            updatedChecklist.priority = priority;
            setChecklistItems(
              Object.values({ ...checklistItems, [index]: updatedChecklist })
            );        
      };

      const handleDeleteChecklist = (id) => {
        dispatch(
          showConfirmation(
            _l("l_are_you_sure"),
            _l("l_you_want_to_delete_this_checklist"),
            _l("l_delete"),
            _l("l_cancel"),
            undefined,
            () => {
              removeChecklist(id);
            }
          )
        );
      };

      const removeChecklist = (id) => {
            setChecklistItems(checklistItems.filter((item) => item.id !== id));
            setAddChecklistItem("");
            dispatch(toastCloseAction());        
      };

      const handleTaskStatus = (
        checklistId,
        description,
        finished,
        checkUnCheck,
        index_id,
        priority,
        status
      ) => {
        const index = checklistItems.findIndex((item) => item.id == checklistId);
        let updatedChecklist = checklistItems[index];
        updatedChecklist.status = status;
        setChecklistItems(
          Object.values({ ...checklistItems, [index]: updatedChecklist })
        );
      };
      
      const itemFinished = (checklistData, checked, index) => {
        checklistData.finished = checked ? "1" : "0";
        let updatedChecklist = checklistItems[index];
        updatedChecklist.finished = checked;
        setChecklistItems(
          Object.values({ ...checklistItems, [index]: updatedChecklist })
        );
        
      };

      const handelCreateTask = () =>{
        setLoder(true)      
        CustomerServices.createNewTask(selectedSpace.project_id, 0, taskStatus, selectedAssignee.length ? selectedAssignee : [], "", taskTitle , 0, 0, [], taskPriority, taskPinned, commentsList, checklistItems, CommentFile).then(
          (res) => {
            if (res.status && res.data.task_id ) {
              setLoder(false);
              dispatch(
                getTaskList(
                  localStorage.getItem("client_id"),
                  localStorage.getItem("contact_id"),
                  "",
                  moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD"),
                  1,
                  [],
                  []
                )
              );
              setTaskTitle("")
              setTaskPriority(2)
              setSelectedSpace({})
              setCommentsList([])
              setAddChecklistItem("")
              setTaskStatus(1)
              setSelectedAssigneeIds([])
              setAddChecklistItem("")
              setChecklistItems([])
              setCheckListTexts([])
              setTaskPinned(false)
              dispatch(showMessage("sucess", _l("l_success"), _l("l_task_created")));
            }else{
              setLoder(false);
              dispatch(
                showMessage("unsucess", _l("l_error"), res.message)
              );

            }
          })
      }

      const handleChecklistAssignee = (user, checklist_id) => {
        let assigneeId = 0;
    
        if (user) {
          user.profile_image_url = user.user_image || user.profile_image;
          assigneeId = user.assigneeid || user.id;
        }            const index = checklistItems.findIndex(
              (item) => item.id === checklist_id
            );
            let updatedChecklist = checklistItems[index];
            let updatedChecklistAssigneeIds = checklistItems[index];
            updatedChecklist.assigned_details = user;
            updatedChecklistAssigneeIds.assignee_id = user.id
            if (user) {
              if (localStorage.getItem("user_type") == "contact") {
                updatedChecklist.assigned_details["id"] = user.id;
              } else {
                updatedChecklist.assigned_details["staffid"] = user.id;
              }
              updatedChecklist.assigned_details.full_name = user.name;
            }
    
            if (assigneeId) {
              updatedChecklist.assigned = 1;
            } else {
              updatedChecklist.assigned = 0;
            }
            setChecklistItems(
              Object.values({ ...checklistItems, [index]: updatedChecklist ,[index] : updatedChecklistAssigneeIds})
            );
      };

    return (
        <>
            <div className='d-none'>
                <div className="lock-screen-main-header">
                    <div className="d-flex lock-screen-slider p-10">
                        <div className="progress-slider w-100">
                            <div className="d-flex align-items-center">
                                <div>{_l("l_space_score")}</div>
                                <div className="progress mx-3">
                                    <div className="progress-bar" style={{ width: `${(taskCount ? taskCount.task_progress : 0)}%` }}></div>
                                </div>
                                <div className="c-font f-12 ms-2 title-fonts">{taskCount ? taskCount.task_progress : 0}%</div>
                            </div>
                        </div>
                    </div>
                    <div className='calendar-modal-color p-10 pb-3'>
                        <div className="d-flex  justify-content-between ">
                            <div className="lock-left-header text-center custom-datepicker cursor_hide abs-input position-relative">
                                <div className="c-font date f-24 fw-bold lh-1 pb-1 title-fonts">{("0" + date.getDate()).slice(-2)}</div>
                                <div className="c-font day f-12  text-uppercase">{days[date.getDay()]}</div>
                                <div className="c-font f-10 month ">{monthNames[date.getMonth()] + '. ' + date.getUTCFullYear()}</div>
                                <div className='react-datepicker-full-height'>
                                    <DatePicker
                                        selected={date}
                                        locale={getDatePickerLocale()}
                                        onChange={(date) => {
                                            setDate(date);
                                            // handleDateChange(date)
                                        }}
                                        calendarStartDay={1}
                                    ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                                </div>
                            </div>
                            <div className="lock-mid-header">
                                <div className="d-flex h-100">
                                    {/* <div className="schedule-header-title text-truncate">
                                <div className="c-font f-16 fw-semibold title-fonts text-truncate">FDI Corporation</div>
                                <div className="c-font color-white-60 f-12  text-truncate"> 8502 Preston Rd. Inglewood, Maine</div>
                            </div> */}
                                    <div className="schedule-right-header flex-grow-1">
                                        <div className="h-100 kpi-main-wrapper p-0 shortcut-wrapper">
                                            <div className="d-flex h-100">
                                                <a href="#/"
                                                    onClick={() => {
                                                        filterTaskList('request');
                                                        // dispatch(
                                                        //     leftPanelFilters({
                                                        //       main_key: "status",
                                                        //       filter_key: 'request',
                                                        //       title: "status_filter"
                                                        //     })
                                                        // )
                                                        // jQuery('.request').toggleClass('active');
                                                    }}
                                                    className={`align-items-center bg-white-05 justify-content-center kpi-box ${is_active_class('request') ? 'active' : ''}`}>
                                                    <div className="kpi-upper-box">
                                                        <div className="d-flex align-items-center">
                                                            <div className="c-font f-10 kpi-upper-box-text">
                                                                <span className="color-orange">{_l("l_request")}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center mt-1">
                                                        <div className="w-50 text-center">
                                                            <div className="c-font f-12 kpi-statatics">{taskCount && taskCount.request_count} </div>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a href="#/"
                                                    onClick={() => {
                                                        filterTaskList('1');
                                                        // dispatch(
                                                        //     leftPanelFilters({
                                                        //       main_key: "status",
                                                        //       filter_key: 'todo',
                                                        //       title: "status_filter"
                                                        //     })
                                                        // )
                                                    }}
                                                    className={`align-items-center bg-white-05 justify-content-center kpi-box ${is_active_class('1') ? 'active' : ''}`}>
                                                    <div className="kpi-upper-box">
                                                        <div className="d-flex align-items-center">
                                                            <div className="c-font f-10 kpi-upper-box-text">
                                                                <span className="color-white-80">{_l("l_todo")}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center mt-1">
                                                        <div className="w-50 text-center">
                                                            <div className="c-font f-12 kpi-statatics">{taskCount && taskCount.todo_count} </div>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a href="#/"
                                                    onClick={() => {
                                                        filterTaskList('4');
                                                        // dispatch(
                                                        //     leftPanelFilters({
                                                        //       main_key: "status",
                                                        //       filter_key: 'in_progress',
                                                        //       title: "status_filter"
                                                        //     })
                                                        // )
                                                    }}
                                                    className={`align-items-center bg-white-05 justify-content-center kpi-box ${is_active_class('4') ? 'active' : ''}`}>
                                                    <div className="kpi-upper-box">
                                                        <div className="d-flex align-items-center">
                                                            <div className="c-font f-10 kpi-upper-box-text">
                                                                <span className="color-blue">{_l("l_inprogress")}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center mt-1">
                                                        <div className="w-50 text-center">
                                                            <div className="c-font f-12 kpi-statatics">{taskCount && taskCount.inprogress_count} </div>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a href="#/"
                                                    onClick={() => {
                                                        filterTaskList('5');
                                                        // dispatch(
                                                        //     leftPanelFilters({
                                                        //       main_key: "status",
                                                        //       filter_key: 'done',
                                                        //       title: "status_filter"
                                                        //     })
                                                        // )
                                                    }}
                                                    className={`align-items-center bg-white-05 justify-content-center kpi-box ${is_active_class('5') ? 'active' : ''}`}>
                                                    <div className="kpi-upper-box">
                                                        <div className="d-flex align-items-center">
                                                            <div className="c-font f-10 kpi-upper-box-text">
                                                                <span className="color-green">{_l("l_done")}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center mt-1">
                                                        <div className="w-50 text-center">
                                                            <div className="c-font f-12 kpi-statatics">{taskCount && taskCount.done_count} </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <a href="#/" className={`lock-right-header text-center ${is_active_class('unread') ? 'c-green-border-50' : ''}`}
                                onClick={() => {
                                    dispatch(
                                        leftPanelFilters({
                                            main_key: "unread_filter",
                                            filter_key: 'unread',
                                            title: "unread_filter"
                                        })
                                    )
                                }}
                            >
                                <div className="align-item-center bg-white-05 d-flex flex-column h60w60 h-100 justify-content-center m-auto position-relative">
                                    <div><ChatCircle size={18} weight="light" className="c-icons" /></div>
                                    <div className='c-font f-10 mt-1'>{_l("l_unread")}</div>
                                    <span class={`rounded-pill badge-custom d-flex ${taskCount.unread_comment_count && taskCount.unread_comment_count > 0 ? "" : "d-none"} `}>{taskCount.unread_comment_count}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='daily-schedule-main my-0'>
                <div className={`lock-screen-main-header position-sticky top-0 z-index-7 ${isBackdrop ? "comman-backdrop-search" : ""}`}>
                    <div className="d-flex lock-screen-slider p-10 radius_3 mb-2 d-none">
                        <div className="progress-slider flex-grow-1 pe-3 mt-auto">
                            <div className='c-font f-12'>{_l("l_space_score")}</div>
                            <div className="d-flex align-items-center">
                                <div className="progress">
                                    <div className="progress-bar" style={{ width: `${(lockTaskCounter ? lockTaskCounter.data.task_progress : 0)}%` }}></div>
                                </div>
                                <div className="c-font f-12 ms-2 title-fonts">{lockTaskCounter ? lockTaskCounter.data.task_progress : 0}%</div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className='align-item-center bg-white-05 d-flex flex-grow-1 justify-content-center p-10 py-1 radius_3 text-nowrap on-hover-bg-white-05'>
                                <div className=" d-flex align-items-center with_separator_10 ps-0">
                                    <a href="#/" className='pe-3' onClick={() => { navigate("/MyPeople"); }}>
                                        <DesktopTower size={18} weight="light" className="c-icons" />
                                        <span className='px-2 title-fonts text-truncate d-none'>On Office</span>
                                    </a>
                                    <div className="position-relative  assignee-list-wrapper card-view-less-padding d-flex justify-content-center">
                                        <div className="multi_inner_wrapper with-min-width d-flex with_minus justify-content-start">
                                            <a href="#/" className="comman-image-box  h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding">
                                                <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url(${User_2})` }}></div>
                                            </a>
                                            <a href="#/" className="comman-image-box  h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding">
                                                <div className="comman-image-box h20w20 with_overlay d-flex rounded-circle">
                                                    <UncontrolledDropdown className="comman-image-box h20w20 with_overlay d-flex rounded-circle">
                                                        <DropdownToggle tag="a" className="comman-image-box h20w20 with_overlay d-flex rounded-circle dropdown team-list-dropdown z-index-2" >
                                                            <div className="unread_count m-auto"
                                                                type="button"
                                                                id="GeneralListing"
                                                                data-bs-toggle="dropdown"
                                                                data-bs-auto-close="outside"
                                                                aria-expanded="false">
                                                                +2
                                                            </div>
                                                            <UncontrolledGeneralListing list={[1, 2, 3].map((index) => {
                                                                return {
                                                                    name: "User name",
                                                                    image: 'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
                                                                    id: index,
                                                                };
                                                            })} />
                                                        </DropdownToggle>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className=" d-flex align-items-center with_separator_10">
                                    <a href="#/" className='pe-3' onClick={() => { navigate("/MyPeople"); }}>
                                        <VideoCamera size={18} weight="light" className="c-icons" />
                                        <span className='px-2 title-fonts text-truncate d-none'>Remote</span>
                                    </a>
                                    <div className="position-relative  assignee-list-wrapper card-view-less-padding d-flex justify-content-center">
                                        <div className="multi_inner_wrapper with-min-width d-flex with_minus justify-content-start">
                                            <a href="#/" className="comman-image-box  h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding">
                                                <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url(${User_2})` }}></div>
                                            </a>
                                            <a href="#/" className="comman-image-box  h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding">
                                                <div className="comman-image-box h20w20 with_overlay d-flex rounded-circle">
                                                    <UncontrolledDropdown className="comman-image-box h20w20 with_overlay d-flex rounded-circle">
                                                        <DropdownToggle tag="a" className="comman-image-box h20w20 with_overlay d-flex rounded-circle dropdown team-list-dropdown z-index-2" >
                                                            <div className="unread_count m-auto"
                                                                type="button"
                                                                id="GeneralListing"
                                                                data-bs-toggle="dropdown"
                                                                data-bs-auto-close="outside"
                                                                aria-expanded="false">
                                                                +2
                                                            </div>
                                                            <UncontrolledGeneralListing list={[1, 2, 3].map((index) => {
                                                                return {
                                                                    name: "User name",
                                                                    image: 'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
                                                                    id: index,
                                                                };
                                                            })} />
                                                        </DropdownToggle>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className=" d-flex align-items-center with_separator_10 d-none">
                                    <a href="#/" className='pe-3' onClick={() => { navigate("/MyPeople"); }}>
                                        <House size={18} weight="light" className="c-icons" />
                                        <span className='px-2 title-fonts text-truncate d-none'>{_l("l_home")}</span>
                                    </a>
                                    <div className="position-relative  assignee-list-wrapper card-view-less-padding d-flex justify-content-center">
                                        <div className="multi_inner_wrapper with-min-width d-flex with_minus justify-content-start">
                                            <a href="#/" className="comman-image-box  h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding">
                                                <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url(${User_2})` }}></div>
                                            </a>
                                            <a href="#/" className="comman-image-box  h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding">
                                                <div className="comman-image-box h20w20 with_overlay d-flex rounded-circle">
                                                    <UncontrolledDropdown className="comman-image-box h20w20 with_overlay d-flex rounded-circle">
                                                        <DropdownToggle tag="a" className="comman-image-box h20w20 with_overlay d-flex rounded-circle dropdown team-list-dropdown z-index-2" >
                                                            <div className="unread_count m-auto"
                                                                type="button"
                                                                id="GeneralListing"
                                                                data-bs-toggle="dropdown"
                                                                data-bs-auto-close="outside"
                                                                aria-expanded="false">
                                                                +2
                                                            </div>
                                                            <UncontrolledGeneralListing list={[1, 2, 3].map((index) => {
                                                                return {
                                                                    name: "User name",
                                                                    image: 'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
                                                                    id: index,
                                                                };
                                                            })} />
                                                        </DropdownToggle>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className=" d-flex align-items-center with_separator_10 pe-0">
                                    <a href="#/" className='pe-3' onClick={() => { navigate("/MyPeople"); }}>
                                        <Umbrella size={18} weight="light" className="c-icons" />
                                        <span className='px-2 title-fonts text-truncate d-none'>Leave</span>
                                    </a>
                                    <div className="position-relative  assignee-list-wrapper card-view-less-padding d-flex justify-content-center">
                                        <div className="multi_inner_wrapper with-min-width d-flex with_minus justify-content-start">
                                            <a href="#/" className="comman-image-box  h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding">
                                                <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url(${User_2})` }}></div>
                                            </a>
                                            <a href="#/" className="comman-image-box  h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding">
                                                <div className="comman-image-box h20w20 with_overlay d-flex rounded-circle">
                                                    <UncontrolledDropdown className="comman-image-box h20w20 with_overlay d-flex rounded-circle">
                                                        <DropdownToggle tag="a" className="comman-image-box h20w20 with_overlay d-flex rounded-circle dropdown team-list-dropdown z-index-2" >
                                                            <div className="unread_count m-auto"
                                                                type="button"
                                                                id="GeneralListing"
                                                                data-bs-toggle="dropdown"
                                                                data-bs-auto-close="outside"
                                                                aria-expanded="false">
                                                                +2
                                                            </div>
                                                            <UncontrolledGeneralListing list={[1, 2, 3].map((index) => {
                                                                return {
                                                                    name: "User name",
                                                                    image: 'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
                                                                    id: index,
                                                                };
                                                            })} />
                                                        </DropdownToggle>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href="#/" className={` bg-white-05 p-10 radius_3 min-width-125 py-1 align-item-center d-flex d-none ${displayAttendance == true && (get_attendance !== undefined && Object.keys(get_attendance) && Object.keys(get_attendance).length > 0) ? "operator_user" : ""}`} onClick={() => {
                                setDisplayAttendance(!displayAttendance)
                                setSelectedTab("start-schedule")
                            }} >
                                <DesktopTower size={18} weight="light" className="c-icons m-0 my-auto" />
                                <span className='ps-2 title-fonts my-auto'>{displayAttendance == true ? "My status" : "My task"}</span>
                            </a>
                        </div>
                    </div>
                    <Calendar
                        projectId={projectId}
                        clearStatusFilter={() => { }}
                        setTaskModalClose={() => { }}
                        pageName={pageName}
                    />
                    {/* <div className='form-control border-0 my-2 p-10'>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-start w100minus100per'>
                                <div className='task-creator-icon'>
                                    <div className='h25w25 comman-round-box with-bg rounded-circle bg-white-05'>
                                        <div className='comman-bg-img h-100 w-100 bg-style-cover rounded-circle' style={{ backgroundImage: `url(${User_2})` }}>

                                        </div>
                                    </div>
                                </div>
                                <div className="checklist-list flex-grow-1 line-height-1">
                                    <textarea className="w-100 bg-transparent border-0 checklist_textarea pt-1 ps-2 pe-0"
                                        rows={1}
                                        value={taskTitle}
                                        placeholder='Hello Emily, How can I help you ?'
                                        onChange={(e) => { setTaskTitle(e.target.value) }}
                                    />
                                </div >
                            </div>

                            <div className='d-flex d-flex align-items-start comman_action_icon'>
                                <div className='d-flex d-flex align-items-start'>
                                    <div className='dropdown me-2'>
                                        <a href="#/" className='action_icon with_bg justify-content-center align-items-center'
                                            type="button"
                                            data-tip={`${_l("l_all_spaces")}`}
                                            data-effect="solid"
                                            data-class="tooltip-main"
                                            id="SapceListMainDropdown"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            data-bs-auto-close="outside"
                                            onClick={() => {
                                                setOpenSpaceListDropdown(true);
                                            }}>
                                            <div className='on-hover-active-toggle-img text-on-hover-active-green'>
                                                <MapPin size={16} className="c-icons" weight="light" />
                                            </div>
                                        </a>
                                        <SpaceListDropdown
                                            openSpaceListDropdown={openSpaceListDropdown}
                                            SpaceList={spaceState}
                                            setOpenSpaceListDropdown={setOpenSpaceListDropdown}
                                            handler={setSelectedSpace}
                                            selectedSpace={selectedSpace}
                                        />
                                    </div>
                                    <a href="#/"
                                      className="bg-white-05 c-font custom-datepicker f-11 me-2 white-border-box" style={{width: "88px"}}
                                    >
                                      <div className="d-flex align-items-center">
                                        <CalendarBlank size={18}  weight="light" className="c-icons opacity-60" />
                                        <DatePicker
                                          selected={startDate}
                                          onChange={(date) => {
                                            setStartDate(date)
                                          }}
                                          dateFormat="dd/MM/yyyy"
                                          selectsStart
                                        />
                                      </div>
                                    </a>
                                    <div className={`dropdown me-2 ${Object.keys(selectedSpace).length > 0 ? "" : "for-disabled"}`}>
                                        <a href="#/" className='action_icon with_bg justify-content-center align-items-center'
                                            id="AddAssigneeMainDropdown11"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            data-bs-auto-close="outside"
                                            type="button"
                                             onClick={() => {
                                              setOpenAssigneeDropdown(true);
                                            }}>
                                            <div className='on-hover-active-toggle-img text-on-hover-active-green'>
                                                <UserCircleGear size={16} className="c-icons" weight="light" />
                                            </div>
                                        </a>
                                        <AddAssigneeMainDropdown
                                            projectId={selectedSpace.project_id}
                                            // key={selectedSpace.project_id}
                                            openAssigneeDropdown={openAssigneeDropdown}
                                            setOpenAssigneeDropdown={setOpenAssigneeDropdown}
                                            pageName={pageName}
                                            handleProvider={handleRequestAssignee}
                                            setDropDownToggle={setDropDownToggle}
                                            selectedId={selectedAssigneeIds}
                                            dropDownToggle={dropDownToggle}      
                                        />
                                    </div>
                                    <div className="comman_action_icon me-2">
                                        <div className="white-border-box color-white-60 action_icon with_bg  dropdown d-flex" type="button"
                                            id="TaskListStatusDropdown"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            data-tip={`${_l("l_task_status")}`}
                                            data-effect="solid"
                                            data-class="tooltip-main"
                                        >
                                            <div className="d-flex align-items-center m-auto">
                                                <span
                                                    className={`c-dots c-10 ${taskStatus == 5
                                                        ? "done"
                                                        : taskStatus == 1
                                                            ? "in-progress-white"
                                                            : taskStatus == 4
                                                                ? "in-progress-blue"
                                                                : "bg-warning"
                                                        } rounded-circle`}
                                                ></span>
                                                <TaskListStatusDropdown
                                                    setTaskStatus={changeTaskStatus}
                                                    is_liteversion={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`comman_action_icon  me-2`}
                                    >
                                        <div className="white-border-box d-flex align-items-center h25w25 action_icon with_bg border-0">
                                            <div
                                                className="priority-checkbox text_wrap position-relative"
                                                data-tip={`${_l("l_priority")}`}
                                                data-effect="solid"
                                                data-class="tooltip-main"
                                            >
                                                <input
                                                    type="checkbox"
                                                    className="absolute-input"
                                                    id="priority"
                                                    checked={taskPriority == 3 ? true : false}
                                                    onChange={(e) => { setTaskPriority(taskPriority == 3 ? 2 : 3) }}
                                                />
                                                <div className="check_box" >
                                                    <HighPriorityActiveIcon className="HW16 check_span_img active" />
                                                    <HighPriorityIcon className="HW16 check_span_img inactive opacity-50" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`dropend d-flex align-items-center pe-2`}
                                    >
                                        <UncontrolledDropdown className="w-100 d-flex">
                                            <DropdownToggle data-toggle="dropdown" tag="span" className='ps-0'>
                                                <a href="#/"
                                                    className="comman_action_icon"
                                                    type="button"
                                                    id="writeComMentDropdown"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                    data-bs-auto-close="outside"
                                                    data-tip={_l("l_comments")}
                                                    data-effect="solid"
                                                    data-class="tooltip-main"
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <div className="action_icon with_bg position-relative on-hover-active-toggle-img">
                                                            <ChatCircle size={16} weight="light" className="c-icons" />
                                                        </div>
                                                        <span
                                                            className={`rounded-pill badge-custom p-0 d-none`}
                                                        >
                                                            0
                                                        </span>
                                                        <span className="c-font f-12 opacity-50 text-on-hover-active-green number-counter-toggle d-none">
                                                            0
                                                        </span>
                                                    </div>
                                                </a>
                                            </DropdownToggle>
                                            <DropdownItem toggle={false} className="p-0">
                                                <CommentMinified
                                                    handleAddComment={handleAddEditComment}
                                                    id={""}
                                                    task={""}
                                                    handleImagePreview={""}
                                                    unContolList={true}
                                                    comments={commentsList}
                                                    deleteComment={handleDeleteComment}
                                                    is_liteversion={true}
                                                />
                                            </DropdownItem>
                                        </UncontrolledDropdown>
                                    </div>
                                    <a href="#/" className='action_icon with_bg justify-content-center align-items-center me-2'
                                        onClick={() => { setTaskPinned(!taskPinned) }}>
                                        <div className='on-hover-active-toggle-img text-on-hover-active-green'>
                                            {taskPinned ?
                                                <PushPin size={16} className="c-icons" weight="fill" color="#00ff6a" />
                                                :
                                                <PushPin size={16} className="m-auto" weight="light" />}
                                        </div>
                                    </a>
                                    <div className={`${loder || (Object.keys(selectedSpace).length < 1)  ? "for-disabled" : ""}`}>
                                    <Button variant="primary" size="sm" onClick={() => { handelCreateTask() }}>
                                      {loder
                                        ? _l('l_please_wait')
                                        : _l('l_create')
                                      }
                                      {
                                        loder ? (
                                          <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            className={`ms-2 `}
                                            aria-hidden="true"
                                          />
                                        ) :
                                          <></>
                                      }
                                    </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
            
                </div>
            </div>
        </>
    )
}

export default LockScreenHeader