import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {_l } from "../../hooks/utilities";
import benchmarkServices from "../../services/benchmark-services";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { Form } from "react-bootstrap";
const EstimatereviewModal = ({ show, handleClose, proposalId }) => {

  const [proposalEstimate, setProposalEstimate] = useState({});

  useEffect(() => {
    if (proposalId > 0) {
      benchmarkServices.getEstimateUsingProposal(proposalId).then((res) => {
        if (res.status && res.data && !Array.isArray(res.data)) {
          setProposalEstimate(res.data);
        }
      })
    }
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l('l_estimate_preview')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          {
            proposalEstimate ?
              <div className="form-wrapper-main">
                <div className="d-flex justify-content-between">
                  <div className="">
                    <div className="fw-semibold c-font f-14 text-truncate pe-2 title-fonts">
                      {_l('l_provider')}:
                    </div>
                    <div className="fw-semibold c-font f-14 text-truncate pe-2">
                      {proposalEstimate.provider_name}
                    </div>
                  </div>
                  <div className="text-right">
                    <div className="fw-semibold c-font f-14 text-truncate pe-2 title-fonts">
                      {_l('l_customer')}:
                    </div>
                    <div className="fw-semibold c-font f-14 text-truncate pe-2">
                      {proposalEstimate.company_name}
                    </div>
                  </div>
                </div>
                <div className=" d-flex flex-column pt-3">
                  <div className="comman-content-scroll-wrapper">
                    <div className="comman-content-scroll">
                      <div className="comman-data-table">
                        <table className="dataTable">
                          <thead className="bg-transparent">
                            <tr className="bg-white-03">
                              <th style={{ width: "3%" }}>#</th>
                              <th>{_l('l_item')}</th>
                              <th style={{ width: "7%" }}>{_l('l_quantity')}</th>
                              <th style={{ width: "8%" }}>{_l('l_tax')} (%)</th>
                              <th style={{ width: "8%" }}>{_l('l_amount')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              proposalEstimate.items && proposalEstimate.items.length && proposalEstimate.items.map((es, k) => {
                                return (
                                  <tr key={k}>
                                    <td className="border-bottom align-top">{k + 1}</td>
                                    <td className="max-width-100 text-truncate border-bottom">
                                      <div className="">
                                        {es.description}
                                      </div>
                                    </td>
                                    <td className="border-bottom align-top">{es.quantity}</td>
                                    <td className="border-bottom align-top">{es.tax_rate}</td>
                                    <td className="border-bottom align-top">{es.amount}</td>
                                  </tr>
                                )
                              })
                            }
                            <tr>
                              <td
                                colSpan={4}
                                className="text-end border-bottom "
                              >
                               {_l('l_sub_total')}
                              </td>
                              <td className="border-bottom">{proposalEstimate.subtotal}</td>
                            </tr>
                            <tr>
                              <td
                                colSpan={4}
                                className="text-end border-bottom  "
                              >
                                {proposalEstimate.default_tax}
                              </td>
                              <td className="border-bottom">{proposalEstimate.tax_amount}</td>
                            </tr>
                            <tr>
                              <td colSpan={4} className="text-end color-green ">
                              {_l('l_total')}
                              </td>
                              <td className="fw-semibold  color-green">{proposalEstimate.total}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Unit Task Estimate Start */}
                <div className=" d-flex flex-column pt-3">
                  <div className="comman-content-scroll-wrapper">
                    <div className="comman-content-scroll">
                      <div className="comman-data-table">
                      {proposalEstimate.unit_task && proposalEstimate.unit_task.length ? (
                          <React.Fragment>
                            <div className="d-flex align-items-center justify-content-between pt-30px pb-10px bg-white-03-solid position-sticky top-0 z-index-5 light-theme-grey-light-bg">
                              <div className="title-fonts fw-semibold">
                                {_l("l_bpu_title")}
                              </div>
                            </div>
                            <div className="comman-data-table small-data-table">
                              <table
                                className="dataTable  title-fonts text-center"
                                width="100%"
                              >
                                <thead className="" style={{ top: "54px" }}>
                                  <tr className="">
                                    <th
                                      style={{ width: "40px", padding: "5px" }}
                                    >
                                      {_l("l_id")}
                                    </th>
                                    <th className="text-start">{`${_l(
                                      "l_bpu_title"
                                    )} ${_l("l_name")}`}</th>
                                    <th style={{ width: "70px" }}>
                                      {_l("l_monthly")}
                                    </th>
                                    <th style={{ width: "70px" }}>
                                      {_l("l_quarterly")}
                                    </th>
                                    <th style={{ width: "70px" }}>
                                      {_l("l_half_yearly")}
                                    </th>
                                    <th style={{ width: "70px" }}>
                                      {_l("l_yearly")}
                                    </th>
                                    <th style={{ width: "70px" }}>
                                      {_l("l_proposal_amount")}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {proposalEstimate.unit_task.map((unit, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td className="text-start">
                                          {unit.name}
                                        </td>
                                        <td className="text-center ">
                                          <label for="" className="">
                                            <input
                                              className="form-check-input "
                                              type="checkbox"
                                              checked={unit.monthly}
                                              disabled={true}
                                            />
                                          </label>
                                        </td>{" "}
                                        <td className="text-center ">
                                          <label for="" className="">
                                            <input
                                              className="form-check-input "
                                              type="checkbox"
                                              checked={unit.quarterly}
                                              disabled={true}
                                            />
                                          </label>
                                        </td>{" "}
                                        <td className="text-center ">
                                          <label for="" className="">
                                            <input
                                              className="form-check-input "
                                              type="checkbox"
                                              checked={unit.half_yearly}
                                              disabled={true}
                                            />
                                          </label>
                                        </td>{" "}
                                        <td className="text-center ">
                                          <label for="" className="">
                                            <input
                                              className="form-check-input "
                                              type="checkbox"
                                              checked={unit.yearly}
                                              disabled={true}
                                            />
                                          </label>
                                        </td>{" "}
                                        <td>
                                          <Form.Control
                                            className="p-1 title-fonts border"
                                            type="text"
                                            placeholder={_l("l_add_price")}
                                            value={`${unit.amount}`}
                                            disabled
                                          />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Unit Task Estimate End */}
              </div>
              :
              <CommanPlaceholder imgType="todo-task" placeholderText = {_l("l_no_items_found")} />
          }
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="terms-condition">
                  <div className="fw-semibold pb-2">{_l("l_terms_conditions")}</div>
                  <div className="color-white-60 py-2">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: proposalEstimate.terms,
                      }}
                    ></span>
                      {/* {estimateTerms || _l("l_estimate_terms_condition")} */}
                  </div>
                </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EstimatereviewModal;
