import React from 'react';
// import CSS from '../css/icon_main.css';

function NoTimeslots(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>


			<svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons w-100 h-100 ${CommanHW}`}>
				<path d="M12.373 65.6271L65.6271 12.373" strokeMiterlimit="10" />
				<path d="M39 39L51 52.5" strokeMiterlimit="10" />
				<path d="M39 39L39 18" strokeMiterlimit="10" />
				<path d="M39 14V10" strokeMiterlimit="10" />
				<path d="M39 68V64" strokeMiterlimit="10" />
				<path d="M14 39L10 39" strokeMiterlimit="10" />
				<path d="M68 39L64 39" strokeMiterlimit="10" />
				<path d="M39 76.6562C59.797 76.6562 76.6562 59.797 76.6562 39C76.6562 18.203 59.797 1.34375 39 1.34375C18.203 1.34375 1.34375 18.203 1.34375 39C1.34375 59.797 18.203 76.6562 39 76.6562Z" strokeMiterlimit="10" />
				<path d="M39 68C55.0163 68 68 55.0163 68 39C68 22.9837 55.0163 10 39 10C22.9837 10 10 22.9837 10 39C10 55.0163 22.9837 68 39 68Z" strokeMiterlimit="10" />
			</svg>

		</React.Fragment>
	)
}

export default NoTimeslots