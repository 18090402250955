import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { _l , getDatePickerLocale } from "../../hooks/utilities";
import GoogleCalendar from "../../services/google-calendar";
import { useSelector, useDispatch } from "react-redux";
import Toasts from "../Toasts/Toasts";
import { useNavigate } from "react-router-dom";
import outlookCalendar from "../../services/outlook-calendar";
import ProgressBarSmallLoader from "../Loader/ProgressBarSmallLoader";
import { CalendarBlank } from "phosphor-react";
import DatePicker from "react-datepicker";
import {SET_NEW_MEETING} from "../../actions/action-type";
import CustomGoogleMicrosoftMeetIcon from "../../assets/icons/CustomGoogleMicrosoftMeetIcon";
import CustomGoogleMeetIcon from "../../assets/icons/CustomGoogleMeetIcon";

const MeetingProviderModal = ({ meetingProvider, HandleClose , selectedTask, commentSection, newRequestFlag, newTaskFlag}) => {
  const dispatch = useDispatch();
  const [toastClass, setToastClass] = useState("unsucess");
  const [header, setHeader] = useState("");
  const [message, setMessage] = useState("");
  const [validationFalse, setValidationFalse] = useState(false);
  const [revoke_token, setRevokeToken] = useState("");
  const [revoke, setRevoke] = useState();
  const projectId = localStorage.getItem("selectedOffice");
  const rel_id =
    localStorage.getItem("user_type") === "staff"
      ? localStorage.getItem("staff_id")
      : localStorage.getItem("client_id");
  const calendar_type = localStorage.getItem('calendar_type');
  const [showModal, setShowModal] = useState();

  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());

  const [selectSlack, setSelectSlack] = useState(false);
  const [selectDiscord, setSelectDiscord] = useState(false);
  const [radioBtnType, setRadioBtnType] = useState(0);
  const [provider, setProvider] = useState("");
  const outlook_calendar  = useSelector((state) => state.discord_auth.outlook_calendar_connectivity);
  const getMeetUrl  = useSelector((state) => state.discord_auth.getMeetUrl);
  const [isOutlookCalendarActive, setIsOutlookCalendarActive] = useState(false);
  const googlecalendar_connectivity = useSelector(
    (state) => state.google_calendar.googlecalendar_connectivity
  );
  useEffect(() => {
    setDate(selectedTask && selectedTask.duedate ? new Date(selectedTask.duedate) : new Date());
  }, [selectedTask])
  function dateConverter(str){
    var date = new Date(str);
    let mnth = ("0" + (date.getMonth()+1)).slice(-2);
    let day  = ("0" + date.getDate()).slice(-2);
    let hours  = ("0" + date.getHours()).slice(-2);
    let minutes = ("0" + date.getMinutes()).slice(-2) ? ("0" + date.getMinutes()).slice(-2) : '00';
    let seconds  = ("0" + date.getSeconds()).slice(-2) ? ("0" + date.getSeconds()).slice(-2) : '00';
    let year = date.getFullYear();
    return `${day}/${mnth}/${year} ${hours}:${minutes}`;
 }
  useEffect(() => {
    if(outlook_calendar != undefined)
    {
      if(outlook_calendar.status == 1)
      {
        setIsOutlookCalendarActive(true)
      }
    }
  }, [outlook_calendar])
  
  useEffect(() => {
    if(getMeetUrl && getMeetUrl != [])
    {
      if(getMeetUrl.data && getMeetUrl.data.length > 0 && getMeetUrl.data.data && getMeetUrl.data.data.google_meeting_link && getMeetUrl.data.data.task_id)
      {
          if((commentSection && commentSection == 1) || (newTaskFlag && newTaskFlag == 1) || (newRequestFlag && newRequestFlag == 1))
              {
                let data= [];
                if(newTaskFlag == 1)
                {
                  data = {
                    link: getMeetUrl.data.data.google_meeting_link,
                    newTaskFlag: 1,
                    newRequestFlag: 0,
                    task_id: getMeetUrl.data.data.task_id
                  };
                } else if(newRequestFlag == 1)
                {
                  data = {
                    link: getMeetUrl.data.data.google_meeting_link,
                    newTaskFlag: 0,
                    newRequestFlag: 1,
                    task_id: getMeetUrl.data.data.task_id
                  };
                } else if(commentSection == 1)
                {
                  data = {
                    link: getMeetUrl.data.data.google_meeting_link,
                    newTaskFlag: 0,
                    newRequestFlag: 0,
                    task_id: getMeetUrl.data.data.task_id
                  };
                }
                dispatch({
                  type: SET_NEW_MEETING,
                  payload: { data },
                });
                closeModal();
              }
              else if(isOutlookCalendarActive == true)
            {
              outlookCalendar.googleMeetingLinkAddedIntoTask(getMeetUrl.data.data.task_id, getMeetUrl.data.data.google_meeting_link, selectedTask.calendar_id,projectId).then((pes) => {
                if(pes.status == 1)
                {
                  localStorage.setItem('join_meeting',JSON.stringify(pes.data.meeting_url));
                  window.dispatchEvent(new Event('join_meeting'));
                }
                localStorage.setItem('selectedTaskId', JSON.stringify(getMeetUrl.data.data.task_id));
                window.dispatchEvent(new Event('selectedTaskId'));
                setProvider("");
                closeModal();
              })
            } else if(googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type == 2 )
            {
              if(selectedTask != "")
              {
              GoogleCalendar.create_meeting(selectedTask.id, selectedTask.calendar_id).then((res)=>{
                
                if(res.status==1)
                {
                  localStorage.setItem('join_meeting',JSON.stringify(res.data.meeting_url));
                  window.dispatchEvent(new Event('join_meeting'));
                  
                  localStorage.setItem('selectedTaskId', JSON.stringify(selectedTask.id));
                  window.dispatchEvent(new Event('selectedTaskId'));
                  setProvider("");
                  closeModal();
                }
              })
            } 
          }
      } else if(getMeetUrl.data && getMeetUrl.data.length > 0 && getMeetUrl.data.data && getMeetUrl.data.data.ms_meeting_link)
      {

        if((commentSection && commentSection == 1) || (newTaskFlag && newTaskFlag == 1) || (newRequestFlag && newRequestFlag == 1))
              {
                let data= [];
                if(newTaskFlag == 1)
                {
                  data = {
                    link: getMeetUrl.data.data.ms_meeting_link,
                    newTaskFlag: 1,
                    newRequestFlag: 0,
                    task_id: getMeetUrl.data.data.task_id
                  };
                } else if(newRequestFlag == 1)
                {
                  data = {
                    link: getMeetUrl.data.data.ms_meeting_link,
                    newTaskFlag: 0,
                    newRequestFlag: 1,
                    task_id: getMeetUrl.data.data.task_id
                  };
                } else if(commentSection == 1)
                {
                  data = {
                    link: getMeetUrl.data.data.ms_meeting_link,
                    newTaskFlag: 0,
                    newRequestFlag: 0,
                    task_id: getMeetUrl.data.data.task_id
                  };
                }
                dispatch({
                  type: SET_NEW_MEETING,
                  payload: { data },
                });
                closeModal();
              }
        else if(isOutlookCalendarActive == true)
        {
          outlookCalendar.MsTeamMeetingLinkAddedIntoTask(selectedTask.id,getMeetUrl.data.data.ms_meeting_link, selectedTask.calendar_id, projectId).then((pes) => {
            if(pes.status == 1)
            {
              localStorage.setItem('join_meeting',JSON.stringify(pes.data.meeting_url));
              window.dispatchEvent(new Event('join_meeting'));
              setProvider("");
              closeModal();
            }
            localStorage.setItem('selectedTaskId', JSON.stringify(selectedTask.id));
            window.dispatchEvent(new Event('selectedTaskId'));
          })
        } else if(googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type == 2 )
        {
          GoogleCalendar.MsTeamMeetingLinkAddedIntoTask(selectedTask.id,getMeetUrl.data.data.ms_meeting_link, selectedTask.calendar_id).then((pes) => {
            if(pes.status == 1)
            {
              localStorage.setItem('join_meeting',JSON.stringify(pes.data.meeting_url));
              window.dispatchEvent(new Event('join_meeting'));
              setProvider("");
              closeModal();
            }
            localStorage.setItem('selectedTaskId', JSON.stringify(selectedTask.id));
            window.dispatchEvent(new Event('selectedTaskId'));
          })
        }
      }
    }
  }, [getMeetUrl])
  const closeModal = () => {
    setShowModal(HandleClose);
    localStorage.setItem('meetingProviderModalClose', JSON.stringify(false));
    window.dispatchEvent(new Event('meetingProviderModalClose'));
  };
  // window.addEventListener('google_meeting' , (detail) => {
  //   setProvider("");
  // });
  // window.addEventListener('ms_meeting' , (detail) => {
  //   setProvider("");
    
  // });
  window.addEventListener('outlook_calendar' , (detail) => {
    setIsOutlookCalendarActive(true);
  });
  const createMeetWithTask = (key) => {
    if(key == 'ms_meet')
    {
      setProvider(key)
      outlookCalendar.oauthUrl('ms_meeting', 0, projectId, 0, date).then((res) => {
        if(res.message == "token_exist")
          {
            let data= [];
            if(newRequestFlag == 1)
            {
              data= {
                link: res.data.meeting_link,
                newTaskFlag: 1,
                newRequestFlag: 1
              };
            } else {
              data= {
                link: res.data.meeting_link,
                newTaskFlag: 1,
                newRequestFlag: 0
              };
            }
            dispatch({
              type: SET_NEW_MEETING,
              payload: { data },
            });
            closeModal();
          }else if(res.message = "oauth_url") {
            var left = (window.screen.width - 600) /2;
            var top = (window.screen.height - 600) / 4;
            // window.location.href = res.data;
            window.open( res.data, "center window", 'resizable = yes, width=600, height=600, top='+ top + ', left=' + left);
          }
      })
    }
    if(key == "google_meet")
    {
      setProvider(key)
      outlookCalendar.oauthUrlGoogleMeet(0, projectId, date).then((res) => {
        if(res.message == "access_token_exists")
        {
          let data = [];
          if(newRequestFlag == 1)
            {
              data= {
                link: res.data.meeting_link,
                newTaskFlag: 1,
                newRequestFlag: 1
              };
            } else {
              data= {
                link: res.data.meeting_link,
                newTaskFlag: 1,
                newRequestFlag: 0
              };
            }
            dispatch({
              type: SET_NEW_MEETING,
              payload: { data },
            });
            closeModal();
        } else {
          var left = (window.screen.width - 600) /2;
            var top = (window.screen.height - 600) / 4;
            // window.location.href = res.data;
            window.open( res.data, "center window", 'resizable = yes, width=600, height=600, top='+ top + ', left=' + left);
        }
    })
    }
  }
  const createMeeting = (task, key) => {
   
    if(key == "ms_meet")
    {
        setProvider(key)
      if(task != "")
      {
          outlookCalendar.oauthUrl('ms_meeting', task.id, projectId).then((res) => {
            if(res.message == "token_exist")
            { 
              if(commentSection && commentSection == 1)
              {
                let data= {
                  link: res.data.meeting_link,
                  newTaskFlag: 0,
                  newRequestFlag: 0,
                  task_id: task.id
                };
                dispatch({
                  type: SET_NEW_MEETING,
                  payload: { data },
                });
                closeModal();
              }
              else if(isOutlookCalendarActive == true && commentSection == 0)
              {
                outlookCalendar.MsTeamMeetingLinkAddedIntoTask(task.id,res.data.meeting_link, task.calendar_id, projectId).then((pes) => {
                  if(pes.status == 1)
                  {
                    localStorage.setItem('join_meeting',JSON.stringify(pes.data.meeting_url));
                    window.dispatchEvent(new Event('join_meeting'));
                    closeModal();
                  }
                  localStorage.setItem('selectedTaskId', JSON.stringify(task.id));
                  window.dispatchEvent(new Event('selectedTaskId'));
                })
              } else if(googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type == 2 && commentSection == 0)
              {
                GoogleCalendar.MsTeamMeetingLinkAddedIntoTask(task.id,res.data.meeting_link, task.calendar_id).then((pes) => {
                  if(pes.status == 1)
                  {
                    localStorage.setItem('join_meeting',JSON.stringify(pes.data.meeting_url));
                    window.dispatchEvent(new Event('join_meeting'));
                    closeModal();
                  }
                  localStorage.setItem('selectedTaskId', JSON.stringify(task.id));
                  window.dispatchEvent(new Event('selectedTaskId'));
                })
              }
            } else if(res.message = "oauth_url") {
              var left = (window.screen.width - 600) /2;
              var top = (window.screen.height - 600) / 4;
              // window.location.href = res.data;
              window.open( res.data, "center window", 'resizable = yes, width=600, height=600, top='+ top + ', left=' + left);
            }
          })
      }
    } else
    if(key == "zoom_meet")
    { 
        setProvider(key)
      if(task != "")
        {
          outlookCalendar.oauthUrlZoom(task.id).then((res) => {
            if(res.message == "oauth_url")
            {
              var left = (window.screen.width - 600) /2;
              var top = (window.screen.height - 600) / 4;
              // window.location.href = res.data;
              window.open( res.data, "center window", 'resizable = yes, width=600, height=600, top='+ top + ', left=' + left);
            } else if(res.message == "access_token_exists")
            {
              if(isOutlookCalendarActive == true)
              {
                outlookCalendar.zoomMeetingLinkAddedIntoTask(task.id,res.data.meeting_link, task.calendar_id).then((pes) => {
                  if(pes.status == 1)
                  {
                    localStorage.setItem('join_meeting',JSON.stringify(pes.data.meeting_url));
                    window.dispatchEvent(new Event('join_meeting'));
                  }
                  localStorage.setItem('selectedTaskId', JSON.stringify(task.id));
                  window.dispatchEvent(new Event('selectedTaskId'));
                  closeModal();
                })
              } else if(googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type == 2 )
              {
                GoogleCalendar.zoomMeetingLinkAddedIntoTask(task.id,res.data.meeting_link,task.calendar_id).then((pes) => {
                  if(pes.status == 1)
                  {
                    localStorage.setItem('join_meeting',JSON.stringify(pes.data.TP_meeting_link));
                    window.dispatchEvent(new Event('join_meeting'));
                  }
                  localStorage.setItem('selectedTaskId', JSON.stringify(task.id));
                  window.dispatchEvent(new Event('selectedTaskId'));
                  closeModal();
                })
              }
            }
          })
        }
    } else
    if(key == "google_meet")
    {
        setProvider(key)
      if(task != "")
      {
        outlookCalendar.oauthUrlGoogleMeet(task.id, projectId).then((res) => {
          if(res.message == "access_token_exists")
          {
            if(commentSection && commentSection == 1)
              {
                let data= {
                  link: res.data.meeting_link,
                  newTaskFlag: 0,
                  newRequestFlag: 0,
                  task_id: task.id
                };
                dispatch({
                  type: SET_NEW_MEETING,
                  payload: { data },
                });
                closeModal();
              }
              else 
            if(isOutlookCalendarActive == true)
            {
              outlookCalendar.googleMeetingLinkAddedIntoTask(task.id, res.data.meeting_link, task.calendar_id,projectId).then((pes) => {
                if(pes.status == 1)
                {
                  localStorage.setItem('join_meeting',JSON.stringify(pes.data.meeting_url));
                  window.dispatchEvent(new Event('join_meeting'));
                }
                localStorage.setItem('selectedTaskId', JSON.stringify(task.id));
                window.dispatchEvent(new Event('selectedTaskId'));
                closeModal();
              })
            } else if(googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type == 2 )
            {
              if(task != "")
              {
              GoogleCalendar.create_meeting(task.id, task.calendar_id).then((res)=>{
                
                if(res.status==1)
                {
                  localStorage.setItem('join_meeting',JSON.stringify(res.data.meeting_url));
                  window.dispatchEvent(new Event('join_meeting'));
                  
                  localStorage.setItem('selectedTaskId', JSON.stringify(task.id));
                  window.dispatchEvent(new Event('selectedTaskId'));
                  closeModal();
                }
              })
            } 
          }
          } else {
            var left = (window.screen.width - 600) /2;
              var top = (window.screen.height - 600) / 4;
              // window.location.href = res.data;
              window.open( res.data, "center window", 'resizable = yes, width=600, height=600, top='+ top + ', left=' + left);
          }
        })
      }
    }
}
  return (
    <>
      <div id="meetingProviderModal">
        <Modal
          show={meetingProvider}
          onHide={closeModal}
          className="custom-modal-style fade"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>{_l('l_meeting_provider')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className=" pt-0">
            <div className="form-wrapper-main">
              <div className="row">
                
                
                {/* <Form.Group className="col-4 c-input-box pb-3 position-relative">
                  <Form.Label className="d-flex c-font f-13 color-white-60 ">
                    <div className="form-check form-check-inline ">
                                <Button onClick={() =>{
                                  if(selectedTask)
                                  {
                                    createMeeting(selectedTask, 'ms_meet');
                                  } else {
                                    createMeetWithTask('ms_meet');
                                  }
                                                        
                                                        }} variant="secondary">MS Team Meet</Button>
                    </div>
                  </Form.Label>
                </Form.Group> */}
                {/* <Form.Group className="col-3 c-input-box pb-3 position-relative d-none">
                  <Form.Label className="d-flex c-font f-13 color-white-60 ">
                    <div className="form-check form-check-inline ">
                                <Button onClick={() =>{createMeeting(selectedTask, 'zoom_meet'); }} variant="secondary">Zoom Meet</Button>
                    </div>
                  </Form.Label>
                </Form.Group> */}
                {/* <Form.Group className="col-4 c-input-box pb-3 position-relative">
                  <Form.Label className="d-flex c-font f-13 color-white-60 ">
                    <div className="form-check form-check-inline ">
                                <Button onClick={() =>{
                              if(selectedTask)
                              {
                                createMeeting(selectedTask, 'google_meet'); 
                              } else {
                                createMeetWithTask('google_meet');
                              }
                              }} variant="secondary">Google Meet</Button>
                    </div>
                  </Form.Label>
                </Form.Group> */}
              </div>
              
              <div className="border-bottom pb-3 w-100 mb-3">
                  {/* <Form.Label className="input-label no-asterisk title-fonts color-white-60">
                  {_l('select_meeting_provider')} 
                  </Form.Label> */}
                  <div className="col-xl-4">
                      <a href="#/"
                            className="custom-datepicker"
                            data-bs-toggle="tooltip"
                            data-tip={_l('l_due_date')}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-className="tooltip-main"
                          >
                            <div className="d-flex align-items-center form-control  color-white-60">
                            <CalendarBlank size={20} className="c-icons" weight="light" />
                              <DatePicker
                                selected={date}
                                onChange={(date) => {
                                  setDate(date);
                                }}
                                showTimeSelect
                                locale={getDatePickerLocale()}
                                dateFormat="dd-MM-yyyy hh:mm:ss"
                                placeholderText={`${_l("l_duedate")}`}
                                className="c-font f-13"
                                readOnly={
                                  selectedTask
                                    ? parseInt(selectedTask.is_allowed_to_edit) < 1
                                    : false
                                }
                                calendarStartDay={1}
                              ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                            </div>
                          </a>
                    </div>
                  
                  {provider == "zoom_meet" || provider == 'ms_meet' || provider == 'google_meet' ? 
                                    <ProgressBarSmallLoader height={30} width={30} /> : ""}
                </div>
              <div className={`platform-card task-list-card list-view mb-2 p-4 no-hover `}>
                  <div className="d-flex justify-content-between">
                    <div className="h30w30">
                      <CustomGoogleMicrosoftMeetIcon />
                    </div>
                    <div className="w100minus30 ps-4">
                      <div className="title-fonts fw-semibold">{_l("l_microsoft_team_meet")}</div>
                      <div className="c-font f-12 color-white-60">{_l("l_gmail_subtext")}</div>
                      <div className="card-btn-wrapper mt-4">
                        <div className="d-flex align-items-center">
                        <Button size="sm" variant="primary" className="position-relative"
                        onClick={() =>{
                          if(selectedTask)
                          {
                            createMeeting(selectedTask, 'ms_meet');
                          } else {
                            createMeetWithTask('ms_meet');
                          }
                          }}>
                        <input className="absolute-input" name="calendartype"  />
                        {_l("l_microsoft_team_meet")}
                        </Button>
                        </div>
                      </div>  
                    </div>
                  </div>
              </div>
              <div className={`platform-card task-list-card list-view mb-0 p-4 no-hover `}>
                  <div className="d-flex justify-content-between">
                    <div className="h30w30">
                      <CustomGoogleMeetIcon />
                    </div>
                    <div className="w100minus30 ps-4">
                      <div className="title-fonts fw-semibold">{_l("l_google_meet")}</div>
                      <div className="c-font f-12 color-white-60">{_l("l_gmail_subtext")}</div>
                      <div className="card-btn-wrapper mt-4">
                        <div className="d-flex align-items-center">
                        <Button size="sm" variant="primary" className="position-relative"
                        onClick={() =>{
                          if(selectedTask)
                          {
                            createMeeting(selectedTask, 'google_meet'); 
                          } else {
                            createMeetWithTask('google_meet');
                          }
                          }}>
                        <input className="absolute-input" name="calendartype"  />
                          {_l("l_google_meet")}
                        </Button>
                        </div>
                      </div>  
                    </div>
                  </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end pt-0">
            <Button size="sm" variant="secondary" onClick={closeModal}>
            {_l('l_close')}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {validationFalse ? (
        <Toasts
          message={message}
          header={"Third Party Tool"}
          toastClass={toastClass}
          delay={10000}
          handler={() => {
            setValidationFalse(false);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default MeetingProviderModal;
