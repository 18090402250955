import React from 'react';
// import CSS from '../css/icon_main.css';

function ManageTeams(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

			<svg width="82" height="88" viewBox="0 0 82 88" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons h-100 w-100 ${CommanHW}`}>
				<path d="M10.9453 22.7357C19.2897 17.9579 27.6193 13.1444 35.9342 8.29492" strokeMiterlimit="10" />
				<path d="M6.38672 56.3388C6.38672 52.1911 6.38672 48.0404 6.38672 43.8897C6.38672 39.5987 6.40762 35.3106 6.38672 31.0195" strokeMiterlimit="10" />
				<path d="M36.0185 79.4511C27.6407 74.5837 19.253 69.7372 10.8555 64.9116" strokeMiterlimit="10" />
				<path d="M71.1396 64.9175C62.7421 69.747 54.3544 74.5945 45.9766 79.4598" strokeMiterlimit="10" />
				<path d="M75.6264 31.064C75.5985 39.5067 75.5985 47.9494 75.6264 56.3921" strokeMiterlimit="10" />
				<path d="M46.0703 8.29199C54.3773 13.1215 62.6951 17.9302 71.0237 22.7179" strokeMiterlimit="10" />
				<path d="M41.0003 12.0486C44.0165 12.0486 46.4615 9.5753 46.4615 6.52431C46.4615 3.47332 44.0165 1 41.0003 1C37.9841 1 35.5391 3.47332 35.5391 6.52431C35.5391 9.5753 37.9841 12.0486 41.0003 12.0486Z" strokeMiterlimit="10" />
				<path d="M41.0003 86.9998C44.0165 86.9998 46.4615 84.5265 46.4615 81.4755C46.4615 78.4245 44.0165 75.9512 41.0003 75.9512C37.9841 75.9512 35.5391 78.4245 35.5391 81.4755C35.5391 84.5265 37.9841 86.9998 41.0003 86.9998Z" strokeMiterlimit="10" />
				<path d="M75.5394 67.2918C78.5555 67.2918 81.0006 64.8185 81.0006 61.7675C81.0006 58.7165 78.5555 56.2432 75.5394 56.2432C72.5232 56.2432 70.0781 58.7165 70.0781 61.7675C70.0781 64.8185 72.5232 67.2918 75.5394 67.2918Z" strokeMiterlimit="10" />
				<path d="M75.5394 31.1599C78.5555 31.1599 81.0006 28.6866 81.0006 25.6356C81.0006 22.5846 78.5555 20.1113 75.5394 20.1113C72.5232 20.1113 70.0781 22.5846 70.0781 25.6356C70.0781 28.6866 72.5232 31.1599 75.5394 31.1599Z" strokeMiterlimit="10" />
				<path d="M6.46124 67.2918C9.4774 67.2918 11.9225 64.8185 11.9225 61.7675C11.9225 58.7165 9.4774 56.2432 6.46124 56.2432C3.44508 56.2432 1 58.7165 1 61.7675C1 64.8185 3.44508 67.2918 6.46124 67.2918Z" strokeMiterlimit="10" />
				<path d="M6.46124 31.1599C9.4774 31.1599 11.9225 28.6866 11.9225 25.6356C11.9225 22.5846 9.4774 20.1113 6.46124 20.1113C3.44508 20.1113 1 22.5846 1 25.6356C1 28.6866 3.44508 31.1599 6.46124 31.1599Z" strokeMiterlimit="10" />
				<path d="M40.9959 56.7537C37.8018 56.7537 34.6058 56.7537 31.4077 56.7537C30.2269 56.7537 30.038 56.5536 30.0793 55.3741C30.2653 49.9991 34.6756 45.3407 39.883 44.8928C42.6043 44.6268 45.3257 45.3978 47.5146 47.0547C49.7036 48.7116 51.2024 51.1352 51.7176 53.8511C51.807 54.3515 51.8642 54.8573 51.8889 55.3651C51.9479 56.5596 51.7531 56.7656 50.5841 56.7656C47.3939 56.7616 44.1979 56.7577 40.9959 56.7537Z" strokeMiterlimit="10" />
				<path d="M40.9973 43.1369C39.837 43.1321 38.7041 42.7803 37.7411 42.1256C36.7781 41.471 36.028 40.5428 35.5854 39.4579C35.1427 38.373 35.0273 37.1799 35.2535 36.0288C35.4797 34.8777 36.0375 33.8199 36.8567 32.9888C37.6759 32.1576 38.7199 31.5902 39.8572 31.3579C40.9945 31.1256 42.1744 31.2388 43.2482 31.6833C44.322 32.1279 45.2419 32.8838 45.8919 33.8559C46.542 34.8281 46.8932 35.9731 46.9013 37.1467C46.9076 37.9336 46.7593 38.7139 46.465 39.4424C46.1708 40.1708 45.7364 40.8329 45.1871 41.3902C44.6379 41.9474 43.9847 42.3888 43.2654 42.6887C42.5462 42.9885 41.7752 43.1408 40.9973 43.1369V43.1369Z" strokeMiterlimit="10" />
				<path d="M25.1348 42.3037C25.1355 41.7423 25.2458 41.1866 25.4593 40.6684C25.6727 40.1502 25.9851 39.6798 26.3787 39.2839C26.7722 38.8881 27.239 38.5746 27.7525 38.3616C28.266 38.1486 28.8159 38.0402 29.3709 38.0425C30.4788 38.0558 31.5374 38.5079 32.3195 39.3018C33.1015 40.0957 33.5447 41.168 33.5539 42.2888C33.5454 43.4222 33.0951 44.5065 32.3008 45.3061C31.5065 46.1056 30.4324 46.5558 29.3119 46.5589C28.2042 46.5418 27.1471 46.0871 26.3665 45.2919C25.586 44.4968 25.1439 43.4243 25.1348 42.3037V42.3037Z" strokeMiterlimit="10" />
				<path d="M48.5606 42.265C48.567 41.4237 48.8194 40.6033 49.2861 39.9069C49.7527 39.2106 50.4126 38.6695 51.1828 38.3519C51.9529 38.0343 52.7987 37.9545 53.6136 38.1223C54.4285 38.2902 55.1761 38.6983 55.7621 39.2952C56.3481 39.8921 56.7463 40.6512 56.9066 41.4767C57.0668 42.3022 56.982 43.1572 56.6627 43.9339C56.3433 44.7107 55.8039 45.3745 55.1122 45.8416C54.4206 46.3087 53.6077 46.5584 52.7761 46.559C52.2203 46.5578 51.6703 46.4457 51.1574 46.2292C50.6446 46.0126 50.179 45.6958 49.7874 45.2969C49.3958 44.898 49.0859 44.4248 48.8754 43.9046C48.6648 43.3843 48.5579 42.8271 48.5606 42.265V42.265Z" strokeMiterlimit="10" />
				<path d="M53.5507 56.7538C53.7721 53.6393 52.916 50.9577 51.2363 48.4554C52.783 48.0592 54.4182 48.225 55.8562 48.9241C58.581 50.2321 60.0806 52.4418 60.3286 55.4936C60.4053 56.4611 60.1219 56.7508 59.1802 56.7568C57.3263 56.7628 55.4636 56.7538 53.5507 56.7538Z" strokeMiterlimit="10" />
				<path d="M28.4359 56.7475C26.4197 56.7475 24.4861 56.7475 22.5526 56.7475C22.432 56.7584 22.3106 56.7421 22.197 56.6998C22.0835 56.6575 21.9806 56.5901 21.8959 56.5027C21.8112 56.4153 21.7467 56.31 21.7071 56.1943C21.6675 56.0786 21.6538 55.9555 21.667 55.8338C21.6994 50.6827 26.6116 47.392 30.7415 48.4849C29.0647 50.9753 28.2086 53.6987 28.4359 56.7475Z" strokeMiterlimit="10" />
			</svg>


		</React.Fragment>
	)
}

export default ManageTeams