import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import Toasts from "../Toasts/Toasts";
import { _l } from "../../hooks/utilities";

import { showMessage } from "../../actions/messages";
import { OAuth2Client } from "google-auth-library";
import onedriveServices from "../../services/onedrive-services";
import CustomOneDriveIcon from "../../assets/icons/CustomOneDriveIcon";
import CustomGoogleDriveIcon from "../../assets/icons/CustomGoogleDriveIcon";
// import MicrosoftLogin from "react-microsoft-login";

const DrivesFilesSetting = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const [toastClass, setToastClass] = useState("unsucess");
  const [header, setHeader] = useState("");
  const [message, setMessage] = useState("");
  const [validationFalse, setValidationFalse] = useState(false);
  const [googleDriveStatus, setGoogleDriveStatus] = useState(false);
  const [revokeBtn, setRevokeBtn] = useState(false);
  const googleDrive = useSelector((state) => state.googleDrive);
  const project_id = useSelector((state) => state.customer.selectedProject);

  useEffect(() => {
    let _document = googleDrive.drive_connectivity;
    if (_document && _document.data && _document.status) {
      setGoogleDriveStatus(true);
      setRevokeBtn(true);
    } else {
      setGoogleDriveStatus(false);
      setRevokeBtn(false);
    }
  }, [googleDrive.drive_connectivity]);

  // const oneDriveAuthUrl =
  //   "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=c4786c02-05b2-46c2-8dcc-dd14f11b0a4f&scope=https://graph.microsoft.com/User.Read https://graph.microsoft.com/Mail.Send https://graph.microsoft.com/Calendars.ReadWrite https://graph.microsoft.com/Calendars.ReadWrite.Shared https://graph.microsoft.com/Mail.ReadWrite https://graph.microsoft.com/Contacts.ReadWrite https://graph.microsoft.com/Chat.ReadWrite https://graph.microsoft.com/ChatMessage.Send https://graph.microsoft.com/Contacts.ReadWrite.Shared OnlineMeetings.ReadWrite files.readwrite.all offline_access openid&response_type=code&redirect_uri=http://localhost:3000&state=one_drive";
  // // const oneDriveAuthUrl = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=c4786c02-05b2-46c2-8dcc-dd14f11b0a4f&scope=https://graph.microsoft.com/User.Read https://graph.microsoft.com/Mail.Send https://graph.microsoft.com/Calendars.ReadWrite https://graph.microsoft.com/Calendars.ReadWrite.Shared https://graph.microsoft.com/Mail.ReadWrite https://graph.microsoft.com/Contacts.ReadWrite https://graph.microsoft.com/Chat.ReadWrite https://graph.microsoft.com/ChatMessage.Send https://graph.microsoft.com/Contacts.ReadWrite.Shared OnlineMeetings.ReadWrite files.readwrite.all offline_access openid&response_type=code&redirect_uri=https://staging.myr.ai&state=one_drive"
  function oneDriveAuthModal() {
    onedriveServices.oauthUrl().then((res) => {
    var left = (window.screen.width - 600) / 2;
    var top = (window.screen.height - 600) / 4;
    if(res.status == 1)
    {
      window.open(
        res.data,
        "center window",
        "resizable = yes, width=600, height=600, top=" + top + ", left=" + left
      );
    }
    })
  }

  const GOOGLE_CLIENT_ID =
    "927290225329-banacgg9k35lva7uqkabhv0v7217ccrj.apps.googleusercontent.com";
  const GOOGLE_CLIENT_SECRET = "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";
  const googleAuthSuccess = (response) => {
  };

  const handleGoogleSignIn = () => {
    const client = new OAuth2Client(GOOGLE_CLIENT_ID, GOOGLE_CLIENT_SECRET);
    const url = client.generateAuthUrl({
      access_type: "offline",
      redirect_uri: window.location.origin,
      scope:
        "https://mail.google.com/ https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.activity  https://www.googleapis.com/auth/contacts https://www.googleapis.com/auth/contacts.other.readonly https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/directory.readonly",
      state: "google_drive",
    });

    var left = (window.screen.width - 600) / 2;
    var top = (window.screen.height - 600) / 4;
    window.open(
      url,
      "center window",
      "resizable = yes, width=600, height=600, top=" + top + ", left=" + left
    );
  };
  window.addEventListener('one_drive', (event) => {
    handleClose();
  });



  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>{_l("l_files_syncronization")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-3">
            
            <a href="#/" className={`platform-card task-list-card list-view mb-2 p-4`}>
                <div className="d-flex justify-content-between">
                  <div className="h30w30">
                    <CustomGoogleDriveIcon />
                  </div>
                  <div className="w100minus30 ps-4">
                    <div className="title-fonts fw-semibold">{_l("l_goggle_drive")}</div>
                    <div className="c-font f-12 color-white-60">{_l("l_gmail_subtext")}</div>
                    <div className="card-btn-wrapper mt-4">
                      <div className="d-flex align-items-center">
                      <Button size="sm" variant="primary" className="position-relative">
                      <input
                        className="absolute-input"
                        type="checkbox"
                        name="google_drive"
                        value="google_drive"
                        onChange={() => {
                          handleGoogleSignIn();
                        }}
                      />
                      {_l("l_goggle_drive")}
                      </Button>
                      </div>
                    </div>  
                  </div>
                </div>
            </a>
            <a href="#/" className={`platform-card task-list-card list-view mb-0 p-4`}>
                <div className="d-flex justify-content-between">
                  <div className="h30w30">
                    <CustomOneDriveIcon />
                  </div>
                  <div className="w100minus30 ps-4">
                    <div className="title-fonts fw-semibold">{_l("l_one_drive")}</div>
                    <div className="c-font f-12 color-white-60">{_l("l_gmail_subtext")}</div>
                    <div className="card-btn-wrapper mt-4">
                      <div className="d-flex align-items-center">
                      <Button size="sm" variant="primary" className="position-relative">
                      <input
                        className="absolute-input"
                        type="checkbox"
                        name="one_drive"
                        value="one_drive"
                        // defaultChecked={true}
                        onChange={() => {
                          oneDriveAuthModal();
                        }}
                      />
                      {_l("l_one_drive")}
                      </Button>
                      </div>
                    </div>  
                  </div>
                </div>
            </a>
            {revokeBtn && (
            <Modal.Footer className="border-0 justify-content-end pt-0">
              <div>
                
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => {
                      // updateGoogleDriveStatus();
                      setRevokeBtn(false);
                      handleClose();
                      // dispatch(removeAllDocs());
                    }}
                  >
                    {_l("l_revoke_google_drive")}
                  </Button>
                
              </div>
            </Modal.Footer>
            )}
          </Modal.Body>
        </Modal>
      </div>
      {validationFalse ? (
        <Toasts
          message={message}
          header={header}
          toastClass={toastClass}
          delay={10000}
          handler={() => {
            setValidationFalse(false);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default DrivesFilesSetting;
