import React, { useState } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { TaskModalClose, _l, favoriteDdashboardSelected } from "../../hooks/utilities";
import { Buildings, CurrencyEur, DotsThreeVertical, PencilSimpleLine, SignOut, User } from "phosphor-react";
import { useDispatch, useSelector } from "react-redux";
import { connectToSocket, getGroupDetails, setShowAccordionVisibility, setShowIsChatScreenDisplay, setactiveChatScreenId, userOffline } from "../../actions/chat";
import { SetSelectedTask, commanoffcanvasisOpen, isPremuimEstimateDisable, lastSessionOfChat, leftPanelFilters, setBulkTaskRating, setFetchedTaskandMessages, setSelectedGloablView, setSpaceList, userLogout } from "../../actions/customer";
import { useNavigate } from "react-router-dom";
import { archieveIDBCollections, clearDatabase, restoreIDBSession } from "../../hooks/indexed_db_helpers";
import { adminLoginAsStaff } from "../../actions/admin-actions";
import { showFullScreenLoader } from "../../actions/messages";
// import { DropdownItem } from "react-bootstrap";

const UserProfileDropdown = ({selectLiteVersion, setSelectedProjectm}) => {

  const { leftPanelTab, commanoffcanvasIsOpen } = useSelector((state) => state.customer);


  const [dropdownOpen, setDropdownOpen] = useState(false);

  const fullName = localStorage.getItem("full_name");
  const email = localStorage.getItem("email");
  const adminAsStaff = localStorage.getItem('adminArchieve') ? true : false;
  const contact_role = localStorage.getItem("contact_role");
  const contact_image = localStorage.getItem("contact_image");
  const company_logo = localStorage.getItem("company_logo") ? localStorage.getItem("company_logo") : "";
  const rel_id =
  localStorage.getItem("user_type") === "staff"
    ? localStorage.getItem("staff_id")
    : localStorage.getItem("client_id");
  const [randomID, setRandomID] = useState(String(Math.random()));
  var userType = localStorage.getItem("user_type") ? localStorage.getItem("user_type") : "";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    var chatUserId = localStorage.getItem("chatUserId");
    var language = localStorage.getItem("language");
    clearDatabase();
    if (chatUserId != "" && chatUserId != undefined) {
      dispatch(userOffline({ userId: chatUserId }));
    }
    dispatch(userLogout());
    localStorage.clear();
    localStorage.setItem("version", process.env.REACT_APP_VERSION);
    localStorage.setItem("language", language);
    navigate("/login");
    dispatch(
      leftPanelFilters('', '', 'all',)
    );

  };

  const handleSwitchLogin = async() => {
    let email = "";
    email = localStorage.getItem("email");
    var chatUserId = localStorage.getItem("chatUserId");
    if (chatUserId != "" && chatUserId != undefined) {
      dispatch(userOffline({ userId: chatUserId }));
    }
    const archieve = JSON.parse(localStorage.getItem("adminArchieve"));
    const projectId = localStorage.getItem('selectedOffice');
    await archieveIDBCollections(email);
    
    localStorage.clear();
    localStorage.setItem("version", process.env.REACT_APP_VERSION);
    archieve.forEach((item) => {
      localStorage.setItem(item.key, item.value);
      if (item.key == "fav_dashboard") {
        favoriteDdashboardSelected(item.value);
      }
    });
    //Get Admin's Email
    email = localStorage.getItem("email");
    dispatch(adminLoginAsStaff(false));
    localStorage.removeItem("adminArchieve");
    await clearDatabase();
    await restoreIDBSession(email);
    localStorage.setItem('selectedOffice', projectId)
    dispatch(showFullScreenLoader());
    dispatch(setSpaceList(JSON.parse(localStorage.getItem("spaces"))));
    dispatch(isPremuimEstimateDisable());
    setTimeout(() => {
      dispatch(connectToSocket());
    }, 500);
    dispatch(leftPanelFilters("","","all"))
    dispatch(getGroupDetails({}));
    dispatch(lastSessionOfChat([]));
    dispatch(setFetchedTaskandMessages([]));
    dispatch(setSelectedGloablView("AichatView"));
    navigate("/redirect", {
      state: {
        url: "/",
      }
    });
  };

  return (
    <UncontrolledDropdown
      direction="up"
      className="d-flex rounded-circle dropdown dropdown-center team-list-dropdown w-100 align-items-center"
    >
      <DropdownToggle tag="div" className="d-flex w-100">
        <div
          className={`h-user-part no-after position-relative dropdown dropdown-center d-flex w-100 justify-content-center align-items-center`}
        >
          <div className="d-flex justify-content-center align-items-center flex-column">
          <div
            className={`h32w32 comman-round-box with-bg d-flex align-items-center rounded-circle mx20px border-yellow`}
            type="button"
            id="headerUserDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            data-tip={_l("l_profile")}
            data-effect="solid"
            data-delay-show="1000"
            data-class="tooltip-main"
            data-bs-auto-close="outside"
            data-place="left"
          >
          {userType == "operator" 
            ? <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"style={{backgroundImage: `url('${company_logo}')`}}></div>
            : contact_image ? 
            <div
              className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
              style={{
                backgroundImage: `url('${contact_image}')`,
              }}
            ></div> 
            :
            <User size={24} className="c-icons" weight="light" />
          }
          </div>
          {commanoffcanvasIsOpen ?
          <div className="c-font color-white-80 d-block f-10 fw-light line-height-1 pt-1 text-truncate text-center w-100 ">
            {_l("l_profile")}
          </div> : <></>}
          </div>
        { ["my-spaces"].includes(leftPanelTab) || (userType != "staff" && leftPanelTab == "my-client")
        ? <></>
        : <div class="w100minus100per d-flex radius_3 title-fonts color-white-80 left-toggle-none cursor-pointer">
                <div class="d-flex w-85  align-items-center">
                  <div class="d-flex align-items-center justify-content-between w100minus100 flex-grow-1 pe-1">
                    <div class="comman-list-wrapper-main flex-grow-1 left-toggle-none w100minus120">
                      <div class="list-left-part text-truncate">
                        <a
                          href="#/"
                          class="text-truncate fw-semibold c-font f-14 title-fonts w-100 lh-1"
                        >
                          {localStorage.getItem("full_name")}
                        </a>
                        <div
                          href="#/"
                          class="text-truncate c-font f-10 w-100 color-white-80 lh-1"
                        >
                          My profile
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="comman_action_icon ms-10px me-10px">
                  <a href="#/" className="action_icon  h32w32">
                    <DotsThreeVertical size={24} className="c-icons" weight="light" />
                  </a>
                </div>
          </div>
        }

        </div>
      </DropdownToggle>
      <DropdownMenu
        container="body"
        className="w_350 pb-10px"
        style={{ marginLeft: "25px" }}
      >
        <DropdownItem
          onClick={() => {
            dispatch(setShowIsChatScreenDisplay(true));
            dispatch(setShowAccordionVisibility(false));
            dispatch(setactiveChatScreenId(0));
            navigate("/myprofile")
          }}
        >
          <div className="d-flex">
            <div className="c-list-icon">
              <div
                className={`h40w40 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 comman-image-box}`}
              >
                {/* <div
                  className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                  style={{
                    backgroundImage: `url('${"https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}')`,
                  }}
                ></div> */}
                {userType == "operator" 
                  ? <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"style={{backgroundImage: `url('${company_logo}')`}}></div>
                  : contact_image ? 
                  <div
                    className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                    style={{
                      backgroundImage: `url('${contact_image}')`,
                    }}
                  ></div> 
                  :
                  <User size={14} className="c-icons" weight="light" />
                }
              </div>
            </div>
            <div className="ps-2 w100minus40 title-fonts d-flex  flex-column">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column text-truncate w100minus100per">
                  <div className="title-font text-truncate c-font fw-semibold f-16">
                    {fullName}
                  </div>
                  <div className="c-font color-white-60 f-12 max-w120px text-truncate">{email}</div>
                  </div>
                  <div className="btn btn-white-05 btn-with-default-cursor c-font f-10 fw-semibold p-1 position-relative px-2 rounded-5 text-truncate">
                    { userType == "contact" 
                        ? _l("l_contact")
                        : userType == "operator"
                          ? _l("l_provider")
                          : ""
                    }
                  </div>
                </div>
            </div>
          </div>
        </DropdownItem>
        <DropdownItem className="p-0">
          <hr className="dropdown-divider my-0"></hr>
        </DropdownItem>
        {/* <DropdownItem className="text-truncate">
                <div className="d-flex align-items-center">
                  <div className=" me-2">
                  <PencilSimpleLine size={18} className="c-icons" weight="light" />
                  </div>
                  <div className="text-truncate">Edit Profile</div>
                </div>
            </DropdownItem> */}
        <DropdownItem className="p-0">
          <hr className="dropdown-divider my-0"></hr>
        </DropdownItem>
        <DropdownItem className="text-truncate" onClick={() => {
        dispatch(setShowIsChatScreenDisplay(true));
          dispatch(setShowAccordionVisibility(false));
          dispatch(setactiveChatScreenId(0));
          navigate("/myprofile")
        }}>
            <div className="d-flex align-items-center">
              <div className=" me-2" ><PencilSimpleLine size={18} className="c-icons" weight="light" /> </div>
              <div className="text-truncate">{_l('l_edit_profile')}</div>
            </div>
        </DropdownItem>
        {userType === 'operator' ? 
        <DropdownItem className="text-truncate" onClick={() => {
          navigate("/premiumsubscription");
        }}>
            <div className="d-flex align-items-center">
              <div className=" me-2" ><CurrencyEur size={18} className="c-icons" weight="light" /> </div>
              <div className="text-truncate">{_l('l_my_plan')}</div>
            </div>
        </DropdownItem>
        : <></>}
        {/* <DropdownItem className="text-truncate"
        //  onClick={() => navigate("/myprofile")}
         data-tip={"#" + rel_id}
         data-for={randomID}
         data-effect="solid"
         data-delay-show='1000'
         data-class="tooltip-main"
         data-place="right"
         onClick={() => {
            dispatch(setFetchedTaskandMessages([]))
              dispatch(commanoffcanvasisOpen(false)) 
              dispatch(SetSelectedTask({}));
              TaskModalClose();
            if ((["operator","contact"].includes(userType) && contact_role != 3) || userType == "staff") {
              dispatch(leftPanelFilters("","","topicFilterClear"))
              navigate("/dashboard");
              localStorage.removeItem("selectedOffice")
              localStorage.removeItem("selectedVirtualSpace")
              dispatch(setBulkTaskRating([]))
              setSelectedProject(0)
            }else{
              selectLiteVersion()
            }
          }}
         >
            <div className="d-flex align-items-center">
              <div className=" me-2" ><Buildings size={18} weight="light" className="" /></div>
              <div className="text-truncate">{_l('l_spaces')}</div>
            </div>
        </DropdownItem> */}
        <DropdownItem className="text-truncate" onClick={handleLogout}>
          <div className="d-flex align-items-center">
            <div className=" me-2">
              <SignOut size={18} className="c-icons" weight="light" />
            </div>
            <div className="text-truncate">{_l("l_logout")}</div>
          </div>
        </DropdownItem>
        {adminAsStaff ? (
          <DropdownItem className="text-truncate" onClick={handleSwitchLogin}>
            <div className="d-flex align-items-center">
              <div className=" me-2">
                <SignOut size={18} className="c-icons" weight="light" />
              </div>
              <div className="text-truncate">{_l("l_switch_to_admin")}</div>
            </div>
          </DropdownItem>
        ) : (
          <></>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default UserProfileDropdown;
