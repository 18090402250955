import React from 'react';
// import CSS from '../css/icon_main.css';

function SunIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<g clipPath="url(#clip0_14078_218862)">
<path d="M8 2.25V1M3.93126 3.93124L3.05 3.04999M2.25 8H1M3.93126 12.0688L3.05 12.95M8 13.75V15M12.0688 12.0688L12.95 12.95M13.75 8H15M12.0688 3.93124L12.95 3.04999M8 11.75C10.0711 11.75 11.75 10.0711 11.75 8C11.75 5.92893 10.0711 4.25 8 4.25C5.92893 4.25 4.25 5.92893 4.25 8C4.25 10.0711 5.92893 11.75 8 11.75Z" />
</g>
<defs>
<clipPath id="clip0_14078_218862">
<rect width="16" height="16" />
</clipPath>
</defs>
</svg>


		</React.Fragment>
	)
}

export default SunIcon