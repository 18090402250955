import React, { useEffect, useState } from 'react'
import CommanLoader from '../components/Loader/CommanLoader'
import { useSearchParams } from 'react-router-dom';
import { autoLogin } from '../actions/auth';
import { useDispatch } from 'react-redux';

export const CustomerInvitation = () => {

    const [searchParams] = useSearchParams();
    const page = searchParams.get("page");
    const hash = searchParams.get("hash");
    const dispatch = useDispatch();

    console.log("page", page, "hash", hash);
    useEffect(() => {
    if (page == "customer-invitation" && hash) {
        dispatch(autoLogin(hash))
    }
    }, [])
    
  return (
    <div>
        <CommanLoader className="main-loader-wrapper full-screen" />
   </div>
  )
}
