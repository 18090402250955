import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { GoogleMap, useJsApiLoader, Marker,StreetViewPanorama } from "@react-google-maps/api";
import {
  createPalette,
  getDataLayerUrls,
  getLayer,
  normalize,
  panelsPalette,
  rgbToColor,
} from "../../hooks/solar_map_helper";
import { Form } from "react-bootstrap";
import {
  ArrowsCounterClockwise,
  CaretRight,
  Check,
  CurrencyDollar,
  Lightning,
  MagnifyingGlass,
  Sun,
} from "phosphor-react";
import { _l, showError } from "../../hooks/utilities";
import Geocode from "react-geocode";
import { useSearchParams } from "react-router-dom";
import Loader from "./Loader";
import RangeSlider from "./RangeSlider";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import mappin from "../../assets/images/mappin.svg";
import PiggyBank from "../../assets/images/PiggyBank.svg";
import solarpanelicon from "../../assets/images/solarpanelicon.svg";
import Solarhublogo from "../../assets/images/blazlogoblack.svg";
import BenchmarkServices from "../../services/benchmark-services";
import html2canvas from "html2canvas";


Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_SECRET);
Geocode.setLanguage("fr");
Geocode.setRegion("fr");

const containerStyle = {
  width: "100%",
  height: "100%",
  position: "static",
};

const DEFAULT_VIEWPORT_ZOOM = 18;
const MAX_VIEWPORT_ZOOM = 18.5;
const CUSTOM_SOLAR_PANEL_ZOOM = 19;
const DEFAULT_SOLAR_PANEL_CAPACITY = 375;

//This is calculated by trial and error for 1920 screen and after finding it suitable, linerally mapped for other screens
const LEFT_OFFSET = (window.innerWidth * 0.00091411755789) / 1920;

const SOLAR_PANEL_DEFAULT_WIDTH = 1.6; //In meters
const SOLAR_PANEL_DEFAULT_HEIGHT = 1; //In meters

const VERTICAL_STEP_SIZE = 0.000006558; //This is calculated using trial and error by considering the accuracy and speed, lower the number to improve the accuracy, (but this will result into long iteration of nested loop that can result into significant delay)
const HORIZONTAL_STEP_SIZE = 0.000006326; //Same as above...

const DEFAULT_SOLAR_CONFIG = [
  {
    panelsCount: 8,
    yearlyEnergyDcKwh: 8 * DEFAULT_SOLAR_PANEL_CAPACITY,
  },
  {
    panelsCount: 12,
    yearlyEnergyDcKwh: 12 * DEFAULT_SOLAR_PANEL_CAPACITY,
  },
  {
    panelsCount: 16,
    yearlyEnergyDcKwh: 16 * DEFAULT_SOLAR_PANEL_CAPACITY,
  },
  {
    panelsCount: 20,
    yearlyEnergyDcKwh: 20 * DEFAULT_SOLAR_PANEL_CAPACITY,
  },
  {
    panelsCount: 24,
    yearlyEnergyDcKwh: 24 * DEFAULT_SOLAR_PANEL_CAPACITY,
  }
];

let isGeoCodeProcessOngoing;

//Default Configurations:

let polygonVertices = [];
let solarPanelsInitialPositions = [];

const MapScreen = forwardRef(({
  solarInfo,
  setSolarInfo,
  handleNextStep,
  regionList,
  mapScreenRef,
  captureMapScreenShot,
  setUserInfo = ()=>{},
  userInfo,
  setAddressImageURL = ()=>{},
  setCoordinates = ()=>{},
  isSolarDataFetched =false,
  setIsSolarDataFetched = ()=>{},
  showInput= false,
  setShowInput= ()=> {}
}, ref ) => {
  const addressOptions = {
    componentRestrictions: { country: "fr" },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["address"],
  };

  const dotIcon = {
    path: 'M-5,-5 L5,-5 5,5 -5,5 Z',
    fillColor: 'red',
    fillOpacity: 1,
    strokeColor: 'red',
    strokeOpacity: 1,
    strokeWeight: 1,
    scale: 0.8  // Adjust the size of the square as needed
  };

  const [searchParams, setSearchParams] = useSearchParams();
  let addressQueryString = searchParams.get("address");
  addressQueryString =
    addressQueryString && addressQueryString.length
      ? decodeURIComponent(addressQueryString)
      : "";

  const [map, setMap] = useState(null);
  const [location, setLocation] = useState({ lat: 48.8313442, lng: 2.3761257 });
  const [addressText, setAddressText] = useState(
    addressQueryString || solarInfo.address
  );
  const [solarConfigs, setSolarConfigs] = useState(DEFAULT_SOLAR_CONFIG);
  const [buildingInsightsData, setBuildingInsightsData] = useState();
  const [currentConfigId, setCurrentConfigId] = useState(1);
  const [solarPanels, setSolarPanels] = useState([]);
  const [mapCenter, setMapCenter] = useState({
    lat: 48.8581782,
    lng: 2.2938945,
  });
  const [isMapInformationFetching, setIsMapInformationFetching] =
    useState(false);
  const [boxPoints, setBoxPoints] = useState([]);
  const [allowToSelectCustomPanels, setAllowToSelectCustomPanels] =
    useState(false);
  const [currentPolygonRef, setCurrentPolygonRef] = useState(null);
  const [showUndoButtonForPolygon, setShowUndoButtonForPolygon] =
    useState(false);
  const [monthlyBill, setMonthlyBill] = useState("");
  const [monthlyConsumption, setMonthlyConsumption] = useState(0);
  const [sliderCurrentValue, setSliderCurrentValue] = useState(0);
  const [solarProviderCount, setSolarProviderCount] = useState(0);
  const [mapDataLayer, setMapDataLayer] = useState(null);
  const [verticiesPolygon, setVerticiesPolygon] = useState(null);
  const [hideAddressInput, setHideAddressInput] = useState(false);
  const [currentSelectedKPI, setCurrentSelectedKPI] = useState(1);
  const [yearlyConsumptionSave, setyearlyConsumptionSave] = useState(0);
  const [showSliderBar, setShowSliderBar] = useState(false);
  const [air_conditioner, setAir_conditioner] = useState(solarInfo.air_conditioner ? Number(solarInfo.air_conditioner) : 0);
  const [garage, setGarage] = useState(solarInfo.garage ? Number(solarInfo.garage) : 0);
  const [back_yard, setBack_yard] = useState(solarInfo.back_yard ? Number(solarInfo.back_yard) : 0);
  const [electronic_gadgets, setElectronic_gadgets] = useState(solarInfo.electronic_gadgets ? Number(solarInfo.electronic_gadgets) : 0);
  const [isManualModeOn, setIsManualModeOn] = useState(false)
  const [prevArea, setPrevArea] = useState({});
  const [clearedArea, setClearedArea] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [preaAreaLocation,setPreaAreaLocation] = useState({});
  const addressRef = useRef();
  const autoCompleteRef = useRef();
  const maxPanelCountRef = useRef(0);
  const currPolyline = useRef();
  const mapRef = useRef();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_SECRET,
  });

  const onMapLoad = (map) => {
    setMap(map);
    mapRef.current = map;
  };

  const setPanelCalculations = async (panelConfig) => {
    // Initial Values
    let panelsCount = 20;
    let yearlyEnergyDcKwh = 12000;

    // Basic settings
    let monthlyAverageEnergyBill = 300;
    let energyCostPerKwh = 0.31;
    let panelCapacityWatts = 400;
    let solarIncentives = 7000;
    let installationCostPerWatt = 4.0;
    let installationLifeSpan = 20;

    // Advanced settings
    let dcToAcDerate = 0.85;
    let efficiencyDepreciationFactor = 0.995;
    let costIncreaseFactor = 1.022;
    let discountRate = 1.04;

    // Solar installation
    let installationSizeKw = (panelsCount * panelCapacityWatts) / 1000;
    let installationCostTotal =
      installationCostPerWatt * installationSizeKw * 1000;

    // Energy consumption
    let monthlyKwhEnergyConsumption =
      monthlyAverageEnergyBill / energyCostPerKwh;
    let yearlyKwhEnergyConsumption = monthlyKwhEnergyConsumption * 12;

    // Energy produced for installation life span
    let initialAcKwhPerYear = yearlyEnergyDcKwh * dcToAcDerate;
    let yearlyProductionAcKwh = [...Array(installationLifeSpan).keys()].map(
      (year) => initialAcKwhPerYear * efficiencyDepreciationFactor ** year
    );

    // Cost with solar for installation life span
    let yearlyUtilityBillEstimates = yearlyProductionAcKwh.map(
      (yearlyKwhEnergyProduced, year) => {
        const billEnergyKwh =
          yearlyKwhEnergyConsumption - yearlyKwhEnergyProduced;
        const billEstimate =
          (billEnergyKwh * energyCostPerKwh * costIncreaseFactor ** year) /
          discountRate ** year;
        return Math.max(billEstimate, 0); // bill cannot be negative
      }
    );
    let remainingLifetimeUtilityBill = yearlyUtilityBillEstimates.reduce(
      (x, y) => x + y,
      0
    );
    let totalCostWithSolar =
      installationCostTotal + remainingLifetimeUtilityBill - solarIncentives;

    // Cost without solar for installation life span
    let yearlyCostWithoutSolar = [...Array(installationLifeSpan).keys()].map(
      (year) =>
        (monthlyAverageEnergyBill * 12 * costIncreaseFactor ** year) /
        discountRate ** year
    );
    let totalCostWithoutSolar = yearlyCostWithoutSolar.reduce(
      (x, y) => x + y,
      0
    );

    // Savings with solar for installation life span
    let savings = totalCostWithoutSolar - totalCostWithSolar;
    let dataUrl;
    if (mapScreenRef.current) {
      const canvas = await html2canvas(mapScreenRef.current, { useCORS: true });
      dataUrl = canvas.toDataURL("image/jpeg");
    }
    setSolarInfo({
      ...solarInfo,
      no_of_plates: panelConfig.panelsCount,
      total_savings: calculateSavings(),
      aids_financial: solarIncentives,
      electricity_production: panelConfig.yearlyEnergyDcKwh,
      plate_capacity: panelCapacityWatts,
      monthly_bill: monthlyBill,
      image : dataUrl ? dataUrl : ""
    });
  };

  const selectDefaultSolarPanelConfig = (value) => {
    const yearlyConsumption = calculateConsumptionFromMothlyBill(value);

    const findNearestIndex = (array, targetValue) =>
      array.findIndex(
        (config) =>
          config.yearlyEnergyDcKwh  >= targetValue          
      );

    let idealConfigIndex;

    idealConfigIndex = findNearestIndex(solarConfigs, yearlyConsumption);

    if (idealConfigIndex > -1) {
      setCurrentConfigId(idealConfigIndex);
      setSliderCurrentValue(solarConfigs[idealConfigIndex].yearlyEnergyDcKwh);
      setCurrentSelectedKPI(2);
    } else {
      setCurrentConfigId(solarConfigs.length - 1);
      setSliderCurrentValue(
        solarConfigs[solarConfigs.length - 1].yearlyEnergyDcKwh
      );
      setCurrentSelectedKPI(2);
    }
  };

  const findClosestBuilding = async (location) => {
    const args = {
      "location.latitude": location.lat.toFixed(5),
      "location.longitude": location.lng.toFixed(5),
    };
    const params = new URLSearchParams({
      ...args,
      key: process.env.REACT_APP_GOOGLE_MAP_SECRET,
    }); // Replace with your actual API key

    try {
      const response = await fetch(
        `https://solar.googleapis.com/v1/buildingInsights:findClosest?${params}`
      );
      const content = await response.json();

      if (response.status !== 200) {
        console.error("findClosestBuilding\n", content);
        throw content;
      }
      return content;
    } catch (error) {
      console.error("Error in findClosestBuilding:", error);
      throw error;
    }
  };

  const showSolarPotential = async (location, init = true) => {
    try {
      setIsMapInformationFetching(true);
      const buildingInsights = await findClosestBuilding(location);

      if (buildingInsights) {
        setAllowToSelectCustomPanels(false);
      }

      if (init) {
        setBuildingInsightsData(buildingInsights);
      }

      if (
        buildingInsights.boundingBox &&
        buildingInsights.boundingBox.sw &&
        buildingInsights.boundingBox.ne
      ) {
        await applyBackgroundColorToMap(
          buildingInsights.boundingBox.sw,
          buildingInsights.boundingBox.ne,
          buildingInsights.center
        );
      }

      let configArray = [];
      if (
        buildingInsights.solarPotential &&
        buildingInsights.solarPotential.solarPanelConfigs
      ) {
        configArray = buildingInsights.solarPotential.solarPanelConfigs;
        //As per the discussion, the minimum installation must be 3K, filetr all the value below that
        configArray = configArray
          .filter((config) => config.yearlyEnergyDcKwh >= 2500);

        //Range for catalog
        const ranges = {
          3000: 3000,
          4500: 4500,
          6000: 6000,
          7500: 7500,
          9000: 9000
        };

        //Normalize the values...
        configArray.forEach(obj => {
          for (const [key, value] of Object.entries(ranges)) {
            if (obj.yearlyEnergyDcKwh <= parseInt(key)) {
                obj.yearlyEnergyDcKwh = value;
                break;
            }
          }
        });
        configArray = Array.from(
          configArray.reduce((acc, obj) => acc.set(obj.yearlyEnergyDcKwh, obj.panelsCount > (acc.get(obj.yearlyEnergyDcKwh) || 0) ? obj.panelsCount : acc.get(obj.yearlyEnergyDcKwh)), new Map())
        ).map(([yearlyEnergyDcKwh, panelsCount]) => ({ yearlyEnergyDcKwh, panelsCount }));
      }

      if (buildingInsights.solarPotential) {
        if (
          buildingInsights.solarPotential.solarPanels &&
          buildingInsights.solarPotential.solarPanels.length
        ) {
          const panel = buildingInsights.solarPotential.solarPanels[0];
          const config = configArray[configArray.length - 1];
          maxPanelCountRef.current = config.panelsCount;
          setMapCenter({
            lat: panel.center.latitude,
            lng: panel.center.longitude
          });
          setPrevArea({
            lng: panel.center.longitude,
            lat: panel.center.latitude
          });
          map.setZoom(MAX_VIEWPORT_ZOOM);
        }
      } else {
        maxPanelCountRef.current = 16;
      }

      if (
        buildingInsights &&
        buildingInsights.solarPotential &&
        buildingInsights.solarPotential.solarPanelConfigs
      ) {

        setSolarConfigs(configArray);
        /**
        const configArrayLength = configArray.length;
        if (configArrayLength && configArrayLength < 5) {
          let updatedConfig = configArray.slice();
          let lastConfigValue = configArray[configArrayLength - 1];

          for (let index = 1; index <= configArrayLength - 5; index++) {
            const panels = lastConfigValue + 2 * index;
            updatedConfig.push({
              panelsCount: panels,
              yearlyEnergyDcKwh: panels * DEFAULT_SOLAR_PANEL_CAPACITY,
            });
          }
        } else if (configArrayLength && configArrayLength > 5) {
          setSolarConfigs(configArray);
        }
        */
        setCurrentConfigId(1);
      }
    } catch (error) {
      setBuildingInsightsData({});
      drawMarkerAtLocation(location);
      setAllowToSelectCustomPanels(true);
      maxPanelCountRef.current = 16;
      console.error("Error in showSolarPotential:", error);
      throw error;
    } finally {
      setIsMapInformationFetching(false);
      setIsSolarDataFetched(true);
      if (init == true) {
        setHideAddressInput(true);
      }
      setShowInput(false);
    }
  };

  const selectSpaceRegion = (addressComponent) => {
    let regionId = 0;
    let regionName = "";
    let fetchedRegion = "";
    let index = addressComponent.findIndex(
      (add) => add.types && add.types.includes("administrative_area_level_2")
    );
    if (index === -1) {
      index = addressComponent.findIndex(
        (add) => add.types && add.types.includes("administrative_area_level_1")
      );
    }
    if (index > -1) {
      fetchedRegion = addressComponent[index].long_name;
    }
    regionList.forEach((region) => {
      if (
        region.label.toLowerCase().trim() == fetchedRegion.toLowerCase().trim()
      ) {
        regionId = region.value;
        regionName = region.label;
      }
    });
    if (regionId == 0) {
      return { region_id: 0, region_name: fetchedRegion };
    } else {
      return { region_id: regionId, region_name: regionName };
    }
  };

  const handleSliderMovement = (value) => {
    let configId = solarConfigs.findIndex((w) => w.yearlyEnergyDcKwh >= value);
    if(configId === -1)
    {
      return;
    }
    setCurrentConfigId(configId);
    updatePanelCalculations(configId);
    setSliderCurrentValue(
      Math.ceil(solarConfigs[configId].yearlyEnergyDcKwh / 1000) * 1000
    );
  };

  const drawMarkerAtLocation = (location) => {
    const markerPosition = {
      lat: Number(location.lat),
      lng: Number(location.lng),
    };

    // Remove pin of previous area
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    setMarkers([]);

    setPreaAreaLocation({
      lat: markerPosition.lat - (allowToSelectCustomPanels ? 0.0001 : 0),
      lng: markerPosition.lng
    });
    setCurrentConfigId(1);
    setMonthlyBill();
    const marker = new window.google.maps.Marker({
      position: markerPosition,
      title: addressText,
      draggable: true,
    });
    marker.setMap(map);
    setCurrentPolygonRef(marker);
  };

  const updatePanelCalculations = async (configId) => {
    if (typeof configId === "undefined") {
      configId = currentConfigId;
    }
  
    const panelConfig = solarConfigs[configId];
  
    if (panelConfig) {
      setPanelCalculations(panelConfig);
    }
  
    if (allowToSelectCustomPanels) {
      const currentPanels = solarPanels.slice();
      currentPanels.forEach((x) => x.setMap(null));
      let newPanels = solarPanels.slice();
      newPanels.forEach((w, i) => {
        if (i < solarConfigs[configId].panelsCount) {
          w.setMap(map);
        }
      });
    } else {
      try {
        const solarPotential = buildingInsightsData.solarPotential;
        if (
          solarPotential &&
          solarPotential.solarPanels &&
          solarPotential.solarPanels.length
        ) {
          const palette = createPalette(panelsPalette).map(rgbToColor);
          const minEnergy = parseInt(
            solarPotential.solarPanels.slice(-1)[0].yearlyEnergyDcKwh
          );
          const maxEnergy = parseInt(
            solarPotential.solarPanels[0].yearlyEnergyDcKwh
          );
          
          // Create tooltip container
          const tooltip = document.createElement("div");
          tooltip.style.position = "absolute";
          tooltip.style.padding = "5px 10px";
          tooltip.style.backgroundColor = "white"; // Customize color here
          tooltip.style.color = "black"; // Customize text color here
          tooltip.style.borderRadius = "5px";
          tooltip.style.visibility = "hidden"; // Hide tooltip by default
          tooltip.style.pointerEvents = "none"; // Avoid blocking clicks
          document.body.appendChild(tooltip);
  
          let panels = solarPotential.solarPanels.map((panel, i) => {
            const [w, h] = [
              solarPotential.panelWidthMeters / 2,
              solarPotential.panelHeightMeters / 2,
            ];
            const points = [
              { x: +w, y: +h },
              { x: +w, y: -h },
              { x: -w, y: -h },
              { x: -w, y: +h },
              { x: +w, y: +h },
            ];
            const orientation = panel.orientation === "PORTRAIT" ? 90 : 0;
            const azimuth =
              solarPotential.roofSegmentStats[panel.segmentIndex]
                .azimuthDegrees;
            const colorIndex = Math.round(
              normalize(panel.yearlyEnergyDcKwh, maxEnergy, minEnergy) * 255
            );
  
            if (panelConfig && i < panelConfig.panelsCount) {
              const polygon = new window.google.maps.Polygon({
                paths: points.map(({ x, y }) =>
                  window.google.maps.geometry.spherical.computeOffset(
                    {
                      lat: Number(panel.center.latitude),
                      lng: Number(panel.center.longitude),
                    },
                    Math.sqrt(x * x + y * y),
                    Math.atan2(y, x) * (180 / Math.PI) + orientation + azimuth
                  )
                ),
                strokeColor: "#B0BEC5",
                strokeOpacity: 0.9,
                strokeWeight: 1,
                fillColor: palette[colorIndex],
                fillOpacity: 0.9,
              });
  
              // Calculate panel dimensions
              const panelWidth = solarPotential.panelWidthMeters;
              const panelHeight = solarPotential.panelHeightMeters;
  
              // Add tooltip event listeners
              polygon.addListener("mouseover", (e) => {
                tooltip.innerHTML = `Width: ${panelWidth.toFixed(2)} m, Height: ${panelHeight.toFixed(2)} m`;
                tooltip.style.visibility = "visible";
                tooltip.style.left = e.domEvent.pageX + "px";
                tooltip.style.top = e.domEvent.pageY + "px";
              });
  
              polygon.addListener("mouseout", () => {
                tooltip.style.visibility = "hidden";
              });
  
              polygon.addListener("mousemove", (e) => {
                tooltip.style.left = e.domEvent.pageX + "px";
                tooltip.style.top = e.domEvent.pageY + "px";
              });
  
              return polygon;
            }
          });
          
          // Clear existing panels
          solarPanels.forEach((panel) => panel.setMap(null));
  
          // Filter out undefined panels
          panels = panels.filter((x) => x);
  
          // Add panels to the map
          panels.forEach((panel) => panel.setMap(map));
  
          setSolarPanels(panels);
        }
      } catch (e) {
        console.error(e);
      } finally {
        if (solarInfo.is_edit_page == true) {
          if (mapScreenRef.current) {
            const canvas = await html2canvas(mapScreenRef.current, { useCORS: true });
            const dataUrl = canvas.toDataURL("image/jpeg");
            setSolarInfo({ ...solarInfo, 
              image: dataUrl,
              monthly_bill : monthlyBill,
              no_of_plates: panelConfig && panelConfig.panelsCount ? panelConfig.panelsCount : solarInfo.no_of_plates,
              electricity_production : panelConfig.yearlyEnergyDcKwh ? panelConfig.yearlyEnergyDcKwh : solarInfo.yearlyEnergyDcKwh
            });
          }
        } else {
          captureMapScreenShot();
        }
      }
    }
  };
  
  const getSliderLabels = () => {
    let calc = (solarConfigs[currentConfigId].panelsCount * 400) / 1000;
    return {
      [`${calc * 1000}`]: calc,
    };
  };

  const calculatePercentageConsumption = () => {
    const maximumPowerConsumption = parseInt(
      solarConfigs[solarConfigs.length - 1].yearlyEnergyDcKwh
    );
    const currentPowerConsumption = parseInt(
      solarConfigs[currentConfigId] && solarConfigs[currentConfigId].yearlyEnergyDcKwh
    );
    const percentage =
      (currentPowerConsumption * 100) / maximumPowerConsumption;
    return percentage;
  };

  const applyBackgroundColorToMap = async (sw, ne, center) => {
    try {
      let overlays = [];
      let layer;
      let layerId = "monthlyFlux";
      let dataLayersResponse;

      const diameter =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          new window.google.maps.LatLng(ne.latitude, ne.longitude),
          new window.google.maps.LatLng(sw.latitude, sw.longitude)
        );
      const radius = Math.ceil(diameter / 2);
      try {
        dataLayersResponse = await getDataLayerUrls(
          center,
          radius,
          process.env.REACT_APP_GOOGLE_MAP_SECRET
        );
      } catch (e) {
        console.error(e);
        return;
      }

      try {
        layer = await getLayer(
          layerId,
          dataLayersResponse,
          process.env.REACT_APP_GOOGLE_MAP_SECRET
        );
      } catch (e) {
        console.error(e);
        return;
      }

      const bounds = layer.bounds;
      // overlays.map((overlay) => overlay.setMap(null));
      overlays = layer
        .render(true, 0, 14)
        .map(
          (canvas) =>
            new window.google.maps.GroundOverlay(canvas.toDataURL(), bounds)
        );
      if (overlays.length) {
        overlays[5].setMap(map);
        setMapDataLayer(overlays[5]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const addBoxPoint = (coordinates) => {
    if (!boxPoints.length) {
      // setMapCenter({
      //   lat: coordinates.lat,
      //   lng: coordinates.lng
      // });
      setMapCenter({});
      setIsSolarDataFetched(true);
    }

    setBoxPoints([...boxPoints, coordinates]);
  };

  const eraseAllVerticies = () => {
    setIsManualModeOn(false);
    setBoxPoints([]);
    polygonVertices = [];
    if (currentPolygonRef) {
      setCurrentPolygonRef(null);
      currentPolygonRef.setMap(null);
    }
    solarPanels.forEach((x) => {
        x.setMap(null);
    });
    setSolarPanels([]);
    setAddressText("");
    setIsSolarDataFetched(false);
    setMonthlyBill("");
    setMonthlyConsumption(0);
    if (addressRef && addressRef.current) {
      addressRef.current.value = "";
    }
    if (mapDataLayer) {
      mapDataLayer.setMap(null);
      setMapDataLayer(null);
    }
    if (verticiesPolygon) {
      verticiesPolygon.setMap(null);
      setVerticiesPolygon(null);
    }
    setHideAddressInput(false);
  };

  useImperativeHandle(ref, () => ({
    eraseAllVerticies,
  }));
  
  const eraseLastVertex = () => {
    polygonVertices = boxPoints.slice();
    polygonVertices.pop();
    setBoxPoints(polygonVertices);
  };

  const calculateAngle = (x1, y1, x2, y2) => {
    let deltaY = y2 - y1;
    let deltaX = x2 - x1;

    let slope = deltaY / deltaX;

    let angleRadians = Math.atan(slope);

    let angleDegrees = angleRadians * (180 / Math.PI);

    if (angleDegrees < 0) {
      angleDegrees += 360;
    }

    return angleDegrees;
  };

  const calculatePolygonCenter = (vertices) => {
    let latSum = 0;
    let lngSum = 0;
    vertices.forEach((vertex) => {
      latSum += vertex.lat();
      lngSum += vertex.lng();
    });

    const latCenter = latSum / vertices.length;
    const lngCenter = lngSum / vertices.length;

    return new window.google.maps.LatLng(latCenter, lngCenter);
  };

  const rotatePoint = (point, center, angle) => {
    const angleRad = (Math.PI / 180) * angle;
    const sinAngle = Math.sin(angleRad);
    const cosAngle = Math.cos(angleRad);

    const translatedLat = point.lat() - center.lat();
    const translatedLng = point.lng() - center.lng();

    const rotatedLat = translatedLat * cosAngle - translatedLng * sinAngle;
    const rotatedLng = translatedLat * sinAngle + translatedLng * cosAngle;

    const newLat = rotatedLat + center.lat();
    const newLng = rotatedLng + center.lng();

    return new window.google.maps.LatLng(newLat, newLng);
  };

  const rotatePolygon = (polygon, angle) => {
    const path = polygon.getPath().getArray();
    const center = calculatePolygonCenter(path);
  
    const rotatedPath = path.map((vertex) => {
      const distance = window.google.maps.geometry.spherical.computeDistanceBetween(center, vertex);
      const bearing = window.google.maps.geometry.spherical.computeHeading(center, vertex);
      const newBearing = bearing + angle;
  
      const rotatedVertex = window.google.maps.geometry.spherical.computeOffset(center, distance, newBearing);
      return rotatedVertex;
    });
  
    polygon.setPath(rotatedPath);
  };

  const addRotateButton = () => {
    const rotateButton = document.getElementById('RotateBtn');

    return rotateButton;
  };

  let selectedPanels = [];
  let hasRotatedOnce = false;

  const handlePanelClick = (panel) => {
    if (hasRotatedOnce) {
      resetSelectedPanels();
      hasRotatedOnce = false;
    }

    const panelIndex = selectedPanels.indexOf(panel);
    if (panelIndex > -1) {
      selectedPanels.splice(panelIndex, 1);
      panel.setOptions({ strokeColor: "#000", fillColor: "#323284" });
    } else {
      selectedPanels.push(panel);
      panel.setOptions({ strokeColor: "#020202", fillColor: "#EA4335" }); 
    }
  };

  const rotateSelectedPanels = () => {
    if (selectedPanels.length === 0) return;

    selectedPanels.forEach((panel) => {
      rotatePolygon(panel, 15);
    });

    hasRotatedOnce = true;
  };

  const resetSelectedPanels = () => {
    selectedPanels.forEach((panel) => {
      panel.setOptions({ strokeColor: "#000", fillColor: "#323284" });
    });
    selectedPanels = [];
  };

  const calculatePanelDimensions = (panelCoords) => {
    let minLat = Number.MAX_VALUE;
    let maxLat = -Number.MAX_VALUE;
    let minLng = Number.MAX_VALUE;
    let maxLng = -Number.MAX_VALUE;
  
    panelCoords.forEach(coord => {
      const lat = coord.lat();
      const lng = coord.lng();
      
      if (lat < minLat) minLat = lat;
      if (lat > maxLat) maxLat = lat;
      if (lng < minLng) minLng = lng;
      if (lng > maxLng) maxLng = lng;
    });
  
    // Calculate height (distance between top and bottom points)
    const height = window.google.maps.geometry.spherical.computeDistanceBetween(
      new window.google.maps.LatLng(minLat, minLng),
      new window.google.maps.LatLng(maxLat, minLng)
    );
  
    // Calculate width (distance between left and right points)
    const width = window.google.maps.geometry.spherical.computeDistanceBetween(
      new window.google.maps.LatLng(minLat, minLng),
      new window.google.maps.LatLng(minLat, maxLng)
    );
  
    return { width, height };
  };

  const drawCustomPanels = (polygonCoords) => {
    if (currentPolygonRef) {
      currentPolygonRef.setMap(null);
    }
    drawMarkerAtLocation(polygonCoords[0]);
  
    const poly = new window.google.maps.Polygon({
      paths: polygonCoords,
      fillColor: "#FFA500",
      fillOpacity: 1,
      zIndex: -1,
      map,
    });
    setVerticiesPolygon(poly);
    const bounds = new window.google.maps.LatLngBounds();
  
    polygonCoords.forEach((coord) => {
      bounds.extend(coord);
    });
  
    const minLat = bounds.getSouthWest().lat();
    const maxLat = bounds.getNorthEast().lat();
    const minLng = bounds.getSouthWest().lng();
    const maxLng = bounds.getNorthEast().lng();
    const grids = [];
    const azimuth = calculateAngle(
      polygonCoords[0].lat,
      polygonCoords[0].lng,
      polygonCoords[1].lat,
      polygonCoords[1].lng
    );
    const [w, h] = [
      SOLAR_PANEL_DEFAULT_WIDTH / 2,
      SOLAR_PANEL_DEFAULT_HEIGHT / 2,
    ];
    const points = [
      { x: +w, y: +h },
      { x: +w, y: -h },
      { x: -w, y: -h },
      { x: -w, y: +h },
      { x: +w, y: +h },
    ];
    const panelPositions = new Set();
    const tooltip = document.createElement("div");
    tooltip.style.position = "absolute";
    tooltip.style.padding = "5px 10px";
    tooltip.style.backgroundColor = "white";
    tooltip.style.color = "black";
    tooltip.style.borderRadius = "5px";
    tooltip.style.visibility = "hidden"; // Hide by default
    tooltip.style.pointerEvents = "none"; // Make sure the tooltip doesn't interfere with clicks
    document.body.appendChild(tooltip);
    // const infoWindow = new window.google.maps.InfoWindow(); // Create InfoWindow for tooltips

    for (let lat = minLat; lat < maxLat; lat += VERTICAL_STEP_SIZE) {
      for (let lng = minLng; lng < maxLng; lng += HORIZONTAL_STEP_SIZE) {
        if (
          window.google.maps.geometry.poly.containsLocation({ lat, lng }, poly)
        ) {
          let isOverLap = false;
  
          const panelCoords = points.map(({ x, y }) =>
            window.google.maps.geometry.spherical.computeOffset(
              { lat, lng },
              Math.sqrt(x * x + y * y),
              Math.atan2(y, x) * (180 / Math.PI) + 90 + azimuth
            )
          );
  
          const allInside = panelCoords.every((coord) =>
            window.google.maps.geometry.poly.containsLocation(coord, poly)
          );
  
          if (!allInside) {
            continue;
          }
  
          const panelKey = `${lat},${lng}`;
          if (panelPositions.has(panelKey)) {
            continue;
          }
  
          for (const existingPanel of grids) {
            const existingCoords = existingPanel.getPath().getArray();
            for (const existingCoord of existingCoords) {
              const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
                new window.google.maps.LatLng(lat, lng),
                existingCoord
              );
              if (distance < (w + HORIZONTAL_STEP_SIZE)) {
                isOverLap = true;
                break;
              }
            }
            if (isOverLap) break;
          }
  
          if (isOverLap) continue; 
  
          panelPositions.add(panelKey);
  
          const polygon = new window.google.maps.Polygon({
            paths: panelCoords,
            strokeColor: "#000",
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: "#323284",
            fillOpacity: 1,
            draggable: true,
          });

          const { width, height } = calculatePanelDimensions(panelCoords);

        // Mouse over and out event listeners for custom tooltip
        polygon.addListener("mouseover", (e) => {
          tooltip.innerHTML = `Width: ${width.toFixed(2)} m, Height: ${height.toFixed(2)} m`;
          tooltip.style.visibility = "visible";
          tooltip.style.left = e.domEvent.pageX + "px";
          tooltip.style.top = e.domEvent.pageY + "px";
        });

        polygon.addListener("mouseout", () => {
          tooltip.style.visibility = "hidden";
        });

        polygon.addListener("mousemove", (e) => {
          tooltip.style.left = e.domEvent.pageX + "px";
          tooltip.style.top = e.domEvent.pageY + "px";
        });

          polygon.addListener('click', () => handlePanelClick(polygon));
          grids.push(polygon);
        }
      }
    }  
    setSolarPanels(grids);

    const rotateButton = addRotateButton();
    rotateButton.addEventListener('click', () => {
      rotateSelectedPanels();
    });
  };  

  const updateMonthlyConsumption = (value) => {
    setMonthlyBill(value);

    const annualPowerConsumption = Number(value.replace(/\D/g, "")) * 4;

    setMonthlyConsumption(annualPowerConsumption);
    selectDefaultSolarPanelConfig(value);

    if(allowToSelectCustomPanels) {
      setIsManualModeOn(true);
      setMapCenter(preaAreaLocation);
    }
    map.setZoom(21);
  };
  useEffect(() => {
    let recommendation = 1
    switch (currentSelectedKPI) {
      case 1:
        recommendation = roundToNearestHalf(solarConfigs[0].yearlyEnergyDcKwh /1000)
        break;
      case 2:
        recommendation = roundToNearestHalf(solarConfigs[currentConfigId] && solarConfigs[currentConfigId].yearlyEnergyDcKwh /1000)
        break;
      case 3:
        recommendation = roundToNearestHalf(solarConfigs[solarConfigs.length - 1].yearlyEnergyDcKwh /1000)
        break;
      default:
        break;
    }
    let monthlyBills = monthlyBill 
    let recommendations = Math.round((recommendation * 1000) / 12)
    let monthlyConsumptions = monthlyConsumption
    const saveYearly = Math.round((monthlyBills * recommendations)/monthlyConsumptions)
    setyearlyConsumptionSave(saveYearly)
  }, [currentSelectedKPI, currentConfigId, monthlyConsumption])
  

  const calculateConsumptionFromMothlyBill = (bill) => {
    const output = Math.floor((Math.max(bill, 100) - 1) / 100) * 1.5 + 3;
    return output * 1000;
  };

  const getSolarRecommendation = (recommendationType) => {
    if (!solarConfigs.length) {
      return 0;
    }
    switch (recommendationType) {
      case "min":
        return Math.floor(solarConfigs[0].yearlyEnergyDcKwh / 1000);
      case "ideal":
        const yearlyConsumption = calculateConsumptionFromMothlyBill(monthlyBill);

        const findNearestIndex = (array, targetValue) =>
          array.reduce(
            (acc, obj, index) =>
              Math.abs(obj.yearlyEnergyDcKwh - targetValue) <
              Math.abs(array[acc].yearlyEnergyDcKwh - targetValue)
                ? index
                : acc,
            0
          );

        const idealConfigIndex = findNearestIndex(
          solarConfigs,
          yearlyConsumption
        );

        if (idealConfigIndex > -1) {
          if (
            idealConfigIndex === solarConfigs.length &&
            solarConfigs[idealConfigIndex].yearlyEnergyDcKwh < yearlyConsumption
          ) {
            return Math.ceil(yearlyConsumption / 1000);
          }
          return Math.ceil(
            solarConfigs[idealConfigIndex].yearlyEnergyDcKwh / 1000
          );
        } else {
          return Math.ceil(yearlyConsumption / 1000);
        }
      case "max":
        return Math.ceil(
          solarConfigs[solarConfigs.length - 1].yearlyEnergyDcKwh / 1000
        );
      default:
        return 0;
    }
  };

  const calculateSavings = () => {
    return parseInt(
      (solarConfigs[currentConfigId] && solarConfigs[currentConfigId].yearlyEnergyDcKwh * 0.1 * 25) / 4.8
    );
  };

  const getSolarProvidersCount = async (region_id, region_name) => {
    try {
      const response = await BenchmarkServices.getProviderCountFromRegion(
        region_id,
        region_name
      );
      if (response.status) {
        setSolarProviderCount(response.data.total_provider);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getSolarRecommendationTitle = () => {
    const recommendedPowerGeneration = getSolarRecommendation("ideal") * 1000;
    const currentPowerGeneration =
      Math.ceil(solarConfigs[currentConfigId] && solarConfigs[currentConfigId].yearlyEnergyDcKwh / 1000) * 1000;

    let title = "";

    if (recommendedPowerGeneration > currentPowerGeneration) {
      title = "l_below_recommendation";
    } else if (currentPowerGeneration > recommendedPowerGeneration) {
      title = "l_surplus_recommendation";
    } else {
      title = "l_equilibre_energetique";
    }

    return title;
  };

  const getSolarRecommendationClass = () => {
    const recommendedPowerGeneration = getSolarRecommendation("ideal") * 1000;
    const currentPowerGeneration =
      Math.ceil(solarConfigs[currentConfigId] && solarConfigs[currentConfigId].yearlyEnergyDcKwh / 1000) * 1000;

    let className = "";

    if (recommendedPowerGeneration > currentPowerGeneration) {
      className = ""; //Add class for below generation
    } else if (currentPowerGeneration > recommendedPowerGeneration) {
      className = " ";
    } else {
      className = "";
    }

    return className;
  };

  const selectSolarPanelsAsPerKPI = (configType) => {
    switch(configType)
    {
      case "min":
        setCurrentSelectedKPI(1);
        updatePanelCalculations(0);
        break;
      case "ideal":
        setCurrentSelectedKPI(2);
        updatePanelCalculations(currentConfigId);
        break;
      case "max":
        setCurrentSelectedKPI(3);
        updatePanelCalculations(solarConfigs.length - 1);
        break;
      default:
        break;
    }
  };

  const handleGeocoding = async (addressValue, init = true) => {
    isGeoCodeProcessOngoing = true;
    Geocode.fromAddress(addressValue).then(
      (response) => {
        const placeId = response.results[0].place_id;
        const { lat, lng } = response.results[0].geometry.location;
        const { region_id, region_name } = selectSpaceRegion(
          response.results[0].address_components
        );
        const location = response.results[0].geometry.location;
        setCoordinates(location);
        const streetViewUrl = `https://maps.googleapis.com/maps/api/streetview?size=2500x2500&location=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAP_SECRET}`;
        setAddressImageURL(streetViewUrl)
        setSolarInfo({
          ...solarInfo,
          address: addressValue,
          region_id,
          region_name,
          lat,
          lng,
          place_id: placeId,
        });
        getSolarProvidersCount(region_id, region_name);
        setLocation({
          lat: Number(lat),
          lng: Number(lng),
        });
        showSolarPotential({ lat: Number(lat), lng: Number(lng) }, init).finally(
          () => {
            isGeoCodeProcessOngoing = false;
          }
        );
      },
      (error) => {
        console.error(error);
        isGeoCodeProcessOngoing = false;
        showError(_l("l_not_valid_address"));
        setAddressText("");
      }
    );
  };

  const getAddressWithoutSuggestion = () => {
    handleGeocoding(solarInfo.address);
  };

  const roundToNearestHalf = (number) => {
    return Math.round(number * 2) / 2;
  };
  
  const getSolarPanelsCount = () => {
    let panelCount = 0;
    
    switch (currentSelectedKPI) {
      case 1:
        panelCount = solarConfigs[0].panelsCount;
        break;
      case 2:
        panelCount = solarConfigs[currentConfigId].panelsCount;
        break;
      case 3:
        panelCount = solarConfigs[solarConfigs.length - 1].panelsCount;
        break;
      default:
        panelCount = 0;
        break;
    }

    return panelCount;
  };

  const handleClearMap = () => {
    setIsManualModeOn(false);
    setAllowToSelectCustomPanels(true);

    if (mapDataLayer) {
      mapDataLayer.setMap(null);
      setMapDataLayer(null);
    }

    solarPanels.forEach((x) => {
      if (x) {
        x.setMap(null);
      }
    });
    setSolarPanels([]);

    const center = map.getCenter();
    const clearedLocation = {
      lat: prevArea.lat,
      lng: prevArea.lng,
    };

    setClearedArea(clearedLocation);

    const marker = new window.google.maps.Marker({
      position: clearedLocation,
      map: map,
      title: 'Cleared Area',
    });

    setMarkers((prevMarkers) => [...prevMarkers, marker]);
  };

  useEffect(() => {
    if (clearedArea) {
      const marker = new window.google.maps.Marker({
        position: clearedArea,
        map: map,
        title: 'Previously Cleared Area',
      });

      setMarkers((prevMarkers) => [...prevMarkers, marker]);
    }
  }, [clearedArea]);

  useEffect(() => {
    if (map && addressText && addressText.trim().length) {
      if (addressQueryString) {
        handleGeocoding(addressQueryString);
        searchParams.delete("address");
        setSolarInfo({ ...solarInfo, address: addressQueryString });
        setSearchParams(searchParams);
      } else {
        map.setZoom(DEFAULT_VIEWPORT_ZOOM);
        try {
          autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            addressRef.current,
            addressOptions
          ).addListener("place_changed", () => {
            if (!isGeoCodeProcessOngoing) {
              //Used this state to prevent event multiple trigger on enter key press
              handleGeocoding(addressRef.current.value);
            }
          });
        } catch (e) {
          console.error(e);
        }
      }
    }
  }, [addressText, map]);

  useEffect(() => {
    if (monthlyBill > 0) {
      updatePanelCalculations();
    }
  }, [monthlyBill]);

  useEffect(() => {
    if (solarInfo.monthly_bill > 0 && hideAddressInput === true) {
      updatePanelCalculations();
      selectSolarPanelsAsPerKPI("ideal")
    }
  }, [])
  

  useEffect(() => {
    if (boxPoints.length >= 2) {
      if (currPolyline.current !== undefined) {
        currPolyline.current.setMap(null);
      }
      const newPolyline = new window.google.maps.Polyline({
        path: boxPoints,
        geodesic: false,
        strokeColor: "#FF0000",
      });
      newPolyline.setMap(mapRef.current);
      // newPolyline.addListener('click', () => {newPolyline.setMap(null);})
      currPolyline.current = newPolyline;
    } else {
      if (currPolyline.current !== undefined) {
        currPolyline.current.setMap(null);
      }
      if (boxPoints.length === 1) {
        solarPanels.forEach((panel) => panel.setMap(null));
        setShowUndoButtonForPolygon(true);
      }
    }
  });

  useEffect(() => {
    setUserInfo({
      ...userInfo,
      air_conditioner : air_conditioner,
      garage : garage,
      back_yard : back_yard,
      electronic_gadgets : electronic_gadgets,
    })
    if (solarInfo.is_edit_page) {
      setSolarInfo({
        ...solarInfo,
        air_conditioner : air_conditioner,
        garage : garage,
        back_yard : back_yard,
        electronic_gadgets : electronic_gadgets,

      })
    }
  }, [air_conditioner,garage,back_yard,electronic_gadgets])

  useEffect(() => {
    if (showInput) {
      setMonthlyBill();
      setMonthlyConsumption(0);
      setSliderCurrentValue(0);
      setSolarProviderCount(0);
      setBuildingInsightsData();
      setSolarPanels([]);
      setCurrentSelectedKPI(1);
    }
  }, [showInput])
  
  useEffect(() => {
    if (solarInfo.is_edit_page && map) {
      handleGeocoding(addressText, false);
    }
  }, [solarInfo.is_edit_page, map]);
  
  return (
    <div className="h-100 overflow-hidden position-relative rounded-2">
      <div className="d-flex">
      <div
            className={`align-items-center d-flex justify-content-center w-100 position-absolute z-index-2 top-0 p-35px  ${
              isSolarDataFetched && (solarPanels.length ||
                (buildingInsightsData &&
                  buildingInsightsData.solarPotential))
                  ? ""
                  : "d-none"
            }`}
          >
            <a
              className="btn btn-primary px-4"
              onClick={eraseAllVerticies}
            >
              {_l("l_change_address")}
            </a>
            <a
              className="btn btn-primary px-4 ms-20px"
              onClick={()=>{
                handleClearMap();
              }}
            >
              {_l("mode manuel")}
            </a>
            <a 
              className={`btn btn-primary px-4 ms-20px ${!isManualModeOn && "d-none"}`}
              id="RotateBtn" 
            >
              Rotate Panels
            </a>
          </div>
          </div>

      {isLoaded ? (
        <div className="onboarding-map h-100 hide-magnify MapMob d-flex flex-column">
          {currentConfigId > -1 ? (
            <React.Fragment>
              <div className="mapareabox w270px p-20 z-2 d-none">
                <div className="d-flex align-items-center justify-content-center">
                  <div>
                    <div className="c-font f-16 pb-20px title-fonts">
                      {_l("l_panels_count")}
                    </div>
                    <div className="CircularBar align-items-center d-flex justify-content-center position-relative">
                      <CircularProgressbar
                        value={20 * (currentConfigId + 1)}
                        text={`${20 * (currentConfigId + 1)}%`}
                      />
                      <div className="circularInner">
                        <Sun size={24} weight="light" className="c-icons" />
                      </div>
                    </div>
                    <div className="c-font f-14 title-fonts fblue pt-20px text-center">
                      <span className="fw-semibold">
                        {solarConfigs && solarConfigs[currentConfigId] && solarConfigs[currentConfigId].panelsCount}{" "}
                      </span>
                      <span>/ {maxPanelCountRef.current}</span>
                    </div>
                  </div>
                  <div>
                    <div className="c-font f-16 pb-20px title-fonts">
                      {_l("l_yearly_energy")}
                    </div>
                    <div className="CircularBar align-items-center d-flex justify-content-center position-relative">
                      <CircularProgressbar
                        value={calculatePercentageConsumption()}
                        text={`${calculatePercentageConsumption()}%`}
                      />
                      <div className="circularInner">
                        <Lightning
                          size={24}
                          weight="light"
                          className="c-icons"
                        />
                      </div>
                    </div>
                    <div className="c-font f-14 title-fonts fblue pt-20px text-center">
                      <span className="fw-semibold">
                        {parseInt(
                          solarConfigs[currentConfigId] && solarConfigs[currentConfigId].yearlyEnergyDcKwh / 1000
                        )}{" "}
                      </span>
                      <span className="c-font f-12">Kwc</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`right_panel mapareabox d-xl-flex   ${
                  isSolarDataFetched ? "" : "d-none"
                }`}
              >
                {/* Step 0 Alert to select the roofs */}
                <div className= {`align-items-start bg-white d-flex flex-column minw378px p-20 radius10 z-2 ${hideAddressInput ? "" : "d-none"} ${allowToSelectCustomPanels ? "" : "d-none"} ${solarPanels.length ||
                    (buildingInsightsData &&
                      buildingInsightsData.solarPotential) ? "d-none" : ""}`} >
                  <div className="fblue fw-bold title-fonts mb-15px">
                    <span className="me-1">1.</span>
                    <span>{_l("l_draw_polygon_around_roof")} </span>
                  </div>
                  {/* Reset button */}
                  <div
                    className={`align-items-center d-flex justify-content-end mt-10 w-100 ${
                      isSolarDataFetched ? "" : "d-none"
                    }`}
                  >
                    <a
                      className="btn btn-primary px-4  w-100"
                      onClick={eraseAllVerticies}
                    >
                      {_l("l_reset")}
                    </a>
                  </div>
                </div>
                {/* Step 1 Monthly Input */}
                <div
                  className={`align-items-start bg-white d-flex flex-column minw378px radius10 z-2 comman-spacing p-20 ${
                    isSolarDataFetched && (solarPanels.length ||
                    (buildingInsightsData &&
                      buildingInsightsData.solarPotential))
                      ? ""
                      : "d-none"
                  }`}
                >
                  <div className="fblue fw-bold title-fonts d-flex text-truncate">
                    <span className="me-1 comman-font">
                      {allowToSelectCustomPanels ? "1." : "1."}
                    </span>
                    <div className="text-truncate comman-font">
                    {_l("l_montant_mensuel")}{" "}
                    </div>
                  </div>
                  <div className="d-flex  justify-content-between w-100 flex-grow-1 ">
                    <div className="position-relative d-flex align-items-center border w-100 mt-10  radius10 title-fonts">
                      <div className="d-flex align-items-center flex-column w-100 commen-height h50px">
                        <input
                          type="text"
                          className="bg-transparent border-0 c-font f-24 fblue fw-semibold text-center h-100"
                          value={monthlyBill}
                          placeholder="0"
                          onChange={(e) =>
                            updateMonthlyConsumption(
                              e.target.value.replace(/\D/g, "")
                            )
                          }
                        />
                      </div>
                      <div className="c-font f-24 text-black fw-semibold pe-20pximp">
                        <span>€</span>
                      </div>
                    </div>
                  </div>
                  <div className="c-font f-10  text-center text-black opacity-60 pt-1">
                          soit {monthlyConsumption} Kwc mois
                        </div>
                  <div className="d-flex justify-content-between pt-15 w-100">
                    <div className="align-items-center d-flex ">
                      <img src={PiggyBank} className="h20w20 " />
                      <div className="ps-10px  title-fonts ">
                        {_l("l_potential_savings")}
                      </div>
                    </div>
                    <div className="title-fonts">
                      <span className="">€ {yearlyConsumptionSave || 0} /</span>
                      <span className="ms-1">{_l("l_months")}</span>
                    </div>
                  </div>
                  {/* Reset button */}
                  <div
                    className={`align-items-center d-flex justify-content-end mt-auto w-100 pt-15 d-none  ${
                      isSolarDataFetched && !allowToSelectCustomPanels ? "" : ""
                    }`}
                  >
                    <a
                      className="btn btn-primary px-4  w-100"
                      onClick={eraseAllVerticies}
                    >
                      {_l("l_change_address")}
                    </a>
                  </div>
                </div>
                <>
                  {/* Step 2 KPIs */}
                  <div className={`align-items-start bg-white d-flex flex-column minw378px comman-spacing p-20 radius10 z-2 maxw378 ${monthlyBill > 0 && hideAddressInput ? "" : "d-none"}`}>
                    <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="fblue fw-bold title-fonts d-flex">
                      <span className="me-1 comman-font">
                        {allowToSelectCustomPanels ? "2." : "2."}
                      </span>
                      <div className="ms-1 comman-font">
                      {_l("l_notre_recommandation")}
                      </div>
                    </div>
                    <div className="form-check form-check-inline m-0 d-flex align-items-center">
                      <div className="c-font f-11 pe-2">Personalisé</div>
                      <Form.Check
                        className=" with-no-label-space"
                        type="switch"
                        role="switch"
                        onChange={()=> setShowSliderBar(!showSliderBar)}
                      />
                      {/* <div className="c-font f-11 ps-2">Lorem</div> */}
                      </div>
                    </div>
                    
                    {/* New KPI Start */}

                  {!showSliderBar ? 
                  <React.Fragment>
                      <div className="d-flex text-center gap-3 w-100 align-items-center h100px">
                      {
                        monthlyBill > 100
                        ?
                        <a className="pt-10px flex-grow-1 h-100 d-flex flex-column justify-content-center count-box" onClick={() => {selectSolarPanelsAsPerKPI("min")}}>
                          <div className="align-items-center d-flex h-100 ">
                            <div
                              className={`${currentSelectedKPI === 1 || (solarConfigs[currentConfigId] && solarConfigs[currentConfigId].yearlyEnergyDcKwh /1000) == (solarConfigs[0].yearlyEnergyDcKwh /1000)
                              ? "activeblueborder" : ""} border  fw-bold comman-spacing p-10 text-center title-fonts  radius_5 flex-grow-1 d-flex flex-column justify-content-center `}
                            >
                              <div className="d-flex align-items-center justify-content-center">
                              <div className="c-font f-24 lh-sm fcolorblue">
                                {roundToNearestHalf(
                                  solarConfigs[0].yearlyEnergyDcKwh /
                                    1000
                                )}
                              </div>
                              <sub className="c-font f-10 fw-normal fcolorblue"> <span className="ps-1"> / </span><span>Kwc</span></sub>
                              </div>
                            </div>
                          </div>
                          {/* <div className={`${getSolarRecommendationClass()} pt-10 c-font f-10 text-truncate `}</div> */}
                            <div
                            className={`fcolorblue pt-10 c-font f-10 text-truncate `}
                          >
                            {_l("l_minimum_energetique")}
                          </div>
                        </a>
                        :
                        <></>
                      }
                      <a className="pt-10px flex-grow-1 h-100 d-flex flex-column justify-content-center count-box" onClick={() => {selectSolarPanelsAsPerKPI("ideal")}}>
                        <div className="align-items-center d-flex h-100 ">
                        <div
                          className={`${currentSelectedKPI === 2 || (solarConfigs[0].yearlyEnergyDcKwh / 1000 ) > (solarConfigs[currentConfigId] && solarConfigs[currentConfigId].yearlyEnergyDcKwh /1000) && (solarConfigs[solarConfigs.length - 1].yearlyEnergyDcKwh / 1000 )  > (solarConfigs[currentConfigId].yearlyEnergyDcKwh /1000)? "activegreenborder " : ""} border flex-grow-1 fw-bold comman-spacing p-10 text-center title-fonts radius_5 d-flex flex-column justify-content-center `}
                        >
                          <div className="d-flex align-items-center justify-content-center">
                          <div className="c-font f-24 lh-sm fcolorgreen">
                            {roundToNearestHalf(
                             solarConfigs[currentConfigId] && solarConfigs[currentConfigId].yearlyEnergyDcKwh /
                                1000
                            )}
                          </div>
                          <sub className="c-font f-10 fw-normal fcolorgreen"> <span className="ps-1 fcolorgreen"> / </span><span>Kwc</span></sub>
                          </div>
                        </div>
                        </div>
                        <div
                          className={`${currentSelectedKPI === 3 ? "activegreenborder" : ""} pt-1 c-font f-10 text-truncate fcolorgreen maxw100px `}>
                          {_l(getSolarRecommendationTitle())}
                        </div>
                      </a>
                      <a className="pt-10px flex-grow-1 h-100 d-flex flex-column justify-content-center count-box" onClick={() => {selectSolarPanelsAsPerKPI("max")}}>
                        <div className="align-items-center d-flex h-100 ">
                        <div
                          className={`${currentSelectedKPI === 3 || (solarConfigs[currentConfigId] && solarConfigs[currentConfigId].yearlyEnergyDcKwh /1000) == (solarConfigs[solarConfigs.length - 1].yearlyEnergyDcKwh /1000)? "activeorangeborder" : ""} border flex-grow-1 fw-bold comman-spacing p-10 text-center title-fonts radius_5 d-flex flex-column justify-content-center `}
                        >
                           <div className="d-flex align-items-center justify-content-center">
                          <div className="c-font f-24 lh-sm fcolororange">
                            {roundToNearestHalf(
                              solarConfigs[solarConfigs.length - 1].yearlyEnergyDcKwh /
                                1000
                            )}
                          </div>
                          <sub className="c-font f-10 fw-normal fcolororange"> <span className="ps-1 "> / </span><span>Kwc</span></sub>
                          </div>
                        </div>
                        </div>
                        {/* <div
                          className={`${getSolarRecommendationClass()} pt-1 c-font f-10 text-truncate `}
                        ></div> */}
                        <div
                          className={`fcolororange pt-1 c-font f-10 text-truncate `}
                        >
                          {_l("l_maximum_capacity")}
                        </div>
                      </a>
                    </div>
                     
                      </React.Fragment> :
                    <div className="d-flex align-items-center h100px w-100">
                      <div className="w-100">
                      {
                        showSliderBar
                        &&
                        <RangeSlider
                          handleChangeVertical={handleSliderMovement}
                          verticalLabels={getSliderLabels()}
                          currentValue={sliderCurrentValue}
                          sliderMinValue={3000}
                          sliderMaxValue={
                            solarConfigs[solarConfigs.length - 1]
                              .yearlyEnergyDcKwh
                          }
                        />
                      }
                      </div>
                    </div>
                    }
                    <div className="d-flex justify-content-between pt-15 w-100">
                        <div className="align-items-center d-flex ">
                          <img src={solarpanelicon} className="h20w20 " />
                          <div className="ps-10px  title-fonts ">
                            {_l("l_number_of_panel")}
                          </div>
                        </div>
                        <div className="title-fonts">
                          {getSolarPanelsCount()}  {_l("l_panels")}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-2 w-100">
                        <div className="align-items-center d-flex ">
                          <Lightning
                            size={18}
                            weight="light"
                            className="c-icons"
                          />
                          <div className="ps-10px  title-fonts ">
                          Alimentation par panneau
                          </div>
                        </div>
                        <div className="title-fonts">
                        <span
                          title={`${Number((((solarConfigs[currentConfigId].panelsCount * 400) / 1000) / solarConfigs[currentConfigId].panelsCount).toFixed(1))} * ${solarConfigs[currentConfigId].panelsCount} = ${(solarConfigs[currentConfigId].panelsCount * 400) / 1000} / Kwc`}
                        >
                          {Number((((solarConfigs[currentConfigId].panelsCount * 400) / 1000) / solarConfigs[currentConfigId].panelsCount).toFixed(1))} Kwc
                        </span>
                        </div>
                      </div>

                    {/* New KPI End */}
                    {/* <div className="d-flex justify-content-between  text-center">
                      <div className="">
                        <div className="fblue fw-bold text-center title-fonts">
                          <div className="c-font f-38">
                            {getSolarRecommendation("min")}
                          </div>
                          <div> kwh</div>
                        </div>
                        <div className="fblue60 pt-10px c-font f-12">
                          {_l("l_minimum_energetique")}
                        </div>
                      </div>
                      <div className="">
                        <div className="color-greenpill fw-bold text-center title-fonts">
                          <div className="c-font f-38">
                            {getSolarRecommendation("ideal")}
                          </div>{" "}
                          <div>kwh</div>
                        </div>
                        <div className="fblue60 pt-10px c-font f-12">
                          {_l("l_equilibre_energetique")}
                        </div>
                      </div>
                      <div className="">
                        <div className="color-orange fw-bold text-center title-fonts">
                          <div className="c-font f-38">
                            {getSolarRecommendation("max")}
                          </div>{" "}
                          <div>kwh</div>
                        </div>
                        <div className="fblue60 pt-10px">
                          {_l("l_surplus_energetique")}
                        </div>
                      </div>
                    </div> */}
                    {/* Old KPI End */}
                  </div>
                  
                  {/* Step 3 Range Slider */}
                  {/* <div className={` align-items-start bg-white d-flex flex-column minw378px p-20 radius10 z-2  ${monthlyBill > 0 ? "" : "d-none"}`}>
                    {/* <input type="range" className="" id="customRange1" /> * /}
                    <div className="pb-20px">
                      <RangeSlider
                        handleChangeVertical={handleSliderMovement}
                        verticalLabels={getSliderLabels()}
                        currentValue={sliderCurrentValue}
                        sliderMinValue={3000}
                        sliderMaxValue={
                          solarConfigs[solarConfigs.length - 1]
                            .yearlyEnergyDcKwh
                        }
                      />
                    </div>
                  </div> */}

                  {/* Step 4 battery */}
                  <div className={`align-items-start bg-white d-flex flex-column minw378px maxw400px comman-spacing p-20 radius10 z-2 ${monthlyBill > 0 && hideAddressInput ? "" : "d-none"}`}>
                    <div className="fblue fw-bold title-fonts d-flex">
                      <span className="me-1 comman-font">{allowToSelectCustomPanels ? "3." : "3."}</span>
                     <div className="ms-1 comman-font">Quels appareils ou zones votre installation solaire alimentera-t-elle?</div>
                    </div>
                    <div className="row mb-4 g-0 w-100">
                      <div className="col-lg-5 mt-3">
                        <div class="d-flex form-check">
                          <div class="pe-2">
                            <input
                              className="form-check-input light-theme-border light-theme-white-bg box-shadow-none"
                              type="checkbox"
                              value={air_conditioner == 1 ? true : false}
                              id="ac"
                              checked={air_conditioner == 1}
                              onChange={()=>{
                                setAir_conditioner(air_conditioner == 0 ? 1 : 0);
                              }}

                            />
                          </div>
                          <label class="c-font f-12 form-check-label fw-semibold" for="ac">Climatiseur</label>
                        </div>
                      </div>
                      <div className="col-lg-7 mt-3">
                        <div class="d-flex form-check">
                          <div class="pe-2">
                            <input
                              className="form-check-input light-theme-border light-theme-white-bg box-shadow-none"
                              type="checkbox"
                              value={garage == 1 ? true : false}
                              checked={garage == 1}
                              id="garage"
                              onChange={()=>{
                                setGarage(garage == 0 ? 1 : 0);
                              }}
                            />
                          </div>
                          <label
                            class="c-font f-12 form-check-label fw-semibold"
                            for="garage"
                          >
                            Garage
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-5 mt-3">
                        <div class="d-flex form-check">
                          <div class="pe-2">
                            <input
                              className="form-check-input light-theme-border light-theme-white-bg box-shadow-none"
                              type="checkbox"
                              value={back_yard == 1 ? true : false}
                              checked={back_yard == 1}
                              id="backyard"
                              onChange={()=>{
                                setBack_yard(back_yard == 0 ? 1 : 0)
                              }}
                            />
                          </div>
                          <label
                            class="c-font f-12 form-check-label fw-semibold"
                            for="backyard"
                          >
                            Arrière-cour
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-7 mt-3">
                        <div class="d-flex form-check">
                          <div class="pe-2">
                            <input
                              className="form-check-input light-theme-border light-theme-white-bg box-shadow-none"
                              type="checkbox"
                              value={electronic_gadgets == 1 ? true : false}
                              checked ={electronic_gadgets == 1}
                              id="other garage"
                              onChange={()=>{
                                setElectronic_gadgets(electronic_gadgets == 0 ? 1 : 0)
                              }}

                            />
                          </div>
                          <label
                            class="c-font f-12 form-check-label fw-semibold"
                            for="other garage"
                          >
                            Autres gadgets électroniques
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column h-100 w-100 mb-3 d-none">
                      <div className="d-flex gap-3 pt-10px text-center w-100 h-100 ">
                        <div className="space-select-box select-input-cox commen-height radius_5 shadow-none bg-transparent flex-grow-1 w-auto">
                          <input
                            className="bg-transparent bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100"
                            name="provider-type"
                            type="radio"
                            value={"1"}
                            onChange={(e)=>{
                              setSolarInfo({
                                ...solarInfo,
                                is_installation_urgent: true
                              })
                            }}
                          />
                          <a href="#/" className="check-image top-50 translate-middle-y">
                            <div className="check-image-box">
                              <Check size={14} className="c-icons color-white-03-solid" />
                            </div>
                          </a>
                          
                            <div className="align-items-center c-font d-flex f-18 fw-semibold h-100 justify-content-center lh-sm m-auto  w-100 title-fonts">
                            - de 3 mois
                            </div>
                        </div>
                        <div className="space-select-box select-input-cox commen-height radius_5 shadow-none bg-transparent flex-grow-1 w-auto">
                          <input
                            className="bg-transparent bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100"
                            name="provider-type"
                            type="radio"
                            value={"0"}
                            onChange={(e)=>{
                              setSolarInfo({
                                ...solarInfo,
                                is_installation_urgent: false
                              })
                            }}
                          />
                          <a href="#/" className="check-image top-50 translate-middle-y">
                            <div className="check-image-box">
                              <Check size={14} className="c-icons color-white-03-solid" />
                            </div>
                          </a>
                          
                            <div className="align-items-center c-font d-flex f-18 fw-semibold h-100 justify-content-center lh-sm m-auto  w-100 title-fonts">
                            + de 3 mois
                            </div>
                        </div>
                      </div>
                    </div>
                    


                    {/* <div className="mt-10px mb-10px w-100 ">
                      <div className="d-flex justify-content-between w-100 ">
                        <div className="d-flex pe-15px">
                          <CurrencyDollar
                            size={18}
                            weight="light"
                            className="c-icons mt-0 line-height-0"
                          />
                          <div className="ps-10px lh-sm  title-fonts me-2">
                            {_l("l_do_you_need_a_battery")}
                          </div>
                        </div>
                        <div className="h20w20">
                                <Form.Check
                                  type="checkbox"
                                  className="h20w20"
                                  onChange={(e) =>
                                    setSolarInfo({
                                      ...solarInfo,
                                      number_of_batteries: e.target.checked
                                        ? 1
                                        : 0,
                                    })
                                  }
                                />
                        </div>
                      </div>
                    </div> */}
                    {/* step 5 */}
                    <div className="d-flex flex-column w-100">
                    <div className="fblue fw-bold title-fonts  d-none">
                      <span className="me-1">
                        {allowToSelectCustomPanels ? "4." : "4."}
                      </span>
                      {_l("l_details_your_simulation")}
                    </div>
                    <div className="mt-10px w-100 d-none ">
                      <div className="d-flex justify-content-between">
                        <div className="align-items-center d-flex ">
                          <img src={solarpanelicon} className="h20w20 " />
                          <div className="ps-10px  title-fonts ">
                            {_l("l_number_of_panel")}
                          </div>
                        </div>
                        <div className="title-fonts">
                          {solarConfigs[currentConfigId].panelsCount}  {_l("l_panels")}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-15px d-none">
                        <div className="align-items-center d-flex text-truncate ">
                          <img src={PiggyBank} className="h20w20 " />
                          <div className="ps-10px text-truncate title-fonts ">
                            {_l("l_saving_on_25_years")}{" "}
                          </div>
                        </div>
                        <div className="title-fonts">{calculateSavings()}€</div>
                      </div>
                      <div className="d-flex justify-content-between pt-15px d-none">
                        <div className="align-items-center d-flex text-truncate ">
                          <CurrencyDollar
                            size={18}
                            weight="light"
                            className="c-icons"
                          />
                          <div className="ps-10px text-truncate title-fonts ">
                            {_l("l_solar_providers")}
                          </div>
                        </div>
                        <div className="title-fonts">{solarProviderCount}</div>
                      </div>
                      <div className="d-flex justify-content-between pt-15px d-none">
                        <div className="align-items-center d-flex text-truncate ">
                          <CurrencyDollar
                            size={18}
                            weight="light"
                            className="c-icons"
                          />
                          <div className="ps-10px text-truncate title-fonts ">
                            {_l("l_fair_price")}
                          </div>
                        </div>
                        <div className="title-fonts">
                          <span>11600 €</span>
                          <span> ≈ </span>
                          <span> 12,305 €</span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex form-check mt-15px d-none">
                      <div className="pe-2">
                        <input
                          className="form-check-input light-theme-border light-theme-white-bg"
                          type="checkbox"
                          onChange={(e) =>
                            setSolarInfo({
                              ...solarInfo,
                              is_installation_urgent: e.target.checked ? 1 : 0,
                            })
                          }
                        />
                      </div>
                      <label
                        className="form-check-label c-font f-14"
                        for="flexCheckDefault"
                      >
                        {_l("l_is_urgent_schedule")}
                      </label>
                    </div>
                    {/* Final Button */}
                  <div
                    className={`align-items-center d-flex justify-content-end mt-auto w-100 ${
                      monthlyBill > 0 ? "" : "d-none"
                    }`}
                  >
                    <a
                      onClick={() => handleNextStep()}
                      className="btn  w-100 px-4 btnyellow"
                    >
                      {_l("l_next")}
                    </a>
                  </div>
                    </div>
                  </div>
                  {/* Step 5 Calculations */}
                  <div className={`align-items-start bg-white d-flex flex-column minw378px p-20 radius10 z-2 d-none ${monthlyBill > 0 ? "" : "d-none"}`}>
                    <div className="fblue fw-bold title-fonts">
                      <span className="me-1">
                        {allowToSelectCustomPanels ? "4." : "4."}
                      </span>
                      {_l("l_details_your_simulation")}
                    </div>
                    <div className="mt-10px w-100">
                      <div className="d-flex justify-content-between">
                        <div className="align-items-center d-flex ">
                          <img src={solarpanelicon} className="h20w20 " />
                          <div className="ps-10px  title-fonts ">
                            {_l("l_number_of_panel")}
                          </div>
                        </div>
                        <div className="title-fonts">
                          {solarConfigs[currentConfigId].panelsCount} panels
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-15px d-none">
                        <div className="align-items-center d-flex text-truncate ">
                          <img src={PiggyBank} className="h20w20 " />
                          <div className="ps-10px text-truncate title-fonts ">
                            {_l("l_saving_on_25_years")}{" "}
                          </div>
                        </div>
                        <div className="title-fonts">{calculateSavings()}€</div>
                      </div>
                      <div className="d-flex justify-content-between pt-15px d-none">
                        <div className="align-items-center d-flex text-truncate ">
                          <CurrencyDollar
                            size={18}
                            weight="light"
                            className="c-icons"
                          />
                          <div className="ps-10px text-truncate title-fonts ">
                            {_l("l_solar_providers")}
                          </div>
                        </div>
                        <div className="title-fonts">{solarProviderCount}</div>
                      </div>
                      <div className="d-flex justify-content-between pt-15px d-none">
                        <div className="align-items-center d-flex text-truncate ">
                          <CurrencyDollar
                            size={18}
                            weight="light"
                            className="c-icons"
                          />
                          <div className="ps-10px text-truncate title-fonts ">
                            {_l("l_fair_price")}
                          </div>
                        </div>
                        <div className="title-fonts">
                          <span>11600 €</span>
                          <span> ≈ </span>
                          <span> 12,305 €</span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex form-check mt-15px d-none">
                      <div className="pe-2">
                        <input
                          className="form-check-input light-theme-border light-theme-white-bg"
                          type="checkbox"
                          onChange={(e) =>
                            setSolarInfo({
                              ...solarInfo,
                              is_installation_urgent: e.target.checked ? 1 : 0,
                            })
                          }
                        />
                      </div>
                      <label
                        className="form-check-label c-font f-14"
                        for="flexCheckDefault"
                      >
                        {_l("l_is_urgent_schedule")}
                      </label>
                    </div>
                    {/* Final Button */}
                  <div
                    className={`align-items-center d-flex justify-content-end mt-15px w-100 ${
                      monthlyBill > 0 ? "" : "d-none"
                    }`}
                  >
                    <a
                      onClick={() => handleNextStep()}
                      className="btn  w-100 px-4 btnyellow"
                    >
                      {_l("l_next")}
                    </a>
                  </div>
                  </div>
                  
                </>
              </div>

              {/* <div>
                <RangeSlider
                  handleChangeVertical={handleSliderMovement}
                  verticalLabels={getSliderLabels()}
                  currentValue={20 * (currentConfigId + 1)}
                />
              </div> */}
            </React.Fragment>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          <div className={isMapInformationFetching ? "" : "d-none"}>
          <div
              className={`solarloader d-flex align-items-center justify-content-center flex-column opacity-50`}
            >
              
              </div>
            <div className="Maploader">
            <div className="logohead">
          <img className="h50px" src={Solarhublogo} />
        </div>
              <div className="w600 d-flex align-items-center justify-content-center flex-column pt-lg-5">
                <div className="pt-40px d-flex w-100">
                  <div className="loading-bar">
                    <div className="progress-bar"></div>
                  </div>
                </div>
                </div>
            
            </div>
          </div>
          <div className="end-0 position-sticky start-0 bottom-0 w-100 z-index-2">
            <div className="row justify-content-center">
              <div className="col-xl-12 c-input-box  position-relative">
                {showUndoButtonForPolygon ? (
                  <div className="d-flex align-items-center fixedmapicon">
                    <a
                      href="#"
                      className="bg-white d-flex h50w50 me-20px radius_3 rounded-circle d-none"
                      onClick={eraseAllVerticies}
                    >
                      <ArrowsCounterClockwise
                        size={24}
                        weight="light"
                        className="c-icons"
                      />
                    </a>
                    <a
                      href="#"
                      className="btn btn-primary d-flex d-none radius_3"
                      onClick={eraseAllVerticies}
                    >
                      {_l("l_reset")}
                    </a>
                  </div>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
                <div className="mapdirection">
                  <div className="align-items-center bg-white d-flex h50w50 justify-content-center  radius_3 rounded-circle">
                    <img src={mappin} className="h35w35 mb-1" />
                  </div>
                </div>
                {hideAddressInput ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <div className="map-input-box">
                    <Form.Group
                      className={`c-input-box position-relative w-100`}
                    >
                      <div className="d-flex align-items-center">
                        <input
                          className="c-font f-16 form-control ps-0 py-xxl-3 regFormInput w100minus50"
                          placeholder={_l("l_address_placeholder")}
                          type="text"
                          name=""
                          onChange={(e) => {
                            setAddressText(e.target.value);
                            setSolarInfo({
                              ...solarInfo,
                              address: e.target.value,
                            });
                          }}
                          ref={addressRef}
                          defaultValue={solarInfo.address}
                          autoFocus
                        />

                        <a
                          href="#/"
                          className={`${
                            isMapInformationFetching
                              ? "for-disabled opacity-50"
                              : ""
                          } align-items-center btn btn-primary c-font d-flex f-20 fw-semibold h50w50 light-theme-blue-bg position-relative rounded-circle text-nowrap`}
                          onClick={
                            !isMapInformationFetching ? () => {getAddressWithoutSuggestion()} : () => {}
                          }
                        >
                          {/* <span className="me-10px text-capitalize">
                                {" "}
                                {isMapInformationFetching
                                  ? _l("l_please_wait")
                                  : _l("l_search")}
                              </span> */}
                          <MagnifyingGlass
                            size={18}
                            className="c-icons"
                            weight="light"
                          />
                        </a>
                      </div>
                    </Form.Group>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="commen-height flex-grow-1" ref={mapScreenRef}>
            <GoogleMap
              onLoad={onMapLoad}
              mapContainerStyle={containerStyle}
              zoom={DEFAULT_VIEWPORT_ZOOM}
              center={mapCenter}
              onClick={(e) => {
                if (allowToSelectCustomPanels) {
                  addBoxPoint(e.latLng.toJSON());
                }
              }}
              options={{
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
                disableDefaultUI: true,
                mapTypeId: "satellite",
                tilt: 9,
                heading: 9,
              }}
            >
              {boxPoints.length > 0 &&
                boxPoints.map((coordinates, index) => (
                  <Marker
                    key={index}
                    position={coordinates}
                    icon={dotIcon}
                    onClick={() => {
                      if (index === 0 && boxPoints.length >= 3) {
                        polygonVertices = boxPoints;
                        setBoxPoints([]);
                        drawCustomPanels(boxPoints);
                      }
                    }}
                  />
                ))}
              {/* <StreetViewPanorama
                position={coordinates}
                visible={coordinates != null}
                options={{ 
                  pov: { heading: 100, pitch: 0 },
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                  disableDefaultUI: true,
                  motionTracking: false,
                  motionTrackingControl: false
            
                 }}
              /> */}
            </GoogleMap>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
});

export default MapScreen;
