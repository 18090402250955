import React from 'react'
const CRMSkeleton = () => {
  return (
    <>
    <div className="mx-auto d-grid gap-3  padding-bottom-80px">
      {
        [0,1,2,3].map((s, i) =>{
          return(<>
          <div className='SkeletonMain bg-white-03 comman-white-box-shadow d-flex radius_3' key={i}>
        <div className='radius_3 skeleton-animation w25per' style={{ height: '150px', width: '190px',margin: '5px' }}></div>
        <div className='p-20px flex-grow-1'>
          <div className="d-flex align-items-center justify-content-between mb20px pb-1">
            <div className='flex-grow-1 d-flex flex-column justify-content-center'>
              <div className='mb-2 skeleton-animation radius_3' style={{ width: '40%', height: '15px' }}></div>
              <div className='skeleton-animation radius_3' style={{ width: '25%', height: '10px' }}></div>
            </div>
            <div className='radius_3 skeleton-animation h32wauto' style={{ width: '110px' }}></div>
          </div>
          <div className='align-items-md-center align-items-start d-flex justify-content-between'>
            <div className='d-flex flex-column justify-content-center' style={{ width: '150px', }}>
              <div className='mb-2 skeleton-animation radius_3' style={{ width: '100%', height: '15px' }}></div>
              <div className='skeleton-animation radius_3' style={{ width: '60%', height: '10px' }}></div>
            </div>
            <div className='with_separator_10 p-0'></div>
            <div className='d-flex flex-column justify-content-center' style={{ width: '150px', }}>
              <div className='mb-2 skeleton-animation radius_3' style={{ width: '100%', height: '15px' }}></div>
              <div className='skeleton-animation radius_3' style={{ width: '60%', height: '10px' }}></div>
            </div>
            <div className='with_separator_10 p-0'></div>
            <div className='d-flex flex-column justify-content-center' style={{ width: '150px', }}>
              <div className='mb-2 skeleton-animation radius_3' style={{ width: '100%', height: '15px' }}></div>
              <div className='skeleton-animation radius_3' style={{ width: '60%', height: '10px' }}></div>
            </div>
          </div>
        </div>
      </div>
          </>)
        })
      }

      
    </div>
    </>
  )
}
export default CRMSkeleton