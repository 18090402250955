import React from "react";
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { _l } from "../../hooks/utilities";
import { CaretRight, Envelope } from "phosphor-react";

const CustomPhoneInput = ({ value, onChange, handleNextStep }) => {
  return (
    <div className="col-12 col-xl-9 col-xxl-8 registration-content flex-grow-1 mx-auto row mb-70">
      <div className="col-lg-2"></div>
      <div className="align-items-center col-lg-7 d-flex mx-auto">
        <Form.Group className="c-input-box position-relative w-100 ">
          <label class="c-font f-11 fw-semibold text-uppercase title-fonts cblue70">
            {_l("l_phone_number")}
          </label>
          <div className="inputWrapper d-flex align-items-center pb-15px">
            <div className="with_separator_10 pe-20pximp iconabsolute opacity-0">
              <Envelope size={24} weight="light" className="c-icons mx-0" />
            </div>
            <div className="p-15 pb-0 pe-0 position-relative pt-0 flaginput w-100 position-relative ">
              <PhoneInput
                autoFormat={true}
                disableCountryCode={false}
                disableCountryGuess={true}
                countryCodeEditable={false}
                localization={""}
                country="fr"
                preferredCountries={["fr", "de"]}
                value={value}
                onChange={onChange}
                onEnterKeyPress={handleNextStep}
                inputProps={{
                  autoFocus: true,
                }}
              />
              <div className="comman_action_icon absolutebtn top-0">
                <a
                  className="action_icon d-flex h40w40  btn-primary shadow-none "
                  onClick={handleNextStep}
                >
                  <CaretRight size={18} weight="light" className="c-icons" />
                </a>
              </div>
            </div>
          </div>
        </Form.Group>
      </div>
      <div className="col-lg-2"></div>
    </div>
  );
};

export default CustomPhoneInput;
