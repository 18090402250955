import React from 'react';
// import CSS from '../css/icon_main.css';

function SpeakerSlashIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"  className={`comman-icons ${CommanHW}`}>
<g clipPath="url(#clip0_15359_259070)">
<g clipPath="url(#clip1_15359_259070)">
<path d="M13.6812 4.81836C14.0996 5.23578 14.4316 5.73163 14.6581 6.27751C14.8845 6.8234 15.0011 7.4086 15.0011 7.99961C15.0011 8.59062 14.8845 9.17582 14.6581 9.7217C14.4316 10.2676 14.0996 10.7634 13.6812 11.1809"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5 5.5V10.5"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.9125 6.58789C12.0985 6.77313 12.246 6.99325 12.3467 7.23565C12.4473 7.47804 12.4992 7.73792 12.4992 8.00039C12.4992 8.26286 12.4473 8.52274 12.3467 8.76513C12.246 9.00753 12.0985 9.22766 11.9125 9.41289"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3 2.5L13 13.5"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.5 9.6502V14.0002L5 10.5002H2C1.86739 10.5002 1.74021 10.4475 1.64645 10.3537C1.55268 10.26 1.5 10.1328 1.5 10.0002V6.0002C1.5 5.86759 1.55268 5.74041 1.64645 5.64664C1.74021 5.55287 1.86739 5.5002 2 5.5002H5L5.425 5.16895"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.01251 3.9375L9.50001 2V6.675"  strokeLinecap="round" strokeLinejoin="round"/>
</g>
</g>
<defs>
<clipPath id="clip0_15359_259070">
<rect width="16" height="16" />
</clipPath>
<clipPath id="clip1_15359_259070">
<rect width="16" height="16" />
</clipPath>
</defs>
</svg>


		</React.Fragment>
	)
}

export default SpeakerSlashIcon