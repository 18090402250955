import {
  ChatCircle,
  Check,
  FileText,
  MapPin,
  ShieldCheck,
  Star,
  X,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import {
  _l,
  getFileType,
  hideLeftPanelNew,
  openNewChatChannel,
  removeModifiedClass,
  roundOffPowerValue,
  showError,
} from "../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import SignatureModal from "./SignatureModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SolarDocumentPreview from "./SolarDocumentPreview";
import MapIframe from "./MapIframe";
import { Congratulation } from "./Congratulation";
import solarpanelsimg from "../../assets/images/solarpanelsimg.svg";
import accomptecalender from "../../assets/images/accomptecalender.svg";
import moment from "moment";
import Select from "react-select";
import Lightbox from "yet-another-react-lightbox";
import documentsServices from "../../services/documents-services";
import { useNavigate } from "react-router-dom";
import SolarFooter from "./SolarComponents/SolarFooter";
import customerServices from "../../services/customer-services";
import {
  fetchMessages,
  setChatGroupsList,
  setChatGroupsListDetails,
  setClearmessage,
  setactiveChatScreenId,
} from "../../actions/chat";
import chatServices from "../../services/chat-services";

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CustomerProposalDetail = ({
  proposal,
  rejectProposal,
  fetchTasks,
  handleNextStep,
  isExternal,
}) => {
  const { benchmarkDetail } = useSelector((state) => state.customer);
  const { chatGroups } = useSelector((state) => state.chat);
  const dispatch = useDispatch();

  let proposalNew =
    benchmarkDetail.proposal_list && benchmarkDetail.proposal_list.length
      ? benchmarkDetail.proposal_list[0].proposals.find(
          (w) => w.proposal_id == benchmarkDetail.proposal_id
        )
      : {};
  const [termsAndConditionAccepted, setTermsAndConditionAccepted] =
    useState(false);
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [viewingOneTimePrice, setViewingOneTimePrice] = useState(true);
  const [openLightBox, setOpenLightBox] = useState(false);
  const [showCongratulationsStep, setShowCongratulationsStep] = useState(false);
  let aidTitle = "";
  const [numberOfPlates, setNumberOfPlates] = useState(
    roundOffPowerValue(benchmarkDetail.solar_items.electricity_production) > 0
      ? roundOffPowerValue(benchmarkDetail.solar_items.electricity_production)
      : ""
  );
  const [maintainance, setMaintainance] = useState(
    proposal.maintenance > 0 ? Number(proposal.maintenance) : ""
  );
  const [guarantee, setGuarantee] = useState(
    proposal.garantie > 0 ? Number(proposal.garantie) : ""
  );
  const [openGallery, setOpenGallery] = useState(false);
  const [attachmentUrl, setAttachmentUrl] = useState([]);
  const [monthalySaving, setMonthalySaving] = useState("00");

  const VATselectionOption = [
    { label: "0%", value: "0" },
    { label: "5%", value: "5" },
    { label: "10%", value: "10" },
    { label: "20%", value: "20" },
  ];
  const lightBoxData = [
    {
      src: benchmarkDetail.solar_map,
      type: getFileType(benchmarkDetail.solar_map),
    },
  ];
  const accessoriesList =
    proposal && proposal.proposal_item
      ? proposal.proposal_item.map((w) => {
          w.title = w.name || "";
          w.one_time_price = w.one_time_amount;
          return w;
        })
      : [];
  if (accessoriesList && accessoriesList.length) {
    accessoriesList.forEach((acc) => {
      if (acc.is_goverment == 1) {
        proposal.goverment_help = !isNaN(Number(acc.one_time_amount))
          ? Number(acc.one_time_amount)
          : 0;
        aidTitle = acc.name;
      }
    });
  }

  const handleAcceptProposal = () => {
    if (!termsAndConditionAccepted) {
      showError("l_please_accept_terms_and_conditions");
      return;
    } else {
      setShowSignatureModal(true);
    }
  };

  const goToDiscussion = () => {
    localStorage.setItem("currentProposal", JSON.stringify(proposal));
    // navigate(`/aichat?proposal_id=${proposal.proposal_id}`)
    // window.open(`/aichat?proposal_id=${proposal.proposal_id}`, "_blank");
    openNewChatChannel(proposal.proposal_id);
  };

  const getTotalPayableAmount = () => {
    if (viewingOneTimePrice) {
      let totalAmount =
        Number(proposal.amount) +
        accessoriesList
          .filter((a) => a.is_goverment != 1)
          .reduce(
            (total, accessory) =>
              Number(total) + Number(accessory.one_time_price),
            0
          );
      if (totalAmount > 0 && !isNaN(Number(proposal.goverment_help))) {
        return totalAmount - Number(proposal.goverment_help);
      } else {
        return 0;
      }
    } else {
      let totalAmount =
        Number(proposal.monthly_amount) * Number(proposal.number_of_month) +
        accessoriesList
          .filter((a) => a.is_goverment != 1)
          .reduce(
            (total, accessory) =>
              Number(total) +
              Number(accessory.number_of_month) *
                Number(accessory.monthly_price),
            0
          );
      if (totalAmount > 0 && !isNaN(Number(proposal.goverment_help))) {
        return totalAmount - Number(proposal.goverment_help);
      } else {
        return 0;
      }
    }
  };

  const calculateSolarhubFees = () => {
    let totalAmount = 0;
    if (accessoriesList) {
      accessoriesList.forEach((accessory) => {
        if (
          accessory.is_goverment != 1 &&
          !isNaN(Number(accessory.one_time_price))
        ) {
          totalAmount +=
            Number(accessory.one_time_price) +
            Number(accessory.one_time_tax_amount);
        }
      });
    }
    return Number(Number(totalAmount * 0.1).toFixed(2));
  };

  const getTotalPayableVATfees = () => {
    let totalAmount = 0;

    accessoriesList.map((accessory, i) => {
      if (
        Number(accessory.provider_tax) > -1 &&
        Number(accessory.one_time_price)
      ) {
        totalAmount =
          totalAmount +
          Number(accessory.provider_tax) *
            Number(accessory.one_time_price) *
            0.01;
      }
    });
    return totalAmount.toFixed(2);
  };

  const handlePDFPreview = async () => {
    try {
      let response = await documentsServices.getPDFForSolarCustomer(
        proposal.proposal_id
      );
      if (response && response.status) {
        let url = response.data && response.data.url ? response.data.url : "";
        window.open(url, "_blank");
      } else {
        showError(_l("l_no_pdf_preview"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let monthlyBills = benchmarkDetail.solar_items
      ? benchmarkDetail.solar_items.monthly_bill
      : 0;
    let recommendation = benchmarkDetail.solar_items
      ? Math.floor(
          roundOffPowerValue(benchmarkDetail.solar_items.electricity_production)
        )
      : 0;
    let recommendations = Math.round((recommendation * 1000) / 12);
    let monthlyConsumptions = monthlyBills * 4;
    const saveYearly = Math.round(
      (monthlyBills * recommendations) / monthlyConsumptions
    );
    setMonthalySaving(saveYearly);
  }, [benchmarkDetail]);

  useEffect(() => {
    if (isExternal) {
      hideLeftPanelNew();
      // removeModifiedClass();
    }
  });

  return (
    <>
      <div className="row mt-30px h-100 overflow-hidden">
        {showCongratulationsStep ? (
          // <div className="mt-5 pt-5">
          //   <Congratulation
          //     congratulationMessage={_l("l_solar_payment_successful")}
          //     buttonText={_l("l_view_task")}
          //     buttonOnclickHandler={fetchTasks}
          //   />
          // </div>
          <></>
        ) : (
          <div className="col-lg-12 registration-footer-content mx-auto h_calc30">
            <div className="flex-grow-1 d-flex flex-column h-100">
              <div className="row gx-5 px-0 px-md-5 h-100">
                <div className="col-xl-8 ps-0 h-100 overflow-hiiden-auto">
                  <div className="c-font color-white-60 light-theme-color-white-70 f-14 title-fonts mb-10px">
                    {_l("l_proposal_listing_title")}
                  </div>
                  {/* Proposal Prestataire card detail top loop start  */}
                  <div className="task-list-card list-view schedule-card-main document-list-card comman-white-box-shadow border radius10">
                    <div className=" d-flex flex-wrap justify-content-between">
                      <span className="mb-3 mb-sm-0">
                        {proposal && proposal.company_logo ? (
                          <img
                            src={proposal.company_logo}
                            alt={"solarbox"}
                            className="h32wauto me-10px"
                          />
                        ) : (
                          <></>
                        )}
                        <span className="fw-semibold title-fonts">
                          {proposal.company_name}
                        </span>
                        <span
                          className={`ps-2 pe-15px ${
                            proposal.is_verified > 0 ? "opacity-1" : "opacity-0"
                          }`}
                        >
                          <ShieldCheck
                            size={18}
                            weight="fill"
                            className={"c-icons color-green"}
                          />
                        </span>
                        {proposal.compli_tasks > 0 ? (
                          <span className="badge  badge-white rounded-pill fw-light h32wauto c-font f-12">
                            {`${proposal.compli_tasks} ${_l("l_total_tasks")}`}
                          </span>
                        ) : (
                          <></>
                        )}
                      </span>
                      <div className="d-flex align-items-start align-items-sm-center">
                        <a
                          href="#/"
                          className="btn btn-white-05 position-relative fw-semibold"
                          onClick={() => goToDiscussion()}
                        >
                          <ChatCircle
                            size={15}
                            className="c-icons"
                            weight="light"
                          />
                          <span className="ms-10px c-font f-12">
                            {_l("l_discussion")}
                          </span>
                          {proposal.unread_message_count > 0 ? (
                            <div className=" rounded-pill badge-custom  d-flex p-2 light-pill border-1">
                              {proposal.unread_message_count}
                            </div>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                        </a>
                        {/* <span className={`c-font f-12 d-flex justify-content-center ms-10px`}>0</span> */}
                      </div>
                    </div>
                    <div className="p-15 px-0">
                      <div className="border-bottom"></div>
                    </div>
                    <div>
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-wrap align-items-start">
                          <div className="c-font mb-3 mb-sm-0 color-white-60 f-12 with_separator_10 d-flex align-items-center ps-0">
                            <MapPin
                              size={18}
                              weight="fill"
                              className="c-icons"
                            />
                            <span className="ms-10px max-width-160px text-truncate">
                              {proposal.address}
                            </span>
                          </div>
                          <div className="with_separator_10 d-flex">
                            <div className="d-flex align-items-center">
                              <div className="c-font color-white-60 f-12 fw-semibold">
                                {proposal.company_rating}
                              </div>
                              <div className="d-flex align-items-center ms-10px pe-10px">
                                <span className="star-rating-items text-center d-flex flex-column active p-0">
                                  <Star
                                    size={18}
                                    weight={
                                      proposal.company_rating > 1
                                        ? "fill"
                                        : "light"
                                    }
                                    className="c-icons"
                                  />
                                </span>
                                <span className="star-rating-items text-center d-flex flex-column active p-0">
                                  <Star
                                    size={18}
                                    weight={
                                      proposal.company_rating > 2
                                        ? "fill"
                                        : "light"
                                    }
                                    className="c-icons"
                                  />
                                </span>
                                <span className="star-rating-items text-center d-flex flex-column active p-0">
                                  <Star
                                    size={18}
                                    weight={
                                      proposal.company_rating > 3
                                        ? "fill"
                                        : "light"
                                    }
                                    className="c-icons"
                                  />
                                </span>
                                <span className="star-rating-items text-center d-flex flex-column active p-0">
                                  <Star
                                    size={18}
                                    weight={
                                      proposal.company_rating > 4
                                        ? "fill"
                                        : "light"
                                    }
                                    className="c-icons"
                                  />
                                </span>
                                <span className="star-rating-items text-center d-flex flex-column active p-0">
                                  <Star
                                    size={18}
                                    weight={
                                      proposal.company_rating > 5
                                        ? "fill"
                                        : "light"
                                    }
                                    className="c-icons"
                                  />
                                </span>
                              </div>
                              {proposal.company_total_review > 0 ? (
                                <div className="c-font color-white-60 f-12">
                                  {proposal.company_total_review} avis Google
                                </div>
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Proposal Prestataire card detail top loop end  */}
                  <div>
                    <div className="align-items-center d-flex justify-content-between p-10 shadow-none ps-0">
                      <div className="c-font color-white-60 light-theme-color-white-70 f-14 title-fonts">
                        {_l("l_proposal")}
                      </div>
                    </div>
                    <div className="border radius10 row g-0 comman-white-box-shadow overflow-hiiden-auto bg-white-03 shadow-none mb-10">
                      <div className="col-lg-7">
                        <div className="d-flex h-100 flex-column pt-20px pb-20px ps-10px pe-10px">
                          <div class="d-flex justify-content-between ">
                            <div class="text-truncate mb20px with_separator_10 ps-20pximp pe-20pximp before-dnone">
                              <div class="c-font f-16 fw-semibold text-truncate title-fonts">
                                Installation de panneaux solaire
                                {benchmarkDetail.solar_items
                                  ? ` ${Math.floor(
                                      roundOffPowerValue(
                                        benchmarkDetail.solar_items
                                          .electricity_production / 1000
                                      )
                                    )} Kwc`
                                  : 0}
                              </div>
                              <div class="c-font color-white-60 f-12 pt-1 text-truncate light-theme-color-white-70">
                                {proposal.address}
                              </div>
                            </div>
                          </div>
                          <div class="align-items-start align-items-md-center d-flex flex-column flex-md-row gap-3 gap-md-0">
                            <div class="with_separator_10 flex-grow-1 ps-20pximp pe-20pximp">
                              <div class="c-font f-16 fw-semibold title-fonts">
                                {moment(proposal.date).format("DD-MM-YYYY")}
                              </div>
                              <div class="c-font color-white-60 f-12 pt-1 text-wrap light-theme-color-white-70">
                                Date prévisionnelle
                              </div>
                            </div>
                            <div class="with_separator_10 flex-grow-1 ps-20pximp pe-20pximp before-dnone">
                              <div class="c-font f-16 fw-semibold title-fonts">
                                {benchmarkDetail.solar_items
                                  ? benchmarkDetail.solar_items.monthly_bill
                                  : "0"}{" "}
                                €
                              </div>
                              <div class="c-font color-white-60 f-12 pt-1 text-wrap light-theme-color-white-70">
                                {_l("l_monthly_energy_bill")}
                              </div>
                            </div>
                            <div class="with_separator_10 flex-grow-1 ps-20pximp pe-20pximp d-none">
                              <div class="c-font f-16 fw-semibold title-fonts">
                                1000m²
                              </div>
                              <div class="c-font color-white-60 f-12 pt-1 text-wrap light-theme-color-white-70">
                                Superficie
                              </div>
                            </div>
                            <div class="with_separator_10 flex-grow-1 ps-20pximp pe-20pximp d-none">
                              <div class="c-font f-16 fw-semibold title-fonts align-items-center d-flex">
                                1
                              </div>
                              <div class="c-font color-white-60 f-12 pt-1 text-wrap light-theme-color-white-70">
                                Occupants
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-1"></div>
                      <div class="col-lg-4 d-flex flex-column">
                        {benchmarkDetail.solar_map ? (
                          <div
                            className="border-start d-flex light-theme-grey-light-bg flex-grow-1 min-h-140 max-h-150"
                            onClick={() => setOpenLightBox(true)}
                          >
                            <img
                              className="h-100 w-100 object-fit-cover min-h-140 max-h-150"
                              src={benchmarkDetail.solar_map}
                              alt={"Solar Map"}
                            />
                          </div>
                        ) : (
                          <MapIframe
                            fullWidth
                            address={benchmarkDetail.address}
                          />
                        )}
                      </div>
                    </div>
                    {/* {benchmarkDetail.solar_map ? (
                    <div
                      className="border radius_5 d-flex d-none"
                      onClick={() => setOpenLightBox(true)}
                    >
                      <img
                        className="h-100 w-100 object-fit-cover"
                        src={benchmarkDetail.solar_map}
                        alt={"Solar Map"}
                      />
                    </div>
                  ) : (
                    <MapIframe fullWidth address={benchmarkDetail.address} />
                  )} */}
                    <div className="border radius_5 bg-white-03">
                      {/* <div className=" d-flex justify-content-between align-items-center p-20 border-last-none border-bottom">
                      <div className="d-flex align-items-center">
                        <div className="h35w35 comman-round-box with-bg bg-white-05 me-20px">
                          <div
                            className="comman-bg-img h-100 w-100 bg-style-cover"
                            style={{
                              backgroundImage: `url('${solarpanelsimg}')`,
                            }}
                          ></div>
                        </div>
                        <div className="c-font f-14 fw-semibold title-fonts temp d-none">
                          {_l("l_solar_installation_title")}{" "}
                          {Math.floor(roundOffPowerValue(
                            benchmarkDetail.solar_items.electricity_production / 1000)
                          )}{" "}
                          Kwc
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-3">
                      <div className=" c-font f-14 fw-semibold title-fonts">
                        € {proposal.amount}
                      </div>
                        <div className="d-flex align-items-center position-relative for-disabled">
                          <Select
                            // menuIsOpen={true}
                            // placeholder={_l("l_payment_method")}
                            className="custom-select-menu drop-down f14 bg-white-05"
                            classNamePrefix="react-select"
                            value={VATselectionOption.find(
                              (w) => w.value == 10
                            )}
                            disabled={true}
                            menuIsOpen={false}
                          />
                        </div>
                        </div>
                    </div> */}
                      {accessoriesList.map((accessory, index) => {
                        if (accessory.is_goverment == 1) {
                          return <React.Fragment></React.Fragment>;
                        }
                        let url = encodeURI(accessory.attachment_url);
                        return (
                          <div
                            key={index}
                            className=" d-flex justify-content-between align-items-center p-20 border-last-none border-bottom"
                          >
                            <div className="d-flex align-items-center flex-grow-1">
                              <div className="h35w35 comman-round-box with-bg bg-transparent bg-white-05 me-20px">
                                <div
                                  className="comman-bg-img h-100 w-100 bg-style-cover"
                                  style={{
                                    backgroundImage: `url('${solarpanelsimg}')`,
                                  }}
                                >
                                  {/* <FileText
                                    size={34}
                                    weight="light"
                                    className="c-icons"
                                  /> */}
                                </div>
                              </div>
                              <div className="d-flex flex-column text-truncate w100minus100per">
                                <span className="c-font f-14 fw-semibold title-fonts text-truncate">
                                  {accessory.name}
                                </span>
                                <span className="c-font f-12 text-truncate">
                                  {accessory.description}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center gap-3">
                              <div className="c-font f-14 fw-semibold title-fonts">
                                € {accessory.one_time_price}
                              </div>
                              <div className="c-font f-14 fw-semibold title-fonts d-flex align-items-center position-relative d-none">
                                {` ${accessory.provider_tax}%`}{" "}
                                <div className="c-font f-12 fw-normal fw-regular ms-1">
                                  VAT
                                </div>
                                {/* <Select
                            // menuIsOpen={true}
                            // placeholder={_l("l_payment_method")}
                            className="custom-select-menu drop-down f14 bg-white-05"
                            classNamePrefix="react-select"
                            value={VATselectionOption.find(
                              (w) => w.value == accessory.provider_tax
                            )}
                            disabled={true}
                            menuIsOpen={false}
                          /> */}
                              </div>
                              {url ? (
                                <div class="ms-10px upload-image-preview comman_action_icon ">
                                  <div class="comman-image-box h55w55 radius_3 upload-image action_icon">
                                    <div
                                      class="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 cursor-pointer"
                                      onClick={() => {
                                        setAttachmentUrl([url]);
                                        // window.open(`${url}`, "_blank")
                                        setOpenGallery(true);
                                      }}
                                      style={{
                                        backgroundImage: `url(${encodeURI(
                                          url
                                        )})`,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        );
                      })}
                      <div className=" d-flex justify-content-between align-items-center p-20 border-last-none border-bottom d-none">
                        <div className="d-flex align-items-center">
                          <div className="h35w35 comman-round-box with-bg bg-transparent bg-white-05 me-20px">
                            <div
                              className="comman-bg-img h-100 w-100 bg-style-cover"
                              style={{
                                backgroundImage: `url('${solarpanelsimg}')`,
                              }}
                            >
                              {/* <FileText
                                    size={34}
                                    weight="light"
                                    className="c-icons"
                                  /> */}
                            </div>
                          </div>
                          <div className="c-font f-14 fw-semibold title-fonts">
                            {_l("l_solar_hub_platform_cost")}
                          </div>
                        </div>
                        <div className="c-font f-14 fw-semibold title-fonts">
                          € {calculateSolarhubFees()}
                        </div>
                      </div>
                      {!isNaN(Number(proposal.goverment_help)) ? (
                        <div className=" d-flex justify-content-between align-items-center p-20 border-last-none border-bottom">
                          <div className="d-flex align-items-center">
                            <div className="c-font f-14 fw-semibold title-fonts">
                              {aidTitle || _l("l_aids_financial")}
                            </div>
                          </div>
                          <div className="c-font f-14 fw-semibold title-fonts">
                            € {proposal.goverment_help}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="">
                      <div className="align-items-center d-flex justify-content-between p-10 shadow-none ps-0 mt-10">
                        <div className="c-font color-white-60 light-theme-color-white-70 f-14 title-fonts">
                          {_l("l_simulation_audit_energetique")}
                        </div>
                      </div>
                      <div className="align-items-center border d-flex pt-20px pt-15 pb-15px ps-10px radius_5 bg-white-03 for-disabled">
                        <div className="with_separator_10 flex-grow-1 text-center ps-0 d-flex justify-content-center align-items-center">
                          <div className="c-font f-24 fw-semibold title-fonts color-blue">
                            {benchmarkDetail.solar_items
                              ? ` ${Math.floor(
                                  roundOffPowerValue(
                                    benchmarkDetail.solar_items
                                      .electricity_production
                                  )
                                )} Kwc`
                              : 0}
                            {/* 00 Kwc */}
                          </div>
                          <div className="c-font color-white-60 f-14 pt-1 text-wrap ms-20px">
                            Production Electrique
                          </div>
                        </div>
                        <div className="with_separator_10 flex-grow-1 text-center d-flex justify-content-center align-items-center">
                          <div className="c-font f-24 fw-semibold title-fonts color-greenpill">
                            {/* {benchmarkDetail.solar_items.savings}€ */}
                            {monthalySaving} €
                          </div>
                          <div className="c-font color-white-60 f-14 pt-1 text-wrap ms-20px">
                            <span>
                              {/* {_l("l_savings")}
                            {_l("l_on")} */}
                              Economies Mensuelles
                            </span>
                            <span className="fw-semibold fblue ps-1"></span>
                          </div>
                        </div>
                        <div className="with_separator_10 flex-grow-1 text-center d-none">
                          <div className="c-font f-16 fw-semibold title-fonts color-orange">
                            {/* {proposal.goverment_help} */}€ 00
                          </div>
                          <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                            {_l("l_financial_aid")}
                          </div>
                        </div>

                        {/* {!isNaN(Number(proposal.goverment_help)) ? (
                        <div className="with_separator_10 flex-grow-1 text-center">
                          <div className="c-font f-16 fw-semibold title-fonts color-orange">
                            {proposal.goverment_help}
                          </div>
                          <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                            {aidTitle || _l("l_financial_aid")}
                          </div>
                        </div>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )} */}
                      </div>
                    </div>
                    <div>
                      <div className="align-items-center d-flex justify-content-between p-10 shadow-none ps-0 mt-10">
                        <div className="c-font color-white-60 light-theme-color-white-70 f-14 title-fonts">
                          {_l("l_terms_conditions")}
                        </div>
                      </div>
                      <div className="border radius10 bg-white-03 comman-white-box-shadow">
                        <div className=" d-flex justify-content-between align-items-center p-20 border-last-none border-bottom flex-wrap flex-xl-nowrap gap-3">
                          <div className="d-flex align-items-center">
                            <div className="c-font f-14 fw-semibold title-fonts">
                              Garantie
                            </div>
                          </div>
                          <span className="c-font f-14 fw-semibold title-fonts">
                            {proposal.garantie}
                          </span>
                        </div>
                        <div className=" d-flex justify-content-between align-items-center p-20 border-last-none border-bottom flex-wrap flex-xl-nowrap gap-3">
                          <div className="d-flex align-items-center">
                            <div className="c-font f-14 fw-semibold title-fonts">
                              Maintenance SAV
                            </div>
                          </div>
                          <span className="c-font f-14 fw-semibold title-fonts">
                            {proposal.maintenance}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-start p-20 border-last-none border-bottom bg-white-05">
                          <div className="d-flex align-items-center flex-grow-1 flex-column">
                            <span>{proposal.terms}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div>
                  <div className="align-items-center d-flex justify-content-between p-10 shadow-none ps-0">
                    <div className="c-font color-white-80 f-12 title-fonts">
                      Proposal
                    </div>
                  </div>
                  <div className="border radius_5 bg-white-03">
                    <div className=" d-flex justify-content-between align-items-center p-20 border-last-none border-bottom">
                      <div className="d-flex align-items-center">
                        <div className="c-font f-14 fw-semibold title-fonts">
                          Panneaux solaires 3KW + Pose{" "}
                        </div>
                      </div>
                      <a href="#/" className="btn btn-white-05 btn-sm">
                        incluse
                      </a>
                    </div>
                    <div className=" d-flex justify-content-between align-items-center p-20 border-last-none border-bottom">
                      <div className="d-flex align-items-center">
                        <div className="c-font f-14 fw-semibold title-fonts">
                          Panneaux solaires 3KW + Pose{" "}
                        </div>
                      </div>
                      <a href="#/" className="btn btn-white-05 btn-sm">
                        incluse
                      </a>
                    </div>
                    <div className=" d-flex justify-content-between align-items-center p-20 border-last-none border-bottom">
                      <div className="d-flex align-items-center">
                        <div className="c-font f-14 fw-semibold title-fonts">
                          Panneaux solaires 3KW + Pose{" "}
                        </div>
                      </div>
                      <a href="#/" className="btn btn-white-05 btn-sm">
                        incluse
                      </a>
                    </div>
                    <div className=" d-flex justify-content-between align-items-center p-20 border-last-none border-bottom">
                      <div className="d-flex align-items-center">
                        <div className="c-font f-14 fw-semibold title-fonts">
                          Panneaux solaires 3KW + Pose{" "}
                        </div>
                      </div>
                      <a href="#/" className="btn btn-white-05 btn-sm">
                        incluse
                      </a>
                    </div>
                  </div>
                </div> */}
                </div>
                <div className="col-xl-4 pe-0 overflow-hiiden-auto h-100">
                  <div className="">
                    <div className="align-items-center d-flex justify-content-between p-10 shadow-none ps-0">
                      <div className="c-font color-white-60 light-theme-color-white-70 f-14 title-fonts">
                        {_l("l_proposition_commerciale")}
                      </div>
                    </div>
                    <div className="border radius_5 bg-white-03">
                      <div className="p-20 title-fonts">
                        {proposal.monthly_price > 0 ? (
                          <div className="align-items-center btn-white-05 d-flex p-1 mb-20">
                            <label
                              className={`row-btn-label w-50`}
                              for="flexRadioDefault1"
                            >
                              <input
                                type="radio"
                                className="hidden-input"
                                name="payment_mode"
                                value={"onetime"}
                                onChange={(e) =>
                                  setViewingOneTimePrice(
                                    e.target.value === "onetime"
                                  )
                                }
                                checked={viewingOneTimePrice}
                              />
                              <div className="row-btn-box c-font f-14 p-2 fw-semibold title-fonts cblue">
                                {_l("l_pay_once")}
                              </div>
                            </label>
                            <label
                              className={`row-btn-label w-50`}
                              for="flexRadioDefault2"
                            >
                              <input
                                type="radio"
                                className="hidden-input"
                                name="payment_mode"
                                value={"monthly"}
                                onChange={(e) =>
                                  setViewingOneTimePrice(
                                    e.target.value === "onetime"
                                  )
                                }
                                checked={!viewingOneTimePrice}
                                disabled={
                                  isNaN(Number(proposal.monthly_amount)) ||
                                  !Number(proposal.monthly_amount)
                                }
                              />
                              <div className="row-btn-box c-font f-14 p-2 fw-semibold title-fonts cblue">
                                {`${_l("l_payment")} ${_l("l_monthly")}`}
                              </div>
                            </label>
                          </div>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                        <div>
                          <div className="c-font f-14 fw-semibold title-fonts pb-10px color-white-60">
                            {_l("l_details")}
                          </div>
                          <div className="c-font f-14 pb-10px color-white-60 d-flex justify-content-between align-items-center d-none">
                            <div>
                              {" "}
                              {_l("l_solar_installation_title")}{" "}
                              {proposal.no_of_plates ||
                                benchmarkDetail.solar_items.no_of_plates}{" "}
                              KW
                            </div>
                            <div>
                              €{" "}
                              {viewingOneTimePrice
                                ? Number(proposal.amount)
                                : Number(proposal.monthly_amount) *
                                  Number(proposal.number_of_month)}
                            </div>
                          </div>
                          <div className="c-font f-14 pb-10px color-white-60 d-flex justify-content-between align-items-center d-none">
                            <div className="c-font f-14 title-fonts">
                              {_l("l_solar_installation_title")}{" "}
                              {roundOffPowerValue(
                                benchmarkDetail.solar_items
                                  .electricity_production / 1000
                              )}{" "}
                              Kwc
                            </div>
                            <div className=" c-font f-14 title-fonts">
                              € {proposal.amount}
                            </div>
                          </div>
                          {/* {accessoriesList
                          .filter((w) => w.is_goverment != 1)
                          .map((accessory, index) => {
                            return (
                              <div
                                key={index}
                                className="c-font f-14 pb-10px color-white-60 d-flex justify-content-between align-items-center"
                              >
                                <div> {accessory.title} </div>
                                <div>
                                  €{" "}
                                  {viewingOneTimePrice
                                    ? (Number(accessory.one_time_price) + (Number(accessory.one_time_price) * (Number(accessory.provider_tax) / 100 ))).toFixed(2)
                                    : Number(accessory.monthly_price) *
                                      Number(accessory.number_of_month)}
                                </div>
                              </div>
                            );
                          })} */}
                          {/* <div className="c-font f-14 pb-10px color-white-60 d-flex justify-content-between align-items-center">
                          <div> {_l("l_solarHub_fees")} </div>
                          <div>€ {calculateSolarhubFees()}</div>
                        </div> */}
                          <div className="c-font f-14 pb-10px color-white-60 d-flex justify-content-between align-items-center">
                            <div> {_l("l_total_without_vat")} </div>
                            <div>
                              €{" "}
                              {accessoriesList
                                .reduce((accumulator, currentItem) => {
                                  return (
                                    accumulator +
                                    Number(currentItem.one_time_amount)
                                  );
                                }, 0)
                                .toFixed(2)}
                            </div>
                          </div>
                          <div className="c-font f-14 pb-10px color-white-60 d-flex justify-content-between align-items-center">
                            <div> {_l("l_vat")} </div>
                            <div>
                              €{" "}
                              {accessoriesList
                                .reduce((accumulator, currentItem) => {
                                  return (
                                    accumulator +
                                    Number(currentItem.one_time_tax_amount)
                                  );
                                }, 0)
                                .toFixed(2)}
                            </div>
                          </div>
                          <div className="c-font f-14 pb-10px d-flex justify-content-between align-items-center fw-semibold d-none">
                            <div className="c-font f-14 title-fonts">
                              {_l("l_total_VAT_fees_pay_now")}{" "}
                            </div>
                            <div className=" c-font f-14 title-fonts">
                              € {getTotalPayableVATfees()}
                            </div>
                          </div>
                          {!isNaN(Number(proposal.goverment_help)) ? (
                            <div className="c-font f-14 pb-10px color-white-60 d-flex justify-content-between align-items-center">
                              <div> {aidTitle || _l("l_aids_financial")} </div>
                              <div>- € {Number(proposal.goverment_help)}</div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {/* <div className="c-font f-14 pb-10px d-flex justify-content-between align-items-center color-white fw-bold ">
                          <div>{_l("l_total")}</div>
                          <div>
                            €{" "}
                            {console.log("getTotalPayableAmount",getTotalPayableAmount(),"calculateSolarhubFees",calculateSolarhubFees(),"Number(proposal.amount) ",Number(proposal.amount) , "Number(getTotalPayableVATfees())).toFixed(2)",getTotalPayableVATfees())}
                            {(getTotalPayableAmount() + calculateSolarhubFees() + Number(proposal.amount) + Number(getTotalPayableVATfees())).toFixed(2)} TTC
                          </div>
                        </div> */}
                          <div className="c-font f-14 pb-10px d-flex justify-content-between align-items-center color-white fw-bold ">
                            <div>{_l("l_total_with_vat")}</div>
                            <div>
                              €{" "}
                              {accessoriesList
                                .reduce((accumulator, currentItem) => {
                                  return (
                                    accumulator +
                                    Number(currentItem.one_time_amount) +
                                    Number(currentItem.one_time_tax_amount)
                                  );
                                }, 0)
                                .toFixed(2)}
                            </div>
                          </div>
                          {/* <div className="c-font f-14 fw-semibold pb-10px d-flex justify-content-between align-items-center color-white">
                          <div>
                            {_l("l_tax")}
                            {" (20%)"}
                          </div>
                          <div>€ {calculateSolarhubFees() * 0.2}</div>
                        </div> */}
                        </div>
                        <div className="pt-10px pb-20px">
                          <div className="border-bottom-dashed"></div>
                        </div>
                        <div>
                          <div>
                            <div class="c-font f-15 fw-semibold title-fonts pb-10px color-white-60">
                              {_l("l_conditions")}
                            </div>
                            <div class="c-font f-15 pb-10px color-white-60 d-flex justify-content-between align-items-center">
                              <div>
                                {" "}
                                {_l("l_25_year_warranty")}{" "}
                                {guarantee ? `${guarantee} ans` : ""}{" "}
                              </div>
                              <div>
                                {guarantee
                                  ? _l("l_included")
                                  : _l("l_not_included")}{" "}
                              </div>
                            </div>
                            <div class="c-font f-15 pb-10px color-white-60 d-flex justify-content-between align-items-center">
                              <div>
                                {" "}
                                {_l("l_maintenance")}{" "}
                                {maintainance
                                  ? `& SAV ${maintainance} ans`
                                  : "& SAV"}{" "}
                              </div>
                              <div>
                                {maintainance
                                  ? _l("l_included")
                                  : _l("l_not_included")}{" "}
                              </div>
                            </div>
                          </div>
                          <div class="pt-10px pb-20px">
                            <div class="border-bottom-dashed"></div>
                          </div>
                        </div>
                        {/* <div className="c-font f-15 fw-semibold d-flex justify-content-between align-items-center color-white text-capitalize">
                        <div>{_l("l_total_to_pay_now")}</div>
                        <div className="color-green">
                          € {calculateSolarhubFees()}
                        </div>
                      </div> */}
                        {/* <div className="c-font pb-10px color-white-60  d-flex justify-content-between align-items-center color-white text-capitalize ">
                        <div>{_l("l_total_VAT_fees_pay_now")}</div>
                        <div className="color-white-60">
                          € {getTotalPayableVATfees()}
                        </div>
                      </div> */}
                        <div className="c-font f-15 pb-10px color-white-60 d-flex justify-content-between align-items-center c-input-box">
                          <div>premier ca</div>

                          <div>-1444</div>
                          <Form.Control
                            placeholder={10}
                            className="color-white form-control fw-semibold h32wauto pb-0 pt-0 res-width-100 title-fonts w-25 d-none"
                            type="number"
                          />
                        </div>
                        <div className="c-font f-15 pb-10px color-white-60 d-flex justify-content-between align-items-center c-input-box">
                          <div>autres</div>
                          <div>-1000</div>
                          <Form.Control
                            placeholder={10}
                            className="color-white form-control fw-semibold h32wauto pb-0 pt-0 res-width-100 title-fonts w-25 d-none"
                            type="number"
                          />
                        </div>
                        <div className="c-font f-15 fw-semibold d-flex justify-content-between align-items-center color-white text-capitalize pb-10px">
                          <div>{_l("l_total_to_pay_now")}</div>
                          <div className=" color-greenpill c-font f-22 title-fonts">
                            {/* € {Number(calculateSolarhubFees())+ Number(getTotalPayableVATfees())} */}
                            € {calculateSolarhubFees()}
                          </div>
                        </div>
                      </div>
                      <div className="p-20 bg-white-05">
                        <div class="d-flex form-check">
                          <div class="pe-2">
                            <input
                              className="form-check-input light-theme-border light-theme-white-bg"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              checked={isExternal && proposal.status == 3 ? true : termsAndConditionAccepted}
                              onChange={(e) =>
                                setTermsAndConditionAccepted(e.target.checked)
                              }
                              disabled={isExternal && proposal.status == 3 ? true : false}
                            />
                          </div>
                          <label
                            class="form-check-label c-font f-14"
                            for="flexCheckDefault"
                          >
                            {_l("l_terms_solar")}
                            <a href="#/" class="color-green ms-1">
                              Blaz.com{" "}
                            </a>{" "}
                            {_l("l_terms_cgs_solar")}
                          </label>
                        </div>
                        <div className="pt-20px pb-20px">
                          <div className="border-bottom"></div>
                        </div>
                        <div className="gap-3 d-flex">
                          {isExternal ? (
                            proposal.status == 3 ? (
                              <>
                              <Button
                                  variant={"success"}
                                  className="flex-grow-1 for-disabled onhoverimginv"
                                  disabled
                                >
                                  <Check
                                    size={18}
                                    weight="light"
                                    className="c-icons"
                                  />
                                  <span className="ms-10px">
                                    {_l("l_accepted")}
                                  </span>
                                </Button>
                              </>
                            ) : proposal.status == 2 ? (
                              <>
                              <Button
                                  variant={"danger"}
                                  className="flex-grow-1 for-disabled onhoverimginv"
                                  disabled
                                >
                                  <X
                                    size={18}
                                    weight="light"
                                    className="c-icons"
                                  />
                                  <span className="ms-10px">
                                    {_l("l_rejected")}
                                  </span>
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  variant={"success"}
                                  className="flex-grow-1 onhoverimginv"
                                  onClick={handleAcceptProposal}
                                >
                                  <Check
                                    size={18}
                                    weight="light"
                                    className="c-icons"
                                  />
                                  <span className="ms-10px">
                                    {_l("l_accept")}
                                  </span>
                                </Button>
                                <Button
                                  variant={"danger"}
                                  className="flex-grow-1 onhoverimginv"
                                  onClick={rejectProposal}
                                >
                                  <X
                                    size={18}
                                    weight="light"
                                    className="c-icons"
                                  />
                                  <span className="ms-10px">
                                    {_l("l_reject")}
                                  </span>
                                </Button>
                              </>
                            )
                          ) : (
                            <>
                              <Button
                                variant={"success"}
                                className="flex-grow-1 onhoverimginv"
                                onClick={handleAcceptProposal}
                              >
                                <Check
                                  size={18}
                                  weight="light"
                                  className="c-icons"
                                />
                                <span className="ms-10px">
                                  {_l("l_accept")}
                                </span>
                              </Button>
                              <Button
                                variant={"danger"}
                                className="flex-grow-1 onhoverimginv"
                                onClick={rejectProposal}
                              >
                                <X
                                  size={18}
                                  weight="light"
                                  className="c-icons"
                                />
                                <span className="ms-10px">
                                  {_l("l_reject")}
                                </span>
                              </Button>
                            </>
                          )}
                        </div>
                        <div className="d-flex mt-10 w-100">
                          <Button
                            variant={""}
                            className={`flex-grow-1  ${isExternal ? "bgyellow" : "bgyellow"}`}
                            onClick={() => {
                              handlePDFPreview();
                            }}
                          >
                            <FileText
                              size={18}
                              weight="light"
                              className="c-icons "
                            />
                            <span className="ms-10px">
                              {_l("l_preview_as_pdf")}
                            </span>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showSignatureModal ? (
          <Elements stripe={stripePromise}>
            <SignatureModal
              proposalDetails={proposal}
              handleClose={(flag) => {
                setShowSignatureModal(false);
                if (flag) {
                  // setShowCongratulationsStep(true);
                  handleNextStep();
                }
              }}
            />
          </Elements>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        {openLightBox && lightBoxData.length ? (
          <SolarDocumentPreview
            open={openLightBox}
            setOpen={setOpenLightBox}
            slides={lightBoxData}
          />
        ) : (
          <></>
        )}
        <Lightbox
          open={openGallery}
          close={() => setOpenGallery(false)}
          slides={attachmentUrl.map((img) => {
            return { src: img };
          })}
        />
      </div>
    </>
  );
};

export default CustomerProposalDetail;
