import React from 'react'
const PriceCatalogGeneralConditionsSkeleton = () => {
  return (
    <>
        {
          [0, 1, 2].map((s, index) => {
            return (<>
              <div className="d-flex justify-content-between align-items-center p-20 border-last-none border-bottom SkeletonMain">
                <div className='skeleton-animation radius_3' style={{ width: '200px', height: '15px' }}></div>
                <div className='radius_3 skeleton-animation' style={{ width: '200px', height: '53px' }}></div>
              </div>
            </>)
          })
        }
    </>
  )
}
export default PriceCatalogGeneralConditionsSkeleton