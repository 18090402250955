import React, { useState } from "react";
import { _l } from "../../../hooks/utilities";
import { useSelector } from "react-redux";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";

export function FloorPlanSummary({
  onFloorSelect = () => {},
  hideFloorDetails,
  showGeneralAreaAsFloor,
  kpiData,
  getTypeOfSpaceCounts,
  filteredFloors
}) {
  const { floorDetails } = useSelector((state) => state.benchmarkReducer);
  const [activeKPIIndex, setActiveKPIIndex] = useState(-1)
  return (
    <React.Fragment>
      {showGeneralAreaAsFloor ? (
        <a href="#/"
          className={`task-list-card  w-100  border-base-header-color pt-15px pb-15px light-theme-grey-light-bg-imp ${
            kpiData ? " mt-0" : ""
          }`}
          onClick={() => onFloorSelect({ id: 0, title: "General Area" })}
        >
          <div className="d-flex align-content-center accordian-main-header title-fonts ">
            <div className="d-flex align-items-center justify-content-between w-100 line-height-1">
              <div
                className={`text-truncate flex-grow-1 ${
                  kpiData ? "" : "w-75"
                } `}
              >
                <div className="c-font color-white-80 f-12 text-truncate title-fonts">
                  {_l("l_floor_title")}
                </div>
                <div className="c-font f-14 fw-semibold text-truncate title-fonts pt-2">
                {_l("l_general_area")}
                </div>
              </div>
              {kpiData ? (
                kpiData.map((row) => {
                  return (
                    <React.Fragment>
                      {row.map((kpiDetail, index) => {
                        if(kpiDetail.field == "l_type_of_space_label")
                        {
                          return <React.Fragment></React.Fragment>
                        }
                        return (
                          <div
                            key={index}
                            className="text-center px-3"
                            onMouseEnter={() => {
                              setActiveKPIIndex(0);
                            }}
                            onMouseLeave={() => {
                              setActiveKPIIndex(-1);
                            }}
                          >
                            <div className="c-font color-white-80 f-12 py-1">
                              {_l(kpiDetail.field)}
                            </div>
                            {kpiDetail.getJSX ? (
                              kpiDetail.getJSX(0)
                            ) : (
                              <div className="c-font f-14 fw-semibold title-fonts py-1 text-start ">
                                {kpiDetail.value(0)}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </React.Fragment>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>
        </a>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {floorDetails.floor.length ? (
        floorDetails.floor.map((floor, index) => {
          let totalFloorWiseTypeOfSpaces = 0;

          if(filteredFloors && filteredFloors.includes(Number(floor.id)))
          {
            return <></>
          }

          floorDetails.type_of_space.forEach((space) => {
            space.details.forEach((spaceItem) => {
              if (spaceItem.floor_id == floor.id) {
                totalFloorWiseTypeOfSpaces++;
              }
            });
          });

          return (
            <a href="#/"
              key={index}
              className={`border-base-header-color task-list-card  w-100 pt-15px pb-15px d-flex flex-column justify-content-center shadow-none light-theme-grey-light-bg-imp ${
                kpiData ? "" : ""
              }`}
              onClick={() => onFloorSelect(floor)}
            >
              <div className="d-flex align-content-center accordian-main-header title-fonts ">
                <div className="d-flex align-items-center justify-content-between w-100 line-height-1">
                  <div
                    className={`text-truncate flex-grow-1 ${
                      kpiData ? "" : "w-75"
                    } `}
                  >
                    <div className="c-font color-white-80 f-12 text-truncate title-fonts pb-2">
                      {_l("l_floor_title")}
                    </div>
                    <div className="c-font f-14 fw-semibold text-truncate title-fonts">
                      {floor.title}
                    </div>
                  </div>
                  {kpiData ? (
                    kpiData.map((row) => {
                      return (
                        <React.Fragment>
                          {row.map((kpiDetail, index2) => {
                            return (
                              <div key={index2} className="text-center px-3"onMouseEnter={()=>{setActiveKPIIndex(index+1);}} onMouseLeave={()=>{setActiveKPIIndex(-1)}}>
                                <div className="c-font color-white-80 f-12 py-1">
                                  {_l(kpiDetail.field)}
                                </div>
                                {
                                  kpiDetail.getJSX
                                  ?
                                  kpiDetail.getJSX(floor.id)
                                  :
                                  <div className="c-font f-14 fw-semibold title-fonts py-1 text-start ">
                                    {kpiDetail.value(floor.id)}
                                  </div>
                                }
                              </div>
                            );
                          })}
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <></>
                  )}
                  {hideFloorDetails ? (
                    <React.Fragment></React.Fragment>
                  ) : (
                    <div className="align-items-center d-flex justify-content-between">
                      <div className="text-center px-3 ">
                        <div className="c-font color-white-80 f-12 py-1">
                          {_l("l_desks")}
                        </div>
                        <div className="c-font f-14 fw-semibold title-fonts py-1 text-start ">
                          {floor.number_of_desk}
                        </div>
                      </div>
                      <div className="text-center px-3 ">
                        <div className="c-font color-white-80 f-12 py-1">
                          {_l("l_superficy")}
                        </div>
                        <div className="c-font f-14 fw-semibold title-fonts py-1 text-start ">
                          {floor.superficy} (m²)
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {hideFloorDetails && !getTypeOfSpaceCounts ? (
                <React.Fragment></React.Fragment>
              ) : (
               <>
               {index+1 == activeKPIIndex ? 
                <div className="align-item-center d-flex flex-wrap p-15 px-0 border-top plan-summary-section">
                  {totalFloorWiseTypeOfSpaces &&
                  floorDetails.type_of_space &&
                  floorDetails.type_of_space.length ? (
                    floorDetails.type_of_space.map((space) => {
                      let spaceCount = 0;
                      if(getTypeOfSpaceCounts && space.is_default == 1)
                      {
                        spaceCount = getTypeOfSpaceCounts(0);
                      }
                      space.details.forEach((item) => {
                        if (item.floor_id == floor.id) {
                          if(getTypeOfSpaceCounts)
                          {
                            spaceCount = getTypeOfSpaceCounts(item.id);
                          }
                          else {
                            spaceCount++;
                          }
                        }
                      });
                      if (!spaceCount) {
                        return <React.Fragment></React.Fragment>;
                      }
                      return (
                        <div className="with_separator_10 px-3">
                          <span className="c-font color-white-80 title-fonts f-13">
                            {space.title} :{" "}
                          </span>
                          <span className="c-font title-fonts f-12">
                            {spaceCount}
                          </span>
                        </div>
                      );
                    })
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </div>
                : <></> }
                </> 
              )}
            </a>
          );
        })
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {/* <div className="col-xl-12 d-none">
        <div className="bg-white-03 p-3 radius_5 w-100">
          <div class="align-items-center d-flex justify-content-between">
            <div class="c-font f-14 fw-semibold text-truncate title-fonts color-white-60 w100minus100per">
              Floor 1
            </div>
            <a href="#/" className="btn btn-white-05 btn-sm border-0">Setup Floor</a>
          </div>
        </div>
      </div> */}
    </React.Fragment>
  );
}
