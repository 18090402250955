import React, { useEffect, useState } from "react";
import CommentSection from "../../Comments/CommentSection";
import CommanLoader from "../../Loader/CommanLoader";
import { _l } from "../../../hooks/utilities";

const FullScreenCommentView = ({
  commentsList,
  handleAddComment,
  setShowCommentSection,
  deleteComment,
  loadingComments,
  mentionList,
  type,
  recipientName,
  recipientImage
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (document.readyState === "complete") {
      setShow(true);
    } else {
      const handlePageLoad = () => {
        window.removeEventListener("load", handlePageLoad);
      };
      setShow(true);
      window.addEventListener("load", handlePageLoad);
    }
  }, []);

  useEffect(() => {
    if(show && !loadingComments)
    {
      const recipientNameArea = document.getElementById("chat-screen-recipient-name");
      const recipientImageArea = document.getElementById("chat-screen-recipient-image");
  
      if(recipientNameArea && recipientImageArea)
      {
        if(recipientName)
        {
          recipientNameArea.textContent = recipientName;
          recipientImageArea.style.backgroundImage = `url("${recipientImage}")`;
        }
        else {
          recipientImageArea.parentElement.remove();
          recipientNameArea.textContent = _l("l_comments");
          recipientNameArea.classList.remove("text-truncate")
        }
      }
    }
  }, [loadingComments, show]);
  

  return (
    <>
      {show && !loadingComments ? (
        <CommentSection
          isRelativeView={true}
          commentList={commentsList}
          data={""}
          handleAddOrEditComment={handleAddComment}
          handleClose={() => {
            setShowCommentSection(false);
          }}
          handleDeleteComment={deleteComment}
          handleSearchComment={() => {}}
          loading={false}
          mentionList={mentionList.map((name) => {
            let id =
              name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
            return {
              display: name.name,
              id: id,
              image: name.profile_image || "",
              isteam: name.is_team ? 1 : 0,
            };
          })}
          show={show}
          docType={type}
        />
      ) : (
        <CommanLoader />
      )}
    </>
  );
};

export default FullScreenCommentView;
