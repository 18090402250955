import React, { useState, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import CaretDoubleDownIcon from "../../assets/icons/CaretDoubleDownIcon";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import { _l } from "../../hooks/utilities";
import { Info } from "phosphor-react";

export default function MySpacePlan({
  StandAlonePlatform = null,
  SpaceManger = null,
  handler,
  hideButtons,
  setHideButtons,
  fromSettingModal
}) {
  const [PlanDetails, setPlanDetails] = useState([]);
  const [newOffices, setNewOffices] = useState([]);
  const [curOffices, setCurOffices] = useState([]);
  const [spaceTitleCheck, setSpaceTitleCheck] = useState([]);
  const [curSpacePage, setCurSpacePage] = useState(1);
  const [newSpacePage, setNewSpacePage] = useState(1);
  const [loder, setLoder] = useState({
    newLoder: false,
    currentLoder: false,
  });
  useEffect(() => {
    setPlanDetails([]);
    if (StandAlonePlatform) {
      let tempStandAlonePlatform = StandAlonePlatform;
      var spaceItems = tempStandAlonePlatform.items.reverse();
      const tempNewOffices = spaceItems.filter((space) => space.status == 0);
      const tempCurOffices = spaceItems.filter(
        (space) => space.status == 1 || space.status == 2
      );

      setNewOffices(tempNewOffices);
      setCurOffices(tempCurOffices);
      setPlanDetails(tempStandAlonePlatform);
    } else if (SpaceManger) {
      setPlanDetails(SpaceManger);
    }
  }, [StandAlonePlatform, SpaceManger]);

  useEffect(() => {
    if (
      PlanDetails &&
      PlanDetails.total &&
      (PlanDetails.total.split("€").pop() === NaN ||
        PlanDetails.total.split("€").pop() <= 0)
    ) {
      setHideButtons(true);
    }
  }, [PlanDetails]);

  const scroll = (status) => {
    let page;
    let spaceStaus = status;
    if (status == 0) {
      page = newSpacePage + 1;
      setNewSpacePage(page);
    } else if (status == 1) {
      page = curSpacePage + 1;
      setCurSpacePage(page);
    }
    if (status == 0) {
      setLoder({ ...loder, ["newLoder"]: false });
    } else if (status == 1) {
      setLoder({ ...loder, ["currentLoder"]: false });
    }
  };
  return (
    <>
    {
      fromSettingModal
      ?
      <></>
      :
      <>
        <li className="overflow-auto py-0">
          <ul>
            <div
              className={`color-white custom-accordian-Header-sticky py-2 mb-2 ps-2 radius_3 bg-premium-transparent   ${
                newOffices.length == 0 ? "d-none" : ""
              }`}
            >
              # {_l("l_new_space")}
            </div>
            {newOffices && newOffices.length ? (
              newOffices
                .slice(0, newSpacePage > 1 ? newOffices.length : newSpacePage * 3)
                .map((space, index) => {
                  return (
                    <>
                      {/* <li className="list-group-item border-separator w-100" key={index}>
                    <div className="d-flex justify-content-between  p-0 w-100">
                      <div className="d-flex  p-0 w100minus100">
                        <div>{index+1}</div>
                        <div className="ms-3">
                          <div className="color-white c-font f-15">{space.office_title ? space.office_title   : space.description ? space.description.split('<br>')[0] : ''}</div>
                          <div className="color-white-60 c-font f-13">{space.office_title && space.description ? space.description   : space.description ? space.description.split('<br>')[1] : ''}</div>
                          <div className="color-white-60 c-font f-13">{space.area ? space.area + "m²"  : ''} </div>
                        </div>
                      </div>
                      <div className="color-green"> {space.total_price ? '€ '+space.total_price   : ''}</div>
                    </div>
                  </li> */}

                      {/* {spaceHeader} */}
                      <li class="list-group-item border-separator w-100 px-3">
                        <div class="d-flex justify-content-between p-0 w-100">
                          <div class="d-flex p-0 w100minus100">
                            <div>{index + 1}</div>
                            <div class="ms-2 w-100">
                              <div className="d-flex">
                                <div
                                  class="fw-semibold c-font f-14 text-truncate title-fonts"
                                  dangerouslySetInnerHTML={{
                                    __html: space.office_title
                                      ? space.office_title
                                      : space.description
                                      ? space.description.split("<br>")[0]
                                      : "",
                                  }}
                                ></div>
                                {["bottom"].map((placement) => (
                                  <OverlayTrigger
                                    trigger={["hover", "focus"]}
                                    key={placement}
                                    placement={placement}
                                    overlay={
                                      <Popover
                                        id={`popover-positioned-${placement}`}
                                        className="overflowscroll"
                                      >
                                        {/* <Popover.Header as="h3">{`Popover ${placement}`}</Popover.Header> */}
                                        <Popover.Body className="pt-0">
                                          <div className="popover-detail-wrapper pb-0">
                                            <div className="d-flex justify-content-between">
                                              <div className="left-detail w-75">
                                                {space.office_title &&
                                                space.description
                                                  ? space.description
                                                  : space.description
                                                  ? space.description.split(
                                                      "<br>"
                                                    )[1]
                                                  : ""}
                                              </div>
                                              <div className="right-amount color-white fw-semibold">
                                                {space.total_price &&
                                                PlanDetails.total_price != NaN
                                                  ? space.total_price
                                                  : ""}
                                              </div>
                                            </div>
                                          </div>
                                          {/* <div className="popover-detail-wrapper">
                                      <div className="d-flex justify-content-between">
                                        <div className="left-detail line-clamp-2 w-75">
                                          John's spaceFrance Langue Nice, Boulevard Auguste
                                          Raynaud, Nice, France-100sqm
                                        </div>
                                        <div className="right-amount color-white fw-semibold">
                                          €0.00
                                        </div>
                                      </div>
                                    </div>
                                    <div className="popover-detail-wrapper">
                                      <div className="d-flex justify-content-between">
                                        <div className="left-detail line-clamp-2 w-75">
                                          John's spaceFrance Langue Nice, Boulevard Auguste
                                          Raynaud, Nice, France-100sqm
                                        </div>
                                        <div className="right-amount color-white fw-semibold">
                                          €0.00
                                        </div>
                                      </div>
                                    </div> */}
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <a href="#/" className="info-icon px-2 h-100">
                                      <Info size={14}  weight="light" className="c-icons  opacity-50" />
                                    </a>
                                  </OverlayTrigger>
                                ))}
                              </div>
                              {/* <div className="d-flex align-items-center title-fonts">
                              <div className="color-white-60 c-font f-12">
                                Start date:   {
                                  //   space.date_added != "" &&
                                  // space.date_added != "null" &&
                                  // space.date_added != "undefined"
                                  //   ? moment(space.date_added).format(
                                  //       "DD/MM/YYYY"
                                  //     )
                                  //   : "Start Date "
                                }
                              </div>
                              <a href="#/" onClick={() => {
                                handler(space.id)
                              }}>
                              </a>
                            </div> */}
                            </div>
                          </div>
                          <div className="color-green">
                            {space.rate ? space.rate : ""}
                          </div>
                        </div>
                      </li>
                    </>
                  );
                })
            ) : (
              <>
                {/* <div className="comman-placeholder-wrapper w-100 mt-5">
                  <div className="d-flex flex-column align-items-center m-auto">
                    <img
                      src={Placeholder}
                      alt="Placeholder"
                      className="big-image"
                    />
                    <span className="color-white-80 py-2 title-fonts">
                      No New space
                    </span>
                  </div>
                </div> */}
              </>
            )}
            {/* (newOffices.length >= (((newSpacePage - 1) * 3) + 3) && newOffices.length != (((newSpacePage - 1) * 3) + 3)) */}
            {(newOffices.length > 3 && newSpacePage == 1) || loder.newLoder ? (
              <a href="#/"
                className="btn c-font f-12 py-2 w-100 view-more-btn btn-white-03"
                onClick={() => {
                  setLoder({ ...loder, ["newLoder"]: true });
                  scroll(0);
                }}
              >
                <div className="d-flex align-item-center justify-content-center">
                  <span className="title-fonts">
                    {_l("l_view_all")} ({newOffices.length - 3})
                  </span>
                  <CaretDoubleDownIcon className="comman-icons mx-1 HW14" />
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    className={`ms-2 ${
                      loder.newLoder ? "opacity-1" : "opacity-0"
                    }`}
                    aria-hidden="true"
                  />
                </div>
              </a>
            ) : (
              <></>
            )}
            <div
              className={`color-white custom-accordian-Header-sticky py-2 mb-2 ps-2 radius_3 bg-premium-transparent ${
                curOffices.length == 0 ? "d-none" : ""
              }`}
            >
              # {_l("l_current_space")}
            </div>
            {curOffices && curOffices.length ? (
              curOffices
                .slice(0, curSpacePage > 1 ? curOffices.length : curSpacePage * 3)
                .map((space, index) => {
                  // let spaceHeader = ''
                  // if (!tempHeaderPushOrNotNew && space.status == 0) {
                  //   tempHeaderPushOrNotNew = true
                  //   spaceHeader = <div className={`color-white mt-4`}># {space.status == 0 ? 'New ' : 'Current '} space</div>
                  // } else if (!tempHeaderPushOrNotCurrent && (space.status == 1 || space.status == 2)) {
                  //   tempHeaderPushOrNotCurrent = true
                  //   spaceHeader = <div className={`color-white mt-4`}># {space.status == 0 ? 'New ' : 'Current '} space</div>
                  // }
                  return (
                    <>
                      {/* <li className="list-group-item border-separator w-100" key={index}>
                    <div className="d-flex justify-content-between  p-0 w-100">
                      <div className="d-flex  p-0 w100minus100">
                        <div>{index+1}</div>
                        <div className="ms-3">
                          <div className="color-white c-font f-15">{space.office_title ? space.office_title   : space.description ? space.description.split('<br>')[0] : ''}</div>
                          <div className="color-white-60 c-font f-13">{space.office_title && space.description ? space.description   : space.description ? space.description.split('<br>')[1] : ''}</div>
                          <div className="color-white-60 c-font f-13">{space.area ? space.area + "m²"  : ''} </div>
                        </div>
                      </div>
                      <div className="color-green"> {space.total_price ? '€ '+space.total_price   : ''}</div>
                    </div>
                  </li> */}

                      {/* {spaceHeader} */}
                      <li class="list-group-item border-separator w-100 px-3">
                        <div class="d-flex justify-content-between p-0 w-100">
                          <div class="d-flex p-0 w100minus100">
                            <div>{index + 1}</div>
                            <div class="ms-2 w-100">
                              <div className="d-flex">
                                <div
                                  class="fw-semibold c-font f-14 text-truncate title-fonts"
                                  dangerouslySetInnerHTML={{
                                    __html: space.office_title
                                      ? space.office_title
                                      : space.description
                                      ? space.description.split("<br>")[0]
                                      : "",
                                  }}
                                ></div>
                                {["bottom"].map((placement) => (
                                  <OverlayTrigger
                                    trigger={["hover", "focus"]}
                                    key={placement}
                                    placement={placement}
                                    overlay={
                                      <Popover
                                        id={`popover-positioned-${placement}`}
                                        className="overflowscroll"
                                      >
                                        {/* <Popover.Header as="h3">{`Popover ${placement}`}</Popover.Header> */}
                                        <Popover.Body className="pt-0">
                                          <div className="popover-detail-wrapper pb-0">
                                            <div className="d-flex justify-content-between">
                                              <div className="left-detail w-75">
                                                {space.office_title &&
                                                space.description
                                                  ? space.description
                                                  : space.description
                                                  ? space.description.split(/<br>|\n/)[1] 
                                                  : ""}
                                              </div>
                                              <div className="right-amount color-white fw-semibold">
                                                {space.total_price &&
                                                PlanDetails.total_price != NaN
                                                  ? space.total_price
                                                  : ""}
                                              </div>
                                            </div>
                                          </div>
                                          {/* <div className="popover-detail-wrapper">
                                      <div className="d-flex justify-content-between">
                                        <div className="left-detail line-clamp-2 w-75">
                                          John's spaceFrance Langue Nice, Boulevard Auguste
                                          Raynaud, Nice, France-100sqm
                                        </div>
                                        <div className="right-amount color-white fw-semibold">
                                          €0.00
                                        </div>
                                      </div>
                                    </div>
                                    <div className="popover-detail-wrapper">
                                      <div className="d-flex justify-content-between">
                                        <div className="left-detail line-clamp-2 w-75">
                                          John's spaceFrance Langue Nice, Boulevard Auguste
                                          Raynaud, Nice, France-100sqm
                                        </div>
                                        <div className="right-amount color-white fw-semibold">
                                          €0.00
                                        </div>
                                      </div>
                                    </div> */}
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <a href="#/" className="info-icon px-2 h-100 opacity-50">
                                      <Info size={16}  weight="light" className="c-icons" />
                                    </a>
                                  </OverlayTrigger>
                                ))}
                              </div>
                              {/* <div className="d-flex align-items-center title-fonts">
                              <div className="color-white-60 c-font f-12">
                                Start date:   {
                                  //   space.date_added != "" &&
                                  // space.date_added != "null" &&
                                  // space.date_added != "undefined"
                                  //   ? moment(space.date_added).format(
                                  //       "DD/MM/YYYY"
                                  //     )
                                  //   : "Start Date "
                                }
                              </div>
                              <a href="#/" onClick={() => {
                                handler(space.id)
                              }}>
                              </a>
                            </div> */}
                            </div>
                          </div>
                          <div className="color-green">
                            {space.rate ? space.rate : ""}
                          </div>
                        </div>
                      </li>
                    </>
                  );
                })
            ) : (
              <>
                {/* <div className="comman-placeholder-wrapper w-100 mt-5">
                  <div className="d-flex flex-column align-items-center m-auto">
                    <img
                      src={Placeholder}
                      alt="Placeholder"
                      className="big-image"
                    />
                    <span className="color-white-80 py-2 title-fonts">
                      No Current space
                    </span>
                  </div>
                </div> */}
              </>
            )}
            {/* (curOffices.length >= (((curSpacePage - 1) * 3) + 3) && curOffices.length != (((curSpacePage - 1) * 3) + 3)) */}
            {(curOffices.length > 3 && curSpacePage == 1) ||
            loder.currentLoder ? (
              <a href="#/"
                className="btn c-font f-12 py-2 w-100 view-more-btn btn-white-03"
                onClick={() => {
                  setLoder({ ...loder, ["currentLoder"]: true });
                  scroll(1);
                }}
              >
                <div className="d-flex align-item-center justify-content-center">
                  <span className="title-fonts">
                    {_l("l_view_all")} ({curOffices.length - 3})
                  </span>
                  <CaretDoubleDownIcon className="comman-icons mx-1 HW14" />
                  {loder.currentLoder ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      className={`ms-2 ${
                        loder.currentLoder ? "opacity-1" : "opacity-0"
                      }`}
                      aria-hidden="true"
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </a>
            ) : (
              <></>
            )}
          </ul>
        </li>
        <li className="border-separator w-100 p-0 px-3 mt-auto d-none">
          <div className="d-flex justify-content-between align-items-center p-15 w-100">
            <div className="d-flex align-items-center p-0 w100minus100">
              <div className="d-flex align-items-center position-relative">
                <div className="color-white-60">{_l("l_current_space")}</div>
                {["bottom"].map((placement) => (
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    key={placement}
                    placement={placement}
                    overlay={
                      <Popover
                        id={`popover-positioned-${placement}`}
                        className="overflowscroll"
                      >
                        {/* <Popover.Header as="h3">{`Popover ${placement}`}</Popover.Header> */}
                        <Popover.Body className="pt-0">
                          <div className="popover-detail-wrapper">
                            <div className="d-flex justify-content-between">
                              <div className="left-detail line-clamp-2 w-75">
                                John's spaceFrance Langue Nice, Boulevard Auguste
                                Raynaud, Nice, France-100sqm
                              </div>
                              <div className="right-amount color-white fw-semibold">
                                €0.00
                              </div>
                            </div>
                          </div>
                          <div className="popover-detail-wrapper">
                            <div className="d-flex justify-content-between">
                              <div className="left-detail line-clamp-2 w-75">
                                John's spaceFrance Langue Nice, Boulevard Auguste
                                Raynaud, Nice, France-100sqm
                              </div>
                              <div className="right-amount color-white fw-semibold">
                                €0.00
                              </div>
                            </div>
                          </div>
                          <div className="popover-detail-wrapper">
                            <div className="d-flex justify-content-between">
                              <div className="left-detail line-clamp-2 w-75">
                                John's spaceFrance Langue Nice, Boulevard Auguste
                                Raynaud, Nice, France-100sqm
                              </div>
                              <div className="right-amount color-white fw-semibold">
                                €0.00
                              </div>
                            </div>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <a href="#/" className="info-icon px-2 opacity-50">
                      <Info size={16}  weight="light" className="c-icons" />
                    </a>
                  </OverlayTrigger>
                ))}
              </div>
            </div>
            <div className="color-white-60">€ 20</div>
          </div>
        </li>
      </>
    }
      {/* <li className="border-separator w-100 ">
        <div className="d-flex justify-content-between align-items-center p-15 w-100">
          <div className="d-flex align-items-center p-0 w100minus100">
            <div className="d-flex align-items-center">
              <div className="color-white-60">Subtotal</div>
              {["bottom"].map((placement) => (
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  key={placement}
                  placement={placement}
                  overlay={
                    <Popover
                      id={`popover-positioned-${placement}`}
                      className="overflowscroll w-100"
                    >
                      <Popover.Header as="h3">{`Popover ${placement}`}</Popover.Header> 
                      <Popover.Body>
                        <div className="row justify-content-between">
                          <div className="col-5 text-center">
                            <div className="price fw-semibold color-white title-fonts">
                              €0.00
                            </div>
                            <div className="price color-white-60">
                              new space
                            </div>
                          </div>
                          <div className="col-2 text-center c-font f-24 color-white">
                            +
                          </div>
                          <div className="col-5 text-center">
                            <div className="price fw-semibold color-white title-fonts">
                              €0.00
                            </div>
                            <div className="price color-white-60">
                              new space
                            </div>
                          </div>
                        </div>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <a href="#/" className="info-icon px-2">
                    <Info size={16}  weight="light" className="c-icons" />
                  </a>
                </OverlayTrigger>
              ))}
            </div>
          </div>
          <div className="color-white-60">€ {PlanDetails.subtotal ? PlanDetails.subtotal : ''}</div>
        </div>
      </li> */}
      {/* <li className="border-separator w-100">
        <div className="d-flex justify-content-between align-items-center p-15 w-100">
          <div className="d-flex align-items-center p-0 w100minus100">
            <div className="">
              <div className="color-white-60">{PlanDetails.default_name ? PlanDetails.default_name : ''}</div>
            </div>
          </div>
          <div className="color-white-60">€ {PlanDetails.default_tax ? PlanDetails.default_tax : ''}</div>
        </div>
      </li> */}

      <li className={`border-separator w-100  p-15 px-3 `}>
        <div
          className={`d-flex justify-content-between align-items-center pb-3  w-100 ${
            newOffices.length == 0 ? "d-none" : ""
          }`}
        >
          <div className="d-flex align-items-center p-0 w100minus100">
            <div className="">
              <div className="color-white-60">
                {_l("l_new_space_subtitle")}
                {PlanDetails.future_subtotal_date &&
                  ["bottom"].map((placement) => (
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      key={placement}
                      placement={placement}
                      overlay={
                        <Popover
                          id={`popover-positioned-${placement}`}
                          className="overflowscroll"
                        >
                          {/* <Popover.Header as="h3">{`Popover ${placement}`}</Popover.Header> */}
                          <Popover.Body className="w-200 p-2">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="flex-grow-1 text-center">
                                <div className="title-fonts">
                                  {_l("l_next_billing_date")} -
                                </div>
                                <div className="c-font f-12">
                                  {PlanDetails.future_subtotal_date !=
                                  "0000-00-00"
                                    ? moment(
                                        PlanDetails.future_subtotal_date
                                      ).format("DD/MM/YYYY")
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <a href="#/" className="info-icon ps-1">
                        <Info size={14}  weight="light" className="c-icons opacity-50" />
                      </a>
                    </OverlayTrigger>
                  ))}
              </div>
            </div>
          </div>
          <div class="color-green title-fonts">
            {PlanDetails.new_space_subtotal
              ? PlanDetails.new_space_subtotal
              : ""}
          </div>
        </div>
        <div
          className={`d-flex justify-content-between align-items-center pb-3  w-100 ${
            curOffices.length == 0 ? "d-none" : ""
          }`}
        >
          <div className="d-flex align-items-center p-0 w100minus100 text-truncate">
            <div className="d-flex align-items-center text-truncate">
              <div className="color-white-60 text-truncate">
                {_l("l_current_space_subtitle")}
              </div>
              {PlanDetails.subtotal_date &&
                ["bottom"].map((placement) => (
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    key={placement}
                    placement={placement}
                    overlay={
                      <Popover
                        id={`popover-positioned-${placement}`}
                        className="overflowscroll"
                      >
                        {/* <Popover.Header as="h3">{`Popover ${placement}`}</Popover.Header> */}
                        <Popover.Body className="w-200 p-2">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="flex-grow-1 text-center">
                              <div className="title-fonts">
                                {_l("l_next_billing_date")} -
                              </div>
                              <div className="c-font f-12">
                                {PlanDetails.subtotal_date != "0000-00-00"
                                  ? moment(PlanDetails.subtotal_date).format(
                                    "DD/MM/YYYY"
                                  )
                                  : ""}
                              </div>
                            </div>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <a href="#/" className="info-icon px-2">
                      <Info size={14}  weight="light" className="c-icons opacity-50" />
                    </a>
                  </OverlayTrigger>
                ))}
            </div>
          </div>
          <div class="color-green title-fonts">
            {PlanDetails.current_space_subtotal
              ? PlanDetails.current_space_subtotal
              : ""}
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center pb-3 w-100">
          <div className="d-flex align-items-center p-0 w100minus100 text-truncate">
            <div className="d-flex align-items-center text-truncate">
              <div className="color-white-60 text-truncate">
                {_l("l_subtotal")}
              </div>
              {["bottom"].map((placement) => (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  key={placement}
                  placement={placement}
                  overlay={
                    <Popover
                      id={`popover-positioned-${placement}`}
                      className="overflowscroll"
                    >
                      {/* <Popover.Header as="h3">{`Popover ${placement}`}</Popover.Header> */}
                      <Popover.Body className="w-200 p-2">
                        {/* <div class="color-white-60">
                          StartDate - {PlanDetails.subtotal_date ? moment(PlanDetails.subtotal_date).format(
                            "DD/MM/YYYY"
                          ) : ""}
                        </div> */}
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="flex-grow-1 text-center">
                            {/* <div className="title-fonts">{PlanDetails.new_space_subtotal ? PlanDetails.new_space_subtotal : ""}</div> */}
                            <div className="title-fonts">
                              {_l("l_next_billing_date")} -
                            </div>

                            <div className="c-font f-12">
                              {PlanDetails.subtotal_date != "0000-00-00"
                                ? moment(PlanDetails.subtotal_date).format(
                                    "DD/MM/YYYY"
                                  )
                                : ""}
                            </div>
                          </div>

                          {/* <PlusIcon className="HW14" />
                          <div className="flex-grow-1 text-center">
                            <div className="title-fonts">{PlanDetails.current_space_subtotal ? PlanDetails.current_space_subtotal : ""}</div>
                            <div className="c-font f-12">Current Space</div>
                          </div> */}
                        </div>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <a href="#/" className="info-icon px-2">
                    <Info size={14}  weight="light" className="c-icons opacity-50" />
                  </a>
                </OverlayTrigger>
              ))}
            </div>
          </div>
          <div class="color-green title-fonts">
            {PlanDetails.subtotal && PlanDetails.subtotal != NaN
              ? PlanDetails.subtotal
              : ""}
          </div>
        </div>
        <div
          className={`d-flex justify-content-between align-items-center pb-3 w-100 ${
            PlanDetails.subtotal && PlanDetails.future_subtotal > 0
              ? ""
              : "d-none"
          }`}
        >
          <div className="d-flex align-items-center p-0 w100minus100">
            <div className="d-flex align-items-center">
              <div className="color-white-60">{_l("l_future_subtotal")}</div>
              {["bottom"].map((placement) => (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  key={placement}
                  placement={placement}
                  overlay={
                    <Popover
                      id={`popover-positioned-${placement}`}
                      className="overflowscroll"
                    >
                      {/* <Popover.Header as="h3">{`Popover ${placement}`}</Popover.Header> */}
                      <Popover.Body className="pt-0">
                        <div class="color-white-60">
                          {_l("l_startdate")} -{" "}
                          {PlanDetails.future_subtotal_date
                            ? moment(PlanDetails.future_subtotal_date).format(
                                "DD/MM/YYYY"
                              )
                            : ""}
                        </div>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <a href="#/" className="info-icon px-2 h-100 opacity-50">
                    <Info size={16}  weight="light" className="c-icons" />
                  </a>
                </OverlayTrigger>
              ))}
            </div>
          </div>
          <div class="color-green title-fonts">
            {PlanDetails.future_subtotal ? PlanDetails.future_subtotal : ""}
          </div>
        </div>
        {PlanDetails.discount_percent && PlanDetails.discount_percent > 0 ? (
          <div className="d-flex justify-content-between align-items-center pb-3 w-100">
            <div className="d-flex align-items-center p-0 w100minus100">
              <div className="d-flex align-items-center">
                <div className="color-white-60">{_l("l_discount")}</div>
              </div>
            </div>
            <div class="color-green title-fonts">
              {PlanDetails.discount_total ? PlanDetails.discount_total : ""}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex align-items-center p-0 w100minus100">
            <div className="">
              <div className="color-white-60">
                {PlanDetails.default_name ? PlanDetails.default_name : ""}{" "}
                {PlanDetails.default_tax
                  ? "(" + PlanDetails.default_tax + "%)"
                  : ""}
              </div>
            </div>
          </div>
          <div class="color-green title-fonts">
            {PlanDetails.total_tax ? PlanDetails.total_tax : ""}
          </div>
        </div>
      </li>
      {/* <li className="border-separator w-100 p-15">
        <div className="d-flex justify-content-between align-items-center p-0 w-100">
          <div className="d-flex align-items-center">
            <div className="">
              <div className="color-white">Total</div>
            </div>
          </div>
          <div className="color-white">
            € {PlanDetails.total ? PlanDetails.total : ""} / Month
          </div>
        </div>
      </li> */}
      <li className="w-100 p-15">
        <div className="d-flex justify-content-between align-items-center p-0 w-100">
          <div className="d-flex align-items-center">
            <div className="">
              <div className="color-white fw-semibold title-fonts">{_l("l_total_amount")}</div>
            </div>
          </div>
          <div className="color-green title-fonts fw-semibold">
            {" "}
            {PlanDetails.total && PlanDetails.total != NaN
              ? PlanDetails.total +  `/ ${_l("l_month")}`
              : ""}
          </div>
        </div>
      </li>

      {/* <div className="form-check with-span p-15 pb-0">
        <div className="d-flex align-items-center">
          <label className="form-check-label text_wrap w-100">
            <input className="form-check-input" type="checkbox" value="" />
            <span>
              By clicking on “Start my onboarding”, I agree to the Terms of
              Service (CGSC) and privacy policy (RGPD) of the platform.
            </span>
          </label>
        </div>
      </div>
      <li className="list-group-item border-separator w-100 ">
        <a href="#/" className="btn btn-primary btn-sm w-100">Change plan</a> */}
      {/* <div className="color-white-60 c-font f-14 text-center mt-3">
            After the onboarding period you will charge of total € by
            months
          </div> */}
      {/* </li> */}
    </>
  );
}
