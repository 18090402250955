import React, { useEffect, useState } from "react";
import Customcalendar from "../components/Customcalendar";
import { showLeftPanel ,leftPanelCategoryListToggle } from "../hooks/utilities";

const CustomFullCalendar = ({pageName}) => {

  const [selectedEventDate, setselectedEventDate] = useState("");
  const [selectStartDate] = useState("")

  useEffect(() => {
    showLeftPanel(true , true);
    document.addEventListener('mouseup', leftPanelCategoryListToggle);
    return () => {
      document.removeEventListener('mouseup', leftPanelCategoryListToggle);
    }
  }, []);
  return (
    <>
     <Customcalendar pageName="calendar"  setselectedEventDate={setselectedEventDate} selectStartDate={selectStartDate}/>
    </>
  );
  };

export default CustomFullCalendar;
