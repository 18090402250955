import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import CustomerServices from "../../services/customer-services";
import { _l } from "../../hooks/utilities";
import { showMessage } from "../../actions/messages";
import { useDispatch } from "react-redux";
const AddNewCategory = ({ categories, handler, projectId, show, handleClose, activeTopicKey, editTopicDetails }) => {
  const dispatch = useDispatch();
  const [categoryTitle, setCategoryTitle] = useState(editTopicDetails ? editTopicDetails.title : "");
  const [selectedCategory, setSelectedCategory] = useState(categories && categories.length ? categories[0] : { value: "", label: "" });
  const page_name = window.location.pathname;
  const saveCategory = () => {

    if (categoryTitle && categoryTitle != '') {
      CustomerServices.addCustomTopicFilter(categoryTitle, activeTopicKey, editTopicDetails && editTopicDetails.id ? editTopicDetails.id : 0,page_name == "/leads" ? "leadPage" :"").then((res) => {
        if (res.status == 1) {
          if (editTopicDetails && editTopicDetails.id && editTopicDetails.id > 0) {
            handler({ id: editTopicDetails.id, name: categoryTitle });
          }else{
            handler({ id: res.data.tag_id ? res.data.tag_id : 0, name: categoryTitle });
          }
          handleClose();
        }
      });
    } else {
      dispatch(showMessage('unsucess', _l("l_error"), _l("l_please_enter_valid_topic")));
      return false;
    }
  };

  return (
    <div className="custom-modal-style add-topic-search">
      <Modal
        className="modal fade custom-modal-style"
        tabIndex="-1"
        aria-labelledby="addNewCategoryModal"
        aria-hidden="true"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton className="">
          <Modal.Title>{editTopicDetails && editTopicDetails.id && editTopicDetails.id > 0 ? _l('l_edit_new_topic') : _l('l_add_new_topic')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="add-new-category">
            <div className="d-flex align-items-center">
              <a href="#/"
                className="bg-white-03 dropdown-toggle d-flex align-items-center p-2 d-none"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                id="EmojiDropdown"
              >
                <div className="h30w30  d-flex comman-round-box with-bg radius_3 me-2">
                  <span className="m-auto">😊</span>
                </div>
              </a>
              <Form.Group className="comment-input-box flex-grow-1">
                <Form.Label className="input-label no-asterisk ">
                  {_l('l_topic_name')}
                </Form.Label>
                <Form.Control
                  className="height-45 pe-2"
                  type="text"
                  placeholder={`${_l('l_topic_name')}`}
                  value={categoryTitle}
                  onChange={(e) => { setCategoryTitle(e.target.value) }}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      saveCategory();
                    }
                  }}
                />

              </Form.Group>
            </div>
            <Form.Group className="c-input-box  position-relative py-3 d-none">
              <div className="row align-items-center">
                <div className="col-2">
                  <Form.Label className="input-label no-asterisk pe-0 mb-0">
                    {_l('l_category')}
                  </Form.Label>
                </div>
                <div className="col-10">
                  <Select
                    className="custom-select-menu"
                    classNamePrefix="react-select"
                    options={categories}
                    value={selectedCategory}
                    onChange={(option) => { setSelectedCategory(option) }}
                  />
                </div>
              </div>
            </Form.Group>
          </div>

        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l('l_close')}
          </Button>
          <Button variant="primary" size="sm" onClick={saveCategory}>{_l('l_save')}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddNewCategory;
