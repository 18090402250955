import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { PencilSimpleLine, Trash } from "phosphor-react";
import { useDispatch } from "react-redux";
import AddEditNoteProposalModal from "./AddEditNoteProposalModal";
import benchmarkServices from "../../services/benchmark-services";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { showConfirmation, showMessage } from "../../actions/messages";
import { _l } from "../../hooks/utilities";
import NotesSkeleton from '../Skeleton/NotesSkeleton';

const NoteProposalModal = ({
  handleClose,
  openNoteModal
}) => {
    const [notesItems, setnotesItems] = useState([]);
    const [editNoteItem, setEditNoteItem] = useState({})
    const [description, setDescription] = useState(editNoteItem.description ? editNoteItem.description :'')
    const [loader, setLoader] = useState(false)
    const [loader2, setLoader2] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(true);    
    const dispatch = useDispatch();
    const textareaRef = useRef(null);

    useEffect(() => {
      setShowSkeleton(true);
     try {
      benchmarkServices.getNoteProposal("proposal", openNoteModal.id).then((res) => {
        if (res && res.status) {
          setnotesItems(res.data);
          setShowSkeleton(false);
        } else {
          setShowSkeleton(false);
        }
      })
     } catch (error) {
        console.log(error);
     }
    }, [])

  const deleteCatelogItem = async (brandId) => {
    dispatch(
      showConfirmation(
        _l("l_are_you_sure"),
        "Annuler un élément de note",
        _l("l_yes"),
        _l("l_no"),
        () => {},
        async () => {
          setShowSkeleton(true);
  
          try {
            const res = await benchmarkServices.deleteNoteProposal(brandId);
  
            if (res.status) {
              const updatedNotesItems = notesItems.filter((c) => c.id !== brandId);
              setnotesItems(updatedNotesItems);
              dispatch(showMessage("success", _l("l_success"), "Note supprimée"));
            } else {
              dispatch(showMessage("unsuccess", _l("l_error"), _l(res.message)));
            }
          } catch (error) {
            console.error('Error deleting note:', error);
            dispatch(showMessage("unsuccess", _l("l_error"), "An error occurred while deleting the note."));
          } finally {
            setShowSkeleton(false);
          }
        }
      )
    );
  };

  const fieldValidations = () => {
    const descriptionText = editNoteItem && Number(editNoteItem.id) > 0 
      ? editNoteItem.description.trim() 
      : description.trim();
  
    if (!descriptionText) {
      dispatch(
        showMessage("unsuccess", _l("l_error"), "La description de l'article est requise")
      );
      return false;
    }
  
    return true;
  };

  const addNewItems = () => {
    if (!fieldValidations()) {
      if (editNoteItem && editNoteItem.id > 0) {
        setLoader(false);
      } else {
        setLoader2(false);
      }
      return false;
    }
    setShowSkeleton(true);
    try {
      if (editNoteItem && editNoteItem.id > 0) {
        setLoader(true);
      } else {
        setLoader2(true);
      }
      benchmarkServices.addEditNoteProposal(
        editNoteItem && Number(editNoteItem.id) > 0 ? editNoteItem.description : description, 
        "proposal" , 
        openNoteModal.id, 
        editNoteItem && Number(editNoteItem.id) > 0 ? Number(editNoteItem.id) : 0
      ).then((res) => {
        if (res && res.status) {
          dispatch(showMessage("sucess", _l("l_success"), _l(res.message)));
          
          if (editNoteItem && editNoteItem.id > 0) {
            const  notesItemsUpdate = notesItems.map((brand) => {
              if (brand.id == editNoteItem.id) {
               brand.description = editNoteItem.description
              }
              return brand;
            })
            setnotesItems(notesItemsUpdate);
          }else {
            let update = [{
              "description" : description,
              "id" : res.data.note_id,
              "dateadded" : formateDate()
            }]
            setnotesItems(notesItems.concat(update))
          }
          if (editNoteItem && editNoteItem.id > 0) {
            setLoader(false);
          } else {
            setLoader2(false);
          }
          setEditNoteItem({});
          setDescription("");
        }else{
          dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
          if (editNoteItem && editNoteItem.id > 0) {
            setLoader(false);
          } else {
            setLoader2(false);
          }
        }
      })
    } catch (error) {
      console.error(error);
    }
    setShowSkeleton(false);
  }

  const formateDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  }

  const handleFocus = (index) => {
    const el = document.getElementById(`note_text_${index}`);
    if (el) {
      el.focus();
    }
  };

  return (
    <>
        <Modal
        show={true}
        onHide={handleClose}
        backdrop={"static"}
        keyboard={false}
        size="xl"
        centered
        className="custom-modal-style solarcommanmodal maxheight85vh "
        aria-labelledby="contained-modal-title-vcenter"
        >
        <Modal.Header className="p-3 d-flex align-items-center justify-content-between" closeButton>
            <Modal.Title>{_l("l_note")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-hiiden-auto p-20pximp minheight200px">
          {/* List view start */}
          {
          <div className="row">
             <div className="bg-white d-flex flex-column mb-10px p-10 radius_3">
                    <div className="align-items-center d-flex flex-grow-1 justify-content-between gap-4">
                    <div className="form-wrapper-main p-0 w-100">
                      <div className="row">
                        <Form.Group className="col-xl-12 c-input-box position-relative">
                          <Form.Control
                            placeholder={`${_l("l_description")}`}
                            as="textarea"
                            type="text"
                            name=""
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            onClick={() => setEditNoteItem({})}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    </div>
                </div>
                <div className="d-flex align-items-center gap10px mb-15px justify-content-end">
                  <Button variant="primary" disabled={loader2} onClick={() => addNewItems()}>
                    {loader2 ? 
                      <>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            className={`me-2 `}
                            aria-hidden="true"
                          />
                          {_l('l_please_wait')}
                      </> 
                    : _l("l_add")} 
                  </Button>
                </div>
            {showSkeleton ? <NotesSkeleton /> : notesItems && notesItems.length > 0 ?
            notesItems.map((brand, index) => (
              <>
              <div className="pt-10px border-bottom">
                <div className="d-flex justify-content-between flex-column">
                  <div className="d-flex">
                    <div className="d-flex align-items-center gap10px flex-grow-1">
                      <div className="comman-image-box h32w32 rounded-circle bg-black-op5">
                        <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{backgroundImage: `url(https://api.blaz.app/web/orb/assets/images/default-image-placeholder.svg)`}}>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between flex-grow-1">
                        <div className="d-flex flex-column w-100">
                          <div className="c-font f-13 fw-semibold">Testing username</div> 
                          <div className="c-font color-blacl-70 f-11">{brand.dateadded}</div>
                        </div>
                        <div className="d-flex align-items-center gap10px">
                          { brand.id == editNoteItem.id ?
                            <div className="d-flex align-items-center gap10px">
                              <Button variant="secondary" onClick={() => setEditNoteItem({})}>
                                {_l("l_cancel")}
                              </Button>
                              <Button variant="primary" disabled={loader} onClick={() => addNewItems()}>
                                {loader ? 
                                  <>
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      className={`me-2 `}
                                      aria-hidden="true"
                                    />
                                    {_l('l_please_wait')}
                                  </> 
                                : _l("l_add")} 
                              </Button>
                            </div> 
                            :
                            <a href="#/" className="bg-white d-flex flex-column justify-content-center align-items-center position-relative action_icon h32w32 border radius_3 "
                              onClick={(e) => {
                                e.stopPropagation();
                                setDescription("");
                                setEditNoteItem(brand);
                                handleFocus(index);
                              }}
                            >
                              <PencilSimpleLine size={18} className="c-icons" weight="light"/>
                            </a>
                          }
                          <a href="#/" 
                            className="bg-white d-flex flex-column justify-content-center align-items-center position-relative action_icon h32w32 border radius_3"
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteCatelogItem(brand.id);
                            }}
                          >
                            <Trash size={18} className="c-icons" weight="light"/>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap10px flex-grow-1">
                    <div className="comman-image-box h32w32 rounded-circle">
                      <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"></div>
                    </div>                   
                    <div className="d-flex justify-content-between flex-grow-1">
                      <div className="bg-white d-flex flex-column flex-grow-1 mb-10px pt-10px radius_3">                        
                        <div className="form-wrapper-main p-0 w-100 ">
                          <Form.Group className="col-xl-12 c-input-box position-relative">
                            <Form.Control
                              id={`note_text_${index}`}
                              className={`border-0 p-0 shadow-none ${brand.id != editNoteItem.id ? "for-disabled" : ""}`}
                              placeholder={`${_l("l_description")}`}
                              as="textarea"
                              type="text"
                              name=""
                              // disabled ={brand.id != editNoteItem.id}
                              value={brand.id == editNoteItem.id ? editNoteItem.description : brand.description}
                              onChange={(e) => setEditNoteItem({...editNoteItem, description : e.target.value})}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>                 
            </>
            ))
            : 
            <div className="align-item-center d-flex h-100 justify-content-center">
              <CommanPlaceholder mainWidth={`calednarPlacehoder`} imgType="request" placeholderText={_l("l_no_data")} />
            </div>
          }
          </div>
          }
        </Modal.Body>
        </Modal>
    </>
  );
};

export default NoteProposalModal;
