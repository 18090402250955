import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { _l } from "../../hooks/utilities";
import {  useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import customerServices from "../../services/customer-services";
import { showMessage } from "../../actions/messages";
import { isLoadingData } from "../../hooks/loader_helper";
import CommanLoader from "../Loader/CommanLoader";

const PotentialSavingsModal = ({ show, handleClose, project_id, pageName, spacecontent, data, totalPotentialSaving, showModalLoader =false  }) => {

  const [loader, setLoader] = useState(false)
  const [raisBtnloader, setraisBtnLoader] = useState(false)
  const staff_id = localStorage.getItem("staff_id");
  let user_type = localStorage.getItem("user_type");
  const {daterangeFilterApplied, creditNoteDetails} =  useSelector(state => state.customer);
  const dispatch = useDispatch()

  const handlePreviewCreditNote = () =>{
    setLoader(true);
    if(creditNoteDetails.is_credit_note == 1){
        window.open(creditNoteDetails.credit_note_url, "_blank");
        setLoader(false);
    }else{
        handleRaisCreditNote(true, 1)
    } 

  }
  const handleRaisCreditNote = (fromPreview = false, is_preview = 0 ) =>{
      setraisBtnLoader(fromPreview ? false :true)
    let ItemArray = data.creditnote_items
    customerServices.raiseGlobalCreditnote(totalPotentialSaving, JSON.stringify(ItemArray), is_preview).then((res)=>{
        if (res.status && res.data) {
            setraisBtnLoader(false);
            setLoader(false);
            if(fromPreview){
                window.open(res.extra.credit_note_url,  "_blank")
                handleClose();
            }            
        }else{
            setraisBtnLoader(false);
            setLoader(false);
            dispatch(showMessage("unsucess", _l("l_error"), res.message ? _l(res.message) : _l("l_something_went_wrong")));
        }
    })
  }

  return (
    <>
      <div className="">
        <Modal
          show={show}
          size="lg"
          onHide={handleClose}
          className="custom-modal-style overflow-hidden"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{_l("l_creditnote_count")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <div className="d-flex justify-content-between pt-5px px-0">
                <div className="c-font f-14 title-fonts">{_l("l_no_of_not_completed_task")}</div>
                <div className="c-font f-14 title-fonts fw-semibold">{data && data.total_missing_tasks ? data.total_missing_tasks : 0}</div>
            </div>
            <div className="d-flex justify-content-between p-15 px-0">
                  <div className="c-font f-14 title-fonts">{_l("l_date_duration_of_not_completed_task")}</div>
                <div className="c-font f-14 title-fonts fw-semibold">{daterangeFilterApplied ? `${moment(localStorage.getItem("fromDate")).format("DD/MM/YYYY")} - ${moment(localStorage.getItem("toDate")).format("DD/MM/YYYY")}` : moment(localStorage.getItem("selectedDate")).format("DD/MM/YYYY") }</div>
            </div>
            {/* <div className="d-flex justify-content-between pt-15 px-0">
                <div className="d-flex justify-content-between col-4">
                    <div className="c-font f-14 title-fonts">{_l("l_per_task_amount")}</div>
                    <span>:</span>
                </div>
                <div className="d-flex justify-content-between col-8 comman_action_icon ps-30">
                <div className="c-font f-14 title-fonts">€ { 0}</div>
                
                </div>
            </div> */}
            <div className={`bg-white-03 p-15 ${showModalLoader ? "h-160px" : ""}`}>
              {/* {showModalLoader ? <>
                <CommanLoader className="position-relative start-0" />
              </>
                :
                <> */}
                  <div className="mb-10 color-white-60 c-font f-12">{_l("l_space")}</div>
                  <div className="border-bottom mb-2"></div>
                  <div className="maxh55vh overflow-hiiden-auto">

                    {data && data.creditnote_items && data.creditnote_items.map((credit) => {
                      return (<>
                        <div className="d-flex px-0 pb-15px justify-content-between">
                          <div className="d-flex">
                            <div className="c-font f-14 title-fonts">{credit.name}</div>
                          </div>
                          <div className="c-font f-14 title-fonts">€ {credit.total_credit}</div>
                        </div>
                      </>)
                    })}
                  </div>
                  <div className="d-flex justify-content-between pt-15 px-0 border-top">
                    <div className="c-font f-14 title-fonts fw-semibold">{_l("l_total")}</div>
                    <div className="c-font f-14 title-fonts fw-semibold color-green">€ {totalPotentialSaving}</div>
                  </div>
                  {/* </>} */}
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            {user_type == "contact" ?
             <>
             <Button className="border-0 btn-sm-2" variant="white-05" size="sm" onClick={() => {
              if (!loader) {
                handlePreviewCreditNote()
              }
            }}
              disabled={loader}
            >
              {loader
                ? _l('l_please_wait')
                : _l("l_preview_credit_note")
              }
              {loader ?
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
                : <></>
              }
            </Button>
            <Button variant="primary" size="sm" onClick={() => {
              if (!raisBtnloader) {
                handleRaisCreditNote()
              }
            }}
              disabled={raisBtnloader}
            >
              {raisBtnloader
                ? _l('l_please_wait')
                : _l("l_raise_credit_note")
              }
              {raisBtnloader ?
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
                : <></>
              }
            </Button></> : <></>}
            {
                user_type == "operator" ? 
                <>
                 <Button variant="primary" size="sm" onClick={() => {
              if (!raisBtnloader) {
                handleClose()
              }
            }}
              disabled={raisBtnloader}
            >
              {raisBtnloader
                ? _l('l_please_wait')
                : _l("l_approve_credit_note")
              }
              {raisBtnloader ?
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
                : <></>
              }
            </Button>

                </> 
                : <></>
            }
            
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default PotentialSavingsModal;

