import {
  Hash,
} from "phosphor-react";
import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { _l, showError } from "../../../hooks/utilities";
import { useSelector } from "react-redux";
import EquipmentsServices from "../../../services/equipments-services";
import GeneralCardView from "../../Documents/GeneralCard";

const EquipmentsList = ({
  list,
  projectId,
  equipmentData,
  setEquipmentData,
  onClick,
  getEquipmentList,
  setUploadBulkEquipments,
  isLoading,
  deleteEquipment
}) => {
  const topics = useSelector((state) => state.customer.defaultTopics);

  let operatorData = topics
    ? topics.find((w) => w.main_key === "my_operator")
    : [];
  operatorData =
    operatorData && operatorData.categories
      ? operatorData.categories
          .filter((w) => w.main_sub_key !== "team")
          .map((w) => {
            return {
              full_name: w.title,
              id: w.filter_key,
              profile_image: w.image,
            };
          })
      : [];

  let contactData = topics
    ? topics.find((w) => w.main_key === "my_contact")
    : [];
  contactData =
    contactData && contactData.categories
      ? contactData.categories
          .filter((w) => w.main_sub_key !== "team")
          .map((w) => {
            return {
              full_name: w.title,
              id: w.filter_key,
              profile_image: w.image,
            };
          })
      : [];

  const editEquipmentInfo = async (record, value, field) => {
    try {
      const response = await EquipmentsServices.updateEquipmentDetail(
        projectId,
        record.id,
        field,
        value
      );
      if (response.status) {
        await getEquipmentList();
      } else {
        showError(response.message);
      }
    } catch (e) {
      showError("l_something_went_wrong");
      console.error(e);
    }
  };

  const updateEquipmentQuantity = async (equipment, direction) => {
    if (direction > 0) {
      try {
        const response = await EquipmentsServices.addEquipmentQuantity(
          projectId,
          equipment.id,
          1
        );
        if (response.status) {
          await getEquipmentList();
        } else {
          showError(response.message);
        }
      } catch (e) {
        console.error(e);
        showError("l_something_went_wrong");
      }
    } else if (direction < 0 && equipment.quantity > 1) {
      try {
        let recordId = equipment.quantity_details.pop().id;
        const response = await EquipmentsServices.deleteEquipmentQuantity(
          equipment.id,
          recordId,
          projectId
        );
        if (response.status) {
          await getEquipmentList();
        } else {
          showError(response.message);
        }
      } catch (e) {
        console.error(e);
        showError("l_something_went_wrong");
      }
    }
  };

  return (
    <React.Fragment>
       <div className="d-flex justify-content-end m-0 bg-white-03-solid-imp position-sticky top-0 z-index-2">
        <div className="d-flex align-items-center justify-content-between w-100 ">
          <div class="d-flex align-items-center justify-content-between flex-grow-1">
          <Col xs={12} key={-1}>
            <div className="align-items-center comment-input-box d-flex form-control">
              <div className="align-items-center d-flex w-100 ">
                <div className="pe-2">
                  <Hash size={18} weight="light" className="c-icons" />
                </div>
                <Form.Control
                  className=" p-0 bg-transparent border-0   pe-5 shadow-none "
                  type="text"
                  value={equipmentData.title}
                  placeholder={_l("l_add_equipment")}
                  onChange={(e) => {
                    setEquipmentData({
                      ...equipmentData,
                      title: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </Col>
          </div>
          <div>
          </div>
        </div>
          <div className="ms-2 d-flex " key={1}>
            <Button variant="primary white-05" className="text-nowrap" disabled={isLoading} onClick={() => {onClick(equipmentData.title, 0)}}>{isLoading ? _l("l_please_wait") : _l("l_add_equipment")}</Button>
          </div>
          <div className="ms-2 d-flex " key={1}>
            <Button variant="white-05" className="text-nowrap" onClick={() => setUploadBulkEquipments(true)}>{_l("l_bulk_equipments_upload")}</Button>
          </div>
        </div>
      <div className="row my-0 g-0 comman-main-box">
       
        <div className="mt-10px"></div>
        {list.map((equipment, key) => {
           return (
            <div className="row mb-0  flex-grow-1 overflow-auto gx-0">
              <GeneralCardView
                key={key}
                cardTitle={equipment.title}
                ownerDetails={{
                  name: equipment.title,
                  profile_image: "",
                }}
                pills={[]}
                onClick={() => {onClick(equipment.title, equipment.id)}}
                peopleListTitle={"l_members"}
                people={[]}
                actionItems={[
                  {
                    actionName: _l("l_delete"),
                    actionHandler: () => { deleteEquipment(equipment.id)},
                    actionIcon: "TrashIcon",
                  },
                ]}
                hideChatAndCall
              />
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default EquipmentsList;
