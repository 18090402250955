import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { _l, showError} from "../../hooks/utilities";
import customerServices from "../../services/customer-services";
import { useDispatch, useSelector } from "react-redux";
import {showMessage, toastCloseAction } from "../../actions/messages";
import { closeOutsideClick, selectedTypeOfSapceId} from "../../actions/customer";
import $ from "jquery";
import {Trash } from "phosphor-react";
import benchmarkServices from "../../services/benchmark-services";
import {getFloorDetails, updateFloorDetails } from "../../actions/benchmark-actions/benchmark-actions";
import TypeOfSpaceAccordians from "../Accordians/TypeOfSpaceAccordians";

const SpaceRoom = ({ show, handleClose, handleChangeCordinate, cordinatesData, typeOfSpaceData, floorId, setPOIsList, from, selectedRoom, setSelectedRoom,setLastItem ,newTypeOfSpaceData, setNewTypeOfSpaceData}) => {
    const dispatch = useDispatch();
    const [roomTitle, setRoomTitle] = useState("");
    const [roomId, setRoomId] = useState(0);
    const [typeOfSpaceId, setTypeOfSpaceId] = useState(0);
    const [typeOfSpaceItems, setTypeOfSpaceItems] = useState([]);
    const project_id = localStorage.getItem("selectedOffice");
    const spaceFloorPlan = useSelector(
        (state) => state.benchmarkReducer.floorDetails
    );
    const { getSelectedFloorId } = useSelector((state) => state.customer);
    useEffect(() => {
        if (typeOfSpaceData) {
            setRoomTitle(typeOfSpaceData.title);
            setRoomId(typeOfSpaceData.id);
            setTypeOfSpaceId(typeOfSpaceData.room_id)
        }
        if (from == "create" && typeOfSpaceData) {
            let newArr = spaceFloorPlan.type_of_space
            let index = newArr.findIndex((type) => type.title == newTypeOfSpaceData.title)
            if (index > -1) {
                let key = newArr[index].details.findIndex((item) => item.id == typeOfSpaceData.id)
                if (key > -1 && cordinatesData.length > 0) {
                    newArr[index].details[key].poi_set = cordinatesData
                }
            }
            dispatch(updateFloorDetails({ ...spaceFloorPlan, type_of_space: newArr }));
        }
    }, [JSON.stringify(typeOfSpaceData), JSON.stringify(cordinatesData)])

    const addFloor = (spaceId, itemId, title, poiSet) => {
        // updateTypeOfSpaceTitle
        benchmarkServices.updateTypeOfSpaceTitle(project_id, "type_of_space_item", spaceId || typeOfSpaceId, itemId || roomId, title || roomTitle, poiSet || cordinatesData, floorId).then((res) => {
            if (res.status == 1) {
                if (from != "create") {
                    handleClose();
                }
                setPOIsList();
                dispatch(selectedTypeOfSapceId(undefined));
                dispatch(getFloorDetails(project_id, 1));
                // dispatch(updateFloorDetails({...spaceFloorPlan,type_of_space:res.data.type_of_space}));
                dispatch(showMessage('sucess', _l('l_success'), _l(res.message)));
            } else {
                dispatch(showMessage('unsucess', _l('l_error'), _l(res.message)));
            }
        });
    }
    useEffect(() => {
        $("#client_3d_optionfirst").css({ 'display': 'none' });
        $(".dropdown-menu").removeClass("show");
        // if ($('.blank_anchor').length) {
        //   $('.blank_anchor').remove();
        // }
        dispatch(closeOutsideClick(false));
    }, [])
    useEffect(() => {
        let element = document.body;
        if (show) {
            if (element) {
                element.classList.remove("modal-open");
            }
        }
        return () => {
            if (element) {
                element.classList.remove("modal-open");
            }
        };
    }, [show]);

    const getSpaceOptionList = () => {
        let spaceOptions = spaceFloorPlan.type_of_space
            .map((space) => {
                return {
                    label: space.title,
                    value: space.id,
                };
            });
        spaceOptions = spaceOptions.filter(
            (obj, index, self) =>
                index === self.findIndex((o) => obj.label == o.label)
        );
        return spaceOptions;
    };
    useEffect(() => {
        if (spaceFloorPlan && newTypeOfSpaceData.title && newTypeOfSpaceData.title != "") {
            spaceFloorPlan.type_of_space.map((tos) => {
                setTypeOfSpaceId(tos.id);
                if (tos.title == newTypeOfSpaceData.title) {
                    // if (tos.details.length > 0) {
                        //   const lastItem = tos.details.slice(-1)[0].title.split(" ").slice(-1)[0];
                        //   title = newTypeOfSpaceData.title + " " + (Number(lastItem) + 1)
                        // } else {
                            //   title = newTypeOfSpaceData.title + " " + 1
                            // }
                            setNewTypeOfSpaceData({ ...newTypeOfSpaceData, quantity: tos.details.filter((de) => de.floor_id == floorId).length });
                            if (tos.details.length > 0) {
                                const lastItem = tos.details.slice(-1)[0];
                                // setLastItemId(lastItem.id);
                                setLastItem(lastItem.id);
                                // setNextItemTitle(isNaN(Number(lastItem)) ? 0 : Number(lastItem));
                                let items = []
                                tos.details.forEach((item) => {
                                    if (item.floor_id == getSelectedFloorId) {
                                items.push(item);
                            }
                        });
                        setTypeOfSpaceItems(items.slice())
                    } else {
                        setTypeOfSpaceItems([]);
                    }
                }
            })
        }
    }, [JSON.stringify(newTypeOfSpaceData), JSON.stringify(spaceFloorPlan)])
    useEffect(() => {
        if (newTypeOfSpaceData.title == "") {
            setTypeOfSpaceItems([]);
        }
    }, [newTypeOfSpaceData])
    const removeFloorItem = (slug, records, key) => {
        customerServices.deleteFloorItems(project_id, slug, [records], key).then((res) => {
            if (res.status == 1) {
                dispatch(updateFloorDetails(res.data));
                dispatch(showMessage('sucess', _l('l_success'), _l(res.message)));
            } else {
                showError(res.message);
            }
        })
    }

    const addNewTypeOfSpace = (title, quantity) => {
        customerServices.addFloorItem(
            project_id,
            quantity,
            title,
            "type_of_space",
            floorId,
            0
        ).then((response) => {
            if (response.status == 1) {
                if (response.data) {
                    dispatch(updateFloorDetails(response.data));
                }
            }
        });
    };
    useEffect(() => {
        if (selectedRoom) {
            setNewTypeOfSpaceData({
                ...newTypeOfSpaceData,
                title: selectedRoom.roomName,
            });
        }
    }, [selectedRoom])
    const handleDelete = (index) => {
        let tempArr = cordinatesData
        tempArr = tempArr.filter((d, key) => key != index);
        handleChangeCordinate(tempArr.slice());
        dispatch(toastCloseAction());
    }
    return (
        <>
            <div className="overflow-auto p-15">
                <div className="row">
                    {
                        from == "create" ?
                            <>
                                <TypeOfSpaceAccordians typeOfSpaceItemData={typeOfSpaceItems} floorId={floorId} roomTitle={newTypeOfSpaceData.title} removeFloorItem={removeFloorItem} addFloor={addFloor} cordinatesData={cordinatesData} handleChangeCordinate={handleChangeCordinate} selectedRoom={selectedRoom} setSelectedRoom={setSelectedRoom} />
                            </>
                            :
                            <Form.Group className="col-xl-12 c-input-box py-3 position-relative pb-10px position-sticky top-0">
                                <Form.Control
                                    placeholder={`${_l("l_type_of_space_items")}`}
                                    type="text"
                                    className="col-10"
                                    onChange={(e) => setRoomTitle(e.target.value)}
                                    value={roomTitle}
                                />
                            </Form.Group>
                    }
                    {/* <div className="">{_l("l_title")}: {typeOfSpaceData ? typeOfSpaceData.title : ""}</div> */}
                    {from != "create" && cordinatesData && cordinatesData.length > 0 ?
                        <>
                            <div className="d-flex flex-column p-10 py-0">
                                <div className="pb-10px position-sticky top-0">{_l("l_selected_points")}:</div>
                                <div className="max-h40vh overflow-hiiden-auto">
                                    {cordinatesData.map((cc, index) => {
                                        return <div className="align-items-center bg-white-03 d-flex justify-content-between mb-10 p-10 radius_3">
                                            <div className="d-flex align-items-center">
                                                <div className="c-font f-12 fw-light me-2">{_l("l_point")}-{index + 1} : </div>
                                                <div className="badge badge-white rounded-pill c-font f-12 fw-light me-1">x: {cc.web_x.toFixed(8)}</div>
                                                <div className="badge badge-white rounded-pill c-font f-12 fw-light me-1">y: {cc.web_y.toFixed(8)}</div>
                                                <div className="badge badge-white rounded-pill c-font f-12 fw-light me-1">z: {cc.web_z.toFixed(8)}</div>
                                            </div>
                                            <a href="#/" className="d-flex z-index-2 with_separator_10 pe-0"
                                                onClick={() => {
                                                    handleDelete(index);
                                                }}
                                                data-tip={_l("l_delete")}
                                                data-effect="solid"
                                                data-delay-show='1000'
                                                data-class="tooltip-main"
                                            >
                                                <Trash size={18} weight="light" className="c-icons m-auto" />
                                            </a>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </>
                        : <></>}
                </div>
            </div>
        </>
    )
}

export default SpaceRoom;