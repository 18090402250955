import React from 'react';
// import CSS from '../css/icon_main.css';

function InvoicesplaceholderIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

			<svg width="64" height="79" viewBox="0 0 64 79" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<path d="M17.2495 78.0002C15.0243 76.0156 12.8293 74.0461 10.6342 72.0916C8.69174 70.3686 7.78664 70.3806 5.88024 72.1427C4.34972 73.5559 2.82821 74.9782 1 76.6771V74.8459C1 55.802 1 36.7661 1 17.7382C1 14.1299 2.50347 12.6265 6.11178 12.6265H46.0589C49.8116 12.6265 51.2097 14.0397 51.2097 17.8074V76.623C49.3875 74.9361 47.83 73.4958 46.2754 72.0525C44.5284 70.4317 43.506 70.4137 41.7409 71.9863C39.5368 73.9529 37.3388 75.9284 35.0144 78.0002C32.9337 76.1148 30.8649 74.2475 28.8022 72.3712C26.556 70.3325 25.7261 70.3265 23.4618 72.3712C21.4292 74.2084 19.3904 76.0577 17.2495 78.0002Z" strokeMiterlimit="10" />
				<path d="M26.1142 52.1913C22.3134 52.1913 18.5157 52.1763 14.7149 52.1913C13.1543 52.1913 12.2072 53.2708 12.9349 54.236C13.3017 54.7231 14.1707 55.072 14.8172 55.078C22.3646 55.1351 29.9119 55.111 37.4593 55.1171C38.4907 55.1171 39.456 54.9697 39.477 53.6797C39.4981 52.3898 38.5509 52.1763 37.5105 52.1763L26.1142 52.1913Z" strokeMiterlimit="10" />
				<path d="M26.1762 42.5362C29.9319 42.5362 33.6936 42.5362 37.4493 42.5362C38.4807 42.5362 39.4579 42.4309 39.479 41.1259C39.5 39.8209 38.5258 39.6465 37.4944 39.6465H14.786C13.7576 39.6465 12.7383 39.8209 12.7383 41.0808C12.7383 42.4399 13.8058 42.5452 14.8883 42.5422C18.659 42.5181 22.4176 42.5362 26.1762 42.5362Z" strokeMiterlimit="10" />
				<path d="M20.2921 29.9701C22.2466 29.9701 24.2011 29.9701 26.1526 29.9701C27.1298 29.9701 27.9116 29.6394 27.9266 28.587C27.9447 27.4413 27.1298 27.1196 26.0864 27.1226C22.2777 27.1406 18.4689 27.1406 14.6601 27.1226C13.6468 27.1226 12.7597 27.3421 12.7386 28.5028C12.7176 29.7717 13.6738 29.9822 14.7262 29.9731C16.5905 29.9581 18.4398 29.9701 20.2921 29.9701Z" strokeMiterlimit="10" />
				<path d="M62.7958 65.1935C61.0487 63.5758 59.4882 62.1865 58.0087 60.7432C56.9653 59.7239 55.8678 59.0864 54.1538 59.8411V58.1212C54.1538 44.7915 54.0366 31.4647 54.214 18.129C54.2802 13.0473 51.0116 9.55019 45.8156 9.65243C35.3485 9.8599 24.8754 9.71559 14.4022 9.71259H12.6823C12.6823 7.90843 12.547 6.26362 12.7154 4.64288C12.9379 2.47489 14.8893 1.01353 17.343 1.01052C24.8082 0.996492 32.2744 0.996492 39.7416 1.01052C45.8036 1.01052 51.8656 1.01052 57.9276 1.01052C60.9886 1.01052 62.8439 2.81468 62.8469 5.82162C62.863 25.2103 62.863 44.6 62.8469 63.9907C62.859 64.2944 62.8288 64.5741 62.7958 65.1935Z" strokeMiterlimit="10" />
			</svg>


		</React.Fragment>
	)
}

export default InvoicesplaceholderIcon