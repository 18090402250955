import React from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import TrashIcon from "../../../assets/icons/TrashIcon";
import { _l } from "../../../hooks/utilities";
import moment from "moment";

const ScheduleSetup = ({ slots, setSlots, hideRateField }) => {
  const appendNewEmptySlot = (checked) => {
    const blankSlots = slots.filter((w) =>
      Object.values(w.slots).every((z) => z == 0)
    );

    if (checked && !blankSlots.length) {
      return true;
    }
    return false;
  };

  const handleCheckboxEvent = (slot, index, day, checked) => {
    let days = slot.slots;
    days[day] = checked ? 1 : 0;
    let updatedSlots = Object.values({
      ...slots,
      [index]: { ...slot, slots: days },
    });
    if (appendNewEmptySlot(checked)) {
      updatedSlots.push({
        start_time: "",
        end_time: "",
        rate: "",
        slots: {
          mon: 0,
          tue: 0,
          wed: 0,
          thu: 0,
          fri: 0,
          sat: 0,
          sun: 0,
        },
      });
    }
    setSlots(updatedSlots);
  };

  const updateSlotData = (key, value, index) => {
    let updatedSlots = Object.values({
      ...slots,
      [index]: { ...slots[index], [key]: value },
    });
    setSlots(updatedSlots);
  };

  const getDifferenceInHours = (start_time, end_time, days) => {
    const difference = Math.abs(
      moment.duration(moment(end_time).diff(moment(start_time))).asHours()
    );
    const multiplier = Object.values(days).filter((w) => w == 1).length;

    if (difference > 0) {
      return (difference * multiplier).toFixed(2);
    } else {
      return 0;
    }
  };

  const getMinTime = (timeStamp) => {
    let formattedTimeStamp = moment(timeStamp);
    formattedTimeStamp = formattedTimeStamp.add(15, "minutes");
    return new Date(formattedTimeStamp);
  };

  const getMaxTime = () => {
    let formattedTimeStamp = moment();
    formattedTimeStamp.set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 59,
    });
    return new Date(formattedTimeStamp);
  };

  const deleteSlot = (position) => {
    setSlots(slots.filter((w, index) => position !== index));
  };

  return (
    <div className="dataTable comman-table smaller-table">
      <div className="dataTable comman-table smaller-table">
        <table className="dataTable comman-table smaller-table">
          <thead className="fw-normal c-font f-13 position-sticky top-0 z-index-2">
            <tr className="bg-white-03 light-theme-grey-light-bg">
              <th className="align-top ps-20pximp">{_l("l_set_hours")}</th>
              {
                hideRateField
                ?
                <React.Fragment></React.Fragment>
                :
                <th className="text-center align-top" style={{ width: "110px" }}>
                  {_l("l_rate_by_hour")}{" "}
                </th>
              }
              <th className="text-center align-top" style={{ width: "30px" }}>
                {_l("l_week_single_letter_monday")}
              </th>
              <th className="text-center align-top" style={{ width: "30px" }}>
                {_l("l_week_single_letter_tuesday")}
              </th>
              <th className="text-center align-top" style={{ width: "30px" }}>
                {_l("l_week_single_letter_wednesday")}
              </th>
              <th className="text-center align-top" style={{ width: "30px" }}>
                {_l("l_week_single_letter_thrusday")}
              </th>
              <th className="text-center align-top" style={{ width: "30px" }}>
                {_l("l_week_single_letter_friday")}
              </th>
              <th className="text-center align-top" style={{ width: "30px" }}>
                {_l("l_week_single_letter_saturday")}
              </th>
              <th className="text-center align-top" style={{ width: "30px" }}>
                {_l("l_week_single_letter_sunday")}
              </th>
              <th className="text-center align-top" style={{ width: "150px" }}>
                {_l("l_potential_hours")}
              </th>
              <th
                className="text-center align-top"
                style={{ width: "80px" }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {slots.map((slot, key) => {
              return (
                <tr key={key}>
                  <td>
                    <div className="d-flex custom-datepicker">
                      <div
                        className="bg-white-05 me-3 light-theme-white-bg radius_3 light-theme-border"
                        style={{ width: "80px" }}
                      >
                        <DatePicker
                          showTimeSelect
                          showTimeSelectOnly
                          className="p-2"
                          placeholderText="00:00"
                          selected={slot.start_time}
                          onChange={(date) => {
                            updateSlotData("start_time", date, key);
                          }}
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          excludeTimes={[
                            new Date(
                              moment().set({
                                hour: 23,
                                minute: 45,
                                second: 0,
                                millisecond: 0,
                              })
                            ),
                          ]}
                          calendarStartDay={1}
                        >
                          <div className="datepicker-label">
                            {_l("l_select_time")}
                          </div>
                        </DatePicker>
                      </div>{" "}
                      <div
                        className="bg-white-05 me-3 light-theme-white-bg radius_3 light-theme-border"
                        style={{ width: "80px" }}
                      >
                        <DatePicker
                          showTimeSelect
                          showTimeSelectOnly
                          className="p-2"
                          placeholderText="00:00"
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          selected={slot.end_time}
                          readOnly={!slot.start_time}
                          minTime={getMinTime(slot.start_time)}
                          maxTime={getMaxTime()}
                          onChange={(date) => {
                            updateSlotData("end_time", date, key);
                          }}
                          calendarStartDay={1}
                        >
                          <div className="datepicker-label">
                            {_l("l_select_time")}
                          </div>
                        </DatePicker>
                      </div>
                    </div>
                  </td>
                  {
                    hideRateField
                    ?
                    <React.Fragment></React.Fragment>
                    :
                    <td>
                      <div
                        className="align-items-center bg-white-05 d-flex form-control h32wauto justify-content-center light-theme-white-bg p-0"
                        style={{ width: "80px" }}
                      >
                        <input
                          type="text"
                          className="bg-transparent border-0 p-2"
                          placeholder="00"
                          value={slot.rate}
                          onChange={(e) => {
                            updateSlotData("rate", e.target.value, key);
                          }}
                        />
                      </div>
                    </td>
                  }
                  {Object.keys(slot.slots).map((day, key2) => {
                    return (
                      <td key={key2}>
                        <Form.Group className="c-input-box text-center">
                          <Form.Check
                            type="checkbox"
                            checked={slot.slots[day] > 0}
                            onChange={(e) => {
                              handleCheckboxEvent(
                                slot,
                                key,
                                day,
                                e.target.checked
                              );
                            }}
                          />
                        </Form.Group>
                      </td>
                    );
                  })}
                  <td className="text-center">
                    <span className="align-items-center bg-white-05 d-flex form-control h32wauto justify-content-center light-theme-white-bg">
                      {getDifferenceInHours(
                        slot.start_time,
                        slot.end_time,
                        slot.slots
                      )}
                      H
                    </span>
                  </td>
                  <td>
                    {slots.length > 1 ? (
                      <a href="#/"
                        className="h32w32 bg-white-05 d-flex"
                        onClick={() => deleteSlot(key)}
                      >
                        <TrashIcon weight="light" height="14" width="14" />
                      </a>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScheduleSetup;
