import React from 'react';
// import CSS from '../css/icon_main.css';

function ImageIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>



		<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
		<path d="M2.39062 10.5L5.53438 7.35625C5.5809 7.30901 5.63636 7.27149 5.69752 7.24588C5.75868 7.22027 5.82432 7.20709 5.89062 7.20709C5.95693 7.20709 6.02257 7.22027 6.08373 7.24588C6.14489 7.27149 6.20035 7.30901 6.24687 7.35625L9.03438 10.1438C9.0809 10.191 9.13636 10.2285 9.19752 10.2541C9.25868 10.2797 9.32432 10.2929 9.39062 10.2929C9.45693 10.2929 9.52257 10.2797 9.58373 10.2541C9.64489 10.2285 9.70035 10.191 9.74687 10.1438L11.0344 8.85625C11.0809 8.80901 11.1364 8.77149 11.1975 8.74588C11.2587 8.72027 11.3243 8.70709 11.3906 8.70709C11.4569 8.70709 11.5226 8.72027 11.5837 8.74588C11.6449 8.77149 11.7004 8.80901 11.7469 8.85625L14.3906 11.5M2.89062 3H13.8906C14.1668 3 14.3906 3.22386 14.3906 3.5V12.5C14.3906 12.7761 14.1668 13 13.8906 13H2.89062C2.61448 13 2.39062 12.7761 2.39062 12.5V3.5C2.39062 3.22386 2.61448 3 2.89062 3Z"/>
		<path d="M10.1406 7C10.5548 7 10.8906 6.66421 10.8906 6.25C10.8906 5.83579 10.5548 5.5 10.1406 5.5C9.72641 5.5 9.39062 5.83579 9.39062 6.25C9.39062 6.66421 9.72641 7 10.1406 7Z" fill="#00FF6A"/>
		</svg>

	


		</React.Fragment>
	)
}

export default ImageIcon