import React from 'react';
// import CSS from '../css/icon_main.css';

function OfficeNotFound(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg viewBox="0 0 94 92" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons w-100 h-100 ${CommanHW}`}> 
				<path d="M6.41925 53.4977C0.0177402 39.3802 2.93247 22.7277 14.4296 11.9476C28.8543 -1.58095 50.9609 -2.13574 65.4966 10.3515C80.3055 23.0606 83.7878 46.4988 68.8681 63.3518C69.2948 63.7786 69.7003 64.2608 70.1484 64.6961C77.0506 71.4931 83.9543 78.2886 90.8594 85.0828C91.2923 85.4736 91.6973 85.8943 92.0715 86.3417C92.5307 86.8463 92.7787 87.5079 92.7644 88.1901C92.7501 88.8722 92.4745 89.5228 91.9946 90.0077C91.534 90.5478 90.8852 90.8924 90.1799 90.9717C89.4745 91.0509 88.7653 90.8588 88.1964 90.4344C87.7388 90.0767 87.3106 89.6828 86.9161 89.2566L66.0813 68.7717C65.6246 68.3236 65.1595 67.8926 64.6217 67.3805C58.1202 72.4346 50.1865 75.3027 41.9561 75.5744" strokeMiterlimit="10"/>
				<path d="M13.3691 58.3799L31.383 76.2828" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M31.383 58.3799L13.3691 76.2828" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M22.3771 46.277C10.641 46.2386 1.06003 55.6658 1.00028 67.2782C0.940534 78.8905 10.3892 88.241 22.2747 88.3818C33.9553 88.5184 43.7582 78.9075 43.7454 67.3208C43.7326 55.7341 34.1473 46.3154 22.3771 46.277Z" strokeMiterlimit="10"/>
			</svg>

		</React.Fragment>
	)
}

export default OfficeNotFound