import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CustomerServices from "../../services/customer-services";
import { useDispatch } from "react-redux";
import Toasts from "../Toasts/Toasts";
import { getTopicTasks } from "../../actions/customer";
import { _l } from "../../hooks/utilities";
import CustomOutlookIcon from "../../assets/icons/CustomOutlookIcon";
import CustomGoogleIcon from "../../assets/icons/CustomGoogleIcon";
const MailSetting = ({ show, handleClose, setSynchronizedMail,pageName }) => {
  const dispatch = useDispatch();
  const [toastClass, setToastClass] = useState("unsucess");
  const [header, setHeader] = useState("");
  const [message, setMessage] = useState("");
  const [validationFalse, setValidationFalse] = useState(false);
  const [gmailStatus, setGmailStatus] = useState(false);
  const [outlookStatus, setOutlookStatus] = useState(false);

  const projectId = localStorage.getItem("selectedOffice");

  const oneOutlookAuthUrl =
    // "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=c4786c02-05b2-46c2-8dcc-dd14f11b0a4f&scope=https://graph.microsoft.com/User.Read https://graph.microsoft.com/Mail.Send https://graph.microsoft.com/Calendars.ReadWrite https://graph.microsoft.com/Calendars.ReadWrite.Shared https://graph.microsoft.com/Mail.ReadWrite https://graph.microsoft.com/Contacts.ReadWrite https://graph.microsoft.com/Chat.ReadWrite https://graph.microsoft.com/ChatMessage.Send https://graph.microsoft.com/Contacts.ReadWrite.Shared OnlineMeetings.ReadWrite files.readwrite.all offline_access openid&response_type=code&redirect_uri=http://localhost:3000&state=outlook_mail";
       "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=c4786c02-05b2-46c2-8dcc-dd14f11b0a4f&scope=https://graph.microsoft.com/User.Read https://graph.microsoft.com/Mail.Send https://graph.microsoft.com/Calendars.ReadWrite https://graph.microsoft.com/Calendars.ReadWrite.Shared https://graph.microsoft.com/Mail.ReadWrite https://graph.microsoft.com/Contacts.ReadWrite https://graph.microsoft.com/Chat.ReadWrite https://graph.microsoft.com/ChatMessage.Send https://graph.microsoft.com/Contacts.ReadWrite.Shared OnlineMeetings.ReadWrite files.readwrite.all offline_access openid&response_type=code&redirect_uri="+window.location.origin+"&state=outlook_mail"

  function outlookMailAuthModal() {
    var left = (window.screen.width - 600) / 2;
    var top = (window.screen.height - 600) / 4;
    window.open(
      oneOutlookAuthUrl,
      "center window",
      "resizable = yes, width=600, height=600, top=" + top + ", left=" + left
    );
  }
  const updateGmailStatus = (status) => {
    setGmailStatus(status);
  };

  const _fetchMails = () => {
    setSynchronizedMail('gmail');
    dispatch(getTopicTasks(projectId, pageName));
    window.dispatchEvent(new Event("gmail_exchange"));
  };
  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={() => {
            handleClose();
            _fetchMails();
          }}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>{_l("l_mail_syncronization")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-3">
            
            <a href="#/" className={`platform-card task-list-card list-view p-4`}>
                <div className="d-flex justify-content-between">
                  <div className="h30w30">
                    <CustomGoogleIcon />
                  </div>
                  <div className="w100minus30 ps-4">
                    <div className="title-fonts fw-semibold">{_l("l_gmail")}</div>
                    <div className="c-font f-12 color-white-60">{_l("l_gmail_subtext")}</div>
                    <div className="card-btn-wrapper mt-4">
                      <div className="d-flex align-items-center">
                      <Button size="sm" variant="primary" className="position-relative">
                      <input
                        className="absolute-input"
                        type="radio"
                        name="mail_type"
                        id="gmail"
                        value="google_mail"
                        checked={gmailStatus == true}
                        onChange={() => {
                          updateGmailStatus(!gmailStatus);
                        }}
                      />{_l("l_gmail")}
                      </Button>
                      </div>
                    </div>  
                  </div>
                </div>
            </a>
            <a href="#/" className={`platform-card task-list-card list-view p-4`}>
              <div className="d-flex justify-content-between">
                <div className="h30w30">
                  <CustomOutlookIcon />
                </div>
                <div className="w100minus30 ps-4">
                  <div className="title-fonts fw-semibold">{_l("l_outlook")}</div>
                  <div className="c-font f-12 color-white-60">{_l("l_gmail_subtext")}</div>
                  <div className="card-btn-wrapper mt-4">
                    <div className="d-flex align-items-center">
                      <Button size="sm" variant="primary" className="position-relative">
                      <input 
                      className="absolute-input"
                      type="radio"
                      name="mail_type"
                      id="outlook"
                      value="outlook_mail"
                      checked={outlookStatus === true}
                      onChange={() => {
                        setOutlookStatus(!outlookStatus);
                      }}/>{_l("l_outlook")}
                      </Button>
                    </div>
                  </div>  
                </div>
              </div>
            </a>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end pt-0">
            <Button
              variant="secondary"
              size="sm"
              onClick={() => {
                handleClose();
                _fetchMails();
              }}
            >
              {_l("l_close")}
            </Button>
            <Button
              size="sm"
              onClick={() => {
                if (outlookStatus == true) {
                  outlookMailAuthModal();
                } else {
                  CustomerServices.getMailSettings().then((res) => {
                    var left = (window.screen.width - 600) / 2;
                    var top = (window.screen.height - 600) / 4;
                    window.open(
                      res.data,
                      "center window",
                      "resizable = yes, width=600, height=600, top=" +
                        top +
                        ", left=" +
                        left
                    );
                  });
                }
              }}
            >
              {_l("l_submit")}
            </Button>

            
          </Modal.Footer>
        </Modal>
      </div>
      {validationFalse ? (
        <Toasts
          message={message}
          header={header}
          toastClass={toastClass}
          delay={10000}
          handler={() => {
            setValidationFalse(false);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default MailSetting;
