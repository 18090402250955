import React, { useEffect, useState } from "react";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CustomerServices from "../../services/customer-services";
import Toasts from "../Toasts/Toasts";
import { _l } from "../../hooks/utilities";

const CreateVirtualSpace = ({ show, handleClose, dataSource, setDataSource, team, teamsData, setTeamsData, handleAddedTeam }) => {

  const [employeeData, setEmployeeData] = useState([]);
  const [manager, setManager] = useState(team ? { label: team.manager_name, value: parseFloat(team.manager_id) } : []);
  const [teamMembers, setTeamMembers] = useState(team ? team.members.map(x => {
    return {
      value: x.member_id,
      label: `${x.firstname} ${x.lastname}`
    }
  }) : []);
  const [teamName, setTeamName] = useState(team && team.teamTitle ? team.teamTitle : "");
  const [nameValidationShow, setNameValidationShow] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastClass, setToastClass] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toastHeader, setToastHeader] = useState("");
  const userType = localStorage.getItem('user_type');

  useEffect(() => {
    CustomerServices.getCompanyEmployee().then((res) => {
      if (res.status && res.data) {
        const formattedResponse = res.data.map((employee) => {
          return {
            value: employee.id,
            label: employee.full_name,
            role: employee.role_detail.role_id,
            profile_image: employee.profile_image,
          }
        });
        setEmployeeData(formattedResponse);
      }
    })
  }, []);

  const prepareToast = (className, messageBody, header) => {
    setShowToast(true);
    setToastHeader(header);
    setToastClass(className);
    setToastMessage(messageBody);
  }

  const handleCreateTeam = () => {
    if (!teamName || !teamName.trim().length) {
      prepareToast("unsucess", _l('l_please_enter_team_name'), _l('l_error'));
      return false;
    }
    else if (!manager.value || parseFloat(manager.value) === NaN || parseFloat(manager.value) < 0) {
      prepareToast("unsucess", _l('l_please_select_team_manager'), _l('l_error'));
      return false;
    }
    else if (!teamMembers.length) {
      prepareToast("unsucess", _l('l_please_add_members_to_team'), _l('l_error'));
      return false;
    }
    else {
      const members = teamMembers.map(x => x.value);
      const managerId = parseFloat(manager.value);
      CustomerServices.createTeam(members, managerId, teamName, team && team.id ? team.id : 0).then((res) => {
        if (res.status) {
          if (team && team.id) {
            let updatedTeam = {
              id: parseFloat(team.id),
              index: team.index,
              teamTitle: teamName,
              manager_name: manager.label,
              manager_id: manager.value,
              members: teamMembers.map((x) => {
                return {
                  firstname: x.label,
                  lastname: "",
                  profile_image: employeeData.find((profile) => parseFloat(profile.value) === parseFloat(x.value)).profile_image,
                  member_id: x.value
                }
              }),
            }
            let pos = teamsData.findIndex((x => x.id == parseFloat(team.id)));
            setTeamsData(Object.values({ ...teamsData, [pos]: updatedTeam }));
            pos = dataSource.findIndex((x => x.id == parseFloat(team.id)));
            setDataSource(Object.values({ ...dataSource, [pos]: updatedTeam }));
            prepareToast("success", _l('l_team_update'), _l('l_success'));
          }
          else {
            let newTeam = {
              id: parseFloat(res.data.team_id),
              index: dataSource.length,
              teamTitle: teamName,
              manager_name: manager.label,
              manager_id: manager.value,
              members: teamMembers.map((x) => {
                return {
                  firstname: x.label,
                  lastname: "",
                  profile_image: employeeData.find((profile) => parseFloat(profile.value) === parseFloat(x.value)).profile_image,
                  member_id: x.value
                }
              }),
            }
            setTeamsData(teamsData.concat(newTeam));
            setDataSource(dataSource.concat(newTeam));
            handleAddedTeam(newTeam);
            prepareToast("success", _l('l_team_create'), _l('l_success'));
          }
          handleClose();
        }
      })
    }
  }

  return (
    <>

      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
      >
        {
          showToast ?
            <Toasts delay={2500} handler={() => { setShowToast(false) }} header={toastHeader} message={toastMessage} toastClass={toastClass} />
            :
            <></>
        }
        <Modal.Header closeButton>
          <Modal.Title>{team ? _l('l_edit_team') : _l('l_create_team')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          <div className="form-wrapper-main">
            <div className="row">
              <Form.Group className="col-12 c-input-box pb-4 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l('l_team_name')}
                </Form.Label>
                <Form.Control
                  placeholder={`${_l('l_team_name')}`}
                  type="text"
                  value={teamName}
                  onChange={(e) => { setTeamName(e.target.value) }}
                  onFocus={() => setNameValidationShow(true)}
                  name=""
                />
                {
                  nameValidationShow ? <Form.Control.Feedback type={teamName.trim().length ? "" : "invalid"}>{teamName.trim().length ? "Looks good!" : "Please enter team name"}</Form.Control.Feedback> : <></>
                }
              </Form.Group>
              <Form.Group className="col-12 c-input-box pb-4 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l('l_manager_name')}
                </Form.Label>
                <Select
                  defaultValue={manager}
                  className="custom-select-menu"
                  classNamePrefix="react-select"
                  options={userType == "contact" ? employeeData.filter(x => parseFloat(x.role) === 1 || parseFloat(x.role) === 2) : employeeData}
                  closeMenuOnSelect={true}
                  placeholder={`${_l('l_select_manager')}`}
                  onChange={(value) => setManager(value)}
                />
              </Form.Group>
              <Form.Group className="col-12 c-input-box pb-4 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l('l_employee')}
                </Form.Label>
                <Select
                  defaultValue={teamMembers}
                  className="custom-select-menu max-h95 multi-select-with-scroll"
                  classNamePrefix="react-select"
                  options={employeeData.filter(x => x.value != manager.value)}
                  isMulti
                  placeholder={`${_l('l_select_employee')}`}
                  onChange={(value) => {
                    setTeamMembers(value);
                  }}
                />
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l('l_close')}
          </Button>
          <Button variant="primary" size="sm" onClick={handleCreateTeam}>{_l('l_save')} </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateVirtualSpace;
