import React, { useEffect, useState, useRef } from "react";
import Select, {components} from "react-select";
import Form from "react-bootstrap/Form";
import CustomerServices from "../../services/customer-services";
import jquery from "jquery";
import Geocode from "react-geocode";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useDispatch } from "react-redux";
import { showMessage } from "../../actions/messages";
import { Info, Pencil, Star } from "phosphor-react";
import { _l } from "../../hooks/utilities";
import Spinner from "react-bootstrap/Spinner";
import { Button } from "react-bootstrap";
import { DEFAULT_ADDRESS, DEFAULT_LAT, DEFAULT_LNG } from "../../constants/constants";

const Companyprofilestep = ({ setShowInfoModal, setPushNotificationPreference, setEmailNotificationPreference, isfromTopSetEditProf = false}) => {
  
  
  const [companyProfile, setCompanyProfile] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState(DEFAULT_ADDRESS);
  const [companyType, setCompanyType] = useState("");
  const [companyLable, setCompanyLable] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [locationLetAndLng, setLocationLetAndLng] = useState({
    lat: DEFAULT_LAT,
    lng: DEFAULT_LNG
  });
  const [validResetDetails, setValidResetDetails] = useState(true);
  const [getDetails, setGetDetails] = useState(false);
  const [suggestedAddress, setSuggestedAddress] = useState(DEFAULT_ADDRESS);
  const [taxNumber, setTaxNumber] = useState("");
  const [aditionalData, setAditionalData] = useState("");
  const [companyIdNumber, setCompanyIdNumber] = useState("");
  const userType = localStorage.getItem("user_type");
  const [logo , setLogo] = useState();
  const [regionList, setRegionList] = useState([]);
  const [regionvalue, setRegionValue] = useState([]);
  const [categoryListing, setCategoriesList] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([])
  const [map, setMap] = useState("");
  const [reviewAddress, setReviewAddress] = useState(
    {
      place_id: 0,
      rating : 0,
      reviewCount : 0,
      lat : 0,
      lng : 0,
      review : []
    }
  );

  const dispatch = useDispatch();

  const containerStyle = {
    width: "400px",
    height: "400px",
  };
  let star = [
    {
      value :1,
      titte : "l_worst"
    },
    {
      value :2,
      titte : "l_bad"
    },
    {
      value :3,
      titte : "l_average"
    },
    {
      value :4,
      titte : "l_good"
    },
    {
      value :5,
      titte : "l_excellent"
    },
     ]
  const companytype = [
    {
      value: "1",
      label: "Office",
    },
    {
      value: "2",
      label: "Coworking & Flex-office ",
    },
    {
      value: "3",
      label: "Building ",
    },
    {
      value: "4",
      label: " Multi-Properties",
    },
    {
      value: "5",
      label: "Hotel & Retail ",
    },
    {
      value: "6",
      label: "Property Manager & Facility Manager ",
    },
  ];

  const autoCompleteRef = useRef();
  const inputRef = useRef();

  const options = {
    //  componentRestrictions: { country: "ng" },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["address"],
  };

  useEffect(() => {
    if (companyAddress && companyAddress.trim().length && inputRef.current) {
      try {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
          inputRef.current,
          options
        ).addListener("place_changed", () => {
          setSuggestedAddress(inputRef.current.value);
          setCompanyAddress(inputRef.current.value);
        });
      } catch (e) {
        console.log(e);
      }
      getAddressForMapLocation(companyAddress);
    }
  }, [companyAddress]);

  const handleMapLoad = (mapInstance) => {
    setMap(mapInstance);
  }

  const getAddressForMapLocation = (address) => {
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        const placeId = response.results[0].place_id;
        setLocationLetAndLng({
          lat: lat,
          lng: lng,
        });
        try {      
          var request = {
            placeId: placeId,
            fields: ['rating', 'international_phone_number', 'name', 'user_ratings_total','review']
          };
          
          let service = new window.google.maps.places.PlacesService(map);
          service.getDetails(request, callback);
          
          function callback(place, status) {
            if (status == window.google.maps.places.PlacesServiceStatus.OK) {
              const rating =  place && place.rating ? place.rating : ""
              const review = place && place.reviews ? place.reviews : []
              setReviewAddress({
                place_id: placeId,
                rating : rating,
                reviewCount : review && review.length > 0 ? review.length : 0,
                lat : lat,
                lng : lng,
                review : review && review.length > 0 ? review : []
              });
            }
          }
        }
        catch(e)
        {
          console.log(e)
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    let regions = [];
    let categoryLists = [];
    CustomerServices.getRegionList().then((response) => {
      if(response.status)
      {
         regions = response.data.map((region) => {
          return {
            label: region.name_with_code,
            value: region.id,
          };
        })
        // setRegionList(regions.sort((a, b) => a.label.localeCompare(b.label)));
        setRegionList(regions);
      }
    })  
    CustomerServices.getProviderServices().then((response) => {
      if (response.status) {
         categoryLists = response.data.map((w) => {
            return { 
              label: w.name, 
              value: w.id, 
            };
          })
        setCategoriesList(categoryLists)
      }
    })
    CustomerServices.getCompanyProfile(
      companyName,
      companyAddress,
      companyType
    ).then((res) => {
      if (res.status && res.data) {
        setCompanyProfile(res.data);
        setCompanyName(res.data.company ? res.data.company : "");
        setCompanyAddress(res.data.address ? res.data.address : "");
        setSuggestedAddress(res.data.address ? res.data.address : "");
        setTaxNumber(res.data.v_t_number ? res.data.v_t_number : "");
        setLogo(res.data.logo ?res.data.logo :"");
        setPushNotificationPreference(Number(res.data.push_notification));
        setEmailNotificationPreference(Number(res.data.email_notification_preference_type));
        setCompanyIdNumber(
          res.data.company_id_number ? res.data.company_id_number : ""
        );
        setAditionalData(
          res.data.additional_data ? res.data.additional_data : ""
        );

        let CompanyIndustryTypeData = res.data.industry_type;
        setCompanyLable(
          CompanyIndustryTypeData ? res.data.types[CompanyIndustryTypeData] : ""
        );
        setRegionValue(res.data.regions ? regions.filter((item) => res.data.regions.includes(item.value)).map((w) => {
          return{
            label : w.label,
            value : w.value,
            isFixed : true,
          }
        }) : []);
        setSelectedCategories(res.data.categories ? categoryLists.filter((item) => res.data.categories.includes(item.value)).map((w) => {
          return{
            label : w.label,
            value : w.value,
            isFixed : true,
          }
        })  : []);
        let industryTypeResponse = res.data.types
          ? res.data.types[CompanyIndustryTypeData]
          : "";
        let getCompanyTypeValue =
          industryTypeResponse == "Office"
            ? "1"
            : industryTypeResponse == "Coworking & Flex-office"
            ? "2"
            : industryTypeResponse == "Building"
            ? "3"
            : industryTypeResponse == "Multi-Properties"
            ? "4"
            : industryTypeResponse == "Hotel & Retail"
            ? "5"
            : industryTypeResponse == "Property Manager & Facitlity Manager"
            ? "6"
            : "";
        setValidResetDetails(true);
        setCompanyType(getCompanyTypeValue);
        jquery("#companyName").text(
          companyName != "" ? res.data.company : companyProfile.company
        );
      }
    });
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_SECRET);
    Geocode.setLanguage("fr");
    Geocode.setRegion("fr");
  }, []);

  const validCompanyDetails = () => {
    if (companyName == "") {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_the") + " " + _l("l_company_name"))
      );
      return;
    } else if (companyIdNumber == "") {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_the") + " " + _l("l_company_id_number"))
      );
      return;
    } else if (
      !suggestedAddress.length ||
      !companyAddress.length ||
      suggestedAddress.toLowerCase() !== companyAddress.toLowerCase()
    ) {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_enter_valid_address")
        )
      );
      return;
    }
    setShowLoader(true);
    CustomerServices.getCompanyUpdate(
      companyName,
      companyAddress,
      companyType,
      taxNumber,
      aditionalData,
      companyIdNumber,
      regionvalue,
      selectedCategories,
      reviewAddress
    ).then((res) => {
      if (res.status) {
        setShowLoader(false);
        setValidResetDetails(false);
        setCompanyType(companyType);
        jquery("#companyName").text(companyName);
        localStorage.setItem("company_name", companyName);
        localStorage.setItem("company_address", companyAddress);
        localStorage.setItem("company_type", companyLable);
        dispatch(showMessage("sucess", _l("l_success"), res.message));
      }
    });
  };

  const resetDetails = () => {
    setCompanyName(
      getDetails != true
        ? companyProfile.company
        : localStorage.getItem("company_name")
    );
    setCompanyAddress(
      getDetails != true
        ? companyProfile.address
        : localStorage.getItem("company_address")
    );
    let companyTypeIndex = companyProfile.industry_type;
    setCompanyLable(companyProfile.types[companyTypeIndex]);
  };
 

  const handleUploadLogo =(files)=>{
    if (files.length === 1) {                            
      CustomerServices.updateCompanyLogo(
       files[0]                             
      )
      let thumbnails = [];
      Array.from(files).map((img) => {
        thumbnails.push({ name: img.name, url: URL.createObjectURL(img) });
      });
      setLogo(thumbnails[0].url)
    }
  }
  const MultiValueRemove = (props) => {
    if (props.data.isFixed) {
      return null;
    }
    return  <components.MultiValueRemove {...props} /> ;
  };

  return (
    <>
      <div className="tab-contain-wrapper comman_vertical_btn_h pe10per ">
        <div className={`p-0 ${isfromTopSetEditProf ? '' : 'd-none comman_top_header'} `}>
          <div className="comman-tab-wrapper pb-0">
            <div className="tab-wrapper align-items-center">
              <div className="comman_top_header  position-sticky top-0 z-index-4 p-0 bgpatternCommanImg"> 
                <div className="d-flex justify-content-between align-items-center">
                  <div className="comman-tab-wrapper pb-0 d-flex justify-content-between">
                    <div className="tab-wrapper d-inline-flex align-items-center">
                      <div className="tab-name title-fonts fw-semibold m-0 color-white-80 me-1">
                      {_l('l_company_profile')}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <a href="#/"
                      className="btn btn-secondary btn-sm me-3"
                      onClick={validResetDetails == true ? resetDetails : ""}
                    >
                      {_l('l_reset')}
                    </a>
                    <Button className={`btn btn-primary btn-sm`} disabled={showLoader} onClick={validCompanyDetails}>
                    {showLoader ? _l('l_please_wait') : _l('l_submit')}
                  </Button>
                  </div>
                </div>
              </div>
              <a href="#/" className="info-icon d-inline-block d-none" 
              onClick={() => {
                setShowInfoModal(true);
              }}
              >
                <Info size={18}  weight="light" className="c-icons m-auto" />
              </a>
            </div>
          </div>
        </div>
        <div className="comman-content-scroll-wrapper p-15 bg-white">
          <div className="comman-content-scroll">
            <div className="row">
              <div className={`col-xl-12 mb-3 ${isfromTopSetEditProf ? 'd-none' : ''}`}>
                <Form.Group className=" c-input-box  position-relative">
                  <Form.Label className="input-label no-asterisk ">
                  {_l('l_company_logo')}
                </Form.Label>
                <div className="h70w70 comman-round-box rounded-circle bg-white-05">
                  <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" 
                   style={{backgroundImage: `url('${logo !="" ? logo: 'https://myrpp.myr.ai/orb/assets/images/default-comapny-placeholder.svg'}')`}}>
                  </div>
                  <a href="#/" className="remove-img large" >
                      <input
                          type="file"
                          accept="image/*"
                          className="absolute-input"
                          onChange={(event) => { handleUploadLogo(event.target.files) }} 
                      />
                      <Pencil size={14} weight="light" className="c-icons m-auto" />
                  </a>
                </div>
                </Form.Group>
              </div>
              <div className="col-xl-6 mb-3">
                <Form.Group className=" c-input-box  position-relative">
                  <Form.Label className="input-label no-asterisk ">
                  {_l('l_company_name')}
                  </Form.Label>
                  <Form.Control
                    value={companyName}
                    type="text"
                    placeholder={`${_l("l_company_name_placeholder")}`}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                      setValidResetDetails(true);
                      setGetDetails(true);
                    }}
                  />
                </Form.Group>
              </div>
              {/* {userType == "contact" ? ( */}
                <div className="col-xl-6 mb-3 z-index-3">
                  <Form.Group className="c-input-box  position-relative ">
                    <Form.Label className="input-label no-asterisk ">
                    {_l('l_company_type')}
                    </Form.Label>
                    <div className="d-flex flex-grow-1 align-items-center">
                      <Select
                        className="custom-select-menu w-100"
                        classNamePrefix="react-select"
                        placeholder={`${_l("l_select_company_type")}`}
                        options={companytype}
                        closeMenuOnSelect={true}
                        value={
                          companyType != "" || companyLable != ""
                            ? { value: companyType, label: companyLable }
                            : ""
                        }
                        onChange={(e) => {
                          setCompanyType(e.value);
                          setCompanyLable(e.label);
                          setValidResetDetails(true);
                        }}
                      />
                    </div>
                  </Form.Group>
                </div>
              {/* ) : (
                <></>
              )} */}
              {/* {userType == "contact" ? ( */}
                <div className="col-xl-6 mb-3">
                  <Form.Group className=" c-input-box  position-relative">
                    <Form.Label className="input-label no-asterisk ">
                    {_l('l_company_id_number')} 
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={_l('l_company_id_number')}
                      value={companyIdNumber}
                      onChange={(e) => {
                        setCompanyIdNumber(e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
              {/* ) : (
                <></>
              )} */}
              <div className="col-xl-6 mb-3">
                <Form.Group className=" c-input-box  position-relative">
                  <Form.Label className="input-label no-asterisk ">
                    {userType == "contact"
                      ? _l('l_vat_number')
                      : _l('l_tax_id_number')}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder= {userType == "contact"? _l('l_vat_number'): _l('l_tax_id_number')}
                    value={taxNumber}
                    onChange={(e) => {
                      setTaxNumber(e.target.value);
                    }}
                  />
                </Form.Group>
              </div>
              {userType == "operator" ? <>
              <div className="col-xl-6 mb-3">
                <Form.Group className="c-input-box  position-relative">
                  <Form.Label className="input-label no-asterisk">
                    {_l("l_select_region")}
                  </Form.Label>
                  <Select
                    placeholder={_l("l_select_region")}
                    className="custom-select-menu react-select-wauto z-index-12"
                    classNamePrefix="react-select"
                    isMulti
                    value={regionvalue}
                    isClearable={false}
                    options={regionList}
                    components={{MultiValueRemove}}
                    onChange={(value) => { setRegionValue(value)}}
                  />
                </Form.Group>
              </div>
              <div className="col-xl-6 mb-3">
                <Form.Group className="c-input-box  position-relative">
                  <Form.Label className="input-label no-asterisk ">
                    {" "}
                    {_l("l_select_category")}
                  </Form.Label>
                  <Select
                    placeholder={_l("l_select_category")}
                    className="custom-select-menu"
                    classNamePrefix="react-select"
                    styl es={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    // menuPortalTarget={document.body}
                    options={categoryListing}
                    isClearable={false}
                    value={selectedCategories}
                    onChange={(option) => { setSelectedCategories(option) }}
                    components={{MultiValueRemove}}
                    isMulti
                  />
                </Form.Group>
              </div>
              </> :<></>}
              <div className="col-xl-12 mb-3">
                <Form.Group className=" c-input-box position-relative ">  
                  <Form.Label className="input-label no-asterisk ">
                  {_l('l_company_address')} 
                  </Form.Label>
                  <Form.Control
                    ref={inputRef}
                    // as="textarea"
                    value={companyAddress}
                    onChange={(e) => {
                      setCompanyAddress(e.target.value);
                      setValidResetDetails(true);
                      setGetDetails(true);
                    }}
                    rows={3}
                    placeholder={`${_l("l_address")}`}
                  ></Form.Control>
                  <div className="d-flex align-items-center ratingabsstar">
                  <div className="c-font color-white-60 f-12 fw-semibold pe-10px">
                    {reviewAddress.rating || 0}
                  </div>
                    {star.map((item, index) => {
                      return (
                        <>
                          <div
                            className={`star-rating-items text-center d-flex flex-column active p-0 ${
                              index <
                              Math.round(
                                reviewAddress.rating
                              )
                                ? "active"
                                : ""
                            }`}
                          >
                            {index <
                            Math.round(
                              reviewAddress.rating
                            ) ? (
                              <Star
                                size={18}
                                weight="fill"
                                className="c-icons"
                                color="#00ff6a"
                              />
                            ) : (
                              <Star
                                size={18}
                                className="c-icons"
                              />
                            )}
                          </div>
                        </>
                      );
                    })}
                    <div className="c-font color-white-60 f-12 ps-10px">
                      {reviewAddress.reviewCount || 0} avis Google
                    </div>  
                  </div>
                </Form.Group>
              </div>
              <div className="col-xl-12 mb-3">
                <Form.Group className=" c-input-box ">
                  <Form.Label className="input-label no-asterisk ">
                  {_l('l_additional_data')}  
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder={_l('l_additional_data_placeholder')}
                    value={aditionalData}
                    onChange={(e) => {
                      setAditionalData(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </div>  
              <div className="col-xl-12">
                <Form.Group className=" map-box-parent">
                  {Object.keys(locationLetAndLng).length ? (
                    <GoogleMap
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_SECRET}
                      // id="marker-example"
                      options={{
                        streetViewControl: false,
                        scrollwheel: false,
                      }}
                      onLoad={handleMapLoad}
                      mapContainerStyle={containerStyle}
                      center={locationLetAndLng}
                      zoom={8}
                    >
                      <Marker position={locationLetAndLng} />
                    </GoogleMap>
                  ) : (
                    <></>
                  )}
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="comman-content-bottom-btn d-flex justify-content-end align-items-center d-none">
            <a href="#/"
              className="btn btn-secondary me-3"
              onClick={validResetDetails == true ? resetDetails : ""}
            >
              {_l('l_reset')}
            </a>
            <Button className={`btn btn-primary`} disabled={showLoader} onClick={validCompanyDetails}>
            {showLoader ? _l('l_please_wait') : _l('l_submit')}
            {showLoader ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                className={`ms-2 `}
                aria-hidden="true"
              />
            ) : (
              <></>
            )}
          </Button>
          </div>
        </div>
      </div>
       
    </>
  );
};

export default Companyprofilestep;
