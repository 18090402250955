import React from 'react';
// import CSS from '../css/icon_main.css';

function GoogleIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.8095 8.14946C15.8095 7.49393 15.7563 7.0156 15.6413 6.51953H8.15593V9.4782H12.5497C12.4611 10.2135 11.9827 11.3207 10.9197 12.0648L10.9048 12.1639L13.2715 13.9973L13.4355 14.0137C14.9414 12.6229 15.8095 10.5766 15.8095 8.14946Z" fill="#4285F4"/>
<path d="M8.15566 15.9446C10.3082 15.9446 12.1153 15.2359 13.4353 14.0134L10.9195 12.0646C10.2462 12.5341 9.34266 12.8618 8.15566 12.8618C6.0474 12.8618 4.258 11.4711 3.62013 9.54883L3.52666 9.55676L1.06571 11.4614L1.03352 11.5508C2.34455 14.1552 5.03746 15.9446 8.15566 15.9446Z" fill="#34A853"/>
<path d="M3.62027 9.54889C3.452 9.05282 3.35453 8.52129 3.35453 7.97209C3.35453 7.42282 3.452 6.89136 3.6114 6.39529L3.60693 6.28963L1.11518 4.35449L1.03365 4.39329C0.493312 5.47403 0.183266 6.68762 0.183266 7.97209C0.183266 9.25656 0.493312 10.4701 1.03365 11.5508L3.62027 9.54889Z" fill="#FBBC05"/>
<path d="M8.15566 3.08269C9.65273 3.08269 10.6625 3.72933 11.2383 4.26973L13.4884 2.07285C12.1065 0.7884 10.3082 0 8.15566 0C5.03746 0 2.34455 1.78937 1.03352 4.39373L3.61126 6.39573C4.258 4.47347 6.0474 3.08269 8.15566 3.08269Z" fill="#EB4335"/>
</svg>


		</React.Fragment>
	)
}

export default GoogleIcon