import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { _l } from "../../../../hooks/utilities";
import Document from "../../tabs/Document";
import Paytm from "../../tabs/Paytm";
import React, { useEffect, useRef, useState } from "react";
import Activities from "../../tabs/Activities";
import DocumentsDetail from "../../DocumentsDetail";

const Documents = ({ projectId, clientId, location, zoomLevel, data }) => {
  const [selectedTab, setSelectedTab] = useState("document");
  const [showSearch, setshowSearch] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  return (
    <>
      <div className="tab-wrapper_main pb-3 res-overflow-auto">
        <div className="d-flex align-items-center justify-content-between border-bottom">
          <div className="comman-tab-wrapper pb-0">
            <div class="d-flex align-items-center">
               <div
              className={`tab-wrapper ${
                selectedTab === "document" ? "active" : ""
              }`}
            >
              <a href="#/"
                className="tab-name"
                onClick={() => {
                  setSelectedTab("document");
                }}
              >
                {_l("l_document")}
              </a>
            </div>

            <div
              className={`tab-wrapper ${
                selectedTab === "basic_details" ? "active" : ""
              }`}
            >
              <a href="#/"
                className="tab-name"
                onClick={() => {
                  setSelectedTab("basic_details");
                }}
              >
                {_l("l_basic_details")}
              </a>
            </div>

            <div
              className={`tab-wrapper ${
                selectedTab === "activities" ? "active" : ""
              }`}
            >
              <a href="#/"
                className="tab-name"
                onClick={() => {
                  setSelectedTab("activities");
                }}
              >
                {_l("l_activities")}
              </a>
            </div>
            </div>
           
          </div>

          <div className="task-header-right comman-tab-wrapper pb-0">
            <div
              className={`d-flex align-items-center justify-content-end tab-wrapper ${
                selectedTab === "paytm" ? "active" : ""
              }`}
            >
              <a href="#/"
                className="tab-name d-none"
                onClick={() => {
                  setSelectedTab("paytm");
                }}
              >
                <button type="button" className="btn btn-primary btn-sm">
                  {_l("l_pay_now")}
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-hiiden-auto comman_vertical_btn_h pb-2">
        {selectedTab === "document" ? <Document data={data} /> : (
          selectedTab === "activities" ? <Activities data={data}/> : (
          selectedTab === "basic_details" ? <DocumentsDetail data={data}/> : <Paytm />
          ) )}
      </div>
    </>
  );
};
export default Documents;
