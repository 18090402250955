import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import {
  _l,
  generateDefaultSignature,
  openNewChatChannel,
  roundOffPowerValue,
  showError,
} from "../../hooks/utilities";
import {
  ArrowBendDoubleUpLeft,
  ChatCircle,
  Eraser,
  LockOpen,
  Phone,
  Headphones,
  ShieldCheck,
  MapPin,
  Star,
  ChatCircleDots,
  Key,
} from "phosphor-react";
import "react-datepicker/dist/react-datepicker.css";
import SignatureCanvas from "react-signature-canvas";
import PaymentCardInput from "./PaymentCardInput";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import BenchmarkServices from "../../services/benchmark-services";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerSpaces, getDefaultTopics } from "../../actions/customer";
import accomptecalender from "../../assets/images/accomptecalender.svg";
import Paypal from "../../assets/images/paypal.svg";
import Visa from "../../assets/images/visa.svg";
import MasterCard from "../../assets/images/mastercard.svg";
import AmericanExpress from "../../assets/images/americanexpress.svg";
import { showMessage } from "../../actions/messages";
import { useNavigate, useSearchParams } from "react-router-dom";
import documentsServices from "../../services/documents-services";
import customerServices from "../../services/customer-services";
import chatServices from "../../services/chat-services";
import { fetchMessages, setChatGroupsList, setChatGroupsListDetails, setClearmessage, setactiveChatScreenId } from "../../actions/chat";
import { getExternalBenchmarkDetails, updateExternalBenchmarkDetails } from "../../actions/benchmark-actions/benchmark-actions";
// import { backgroundImage } from "html2canvas/dist/types/css/property-descriptors/background-image";

const SignatureModal = ({
  handleClose,
  proposalDetails,
  isProviderEstimate,
}) => {
  const fullName = localStorage.getItem("full_name");
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");

  const [userInformation, setUserInformation] = useState({
    firstName: fullName.split(" ")[0],
    lastName: fullName.split(" ").slice(1),
    billingAddress: address,
    emailAddress: email,
    cardNumber: "",
  });
  const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] =
    useState(false);
  const [isSignProcessOnGoing, setIsSignProcessOnGoing] = useState(false);
  const signatureContent = `${userInformation.firstName} ${userInformation.lastName}`;
  const [currentStep, setCurrentStep] = useState(
    isProviderEstimate ? "payment" : "signature"
  );

  const dispatch = useDispatch();
  const signaturepadRef = useRef();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const cardElement = elements ? elements.getElement(CardElement) : "";

  const { benchmarkDetail } = useSelector((state) => state.customer);

  const [searchParams, setSearchParams] = useSearchParams();

  const hash = searchParams.get("hash");

  const clearSignaturePad = () => {
    if (signaturepadRef.current && signaturepadRef.current.clear) {
      signaturepadRef.current.clear();
    }
  };

  const undoSignature = () => {
    if (signaturepadRef.current && signaturepadRef.current.toData()) {
      let signaturePadData = signaturepadRef.current.toData();
      signaturePadData.pop();
      signaturepadRef.current.fromData(signaturePadData);
    }
  };

  const sendTokenToServer = async (token) => {
    try {
      setIsSignProcessOnGoing(true);
      // const response = await BenchmarkServices.sendStripeTokenToServer(
      //   token,
      //   calculateSolarhubFees()
      // );
      // if (response.status) {
        // const stripeResponse = await stripe.confirmCardPayment(
        //   response.data.client_secret,
        //   {
        //     payment_method: {
        //       card: cardElement,
        //       billing_details: {
        //         name: `${userInformation.firstName} ${userInformation.lastName}`,
        //       },
        //     },
        //   }
        // );
        // console.log("stripeResponse",stripeResponse)
        const backendResponse = await BenchmarkServices.createSolarEstimate(
          benchmarkDetail.project_id,
          benchmarkDetail.id,
          proposalDetails.proposal_id,
          userInformation.firstName,
          userInformation.lastName,
          userInformation.emailAddress,
          // stripeResponse.paymentIntent,
          calculateSolarhubFees(),
          userInformation.startDate,
          signaturepadRef.current.getTrimmedCanvas().toDataURL("image/png")
        );
        if (backendResponse.status) {
          const client_id = localStorage.getItem("client_id");
          const contact_id = localStorage.getItem("contact_id");
          handleClose(true);
          dispatch(getDefaultTopics(benchmarkDetail.project_id));
          dispatch(getCustomerSpaces(client_id, contact_id));
          if (localStorage.getItem("is_external") && localStorage.getItem("is_external") == 1 ) {
            dispatch(getExternalBenchmarkDetails(hash));
            dispatch(updateExternalBenchmarkDetails({...benchmarkDetail,stuck_page_name :"contract"}))

            searchParams.set("step", "proposal-summary");
            setSearchParams(searchParams)
          }
        } else {
          showError("l_something_went_wrong");
        // }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsSignProcessOnGoing(false);
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();

    // if (!stripe || !elements) {
    //   return;
    // }

    // const card = elements.getElement(CardElement);
    // const result = await stripe.createToken(card);

    // if (result.error) {
    //   // Show error to your customer.
    //   showError(result.error.message);
    // } else {

      if (isProviderEstimate) {
        try {
          setIsSignProcessOnGoing(true);
          const response = await BenchmarkServices.purchaseSolarProviderPlan(
            // result.token.id
          );
          if (response.status) {
            const client_id = localStorage.getItem("client_id");
            const contact_id = localStorage.getItem("contact_id");

            dispatch(getCustomerSpaces(client_id, contact_id)).then(() => {
              const hash = localStorage.getItem(
                "is_solar_provider_plan_purchased"
              );
              // navigate(`/subscription/${hash}`);
              // navigate("/?page=proposals#/");

            });
            dispatch(showMessage("sucess", _l("l_sucess"), _l("l_solar_payment_successful")))
            handleClose();

          } else {
            dispatch(showMessage("unsucess", _l("l_error"),response.message));
          }
        } catch (e) {
          console.error(e);
        } finally {
          setIsSignProcessOnGoing(false);
        }
      } else {
        sendTokenToServer();
      }
    // }
  };

  const goToDiscussion = () => {
    localStorage.setItem("currentProposal", JSON.stringify(proposalDetails));
    // window.open(`/aichat?proposal_id=${proposalDetails.proposal_id}`, "_blank");
    // navigate(`/aichat?proposal_id=${proposalDetails.proposal_id}`);
    openNewChatChannel(proposalDetails.proposal_id)
    // handleClose();
  };

  const generateSignature = () => {
    if (signaturepadRef.current) {
      generateDefaultSignature(signaturepadRef, signatureContent);
    }
  };

  const calculateSolarhubFees = () => {
    const accessoriesList =
    proposalDetails && proposalDetails.proposal_item
      ? proposalDetails.proposal_item.map((w) => {
          w.title = w.name || "";
          w.one_time_price = w.one_time_amount;
          return w;
        })
      : [];

    // let totalAmount = Number(proposalDetails.amount);
    // if (proposalDetails.proposal_item) {
    //   proposalDetails.proposal_item.forEach((accessory) => {
    //     if (
    //       accessory.is_goverment != 1 &&
    //       !isNaN(Number(accessory.one_time_price))
    //     ) {
    //       totalAmount += Number(accessory.one_time_price);
    //     }
    //   });
    // }
    // console.log("totalAmount",totalAmount)
    // return Number(Number(totalAmount * 0.1).toFixed(2));
    let totalAmount = 0
    if (accessoriesList) {
      accessoriesList.forEach((accessory) => {
        if (
          accessory.is_goverment != 1 &&
          !isNaN(Number(accessory.one_time_price))
        ) {
          totalAmount += Number(accessory.one_time_price) + Number(accessory.one_time_tax_amount);
        }
      });
    }
    return Number(Number(totalAmount * 0.1).toFixed(2));
  };

  const goToNextStep = (e) => {
    switch (currentStep) {
      case "signature":
        // setCurrentStep("payment");
        handleSave(e);

        break;
      case "payment":
        if (!isSignProcessOnGoing) {
          handleSave(e);
        }
        break;
      default:
        break;
    }
  };

  const handlePDFPreview = async() =>{
    try {
      let response = await documentsServices.getPDFForSolarCustomer(proposalDetails.proposal_id)
      if (response && response.status) {
        let url = response.data && response.data.url ? response.data.url : ""
        window.open(url, '_blank');        
      }else{
        showError(_l("l_no_pdf_preview"))
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    generateSignature();
  }, []);

  return (
    <div className="solarmodal">
      <Modal
        onHide={handleClose}
        className="custom-modal-style solarcommanmodal "
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="xl"
        show
        scrollable={true}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-20pximp py-0 bg-white">
          <div
            className={`form-wrapper-main p-0 ${
              currentStep === "payment" ? "d-none" : ""
            }`}
          >
            {/* step 1 start */}
            {isProviderEstimate ? (
              <React.Fragment></React.Fragment>
            ) : (
              <div className="">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="leftsignpart RegNewFlow">
                      <div className="c-font f-14 fw-semibold title-fonts">
                        1. Le Contrat de votre installation de panneaux solaires
                      </div>
                      <div className="pb-20px pt-20px">
                        <div className="task-list-card list-view schedule-card-main document-list-card comman-white-box-shadow border radius10">
                          <div className=" d-flex justify-content-between">
                            <span>
                              {/* <img src={solarbox} alt={"solarbox"} className="h32wauto" /> */}
                              <span className="fw-semibold title-fonts">
                                {proposalDetails.company_name}
                              </span>
                              <span
                                className={`ps-2 pe-15px ${
                                  proposalDetails.is_verified > 0
                                    ? "opacity-1"
                                    : "opacity-0"
                                }`}
                              >
                                <ShieldCheck
                                  size={18}
                                  weight="fill"
                                  className={"c-icons color-green"}
                                />
                              </span>
                              {proposalDetails.compli_tasks > 0 ? (
                                <span className="badge  badge-white rounded-pill fw-light h32wauto c-font f-12">
                                  {`${proposalDetails.compli_tasks} ${_l(
                                    "l_total_tasks"
                                  )}`}
                                </span>
                              ) : (
                                <></>
                              )}
                            </span>
                            <div className="d-flex align-items-center">
                              <a
                                href="#/"
                                className="btn btn-white-05 position-relative fw-semibold"
                                onClick={() => goToDiscussion()}
                              >
                                <ChatCircle
                                  size={15}
                                  className="c-icons"
                                  weight="light"
                                />
                                <span className="ms-10px c-font f-12">
                                  {_l("l_discussion")}
                                </span>
                                {proposalDetails.unread_message_count > 0 ? (
                                  <div className=" rounded-pill badge-custom  d-flex p-2 light-pill ">
                                    {proposalDetails.unread_message_count}
                                  </div>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                              </a>
                              {/* <span className={`c-font f-12 d-flex justify-content-center ms-10px`}>0</span> */}
                            </div>
                          </div>
                          <div className="p-15 px-0">
                            <div className="border-bottom"></div>
                          </div>
                          <div>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex">
                                <div className="c-font color-white-60 f-12 with_separator_10 d-flex align-items-center ps-0">
                                  <MapPin
                                    size={18}
                                    weight="fill"
                                    className="c-icons"
                                  />
                                  <span className="ms-10px max-width-160px text-truncate">
                                    {proposalDetails.address}
                                  </span>
                                </div>
                                <div className="with_separator_10 d-flex">
                                  <div className="d-flex align-items-center">
                                    <div className="c-font color-white-60 f-12 fw-semibold">
                                      {proposalDetails.company_rating}
                                    </div>
                                    <div className="d-flex align-items-center ms-10px pe-10px">
                                      <span className="star-rating-items text-center d-flex flex-column active p-0">
                                        <Star
                                          size={18}
                                          weight={
                                            proposalDetails.company_rating > 1
                                              ? "fill"
                                              : "light"
                                          }
                                          className="c-icons"
                                        />
                                      </span>
                                      <span className="star-rating-items text-center d-flex flex-column active p-0">
                                        <Star
                                          size={18}
                                          weight={
                                            proposalDetails.company_rating > 2
                                              ? "fill"
                                              : "light"
                                          }
                                          className="c-icons"
                                        />
                                      </span>
                                      <span className="star-rating-items text-center d-flex flex-column active p-0">
                                        <Star
                                          size={18}
                                          weight={
                                            proposalDetails.company_rating > 3
                                              ? "fill"
                                              : "light"
                                          }
                                          className="c-icons"
                                        />
                                      </span>
                                      <span className="star-rating-items text-center d-flex flex-column active p-0">
                                        <Star
                                          size={18}
                                          weight={
                                            proposalDetails.company_rating > 4
                                              ? "fill"
                                              : "light"
                                          }
                                          className="c-icons"
                                        />
                                      </span>
                                      <span className="star-rating-items text-center d-flex flex-column active p-0">
                                        <Star
                                          size={18}
                                          weight={
                                            proposalDetails.company_rating > 5
                                              ? "fill"
                                              : "light"
                                          }
                                          className="c-icons"
                                        />
                                      </span>
                                    </div>
                                    {proposalDetails.company_total_review >
                                    0 ? (
                                      <div className="c-font color-white-60 f-12">
                                        {proposalDetails.company_total_review}{" "}
                                        avis Google
                                      </div>
                                    ) : (
                                      <React.Fragment></React.Fragment>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pt-10px pb-20px">
                        <div className="border radius_5 bg-white-03">
                          <div className=" d-flex justify-content-between align-items-center p-20 border-last-none border-bottom d-none">
                            <div className="d-flex align-items-center">
                              {/* <div className="h35w35 comman-round-box with-bg bg-white-05 me-20px">
                            <div
                              className="comman-bg-img h-100 w-100 bg-style-cover"
                              style={{ backgroundImage: `url('${User_2}')` }}
                            ></div>
                          </div> */}
                              <div className="c-font f-14 fw-semibold title-fonts">
                                {_l("l_solar_installation_title")}{" "}
                                {roundOffPowerValue(
                                  benchmarkDetail.solar_items
                                    .electricity_production
                                )}{" "}
                                Kwc
                              </div>
                            </div>
                            <div className=" c-font f-14 fw-semibold title-fonts">
                              € {proposalDetails.amount}
                            </div>
                          </div>
                          {proposalDetails.proposal_item.map(
                            (accessory, index) => {
                              if (accessory.is_goverment == 1) {
                                return <React.Fragment></React.Fragment>;
                              }
                              return (
                                <div
                                  key={index}
                                  className=" d-flex justify-content-between align-items-center p-20 border-last-none border-bottom"
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="c-font f-14 fw-semibold title-fonts">
                                      {accessory.name}
                                    </div>
                                  </div>
                                  <div className="c-font f-14 fw-semibold title-fonts">
                                    € {accessory.one_time_price}
                                  </div>
                                </div>
                              );
                            }
                          )}
                          <div className=" d-flex justify-content-between align-items-center p-20 border-last-none border-bottom d-none">
                            <div className="d-flex align-items-center">
                              <div className="c-font f-14 fw-semibold title-fonts">
                                {_l("l_solar_hub_platform_cost")}
                              </div>
                            </div>
                            <div className="c-font f-14 fw-semibold title-fonts">
                              € {calculateSolarhubFees()}
                            </div>
                          </div>
                          {proposalDetails.proposal_item.map(
                            (accessory, index) => {
                              if (accessory.is_goverment != 1) {
                                return <React.Fragment></React.Fragment>;
                              }
                              return (
                                <div
                                  key={index}
                                  className=" d-flex justify-content-between align-items-center p-20 border-last-none border-bottom"
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="c-font f-14 fw-semibold title-fonts">
                                      {accessory.name}
                                    </div>
                                  </div>
                                  <div className="c-font f-14 fw-semibold title-fonts">
                                    - € {accessory.one_time_price}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                      <div className="pb-20px">
                        <div className="p-20 bgblue05">
                          <div className="fblue c-font f-14 fw-semibold">
                            Conditions générales de ventes{" "}
                          </div>
                          <div className="fblue60 c-font f-14 pt-1">
                            {` Le contrat comprends la mise en place de votre installation solaire .En cliquant sur signer, je confirme avoir lu les conditions générales de ventes et je consens à être légalement lié par cette représentation électronique de ma signatureLe contrat comprends la mise en place de votre installation solaire .`}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="c-font f-14 fw-semibold title-fonts pb-20px">
                      Signature du contrat
                    </div>

                    <div className="row">
                      <Form.Group className="col-xl-6 c-input-box pb-20px position-relative">
                        <Form.Label className="input-label no-asterisk ">
                          {_l("l_first_name")}
                        </Form.Label>
                        <Form.Control
                          placeholder={`${_l("l_first_name_placeholder")}`}
                          type="text"
                          name=""
                          value={userInformation.firstName}
                          onChange={(e) => {
                            setUserInformation({
                              ...userInformation,
                              firstName: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="col-xl-6 c-input-box pb-20px position-relative">
                        <Form.Label className="input-label no-asterisk ">
                          {_l("l_last_name")}
                        </Form.Label>
                        <Form.Control
                          placeholder={`${_l("l_last_name_placeholder")}`}
                          type="text"
                          name=""
                          value={userInformation.lastName}
                          onChange={(e) => {
                            setUserInformation({
                              ...userInformation,
                              lastName: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="col-xl-12 c-input-box pb-20px position-relative">
                        <Form.Label className="input-label no-asterisk ">
                          {_l("l_email")}
                        </Form.Label>
                        <Form.Control
                          placeholder={`${_l("l_email_placeholder")}`}
                          type="email"
                          name=""
                          value={userInformation.emailAddress}
                          onChange={(e) => {
                            setUserInformation({
                              ...userInformation,
                              emailAddress: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="col-xl-12 c-input-box pb-20px position-relative">
                        <Form.Label className="input-label no-asterisk ">
                          {_l("l_signature")}
                        </Form.Label>
                        <SignatureCanvas
                          penColor="black"
                          ref={signaturepadRef}
                          canvasProps={{
                            height: 100,
                            className:
                              "custom-canvas-style form-control p-0 w-100 border",
                          }}
                        />
                      </Form.Group>
                      <div className="col-xl-12 c-input-box pb-20px position-relative">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex">
                            <a
                              href="#/"
                              className="h40w40 d-flex  form-control p-0  me-3"
                              onClick={undoSignature}
                              data-tip={_l("l_undo")}
                              data-delay-show="1000"
                              data-class="tooltip-main"
                            >
                              <ArrowBendDoubleUpLeft
                                size={18}
                                className="c-icons"
                                weight="light"
                              />
                            </a>
                            <a
                              href="#/"
                              className="h40w40 d-flex  form-control p-0"
                              onClick={clearSignaturePad}
                              data-tip={_l("l_clear")}
                              data-effect="solid"
                              data-delay-show="1000"
                              data-class="tooltip-main"
                            >
                              <Eraser
                                size={18}
                                className="c-icons"
                                weight="light"
                              />
                            </a>
                          </div>
                          <Button
                            variant=""
                            className="btn-white-05 fw-semibold"
                            onClick={generateSignature}
                          >
                            Signature par défaut
                          </Button>
                        </div>
                      </div>
                      <Form.Group className="col-xl-12 c-input-box pb-20px position-relative">
                        <div className="d-flex align-items-center">
                          <label className="form-check-label  me-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`default`}
                              label="I Accept this Estimate"
                              onChange={(e) => {
                                setIsTermsAndConditionsAccepted(
                                  e.target.checked
                                );
                              }}
                              checked={true}
                              disabled
                            />
                          </label>
                          <div className="d-flex align-items-center c-font f-12">
                            <span>
                              {" "}
                              En cliquant sur signer, je confirme avoir lu les
                              conditions générales de ventes
                              <a
                                href="#/"
                                className="fblueimp fw-semibold px-1"
                              >
                                conditions générales de ventes
                              </a>
                              et je consens à être légalement lié par cette
                              représentation électronique de ma signatre .
                            </span>
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group className="col-xl-12 c-input-box  position-relative">
                        <a
                          className="btn bluebtn fw-semibold w-100"
                          onClick={goToNextStep}
                        >
                          {isSignProcessOnGoing ? (
                        <>
                          <span>{_l("l_please_wait")}</span>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            className={`ms-2 `}
                            aria-hidden="true"
                          />
                        </>
                      ) : (
                          "SIGNER EN LIGNE"
                      )}
                        </a>
                        <a
                          className="btn btn-white-05 fw-semibold w-100 mt-1"
                          onClick={()=>{
                            handlePDFPreview()
                          }}
                        >
                          {_l("l_preview_as_pdf")}
                        </a>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* step 1 over */}

          {/* step 2 start */}

          <div className={`${currentStep === "payment" ? "" : "d-none"}`}>
            <div className="row">
              <div className="col-lg-8">
                <div className="leftsignpart">
                  <div className="c-font f-14 fw-semibold title-fonts">
                    {isProviderEstimate
                      ? "1. Abonnement Premium Solairehub Installateur"
                      : "2. Le Contrat de votre installation de panneaux solaires"}
                  </div>
                  <div className="pb-20px pt-20px">
                    <div className="p-20 border radius10px">
                      <div className="d-flex align-items-center">
                        <div className="comman-image-box h40w40 rounded-circle">
                          <div
                            className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                            style={{
                              backgroundImage: `url('https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
                            }}
                          ></div>
                        </div>
                        <div className="p-20 pe-0 py-0 w100minus40">
                          <div className="color-greenpill fw-semibold">
                            Support Blaz
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="align-items-center d-flex fblue title-fonts">
                              <Phone
                                size={18}
                                weight="light"
                                className="c-icons"
                              />
                              <span className="ps-10px fw-semibold">
                                Votre Expert Disponible au 01.96.44.33.22
                              </span>
                            </div>
                            {/* <div>
                              <a
                                href="#/"
                                class="btn btn-white-05 position-relative fw-semibold for-disabled"
                                onClick={() => goToDiscussion()}
                              >
                                <ChatCircle
                                  size={18}
                                  weight="light"
                                  className="c-icons"
                                />
                                <span class="ms-10px c-font f-12">
                                  {_l("l_discussion")}
                                </span>
                              </a>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-10px pb-20px">
                    <div className="p-20 border ">
                      <div className="d-flex align-items-center">
                        <div className="h30w30 comman-round-box">
                          <div
                            className="comman-bg-img h-100 w-100 bg-style-cover"
                            style={{
                              backgroundImage: `url('${accomptecalender}')`,
                            }}
                          ></div>
                        </div>
                        <div className="ps-20pximp w100minus40 d-flex align-items-center c-font f-14 fw-semibold f-blue title-fonts justify-content-between">
                          <div className=" fw-semibold">
                            {isProviderEstimate
                              ? `${_l("l_provider_solarhub_subscription_text")}`
                              : `Réserver votre installation : ${
                                  benchmarkDetail
                                    ? benchmarkDetail.startdate
                                    : ""
                                }`}
                          </div>
                          <div>
                            €{" "}
                            {isProviderEstimate
                              ? "1000 HT"
                              : calculateSolarhubFees()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pb-20px">
                    <div className="p-20 bgblue05 radius_5">
                      {/* premium box start */}
                      {isProviderEstimate ? (
                        <div className="row   align-items-center  ">
                          <div className="text-center fblue fw-semibold pb-20px">
                            Les avantages de l’abonnement premium
                          </div>
                          <div className="row g-2">
                            <div className="col-4">
                              <div className="d-flex justify-content-center">
                                <div className="h40w40 rounded-circle d-flex bg-white">
                                  <ChatCircleDots
                                    size={18}
                                    weight="light"
                                    className="c-icons"
                                  />
                                </div>
                              </div>
                              <div className="text-center pt-15px fblue">
                                Conversation en direct avec les clients avant
                                prise de décision
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="d-flex justify-content-center">
                                <div className="h40w40 rounded-circle d-flex bg-white">
                                  <Star
                                    size={18}
                                    weight="light"
                                    className="c-icons"
                                  />
                                </div>
                              </div>
                              <div className="text-center pt-15px fblue">
                                Voir les prix des autres installateurs avant
                                d’envoyer votre prix
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="d-flex justify-content-center">
                                <div className="h40w40 rounded-circle d-flex bg-white">
                                  <Key
                                    size={18}
                                    weight="light"
                                    className="c-icons"
                                  />
                                </div>
                              </div>
                              <div className="text-center pt-15px fblue">
                                Reception des offres en temps réel sur votre
                                adresse email
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row ">
                          <div className="col-6 d-flex align-items-center">
                            <div className="borderblue h40w40 rounded-circle d-flex bg-white">
                              <LockOpen
                                size={18}
                                weight="light"
                                className="c-icons"
                              />
                            </div>
                            <div className="ps-20pximp w100minus20 title-fonts">
                              <div className="fblue fw-medium">
                                La Garantie{" "}
                                <a
                                  href="javascript:;"
                                  className="fw-bold fblue"
                                >
                                  installé
                                </a>{" "}
                                ou{" "}
                                <a
                                  href="javascript:;"
                                  className="fw-bold fblue"
                                >
                                  remboursé
                                </a>
                              </div>
                              <div className="pt-1 fblue60">
                                La garantie Blaz c’est l’assurance d’une
                                installation sans encombres quoi qui puisse
                                arrivé vous serez installé ou remboursé
                              </div>
                            </div>
                          </div>
                          <div className="col-6 d-flex align-items-center">
                            <div className="borderblue h40w40 rounded-circle d-flex bg-white">
                              <Headphones
                                size={18}
                                weight="light"
                                className="c-icons"
                              />
                            </div>
                            <div className="ps-20pximp w100minus20 title-fonts">
                              <div className="fblue fw-medium">
                                Le{" "}
                                <a
                                  href="javascript:;"
                                  className="fw-bold fblue"
                                >
                                  suivi
                                </a>{" "}
                                de votre installation
                              </div>
                              <div className="pt-1 fblue60">
                                Grâce à notre plateforme vous pourrez suivre les
                                étapes de votre installation et être en lien
                                avec votre installateur en temps réél
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* premium box end */}
                      {/* signature box start  */}
                      {/* signature box end */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="c-font f-14 fw-semibold title-fonts pb-20px">
                  Paiement de votre réservation
                </div>
                <div className="p-20 border ">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="h30w30 comman-round-box">
                      <div
                        className="comman-bg-img h-100 w-100 bg-style-cover"
                        style={{
                          backgroundImage: `url('${accomptecalender}')`,
                        }}
                      ></div>
                    </div>
                    <div className="ps-1 w100minus40 d-flex align-items-center c-font f-14 fw-semibold f-blue title-fonts justify-content-between">
                      <div className="fw-semibold">
                        {isProviderEstimate
                          ? _l("l_subscription")
                          : "Réserver votre installation"}
                      </div>
                      <div>
                        €{" "}
                        {isProviderEstimate
                          ? "1000 HT / Month"
                          : calculateSolarhubFees()}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <Form.Group className="col-xl-12 c-input-box pb-20px position-relative stripeparent pt-20px">
                    <PaymentCardInput className="custom_strip" />
                  </Form.Group>
                  <Form.Group className="col-xl-12 c-input-box pb-20px position-relative">
                    <div className="d-flex align-items-center">
                      <label className="form-check-label  me-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          label="I Accept this Estimate"
                          checked={true}
                          disabled
                        />
                      </label>
                      <div className="d-flex align-items-center c-font f-12">
                        <span>
                          {" "}
                          En cliquant sur Payer Maintenant, je confirme avoir lu
                          les .
                          <a href="#/" className="fblueimp fw-semibold">
                            conditions générales de ventes
                          </a>
                        </span>
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group className="col-xl-12 c-input-box pb-20px position-relative">
                    <div className="d-flex align-items-center">
                      <img src={Paypal} className="me-10px" />
                      <img src={Visa} className="me-10px" />
                      <img src={MasterCard} className="me-10px" />
                      <img src={AmericanExpress} />
                    </div>
                  </Form.Group>
                  <Form.Group className="col-xl-12 c-input-box pb-20px position-relative RegNewFlow">
                    <Button
                      disabled={isSignProcessOnGoing}
                      className={`w-100 btn btn-primary px12pxpy8px`}
                      onClick={goToNextStep}
                    >
                      {isSignProcessOnGoing ? (
                        <>
                          <span>{_l("l_please_wait")}</span>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            className={`ms-2 `}
                            aria-hidden="true"
                          />
                        </>
                      ) : (
                        "PAYER MAINTENANT"
                      )}
                    </Button>
                  </Form.Group>
                  <Form.Group className="col-xl-12 c-input-box pb-20px position-relative">
                    <a className="btn btn-white-05 position-relative fw-semibold w-100">
                      Télécharger en pdf{" "}
                    </a>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default SignatureModal;
