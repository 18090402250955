import React from 'react';
// import CSS from '../css/icon_main.css';

function MultiPropertiesIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<g opacity="0.8">
<path d="M16.1777 10.4561H18.7559V13.0342H16.1777" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.1777 15.6123H18.7559V18.1904H16.1777" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.82226 13.0342H3.24414V10.4561H5.82226" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.82226 18.1904H3.24414V15.6123H5.82226" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.41111 5.40787L5.82225 2.98075L0.644531 7.835V20.7686H5.82225" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.1768 20.7686H21.3545V7.835L16.1768 2.98071L13.5879 5.40788" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11 2.98071L5.82227 7.835V20.7686H16.1777V7.835L11 2.98071Z" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.71094 13.0342V10.4561H12.2891V13.0342" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.2891 16.9014V20.7686" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.71094 20.7686V16.9014" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.83008 13.0342H13.1699" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.83008 16.9014H13.1699" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>



		</React.Fragment>
	)
}

export default MultiPropertiesIcon