import React from 'react';
// import CSS from '../css/icon_main.css';

function ListDownCaret(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
			<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons with-fill ${CommanHW}`}>
				<g clip-path="url(#clip0_15300_187444)">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M5.6875 2.8125C5.6875 2.53636 5.91136 2.3125 6.1875 2.3125H15.1875C15.4636 2.3125 15.6875 2.53636 15.6875 2.8125C15.6875 3.08864 15.4636 3.3125 15.1875 3.3125H6.1875C5.91136 3.3125 5.6875 3.08864 5.6875 2.8125Z" fill="white" />
					<path fill-rule="evenodd" clip-rule="evenodd" d="M5.6875 7.3125C5.6875 7.03636 5.91136 6.8125 6.1875 6.8125H15.1875C15.4636 6.8125 15.6875 7.03636 15.6875 7.3125C15.6875 7.58864 15.4636 7.8125 15.1875 7.8125H6.1875C5.91136 7.8125 5.6875 7.58864 5.6875 7.3125Z" fill="white" />
					<path fill-rule="evenodd" clip-rule="evenodd" d="M8.64645 16.6661C8.84171 16.8613 9.15829 16.8613 9.35355 16.6661L15.5411 10.4786C15.7363 10.2833 15.7363 9.96671 15.5411 9.77145C15.3458 9.57618 15.0292 9.57618 14.8339 9.77145L9 15.6054L3.16605 9.77145C2.97079 9.57618 2.65421 9.57618 2.45895 9.77145C2.26368 9.96671 2.26368 10.2833 2.45895 10.4786L8.64645 16.6661Z" fill="white" />
					<path d="M2.8125 3.375C3.12316 3.375 3.375 3.12316 3.375 2.8125C3.375 2.50184 3.12316 2.25 2.8125 2.25C2.50184 2.25 2.25 2.50184 2.25 2.8125C2.25 3.12316 2.50184 3.375 2.8125 3.375Z" fill="white" />
					<path d="M2.8125 7.875C3.12316 7.875 3.375 7.62316 3.375 7.3125C3.375 7.00184 3.12316 6.75 2.8125 6.75C2.50184 6.75 2.25 7.00184 2.25 7.3125C2.25 7.62316 2.50184 7.875 2.8125 7.875Z" fill="white" />
				</g>
				<defs>
					<clipPath id="clip0_15300_187444">
						<rect width="18" height="18" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</React.Fragment>
	)
}

export default ListDownCaret