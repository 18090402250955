import React from 'react';
// import CSS from '../css/icon_main.css';

function NoChatAvilabl(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

			<svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons h-100 w-100 ${CommanHW}`}>
				<path d="M43.9993 86.7965C67.635 86.7965 86.7955 67.636 86.7955 44.0003C86.7955 20.3646 67.635 1.2041 43.9993 1.2041C20.3636 1.2041 1.20312 20.3646 1.20312 44.0003C1.20312 67.636 20.3636 86.7965 43.9993 86.7965Z"  strokeMiterlimit="10" />
				<path d="M57.1114 62.317C56.7364 62.1295 56.5 62.0195 56.2718 61.9094C52.5098 59.9327 48.76 57.9396 44.9817 55.9955C44.4425 55.7305 43.8503 55.5912 43.2495 55.5879C38.44 55.5553 33.6346 55.5879 28.8292 55.5879C25.2506 55.5879 23.123 53.4684 23.123 49.8817C23.123 43.7354 23.123 37.5877 23.123 31.4386C23.123 27.8682 25.271 25.7324 28.8292 25.7324H59.1575C62.7238 25.7324 64.8636 27.8722 64.8636 31.4386C64.8636 35.1965 64.8636 38.9544 64.8636 42.7164C64.8636 45.2189 64.8921 47.7256 64.8636 50.2322C64.8147 53.4236 62.6097 55.5553 59.4142 55.5756C58.6276 55.5756 57.841 55.5756 57.0543 55.5756C56.1047 55.5756 55.8316 55.8813 56.0272 56.7984C56.3696 58.5673 56.7283 60.3606 57.1114 62.317Z"  strokeMiterlimit="10" />
				<path d="M68.8831 9.18066L18.1309 78.0948"  strokeMiterlimit="10" />
			</svg>

		</React.Fragment>
	)
}

export default NoChatAvilabl