import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import React, {useState, useMemo } from "react";
import { FileText } from "phosphor-react";

const Document = ({ projectId, clientId, location, zoomLevel, data }) => {
  const memoizedata = useMemo(() => data, [data]);

  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      {memoizedata.provider === 'one_drive' ?
        (
            <iframe
          src={ memoizedata.webViewLink }
          height="590px"
          width="100%"
        ></iframe>
        )
      :
      memoizedata.exportLinks ? (
        <iframe
          src={
            memoizedata.exportLinks
              ? memoizedata.webViewLink
              : memoizedata.exportLinks
          }
          height="590px"
          width="100%"
        ></iframe>
      ) : memoizedata.fileExtension == "pdf" ? (
        <embed
          src={
            memoizedata.exportLinks
              ? memoizedata.embedable_data[2]
              : memoizedata.embedable_data
          }
          height="590px"
          width="100%"
        ></embed>
      ) : ["png", "jpg", "jpeg", "gif"].includes(memoizedata.fileExtension) ? (
        <img
          src={data.exportLinks ? data.embedable_data[2] : data.embedable_data}
          alt=""
          height="590px"
          width="100%"
        />
      ) : ["wmv", "avi", "mp4", "webm", "flv", "swf", "mov"].includes(
          data.fileExtension
        ) ? (
        <video
          style={{
            width: "100%",
            height: "calc(100vh - 214px)",
          }}
          controls
        >
          <source
            src={
              memoizedata.exportLinks
                ? memoizedata.embedable_data[2]
                : memoizedata.embedable_data
            }
          />
        </video>
      ) : memoizedata.hasThumbnail && memoizedata.thumbnailLink ? (
        <img
          src={memoizedata.thumbnailLink}
          alt=""
          style={{
            width: "100%",
            height: "calc(100vh - 460px)",
            objectFit: "contain",
          }}
        />
      ) : (
        <FileText size={400} weight="light" className="c-icons" />
      )}
      {/* <table className="w-100">
                <thead></thead>
                <tbody className="">
                    <tr className="w-100 border-bottom c-font f-12">
                        <td className="w-50 p-2">
                            <div className="d-flex ">
                                <span className="">Amount : </span>
                                <span className="color-white-80  ps-3">$140.00</span>
                            </div>
                        </td>
                        <td className="w-50 p-2 border-left">
                            <div className="d-flex ">
                                <span className="">Billing period : </span>
                                <span className="color-white-80  ps-3">Nov 20, 2017</span>
                            </div>
                        </td>
                    </tr>
                    <tr className="w-100 border-bottom">
                        <td className="w-50 p-2">
                            <div className="d-flex ">
                                <span className="">Order Number : </span>
                                <span className="color-white-80  ps-3">1209472-8454378898-97287239</span>
                            </div>
                        </td>
                        <td className="w-50 p-2 border-left">
                            <div className="d-flex ">
                                <span className="">Next billing date : </span>
                                <span className="color-white-80  ps-3">May 20, 2018</span>
                            </div>
                        </td>
                    </tr>
                    <tr className="w-100 c-font f-12">
                        <td className="w-50 p-2">
                            <div className="d-flex ">
                                <span className="">Invoice Date : </span>
                                <span className="color-white-80  ps-3">Nov 20, 2017</span>
                            </div>
                        </td>
                        <td className="w-50 p-2 border-left">

                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="bill-from w-100 d-flex border-top p-2 mt-3">
                <div className="w-50 c-font f-12">
                    <div className="color-white-60  pb-2">Bill from</div>
                    <div className="">
                        <span className="">8 ORBIT , 24 rue de Clichy</span>
                    </div>
                    <div className="">
                        <span className="">75009 Paris , RCS :</span>
                        <span className="color-white-80  ps-3"> 87889318900018 France</span>
                    </div>
                    <div className="">
                        <span className="">VAT Number : </span>
                        <span className="color-white-80  ps-3"> FR68878893189</span>
                    </div>
                </div>
                <div className="w-50 c-font f-12">
                    <div className="color-white-60  pb-2">Bill To</div>
                    <div className="">
                        <span className="">DJS Avocats, 5 Rue Lincoln</span>
                    </div>
                    <div className="">
                        <span className="">Paris, FR 75008</span>
                    </div>
                </div>
            </div>
            <div className="">
                <div className=" d-flex flex-column pt-3">
                    <div className="comman-content-scroll-wrapper">
                        <div className="comman-content-scroll">
                            <div className="comman-data-table">
                                <table className="dataTable">
                                    <thead className="bg-transparent">
                                        <tr className="bg-white-03">
                                            <th style={{ width: "3%" }}>#</th>
                                            <th>Item</th>
                                            <th style={{ width: "7%" }}>Qty</th>
                                            <th style={{ width: "7%" }}>Rate</th>
                                            <th style={{ width: "18%" }}>Tax (%)</th>
                                            <th style={{ width: "8%" }}>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="c-font f-12">
                                            <td className="border-bottom align-top">1</td>
                                            <td className="max-width-100 text-truncate border-bottom">
                                                <div className="color-white-60 pt-2 ">
                                                    CI 944599 Prestation de
                                                </div>
                                            </td>
                                            <td className="border-bottom align-top ">1.00</td>
                                            <td className="border-bottom align-top ">1550.00</td>
                                            <td className="border-bottom align-top ">TVA 20.00%</td>
                                            <td className="border-bottom align-top ">1550.00</td>
                                        </tr>
                                        <tr className="c-font f-12">
                                            <td
                                                colSpan={5}
                                                className="text-end border-bottom "
                                            >
                                                Sub Total
                                            </td>
                                            <td className="border-bottom  color-white-60 ">€1550.00</td>
                                        </tr>
                                        <tr className="c-font f-12">
                                            <td
                                                colSpan={5}
                                                className="text-end border-bottom  "
                                            >
                                                TVA (20.00%)
                                            </td>
                                            <td className="border-bottom  color-white-60">€20.00</td>
                                        </tr>
                                        <tr className="c-font f-12">
                                            <td colSpan={5} className="text-end color-green ">
                                                Total
                                            </td>
                                            <td className="fw-semibold  color-green">€1570.00</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="terms-condition">
                    <div className="c-font fw-semibold pb-2">Terms & Conditions:</div>
                    <div className="color-white-60">
                        <div className="pb-3">
                            Myr.ai est une marque de la société 8 Orbit SAS immatriculée au
                            RCS de Paris sous le numéro de SIRET 878 893 189 et dont le
                            siège social est 75 rue Guy Moquet 92240 MALAKOFF / Myr.ai is a
                            registered trademark of the company 8 Orbit SAS registered with
                            the RCS of Paris under the number SIRET 878 893 189 and whose
                            registered office is 95 rue Guy Moquet 92240 MALAKOFF - FRANCE
                        </div>
                    </div>
                </div>
            </div> */}
    </div>
  );
};
export default Document;
