import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FileText } from "phosphor-react";
import { _l } from "../../hooks/utilities";

import { getContactPermissions } from "../../actions/customer";
import { useDispatch, useSelector } from "react-redux";

const ManageRolePermissionModal = ({ projectId, show, handleClose, handleShow, role }) => {

  const [userPermissions, setUserPermissions] = useState([]);
  const [allPermissions, setAllPermissions] = useState([]);

  let contactPermissions = useSelector((state) => state.customer.contactPermissions);
  const dispatch = useDispatch();

  useEffect(() => {
    if (contactPermissions && contactPermissions.length) {
      setAllPermissions(contactPermissions);
    }
    else {
      dispatch(getContactPermissions());
    }
  }, [contactPermissions]);

  useEffect(() => {
    setUserPermissions(role.permissions.filter((x) => x[1] === true))
  }, [role]);


  return (
    <>
      <div className="">
        <Modal
          show={show}
          onHide={handleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>{_l("l_permissions")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className=" pt-0">
          
            <div className="form-wrapper-main d-flex flex-wrap">
              {
                userPermissions && userPermissions.length ?
                  userPermissions.map((permission, index) => {
                    const permissionName = allPermissions.find((x => x.id === parseFloat(permission[0])))
                    return (
                     <div className=""> 
                       <a href="#/" className="me-2 badge rounded-pill mb-2 d-flex align-items-center">
                          <FileText size={18} className="c-icons" weight="light" />
                          <span key={index} class=" c-font ms-1 color-white-80 f-12 fw-light">{permissionName && permissionName.short_name ? _l(`l_${permissionName.short_name}`) : ""}</span>
                        </a>
                      </div>
                    )
                  })
                  :
                  <></>
              }
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            <Button variant="secondary" onClick={handleClose}>
            {_l("l_close")}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ManageRolePermissionModal;
