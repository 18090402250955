import React, { useEffect, useState } from "react";
import { _l } from "../../../hooks/utilities";
import ReactTooltip from "react-tooltip";
import {
  CalendarBlank,
  ChatCircle,
  DotsNine,
  Star,
  Trash,
  Wrench,
} from "phosphor-react";
import HighPriorityActiveIcon from "../../../assets/icons/HighPriorityActiveIcon";
import HighPriorityIcon from "../../../assets/icons/HighPriorityIcon";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import CommentMinified from "../../Comments/CommentMinified";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import moment from "moment";
import jQuery from "jquery";
import CommentDropdown from "../../Dropdowns/CommentDropdown";
import customerServices from "../../../services/customer-services";
import GeneralListing from "../../TaskModalComponents/GeneralListing";
import Lightbox from "yet-another-react-lightbox";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";


const ExternalChecklist = () => {
  const [checklistItems, setChecklistItems] = useState([]);
  const [showIcons, setshowIcons] = useState(-1);
  const [openCommentTab, setOpenCommentTab] = useState(false);
  const [taskStatus, settaskStatus] = useState();
  const [lightBoxAttachments,setLightBoxAttachments] = useState([])
  const [openGallery, setOpenGallery] = useState(false);




  const checklistStateData = useSelector(
    (state) => state.customer.taskChecklist
  );
  const selectedTask = useSelector((state) => state.customer.selectedTask);

  const handleTaskStatus = (
    checklistId,
    description,
    finished,
    checkUnCheck,
    index_id,
    priority,
    status
  ) => {
    const index = checklistItems.findIndex((item) => item.id == checklistId);
    let updatedChecklist = checklistItems[index];
    updatedChecklist.status = status;
    setChecklistItems(
      Object.values({ ...checklistItems, [index]: updatedChecklist })
    );
    handleCheckListUpdate(
      checklistId,
      description,
      finished,
      checkUnCheck,
      index_id,
      priority,
      status
    );
  };

  const handleCheckListUpdate = (
    id,
    value,
    isFinished,
    checkUnCheck = false,
    index = -1,
    priority,
    taskStatus,
    time
  ) => {
    const prev =
      checklistItems && checklistItems.length
        ? checklistItems.filter((previousValue) => previousValue.id == id)
        : {};

    if (
      !checkUnCheck &&
      prev[0].addedfrom != localStorage.getItem("contact_id") &&
      localStorage.getItem("user_type") == "contact"
    ) {
      return;
    }

    // const format = /[`_+\-=\[\]{};':"\\|<>\/?~]/;
    if (value) {
      //if (value && !format.test(value)) { //Temporary disabling the validations
      customerServices.updateChecklistItem(
        selectedTask.project_id,
        selectedTask.id,
        id,
        isFinished,
        value.trim(),
        priority,
        taskStatus,
        time
      ).then((res) => {
        if (res.status) {
         
        }
      });
    } 
  };


  const setAttchmentOnTop = (id, attachments) => {
    const targetAttchement = attachments.filter(
      (attachment) => attachment.id === id
    );
    const otherAttachments = attachments.filter(
      (attachment) => attachment.id != id
    );
    setLightBoxAttachments(targetAttchement.concat(otherAttachments));
    setOpenGallery(true);
  };


  useEffect(() => {
    setChecklistItems(checklistStateData);
  }, [JSON.stringify(checklistStateData)]);
 
  useEffect(() => {
   jQuery("body").addClass("solar-login") 
  })
  
  return (
    <>
     { openGallery ?<Lightbox
                      open={openGallery}
                      close={() => setOpenGallery(false)}
                      slides={lightBoxAttachments.map ((img , index)  => {
                        return { src: img.image_url };
                      })}
                    /> : <></>}
      <div className="d-flex  flex-grow-1  flex-column ">
        <div className="d-flex flex-grow-1 w-100 h100minus70px">
          <div className="w-100 d-flex flex-column flex-grow-1 gap10px">
            {checklistItems &&
              checklistItems.length > 0 &&
              checklistItems.map((checklist, index) => {
                return (
                  <>
                    <div className="task-list-card list-view   taskcardliteversion overflow-visible">
                      <div className="task-list-wrapper">
                        <div className="d-flex w-100 align-items-start">
                          <div className="d-flex task-list-detail">
                            <div className="task-left flex-grow-1 width100minus160 ">
                              <div className="d-flex align-items-center w-100 task-left-part-toggle text-truncate">
                                <div className="task-top-part text-truncate flex-grow-1 d-flex main-calc-width-task">
                                  <div className="flex-wrap d-flex  w-100 align-items-center comman_action_icon">
                                    <div className="task-creator-img h32w32 task-creator-img m-auto rounded-5">
                                      <div className="comman-image-box h-100 w-100 rounded-circle m-auto bg-black-op5 ">
                                        <div
                                          className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                          style={{
                                            backgroundImage: `url(${checklist.addedfrom_details.profile_url})`,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-column w100minus100per task-list-name text-truncate ms-10px">
                                      <div className="text-truncate w-100  fw-semibold">
                                        {checklist.description}
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <div className="c-font color-white-60 f-10 mt-1 location me-2 fw-normal ">
                                          <span className=" p-0 ">Date : </span>
                                          <span className="p-0">
                                            {" "}
                                            {moment(checklist.dateadded).format(
                                              "DD/MM/YYYY HH:mm:ss A"
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center task-bottom-part-full ">
                              <div className="task-right-static-options">
                                <div className="d-flex align-items-center gap10px comman_action_icon">
                                  {/* status  start*/}
                                  <div
                                    className={` dropdown dropdown-center comman_action_icon card-view-less-padding p-0`}
                                  >
                                    <a
                                    href="#/"
                                    className={`white-border-box me-0 color-white-60 action_icon with_bg h32w32 d-flex close-toast`}
                                      type="button"
                                      id="TaskListStatusDropdown"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      data-tip={`${_l("l_status")}`}
                                      data-effect="solid"
                                      data-delay-show="1000"
                                      data-class="tooltip-main"
                                      onClick={()=>{
                                        settaskStatus(
                                          checklist.status == 0 ? 2 : 0
                                        );
                                        handleTaskStatus(
                                          checklist.id,
                                          checklist.description,
                                          checklist.finished,
                                          "",
                                          index,
                                          checklist.priority,
                                          checklist.status == 0 ? 2 : 0
                                        );
                                      }}
                                    >
                                      <div className="d-flex align-items-center m-auto">
                                        <span
                                          className={`c-dots c-10 ${checklist.finished == 0
                                            ? checklist.status == 0
                                              ? "in-progress-white"
                                              : "in-progress-blue"
                                            : "done"
                                            }`}
                                        ></span>
                                      </div>
                                    </a>
                                    <ReactTooltip />
                                  </div>
                                  {/* staus end */}

                                  {/* task rating start */}
                                  {checklist.id == showIcons ? <div className="   text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding ">
                                    <a
                                      href="#/"
                                      className="comman_action_icon w-100 d-flex align-items-center close-toast border-0"
                                      data-tip={_l("l_star_rating")}
                                      data-effect="solid"
                                      data-delay-show="1000"
                                      data-class="tooltip-main"
                                    >
                                      <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                                        {/* <Star size={18} className="c-icons" weight="fill"  color="##ff8900" /> */}

                                        <Star size={18} className="c-icons" />
                                      </div>
                                      <span className="c-font f-12 d-flex justify-content-center flex-grow-1">
                                        {checklist.rating}
                                      </span>
                                    </a>
                                    <ReactTooltip />
                                  </div> : <></>}
                                  {/* task rating end */}

                                  {/* HighPriorityIcon start */}
                                 {checklist.id == showIcons ? <div className="comman_action_icon card-view-less-padding p-0">
                                    <div
                                      className={`priority-checkbox span-13 white-border-box action_icon with_bg h32w32 close-toast`}
                                      data-tip={_l("l_priority")}
                                      data-effect="solid"
                                      data-delay-show="1000"
                                      data-class="tooltip-main"
                                    >
                                      <div className="priority-checkbox text_wrap position-relative">
                                        <input
                                          type="checkbox"
                                          className="absolute-input"
                                          id="priority"
                                          onClick={(e) => {}}
                                          checked={checklist.priority == 1}
                                        />
                                        <div
                                          className="check_box"
                                          onChange={() => {
                                            // handleChecklistAssignee(0, checklist.id, 'priority')
                                          }}
                                        >
                                          <HighPriorityActiveIcon className="HW18 check_span_img active" />
                                          <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                                        </div>
                                      </div>
                                    </div>
                                    <ReactTooltip />
                                  </div> : <></>}
                                  {/* HighPriorityIcon end */}

                                  {/* comment icon start */}
                                  <div
                                    className={` dropdown-center dropdown  text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding close-toast`}
                                  >
                                    <UncontrolledDropdown className="w-100">
                                      <DropdownToggle
                                        data-toggle="dropdown"
                                        tag="span"
                                        className="comman_action_icon w-100 d-flex close-toast border-0"
                                      >
                                        <a
                                          href="#/"
                                          data-tip={_l("l_comments")}
                                          data-effect="solid"
                                          data-delay-show="1000"
                                          data-class="tooltip-main"
                                          onClick={()=>{
                                            setOpenCommentTab(!openCommentTab);
                                          }}
                                        >
                                          <div className="d-flex align-items-center w-100">
                                            <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                                              <ChatCircle
                                                size={18}
                                                className="c-icons"
                                              />
                                             { checklist.unread_comment_count > 0 ? 
                                             <span
                                                className={`rounded-pill badge-custom p-0`}
                                              >
                                                {checklist.unread_comment_count}
                                              </span> : <></>}
                                            </div>
                                            {/* {isLiteVersion ? (<></>) : ( */}
                                            <span className="c-font f-12 d-flex justify-content-center flex-grow-1 number-counter-toggle">
                                            {checklist.comment_count}
                                            </span>
                                            {/* )} */}
                                          </div>
                                        </a>
                                      </DropdownToggle>
                                      <DropdownItem
                                        toggle={false}
                                        className="p-0"
                                      >
                                         {
                                                  <CommentDropdown
                                                    docType={"imageview"}
                                                    data={selectedTask}
                                                    key={index}
                                                    openCommentTab={openCommentTab}
                                                    setOpenCommentTab={
                                                      setOpenCommentTab
                                                    }
                                                    projectId={selectedTask.projectId ||selectedTask.project_id}
                                                    task_id={selectedTask.id}
                                                    updatedCommentCount={(count) => {
                                                      // setCommentCount({
                                                      //   ...commentCount,
                                                      //   [item.id]: count,
                                                      // });
                                                    }}
                                                    checklistId={checklist.id}
                                                    allComment={1}
                                                    unContolList={true}
                                                    isExternal={true}
                                                    hash_id={ selectedTask.hash}
                                                    mainClassName="checklist-comment"
                                                    setChecklistItems={setChecklistItems}
                                                    checklistItems={checklistItems}
                                                  />
                                                }
                                        
                                      </DropdownItem>
                                    </UncontrolledDropdown>
                                  </div>
                                  {/* comment icon end */}

                                  {/* Equipment icon start */}
                                 {checklist.id == showIcons? <div className="   text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding ">
                                    <a
                                      href="#/"
                                      className="comman_action_icon w-100 d-flex align-items-center close-toast border-0"
                                      data-tip={_l("l_equipment")}
                                      data-effect="solid"
                                      data-delay-show="1000"
                                      data-class="tooltip-main"
                                    >
                                      <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                                        <Wrench size={18} className="c-icons" />
                                      </div>
                                    </a>
                                    <ReactTooltip />
                                  </div> : <></>}

                                  {/* Equipment icon end */}

                                  {/* Delete icon start */}
                                 {checklist.id == showIcons? <div className="   text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding ">
                                    <a
                                      href="#/"
                                      className="comman_action_icon w-100 d-flex align-items-center close-toast border-0"
                                      data-tip={_l("l_equipment")}
                                      data-effect="solid"
                                      data-delay-show="1000"
                                      data-class="tooltip-main"
                                    >
                                      <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                                        <Trash size={18} className="c-icons" />
                                      </div>
                                    </a>
                                    <ReactTooltip />
                                  </div> : <></>}

                                  {/* Delete icon end */}

                                  {/* checklist Attachments start */}
                                  {checklist && checklist.attachments && checklist.attachments.length > 0 &&
                                        checklist.attachments.map ((img , index) =>
                                        {return(<>
                                        {index == 0 ? 
                                        <div className="assignee-img-with-remove">
                                          <a href="#/"
                                            className="h32w32 comman-round-box with-bg d-flex align-items-center bg-white-05 radius_3"
                                            data-tip={""}
                                            data-place="left"
                                            data-effect="solid"
                                            data-delay-show='1000'
                                            data-class="tooltip-main"
                                            onClick={()=>{
                                              setLightBoxAttachments(checklist.attachments)
                                              setOpenGallery(true);
                                            }}
                                          >
                                            <div
                                              className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                                              style={{
                                                backgroundImage: `url('${img.image_url}')`,
                                              }}
                                            ></div>
                                          </a>
                                        </div> : <></>}
                                        {index == 1 && checklist.attachments.length > 1 ?
                                        <>
                                        <div className="assignee-img-with-remove">
                                         <a href="#/"
                                            className="h32w32 comman-round-box with_overlay d-flex align-items-center bg-white-05 radius_3"
                                            data-tip={""}
                                            data-place="left"
                                            data-effect="solid"
                                            data-delay-show='1000'
                                            data-class="tooltip-main"
                                          >
                                          <div class="comman-bg-img h-100 w-100 bg-style-cover radius_3 d-flex"
                                            type="button"
                                            id="GeneralListing"
                                            data-bs-toggle="dropdown"
                                            data-bs-auto-close="outside"
                                            aria-expanded="false">
                                              <div class="unread_count m-auto">+{checklist.attachments.length - 1}</div> 
                                          </div>
                                          <GeneralListing 
                                          handler={ (attachData)=>{
                                              setLightBoxAttachments(checklist.attachments);
                                              setAttchmentOnTop(attachData.id ,checklist.attachments);
                                            } }
                                          roundedImage={false} list={checklist.attachments.map((generalAttach, index) => {
                                            return {
                                              name: generalAttach.file_name,
                                              image: generalAttach.image_url,
                                              id: generalAttach.id,
                                              otherData: generalAttach
                                            };
                                          })} />
                                          </a>
                                          </div>
                                        </>
                                      : <></>}
                                        </>)}
                                        )}
                                  {/* <div
                                    className={`d-flex align-items-center comman_action_icon multi_inner_wrapper gap10px`}
                                  >
                                    <div className="assignee-img-with-remove ">
                                      <a
                                        href="#/"
                                        className="h32w32 comman-round-box with-bg d-flex align-items-center bg-white-05 radius_3"
                                        data-tip={""}
                                        data-place="left"
                                        data-effect="solid"
                                        data-delay-show="1000"
                                        data-class="tooltip-main"
                                      >
                                        <div
                                          className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                                          style={{
                                            backgroundImage: `url('https://images.unsplash.com/photo-1526406915894-7bcd65f60845?q=80&w=1924&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
                                          }}
                                        ></div>
                                      </a>
                                    </div>

                                    <>
                                      <div className="assignee-img-with-remove">
                                        <a
                                          href="#/"
                                          className="h32w32 comman-round-box with_overlay d-flex align-items-center bg-white-05 radius_3"
                                          data-tip={""}
                                          data-place="left"
                                          data-effect="solid"
                                          data-delay-show="1000"
                                          data-class="tooltip-main"
                                        >
                                          <div
                                            class="comman-bg-img h-100 w-100 bg-style-cover radius_3 d-flex"
                                            type="button"
                                            id="GeneralListing"
                                            data-bs-toggle="dropdown"
                                            data-bs-auto-close="outside"
                                            aria-expanded="false"
                                          >
                                            <div class="unread_count m-auto">
                                              +5
                                            </div>
                                          </div>
                                        </a>
                                      </div>
                                    </>
                                  </div> */}
                                  {/* checklist Attachments end */}

                                  {/* seperator start */}
                                  {/* <div className="Seperaotr position-relative"></div> */}
                                  {/* seperator end */}

                                  {/* calendar icon strat */}

                                  {checklist.id == showIcons ? <div
                                    className="comman_action_icon custom-datepicker withinputabsolute action_icon h32w32 with_bg  w-330px"
                                    data-tip="03-07-2024"
                                    data-effect="solid"
                                    data-delay-show="1000"
                                    data-class="tooltip-main"
                                  >
                                    <div
                                      className={`m-auto on-hover-active-toggle-img light-theme-img position-relative`}
                                    >
                                      <CalendarBlank
                                        size={18}
                                        className="c-icons"
                                      />
                                      <DatePicker
                                        portalId="custom_datepicker"
                                        calendarClassName="custom-datepicker"
                                        className="close-toast"
                                      ></DatePicker>
                                    </div>
                                  </div> : <></>}

                                  {/* calendar icon end */}

                                  {/* nine dots start */}

                                  {/* <div className={`comman_action_icon `}>
                                    <a
                                      href="#/"
                                      className={`action_icon h32w32 with_bg with-border-active`}
                                      onClick={()=>{
                                        if (checklist.id == showIcons) {
                                          setshowIcons(-1)
                                        } else {
                                          setshowIcons(checklist.id)
                                        }
                                      }}
                                    >
                                      <DotsNine size={18} className="c-icons" />
                                    </a>
                                  </div> */}

                                  {/* Nine dots over */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
              {
                checklistItems &&
                checklistItems.length == 0 ? <>
                <CommanPlaceholder imgType="no-checklist" mainSpacing="m-auto" placeholderText={_l("l_no_checklist")} />
                </> : <></> 
              }
          </div>
        </div>
      </div>
    </>
  );
};

export default ExternalChecklist;
