import React from 'react';
// import CSS from '../css/icon_main.css';

function NoNotes(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

			<svg width="86" height="112" viewBox="0 0 86 112" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons h-100 w-100 ${CommanHW}`}>
				<path d="M39.0202 97.8363C31.5245 97.8363 15.2761 97.873 7.78045 97.8363C3.29668 97.8049 1.01813 95.5367 1.01289 91.1106C1.01289 66.9579 0.950034 42.8 1.10194 18.6473C1.23467 16.525 2.05262 14.5031 3.43287 12.8855C6.34671 9.48626 9.51813 6.31666 12.919 3.4047C14.4581 2.07114 16.3876 1.2717 18.4189 1.1261C34.0336 0.948002 49.6534 0.995196 65.2733 1.04758C70.0399 1.04758 72.0828 3.19517 72.0828 7.97751C72.0828 24.2155 72.0828 49.6357 72.0828 65.8789M7.77521 14.0222C9.93329 13.7865 13.4009 14.546 14.5428 13.3622C15.6847 12.1784 14.7732 9.30271 14.7732 7.17083C12.4371 9.40747 9.90186 12.0003 7.77521 14.017V14.0222Z" strokeMiterlimit="10" />
				<path d="M84.6431 87.0723C84.6397 90.2276 84.0129 93.3512 82.7987 96.2636C81.5846 99.1761 79.807 101.82 77.568 104.043C75.3291 106.267 72.6729 108.026 69.7521 109.22C66.8312 110.413 63.7033 111.018 60.548 111C54.1723 110.953 48.0759 108.377 43.5975 103.839C39.1191 99.3004 36.6249 93.1704 36.6625 86.7946C36.6672 83.6373 37.2976 80.5121 38.5174 77.5999C39.7372 74.6877 41.5221 72.0462 43.769 69.828C46.0159 67.6098 48.6802 65.8591 51.6079 64.6769C54.5356 63.4947 57.6685 62.9045 60.8257 62.9405C63.9804 62.9388 67.1041 63.5642 70.015 64.7802C72.926 65.9963 75.5663 67.7788 77.7824 70.0242C79.9985 72.2695 81.7462 74.9328 82.924 77.8595C84.1017 80.7862 84.6861 83.9178 84.6431 87.0723Z" strokeMiterlimit="10" />
				<path d="M43.9707 69.6138L77.3161 104.29" strokeMiterlimit="10" />
				<path d="M14.6738 22.1411H58.4115" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M14.6523 33.3872H46.8297" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M14.6523 44.6333H38.7055" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M14.6523 55.8799H30.5865" strokeLinecap="round" strokeLinejoin="round" />
			</svg>


		</React.Fragment>
	)
}

export default NoNotes