import { Check, CheckCircle, MagnifyingGlass, Wrench } from "phosphor-react";
import React from "react";
import { _l } from "../../../hooks/utilities";

const EQUIPMENTS_TYPE = [
  {
    key: "l_add_equipments",
    description: "l_add_equipments_descriptions",
    icon: Wrench,
  },
  {
    key: "l_find_equipments",
    description: "l_find_equipments_description",
    icon: MagnifyingGlass,
    disabled: true
  },
];

const SelectEquipmentAction = ({data, onChange}) => {

  return (
    <React.Fragment>
      {EQUIPMENTS_TYPE.map((equipment, key) => {
        let title = equipment.key;
        return (
          <div
            key={key}
            className="space-select-box select-input-cox mx-3 bg-transparent"
          >
            <input
              className="bottom-0 end-0 form-check-input h_230px comman-input-check-box position-absolute start-0 top-0 w-100 bg-transparent"
              name="equipment-type"
              type="radio"
              value={equipment.key.replace("l_", "")}
              onChange={onChange}
              disabled={equipment.disabled}
              checked={data.value === equipment.key.replace("l_", "")}
            />
            <a href="#/" className="check-image">
              <div className="check-image-box">
                <Check size={14} className="c-icons color-white-03-solid" />
              </div>
            </a>
            <div className="">
              <div className="d-flex bg-white-03 m-auto h_230px radius_3">
                <equipment.icon
                  size={56}
                  weight="light"
                  className="c-icons stroke-width-3px"
                />
              </div>
              <div className="text-center p-10">
                <div className="text-center c-font f-16 p-10 px-0 fw-semibold">
                  {_l(title)}
                </div>
                <div className="text-center c-font f-14 color-white-60 fw-light">
                  {_l(equipment.description)}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default SelectEquipmentAction;
