import React from 'react';
// import CSS from '../css/icon_main.css';

function PaperclipIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
			<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M10.3906 4.99974L5.18437 10.2935C5.01201 10.4837 4.9194 10.7329 4.92572 10.9896C4.93204 11.2462 5.0368 11.4905 5.21831 11.672C5.39983 11.8536 5.64419 11.9583 5.90081 11.9646C6.15743 11.971 6.40665 11.8783 6.59687 11.706L12.8031 5.41224C13.1478 5.03179 13.3331 4.53335 13.3204 4.02011C13.3078 3.50687 13.0983 3.01815 12.7352 2.65512C12.3722 2.2921 11.8835 2.08258 11.3703 2.06994C10.857 2.05731 10.3586 2.24252 9.97812 2.58724L3.77187 8.88099C3.20994 9.44292 2.89426 10.2051 2.89426 10.9997C2.89426 11.7944 3.20994 12.5566 3.77187 13.1185C4.3338 13.6804 5.09594 13.9961 5.89062 13.9961C6.68531 13.9961 7.44744 13.6804 8.00937 13.1185L13.1406 7.99974"/>
</svg>




		</React.Fragment>
	)
}

export default PaperclipIcon