import React from 'react';
// import CSS from '../css/icon_main.css';

function CreditNoteplaceholderIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

			<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<path d="M37.5 37.5H62.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M37.5 50H62.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M37.5 62.5H50" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M61.2109 84.375H18.75C17.9212 84.375 17.1263 84.0458 16.5403 83.4597C15.9542 82.8737 15.625 82.0788 15.625 81.25V18.75C15.625 17.9212 15.9542 17.1263 16.5403 16.5403C17.1263 15.9542 17.9212 15.625 18.75 15.625H81.25C82.0788 15.625 82.8737 15.9542 83.4597 16.5403C84.0458 17.1263 84.375 17.9212 84.375 18.75V61.2109C84.3764 61.6167 84.2978 62.0188 84.1436 62.3942C83.9895 62.7695 83.7628 63.1108 83.4766 63.3984L63.3984 83.4766C63.1108 83.7628 62.7695 83.9895 62.3942 84.1436C62.0188 84.2978 61.6167 84.3764 61.2109 84.375V84.375Z" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M84.1016 62.5H62.5V84.1016" strokeLinecap="round" strokeLinejoin="round" />
			</svg>


		</React.Fragment>
	)
}

export default CreditNoteplaceholderIcon