import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import { getScheduleTitle, LeftPanelResponsiveToggle, _l, showError, isSolarHubPlatform, CardListFullHeight, hideSolairHubleftPanel } from "../../hooks/utilities";
import DatePicker from "react-datepicker";
import GeneralListing from "../TaskModalComponents/GeneralListing";
import { getMentionList, setCategoryNotificationCounts, setCreateScheduledetails, setSpaceNotificationCount, setVirtualSpaceNotificationCount } from "../../actions/customer";
import moment from "moment";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import { UserCircleGear, CalendarBlank, Repeat, DotsThreeVertical, ChatCircle,  ChatDots } from "phosphor-react";
import { getFloorDetails } from "../../actions/benchmark-actions/benchmark-actions";
import CommentMinified from "../Comments/CommentMinified";
import DocumentsServices from "../../services/documents-services";
import customerServices from "../../services/customer-services";
import { useNavigate } from "react-router-dom";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import LeftPanelLogoSolar from "../../assets/images/left-panel-logo.png";
import LeftPanelLogoMyr from "../../assets/images/left-panel-logo-myr.png";
import BenchmarkServices from "../../services/benchmark-services";
import DeleteDropdown from "../Dropdowns/DeleteDropdown";
import { USR_IMG_PLACEHOLDER } from "../../actions/chat-action-type";


let showComments = false;

const ScheduleCard = ({ benchmark, handleBenchmarkModal = () => { }, active, handler = () => { }, handleDeleteBenchmark = () => { }, handleStopBenchmark = () => { }, readOnly, pageName, fromChat = false, aiChatAssistURL, reduceBadgeCount, 
initReply = () => {},
setUnreadMessage = ()=>{},
msgid = "",
fromReply = false,
proposal_id
}) => {
  let isSolarCard = Number(benchmark.is_solar) == 1 ? true : false
  
  const [showTaskAssignee, setShowTaskAssignee] = useState(0);
  const [startDate, setStartDate] = useState(
    isSolarCard ? benchmark.start_date ? new Date(benchmark.start_date) : null :
    benchmark.start_date && benchmark.start_date != "0000-00-00"
    ? new Date(benchmark.start_date)
    : null
    );
    const [commentsLoader, setCommentsLoader] = useState(false);
    const [benchmarkComments, setBenchmarkComments] = useState([]);

  const navigate = useNavigate();

  const createrImage = benchmark.creator_details
    ? benchmark.creator_details.profile_image_url
    : "";
  const createrName = benchmark.creator_details
    ? benchmark.creator_details.full_name
    : "";
  const assignees = benchmark.assignees || [];
  const dispatch = useDispatch();
  const handleTaskAssigneeData = () => {
    setShowTaskAssignee(showTaskAssignee == 0 ? 1 : 0);
  };

  let commentCount = useRef(benchmark.comments_count);
  let unreadCount = useRef(benchmark.unread_comment_count);
  const comments = useSelector(state => state.documentsReducer);
  const projectId = useSelector(state => state.customer.selectedProject);
  const mentionsList = useSelector((state) => state.customer.mentionList);

  const isSchedule = benchmark.is_schedule;
  const userType = localStorage.getItem("user_type");
  const contact_id = localStorage.getItem("contact_id") || 0;
  const staff_id = localStorage.getItem("staff_id") || 0;
  const contact_image = localStorage.getItem("contact_image");
  const full_name = localStorage.getItem("full_name");
  const rel_id = userType == "operator" && benchmark.proposal_id ? benchmark.proposal_id : benchmark.id;
  const rel_type = userType == "operator" && benchmark.proposal_id ? "proposal" : "schedule";

  useEffect(() => {
    if (benchmark) {
      setStartDate(
        benchmark.start_date && benchmark.start_date != "0000-00-00"
          ? new Date(benchmark.start_date)
          : null
      );
      commentCount.current = benchmark.comments_count;
      unreadCount.current = benchmark.unread_comment_count;
    }
  }, [benchmark]);

  const updateBenchmark = (id, selectedDate = "") => {
    customerServices.updateBenchmark(id, moment(selectedDate).format('YYYY-MM-DD')).then((res) => {
      if(res.status == 1)
      {
      }
    })
  }
  useEffect(() => {
    const commentsObj = { benchmark: comments.benchmark_comments, schedule: comments.schedule_comments, proposal: comments.proposal_comments };
    if (benchmark.proposal_id > 0) {
      if (commentsObj.proposal.length > 1) {
        commentsObj.proposal.forEach((schedule) => {
          if (schedule.id == benchmark.proposal_id) {
            commentCount.current = schedule.comments.length;
            unreadCount.current = 0;
          }
        });
      }
    }
    else {
      if (commentsObj.benchmark.length > 1) {
        commentsObj.benchmark.forEach((schedule) => {
          if (schedule.id == benchmark.id) {
            commentCount.current = schedule.comments.length;
            unreadCount.current = 0;
          }
        });
      }
      else if (commentsObj.schedule.length > 1) {
        commentsObj.schedule.forEach((schedule) => {
          if (schedule.id == benchmark.id) {
            commentCount.current = schedule.comments.length;
            unreadCount.current = 0;
          }
        });
      }
    }
  }, [JSON.stringify(comments.benchmark_comments), JSON.stringify(comments.schedule_comments)]);


  const statusClassArr = {
    1: "text-warning",
    2: "text-danger",
    3: "c-text done",
    4: "c-text in-progress-blue",
    5: "text-danger",
  };

  let benchmarkStatus = benchmark.is_opened == "0" ? 2 : 3;
  let statusClass = useRef(statusClassArr[benchmark.is_schedule == 1 ? benchmark.status : benchmarkStatus]);
  let statusText = getBenchmarkStatusText(benchmark);

  const handleScheduleEdit = async () => {
    let allowEdit = false;
    let benchmarkId = benchmark.id;
    const rel_id = benchmark.id;
    let rel_type = userType == "operator" && benchmark.proposal_id ? "proposal" : benchmark.is_schedule == 0 ? "benchmark" : "schedule";

    if (benchmark.is_allowed_to_edit == 1 && benchmark.is_allowed_to_copy == 1) {
      rel_type = benchmark.open_schedule_modal == 1 ? "schedule" : "benchmark";
      navigate(`/connect-provider?space=${benchmark.project_id}&provider-type=${rel_type}&id=${rel_id}&copy=1&action=edit`);
      allowEdit = true;
      return true;
    }
    else if(benchmark.is_allowed_to_edit == 1 && benchmark.is_allowed_to_copy == 0)
    {
      navigate(`/connect-provider?space=${benchmark.project_id}&provider-type=${rel_type}&id=${rel_id}&action=edit`);
      return true;
    }
    handleBenchmarkModal(
      { ...benchmark, id: benchmarkId },
      allowEdit
    );
    dispatch(setCreateScheduledetails({}));
    LeftPanelResponsiveToggle();
  }

  function getBenchmarkStatusText(benchmark) {
    if (benchmark.is_schedule == 0) {
      if (userType == "operator") {
        switch (benchmark.proposal_status) {
          case "2":
            statusClass.current = "text-danger";
            return _l("l_rejected")
          case "3":
            statusClass.current = "text-done";
            return _l("l_accepted");;
          case "4":
            statusClass.current = "in-progress";
            if (benchmark.proposal_amount == 0 || benchmark.proposal_amount == null) {
              return _l("l_received");
            }
            else {
              statusClass.current = "text-warning";
              return _l("l_pending");
            }
          default:
            break;
        }
      }
      else {
        if(benchmark.status == 1)
        {
          statusClass.current = "text-warning";
          return _l('l_draft');
        }
        else if (benchmark.is_opened == 1) {
          statusClass.current = "text-done";
          return _l('l_open');
        }
        else {
          statusClass.current = "text-danger";
          return _l('l_close');
        }
      }
    }
    else {
      if ((benchmark.status == 3 || benchmark.status == 4)) {
        if(!benchmark.start_date)
        {
          statusClass.current = "text-warning";
          return _l('l_waiting_for_start_date');
        }
        else if((benchmark.is_assigneed == 0))
        {
          statusClass.current = "text-warning";
          return _l('l_waiting_for_assignee');
        }
        else {
          statusClass.current = "text-done";
          return benchmark.status_text;
        }
      }
      else {
        statusClass.current = "text-done";
        return benchmark.status_text;
      }
    }
  }

  const getScheduleComments = async () => {
    try {
      setCommentsLoader(true);
      showComments = true;
      dispatch(getMentionList(rel_id, rel_type));
      const response = await DocumentsServices.getComments(rel_id, rel_type, undefined, undefined, 1, benchmark.id);
      if (response.data) {
        if(unreadCount.current > 0)
        {
          if(reduceBadgeCount)
          {
            reduceBadgeCount(unreadCount.current);
          }
          if (localStorage.getItem('selectedVirtualSpace') && JSON.parse(localStorage.getItem('selectedVirtualSpace'))) {
            dispatch(setVirtualSpaceNotificationCount(projectId, Number(benchmark.unread_comment_count), -1));
          }
          else {
            dispatch(setSpaceNotificationCount(projectId, Number(benchmark.unread_comment_count), -1));
          }
          dispatch(setCategoryNotificationCounts("providers", benchmark.provider_id, benchmark.is_schedule == 0 ? "benchmark_count" : "schedule_count", Number(benchmark.unread_comment_count), -1));
        }
        let formattedComments = response.data.map((comment) => {
          return { ...comment, profile_url: comment.addedfrom_details.profile_url, full_name: comment.addedfrom_details.full_name }
        });
        formattedComments.sort((a,b) => Number(a.id) - Number(b.id));
        setBenchmarkComments(formattedComments);
        unreadCount.current = 0;
      }
    }
    catch (e) {
      console.error(e);
    }
    finally {
      setCommentsLoader(false);
    }
  }

  const handleAddComment = async (comment, files, id = 0) => {
    try {
      if ((comment && comment.trim().length) || files) {
        const response = await DocumentsServices.addDocumentComment(rel_type, rel_id, comment, undefined, id, files, benchmark.id);
        if (id > 0) {
          const commentIndex = benchmarkComments.findIndex((w) => w.id == id);
          setBenchmarkComments(Object.values({ ...benchmarkComments, [commentIndex]: { ...benchmarkComments[commentIndex], content: comment } }));
        }
        else {
          if (response.data.comment_id) {
            let attachments = [];

            if (files) {
              Array.from(files).map((file) => {
                attachments.push({
                  file_url: URL.createObjectURL(file),
                  file_name: file.name
                })
              });
            }
            commentCount.current = benchmarkComments.length + 1;
            setBenchmarkComments(benchmarkComments.concat([{ content: comment, full_name, contact_id, staffid: staff_id, profile_url: contact_image, id: response.data.comment_id, attachments, is_allowed_to_edit: 1 }]))
          }
        }
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  const deleteComment = async (id) => {
    try {
      const response = await DocumentsServices.removeComment(rel_id, rel_type, id);
      if (response) {
        setBenchmarkComments(benchmarkComments.filter((w) => w.id != id));
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  const downloadPDP = async(docType) => {
    try {
      const response = await BenchmarkServices.downloadPDP(benchmark.id, benchmark.project_id, docType);
      if(response.status)
      {
        window.open(response.data.url, "_blank");
      }
      else {
        showError("l_something_went_wrong");
      }
    }
    catch(e)
    {
      console.error(e);
    }
  }

  let activeBenchmarkActions = [
    {
      actionName: _l("l_edit"),
      actionHandler: () => {
        handleScheduleEdit();
      },
      actionIcon: "PencilSimpleLineIcon",
    },
    {
      actionName: benchmark.is_schedule == 0 ? _l("l_stop_benchmark") : _l("l_stop_schedule"),
      actionHandler: () => { handleStopBenchmark(benchmark) },
      actionIcon: "ProhibitIcon",
    },
    {
      actionName: _l("l_delete"),
      actionHandler: () => { handleDeleteBenchmark(benchmark.id) },
      actionIcon: "TrashIcon",
    },
  ];

  let draftBenchmarkActions = [
    {
      actionName: _l("l_edit"),
      actionHandler: () => {
        handleScheduleEdit();
      },
      actionIcon: "PencilSimpleLineIcon",
    },
    {
      actionName: _l("l_delete"),
      actionHandler: () => { handleDeleteBenchmark(benchmark.id) },
      actionIcon: "TrashIcon",
    },
  ];

  // if(benchmark.is_opened > 0 && benchmark.is_schedule == 0)
  {
    activeBenchmarkActions = activeBenchmarkActions.concat([
      {
        actionName: _l("l_download_pdp"),
        actionHandler: () => { downloadPDP(1) },
        actionIcon: "DownloadSimpleIcon",
      },
      {
        actionName: _l("l_download_bpu"),
        actionHandler: () => { downloadPDP(2) },
        actionIcon: "DownloadSimpleIcon",
      },
    ]);
    draftBenchmarkActions = draftBenchmarkActions.concat([
      {
        actionName: _l("l_download_pdp"),
        actionHandler: () => { downloadPDP(1) },
        actionIcon: "DownloadSimpleIcon",
      },
      {
        actionName: _l("l_download_bpu"),
        actionHandler: () => { downloadPDP(2) },
        actionIcon: "DownloadSimpleIcon",
      },
    ]);
  }

  const getCardTitle = () => {
    let title = "";
    if(fromChat)
    {
      title = benchmark.title;
    }
    else {
      if(benchmark.parent_id > 0)
      {
        title = `#${benchmark.parent_id}`;
      }
      else {
        title = `#${benchmark.id}`;
      }

      if(benchmark.list_order)
      {
        title += `-${benchmark.list_order}`;
      }
      
      if(benchmark.category)
      {
        title += `-${benchmark.category}`;
      }

      if(benchmark.parent_detail)
      {
        title += ` ${benchmark.parent_detail ? "("+_l(`l_created_from_${benchmark.parent_detail}`)+")" : "" }`;
      }
    }
    return title;
  };

  return (
    <div className={`${pageName == "proposal-details" ?  "chat-box d-flex" :fromChat && !fromReply? "d-flex chat-box with-padding width-custom" : "" }`}> 
       {fromChat && !fromReply ? <div className="h30w30 comman-image-box with-bg rounded-circle bg-white-05 mt-auto">
          <div
            className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
            style={{
              backgroundImage: `url('${ isSolarCard ? USR_IMG_PLACEHOLDER : "https://myrpp.myr.ai/orb/uploads/chat_documents/small_myr-ai-logo-object.png"}')`,
            }}
          ></div>
          </div> : <></>}
      <div className={`task-list-card list-view schedule-card-main document-list-card overflow-visible ${active ? "active" : ""} ${pageName == "proposal-details" ? "p-2 px-0 shadow-none" : fromChat && !fromReply? "p-2 ml-10" : ""} ${fromReply ? "p-0" : ""}`} 
      id={`benchmark_${benchmark.id}`}
     >
        <div className={`task-list-wrapper cursor-pointer ${fromReply ? "base-body-color p-2" : ""}`}
         onClick={()=>{
          if(fromChat){
            // dispatch(setFetchedTaskandMessages([]));
            // dispatch(setClearmessage());
            // dispatch(updateTaskList([]))
            if(isSolarHubPlatform()){
              localStorage.setItem("proposal_id",proposal_id);
              CardListFullHeight();
              hideSolairHubleftPanel();
              if (userType == "contact") {
                navigate(`/proposal-details?benchmark_id=${benchmark.id}&project_id=${benchmark.project_id}&proposal_id=${benchmark.proposalId}`)
              }else{
                navigate(`/proposal-details?benchmark_id=${benchmark.id}&project_id=${benchmark.project_id}`)
              }
            }else{
              navigate(benchmark.link)
            }
          }
        }}
        >
          <div className="d-flex w-100 align-items-start">
            <div className="d-flex task-list-detail align-items-start">
              <div className={`task-left flex-grow-1 width100minus160 ${ fromChat ? "flex-wrap" : ""} ${fromChat && !fromReply? "pe-none" : ""}`}>
                <div
                  id={`benchmark_card_${benchmark.id}`}
                  className="d-flex align-items-center w-100 task-left-part-toggle text-truncate"
                  onClick={() => {
                      dispatch(getFloorDetails(benchmark.project_id));
                      handleBenchmarkModal(
                        benchmark
                      );
                      dispatch(setCreateScheduledetails({}));
                      LeftPanelResponsiveToggle();    
                  }}
                >
                  <div className="task-top-part text-truncate flex-grow-1 d-flex main-calc-width-task">
                    <div className="d-flex align-items-center w-100">
                      <div
                        className="task-creator-img"
                        data-tip={createrName}
                        data-place="right"
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                      >
                        <div className={`comman-image-box  rounded-circle ${pageName == "proposal-details" ? "h25w25" : "h32w32"}`}>
                          <div
                            className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                            style={{ backgroundImage: `url('${fromChat ? isSolarHubPlatform() ? LeftPanelLogoSolar : LeftPanelLogoMyr :createrImage}')` }}
                          ></div>
                        </div>
                      </div>
                      <a 
                        className={`task-list-name text-truncate ${pageName == "proposal-details" ? "" : "ms-2" }`}
                        data-tip={
                          fromChat ? benchmark.title : getScheduleTitle(benchmark)
                        }
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                        data-place="top"
                      >
                        <span className=" ps-0 text-truncate">
                          {getCardTitle()}
                        </span>
                        {
                          benchmark.proposal_id > 0
                          ?
                            <div className="c-font color-white-60 f-11 lh-1 location m-1 fw-normal ">
                              <span className={` p-0 `}>
                                {benchmark.company_name} - {benchmark.address}
                              </span>
                            </div>
                          :
                          <React.Fragment></React.Fragment>
                        }
                        {isSolarCard  && <div className="c-font color-white-60 f-10  location mt-1 fw-normal "><span class=" p-0 ">{benchmark.address}</span></div>}
                      </a>
                    </div>
                  </div>
                </div>
                <div className={`d-flex align-items-center ${pageName == "proposal-details" ? "flex-wrap pt-10px"  : fromChat ?" mt-2" : ""}`}>
                  {
                    benchmark.is_schedule == 0 && benchmark.proposal_amount > 0 ?
                      <div
                        className="comman_action_icon with_separator_10 card-view-less-padding d-flex pe-0 before-dnone"
                        data-tip={_l("l_proposal") + " " + _l("l_amount") + ": €" + `${benchmark.proposal_amount}`}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                      >
                        <div className="badge with-min-max-width c-text normal_pill px-2 radius_3 text-truncate green-text fw-semibold">
                          €{benchmark.proposal_amount}
                        </div>
                      </div>
                      :
                      <></>
                  }
                  {isSchedule == 0 && userType != "operator" && Number(benchmark.proposal_count) > 0 ? (
                      <div
                        className="category-wrapper with_separator_10 card-view-less-padding with-min-width125 "
                        data-tip={`${_l("l_total_proposal")}: ${benchmark.proposal_count}`}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                      >
                        <div className="action_icon d-flex align-items-center justify-content-end">
                          <span className="c-font color-white-80 text-nowrap f-12">
                            <span className="total-text">  {_l("l_total")}</span>
                            <span>  {_l("l_proposal")}</span>

                          </span>
                          <span className="c-font color-white-80 f-12 fw-semibold title-fonts ps-1">
                            {benchmark.proposal_count}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )
                  }
                  <div
                    className={`category-wrapper with_separator_10 card-view-less-padding with-min-width pe-0 ${benchmark.is_schedule > 0 && benchmark.total_task > 0 ? '' : 'd-none'}`}
                    data-tip={`Total task: ${benchmark.total_task}`}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main"
                  >
                    <div className="action_icon d-flex align-items-center justify-content-end">
                      <span className="c-font color-white-80 text-nowrap f-12">
                        <span className="total-text">  {_l("l_total")}</span>
                        <span>  {_l("l_tasks")}</span>
                      </span>
                      <span className="c-font color-white-80 f-12 fw-semibold title-fonts ps-1">
                        {benchmark.total_task}
                      </span>
                    </div>
                  </div>
                  <div
                    className="category-wrapper with_separator_10 card-view-less-padding with-min-width before-dnone pe-0"
                    data-tip={isSolarCard ? _l("l_solar_panels") + ":" + `${benchmark.number_of_plates}`  :`Total Items: ${benchmark.item_count}`}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main"
                  >
                    <div className="action_icon d-flex align-items-center justify-content-end">
                      <span className="c-font color-white-80 text-nowrap f-12">
                       {isSolarCard ? <span className="total-text"> { _l("l_solar_panels")} </span> 
                       :<> <span className="total-text">  {_l("l_number_of")}</span>
                        <span>  {_l("l_items")}</span> </>}
                      </span>
                      <span className="c-font color-white-80 f-12 fw-semibold title-fonts ps-1">
                        {isSolarCard ? benchmark.number_of_plates : benchmark.item_count ? benchmark.item_count : 0}
                      </span>
                    </div>
                  </div>
                {fromChat ? <></> 
                : <div className="with_separator_10 before-dnone pe-0">
                  <div
                    className="task-mid-part w-460 d-flex res-flex-wrap-nowrap align-items-center  for-content-between comman_action_icon"
                    data-tip={`${_l("l_status")}`}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main"
                  >
                    {
                      pageName == "Proposals"
                      ?
                        <React.Fragment></React.Fragment>
                      :
                        <div className="d-flex align-items-center justify-content-end res-space-start res-width-100">
                          <div className="pill-min-width pill-bottom-show  text-end">
                            <div className="d-flex align-items-center">
                              <div className="badge  badge-white rounded-pill fw-light h32wauto c-font f-12 d-flex align-items-center ">
                                <span className={`${statusClass.current} p-0`}>
                                  {getBenchmarkStatusText(benchmark)}
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* <div className="dropdown with_separator_10 d-flex align-items-center card-view-less-padding before-block  before-dnone">
                                              <div className="comman_action_icon w-100 d-flex c-font f-12 color-green">Activated</div></div> */}
                        </div>
                      }
                  </div>
                  </div>}
                  <div className={`d-flex align-items-center ${pageName == "proposal-details" ? "pt-1" : ""}`}>
                    {
                      benchmark.company_name
                      ?
                      <div className="d-flex align-items-center justify-content-end with_separator_10 before-dnone">
                        <div className="position-relative assignee-list-wrapper card-view-less-padding">
                          <div className="multi_inner_wrapper with-min-width d-flex with_minus">
                            <a href="#/"
                              data-bs-toggle="tooltip"
                              className="comman-image-box  h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                              data-tip={benchmark.company_name}
                              data-place="right"
                              data-effect="solid"
                              data-delay-show='1000'
                              data-class="tooltip-main"
                            >
                              <div
                                className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                style={{
                                  backgroundImage: `url('${benchmark.company_logo}')`,
                                }}
                              ></div>
                            </a>
                          </div>
                        </div>
                      </div>
                      :
                      <React.Fragment></React.Fragment>
                    }
                    <div className="task-right-static-options ">
                      <div className="d-flex align-items-center">
                      {
                            assignees.length >= 1 ?

                            <div className="with_separator_10 pe-0 before-dnone">
                              <div className="d-flex align-items-center justify-content-end ">
                                  <div className="position-relative assignee-list-wrapper card-view-less-padding">
                                    <div className="multi_inner_wrapper with-min-width d-flex with_minus">
                                      { assignees.map((assignee, index) => {
                                          if (index < 1)
                                            return (
                                              <a href="#/"
                                                data-bs-toggle="tooltip"
                                                className="comman-image-box  h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                                                data-tip={assignee.full_name}
                                                data-place="right"
                                                data-effect="solid"
                                                data-delay-show='1000'
                                                data-class="tooltip-main"
                                              >
                                                <div
                                                  className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                                  style={{
                                                    backgroundImage: `url('${assignee.profile_image}')`,
                                                  }}
                                                ></div>
                                              </a>
                                            );
                                        })}
                                      {assignees.length > 1 ? (
                                        <a href="#/"
                                          className="comman-image-box h32w32 with_overlay d-flex rounded-circle"
                                          // id="TaskAssigneeDropdown"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          data-tip={`${_l("l_view_all")}`}
                                          data-effect="solid"
                                          data-delay-show='1000'
                                          data-class="tooltip-main"
                                          onClick={handleTaskAssigneeData}
                                        >
                                          <div className="unread_count m-auto c-font f-12">
                                            +{assignees.length - 1}
                                          </div>
                                        </a>
                                      ) : (
                                        <></>
                                      )}
                                      <GeneralListing
                                        handler={() => { }}
                                        list={assignees.map((item, index) => {
                                          return {
                                            name: item.full_name || item.name,
                                            image:
                                              item.user_image || item.profile_image,
                                            id: index,
                                          };
                                        })}
                                      />
                                    </div>
                                  </div>
                                  <div className="comman_action_icon with_separator_10 card-view-none d-none pe-0">
                                    <div className="d-flex align-items-center">
                                      <div className="dropup">
                                        <div className="">
                                          <div className="action_icon with_bg on-hover-active-toggle-img">
                                            <UserCircleGear size={18} weight="light" className="c-icons" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            :
                            <React.Fragment></React.Fragment>
                      }
                        <div className={`comman_action_icon custom-datepicker w100px list-toggle-view-box ms-10px ${isSolarCard ? "h32wauto" : "withinputabsolute"}`}>
                          <a href="#/" className={`action_icon with_bg on-hover-active-toggle-img light-theme-img c-font f-11 position-relative ${isSolarCard ? "w-100 h-100" : "h32w32"}`}
                          data-tip={
                            startDate != null &&
                              startDate != "" &&
                              startDate != "0000-00-00"
                              ? _l("l_start_date")+':' +
                              moment(startDate).format("DD/MM/YYYY")
                              : _l("l_start_date")
                          }
                          // data-place="right"
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main">
                            {/* <div
                              className="d-flex align-items-center "
                              
                            > */}
                              <div className="d-flex ms-1">
                                <CalendarBlank size={18} weight="light" className="c-icons" />
                              </div>
                              <DatePicker
                                showTimeSelect
                                dateFormat="dd/MM/yyyy"
                                selected={startDate}
                                readOnly={true}
                                onChange={(date) => {
                                  // if(benchmark.status_text === "future") 
                                  // {
                                  //   updateBenchmark(benchmark.id, date);
                                  //     setStartDate(date);
                                  // } else {
                                    setStartDate(date);
                                  // }
                                }}
                                placeholderText={`${_l("l_select_date_and_time")}`}
                                calendarStartDay={1}
                              >
                                {/* <div className="datepicker-label">{_l("l_select_date_and_time")}</div> */}
                              </DatePicker>
                            {/* </div> */}
                          </a>
                        </div>
                        <div
                          className="comman_action_icon with_separator_10 card-view-less-padding d-flex h32wauto before-dnone pe-0"
                          data-tip={`${_l("l_budget")}: ${isSolarCard ? benchmark.max_budget : benchmark.max_budget}`}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                        >
                          <div className="badge with-min-max-width c-text normal_pill c-font f-12 px-2 radius_3 text-truncate green-text fw-semibold d-flex align-items-center ">
                            {isSolarCard ? benchmark.max_budget : benchmark.max_budget}
                          </div>
                        </div>
                       {isSolarCard ? <></> :
                        <UncontrolledDropdown className="dropdown with_separator_10 d-flex align-items-center card-view-less-padding before-dnone pe-0"
                          data-tip={_l("l_comments")}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                        >
                          <DropdownToggle tag={'a'}
                            key={benchmark.id}
                            className="comman_action_icon  d-flex"
                            type="button"
                            id={`minified_comment_${benchmark.id}`}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-bs-auto-close="outside"
                            onClick={getScheduleComments}
                          >
                            <div className="d-flex align-items-center w-100">
                              <div className="action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img position-relative">
                                {
                                  unreadCount.current
                                    ?
                                    <span className=" rounded-pill badge-custom  d-flex p-2 light-pill ">{unreadCount.current}</span>
                                    :
                                    <React.Fragment></React.Fragment>
                                }
                                <ChatCircle size={18} className="c-icons" weight="light" />
                              </div>
                              <span
                                className={`c-font f-12 d-flex justify-content-center flex-grow-1 text-on-hover-active-green number-counter-toggle`}
                                data-cmt-cnt-id={benchmark.id}
                              >
                                {commentCount.current}
                              </span>
                            </div>
                          </DropdownToggle>
                          <ReactTooltip />
                          {
                            <CommentMinified
                              task={{docType: rel_type}}
                              unContolList={true}
                              id={benchmark.id}
                              loading={commentsLoader}
                              comments={benchmarkComments}
                              mentionList={mentionsList.map((name) => {
                                let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                                return {
                                  display: name.name,
                                  id: id,
                                  image: name.profile_image || "",
                                  isteam: name.is_team ? 1 : 0,
                                };
                              })}
                              handleAddComment={handleAddComment}
                              disabled={benchmark.proposal_id && benchmark.proposal_status == 2}
                              showComments={showComments}
                            />
                          }
                          {
                            aiChatAssistURL
                            ?
                            <div className="d-flex align-items-center justify-content-end table-list-attechment w_172 w-auto">
                              <div
                                className="with_separator_10 d-flex align-items-center card-view-less-padding before-dnone pe-0"
                                data-tip={_l("l_support")}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                              >
                                <a href="#/"
                                  className="comman_action_icon w-100 d-flex"
                                  type="button"
                                  onClick={() => navigate(aiChatAssistURL)}
                                >
                                  <div className="d-flex align-items-center w-100">
                                    <div className="action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img">
                                      <ChatDots
                                        size={18}
                                        className="c-icons"
                                        weight="light"
                                      />
                                    </div>
                                  </div>
                                </a>
                                <ReactTooltip />
                              </div>
                            </div>
                            :
                            <React.Fragment></React.Fragment>
                          }
                        </UncontrolledDropdown>}
                        {fromChat ? 
                          <div className="with_separator_10 before-dnone pe-0">
                            <div className="badge badge-white rounded-pill fw-light pill-task-active p-2 max-width75 text-truncate ">
                              <div className="d-flex align-items-center text-truncate">
                                <div className={`text-truncate ${isSolarCard ? "color-greenpill fw-bold f-13" : ""}`}>{isSolarCard ? "€" + benchmark.operator_amount : benchmark.is_schedule == 1 ? _l("l_schedule") : _l("l_benchmark")}</div>
                              </div>
                            </div>
                          </div> :<></>}
                        { isSolarCard ? <></> :
                         <div
                          className="dropdown with_separator_10 before-dnone pe-0 d-flex align-items-center card-view-less-padding me-10px"
                          data-tip={benchmark.recursive === "1" ? _l("l_recurrent") : _l("l_onetime")}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                        >
                          <div
                            className="comman_action_icon w-100 d-flex"
                          // type="button"
                          >
                            <div className="d-flex align-items-center w-100">
                              <div className="h32w32 d-flex position-relative  light-theme-img">
                                <Repeat size={18} weight="light" className="c-icons" />
                              </div>
                              <span className="c-font f-12 opacity-50 d-flex justify-content-center flex-grow-1 ps-0 pe-1  text-nowrap display-none-card-view">
                                {benchmark.recursive === "1"
                                  ? _l("l_recurrent")
                                  : _l("l_onetime")}
                              </span>
                            </div>
                          </div>
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="task-assign-section comman_action_icon">
              {fromChat ? <></> :
                <div className={`d-flex align-items-center justify-content-end ${(benchmark.is_allowed_to_edit == 1) || window.location.pathname == '/schedule' || window.location.pathname == '/benchmark' ? '' : 'for-disabled opacity-50'}`}>
                  <div className={`dropdown action_icon with_bg h32w32 d-flex align-items-center justify-content-center`}>
                    <a href="#/" type="button" className="line-height-0 "
                      id="ActiveOfficeOptionsDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-tip={_l("l_options")}
                      data-effect="solid"
                      data-delay-show='1000'
                      data-class="tooltip-main">
                      <DotsThreeVertical size={18} weight="light" className="c-icons" />
                    </a>
                    {/* <benchmarkActionDropdown benchmark_id={benchmark.id} /> */}
                    <ActionDropdown
                      benchmark_id = {benchmark.id}
                      actions={
                        benchmark.status == 3 || benchmark.status == 4 ?
                          activeBenchmarkActions
                          :
                          draftBenchmarkActions
                      }
                    />
                  </div>
                </div>
              }
           
              </div>
              
            </div>


          </div>
        </div>
        { fromChat && !fromReply ? <UncontrolledDropdown
                  setActiveFromChild={true}
                  isOpen={false}
                  direction="end" 
                  className={`dropdown dropdown-center chat-action-option dropup ${pageName == "proposal-details" ? "rightminus10" :""}`}
                >
              <DropdownToggle
                id={`taskOptions`} 
                type="button"
                className={`border-0 line-height-0  p-0 d-flex align-items-center bg-transparent`}
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
                data-tip={`${_l("l_options")}`}
                data-effect="solid"
                data-delay-show='1000'
                data-class="tooltip-main"
                data-place="left"                                        
              >
                    <DotsThreeVertical
                      size={16}
                      weight="light"
                      className="c-icons"
                    />
              </DropdownToggle>
                  <DeleteDropdown
                    edit={false}
                    widthClass="w-140"
                    pageName="schedule"
                    initReply={initReply}
                    newEditId={msgid}
                    allowToDelete={false}
                    // unreadMessage={true}
                    // setUnreadMessage={setUnreadMessage}
                  />
        </UncontrolledDropdown> : <></>
        }
      </div>
      <ReactTooltip />
    </div>
  );
};

export default ScheduleCard;
