import React from "react";

const ChatSupportDropdown = ({ customerAdmins }) => {
  return (
    <>
      <ul className="dropdown-menu w-200 overflowscroll chat-dropdown">
        {customerAdmins.map((customerAdmin, index) => {
          return (
            <li className="hr_sepretr" key={index} id={`support_staff_${customerAdmin.staffid}`}>
              <a href="#/" className="dropdown-item">
                <div className="d-flex align-items-center text-truncate ">
                  <div className="c-list-icon">
                    <div className="h30w30 comman-round-box with-bg d-flex align-items-center bg-white-05  p-5px rounded-circle">
                      <div
                        className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                        style={{
                          backgroundImage: `url('${customerAdmin.profile_url}')`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="ps-2 w100minus20 text_wrap">
                    <div className="c-list-detail text-truncate">
                      {`${customerAdmin.firstname}  ${customerAdmin.lastname}`}
                    </div>
                  </div>
                </div>
              </a>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default ChatSupportDropdown;
