import React, { useEffect, useRef } from 'react'
import { Form, Row } from 'react-bootstrap';
import { _l, getDatePickerLocale, getPreviewFromURL } from '../../hooks/utilities';
import { CalendarBlank, CloudArrowUp, X } from 'phosphor-react';
import DatePicker from "react-datepicker";
import ScheduleSetup from '../Modals/OnboardingReusableComponents/ScheduleSetup';
import moment from 'moment';

const OperatorForm = ({
    formData,
    setFormData,
    contract,
    setContract,
    contractAttachments,
    setContractAttachments,
  }) => {
    const addressRef = useRef();
    const autoCompleteRef = useRef();
  
    const addressOptions = {
      // componentRestrictions: { country: "fr" },
      fields: ["address_components", "geometry", "icon", "name"],
      types: ["address"],
    };
  
    const handleContractUpload = (e) => {
      if (contract && Array.from(contract).length) {
        setContract(Array.from(contract).concat(e.target.files));
        setContractAttachments(
          contractAttachments.concat(
            Array.from(e.target.files).map((w) => {
              return { name: w.name, type: w.type, url: URL.createObjectURL(w) };
            })
          )
        );
      } else {
        setContract(e.target.files);
        setContractAttachments(
          Array.from(e.target.files).map((w) => {
            return { name: w.name, type: w.type, url: URL.createObjectURL(w) };
          })
        );
      }
    };
  
    const removeAttachment = (attachment) => {
      setContractAttachments(
        contractAttachments.filter((w) => w.name != attachment.name)
      );
      setContract(Array.from(contract).filter((w) => w.name != attachment.name));
    };
  
    const updateSlots = (slots) => {
      setFormData({ ...formData, slots });
    };
  
    const disableServiceEndData = (checked) => {
      setFormData({ ...formData, unknown_end_date: checked ? 1 : 0 });
    };
  
    useEffect(() => {
      if (
        formData.mission_address &&
        formData.mission_address.trim().length &&
        addressRef.current
      ) {
        try {
          autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            addressRef.current,
            addressOptions
          ).addListener("place_changed", () => {
            setFormData({
              ...formData,
              mission_address: addressRef.current.value,
            });
          });
        } catch (e) {
          console.log(e);
        }
      }
    }, [formData.mission_address]);
  
    return (
      <Row>
        <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
          <Form.Label className="input-label no-asterisk no-asterisk">
            {_l("l_mission_address")}
          </Form.Label>
          <input
            className="form-control "
            placeholder={_l("l_address_placeholder")}
            type="text"
            name="address"
            ref={addressRef}
            value={formData.mission_address}
            onChange={(e) =>
              setFormData({ ...formData, mission_address: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group className="col-6 c-input-box pb-4 position-relative">
          <Form.Label className="input-label no-asterisk">
            {_l("l_service_start_date")}
          </Form.Label>
          <div className="custom-datepicker">
            <div className="d-flex align-items-center form-control p-15 color-white-60 dropdown-center dropdown comman_action_icon">
            <div className="h32w32 action_icon with_bg d-flex align-items-center justify-content-center me-10px">
              <CalendarBlank
                size={18}
                weight="light"
                className="c-icons c-icons m-0 "
              />
              </div>
              <DatePicker
                placeholderText={`${_l("l_service_start_date")}`}
                calendarStartDay={1}
                selected={new Date(formData.service_start_date)}
                locale={getDatePickerLocale()}
                disabledKeyboardNavigation
                dateFormat="dd/MM/yyyy"
                selectsStart
                defaultMenuIsOpen
                minDate={new Date()}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                onChange={(date) =>
                  setFormData({
                    ...formData,
                    service_start_date: moment(date).format("YYYY-MM-DD"),
                  })
                }
              >
                <div className="datepicker-label">
                  {_l("l_select_date_and_time")}
                </div>
              </DatePicker>
            </div>
          </div>
        </Form.Group>
        <Form.Group className="col-6 c-input-box pb-4 position-relative">
          <Form.Label className="input-label no-asterisk">
            {_l("l_service_end_date")}
          </Form.Label>
          <div className="custom-datepicker">
            <div className="d-flex align-items-center form-control p-15 color-white-60 dropdown-center dropdown comman_action_icon">
            <div className="h32w32 action_icon with_bg d-flex align-items-center justify-content-center me-10px">
              <CalendarBlank
                size={18}
                weight="light"
                className="c-icons c-icons m-0 "
              />
              </div>
              <DatePicker
                placeholderText={`${_l("l_service_end_date")}`}
                calendarStartDay={1}
                locale={getDatePickerLocale()}
                disabledKeyboardNavigation
                dateFormat="dd/MM/yyyy"
                readOnly={formData.unknown_end_date == 1}
                selectsStart
                defaultMenuIsOpen
                selected={
                  formData.unknown_end_date == 1
                    ? ""
                    : new Date(formData.service_end_date)
                }
                minDate={new Date(formData.service_start_date)}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                onChange={(date) =>
                  setFormData({
                    ...formData,
                    service_end_date: moment(date).format("YYYY-MM-DD"),
                  })
                }
              >
                <div className="datepicker-label">
                  {_l("l_select_date_and_time")}
                </div>
              </DatePicker>
            </div>
          </div>
          <Form.Check
            type="checkbox"
            className="mt-2"
            label={_l("l_disable_end_date")}
            onChange={(e) => disableServiceEndData(e.target.checked)}
          />
        </Form.Group>
        <ScheduleSetup slots={formData.slots} setSlots={updateSlots} />
        <Form.Group className="col-12 c-input-box py-4 pt-3 position-relative ">
          <Form.Label className="input-label no-asterisk">
            {`${_l("l_document_upload")}`}
          </Form.Label>
          <div className="form-control file-upload with-bg position-relative p-0 flex-grow-1">
            <div className="d-flex align-items-center justify-content-between p-15">
              <input
                className="z-index-3 hidden-input"
                type="file"
                onChange={handleContractUpload}
                multiple
              />
              <div className="m-auto">
                <CloudArrowUp size={18} className="c-icons" weight="light" />
                <span className="color-white-60 ps-2">
                  {_l("l_click_to_upload")}
                </span>
              </div>
            </div>
          </div>
          <div className="upload-image-preview pt-3 d-flex flex-wrap">
            <div className="d-flex">
              {contractAttachments.map((attachment, key) => {
                let preview = {};
                preview.url = getPreviewFromURL(attachment.name);
                return (
                  <div className="h60w60 me-2" key={key}>
                    <div className="comman-image-box h40w40 radius_3 upload-image with-margin">
                      {typeof preview.url === "string" ? (
                        <div
                          className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 cursor-pointer"
                          style={{ backgroundImage: `url(${attachment.url})` }}
                          data-tip={_l("l_preview_attachment")}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                        ></div>
                      ) : (
                        <div
                          className="h-100 w-100 d-flex cursor-pointer"
                          style={{
                            alignContent: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <preview.url
                            size={72}
                            weight="light"
                            className="c-icons"
                          />
                        </div>
                      )}
                      <a href="#/"
                        className="upload-close"
                        onClick={() => removeAttachment(attachment)}
                      >
                        <X size={10} weight="light" className="c-icons" />
                      </a>
                    </div>
                    <div className="c-font color-white-60 f-12 text-truncate">
                      {attachment.name}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Form.Group>
        <Form.Group className="col-12 c-input-box pb-4 position-relative">
          <Form.Label className="input-label no-asterisk">
            {_l("l_details")}
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            onChange={(e) => {
              setFormData({ ...formData, contract_description: e.target.value });
            }}
            value={formData.contract_description}
            placeholder={_l("l_details")}
          ></Form.Control>
        </Form.Group>
      </Row>
    );
  };

export default OperatorForm