import React, { useEffect, useState } from "react";
import {
  _l,
  getFileType,
  getPreviewFromURL,
  showError,
} from "../../../hooks/utilities";
import {
  CalendarBlank,
  CaretDown,
  CloudArrowUp,
  FilePdf,
  FileText,
  MapPin,
  UserCircleGear,
  X,
} from "phosphor-react";
import ComingSoon from "../../../assets/images/yellowbg.svg";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import customerServices from "../../../services/customer-services";
import { useDispatch, useSelector } from "react-redux";
import Lightbox from "yet-another-react-lightbox";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";
import { setTaskData, updateInstallationList } from "../../../actions/customer";
import ReactTooltip from "react-tooltip";
import TaskListStatusDropdown from "../../Dropdowns/TaskListStatusDropdown";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import InstallationAssigneeDropDown from "../../Dropdowns/InstallationAssigneeDropDown";
import CommanOffCanvas from "../../Offcanvas/CommanOffCanvas";
import Spinner from "react-bootstrap/Spinner";
import PosesSkeleton from "../../Skeleton/PosesSkeleton";
import NoMapPlaceholder from "../../../assets/images/NoMapPlaceholder.svg";
import PdfIcon from "../../../assets/images/proposal_contract.png";
import SolarDocumentPreview from "../SolarDocumentPreview";

let taskStatus;
let task;

const InstallationList = ({showSckeleton}) => {
  const { benchmarkList, installationProposalList, globalSearchString, installationFilter } = useSelector(
    (state) => state.customer
  );
  const STATUS_LIST = [
    {
      title: "Demande",
      value: 1,
    },
    {
      title: "Manque document",
      value: 2,
    },
    {
      title: "En cours",
      value: 3,
    },
    {
      title: "Complétée",
      value: 4,
    },
  ];
  const [startDate, setStartDate] = useState();
  const [selectinstallation, setSelectinstallation] = useState({})
  const [allAttachments, setAllAttachments] = useState([]);
  const [showTaskImage, setShowTaskImage] = useState(false);
  const [benchMarkListLocal, setbenchMarkListLocal] = useState([]);
  const [currentTab, setcurrentTab] = useState(1);
  const [employeeData, setEmployeeData] = useState([]);
  const [assigneeInstallationLoader, setAssigneeInstallationLoader] = useState(false);
  const [assigneeDropDwon, setAssigneeDropDwon] = useState(-1)
  const [showTaskCanvas, setShowTaskCanvas] = useState(false);
  const [taskDetail, setTaskDetail] = useState();
  const [tabCounts, setTabCounts] = useState({
    tab1: 0,
    tab2: 0,
    tab3: 0,
    tab4: 0,
  });
  const [showCommentLoader, setShowCommentLoader] = useState(-1)
  const [loadedImages, setLoadedImages] = useState({});
  const [openLightBox, setOpenLightBox] = useState(false);


  const dispatch = useDispatch();

  const setAttchmentOnTop = (task_attachments, id) => {
    const targetAttchement = task_attachments.filter(
      (attachment) => attachment.id === id
    );
    const otherAttachments = task_attachments.filter(
      (attachment) => attachment.id != id
    );
    setAllAttachments(targetAttchement.concat(otherAttachments));
    setShowTaskImage(true);
  };
  const filterProjects = (tab) => {
    return installationProposalList.filter((item) => {
      const tasks = item.solar_task_ids;

      if (tab === 1) {
        return tasks.every((task) => !task.startdate);
      } else if (tab === 2) {
        return tasks.some(
          (task) => task.startdate && task.task_attachments && task.task_attachments.length === 0 
        );
      } else if (tab === 3) {
        return tasks.every(
          (task) => task.task_attachments && task.task_attachments.length > 0 && task.startdate && task.status != 5
        );
      }else if (tab === 4) {
        return tasks.every(
          (task) => task.status == 5
        );
      }

      return false;
    });
  };

  const handleChangeTab = (tab) => {
    setcurrentTab(tab);

    switch (tab) {
      case 1:
        setbenchMarkListLocal(filterProjects(1));
        break;
      case 2:
        setbenchMarkListLocal(filterProjects(2));
        break;
      case 3:
        setbenchMarkListLocal(filterProjects(3));
        break;
      case 4:
        setbenchMarkListLocal(filterProjects(4));
        break;

      default:
        break;
    }
  };

  const handleDocumentUpload = (files, task, benchmarkid) => {
    setShowCommentLoader(task.id)
    try {
    customerServices
      .addLinkComment(
        task.id,
        0,
        "",
        "",
        0,
        0,
        0,
        files,
        0,
        "",
        0,
        0,
        "",
        "",
        0,
        false,
        1
      )
      .then((res) => {
        if (res && res.status && res.data.files_list && res.data.files_list.length) {
          let tempBenchmarkList = installationProposalList;
          let benchmarkIndex = -1;
          benchmarkIndex = tempBenchmarkList.findIndex(
            (benchmark) => benchmark.id === benchmarkid
          );
          if (benchmarkIndex > -1) {
            let taskIndex = -1;
            taskIndex = tempBenchmarkList[
              benchmarkIndex
            ].solar_task_ids.findIndex((t) => t.id == task.id);
            if (taskIndex > -1) {
              tempBenchmarkList[benchmarkIndex].solar_task_ids[
                taskIndex
              ].task_attachments = tempBenchmarkList[
                benchmarkIndex
              ].solar_task_ids[taskIndex].task_attachments.concat(
                res.data.files_list
              );
            }
          }
          dispatch(updateInstallationList(tempBenchmarkList));
          setShowCommentLoader(-1);
        
        }else{
          showError("l_something_went_wrong");
          setShowCommentLoader(-1);
        }
      });

    } catch (error) {
      console.log(error)
    }

  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'copy';
};

const handleDrop = (e,task, benchmarkid) => {
    e.preventDefault();
    e.stopPropagation();
    handleDocumentUpload(e.dataTransfer.files,task, benchmarkid)
};

  
    const task_updated = localStorage.getItem('task_updated');
    useEffect(() => {
        if(task_updated == "true"){
            handleChangeDate(selectinstallation.updateDate, selectinstallation.id, selectinstallation.solar_task_ids);
            localStorage.removeItem("task_updated");
        }else{
            setSelectinstallation({});
        }
    }, [task_updated])
    


  const handleChangeDate = (date, bid, tid) => {
    let tempBenchmarkList = installationProposalList;
    let benchmarkIndex = -1;
    benchmarkIndex = tempBenchmarkList.findIndex((b) => b.id === bid);
    if (benchmarkIndex > -1) {
      let taskIndex = -1;
      taskIndex = tempBenchmarkList[benchmarkIndex].solar_task_ids.findIndex(
        (t) => t.id == tid
      );
      if (taskIndex > -1) {
        tempBenchmarkList[benchmarkIndex].solar_task_ids[taskIndex].startdate =
          date;
        dispatch(updateInstallationList(tempBenchmarkList));
        setSelectinstallation({});
      }
    }
  };

  const changeTaskStatus = (statusId, task , benchmark) => {
    let status =  statusId == "task_convert_to_request" ? 1 : statusId


    switch (status) {
      case 1:
        changeStatus(statusId, task , benchmark)
        break;
      case 4:
        if (task.start_date && task.start_date != "") {
        changeStatus(statusId, task , benchmark)
        } else {
          showError("l_please_select_date")
        }
      case 5:
        if (task.task_attachments && task.task_attachments.length > 0) {
        changeStatus(statusId, task , benchmark)
        }else{
          showError("l_upload_additional_document")
        }
        break;
    
      default:
        break;
    } 
  }

  const changeStatus = (statusId, task , benchmark) =>{
    let status =  statusId == "task_convert_to_request" ? 1 : statusId

    customerServices.updateTaskStatus(
      task.id,
      benchmark.project_id,
      status,
      false,
      0,
      "",
      ""
    ).then((res)=>{
      if (res && res.status)  if (res && res.status) {
        let tempBenchmarkList = installationProposalList
        let benchmarkIndex = -1
        benchmarkIndex = tempBenchmarkList.findIndex((b)=> b.id === benchmark.id);
        if (benchmarkIndex > -1) {
          let taskIndex = -1
          taskIndex = tempBenchmarkList[benchmarkIndex].solar_task_ids.findIndex((t)=> t.id == task.id);
          if (taskIndex > -1) {
            tempBenchmarkList[benchmarkIndex].solar_task_ids[taskIndex].status = status
          }
          
        }
        dispatch(updateInstallationList(tempBenchmarkList))
        
      }
    });
  }

  const employeeAssigneeInstall = (banchmarck, task, remove = '') => {
    try {
      setAssigneeInstallationLoader(true)
      let category = remove == "remove" 
                      ? [] 
                      : [
                          {
                            "filter_key": task.staff_id,
                            "main_sub_key": "people"
                          }
                        ]
      let assignee = [
          {
            "main_key": "my_operator",
            "categories": category
          }
        ]
        let taskId = banchmarck.solar_task_ids[0].id
        customerServices.installationAssignee(banchmarck.project_id, taskId, assignee).then((res) => {
            if (res && res.status) {
                let tempBenchmarkList = installationProposalList;
                let benchmarkIndex = -1;
                benchmarkIndex = tempBenchmarkList.findIndex((b) => b.id === banchmarck.id);
                if (benchmarkIndex > -1) {
                let taskIndex = -1;
                taskIndex = tempBenchmarkList[benchmarkIndex].solar_task_ids.findIndex(
                    (t) => t.id == taskId
                );
                if (taskIndex > -1) {
                    if (remove == "remove") {
                      tempBenchmarkList[benchmarkIndex].solar_task_ids[taskIndex].assignee_id = '';
                    }else{
                      tempBenchmarkList[benchmarkIndex].solar_task_ids[taskIndex].assignee_id = task.staff_id;
                    }
                    dispatch(updateInstallationList(tempBenchmarkList));
                }
                }
            }
        })
    } catch (error) {
      console.error(error);
    }finally{
      setAssigneeInstallationLoader(false);
    }
  }

  const showTaskDetails = (task) => {
    customerServices.getTaskData(0,task.id).then((res)=>{
      if (res && res.status) {
        setTaskDetail(res.data);
        setShowTaskCanvas(true);
      }
    })
  }

  const handleImageLoad = (index) => {
    setLoadedImages((prevState) => ({ ...prevState, [index]: true }));
  };

  // Handle image error event (optional, for broken images)
  const handleImageError = (index) => {
    setLoadedImages((prevState) => ({ ...prevState, [index]: true }));
  };

  useEffect(() => {
    // removeModifiedClass();
    setbenchMarkListLocal(filterProjects(currentTab));
    setTabCounts({
      tab1: filterProjects(1).length,
      tab2: filterProjects(2).length,
      tab3: filterProjects(3).length,
      tab4: filterProjects(4).length,
    });
  }, [JSON.stringify(installationProposalList)]);

  useEffect(() => {
    if (globalSearchString) {
      setbenchMarkListLocal(benchMarkListLocal.filter((benchmark)=>benchmark.company_name.toLocaleLowerCase().includes(globalSearchString.toLocaleLowerCase()) || benchmark.address.toLocaleLowerCase().includes(globalSearchString.toLocaleLowerCase())))
    }else{
      setbenchMarkListLocal(filterProjects(currentTab));
    }
    
  }, [globalSearchString])

  useEffect(() => {
    if (installationFilter && installationFilter.length > 0) {
      let installationFilterFind = []
      let intialData = filterProjects(currentTab);
      intialData.forEach((benchmark)=> {
        if (installationFilter.includes(benchmark.solar_task_ids[0].assignee_id)) {
          installationFilterFind.push(benchmark);
        }
      })
      setbenchMarkListLocal(installationFilterFind);
    }else{
      setbenchMarkListLocal(filterProjects(currentTab));
    }

  }, [installationFilter, currentTab])
  useEffect(() => {
    try {
        customerServices.getCompanyEmployee(0, 1, 0).then((res) => {
            if (res.status) {
                setEmployeeData(res.data);
            }
        })
    } catch (error) {
        
    }
  }, [])

  return (
    <>
      {/* {showTaskImage && !openLightBox && (
        <Lightbox
          key={0}
          open={showTaskImage}
          close={() => setShowTaskImage(false)}
          slides={allAttachments.map((img) => {
            return { src: getPreviewFromURL(img.image_url) };
          })}
        />
      )} */}
      {openLightBox && allAttachments.length ? (
          <SolarDocumentPreview
            open={openLightBox}
            setOpen={() => {
              setShowTaskImage(false);
              setAllAttachments([]);
              setOpenLightBox(false);
            }}
            slides={allAttachments.map((item) => {
              return ({
                ...item, 
                type : item.file_type,
                src : item.image_url
              });
            })}
          />
        ) : (
          <></>
        )}
      <div className="flex-grow-1 d-flex flex-column position-relative h-100">
       
          <div className="registration-footer-content  flex-grow-1 overflow-hiiden-auto">
            <div className="position-sticky top-0  z-index-2 d-flex flex-column gap-3 white-shadow shadow-none pt-40px bgpatternCommanImg">
              {/* <div className=" d-flex justify-content-between flex-column flex-sm-row align-items-center">
                <a
                  href="#/"
                  className="btn btn-primary position-relative radius_5 mt-30px  propostion_btn resmargin15px onhoverimginv"
                >
                  <FileText size={14} className="c-icons" weight="light" />
                  <span class="ms-10px">Créer une demarche</span>
                </a>
                
              </div> */}
              <div className="align-items-center justify-content-between horizontaltab btn-white-05 bg-solar d-flex p-1 mb-20 radius_5 text-uppercase shadow-none z-index-6">
                {STATUS_LIST.map((status, index) => {
                  return (
                    <>
                      <label
                        className={`row-btn-label w-25 d-flex`}
                        for="administrativeradio1"
                      >
                        {/* <span
                                        class={`rounded-pill badge-custom  d-flex p-2 light-pill fw-semibold right0px`}
                                    >
                                        0
                                    </span> */}
                        <input
                          type="radio"
                          className="hidden-input"
                          name="proposal_status"
                          checked={status.value == currentTab}
                          value={status.title}
                          onChange={() => {
                            handleChangeTab(status.value);
                          }}
                        />
                        <div className="row-btn-box c-font f-12 p-2 fw-semibold title-fonts cblue w-100">
                          <span class={`c-dots  c-8 me-2 
                          ${index == 0
                              ? `bg-black`
                              : index == 1
                              ? `bgyellow`
                              : index == 2
                              ? `bg-blue`
                              : index == 3
                              ? `lightgreen`
                              : `bg-black`}`}></span>
                          <span>{status.title}</span>
                          <span className="ps-1">
                            {index == 0
                              ? `- (${tabCounts.tab1})`
                              : index == 1
                              ? `- (${tabCounts.tab2})`
                              : index == 2
                              ? `- (${tabCounts.tab3})`
                              : index == 3
                              ? `- (${tabCounts.tab4})`
                              : 0}
                          </span>
                        </div>
                      </label>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="mx-auto d-grid gap-3 padding-bottom-80px">
              {showSckeleton ?  <PosesSkeleton /> : benchMarkListLocal &&
                benchMarkListLocal.length > 0 &&
                benchMarkListLocal.map((benchmark, index) => {
                  let taskDetails = benchmark.solar_task_ids &&
                  benchmark.solar_task_ids.length > 0 &&
                  benchmark.solar_task_ids
                    .filter((task) => task.solar_task_type == 4)[0]
                  taskStatus = taskDetails.status
                  return (
                    <>
                      <a className="radius_3 d-flex comman-white-box-shadow overflow-hiiden-auto bg-white-03  flex-column-reverse flex-sm-row" 
                        onClick={()=>{
                          showTaskDetails(taskDetails)
                        }}>
                        <div className="flex-grow-1 order-1">
                          <div className="d-flex align-align-items-center h-100 flex-column p-20pximp gap-4 gap-md-0 proposalListing p10pxresponsive">
                            <div className="d-flex justify-content-between flex-column">
                              <div className="d-flex align-items-lg-start justify-content-between w-100 mb20px">
                                <div>
                                  <div className="c-font f-16 fw-semibold text-truncate title-fonts">
                                    {benchmark.company_name}
                                  </div>
                                  <div class="c-font color-white-60 f-12 pt-1 text-truncate d-flex align-items-center gap-2">
                                    <MapPin size={14} className="c-icons"/>
                                    {benchmark.address}
                                  </div>
                                </div>
                                <div className="d-flex align-items-center gap10px" onClick={(e) => e.stopPropagation()}>
                                <div className={` dropstart dropdown-center comman_action_icon card-view-less-padding p-0 d-flex action_icon with_bg h32wauto on-hover-active-toggle-img  justify-content-center `}>
                                              <div
                                                className={`BigPill c-font close-toast d-flex f-12 fw-medium me-0 text-black text-uppercase w-100 commanbgwithborder`}
                                                type="button"
                                                id="TaskListStatusDropdown"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                data-tip={`${_l("l_status")}`}
                                                data-effect="solid"
                                                data-delay-show='1000'
                                                data-class="tooltip-main"
                                              >
                                                <div className="d-flex align-items-center w-100 justify-content-center gap10px">
                                                  <span
                                                    className={`c-dots c-10 ${taskStatus == 0
                                                      ? "bg-warning"
                                                      : taskStatus == 1
                                                        ? "bg-warning"
                                                        : taskStatus == 4
                                                          ? "in-progress-blue"
                                                          : "done"
                                                      }`}
                                                  ></span>
                                                  <span className="ps-0">
                                                    {taskStatus == 1
                                                      ? "Request"
                                                      : taskStatus == 4
                                                        ? "In Progress"
                                                        : taskStatus == 5 ?
                                                          "Done"
                                                          : "Request"
                                                    }
                                                  </span>
                                                  <CaretDown
                                size={14}
                                className="c-icons ms-auto me-0"
                              />
                                                  <TaskListStatusDropdown
                                                    setTaskStatus={(statusId) => {
                                                      changeTaskStatus(statusId,task, benchmark);
                                                    }}
                                                    bulkAction={false}
                                                    isRequest={false}
                                                    is_liteversion={false}
                                                    is_task={true}
                                                    hideTodo={true}
                                                  />
                                                </div>
                                              </div>
                                              <ReactTooltip />
                                            </div>
                                  {benchmark.solar_task_ids && benchmark.solar_task_ids.length && benchmark.solar_task_ids[0] && benchmark.solar_task_ids[0].assignee_id > 0 ?
                                    <a href="#/"
                                      data-bs-toggle="tooltip"
                                      className="comman-image-box multi-wrapper-border h32w32 rounded-circle one  bg-black-op5 assignee-list-wrapper card-view-less-padding"
                                      data-tip={employeeData.find((assign) => assign.staff_id == benchmark.solar_task_ids[0].assignee_id) ? employeeData.find((assign) => assign.staff_id == benchmark.solar_task_ids[0].assignee_id).full_name : ''}
                                      data-place="right"
                                      data-effect="solid"
                                      data-delay-show='1000'
                                      data-class="tooltip-main"
                                      key={index}
                                    >
                                      <div
                                        className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                        style={{
                                          backgroundImage: `url('${employeeData.find((assign) => assign.staff_id == benchmark.solar_task_ids[0].assignee_id) ? employeeData.find((assign) => assign.staff_id == benchmark.solar_task_ids[0].assignee_id).profile_image : ''}')`,
                                        }}
                                      ></div>
                                      <ReactTooltip />
                                    </a> : <></>}
                                  <UncontrolledDropdown className="d-flex  dropdown dropdown-center team-list-dropdown ">
                                    <DropdownToggle tag="div" className="" >
                                      <div
                                        className=""
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        data-bs-auto-close="outside"
                                        aria-expanded="false"
                                        data-tip={_l("l_assignee")}
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setAssigneeDropDwon(index)
                                        }}
                                      >
                                        <a href="#/" className="action_icon align-items-center border d-flex gap10px h32wauto justify-content-center pe-10px position-relative ps-10px radius_3 commanbgwithborder">
                                            <UserCircleGear size={18} className="c-icons"/>
                                            <span className="c-font f-12 fw-medium">Affecter à</span>
                                        </a>
                                      </div>
                                    </DropdownToggle>
                                    {assigneeDropDwon == index &&
                                      <InstallationAssigneeDropDown 
                                        employeeData = {employeeData}
                                        installAssignee = { (item, remove) => {
                                            employeeAssigneeInstall(benchmark, item, remove)
                                        }}
                                        banchmarck = {benchmark}
                                        assigneeInstallationLoader= {assigneeInstallationLoader}
                                      /> }
                                    <ReactTooltip />
                                  </UncontrolledDropdown>

                                  <div
                                    className={` white-border-box p-0 d-flex list-toggle-view-box position-relative h32wauto bg-transparent`}
                                  >
                                    <a
                                      href="#/"
                                      className={`custom-datepicker datepicker-input w110px m-auto ms-1 c-font title-fonts commanbgwithborder fw-medium border-0`}
                                      data-bs-toggle="tooltip"
                                      data-tip={"28-06-2024"}
                                      data-effect="solid"
                                      data-delay-show="1000"
                                      data-class="tooltip-main"
                                    >
                                      <div className="d-flex align-items-center">
                                        <div className="h20w20">
                                          <CalendarBlank
                                            size={18}
                                            weight="light"
                                            className="c-icons"
                                          />
                                        </div>
                                        <DatePicker
                                          portalId="custom_datepicker"
                                          calendarClassName="custom-datepicker"
                                          calendarStartDay={1}
                                          className="close-toast"
                                          placeholderText={`${_l(
                                            "l_startdate"
                                          )}`}
                                          dateFormat="dd/MM/yyyy"
                                          selected={
                                            benchmark.solar_task_ids &&
                                            benchmark.solar_task_ids.length > 0 &&
                                            benchmark.solar_task_ids[0]
                                              .startdate != "" && benchmark.solar_task_ids[0]
                                              .startdate 
                                              ? new Date(
                                                  benchmark.solar_task_ids[0].startdate
                                                ) : ""
                                              
                                          }
                                          onChange={(date) => {
                                            setSelectinstallation({updateDate :date, id :benchmark.id, solar_task_ids : benchmark.solar_task_ids[0].id, benchmark: benchmark});
                                            dispatch(
                                              setTaskData(
                                                0,
                                                benchmark.solar_task_ids[0].id,
                                                "",
                                                date
                                              )
                                            );
                                            setStartDate(date);
                                            // handleChangeDate(
                                            //   date,
                                            //   benchmark.id,
                                            //   benchmark.solar_task_ids[0].id
                                            // );
                                          }}
                                        ></DatePicker>
                                        {/* <span className="color-white-60 d-none">Aug, 8 2022</span> */}
                                      </div>
                                    </a>
                                  </div>

                                  <a href="#/" className={`action_icon with_bg h32w32 on-hover-active-toggle-img d-flex justify-content-center`}>
                                  <div className="d-flex flex-column justify-content-center align-items-center position-relative action_icon commanbgwithborder h32w32 border radius_3"
                                    data-bs-toggle="dropdown"
                                    data-tip={`${_l("")}`}
                                    data-place="left"
                                    data-effect="solid"
                                    data-delay-show='1000'
                                    data-class="tooltip-main"
                                    data-bs-auto-close="outside"
                                    aria-expanded="false">
                                    <FileText
                                      size={18}
                                      className="c-icons"
                                      weight="light"
                                    />
                                  </div>
                                </a> 
                                  
                                </div>
                              </div>
                              <div className="row">
                                {benchmark.solar_task_ids &&
                                  benchmark.solar_task_ids.length > 0 &&
                                  benchmark.solar_task_ids
                                    .filter((task) => task.solar_task_type == 4)
                                    .map((task) => {
                                      // taskStatus = task.status
                                      task = task
                                      return (
                                        <>
                                          <div className="col-12 d-flex flex-column gap10px">
                                          <a href="#/" className="c-font f-14 fw-semibold text-truncate title-fonts  d-block" 
                                            // onClick={()=>{
                                            //   showTaskDetails(task)
                                            // }}
                                            >
                                              {task.name}
                                            </a>
                                            <div className="d-flex align-items-center upload-image-preview gap10px">
                                              <div className="commanbgwithborder w80h55 position-relative radius_3 text-center d-flex flex-column  justify-content-center" onClick={(e) => e.stopPropagation()}>
                                                <input
                                                  type="file"
                                                  className="absolute-input "
                                                  multiple
                                                  onChange={(e) => {
                                                    handleDocumentUpload(
                                                      e.target.files,
                                                      task,
                                                      benchmark.id
                                                    );
                                                  }}
                                                  onDragOver={handleDragOver}
                                                  onDrop={(e)=>{handleDrop(e, task,benchmark.id)}}
                                                />
                                                {showCommentLoader == task.id
                                                  ?
                                                  <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    className={`m-auto`}
                                                    aria-hidden="true"
                                                  />
                                                  : <CloudArrowUp
                                                    size={18}
                                                    className="c-icons"
                                                  />}
                                                
                                              </div>
                                              {task.task_attachments &&
                                                task.task_attachments.length >
                                                  0 &&
                                                task.task_attachments.map(
                                                  (img, index) => {
                                                    return (
                                                      <>
                                                        <a
                                                          href="#/"
                                                          className="comman-image-box w80h55 radius_3 upload-image"
                                                          key={index}
                                                          onClick={(e) => {
                                                            e.stopPropagation();
                                                            setOpenLightBox(true);
                                                            setAllAttachments(
                                                              task.task_attachments
                                                            );
                                                            setAttchmentOnTop(
                                                              task.task_attachments,
                                                              img.id
                                                            );
                                                          }}
                                                        > 
                                                        {getFileType(img.image_url) == "pdf" ?
                                                          <FilePdf size={32} className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 cursor-pointer"/>
                                                        :
                                                          <div
                                                            className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 cursor-pointer"
                                                            style={{
                                                              backgroundImage: `url(${img.image_url})`,
                                                            }}
                                                          ></div> }
                                                        </a>
                                                      </>
                                                    );
                                                  }
                                                )}
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column">
                          <div className="w200px d-flex p-5px flex-grow-1 min-h-140 position-relative">
                              {!loadedImages[index] && (
                                <img
                                  src={NoMapPlaceholder}  // Your placeholder image
                                  alt="placeholder"
                                  style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                  }}
                                />
                              )}
                            <img
                              className="h-100 w-100 object-fit-cover min-h-140 "
                              src={benchmark.solar_map}
                              style={{ display: loadedImages[index] ? 'block' : 'none' }}
                              onLoad={() => handleImageLoad(index)}  
                              onError={() => handleImageError(index)} 
                            />
                          </div>
                        </div>
                      </a>
                    </>
                  );
                })}
              {showSckeleton ? <></> : benchMarkListLocal && benchMarkListLocal.length == 0 ? (
                <>
                  <CommanPlaceholder imgType="request" placeholderText = {_l("l_no_data")} />
                </>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </div>
          </div>
        <div className="Absolutecomingsoon d-none">
          <div className="d-flex align-items-center justify-content-center flex-column h-100">
            <img className="h100px" src={ComingSoon} alt="" />
            {/* <h2 data-text="Coming soon">Coming soon</h2> */}
            <div className="display-2 fw-semibold title-fonts">Coming soon</div>
          </div>
        </div>
      </div>
      {showTaskCanvas ? (
            <CommanOffCanvas
              show={showTaskCanvas}
              handleClose={() => {
                setShowTaskCanvas(false);
              }}
            //   pinHandler={(id, type, flag) => {
            //     handlePinUnPin(id, type, flag);
            //   }}
              data={taskDetail}
              setData={()=>{}}
              docType={'task'}
              setSelectedId={()=>{}}
              is_fullscreen={true}
            />
          ) : (
            <></>
          )}
    </>
  );
};

export default InstallationList;
