import React from 'react';
// import CSS from '../css/icon_main.css';

function FIleXicon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>


<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" viewBox="0 0 256 256" className={`comman-icons ${CommanHW}`}><path d="M212.24,83.76l-56-56A6,6,0,0,0,152,26H56A14,14,0,0,0,42,40V216a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V88A6,6,0,0,0,212.24,83.76ZM158,46.48,193.52,82H158ZM202,216a2,2,0,0,1-2,2H56a2,2,0,0,1-2-2V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50Zm-45.76-92.24a6,6,0,0,1,0,8.48L136.49,152l19.75,19.76a6,6,0,1,1-8.48,8.48L128,160.49l-19.76,19.75a6,6,0,0,1-8.48-8.48L119.51,152,99.76,132.24a6,6,0,1,1,8.48-8.48L128,143.51l19.76-19.75A6,6,0,0,1,156.24,123.76Z"></path></svg>
		


		</React.Fragment>
	)
}

export default FIleXicon