import React from 'react';
// import CSS from '../css/icon_main.css';

function ArVrIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
		<svg width="22" height="16" viewBox="0 0 22 16" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
			<path d="M1 6C1 5.46957 1.21071 4.96086 1.58579 4.58579C1.96086 4.21072 2.46957 4 3 4H19C19.5304 4 20.0391 4.21072 20.4142 4.58579C20.7893 4.96086 21 5.46957 21 6V13C21 13.5304 20.7893 14.0391 20.4142 14.4142C20.0391 14.7893 19.5304 15 19 15H15.132C14.7866 15 14.4471 14.9106 14.1466 14.7404C13.8461 14.5703 13.5947 14.3252 13.417 14.029L11.857 11.429C11.7681 11.2812 11.6425 11.1588 11.4923 11.0739C11.3421 10.989 11.1725 10.9443 11 10.9443C10.8275 10.9443 10.6579 10.989 10.5077 11.0739C10.3575 11.1588 10.2319 11.2812 10.143 11.429L8.583 14.029C8.40531 14.3252 8.15395 14.5703 7.8534 14.7404C7.55286 14.9106 7.21337 15 6.868 15H3C2.46957 15 1.96086 14.7893 1.58579 14.4142C1.21071 14.0391 1 13.5304 1 13V6ZM2.813 2.781C3.17819 2.23329 3.67291 1.78418 4.25327 1.4735C4.83364 1.16282 5.48171 1.00018 6.14 1H15.858C16.5165 1.00001 17.1647 1.16257 17.7453 1.47326C18.3258 1.78395 18.8207 2.23315 19.186 2.781L20 4H2L2.813 2.781V2.781Z" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
		</React.Fragment>
	)
}

export default ArVrIcon