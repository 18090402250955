import React, { useEffect, useState } from "react";
import OnboardingHeader from "../SpaceOnBoarding/OnboardingHeader";
import OnboardingFooter from "../SpaceOnBoarding/OnboardingFooter";
import { _l, showError, validateEmail } from "../../hooks/utilities";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import CustomerServices from "../../services/customer-services";
import ListingWithCheckbox from "./ListingWithCheckbox";
import ProgressBarLoader from "../Loader/ProgressBarLoader";
import { OnboardingFlowFinalStep } from "../SpaceOnBoarding/SpaceDetails/OnboardingFlowFinalStep";
import { useNavigate } from "react-router-dom";
import { COUNTRY_CODE_OPTIONS } from "../../constants/constants";

const NO_HEADERS_STEP = [
  "congratulations-screen",
  "screen-1",
  "screen-2",
  "screen-3",
  "screen-4",
];

const STATIC_STEPS = [ "congratulations-screen",
"screen-1",
"screen-2",
"screen-3",
"screen-4"];

const Leads = ({}) => {
  const [headerTitle, setHeaderTitle] = useState("l_setup_account");
  const [headerDescription, setHeaderDescription] = useState(
    "l_setup_account_description"
  );
  const [currentStep, setCurrentStep] = useState("user-info");
  const [loadingNextStep, setLoadingNextStep] = useState(false);
  const [toolsList, setToolsList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [tenantsList, setTenantsList] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [userInfo, setUserInfo] = useState({
    name: "",
    company_name: "",
    email: "",
    country_code: "+31",
    phonenumber: "",
    position: "",
    password: "",
    total_no_space: "",
    total_no_sqm: "",
    total_employee: "",
    number_of_hours: "",
    tenent_management_hours: "",
    provider_hours: "",
    equipment_hours: "",
    tenent_management_budget: "",
    provider_budget: "",
    equipment_budget: "",
  });

  const navigate = useNavigate();

  const handleUserInfoForm = () => {
    let flag = true;

    if (!userInfo.name.trim().length) {
      showError("l_please_enter_name");
      flag = false;
    } else if (!userInfo.company_name.trim().length) {
      showError("l_please_enter_company_name");
      flag = false;
    } else if (!validateEmail(userInfo.email)) {
      showError("l_please_enter_valid_email");
      flag = false;
    } else if (!userInfo.country_code) {
      showError("l_please_enter_company_name");
      flag = false;
    } else if (!userInfo.phonenumber.trim().length) {
      showError("l_please_enter_valid_phone_number_with_country_code");
      flag = false;
    } else if (!userInfo.position.trim().length) {
      showError("l_Please_select_employee_position");
      flag = false;
    } else if (!userInfo.password.trim().length) {
      showError("l_please_enter_password");
      flag = false;
    }

    return flag;
  };

  const saveLeadInfo = async () => {
    try {
      const response = await CustomerServices.addLead({
        ...userInfo,
        serviceList: serviceList.filter((w) => w.checked),
        tenantsList: tenantsList.filter((w) => w.checked),
        providerList: providerList.filter((w) => w.checked),
        equipmentList: equipmentList.filter((w) => w.checked),
        toolsList: toolsList,
      });
      if (response.status) {
        setTimeout(() => {
          setCurrentStep("congratulations-screen");
        }, 5000);
      }
      setCurrentStep("progress-loader");
    } catch (e) {
      console.error(e);
    }
  };

  const changeNextStep = (direction) => {
    if (direction > -1) {
      switch (currentStep) {
        case "user-info":
          if (handleUserInfoForm()) {
            setCurrentStep("space-info");
          }
          break;
        case "space-info":
          if (handleUserInfoForm()) {
            setCurrentStep("people-count");
          }
          break;
        case "people-count":
          setCurrentStep("tools-selection");
          break;
        case "tools-selection":
          setCurrentStep("service-selection");
          break;
        case "service-selection":
          const selectedService = serviceList.filter((w) => w.checked);
          if (!selectedService.length) {
            showError("l_please_select_category");
            return;
          }
          switch (selectedService[0].value) {
            case "1":
              setCurrentStep("tenants-management");
              break;
            case "2":
              setCurrentStep("providers-management");
              break;
            default:
              setCurrentStep("equipments-management");
              break;
          }
          break;
        case "tenants-management":
          if (!tenantsList.find((w) => w.checked)) {
            showError("l_please_select_category");
            return;
          }
          setCurrentStep("tenant-budget-screen");
          break;
        case "providers-management":
          if (!providerList.find((w) => w.checked)) {
            showError("l_please_select_category");
            return;
          }
          setCurrentStep("provider-budget-screen");
          break;
        case "equipments-management":
          if (!equipmentList.find((w) => w.checked)) {
            showError("l_please_select_category");
            return;
          }
          setCurrentStep("equipments-budget-screen");
          break;
        case "tenant-budget-screen":
          if (
            !userInfo.tenent_management_budget ||
            !userInfo.tenent_management_hours
          ) {
            showError("l_please_add_budget");
            return;
          }
          const serviceOtherthanTenant = serviceList.filter(
            (w) => w.checked && [2, 3].includes(Number(w.value))
          );
          if (serviceOtherthanTenant.length) {
            setCurrentStep(
              serviceOtherthanTenant.length > 1
                ? "providers-management"
                : serviceOtherthanTenant[0].key
            );
          } else {
            saveLeadInfo();
          }
          break;
        case "provider-budget-screen":
          if (!userInfo.provider_budget || !userInfo.provider_hours) {
            showError("l_please_add_budget");
            return;
          }
          const serviceOtherthanProviders = serviceList.filter(
            (w) => w.checked && Number(w.value) == 3
          );
          if (serviceOtherthanProviders.length) {
            setCurrentStep("equipments-management");
          } else {
            saveLeadInfo();
          }
          break;
        case "equipments-budget-screen":
          if (!userInfo.equipment_budget || !userInfo.equipment_hours) {
            showError("l_please_add_budget");
            return;
          }
          saveLeadInfo();
          break;
        case "congratulations-screen":
          setCurrentStep("screen-1");
          break;
        case "screen-1":
          setCurrentStep("screen-2");
          break;
        case "screen-2":
          setCurrentStep("screen-3");
          break;
        case "screen-3":
          setCurrentStep("screen-4");
          break;
        case "screen-4":
          navigate("/leads");
          break;
        default:
          break;
      }
    } else {
      switch (currentStep) {
        case "space-info":
          setCurrentStep("user-info");
          break;
        case "people-count":
          setCurrentStep("space-info");
          break;
        case "tools-selection":
          setCurrentStep("people-count");
          break;
        case "service-selection":
          setCurrentStep("tools-selection");
          break;
        case "tenants-management":
          setCurrentStep("service-selection");
          break;
        case "providers-management":
          const serviceOtherthanProviders = serviceList.filter(
            (w) => w.checked && Number(w.value) == 1
          );
          if (serviceOtherthanProviders.length) {
            setCurrentStep("tenant-budget-screen");
          } else {
            setCurrentStep("service-selection");
          }
          break;
        case "equipments-management":
          if (serviceList.find((w) => w.checked && Number(w.value) == 2)) {
            setCurrentStep("provider-budget-screen");
          } else if (
            serviceList.find((w) => w.checked && Number(w.value) == 1)
          ) {
            setCurrentStep("tenant-budget-screen");
          } else {
            setCurrentStep("service-selection");
          }
          break;
        case "tenant-budget-screen":
          setCurrentStep("tenants-management");
          break;
        case "provider-budget-screen":
          setCurrentStep("providers-management");
          break;
        case "equipments-budget-screen":
          setCurrentStep("equipments-management");
          break;
        case "screen-2":
          setCurrentStep("screen-1");
          break;
        case "screen-3":
          setCurrentStep("screen-2");
          break;
        case "screen-4":
          setCurrentStep("screen-3");
          break;
        default:
          break;
      }
    }
  };

  const nextButtonText = () => {
    let buttonText = _l("l_next");
    if (["flow-complete"].includes(currentStep)) {
      buttonText = "";
    }
    return currentStep === "final-step" ? _l("l_register") : buttonText;
  };

  const getToolsList = async () => {
    try {
      const response = await CustomerServices.getLeadTools();
      if (response.status) {
        setToolsList(
          response.data.map((w) => {
            return { label: w.name, value: w.id };
          })
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getServicesList = async () => {
    try {
      const response = await CustomerServices.getServiceList();
      if (response.status) {
        let key;
        setServiceList(
          response.data.map((w) => {
            switch (w.id) {
              case "1":
                key = "tenants-management";
                break;
              case "2":
                key = "providers-management";
                break;
              case "3":
                key = "equipments-management";
                break;
              default:
                break;
            }
            return { label: w.name, value: w.id, key };
          })
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getTenantsServices = async () => {
    try {
      const response = await CustomerServices.getTenantsServices();
      if (response.status) {
        setTenantsList(
          response.data.map((w) => {
            return { label: w.name, value: w.id };
          })
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getProvidersServices = async () => {
    try {
      const response = await CustomerServices.getProviderServices();
      if (response.status) {
        setProviderList(
          response.data.map((w) => {
            return { label: w.name, value: w.id };
          })
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getEquipmentsServices = async () => {
    try {
      const response = await CustomerServices.getEquipmentsServices();
      if (response.status) {
        setEquipmentList(
          response.data.map((w) => {
            return { label: w.name, value: w.id };
          })
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getToolsList();
    getServicesList();
    getTenantsServices();
    getProvidersServices();
    getEquipmentsServices();
  }, []);

  return (
    <div
      className={`onboarding-wrapper-main m-auto h-100 d-flex flex-column onboarding-with-bg ${
        [].includes(currentStep) ? "with-modified-width" : ""
      }`}
    >
      {NO_HEADERS_STEP.includes(currentStep) ? (
        <React.Fragment></React.Fragment>
      ) : (
        <OnboardingHeader
          title={headerTitle}
          description={headerDescription}
          backBtnHandler={() => {
            changeNextStep(-1);
          }}
          nextBtnHandler={() => {
            changeNextStep(1);
          }}
          backButtonText={_l("l_previous")}
          hideBackButton={[
            "user-info",
            "congratulations-screen",
            "screen-1",
          ].includes(currentStep)}
          nextButtonText={nextButtonText()}
          loadingNextStep={loadingNextStep}
          loader={loader}
          // hideNextButton={currentStep !== "user-info" ? true : false}
        />
      )}
      <div
        className={`justify-content-center onboarding-content-scroll-wrapper d-flex flex-column`}
      >
        <div className="h-100 d-flex flex-column  comman-main-box pt-0 px-0">
          {currentStep === "user-info" ? (
            <div className="row">
              <Form.Group className="col-12  c-input-box mb-32px position-relative">
                <div className="custom_input_box with_before">
                  <Form.Label className="input-label   form-label">
                    {_l("l_name_label")}
                  </Form.Label>
                  <Form.Control
                    placeholder={_l("l_full_name_placeholder")}
                    className="form-control"
                    name="fullName"
                    type="text"
                    value={userInfo.name}
                    onChange={(e) => {
                      setUserInfo({ ...userInfo, name: e.target.value });
                    }}
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-12  c-input-box mb-32px position-relative">
                <div className="custom_input_box with_before">
                  <Form.Label className="input-label   form-label">
                    {_l("l_company_name")}
                  </Form.Label>
                  <Form.Control
                    placeholder={_l("l_globex_corporation")}
                    className="form-control"
                    name="companyName"
                    type="text"
                    value={userInfo.company_name}
                    onChange={(e) => {
                      setUserInfo({
                        ...userInfo,
                        company_name: e.target.value,
                      });
                    }}
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-6  c-input-box mb-32px position-relative">
                <div className="custom_input_box with_before">
                  <Form.Label className="input-label   form-label">
                    {_l("l_email")}
                  </Form.Label>
                  <Form.Control
                    placeholder={_l("l_email_placeholder")}
                    className="form-control"
                    name="email"
                    type="email"
                    value={userInfo.email}
                    onChange={(e) => {
                      setUserInfo({ ...userInfo, email: e.target.value });
                    }}
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-xl-6 c-input-box mb-32px position-relative">
                <Form.Label className="input-label   form-label">
                  {_l("l_phone_number")}
                </Form.Label>
                <div className="row">
                  <div className="c_input_border drop_image col-3 position-relative">
                    <Select
                      className="custom-select-menu   scroll-height-30vh h-100 profileSelecth100"
                      classNamePrefix="react-select"
                      placeholder={`${_l("l_country_code_placeholder")}`}
                      options={COUNTRY_CODE_OPTIONS}
                      value={COUNTRY_CODE_OPTIONS.find(
                        (w) => w.label == userInfo.country_code
                      )}
                      onChange={(e) => {
                        setUserInfo({ ...userInfo, country_code: e.label });
                      }}
                    />
                  </div>
                  <div className="col-9  ps-0">
                    <Form.Control
                      className=""
                      placeholder={_l("l_phone_number_placeholder")}
                      type="number"
                      name="mobile_number"
                      value={userInfo.phonenumber}
                      onChange={(e) => {
                        setUserInfo({
                          ...userInfo,
                          phonenumber: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="col-6  c-input-box mb-32px position-relative">
                <div className="custom_input_box with_before">
                  <Form.Label className="input-label   form-label">
                    {_l("l_position")}
                  </Form.Label>
                  <Form.Control
                    placeholder={_l("l_position")}
                    className="form-control"
                    name="email"
                    value={userInfo.position}
                    onChange={(e) => {
                      setUserInfo({ ...userInfo, position: e.target.value });
                    }}
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-6  c-input-box mb-32px position-relative">
                <div className="custom_input_box with_before">
                  <Form.Label className="input-label   form-label">
                    {_l("l_password")}
                  </Form.Label>
                  <Form.Control
                    placeholder="*****"
                    className="form-control"
                    name="email"
                    type="password"
                    value={userInfo.password}
                    onChange={(e) => {
                      setUserInfo({ ...userInfo, password: e.target.value });
                    }}
                  />
                </div>
              </Form.Group>
            </div>
          ) : currentStep === "space-info" ? (
            <div className="row">
              <div className="color-white-80 c-font f-16 form-label fw-semibold title-fonts pb-3">
                {_l("l_how_many_spaces_are_you_managing")}
              </div>
              <Form.Group className="col-6  c-input-box mb-32px position-relative">
                <div className="custom_input_box with_before">
                  <Form.Label className="input-label   form-label">
                    {_l("l_enter_number_of_sapce")}
                  </Form.Label>
                  <Form.Control
                    placeholder="50"
                    className="form-control"
                    type="number"
                    value={userInfo.total_no_space}
                    onChange={(e) => {
                      setUserInfo({
                        ...userInfo,
                        total_no_space: e.target.value,
                      });
                    }}
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-6  c-input-box mb-32px position-relative">
                <div className="custom_input_box with_before">
                  <Form.Label className="input-label   form-label">
                    {_l("l_total_superficy")}
                  </Form.Label>
                  <Form.Control
                    placeholder="SQM"
                    className="form-control"
                    type="number"
                    value={userInfo.total_no_sqm}
                    onChange={(e) => {
                      setUserInfo({
                        ...userInfo,
                        total_no_sqm: e.target.value,
                      });
                    }}
                  />
                </div>
              </Form.Group>
            </div>
          ) : currentStep === "people-count" ? (
            <div className="row">
              <div className="color-white-80 c-font f-16 form-label fw-semibold title-fonts pb-3">
                {_l("l_how_many_people_are_working_with_you")}
              </div>
              <Form.Group className="col-12 c-input-box mb-32px position-relative">
                <div className="custom_input_box with_before">
                  <Form.Label className="input-label   form-label">
                    {_l("l_total_number_of_peolpe")}
                  </Form.Label>
                  <Form.Control
                    placeholder="50"
                    className="form-control"
                    type="number"
                    value={userInfo.total_employee}
                    onChange={(e) => {
                      setUserInfo({
                        ...userInfo,
                        total_employee: e.target.value,
                      });
                    }}
                  />
                </div>
              </Form.Group>
            </div>
          ) : currentStep === "tools-selection" ? (
            <ListingWithCheckbox
              list={toolsList}
              setList={setToolsList}
              handleAddNewCategory={(label) => {
                setToolsList(
                  toolsList.concat([{ label, id: 0, checked: true }])
                );
              }}
            />
          ) : currentStep === "service-selection" ? (
            <ListingWithCheckbox
              list={serviceList}
              setList={setServiceList}
              handleAddNewCategory={(label) => {
                setServiceList(
                  serviceList.concat([{ label, id: 0, checked: true }])
                );
              }}
              allowdToAdd={false}
            />
          ) : currentStep === "tenants-management" ? (
            <ListingWithCheckbox
              list={tenantsList}
              setList={setTenantsList}
              handleAddNewCategory={(label) => {
                setTenantsList(
                  tenantsList.concat([{ label, id: 0, checked: true }])
                );
              }}
            />
          ) : currentStep === "tenant-budget-screen" ? (
            <div className="row">
              <div className="color-white-80 c-font f-16 form-label fw-semibold title-fonts pb-3">
                {_l("l_how_many_spaces_are_you_managing")}
              </div>
              <Form.Group className="col-6  c-input-box mb-32px position-relative">
                <div className="custom_input_box with_before">
                  <Form.Label className="input-label   form-label">
                    {_l("l_number_of_hours")}
                  </Form.Label>
                  <Form.Control
                    placeholder="50"
                    className="form-control"
                    type="number"
                    value={userInfo.tenent_management_hours}
                    onChange={(e) => {
                      setUserInfo({
                        ...userInfo,
                        tenent_management_hours: e.target.value,
                      });
                    }}
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-6  c-input-box mb-32px position-relative">
                <div className="custom_input_box with_before">
                  <Form.Label className="input-label   form-label">
                    {_l("l_budget")}
                  </Form.Label>
                  <Form.Control
                    placeholder={_l("l_budget_placeholder")}
                    className="form-control"
                    name="number"
                    value={userInfo.tenent_management_budget}
                    onChange={(e) => {
                      setUserInfo({
                        ...userInfo,
                        tenent_management_budget: e.target.value,
                      });
                    }}
                  />
                </div>
              </Form.Group>
            </div>
          ) : currentStep === "providers-management" ? (
            <ListingWithCheckbox
              list={providerList}
              setList={setProviderList}
              handleAddNewCategory={(label) => {
                setProviderList(
                  providerList.concat([{ label, id: 0, checked: true }])
                );
              }}
            />
          ) : currentStep === "provider-budget-screen" ? (
            <div className="row">
              <div className="color-white-80 c-font f-16 form-label fw-semibold title-fonts pb-3">
                {_l("l_how_many_spaces_are_you_managing")}
              </div>
              <Form.Group className="col-6  c-input-box mb-32px position-relative">
                <div className="custom_input_box with_before">
                  <Form.Label className="input-label   form-label">
                    {_l("l_number_of_hours")}
                  </Form.Label>
                  <Form.Control
                    placeholder="50"
                    className="form-control"
                    type="number"
                    value={userInfo.provider_hours}
                    onChange={(e) => {
                      setUserInfo({
                        ...userInfo,
                        provider_hours: e.target.value,
                      });
                    }}
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-6  c-input-box mb-32px position-relative">
                <div className="custom_input_box with_before">
                  <Form.Label className="input-label   form-label">
                    {_l("l_budget")}
                  </Form.Label>
                  <Form.Control
                    placeholder={_l("l_budget_placeholder")}
                    className="form-control"
                    name="number"
                    value={userInfo.provider_budget}
                    onChange={(e) => {
                      setUserInfo({
                        ...userInfo,
                        provider_budget: e.target.value,
                      });
                    }}
                  />
                </div>
              </Form.Group>
            </div>
          ) : currentStep === "equipments-management" ? (
            <ListingWithCheckbox
              list={equipmentList}
              setList={setEquipmentList}
              handleAddNewCategory={(label) => {
                setEquipmentList(
                  providerList.concat([{ label, id: 0, checked: true }])
                );
              }}
            />
          ) : currentStep === "equipments-budget-screen" ? (
            <div className="row">
              <div className="color-white-80 c-font f-16 form-label fw-semibold title-fonts pb-3">
                {_l("l_how_many_spaces_are_you_managing")}
              </div>
              <Form.Group className="col-6  c-input-box mb-32px position-relative">
                <div className="custom_input_box with_before">
                  <Form.Label className="input-label   form-label">
                    {_l("l_number_of_hours")}
                  </Form.Label>
                  <Form.Control
                    placeholder="50"
                    className="form-control"
                    type="number"
                    value={userInfo.equipment_hours}
                    onChange={(e) => {
                      setUserInfo({
                        ...userInfo,
                        equipment_hours: e.target.value,
                      });
                    }}
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-6  c-input-box mb-32px position-relative">
                <div className="custom_input_box with_before">
                  <Form.Label className="input-label   form-label">
                    {_l("l_budget")}
                  </Form.Label>
                  <Form.Control
                    placeholder={_l("l_budget_placeholder")}
                    className="form-control"
                    name="number"
                    value={userInfo.equipment_budget}
                    onChange={(e) => {
                      setUserInfo({
                        ...userInfo,
                        equipment_budget: e.target.value,
                      });
                    }}
                  />
                </div>
              </Form.Group>
            </div>
          ) : currentStep === "progress-loader" ? (
            <div className="row h-100">
              <ProgressBarLoader />
            </div>
          ) : currentStep === "congratulations-screen" ? (
            <OnboardingFlowFinalStep
              congratulationMessage={`l_so_we_have_bad_and_good_news_for_you`}
              navigation={""}
              removeExtraMargin={true}
            />
          ) : currentStep === "screen-1" ? (
            <div className="row h-100">
              <div className="col-8 m-auto">
                <div className="c-font f-24 fw-semibold title-fonts text-center">
                  <span>
                    {_l("l_so_the_bad_news_is")}
                    <span className="gradientText">
                      {" "}
                      {_l("l_25_hours_by_week")}{" "}
                    </span>
                    {_l("l_are_currently_lost_it_means")}{" "}
                  </span>
                  <span className="c-font display-3 gradientText d-block fw-bold">
                    {_l("l_48_days")}
                  </span>
                  <span>
                    {_l(
                      "l_by_year_are_lost_because_you_dont_have_the_right_tools"
                    )}{" "}
                  </span>
                </div>
              </div>
            </div>
          ) : currentStep === "screen-2" ? (
            <div className="row h-100">
              <div className="col-12 m-auto">
                <div className="c-font f-24 fw-semibold title-fonts text-center">
                  <span>
                    {_l(
                      "l_the_good_news_is_we_can_show_you_how_you_can_save_until"
                    )}{" "}
                  </span>
                  <span className="c-font display-3 gradientText d-block fw-bold">
                    {_l("l_15_days")}
                  </span>
                  <span>
                    {_l(
                      "l_by_year_to_help_you_and_your_organization_like_our_hundred_of_customers"
                    )}{" "}
                  </span>
                </div>
              </div>
            </div>
          ) : currentStep === "screen-3" ? (
            <div className="row h-100">
              <div className="col-12 m-auto">
                <div className="c-font f-24 fw-semibold title-fonts text-center">
                  <span>
                    {_l("l_so_the_bad_news_is_an_average")},
                    <span className="gradientText">
                      {_l("l_17_per_of_tasks")}{" "}
                    </span>
                    {_l("l_are_not_dont_it_means_nearly")}{" "}
                  </span>
                  <span className="c-font display-3 gradientText d-block fw-bold">
                    {_l("l_5010_es")}
                  </span>
                  <span>
                    {_l(
                      "l_by_year_are_lost_because_you_dont_have_the_right_tools"
                    )}
                  </span>
                </div>
              </div>
            </div>
          ) : currentStep === "screen-4" ? (
            <div className="row h-100">
              <div className="col-12 m-auto">
                <div className="c-font f-24 fw-semibold title-fonts text-center">
                  <span>
                    {_l(
                      "l_the_good_news_is_we_can_show_you_how_you_can_save_until"
                    )}{" "}
                  </span>
                  <span className="c-font display-3 gradientText d-block fw-bold">
                    {_l("l_2505_es")}
                  </span>
                  <span>
                    {_l(
                      "l_by_year_to_help_you_and_your_organization_like_our_hundred_of_customers"
                    )}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </div>
      </div>
      {
        STATIC_STEPS.includes(currentStep)
        ?
        <div className="d-flex align-items-center justify-content-center">
          <Button variant={"primary"} className="" onClick={() => changeNextStep(1)}>
            {_l("l_save")}
          </Button>
        </div>
        :
        <></>
      }
    </div>
  );
};

export default Leads;
