import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { _l } from "../../hooks/utilities";
import ReactHtmlParser from "react-html-parser";

const Typing = ({ chatInput = false , disableInputChat}) => {
    const [typingHTML, setTypingHTML] = useState("");
    const [checkingHTML, setCheckingHTML] = useState("");
    const { typingMembers } = useSelector((state) => state.chat);
    const { aiMyr } = useSelector((state) => state.customer);

    useEffect(() => {
        setCheckingHTML(`<div class="fw-bold mx-1">` + (aiMyr && aiMyr.categories && aiMyr.categories.title + '</div>' + _l("l_is_checking")))
        let typingMemberss = Array.isArray(typingMembers) ? typingMembers.slice() : [];

        if(disableInputChat == 1){
            typingMemberss.push({fname : aiMyr && aiMyr.categories && aiMyr.categories.title, lname : ''})
        }
        if (!chatInput) {
            
        }
        var tLength = typingMemberss && typingMemberss.length;
        if(tLength > 0){
            var tHTML = typingMemberss.map((item, i) => {
                return (
                    `<div class="fw-bold mx-1">${item.fname}${tLength > 2 && i < tLength - 2 ? ',' : ''}</div>`
                );
            });
            setTypingHTML(tHTML.slice(0, -1).join('')+(tLength > 1 ? ' and ' : '')+tHTML.slice(-1)+(tLength > 1 ? "are" : "is")+" typing");
        }
    }, [typingMembers, disableInputChat]);

    return (
        <React.Fragment>
            <div className="align-items-center d-flex">
                <div className="align-items-center c-font color-white-60 d-flex f-12 me-2 typing-text">{chatInput ? ReactHtmlParser(typingHTML) : ReactHtmlParser(checkingHTML)}</div>
                <div className="typing">
                    <div className="typing__dot"></div>
                    <div className="typing__dot"></div>
                    <div className="typing__dot"></div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Typing;
