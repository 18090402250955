import React, { useEffect, useState } from "react";
import UserIcon from "../assets/icons/UserIcon";
import Form from 'react-bootstrap/Form';
import { _l } from "../hooks/utilities";
import { User } from "phosphor-react";

const GeneralListingWithCheckbox = ({ list, handler, isFromCalDropDown = null }) => {

  const [dropdownData, setDropdownData] = useState(list);
  // useEffect(() => {
  //   let unOrderedData = list;
  //   unOrderedData.sort((a, b) => {
  //     if (a.name < b.name)
  //       return -1;
  //   });
  //   setDropdownData(unOrderedData);

  // }, [list]);

  return (
    <>
    <ul className="dropdown-menu w-250 overflowscroll with-transform-option" aria-labelledby="GeneralListingWithCheckbox">
      <div className="title-font f13 opacity-50 m-2">{_l("l_current_provider")}</div>
      {dropdownData.length ? (
        dropdownData.map((item, index) => {
          return (
            <React.Fragment key={index}>
            <li className="hr_sepretr">
              <a href="#/" className="dropdown-item">
                <div className="d-flex align-items-center text-truncate">
                {item.image ? (
                    <div className="c-list-icon">
                      <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
                        <div
                          className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                          style={{
                            backgroundImage: `url('${item.image}')`,
                          }}
                        ></div>
                      </div>
                    </div>
                    ) : (
                      <></>
                    )}
                  <div className="ps-2 w100minus20 text_wrap">
                    <div className="c-list-detail text-truncate color-white-60">
                    {item.name}
                    </div>
                  </div>
                  <Form.Check 
                    type="checkbox"
                    id={`default-`}
                  />
                </div>
                <div className="c-font f-13 d-flex">
                  <div className="progress-slider w-100 pt-2 pb-2">
                      <div className="d-flex align-items-center">
                          <div className="progress">
                              <div className="progress-bar" style={{ width: "39%" }}></div>
                          </div>
                      </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between color-green ms-3">
                      <div>39%</div>
                  </div>
                </div>
              </a>
            </li>
            </React.Fragment>
         );
        })
        ) : (
        <li>
          <div className="comman-placeholder-wrapper w-100 py-0 d-flex h-100">
            <div className="d-flex flex-column align-items-center m-auto">
              <User size={16}  weight="light" className="c-icons small-image opacity-50" />
              <span className="color-white-80 py-2 title-fonts">
              {_l("l_no_provider_found")}
              </span>
            </div>
          </div>
        </li>
      )}
    </ul>
    </>
  );
};

export default GeneralListingWithCheckbox;
