import React, { useEffect, useRef, useState } from "react";
import PushPinFillIcon from "../../assets/icons/PushPinFillIcon";
import PushPinIcon from "../../assets/icons/PushPinIcon";
import { IsToastOpenOrClose } from "../../actions/customer"
import CustomerServices from "../../services/customer-services";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import Toasts from "../Toasts/Toasts";
import { _l } from "../../hooks/utilities";
import { getTaskChecklist } from "../../actions/customer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ArrowRight,DotsThreeVertical } from "phosphor-react";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";

// import {IsToastOpenOrClose} from "../../actions/customer"
const NotesComponent = ({ projectId, task_id, toastClose, closeAllTost, isExternal }) => {
  const [toastHeader, setToastHeader] = useState("");
  const [confirmButtonText, setConfirmButtonText] = useState("");
  const [cancelButtonText, setCancelButtonText] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastClass, setToastClass] = useState("unsucess");
  const [toastMessage, setToastMessage] = useState("");
  const [noteItem, setNoteItem] = useState("");
  const [notes, setNotes] = useState([]);
  const [noteId, setNoteId] = useState(0);
  const [disableOnClickEvent, setDisableOnClickEvent] = useState('');
  const dispatch = useDispatch();
  const toastdata = useSelector((state) => state.customer.toastClose);
  const { id: hash_id, page } = useParams();

  const contactId = localStorage.getItem("contact_id");

  const inputReference = useRef(null);

  useEffect(() => {
    setNoteItem('')
    // setDisableOnClickEvent('')
    if (task_id) {
      CustomerServices.getNotes(projectId, task_id, (isExternal || hash_id) ? hash_id : false).then((res) => {
        if (res.data) {
          const notesData = res.data;
          setNotes([]);
          notesData.forEach((item) => {
            setNotes((notes) => [...notes, item]);
          });
        }
      });
    }
  }, [task_id]);
  // useEffect(() => {
  //   openToastClose()
  // }, [toastClose])
  const openToastClose = () => {
    setToastClass("unsucess");
    setToastHeader("");
    setConfirmButtonText("");
    setCancelButtonText("");
    setToastMessage(0);
    setNoteId(0);
    setShowToast(false);
  };
  const handleAddNote = () => {
    var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?~]/;
    if (noteItem.trim() && !format.test(noteItem)) {
      if (noteId > 0) {
        CustomerServices.editNote(
          task_id,
          projectId,
          noteItem.trim(),
          noteId
        ).then((res) => {
          if (res.status == 1) {
            notes.forEach((note) => {
              if (note.id === noteId) {
                note.description = noteItem.trim();
              }
            });
            setNotes(notes);
            setNoteItem("");
            setNoteId(0);
          } 
        });
      } else {
        CustomerServices.setNote(task_id, projectId, noteItem.trim()).then(
          (res) => {
            if (res.status == 1) {
              const addnote = [
                {
                  description: noteItem.trim(),
                  id: res.data.note_id,
                  addedfrom: localStorage.getItem("contact_id"),
                  is_added_from_contact: 1,
                  is_pinned: 0,
                  is_allowed_to_edit: 1
                },
              ];
              setNotes(addnote.concat(notes));
              setNoteItem("");
            } 
          }
        );
      }
    } else {
      setToastHeader(_l("l_error"));
      setToastMessage(_l("l_please_enter_valid_note"));
      setConfirmButtonText("");
      setCancelButtonText("");
      setToastClass("unsucess");
      setShowToast(true);
    }
  };

  const handleDeleteNote = (id) => {
    if (id > 0) {
      CustomerServices.removeNote(task_id, projectId, id).then((res) => {
        setNotes(notes.filter((note) => note.id !== id));
        setNoteItem("");
        setNoteId(0);
        dispatch(IsToastOpenOrClose(false))
      });
    }
  };
  useEffect(() => {

    if (disableOnClickEvent == '' && noteItem != '') {
      handleAddNote()
      // setDisableOnClickEvent(false)
    }
  }, [disableOnClickEvent])
  const handleEditNote = (id) => {
    const targetNote = notes.filter((note) => note.id === id);
    if (targetNote.length) {
      setNoteId(id);
      setNoteItem(targetNote[0].description);
      inputReference.current.focus();
    }
  };

  const handlePinnedNote = (id, is_pinned) => {
    if (id) {
      CustomerServices.pinNotes(id).then((res) => {
        if (is_pinned == "Pin") {
          let targetNote = notes.filter((notes) => notes.id == id);

          targetNote = targetNote.map((obj) => {
            if (obj.id == id) {
              return { ...obj, is_pinned: "1" };
            }
            return obj;
          });

          const othernotes = notes.filter((notes) => notes.id != id);
          setNotes(targetNote.concat(othernotes));
        } else {
          const newNotes = notes.map((obj) => {
            if (obj.id == id) {
              return { ...obj, is_pinned: "0" };
            }
            return obj;
          });
          let pinnedNote = newNotes.filter((note) => note.is_pinned == "1");
          let unpinnedNote = newNotes.filter((note) => note.is_pinned == "0");
          setNotes(pinnedNote.concat(unpinnedNote));
        }
      });
    }
  };
  const convertToCheckList = (id) => {
    CustomerServices.convertToChecklist(projectId, id, task_id).then((res) => {
      if (res.status) {
        setNotes(notes.filter((note) => note.id !== id));
        dispatch(getTaskChecklist(task_id, projectId));
      }
    });
  };
  const openNotesToastClose = () => {
    setToastClass("unsucess");
    setToastHeader("");
    setConfirmButtonText("");
    setCancelButtonText("");
    setToastMessage(0);
    setNoteId(0);
    setShowToast(false);
  };
  return (
    <>
      {showToast && !toastdata ? (
        <Toasts
          delay={2000}
          handler={() => {
            setShowToast(false);
            setNoteId(0);
          }}
          header={toastHeader}
          message={toastMessage}
          toastClass={toastClass}
          cancelButtonText={cancelButtonText}
          cancelButtonHandler={() => {
            setShowToast(false);
            setNoteId(0);
          }}
          confirmButtonText={confirmButtonText}
          confirmButtonHandler={() => {
            handleDeleteNote(noteId);
            setShowToast(false);
            setNoteId(0);
          }}
        />
      ) : (
        <></>
      )}
      <div className="comment-input-box">
        <input
          className="form-control height-45 border-0"
          type="text"
          placeholder={`${_l('l_create_notes')}`}
          value={noteItem}
          onChange={(e) => {
            setNoteItem(e.target.value);
            if (e.target.value != '') {
              setDisableOnClickEvent(e.target.value.replace(/[^a-zA-Z0-9^a-zA-Z0-9\ \,.;]/ig,
                ""))
            }
            if (!e.target.value && noteId > 0) setNoteId(0);
          }}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              if (disableOnClickEvent != '') {
                setDisableOnClickEvent('')
              }
              // handleAddNote();
            }
          }}
          aria-label="default input example"
          ref={inputReference}
        />
        <a href="#/" className="send-arrow-btn" onClick={() => {
          if (disableOnClickEvent != '') {
            setDisableOnClickEvent('')
          }
          // handleAddNote()
        }}>
          <ArrowRight size={18}  weight="light" className="c-icons" />
        </a>
      </div>
      <div className="task-checklist-wrapper">
        <div className="row">
          {notes.map((note, index) => {
            const is_pinned = note.is_pinned == "1" ? _l('l_unpin') : _l('l_pin');
            const pinIcon = note.is_pinned == "1" ? PushPinIcon : PushPinFillIcon;
            return (
              <div key={index} className="col-xl-4">
                <div className="note-listing-main">
                  <div className="note-title">
                    <div className="d-flex justify-content-between">
                      <div className="note-title c-font f-16 fs fw-semibold title-fonts text-break">
                        {note.description}
                        {/* Note {index + 1} */}
                      </div>
                      <div className="note-options">
                        <div className="d-flex align-items-center">
                          <a href="#/"
                            className={`${note.is_pinned == "1" ? "" : "d-none"
                              }`}
                          >
                            <img
                              src={
                                note.is_pinned == "1" ? PushPinFillIcon : PushPinIcon
                              }
                              alt={"PushPinFillIcon"}
                              className="img-width-20 "
                            />
                          </a>
                          {(note.is_added_from_contact == 1 &&
                            note.addedfrom == contactId) || (note.is_allowed_to_edit == 1) ? (
                            <div className="dropend">
                              <a href="#/"
                                type="button"
                                id="PinEditDeleteDropdown"
                                data-bs-toggle="dropdown"
                                aria-expanded="outside"
                                data-tip={_l("l_options")}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                              >
                                <DotsThreeVertical size={18}  weight="light" className="c-icons" />
                              </a>
                              <ActionDropdown
                                actions={[
                                  {
                                    actionName: is_pinned,
                                    actionHandler: () => {
                                      handlePinnedNote(note.id, is_pinned);
                                    },
                                    actionIcon: "pinIcon",
                                  },
                                  {
                                    actionName: _l('l_edit'),
                                    actionHandler: () => {
                                      handleEditNote(note.id);
                                      setDisableOnClickEvent(note.id)
                                    },
                                    actionIcon: "PencilSimpleLineIcon",
                                  },
                                  {
                                    actionName: _l('l_convert_to_checklist'),
                                    actionHandler: () => {
                                      convertToCheckList(note.id);
                                    },
                                    actionIcon: "Checklist",
                                  },
                                  {
                                    actionName: _l('l_delete'),
                                    actionHandler: () => {
                                      setToastHeader(_l('l_are_you_sure'));
                                      setToastClass("toast-with-btn");
                                      setToastMessage(
                                        _l('l_you_want_to_delete_this_note')
                                      );
                                      setConfirmButtonText(_l('l_delete'));
                                      setCancelButtonText(_l('l_cancel'));
                                      setShowToast(true);
                                      toastClose()
                                      // dispatch(IsToastOpenOrClose(true))
                                      dispatch(IsToastOpenOrClose(false))
                                      setNoteId(note.id);
                                      // handleDeleteNote(note.id);
                                    },
                                    actionIcon: "TrashIcon",
                                  },

                                ]}
                              />
                              {/* <DeleteDropdown
                              handleEdit={() => {
                                handleEditNote(note.id);
                              }}
                              handleDelete={() => {
                                handleDeleteNote(note.id);
                              }}
                              edit={true}
                            /> */}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="note-discription color-white-60 c-font f-14 text-break"></div>
                </div>
              </div>
            );
          })}

        </div>
        {notes && notes.length == 0 ? (
          <CommanPlaceholder imgType="no-notes" placeholderText = {_l('l_no_notes')} />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default NotesComponent;
