import React, { useEffect, useState } from 'react'
import { _l, getDaysBetweenDates, getHoursBetweenDates, getMintuesBetweenDates, getMonthsBetweenDates, getYearsBetweenDates } from '../../hooks/utilities';
import { Button, Modal, Spinner } from 'react-bootstrap';
import CommanPlaceholder from '../Placeholder/CommanPlaceholder';
import benchmarkServices from '../../services/benchmark-services';
import ActivityLogSkeleton from '../Skeleton/ActivityLogSkeleton';
import EmailSentTableSkeleton from '../Skeleton/EmailSentTableSkeleton';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../actions/messages';
import moment from 'moment';
import { Eye } from 'phosphor-react';

const ActivityOrEmailTrackingModal = ({handleClose, proposalModal}) => {
    const [dataItems, setDataItems] = useState([]);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [emailTracking, setemailTracking] = useState({})

    const dispatch = useDispatch();
    useEffect(() => {
        try {
            setSkeletonLoader(true);
            if (proposalModal.email_sent) {
                if (proposalModal) {
                    benchmarkServices.emailSentProposal(proposalModal.proposal.proposal_id, proposalModal.proposal.id).then((res) => {
                      if (res && res.status) {
                         setDataItems(res.data &&  res.data.email ? res.data.email : []);
                         setSkeletonLoader(false);
                        }else{
                          setSkeletonLoader(false);
                          dispatch(showMessage("error", _l("l_error"), res.message));
                      }
                    })
                }
            }else if (proposalModal.email_tracking) {
                if (proposalModal) {
                    benchmarkServices.emailTrackingProposal(proposalModal.proposal.rel_id, proposalModal.proposal.id).then((res) => {
                    if (res && res.status) {
                        setDataItems(res.data &&  res.data.view_track ? res.data.view_track : []);
                        setSkeletonLoader(false);
                    }else{
                        setSkeletonLoader(false);
                        dispatch(showMessage("error", _l("l_error"), res.message));
                    }
                    })
                }
            }else if (proposalModal) {
                    benchmarkServices.activityLogProposal(proposalModal.proposal.proposal_id, proposalModal.proposal.id).then((res) => {
                      if (res && res.status) {
                        setDataItems(res.data);
                        setSkeletonLoader(false);
                      }else{
                        setSkeletonLoader(false);
                        dispatch(showMessage("error", _l("l_error"), res.message));
                    }
                    })
                }
        } catch (error) {
         
        }
    }, [])
 
  return (
    <>
    <Modal
        show={true}
        onHide={handleClose}
        backdrop={"static"}
        keyboard={false}
        size="xl"
        centered
        className="custom-modal-style solarcommanmodal maxheight85vh "
        aria-labelledby="contained-modal-title-vcenter"
        >
        <Modal.Header className="p-3 d-flex align-items-center justify-content-between" closeButton>
            <Modal.Title>{proposalModal.email_tracking ? _l("l_email_tracking") :proposalModal.email_sent ?  `${_l("l_email")} ${_l("l_sent")}` :_l("l_activity_log")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`overflow-hiiden-auto minheight200px ${proposalModal.email_tracking || proposalModal.email_sent ? 'p-20pximp pt-0' :'border px-5 pb-5'} `}>
            {proposalModal.email_sent ? 
            <div className='comman-data-table '>
                <div className='bg-white position-sticky pt-20px top-0 z-index-2'></div>
                <table className="dataTable text-center tablewithborder border-top-0">
                    <thead className="" style={{top: '20px'}}>
                        <tr>
                            <th style={{ width: "20%" }} className="text-start">{_l("l_date")}</th>
                            <th style={{ width: "20%" }} className="text-start">{_l("l_subject")}</th>
                            <th style={{ width: "30%" }} className="text-start">{_l("l_to")}</th>
                            <th style={{ width: "15%" }} className="text-start">{_l("l_opened")}</th>
                            <th style={{ width: "2%" }} className="text-start">Suivie</th>
                        </tr>
                    </thead>
                    <tbody style={{ verticalAlign: "middle" }}>
                    {skeletonLoader 
                        ? <EmailSentTableSkeleton /> 
                        : dataItems && dataItems.length > 0 ?
                        dataItems.sort((a, b) => new Date(a.date) - new Date(b.date)).map((item, index) => {
                           return (
                            <tr>
                                <td className='text-start'>{moment(item.date).format("DD/MM/YYYY hh:mm A")}</td>
                                <td className='max-width-200px text-start text-truncate'> {item.subject}</td>
                                <td className='text-start'>{item.email}</td>
                                <td className='d-flex gap-2 text-start' >
                                    {item.opened == 1 ?
                                        <div className='color-greenpill'>
                                            Opened
                                        </div>
                                        : <div className='color-red'>
                                            Not opened
                                        </div>
                                    }
                                </td>
                                <td className='text-center' >
                                    {item.opened == 1 
                                    &&
                                        <a 
                                            onClick={(() => setemailTracking({ proposal: item, email_tracking: true }))}>
                                            <Eye size={18}
                                                weight="light"
                                                className="c-icons"
                                            />
                                        </a>
                                    }
                                </td>
                            </tr>
                            )
                        }) : 
                        <tr>
                            <td colSpan={5} className="">
                                <CommanPlaceholder imgType="request" placeholderText={_l("l_no") + '' + _l("l_email")} />
                            </td>
                        </tr> 
                        }
                    </tbody>

                </table>
            </div>
            :
            <>
                {skeletonLoader ? <ActivityLogSkeleton is_Email_tracking = {proposalModal.email_tracking}/> : dataItems && dataItems.length > 0 ?
                dataItems.sort((a, b) => new Date(a.date) - new Date(b.date)).map((item, index) => {
                    const date = (date) => {
                        const years =
                            date && date != null 
                            ? getYearsBetweenDates(date)
                            : "";
                        const months =
                            date && date != null 
                            ? getMonthsBetweenDates(new Date(date))
                            : "";
                        const days =
                            date && date != null
                            ? getDaysBetweenDates(date)
                            : "";
                        const hours =
                            date && date != null
                            ? getHoursBetweenDates(date)
                            : "";
                        const mintues =
                            date && date != null
                            ? getMintuesBetweenDates(date)
                            : "";
                        return years ? `${years} Years ago` : months ? `${months} Months ago ` : days ? `${days} Days ago` : hours ? `${hours} Hours ago` : mintues ? `${mintues}  Minute ago` : "just Now"
                    } 
                return (
                <>
                <div className={`position-relative ${proposalModal.email_tracking || proposalModal.email_sent ? 'border-bottom py-10px' :'timelinemain '}`}>

                    <div className="d-flex justify-content-between flex-column">
                    <div className="d-flex">
                        <div className="align-items-center flex-grow-1 gap10px">
                            <div className={`d-flex my-2 w-auto ${proposalModal.email_tracking  ? '' :'ml-10 '}`}>
                                {proposalModal.email_tracking ? 
                                <div className="c-font color-blacl-70 f-11 border-bottom-dashed fw-bold"> Date : {moment(item.date).format("DD/MM/YYYY hh:mm A")}</div>
                                :
                                <div className="c-font color-blacl-70 f-11 border-bottom-dashed fw-bold">{date(moment(item.date).format())}</div>
                                }
                            </div>
                            <div className='d-flex gap10px'>
                                {proposalModal.email_tracking 
                                    ? <></> 
                                    :<div className="comman-image-box h32w32 rounded-circle bg-black-op5">
                                        <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{backgroundImage: `url(${item.profile_img})`}}>
                                        </div>
                                    </div>
                                }
                                <div className="d-flex align-items-center justify-content-between flex-grow-1">
                                    <div className="d-flex flex-column w-100">
                                        {proposalModal.email_tracking  
                                            ?<div className='d-flex gap-1'> 
                                                <div className="c-font f-13 ">IP Address : {_l(item.view_ip)}</div>
                                            </div> 
                                            :
                                            <div className='d-flex gap-1'>
                                                <div className="c-font f-13 fw-semibold">{item.full_name}</div> <div className="c-font f-13 "> - {_l(item.description)}</div>
                                                {
                                                    item.description == 'l_proposal_item_updated' 
                                                        ? <div className="c-font f-13 "> - {_l(item.additional_data && item.additional_data.name)}</div> 
                                                        : item.description == 'l_proposal_item_added' 
                                                            ? <div className="c-font f-13 "> - {_l(item.additional_data && item.additional_data.item_name)}</div>
                                                            : item.description == 'l_provider_proposal_amount_update' 
                                                                ? <div className="c-font f-13 "> - {_l(item.additional_data && item.additional_data.amount)}</div>
                                                                : item.description == 'l_provider_proposal_amount_added' 
                                                                    ? <div className="c-font f-13 "> - {_l(item.additional_data && item.additional_data.amount)}</div> 
                                                                    : item.description == 'l_proposal_item_price_updated' 
                                                                        ?<div className="d-flex"> 
                                                                            <div className="c-font f-13 fw-semibold">{item.additional_data.name}</div> 
                                                                            <div className="c-font f-13 ">{ `- From ${item.additional_data.old_amount} to ${item.additional_data.new_amount}`}</div>
                                                                        </div> 
                                                                        : <></>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>                 
                </>
                )})
                : 
                <div className="align-item-center d-flex h-100 justify-content-center">
                <CommanPlaceholder mainWidth={`calednarPlacehoder`} imgType="request" placeholderText={proposalModal.email_tracking ? _l("l_no") + '' + _l("l_email") : _l("l_no_activity_logs")} />
                </div>
            }
            </>
          }
        </Modal.Body>
    </Modal>
    { emailTracking.email_tracking ?
        <ActivityOrEmailTrackingModal 
          handleClose={ () => {setemailTracking({modal : false})}}
          proposalModal={emailTracking}
         />
        :<></>
      }
    </>
  );
}

export default ActivityOrEmailTrackingModal