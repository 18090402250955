import React, { useEffect, useRef, useState } from "react";
import {
  X,
  ArrowRight,
  DotsThreeVertical,
  Paperclip,
  Image,
  LinkSimple,
} from "phosphor-react";
import CustomerServices from "../../services/customer-services";
import { MentionsInput, Mention } from "react-mentions";
import {
  _l,
  getImagePreviewIcon,
  getPreviewFromURL,
} from "../../hooks/utilities";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";
import Toasts from "../Toasts/Toasts";
import CommanLoader from "../Loader/CommanLoader";
import ActionDropdown from "./ActionDropdown";
import { useDispatch } from "react-redux";
import { showMessage } from "../../actions/messages";
import { UsersThree } from "phosphor-react";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { DropdownMenu, DropdownItem } from "reactstrap";
import moment from "moment";
import { mentionStyle } from "../../assets/mention/mentionStyle";
import Lightbox from "yet-another-react-lightbox";
import { Spinner } from "react-bootstrap";
import { updateedTaskChecklist } from "../../actions/customer";
import benchmarkServices from "../../services/benchmark-services";

let fileUplods = [];

const CommentDropdown = ({
  openCommentTab,
  setOpenCommentTab,
  task_id,
  projectId,
  updatedCommentCount,
  checklistId = 0,
  Request_task_converted = false,
  callbackFunction,
  allComment = 0,
  data,
  docType,
  actionStausChange,
  action = false,
  bulkAction = false,
  unContolList = false,
  is_liteversion = false,
  checklistItems = [],
  setChecklistItems = () => {},
  CommentFile = [],
  setCommentFile = () => {},
  isExternal = false,
  hash_id = 0,
  mainClassName = "",
}) => {
  const [commentList, setCommentList] = useState([]);
  const [commentContent, setCommentContent] = useState("");
  const [mentionList, setMentionList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const mentionsList = useSelector((state) => state.customer.mentionList);
  const [imagePreviews, setImagePreviews] = useState([]);

  const contact_id = localStorage.getItem("contact_id");
  const contact_image = localStorage.getItem("contact_image");
  const userName = localStorage.getItem("full_name");
  const [toastHeader, setToastHeader] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [confirmButtonText, setConfirmButtonText] = useState("");
  const [cancelButtonText, setCancelButtonText] = useState("");
  const [toastClass, setToastClass] = useState("unsucess");
  const [randomID, setRandomID] = useState(String(Math.random()));
  const [showToast, setShowToast] = useState(false);
  const [editcommentId, setEditcommentId] = useState(0);

  const validCharacterForComment =
    /^[-a-zA-Z0-9@:%._\+~#=]\.[a-z]{([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  const dispatch = useDispatch();

  const [previewSrc, setPreviewSrc] = useState([]);
  const [openGallery, setOpenGallery] = useState(false);
  const [showTaskImage, setShowTaskImage] = useState(false);
  const [attachmentDetails, setAttachmentDetails] = useState({});
  const [selectedAttachmentId, setSelectedAttachmentId] = useState(0);
  const [allAttachments, setAllAttachments] = useState([]);
  const dropdownRef = useRef(null);
  const selectedTask = useSelector((state) => state.customer.selectedTask);
  const [fileUploadLoader, setFileUploadLoader] = useState(false);
  const checklistStateData = useSelector(
    (state) => state.customer.taskChecklist
  );

  const addNewComment = (event, is_link = 0) => {
    if (action) {
      actionStausChange(0, commentContent.trim(), "comment", fileUplods);
    } else if (is_liteversion) {
      var current = new Date();
      let pattern = commentContent.match(
        /^((http|https)\:\/\/)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      let htmlPattern = commentContent.match("<(\"[^\"]*\"|'[^']*'|[^'\">])*>");
      let webPattern = commentContent.match(/^www\.([A-z]+)\.([A-z]{2,})/g);

      if (event.detail === 1 || event.keyCode === 13) {
        if (pattern || !webPattern) {
          if (commentContent.trim()) {
            setCommentList((commentList) => [
              ...commentList,
              {
                content: commentContent.trim(),
                contact_id,
                id: commentList.length + 1,
                addedfrom_details: {
                  profile_url: contact_image,
                  full_name: userName,
                },
                time: current.toLocaleString(),
                staffid: localStorage.getItem("staff_id"),
                is_link: is_link == 1 ? 1 : 0,
              },
            ]);
            setCommentContent("");
            updatedCommentCount(commentList.length + 1);
            let tempChecklist = checklistStateData.slice();
            let key = tempChecklist.findIndex((c) => c.id == checklistId);
            if (key > -1) {
              tempChecklist[key].comment_count += 1;
            }
            dispatch(updateedTaskChecklist(tempChecklist));

            let index = checklistItems.findIndex(
              (item) => item.id == checklistId
            );
            let updatedChecklist = checklistItems[index];
            updatedChecklist.comments = [
              ...commentList,
              {
                content: commentContent.trim(),
                id: commentList.length + 1,
                addedfrom_details: {
                  profile_url: contact_image,
                  full_name: userName,
                },
                time: current.toLocaleString(),
                staffid: localStorage.getItem("staff_id"),
                is_link: is_link == 1 ? 1 : 0,
              },
            ];
            setChecklistItems(
              Object.values({ ...checklistItems, [index]: updatedChecklist })
            );
          }
        } else if (commentContent == "" || !fileUplods) {
          setCommentContent("");
          setToastHeader(_l("l_error"));
          setToastMessage(_l("l_please_enter_valid_comment"));
          setConfirmButtonText("");
          setCancelButtonText("");
          setToastClass("unsucess");
          setShowToast(true);
        } else {
          setCommentContent("");
          setToastHeader(_l("l_error"));
          setToastMessage(
            _l(
              pattern === null && htmlPattern === null
                ? "l_please_enter_valid_link"
                : "l_please_enter_valid_comment"
            )
          );
          setConfirmButtonText("");
          setCancelButtonText("");
          setToastClass("unsucess");
          setShowToast(true);
        }
      }
    } else {
      if (event.detail === 1 || event.keyCode === 13) {
        let pattern = commentContent.match(
          /^((http|https)\:\/\/)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        let htmlPattern = commentContent.match(
          "<(\"[^\"]*\"|'[^']*'|[^'\">])*>"
        );
        let webPattern = commentContent.match(/^www\.([A-z]+)\.([A-z]{2,})/g);
        if (is_link == 1) {
          if (!pattern) {
            setToastHeader(_l("l_error"));
            setToastMessage(_l("l_please_enter_valid_link"));
            setConfirmButtonText("");
            setCancelButtonText("");
            setToastClass("unsucess");
            setShowToast(true);
            return false;
          }
        }
        if (pattern || !webPattern) {
          if (commentContent.trim()) {
            if (editcommentId > 0) {
              CustomerServices.addLinkComment(
                task_id,
                projectId,
                commentContent.trim(),
                is_link == 1 ? 1 : undefined,
                checklistId,
                "",
                0,
                undefined,
                0,
                "",
                selectedTask.hash,
                0,
                "",
                "",
                0,
                true,
                0,
                editcommentId
              ).then((res) => {
                if (res.status) {
                  let commentUpdate = commentList.map((comment) => {
                    if (comment.id == editcommentId) {
                      if (fileUplods.length) {
                        let tempImage = comment.attachments.length
                          ? comment.attachments
                          : [];
                        if (res.data.files_list && res.data.files_list.length) {
                          res.data.files_list.map((img) => {
                            tempImage.push({
                              id: img.file_id,
                              file_name: img.image_url,
                            });
                          });
                        }
                        comment.attachments = tempImage;
                      }
                      comment["content"] =
                        commentContent != "" ? commentContent : "";
                      comment["description"] =
                        commentContent != "" ? commentContent : "";
                      return comment;
                    }
                    return comment;
                  });

                  setCommentList(commentUpdate);
                  setCommentContent("");
                }
              });
            } else {
              CustomerServices.addLinkComment(
                task_id,
                projectId,
                commentContent.trim(),
                is_link == 1 ? 1 : undefined,
                checklistId,
                "",
                0,
                undefined,
                0,
                "",
                isExternal ? hash_id : 0,
                0,
                "",
                "",
                0,
                isExternal
              ).then((res) => {
                if (res.status) {
                  setCommentList((commentList) => [
                    ...commentList,
                    {
                      content: commentContent.trim(),
                      contact_id,
                      id: res.data.comment_id,
                      addedfrom_details: {
                        profile_url: contact_image,
                        full_name: userName,
                      },
                      time: res.data.time,
                      staffid: localStorage.getItem("staff_id"),
                      is_link: is_link == 1 ? 1 : 0,
                    },
                  ]);
                  setCommentContent("");
                  updatedCommentCount(commentList.length + 1);
                  let tempChecklist = checklistStateData.slice();
                  let key = tempChecklist.findIndex((c) => c.id == checklistId);
                  if (key > -1) {
                    tempChecklist[key].comment_count += 1;
                  }
                  dispatch(updateedTaskChecklist(tempChecklist));
                }
              });
            }
          }
        } else if (commentContent == "" || !fileUplods) {
          setCommentContent("");
          setToastHeader(_l("l_error"));
          setToastMessage(_l("l_please_enter_valid_comment"));
          setConfirmButtonText("");
          setCancelButtonText("");
          setToastClass("unsucess");
          setShowToast(true);
        } else {
          setCommentContent("");
          setToastHeader(_l("l_error"));
          setToastMessage(
            _l(
              pattern === null && htmlPattern === null
                ? "l_please_enter_valid_link"
                : "l_please_enter_valid_comment"
            )
          );
          setConfirmButtonText("");
          setCancelButtonText("");
          setToastClass("unsucess");
          setShowToast(true);
        }
      }
    }
  };

  const preparePreview = (files) => {
    let previrewArray = [];
    Array.from(files).map((img) => {
      let preview_url = getImagePreviewIcon(img);
      previrewArray.push({ name: img.name, url: preview_url });
    });
    fileUplods = files;
    setImagePreviews(previrewArray);
  };
  const preImagePreview = (files) => {
    let previrewArray = [];
    Array.from(files).map((img) => {
      let preview_url = getImagePreviewIcon(img);
      previrewArray.push({ name: img.name, url: preview_url });
    });
    fileUplods = files;
    setImagePreviews(previrewArray);
  };

  const removeImageSelection = (name) => {
    imagePreviews.forEach((thumbnail) => {
      URL.revokeObjectURL(thumbnail.url);
    });
    const updatedFileList = Array.from(fileUplods).filter(
      (img) => img.name != name
    );

    // setImageBuffer(updatedFileList);
    preparePreview(updatedFileList);
    preImagePreview(updatedFileList);
  };

  const handleDeleteAction = (value) => {
    // benchmarkServices.deleteChecklistComment( checklistId,value.id ,"task").then((res)=>{
    //   if (res.status) {
    //     setCommentList(commentList.filter((comment) => comment.id != value.id));
    //   }
    // })
    CustomerServices.deleteExternalTaskDocumentAndComment(
      value.id
    ).then((res) => {
      if (res.status) {
        updatedCommentCount(commentList.length - 1);
            let tempChecklist = checklistStateData.slice();
            let key = tempChecklist.findIndex((c) => c.id == checklistId);
            if (key > -1) {
              tempChecklist[key].comment_count -= 1;
            }
            dispatch(updateedTaskChecklist(tempChecklist));
        setCommentList(commentList.filter((comment) => comment.id != value.id));
      }
    });
  };
  const handleEditAction = (id) => {
    const targetComment = commentList.filter((comment) => comment.id == id);
    if (targetComment.length) {
      setCommentContent(
        targetComment[0].content ||
          targetComment[0].comment ||
          targetComment[0].description
      );
    }
  };

  useEffect(() => {
    setCommentContent("");
    if (task_id && openCommentTab) {
      setShowLoader(true);
      CustomerServices.getComments(
        task_id,
        projectId,
        checklistId,
        0,
        allComment == 1 ? 1 : 0,
        0,
        "",
        isExternal ? hash_id : "",
        1,
        0,
        0
      ).then((res) => {
        setShowLoader(false);
        const comments = res.data;
        setCommentList([]);
        comments &&
          comments.length > 0 &&
          comments.forEach((item) => {
            setCommentList((commentList) => [...commentList, item]);
          });
        if (callbackFunction) {
          callbackFunction();
        }
      });
    }

    return () => {
      //Comment this statement if it is not required to call an API everytime user clicks on the component
      if (setOpenCommentTab) setOpenCommentTab(false);
    };
  }, [openCommentTab, task_id, projectId]);

  useEffect(() => {
    if (mentionsList) {
      setMentionList(
        mentionsList.map((name) => {
          let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
          return {
            display: name.name,
            id: id,
            image: name.profile_image || "",
            isteam: name.is_team ? 1 : 0,
          };
        })
      );
    }
  }, [mentionsList]);

  const uploadFile = (files) => {
    setFileUploadLoader(true);
    if (is_liteversion) {
      var current = new Date();
      let preview_url = "";
      let commentsfiles = [];
      let attachments = [];

      Array.from(files).map((img) => {
        preview_url = getImagePreviewIcon(img);
        let tempImg = img;
        tempImg.checklist_key =
          "checklist_" + moment(new Date()).format("YYYY_MM_DD_HH_mm_ss");
        commentsfiles.push(tempImg);
        attachments.push({
          file_url: URL.createObjectURL(img),
          file_name: img.name,
          checklist_key:
            "checklist_" + moment(new Date()).format("YYYY_MM_DD_HH_mm_ss"),
        });
      });
      setImagePreviews([]);
      setCommentList((commentList) => [
        ...commentList,
        {
          attachments: attachments,
          content: "",
          contact_id,
          id: commentList.length + 1,
          addedfrom_details: {
            profile_url: contact_image,
            full_name: userName,
          },
          time: current.toLocaleString(),
          staffid: localStorage.getItem("staff_id"),
        },
      ]);

      let index = checklistItems.findIndex((item) => item.id == checklistId);
      let updatedChecklist = checklistItems[index];
      updatedChecklist.comments = [
        ...commentList,
        {
          content: commentContent.trim(),
          id: commentList.length + 1,
          addedfrom_details: {
            profile_url: contact_image,
            full_name: userName,
          },
          time: current.toLocaleString(),
          staffid: localStorage.getItem("staff_id"),
          is_link: 0,
          attachments: attachments,
        },
      ];
      setChecklistItems(
        Object.values({ ...checklistItems, [index]: updatedChecklist })
      );
      setCommentContent("");
      updatedCommentCount(commentList.length + 1);
      setCommentFile(CommentFile.concat(commentsfiles));
    } else {
      CustomerServices.addLinkComment(
        task_id,
        projectId,
        "",
        undefined,
        checklistId,
        0,
        0,
        files,
        0,
        "",
        isExternal ? selectedTask.hash : 0,
        0,
        0,
        "",
        0,
        isExternal,
        0,
        0
      ).then((res) => {
        if (res.status) {
          setFileUploadLoader(false);
          setImagePreviews([]);
          setCommentList((commentList) => [
            ...commentList,
            {
              attachments: [
                {
                  id:
                    res.data.files_list && res.data.files_list.length
                      ? res.data.files_list[0].file_id
                      : 0,
                  file_name:
                    res.data.files_list && res.data.files_list.length
                      ? res.data.files_list[0].image_url
                      : "",
                  file_type:
                    res.data.files_list && res.data.files_list.length
                      ? res.data.files_list[0].file_type
                      : "",
                },
              ],
              content: "",
              contact_id,
              id: res.data.comment_id,
              addedfrom_details: {
                profile_url: contact_image,
                full_name: userName,
              },
              time: res.data.time,
              staffid: localStorage.getItem("staff_id"),
            },
          ]);
          setCommentContent("");
          updatedCommentCount(commentList.length + 1);
          let tempChecklist = checklistStateData.slice();
          let key = tempChecklist.findIndex((c) => c.id == checklistId);
          if (key > -1) {
            tempChecklist[key].comment_count +=
              res.data.files_list && res.data.files_list.length;
            let attachments = [...tempChecklist[key].attachments, files];
            tempChecklist[key].attachments = attachments;
          }
          dispatch(updateedTaskChecklist(tempChecklist));
        } else {
          setFileUploadLoader(false);
        }
      });
    }
  };

  useEffect(() => {
    if (commentList && commentList.length > 0) {
      commentList.map((item) => {
        if (item.attachments && item.attachments.length > 0) {
          item.attachments.map((item2) => {
            setAllAttachments((allAttachments) => [
              ...allAttachments,
              {
                id: item2.id,
                task_id: data ? data.id : 0,
                task_comment_id: item.id,
                image_url: item2.file_name,
                dateadded: item.dateadded,
              },
            ]);
          });
        }
      });
    }
    scrollToBottom();
  }, [commentList]);

  const scrollToBottom = () => {
    if (commentList.length) {
      var cmntListDiv = document.getElementById(
        "comment-list-dropdown-" + task_id
      );
      try {
        if (cmntListDiv.scrollHeight && cmntListDiv.scrollHeight != null)
          cmntListDiv.scrollTop = cmntListDiv.scrollHeight
            ? cmntListDiv.scrollHeight
            : 0;
      } catch (err) {
        console.log(err);
      }
    }
  };
  const setAttchmentOnTop = (id) => {
    const targetAttchement = allAttachments.filter(
      (attachment) => attachment.id === id
    );
    const otherAttachments = allAttachments.filter(
      (attachment) => attachment.id != id
    );
    setAllAttachments(targetAttchement.concat(otherAttachments));
  };
  const CommentDropdownContain = (
    <>
      {bulkAction ? (
        <></>
      ) : (
        <>
          <ul className="dropdown-head">
            <li>
              <div className="dropdown-header">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="c-list-detail title-fonts text-truncate color-white c-font f-13">
                    {_l("l_comments")}
                  </div>
                  <a
                    href="#/"
                    className="right-image d-none"
                    data-bs-dismiss="dropdown"
                  >
                    <X size={18} className="c-icons" weight="light" />
                  </a>
                </div>
              </div>
            </li>
            <li>
              <hr className="dropdown-divider mt-0" />
            </li>
            <li></li>
          </ul>

          <ul
            className="dropdown-contain overflowscroll comment-dropdown-with-min-height-20vh"
            id={`comment-list-dropdown-${task_id}`}
          >
            {showLoader ? (
              <div>
                <CommanLoader
                  className={`dropdown-loader comman-main-loader-wrapper`}
                />
              </div>
            ) : (
              <>
                <div className="file-list-wrapper ">
                  <div className="d-flex flex-wrap">
                    {commentList.length ? (
                      commentList.map((comment, index) => {
                        let pattern = comment.content.match(
                          /^((http|https)\:\/\/)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
                        );
                        let className =
                          localStorage.getItem("is_external") == 1 &&
                          comment.contact_id ==
                            localStorage.getItem("contact_id")
                            ? "right-side-msg"
                            : localStorage.getItem("user_type") == "contact"
                            ? contact_id !== comment.contact_id
                              ? ""
                              : ""
                            : localStorage.getItem("staff_id") ==
                              comment.staffid
                            ? ""
                            : "";

                        const commentContent = comment.content;
                        const dummyDiv = document.createElement("div");
                        dummyDiv.innerHTML = commentContent;
                        return (
                          <>
                            {allComment &&
                            comment.attachments &&
                            comment.attachments.length ? (
                              <div key={index} className="chat-wrapper w-100">
                                <div
                                  className={`chat-box max-w-100 ${className}`}
                                >
                                  <div className="d-flex position-relative">
                                    <div
                                      className="comman-image-box h25w25 rounded-circle me-2"
                                      data-tip={comment.contact_full_name}
                                      data-effect="solid"
                                      data-delay-show="1000"
                                      data-class="tooltip-main"
                                    >
                                      <div
                                        className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                        style={{
                                          backgroundImage: `url('${comment.addedfrom_details.profile_url}')`,
                                        }}
                                      ></div>
                                    </div>
                                    {comment.attachments &&
                                    comment.attachments.length ? (
                                      <div className="d-flex flex-wrap flex-column">
                                        {comment.attachments.map(
                                          (image, index) => {
                                            if (index < 3) {
                                              return (
                                                <a
                                                  href="#/"
                                                  key={index}
                                                  className="h70w70 comman-round-box with-bg d-flex align-items-center bg-white-05 p-5px radius_3 m-auto mb-2"
                                                  onClick={() => {
                                                    if (image) {
                                                      setShowTaskImage(true);
                                                      setAttachmentDetails({
                                                        id: image.id,
                                                        task_id: data
                                                          ? data.id
                                                          : 0,
                                                        task_comment_id:
                                                          comment.id,
                                                        image_url:
                                                          image.file_name,
                                                        dateadded:
                                                          comment.dateadded,
                                                      });
                                                      setAttchmentOnTop(
                                                        image.id
                                                      );
                                                    } else {
                                                      setOpenGallery(true);
                                                      let selectAttachment =
                                                        comment.attachments.filter(
                                                          (file, innerIndex) =>
                                                            innerIndex == index
                                                        );
                                                      let otherAttachment =
                                                        comment.attachments.filter(
                                                          (file, innerIndex) =>
                                                            innerIndex != index
                                                        );
                                                      setPreviewSrc(
                                                        selectAttachment
                                                          .concat(
                                                            otherAttachment
                                                          )
                                                          .map((src) => {
                                                            return {
                                                              src: src.file_name,
                                                            };
                                                          })
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <DropdownItem
                                                    toggle={true}
                                                    tag="div"
                                                    className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                                                    style={{
                                                      backgroundImage: `url('${
                                                        image.file_url ||
                                                        image.name ||
                                                        image.file_name
                                                      }')`,
                                                    }}
                                                  ></DropdownItem>
                                                </a>
                                              );
                                            } else if (index === 3) {
                                              return (
                                                <a
                                                  href="#/"
                                                  className="h70w70 comman-round-box with-bg d-flex align-items-center bg-white-05 p-5px radius_3 m-auto mb-2 with_overlay"
                                                  onClick={() => {
                                                    setAttchmentOnTop(image.id);
                                                  }}
                                                >
                                                  <div class="unread_count m-auto">
                                                    +
                                                    {comment.attachments
                                                      .length - 3}
                                                  </div>
                                                </a>
                                              );
                                            }
                                          }
                                        )}
                                        <div className="chat-send-name c-font f-10 opacity-50">
                                          <span>{comment.time}</span>
                                        </div>
                                        <ReactTooltip />
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                    <div className={`chat-action-option`}>
                                      <a
                                        href="#/"
                                        className={`line-height-0 `}
                                        type="button"
                                        id="commentOptionDropdown"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        data-tip={_l("l_options")}
                                        data-effect="solid"
                                        data-delay-show="1000"
                                        data-class="tooltip-main"
                                      >
                                        <DotsThreeVertical
                                          size={18}
                                          weight="light"
                                          className="c-icons"
                                        />
                                      </a>
                                      <ActionDropdown
                                        actions={[
                                          {
                                            actionName: _l("l_delete"),
                                            actionHandler: () => {
                                              handleDeleteAction(comment);
                                            },
                                            actionIcon: "TrashIcon",
                                          },
                                        ]}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="h40w40 h-auto pt-2">
                                  <div className="c-font text-truncate f-12 color-white-60 ">
                                    {/* {attachment.file_name} */}
                                  </div>
                                </div>
                                <ReactTooltip />
                              </div>
                            ) : allComment && comment.is_link == 1 ? (
                              <div className="chat-wrapper w-100">
                                <div
                                  className={`chat-box max-w-100 ${className}`}
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="comman-image-box h25w25 rounded-circle">
                                      <div
                                        className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                        style={{
                                          backgroundImage: `url('${comment.addedfrom_details.profile_url}')`,
                                        }}
                                      ></div>
                                    </div>
                                    <div className="chat-msg">
                                      <div className="chat-text-box">
                                        <a
                                          href={comment.content}
                                          className="chat-text c-font f-12 on-hover-color-green"
                                          target={"_blank"}
                                          rel="noopener noreferrer"
                                        >
                                          {comment.content}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <ReactTooltip />
                              </div>
                            ) : (
                              <div key={index} className="chat-wrapper w-100">
                                <div
                                  className={`chat-box max-w-100 ${className}`}
                                >
                                  <div className="d-flex">
                                    <div
                                      className="comman-image-box h25w25 rounded-circle"
                                      data-tip={
                                        comment.guest_user_name
                                          ? comment.guest_user_name
                                          : comment.addedfrom_details.full_name
                                      }
                                      data-effect="solid"
                                      data-delay-show="1000"
                                      data-class="tooltip-main"
                                    >
                                      <div
                                        className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                        style={{
                                          backgroundImage: `url('${comment.addedfrom_details.profile_url}')`,
                                        }}
                                      >
                                        <ReactTooltip />
                                      </div>
                                    </div>
                                    <a
                                      href={pattern}
                                      target={"_blank"}
                                      className="chat-msg flex-grow-1"
                                    >
                                      <div className="chat-text-box">
                                        <div
                                          className="chat-text c-font f-12"
                                          dangerouslySetInnerHTML={{
                                            __html: comment.content,
                                          }}
                                        >
                                          {/* {dummyDiv.innerText} */}
                                        </div>
                                        <div
                                          className={`chat-action-option
                                  `}
                                        >
                                          <a
                                            href="#/"
                                            className={`line-height-0 `}
                                            type="button"
                                            id="commentOptionDropdown"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            data-tip={_l("l_options")}
                                            data-effect="solid"
                                            data-delay-show="1000"
                                            data-class="tooltip-main"
                                          >
                                            <DotsThreeVertical
                                              size={18}
                                              weight="light"
                                              className="c-icons"
                                            />
                                          </a>
                                          <ActionDropdown
                                            actions={[
                                              {
                                                actionName: _l("l_edit"),
                                                actionHandler: () => {
                                                  setEditcommentId(comment.id);
                                                  handleEditAction(comment.id);
                                                },
                                                actionIcon:
                                                  "PencilSimpleLineIcon",
                                              },
                                              {
                                                actionName: _l("l_delete"),
                                                actionHandler: () => {
                                                  handleDeleteAction(comment);
                                                },
                                                actionIcon: "TrashIcon",
                                              },
                                            ]}
                                          />
                                        </div>
                                      </div>

                                      <div className="chat-send-name c-font f-10 opacity-50">
                                        <span className="d-none">
                                          {comment.addedfrom_details.full_name}{" "}
                                          -{" "}
                                        </span>
                                        <span>{comment.time}</span>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                                <ReactTooltip id={randomID} />
                              </div>
                            )}
                          </>
                        );
                      })
                    ) : (
                      <CommanPlaceholder
                        imgType="no-comment"
                        placeholderText={_l("l_no_comments")}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </ul>
          {showToast ? (
            <Toasts
              delay={2000}
              handler={() => {
                setShowToast(false);
              }}
              header={toastHeader}
              message={toastMessage}
              toastClass={toastClass}
              cancelButtonText={cancelButtonText}
              cancelButtonHandler={() => {
                setShowToast(false);
              }}
              confirmButtonText={confirmButtonText}
              confirmButtonHandler={() => {
                setShowToast(false);
              }}
            />
          ) : (
            <></>
          )}
        </>
      )}
      <ul className="dropdown-footer">
        {bulkAction ? (
          <></>
        ) : (
          <li>
            <hr className="dropdown-divider mb-0" />
          </li>
        )}
        <li className="">
          <React.Fragment>
            {imagePreviews && imagePreviews.length ? (
              <div className="upload-image-preview p-2 border-bottom calendar-modal-color">
                <div className="d-flex align-items-center justify-content-start">
                  {imagePreviews.map((preview, index) => {
                    if (typeof preview.url === "string") {
                      return (
                        <div
                          key={index}
                          className="comman-image-box h60w60 radius_3 upload-image with-margin"
                        >
                          <div
                            className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                            style={{
                              backgroundImage: `url(
                                  '${preview.url}'
                                )`,
                            }}
                          ></div>
                          <a
                            href="#/"
                            className="upload-close"
                            onClick={() => {
                              // handleAction('removePreview', preview.name)
                              removeImageSelection(preview.name);
                            }}
                          >
                            <X size={10} weight="light" className="c-icons" />
                          </a>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          key={index}
                          className="comman-image-box h60w60 radius_3 upload-image with-margin d-flex"
                        >
                          <preview.url
                            size={40}
                            weight="light"
                            className="c-icons"
                          />
                          <a
                            href="#/"
                            className="upload-close"
                            onClick={() => {
                              // handleAction('removePreview', preview.name)
                              removeImageSelection(preview.name);
                            }}
                          >
                            <X size={10} weight="light" className="c-icons" />
                          </a>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            ) : (
              <></>
            )}
          </React.Fragment>
          <div className="chat-comment-box comman_action_icon">
            <div className="comment-input-box with-mention-top">
              <div className="d-flex  position-relative form-control border-0 bg-transparent px-2">
                <div
                  data-bs-toggle="tooltip"
                  className="comman-image-box h25w25 rounded-circle"
                >
                  <div
                    className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                    style={{
                      backgroundImage: `url('${contact_image}')`,
                    }}
                  ></div>
                </div>

                <MentionsInput
                  value={commentContent}
                  className="mension-box w100minus100per"
                  onChange={(e) => {
                    setCommentContent(e.target.value);
                  }}
                  placeholder={`${_l("l_write_comment")}`}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      addNewComment(e);
                    }
                  }}
                  disabled={Request_task_converted ? true : false}
                >
                  <Mention
                    markup='<span style="font-weight: 600;" contenteditable="false"   data-mention-id="__id__"   data-mce-style="font-weight: 600;" data-mce-selected="1">@__display__</span>'
                    trigger="@"
                    data={mentionList}
                    displayTransform={(id, display) => `@${display}`}
                    style={mentionStyle}
                    renderSuggestion={(
                      suggestion,
                      search,
                      highlightedDisplay
                    ) => {
                      return (
                        <div className="user d-flex align-items-center">
                          <div className="comman-image-box h25w25 rounded-circle me-2 ">
                            {suggestion.isteam == 1 ? (
                              <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle d-flex">
                                {" "}
                                <UsersThree className="m-auto opacity-60" />{" "}
                              </div>
                            ) : (
                              <div
                                className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                style={{
                                  backgroundImage: `url('${suggestion.image}')`,
                                }}
                              ></div>
                            )}
                          </div>
                          {highlightedDisplay}
                        </div>
                      );
                    }}
                  />
                </MentionsInput>
                {allComment ? (
                  <>
                    <a
                      href="#/"
                      className="action_icon with_bg d-flex me-2 d-none"
                      data-tip={_l("l_add_link")}
                      data-effect="solid"
                      data-delay-show="1000"
                      data-class="tooltip-main"
                      onClick={(e) => {
                        addNewComment(e, 1);
                      }}
                    >
                      <LinkSimple
                        size={18}
                        weight="light"
                        className="c-icons"
                      />
                    </a>
                    <a
                      href="#/"
                      className="position-relative action_icon with_bg d-flex d-none"
                      data-tip={_l("l_upload_file")}
                      data-effect="solid"
                      data-delay-show="1000"
                      data-class="tooltip-main"
                    >
                      <input
                        type="file"
                        accept="image/*"
                        className="absolute-input"
                        onChange={(e) => {
                          if (e.target.files && e.target.files.length <= 5) {
                            // uploadFile(e.target.files);
                            preImagePreview(e.target.files);
                          } else {
                            dispatch(
                              showMessage(
                                "unsucess",
                                _l("l_error"),
                                _l("l_maximum_5_files_are_allowed_at_a_time")
                              )
                            );
                          }
                        }}
                      />
                      <Image size={18} weight="light" className="c-icons" />
                    </a>
                  </>
                ) : (
                  <></>
                )}

                <div className="d-flex">
                  <div
                    className={`comman_action_icon px-2 ${
                      isExternal ? "" : ""
                    }`}
                  >
                    <div className="d-flex align-items-center">
                      <a
                        href="#/"
                        className="action_icon with_bg position-relative"
                        data-tip={_l("l_choose_file")}
                        data-effect="solid"
                        data-delay-show="1000"
                        data-class="tooltip-main"
                      >
                        <input
                          type="file"
                          multiple="multiple"
                          className="absolute-input"
                          accept=".png,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.zip,.rar,.txt,.jpeg,.mp4,.m4r,.m4a,.mp3,.glb"
                          onChange={(event) => {
                            if (event.target.files.length > 4) {
                              dispatch(
                                showMessage(
                                  "unsucess",
                                  _l("l_error"),
                                  _l("l_maximum_4_files_are_allowed_at_a_time")
                                )
                              );
                              return;
                            }
                            preparePreview(event.target.files);
                          }}
                          onClick={(e) => {
                            e.target.value = "";
                          }}
                        />
                        <Paperclip
                          size={18}
                          weight="light"
                          className="c-icons"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="comman_action_icon">
                    {fileUploadLoader ? (
                      <div className="action_icon with_bg justify-content-center align-items-center">
                        {" "}
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                      </div>
                    ) : (
                      <a
                        href="#/"
                        className="action_icon with_bg 1"
                        data-tip={`Add comment`}
                        data-effect="solid"
                        data-delay-show="1000"
                        data-for={randomID}
                        data-class="tooltip-main"
                        onClick={(e) => {
                          commentContent.trim().length == 0 &&
                          (fileUplods || fileUplods.length > 0)
                            ? uploadFile(fileUplods)
                            : addNewComment(e);
                        }}
                      >
                        <ArrowRight
                          size={18}
                          className="c-icons"
                          weight="light"
                        />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="comment-bottom-option d-none"></div>
          </div>
        </li>
      </ul>
      {showTaskImage && !isExternal ? (
        <CommanOffCanvas
          show={showTaskImage}
          handleClose={() => {
            setShowTaskImage(false);
          }}
          data={{
            id: attachmentDetails.id,
            taskId: docType == "imageview" ? data && data.id : 0,
            projectId: localStorage.getItem("selectedOffice"),
            attachmentDetails: attachmentDetails,
            taskDetails: data,
            allAttachments: allAttachments,
          }}
          docType={"imageview"}
          setSelectedId={setSelectedAttachmentId}
          selectedTaskId={data.id}
          handleParentComponentAction={() => {}}
          commentList={commentList}
          isExternal={localStorage.getItem("guest_id") ? true : false}
        />
      ) : (
        <>
          {showTaskImage && (
            <Lightbox
              key={0}
              open={showTaskImage}
              close={() => setShowTaskImage(false)}
              slides={allAttachments.map((img) => {
                return { src: getPreviewFromURL(img.image_url) };
              })}
            />
          )}
        </>
      )}
    </>
  );
  return (
    <>
      {unContolList ? (
        <DropdownMenu
          container="body"
          className={`dropdown-menu h-auto ${mainClassName}`}
          aria-labelledby="writeComMentDropdown"
          id="commentDropDownOpen"
          ref={dropdownRef}
        >
          {CommentDropdownContain}
        </DropdownMenu>
      ) : (
        <ul
          className={`dropdown-menu h-auto ${mainClassName}`}
          aria-labelledby="writeComMentDropdown"
          id="commentDropDownOpen"
          ref={dropdownRef}
        >
          {CommentDropdownContain}
        </ul>
      )}
    </>
  );
};

export default CommentDropdown;
