import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useRef, useState } from "react";
import User_2 from "../../../../assets/images/users/user_2.jpg";
import User_9 from "../../../../assets/images/users/user_9.jpg";
import ReactTooltip from "react-tooltip";
import GeneralListing from "../../../TaskModalComponents/GeneralListing";
import { getDaysBetweenDates, _l } from "../../../../hooks/utilities";
import { Pencil } from "phosphor-react";

const LeadsProfile = ({ data }) => {
  const [leadProfileDetail, setLeadProfileDetail] = useState(data);
  const [companyfieldEditable, setCompanyfieldEditable] = useState(true);
  const [generalInformationfieldEditable, setgeneralInformationfieldEditable] = useState(true);
  const [customfieldEditable, setcustomfieldEditable] = useState(true);

  useEffect(() => {
    setLeadProfileDetail(data);
  }, [JSON.stringify(data)]);

  return (
    <div className="py-0 p-10">
      <div className="row">
        <div className="col-xl-12 ">
          <div className="bg-white-05 c-font color-white fw-semibold leadProfileTitle p-10 radius_3 h46px">
          <div className="d-flex align-items-center justify-content-between">
           <div>{_l('l_company_info')}</div>
           <div className="d-flex align-items-center">
           {!companyfieldEditable ? <><a href="#/"  className="btn btn-primary btn-sm-2 text-uppercase line-height-1 action_icon me-10px " onClick={()=>{ 
            }}> {_l('l_save')}</a>
             <a href="#/" className="btn btn-secondary btn-sm-2 text-uppercase line-height-1 action_icon" onClick={()=>{ 
              setCompanyfieldEditable(true)
            }}> {_l('l_cancel')}</a></>:
             <a href="#/" className="h25w25 border-transparent" onClick={()=>{ 
            setCompanyfieldEditable(false)
            setgeneralInformationfieldEditable(true);
            setcustomfieldEditable(true);
            }}> <Pencil size={18}  weight="light" className="c-icons" /></a>}
            </div>
          </div>
          
          </div>
          <ul className="p-10 px-0">
            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_name')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${companyfieldEditable ? "bg-transparent" : "border"}`}
              value={leadProfileDetail.name ? leadProfileDetail.name : ""}
              disabled={companyfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_Prénom')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${companyfieldEditable ? "bg-transparent" : "border"}`}
              value={"Jean-Aymeric"}
              disabled={companyfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_position')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${companyfieldEditable ? "bg-transparent" : "border"}`}
              value={"Directeur ARMONIA FM"}
              disabled={companyfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_email_address_label')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${companyfieldEditable ? "bg-transparent" : "border"}`}
              value={leadProfileDetail && leadProfileDetail.email ? leadProfileDetail.email : ""}
              disabled={companyfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>
            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_phone_label')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${companyfieldEditable ? "bg-transparent" : "border"}`}
              value={leadProfileDetail && leadProfileDetail.phonenumber ? leadProfileDetail.phonenumber : ""}
              disabled={companyfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>
            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_website')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${companyfieldEditable ? "bg-transparent" : "border"}`}
              value={""}
              disabled={companyfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>
            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_phone')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${companyfieldEditable ? "bg-transparent" : "border"}`}
              value={""}
              disabled={companyfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>
            
            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_company')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${companyfieldEditable ? "bg-transparent" : "border"}`}
              value={leadProfileDetail && leadProfileDetail.company ? leadProfileDetail.company : ""}
              disabled={companyfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_address')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${companyfieldEditable ? "bg-transparent" : "border"}`}
              value={""}
              disabled={companyfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_city')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${companyfieldEditable ? "bg-transparent" : "border"}`}
              value={""}
              disabled={companyfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_state')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${companyfieldEditable ? "bg-transparent" : "border"}`}
              value={""}
              disabled={companyfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_country')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${companyfieldEditable ? "bg-transparent" : "border"}`}
              value={""}
              disabled={companyfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_zip_code')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${companyfieldEditable ? "bg-transparent" : "border"}`}
              value={""}
              disabled={companyfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>
          </ul>
        </div>
        <div className="col-xl-12">
          <div className="bg-white-05 c-font color-white fw-semibold leadProfileTitle p-10 radius_3 h46px">
          <div className="d-flex align-items-center justify-content-between">
           <div>{_l('l_general_information')}</div>
           <div className="d-flex align-items-center">
           {!generalInformationfieldEditable ? <><a href="#/"  className="btn btn-primary btn-sm-2 text-uppercase line-height-1 action_icon me-10px " onClick={()=>{ 
            }}> {_l('l_save')}</a>
             <a href="#/" className="btn btn-secondary btn-sm-2 text-uppercase line-height-1 action_icon" onClick={()=>{ 
              setgeneralInformationfieldEditable(true)
            }}> {_l('l_cancel')}</a></>:
           <a href="#/" className="h25w25 border-transparent" onClick={()=>{
            setgeneralInformationfieldEditable(false);
            setCompanyfieldEditable(true);
            setcustomfieldEditable(true)
            }}> <Pencil size={18}  weight="light" className="c-icons" /></a>}
            </div>
          </div>
          </div>
          
          <ul className="p-10 px-0">
          <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_status')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${generalInformationfieldEditable ? "bg-transparent" : "border"}`}
              value={leadProfileDetail && leadProfileDetail.status_text ? leadProfileDetail.status_text : ""}
              disabled={generalInformationfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

          <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_source')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${generalInformationfieldEditable ? "bg-transparent" : "border"}`}
              value={leadProfileDetail && leadProfileDetail.source_text ? leadProfileDetail.source_text : ""}
              disabled={generalInformationfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

          <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_default_language')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${generalInformationfieldEditable ? "bg-transparent" : "border"}`}
              value={leadProfileDetail && leadProfileDetail.default_language ? leadProfileDetail.default_language : ""}
              disabled={generalInformationfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>
          
            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_assigned')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <div className="c-font f-13  color-white-80">
                <div className="position-relative px-2 assignee-list-wrapper card-view-less-padding">
                  {leadProfileDetail.assigned_list &&
                  leadProfileDetail.assigned_list.length ? (
                    <div className="multi_inner_wrapper with-min-width d-flex with_minus justify-content-start">
                      <a href="#/" className="comman-image-box h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding">
                        <div
                          className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                          style={{
                            backgroundImage: `url('${leadProfileDetail.assigned_list[0].profile_image}')`,
                          }}
                        ></div>
                      </a>
                      <a href="#/"
                        className="comman-image-box h32w32 with_overlay d-flex rounded-circle "
                        type="button"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                        data-tip={leadProfileDetail.assigned_list[0].full_name}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                      >
                        {leadProfileDetail.assigned_list.length > 1 ? (
                          <div className="unread_count m-auto">
                            +{leadProfileDetail.assigned_list.length - 1}
                          </div>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </a>
                      <GeneralListing
                        list={leadProfileDetail.assigned_list.map(
                          (assignee) => {
                            return {
                              name: assignee.full_name,
                              image: assignee.profile_image,
                              id: assignee.staffid,
                            };
                          }
                        )}
                      />
                    </div>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </div>
              </div>
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_created')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${generalInformationfieldEditable ? "bg-transparent" : "border"}`}
              value={(leadProfileDetail && leadProfileDetail.created ? getDaysBetweenDates(leadProfileDetail.created)  : 0) + " " + _l('l_days_ago')}
              disabled={generalInformationfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_last_contact')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${generalInformationfieldEditable ? "bg-transparent" : "border"}`}
              value={(leadProfileDetail && leadProfileDetail.lastcontact ? getDaysBetweenDates(leadProfileDetail.lastcontact)  : 0) + " " + _l('l_days_ago')}
              disabled={generalInformationfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_public')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${generalInformationfieldEditable ? "bg-transparent" : "border"}`}
              value={(leadProfileDetail && leadProfileDetail.is_public ?leadProfileDetail.is_public : "")}
              disabled={generalInformationfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_tags')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${generalInformationfieldEditable ? "bg-transparent" : "border"}`}
              value={ ""}
              disabled={generalInformationfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_time_arrived')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${generalInformationfieldEditable ? "bg-transparent" : "border"}`}
              value={ ""}
              disabled={generalInformationfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_location')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${generalInformationfieldEditable ? "bg-transparent" : "border"}`}
              value={ ""}
              disabled={generalInformationfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_rereral_URL')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${generalInformationfieldEditable ? "bg-transparent" : "border"}`}
              value={ ""}
              disabled={generalInformationfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_channel')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${generalInformationfieldEditable ? "bg-transparent" : "border"}`}
              value={ ""}
              disabled={generalInformationfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>
           
          </ul>
        </div>
        <div className="col-xl-12 ">
          <div className="bg-white-05 c-font color-white fw-semibold leadProfileTitle p-10 radius_3 h46px">
          <div className="d-flex align-items-center justify-content-between">
           <div>{_l('l_custom_fields')}</div>
           <div className="d-flex align-items-center">
           {!customfieldEditable ? <><a href="#/"  className="btn btn-primary btn-sm-2 text-uppercase line-height-1 action_icon me-10px " onClick={()=>{ 
            }}> {_l('l_save')}</a>
             <a href="#/" className="btn btn-secondary btn-sm-2 text-uppercase line-height-1 action_icon" onClick={()=>{ 
              setcustomfieldEditable(true)
            }}> {_l('l_cancel')}</a></>:
           <a href="#/" className="h25w25 border-transparent" onClick={()=>{
             setgeneralInformationfieldEditable(true);
             setCompanyfieldEditable(true);
             setcustomfieldEditable(false)
           }}> <Pencil size={18}  weight="light" className="c-icons" /></a>}
           </div>
          </div>
          </div>
          <ul className="p-10 px-0">
          <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_status')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={_l('l_inprogress')}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

          <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_Fin_de_contrat')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={""}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

          <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_size')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={""}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>
        
            
            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_assigned')}
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
                <div className="c-font f-13  color-white-80 text-truncate">
                  <div className="position-relative px-2 ">
                    <div className="multi_inner_wrapper  d-flex with_minus">
                      <a href="#/" className="comman-image-box h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding">
                        <div
                          className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                          style={{ backgroundImage: `url('${User_2}')` }}
                        ></div>
                      </a>
                      <a href="#/"
                        className="comman-image-box h32w32 with_overlay d-flex rounded-circle"
                        type="button"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                        data-tip={_l("l_view_more")}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                      >
                        <div className="unread_count m-auto">+5</div>
                      </a>
                      <GeneralListing
                        list={[1, 2, 3, 4].map((index) => {
                          return {
                            name: "person name",
                            image: User_9,
                            id: index,
                          };
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_decision_deadline')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={""}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_preferred_communication')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={_l('l_meeting')}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_objectives')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={""}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_budget')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={""}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_status')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={"Paladin"}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_role')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={_l('l_admin')}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_Decision_Maker_type')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={'Decision-Maker'}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_value_proposition')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={'Optimization of real estate management processes'}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_you_know_our_company')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={''}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_Prestataire_de_nettoyage')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={''}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_URL_records')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={''}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_Durée_de_préavis')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={''}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_budget')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={''}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_Nombre_de_salariés')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={''}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_Surface_bureau')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={''}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_date_signature')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={''}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_economics')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={''}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_decision_process')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={''}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>
            
            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_contact_clé_1')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={''}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_paper_process')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={''}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_contact_clé_2')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={''}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>

            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l('l_competitor')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col-lg-9">
              <input 
              className={`form-control c-font f-13  color-white-80 text-truncate p-10 ${customfieldEditable ? "bg-transparent" : "border"}`}
              value={''}
              disabled={customfieldEditable}
                //  {leadProfileDetail.name}
              />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <ReactTooltip />
    </div>
  );
};
export default LeadsProfile;
