import React, {  } from "react";
import { _l } from "../../hooks/utilities";
import { Star, } from "phosphor-react";
import { DropdownMenu, DropdownItem } from 'reactstrap';
import { useSelector } from "react-redux";

const StarRatingDropdown = ({
  handler = () => { },
  rating = 0,
}) => {
  let star = [
  {
    value :1,
    titte : "l_worst"
  },
  {
    value :2,
    titte : "l_bad"
  },
  {
    value :3,
    titte : "l_average"
  },
  {
    value :4,
    titte : "l_good"
  },
  {
    value :5,
    titte : "l_excellent"
  },
   ]
  let userType = localStorage.getItem("user_type");
  let contact_role = localStorage.getItem("contact_role");
  const selectedTask = useSelector((state) => state.customer.selectedTask);


// selectedTask.schedule_id > 0 && 
  return (
    <DropdownMenu container="body" className={`dropdown-menu w-250 ${userType == "contact" && JSON.parse(contact_role != 3 )? "" : "for-disabled"}`}>
      <ul className="">
        <li>
          <div className="dropdown-header">
            <div className="d-flex align-items-center justify-content-between">
              <div className="c-list-detail title-fonts text-truncate color-white c-font f-13">
                {_l("l_star_rating")}
              </div>
            </div>
          </div>
        </li>
        <li>
          <hr className="dropdown-divider my-0" />
        </li>
        <li></li>
      </ul>
    
      <ul className="dropdown-contain overflowscroll" id="conversation-area">
        <div className="star-rating-main">
          <div className="d-flex align-items-center justify-content-between">
            {star.map((star, index) => {
              return (<>
                <a href="#/" className={`star-rating-items text-center d-flex flex-column ${index < rating ? "active" : ""}`} onClick={(e) => {
                  handler(star.value == rating ? 0 :star.value)
                }} >
                  <Star size={18} weight="fill" className="c-icons" />
                  <span className="c-font color-white-60 f-10 lh-1 location fw-normal mt-1">{_l(star.titte)}</span>
                </a>
              </>)
            })}
          </div>
        </div>
      </ul>
    </DropdownMenu>
  );
};

export default StarRatingDropdown;
