import {
  ArrowBendDoubleUpLeft,
  CalendarBlank,
  CaretLeft,
  DownloadSimple,
  Eraser,
  FileText,
  Paperclip,
} from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { _l, generateDefaultSignature, showError, showSuccess } from "../../hooks/utilities";
import solarpanelsimg from "../../assets/images/solarpanelsimg.svg";
import { Button } from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";
import benchmarkServices from "../../services/benchmark-services";
import { useDispatch, useSelector } from "react-redux";
import ContractImg from "../../assets/images/solar_govt.jpg";
import { useNavigate } from "react-router";
import store from "../../store";
import { leftPanelFilters } from "../../actions/customer";
import DatePicker from "react-datepicker";
import { useSearchParams } from "react-router-dom";
import { getExternalBenchmarkDetails } from "../../actions/benchmark-actions/benchmark-actions";
import AcceptProposalCustomer from "./AcceptProposalCustomer";


const AfterPaymentSignature = ({
  handleNextStep,
  proposal,
  isExternal = false,
  step = "",
  setCustomerDocuments = () => { },
  customerDocuments,
  handleSaveSignature = () => { },
  loadingNextStep = false,
}) => {
  const iframeRef = useRef(null);
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const hash = searchParams.get("hash");

  const fullName = localStorage.getItem("full_name");
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");
  const contact_role = localStorage.getItem("contact_role")
    ? localStorage.getItem("contact_role")
    : "";
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const signaturepadRef = useRef();
  const navigate = useNavigate();

  const [userInformation, setUserInformation] = useState({
    firstName: fullName.split(" ")[0],
    lastName: fullName.split(" ").slice(1),
    billingAddress: address,
    emailAddress: email,
    cardNumber: "",
    signature: "",
    customerContract: "",
  });

  const signatureContent = `${userInformation.firstName} ${userInformation.lastName}`;
  const { benchmarkDetail } = useSelector((state) => state.customer);
  const isBlankCanvas =
    signaturepadRef &&
    signaturepadRef.current &&
    signaturepadRef.current.getTrimmedCanvas().getAttribute("width");

  const pdfRef = useRef(null);

  const clearSignaturePad = () => {
    if (signaturepadRef.current && signaturepadRef.current.clear) {
      signaturepadRef.current.clear();
    }
  }; 
  const undoSignature = () => {
    if (signaturepadRef.current && signaturepadRef.current.toData()) {
      let signaturePadData = signaturepadRef.current.toData();
      signaturePadData.pop();
      signaturepadRef.current.fromData(signaturePadData);
    }
  };

  const generateSignature = () => {
    if (signaturepadRef.current) {
      generateDefaultSignature(signaturepadRef, signatureContent);
    }
  };

  const submitData = async () => {
    if (
      userInformation.signature == "" &&
      userInformation.customerContract == ""
    ) {
      showError(_l("l_please_upload_the_sign_picture_or_sign_the_doucment"));
    } else {
      const backendResponse = await benchmarkServices.signatureAfterPayment(
        benchmarkDetail.project_id,
        contact_role,
        benchmarkDetail.staff_id,
        benchmarkDetail.contact_id,
        benchmarkDetail.client_id,
        benchmarkDetail.id,
        userInformation.customerContract,
        userInformation.signature
      );
      if (backendResponse.status) {
        selectSolarCategory();
      } else {
        showError(_l("l_please_upload_the_sign_picture_or_sign_the_doucment"));
      }
    }
  };

  const handlesubmitSignature = ()=>{
    let signature = signaturepadRef.current.getTrimmedCanvas().toDataURL("image/png")
    const isBlankCanvas = signaturepadRef.current.getTrimmedCanvas().getAttribute("width");
    if ( isBlankCanvas > 1  && signature  ) {
      handleSaveSignature();
    }else{
      showError(_l("l_please_upload_the_sign_picture_or_sign_the_doucment"));
    }
  }

  const getProposalPDF = async () => {
    if (proposal.proposal_id) {
      const res = await benchmarkServices.getProposalPDF(proposal.proposal_id);
      if (res.status) {
        setPdfUrl(res.data.url);
      } else {
        showError(_l(""));
      }
    }
  };

  const selectSolarCategory = () => {
    //Select Solar category
    store.dispatch(
      leftPanelFilters({
        main_key: "providers",
        filter_key: "1",
        title: "Blaz",
        image: "",
        main_sub_key: "providers",
        sub_category: [],
      })
    );
    navigate(
      `/?main_key=providers&filter_key=1&main_sub_key=providers&space=${benchmarkDetail.project_id}`
    );
  };

  useEffect(() => {
    getProposalPDF();
  }, []);


  
  return (
    <>
    {/* <AcceptProposalCustomer/> */}
      <div className="col-12 col-xl-10 col-xxl-10 registration-content flex-grow-1 mx-auto row mb-7 pt-20pximp">
        <div className="col-lg-2"></div>
        <div className="align-items-center col-lg-8 d-flex mx-auto flex-column">
          <div className="border radius_3 bg-white-03 comman-white-box-shadow w-100">
            {/* loop start */}
            <div className="align-items-center border-bottom border-last-none d-flex flex-wrap p-20 justify-content-between ">
              <div className="d-flex align-items-center flex-grow-1">
               {step == "signature-mandate" ?
                <a href="#/" className="action_icon align-items-center border d-flex h32wauto gap10px justify-content-center me-20px on-hover-active-toggle-img radius_3 with_bg"
                onClick={()=>{
                  handleNextStep();
                }}>
                  <CaretLeft size={18}  weight="light" />
                  <span className="c-font f-12 me-10px">RETOUR</span>
                </a> : <></>}
                <div className="h35w35 comman-round-box with-bg bg-white-05 me-10px">
                  <div
                    className="comman-bg-img h-100 w-100 bg-style-cover"
                    style={{ backgroundImage: `url('${solarpanelsimg}')` }}
                  ></div>
                </div>
                <div className="w-100">
                  <div className="c-font f-14 fw-semibold title-fonts d-flex align-items-center  w100minus100">
                    {isExternal
                      ? "MANDAT D’ASSISTANCE ADMINISTRATIVE"
                      : "Document 1"}
                  </div>
                  {isExternal ? (
                    <></>
                  ) : (
                    <div className="c-font f-11 title-fonts d-flex align-items-center">
                      {" "}
                      signature Status :
                      <span className="fw-semibold">
                        {(isBlankCanvas && isBlankCanvas > 1) ||
                          userInformation.customerContract
                          ? " already uploaded"
                          : " pending"}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className=" d-flex align-items-center comman_action_icon gap-3">
              {step == "signature-mandate"  ? <></> :
                <Button
                  variant={
                    isExternal && step == "installation-complete"
                      ? "success"
                      : "primary"
                  }
                  className="btn btn-primary position-relative  d-flex align-items-center"
                  onClick={() => {
                    if (
                      isExternal &&
                      (step == "assistance-mandate")
                    ) {
                      handleNextStep();
                    } else {
                      setShowSignatureModal(true);
                    }
                  }}
                >
                  <span className="">
                    {isExternal
                      ? step == "installation-complete"
                        ? _l("l_submit")
                        : _l("l_sign_online")
                      : "Signer mon mandat"}
                  </span>
                </Button>}
                {isExternal ? (
                  <></>
                ) : (
                  <>
                    <a
                      href="#/"
                      className={`action_icon min-width55 with_bg position-relative h32w32 ${isBlankCanvas > 1 ? "for-disabled" : ""
                        }`}
                    >
                      <input
                        type="file"
                        className="absolute-input"
                        disabled={isBlankCanvas > 1 ? true : false}
                        onChange={(event) => {
                          setUserInformation({
                            ...userInformation,
                            customerContract: event.target.files,
                          });
                        }}
                      />
                      <Paperclip size={16} weight="light" className="c-icons" />
                    </a>
                    <a
                      // href={"https://api.solairehub.com/web/orb/assets/pdf/solar_govt.pdf"}
                      className="action_icon min-width55 with_bg position-relative h32w32"
                      onClick={() => {
                        window.open(
                          "https://api.solairehub.com/web/orb/assets/pdf/solar_govt.pdf",
                          "_blank"
                        );
                      }}
                    >
                      <DownloadSimple
                        size={16}
                        weight="light"
                        className="c-icons"
                      />
                    </a>
                  </>
                )}
              </div>
            </div>
            {/* <div className="align-items-center border-bottom border-last-none d-flex flex-wrap p-20 justify-content-between ">
              <div className="d-flex align-items-center flex-grow-1">
                <div className="h35w35 comman-round-box with-bg bg-white-05 me-10px">
                  <div
                    className="comman-bg-img h-100 w-100 bg-style-cover"
                    style={{ backgroundImage: `url('${solarpanelsimg}')` }}
                  ></div>
                </div>
                <div className="w-100 d-flex align-items-center">
                  <div className="c-font f-14 fw-semibold title-fonts d-flex align-items-center  w100minus100">
                    {_l("l_choose_installation_date")}
                  </div>
                  <div
                    className={` white-border-box p-0 d-flex list-toggle-view-box position-relative h32wauto bg-transparent`}
                  >
                    <a
                      href="#/" 
                      className={`custom-datepicker datepicker-input w110px m-auto ms-1 c-font title-fonts commanbgwithborder fw-medium border-0`}
                      data-bs-toggle="tooltip"
                      data-tip={"28-06-2024"}
                      data-effect="solid"
                      data-delay-show="1000"
                      data-class="tooltip-main"
                    >
                      <div className="d-flex align-items-center">
                        <div className="h20w20">
                          <CalendarBlank
                            size={18}
                            weight="light"
                            className="c-icons"
                          />
                        </div>
                        <DatePicker
                          portalId="custom_datepicker"
                          calendarClassName="custom-datepicker"
                          calendarStartDay={1}
                          className="close-toast"
                          placeholderText={`${_l("l_startdate")}`}
                          dateFormat="dd/MM/yyyy"
                          selected={mandateDate}
                          onChange={(date) => {
                            handleDateChange(date)
                           }}
                        ></DatePicker>
                        {/* <span className="color-white-60 d-none">Aug, 8 2022</span> * /}
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className=" d-flex align-items-center comman_action_icon gap-3">
              </div>
            </div> */}
            {/* loop end */}
          </div>
          {isExternal && step == "assistance-mandate" ? (
            <div className="bg-white border h-100 w-100 bordertop0imp position-relative uploadabsimg mb-15px">
              <div>
                {/* <img src={solarPdf} className="h-100 w-100 object-fit-fill" /> */}
            {/* <img src={ContractImg} className="h-100 w-100" />  */}
                <div className="position-absolute top-0 start-0 w-100">              
                      <iframe 
                         ref={iframeRef}
                      src={pdfUrl} 
                      frameborder="0"
                       width={"100%"} 
                       height={"600px"}></iframe>
                      </div> 
       
              
              </div>
            </div>
          ) : (
            <></>
          )}
          {isExternal && step == "signature-mandate" ? (
            <div className="bg-white border h-100 w-100 bordertop0imp position-relative uploadabsimg mb-15px">
              <div className="d-flex flex-column p-20px">
                <div className="row">
                  <Form.Group className="col-xl-6 c-input-box pb-20px position-relative">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_first_name")}
                    </Form.Label>
                    <Form.Control
                      placeholder={`${_l("l_first_name_placeholder")}`}
                      type="text"
                      name=""
                      value={userInformation.firstName}
                      onChange={(e) => {
                        setUserInformation({
                          ...userInformation,
                          firstName: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="col-xl-6 c-input-box pb-20px position-relative">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_last_name")}
                    </Form.Label>
                    <Form.Control
                      placeholder={`${_l("l_last_name_placeholder")}`}
                      type="text"
                      name=""
                      value={userInformation.lastName}
                      onChange={(e) => {
                        setUserInformation({
                          ...userInformation,
                          lastName: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="col-xl-12 c-input-box pb-20px position-relative">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_email")}
                    </Form.Label>
                    <Form.Control
                      placeholder={`${_l("l_email_placeholder")}`}
                      type="email"
                      name=""
                      value={userInformation.emailAddress}
                      onChange={(e) => {
                        setUserInformation({
                          ...userInformation,
                          emailAddress: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                </div>
                <div
                  className="flex-grow-1"
                  onMouseLeave={() => {
                    const isBlankCanvas = signaturepadRef.current
                      .getTrimmedCanvas()
                      .getAttribute("width");
                    if (isBlankCanvas > 1) {
                      if (isExternal) {
                        setCustomerDocuments({
                          ...customerDocuments,
                          signature: signaturepadRef.current
                            .getTrimmedCanvas()
                            .toDataURL("image/png"),
                        });
                      }
                    }
                  }}
                >
                  <SignatureCanvas
                    penColor="black"
                    ref={signaturepadRef}
                    canvasProps={{
                      className: "bg-body-tertiary border p-0 w-100 bg-white h-160px",
                    }}
                  />
                </div>
                <div className="col-xl-12 c-input-box pb-20px position-relative mt-10px">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex">
                      <a
                        href="#/"
                        className="h40w40 d-flex  form-control p-0  me-3"
                        onClick={undoSignature}
                        data-tip={_l("l_undo")}
                        data-delay-show="1000"
                        data-class="tooltip-main"
                      >
                        <ArrowBendDoubleUpLeft
                          size={18}
                          className="c-icons"
                          weight="light"
                        />
                      </a>
                      <a
                        href="#/"
                        className="h40w40 d-flex  form-control p-0"
                        onClick={clearSignaturePad}
                        data-tip={_l("l_clear")}
                        data-effect="solid"
                        data-delay-show="1000"
                        data-class="tooltip-main"
                      >
                        <Eraser size={18} className="c-icons" weight="light" />
                      </a>
                    </div>
                    <div className="d-flex align-items-center gap10px">

                      <a href="#/" className="btn btn-primary onhoverimginv d-flex align-items-center gap10px position-relative" >
                        <input type="file" className="absolute-input" accept="image/*"
                        onChange={(e)=>{
                          setCustomerDocuments({
                            ...customerDocuments,
                            customerContract: e.target.files[0],
                          });
                        }}
                         />
                        <Paperclip size={18} className="c-icons" />
                        {_l("l_import") + " " + _l("l_signature")}
                      </a>

                      <Button
                        variant=""
                        className="btn-white-05 fw-semibold"
                        onClick={generateSignature}
                      >
                        {_l("l_signature_by_default")}
                      </Button>
                      <Button
                        variant=""
                        className={`"btn-white-05 fw-semibold border-black ${loadingNextStep ? "for-disabled" : ""}`}
                        onClick={()=>{
                          handlesubmitSignature()
                        }}
                      >
                        {loadingNextStep ? (
                        <>
                          <span>{_l("l_please_wait")}</span>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            className={`ms-2 `}
                            aria-hidden="true"
                          />
                        </>
                      ) : (
                        _l("l_save")
                      )}
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column py-3">
                  <div className="c-font color-black fw-semibold f-14 mb-10">
                    You have agreed to the terms, for the further process please
                    sign the mandate
                  </div>
                  <div className="c-font f-10 color-white-50">
                    For a record, please sign on the dialogue box given above
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {isExternal && step == "installation-complete" ? (
            <div className="bg-white border h-100 w-100 bordertop0imp position-relative uploadabsimg mb-70">
              <div className=" p-20px">
                <SignatureCanvas
                  penColor="black"
                  ref={signaturepadRef}
                  canvasProps={{
                    className: "bg-body-tertiary border p-0 w-100 bg-white",
                  }}
                />
                <div className="d-flex flex-column py-3">
                  <div className="c-font color-black fw-semibold f-14 mb-10">
                    You have successfully completed the installation
                  </div>
                  <div className="c-font f-10 color-white-50">
                    For a record, please sign on the dialogue box given above
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {isExternal ? (
            <></>
          ) : (
            <div className="pt-70px">
              <Button className="h45w45 w-auto px-4" onClick={submitData}>
                {_l("l_submit")}
              </Button>
            </div>
          )}
        </div>
        <div className="col-lg-2"></div>
      </div>
      {showSignatureModal ? (
        <div className="solarmodal">
          <Modal
            onHide={() => {
              setShowSignatureModal(false);
            }}
            className="custom-modal-style solarcommanmodal "
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="xl"
            show
            scrollable={true}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="p-20pximp py-0 bg-white">
              <div className={`form-wrapper-main p-0`}>
                {/* step 1 start */}
                <div className="">
                  <div className="row">
                    <div className="col-lg-8 position-relative pe-2 h-100">
                      <div className="position-absolute top-0 start-0 w-100">
                        <a
                          className="bg-opacity-25 bg-secondary p-1 position-absolute rounded-2 right-20"
                          href={pdfUrl}
                          download
                          target="_blank"
                          rel="noreferrer"
                        >
                          <DownloadSimple size={32} />
                        </a>
                        {/* <img src={ContractImg} className="h-100 w-100" /> */}
                        <iframe
                          src={pdfUrl}
                          frameborder="0"
                          width={"100%"}
                          height={"600px"}
                        ></iframe>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="c-font f-14 fw-semibold title-fonts pb-20px">
                        {_l("l_contract_signature")}
                      </div>

                      <div className="row">
                        <Form.Group className="col-xl-6 c-input-box pb-20px position-relative">
                          <Form.Label className="input-label no-asterisk ">
                            {_l("l_first_name")}
                          </Form.Label>
                          <Form.Control
                            placeholder={`${_l("l_first_name_placeholder")}`}
                            type="text"
                            name=""
                            value={userInformation.firstName}
                            onChange={(e) => {
                              setUserInformation({
                                ...userInformation,
                                firstName: e.target.value,
                              });
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="col-xl-6 c-input-box pb-20px position-relative">
                          <Form.Label className="input-label no-asterisk ">
                            {_l("l_last_name")}
                          </Form.Label>
                          <Form.Control
                            placeholder={`${_l("l_last_name_placeholder")}`}
                            type="text"
                            name=""
                            value={userInformation.lastName}
                            onChange={(e) => {
                              setUserInformation({
                                ...userInformation,
                                lastName: e.target.value,
                              });
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="col-xl-12 c-input-box pb-20px position-relative">
                          <Form.Label className="input-label no-asterisk ">
                            {_l("l_email")}
                          </Form.Label>
                          <Form.Control
                            placeholder={`${_l("l_email_placeholder")}`}
                            type="email"
                            name=""
                            value={userInformation.emailAddress}
                            onChange={(e) => {
                              setUserInformation({
                                ...userInformation,
                                emailAddress: e.target.value,
                              });
                            }}
                          />
                        </Form.Group>
                        <Form.Group
                          className={`col-xl-12 c-input-box pb-20px position-relative ${userInformation.customerContract
                              ? "for-disabled"
                              : ""
                            }`}
                        >
                          <Form.Label className="input-label no-asterisk ">
                            {_l("l_signature")}
                          </Form.Label>
                          <SignatureCanvas
                            penColor="black"
                            ref={signaturepadRef}
                            canvasProps={{
                              height: 100,
                              className:
                                "custom-canvas-style form-control p-0 w-100 border",
                            }}
                          />
                        </Form.Group>
                        <div className="col-xl-12 c-input-box pb-20px position-relative">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex">
                              <a
                                href="#/"
                                className="h40w40 d-flex  form-control p-0  me-3"
                                onClick={undoSignature}
                                data-tip={_l("l_undo")}
                                data-delay-show="1000"
                                data-class="tooltip-main"
                              >
                                <ArrowBendDoubleUpLeft
                                  size={18}
                                  className="c-icons"
                                  weight="light"
                                />
                              </a>
                              <a
                                href="#/"
                                className="h40w40 d-flex  form-control p-0"
                                onClick={clearSignaturePad}
                                data-tip={_l("l_clear")}
                                data-effect="solid"
                                data-delay-show="1000"
                                data-class="tooltip-main"
                              >
                                <Eraser
                                  size={18}
                                  className="c-icons"
                                  weight="light"
                                />
                              </a>
                            </div>
                            <Button
                              variant=""
                              className="btn-white-05 fw-semibold"
                              onClick={generateSignature}
                            >
                              {_l("l_signature_by_default")}
                            </Button>
                          </div>
                        </div>
                        <Form.Group className="col-xl-12 c-input-box pb-20px position-relative">
                          <div className="d-flex align-items-center">
                            <label className="form-check-label  me-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={`default`}
                                label="I Accept this Estimate"
                                checked={true}
                                disabled
                              />
                            </label>
                            <div className="d-flex align-items-center c-font f-12">
                              <span>
                                {" "}
                                {_l(
                                  "l_by_clicking_on_sign_i_confirm_that_i_have_read_the"
                                )}
                                <a
                                  href="#/"
                                  className="fblueimp fw-semibold px-1"
                                >
                                  {_l("l_terms_and_conditions")}
                                </a>
                                {_l(
                                  "l_and_i_agree_to_be_legally_bound_by_his_electronic_representation_of_my_signature"
                                )}
                              </span>
                            </div>
                          </div>
                        </Form.Group>
                        <Form.Group
                          className={`col-xl-12 c-input-box  position-relative ${userInformation.customerContract
                              ? "for-disabled"
                              : ""
                            }`}
                        >
                          <a
                            className="btn bluebtn fw-semibold w-100"
                            onClick={() => {
                              const isBlankCanvas = signaturepadRef.current
                                .getTrimmedCanvas()
                                .getAttribute("width");
                              if (isBlankCanvas > 1) {
                                setUserInformation({
                                  ...userInformation,
                                  signature: signaturepadRef.current
                                    .getTrimmedCanvas()
                                    .toDataURL("image/png"),
                                });
                                setShowSignatureModal(false);
                              } else {
                                showError("Please sign the document");
                              }
                            }}
                          >
                            {_l("l_sign_online")}
                          </a>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* step 1 over */}
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </>
  );
};

export default AfterPaymentSignature;
