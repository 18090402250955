import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import RegistrationFlow from "./RegistrationFlow";
import ForgotPassword from "./Forgot";
import ContactForm from "./ContactForm";
import RegistrationNew from "./RegistrationNew";
import ResetPassword from "./ResetPassword";
import SolarExternalBenchmark from "./SolarExternalBenchmark";
import SolarProviderRegistration from "./SolarProviderRegistration";
import RegistrationFlowNew from "./RegistrationFlowNew";
import ConnectNew from "./ConnectNew";
import { CustomerInvitation } from "./CustomerInvitation";
import ProviderDocuments from "./ProviderDocuments";
import ExternalProposalDetails from "./ExternalProposalDetails";

const ExternalPageConnector = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const navigate = useNavigate();

  switch(page)
  {
    case "connect":
        return <RegistrationFlow />;
    case "forgot-password":
        return <ForgotPassword />;
    case "contact":
        // return <ContactForm />;
        return <ConnectNew />
    case "registration":
        return <RegistrationNew />;
    case "reset-password":
        return <ResetPassword />;
    case "register-provider":
    case "proposals":
    case "external-proposal":
        return <SolarProviderRegistration />;
    case "create-benchmark":
        return <SolarExternalBenchmark />;
    case "register-new-user":
        return <RegistrationFlowNew />;
    case "customer-invitation" :
        return <CustomerInvitation />
    case "proposal-deatils" :
        return <ExternalProposalDetails />
    default:
        navigate("/login");
  }
};

export default ExternalPageConnector;
