import React from 'react'
const AddItemGridRowSkeleton = () => {
  return (
    <>
        {
          [0, 1, 2].map((s, index) => {
            return (<>
              <div className="align-items-center bg-white border d-flex justify-content-between mb-10px p-10 radius_3 SkeletonMain">
                <div className='align-items-center d-flex w100minus120'>
                  <div className='h70w70 skeleton-animation radius_3'></div>
                  <div className='d-flex flex-column ms-3 w100minus100per'>
                    <div className='mb-2 skeleton-animation radius_3' style={{ width: '200px', height: '15px' }}></div>
                    <div className='mb-2 skeleton-animation radius_3' style={{ width: '250px', height: '12px' }}></div>
                    <div className='mt-2 d-flex align-items-center gap10px'>
                      <div className='skeleton-animation radius_3' style={{ width: '40px', height: '10px' }}></div>
                      <div className='p-0 with_separator_10'></div>
                      <div className='skeleton-animation radius_3' style={{ width: '70px', height: '10px' }}></div>
                    </div>
                  </div>
                </div>
                <div className='d-flex align-items-center gap10px'>
                  <div className='radius_3 skeleton-animation h32w32'></div>
                  <div className='radius_3 skeleton-animation h32w32'></div>
                  <div className='radius_3 skeleton-animation h32w32'></div>
                </div>
              </div>
            </>)
          })
        }
    </>
  )
}
export default AddItemGridRowSkeleton