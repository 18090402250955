import React, { useEffect, useRef, useState } from "react";
// import { mapStyle } from "../../assets/map/mapStyle";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import Geocode from "react-geocode";
import { _l, showError } from "../../hooks/utilities";
import { CaretRight, House, MagnifyingGlass, MapPin, PencilLine, Phone, Star, StarHalf, Trash } from "phosphor-react";
import Form from "react-bootstrap/Form";
import Loader from "./Loader";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_SECRET);
Geocode.setLanguage("fr");
Geocode.setRegion("fr");


export const Address = ({
  setSpaceDetail,
  spaceDetail,
  regionList,
  setRegionList,
  coOrdinates,
  setCoOrdinates,
  nextButtonHandler
}) => {
  const containerStyle = {
    width: "100%",
    height: "100%",
    position: "static",
  };

  const addressOptions = {
    componentRestrictions: { country: "fr" },
    fields: ["address_components", "geometry", "icon", "name"],
    // types: ['(regions)'],
  };

  const [addressText, setAddressText] = useState("");
  const [isGeoCodeProcessOngoing, setIsGeoCodeProcessOngoing] = useState(true);
  const [map, setMap] = useState("");
  const [isSelecteAddress, setIsSelecteAddress] = useState(false);
  const [loader, setLoader] = useState(false)
  const addressRef = useRef();
  const autoCompleteRef = useRef();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_SECRET,
  });

  const selectSpaceRegion = (addressComponent) => {
    let regionId = 0;
    let regionName = "";
    let fetchedRegion = "";
    let index = addressComponent.findIndex(
      (add) => add.types && add.types.includes("administrative_area_level_2")
    );
    if (index === -1) {
      index = addressComponent.findIndex(
        (add) => add.types && add.types.includes("administrative_area_level_1")
      );
    }
    if (index > -1) {
      fetchedRegion = addressComponent[index].long_name;
    }
    regionList.forEach((region) => {
      if (
        region.label.toLowerCase().trim() == fetchedRegion.toLowerCase().trim()
      ) {
        regionId = region.value;
        regionName = region.label;
      }
    });
    if (regionId == 0) {
      setRegionList(regionList.concat([{ value: 0, label: fetchedRegion }]));
      return { region_id: 0, region_name: fetchedRegion };
    } else {
      return { region_id: regionId, region_name: regionName };
    }
  };

  const addNewSpace = async () => {
    try {
      const { region_id, place_id, lat, lng, region_name, address, mapPhoneNumber, rating, reviewCount,review} = spaceDetail;
      let currentSpaceArray = spaceDetail.provider_address;
      if (currentSpaceArray.length) {
        if (
          currentSpaceArray.find(
            (w) => w.address.trim().toLowerCase() === addressText
          )
        ) {
          showError("");
          return;
        }
         else {
          setSpaceDetail({
            ...spaceDetail,
            company_name: region_name,
            phonenumber: mapPhoneNumber,
            provider_address: [{
              address,
              region_id,
              region_name,
              place_id,
              lat,
              lng,
              mapPhoneNumber,
              rating,
              reviewCount,
              review
            },
            ],
          });
        }
      } else {
        setSpaceDetail({
          ...spaceDetail,
          company_name: region_name,
          phonenumber: mapPhoneNumber,
          provider_address: [
            {
              address,
              region_id,
              region_name,
              place_id,
              lat,
              lng,
              mapPhoneNumber,
              rating,
              reviewCount,
              review
            },
          ],
        });
      }
      addressRef.current.value = ""
      setIsSelecteAddress(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleMapLoad = (mapInstance) => {
    setMap(mapInstance);
  }

  useEffect(() => {
    if (addressText && addressText.trim().length && addressRef.current) {
      try {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
          addressRef.current,
          addressOptions
        ).addListener("place_changed", () => {
          if(!isGeoCodeProcessOngoing)
          {
            setLoader(true);
            Geocode.fromAddress(addressRef.current.value).then(
              (response) => {
                setLoader(false)
                const placeId = response.results[0].place_id;
                const { lat, lng } = response.results[0].geometry.location;
                const { region_id } = selectSpaceRegion(
                  response.results[0].address_components
                );
                try {      
                  var request = {
                    placeId: placeId,
                    fields: ['rating', 'international_phone_number', 'name', 'user_ratings_total','review']
                  };
                  
                  let service = new window.google.maps.places.PlacesService(map);
                  service.getDetails(request, callback);
                  
                  function callback(place, status) {
                    if (status == window.google.maps.places.PlacesServiceStatus.OK) {
                      const phoneNumber =  place && place.international_phone_number ? place.international_phone_number : ""
                      const rating =  place && place.rating ? place.rating : ""
                      const reviewCount = place && place.user_ratings_total ? place.user_ratings_total : ""
                      const region_name = place && place.name ? place.name : ""
                      const review = place && place.reviews ? place.reviews : []
                      setSpaceDetail({
                        ...spaceDetail,
                        address: addressRef.current.value,
                        region_id,
                        region_name,
                        lat,
                        lng,
                        place_id: placeId,
                        mapPhoneNumber:phoneNumber,
                        rating:rating,
                        reviewCount: reviewCount,
                        review : review
                      });
                      setIsSelecteAddress(true);
                    }
                  }
                }
                catch(e)
                {
                  console.log(e)
                }
                
                setCoOrdinates({
                  lat: lat,
                  lng: lng,
                });
                setIsGeoCodeProcessOngoing(true)
              },
              (error) => {
                console.log(error);
                setIsGeoCodeProcessOngoing(true)
              }
            );
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, [addressText])

  useEffect(() => {
    if (isSelecteAddress) {
      addNewSpace();
    }
  }, [isSelecteAddress])

  return (
    <div className="h-100 overflow-hidden position-relative rounded-2">
      {isLoaded ? (
        <div className="onboarding-map hide-magnify">
          <div className="end-0 position-sticky p-4 start-0 top-0 w-100 z-index-2">
            <div className="row justify-content-center">
              <div className="col-xl-12 c-input-box pb-3 position-relative">
                {
                  spaceDetail.provider_address &&
                  spaceDetail.provider_address.length ?
                  <>
                    <div class="mapareabox p-20 pb-0">
                      <div class="areaheader d-flex align-items-center">
                      <House size={20} weight="fill" className="c-icons m-0 " />
                          <span class="fw-semibold c-font f-14 title-fonts ps-10px">{spaceDetail.provider_address[spaceDetail.provider_address.length-1].region_name}</span>
                      </div>
                      <div class="d-flex p-20 px-0 "><span class="border-top w-100"></span></div>
                      <div className="d-flex justify-content-between pb-3">
                          <div class="color-white-60 light-theme-color-white-70 title-fonts ">
                            {spaceDetail.provider_address[spaceDetail.provider_address.length - 1].rating && spaceDetail.provider_address[spaceDetail.provider_address.length - 1].rating != ""
                              ? <div class="d-flex w-100 align-items-center w100minus120">
                                <div class="fw-semibold">{spaceDetail.provider_address[spaceDetail.provider_address.length - 1].rating}</div>
                                <div class="d-flex ps-10px star-rating-items active">
                                  {[...Array(5)].map((x, i) => {
                                      if ((spaceDetail.provider_address[spaceDetail.provider_address.length - 1].rating) >= i+1) {
                                        return (<Star size={20} weight="fill" className="c-icons m-0 pe-1" />)
                                    } else {
                                        if ((spaceDetail.provider_address[spaceDetail.provider_address.length - 1].rating) > i && (spaceDetail.provider_address[spaceDetail.provider_address.length - 1].rating) < i+1) {
                                        return (<StarHalf size={20} weight="fill" className="c-icons m-0 pe-1" />)
                                      } else {
                                        return (<Star size={20} weight="light" className="c-icons m-0 pe-1" />)
                                      }
                                    }
                                  }
                                  )}
                                </div>
                                {spaceDetail.provider_address[spaceDetail.provider_address.length - 1].reviewCount
                                  ? <div class="fw-medium ps-20pximp text-nowrap w-100">
                                    {spaceDetail.provider_address[spaceDetail.provider_address.length - 1].reviewCount > 1000 ?
                                      `${(spaceDetail.provider_address[spaceDetail.provider_address.length - 1].reviewCount / 1000).toFixed()}K+ Google Review `
                                      : `(${spaceDetail.provider_address[spaceDetail.provider_address.length - 1].reviewCount} Google Review)`
                                    }
                                  </div>
                                  : <></>
                                }
                              </div>
                              : <></>
                            }
                            <div class="d-flex pt-3">
                              <MapPin size={20} weight="light" className="c-icons m-0 pe-1 " />
                              <div class="fw-medium ps-10px text-truncate w100minus10">{spaceDetail.provider_address[spaceDetail.provider_address.length - 1].address}</div>
                            </div>
                            {spaceDetail.provider_address[spaceDetail.provider_address.length - 1].mapPhoneNumber && spaceDetail.provider_address[spaceDetail.provider_address.length - 1].mapPhoneNumber != ""
                              ? <div class="d-flex pt-3">
                                <Phone size={20} weight="light" className="c-icons m-0 pe-1 " />
                                <div class="fw-medium ps-10px">{spaceDetail.provider_address[spaceDetail.provider_address.length - 1].mapPhoneNumber}</div>
                              </div>
                              : <></>
                            }
                          </div>
                          <div className="d-flex  gap-4 radius_5 d-none">
                            <img alt="logo" className="radius_3 mapareabox_img" />
                          </div>
                      </div>
                    </div>
                    {/* <div className="mapareabox p-20 pb-0 top-270">
                      <div className="areaheader d-flex align-items-center">
                        <House size={20} weight="light" className="c-icons m-0 " />
                        <span className="fw-semibold c-font f-14 title-fonts ps-10px">
                          {_l("l_my_geolocalized_areas")}{" "}
                        </span>
                      </div>
                      <div className="d-flex p-20 px-0 ">
                        <span className="border-top w-100"></span>
                      </div>
                      {
                        spaceDetail.provider_address.map((address, index) => {
                          return (
                            <>
                            <div key={index} className="arealist pb-3">
                              <div className="d-flex align-items-center justify-content-between RegNewFlow">
                                <div className="d-flex text-truncate">
                                  <House
                                    size={20}
                                    weight="fill"
                                    className="c-icons m-0 me-2"
                                  />
                                  <div className="d-flex flex-column text-truncate w-100">
                                    <div className="c-font f-14 fblue fw-semibold light-theme-color-white-70 text-truncate title-fonts">
                                      {address.address}
                                    </div>
                                    <div className="c-font color-white-60 f-12 fw-normal light-theme-color-white-70 text-truncate title-fonts">
                                      {address.region_name}
                                    </div>
                                  </div>
                                </div>
                               
                                <div class="d-flex align-items-center d-none">
                                  <a href="#/" class="h-right-part-link header-support comman_action_icon ms-10px">
                                      <div class="action_icon align-items-center border d-flex flex-column h32w32 justify-content-center position-relative radius_3">
                                        <PencilLine size={20} weight="light" className="c-icons m-0 " />
                                      </div>
                                  </a>
                                  <a href="#/" class="h-right-part-link header-support comman_action_icon ms-10px">
                                      <div class="d-flex flex-column justify-content-center align-items-center position-relative action_icon h32w32 border radius_3">
                                        <Trash size={20} weight="light" className="c-icons m-0 " />
                                      </div>
                                  </a>
                              </div>
                              </div>
                            </div>
                         </>
                          );
                        })
                      }
                    </div> */}
                  </>
                  : loader ?
                  <div className="mapareabox p-20">
                    <div className="areaheader d-flex align-items-center">
                    <div className="spinner-border spinner-border-sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="fw-semibold c-font f-14 title-fonts ps-10px">{_l("l_please_wait")}</div>
                    </div>
                  </div>
                  : <></>
                }
                <div className="map-input-box">
                  <Form.Group className={`c-input-box position-relative w-100`}>
                    <div className="d-flex">
                      <div className="d-flex align-items-center">
                        <MagnifyingGlass
                          size={24}
                          weight="light"
                          className="c-icons mx-0"
                        />
                      </div>
                      <input
                        className="form-control c-font f-20 regFormInput py-3"
                        placeholder={_l("l_address_placeholder")}
                        type="text"
                        autoFocus
                        onChange={(e) => {
                          setAddressText(e.target.value);
                          if (isGeoCodeProcessOngoing) {
                            setIsGeoCodeProcessOngoing(false)
                          }
                          setSpaceDetail({
                            ...spaceDetail,
                            address: e.target.value,
                          });
                        }}
                        onKeyDown={(event) => {
                          if (event.keyCode === 13 ) {
                             event.preventDefault();
                          }
                        }}
                        ref={addressRef}
                        defaultValue={addressText}
                      />

                      <a
                        href="#/"
                        className={`btn btn-primary position-relative light-theme-blue-bg text-nowrap d-flex align-items-center rounded-5 c-font f-20 fw-semibold ps-40px pe-40px ${loader ? 'for-disabled' :''}`}
                        onClick={nextButtonHandler}
                      >
                        <span className="me-10px text-capitalize">
                          {" "}
                          {_l("l_search")}
                        </span>
                        <CaretRight
                          size={18}
                          className="c-icons"
                          weight="fill"
                        />
                      </a>
                    </div>
                  </Form.Group>
                </div>
              </div>
              {/* <div className="col-xl-2 c-input-box pb-3 position-relative">
              <Select
                className="custom-select-menu onboarding-map-input"
                classNamePrefix="react-select"
                value={
                  spaceDetail
                    ? regionList.find(
                        (x) =>
                          x.value == spaceDetail.region_id ||
                          x.label == spaceDetail.region_name
                      )
                    : []
                }
                isDisabled={true}
              />
              </div> */}
            </div>
          </div>
          <GoogleMap
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_SECRET}
            options={{
              streetViewControl: false,
              scrollwheel: false,
              // styles: mapStyle,
            }}
            onLoad={handleMapLoad}
            mapContainerStyle={containerStyle}
            center={coOrdinates}
            zoom={12}
          >
            {
              spaceDetail && spaceDetail.provider_address.length
              ?
              spaceDetail.provider_address.map((address, index) => {
                return (
                  <Marker key={index} position={{lat: address.lat, lng: address.lng}} />
                )
              })
              :
              <React.Fragment></React.Fragment>
            }
          </GoogleMap>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Address;
