import React, { useState } from 'react'
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import PlacesAutocomplete from "react-places-autocomplete";
import { validateEmail, _l } from "../../hooks/utilities";
import { Minus, Plus } from "phosphor-react";
import Select from "react-select";
const AutosearchPlaces = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
}) => {
    return (
        <div>
            <Form.Control
                placeholder={`${_l("l_address_placeholder")}`}
                type="text"
                name="address_3dplan"
                {...getInputProps({
                    placeholder: "Avenue de France, Paris, France",
                })}
            />
            <div className="custom-map-suggestion border-0">
                {loading && <div>{_l("l_loading")}</div>}
                {suggestions.map((suggestion, index) => {
                    return (
                        <div {...getSuggestionItemProps(suggestion)} key={index}>
                            <a href="#/">{suggestion.description}</a>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

function LeadContactProviderForm({ setCustomerData, customerData, countryList, regionsList, type, categoryListing }) {
    const optionsStrict = {
        componentRestrictions: {
            country: ["fr", "uk", "de", "esp", "ita", "bel", "che"],
        },
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
        types: ["address"],
    };
    const handleSelect = (value) => {
        setCustomerData({ ...customerData, spaceAddress: value });
    };
    const spaceTypeOptions = [
        {
            value: "1",
            label: _l("l_office"),
        },
        {
            value: "2",
            label: _l("l_coworking_and_flex_office"),
        },
        {
            value: "3",
            label: _l("l_building"),
        },
        {
            value: "4",
            label: _l("l_multi_properties"),
        },
        {
            value: "5",
            label: _l("l_hotel_and_retail"),
        },
        {
            value: "6",
            label: _l("l_property_manager_and_facility_manager"),
        },
    ];
    const countryCodes = [
        {
            value: "+32",
            label: "+32",
        },
        {
            value: "+33",
            label: "+33",
        },
        {
            value: "+49",
            label: "+49",
        },
        {
            value: "+325",
            label: "+325",
        },
        {
            value: "+34",
            label: "+34",
        },
        {
            value: "+41",
            label: "+41",
        },
        {
            value: "+44",
            label: "+44",
        },
    ];
    return (
        <div className="form-wrapper-main pt-0">
            <div className="row align-items-center">
                <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                        {_l("l_first_name")}
                    </Form.Label>
                    <Form.Control
                        placeholder={`${_l("l_first_name_placeholder")}`}
                        type="text"
                        name="first_name"
                        value={customerData && customerData.firstName}
                        onChange={(e) => {
                            setCustomerData({
                                ...customerData,
                                firstName: e.target.value,
                            });
                        }}
                    />
                </Form.Group>
                <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                        {_l("l_last_name")}
                    </Form.Label>
                    <Form.Control
                        placeholder={`${_l("l_last_name_placeholder")}`}
                        type="text"
                        name="last_name"
                        value={customerData && customerData.lastName}
                        onChange={(e) => {
                            setCustomerData({
                                ...customerData,
                                lastName: e.target.value,
                            });
                        }}
                    />
                </Form.Group>
                {
                    type == 'contact' ?
                        <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                            <Form.Label className="input-label no-asterisk ">
                                {_l("l_position")}
                            </Form.Label>
                            <Form.Control
                                placeholder={`${_l("l_position_placeholder")}`}
                                type="text"
                                name="position"
                                value={customerData && customerData.position}
                                onChange={(e) => {
                                    setCustomerData({
                                        ...customerData,
                                        position: e.target.value,
                                    });
                                }}
                            />
                        </Form.Group> : <></>
                }

                <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                        {_l("l_email")}
                    </Form.Label>
                    <Form.Control
                        placeholder={`${_l("l_email_placeholder")}`}
                        type="text"
                        name="email"
                        value={customerData && customerData.email}
                        onChange={(e) => {
                            setCustomerData({
                                ...customerData,
                                email: e.target.value,
                            });
                        }}
                    />
                </Form.Group>
                <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                        {_l("l_company")}
                    </Form.Label>
                    <Form.Control
                        placeholder={`${_l("l_company_placeholder")}`}
                        type="text"
                        name="company"
                        value={customerData && customerData.company}
                        onChange={(e) => {
                            setCustomerData({
                                ...customerData,
                                company: e.target.value,
                            });
                        }}
                    />
                </Form.Group>
                <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                        {_l("l_phone")}
                    </Form.Label>
                    <div className="row">
                        <div className="c_input_border drop_image col-3 col-md-3  col-xl-3 position-relative">
                            <Select
                                className="custom-select-menu"
                                classNamePrefix="react-select"
                                placeholder={`${_l('l_country_code_placeholder')}`}
                                options={countryCodes}
                                closeMenuOnSelect={true}
                                value={customerData.phone_code ? customerData.phone_code : ''}
                                onChange={(value) => {
                                    setCustomerData({
                                        ...customerData,
                                        phone_code: value,
                                    });
                                }}
                            />
                        </div>
                        <div className="col-9 col-md-9 col-xl-9 ps-md-0">
                            <Form.Control
                                placeholder={`${_l("l_phone_placeholder")}`}
                                type="text"
                                name="phone"
                                value={customerData && customerData.phone}
                                onChange={(e) => {
                                    setCustomerData({
                                        ...customerData,
                                        phone: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </Form.Group>
                <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                        {_l("l_website")}
                    </Form.Label>
                    <Form.Control
                        placeholder={`${_l("l_website_placeholder")}`}
                        type="text"
                        name="website"
                        value={customerData && customerData.website}
                        onChange={(e) => {
                            setCustomerData({
                                ...customerData,
                                website: e.target.value,
                            });
                        }}
                    />
                </Form.Group>
                <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                        {_l("l_address")}
                    </Form.Label>
                    <PlacesAutocomplete
                        value={customerData && customerData.leadAddress}
                        onChange={(value) => {
                            setCustomerData({ ...customerData, leadAddress: value });
                        }}
                        onSelect={(value) => {
                            setCustomerData({ ...customerData, leadAddress: value });
                        }}
                        searchOptions={optionsStrict}
                    >
                        {AutosearchPlaces}
                    </PlacesAutocomplete>
                </Form.Group>
                <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                        {_l("l_city")}
                    </Form.Label>
                    <Form.Control
                        placeholder={`${_l("l_city_placeholder")}`}
                        type="text"
                        name="city"
                        value={customerData && customerData.city}
                        onChange={(e) => {
                            setCustomerData({
                                ...customerData,
                                city: e.target.value,
                            });
                        }}
                    />
                </Form.Group>
                <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                        {_l("l_state")}
                    </Form.Label>
                    <Form.Control
                        placeholder={`${_l("l_state_placeholder")}`}
                        type="text"
                        name="state"
                        value={customerData && customerData.state}
                        onChange={(e) => {
                            setCustomerData({
                                ...customerData,
                                state: e.target.value,
                            });
                        }}
                    />
                </Form.Group>
                <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                        {_l("l_country")}
                    </Form.Label>
                    <Select
                        className="custom-select-menu"
                        classNamePrefix="react-select"
                        placeholder={_l("l_country")}
                        options={countryList}
                        closeMenuOnSelect={true}
                        onChange={(value) => {
                            setCustomerData({ ...customerData, leadCountry: value });
                        }}
                        value={customerData && customerData.leadCountry}
                    ></Select>
                </Form.Group>
                <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                        {_l("l_zip_code")}
                    </Form.Label>
                    <Form.Control
                        placeholder={`${_l("l_zip_code_placeholder")}`}
                        type="text"
                        name="zip_code"
                        value={customerData && customerData.leadZipCode}
                        onChange={(e) => {
                            setCustomerData({
                                ...customerData,
                                leadZipCode: e.target.value,
                            });
                        }}
                    />
                </Form.Group>
                <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                        {_l("l_password")}
                    </Form.Label>
                    <Form.Control
                        placeholder={`${_l("l_password_placeholder")}`}
                        type="password"
                        name="password"
                        value={customerData && customerData.leadPassword}
                        onChange={(e) => {
                            setCustomerData({
                                ...customerData,
                                leadPassword: e.target.value,
                            });
                        }}
                    />
                </Form.Group>
                {
                    type == 'operator' ?
                        <>
                            <Form.Group className="col-xl-4 c-input-box pb-4 position-relative ">
                                <Form.Label className="input-label no-asterisk no-asterisk">
                                    {_l('l_region_label')}
                                </Form.Label>
                                <div className="d-flex flex-grow-1 align-items-center">
                                    <Select
                                        placeholder={_l('l_select_region')}
                                        className="custom-select-menu w-100"
                                        classNamePrefix="react-select"
                                        options={regionsList}
                                        closeMenuOnSelect={true}
                                        value={customerData && customerData.region}
                                        onChange={(value) => {
                                            setCustomerData({
                                                ...customerData,
                                                region: value,
                                            });
                                        }}

                                    />
                                </div>
                            </Form.Group>
                            <Form.Group className="col-xl-4 c-input-box pb-4 position-relative ">
                                <Form.Label className="input-label no-asterisk">
                                    {_l('l_type_of_job')}
                                </Form.Label>
                                <Select
                                    className="custom-select-menu"
                                    classNamePrefix="react-select"
                                    placeholder={_l('l_type_of_job_placeholder')}
                                    options={categoryListing}
                                    closeMenuOnSelect={true}
                                    value={customerData && customerData.typeJob}
                                    onChange={(value) => {
                                        setCustomerData({
                                            ...customerData,
                                            typeJob: value,
                                        });
                                    }}
                                />
                            </Form.Group>
                        </>
                        : <></>
                }
                <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk opacity-0">
                        {_l("l_send_password_email")}
                    </Form.Label>
                    <div className="form-check with-span">
                        <div className="d-flex align-items-center">
                            <Form.Label className="form-check-label text_wrap w-auto">
                                <input className="form-check-input"
                                    placeholder={`${_l("l_send_password_email_placeholder")}`}
                                    type="checkbox"
                                    name="send_password_email"
                                    checked={customerData && customerData.setSendPasswordEmail}
                                    onChange={(e) => {
                                        setCustomerData({
                                            ...customerData,
                                            setSendPasswordEmail: e.target.checked,
                                        });
                                    }}
                                />
                                <span> {_l("l_send_password_email")}</span>
                            </Form.Label>
                        </div>
                    </div>
                </Form.Group>
                {/* Space Form */}
                <Form.Group className="col-xl-4 c-input-box pb-3 position-relative d-none">
                    <Form.Label className="input-label no-asterisk ">
                        {_l("l_space_title")}
                    </Form.Label>
                    <Form.Control
                        placeholder={`${_l("l_space_title_placeholder")}`}
                        type="text"
                        name="space_title"
                        value={customerData && customerData.spaceTitle}
                        onChange={(e) => {
                            setCustomerData({
                                ...customerData,
                                spaceTitle: e.target.value,
                            });
                        }}
                    />
                </Form.Group>
                <Form.Group className="col-xl-4 c-input-box pb-3 position-relative d-none">
                    <Form.Label className="input-label no-asterisk ">
                        {_l("l_space_address")}
                    </Form.Label>
                    <PlacesAutocomplete
                        value={customerData && customerData.spaceAddress}
                        onChange={(value) => {
                            setCustomerData({ ...customerData, spaceAddress: value });
                        }}
                        onSelect={handleSelect}
                        searchOptions={optionsStrict}
                    >
                        {AutosearchPlaces}
                    </PlacesAutocomplete>
                </Form.Group>
                <Form.Group className="col-xl-4 c-input-box pb-3 position-relative d-none">
                    <Form.Label className="input-label no-asterisk ">
                        {_l("l_space_type")}
                    </Form.Label>
                    <Select
                        className="custom-select-menu"
                        classNamePrefix="react-select"
                        placeholder={_l("l_select_space_type")}
                        options={spaceTypeOptions}
                        closeMenuOnSelect={true}
                        onChange={(value) => {
                            setCustomerData({ ...customerData, spaceType: value });
                        }}
                        value={customerData && customerData.spaceType}
                    ></Select>
                </Form.Group>
                <Form.Group className="col-xl-4 c-input-box position-relative d-none">
                    <Form.Label className="input-label no-asterisk ">
                        {_l("l_select_region")}
                    </Form.Label>
                    <Select
                        className="custom-select-menu"
                        classNamePrefix="react-select"
                        placeholder={_l("l_select_region")}
                        options={regionsList}
                        closeMenuOnSelect={true}
                        onChange={(value) => {
                            setCustomerData({ ...customerData, spaceRegion: value });
                        }}
                        value={customerData && customerData.spaceRegion}
                    ></Select>
                </Form.Group>
                <Form.Group className="col-xl-4 c-input-box pb-3 position-relative d-none">
                    <Form.Label className="input-label no-asterisk title-fonts color-white-60">
                        {_l("l_superficy")} (m²)
                    </Form.Label>
                    <div className="d-flex align-items-center justify-content-between form-control">
                        <a href="#/"
                            className="c-font f-22 line-height-0"
                            onClick={() => {
                                if (customerData && customerData.superficy >= 50) {
                                    setCustomerData({
                                        ...customerData,
                                        superficy: customerData && customerData.superficy - 50,
                                    });
                                }
                            }}
                        >
                            <Minus size={18} className="c-icons" weight="light" />
                        </a>
                        <input
                            className="form-check-input bg-transparent w-100 text-center"
                            placeholder="0"
                            type="number"
                            value={customerData && customerData.superficy}
                            onChange={(e) => {
                                setCustomerData({
                                    ...customerData,
                                    superficy: e.target.value,
                                });
                            }}
                            name=""
                        />
                        <a href="#/"
                            className="c-font f-22 line-height-0"
                            onClick={() => {
                                setCustomerData({
                                    ...customerData,
                                    superficy: customerData && customerData.superficy + 50,
                                });
                            }}
                        >
                            <Plus size={18} className="c-icons" weight="light" />
                        </a>
                    </div>
                </Form.Group>
                <Form.Group className="col-xl-4 c-input-box pb-3 position-relative d-none">
                    <Form.Label className="input-label no-asterisk title-fonts color-white-60">
                        {_l("l_number_of_desk")}
                    </Form.Label>
                    <div className="d-flex align-items-center justify-content-between form-control">
                        <a href="#/"
                            className="c-font f-22 line-height-0"
                            onClick={() => {
                                if (customerData && customerData.numberOfDesk > 0) {
                                    setCustomerData({
                                        ...customerData,
                                        numberOfDesk: customerData && customerData.numberOfDesk - 1,
                                    });
                                }
                            }}
                        >
                            <Minus size={18} className="c-icons" weight="light" />
                        </a>
                        <input
                            className="form-check-input bg-transparent w-100 text-center"
                            placeholder="0"
                            type="number"
                            name=""
                            value={customerData && customerData.numberOfDesk}
                            onChange={(e) => {
                                setCustomerData({
                                    ...customerData,
                                    numberOfDesk: e.target.value,
                                });
                            }}
                        />
                        <a href="#/"
                            className="c-font f-22 line-height-0"
                            onClick={() => {
                                setCustomerData({
                                    ...customerData,
                                    numberOfDesk: customerData && customerData.numberOfDesk + 1,
                                });
                            }}
                        >
                            <Plus size={18} className="c-icons" weight="light" />
                        </a>
                    </div>
                </Form.Group>
            </div>
        </div>
    )
}

export default LeadContactProviderForm