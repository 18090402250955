import React, { useState, useEffect } from 'react'
import { Info } from "phosphor-react";
import { _l } from '../../../hooks/utilities';
import jquery from "jquery";

function OperatorCard({ Employee, projectId, taskList, setTaskList }) {

    const [showLoader, setShowLoader] = useState(false);
    const FullLeftPanelWidth = jquery('.fixed-left-panel').width();
    const FullLeftPanelHeight = jquery('.fixed-left-panel').height();
    const FullLeftPanelHeight2 = jquery('#employeeListingDropDown').height();
    const [heightWidth, setHeightWidth] = useState({});
    let IdName = 'employeeListingDropDown';

    useEffect(() => {
        if (Object.keys(heightWidth).length) {
            IdName = 'employeeListingDropDown';
            let tempWidth = heightWidth.width;
            let tempHeight = heightWidth.height;
            if (heightWidth.width + jquery("#" + IdName).width() > FullLeftPanelWidth) {
                tempWidth = heightWidth.width - jquery("#" + IdName).width()
                // tempWidth = 332;
            }
            if (heightWidth.height + jquery("#" + IdName).height() > FullLeftPanelHeight) {
                tempHeight = heightWidth.height
                // tempHeight = 400;
            }
            let setposition = {
                position: 'absolute',
                left: tempWidth,
                top: tempHeight > 600 ? Math.abs(tempHeight) - FullLeftPanelHeight2  : Math.abs(tempHeight),
                bottom:'auto',
            };
            // let setposition2 = {
            //     position: 'absolute',
            //     left: tempWidth,
            //     bottom: Math.abs(tempHeight),
            //     top:'auto',
            // };
            jquery("#employeeListingDropDown").css(
                setposition
            );
        }
    }, [heightWidth])

    return (
        <tr>
            <td >
                <div className="name-with-progress max-w300">
                    <div className="d-flex">
                            {/* <a href="#/" className="h35w35 comman-round-box with-bg rounded-circle bg-white-05 p-5px">
                                <div
                                    className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                    style={{ backgroundImage: `url('${Employee.profile_image_url ? Employee.profile_image_url : ''}')` }}
                                ></div>
                            </a> */}
                        <div className="ps-2 w100minus70 text-start flex-grow-1">
                            <div className="d-flex flex-column">
                                <a href="#/" className="title-fonts text-truncate text-start">
                                    {Employee.name ? _l(Employee.name) : ''}
                                </a>
                                <div className="progress-slider">
                                    <div className="d-flex align-items-center">
                                        <div className="progress">
                                            <div
                                                className="progress-bar"
                                                style={{
                                                    width: `${Employee.task_progress ? Employee.task_progress : 0}%`,
                                                }}
                                            ></div>
                                        </div>
                                        <div className="progress-text c-font f-12 title-fonts ps-2">
                                            {Employee.task_progress ? Employee.task_progress : 0}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
            <td className="max-width-100 text-truncate text-center">
                <a href="#/" className="employee-table-items">{Employee.total_time ? Employee.total_time : '-'}</a>
            </td>
            <td>
                <a href="#/" className="employeeListingDropDownOpen employee-table-items"
                    type="button"
                    data-tip={`${_l("l_view_more")}`}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main"
                    onClick={(event) => {
                        // setTaskList([])
                        // taskList(1, Employee.staffid ? Employee.staffid : 0)
                        let width = event.pageX
                        let height = event.pageY
                        setHeightWidth({ width: width, height: height })
                    }}

                >
                    <span className=""> {Employee.requested_count ? Employee.requested_count : '0'}</span>
                    <span className={`color-white-60 c-font f-12 px-1 ${Employee.requested_task_total_time == '00:00' ? 'd-none' : 'd-none'}`}>[ {Employee.requested_task_total_time ? Employee.requested_task_total_time : '-'}]</span>
                    <a><Info size={16} color="#fca21f" className="d-none" weight="light" /></a>
                </a>

            </td>
            <td>
                <a href="#/" className="employeeListingDropDownOpen employee-table-items"
                    type="button"
                    data-tip={`${_l("l_view_more")}`}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main"
                    onClick={(event) => {
                        // setTaskList([])
                        // taskList(1, Employee.staffid ? Employee.staffid : 0)
                        let width = event.pageX
                        let height = event.pageY
                        setHeightWidth({ width: width, height: height })
                    }}

                >
                    <span className=""> {Employee.todo_count ? Employee.todo_count : '0'}</span>
                    <span className={`color-white-60 c-font f-12 px-1 ${Employee.to_do_task_total_time == '00:00' ? 'd-none' : 'd-none'}`}>[ {Employee.to_do_task_total_time ? Employee.to_do_task_total_time : '-'}]</span>
                    <a><Info size={16} color="#fca21f" className="d-none" weight="light" /></a>
                </a>

            </td>
            <td >
            <a href="#/" className='employeeListingDropDownOpen employee-table-items'>
                <span className=" "
                    // data-bs-toggle="dropdown"
                    // data-bs-auto-close="outside"
                    // aria-expanded="false"
                    data-tip={`${_l("l_view_more")}`}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main"
                    onClick={(event) => {
                        // setTaskList([])
                        // taskList(4, Employee.staffid ? Employee.staffid : 0)
                        let width = event.pageX
                        let height = event.pageY
                        setHeightWidth({ width: width, height: height })
                    }}
                > {Employee.ongoing_count ? Employee.ongoing_count : '0'}</span><span className={`color-white-60 c-font f-12 px-1 ${Employee.ongoing_task_total_time == '00:00' ? 'd-none' : 'd-none'}`}>[ {Employee.ongoing_task_total_time ? Employee.ongoing_task_total_time : '-'}]</span>
                <a><Info size={16} color="#fca21f" className="d-none" weight="light" /></a>
                {/* <EmployeeListing list={getTaskList.map((task) => {
                    return {
                        task_name: task.task_name ? task.task_name : '',
                        name: task.creator_details.full_name ? task.creator_details.full_name : '',
                        image: task.creator_details.profile_image_url ? task.creator_details.profile_image_url : '',
                        id: task.id ? task.id : '0',
                        comments_count: task.comments_count ? task.comments_count : '0',
                        unread_comment_count: task.unread_comment_count ? task.unread_comment_count : '0'
                    };

                })} handler={() => { }} updatedCommentCount={(id) => { updatedCommentCount(id) }} showLoader={showLoader} /> */}
            </a>
            </td>
            <td><a href="#/" className='employeeListingDropDownOpen employee-table-items'>
                <span className=""
                    // data-bs-toggle="dropdown"
                    //     data-bs-auto-close="outside"
                    //     aria-expanded="false"
                    data-tip={`${_l("l_view_more")}`}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main"
                    onClick={(event) => {
                        // setTaskList([])
                        // taskList(5, Employee.staffid ? Employee.staffid : 0)
                        let width = event.pageX
                        let height = event.pageY
                        setHeightWidth({ width: width, height: height })
                    }}
                > {Employee.completed_count ? Employee.completed_count : '0'}</span><span className={`color-white-60 c-font f-12 px-1 ${Employee.completed_task_total_time == '00:00' ? 'd-none' : 'd-none'}`}>[ {Employee.completed_task_total_time ? Employee.completed_task_total_time : '00:00'}]</span>
                <a href="#/" className="d-none ps-2"><Info size={16} color="#fca21f" className="d-none" weight="light" /></a>
                {/* <EmployeeListing list={getTaskList.map((task) => {
                    return {
                        task_name: task.task_name ? task.task_name : '',
                        name: task.creator_details.full_name ? task.creator_details.full_name : '',
                        image: task.creator_details.profile_image_url ? task.creator_details.profile_image_url : '',
                        id: task.id ? task.id : '0',
                        comments_count: task.comments_count ? task.comments_count : '0',
                        unread_comment_count: task.unread_comment_count ? task.unread_comment_count : '0'
                    };

                })} handler={() => { }} updatedCommentCount={(id) => { updatedCommentCount(id) }} projectId={projectId} showLoader={showLoader} /> */}
            </a>
            </td>
        </tr>
    )
}

export default OperatorCard