import React from 'react';
// import CSS from '../css/icon_main.css';

function AppointmentplaceholderIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<path d="M83.9685 65.7078V21.8387C83.9685 20.1194 82.5776 18.7256 80.862 18.7256H18.7315C17.0158 18.7256 15.625 20.1194 15.625 21.8387V84.1005C15.625 85.8199 17.0158 87.2136 18.7315 87.2136H62.6293"  strokeLinecap="round" strokeLinejoin="round" />
				<path d="M68.4395 12.5V24.9524"  strokeLinecap="round" strokeLinejoin="round" />
				<path d="M31.1582 12.5V24.9524"  strokeLinecap="round" strokeLinejoin="round" />
				<path d="M15.625 37.4053H83.9685"  strokeLinecap="round" strokeLinejoin="round" />
				<path d="M84.3744 79.7183H68.8418"  strokeLinecap="round" strokeLinejoin="round" />
				<path d="M76.6055 71.9346V87.5"  strokeLinecap="round" strokeLinejoin="round" />
			</svg>



		</React.Fragment>
	)
}

export default AppointmentplaceholderIcon