import React from 'react';
// import CSS from '../css/icon_main.css';

function NoUser(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

			<svg width="78" height="88" viewBox="0 0 78 88" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons w-100 h-100 ${CommanHW}`}>
				<path d="M13.8914 19.1349C13.8884 16.7346 14.364 14.3576 15.2903 12.1422C16.2167 9.9267 17.5754 7.91697 19.2875 6.22973C20.9996 4.5425 23.031 3.21143 25.2635 2.3139C27.496 1.41636 29.8851 0.970271 32.2921 1.00154C37.1278 1.03661 41.7516 2.98568 45.1463 6.42002C48.541 9.85436 50.4287 14.4927 50.3941 19.3148C50.3825 21.7023 49.8979 24.064 48.9681 26.2642C48.0384 28.4643 46.6818 30.4596 44.9763 32.1354C43.2708 33.8111 41.25 35.1342 39.0301 36.0288C36.8102 36.9233 34.4349 37.3716 32.0407 37.3479C29.6457 37.3546 27.2731 36.8873 25.0605 35.9729C22.848 35.0586 20.8394 33.7154 19.1512 32.0213C17.4631 30.3273 16.1289 28.3159 15.226 26.1039C14.3231 23.8918 13.8695 21.523 13.8914 19.1349V19.1349Z"  strokeMiterlimit="10" />
				<path d="M41.098 69.0109C41.098 64.24 42.9986 59.6644 46.3817 56.2909C49.7649 52.9173 54.3534 51.0221 59.1379 51.0221C60.3829 51.0224 61.6245 51.1498 62.8435 51.4025C62.2545 49.3266 61.4621 47.3135 60.4776 45.3925C58.5937 41.7387 55.7458 39.1642 51.6883 38.02C48.9054 37.2385 46.2556 37.1943 43.8661 39.1701C43.1252 39.7485 42.3334 40.2587 41.5002 40.6947C36.798 43.3488 31.8681 44.2158 26.7666 42.0571C24.5191 41.1076 22.4962 39.6449 20.2989 38.5626C19.2372 38.0406 17.9951 37.554 16.8506 37.5835C11.2582 37.731 7.2806 40.562 4.72544 45.419C1.47235 51.6119 0.665001 58.3385 1.11452 65.1772C1.4694 70.5444 4.48887 74.2159 9.69086 75.8319C11.3018 76.2879 12.9699 76.5114 14.6444 76.4955C20.6124 76.5721 26.5803 76.5279 32.5453 76.5279V76.5102C35.9492 76.5102 39.3472 76.5338 42.7541 76.5397C41.6621 74.1792 41.097 71.6105 41.098 69.0109V69.0109Z"  strokeMiterlimit="10" />
				<path d="M59.1375 87.0002C69.1007 87.0002 77.1774 78.9463 77.1774 69.0113C77.1774 59.0763 69.1007 51.0225 59.1375 51.0225C49.1744 51.0225 41.0977 59.0763 41.0977 69.0113C41.0977 78.9463 49.1744 87.0002 59.1375 87.0002Z"  strokeMiterlimit="10" />
				<path d="M49.8223 59.7837L68.4507 78.2414"  strokeLinecap="round" strokeLinejoin="round" />
				<path d="M68.4507 59.7837L49.8223 78.2414"  strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		</React.Fragment>
	)
}

export default NoUser