import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import { _l } from "../../../hooks/utilities";
import EstimateList from "../../../components/EstimateList";
const ClientContractsTab = ({data}) => {
    return (
        <div>
            <EstimateList pageName="customer" clientid={data.id}/>
        </div>
    )
}
export default ClientContractsTab;