import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l, showError } from "../../hooks/utilities";
import CustomerServices from "../../services/customer-services";
import ReactHtmlParser from "react-html-parser";
import Select from "react-select";
import { COUNTRY_CODE_OPTIONS } from "../../constants/constants";
import { useDispatch } from "react-redux";
import { editSpaceAccess } from "../../actions/customer";
import { showMessage } from "../../actions/messages";
import Spinner from "react-bootstrap/esm/Spinner";

const AccessSapceEdit = ({ accessOfficeData, show, handleClose }) => {

  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [wifiCode, setWifiCode] = useState('');
  const [phoneNumber, setphoneNumber] = useState('');
  const [description, setDescription] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [loader, setLoader] = useState(false);
  const [spaceEmail, setSpaceEmail] = useState("");

  const projectId = localStorage.getItem("selectedOffice");

  useEffect(() => {
    if (accessOfficeData) {
      if (accessOfficeData.phonenumber) { 
        let mobile = accessOfficeData.phonenumber.split(" ")[1];
        let c_Code = accessOfficeData.phonenumber.split(" ")[0];
        setCountryCode(c_Code);
        setphoneNumber(mobile);
      }
      setName(accessOfficeData.name)
      setAccessCode(accessOfficeData.accese_code)
      setWifiCode(accessOfficeData.wifi_code)
      setSpaceEmail(accessOfficeData.space_email ? accessOfficeData.space_email.replace("@mg.myr.ai", "") : "");
      if(accessOfficeData.description){
        setDescription(accessOfficeData.description)
      }
    }
  }, [accessOfficeData])

  const handleSave = () => {
    setLoader(true);
    const emailString = `${spaceEmail}@mg.myr.ai`;
    CustomerServices.editAccessOfficeDetail(accessCode, wifiCode, `${countryCode} ${phoneNumber}`, description).then((res) => {
      setLoader(false);
      if (res.status == 1) {
        handleClose()
        dispatch(showMessage("sucess", _l('l_success'), _l(res.message)))
        dispatch(editSpaceAccess({
          accese_code: accessCode,
          description: description,
          name: name,
          phonenumber: `${countryCode} ${phoneNumber}`,
          wifi_code: wifiCode,
          space_email: `${spaceEmail}`
        }))
      }
    })
    if(spaceEmail)
    { 
      CustomerServices.addSpaceEmail(emailString, projectId).catch((e) => {
       console.error(e);
      })
    }
  }

  const checkEmailExists = async() => {
    if(!spaceEmail.trim().length)
    {
      return;
    }
    try{
      const emailString = `${spaceEmail}@mg.myr.ai`;
      const response = await CustomerServices.checkSpaceEmailExists(emailString);
      if(!response.status)
      {
        showError(response.message);
      }
    }
    catch(e)
    {
      console.error(e);
    }
  }

  return (
    <>
      <div className="">
        <Modal
          show={show}
          onHide={handleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>{_l('l_edit') + " " +_l('l_access_office')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className=" pt-0">
            <div className="form-wrapper-main">
              <div className="row">
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                  {_l('l_full_name_label')}
                  </Form.Label>
                  <Form.Control
                    placeholder=""
                    type="text"
                    disabled
                    value={name}
                  />
                </Form.Group>
                <Form.Group className="col-xl-12 c-input-box position-relative pb-3">
                    <Form.Label className="input-label no-asterisk no-asterisk">
                      {_l("l_mobile_number")}
                    </Form.Label>
                    <div className="row g-0 border-0 radius_3">
                      <div className="c_input_border  col-2 position-relative border-right">
                        <Select
                          className="custom-select-menu border-right  border-0  scroll-height-30vh"
                          classNamePrefix="react-select"
                          placeholder={`${_l("l_country_code_placeholder")}`}
                          options={COUNTRY_CODE_OPTIONS}
                          value={COUNTRY_CODE_OPTIONS.find(
                            (x) => x.label == countryCode
                          )}
                          onChange={(e) => {setCountryCode(e.label)}}
                        />
                      </div>
                      <div className="col-10">
                        <Form.Control
                          className=" border-0"
                          placeholder={_l("l_phone_number_placeholder")}
                          type="number"
                          name="phonenumber"
                          value={phoneNumber}
                          onChange={(e) => setphoneNumber(e.target.value)}
                        />
                      </div>
                    </div>
                  </Form.Group>
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                  {_l('l_access_code')} 
                  </Form.Label>
                  <Form.Control
                    placeholder={_l('l_access_code')} 
                    type="text"
                    value={accessCode}
                    onChange={(e) => setAccessCode(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                  {_l('l_space_email')} 
                  </Form.Label>
                  <div className="row g-0">
                    <div className="col-7">
                      <Form.Control
                        placeholder={_l('l_employee_name_placeholder')} 
                        type="text"
                        value={spaceEmail}
                        onChange={(e) => setSpaceEmail(e.target.value)}
                        onBlur={checkEmailExists}
                      />
                    </div>
                    <div className="col-5">
                      <span className="form-control for-disabled color-white-60">@mg.myr.ai</span>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                  {_l('l_wi_fi_code')}
                  </Form.Label>
                  <Form.Control
                    placeholder={_l('l_wi_fi_code')}
                    type="text"
                    value={wifiCode}
                    onChange={(e) => setWifiCode(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
              <Form.Label className="input-label no-asterisk ">
              {_l('l_access_description')}
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder={`${_l("l_type_description_here")}`}
                type="text"
                value={ReactHtmlParser(description)}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l('l_close')}
            </Button>
            <Button 
              variant="primary" disabled={loader} size="sm" 
              onClick={() => {
                handleSave()
              }}>
              {loader ? _l('l_please_wait') : _l('l_save')}
              {loader && <Spinner as="span" animation="border" size="sm" role="status" className={`ms-2`} aria-hidden="true" />}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AccessSapceEdit;
