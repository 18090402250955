import React from 'react';
// import CSS from '../css/icon_main.css';

function RepeatIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
			<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"className={`comman-icons ${CommanHW}`}>
			<path fillRule="evenodd" clipRule="evenodd" d="M12.6768 2.32322C12.5791 2.22559 12.4209 2.22559 12.3232 2.32322C12.2256 2.42085 12.2256 2.57915 12.3232 2.67678L13.3964 3.75L6 3.75L5.99961 3.75C4.87308 3.75176 3.7932 4.20005 2.99662 4.99662C2.20005 5.7932 1.75176 6.87308 1.75 7.99961C1.74979 8.13768 1.86154 8.24978 1.99961 8.25C2.13768 8.25022 2.24979 8.13846 2.25 8.00039C2.25155 7.0062 2.64718 6.05317 3.35018 5.35018C4.05313 4.64722 5.00608 4.2516 6.0002 4.25H13.3964L12.3232 5.32322C12.2256 5.42085 12.2256 5.57915 12.3232 5.67678C12.4209 5.77441 12.5791 5.77441 12.6768 5.67678L14.1766 4.17691L14.1768 4.17678L12.6768 2.32322ZM14.231 3.9043C14.2433 3.93378 14.25 3.9661 14.25 4C14.25 3.96744 14.2437 3.93487 14.231 3.9043ZM3.67678 10.3232C3.77441 10.4209 3.77441 10.5791 3.67678 10.6768L2.60355 11.75H9.99961H9.99976C10.9939 11.7484 11.9469 11.3528 12.6498 10.6498C13.3528 9.94683 13.7485 8.9938 13.75 7.99961C13.7502 7.86154 13.8623 7.74978 14.0004 7.75C14.1385 7.75022 14.2502 7.86232 14.25 8.00039C14.2482 9.12692 13.8 10.2068 13.0034 11.0034C12.2068 11.8 11.1269 12.2482 10.0004 12.25H10H2.60355L3.67678 13.3232C3.77441 13.4209 3.77441 13.5791 3.67678 13.6768C3.57915 13.7744 3.42085 13.7744 3.32322 13.6768L1.82322 12.1768C1.79925 12.1528 1.78117 12.1252 1.76897 12.0957C1.75788 12.0689 1.75165 12.0405 1.75029 12.012C1.7501 12.008 1.75 12.004 1.75 12C1.75 11.9661 1.75675 11.9338 1.76897 11.9043C1.78117 11.8748 1.79925 11.8472 1.82322 11.8232L3.32322 10.3232C3.42085 10.2256 3.57915 10.2256 3.67678 10.3232Z"/>
			</svg>
		</React.Fragment>
	)
}

export default RepeatIcon