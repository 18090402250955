import React, { useEffect, useState } from "react";
import { _l, isSolarHubPlatform } from "../../hooks/utilities";
import AddAssigneeMainDropdown from "../TaskModalComponents/AddAssigneeMainDropdown";
import CommentDropdown from "./CommentDropdown";
import DropdownWithAccordion from "./DropdownWithAccordion";
import TaskListStatusDropdown from "./TaskListStatusDropdown";
import "react-datepicker/dist/react-datepicker.css";
import GeneralListing from "../TaskModalComponents/GeneralListing";
import { CaretRight } from "phosphor-react";
import { DropdownToggle, UncontrolledDropdown ,DropdownItem, DropdownMenu } from 'reactstrap';
import AccessDescriptionBox from "../Myspace/MyspaceComponent/AccessDescriptionBox";
import { useDispatch, useSelector } from "react-redux";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { unreadTaskComment } from "../../actions/customer";

const ActionDropdown = ({ actions, list, handler, loder, setLoder, actionStausChange,widthClass ,unControlList=true ,IsKanbanView, benchmark_id , selectedspaceStatus,
  setSelectedspaceStatus =()=>{},type="", isLoginAsUserDataLoaded}) => {
  const [dropdownData, setDropdownData] = useState(list);
  const [dropdownOpen, setDropdownOpen] = useState([]);
  const { getSelectedFloorId } = useSelector((state) => state.customer)
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [selectedId, setSelectedId] = useState([]);
  const base_url = process.env.REACT_APP_CHAT_WEB_BASE_URL;
  const dispatch = useDispatch()
  useEffect(() => {
    setDropdownData([]);
    if (list && list.length) {
      setDropdownData(list);
    }

  }, [JSON.stringify(list)]);

  // let array = actions.map((ss) => [_l("l_assign")].includes(ss.actionName))
  return (
    <ul
      className={`dropdown-menu commentDropdownclose ${type == "forMe" ? "WithPosition overflowscroll max-height-50vh " : ""} ${type == "equipments" || type =="roomSetup" ? "overflowscroll" : ""} ${window.location.pathname == '/benchmark' || window.location.pathname == '/schedule' ? 'w-250' : 'w-140'} ${widthClass}`}
      aria-labelledby="actionDropdown"
      data-popper-placement="left-start"
      
    >
      
      {window.location.pathname == "/benchmark" || window.location.pathname == "/schedule"?
      (
        <>
        <li className="dropdown-item" >
          <a href={`${base_url}/orb/admin/benchmarks#`} target="_blank"><span>{_l("l_view_benchmark_related_tasks")}</span></a>
        </li>
        <li>
          <hr className="dropdown-divider my-0" />
        </li>
        <li className="dropdown-item" >
            <a href={`${base_url}/orb/benchmarks/create_customer_provider/${benchmark_id}`} target="_blank"><span>{_l('l_create_customer_provider_manually')}</span></a>
        </li>
        <li>
          <hr className="dropdown-divider my-0" />
        </li>
        <li className="dropdown-item"> 
            <a href={`${base_url}/orb/cron/generate_current_month_benchmark_tasks/${benchmark_id}`} target="_blank"><span>{_l("l_generate_task_manually")}</span></a>
        </li>
        <li>
          <hr className="dropdown-divider my-0" />
        </li>
        <li className="dropdown-item"> 
            <a href={`${base_url}/orb/admin/projects/view_schedule/${localStorage.getItem('staff_id')}/${benchmark_id}`} target="_blank"><span>{_l("l_view")}</span></a>
        </li>
        <li>
          <hr className="dropdown-divider my-0" />
        </li>
        <li className="dropdown-item"> 
            <a href={`${base_url}/orb/benchmarks#`} target="_blank"><span>{_l('l_completed') + " " + _l("l_tasks")}</span></a>
        </li>
        <li>
          <hr className="dropdown-divider my-0" />
        </li>
        <li className="dropdown-item"> 
            <a href={`${base_url}/orb/admin/benchmarks/proposals/send_email/${benchmark_id}/0`} target="_blank"><span>{_l("l_send_email_to_reg")}</span></a>
        </li>
        <li>
          <hr className="dropdown-divider my-0" />
        </li>
        <li className="dropdown-item"> 
            <a href={`${base_url}/orb/admin/benchmarks/proposals/send_email/${benchmark_id}/1`} target="_blank"><span>{_l("l_send_email_to_reg")}</span></a>
        </li>
        <li>
          <hr className="dropdown-divider my-0" />
        </li>
        <li className="dropdown-item"> 
            <a href={`${base_url}/orb/admin/benchmarks/proposals/send_email/${benchmark_id}/2`} target="_blank"><span>{_l("l_send_email_to_both")}</span></a>
        </li>
        <li>
          <hr className="dropdown-divider my-0" />
        </li>
        <li className="dropdown-item"> 
            <a href={`${base_url}/orb/admin/benchmarks/destroy/${benchmark_id}`} target="_blank"><span>{_l("l_delete")}</span></a>
        </li>
      </>
      )
      :
      <>
      {
        type == "forMe" ?
        <>
         <div className=" d-flex align-items-center justify-content-between position-sticky top-0 z-index-7 calendar-box-bg list-view p-10 rounded-0 c-font f-11 fw-semibold">{_l("l_for_me")}</div>
         </> : <> </>}
        <>
   {   actions && actions.length ? (
        actions.map((actionItem, index) => {
          var IconComponent = require("../../assets/icons/" + actionItem.actionIcon).default;
          return (
            <li
              className={`hr_sepretr ${actionItem.actionName === _l("l_status") || actionItem.actionName === _l("l_assign")|| actionItem.actionName === _l("l_due_date") || actionItem.actionName === _l("l_comment")
                ? "dropend "
                : " "
                } `}
              key={index}
            >
              <a href="#/"
                className={`dropdown-item ${actionItem.active ? "active with-tick" : ""} ${actionItem.actionName === _l("l_login_as_user") || actionItem.actionName === _l("l_status")|| actionItem.actionName === _l("l_assign")|| actionItem.actionName === _l("l_due_date") || actionItem.actionName === _l("l_comment") || actionItem.actionName === _l("l_access_office") || actionItem.actionName ===  _l("l_change") +" "+ _l("l_project_status") || actionItem.ActionType == "equipments" || actionItem.ActionType == "roomSetup"
                  ? "d-none"
                  : ""
                  } ${actionItem.actionName === _l("l_list_view") && !IsKanbanView ? "active with-tick" : ""} ${actionItem.actionName === _l("l_kanban_view") && IsKanbanView? "active with-tick" : ""}`}
                onClick={(e) => { e.stopPropagation()
                  if (actionItem.actionName === _l("l_status")) {
                    // setShow(!show);
                  } else {
                    actionItem.actionHandler()
                    dispatch(unreadTaskComment("my_task"))
                  }
                }}
              >
               <div className="d-flex align-items-center text-truncate">
                  <div className="c-list-icon comman_action_icon">
                    <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
                      {actionItem.profileImage && actionItem.profileImage != "" ?
                      <div
                        className="comman-bg-img h-100 w-100 bg-style-cover"
                        style={{ backgroundImage: `url(${actionItem.profileImage})` }}
                      >
                      </div>
                      :
                      <IconComponent />
                      }
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between w100minus35">
                    <div className="ps-2 text_wrap">
                        <div className="c-list-detail text-truncate ">
                          {actionItem.actionName}
                        </div>
                    </div>
                    <div className={`rounded-pill badge-custom d-flex with-position-relative  ${actionItem.total_notification_count && actionItem.total_notification_count.task_count > 0 ? '' : 'd-none'} ${actionItem.active ? 'me-4' :''}`}>
                      <span className={`m-auto ps-0`}>
                        {actionItem.total_notification_count && actionItem.total_notification_count.task_count }
                      </span>
                  </div>
                  </div>
                </div>

              </a>
              {actionItem.ActionType == "equipments" || actionItem.ActionType == "roomSetup" ? (
                <>
                  <UncontrolledDropdown direction="end" className="w-100 dropdown-item " >
                    <DropdownToggle className="w-100 border-0 no-hover" onClick={() => actionItem.expandChildMenu(actionItem)}>
                      <div
                        className="d-flex align-items-center text-truncate"
                      >
                        <div className="c-list-icon comman_action_icon">
                          <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
                            <IconComponent />
                          </div>
                        </div>
                        <div className="ps-2 w100minus40 text_wrap text-start"
                        data-tip={actionItem.actionName}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                        >
                          <div className="c-list-detail text-truncate ">
                            {actionItem.actionName}
                          </div>
                        </div>
                        <div className="c-list-icon">
                          <CaretRight size={14} weight="light" className="c-icons " />
                        </div>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu container="body" className="w-200">
                    <DropdownItem toggle={false} className="p-0">
                      <DropdownWithAccordion
                          list={
                            actionItem.ActionType == "roomSetup" ?
                            actionItem.list.filter((e) => e.floor_id == getSelectedFloorId)
                            :
                            actionItem.list.map((e) => {return{...e,equipment_id : actionItem.id}})
                          }
                          handler={handler}
                          setLoder={() => { }}
                          unControlList={unControlList}
                          type={actionItem.ActionType == "roomSetup" ? "roomSetup" : "equipment"}
                        />
                    </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </>) : actionItem.actionName === _l("l_login_as_user") ? (
                <>
                <UncontrolledDropdown direction="end" className="w-100 dropdown-item " >
                  <DropdownToggle  className="w-100 border-0 no-hover"  onClick={actionItem.actionHandler}>
                    <div
                      className="d-flex align-items-center text-truncate"
                      // onClick={actionItem.actionHandler}
                    >
                      <div className="c-list-icon comman_action_icon">
                        <div className="h32w32 d-flex align-items-center rounded-5 action_icon with_bg">
                          <IconComponent />
                        </div>
                      </div>
                      <div className="ps-2 w100minus40 text_wrap text-start">
                        <div className="c-list-detail text-truncate text-capitalize">
                          {actionItem.actionName}
                        </div>
                      </div>
                      <div className="c-list-icon">
                        <CaretRight size={14} weight="light"className="c-icons "/>
                      </div>
                    </div>
                    </DropdownToggle>
                    {isSolarHubPlatform() && localStorage.getItem("user_type") != "staff" ? <DropdownItem toggle={false} className="p-0">
                  <DropdownWithAccordion
                    list={dropdownData}
                    handler={handler}
                    loder={loder}
                    setLoder={setLoder}
                    unControlList={unControlList}
                    loginuser={actionItem.actionName}
                    isLoginAsUserDataLoaded={isLoginAsUserDataLoaded}
                  />
                 </DropdownItem> : <></>}
                  </UncontrolledDropdown>
                </>) : actionItem.actionName === _l("l_access_office") ? (
                  <>
                    <UncontrolledDropdown direction="end" className="w-100 dropdown-item " >
                      <DropdownToggle className="w-100 border-0 no-hover" onClick={actionItem.actionHandler}>
                        <div
                          className="d-flex align-items-center text-truncate"
                        // onClick={actionItem.actionHandler}
                        >
                          <div className="c-list-icon comman_action_icon">
                            <div className="h32w32 d-flex align-items-center rounded-5 action_icon with_bg">
                              <IconComponent />
                            </div>
                          </div>
                          <div className="ps-2 w100minus40 text_wrap text-start">
                            <div className="c-list-detail text-truncate text-capitalize">
                              {actionItem.actionName}
                            </div>
                          </div>
                          <div className="c-list-icon">
                            <CaretRight size={14} weight="light" className="c-icons " />
                          </div>
                        </div>
                      </DropdownToggle>
                      <DropdownItem toggle={false} className="p-0">
                        <AccessDescriptionBox />
                      </DropdownItem>
                    </UncontrolledDropdown>
                  </>
              ) : actionItem.actionName === _l("l_status") ? (
                <>
                  <a href="#/"
                    className="dropdown-item"
                    type="button"
                    id="Loginuserdropdown"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                  >
                    <div
                      className="d-flex align-items-center text-truncate"
                      onClick={actionItem.actionHandler}
                    >
                      <div className="d-flex align-items-center w100minus10 text_wrap">
                        <div className="c-list-icon comman_action_icon">
                          <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
                            <IconComponent />
                          </div>
                        </div>
                        <div className="ps-2 w100minus30 text_wrap">
                          <div className="c-list-detail text-truncate ">
                            {actionItem.actionName}
                          </div>
                        </div>
                      </div>
                      <div className="c-list-icon">
                        <CaretRight size={14} weight="light" className="c-icons" /> 
                      </div>
                    </div>
                  </a>
                  <TaskListStatusDropdown
                    setTaskStatus={actionStausChange}
                    is_task={1}
                    bulkAction={true}
                  />
                </>
              ) : actionItem.actionName === _l("l_assign") ? (
                <>
                  <a href="#/"
                    className="dropdown-item"
                    type="button"
                    id="Loginuserdropdown"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                  >
                    <div
                      className="d-flex align-items-center text-truncate"
                      onClick={actionItem.actionHandler}
                    >
                      <div className="d-flex align-items-center w100minus10 text_wrap">
                        <div className="c-list-icon comman_action_icon">
                          <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
                            <IconComponent />
                          </div>
                        </div>
                        <div className="ps-2 w100minus30 text_wrap">
                          <div className="c-list-detail text-truncate ">
                            {actionItem.actionName}
                          </div>
                        </div>
                      </div>
                      <div className="c-list-icon">
                        <CaretRight size={14} weight="light" className="c-icons" /> 
                      </div>
                    </div>
                  </a>
                  <AddAssigneeMainDropdown 
                  actionStausChange={actionStausChange}
                  action={true}
                  fromTaskcard="1"
                  selectedTagId={""}
                  selectedId={selectedId}
                  pageName="task"
                  direction="left"
                  bulkAction={true}
                  setSelectedId={setSelectedId}
                  />


                </>
              ) : actionItem.actionName === _l("l_due_date") ? (
                <>
                <a href="#/"
                    className="dropdown-item"
                    type="button"
                    id="Loginuserdropdown"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                  >
                    <div
                      className="d-flex align-items-center text-truncate"
                      onClick={actionItem.actionHandler}
                    >
                      <div className="d-flex align-items-center w100minus10 text_wrap">
                        <div className="c-list-icon comman_action_icon">
                          <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
                            <IconComponent />
                          </div>
                        </div>
                        <div className="ps-2 w100minus30 text_wrap">
                          <div className="c-list-detail text-truncate ">
                            {actionItem.actionName}
                          </div>
                        </div>
                      </div>
                      <div className="c-list-icon">
                        <CaretRight size={14} weight="light" className="c-icons" /> 
                      </div>
                    </div>
                  </a>
                  <GeneralListing actionStausChange={actionStausChange} type = {"bulkActionDate"} widthClass = "w-350 custom-datepicker overflow-hidden max-height-50vh date datepicker_w_100 " />
                </>
              ) : actionItem.actionName === _l("l_comment") ? (
                <>
                  <a href="#/"
                    className="dropdown-item"
                    type="button"
                    id="Loginuserdropdown"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                  >
                    <div
                      className="d-flex align-items-center text-truncate"
                      onClick={actionItem.actionHandler}
                    >
                      <div className="d-flex align-items-center w100minus10 text_wrap">
                        <div className="c-list-icon comman_action_icon">
                          <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
                            <IconComponent />
                          </div>
                        </div>
                        <div className="ps-2 w100minus30 text_wrap">
                          <div className="c-list-detail text-truncate ">
                            {actionItem.actionName}
                          </div>
                        </div>
                      </div>
                      <div className="c-list-icon">
                        <CaretRight size={14} weight="light" className="c-icons" /> 
                      </div>
                    </div>
                  </a>
                  <CommentDropdown action={true} actionStausChange={actionStausChange} bulkAction={true} />
                </>
              ) :actionItem.actionName ===  _l("l_change") +" "+ _l("l_project_status") ?(
              <>
               <UncontrolledDropdown direction="end" className="w-100 dropdown-item " >
                  <DropdownToggle  className="w-100 border-0 no-hover"  onClick={actionItem.actionHandler}>
                    <div
                      className="d-flex align-items-center text-truncate"
                      // onClick={actionItem.actionHandler}
                    >
                      <div className="c-list-icon comman_action_icon">
                        <div className="h32w32 d-flex align-items-center rounded-5 action_icon with_bg">
                          <IconComponent />
                        </div>
                      </div>
                      <div className="ps-2 w100minus40 text_wrap text-start">
                        <div className="c-list-detail text-truncate text-capitalize">
                          {actionItem.actionName}
                        </div>
                      </div>
                      <div className="c-list-icon">
                        <CaretRight size={14} weight="light"className="c-icons "/>
                      </div>
                    </div>
                    </DropdownToggle>
                    <DropdownItem toggle={false} className="p-0">
                    <DropdownWithAccordion
                    list={[]}
                    handler={handler}
                    loder={loder}
                    setLoder={setLoder}
                    unControlList={unControlList}
                    is_projectStatus={true}
                    project_status={dropdownData}
                    selectedspaceStatus={selectedspaceStatus}
                    setSelectedspaceStatus={setSelectedspaceStatus}
                  />
                 </DropdownItem>
                  </UncontrolledDropdown></>) :(<></>)}
            </li>
          );
        })
      ) : (
        <>
              {type == "equipments" || type == "roomSetup" ?
                <CommanPlaceholder />
                : <></>
              }
        </>
      )}
      </>
      </>
      }
    </ul>
  );
};
export default ActionDropdown;
