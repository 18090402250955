import React, { useEffect, useState } from "react";
import { CaretRight,CaretLeft,X } from "phosphor-react";
import myrLogo from "../../assets/images/myr-ai-logo-white.svg";
import LogoGreenDark from "../../assets/icons/LogoGreenDark";
import LogoGreenLight from "../../assets/icons/LogoGreenLight";
import moment from "moment";
import jquery from "jquery";
import { TaskCommentModalToggle, _l, getFileType, isSolarHubPlatform} from "../../hooks/utilities";
import ReactTooltip from "react-tooltip";
import CommanFooterPlus from "../CommanFooterPlus";
import { setImageViewerisOPen, setTaskAttchmentsForCreate } from "../../actions/customer";
import { useDispatch, useSelector } from "react-redux";
import LogoSolarHub from "../../assets/images/solairehublogo.svg"

const ImageViewer = ({attachmentURL, attachmentDate, changeAttach, showPrevBtn, showNextBtn, handleAction, CloseCommentModal,isChat,docType,showCommentSection,ShowCommentModal,pageName=""}) => {


  const {themeColor} = useSelector((state) => state.customer)
  const [LightTheme , setLightTheme] = useState(localStorage.getItem('selectedTheme') == "light" ? true : false);

  const dispatch = useDispatch();
  useEffect(() => {
  dispatch(setImageViewerisOPen(true));    
  }, [])

  useEffect(() => {
    if (themeColor == "light") {
      jquery("html").addClass("light-theme")
      setLightTheme(true);
    }else{
      jquery("html").removeClass("light-theme")
      setLightTheme(false);
    }

    
  }, [themeColor])
  
  return (
    <>
      <div className="flex-grow-1 right-content-part flex-grow-1 h-100 position-relative c-list-icon w-100 h_16vh with-overlay light-theme-base-body-color radius_3">
        <div className={`header-logo position-absolute top-0 start-0 z-index-2 w-100`}>
          <div className="d-flex align-items-center justify-content-between">
          {isSolarHubPlatform() ? (
              <>
                <img src={LogoSolarHub} alt="" />
              </>
            ) : (
              <>
                {LightTheme ? (
                  <LogoGreenLight className="header-logo-img " />
                ) : (
                  <LogoGreenDark className="header-logo-img" />
                )}
              </>
            )}
            {/* {LightTheme ? < LogoGreenLight className="header-logo-img p-2 max-w-130px h-auto "/> :  <LogoGreenDark  className="header-logo-img p-2 max-w-130px h-auto "/>} */}
            {/* <a href="#/" 
              className="p-3 action_icon with_bg on-hover-active-toggle-img light-theme-img"
              onClick={() => {
                // CloseCommentModal(isChat);
                // handleAction("modalClose");
                // jquery(".image-view-comment").addClass("d-none");
                // if(!jquery(".taskDetailModal").hasClass(".modal-full-left-panel")){
                // }
                dispatch(setImageViewerisOPen(false));   
                handleAction("modalClose");
                if(jquery('#commanCanvasImageBody').length == 1){
                  if(jquery('#commonCanvasBody').length == 0){
                    if(jquery(".modal-full-left-panel").length > 1){
                      if(jquery('body').hasClass('with-right-penel')){
                      }else{
                        jquery('body').addClass('with-right-penel');
                      }
                      jquery('body').addClass('task-modal-offcanvas-open');
                      }else{
                      jquery('body').removeClass('with-right-penel');
                      jquery('body').removeClass('task-modal-offcanvas-open');
                    }
                      jquery('body').addClass('with-left-penel');
                  }
                  if((jquery('.comman-comments').length == 0)&& jquery("#commonCanvasBody").length ==1){
                      jquery('body').removeClass('with-right-penel');
                      jquery('body').addClass('with-left-penel');
                  }
              }
              dispatch(setTaskAttchmentsForCreate())
              }}
            >
              <X size={18} weight="light" className="c-icons" />
            </a> */}
          </div>
        </div>
        {getFileType(attachmentURL) == "pdf" ?
          <iframe src={attachmentURL} width="100%" height="80%" title="PDF Preview" className="mt-5" /> 
         :
          <div className="comman-image-box-bg bg-style-contain h-100 w-100 radius_3" style={{ backgroundImage: `url('${attachmentURL}')` }}></div>
        }
        { showPrevBtn ? 
        <a href="#/" className="ms-2 bg-silder-btn h40w40 with_overlay d-flex  rounded-circle position-absolute top-50 start-0 translate-middle-y"
        data-tip={_l("l_previous")}
        data-effect="solid"
        data-delay-show='1000'
        data-class="tooltip-main"
        onClick={() => changeAttach("prev")}>
          <CaretLeft size={18} weight="light" className="c-icons " />
          <ReactTooltip />
        </a>
        : <></>}
        { showNextBtn ? 
        <a href="#/" className="me-2 bg-silder-btn h40w40 with_overlay d-flex  rounded-circle position-absolute top-50 end-0 translate-middle-y"
        data-tip={_l("l_next")}
        data-effect="solid"
        data-delay-show='1000'
        data-class="tooltip-main"
        onClick={() => changeAttach("next")}>
          <CaretRight size={18} weight="light" className="c-icons " />
          <ReactTooltip />
        </a>
        : <></>}
        <div className="file-uploader-name d-flex pb-3"></div>
        <div className="position-absolute top-100 start-50 translate-middle pb-5 d-none">
        <a href="#/" className="rounded-pill text-nowrap c-green-border px-2 py-1 mb-5"><span className="color-green title-font c-font f-14">{attachmentDate && attachmentDate != "" ? moment(attachmentDate).format("DD/MM/YYYY hh:MM a") : ""}</span></a>
        </div>
        <CommanFooterPlus MainClass="with-position" bottomMenuClass="footer-right-10" id="ImageViewerFooterHandle" toggleId="ImageViewerFooterShow" docType={docType} />
        <ReactTooltip />
      </div>
    </>
  );
};

export default ImageViewer;
