import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { _l } from "../../../hooks/utilities";

const EditProposalItem = ({
  fields,
  setFields,
  handleClose,
  headerTitle = "Edit accesories",
  index,
  keyName
}) => {
  return (
    <Modal
      show={true}
      onHide={handleClose}
      backdrop={"static"}
      keyboard={false}
      size="lg"
      centered
      className="custom-modal-style solarcommanmodal "
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header className="p-3" closeButton>
        <Modal.Title>{_l(headerTitle)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-wrapper-main p-0">
          <div className="row">
            <Form.Group className="col-xl-12 c-input-box pb-20px position-relative">
              <Form.Label className="input-label no-asterisk ">
                {_l("l_title")}
              </Form.Label>
              <Form.Control
                placeholder={`${_l("l_accessories_title")}`}
                // as="textarea"
                type="text"
                name=""
                value={(fields && fields[index] && fields[index][keyName]) || (fields && fields[index] && fields[index].title)}
                onChange={(e) => {
                  setFields(keyName || "title", e.target.value, index);
                }}
              />
            </Form.Group>

            <Form.Group className="col-xl-12 c-input-box pb-20px position-relative">
              <Form.Label className="input-label no-asterisk ">
                {_l("l_description")}
              </Form.Label>
              <Form.Control
                placeholder={`${_l("l_accessories_description")}`}
                as="textarea"
                type="text"
                name=""
                value={fields && fields[index] && fields[index].description}
                onChange={(e) => {
                  setFields("description", e.target.value, index);
                }}
              />
            </Form.Group>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {_l("l_cancel")}
        </Button>
        <Button variant="primary" onClick={handleClose}>
          {_l("l_save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditProposalItem;
