import React from 'react';
// import CSS from '../css/icon_main.css';

function GetCoordinates(props) {
    const height = (props.height) ? props.height : "13";
    const width = (props.width) ? props.width : "16";
    const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
    const CommanHW = (props.className) ? props.className : "";
    // const fill 		= (props.fill) ? props.fill : "#ffffff";

    return (
        <React.Fragment>
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
                <g clip-path="url(#clip0_5209_12715)">
                    <path d="M19.5234 22.1797H3.98437C2.51732 22.1797 1.32812 20.9904 1.32812 19.5234V3.98443C1.32812 2.51745 2.51732 1.32819 3.98437 1.32819H19.5234C20.9904 1.32819 22.1796 2.51745 22.1796 3.98443V19.5234C22.1796 20.9904 20.9904 22.1797 19.5234 22.1797Z" stroke="black" stroke-width="2" stroke-miterlimit="10" />
                    <path d="M31.3398 32.3438H29.75V33H31.3398C32.2546 33 32.9961 32.2585 32.9961 31.3438V29.6875H32.3398V31.3438V32.3438H31.3398Z" fill="black" stroke="black" stroke-width="2" />
                    <path d="M19.7812 32.3438H19.125V33H19.7812V32.3438ZM25.0937 32.3438H24.4375V33H25.0937V32.3438Z" fill="black" stroke="black" stroke-width="2" />
                    <path d="M31.3398 12.1484H32.3398V13.1484V14.8047H32.9961V13.1484C32.9961 12.2337 32.2546 11.4922 31.3398 11.4922H29.75V12.1484H31.3398Z" fill="black" stroke="black" stroke-width="2" />
                    <path d="M24.4375 11.4922V12.1485H25.0937V11.4922H24.4375ZM32.9961 19.9844V19.3281H32.3398V19.9844H32.9961ZM32.9961 25.1641V24.5078H32.3398V25.1641H32.9961Z" fill="black" stroke="black" stroke-width="2" />
                    <path d="M13.0156 32.3438H12.0156V31.3438V29.6875H11.3594V31.3438C11.3594 32.2585 12.1009 33 13.0156 33H14.6055V32.3438H13.0156Z" fill="black" stroke="black" stroke-width="2" />
                    <path d="M12.0156 24.5078H11.3594V25.1641H12.0156V24.5078Z" fill="black" stroke="black" stroke-width="2" />
                    <path d="M10.1562 16.8672H16.7969V10.2266" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M16.8027 16.8672L9.79688 9.86135" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
                </g>
                <defs>
                    <clipPath id="clip0_5209_12715">
                        <rect width="34" height="34" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </React.Fragment>
    )
}

export default GetCoordinates