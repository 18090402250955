import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { _l } from "../../../hooks/utilities";
import AddCatalogItem from "./AddCatalogItem";
import benchmarkServices from "../../../services/benchmark-services";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";
import { Check, Funnel, PencilSimpleLine, Star, Trash } from "phosphor-react";
import { useDispatch } from "react-redux";
import { showConfirmation, showMessage } from "../../../actions/messages";
import solarpanelsimg from "../../../assets/images/solarpanelsimg.svg";
import PreviewNotAvailable from "../../../assets/images/preview-not-available.jpg";
import AddItemGridSkeleton from "../../Skeleton/AddItemGridSkeleton";
import AddItemGridRowSkeleton from "../../Skeleton/AddItemGridRowSkeleton";

const ChooseCatalogItem = ({
  handleClose,
  addCatalogItems,
  rel_type,
  setPricingTemplates,
  pricingTemplates,
  isFrom = ''
}) => {
    const [addItems, setAddItems] = useState(false)
    const [brandsItems, setBrandsItems] = useState([]);
    const [editBrandItem, setEditBrandItem] = useState({})
    const [toggleView, setToggleView] = useState(JSON.parse(localStorage.getItem("catelog_toggle_View")) == true ? true : false);
    const [showSckeleton, setShowSckeleton] = useState(true);
    const [loadedImages, setLoadedImages] = useState({});

    const [isLoading, setIsLoading] = useState(true);

    const handleLoad = () => {
      setIsLoading(false);
    };
  
    const handleError = () => {
      setIsLoading(false);
      // You might want to handle errors (like displaying a placeholder image)
    };
    
    const dispatch = useDispatch();

    useEffect(() => {
      setShowSckeleton(true);
     try {
      benchmarkServices.getSolarProposalBrands(rel_type).then((res) => {
        if (res && res.status) {
      
          setBrandsItems(res.data);
          // setTimeout(() => {
            setShowSckeleton(false);
          // }, 500);
        }else{
          setShowSckeleton(false);
        }
      })
     } catch (error) {
      
     }
    }, [])

    const updateAddCatalog = (item) => {
      if (isFrom == "proposals") {
        if (addCatalogItems == pricingTemplates.length) {
          let newItem = [{
          "title": item.name,
          "brand_id": item.id,
          "one_time_price": item.rate,
          "number_of_month": "",
          "monthly_price": "",
          "provider_tax": item.tax,
          "is_default": 0,
          "one_time_amount": item.rate,
          "one_time_tax_amount": (Number(item.rate || 0) * Number(item.tax || 0) * 0.01),
          "name": item.name,
          "description": item.description,
          "attachment_url": item.document && item.document[0] && item.document[0].image_url ? item.document[0].image_url : '' 
          }]
          setPricingTemplates(pricingTemplates.concat(newItem))
        }else{
          setPricingTemplates(
            pricingTemplates.map((pt, i) => {
              if (i == addCatalogItems) {
                pt.one_time_amount = item.rate;
                pt.one_time_price = item.rate;
                pt.provider_tax = item.tax;
                pt.attachment_url = item.document && item.document[0] && item.document[0].image_url ? item.document[0].image_url : '' ;
                pt.brand_id = item.id
                pt.name = item.name
                pt.title = item.name
                pt.description = item.description
              }
              return pt;
            })
          );
        }
      }else{
        setPricingTemplates(
          pricingTemplates.map((pt) => {
            if (pt.position == addCatalogItems) {
              pt.value = item.rate;
              pt.tax = item.tax;
              pt.url = item.document && item.document[0] && item.document[0].image_url ? item.document[0].image_url : '' ;
              pt.brand_id = item.id
              pt.item_name = item.name
              pt.name = item.name
              pt.description = item.description
            }
            return pt;
          })
        );
      }
      handleClose();
    }
  const deleteCatelogItem = (brandId) => {
    dispatch(showConfirmation(_l("l_are_you_sure"), _l("l_cancel_catalog_item"), _l("l_yes"), _l("l_no"), () => {}, async() => {
    try {
      benchmarkServices.deleteCatelogItemBrand(brandId).then((res) => {
        if (res.status) {
          let updatebrandsItems = brandsItems.filter((c) => c.id != brandId);
          setBrandsItems(updatebrandsItems.slice());
          dispatch(showMessage("sucess", _l("l_success"), _l(res.message)));
        }else{
          dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
        }
      })
    } catch (error) {
      console.log('error', error);
    }
    }))
  }

  const handleImageLoad = (index) => {
    setLoadedImages((prevState) => ({ ...prevState, [index]: true }));
  };

  // Handle image error event (optional, for broken images)
  const handleImageError = (index) => {
    setLoadedImages((prevState) => ({ ...prevState, [index]: true }));
  };
    
  return (
    <>
        <Modal
        show={true}
        onHide={handleClose}
        backdrop={"static"}
        keyboard={false}
        size="xl"
        centered
        className="custom-modal-style solarcommanmodal maxheight85vh "
        aria-labelledby="contained-modal-title-vcenter"
        >
        <Modal.Header className="p-3 d-flex align-items-center justify-content-between" closeButton>
            <Modal.Title>Ajouter des articles</Modal.Title>
            <div className="topic-switch d-flex align-items-center me-5 text-capitalize">
              <div className="form-check form-check-inline m-0 d-flex align-items-center gap10px">
                <div>vue en grille</div>
                <Form.Check 
                  className=" with-no-label-space" 
                  type="switch" 
                  role="switch"
                  checked={toggleView}
                  onChange={() => {
                    setToggleView(!toggleView);
                    localStorage.setItem("catelog_toggle_View", !toggleView);
                  }}
                />
                <div>vue en liste</div>
              </div>
            </div>
        </Modal.Header>
        <Modal.Body className="overflow-hiiden-auto p-20pximp minheight200px">
          {/* List view start */}
          {toggleView ?
          <>
          {showSckeleton ? <AddItemGridRowSkeleton/> : brandsItems && brandsItems.length > 0 ?
            brandsItems.map((brand) => ( 
              <>
          <div className="bg-white border d-flex flex-column mb-10px p-10 radius_3">
            <div className="align-items-center d-flex flex-grow-1 justify-content-between" onClick={() => {updateAddCatalog(brand)}}>
              <div className="align-items-center d-flex w100minus120">
                <div className="h70w70">
                  <img src={brand.document && brand.document[0] && brand.document[0].image_url ? brand.document[0].image_url : solarpanelsimg} className="h-100 w-100 radius_3"/>
                </div>
                <div className="d-flex flex-column ms-3 w100minus100per">
                  <div className="f-black fw-semibold text-truncate">{brand.name}</div>
                  <div className="c-font f-12 f-black opacity-50 line-clamp-2">{brand.description}</div>
                  <div className="d-flex align-items-center pt-2 c-font f-12">
                    <div className="d-flex align-items-center">
                      <div className="f-black opacity-50">Vat : </div>
                      <div className="fw-semibold fcolororange">{brand.tax}%</div>
                    </div>
                    <div class="px-2 with_separator_10"></div>
                    <div className="d-flex align-items-center ps-3">
                    <div className="f-black opacity-50">Amount : </div>
                    <div className="fw-semibold fcolorBlue">{brand.rate}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center gap10px">
                <a href="#/" className="bg-white d-flex flex-column justify-content-center align-items-center position-relative action_icon h32w32 border radius_3 "
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditBrandItem(brand)
                    setAddItems(true);
                  }}
                >
                  <PencilSimpleLine size={18} className="c-icons" weight="light"/>
                </a>
                <a href="#/" className="bg-white d-flex flex-column justify-content-center align-items-center position-relative action_icon h32w32 border radius_3"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteCatelogItem(brand.id);
                  }}
                >
                  <Trash size={18} className="c-icons" weight="light"/>
                </a>
              </div>
            </div>
          </div>
              </>
        ))
        : 
          <div className="align-item-center d-flex h-100 justify-content-center">
            <CommanPlaceholder mainWidth={`calednarPlacehoder`} imgType="request" placeholderText={_l("l_no_data")} />
          </div>
          }</>
          :
          <div className="row">
            { showSckeleton ? <AddItemGridSkeleton/> : brandsItems && brandsItems.length > 0 ?
            brandsItems.map((brand, index) => (
              <div className="col-lg-4 mb-20 ">
                <a href="#/" className="border radius_3 p-10 space-select-box select-input-cox w-100 bgbodyhover position-relative"
                  onClick={() => {updateAddCatalog(brand)}}
                >
                  <div className="d-flex align-items-center Absadditemicons">
                    <a href="#/" className="bg-white d-flex flex-column justify-content-center align-items-center position-relative action_icon h32w32 border radius_3 me-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditBrandItem(brand)
                        setAddItems(true);
                      }}
                    >
                    <PencilSimpleLine
                        size={18}
                        className="c-icons"
                        weight="light"
                      />
                    </a>
                    <a href="#/" className="bg-white d-flex flex-column justify-content-center align-items-center position-relative action_icon h32w32 border radius_3 me-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteCatelogItem(brand.id);
                      }}
                    >
                      <Trash
                        size={18}
                        className="c-icons"
                        weight="light"
                      />
                    </a>
                  </div>
                  <div className="max-h-150 w-100 h-160px">
                    {!loadedImages[index] && (
                      <img
                        src={PreviewNotAvailable}  // Your placeholder image
                        alt="placeholder"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    )}
                    <img 
                    src={brand.document && brand.document[0] && brand.document[0].image_url ? brand.document[0].image_url : solarpanelsimg} 
                    className="h-100 w-100 object-fit-cover" 
                    alt="https://api.blaz.app/web/orb/download/preview_image?path=uploads/solar_benchmark/73/map-1.jpeg"
                    style={{ display: loadedImages[index] ? 'block' : 'none' }}
                    onLoad={() => handleImageLoad(index)}  
                    onError={() => handleImageError(index)} 
                    />
                  </div>
                  <div className="d-flex flex-column mt-10px gap10px">
                    {/* <div className="d-flex align-items-center gap10px">
                      <div className="h20w20 w-auto">
                        <img src="https://images.unsplash.com/photo-1496200186974-4293800e2c20?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" className="h-100 w-100"/>
                      </div>
                      <div className="c-font f-12 text-black">Mairie</div>
                    </div> */}
                    <div className="c-font f-12 text-black">
                      <div className="c-font f-16 fw-semibold mb-1">{brand.name}</div>
                      <div className="c-font color-white-60 f-12">{brand.description}</div>
                      <div className="fcolororange fw-semibold c-font f-12">{brand.tax} %</div>
                    </div>
                    <div className="c-font f-14 fcolorBlue fw-semibold">€{brand.rate}</div>
                  </div>
                </a>
              </div>
            ))
            : 
            <div className="align-item-center d-flex h-100 justify-content-center">
              <CommanPlaceholder mainWidth={`calednarPlacehoder`} imgType="request" placeholderText={_l("l_no_data")} />
            </div>
          }
          </div>
          }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => setAddItems(true)}
            >
            {_l("l_add")}
            </Button>
            <Button variant="primary" onClick={handleClose}>
            {_l("l_close")}
            </Button>
        </Modal.Footer>
        </Modal>
        {addItems ? (
            <AddCatalogItem
              handleClose={() => {
                setAddItems(false);
                setEditBrandItem({});
              }}
              rel_type = {rel_type}
              setBrandsItems = {setBrandsItems}
              brandsItems = {brandsItems}
              editBrandItems = {editBrandItem.id ? editBrandItem : ''}
            />
          ) : (
            <></>
          )}
    </>
  );
};

export default ChooseCatalogItem;
