import React, { useEffect, useState } from "react";
import LoaderVideo from "../../assets/images/myr.ai-loader.gif";
import { _l, isSolarHubPlatform } from "../../hooks/utilities";
import Loader from "../SolarExternalPages/Loader";



const FullScreenLoader = ({}) => {
  
  if(isSolarHubPlatform())
  {
    return <Loader />
  }

  return (
    <>
      <div className="main-loader-wrapper full-screen">
          <div className="vw-100 vh-100 d-flex">
            <div className="loader-video">
            <img src={LoaderVideo} alt={LoaderVideo} />
              {/* <video  autostart="true" autoPlay="true" loop="true" src={LoaderVideo} type={LoaderVideo}/> */}
              <div className="loader-text">{_l("l_please_wait")}</div>
            </div>
          </div>
      </div>
    </>
  );
};

export default FullScreenLoader;
