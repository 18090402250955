import React, { useEffect } from "react";
import {
  _l,
} from "../hooks/utilities";
import BenchmarkListing from "../components/ExploreFeatures/BenchmarkList/BenchmarkListing";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setOnboardingStepData } from "../actions/onboarding-actions/onboarding-actions";

const Benchmarks = () => {

  const dispatch = useDispatch();

  const spaces = JSON.parse(localStorage.getItem("spaces"));
  const [searchParams] = useSearchParams();

  const projectId = searchParams.get("space");

  useEffect(() => {
  
    const spaceDetails = spaces.find((w) => w.project_id == projectId);
    dispatch(
      setOnboardingStepData({
        step: "space-onboarding",
      })
    );

    const titleElement = document.getElementById("header-page-title");
    const addressElement = document.getElementById("header-page-address");
    if (titleElement && addressElement) {
      titleElement.textContent = _l("l_providers"); //To capitalize the first letter
      addressElement.textContent = spaceDetails
        ? spaceDetails.postal_address.replace(/^./, (str) => str.toUpperCase())
        : "";
    }
  }, []);

  return (
    <>
      <div className="d-flex flex-column h-100">
        <BenchmarkListing />
      </div>
    </>
  );
};

export default Benchmarks;
