import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import {
  _l,
  fetchMydriveData,
  getPreviewFromURL,
  searchObjects,
} from "../../hooks/utilities";
import GeneralCardView from "../Documents/GeneralCard";
import moment from "moment";
import DownloadSimpleIcon from "../../assets/icons/DownloadSimpleIcon";
import { Form } from "react-bootstrap";
// import LeaseCard from "../Documents/tabs/LeaseCard";
import { useDispatch, useSelector } from "react-redux";
import { CardView } from "../Documents/CardView";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import CaretDoubleDownIcon from "../../assets/icons/CaretDoubleDownIcon";
import TaskListSkeleton from "../Skeleton/TaskListSkeleton";
import { File, FileCloud, Files } from "phosphor-react";
import { setMandatedDocumentCoordinat } from "../../actions/customer";

const DOCUMENT_TYPE = [
  {
    key: "google_drive",
    name: "l_documents",
    icon: File,
    status: "to-do",
  },
  {
    key: "space",
    name: "l_space_document",
    icon: FileCloud,
    status: "not-started",
  },
  {
    key: "mandate",
    name: "l_mandate",
    icon: Files,
    status: "done",
  },
  {
    key: "dp",
    name: "l_dp",
    icon: Files,
    status: "ongoing",
  },
  {
    key: "reference",
    name: "l_reference",
    icon: Files,
    status: "in-progress",
  },
];

const MydriveDocumentPage = ({
  editTopicDocument = () => {},
  deleteTopciDocument = () => {},
  openDocumentDetailView = () => {},
  getLeaseDocumentDetails = () => {},
  cancelLeaseContracts = () => {},
  listData = {},
  setListData = () => {},
  dashBoardDocData = [],
  setDocumentTemplateModal = () => {}
}) => {
    const dispatch = useDispatch();
  const spaceState = useSelector((state) => state.customer.spaces);
  const {
    myDriveTopic,
    leftPanelTab,
    globalSearchString,
    daterangeAppliedFilters,
    docBoolean,
  } = useSelector((state) => state.customer);

  const projectId = localStorage.getItem("selectedOffice");
  const staff_id = localStorage.getItem("staff_id");
  let selectedDate = localStorage.getItem("selectedDate");
  let fromDate = localStorage.getItem("fromDate");
  let toDate = localStorage.getItem("toDate");
  const [isSkeltonShow, setIsSkeltonShow] = useState(false);

  const [defaultActiveKeys, setDefaultActiveKeys] = useState([]);
  const [documents, setDocuments] = useState({
    google_drive: [],
    space: [],
    mandate: [],
    reference : [],
    dp : [],
  });
  const [paginationState, setPaginationState] = useState({
    l_documents: 1,
    l_space_document: 1,
    l_mandate: 1,
    l_dp : 1,
    l_reference : 1,

  });

  const handleAccordianExpansion = (key) => {
    const index = defaultActiveKeys.indexOf(key);
    if (index === -1) {
      setDefaultActiveKeys(defaultActiveKeys.concat(key));
    } else {
      setDefaultActiveKeys(
        defaultActiveKeys.filter((selected) => selected !== key)
      );
    }
  };
  

  const fetchDocumentsFromIDB = async () => {
    try {
    setIsSkeltonShow(true);
    let keyArray = [];
    // rel_type === "documents" ? "topic_document" : rel_type
    let documentsList = await fetchMydriveData("topic_document");
    let spaceDocuments = await fetchMydriveData("space");
    let mandate = await fetchMydriveData("mandate");
    let dp = await fetchMydriveData("dp");
    let reference = await fetchMydriveData("reference");
    setDocuments({
      google_drive:
        documentsList && documentsList.length > 0 ? documentsList : [],
      space: spaceDocuments && spaceDocuments.length > 0 ? spaceDocuments : [],
      mandate:
        mandate && mandate.length > 0 ? mandate : [],
      dp:
        dp && dp.length > 0 ? dp : [],
      reference:
        reference && reference.length > 0 ? reference : [],
    });
    if (documentsList && documentsList.length > 0 > 0) {
      keyArray.push("0");
    }
    if (spaceDocuments && spaceDocuments.length > 0) {
      keyArray.push("1");
    }
    if (mandate && mandate.length > 0) {
      keyArray.push("2");
    }
    if (dp && dp.length > 0) {
      keyArray.push("3");
    }
    if (reference && reference.length > 0) {
      keyArray.push("4");
    }
    setDefaultActiveKeys(keyArray);
    setIsSkeltonShow(false);
  } catch (error) {
    console.error('fetchDocumentsFromIDB', error);
    setIsSkeltonShow(false);
  }
  };

  const dateRangeFilters = (item) => {
    let date = new Date();
    switch (myDriveTopic.key) {
      case "payouts":
        date =
          item && item.payout && item.payout.created && item.payout.created;
        break;
      case "invoice":
        date = item.date;
        break;
      case "estimate":
        date = item.date;
        break;
      case "credit_notes":
        date = item.date;
        break;
      case "subscription":
        date = item.date;
        break;
      case "google_drive":
        date = item.created_at;
        break;
      case "space":
        date = "";
        break;
     
      default:
        date = "";
        break;
    }

    if (date == "") {
      return item;
    } else {
      const itemDate = moment(date).format("YYYY-MM");
      const setSelectedDate = moment(selectedDate).format("YYYY-MM");
      const setFromDate = moment(fromDate).format("YYYY-MM");
      const setToDate = moment(toDate).format("YYYY-MM");

      if (["today", "date"].includes(daterangeAppliedFilters)) {
        if (moment(itemDate).isSame(moment(setSelectedDate), "day"))
          return item;
      } else if (daterangeAppliedFilters == "this_week") {
        if (moment(itemDate).isBetween(setFromDate, setToDate, "day", "[]"))
          return item;
      } else if (daterangeAppliedFilters == "month") {
        if (
          moment(itemDate).isSameOrAfter(setFromDate, "month") &&
          moment(itemDate).isSameOrBefore(setToDate, "month")
        )
          return item;
      } else if (daterangeAppliedFilters == "CustomRange") {
        if (moment(itemDate).isBetween(setFromDate, setToDate, "day", "[]"))
          return item;
      } else if (daterangeAppliedFilters == "all") {
        return item;
      }
    }
  };

  const renderAccordionBody = (type, data) => {
    let key = type.name;
    if (
      data &&
      data.filter(
        (i) => searchObjects(i, globalSearchString) && dateRangeFilters(i)
      ).length > 0
    ) {
      return (
      <>
        {data
          .filter(
            (i) => searchObjects(i, globalSearchString) && dateRangeFilters(i)
          )
          .slice(0, getCountPerPage(data.length, paginationState[key]))
          .map((item, index2) => {
            switch (type.key) {
              case "google_drive":
                return (
                  <GeneralCardView
                    key={index2}
                    cardTitle={`${item.file_name || item.file}`}
                    ownerDetails={{
                      name: item.task_name || item.file_name || item.file,
                      profile_image: getPreviewFromURL(
                        item.image_url ? item.image_url : ""
                      ),
                    }}
                    pills={[
                      {
                        color: "color-green",
                        content: `${moment(item.created_at).format("ll")}`,
                      },
                    ]}
                    onClick={() => {
                        openDocumentDetailView(
                            item,
                            key
                          );
                    }}
                    actionItems={
                      staff_id == item.added_from
                        ? [
                            {
                              actionName: _l("l_edit"),
                              actionHandler: () => {
                                editTopicDocument(item, key);
                              },
                              actionIcon: "PencilSimpleLineIcon",
                            },
                            {
                              actionName: _l("l_delete"),
                              actionHandler: () => {
                                deleteTopciDocument(item, key);
                              },
                              actionIcon: "TrashIcon",
                            },
                          ]
                        : []
                    }
                    hideChatAndCall={true}
                    ActionButton={
                      <a
                        className="comman_action_icon w-100 d-flex"
                        href={`${item.image_url}`}
                        target="_blank"
                      >
                        <div className="action_icon with_bg h32w32 on-hover-active-toggle-img position-relative">
                          <DownloadSimpleIcon className="HW18" />
                        </div>
                      </a>
                    }
                  />
                );

              case "space":
                let options = [];
                return (
                  <CardView
                    hideShow={(estiamteDuplicateData = false) => {
                      if (estiamteDuplicateData) {
                        openDocumentDetailView(
                          estiamteDuplicateData,
                          "l_estimates"
                        );
                      } else {
                        openDocumentDetailView(item, key);
                      }
                    }}
                    docType={
                      type.name === "l_space_document"
                        ? "spaceDocument"
                        : type.name.replace("l_", "")
                    }
                    setDocType={() => {}}
                    pinHandler={() => {}}
                    User_2={""}
                    data={item}
                    selectedId={0}
                    key={index2}
                    setSelectedId={() => {}}
                    aiChatAssistURL={`/aichat?rel_type=${type.key}&rel_id=${item.id}&project_id=${projectId}`}
                    actionItems={options}
                    // listData={listData}
                    // setListData={setListData}
                  />
                );
                
              case "dp" :
                return (
                  <GeneralCardView
                    key={index2}
                    cardTitle={`${item.file_name || item.file}`}
                    ownerDetails={{
                      name: item.task_name || item.file_name || item.file,
                      profile_image: getPreviewFromURL(
                        item.image_url ? item.image_url : ""
                      ),
                    }}
                    pills={[
                      {
                        color: "color-green",
                        content: `${moment(item.created_at).format("ll")}`,
                      },
                    ]}
                    onClick={() => {
                        dispatch(setMandatedDocumentCoordinat(item.cordinates));
                        openDocumentDetailView(
                            item,
                            key
                          );
                    }}
                    hideChatAndCall={true}
                    ActionButton={
                      <a
                        className="comman_action_icon w-100 d-flex"
                        href={`${item.image_url}`}
                        target="_blank"
                      >
                        <div className="action_icon with_bg h32w32 on-hover-active-toggle-img position-relative">
                          <DownloadSimpleIcon className="HW18" />
                        </div>
                      </a>
                    }
                    actionItems={
                      [
                         {
                             actionName: _l("l_edit"),
                             actionHandler: () => {
                               setDocumentTemplateModal({doc_type : 'edit_doc_template', modal : true, key : type.key });
                             },
                             actionIcon: "PencilSimpleLineIcon",
                         },
                       ]
                     }
                  />
                );
              case "reference":
                return (
                  <GeneralCardView
                    key={index2}
                    cardTitle={`${item.file_name || item.file}`}
                    ownerDetails={{
                      name: item.task_name || item.file_name || item.file,
                      profile_image: getPreviewFromURL(
                        item.image_url ? item.image_url : ""
                      ),
                    }}
                    pills={[
                      {
                        color: "color-green",
                        content: `${moment(item.created_at).format("ll")}`,
                      },
                    ]}
                    onClick={() => {
                        dispatch(setMandatedDocumentCoordinat(item.cordinates));
                        openDocumentDetailView(
                            item,
                            key
                          );
                    }}
                    hideChatAndCall={true}
                    ActionButton={
                      <a
                        className="comman_action_icon w-100 d-flex"
                        href={`${item.image_url}`}
                        target="_blank"
                      >
                        <div className="action_icon with_bg h32w32 on-hover-active-toggle-img position-relative">
                          <DownloadSimpleIcon className="HW18" />
                        </div>
                      </a>
                    }
                    actionItems={
                      [
                         {
                             actionName: _l("l_edit"),
                             actionHandler: () => {
                               setDocumentTemplateModal({doc_type : 'edit_doc_template', modal : true, key : type.key });
                             },
                             actionIcon: "PencilSimpleLineIcon",
                         },
                       ]
                     }
                  />
                );
              case "mandate" :
                return (
                  <GeneralCardView
                    key={index2}
                    cardTitle={`${item.file_name || item.file}`}
                    ownerDetails={{
                      name: item.task_name || item.file_name || item.file,
                      profile_image: getPreviewFromURL(
                        item.image_url ? item.image_url : ""
                      ),
                    }}
                    pills={[
                      {
                        color: "color-green",
                        content: `${moment(item.created_at).format("ll")}`,
                      },
                    ]}
                    onClick={() => {
                        dispatch(setMandatedDocumentCoordinat(item.cordinates));
                        openDocumentDetailView(
                            item,
                            key
                          );
                    }}
                    hideChatAndCall={true}
                    ActionButton={
                      <a
                        className="comman_action_icon w-100 d-flex"
                        href={`${item.image_url}`}
                        target="_blank"
                      >
                        <div className="action_icon with_bg h32w32 on-hover-active-toggle-img position-relative">
                          <DownloadSimpleIcon className="HW18" />
                        </div>
                      </a>
                    }
                    actionItems={
                       [
                          {
                              actionName: _l("l_edit"),
                              actionHandler: () => {
                                setDocumentTemplateModal({doc_type : 'edit_doc_template', modal : true, key : type.key });
                              },
                              actionIcon: "PencilSimpleLineIcon",
                          },
                        ]
                      } 
                  />
                );


              default:
                break;
            }
          })}
        {hideViewMoreBtn(
          data.length,
          globalSearchString.trim().length
            ? listData[key].filter(
                (item) =>
                  searchObjects(item, globalSearchString) &&
                  dateRangeFilters(item)
              ).length
            : data &&
            data.length &&
            data.filter((item) => dateRangeFilters(item)).length,
          paginationState[key]
        ) ? (
          <div className="d-flex justify-content-center">
            <a
              href="#/"
              className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
              onClick={() => {
                setPaginationState({
                  ...paginationState,
                  [key]: paginationState[key] + 1,
                });
              }}
            >
              <span className="title-fonts d-flex justify-content-center align-item-center ">
                <div className="d-flex align-item-center justify-content-center">
                  {_l("l_view_more_btn")} (
                  {getTotalCountPerPage(
                    data,
                    globalSearchString.trim().length
                      ? data.filter((item) =>
                          searchObjects(item, globalSearchString)
                        ).length
                      : data.length,
                    paginationState[key]
                  )}
                  )
                  <CaretDoubleDownIcon className="HW14 mx-2" />
                  <span
                    role="status"
                    aria-hidden="true"
                    className="ms-2 opacity-0 spinner-border spinner-border-sm"
                  ></span>
                </div>
              </span>
            </a>
            {showViewAllButton(key, data) ? (
              <a
                href="#/"
                className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                onClick={() => {
                  viewAll(key, data);
                }}
              >
                <span className="title-fonts d-flex justify-content-center align-item-center ">
                  <div className="d-flex align-item-center justify-content-center">
                    {_l("l_view_all")}
                  </div>
                </span>
              </a>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </div>
        ) : (
          <></>
        )}
      </>
    ) 
  } else {
    return (
      <CommanPlaceholder
        mainSpacing="bgOfficelisting p-0"
        imgWidth="big-image h95 d-none"
        isImageOpacity="opacity-50"
        placeholderTextClass="c-font f-16 pt-0 opacity30"
        imgType="no-benchmark"
        placeholderText={_l(`l_no_${type.key}`)}
      />
    );
    }
  };

  const getCountPerPage = (pageCount, keyName) => {
    switch (pageCount) {
      case 1:
        return keyName * 10;
      default:
        return keyName === 1 ? keyName * 3 : keyName * 10;
    }
  };


  const getTotalCountPerPage = (totalLength, pageCount, keyName) => {
    switch (totalLength) {
        case 1:
        return pageCount - ((keyName - 1) * 10 + 10);
        default:
        return pageCount - ((keyName - 1) * 10 + 3);
    }
};

  const hideViewMoreBtn = (totalLength, pageCount, keyName) => {
    switch (totalLength) {
      case 1:
        return (
          pageCount >= (keyName - 1) * 10 + 10 &&
          pageCount != (keyName - 1) * 10 + 10
        );
      default:
        return (
          pageCount >= (keyName - 1) * 10 + 3 &&
          pageCount != (keyName - 1) * 10 + 3
        );
    }
  };

  const showViewAllButton = (key, data) => {
    const totalResults = data.length;
    const remainingResults = getTotalCountPerPage(
      data.length,
      globalSearchString.trim().length
        ? data.filter((item) => searchObjects(item, globalSearchString))
            .length
        : data.length,
      paginationState[key]
    );
    return totalResults !== remainingResults;
};

const viewAll = (key, data) => {
  const totalPages = Math.ceil((data.length - 3) / 10) + 1;
  setPaginationState({ ...paginationState, [key]: totalPages });
};

  useEffect(() => {
    // fetchDocumentsFromIDB();
  }, []);

  useEffect(() => {
    let keyArray = [];
    if (dashBoardDocData) {
      let documentsList = dashBoardDocData["topic_document"];
      let spaceDocuments = dashBoardDocData["space"];
      let mandate = dashBoardDocData["mandate"];
      let dp = dashBoardDocData["dp"];
      let reference = dashBoardDocData["reference"];
      setDocuments({
        google_drive:
          documentsList && documentsList.length > 0 ? documentsList : [],
        space:
          spaceDocuments && spaceDocuments.length > 0 ? spaceDocuments : [],
        mandate:
          mandate && mandate.length > 0 ? mandate : [],
        dp:
          dp && dp.length > 0 ? dp : [],
        reference:
          reference && reference.length > 0 ? reference : [],
      });
      if (documentsList && documentsList.length > 0 > 0) {
        keyArray.push("0");
      }
      if (spaceDocuments && spaceDocuments.length > 0) {
        keyArray.push("1");
      }
      if (mandate && mandate.length > 0) {
        keyArray.push("2");
      }
      if (dp && dp.length > 0) {
        keyArray.push("3");
      }
      if (reference && reference.length > 0) {
        keyArray.push("4");
      }
      setDefaultActiveKeys(keyArray);
    }
  }, [dashBoardDocData]);

  return (
    <>
      <div
        id="liteversion_view"
        className={`comman-main-box custom-accordian-main res-padding-right0 overflow-auto flex-grow-1 h-100 task-list-accordian   `}
      >
        <Accordion
          className="task-list-accordian-main with_checkbox_absolute mt-0 w-800px my-0 padding-bottom-60px daily-schedule-main"
          onSelect={(eventKey) => {
            handleAccordianExpansion(eventKey);
          }}
          activeKey={defaultActiveKeys}
          defaultActiveKey={["0", "1", "2", "3", "4", 0, 1, 2, 3, 4]}
        >
          {DOCUMENT_TYPE.map((doc, index) => {
            return (
              <>
                <Accordion.Item
                  eventKey={index.toString()}
                  key={index}
                  className="with-pb-15"
                >
                  <div className="accordian-header-box d-flex align-items-center justify-content-between bgpatternCommanImg">
                    <div className="modal-overlay3"></div>
                    <div className="checkbox_accordian">
                      <Accordion.Header
                        className={`active commanTaskCount w-auto ${doc.status}`}
                        id="request"
                      >
                        <div className="accordion-header-wrapper w-auto ">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="left-task-header">
                              <div className="d-flex align-items-center  fw-semibold title-fonts c-font f-13">
                                <span className={`c-dots c-7 rounded-circle me-3 ${doc.status}`}></span>
                                <span className="task-title me-2">
                                  {_l(`${doc.name}`)}
                                </span>
                                <span className="" id="request_count_display">
                                  {/* {console.log(documents[doc.key])} */}
                                  {`[${documents[doc.key].length}]`}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Header>
                    </div>
                  </div>
                  { isSkeltonShow ?
                    ["0", "1", "2"].map(() => {
                      return <TaskListSkeleton />
                    }) :
                  <Accordion.Body
                    className="taskCountToggle"
                    id="request_count"
                  >
                    {renderAccordionBody(doc, documents[doc.key])}
                  </Accordion.Body>
                  }
                </Accordion.Item>
              </>
            );
          })}
        </Accordion>
      </div>
    </>
  );
};

export default MydriveDocumentPage;
