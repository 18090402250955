import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {
  ArrowRight,
  HourglassSimpleMedium,
  NotePencil,
  PaperPlaneTilt,
  UsersThree,
} from "phosphor-react";
import { Col } from "react-bootstrap";
import { Row } from "reactstrap";
import {
  _l,
  showError,
  showSuccess,
  validateEmail,
} from "../../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { MentionsInput, Mention } from "react-mentions";
import ProposalCard from "./Proposal/ProposalCard";
import { addEditDocumentComment } from "../../../actions/documents-actions/documents-actions";
import MessagePlaceHolderScreen from "./MessagePlaceHolderScreen";
import BenchmarkServices from "../../../services/benchmark-services";
import { getBenchmarkDetail } from "../../../actions/customer";

const ProposalList = ({ mentionList, setStep, getComments }) => {
  const { benchmarkDetail, benchmarkSchedule } = useSelector(
    (state) => state.customer
  );

  const [newComment, setNewComment] = useState("");
  const [increaseCount, setIncreaseCount] = useState(0);
  const [emailAddress, setEmailAddress] = useState("");
  const [showProposalDetail, setShowProposalDetail] = useState("");

  const dispatch = useDispatch();

  let itemCount = 0;
  let proposals =
    benchmarkDetail.proposal_list && benchmarkDetail.proposal_list.length
      ? benchmarkDetail.proposal_list[0].proposals
      : [];
  proposals.sort(
    (a, b) =>
      Number(b.amount) - Number(a.amount) ||
      Number(b.is_updated) - Number(a.is_updated)
  );
  let acceptedProposals = proposals.filter((x) => x.status == 3);
  let pendingProposals = proposals.filter((x) => x.status != 3);
  proposals = acceptedProposals.concat(pendingProposals);

  if (benchmarkSchedule && benchmarkSchedule.total_count) {
    Object.values(benchmarkSchedule.total_count).forEach((x) => {
      itemCount += Number(x);
    });
  }

  const addCommentToAllProposals = () => {
    if (newComment && newComment.trim().length) {
      dispatch(
        addEditDocumentComment(
          "proposal",
          benchmarkDetail.id,
          newComment.trim(),
          0,
          0,
          undefined,
          () => {},
          () => {},
          0,
          proposals.map((x) => x.proposal_id)
        )
      );
      setNewComment("");
      setIncreaseCount(1);
    }
  };

  const showAmountUpdateMessage = () => {
    let message = "";

    //Commenting for now
    // if (
    //   proposals &&
    //   proposals.length &&
    //   proposals.some((w) => w.is_updated > 0)
    // ) {
    //   message = _l("l_waiting_for_new_proposal");
    // }

    return message;
  };

  const sendNewProposal = async () => {
    if (emailAddress && emailAddress.length && validateEmail(emailAddress)) {
      try {
        const response = await BenchmarkServices.addBulkProvidersToCategory(
          benchmarkDetail.project_id,
          benchmarkDetail.provider_id,
          [
            {
              email: emailAddress,
              name: "",
              phonenumber: "",
              company_name: "",
            },
          ],
          0,
          benchmarkDetail.id,
          1
        );
        if (response.status) {
          showSuccess("l_provider_invited");
          dispatch(
            getBenchmarkDetail(benchmarkDetail.id, benchmarkDetail.client_id)
          );
        } else {
          showError("l_something_went_wrong");
        }
      } catch (e) {
        console.error(e);
      } finally {
        setEmailAddress("");
      }
    } else {
      showError("l_please_enter_email");
      return false;
    }
  };

  return (
    <div className="w-100 d-flex flex-column flex-grow-1 ">
      <Row className="end-0 m-0 z-index-2 position-sticky row start-0 top-0 bg-white-03-solid-imp mb-2 g-0 light-theme-grey-light-bg light-theme-overall-scroll-gery-shadow">
        <Col className="mb-32px" xl={12}>
          <div className="select-input-cox p-35px w-100 align-items-center d-flex">
            <input
              className="bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100 bg-transparent"
              name="benchmark-detail"
              checked
              disabled
              type="radio"
            />
            <div class="w100minus100per text-truncate">
              <div class="c-font f-16 fw-semibold text-truncate title-fonts">
                {benchmarkDetail.company_name}
                <span className="ps-10px">
                  {`#${_l(benchmarkDetail.id)} - ${benchmarkDetail.category}`}
                  <span
                    className={`badge rounded-pill ms-2 ${
                      benchmarkDetail.is_opened > 0
                        ? "text-done"
                        : "text-danger"
                    }`}
                  >
                    {benchmarkDetail.is_opened > 0
                      ? _l("l_open")
                      : _l("l_closed")}
                  </span>
                </span>
              </div>
              <div class="c-font color-white-60 f-12 pt-1 text-truncate">
                {benchmarkDetail.address}
              </div>
              {/* <div className="px-3">
            <div className="c-font f-20 fw-semibold title-fonts py-2">
              {`#${_l(benchmarkDetail.id)} - ${benchmarkDetail.category}`}
              <span className={`badge rounded-pill ms-2 ${benchmarkDetail.is_opened > 0 ? "text-done" : "text-danger"}`}>
                {benchmarkDetail.is_opened > 0 ? _l("l_open") : _l("l_closed")}
              </span>
            </div>
    
          </div> */}
            </div>
            <div className="align-items-center d-flex justify-content-between">
              <div className="with_separator_10 text-center flex-grow-1 px-3">
                <div className="c-font f-16 fw-semibold text-truncate title-fonts">
                  {benchmarkDetail.startdate &&
                  moment(new Date(benchmarkDetail.startdate)).isValid()
                    ? moment(new Date(benchmarkDetail.startdate)).format(
                        "DD/MM/YYYY"
                      )
                    : "-"}
                </div>
                <div className="c-font color-white-60 f-12 pt-1 text-truncate">
                  {_l("l_start_date")}
                </div>
              </div>
              <div className="with_separator_10 text-center flex-grow-1 px-3">
                <div className="c-font f-16 fw-semibold text-truncate title-fonts">
                  €{benchmarkDetail.max_budget}
                </div>
                <div className="c-font color-white-60 f-12 pt-1 text-truncate">
                  {_l("l_budget")}
                </div>
              </div>
              <div className="with_separator_10 text-center flex-grow-1 px-3">
                <div className="c-font f-16 fw-semibold text-truncate title-fonts">
                  {itemCount}
                </div>
                <div className="c-font color-white-60 f-12 pt-1 text-truncate">
                  {_l("l_item")}
                </div>
              </div>
              <div className="with_separator_10 text-center flex-grow-1 px-3 pe-0 before-dnone">
                <div className="c-font f-16 fw-semibold text-truncate title-fonts color-green">
                  {proposals.length}
                </div>
                <div className="c-font color-white-60 f-12 pt-1 text-truncate">
                  {_l("l_proposal")}
                </div>
              </div>
            </div>
          </div>
        </Col>
        {benchmarkDetail.is_opened > 0 ? (
          <Col className="" xl={12}>
            <div className="d-flex align-items-center mb-32px">
              <Form.Group className="col-xl-11 c-input-box position-relative flex-grow-1 chat-main-box comman_action_icon">
                <div className="chat-comment-box d-flex align-items-center position-relative">
                  <input
                    className="form-control bg-transparent border-0 p-0 shadow-none "
                    type="email"
                    name="email"
                    placeholder={_l("l_email_placeholder")}
                    value={emailAddress}
                    onChange={(e) => {
                      setEmailAddress(e.target.value);
                    }}
                  />
                  <a
                    href="#/"
                    className="action_icon with_bg h32w32"
                    onClick={sendNewProposal}
                  >
                    <ArrowRight size={18} weight="light" className="c-icons" />
                  </a>
                </div>
              </Form.Group>
              {/* <Button variant="primary" className="ms-1" onClick={sendNewProposal}>
            Invite
          </Button> */}
            </div>
          </Col>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <Col
          className=" pe-0"
          xl={
            proposals && proposals.findIndex((x) => x.status == 3) === -1
              ? 8
              : 12
          }
        >
          <div className="chat-main-box p-0">
            <div className="chat-comment-box bg-transparent p-0 radius_3 border-0 comman_action_icon">
              <div className="comment-input-box">
                <div className="d-flex align-items-center">
                  <div className="chat-main-box with-bg flex-grow-1 m-0">
                    <div className="chat-comment-box">
                      <div className="comment-input-box">
                        <div className="d-flex align-items-center position-relative">
                          <MentionsInput
                            value={newComment}
                            className="mension-box flex-grow-1 text-truncate w100minus100per"
                            placeholder={`${_l("l_write_comment")}`}
                            onChange={(e) => {
                              setNewComment(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.keyCode === 13 &&
                                !e.ctrlKey &&
                                !e.shiftKey
                              ) {
                                e.preventDefault();
                                addCommentToAllProposals();
                              }
                            }}
                            id="commentInputOuter"
                          >
                            <Mention
                              markup='<span style="font-weight: 600;"  contenteditable="false" data-mention-id="__id__"  data-mce-style="font-weight: 600;"  data-mce-selected="1">@__display__</span>'
                              trigger="@"
                              data={mentionList.map((name) => {
                                let id =
                                  name.is_team && name.is_team == 1
                                    ? "-" + name.id
                                    : name.id;
                                return {
                                  display: name.name,
                                  id: id,
                                  image: name.profile_image || "",
                                  isteam: name.is_team ? 1 : 0,
                                };
                              })}
                              renderSuggestion={(
                                suggestion,
                                search,
                                highlightedDisplay
                              ) => {
                                return (
                                  <div className="user d-flex align-items-center">
                                    <div className="comman-image-box h25w25 rounded-circle mx-2">
                                      {suggestion.isteam == 1 ? (
                                        <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle d-flex">
                                          {" "}
                                          <UsersThree className="m-auto opacity-60" />{" "}
                                        </div>
                                      ) : (
                                        <div
                                          className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                          style={{
                                            backgroundImage: `url('${suggestion.image}')`,
                                          }}
                                        ></div>
                                      )}
                                    </div>
                                    {highlightedDisplay}
                                  </div>
                                );
                              }}
                            />
                          </MentionsInput>
                          <a
                            href="#/"
                            className="action_icon with_bg h32w32"
                            onClick={addCommentToAllProposals}
                          >
                            <PaperPlaneTilt
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        {proposals && proposals.findIndex((x) => x.status == 3) === -1 ? (
          <Col className="ps-1 comman_action_icon" xl={4}>
            <Button
              className="h-100 w-100 border-0 d-flex justify-content-center align-items-center light-theme-white-bg"
              variant="white-03"
              onClick={() => setStep("basic-details")}
            >
              <div className="action_icon with_bg h32w32 me-2">
                <NotePencil size={18} weight="light" className="c-icons" />
              </div>
              <span>{_l("l_edit_my_benchmark")}</span>
            </Button>
          </Col>
        ) : (
          <React.Fragment> </React.Fragment>
        )}
        <Form.Label
          className="c-font f-14 title-fonts bg-white-03-solid-imp position-sticky py-2 m-0 z-index-2 rounded-0 w-100 light-theme-grey-light-bg"
          // style={{ top: "273px" }}
        >
          {proposals.length ? _l("l_proposals") : ""}
        </Form.Label>
      </Row>
      {showAmountUpdateMessage() ? (
        <div className="c-font color-red f-15 fw-semibold my-2 text-truncate title-fonts">
          {showAmountUpdateMessage()}
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      <div className="flex-grow-1">
        {proposals.length ? (
          proposals.map((proposal, index) => {
            return (
              <div
                onClick={() => {
                  setShowProposalDetail(true);
                }}
              >
                <ProposalCard
                  proposal={proposal}
                  benchmark={benchmarkDetail}
                  key={index}
                  increaseCount={increaseCount}
                  setIncreaseCount={setIncreaseCount}
                  setStep={setStep}
                  getComments={getComments}
                />
              </div>
            );
          })
        ) : (
          <MessagePlaceHolderScreen
            className=""
            description={"l_waiting_for_proposals"}
            image={{ name: HourglassSimpleMedium }}
            title={"l_congratulation"}
          />
        )}
      </div>
    </div>
  );
};

export default ProposalList;
