import { X } from 'phosphor-react';
import React from 'react';

const TagInput = ({tags, setTags, inputValue, setInputValue}) => {
 
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      addTag();
    }
  };

  const addTag = () => {
    const newTag = inputValue.trim();
    if (newTag) {
      setTags([...tags, newTag]);
      setInputValue('');
    }
  };

  const removeTag = (tagToRemove) => {
    const updatedTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(updatedTags);
  };

  return (
    <div>
      
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyPress={handleInputKeyPress}
        placeholder="Add tags..."
        className='form-control'
      />
      <div
        className='pill-wrapper d-flex flex-wrap gap-2'
      >
        {tags.map((tag, index) => (
          <div
            key={index}
            className='badge c-font f-12 fw-bold p-2 rounded-pill'
          >
            {tag}
            <a href="#/" className='ps-1' onClick={() => removeTag(tag)}>
              <X size={10}  weight="light" className="c-icons" />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TagInput;
