import DatePicker from "react-datepicker";
// import User2 from '../../assets/images/users/user_9.jpg'
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useRef, useState } from "react";
import { Eye, File, Link } from "phosphor-react";
import googleDriveServices from "../../../services/google-drive-services";
import { useSelector } from "react-redux";
import { _l } from '../../../hooks/utilities';
import ReactTooltip from "react-tooltip";
import { getDocumentList, is_google_drive_connected , is_onedrive_connected} from "../../../actions/google-drive";
const Activities = ({ clientId, location, zoomLevel, data }) => {
    const onedriveStatus = useSelector((state) => state.googleDrive);
    const [activityList, setActivityList] = useState([]);
    const [isOneDriveActive,setIsOneDriveActive] = useState(false);

    const { selectedProject: projectId, taskFilters } = useSelector(
        (state) => state.customer
    );
    useEffect(() => {
        getAcitivityList();
    }, []);

    useEffect(() => {
        if(onedriveStatus.onedriveStatus && onedriveStatus.onedriveStatus.status == 1)
        {
          setIsOneDriveActive(true);
        }
      }, [onedriveStatus.onedriveStatus])
    const getAcitivityList = () => {

        googleDriveServices.getAcitivityList((data.is_synced ? data.channel_id : data.id), projectId).then((response) => {
        if (response.status == 1) {
            setActivityList(response.data);
        } else {
            setActivityList([]);
        }
        });
    };
    return (
        <>
        <div className="comman-data-table small-data-table">
            <table className="dataTable">
                <thead className="">
                    <tr className="bg-white-05">
                        <th style={{width:'20%'}}>{_l("l_name")}</th>
                        <th >{_l("l_item")}</th>
                        <th className='px-1' style={{width:'23%'}} >{_l("l_date")}</th>
                        <th style={{width:'5%'}} className='text-center'>{_l("l_action")}</th>
                        <th style={{width:'5%'}} className='text-center'>{_l("l_details")}</th>
                    </tr>
                </thead>
                <tbody>
                {activityList && activityList.length ? (
                    activityList.map((activity, index) => {
                        return (
                            <tr className="">
                                <td className="max-width-100 text-truncate border-bottom ps-3">
                                    {
                                        activity.actors.map((actor, index) => {
                                            return (
                                                <div className='d-flex align-items-center'>
                                                    <div className='comman-image-box h20w20 rounded-circle' data-effect="solid" data-delay-show='1000'  data-tip={actor.user.knownUser.displayName}>
                                                        <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{ backgroundImage: `url('${actor.user.knownUser.photoUrl}')` }}></div>
                                                    </div>
                                                    <div className='text-truncate ps-2 w100minus20'>{(actor.user.knownUser.displayName)}</div>
                                                </div>
                                            )
                                        })
                                    }
                                    
                                </td>

                                <td className="border-bottom align-top max-width-100" >
                                    <div className='d-flex align-items-center'>
                                        <File size={16} className="c-icons" weight="light" />
                                        <div className='text-truncate ps-2 w100minus20'data-tip="File name" data-effect="solid" data-delay-show='1000'>{activity.target}</div>
                                    </div>
                                </td>
                                <td className=" border-bottom align-top px-1"> {activity.timestamp}</td>
                                <td className=" border-bottom text-center align-top">
                                    {activity.event}
                                </td>
                                <td className=" border-bottom text-center align-top">
                                    {activity.details}
                                </td>
                            </tr>
                        )
                    })
                ) : <></> }
                </tbody>
            </table>
            <ReactTooltip data-class="tooltip-main" />
        </div>
        </>
    );
};
export default Activities;
