import React from 'react';
// import CSS from '../css/icon_main.css';

function CubeIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path fillRule="evenodd" clipRule="evenodd" d="M7.89972 0.952928C8.04914 0.86732 8.21838 0.822266 8.39062 0.822266C8.56287 0.822266 8.7321 0.86732 8.88153 0.952928L14.3795 4.04554L14.3813 4.04654C14.513 4.12135 14.6258 4.22475 14.7117 4.34876C14.7282 4.36933 14.7433 4.39148 14.7567 4.41514C14.767 4.43317 14.776 4.45157 14.7837 4.47024C14.8531 4.6084 14.8898 4.76097 14.8906 4.91614L14.8906 4.91883L14.8906 11.0813H14.3906L14.8906 11.084C14.8897 11.2609 14.8421 11.4345 14.7527 11.5872C14.6632 11.7398 14.5351 11.8662 14.3813 11.9536L14.3795 11.9546L8.88151 15.0472C8.73209 15.1328 8.56287 15.1779 8.39062 15.1779C8.21838 15.1779 8.04916 15.1328 7.89974 15.0472L2.40174 11.9546L2.39997 11.9536C2.24611 11.8662 2.11801 11.7398 2.02859 11.5872C1.93917 11.4345 1.89158 11.2609 1.89063 11.084L1.89062 11.0813V4.91883V4.91614H1.89063C1.89147 4.76007 1.9286 4.60663 1.99872 4.46783C2.00575 4.4512 2.01375 4.43479 2.02274 4.41867C2.03629 4.39436 2.05157 4.37162 2.06833 4.35052C2.15434 4.22573 2.26762 4.1217 2.39997 4.04654L2.40174 4.04554L2.40174 4.04554L7.89972 0.952928ZM8.39062 1.82414L13.3725 4.62644L8.44465 7.42588L3.41556 4.62261L8.39062 1.82414ZM2.89062 11.0789V5.47487L7.9444 8.2919L7.89715 13.8984L2.89378 11.084L2.89359 11.0839C2.89272 11.0834 2.89199 11.0827 2.89147 11.0818C2.89096 11.0809 2.89067 11.0799 2.89062 11.0789ZM8.89725 13.891L13.8875 11.084L13.8877 11.0839L13.8886 11.0832L13.8898 11.0818C13.8903 11.0809 13.8906 11.0799 13.8906 11.0789V5.4822L8.94443 8.29206L8.89725 13.891Z"/>
</svg>


		</React.Fragment>
	)
}

export default CubeIcon