import React from 'react';
// import CSS from '../css/icon_main.css';

function SignOutIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
		<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"  className={`comman-icons ${CommanHW}`}>
<path d="M11.2656 5.375L13.8906 8M13.8906 8L11.2656 10.625M13.8906 8H6.89062M6.89062 13.5H3.39062C3.25802 13.5 3.13084 13.4473 3.03707 13.3536C2.9433 13.2598 2.89062 13.1326 2.89062 13V3C2.89062 2.86739 2.9433 2.74021 3.03707 2.64645C3.13084 2.55268 3.25802 2.5 3.39062 2.5H6.89062"/>
</svg>


		</React.Fragment>
	)
}

export default SignOutIcon