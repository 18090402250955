import React from 'react'
const EmailSentTableSkeleton = () => {
  return (
    <>
      {
        [0, 1, 2, 3].map((s, i) => {
          return <tr key={i}>
            <td className='text-start'><div className='skeleton-animation radius_3' style={{ width: '120px', height: '10px' }}></div></td>
            <td className='text-start'> <div className='skeleton-animation radius_3 m-auto' style={{ width: '120px', height: '10px' }}></div></td>
            <td classname='text-start'><div className='skeleton-animation radius_3 m-auto' style={{ width: '150px', height: '10px' }}></div></td>
            <td className='color-red text-start'><div className='skeleton-animation radius_3' style={{ width: '100px', height: '10px' }}></div></td>
          </tr>
        })
      }
    </>
  )
}
export default EmailSentTableSkeleton