import React from 'react';
// import CSS from '../css/icon_main.css';

function EmailplaceholderIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

			<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<path d="M12.5 37.5V78.125C12.5 78.9538 12.8292 79.7487 13.4153 80.3347C14.0013 80.9208 14.7962 81.25 15.625 81.25H84.375C85.2038 81.25 85.9987 80.9208 86.5847 80.3347C87.1708 79.7487 87.5 78.9538 87.5 78.125V37.5L50 12.5L12.5 37.5Z" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M43.1641 59.375L13.4766 80.3516" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M86.5234 80.3516L56.8359 59.375" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M87.5 37.5L56.8359 59.375H43.1641L12.5 37.5" strokeLinecap="round" strokeLinejoin="round" />
			</svg>


		</React.Fragment>
	)
}

export default EmailplaceholderIcon