import React from "react";
import { _l } from "../../hooks/utilities";

const StatusDropdown = ({ statuses = [], handler, currentStatus, page }) => {
  return (
    <ul className="dropdown-menu w-auto" aria-labelledby="StatusDropdown">
      {statuses.map((status, index) => {
        return (
          <li
            key={index}
            className={`${currentStatus == status.id ? 'for-disabled' : ''} dropdown-item hr_1`}
            onClick={() => {
              handler(status.id, status.type ? status.type : '')
            }}
          >
            <div className="d-flex align-items-center">
              <span
                className={`c-dots c-10 ${status.className} rounded-circle me-2`}
              ></span>
              <div className="w100minus20">
                {
                  <span> {_l(status.name)}</span>
                }
              </div>

            </div>
          </li>
        );
      })}
    </ul >
  );
};

export default StatusDropdown;
