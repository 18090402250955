import React, { useEffect, useMemo, useState } from "react";
import jquery from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../actions/messages";
import {
    Info,
    X,
    MagnifyingGlass,
    Funnel,
    CaretDoubleDown,
  } from "phosphor-react";
  import { isLoadingData } from "../hooks/loader_helper";
import CommanLoader from "./Loader/CommanLoader";
import { CardView } from "./Documents/CardView";
import { _l } from "../hooks/utilities";
import { getEquipmentDetail, getEstimateDetail, getInvoiceDetail, getStaffDeatil, getSubscriptionDetail } from "../actions/documents-actions/documents-actions";
import DocumentsServices from "../services/documents-services";
import CommanOffCanvas from "./Offcanvas/CommanOffCanvas";
import { ESTIMATE_COUNTER, ESTIMATE_TOPICS, INVOICE_COUNTER, STAFF_COUNTER, STAFF_TOPICS } from "../actions/action-type";
import moment from "moment";
import CommanPlaceholder from "./Placeholder/CommanPlaceholder";
import { leftPanelFilters } from "../actions/customer";
const EquipmentListing = ({pageName, clientid, is_space}) => {
    const [staff, setStaff] = useState([]);
    const [staffPage, setStaffPage] = useState(1);
    const [allData, setAllData] = useState([]);
    const [draftEstimateListPage, setDraftEstimateListPage] = useState(1);
    const [acceptEstimateListPage, setAcceptEstimateListPage] = useState(1);
    const [expiredEstimateListPage, setExpiredEstimateListPage] = useState(1);
    const [declinedEstimateListPage, setDeclinedEstimateListPage] = useState(1);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showReqestedTaskModal, setShowReqestedTaskModal] = useState(false);
  const [showDocumentCanvas, setShowDocumentCanvas] = useState(false);
  const [documentCardDetails, setDocumentCardDetails] = useState();
  const [documentDetail, setDocumentDetail] = useState();
  const [selectedId, setSelectedId] = useState(0);
  const [selectDocument, setSelectDocument] = useState("");
  const [docType, setDocType] = useState("");
  const [searchString, setSearchString] = useState("");
  const savedDate = localStorage.getItem("selectedDate");
  const { selectedProject: projectId } = useSelector((state) => state.customer);
  const equipmentData = useSelector((state) => state.documentsReducer.equipmentData)
  const {
    taskFilters,
    isAppliedUnreadComment,
    activeTaskStatusFilters,
    taskUnreadCmtCntAll, filterBenchmarkCategory, globalSearchString
  } = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
        getStaff();
  }, [projectId, taskFilters, savedDate]);
  useEffect(() => {
    if(equipmentData)
    {
      setDocumentDetail(equipmentData)
    }
  }, [equipmentData])

  useEffect(() => {
    if(pageName == "staff")
    {
      DocumentsServices.getStaffTopics(pageName).then((res) => {
        if(res.status == 1)
        {
          let data = res.data;
          dispatch({
            type: STAFF_TOPICS,
            payload: {data}
          })
        }
      })
    }
  }, [pageName])
  useEffect(() => {
    if(globalSearchString)
    {
      setStaff(
        allData &&
          allData.filter(
            (est) =>
              ((est.name != null ? est.name : "").toLowerCase().includes(globalSearchString.toLowerCase()) ||
              (est.date != null ? est.date : "")
                .toLowerCase()
                .includes(globalSearchString.toLowerCase()) ||
              (est.id != null ? est.id : "")
                .toLowerCase()
                .includes(globalSearchString.toLowerCase())) ||
              (est.status_text ? est.status_text : "")
              .toLowerCase()
              .includes(globalSearchString.toLowerCase()) ||
              (est.category_name ? est.category_name : "")
              .toLowerCase()
              .includes(globalSearchString.toLowerCase())
          )
      );
    } else {
      setStaff(allData)
    }
  }, [globalSearchString, allData])
  const getStaff = () => {
    let selectedDate =  moment(savedDate).format("YYYY-MM-DD");
    if(pageName == 'customer')
    {
      DocumentsServices.getClientContacts(clientid, is_space).then((res) => {
        if(res.status)
        {
          setStaff(res.data);
        }
      })
    } else {
      DocumentsServices.equipmentlist(projectId, taskFilters, selectedDate).then(
        (res) => {
          if (res.status == 1) {
            setStaff(res.data);
            setAllData(res.data);
            let data = res.extra.counter;
            dispatch({
              type: STAFF_COUNTER,
              payload: { data },
            });
          }
        }
      );
    }
  };  
  const handleViewMore = (section) => {
    switch (section) {
      case "staff":
        setStaffPage(staffPage + 1);
        break;
        case "accept":
          setAcceptEstimateListPage(acceptEstimateListPage + 1);
          break;
          case "draft":
            setDraftEstimateListPage(draftEstimateListPage + 1);
            break;
            case "declined":
              setDeclinedEstimateListPage(declinedEstimateListPage + 1);
              break;
              case "expired":
                setExpiredEstimateListPage(expiredEstimateListPage + 1);
                break;
      default:
        break;
    }
  };
  const staffCardHandler = async (staff, flag = 1) => {
    setShowTaskModal(false);
    if (
      documentCardDetails &&
      documentCardDetails.type === "staff" &&
      documentCardDetails.id == staff.id
    ) {
      if (flag) {
        setShowDocumentCanvas(false);
        setDocumentCardDetails();
      }
    } else {
      try {
        // const details = await fetchDocumentDetails("invoice", invoice.id);
        // setDocumentDetail(details.data);
        dispatch(getEquipmentDetail(staff.id))
        setDocumentCardDetails({ ...staff, type: "equipment" });
        setShowDocumentCanvas(true);
      } catch (e) {
        dispatch(
          showMessage("unsucess", _l("l_error"), _l("l_something_went_wrong"))
        );
        console.error(e);
      }
    }
  };
    return (
        <>
          {
          isLoadingData("equipment_list") ? (
            <div className="comman-main-box custom-accordian-main res-padding-right0 h-100 task-list-accordian">
            <CommanLoader className="position-relative start-0"/>
          </div>
          ) : 
          (
            <>
              <div
                id="list_view"
                className={`comman-main-box custom-accordian-main res-padding-right0 h-100 task-list-accordian ${
                  showTaskModal || showReqestedTaskModal || showDocumentCanvas
                    ? "list-view-toggle"
                    : ""
                }`}
              >
                <Accordion
                  defaultActiveKey={["01","02","03","04"]}
                  alwaysOpen
                  className="task-list-accordian-main"
                >
                  <div className="pill-wrapper">
                    <div id="" className="">
                      <div className="d-flex align-items-center filter-lists-menu min-width-190 justify-content-end">
                        <div className="badge  c-font f-12 color-white-80 bg-white-05  radius_3 me-2 d-none">
                          <div className="d-flex align-items-center">
                            <span className="color-white"></span>
                            <a href="#/" className="ps-1">
                              <X size={14} weight="light" className="c-icons" />
                            </a>
                          </div>
                        </div>
                        {taskFilters.length ? (
                      taskFilters.map((value, index) => {
                        if (value.main_key == "date_range_filter") {
                          jquery("body #active_week_date").removeClass("c-gradinet-active-border");
                          let displayFilterKey = value.title;
                          return (
                            <div
                              key={index}
                              className="badge c-font f-12 w-auto color-white-80 bg-white-05  radius_3 ms-2 comman-round-box active py-1"
                            >
                              <div className="d-flex align-items-center">
                                <span className="color-white">
                                  {displayFilterKey}
                                </span>
                                <a href="#/"
                                  className="ps-1"
                                  onClick={() => {
                                    dispatch(
                                      leftPanelFilters({
                                        main_key: value.main_key,
                                        filter_key: value.filter_key,
                                        title: value.title,
                                        main_sub_key: value.main_sub_key,
                                      })
                                    );
                                  }}
                                >
                                  <X size={14} className="c-icons" weight="light" />
                                </a>
                              </div>
                            </div>
                          );
                        }
                      })
                    ) : (
                      <></>
                    )}
                        <div className={`d-flex radius_3 justify-content-between commn-search ${pageName == 'customer' ? 'd-none' : ""}`}>
                          <div className="comment-input-box">
                            <input
                              type="text"
                              className="form-control bg-transparent with-base-bg border-0 py-1 px-2 f-11 c-font"
                              placeholder={`${_l("l_search_documents")}`}
                              onChange={(e) => {
                                setSearchString(e.target.value);
                              }}
                              value={searchString}
                            />
                          </div>
                          <a href="#/" className="comman_action_icon">
                            <div className="d-flex align-items-center ">
                              <div className="action_icon h25w25 d-none">
                                <X
                                  size={14}
                                  weight="light"
                                  className="c-icons m-auto filter-img"
                                />
                              </div>
                              <div className="action_icon h25w25">
                                <MagnifyingGlass
                                  size={16}
                                  weight="light"
                                  className="c-icons MagnifyingGlassIcon"
                                />
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Accordion.Item eventKey="01" className="with-pb-15">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                    {pageName == 'customer' ? <p className="mb-0"><br></br></p> :
                    <Accordion.Header className="active to-do accordion-header">
                    <div className={`left-task-header`}>
                    <div className="d-flex align-items-center fw-semibold title-fonts c-font f-13">
                        <span className="c-dots c-7 in-progress-white rounded-circle me-2"></span>
                        <span className="task-title me-2">
                        {pageName == "customer"? '' :_l("l_equipment")}
                        </span>
                        <span className="">
                        [{staff ? staff.length : 0}]
                        </span>
                    </div>
                    </div>
                </Accordion.Header>
                }
                
                    </div>
                    <Accordion.Body className="taskCountToggle">
                    {staff && staff.length ? (
                        staff
                        .slice(
                            0,
                            staffPage > 1
                            ? (staffPage - 1) * 10 + 16
                            : staffPage * 16
                        )
                        .map((staff, index) => {
                            return (
                                <CardView
                                    hideShow={() => {
                                    staffCardHandler(staff);
                                    setSelectedId(staff.id);
                                    setSelectDocument(pageName == ""? "contact" :"equipment");
                                    }}
                                    data={staff}
                                    docType={pageName == ""? "contact" :"equipment"}
                                    setDocType={setDocType}
                                    selectedId={selectedId}
                                    key={index}
                                    showDocumentCanvas={showDocumentCanvas}
                                    setSelectedId={setSelectedId}
                                    // pinHandler={(id) => {
                                    // handlePinUnPin(id, "staff");
                                    // }}
                                />
                            );
                        })
                    ) : (
                        <></>
                    )}
                    {staff.length &&
                    staff.length >= (staffPage - 1) * 10 + 16 &&
                    staff.length != (staffPage - 1) * 10 + 16 ? (
                        <>
                        <a href="#/"
                            className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                            onClick={() => {
                            handleViewMore("staff");
                            }}
                        >
                            <span className="title-fonts d-flex justify-content-center align-items-center">
                            <div className="d-flex align-item-center justify-content-center">
                                {_l("l_view_more_btn")}(
                                {staffPage > 1
                                ? staff.length -
                                    ((staffPage - 1) * 10 + 16)
                                : staff.length - 16}
                                )
                                <CaretDoubleDown
                                size={12}
                                weight="light"
                                className="c-icons mx-2"
                                />
                                <span
                                role="status"
                                aria-hidden="true"
                                className="ms-2 opacity-0 spinner-border spinner-border-sm"
                                ></span>
                            </div>
                            </span>
                        </a>
                        </>
                    ) : (
                        <></>
                    )}
                    {staff.length == 0 ? (
                        <CommanPlaceholder imgType="no-userfound" isAbsolute={true} placeholderText = {pageName == "customer"? "No Contacts" : _l("No Equipments")} />
                    ) : (
                        <></>
                    )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                {showDocumentCanvas ? (
            <CommanOffCanvas
              show={showDocumentCanvas}
              handleClose={() => {
                setShowDocumentCanvas(false);
                setDocumentCardDetails();
                setDocumentDetail();
                setSelectedId(0);
              }}
            //   pinHandler={(id, type, flag) => {
            //     handlePinUnPin(id, type, flag);
            //   }}
              data={documentDetail}
              setData={setDocumentDetail}
              docType={pageName == "customer"? "contact" :'equipment'}
              setSelectedId={setSelectedId}
            />
          ) : (
            <></>
          )}
              </div>
          </>
          )}
          </>
          
    )
                      
}
export default EquipmentListing;