import React from 'react';
// import CSS from '../css/icon_main.css';

function NoPastSubscription(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="93" height="93" viewBox="0 0 93 93" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons w-100 h-100 ${CommanHW}`}>
				<path d="M63.9115 39.7798H73.4132C75.7336 39.3347 77.1739 37.8094 77.1639 35.464C77.1639 33.0286 75.7336 31.0532 73.2782 30.9632C67.2421 30.7232 61.1709 30.6831 55.1449 31.0382C55.1449 31.0382 49.2388 33.5987 53.2845 38.9346C54.3147 40.2899 58.9956 39.7848 58.9956 39.7848M45.9932 67.9149C34.9912 67.9449 19.1733 67.9699 8.16134 67.9499C2.80536 67.9499 1 66.1246 1 60.8286C1 47.5362 1 34.2438 1 20.9514V17.8408H86.4655V60.8486M26.2746 25.2322C22.0138 25.2322 17.773 25.3172 13.4973 25.2022C11.1668 25.1371 10.3017 26.0623 10.3567 28.3527C10.4568 32.5085 10.4418 36.6742 10.3567 40.855C10.3167 43.0304 11.2769 43.8555 13.3873 43.8555C21.9088 43.8155 30.4304 43.8155 38.9519 43.8555C41.1123 43.8555 41.9925 42.8954 41.9525 40.795C41.9124 36.6342 41.8774 32.4735 41.9525 28.2927C42.0025 25.9473 40.9523 25.1572 38.7169 25.2072C34.6011 25.3122 30.4354 25.2322 26.2746 25.2322Z"  strokeMiterlimit="10" />
				<path d="M58.6939 54.3223C58.6939 50.1616 58.6939 45.9958 58.6939 41.8201C58.6939 39.7747 59.364 38.0994 61.6194 38.2044C63.6198 38.2994 64.2599 39.8897 64.2399 41.83C64.1849 46.6859 64.1349 51.5468 64.3099 56.3977C64.3449 57.3979 65.3501 58.3981 65.9102 59.3982C66.6153 58.6281 67.2654 57.8029 68.0356 57.1078C68.9157 56.3177 70.036 54.9324 70.8161 55.1075C71.8613 55.3125 72.7365 56.6777 73.5516 57.6579C73.8017 57.958 73.4766 58.6881 73.6766 59.0932C74.1234 59.8632 74.6247 60.6003 75.1769 61.2986C75.872 60.7485 77.0222 60.2984 77.1773 59.6283C77.6123 57.7979 78.4675 56.6627 80.3878 56.9128C82.5382 57.1978 82.8883 58.8732 82.8383 60.6985C82.8383 61.4886 82.6482 62.3838 82.9483 63.0489C83.3033 63.834 84.0935 64.4242 84.6936 65.1043C85.3088 64.5492 86.2689 64.1041 86.4639 63.429C87.009 61.5986 87.7992 60.2084 89.9246 60.6385C92.05 61.0685 92.3 62.7738 92.17 64.5792C91.8099 69.7851 91.6699 75.0211 90.9497 80.187C90.6747 82.2324 89.3745 84.2227 88.2493 86.0581C85.6738 90.2538 82.3882 92.8093 76.8672 92.2742C71.1712 91.7241 65.3651 92.2742 59.634 92.0992C58.1033 92.0359 56.6322 91.4878 55.4333 90.5339C50.4024 86.1781 47.6919 80.5771 47.5569 73.8809C47.4519 68.6299 47.5169 63.379 47.5569 58.123C47.5569 55.1225 48.9871 54.2073 51.6726 55.4225C54.0081 56.4827 54.9932 58.4231 55.0382 60.9235C55.0782 63.0989 54.8782 65.2993 55.1233 67.4547C55.2133 68.2498 56.2684 68.955 56.8835 69.6701C57.4936 68.92 58.6038 68.1698 58.6339 67.4197C58.8039 63.0639 58.7189 58.6981 58.7189 54.3373L58.6939 54.3223Z"  strokeMiterlimit="10" />
				<path d="M1.01953 13.9049C1.01953 11.6744 1.01953 9.69908 1.01953 7.72372C1.06954 2.95786 2.95487 1.0225 7.69573 1.0175C21.885 0.994166 36.0725 0.994166 50.2585 1.0175C60.1803 1.0175 70.1004 1.0175 80.0189 1.0175C84.5197 1.0175 86.5601 3.01787 86.6451 7.51868C86.6851 9.57906 86.6451 11.6444 86.6451 13.9098L1.01953 13.9049Z"  strokeMiterlimit="10" />
				<path d="M33.0202 48.0361H12.8866C11.4973 48.0361 10.3711 49.1623 10.3711 50.5516V51.3917C10.3711 52.781 11.4973 53.9072 12.8866 53.9072H33.0202C34.4095 53.9072 35.5357 52.781 35.5357 51.3917V50.5516C35.5357 49.1623 34.4095 48.0361 33.0202 48.0361Z"  strokeMiterlimit="10" />
				<path d="M23.9936 58.938H11.8464C11.0316 58.938 10.3711 59.5985 10.3711 60.4133V60.8183C10.3711 61.6331 11.0316 62.2936 11.8464 62.2936H23.9936C24.8083 62.2936 25.4688 61.6331 25.4688 60.8183V60.4133C25.4688 59.5985 24.8083 58.938 23.9936 58.938Z"  strokeMiterlimit="10" />
			</svg>


		</React.Fragment>
	)
}

export default NoPastSubscription