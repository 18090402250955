import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { _l, countCheckedValues, tabindexFix } from "../../hooks/utilities";
import GeneralItemTableWithButton from "../Offcanvas/NewSchedule/GeneralItemTableWithButton";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import useScheduleSteps from "../../hooks/useScheduleSteps";
import { Medal, X } from "phosphor-react";
import { Button } from "react-bootstrap";
import { FloorListing } from "../SpaceOnBoarding/SpaceDetails/FloorListing";
import { setFloorFilters } from "../../actions/benchmark-actions/benchmark-actions";

let currentFloorId = 0;
let activeKeySet = false;

const ScheduleActionChecklist = ({ show, handleClose, operator, slot }) => {

  const { type_of_space, floor } = useSelector(
    (state) => state.benchmarkReducer.floorDetails
  );
  const { filteredFloorIds } = useSelector((state) => state.benchmarkReducer);

  const dispatch = useDispatch();

  const checkAllButtonRef = useRef();

  const {
    currentSlot,
    operatorAvailability,
    setOperatorAvailability,
    assignedChecklistItems,
  } = useScheduleSteps();

  let checklistGroupByRoom = {};

  const [selectAllEvent, setSelectAllEvent] = useState();
  const [currentTab, setCurrentTab] = useState("floor-selection");
  const [activeAccordionKey, setActiveAccordionKey] = useState("");
  const [activeAccordionKeys, setActiveAccordionKeys] = useState([0]);

  const setTaskTitle = (title) => {
    setOperatorAvailability(
      Object.values({
        ...operatorAvailability,
        [currentSlot]: {
          ...operatorAvailability[currentSlot],
          task_title: title,
        },
      })
    );
  };

  type_of_space.forEach((room) => {
    if (room.is_default == 1 && currentFloorId == -1) {
      checklistGroupByRoom = {
        ...checklistGroupByRoom,
        [room.id]: {
          checklists: assignedChecklistItems.filter(
            (x) => x.room_id == room.id
          ),
          name: room.title,
          spaceId: room.id,
          spaceItemId: 0,
          skillRequireForBenchmark: room.skill_require_for_benchmark
        },
      };
    }
    room.details.forEach((spaceItem) => {
      if (filteredFloorIds.includes(spaceItem.floor_id)) {
        checklistGroupByRoom = {
          ...checklistGroupByRoom,
          [spaceItem.id]: {
            checklists: assignedChecklistItems.filter(
              (x) =>
                x.room_id == room.id && x.type_of_space_item_id == spaceItem.id
            ),
            name: spaceItem.title,
            spaceId: room.id,
            spaceItemId: spaceItem.id,
            skillRequireForBenchmark: room.skill_require_for_benchmark
          },
        };
      }
    });
  });

  const validateFields = () => {
    //Add validations here
    handleClose();
  };

  const handleSelectAll = (e) => {
    setSelectAllEvent(e);
  };

  const setFloorChecklists = (floor) => {
    currentFloorId = floor.id;
    activeKeySet = false;
    dispatch(setFloorFilters([currentFloorId]));
    setCurrentTab("checklist-assignment");
  };

  const getFloorIdOfSpaceItem = (spaceItemId) => {
    let floorId = 0;
    if (spaceItemId == 0) {
      floorId = -1;
      return floorId;
    }
    if (type_of_space && type_of_space.length) {
      type_of_space.forEach((x) => {
        x.details.forEach((w) => {
          if (w.id == spaceItemId) {
            floorId = w.floor_id;
          }
        });
      });
    }
    return floorId;
  };

  const getSelectedItemsCountForFloor = (floor_id = currentFloorId, itemsWithPhotoRequired) => {
    let selectedItemsCounts = 0;

    assignedChecklistItems.forEach((assignedChecklist) => {
      if(itemsWithPhotoRequired && assignedChecklist.photo_required == 0)
      {
        return
      }
      if (
        assignedChecklist.assigned_days
      ) {
        Object.keys(assignedChecklist.assigned_days).forEach((day) => {
          let floorId = getFloorIdOfSpaceItem(
            assignedChecklist.type_of_space_item_id
          );
          if (
            (floorId == floor_id) &&
            operator.days[day] &&
            assignedChecklist.days[day] &&
            assignedChecklist.assigned_days[day] == slot
          ) {
            selectedItemsCounts++;
          }
        });
      }
    });
    return selectedItemsCounts;
  };

  const getAvailableItemsCountForFloor = (floor_id = currentFloorId, itemsWithPhotoRequired) => {
    let availabiltyCount = 0;
   
    assignedChecklistItems.forEach((checklist) => {
      if(itemsWithPhotoRequired && checklist.photo_required == 0)
      {
        return
      }
      let floorId = getFloorIdOfSpaceItem(
        checklist.type_of_space_item_id
      );
      if(floorId == floor_id)
      {
        Object.keys(operator.days).forEach((day_name) => {
          if (
            operator.days[day_name] &&
            checklist.days[day_name] &&
            (!checklist.assigned_days ||
              ((checklist.assigned_days[day_name] == undefined ||
                  checklist.assigned_days[day_name] == -1 ||
                  checklist.assigned_days[day_name] == slot)))
          ) {
            availabiltyCount += 1;
          }
        });
      }
    });
    
    return availabiltyCount;
  };

  const closeFloorSelection = () => {
    setCurrentTab("floor-selection");
    dispatch(setFloorFilters([currentFloorId]));
  };

  const allFloorItemsAssigned = (itemsWithPhotoRequired) => {
    const availableChecklistItemsCount = getAvailableItemsCountForFloor(currentFloorId, itemsWithPhotoRequired);
    const selectedChecklistItemsCount = getSelectedItemsCountForFloor(currentFloorId, itemsWithPhotoRequired);

    return availableChecklistItemsCount > 0 && availableChecklistItemsCount === selectedChecklistItemsCount;    
  };

  const updateAccordionKeys = (index) => {
    if(activeAccordionKeys.includes(index))
    {
      setActiveAccordionKeys(activeAccordionKeys.filter((w) => w !== index));
    }
    else {
      setActiveAccordionKeys(activeAccordionKeys.concat(index));
    }
  };

  return (
    <Modal
      show={show}
      className="custom-modal-style"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // fullscreen
      size="xl"
      scrollable={true}
      onShow={() => {
        tabindexFix();
      }}
    >
      <Modal.Header>
        <Modal.Title className="flex-grow-1">
          {_l("l_schedule_assignment")}
        </Modal.Title>
        <a href="#/" className="d-flex" onClick={validateFields}>
          <X size={18} weight="light" className="c-icons" />
        </a>
        <Form.Group className="form-check px-1 m-0 ms-auto d-none">
          <Form.Label className="form-check-label m-0">
            <input
              className="form-check-input m-0"
              type="checkbox"
              name="invitepeopleradio"
            />
            <span className="ps-2">Go Cardless</span>
          </Form.Label>
        </Form.Group>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <React.Fragment>
          <div
            className={`${
              currentTab === "floor-selection" ? "h-100" : "d-none"
            }`}
          >
            <div className="pb-2 border-bottom position-sticky top-0 z-index-2 base-modal-bg pt-15">
              <div className="d-flex align-items-center w-100 pb-2">
                <div className="h30w30 comman-round-box with-bg d-flex bg-white-05 rounded-circle">
                  {
                    operator.is_skilled > 0
                    ?
                      <span className="bg-transparent absolutecrownbadge  ps-0 border-0  badge-custom d-flex  rounded-pill bg-none">
                        <Medal size={16}  weight="light" className="c-icons opacity-60"  />
                      </span>
                    :
                    <React.Fragment></React.Fragment>
                  }
                  <div
                    className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                    style={{
                      backgroundImage: `url(${operator.assignee_image})`,
                    }}
                  ></div>
                </div>
                <div className="w100minus30 title-fonts fw-semibold">
                  <div className="d-flex align-items-center w-100">
                    <div className="flex-grow-1 w100minus100per text-truncate ps-2">
                      {operator.assignee_name}
                    </div>
                  </div>
                </div>
                <div className="ms-auto d-flex align-items-center">
                  <div className="left-box with_separator_10">
                    <div className="title-fonts fw-semibold text-center">
                      {
                        currentTab === "checklist-assignment"
                        ?
                        getAvailableItemsCountForFloor()
                        :
                        operatorAvailability[currentSlot].action_count
                      }
                    </div>
                    <div className="c-font f-12 opacity-50">
                      {_l("l_total")}
                    </div>
                  </div>
                  <div className="left-box with_separator_10 pe-0">
                    <div className="title-fonts fw-semibold text-center">
                    {
                        currentTab === "checklist-assignment"
                        ?
                        getSelectedItemsCountForFloor()
                        :
                        countCheckedValues(
                          assignedChecklistItems,
                          currentSlot,
                          operator.days
                        )
                      }
                    </div>
                    <div className="c-font f-12 opacity-50">
                      {_l("l_selected")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-control p-0 d-flex align-items-center border-0">
                <div className="color-white-80 border-right px-3 text-nowrap">{_l("l_title")}</div>
                <Form.Control
                className="border-0 bg-transparent"
                  placeholder={_l("l_task_name")}
                  type="text"
                  value={operatorAvailability[currentSlot].task_title}
                  onChange={(e) => {
                    setTaskTitle(e.target.value);
                  }}
                />
              </div>
            </div>
            <FloorListing
              buttonText={_l("l_assign")}
              setFloor={setFloorChecklists}
              getAvailableItemsCountForFloor={getAvailableItemsCountForFloor}
              getSelectedItemsCountForFloor={getSelectedItemsCountForFloor}
            />
          </div>
          <div
            className={`${
              currentTab === "checklist-assignment" ? "h-100" : "d-none"
            }`}
          >
            <div className="pb-2 border-bottom position-sticky top-0 z-index-4 base-modal-bg pt-15">
              <div className="d-flex align-items-center w-100 pb-2">
                <div className="h30w30 comman-round-box with-bg d-flex bg-white-05 rounded-circle">
                  <div
                    className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                    style={{
                      backgroundImage: `url(${operator.assignee_image})`,
                    }}
                  ></div>
                </div>
                <div className="w100minus30 title-fonts fw-semibold">
                  <div className="d-flex align-items-center w-100">
                    <div className="flex-grow-1 w100minus100per text-truncate ps-2">
                      {operator.assignee_name}
                    </div>
                    <div className="ms-auto d-flex align-items-center">
                      <div className="left-box with_separator_10">
                        <div className="title-fonts fw-semibold text-center">
                          {
                            getAvailableItemsCountForFloor()
                            // operatorAvailability[currentSlot].action_count
                          }
                        </div>
                        <div className="c-font f-12 opacity-50">
                          {_l("l_total")}
                        </div>
                      </div>
                      <div className="left-box with_separator_10 pe-0">
                        <div className="title-fonts fw-semibold text-center">
                          {
                            getSelectedItemsCountForFloor()
                            // countCheckedValues(
                            //   assignedChecklistItems,
                            //   currentSlot
                            // )
                          }
                        </div>
                        <div className="c-font f-12 opacity-50">
                          {_l("l_selected")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-control p-0 d-flex align-items-center border-0">
                <div className="color-white-80 border-right px-3 text-nowrap">{_l("l_title")}</div>
                <Form.Control
                  className="border-0 bg-transparent"
                  placeholder={_l("l_task_name")}
                  type="text"
                  value={operatorAvailability[currentSlot].task_title}
                  onChange={(e) => {
                    setTaskTitle(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="border custom-accordian-main modified-height mt-3 p-10 p-1 radius_3 with-left-header-arrow">
              <div class="align-items-center d-flex justify-content-between p-10 border-bottom">
                <div class="c-font f-14 fw-semibold text-truncate title-fonts w100minus100per">
                  {floor.find((w) => w.id == currentFloorId)
                    ? floor.find((w) => w.id == currentFloorId).title
                    : ""}
                </div>
                <a href="#/"
                  className=""
                  onClick={closeFloorSelection}
                >
                  <X size={18} weight="light" className="c-icons" />
                </a>
              </div>
              <div className="d-flex justify-content-between m-2">
                <Form.Check
                  type="switch"
                  id="assign-all-floor-items"
                  label={_l("l_assign_all")}
                  onChange={(e) => checkAllButtonRef.current.handleSelectAll(e)}
                  checked={allFloorItemsAssigned()}
                />
                <Form.Check
                  type="switch"
                  id="assign-all-floor-items"
                  label={_l("l_select_checklist_with_photo_required")}
                  onChange={(e) => checkAllButtonRef.current.handleSelectAll(e, false, true)}
                  checked={allFloorItemsAssigned(true)}
                />
              </div>
              <Accordion
                defaultActiveKey={[0]} 
                alwaysOpen
              >
                {checklistGroupByRoom &&
                Object.keys(checklistGroupByRoom).length ? (
                  Object.keys(checklistGroupByRoom).map((space, index) => {
                    if (checklistGroupByRoom[space].checklists.length) {
                      if(!activeKeySet)
                      {
                        setActiveAccordionKey(index);
                        activeKeySet = true;
                      }
                      return (
                        <Accordion.Item
                          className={`mainTopicTaskFilter`}
                          eventKey={index}
                        >
                          <div className="accordian-header-box d-flex align-content-center accordian-main-header title-fonts custom-accordian-Header-sticky">
                            <Accordion.Header onClick={() => {
                                if(activeAccordionKey === index)
                                {
                                  setActiveAccordionKey("");
                                }
                                else if(activeAccordionKey === "" || activeAccordionKey !== index) {
                                  setActiveAccordionKey(index);
                                }
                                updateAccordionKeys(index)
                              }}>
                              <div className="d-flex align-items-center justify-content-between w-100 line-height-1">
                                <div className={`title-font c-font f-12 fw-semibold text-truncate ${checklistGroupByRoom[space].skillRequireForBenchmark && operator.is_skilled == 0 ? "opacity-50" : ""}`}>
                                  {checklistGroupByRoom[space].name}
                                </div>
                              </div>
                              {
                                checklistGroupByRoom[space].skillRequireForBenchmark > 0
                                ?
                                <div className="form-check form-check-inline m-0  d-flex align-items-center for-disabled">
                                  <label className="input-label no-asterisk form-label me-10px mb-0">{_l("l_skill_require_for_benchmark")}</label>
                                  <Form.Check
                                    className=" with-no-label-space"
                                    type="switch"
                                    role="switch"
                                    defaultChecked
                                    disabled
                                  />
                                </div>
                                :
                                <React.Fragment></React.Fragment>
                              }
                            </Accordion.Header>
                          </div>
                          <Accordion.Body className="mt-1 childTopicTaskFilter">
                            {
                              activeAccordionKeys.includes(index)
                              ?
                                <GeneralItemTableWithButton
                                  ref={checkAllButtonRef}
                                  spaceId={checklistGroupByRoom[space].spaceId}
                                  spaceItemId={checklistGroupByRoom[space].spaceItemId}
                                  checklist={checklistGroupByRoom[space].checklists}
                                  selectAllEvent={selectAllEvent}
                                  floorId={currentFloorId}
                                  getFloorIdOfItem={getFloorIdOfSpaceItem}
                                  disableAssignment={checklistGroupByRoom[space].skillRequireForBenchmark && operator.is_skilled == 0}
                                />
                              :
                              <></>
                            }
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    }
                  })
                ) : (
                  <></>
                )}
               </Accordion>
            </div>
          </div>
        </React.Fragment>
      </Modal.Body>
      {currentTab === "floor-selection" ? (
        <React.Fragment></React.Fragment>
      ) : (
        <Modal.Footer className="border-top">
          <Button
            variant="primary"
            size="sm"
            onClick={closeFloorSelection}
          >
            {_l("l_assign")}
          </Button>
      </Modal.Footer>
      )}
    </Modal>
  );
};

export default ScheduleActionChecklist;
