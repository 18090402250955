import React from 'react';
// import CSS from '../css/icon_main.css';

function SquareIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

			<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 256 256" className={`comman-icons with-fill ${CommanHW}`}><path  d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208V208Z"></path>
			</svg>

		</React.Fragment>
	)
}

export default SquareIcon