import React, { useEffect, useRef, useState } from "react";
import {
  Buildings,
  CalendarBlank,
  CaretDoubleDown,
  CaretLeft,
  DotsThreeVertical,
  Envelope,
  File,
  FileText,
  Gauge,
  GearSix,
  Handshake,
  HardDrives,
  Headset,
  MagnifyingGlass,
  Phone,
  PhoneCall,
  PhoneDisconnect,
  UserGear,
  Users,
  UsersThree,
  Wrench,
} from "phosphor-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  SetSelectedTask,
  chatMessageLoader,
  clearChatTaskList,
  commanoffcanvasisOpen,
  dayGridDay,
  dayGridMonth,
  dayGridWeek,
  getCalendarTaskDate,
  getDefaultTopics,
  getTaskList,
  leftPanelFilters,
  projectTaskCounts,
  setDaterangeFilter,
  setDaterangeFilterApplied,
  setFetchedTaskandMessages,
  setSelectedGloablView,
  setSelectedTabLeftPanel,
  setSolarhubFilters,
  setSpaceNotificationCount,
  setTasklistLoader,
  updateTaskList,
} from "../actions/customer";
import UserProfileDropdown from "./SolarExternalPages/UserProfileDropdown";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import {
  fetchGroups,
  fetchGroupsExternal,
  fetchMessages,
  getGroupDetails,
  groupIn,
  groupOut,
  setClearmessage,
  setOpenGeneralChatGroup,
  setShowAccordionVisibility,
  setShowIsChatScreenDisplay,
  updateExternalGroupListMessages,
} from "../actions/chat";
import chatServices from "../services/chat-services";
import { TaskModalClose, _l, addExpandedClasstoIntroOfficeList, formateDate, hideLeftPanelNew, hideRightPanelNew, showLeftPanelNew } from "../hooks/utilities";
import ReactTooltip from "react-tooltip";
import { Form, Spinner } from "react-bootstrap";
import jquery from "jquery";
import ActionDropdown from "./Dropdowns/ActionDropdown";
import Autocomplete from "./Dropdowns/Autocomplete";
import Solarlogo from "../assets/images/blzalogoicon.svg";
import SolarlogoText from "../assets/images/solarlogotext.svg";
import Blazlogo from "../assets/images/blzalogoicon.svg";
import BlazlogoText from "../assets/images/blazlogotext.svg";
import customerServices from "../services/customer-services";
import CommanPlaceholder from "./Placeholder/CommanPlaceholder";
import LeftPanelCategorySkeleton from "./Skeleton/LeftPanelCategorySkeleton";
import TwoStepAccordians from "./Accordians/TwoStepAccordians";
import MyDrive from "./LeftPanelDrive/MyDrive";
import ClientDataSkeleton from "./Skeleton/ClientDataSkeleton";

let SPACE_LIST_RESULTS_PER_PAGE = 10;

const projectStatusArray = [
  {
    id: 1,
    name: _l("l_todo"),
    class: "color-gray",
  },
  {
    id: 2,
    name: _l("l_free_trial"),
    class: "color-blue",
  },
  {
    id: 6,
    name: _l("l_active"),
    class: "color-green",
  },
  {
    id: 7,
    name: _l("l_inactive"),
    class: "color-white",
  },
];

const LeftPanelSubmenu = ({
  spaces = [],
  projectId,
  handleUserForSolarStaff = () => {},
  loginHandlerforsolarStaff = () => {},
  loginHandler = () => {},
  setSettingTopicName = () => {},
  setTopicMainKey = () => {},
  onClickOfficeChange = () => {},
  mentionStaffArray = [],
  applyStaffUserFilter = () => {},
  isSolarHubPlatform = () => {},
  getMentionUsersForFilter = () => {},
  setShowBackDropClass = () => {},
  setSpaceSearchOnGoing = () => {},
  setCompletedTasks = () => {},
  contactDirLoader,
  setCompletedTasksCount = () => {},
  setInProgressTasksCount = () => {},
  setTodoTasksCount = () => {},
  searchCategory,
  pageName,
  teamLeftPanel,
  setSelectOperators = () => {},
  setShowScheduleDropDown = () => {},
  setShowProviderDropDown = () => {},
  allMAilMessages,
  allImportantMessages,
  hideMailSyncBtn,
  createTeamModal,
  setCreateTeamModal = () => {},
  equipmentModal,
  setEquipmentModal = () => {},
  selectAdminTeam = () => {},
  selectedTeam,
  clientTeamData,
  handleDeleteTeam,
  handleFavoriteAdminTeam,
  handleEditAdminTeam,
  topicType,
  setTopicType = () => {},
  selectedTeamForEdit,
  setSelectedTeamForEdit = () => {},
  showAddTeamMember,
  setshowAddTeamMember = () => {},
  adminTeamList,
  setAdminTeamList = () => {},
  showUserList,
  searchCategoty,
  searchTopic,
  showForMeSection,
  setShowForMeSection = () => {},
  setshowMyProfile = () => {},
  showMyProfile,
  selectedOffice,
  projectTaskCount,
  setSelectedOffice = () => {},
  setSelectedProject = () => {},
  setSelectedSpace = () => {},
  contactDirData,
  contactDirTopicChange = () => {},
  contactlistShow,
  setContactlistShow = () => {},
  setsearchTopic = () => {},
  getspacedata = () => {},
  spaceData = [],
  admin,
  adminUser,
  createChatChannels = () => {},
  setproject_id = () => {},
  getUserList = () => {},
  setClientIdLoginAsUser = () => {},
  setManagePeopleModal = () => {},
  setshowOpenAiModal = () => {},
  setspaeContent = () => {},
  setOfficeId = () => {},
  setShowToast = () => {},
  prepareToast = () => {},
  setShowDocumentCanvas = () => {},
  projectStatusArray,
  handleChangeProjectStatus = () => {},
  handleChangeViewtoFavView = () => {},
  isSynced,
  tenantesList= [],
  setEmailModalOpenSet = () => {},
  filterTaskActive,
  filterTasksByStatus= () => {},
  totalCounts,
  supportChatCounts
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let defaultTaskStatus = [0, 1, 4, 5];
  const isLeadPage = pageName == "leadPage" ? 1 : 0;


  const { leftPanelTab, taskFilters, globalView, activeTaskStatusFilters, isDayGridDay, isAppliedUnreadComment, customerData } = useSelector((state) => state.customer);
  const { groupListExternal, groupDetails } = useSelector((state) => state.chat);

  const userType = localStorage.getItem("user_type");
  let activeGroupId = localStorage.getItem("activeGroupId");
  var chatUserId = localStorage.getItem("chatUserId");
  let scheduleData = localStorage.getItem("schedule_data");
  let customerScheduleData = localStorage.getItem("customer_schedule_data");
  const contact_role = localStorage.getItem("contact_role");
  const accepted_proposal = localStorage.getItem("accepted_proposal");
  let project_id = localStorage.getItem('selectedOffice');
  const client_id = localStorage.getItem("client_id");
  const contact_id = localStorage.getItem("contact_id");
  const lastMsgGroup = localStorage.getItem("msgGroupId");
  const [startDate] = useState( localStorage.getItem("fromDate") || new Date());
  const [endDate] = useState(localStorage.getItem("toDate") || new Date());

  const [searchParams, setSearchParams] = useSearchParams();
  const [activeOfficePage, setActiveOfficePage] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [isLoginAsUserDataLoaded, setIsLoginAsUserDataLoaded] = useState([]);
  const [userList, setUserList] = useState([]);
  const [showProjectStatusList, setshowProjectStatusList] = useState(false);
  const [loder, setLoder] = useState(false);
  const [selectedspaceStatus, setSelectedspaceStatus] = useState(0);
  const [randomID, setRandomID] = useState(String(Math.random()));
  const [contactShow, setContactShow] = useState(false);
  const [selectedSpace, setselectedSpace] = useState({})

  const proposalId = searchParams.get("proposal_id");
  const id = searchParams.get("id");
  const page = searchParams.get("page");

  const createChatChannelforSolarCustomer = (groupDetails) => {
    chatServices
      .getGroupDetails(localStorage.getItem("chatUserId"), groupDetails.groupId)
      .then((response) => {
        if (
          response &&
          response.success &&
          response.result &&
          response.result.extraData
        ) {
          localStorage.setItem("activeGroupId", groupDetails.groupId);
          dispatch(getGroupDetails({}));
          dispatch(setFetchedTaskandMessages([]));
          dispatch(updateTaskList([]));
          dispatch(setClearmessage());
          dispatch(clearChatTaskList());
          dispatch(
            setSolarhubFilters({
              main_key: response.result.extraData.mainKey,
              filter_key: response.result.extraData.filterKey,
              title: groupDetails.groupName,
              image: response.result.displayPicture,
              main_sub_key: response.result.extraData.mainSubKey,
              sub_category: [],
            })
          );
          dispatch(getGroupDetails(response.result));
          
          var socketDataIn = {
            groupId: activeGroupId,
            userId: localStorage.getItem("chatUserId"),
          };
          dispatch(groupIn(socketDataIn));
          dispatch(chatMessageLoader(true));
          dispatch(
            fetchMessages(
              localStorage.getItem("chatUserId"),
              groupDetails.groupId,
              0,
              () => {},
              1
            )
          ).then((res) => {
              dispatch(chatMessageLoader(false));
          });
          dispatch(setTasklistLoader(true));
          const savedDate = localStorage.getItem("selectedDate");
          dispatch(
            getTaskList(
              client_id,
              contact_id,
              project_id,
              moment(savedDate).format("YYYY-MM-DD"),
              activeTaskStatusFilters.length ? 2 : 1,
              activeTaskStatusFilters,
              taskFilters,
              "",
              0,
              isDayGridDay && !isAppliedUnreadComment ? 1 : 0,
              isDayGridDay
                ? moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD")
                : moment(startDate).format("YYYY-MM-DD"),
              isDayGridDay
                ? moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD")
                : moment(endDate).format("YYYY-MM-DD")
            )
          );
          // navigate("/");
        }
      });
  };
  const handleCreateChatGroup =(projectId,main_key,main_sub_key,filter_key,proposalId,applysolarFilter = false)=>{
    if (leftPanelTab == "my-clients") {
      dispatch(getCalendarTaskDate(projectId));
    }
    localStorage.removeItem("firstDate");
    localStorage.removeItem("lastDate");
    dispatch(setFetchedTaskandMessages([]));
    var activeGroupIdPrev = localStorage.getItem("activeGroupId");
    if (chatUserId != "" && activeGroupIdPrev != "" && activeGroupIdPrev != undefined) {
      var socketDataOut = {
        groupId: activeGroupIdPrev,
        userId: chatUserId,
      };
      dispatch(groupOut(socketDataOut));
    }
      customerServices.createChatGroup(projectId,main_key,main_sub_key,filter_key,proposalId).then((res)=>{
        if(res.status && res.data){
          if (userType == "operator" && leftPanelTab == "my-clients") {
            
          }else{
            dispatch(fetchGroupsExternal(chatUserId, localStorage.getItem('selectedOffice'), 1))
          }
          let groupID = res.data._id
          localStorage.setItem("activeGroupId", groupID);
          
        }else{
          localStorage.setItem("activeGroupId",0);
          // dispatch(showMessage("unsucess", _l("l_error"),_l(res.message)));
        }
      })
    
  }

  useEffect(() => {
    setSearchString('');
  }, [leftPanelTab])
  

  useEffect(() => {
    if (groupListExternal && groupListExternal.length > 0 && ["my-clients", "my-spaces"].includes(leftPanelTab) &&  !["staff", "operator"].includes(userType)) {
      createChatChannelforSolarCustomer(groupListExternal[0]);
      navigate("/");
    }
  }, [JSON.stringify(groupListExternal)])

  // useEffect(() => {
  //   if (["my-clients", "my-spaces"].includes(leftPanelTab) && userType !== "staff" && spaces && spaces.length > 0) {
  //     dispatch(updateExternalGroupListMessages([]))
  //     setselectedSpace(spaces[0])
  //     handleCreateChatGroup(spaces[0].project_id, "providers", "providers", "1");
  //     localStorage.setItem(
  //       "selectedOffice",
  //       spaces[0].project_id
  //     );
  //   }
  // }, [leftPanelTab, JSON.stringify(spaces)])

  return (
    <>
      <div className="fixed-left-panel" id="left-panel-main">
        <div
          className="fixed-left-panel office-list intro-office-list"
          id="intro-office-list"
        >
          <div className="fixed-left-panel-wrapper h-100 d-flex flex-column">
            <div className="upper-part office-list-main overflow-visible flex-grow-0 bg-transparent mt-0">
              <div className="company-logo-box pt1px p-0">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center justify-content-between w100minus70 flex-grow-1 ">
                    <div
                      className={`h70w70 d-flex align-items-center justify-content-center office-list-icon `}
                    >
                      <a
                        href="#/"
                        className={`h70w70 comman-round-box radius_3 d-flex border-transparent left-panel-email flex-column justify-content-center align-items-center`}
                      >
                        <div className="h70w70 comman-round-box radius_3 title-fonts d-flex flex-column square_box"
                        onClick={()=>{
                          localStorage.setItem("leftPanelTab", "homePage");
                          dispatch(leftPanelFilters("", "", "all"));
                          dispatch(setSelectedTabLeftPanel("homePage"));
                          addExpandedClasstoIntroOfficeList();
                          dispatch(commanoffcanvasisOpen(false));
                          TaskModalClose();
                          navigate("/")
                          showLeftPanelNew();
                        }}>
                          <span className="m-auto c-font f-13 office-subtitle text-truncate hover-span mt20pximg">
                            {/* {["my-clients", "my-documents", "support", "my-spaces"].includes(leftPanelTab) && !contactShow ? ( */}
                              <img className="w-auto mb-10" src={Solarlogo} alt="" />
                            {/* ) : leftPanelTab == "my-clients" ? (
                              <Buildings
                                size={24}
                                weight="light"
                                className="c-icons h30px mb-10"
                              />
                            ) : leftPanelTab == "my-documents" ? (
                              <File
                                size={24}
                                weight="light"
                                className="c-icons"
                              />
                            ) : leftPanelTab == "support" ? (
                              <Headset
                                size={24}
                                weight="light"
                                className="c-icons"
                              />
                            ) : leftPanelTab == "my-spaces" ? (
                              <Buildings
                                size={24}
                                weight="light"
                                className="c-icons"
                              />
                            ) : leftPanelTab == "my-task"? (
                              <>
                                <Buildings
                                size={24}
                                weight="light"
                                className="c-icons"
                              />
                              </>
                            ) : (
                              <></> */}
                            {/* )} */}
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="office-list-main">
            <div className={`office-box spaceicon p-0  ${
                      leftPanelTab == "my-request" ? "active" : ""
                    }`}>
                  <a
                    href="#/"
                    className={`h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle  comman-round-box `}
                    onClick={() => {
                      localStorage.setItem("leftPanelTab", "my-request");
                      dispatch(setSelectedTabLeftPanel("my-request"));
                      navigate("/");
                      dispatch(SetSelectedTask({}));
                      dispatch(leftPanelFilters("","","applyForMewithAll"));
                      dispatch(setSelectedGloablView("listView"));
                      dispatch(setDaterangeFilter("all"))
                      dispatch(setDaterangeFilterApplied(true));
                      showLeftPanelNew();
                      dispatch(setShowAccordionVisibility(true));
                      dispatch(commanoffcanvasisOpen(false));
                      TaskModalClose();
                     
                    }}
                  >
                    <div className="comman-round-box  title-fonts d-flex flex-column justify-content-center align-items-center square_box h-100">
                      <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                        <div className="action_icon h30w30">
                          <Gauge size={28} weight="light" className="c-icons" />
                        </div>
                      </div>
                    <span className="color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">{_l("l_my_task")}</span>
                    </div>
                  </a>
                </div>
              {(userType != "staff" && userType === "operator") ||
              (userType === "contact") ? (
                <div className={`office-box spaceicon p-0  ${
                      leftPanelTab == "proposal-list" ? "active" : ""
                    }`}>
                  <a
                    href="#/"
                    className={`h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle  comman-round-box `}
                    onClick={() => {
                      localStorage.setItem("leftPanelTab", "proposal-list");
                      dispatch(leftPanelFilters("", "", "all"));
                      dispatch(setSelectedTabLeftPanel("proposal-list"));
                      dispatch(commanoffcanvasisOpen(false));
                      TaskModalClose();
                      showLeftPanelNew();
                      if (userType === "operator") {
                        navigate("/?page=proposals");
                      } else if (userType === "contact") {
                        let tempScheduleData = customerScheduleData
                          ? JSON.parse(customerScheduleData)
                          : {};
                        if (Object.keys(tempScheduleData).length) {
                          navigate(
                            `/?page=create-benchmark&space=${tempScheduleData.project_id}&type=benchmark&id=${tempScheduleData.benchmark_id}`
                          );
                          return;
                        }
                      }
                    }}
                  >
                    <div className="comman-round-box  title-fonts d-flex flex-column justify-content-center align-items-center square_box h-100">
                      <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                        <div className="action_icon h30w30">
                          <Handshake size={28} weight="light" className="c-icons" />
                        </div>
                      </div>
                    <span className="color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">{userType == "contact" ? _l("l_my_offers") : _l("CRM")}</span>
                    </div>
                  </a>
                </div>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              {(userType === "contact" && accepted_proposal > 0)  ? (
                <React.Fragment>
                  <div className={`office-box spaceicon p-0  ${
                        leftPanelTab == "my-spaces" ? "active" : ""
                      }`}>
                    <a
                      href="#/"
                      className={`h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle  comman-round-box `}
                      onClick={() => {
                        if (leftPanelTab !== "my-spaces") {
                          localStorage.setItem("leftPanelTab", "my-spaces");
                          dispatch(setSelectedTabLeftPanel("my-spaces"));
                          dispatch(commanoffcanvasisOpen(false));
                          dispatch(leftPanelFilters("", "", "all"));
                          localStorage.removeItem("activeGroupId");
                          showLeftPanelNew();
                          TaskModalClose();
                          navigate("/");
                        }
                      }}
                    >
                      <div className="comman-round-box  title-fonts d-flex flex-column justify-content-center align-items-center square_box h-100">
                        <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                          <div className="action_icon h30w30">
                            <Buildings
                              size={28}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </div>
                        <span className=" color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">{_l("l_my_space")}</span>
                      </div>
                    </a>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )}
             { userType === "operator" ? <> <div className={`office-box spaceicon p-0 ${ leftPanelTab=="adminstrative-list" ? "active" : "" }`}>
                <a href="#/" className={`h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle
                  comman-round-box `} 
                  onClick={()=>{
                    localStorage.setItem("leftPanelTab", "adminstrative-list");
                    dispatch(setSelectedTabLeftPanel("adminstrative-list"));
                    dispatch(commanoffcanvasisOpen(false));
                    TaskModalClose();
                    dispatch(leftPanelFilters("", "", "all"));
                    if (userType === "operator") {
                      navigate("/?page=proposals");
                    } else if (userType === "contact") {
                      let tempScheduleData = customerScheduleData
                        ? JSON.parse(customerScheduleData)
                        : {};
                      if (Object.keys(tempScheduleData).length) {
                        navigate(
                          `/?page=create-benchmark&space=${tempScheduleData.project_id}&type=benchmark&id=${tempScheduleData.benchmark_id}`
                        );
                        return;
                      }
                    }
                  }}>
                  <div
                    className="comman-round-box  title-fonts d-flex flex-column justify-content-center align-items-center square_box h-100">
                    <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                      <div className="action_icon h30w30">
                        <UserGear size={28} weight="light" className="c-icons" />
                      </div>
                    </div>
                    <span className="color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">Démarches</span>
                  </div>
                </a>
              </div>
              <div className={`office-box spaceicon p-0 ${ leftPanelTab=="installation-list" ? "active" : "" }`}>
                <a href="#/" className={`h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle
                  comman-round-box `}
                  onClick={()=>{
                    localStorage.setItem("leftPanelTab", "installation-list");
                    dispatch(leftPanelFilters("", "", "all"));
                    dispatch(setSelectedTabLeftPanel("installation-list"));
                    TaskModalClose();
                    dispatch(commanoffcanvasisOpen(false));
                    if (userType === "operator") {
                      navigate("/?page=proposals");
                    } else if (userType === "contact") {
                      let tempScheduleData = customerScheduleData
                        ? JSON.parse(customerScheduleData)
                        : {};
                      if (Object.keys(tempScheduleData).length) {
                        navigate(
                          `/?page=create-benchmark&space=${tempScheduleData.project_id}&type=benchmark&id=${tempScheduleData.benchmark_id}`
                        );
                        return;
                      }
                    }
                  }}>
                  <div
                    className="comman-round-box  title-fonts d-flex flex-column justify-content-center align-items-center square_box h-100">
                    <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                      <div className="action_icon h30w30">
                        <Wrench size={28} weight="light" className="c-icons" />
                      </div>
                    </div>
                    <span className="color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">Poses</span>
                  </div>
                </a>
              </div> </> : <></>}
              {userType !== "contact" ? 
              <>
                <div className={`office-box spaceicon p-0  ${
                      leftPanelTab == "my-clients" ? "active" : ""
                    }`}>
                  <a
                    href="#/"
                    className={`h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle  comman-round-box `}
                    onClick={() => {
                      localStorage.setItem("leftPanelTab", "my-clients");
                      dispatch(leftPanelFilters("", "", "all"));
                      dispatch(setSelectedTabLeftPanel("my-clients"));
                      dispatch(commanoffcanvasisOpen(false));
                      navigate("/")
                      TaskModalClose();
                      showLeftPanelNew();
                      localStorage.setItem("currentView", "AichatView");
                      dispatch(setSelectedGloablView("AichatView"));
                    }}
                  >
                    <div className="comman-round-box  title-fonts d-flex flex-column justify-content-center align-items-center square_box h-100">
                      <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                        <div className="action_icon h30w30 position-relative">
                          <UsersThree size={28} weight="light" className="c-icons" />
                          {
                            totalCounts > 0 &&
                            <div className="badge-custom badge-green d-flex justify-content-center rounded-circle">
                              <span className={`m-auto ps-0`}>
                                {totalCounts}
                              </span>
                            </div>
                          }
                        </div>
                      </div>
                      <span className=" color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">{_l("Clients")}</span>
                    </div>
                  </a>
                </div>
                {userType == "staff" ? 
                <div className={`office-box spaceicon p-0  ${
                      leftPanelTab == "my-staff" ? "active" : ""
                    }`}>
                  <a
                    href="#/"
                    className={`h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle  comman-round-box `}
                    onClick={() => {
                      if (leftPanelTab != "my-staff") {
                        TaskModalClose();
                        localStorage.setItem("leftPanelTab", "my-staff");
                        dispatch(getDefaultTopics(0, "", "", "homepage", '', true));
                        dispatch(setSelectedTabLeftPanel("my-staff"));
                        dispatch(leftPanelFilters("", "", "all"));
                        navigate("/");
                        hideRightPanelNew();
                        showLeftPanelNew();
                        dispatch(commanoffcanvasisOpen(false));
                      }
                    }}
                  >
                    <div className="comman-round-box  title-fonts d-flex flex-column justify-content-center align-items-center square_box h-100">
                      <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                        <div className="action_icon h30w30">
                          <Users size={28} weight="light" className="c-icons" />
                        </div>
                      </div>
                      <span className=" color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">{_l("l_staff")}</span>
                    </div>
                  </a>
                </div>
                : <></>}
                {userType == "staff" ?
                <div className={`office-box spaceicon p-0  ${
                      leftPanelTab == "" ? "active" : ""
                    }`}>
                  <a
                    href="#/"
                    className={`h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle  comman-round-box `}
                   
                  >
                    <div className="comman-round-box  title-fonts d-flex flex-column justify-content-center align-items-center square_box h-100">
                      <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                        <div className="action_icon h30w30">
                          <PhoneDisconnect size={28} weight="light" className="c-icons" />
                        </div>  
                      </div>
                      <span className=" color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">{_l("l_leads")}</span>
                    </div>
                  </a>
                </div> : <></>}

              </>
                : <></>}
                
            </div>
            <div className="lower-part mt-0">
            <div className="office-list-main mt-0">
              <div className={`office-box spaceicon p-0`}>
                <a
                  href="#/"
                  className={`h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle  comman-round-box `}
                >
                  <div className="comman-round-box  title-fonts d-flex flex-column justify-content-center align-items-center square_box h-100">
                    <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                      <div className="action_icon h30w30">
                        <Envelope size={28} weight="light" className="c-icons" />
                      </div>
                    </div>
                    <span className=" color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">{_l("l_email")}</span>
                  </div>
                </a>
              </div>
            {(userType === "operator" || userType === "staff") ||
                (userType == "contact" && accepted_proposal > 0) ? (
                  <div className={`office-box spaceicon p-0  ${
                        leftPanelTab == "my-drive" ? "active" : ""
                      }`}>
                    <a
                      href="#/"
                      className={`h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle  comman-round-box `}
                      onClick={() => {
                        // if (userType === "operator") {
                        //   dispatch(setSelectedTabLeftPanel("my-documents"));
                        //   navigate("/?page=document");
                        //   hideLeftPanelNew();
                        // } else if(userType == "contact" && accepted_proposal > 0){
                        // setSettingTopicName("l_drive");
                        // setTopicMainKey("documents");
                        // dispatch(setShowIsChatScreenDisplay(true));
                        // dispatch(setShowAccordionVisibility(false));
                        // }
                        if (leftPanelTab != "my-drive") {
                          TaskModalClose();
                          localStorage.setItem("leftPanelTab", "my-drive");
                          dispatch(leftPanelFilters("", "", "all"));
                          dispatch(setSelectedTabLeftPanel("my-drive"));
                          navigate("/my-drive");
                          hideRightPanelNew();
                          showLeftPanelNew();
                          dispatch(commanoffcanvasisOpen(false));
                          dispatch(setDaterangeFilter("all"))
                          dispatch(setDaterangeFilterApplied(true));
                        }
      
                      }}
                    >
                      <div className="comman-round-box  title-fonts d-flex flex-column justify-content-center align-items-center square_box h-100">
                        <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                          <div className="action_icon h30w30">
                            <HardDrives size={28} weight="light" className="c-icons" />
                          </div>
                        </div>
                        <span className=" color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">{_l("Drive")}</span>
                      </div>
                    </a>
                  </div>
                ) : (
                  <></>
                )}
              <div className={`office-box spaceicon p-0  ${
                        leftPanelTab == "my-calendar" ? "active" : ""
                      }`}>
                <a
                  href="#/"
                  className={`h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle  comman-round-box `}
                  onClick={() => {
                    if (leftPanelTab != "my-calendar") {
                      TaskModalClose();
                      localStorage.setItem("leftPanelTab", "my-calendar");;
                      dispatch(setSelectedTabLeftPanel("my-calendar"));
                      navigate("/");
                      hideRightPanelNew();
                      showLeftPanelNew();
                      dispatch(commanoffcanvasisOpen(false));
                      dispatch(setSelectedGloablView("calendarView"));
                      dispatch(dayGridDay(false));
                      dispatch(dayGridWeek(false));
                      dispatch(dayGridMonth(true));
                      dispatch(setDaterangeFilterApplied(true));
                      dispatch(leftPanelFilters("","","applyForMeAndMonth"));
                      localStorage.setItem("fromDate",formateDate(moment(new Date()).startOf('month')));
                      localStorage.setItem("toDate", formateDate(moment(new Date()).endOf('month')));

                    }
  
                  }}
                >
                  <div className="comman-round-box  title-fonts d-flex flex-column justify-content-center align-items-center square_box h-100">
                    <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                      <div className="action_icon h30w30">
                        <CalendarBlank size={28} weight="light" className="c-icons" />
                      </div>
                    </div>
                    <span className=" color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">{_l("l_calendar")}</span>
                  </div>
                </a>
              </div>
                {userType != "staff" ? (
                  <div className={`office-box spaceicon p-0  ${
                        leftPanelTab == "support" ? "active" : ""
                      }`}>
                    <a
                      href="#/"
                      className={`h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle  comman-round-box  `}
                      onClick={() => {
                        localStorage.setItem("leftPanelTab", "support");
                        dispatch(leftPanelFilters("", "", "all"));
                        dispatch(setSelectedTabLeftPanel("support"));
                        TaskModalClose();
                        dispatch(commanoffcanvasisOpen(false));
                        navigate(`/aichat?proposal_id=${0}`);
                      }}
                    >
                      <div className="comman-round-box  title-fonts d-flex flex-column justify-content-center align-items-center square_box h-100">
                        <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                          <div className="action_icon h30w30 position-relative">
                            <Headset size={28} weight="light" className="c-icons" />
                            {
                              supportChatCounts > 0 &&
                              <div className="badge-custom badge-green d-flex justify-content-center rounded-circle">
                                <span className={`m-auto ps-0`}>
                                  {supportChatCounts}
                                </span>
                              </div>
                            }
                          </div>
                        </div>
                        <span className=" color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">{_l("l_intro_header_support")}</span>
                      </div>
                    </a>
                  </div>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
                </div>
                <div className="position-relative left-option-box mt-0 d-flex new_user_profile_bottom ms-0 mb-0 office-box">
                  <div
                    className={`position-relative d-flex ${["my-spaces", "my-clients"].includes(leftPanelTab) ? "" : ""}`}
                  >
                    <a
                      href="#/"
                      className="d-flex  align-items-center border-transparent justify-content-center radius_3 flex-column hovertexgreen h70w70 "
                      onClick={() => {}}
                    >
                      <div className="comman-bg-img w-100 d-flex on-hover-active-toggle-img">
                        <UserProfileDropdown
                          selectLiteVersion={() => {}}
                          setSelectedProject={() => {}}
                          leftPanelTab={leftPanelTab}
                        />
                      </div>
                    </a>
                  { ["my-spaces", "my-clients"].includes(leftPanelTab)
                  ? <></>
                  :  <div class="flex-grow-1  d-flex radius_3 title-fonts color-white-80 ml-10 left-toggle-none cursor-pointer w100minus10">
                      <div class="d-flex w-85  align-items-center">
                        <div class="d-flex align-items-center justify-content-between w100minus100 flex-grow-1 pe-1">
                          <div class="comman-list-wrapper-main flex-grow-1 left-toggle-none w100minus120">
                            <div class="list-left-part text-truncate">
                              <a
                                href="#/"
                                class="text-truncate fw-semibold c-font f-14 title-fonts w-100 lh-1"
                              >
                                {localStorage.getItem("full_name")}
                              </a>
                              <div
                                href="#/"
                                class="text-truncate c-font f-10 w-100 color-white-80 lh-1"
                              >
                                My profile
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fixed-left-panel provider-list">
          <div className="fixed-left-panel-wrapper h-100 d-flex flex-column">
            <div className="upper-part">
              <div className="company-logo-box position-relative d-flex flex-column justify-content-center active h60px">
                <div className="d-flex align-items-center justify-content-between h60px">
                  <div className="d-flex align-items-center justify-content-between w100minus100 flex-grow-1 pe-1 h60px">
                    <div className="comman-list-wrapper-main flex-grow-1 left-toggle-none w100minus120 h60px d-flex align-items-center">
                      <div className="list-left-part text-truncate d-flex align-items-center w-100">
                        {contactShow ?
                          <a
                            href="#/"
                            className="me-1"
                            onClick={() => {
                              setContactShow(false);
                              setselectedSpace({});
                            }}
                          >
                            <CaretLeft size={16} weight="light" className="c-icons" />
                          </a>
                          : <></>}
                        <a
                          href="#/"
                          className="text-truncate fw-semibold c-font f-18 text-uppercase title-fonts lh-1 w-100"
                        >
                          {contactShow ?
                            <div
                                href="#/"
                                className={`position-relative task-sub-list w-100 py-2 `}
                              >
                              <div className="d-flex align-items-center w-100">
                                <a className="accordian-left-title w-100 on-hover-active-toggle-img text-on-hover-active-green d-flex position-relative overflow-visible hovertextgreen">
                                  <div className="d-flex align-items-center w100minus100per comman_action_icon">
                                    <div className="w-100">
                                      <div className="d-flex justify-content-between w-100 align-items-center min-h-32px">
                                        <div className="title-font text-truncate color-white-60 left_panel_toggle categorytitle w100minus100per   c-font f-18 text-uppercase">
                                          {selectedSpace.office_title}
                                        </div>
                                        {selectedSpace.unread_notification_count > 0 ? (
                                          <div className="with-bg h18w18 justify-content-center position-static my-auto  badge-green rounded-circle d-flex right-accordian-header-pill">
                                            <span className={`m-auto ps-0`}>
                                              {selectedSpace.unread_notification_count}
                                            </span>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      <div class="d-flex justify-content-between w-100 align-items-center color-white-60">
                                        <div class="c-font f-10 text-truncate pe-2 w100minus100per">
                                          {selectedSpace.postal_address}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          :
                          ["proposal-list", , "my-documents", "support"].includes(leftPanelTab) ?
                            <img className="h20w20 w-auto" src={BlazlogoText} alt="" />
                            : leftPanelTab == "proposal-list"
                            ? "Mes offres"
                            : leftPanelTab == "my-clients"
                            ? _l("l_clients")
                            : leftPanelTab == "my-spaces"
                            ? _l("l_my_space")
                            : leftPanelTab == "my-task"
                            ?  _l("l_my_task")
                            : leftPanelTab == "my-request"
                            ?  _l("l_my_task") 
                            : leftPanelTab == "my-drive"
                            ?  _l("l_drive") 
                            : leftPanelTab == "my-staff"
                            ?  _l("l_staff") 
                            :leftPanelTab == "my-calendar"
                            ?  _l("l_calendar") 
                            : ""}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
               ["my-request", "my-calendar"].includes(leftPanelTab) ? 
                <div className="kpi-main-wrapper shortcut-wrapper p-0 with-top-border">
                    <div className="d-flex align-items-center justify-content-between p-10 pb-0">
                      {defaultTaskStatus.map((status, index) => {
                        var statusTitle =
                          status == 1
                            ? _l("l_todo_kpi_name")
                            : status == 4
                            ? _l("l_ongoing_kpi")
                            : status == 5
                            ? _l("l_done_kpi")
                            : _l("l_request");
                        var statusColor =
                          status == 1
                            ? "color-white-80"
                            : status == 4
                            ? "color-blue"
                            : status == 5
                            ? "color-green"
                            : "color-orange";
                        var taskCount = 0;
                        var requestTaskCount = 0;
                        if (projectTaskCount) {
                          taskCount =
                            status == 1
                              ? projectTaskCount.todo_count
                                ? projectTaskCount.todo_count
                                : 0
                              : status == 4
                              ? projectTaskCount.ongoing_count
                                ? projectTaskCount.ongoing_count
                                : 0
                              : status == 5
                              ? projectTaskCount.completed_count
                                ? projectTaskCount.completed_count
                                : 0
                              : projectTaskCount.request_count
                              ? projectTaskCount.request_count
                              : 0;
                          requestTaskCount =
                            status == 1
                              ? projectTaskCount.todo_request_count
                                ? projectTaskCount.todo_request_count
                                : 0
                              : status == 4
                              ? projectTaskCount.ongoing_request_count
                                ? projectTaskCount.ongoing_request_count
                                : 0
                              : status == 5
                              ? projectTaskCount.completed_count
                              : projectTaskCount.request_count;
                        }

                        return (
                          <a
                            href="#/"
                            id={`kpi_task_status_${status}`}
                            className={`kpi-box bg-white-05 w_25per ${
                              statusTitle == "Request" && isLeadPage == 1
                                ? "d-none"
                                : ""
                            } ${
                              filterTaskActive.indexOf(status) > -1
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              filterTasksByStatus(status);
                              dispatch(
                                projectTaskCounts(
                                  projectId,
                                  customerData && customerData.taskFilters
                                    ? customerData.taskFilters
                                    : [],
                                  "",
                                  isLeadPage,
                                  pageName,
                                  globalView
                                )
                              );
                              if (pageName == "Chat") {
                                dispatch(setOpenGeneralChatGroup(false));
                                navigate("/");
                              }
                            }}
                            key={index}
                          >
                            <div className="kpi-upper-box">
                              <div className="d-flex align-items-center justify-content-center">
                                <div className="kpi-upper-box-text">
                                  <span className={statusColor}>
                                    {statusTitle}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className={`w-10 text-center`}>
                                <div className="kpi-statatics">{taskCount} </div>
                                <div
                                  className={`kpi-tag-box d-none ${
                                    isLeadPage ||
                                    [
                                      "Dashboard",
                                      "calendar",
                                      "dashboard",
                                    ].includes(pageName)
                                      ? "d-none"
                                      : "border-right"
                                  }`}
                                >
                                  {_l("l_task")}
                                </div>
                              </div>
                              <div
                                className={` w-50 text-center kpi-second-box d-none ${
                                  isLeadPage ||
                                  ["Dashboard", "calendar", "dashboard"].includes(
                                    pageName
                                  )
                                    ? "d-none"
                                    : ""
                                }`}
                              >
                                <div className="kpi-statatics">
                                  {requestTaskCount}{" "}
                                </div>
                                <div className="kpi-tag-box text-warning text-truncate">
                                  {_l("l_req")}
                                </div>
                              </div>
                            </div>
                          </a>
                        );
                      })}
                    </div>
                  </div> 
                  :
                  <React.Fragment></React.Fragment>
            }
              {/* Search Input start */}
              {
                leftPanelTab != "my-drive" ?  
               <div className="z-index-15 position-sticky top-0 start-0 end-0 searchInputLeft mb-10 me-10px ms-10px mt-10">
               <div
                          className="  c-input-box w-100 align-items-center d-flex ps-10px bgleftpanelnew radius_5 leftpanelsearch"
                        >
                          <MagnifyingGlass
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          <Autocomplete
                            mainWidth="w-100"
                            inputClass="form-control border-0 bg-transparent office-list-icon-search-input d-flex align-items-center h-100 w-100 ps-2"
                            dropdownClass="max-height-20vh"
                            placeholder={_l("l_search")}
                            options={
                              searchString.length &&
                              searchString.trim().charAt(0) === "@"
                                ? mentionStaffArray
                                : []
                            }
                            value={searchString}
                            onChange={(e) => setSearchString(e.target.value)}
                            onSelect={applyStaffUserFilter}
                            onFocus={() => {
                              // setSearchBarFocus(true);
                              if (
                                userType === "staff" &&
                                isSolarHubPlatform()
                              ) {
                              } else {
                                getMentionUsersForFilter();
                              }
                              setShowBackDropClass(true);
                              setSpaceSearchOnGoing(true);
                            }}
                            filterFunctionForOptionString={(str) =>
                              str.replace("@", "")
                            }
                            actualWidth={true}
                            isCallChatEnable={true}
                           
                          />
                        </div>
                        </div>
                : <></>
                }
              {/* Search Input over */}

            <ul className="office-list-main with-padding mt-0 pt-2">
              {
              ["my-request", "my-staff", 'my-calendar'].includes(leftPanelTab) ? 
                <TwoStepAccordians
                  setCompletedTasksCount={setCompletedTasks}
                  setInProgressTasksCount={setInProgressTasksCount}
                  setTodoTasksCount={setTodoTasksCount}
                  projectId={projectId}
                  searchCategory={searchCategory}
                  pageName={pageName}
                  teamLeftPanel={teamLeftPanel}
                  setSelectOperators={setSelectOperators}
                  setShowScheduleDropDown={setShowScheduleDropDown}
                  setShowProviderDropDown={setShowProviderDropDown}
                  allMAilMessages={allMAilMessages}
                  allImportantMessages={allImportantMessages}
                  hideMailSyncBtn={hideMailSyncBtn}
                  createTeamModal={createTeamModal}
                  setCreateTeamModal={setCreateTeamModal}
                  equipmentModal={equipmentModal}
                  setEquipmentModal={setEquipmentModal}
                  selectAdminTeam={(team, is_remove) =>
                    selectAdminTeam(team, is_remove)
                  }
                  selectedTeam={selectedTeam}
                  clientTeamData={clientTeamData}
                  handleDeleteTeam={handleDeleteTeam}
                  handleFavoriteAdminTeam={handleFavoriteAdminTeam}
                  handleEditAdminTeam={handleEditAdminTeam}
                  topicType={topicType}
                  setTopicType={setTopicType}
                  selectedTeamForEdit={selectedTeamForEdit}
                  setSelectedTeamForEdit={setSelectedTeamForEdit}
                  showAddTeamMember={showAddTeamMember}
                  setshowAddTeamMember={setshowAddTeamMember}
                  adminTeamList={adminTeamList}
                  setAdminTeamList={setAdminTeamList}
                  showUserList={showUserList}
                  searchCategoty={searchCategoty}
                  searchTopic={searchTopic || searchString}
                  showForMeSection={showForMeSection}
                  setShowForMeSection={setShowForMeSection}
                /> : <></>
                }

              {["my-spaces", "my-clients"].includes(leftPanelTab) && userType != "staff" && contactShow ? (
                <>
                    <React.Fragment>
                      {groupListExternal.filter((gd)=> gd.groupName.toLowerCase().includes(searchString.toLowerCase())).map((gd) => {
                        return (
                          <React.Fragment>
                            <div
                              className={`position-relative task-sub-list w-100 py-2 ps-10px pe-10px ${
                                gd.groupId == activeGroupId ? "active" : ""
                              }`}
                              onClick={() => {
                                createChatChannelforSolarCustomer(gd);
                                navigate("/");
                              }}
                            >
                              <div className="d-flex align-items-center w-100">
                                <span></span>
                                <a className="accordian-left-title w-100 on-hover-active-toggle-img text-on-hover-active-green d-flex position-relative overflow-visible hovertextgreen">
                                  <div className="d-flex align-items-center w100minus100per comman_action_icon">
                                    <div className="d-flex align-items-center justify-content-center ic-provider-list office-list-circle rounded-circle comman-round-box bg-white-05">
                                      <div className="comman-round-box d-flex flex-column h45w45 rounded-circle">
                                        <div
                                          className="comman-bg-img w-100 h-100 bg-style-cover rounded-circle"
                                          style={{
                                            backgroundImage: `url('${
                                              gd.displayPicture != ""
                                                ? gd.displayPicture
                                                : "https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg"
                                            }')`,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                    <div className="ps-2 w-100">
                                      <div className="d-flex justify-content-between w-100 align-items-center min-h-32px">
                                        <div className="title-font text-truncate color-white-60 left_panel_toggle categorytitle w100minus100per   c-font f-16">
                                          {gd.groupName}
                                        </div>
                                        {gd.unreadCount > 0 ? (
                                          <div className="with-bg h18w18 justify-content-center position-static my-auto  badge-green rounded-circle d-flex right-accordian-header-pill">
                                            <span className={`m-auto ps-0`}>
                                              {gd.unreadCount}
                                            </span>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </div>

                                      {gd.lastMessage &&
                                      gd.lastMessage != "" ? (
                                        <div class="d-flex justify-content-between w-100 align-items-center color-white-60">
                                          <div class="c-font f-10 text-truncate pe-2 w100minus100per">
                                            {gd &&
                                            gd.lastMsgUserId != chatUserId ? (
                                              <b
                                                style={{ fontWeight: "600" }}
                                              >{`${ReactHtmlParser(
                                                gd.lastMsgUserName.split(" ")[0]
                                              )} :`}</b>
                                            ) : (
                                              <></>
                                            )}
                                            {ReactHtmlParser(gd.lastMessage)}
                                          </div>
                                          <div class="c-font p-1 f-10 badge badge-opacity-15 text-truncate badge-white m-w100px rounded-pill fw-light ms-1 ">
                                            <span class="text-truncate ps-0">
                                              {moment(
                                                gd.lastMessageDate
                                              ).format("L") ==
                                              moment(new Date()).format("L")
                                                ? moment(
                                                    gd.lastMessageDate
                                                  ).format("hh:mm A")
                                                : moment(
                                                    gd.lastMessageDate
                                                  ).format("DD/MM/YYYY")}
                                            </span>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                </>
              ) : <React.Fragment>
              </React.Fragment> }
              {leftPanelTab == "my-clients" && userType == "staff" ? (
                <React.Fragment>
                  { contactDirLoader
                    ? <ClientDataSkeleton />
                    :
                  groupListExternal && groupListExternal.length > 0 ? (
                    groupListExternal
                      .filter((ud) => {
                        return (
                          (ud &&
                            ud.extraData &&
                            ud.extraData.companyName &&
                            ud.extraData.companyName
                              .toLowerCase()
                              .includes(searchString.toLowerCase())) ||
                          ud.groupName
                            .toLowerCase()
                            .includes(searchString.toLowerCase()) ||
                          (ud.extraData &&
                            ud.extraData.companyName &&
                            ud.extraData.companyName
                              .toLowerCase()
                              .includes(searchString.toLowerCase())) ||
                          (ud.clientId
                            .toString()
                            .includes(searchString.toLowerCase()) &&
                            ud.groupId != activeGroupId)
                        );
                      })
                      .sort((a, b) => {
                        const chatA = moment(
                          a.lastMessageDate &&
                            a.lastMessage &&
                            a.lastMessage != ""
                            ? a.lastMessageDate
                            : ""
                        );
                        const chatB = moment(
                          b.lastMessageDate &&
                            b.lastMessage &&
                            b.lastMessage != ""
                            ? b.lastMessageDate
                            : ""
                        );

                        if (!a.lastMessage || a.lastMessage === "") return 1;
                        if (!b.lastMessage || b.lastMessage === "") return -1;
                        if (chatA.isBefore(chatB)) return 1;
                        if (chatA.isAfter(chatB)) return -1;
                      })
                      .slice(
                        0,
                        activeOfficePage > 1
                          ? (activeOfficePage - 1) *
                              SPACE_LIST_RESULTS_PER_PAGE +
                              10
                          : activeOfficePage * SPACE_LIST_RESULTS_PER_PAGE
                      )
                      .map((user) => {
                        return (
                          <>
                            <div
                              className={`d-flex align-items-center comman_action_icon office-box ${
                                user.groupId === activeGroupId ? "active" : ""
                              }`}
                            >
                              <div className="d-flex align-items-center">
                                <div className={`h40w40 d-flex action_icon with_bg rounded-5 position-relative  comman-image-box rounded-circle 
                                  ${user.extraData.mainSubKey == "contact" ? "blueborder" : "greenborder"}`}>
                                  <div
                                    className="comman-bg-img w-100 h-10 bg-style-cover rounded-circle"
                                    style={{
                                      backgroundImage: `url('${
                                        user.displayPicture != ""
                                          ? user.displayPicture
                                          : "https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg"
                                      }')`,
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <a
                                href="#/"
                                className={`office-list-detail w100minus100per ps-3 left-toggle-none ${
                                  user.lastMessage == ""
                                    ? "d-flex align-items-center"
                                    : ""
                                }`}
                              >
                                <div className="office-list-desc-left w-100">
                                  <div className="align-items-center d-flex justify-content-between">
                                    <a
                                      className="d-flex align-items-center text-truncate justify-content-between w-100"
                                      onClick={() => {
                                        handleUserForSolarStaff(user);
                                        dispatch(setSelectedGloablView("AichatView"));
                                      }}
                                    >
                                      <div className="text-truncate max-width-160px  pe-2 c-font f-14 title-font">
                                        {user.groupName}
                                        <span> #{user.clientId}</span>
                                      </div>
                                      {user.unreadCount > 0 &&  
                                        <div className="badge-custom badge-green d-flex justify-content-center position-static rounded-circle">
                                          <span className={`m-auto ps-0`}>
                                            {user.unreadCount}
                                          </span>
                                        </div>
                                      }
                                    </a>
                                    <div className="d-flex align-items-center justify-content-end dropstart dropdown-static-display">
                                      <div
                                        className={` comman_action_icon with_margin_minus_10px dropstart ${
                                          contact_role == 3 ? "d-none" : ""
                                        }`}
                                      >
                                        <a
                                          href="#/"
                                          className="c-three-dots office-list-static action_icon h25w25 on-hover-active-toggle-img left-toggle-none  "
                                          id="ActiveOfficeOptionsDropdown"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          data-bs-auto-close="outside"
                                          onClick={() => {
                                            // HideDropdown();
                                            jquery(this)
                                              .siblings(".dropdown-menu")
                                              .addClass("show");
                                            setUserList([]);
                                          }}
                                        >
                                          <DotsThreeVertical
                                            size={16}
                                            weight="light"
                                            className="c-icons"
                                          />
                                        </a>
                                        <ActionDropdown
                                          widthClass="w-170"
                                          actions={[
                                            {
                                              actionName: _l(
                                                "l_login_as_user_solar"
                                              ),
                                              actionHandler: () => {
                                                loginHandlerforsolarStaff(user);
                                              },
                                              actionIcon: "UserIcon",
                                            },
                                          ]}
                                          list={
                                            showProjectStatusList
                                              ? projectStatusArray
                                              : userList.map((item, index) => {
                                                  return {
                                                    name:
                                                      item.fullname ||
                                                      item.name,
                                                    image:
                                                      item.profile_image_url,
                                                    email: item.email,
                                                    type: item.type
                                                      ? item.type
                                                      : "",
                                                    user_type: item.user_type
                                                      ? item.user_type
                                                      : "",
                                                    is_primary: item.is_primary
                                                      ? item.is_primary
                                                      : "",
                                                    is_boss_operator:
                                                      item.is_boss_operator
                                                        ? item.is_boss_operator
                                                        : "",
                                                    client_id: item.userid,
                                                    contact_role:
                                                      item.contact_role,
                                                    company: item.company,
                                                    id:
                                                      item.user_type ==
                                                      "Provider"
                                                        ? item.staff_id
                                                        : item.contact_id,
                                                    staff_id: item.staff_id,
                                                    boss_operator_id:
                                                      item.boss_operator_id,
                                                    session_details:
                                                      item.session_details,
                                                    last_login: item.last_login,
                                                    no_of_comments:
                                                      item.no_of_comments,
                                                    task_score: item.task_score,
                                                    contact_id: item.contact_id,
                                                  };
                                                })
                                          }
                                          isLoginAsUserDataLoaded={
                                            isLoginAsUserDataLoaded
                                          }
                                          loder={loder}
                                          unControlList={false}
                                          setLoder={setLoder}
                                          handler={(actionItem) => {
                                            loginHandler(
                                              actionItem.email,
                                              actionItem.client_id,
                                              actionItem.type,
                                              actionItem.id,
                                              0,
                                              actionItem
                                            );
                                          }}
                                          selectedspaceStatus={
                                            selectedspaceStatus
                                          }
                                          setSelectedspaceStatus={
                                            setSelectedspaceStatus
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {user.extraData.mainSubKey == "contact" ? (
                                    <></>
                                  ) : (
                                    <div className="c-font f-10 text-truncate">
                                      {user.extraData.companyName
                                        ? user.extraData.companyName
                                        : " "}
                                      {/* <span> #{user.clientId}</span>   */}
                                    </div>
                                  )}
                                  <div className="c-font f-10 text-truncate"></div>

                                  <div
                                    className="d-flex align-items-center color-white-60"
                                    onClick={() => {
                                      handleUserForSolarStaff(user);
                                    }}
                                  >
                                    {user.lastMessage != "" ? (
                                      <>
                                        {" "}
                                        <div
                                          className="c-font f-10 text-truncate w100minus85 pe-2"
                                          data-tip={"user"}
                                          data-for={randomID}
                                          data-effect="solid"
                                          data-delay-show="1000"
                                          data-class="tooltip-main"
                                          data-place="top"
                                        >
                                          <>{user.lastMsgUserName}</>
                                          {": " +
                                            ReactHtmlParser(user.lastMessage)}
                                        </div>
                                        <div className="badge badge-white rounded-pill badge text-truncate badge-white m-w100px rounded-pill fw-light ms-1 me-1 py-1 px-2">
                                          <span className={`ps-0 text-truncate`}>
                                            {moment(
                                              user.lastMessageDate
                                            ).format("DD/MM/YYYY")}
                                          </span>
                                          <ReactTooltip />
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </a>
                            </div>
                          </>
                        );
                      })
                  ) : (
                    <CommanPlaceholder imgType="no-userfound" placeholderText = {_l("l_no_space")} />
                  )}
                  {groupListExternal.filter((ud) => {
                    if (searchString !== "" && ud.groupId != activeGroupId) {
                      return (
                        (ud &&
                          ud.extraData &&
                          ud.extraData.companyName &&
                          ud.extraData.companyName
                            .toLowerCase()
                            .includes(searchString.toLowerCase())) ||
                        ud.groupName
                          .toLowerCase()
                          .includes(searchString.toLowerCase()) ||
                        (ud.extraData &&
                          ud.extraData.companyName &&
                          ud.extraData.companyName
                            .toLowerCase()
                            .includes(searchString.toLowerCase())) ||
                        (ud.clientId
                          .toString()
                          .includes(searchString.toLowerCase()) &&
                          ud.groupId != activeGroupId)
                      );
                    } else if (ud.groupId != activeGroupId) {
                      return ud;
                    }
                  }).length > 10 &&
                  groupListExternal.length >=
                    (activeOfficePage - 1) * 10 + 10 &&
                  groupListExternal.length !=
                    (activeOfficePage - 1) * 10 + 10 ? (
                    <li
                      className="office-box left-view-more-btn p-0"
                      onClick={() => {
                        setActiveOfficePage(activeOfficePage + 1);
                      }}
                    >
                      <div className="btn btnyellow w-100 mb-10px">
                        <div className="d-flex align-item-center justify-content-center">
                          <div className="m-auto d-flex align-item-center">
                          <span className=" title-fonts c-font f-12 ">
                            {_l("l_view_more_btn")} (
                            {activeOfficePage > 1
                              ? groupListExternal.length -
                                ((activeOfficePage - 1) * 10 + 10)
                              : groupListExternal.length - 10}
                            )
                          </span>
                          <CaretDoubleDown
                            size={12}
                            className="c-icons ms-2"
                            weight="light"
                          />
                          </div>
                        </div>
                      </div>
                    </li>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              {leftPanelTab == "my-drive" ?(
                <div className="fixed-left-panel-wrapper h-100 d-flex flex-column">
                  <div className="d-flex flex-column overflow-auto">
                    <div className="mt-0 office-list-main spacelistingmain">
                      <MyDrive
                        data={{main_key:"documents", id: 1}}
                      />
                    </div> 
                  </div>
                </div>
              ) :["my-spaces", "my-clients"].includes(leftPanelTab) && userType !== "staff" && !contactShow && spaces && spaces.length > 0 ? (
                <React.Fragment>
                  {spaces &&
                    spaces.length > 0 &&
                    spaces.map((space, index) => {
                      return (
                        <React.Fragment>
                          <li
                            className={`position-relative task-sub-list w-100 p-10  d-flex align-items-center justify-content-between rounded-0 ${
                              space.project_id ==
                              localStorage.getItem("selectedOffice")
                                ? "active"
                                : ""
                            }`}
                          >
                              
                              <a
                              onClick={() => {
                                if (["my-clients", "my-spaces"].includes(leftPanelTab)) {
                                  dispatch(updateExternalGroupListMessages([]))
                                  // setContactShow(true);
                                  setselectedSpace(space)
                                  handleCreateChatGroup(space.project_id, "providers", "providers", "1");
                                  localStorage.setItem(
                                    "selectedOffice",
                                    space.project_id
                                  );
                                  dispatch(setSpaceNotificationCount(space.project_id, 1, 0, "chat"));
                                }
                              }}
                                href="#/" className="accordian-left-title w100minus100per on-hover-active-toggle-img text-on-hover-active-green d-flex position-relative overflow-visible hovertextgreen comman_action_icon align-items-center">
                                <div className="h50w50 d-flex align-items-center justify-content-center ic-provider-list office-list-circle radius_3 comman-round-box bg-white-05">
                                  <div className="h45w45 comman-round-box radius_3 title-fonts d-flex flex-column">
                                    <span className="m-auto c-font f-13 office-subtitle text-truncate hover-span p-0">
                                      <Buildings
                                        size={18}
                                        weight="light"
                                        className="c-icons"
                                      />
                                    </span>
                                    <span className="color-white-80 d-block c-font f-9 pt-0 pb-1 office-subtitle fw-light line-height-1 px-1 text-truncate text-center w-100">{space.office_title}</span>
                                  </div>
                                </div>
                                <div className="text-truncate w100minus100per ps10px">
                                    <div className="d-flex title-font text-truncate left_panel_toggle categorytitle accordion-bodyc-font f-14 justify-content-between ">
                                      <div className="text-truncate">
                                        {userType == "operator" ? space.company : space.office_title}
                                      </div>
                                    </div>
                                    <div class="title-font text-truncate c-font f-10 left_panel_toggle">
                                      {space.postal_address}
                                    </div>
                                </div>
                              </a>
                              {
                                space.chat_notification_count > 0
                                &&
                              <div className="d-flex justify-content-end ps-10px align-items-center">
                                <div className="badge-custom badge-green d-flex justify-content-center position-static rounded-circle">
                                  <span className={`m-auto ps-0`}>
                                    {space.chat_notification_count}
                                  </span>
                                </div>
                                <a href="#/" style={{display: 'none'}}
                                  className="with_separator_10 justify-content-end on-hover-active-toggle-img">
                                  <Phone size={18} className="c-icons" />
                                </a>
                                <a href="#/" style={{display: 'none'}}
                                  className="with_separator_10 pe-0 justify-content-end on-hover-active-toggle-img">
                                  <GearSix size={18} className="c-icons" />
                                </a>
                              </div>
                              }
                          </li>
                        </React.Fragment>
                      );
                    })}
                </React.Fragment>
              ) : !contactShow && userType !== "staff" &&  !["my-request", "my-calendar"].includes(leftPanelTab) ? (
                <React.Fragment>
                  <div>
                    <CommanPlaceholder  imgType="no-userfound" placeholderText = {_l("l_no_space")} />
                  </div>
                </React.Fragment>
              ) : <React.Fragment></React.Fragment>
              }
              {/* {leftPanelTab == "my-clients" ? <>
              {
                spaces && spaces.length && spaces.map((space, index)=>{
                  return(
                    <>
                      <li className='office-box'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <a href="#/" className='office-list-left flex-grow-1 w-100'>
                            <div className='d-flex align-items-center'>
                              <div className='h50w50 d-flex align-items-center justify-content-center ic-provider-list office-list-circle radius_3'>
                                <div className=' h45w45 comman-round-box d-flex square_box'>
                                  <div className='m-auto'> <Gauge size={24} weight="light" className="c-icons" /></div>
                                </div>
                              </div>
                              <div className='office-list-detail w100minus50 left-toggle-none'>
                                <div className='office-list-desc-left w-100'>
                                  <div className='align-items-center d-flex justify-content-between'>
                                    <div className="d-flex align-items-center text-truncate justify-content-between w-100">
                                      <div className="text-truncate max-width-160px  pe-2 c-font f-12">
                                        Space 3
                                        <span>#287</span>
                                      </div>
                                      <div>
                                        <div className='badge badge-white c-font f-10 fw-semibold m-w100px mx-1 p-1 rounded-pill text-truncate '>
                                          <span className=' color-blue'>contact</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-end dropstart'>
                                      <div className='d-flex align-items-center comman_action_icon'>
                                        <div className=' action_icon h25w25 '>
                                          <DotsThreeVertical size={16} weight="light" className="c-icons" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='d-flex align-items-center color-white-60'>
                                    <div className='c-font f-10 text-truncate w100minus85 pe-2'>
                                      <span className='me-1'>For</span>
                                      <span>Helloo</span>
                                    </div>
                                    <div className='badge badge-white rounded-pill badge text-truncate badge-white m-w100px rounded-pill fw-light ms-1 me-1 py-1 px-2'>
                                      <span className='text-truncate'>30-05-2024</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </li> 
                    </>
                  )
                })
              }

              </>
              : <></>} */}
              {/* space lsiting end */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftPanelSubmenu;
