import React, { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import LogoGreenDark from "../../assets/icons/LogoGreenDark";
import LogoGreenLight from "../../assets/icons/LogoGreenLight";
import EyeIcon from "../../assets/icons/EyeIcon";
import EyeSlashIcon from "../../assets/icons/EyeSlashIcon";
import { useNavigate } from "react-router-dom";
import { _l, isSolarHubPlatform } from "../../hooks/utilities";
import { ArrowSquareOut } from "phosphor-react";
import { setSelectedLanguage } from "../../actions/customer";
import { useDispatch, useSelector } from "react-redux";
import jquery from "jquery";
import LogoSolarHub from "../../assets/images/blazlogoblack.svg"

const LeftSideComponent = ({ data ,type}) => {
  const navigate = useNavigate();
  const languageSelect = [
    { value: "english", label: _l('l_EN') },
    { value: "french", label: _l('l_FR') },
  ];
  const dispatch = useDispatch();
  const [language , setlanguage] = useState(localStorage.getItem("language"))

  const setLanguage = (language) => {
    dispatch(setSelectedLanguage(language));
    localStorage.setItem("language",language)    
  }

  const {themeColor} = useSelector((state) => state.customer)
  const [LightTheme , setLightTheme] = useState(localStorage.getItem('selectedTheme') == "light" ? true : false);
  useEffect(() => {
    if(!isSolarHubPlatform())
    {
      if (themeColor == "light") {
        jquery("html").addClass("light-theme")
        setLightTheme(true);
      }else{
        jquery("html").removeClass("light-theme")
        setLightTheme(false);
      }
    }
  }, [themeColor])
  return (
    <div className="row flex-wrap flex-lg-nowrap Standalonemain">
    <div className="col-lg-5  stand-alone-left-main">
      <div className="d-flex flex-column h-100">
        <div className="stand-alone-top h-100">
          {/* register-btn below */}
          <div className="d-flex align-items-center justify-content-between  pt-0">
              <a href="#/" className="logo-img">
              {isSolarHubPlatform() ? (
              <>
                <img src={LogoSolarHub} alt="" />
              </>
            ) : (
              <>
                {LightTheme ? (
                  <LogoGreenLight className="header-logo-img " />
                ) : (
                  <LogoGreenDark className="header-logo-img" />
                )}
              </>
            )}
              {/* {LightTheme ? < LogoGreenLight className="standalonelogo "/> :  <LogoGreenDark  className="standalonelogo"/>} */}
              </a>
              <div className="d-flex align-items-center">
                <Form.Group className="me-2 c-input-box position-relative d-none">
                  <Select
                    className="custom-select-menu with-filter-select me-0 border-0 min-w-auto"
                    closeMenuOnSelect={true}
                    placeholder={`${_l(language ? language : "l_FR")}`}
                    classNamePrefix="react-select"
                    options={languageSelect}
                    value={languageSelect.filter(dd=> dd.value == language)}
                    onChange={(value)=>{setLanguage(value.value)
                    setlanguage(value.value)}}
                    // menuIsOpen={true}
                  />
                </Form.Group>
                <div
                  className=""
                  onClick={() => {
                    navigate("/login");
                  }}
                > 
                  <a href="#/" class="btn btn-white-03 ms-auto px-lg-4">{_l("l_login")}</a>
                </div>
              </div>
            </div>
          <div className="detail-title-part">
            <div className="text-capitalize c-font f-20 fw-semibold title-fonts title-text-part d-none">
              #{data.id} 
            </div>
            <div className="badge rounded-pill rounded-pill"><span className="color-white c-font f-12">{type}</span></div>
            <div className="c-font fs-1 fw-semibold title-fonts title-text-part ">
              {data.name}
            </div>
            <div className="task-list-card list-view title-fonts title-text-part p-15 mt-3 no-hover">
              <div className="d-flex align-items-center justify-content-between">
                <div className="color-white-60 mb-1">{_l("l_project")}</div>
                
              </div>
              <div className="c-font f-18 fw-semibold">{data && data.project_details ? data.project_details.name : ""}</div>
              {
                data.project_details && data.project_details.company ? 
                <>
                <div className="border-bottom my-2"></div>
                <div className="color-white-60 mb-1">{_l("l_client")}</div>
                <div className="c-font f-18 fw-semibold">{data.project_details ? data.project_details.company : ""}</div>
                </>
                :
                <></>
              }
            </div>
            <div className="subtitle-part color-white-60 d-none">
              In publishing and graphic design, Lorem ipsum is a placeholder
              text commonly
            </div>
          </div>
          {/*https://www.youtube.com/embed/vuGH4Lbz2Uk */}
          {type == "estimate" ? (
            <div className="d-none">
              <div className="iframe-part">
                <iframe className="radius_3 w-100 h-200px" src="https://www.youtube.com/embed/vuGH4Lbz2Uk" title="What is Myr.ai?" allow="autoplay" allowfullscreen></iframe>
              </div>
              {/* <div className="download-btn pt-3"><Button variant="secondary">{_l("l_download")}</Button></div> */}
              <div className="use_cases_listing p-15 px-0 border-bottom comman_action_icon">
                  <div className="title-fonts fw-semibold">{_l("l_use_cases")}</div>
              </div>
              <ol className="comman_action_icon use_cases_listing_ol ps-3 mb-0" type="1">
                <li className="">
                  <a href="https://myr.ai/en/usecases/etude-de-cas-nexity" className="align-items-center d-flex justify-content-between text-truncate py-2" target="_blank">
                    <div className="text-truncate  w100minus20">Nexity Business Case</div>
                    <div  className="">
                      <ArrowSquareOut size={16} className="c-icons"  />
                    </div>
                  </a>
                </li>
                
              </ol>
            </div>
            ) : <></>}
          <div className="login-section d-none">
            <div className="login_page">
              <div className="login_box">
                {/* <div className="login-logo">
                                    <LogoGreenBlack className="header-logo-img logo-light" />
                                    <img
                                    alt="logo"
                                    src={myrLogo}
                                    className="header-logo-img logo-dark"
                                    />
                                </div> */}
                <div className="comman_title_white">Login</div>
                <div className="custom_input_box_main">
                  <div className="custom_input_box  with_before">
                    <label className="input_box_label">
                      <div className="title_text color-white-60">
                        Email Address
                        <span className="star_img">*</span>
                      </div>
                    </label>
                    <input
                      type="text"
                      id="email"
                      className="input_box  autofill_off"
                      placeholder="email@gmail.com"
                    />
                  </div>
                  <div className="custom_input_box with_before">
                    <label className="input_box_label d-flex align-items-center justify-content-between">
                      <div className="title_text color-white-60">
                        Password
                        <span className="star_img">*</span>
                      </div>
                      <a href="#/" className="forger_link">Forgot Password ?</a>
                    </label>
                    <div className="active" id="password_class">
                      <div className="d-flex align-items-center justify-content-between">
                        <input
                          type="password"
                          className="input_box  autofill_off"
                          placeholder="*****"
                          autoComplete="new-password"
                        />
                        <a href="#/" className="">
                          <div
                            className="img-width-18 d-none"
                            data-bs-toggle="tooltip"
                            data-bs-title="Hide password"
                          >
                            <EyeSlashIcon className="HW18" />
                          </div>
                          <div
                            className="img-width-18"
                            data-bs-toggle="tooltip"
                            data-bs-title="Show password"
                          >
                            <EyeIcon className="HW18" />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="mtop20"></div>
                  <div className="login_btn d-flex align-items-center justify-content-start">
                    <button className="btn btn-primary me-4">Login</button>
                    <button className="btn btn-secondary">Reset</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="stand-alone-top standalonefooter">
          <div className="stand-alone-bottom">
            <div className="copy-right-text pt-0 opacity-60">
              <span>{_l("l_myr_copyright")}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default LeftSideComponent;
