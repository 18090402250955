import React, { useEffect, useMemo, useState } from "react";
import jquery from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../actions/messages";
import {
    Info,
    X,
    MagnifyingGlass,
    Funnel,
    CaretDoubleDown,
  } from "phosphor-react";
  import { isLoadingData } from "../hooks/loader_helper";
import CommanLoader from "./Loader/CommanLoader";
import { CardView } from "./Documents/CardView";
import { _l } from "../hooks/utilities";
import { getInvoiceDetail } from "../actions/documents-actions/documents-actions";
import DocumentsServices from "../services/documents-services";
import CommanOffCanvas from "./Offcanvas/CommanOffCanvas";
import { CLIENT_TAB_DATA, INVOICE_COUNTER, INV_HEADER_CAL_COUNTER } from "../actions/action-type";
import moment from "moment";
import CommanPlaceholder from "./Placeholder/CommanPlaceholder";
import { clientTabData, leftPanelFilters } from "../actions/customer";
const InvoiceList = ({pageName, clientid}) => {
    const [invoiceList, setInvoiceList] = useState([]);
    const [paidInvoices, setPaidInvoices] = useState([]);
    const [unpaidInvoices, setUnpaidInvoices] = useState([]);
    const [draftInvoices, setDraftInvoices] = useState([]);
    const [cancelledInvoices, setCancelledInvoices] = useState([]);
    const [invoiceListPage, setInvoiceListPage] = useState(1);
    const [paidInvoicePage, setPaidInvoicePage] = useState(1);
    const [unpaidInvoicePage, setUnpaidInvoicePage] = useState(1);
    const [draftInvoicePage, setDraftInvoicePage] = useState(1);
    const [cancelledInvoicePage, setCancelledInvoicePage] = useState(1);
    const [clientInvoices, setClientInvoices] = useState([]);
    const [clientInvoicePage, setClientInvoicePage] = useState(1);
    const [selectedTab, setSelectedTab] = useState("");
  const [show, setShow] = useState(false);
  const [filterDisplayHandle, setfilterDisplayHandle] = useState(false);
  const [docType, setDocType] = useState("");
  const [documentfilter, setdocumentfilter] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showReqestedTaskModal, setShowReqestedTaskModal] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [estimateList, setEstimateList] = useState([]);
  const [spaceDocumentList, setSpaceDocumentList] = useState([]);
  const [showDocumentCanvas, setShowDocumentCanvas] = useState(localStorage.getItem('clientTab') == 1 ? true : false);
  const [selectDocument, setSelectDocument] = useState("");
  const [documentCardDetails, setDocumentCardDetails] = useState();
  const [documentDetail, setDocumentDetail] = useState();
  const [estimateListPage, setEstimateListPage] = useState(1);
  const [spaceDocumentListPage, setspaceDocumentListPage] = useState(1);
  const [documentListPage, setDocumentListPage] = useState(1);
  const [subscriptionListPage, setSubscriptionListPage] = useState(1);
  const [creditNoteListPage, setCreditNoteListPage] = useState(1);
  const [selectedId, setSelectedId] = useState(0);
  const [lastFilterRemove, setLastFilterRemove] = useState(false);
  const [invoice, setInvoice] = useState([]);
  const [subscription, setSubscription] = useState([]);
  const [estimate, setEstimate] = useState([]);
  const [spaceDocument, setSpaceDocument] = useState([]);
  const [searchString, setSearchString] = useState("");
  const savedDate = localStorage.getItem("selectedDate");
  const google_auth_status = useSelector(
    (state) => state.google_oauth_reducer.google_oauth_status
  );
  const { selectedProject: projectId } = useSelector((state) => state.customer);
  const {
    taskFilters,
    isAppliedUnreadComment,
    activeTaskStatusFilters,
    taskUnreadCmtCntAll, filterBenchmarkCategory,globalSearchString
  } = useSelector((state) => state.customer);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const spaceClient = useSelector((state) => state.customer.spaceClient);
  const invoice_details = useSelector((state) => state.documentsReducer.invoice_details);
  const pathname = window.location.pathname;

 useEffect(()=>{
  if(spaceClient && spaceClient.id && pathname == '/invoice')
  {
    setShowDocumentCanvas(true);
    invoiceCardHandler(spaceClient);
    let data = [];
    dispatch(clientTabData(data));
  }
   if(invoice_details)
   {
     setDocumentDetail(invoice_details);
   }
 }, [spaceClient, invoice_details, pathname])

 setTimeout(() => {
  localStorage.setItem('clientTab', 0);
}, 15000)

  useEffect(() => {
    if (globalSearchString == undefined || globalSearchString == "") {
      setPaidInvoices(
        invoice && invoice.filter(
          (inv) => (inv.status != null && inv.status === "Paid" ? inv.status : "")
          .toLowerCase()
        )
      );

      setUnpaidInvoices(
        invoice && invoice.filter(
          (inv) => (inv.status != null && inv.status === "Unpaid" ? inv.status : "")
          .toLowerCase()
        )
      );

      setDraftInvoices(
        invoice && invoice.filter(
          (inv) => (inv.status != null && inv.status === "Draft" ? inv.status : "")
          .toLowerCase()
        )
      );

      setCancelledInvoices(
        invoice && invoice.filter(
          (inv) => (inv.status != null && inv.status === "Cancelled" ? inv.status : "")
          .toLowerCase()
        )
      );
    } else {
      if(globalSearchString)
      {
        setPaidInvoices(
          invoice &&
            invoice.filter(
              (inv) =>
                (inv.name.toLowerCase().includes(globalSearchString.toLowerCase()) ||
                (inv.date != null ? inv.date : "")
                  .toLowerCase()
                  .includes(globalSearchString.toLowerCase()) ||
                (inv.id != null ? inv.id : "")
                  .toLowerCase()
                  .includes(globalSearchString.toLowerCase())) &&
                (inv.status != null && inv.status === "Paid" ? inv.status : "")
                  .toLowerCase()
            )
        );
  
        setUnpaidInvoices(
          invoice &&
            invoice.filter(
              (inv) =>
                (inv.name.toLowerCase().includes(globalSearchString.toLowerCase()) ||
                (inv.date != null ? inv.date : "")
                  .toLowerCase()
                  .includes(globalSearchString.toLowerCase()) ||
                (inv.id != null ? inv.id : "")
                  .toLowerCase()
                  .includes(globalSearchString.toLowerCase())) &&
                (inv.status != null && inv.status === "Unpaid" ? inv.status : "")
                  .toLowerCase()
            )
        );
  
        setDraftInvoices(
          invoice &&
            invoice.filter(
              (inv) =>
                (inv.name.toLowerCase().includes(globalSearchString.toLowerCase()) ||
                (inv.date != null ? inv.date : "")
                  .toLowerCase()
                  .includes(globalSearchString.toLowerCase()) ||
                (inv.id != null ? inv.id : "")
                  .toLowerCase()
                  .includes(globalSearchString.toLowerCase())) &&
                (inv.status != null && inv.status === "Draft" ? inv.status : "")
                  .toLowerCase()
            )
        );
  
        setCancelledInvoices(
          invoice &&
            invoice.filter(
              (inv) =>
                (inv.name.toLowerCase().includes(globalSearchString.toLowerCase()) ||
                (inv.date != null ? inv.date : "")
                  .toLowerCase()
                  .includes(globalSearchString.toLowerCase()) ||
                (inv.id != null ? inv.id : "")
                  .toLowerCase()
                  .includes(globalSearchString.toLowerCase())) &&
                (inv.status != null && inv.status === "Cancelled" ? inv.status : "")
                  .toLowerCase()
            )
        );
      }
    }
    var counter = invoice.length;
    dispatch({
      type: INV_HEADER_CAL_COUNTER,
      payload: { counter },
    });
  }, [invoice, globalSearchString]);
  
  useEffect(() => {
        getInvoiceList(clientid);
  }, [projectId, taskFilters, savedDate]);
  const getInvoiceList = (clientid) => {
    let selectedDate =  moment(savedDate).format("YYYY-MM-DD");
    
      DocumentsServices.getDocumentInvoices(projectId, taskFilters, 0, selectedDate,0,0, clientid).then(
      (res) => {
        if (res.status == 1) {
          if(pageName == 'customer')
          {
            setClientInvoices(res.data);
          } else {
            setInvoice(res.data);
          }
          let data = res.extra;
          dispatch({
            type: INVOICE_COUNTER,
            payload: { data },
          });
        }
      }
    );
  };
    const handleViewMore = (section) => {
        switch (section) {
          case "paid_invoice":
            setPaidInvoicePage(paidInvoicePage + 1);
            break;
            case "unpaid_invoice":
              setUnpaidInvoicePage(unpaidInvoicePage + 1);
              break;
              case "draft_invoice":
                setDraftInvoicePage(draftInvoicePage + 1);
                break;
                case "cancelled_invoice":
                  setCancelledInvoicePage(cancelledInvoicePage + 1);
                  break;
                  case "client_invoice":
                  setClientInvoicePage(clientInvoicePage + 1);
                  break;
          default:
            break;
        }
      };
      const invoiceCardHandler = async (invoice, flag = 1) => {
        setShowTaskModal(false);
        if (
          documentCardDetails &&
          documentCardDetails.type === "invoice" &&
          documentCardDetails.id == invoice.id
        ) {
          if (flag) {
            setShowDocumentCanvas(false);
            setDocumentCardDetails();
          }
        } else {
          try {
            // const details = await fetchDocumentDetails("invoice", invoice.id);
            // setDocumentDetail(details.data);
            dispatch(
              getInvoiceDetail(projectId, invoice.id, invoice.is_app_invoice)
            );
            setDocumentCardDetails({ ...invoice, type: "invoice" });
            setShowDocumentCanvas(true);
          } catch (e) {
            dispatch(
              showMessage("unsucess", _l("l_error"), _l("l_something_went_wrong"))
            );
            console.error(e);
          }
        }
      };
      const details = useSelector((state) => {
        switch (selectDocument) {
          case "invoice":
            return state.documentsReducer.invoice_details;
          case "subscription":
            return state.documentsReducer.subscription_details;
          case "estimate":
            return state.documentsReducer.estimate_details;
          case "credit_note":
            return state.documentsReducer.credit_note_details;
          case "document":
            return state.documentsReducer.document_details;
          case "spaceDocument":
            return state.customer.getFloorDocument;
          default:
            break;
        }
      });
    
      useEffect(() => {
        if (details) {
          setDocumentDetail(details);
        }
      }, [documentDetail, details]);
    const handlePinUnPin = (id, listName, flag = 1) => {
        let itemPosition = -1;
        let updateInvoice = [];
        switch (listName) {
          case "paid_invoice":
            itemPosition = paidInvoices.findIndex((x) => x.id == id);
              updateInvoice = Object.values({
              ...paidInvoices,
              [itemPosition]: {
                ...paidInvoices[itemPosition],
                pinned: paidInvoices[itemPosition].pinned == 1 ? 0 : 1,
              },
            });
            setPaidInvoices(updateInvoice);
            invoiceCardHandler(updateInvoice[itemPosition], flag);
            break;

          case "unpaid_invoice":
            itemPosition = unpaidInvoices.findIndex((x) => x.id == id);
              updateInvoice = Object.values({
              ...unpaidInvoices,
              [itemPosition]: {
                ...unpaidInvoices[itemPosition],
                pinned: unpaidInvoices[itemPosition].pinned == 1 ? 0 : 1,
              },
            });
            setUnpaidInvoices(updateInvoice);
            invoiceCardHandler(updateInvoice[itemPosition], flag);
            break;

          case "draft_invoice":
            itemPosition = draftInvoices.findIndex((x) => x.id == id);
              updateInvoice = Object.values({
              ...draftInvoices,
              [itemPosition]: {
                ...draftInvoices[itemPosition],
                pinned: draftInvoices[itemPosition].pinned == 1 ? 0 : 1,
              },
            });
            setDraftInvoices(updateInvoice);
            invoiceCardHandler(updateInvoice[itemPosition], flag);
            break;

          case "cancelled_invoice":
            itemPosition = cancelledInvoices.findIndex((x) => x.id == id);
              updateInvoice = Object.values({
              ...cancelledInvoices,
              [itemPosition]: {
                ...cancelledInvoices[itemPosition],
                pinned: cancelledInvoices[itemPosition].pinned == 1 ? 0 : 1,
              },
            });
            setCancelledInvoices(updateInvoice);
            invoiceCardHandler(updateInvoice[itemPosition], flag);
            break;

          case "client_invoice":
            itemPosition = clientInvoices.findIndex((x) => x.id == id);
              updateInvoice = Object.values({
              ...clientInvoices,
              [itemPosition]: {
                ...clientInvoices[itemPosition],
                pinned: clientInvoices[itemPosition].pinned == 1 ? 0 : 1,
              },
            });
            setClientInvoices(updateInvoice);
            invoiceCardHandler(updateInvoice[itemPosition], flag);
            break;
        
        }
        if (itemPosition > -1) {
          let tempDocumentDetail = documentDetail;
          if (tempDocumentDetail && Object.keys(tempDocumentDetail).length) {
            tempDocumentDetail["pinned"] =
              tempDocumentDetail["pinned"] == 1 ? 0 : 1;
            setDocumentDetail(tempDocumentDetail);
          }
        }
      };
    return (
        <>
          {
          isLoadingData("get_all_invoices") ? (
            <div className="comman-main-box custom-accordian-main res-padding-right0 h-100 task-list-accordian">
            <CommanLoader className="position-relative start-0"/>
          </div>
          ) :
           (
            <>
              <div
                id="list_view"
                className={`comman-main-box custom-accordian-main res-padding-right0 h-100 task-list-accordian ${
                  showTaskModal || showReqestedTaskModal || showDocumentCanvas
                    ? "list-view-toggle"
                    : ""
                }`}
              >
                <Accordion
                  defaultActiveKey={["0","01","02","03","04"]}
                  alwaysOpen
                  className="task-list-accordian-main"
                >
                  <div className="pill-wrapper">
                    <div id="" className="">
                      <div className="d-flex align-items-center filter-lists-menu min-width-190 justify-content-end">
                        <div className="badge  c-font f-12 color-white-80 bg-white-05  radius_3 me-2 d-none">
                          <div className="d-flex align-items-center">
                            <span className="color-white"></span>
                            <a href="#/" className="ps-1">
                              <X size={14} weight="light" className="c-icons" />
                            </a>
                          </div>
                        </div>
                        {taskFilters.length ? (
                          taskFilters.map((value, index) => {
                            if (value.main_key == "date_range_filter") {
                              jquery("body #active_week_date").removeClass("c-gradinet-active-border");
                              let displayFilterKey = value.title;
                              return (
                                <div
                                  key={index}
                                  className="badge c-font f-12 w-auto color-white-80 bg-white-05  radius_3 ms-2 comman-round-box active py-1"
                                >
                                  <div className="d-flex align-items-center">
                                    {/* <span className="color-white">{`${filter_key[0].toUpperCase()}${
                                    filter_key.length > 1
                                      ? filter_key.slice(1).split("_").join(" ")
                                      : ""
                                  }`}</span> */}
                                    <span className="color-white">
                                      {displayFilterKey}
                                    </span>
                                    <a href="#/"
                                      className="ps-1"
                                      onClick={() => {
                                        dispatch(
                                          leftPanelFilters({
                                            main_key: value.main_key,
                                            filter_key: value.filter_key,
                                            title: value.title,
                                          })
                                        );
                                        // dispatch(
                                        //   projectTaskCounts(projectId, taskFilters, {
                                        //     main_key: value.main_key,
                                        //     filter_key: value.filter_key,
                                        //     title: value.title,
                                        //   })
                                        // );
                                        // dispatch(
                                        //   projectTaskCounts(
                                        //     projectId,
                                        //     taskFilters,
                                        //     '')
                                        // );
                                        // jquery("body").addClass("body-toggle");
                                        // jquery(".after-login-header").addClass(
                                        //   "body-toggle"
                                        // );
                                        // jquery("footer").addClass("body-toggle");
                                      }}
                                    >
                                      <X size={14} className="c-icons" weight="light" />
                                    </a>
                                  </div>
                                </div>
                              );
                            }
                          })
                        ) : (
                          <></>
                        )}
                        <div className={`d-flex radius_3 justify-content-between commn-search ${pageName == 'customer' ? 'd-none' : ""}`}>
                          <div className="comment-input-box">
                            <input
                              type="text"
                              className="form-control bg-transparent with-base-bg border-0 py-1 px-2 f-11 c-font"
                              placeholder={`${_l("l_search_documents")}`}
                              onChange={(e) => {
                                setSearchString(e.target.value);
                              }}
                              value={searchString}
                            />
                          </div>
                          <a href="#/" className="comman_action_icon">
                            <div className="d-flex align-items-center ">
                              <div className="action_icon h25w25 d-none">
                                <X
                                  size={14}
                                  weight="light"
                                  className="c-icons m-auto filter-img"
                                />
                              </div>
                              <div className="action_icon h25w25">
                                <MagnifyingGlass
                                  size={16}
                                  weight="light"
                                  className="c-icons MagnifyingGlassIcon"
                                />
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  { pageName === 'customer' ?
                  <Accordion.Item eventKey="04" className="with-pb-15 ">
                  <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                    <p className="mb-0"><br></br></p>
                  </div>
                  <Accordion.Body className="taskCountToggle">
                    {clientInvoices && clientInvoices.length ? (
                      clientInvoices
                        .slice(
                          0,
                          clientInvoicePage > 1
                            ? (clientInvoicePage - 1) * 10 + 11
                            : clientInvoicePage * 11
                        )
                        .map((invoice, index) => {
                          return (
                            <CardView
                              hideShow={() => {
                                invoiceCardHandler(invoice);
                                setSelectedId(invoice.id);
                                setSelectDocument("invoice");
                              }}
                              data={{
                                ...invoice,
                                downloadUrl: invoice.invoice_link,
                              }}
                              docType={"invoice"}
                              setDocType={setDocType}
                              selectedId={selectedId}
                              key={index}
                              showDocumentCanvas={showDocumentCanvas}
                              setSelectedId={setSelectedId}
                              pinHandler={(id) => {
                                handlePinUnPin(id, "client_invoice");
                              }}
                            />
                          );
                        })
                    ) : (
                      <></>
                    )}
                    {clientInvoices.length >= (clientInvoicePage - 1) * 10 + 11 &&
                    clientInvoices.length != (clientInvoicePage - 1) * 10 + 11 ? (
                      <div className="d-flex justify-content-center">
                        <a href="#/"
                          className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                          onClick={() => {
                            handleViewMore("client_invoice");
                          }}
                        >
                          <span className="title-fonts d-flex justify-content-center align-items-center">
                            <div className="d-flex align-item-center justify-content-center">
                              {_l("l_view_more_btn")}(
                              {clientInvoicePage > 1
                                ? clientInvoices.length -
                                  ((clientInvoicePage - 1) * 10 + 11)
                                : clientInvoices.length - 11}
                              )
                              <CaretDoubleDown
                                size={12}
                                weight="light"
                                className="c-icons mx-2"
                              />
                              <span
                                role="status"
                                aria-hidden="true"
                                className="ms-2 opacity-0 spinner-border spinner-border-sm"
                              ></span>
                            </div>
                          </span>
                        </a>
                      </div>
                    ) : (
                      <></>
                    )}
                    {clientInvoices.length == 0 ? (
                      <CommanPlaceholder imgType="no-invoices" isAbsolute={true} placeholderText = {_l("l_no_invoices")} />
                    ) : (
                      <></>
                    )}
                    {/* <CommanOffCanvas show={showDocumentCanvas} handleClose={() => {setShowDocumentCanvas(false)}} docType={"dummy3"}/> */}
                    {/* <ViewMoreButton /> */}
                  </Accordion.Body>
                </Accordion.Item>
                :
                <>
                <Accordion.Item eventKey="0" className="with-pb-15 ">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                      <Accordion.Header className="active done accordion-header">
                        <div className="left-task-header">
                          <div className="d-flex align-items-center fw-semibold title-fonts c-font f-13">
                            <span className="c-dots c-7 done rounded-circle me-2"></span>
                            <span className="task-title me-2">
                              {_l("l_paid")}
                            </span>
                            <span className="">
                              [{paidInvoices ? paidInvoices.length : 0}]
                            </span>
                          </div>
                        </div>
                      </Accordion.Header>
                    </div>
                    <Accordion.Body className="taskCountToggle">
                      {paidInvoices && paidInvoices.length ? (
                        paidInvoices
                          .slice(
                            0,
                            paidInvoicePage > 1
                              ? (paidInvoicePage - 1) * 10 + 3
                              : paidInvoicePage * 3
                          )
                          .map((invoice, index) => {
                            return (
                              <CardView
                                hideShow={() => {
                                  invoiceCardHandler(invoice);
                                  setSelectedId(invoice.id);
                                  setSelectDocument("invoice");
                                }}
                                data={{
                                  ...invoice,
                                  downloadUrl: invoice.invoice_link,
                                }}
                                docType={"invoice"}
                                setDocType={setDocType}
                                selectedId={selectedId}
                                key={index}
                                showDocumentCanvas={showDocumentCanvas}
                                setSelectedId={setSelectedId}
                                pinHandler={(id) => {
                                  handlePinUnPin(id, "paid_invoice");
                                }}
                              />
                            );
                          })
                      ) : (
                        <></>
                      )}
                      {paidInvoices.length >= (paidInvoicePage - 1) * 10 + 3 &&
                      paidInvoices.length != (paidInvoicePage - 1) * 10 + 3 ? (
                        <div className="d-flex justify-content-center">
                          <a href="#/"
                            className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                            onClick={() => {
                              handleViewMore("paid_invoice");
                            }}
                          >
                            <span className="title-fonts d-flex justify-content-center align-items-center">
                              <div className="d-flex align-item-center justify-content-center">
                                {_l("l_view_more_btn")}(
                                {paidInvoicePage > 1
                                  ? paidInvoices.length -
                                    ((paidInvoicePage - 1) * 10 + 3)
                                  : paidInvoices.length - 3}
                                )
                                <CaretDoubleDown
                                  size={12}
                                  weight="light"
                                  className="c-icons mx-2"
                                />
                                <span
                                  role="status"
                                  aria-hidden="true"
                                  className="ms-2 opacity-0 spinner-border spinner-border-sm"
                                ></span>
                              </div>
                            </span>
                          </a>
                        </div>
                      ) : (
                        <></>
                      )}
                      {paidInvoices.length == 0 ? (
                        <CommanPlaceholder imgType="no-invoices" placeholderText = {_l("l_no_invoices")} />
                      ) : (
                        <></>
                      )}
                      {/* <CommanOffCanvas show={showDocumentCanvas} handleClose={() => {setShowDocumentCanvas(false)}} docType={"dummy3"}/> */}
                      {/* <ViewMoreButton /> */}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="01" className="with-pb-15 ">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                      <Accordion.Header className="active red accordion-header">
                        <div className="left-task-header">
                          <div className="d-flex align-items-center fw-semibold title-fonts c-font f-13">
                            <span className="c-dots c-7 bg-danger rounded-circle me-2"></span>
                            <span className="task-title me-2">
                              {_l("l_unpaid")}
                            </span>
                            <span className="">
                              [{unpaidInvoices ? unpaidInvoices.length : 0}]
                            </span>
                          </div>
                        </div>
                      </Accordion.Header>
                    </div>
                    <Accordion.Body className="taskCountToggle">
                      {unpaidInvoices && unpaidInvoices.length ? (
                        unpaidInvoices
                          .slice(
                            0,
                            unpaidInvoicePage > 1
                              ? (unpaidInvoicePage - 1) * 10 + 3
                              : unpaidInvoicePage * 3
                          )
                          .map((invoice, index) => {
                            return (
                              <CardView
                                hideShow={() => {
                                  invoiceCardHandler(invoice);
                                  setSelectedId(invoice.id);
                                  setSelectDocument("invoice");
                                }}
                                data={{
                                  ...invoice,
                                  downloadUrl: invoice.invoice_link,
                                }}
                                docType={"invoice"}
                                setDocType={setDocType}
                                selectedId={selectedId}
                                key={index}
                                showDocumentCanvas={showDocumentCanvas}
                                setSelectedId={setSelectedId}
                                pinHandler={(id) => {
                                  handlePinUnPin(id, "unpaid_invoice");
                                }}
                              />
                            );
                          })
                      ) : (
                        <></>
                      )}
                      {unpaidInvoices.length >= (unpaidInvoicePage - 1) * 10 + 3 &&
                      unpaidInvoices.length != (unpaidInvoicePage - 1) * 10 + 3 ? (
                        <div className="d-flex justify-content-center">
                          <a href="#/"
                            className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                            onClick={() => {
                              handleViewMore("unpaid_invoice");
                            }}
                          >
                            <span className="title-fonts d-flex justify-content-center align-items-center">
                              <div className="d-flex align-item-center justify-content-center">
                                {_l("l_view_more_btn")}(
                                {unpaidInvoicePage > 1
                                  ? unpaidInvoices.length -
                                    ((unpaidInvoicePage - 1) * 10 + 3)
                                  : unpaidInvoices.length - 3}
                                )
                                <CaretDoubleDown
                                  size={12}
                                  weight="light"
                                  className="c-icons mx-2"
                                />
                                <span
                                  role="status"
                                  aria-hidden="true"
                                  className="ms-2 opacity-0 spinner-border spinner-border-sm"
                                ></span>
                              </div>
                            </span>
                          </a>
                        </div>
                      ) : (
                        <></>
                      )}
                      {unpaidInvoices.length == 0 ? (
                        <CommanPlaceholder imgType="no-invoices" placeholderText = {_l("l_no_invoices")} />
                      ) : (
                        <></>
                      )}
                      {/* <CommanOffCanvas show={showDocumentCanvas} handleClose={() => {setShowDocumentCanvas(false)}} docType={"dummy3"}/> */}
                      {/* <ViewMoreButton /> */}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="02" className="with-pb-15 ">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                      <Accordion.Header className="active to-do accordion-header">
                        <div className="left-task-header">
                          <div className="d-flex align-items-center fw-semibold title-fonts c-font f-13">
                            <span className="c-dots c-7 in-progress-white rounded-circle me-2"></span>
                            <span className="task-title me-2">
                              {_l("l_draft")}
                            </span>
                            <span className="">
                              [{draftInvoices ? draftInvoices.length : 0}]
                            </span>
                          </div>
                        </div>
                      </Accordion.Header>
                    </div>
                    <Accordion.Body className="taskCountToggle">
                      {draftInvoices && draftInvoices.length ? (
                        draftInvoices
                          .slice(
                            0,
                            draftInvoicePage > 1
                              ? (draftInvoicePage - 1) * 10 + 3
                              : draftInvoicePage * 3
                          )
                          .map((invoice, index) => {
                            return (
                              <CardView
                                hideShow={() => {
                                  invoiceCardHandler(invoice);
                                  setSelectedId(invoice.id);
                                  setSelectDocument("invoice");
                                }}
                                data={{
                                  ...invoice,
                                  downloadUrl: invoice.invoice_link,
                                }}
                                docType={"invoice"}
                                setDocType={setDocType}
                                selectedId={selectedId}
                                key={index}
                                showDocumentCanvas={showDocumentCanvas}
                                setSelectedId={setSelectedId}
                                pinHandler={(id) => {
                                  handlePinUnPin(id, "draft_invoice");
                                }}
                              />
                            );
                          })
                      ) : (
                        <></>
                      )}
                      {draftInvoices.length >= (draftInvoicePage - 1) * 10 + 3 &&
                      draftInvoices.length != (draftInvoicePage - 1) * 10 + 3 ? (
                        <div className="d-flex justify-content-center">
                          <a href="#/"
                            className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                            onClick={() => {
                              handleViewMore("draft_invoice");
                            }}
                          >
                            <span className="title-fonts d-flex justify-content-center align-items-center">
                              <div className="d-flex align-item-center justify-content-center">
                                {_l("l_view_more_btn")}(
                                {draftInvoicePage > 1
                                  ? draftInvoices.length -
                                    ((draftInvoicePage - 1) * 10 + 3)
                                  : draftInvoices.length - 3}
                                )
                                <CaretDoubleDown
                                  size={12}
                                  weight="light"
                                  className="c-icons mx-2"
                                />
                                <span
                                  role="status"
                                  aria-hidden="true"
                                  className="ms-2 opacity-0 spinner-border spinner-border-sm"
                                ></span>
                              </div>
                            </span>
                          </a>
                        </div>
                      ) : (
                        <></>
                      )}
                      {draftInvoices.length == 0 ? (
                        <CommanPlaceholder imgType="no-invoices" placeholderText = {_l("l_no_invoices")} />
                      ) : (
                        <></>
                      )}
                      {/* <CommanOffCanvas show={showDocumentCanvas} handleClose={() => {setShowDocumentCanvas(false)}} docType={"dummy3"}/> */}
                      {/* <ViewMoreButton /> */}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="03" className="with-pb-15 ">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                      <Accordion.Header className="active orange accordion-header">
                        <div className="left-task-header">
                          <div className="d-flex align-items-center fw-semibold title-fonts c-font f-13">
                            <span className="c-dots c-7 bg-warning rounded-circle me-2"></span>
                            <span className="task-title me-2">
                              {_l("l_canceled")}
                            </span>
                            <span className="">
                              [{cancelledInvoices ? cancelledInvoices.length : 0}]
                            </span>
                          </div>
                        </div>
                      </Accordion.Header>
                    </div>
                    <Accordion.Body className="taskCountToggle">
                      {cancelledInvoices && cancelledInvoices.length ? (
                        cancelledInvoices
                          .slice(
                            0,
                            cancelledInvoicePage > 1
                              ? (cancelledInvoicePage - 1) * 10 + 3
                              : cancelledInvoicePage * 3
                          )
                          .map((invoice, index) => {
                            return (
                              <CardView
                                hideShow={() => {
                                  invoiceCardHandler(invoice);
                                  setSelectedId(invoice.id);
                                  setSelectDocument("invoice");
                                }}
                                data={{
                                  ...invoice,
                                  downloadUrl: invoice.invoice_link,
                                }}
                                docType={"invoice"}
                                setDocType={setDocType}
                                selectedId={selectedId}
                                key={index}
                                showDocumentCanvas={showDocumentCanvas}
                                setSelectedId={setSelectedId}
                                pinHandler={(id) => {
                                  handlePinUnPin(id, "cancelled_invoice");
                                }}
                              />
                            );
                          })
                      ) : (
                        <></>
                      )}
                      {cancelledInvoices.length >= (cancelledInvoicePage - 1) * 10 + 3 &&
                      cancelledInvoices.length != (cancelledInvoicePage - 1) * 10 + 3 ? (
                        <div className="d-flex justify-content-center">
                          <a href="#/"
                            className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                            onClick={() => {
                              handleViewMore("cancelled_invoice");
                            }}
                          >
                            <span className="title-fonts d-flex justify-content-center align-items-center">
                              <div className="d-flex align-item-center justify-content-center">
                                {_l("l_view_more_btn")}(
                                {cancelledInvoicePage > 1
                                  ? cancelledInvoices.length -
                                    ((cancelledInvoicePage - 1) * 10 + 3)
                                  : cancelledInvoices.length - 3}
                                )
                                <CaretDoubleDown
                                  size={12}
                                  weight="light"
                                  className="c-icons mx-2"
                                />
                                <span
                                  role="status"
                                  aria-hidden="true"
                                  className="ms-2 opacity-0 spinner-border spinner-border-sm"
                                ></span>
                              </div>
                            </span>
                          </a>
                        </div>
                      ) : (
                        <></>
                      )}
                      {cancelledInvoices.length == 0 ? (
                        <CommanPlaceholder imgType="no-invoices" placeholderText = {_l("l_no_invoices")} />
                      ) : (
                        <></>
                      )}
                      {/* <CommanOffCanvas show={showDocumentCanvas} handleClose={() => {setShowDocumentCanvas(false)}} docType={"dummy3"}/> */}
                      {/* <ViewMoreButton /> */}
                    </Accordion.Body>
                  </Accordion.Item>
                </>}
                </Accordion>
                {showDocumentCanvas ? (
            <CommanOffCanvas
              show={showDocumentCanvas}
              handleClose={() => {
                setShowDocumentCanvas(false);
                setDocumentCardDetails();
                setDocumentDetail();
                setSelectedId(0);
              }}
              pinHandler={(id, type, flag) => {
                handlePinUnPin(id, type, flag);
              }}
              data={documentDetail}
              setData={setDocumentDetail}
              docType={'invoice'}
              setSelectedId={setSelectedId}
            />
          ) : (
            <></>
          )}
              </div>
          </>
          )}
          </>
          
    )
                      
}
export default InvoiceList;