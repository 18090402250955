import React from "react";

const MapIframe = ({ address, fullWidth }) => {
  return (
    <div className={`${fullWidth ? "col-lg-12 flex-grow-1" : "col-lg-4"} d-flex flex-column`}>
      <iframe
        className="w-100 h-100 flex-grow-1"
        src={`https://www.google.com/maps?q=${encodeURIComponent(address)}&output=embed`}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default MapIframe;
