import React from 'react';
// import CSS from '../css/icon_main.css';

function Squarearea(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<path d="M13.125 16.6875H15.375M8.0625 9.375V11.625M10.3125 16.6875H8.625C8.47582 16.6875 8.33274 16.6282 8.22725 16.5227C8.12176 16.4173 8.0625 16.2742 8.0625 16.125V14.4375M18.1875 16.6875H19.875C20.0242 16.6875 20.1673 16.6282 20.2727 16.5227C20.3782 16.4173 20.4375 16.2742 20.4375 16.125V4.875C20.4375 4.72582 20.3782 4.58274 20.2727 4.47725C20.1673 4.37176 20.0242 4.3125 19.875 4.3125H8.625C8.47582 4.3125 8.33274 4.37176 8.22725 4.47725C8.12176 4.58274 8.0625 4.72582 8.0625 4.875V6.5625M8.22422 4.47422L20.2758 16.5258M22.875 21H3.74074C3.54428 21 3.35587 20.923 3.21696 20.786C3.07804 20.6489 3 20.463 3 20.2692V2M22.875 21L21 19.125M22.875 21L21 22.875M3 2L1 4M3 2L5 4" stroke="#000645" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
		</React.Fragment>
	)
}

export default Squarearea