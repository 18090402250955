import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import React, { useEffect, useRef, useState } from "react";

 const AddressPaytabs = ({ projectId, clientId, location, zoomLevel }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  const [startDate, setStartDate] = useState(new Date());
  const numberlisting = [
    {
      value: "1",
      label: "India ",
    },
    {
      value: "2",
      label: "France ",
    },
    {
      value: "3",
      label: "American",
    },
    {
      value: "4",
      label: "Jarman ",
    },
    {
      value: "5",
      label: "Australia ",
    },
  ];
 return (
    <div>
        <div className="row">
            <Form.Group className="col-12 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk title-fonts color-white-60">
                Country
                </Form.Label>
                <Select
                  className="custom-select-menu"
                  options={numberlisting}
                  closeMenuOnSelect={false}
                  classNamePrefix="react-select"
                  placeholder="India"
                />
            </Form.Group>
            <Form.Group className="col-12 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk title-fonts color-white-60">
                Address Line
                </Form.Label>
                <textarea placeholder="Street Address" className="form-control"/>
            </Form.Group>
            <Form.Group className="col-12 c-input-box position-relative mb-3">
                <div className="color-white-60 c-font f-12 ">
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
                </div>
            </Form.Group>
        </div>
    </div>
  );
 };
 export default AddressPaytabs;