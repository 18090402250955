import React, { useEffect, useState } from "react";
import { _l, getFileType, getPreviewFromURL, removeModifiedClass, showError } from "../../../hooks/utilities";
import {
  CaretDown,
  CloudArrowUp,
  FilePdf,
  FileText,
  MapPin,
} from "phosphor-react";
import ComingSoon from "../../../assets/images/yellowbg.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateAdministrativeList } from "../../../actions/customer";
import customerServices from "../../../services/customer-services";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";
import Lightbox from "yet-another-react-lightbox";
import CommanOffCanvas from "../../Offcanvas/CommanOffCanvas";
import TaskListStatusDropdown from "../../Dropdowns/TaskListStatusDropdown";
import ReactTooltip from "react-tooltip";
import MairieLogo from "../../../assets/images/mairie.svg"
import Consuellogo from "../../../assets/images/consuel.svg"
import RaccordementLogo from "../../../assets/images/raccordement.svg"
import Spinner from "react-bootstrap/Spinner";
import ProceduresSkeleton from "../../Skeleton/ProceduresSkeleton";
import NoMapPlaceholder from "../../../assets/images/NoMapPlaceholder.svg";
import DocumentViewModal from "../../Modals/DocumentViewModal";
import SolarDocumentPreview from "../SolarDocumentPreview";


const AdminstrativeList = ({showSckeleton}) => {
  const {
    globalSearchString,
    adminstrativeProposalList
  } = useSelector((state) => state.customer);

  const dispatch = useDispatch();

  const STATUS_LIST = [
    {
      title: "Demande",
      value : 1
    },
    {
      title: "Manque document",
      value : 2
    },
    {
      title: "En cours",
      value : 3
    },
    {
      title: "Complétée",
      value : 4
    },
  ];

  const [benchMarkListLocal, setbenchMarkListLocal] = useState([]);
  const [currentTab, setcurrentTab] = useState(1);
  const [tabCounts, setTabCounts] = useState({
    tab1 : 0,
    tab2 : 0,
    tab3 : 0,
    tab4 : 0,
  });
  const [allAttachments, setAllAttachments] = useState([]);
  const [showTaskImage, setShowTaskImage] = useState(false);
  const [showTaskCanvas, setShowTaskCanvas] = useState(false);
  const [taskDetail, setTaskDetail] = useState();
  const [showCommentLoader, setShowCommentLoader] = useState(-1)
  const [loadedImages, setLoadedImages] = useState({});
  const [openLightBox, setOpenLightBox] = useState(false);


  const handleDocumentUpload = (files, task, benchmarkid) => {
    setShowCommentLoader(task.id)
    try {
   
    customerServices.addLinkComment(
      task.id,
      0,
      "",
      "",
      0,
      0,
      0,
      files,
      0,
      "",
      0,
      0,
      "",
      "",
      0,
      false,
      1
    ).then((res)=>{
      if (res && res.status && res.data.files_list && res.data.files_list.length) {
        let tempBenchmarkList = adminstrativeProposalList
        let benchmarkIndex = -1
        benchmarkIndex = tempBenchmarkList.findIndex((benchmark)=> benchmark.id === benchmarkid);
        if (benchmarkIndex > -1) {
          let taskIndex = -1
          taskIndex = tempBenchmarkList[benchmarkIndex].solar_task_ids.findIndex((t)=> t.id == task.id);
          if (taskIndex > -1) {
            tempBenchmarkList[benchmarkIndex].solar_task_ids[taskIndex].task_attachments = tempBenchmarkList[benchmarkIndex].solar_task_ids[taskIndex].task_attachments.concat(res.data.files_list)
            tempBenchmarkList[benchmarkIndex].solar_task_ids[taskIndex].status = 4 
          }
          
        }
        dispatch(updateAdministrativeList(tempBenchmarkList))
        setShowCommentLoader(-1)
      }else{
        showError("l_something_went_wrong");
        setShowCommentLoader(-1);
      }
    })  
  } catch (error) {
    console.log(error)
  }
  };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = 'copy';
    };

    const handleDrop = (e,task, benchmarkid) => {
        e.preventDefault();
        e.stopPropagation();
        handleDocumentUpload(e.dataTransfer.files,task, benchmarkid)
    };

  const handleChangeTab = (tab) => {

    setcurrentTab(tab);

    switch (tab) {
      case 1:
        setbenchMarkListLocal(filterProjects(1));
        break;
      case 2:
        setbenchMarkListLocal(filterProjects(2));
        break;
      case 3:
        setbenchMarkListLocal(filterProjects(3));        
        break;
      case 4:
        setbenchMarkListLocal(filterProjects(4));     
        break;
    
      default:
        break;
    }
  };

  const filterProjects = (tab) => {
    return adminstrativeProposalList.filter(project => {
      const taskAttachmentsLengths = project.solar_task_ids.map(task => task.task_attachments.length);
      
      const hasAttachments = taskAttachmentsLengths.some(length => length > 0);
      const allHaveAttachments = taskAttachmentsLengths.every(length => length > 0);
      
      if (tab === 1) {
        return taskAttachmentsLengths.every(length => length === 0);
      }
      if (tab === 2) {
        return hasAttachments && !allHaveAttachments;
      }
      if (tab === 3) {
        return allHaveAttachments && Number(project.goverment_status) === 0;
      }
      if (tab === 4) {
        return allHaveAttachments && project.goverment_status > 0;
      }
      return false;
    });
  };

  

  const setAttchmentOnTop = (task_attachments,id) => {
    const targetAttchement = task_attachments.filter(
      (attachment) => attachment.id === id
    );
    const otherAttachments = task_attachments.filter(
      (attachment) => attachment.id != id
    );
    setAllAttachments(targetAttchement.concat(otherAttachments));
    setShowTaskImage(true)
  };
  
  const showTaskDetails = (task) => {
    customerServices.getTaskData(0,task.id).then((res)=>{
      if (res && res.status) {
        setTaskDetail(res.data);
        setShowTaskCanvas(true);
      }
    })
  }
  const changeTaskStatus = (statusId, task , benchmark) => {
    let status =  statusId == "task_convert_to_request" ? 1 : statusId


    switch (status) {
      case 1:
        changeStatus(statusId, task , benchmark)
        break;
      case 4:
      case 5:
        if (task.task_attachments.length > 0) {
        changeStatus(statusId, task , benchmark)
        }else{
          showError("l_upload_additional_document")
        }
        break;
    
      default:
        break;
    } 
  }

  const changeStatus = (statusId, task , benchmark) =>{
    let status =  statusId == "task_convert_to_request" ? 1 : statusId

    customerServices.updateTaskStatus(
      task.id,
      benchmark.project_id,
      status,
      false,
      0,
      "",
      ""
    ).then((res)=>{
      if (res && res.status)  if (res && res.status) {
        let tempBenchmarkList = adminstrativeProposalList
        let benchmarkIndex = -1
        benchmarkIndex = tempBenchmarkList.findIndex((b)=> b.id === benchmark.id);
        if (benchmarkIndex > -1) {
          let taskIndex = -1
          taskIndex = tempBenchmarkList[benchmarkIndex].solar_task_ids.findIndex((t)=> t.id == task.id);
          if (taskIndex > -1) {
            tempBenchmarkList[benchmarkIndex].solar_task_ids[taskIndex].status = status
          }
          
        }
        dispatch(updateAdministrativeList(tempBenchmarkList))
        
      }
    });
  }

  const handleGovermentApproval = (id,flag) => {
    customerServices.updateGovermentApproval(id).then((res)=>{
      if (res && res.status) {
        let tempBenchmarkList = adminstrativeProposalList
        let benchmarkIndex = -1
        benchmarkIndex = tempBenchmarkList.findIndex((benchmark)=> benchmark.id === id);
        if (benchmarkIndex > -1) {
          tempBenchmarkList[benchmarkIndex].goverment_status = Number(flag) == 0 ? 1 : 0
          tempBenchmarkList[benchmarkIndex].solar_task_ids.forEach((task) => task.status = 5)
        }
        dispatch(updateAdministrativeList(tempBenchmarkList))
      }else{
        showError(res.message)
      }
    })
  }

  const handleImageLoad = (index) => {
    setLoadedImages((prevState) => ({ ...prevState, [index]: true }));
  };

  // Handle image error event (optional, for broken images)
  const handleImageError = (index) => {
    setLoadedImages((prevState) => ({ ...prevState, [index]: true }));
  };

  useEffect(() => {
    // removeModifiedClass();
    setbenchMarkListLocal(filterProjects(currentTab));
    setTabCounts({
      tab1 : filterProjects(1).length,
      tab2 : filterProjects(2).length,
      tab3 : filterProjects(3).length,
      tab4 : filterProjects(4).length,
    })
  }, [JSON.stringify(adminstrativeProposalList)]);

  useEffect(() => {
    if (globalSearchString) {
      setbenchMarkListLocal(benchMarkListLocal.filter((benchmark)=>benchmark.company_name.toLocaleLowerCase().includes(globalSearchString.toLocaleLowerCase()) || benchmark.address.toLocaleLowerCase().includes(globalSearchString.toLocaleLowerCase())))
    }else{
      setbenchMarkListLocal(filterProjects(currentTab));
    }
    
  }, [globalSearchString])
  

  return (
    <>
      {/* { showTaskImage && !openLightBox &&
      <Lightbox
        key={0}
        open={showTaskImage}
        close={() => setShowTaskImage(false)}
        slides={allAttachments.map((img) => {
          return { src: getPreviewFromURL(img.image_url) };
        })}
        />} */}
        {openLightBox && allAttachments.length ? (
          <SolarDocumentPreview
            open={openLightBox}
            setOpen={() => {
              setShowTaskImage(false);
              setAllAttachments([]);
              setOpenLightBox(false);
            }}
            slides={allAttachments.map((item) => {
              return ({
                ...item, 
                type : item.file_type,
                src : item.image_url
              });
            })}
          />
        ) : (
          <></>
        )}
      <div className="flex-grow-1 d-flex flex-column position-relative h-100">
          <div className="registration-footer-content  flex-grow-1 overflow-hiiden-auto">
            <div className="position-sticky top-0  z-index-2 d-flex flex-column gap-3 white-shadow shadow-none pt-40px bgpatternCommanImg">
              <div className="align-items-center justify-content-between horizontaltab btn-white-05 bg-solar d-flex p-1 mb-20 radius_5 text-uppercase shadow-none z-index-6">
                {
                  STATUS_LIST.map((status, index) =>{
                    return(<>
                <label
                  className={`row-btn-label w-25 d-flex`}
                  for="administrativeradio1"
                >
                  {/* <span
                    class={`rounded-pill badge-custom  d-flex p-2 light-pill fw-semibold right0px`}
                  >
                    0
                  </span> */}
                  <input
                    type="radio"
                    className="hidden-input"
                    name="proposal_status"
                    checked = {status.value == currentTab}
                    value={status.title}
                    onChange={()=>{
                      handleChangeTab(status.value)
                    }}
                  />
                  <div className="row-btn-box c-font f-12 p-2 fw-semibold title-fonts cblue w-100">
                    <span class={`c-dots  c-8 me-2 
                      ${index == 0
                              ? `bg-black`
                              : index == 1
                              ? `bgyellow`
                              : index == 2
                              ? `bg-blue`
                              : index == 3
                              ? `lightgreen`
                              : `bg-black`}`}></span>
                    <span>{status.title}</span>
                    <span className="ps-1">
                      {
                        index == 0 ? `- (${(tabCounts.tab1)})` :
                        index == 1 ? `- (${(tabCounts.tab2)})` :
                        index == 2 ? `- (${(tabCounts.tab3)})` :
                        index == 3 ? `- (${(tabCounts.tab4)})` : 0
                      }
                      </span>
                  </div>
                </label>
                    </>)
                  })
                }
              </div>
            </div>
            <div className="mx-auto d-grid gap-3 padding-bottom-80px">
              {/* card 1 start */}
              {showSckeleton ? <> <ProceduresSkeleton /></> : benchMarkListLocal &&
                benchMarkListLocal.length > 0 &&
                benchMarkListLocal.map((benchmark, index) => {
                  return (
                    <>
                      <div className="radius_3 d-flex comman-white-box-shadow overflow-hiiden-auto bg-white-03  flex-column-reverse flex-sm-row">
                        <div className="flex-grow-1 order-1">
                          <div className="d-flex align-align-items-center h-100 flex-column p-20pximp gap-4 gap-md-0 proposalListing p10pxresponsive">
                            <div className="d-flex justify-content-between flex-column">
                              <div className="d-flex align-items-lg-start justify-content-between w-100 mb20px">
                                <div>
                                  <div className="c-font f-16 fw-semibold text-truncate title-fonts">
                                    {benchmark.company_name}
                                  </div>
                                  <div class="c-font color-white-60 f-12 pt-1 text-truncate d-flex align-items-center gap-2">
                                  <MapPin size={14} className="c-icons"/>
                                    {benchmark.address}
                                  </div>
                                </div>
                                <div className="d-flex align-items-center gap10px">
                                  <div class="d-flex form-check gap10px">
                                    <input
                                      className="form-check-input light-theme-border light-theme-white-bg box-shadow-none"
                                      type="checkbox"
                                      id="approval"
                                      checked={Number(benchmark.goverment_status) > 0}
                                      disabled={currentTab == 3 ||  currentTab == 4 ? false : true}
                                      onChange={()=>{
                                        handleGovermentApproval(benchmark.id , benchmark.goverment_status)
                                      }}
                                    />
                                    <label
                                      class="c-font f-13 form-check-label fw-medium ps-0"
                                      for="approval"
                                    >
                                      Approbation du gouvernement
                                    </label>
                                  </div>
                                  {currentTab == 4 ?<a
                                    href="#/"
                                    className="btn btn-white-05 position-relative radius_5 propostion_btn resmargin15px onhoverimginv"
                                  >
                                    <FileText
                                      size={14}
                                      className="c-icons"
                                      weight="light"
                                    />
                                    <span class="ms-10px">
                                    Export PDF
                                    </span>
                                  </a> : <></>}
                                </div>
                              </div>
                              <div className="row">
                                {benchmark.solar_task_ids &&
                                  benchmark.solar_task_ids.length > 0 &&
                                  benchmark.solar_task_ids
                                    .filter(
                                      (task , index) =>
                                        task.solar_task_type != 0 &&
                                        task.solar_task_type != 4 && index < 3
                                    )
                                    .map((task) => {
                                      let taskStatus = task.status
                                      return (
                                        <>
                                          <div className="col-lg-4 d-flex flex-column gap10px ">
                                            <div className="d-flex align-items-center gap10px">
                                              <div className="h20w20 w-auto">
                                                <img src={task.solar_task_type == 1 ? MairieLogo : task.solar_task_type == 2 ? Consuellogo : RaccordementLogo} className="h-100 w-100"/>
                                              </div>
                                            <a href="#/" className="c-font f-12 fw-semibold text-truncate title-fonts  d-block" onClick={()=>{
                                              showTaskDetails(task)
                                            }}>
                                              {task.name}
                                            </a>

                                            </div>

                                             {/* status  start*/}

                                            
                      
                       <div className={` dropup-center dropup comman_action_icon card-view-less-padding p-0 d-flex `}>
                          <div
                            className={`BigPill c-font close-toast d-flex f-12 fw-medium me-0 text-black text-uppercase w-100`}
                            type="button"
                            id="TaskListStatusDropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-tip={`${_l("l_status")}`}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                          >
                            <div className="d-flex align-items-center w-100 gap10px">
                              <span
                                className={`c-dots c-10 ${taskStatus == 0
                                    ? "bg-warning"
                                    : taskStatus == 1
                                      ? "bg-warning"
                                      : taskStatus == 4
                                        ? "in-progress-blue"
                                        : "done"
                                  } `}
                              ></span>
                              <span className="">
                                {taskStatus == 1
                                  ? "Request"
                                  : taskStatus == 4
                                  ? "In Progress"
                                  : taskStatus == 5 ?
                                   "Done"
                                  : "Request"
                                  }
                              </span>
                              <CaretDown
                                size={14}
                                className="c-icons ms-auto me-0"
                              />
                             
                                <TaskListStatusDropdown
                               setTaskStatus={(statusId)=>{
                                changeTaskStatus(statusId,task, benchmark);
                              }}
                               bulkAction={false}
                               isRequest={false}
                               is_liteversion={false}
                               is_task={true}
                               hideTodo={true}
                                />
                            </div>
                          </div>
                          <ReactTooltip />
                        </div> 
                        {/* staus end */}

                                            <div className="d-flex align-items-center upload-image-preview gap10px">
                                              <div className=" commanbgwithborder w65h50 position-relative radius_3 text-center d-flex flex-column  justify-content-center">
                                                <input
                                                  type="file"
                                                  className="absolute-input "
                                                  multiple
                                                  onChange={(e) => {
                                                    handleDocumentUpload(
                                                      e.target.files,
                                                      task,
                                                      benchmark.id
                                                    );
                                                  }}
                                                  onDragOver={handleDragOver}
                                                  onDrop={(e)=>{handleDrop(e, task,benchmark.id)}}
                                                />
                                               {showCommentLoader == task.id
                                               ?
                                               <Spinner
                                               as="span"
                                               animation="border"
                                               size="sm"
                                               role="status"
                                               className={`m-auto`}
                                               aria-hidden="true"
                                             />
                                                : <CloudArrowUp
                                                  size={18}
                                                  className="c-icons"
                                                />}
                                              </div>
                                              {task.task_attachments &&
                                                task.task_attachments.length >
                                                  0 &&
                                                task.task_attachments.map(
                                                  (img, index) => {
                                                    return (
                                                      <>
                                                        <a
                                                          href="#/"
                                                          className="comman-image-box w80h55 radius_3 upload-image"
                                                          key={index}
                                                          onClick={()=>{
                                                              setOpenLightBox(true);
                                                              setAllAttachments(task.task_attachments);
                                                              setAttchmentOnTop(task.task_attachments ,img.id);
                                                          }}
                                                        >
                                                        {getFileType(img.image_url) == "pdf" ?
                                                          <FilePdf size={32} className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 cursor-pointer"/>
                                                          :
                                                          <div
                                                            className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 cursor-pointer"
                                                            style={{
                                                              backgroundImage: `url(${img.image_url})`,
                                                            }}
                                                          ></div>}
                                                        </a>
                                                      </>
                                                    );
                                                  }
                                                )}
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column">
                          <div className="w200px d-flex p-5px flex-grow-1 min-h-140 position-relative">
                              {!loadedImages[index] && (
                                <img
                                  src={NoMapPlaceholder}  // Your placeholder image
                                  alt="placeholder"
                                  style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                  }}
                                />
                              )} 
                            <img
                              className="h-100 w-100 object-fit-cover min-h-140 "
                              src={benchmark.solar_map}
                              style={{ display: loadedImages[index] ? 'block' : 'none' }}
                              onLoad={() => handleImageLoad(index)}  
                              onError={() => handleImageError(index)} 
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
                {showSckeleton ? <></> : benchMarkListLocal && benchMarkListLocal.length == 0 ? <>
                  <CommanPlaceholder imgType="request" placeholderText = {_l("l_no_data")} />
                </> : <React.Fragment></React.Fragment>}
              {/* card 1 end */}
            </div>
          </div>
   
        <div className="Absolutecomingsoon d-none">
          <div className="d-flex align-items-center justify-content-center flex-column h-100">
            <img className="h100px" src={ComingSoon} alt="" />
            {/* <h2 data-text="Coming soon">Coming soon</h2> */}
            <div className="display-2 fw-semibold title-fonts">Coming soon</div>
          </div>
        </div>
      </div>
      {showTaskCanvas ? (
            <CommanOffCanvas
              show={showTaskCanvas}
              handleClose={() => {
                setShowTaskCanvas(false);
              }}
            //   pinHandler={(id, type, flag) => {
            //     handlePinUnPin(id, type, flag);
            //   }}
              data={taskDetail}
              setData={()=>{}}
              docType={'task'}
              setSelectedId={()=>{}}
              is_fullscreen={true}
            />
          ) : (
            <></>
          )}
    </>
  );
};

export default AdminstrativeList;
