import React, { useEffect, useState } from "react";
import { _l, getFileType, hideLeftPanelNew, removeDuplicateObjects, showError } from "../hooks/utilities";
import ProviderProposalDetail from "../components/SolarExternalPages/ProviderProposalDetail";
import { useDispatch, useSelector } from "react-redux";
import StepperHeader from "../components/SolarExternalPages/StepperHeader";
import AfterPaymentSignature from "../components/SolarExternalPages/AfterPaymentSignature";
import Fullcalendar from "../components/Fullcalendar";
import Customcalendar from "../components/Customcalendar";
import { dayGridMonth, getBenchmarkDetail, setFetchedTaskandMessages } from "../actions/customer";
import SolarFullCalendar from "../components/SolarExternalPages/SolarFullCalendar";
import CustomerProposalDetail from "../components/SolarExternalPages/CustomerProposalDetail";
import { PROPOSAL_DETAILS } from "../constants/constants";
import SolarTaskDetails from "../components/SolarTaskDetails";
import benchmarkServices from "../services/benchmark-services";
import BenchmarkServices from "../services/benchmark-services";
import { useSearchParams } from "react-router-dom";
import { getExternalBenchmarkDetails } from "../actions/benchmark-actions/benchmark-actions";
import FullScreenLoader from "../components/Loader/FullScreenLoader";
import { showConfirmation, toastCloseAction } from "../actions/messages";
import { fetchGroupsExternal, getGroupDetails, groupOut, setUpdatedChatGroupsList, updateExternalGroupListMessages } from "../actions/chat";
import chatServices from "../services/chat-services";
import customerServices from "../services/customer-services";
import TaskSignatureModal from "../components/Modals/TaskSignatureModal";
import { fetchAuthToken } from "../actions/chat-auth";
import ChatServices from "../services/chat-services"
import jQuery from "jquery";
import AcceptProposalCustomer from "../components/SolarExternalPages/AcceptProposalCustomer";

const STEPS = {
  BACK: -1,
  NEXT: 1,
  SKIP: 0,
};
let proposal = {};
let lightBoxData = [];


const TOTAL_STEPS_COUNT = 6;

const ExternalProposalDetails = () => {
  const dispatch = useDispatch();

  const { benchmarkDetail } = useSelector((state) => state.customer);
  const [events, setEvents] = useState();
  const [searchParams,setSearchParams] = useSearchParams();

  const hash = searchParams.get("hash");
  const proposalId = searchParams.get("proposal_id");
  const step = searchParams.get("step");

  let chatUserId = localStorage.getItem("chatUserId");



  const [proposalDetails, setProposalDetails] = useState();
  const [customerDocuments, setCustomerDocuments] = useState({
    signature : "",
    customerContract : ""
  })

  const [currentStep, setCurrentStep] = useState("");
  const [textContent, setTextContent] = useState({
    backButtonText: "",
    headerTitle: "l_solar_address_step_header",
    nextButtonText: "",
    stepDescription: "",
    stepperProgress: {
      stepCount: 0,
      percentage: 0,
    },
    stepTitle: "",
  });
  const [chatGroups1, setChatGroups1] = useState();
  const [chatGroups2, setChatGroups2] = useState();
  const [showTaskSignature, setShowTaskSignature] = useState(false)
  const [loadingNextStep, setLoadingNextStep] = useState(false);
  const userType = localStorage.getItem("user_type");
  const staff_id = localStorage.getItem("staff_id");
  
  const changeStep = (direction) => {
    if (direction == -1) {
      switch (currentStep) {
        case "proposal-detail":
          setCurrentStep("proposal-summary");
          break;
  
        case "assistance-mandate":
        case "signature-mandate":
          setCurrentStep("proposal-summary");
      
          break;
  
        case "calendar-task":
          setCurrentStep("assistance-mandate");
          break;
  
        case "task-details":
          setCurrentStep("proposal-summary");
          break;
  
        case "installation-complete":
          setCurrentStep( "calendar-task");
          break;
      }
    } else {     
      switch (currentStep) {
        case "proposal-detail":
          if ((proposalDetails && proposalDetails.status == 2 )|| (proposalDetails && proposalDetails.status == 3) ) {
            setCurrentStep("assistance-mandate");
          }
          break;
  
        case "assistance-mandate":
        case "signature-mandate":
          if ((customerDocuments.signature == "" && customerDocuments.customerContract == "") && benchmarkDetail.stuck_page_name == "calander"){
            setCurrentStep("proposal-summary");
          }else  if ((customerDocuments.signature == "" && customerDocuments.customerContract == "")) {
          showError(_l("l_please_upload_the_sign_picture_or_sign_the_doucment"))
          }else{
            submitData()
          }
          break;
  
        case "calendar-task":
          setCurrentStep(direction == 1 ? "task-details" : "assistance-mandate");
          break;
  
        case "task-details":
          // setCurrentStep(direction == 1 ? "installation-complete" : "calendar-task");
          setShowTaskSignature(true)
          break;
  
        case "installation-complete":
          setCurrentStep(direction == 1 ? "" : "calendar-task");
          break;
  
     
      }
    }
  };

  const calculatePercentage = (stepIndex) => {
    const percentage = (stepIndex / TOTAL_STEPS_COUNT) * 100;
    return {
      stepCount: stepIndex + 1,
      percentage: percentage
    };
  };

  const rejectProposal = () => {
    dispatch(
      showConfirmation(
        _l("l_are_you_sure"),
        _l("l_reject_proposal"),
        _l("l_reject"),
        _l("l_cancel"),
        () => {},
        async () => {
          try {
            const response = await BenchmarkServices.rejectSolarProposal(
              benchmarkDetail.project_id,
              benchmarkDetail.id,
              proposalDetails.proposal_id
            );
            if (response.status) {
              dispatch(getExternalBenchmarkDetails(hash));
            } else {
              showError(response.message);
            }
          } catch (e) {
            console.error(e);
            showError("l_something_went_wrong");
          } finally {
            dispatch(toastCloseAction());
          }
        }
      )
    );
  };

  const submitData = async () => {
    setLoadingNextStep(true)
      const backendResponse = await benchmarkServices.signatureAfterPayment(
        benchmarkDetail.project_id,
        1,
        benchmarkDetail.staff_id,
        benchmarkDetail.contact_id,
        benchmarkDetail.client_id,
        benchmarkDetail.id,
        customerDocuments.customerContract,
        customerDocuments.signature
      );
      if (backendResponse && backendResponse.status) {
        setCurrentStep("proposal-summary");
        setLoadingNextStep(false);
        dispatch(getExternalBenchmarkDetails(hash));
      } else {
        showError(_l("l_please_upload_the_sign_picture_or_sign_the_doucment"));
        setLoadingNextStep(false);
      }
  }

  // const handleCreateChatGroup =(projectId,main_key,main_sub_key,filter_key,proposalId,applysolarFilter = false)=>{
  //   localStorage.removeItem("firstDate");
  //   localStorage.removeItem("lastDate");
  //   dispatch(setFetchedTaskandMessages([]));
  //     customerServices.createChatGroup(projectId,main_key,main_sub_key,filter_key,proposalId).then((res)=>{
  //       if(res.status && res.data){
  //         let groupID = res.data._id
  //         localStorage.setItem("activeGroupId", groupID);
  //         setChatGroups([...chatGroups,res.data])
  //         chatServices.getGroupDetails( localStorage.getItem("chatUserId"), groupID).then((res) => {
  //           if (res.success) {
  //             localStorage.setItem("getGroupDetails", JSON.stringify(res.result))
  //             dispatch(getGroupDetails(res.result))       
  //           }else{
  //             // dispatch(showMessage("unsucess", _l("l_error"),res.message));
  //           }
  //         });
          
  //       }else{
  //         localStorage.setItem("activeGroupId",0);
  //       }
  //     })
    
  //   }

    const removeDuplicates = (arr) => {
      const seenIds = new Set();
      return arr.filter(item => {
        if (seenIds.has(item._id)) {
          return false;
        }
        seenIds.add(item._id);
        return true;
      });
    };


  useEffect(() => {
    hideLeftPanelNew();
    localStorage.setItem("is_external", 1);
    jQuery("body").addClass("bg-solar")
  });
  
  useEffect(() => {
    let textContentData = {
      backButtonText: step ? _l("back") : "",
      headerTitle: "l_solar_address_step_header",
      nextButtonText: step ? "" : "l_next",
      stepDescription: "",
      stepperProgress: 0,
      stepTitle: "",
    };

    document.body.classList.remove("px-0");

    switch (currentStep) {
      case "proposal-list":
        textContentData = {
          backButtonText: "",
          headerTitle: "l_my_proposals",
          nextButtonText: "l_next",
          stepDescription: "",
          stepperProgress: calculatePercentage(0),
          stepTitle: "",
          stepCount: 0
        };
        break;

      case "assistance-mandate":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "",
          nextButtonText: "",
          stepDescription: "",
          stepperProgress: calculatePercentage(1),
          stepTitle: "",
          showCancelButton: true,
          stepCount: 1
        };
        break;

      case "signature-mandate":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "",
          nextButtonText: "",
          stepDescription: "",
          stepperProgress: calculatePercentage(2),
          stepTitle: "",
          showCancelButton: true,
          stepCount: 2,
        };
        break;
      case "calendar-task":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "",
          nextButtonText: "",
          stepDescription: "",
          stepperProgress: calculatePercentage(3),
          stepTitle: "",
          showCancelButton: true,
          stepCount: 3,
        };
        break;
      case "task-details":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "",
          nextButtonText: "",
          // nextButtonText: "l_sign_proof",
          stepDescription: "",
          stepperProgress: calculatePercentage(4),
          stepTitle: "",
          showCancelButton: true,
          stepCount: 4,
        };
        break;
      case "installation-complete":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "",
          nextButtonText: "l_next",
          stepDescription: "",
          stepperProgress: calculatePercentage(4),
          stepTitle: "",
          showCancelButton: true,
          stepCount: 4,
        };
        break;
      case "proposal-detail":
        textContentData = {
          backButtonText: "l_back",
          headerTitle: "",
          nextButtonText: "",
          stepDescription: "",
          stepperProgress: calculatePercentage(1),
          stepTitle: "",
          showCancelButton: true,
          stepCount: 4,
        };
        break;
      case "proposal-summary":
        textContentData = {
          backButtonText: "",
          headerTitle: "",
          nextButtonText: "",
          stepDescription: "",
          stepperProgress: calculatePercentage(1),
          stepTitle: "",
          showCancelButton: true,
          stepCount: 4,
        };
        break;
    }
    setTextContent(textContentData);
  }, [currentStep, proposalDetails]);

  useEffect(() => {
    dispatch(dayGridMonth(true));
    dispatch(getExternalBenchmarkDetails(hash));

    if (step && step == "assistance-mandate") {
      searchParams.delete("step");
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    if(benchmarkDetail && benchmarkDetail.contact_details) {
      console.log("benchmarkDetail" , benchmarkDetail);
      customerServices.createChatGroup(0, "myr_support", benchmarkDetail.contact_details.user_type, benchmarkDetail.contact_details.staff_id, '', benchmarkDetail.contact_details.staff_id).then((res)=>{
        if(res.status && res.data){
          let groupID = res.data._id
          localStorage.setItem("activeGroupId", groupID);
          setChatGroups1(res.data._id)
          chatServices.getGroupDetails( localStorage.getItem("chatUserId"), groupID).then((res) => {
            if (res.success) {
              localStorage.setItem("getGroupDetails", JSON.stringify(res.result))
              dispatch(getGroupDetails(res.result))       
            }else{
              // dispatch(showMessage("unsucess", _l("l_error"),res.message));
            }
          });
          
        }else{
          localStorage.setItem("activeGroupId",0);
        }
      })
      }
  }, [benchmarkDetail])
  

  useEffect(() => {
    if (step) {
      setCurrentStep(step)
      setTimeout(() => {
        if (step && step == "assistance-mandate") {
          searchParams.delete("step");
          setSearchParams(searchParams);
        }
      }, 200);
    }
  }, [step])

  useEffect(() => {
    if ((chatGroups1 ) && (chatGroups2 ) && benchmarkDetail) {
      ChatServices.fetchGroups(chatUserId, benchmarkDetail.contact_details.project_id, 1,[chatGroups1,chatGroups2]).then((data) => {
        if (data && data.result) {
          dispatch(updateExternalGroupListMessages(data.result))
        }
      })
    }



  }, [chatGroups1, chatGroups2, benchmarkDetail])
  
  

  useEffect(() => {
    if (benchmarkDetail) {
      localStorage.setItem('full_name',benchmarkDetail.creator_details.full_name)
      localStorage.setItem('contact_image',benchmarkDetail.creator_details.profile_image_url)
      localStorage.setItem('staff_id',benchmarkDetail.creator_details.staff_id)
      localStorage.setItem('client_id',benchmarkDetail.contact_details.client_id);
      localStorage.setItem('contact_id',benchmarkDetail.contact_details.contact_id);
      localStorage.setItem('selectedOffice',benchmarkDetail.contact_details.project_id);
      localStorage.setItem('user_type',benchmarkDetail.contact_details.user_type);
      localStorage.setItem('email',benchmarkDetail.contact_details.email);
      localStorage.setItem('contact_role',1);
      localStorage.setItem('language',"french");
      dispatch(fetchAuthToken(benchmarkDetail.contact_details.email, benchmarkDetail.contact_details.contact_id, benchmarkDetail.contact_details.user_type));

      customerServices.createChatGroup(benchmarkDetail.project_id,"providers","providers",1, '',benchmarkDetail.contact_details.staff_id).then((res)=>{
        if(res.status && res.data){
          let groupID = res.data._id
          localStorage.setItem("activeGroupId", groupID);
          setChatGroups2(res.data._id)
          chatServices.getGroupDetails( localStorage.getItem("chatUserId"), groupID).then((res) => {
            if (res.success) {
              localStorage.setItem("getGroupDetails", JSON.stringify(res.result))
              dispatch(getGroupDetails(res.result))       
            }else{
              // dispatch(showMessage("unsucess", _l("l_error"),res.message));
            }
          });
          
        }else{
          localStorage.setItem("activeGroupId",0);
        }
      })
      proposal =
        benchmarkDetail.proposal_list && benchmarkDetail.proposal_list.length
          ? benchmarkDetail.proposal_list[0].proposals.find(
              (w) => w.proposal_id == benchmarkDetail.proposal_id
            )
          : {};
          let proposalList =
              benchmarkDetail.proposal_list &&
              benchmarkDetail.proposal_list.length &&
              benchmarkDetail.proposal_list[0].proposals
                ? benchmarkDetail.proposal_list[0].proposals
                : [];
          let propsal = proposalList.filter((p) => p.proposal_id == proposalId)
          setProposalDetails(propsal.length ? propsal[0] : {})
          setCurrentStep(propsal && propsal[0] && propsal[0].status == 4 ? "proposal-detail" : currentStep ? currentStep : "proposal-summary")
          // setCurrentStep(step ? step : propsal.length && propsal[0] && propsal[0].status == 2 ? "proposal-detail" : currentStep == "" ? benchmarkDetail.stuck_page_name == "proposal" ? "proposal-detail" : benchmarkDetail.stuck_page_name == "contract" ? "assistance-mandate" :benchmarkDetail.stuck_page_name == "calander" ? "calendar-task" : "proposal-detail" : currentStep )
      lightBoxData = [
        {
          src: benchmarkDetail.solar_map,
          type: getFileType(benchmarkDetail.solar_map),
        },
      ];
    }
  }, [benchmarkDetail]);

  return (
    <React.Fragment>
      {currentStep == "" || !benchmarkDetail ? <>
      <FullScreenLoader/>
      </> : <></>}
    
      {currentStep != "" && benchmarkDetail ?
      <StepperHeader
        backButtonHandler={() => {
          changeStep(STEPS.BACK);
        }}
        nextButtonHandler={() => {
          changeStep(STEPS.NEXT);
        }}
        textContent={textContent}
        transformClass={currentStep === "proposal-list" ? "transformX-0" : ""}
        headerClass={""}
        stepName={currentStep}
        isExternal={true}
        loadingNextStep={loadingNextStep}
      /> : <></>}
      {benchmarkDetail && Object.keys(benchmarkDetail).length > 0 ? 
      <>
      {currentStep == "proposal-summary" ? (
        <>
        <AcceptProposalCustomer
        proposal={proposalDetails}
        handleNextStep={(step)=>{
          setCurrentStep(step);
        }}
        setShowTaskSignature={setShowTaskSignature}

        />
        </>
      ):currentStep == "proposal-detail" && proposalDetails ? (
        <React.Fragment>
          <CustomerProposalDetail
            proposal={proposalDetails}
            rejectProposal={rejectProposal}
            handleNextStep={()=>{}}
            isExternal={true}
          />
        </React.Fragment>
      ) : currentStep == "assistance-mandate" ? (
        <React.Fragment>
          <AfterPaymentSignature
            handleNextStep={() => {
              setCurrentStep("signature-mandate")
            }}
            proposal={proposalDetails}
            isExternal={true}
            step={"assistance-mandate"}
            setCustomerDocuments={setCustomerDocuments}
            customerDocuments={customerDocuments}
          />
        </React.Fragment>
      ) : currentStep == "signature-mandate" ? (
        <React.Fragment>
          <AfterPaymentSignature
            handleNextStep={() => {
              setCurrentStep("assistance-mandate")
            }}
            proposal={proposalDetails}
            isExternal={true}
            step={"signature-mandate"}
            setCustomerDocuments={setCustomerDocuments}
            customerDocuments={customerDocuments}
            handleSaveSignature={submitData}
            loadingNextStep={loadingNextStep}
          />
        </React.Fragment>
      ) : currentStep == "calendar-task" ? (
        <React.Fragment>
          <SolarFullCalendar 
          isExternal={true}
          handler={()=>{
            setCurrentStep("task-details")
          }}
           />
        </React.Fragment>
      ) : currentStep == "task-details" ? (
        <React.Fragment>
          <>
          <SolarTaskDetails
          /></> 
        </React.Fragment>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      </> : <></>
      }
      {
        showTaskSignature ?
        <TaskSignatureModal 
        show={showTaskSignature}
        handleClose={()=>{
          setShowTaskSignature(false)
        }}
        />
         : <></>
      }
    </React.Fragment>
  );
};

export default ExternalProposalDetails;
