import React, { useEffect, useRef, useState } from "react";
import LockScreenHeader from './LockScreenComponents/LockScreenHeader'
import RequestCard from './LockScreenComponents/RequestCard'
import { SetSelectedTask,  getDefaultTopics, getTaskCount,  projectTaskCounts, setTaskUnreadCmtCntAll } from "../actions/customer";
import { _l, isAllowedToAccessPremiumFeatures } from "../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import LockTasks from "./LockScreenComponents/LockTasks";
import AiChatListing from "./LockScreenComponents/AiChatListing";
import { isLoadingData } from "../hooks/loader_helper";
import CommanLoader from "./Loader/CommanLoader";
import TaskCard from "./TaskCard";
import {CaretDoubleDown, CaretRight, ChatCircle, DotsThreeVertical, UserCircleGear, X } from "phosphor-react";
import { Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toggleFeatureRestrictionModal } from "../actions/general-actions/general-actions";
import { showConfirmation, showMessage, toastCloseAction } from "../actions/messages";
import HighPriorityActiveIcon from "../assets/icons/HighPriorityActiveIcon";
import HighPriorityIcon from "../assets/icons/HighPriorityIcon";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import ReactTooltip from "react-tooltip";
import FollowersDropdown from "./Dropdowns/FollowersDropdown";
import CommentDropdown from "./Dropdowns/CommentDropdown";
import DeleteDropdown from "./Dropdowns/DeleteDropdown";
import { removeDuplicateObjects } from "../hooks/utilities";
import CommanOffCanvas from "./Offcanvas/CommanOffCanvas";
import jquery, {  } from "jquery";
import CommanPlaceholder from "./Placeholder/CommanPlaceholder";
import { FloorPlanSummary } from "./SpaceOnBoarding/SpaceDetails/FloorPlanSummary";
import CreateTaskOffCanvasNew from "./Offcanvas/CreateTaskOffCanvasNew";


const LockscreenMain = ({pageName}) => {
  const dispatch = useDispatch();
  const spaces = localStorage.getItem("spaces") != null && localStorage.getItem("spaces") != undefined ? JSON.parse(localStorage.getItem("spaces")) : '';
  const savedProject = localStorage.getItem("selectedOffice");

  const [projectId, setProjectId] = useState(savedProject && (savedProject != null ? savedProject : (spaces && spaces.length ? spaces[0].project_id : 0))
  );

  const client_id = localStorage.getItem("client_id");
  const contact_id = localStorage.getItem("contact_id");
  const { taskFilters, 
    activeTaskStatusFilters, 
     globalSearchString,
     calendarData, 
     isAppliedUnreadComment, 
     commanoffcanvasIsOpen,
     getSelectedCalendarDate
    } = useSelector((state) => state.customer);
  const taskListState = useSelector((state) => state.customer.taskList);
  const savedDate = localStorage.getItem("selectedDate");
  const [leftPanelActiveFilters, setLeftActivePanelFilters] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [taskSearchString, setTaskSearchString] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [displayAttendance, setDisplayAttendance] = useState(false);
  const get_attendance = useSelector((state) => state.customer.getAttendance)
  const [selectedTab, setSelectedTab] = useState("start-schedule");
  const [selectedTaskId, setSelectedTaskId] = useState(0);
  const [checkedTask, setCheckedTask] = useState([]);
  const [taskPinned, setTaskPinned] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [taskId ,setTaskId] = useState()
  const [taskCount, setTaskCount] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const [unreadTaskList, setUnreadTaskList] = useState([])
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [initialMount, setInitialMount] = useState(true);

  
  const handleSelectedTask = (
    taskId = 0,
    show = "",
    isRequestedTask = null,
    convertRequestTaskId = 0
  ) => {

    // getMeetingLink(taskId);
    setShowTaskModal(false);
    setSelectedTaskId(taskId);
    // setConvertTaskId(convertRequestTaskId);
    localStorage.setItem("SelectedTask", taskId);
    localStorage.setItem(
      "TaskType",
      isRequestedTask && isRequestedTask != null ? isRequestedTask : 0
    );
    if (isRequestedTask || taskId) {

      setShowTaskModal(show);
    }
  };
  const taskCheckUncheck = (task_id) => {
    const taskPosition = checkedTask.findIndex(x => x == task_id)
    if (taskPosition === -1) {
      // checkedTask.push(task_id);
      setCheckedTask([...checkedTask, task_id])
    }
    else {
      setCheckedTask(checkedTask.filter((taskId) => taskId != task_id))
    }
  }

// lock screen header code start
    const spaceState = useSelector((state) => state.customer.spaces);
    // const [date, setDate] = useState(localStorage.getItem('__selectedDate') != undefined ? new Date(localStorage.getItem('__selectedDate')) : new Date());
    const [filter, setFilter] = useState([]);
    const { defaultTopics,isBackdrop} = useSelector((state) => state.customer);
    const [selectedSpace, setSelectedSpace] = useState({})
    const [commentsList, setCommentsList] = useState([]);
    const [taskStatus, setTaskStatus] = useState(1);
    const [taskPriority, setTaskPriority] = useState(2)
    const [selectedAssigneeIds, setSelectedAssigneeIds] = useState([]);
    const [selectedAssignee, setSelectedAssignee] = useState([]);
    const [dropDownToggle, setDropDownToggle] = useState(false);
    const [addChecklistItem, setAddChecklistItem] = useState("");
    const [taskTitle, setTaskTitle] = useState("");
    const [checklistItems, setChecklistItems] = useState([]);
    const [checkListTexts, setCheckListTexts] = useState([]);
    const [openFollowersDropdown, setOpenFollowersDropdown] = useState(false);
    const [openCommentTab, setOpenCommentTab] = useState(false);
    const [checklistStatus, setChecklistStatus] = useState(0);
    const [disableOnClickEvent, setDisableOnClickEvent] = useState(false);
    const [addChecklistItemEvent, setAddChecklistItemEvent] = useState("");
    const [commentCount, setCommentCount] = useState([]);
    const [taskAssignee, setTaskAssignee] = useState([]);
    const [loder, setLoder] = useState(false)
    const [CommentFile, setCommentFile] = useState([])
    const [selectedAssigneeIdsForTask, setselectedAssigneeIdsForTask] = useState([])
    const [startDate, setStartDate] = useState(new Date());
    const [showTaskList, setshowTaskList] = useState(true);
    const [inputActionIconShow, setinputActionIconShow] = useState(true);
    const [checklistItemAssigneeList, setChecklistItemAssigneeList] = useState([]);
    const { userTeams, } = useSelector((state) => state.customer);
    const selectedTask = useSelector((state) => state.customer.selectedTask);
    const [callUserList, setCallUserList] = useState([]);
    const [showReqestedTaskModal, setShowReqestedTaskModal] = useState(false);
    const [commonModalData, setCommonModalData] = useState();
    const [task_type, setTask_type] = useState('');
    const [IsKanbanView, setIsKanbanView] = useState(false);
    const [meetingUrl, setMeetingUrl] = useState("");
    const [imageCount, setImageCount] = useState(0);
    const [IsAttachmentCountUpdate, setIsAttachmentCountUpdate] = useState(false);
    const handleClose = () => setShowReqestedTaskModal(false);
    const handleShow = () => setShowReqestedTaskModal(true);
    const [taskAssigneePills, setTaskAssigneePills] = useState([]);
    const inputRef = useRef(null);
    const [checklistFiles, setchecklistFiles] = useState([])
    const [taskPage, setTaskPage] = useState(1);
    const [viewMoreLoader, setviewMoreLoader] = useState(false)
    const userType = localStorage.getItem("user_type");
    const [dropDownList, setdropDownList] = useState([])
    const [taskNewFilter, setTaskNewFilter] = useState([])
    const project_id = localStorage.getItem("selectedOffice");
    const {floorDetails} = useSelector((state) => state.benchmarkReducer);
    let defaultTaskStatus = [0, 1, 4, 5];


  useEffect(() => {
    if (taskFilters && taskFilters.length > 0 && pageName == "LiteVersion") {
      dispatch(
        projectTaskCounts(
          0,
          taskFilters,
          '',
          false,
          pageName
        )
      );
    }
  }, [taskFilters])

  useEffect(() => {
    if (typeof globalSearchString !== 'undefined') {
      setTaskSearchString(globalSearchString !== undefined ? globalSearchString : "");
    }
  }, [globalSearchString]);
  useEffect(() => {
    if (taskListState) { 
      let countArr = {}
      countArr.request_count = taskListState.filter((ts) => ts.is_requested == 1).length
      countArr.todo_count = taskListState.filter((ts) => ts.status == 1).length
      countArr.inprogress_count = taskListState.filter((ts) => ts.status == 4).length
      countArr.done_count = taskListState.filter((ts) => ts.status == 5).length
      setTaskCount(countArr)

      let pinTasks = [];
      let priorityTask = [];
      let otherTask = [];
      let unreadTask = [];
      let unreadCount = 0
      taskListState.forEach((task) => {
        unreadCount = unreadCount + task.unread_comment_count + task.unread_checklist_comment_count

        if (task.unread_comment_count > 0) {
          unreadTask.push(task)
        } else if (task.pinned == 1) {
          pinTasks.push(task)
        } else if (task.priority == '3') {
          priorityTask.push(task)
        } else {
          otherTask.push(task)
        }
      })
      
      if (isAppliedUnreadComment == true) {
        let tempTasklistforunreadtask = taskListState.filter((ts) => ts.unread_comment_count > 0 || ts.unread_checklist_comment_count > 0)
        setTaskList(tempTasklistforunreadtask)
      }else{
        // clearDateRangeFilter()
        setTaskList(unreadTask.concat(priorityTask, pinTasks, otherTask))
      }
            dispatch(setTaskUnreadCmtCntAll(unreadCount))
    }
    if(localStorage.getItem("selectedOffice")){
      localStorage.removeItem("selectedOffice");
    }

  }, [JSON.stringify(taskListState), JSON.stringify(taskFilters), isAppliedUnreadComment]);

  useEffect(() => {
    if(!isAllowedToAccessPremiumFeatures(projectId) && (projectId != null && projectId.length > 0))
    {
      dispatch(toggleFeatureRestrictionModal(true));
      navigate(location.state && location.state.from ? location.state.from : "/");
    }
  }, [projectId])

  useEffect(() => {
    if(savedProject){
      localStorage.removeItem("selectedOffice")
      // localStorage.setItem("isLiteVersion", true)
    }
  }, [])
  useEffect(() => {
    dispatch(getTaskCount(client_id, contact_id, null, moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD"), taskFilters, "", [], "", "", pageName))
  }, [taskFilters])

    useEffect(() => {
        let task_assignee = [];
        let task_assignee_ids = [];
        let task_checklist_assignee = [];
      let task_assignee_pills = [];
        if (selectedAssignee.length > 0) {
            selectedAssignee.map((ta) => {
              ta.categories && ta.categories.map((taskAssign) => {
                task_assignee_ids.push({
                  name: ta.main_key,
                  id: taskAssign.filter_key,
                })
              if (
                ta.main_key == "myr_staff_task" ||
                ta.main_key == "my_people" ||
                ta.main_key == "task_topic"
              ) {
                defaultTopics &&
                  defaultTopics.forEach((topic) => {
                    if (topic.main_key == ta.main_key) {
                      topic.categories.forEach((provider) => {
                        if (taskAssign.filter_key == provider.filter_key) {
                          task_assignee.push({
                            name: provider.title,
                            profile_image: provider.image,
                            id: provider.filter_key,
                          });
                        }
                      });
                    }
                  });
              } else {
                defaultTopics &&
                  defaultTopics.forEach((topic) => {
                    if (topic.main_key == ta.main_key) {
                      topic.categories.forEach((provider) => {
                        if (taskAssign.filter_key == provider.filter_key) {
                          if (topic.main_key != "equipments") {
                            task_assignee_pills.push({
                              name: provider.title,
                              id: provider.filter_key,
                              main_key:topic.main_key
                            });
                          }
  
                          let subCategory = []
                          provider.sub_category && provider.sub_category.forEach((sc) => {
                            taskAssign.sub_category && taskAssign.sub_category.forEach((dd) => {
                              if (sc.id == dd.id) {
                                  let title = taskTitle
                                  let tempTitle = title.split('-')
                                  if (tempTitle[1]) {
                                    title = taskAssign.sub_category[taskAssign.sub_category.length - 1].name + " - " + tempTitle[1]
                                  }else{
                                    if(!taskTitle.toLocaleLowerCase().includes(dd.name.toLocaleLowerCase())){
                                        title =  taskAssign.sub_category[taskAssign.sub_category.length - 1].name + `${taskTitle ? " - " : "" }` + taskTitle
                                      }
                                  }
                                subCategory.push(dd.id);
                                task_assignee_pills.push({
                                  name: sc.name,
                                  id: sc.id,
                                  main_key:topic.main_key
                                });
                              }
                            })
                          });
                          if (subCategory.length == 0) {
                            task_assignee_ids = task_assignee_ids.filter((tas) => tas.name != "equipments")
                          }
                          task_assignee_ids.push({
                            id: provider.filter_key,
                            name: provider.title,
                            sub_category: subCategory
                          })
                        }
                      });
                    }
                  });
              }
            });
          })
        }
      setTaskAssignee(task_assignee);
      setSelectedAssigneeIds(task_assignee_ids);
      setTaskAssigneePills(task_assignee_pills.slice())
      setdropDownList(task_assignee.slice().concat(task_assignee_pills))

      let ids = [];
      task_assignee_ids.map((data)=>{
       ids.push(data.id)
      })
      setselectedAssigneeIdsForTask(ids)
  }, [JSON.stringify(selectedAssignee)]);

    useEffect(() => {
      if (disableOnClickEvent == "" && addChecklistItemEvent != "") {
        handleAddChecklistItem(addChecklistItemEvent);
      }
    }, [disableOnClickEvent]);

    const is_active_class = (key) => {
        let activeClassTask = false;
        filter.forEach(element => {
            if (element == key) {
                activeClassTask = true;
            }
        });
        return activeClassTask;
    }
// useEffect(() => {
//   dispatch(getFloorDetails(project_id ? project_id : selectedSpace ? selectedSpace.rel_id : null));
  
// }, [project_id])

    const handleAddChecklistItem = (e) => {
        let checklistArr = [];
        if (e.type == "paste") {
            checklistArr = e.clipboardData.getData("Text").split("\n");
        }
        checklistArr = e.target.value.split("\n");
        let newArr = [];
        for (let i = 0; i < checklistArr.length; i++) {
            if (checklistArr[i].trim().length) {
                newArr.push({ description: checklistArr[i], finished: 0 });
            }
        }
       
        let checklistText = checkListTexts;
        let newList = [];
        newList.push({
            addedfrom: contact_id,
            description: (e.target.value).trim(),
            finished: "0",
            id: checklistItems.length + 1,
            is_added_from_contact: "1",
            list_order: "0",
            req_photo: "0",
            comments_count: 0,
            links_count: 0,
            attachments_count: 0,
            is_allowed_to_edit: 1,
            status: 0,
            comments:[],
            assignee_id:0,
        });
        setAddChecklistItem('')
        let arr = checklistItems.concat(newList)
        setChecklistItems(checklistItems.concat(newList))

        arr.forEach((item) => {
            checklistText[item.id] = (item.description).trim();
          });
        setCheckListTexts(checklistText);
      setNewChecklistItemDescription("");


    };

    const handleEditCheckList = (e, id) => {
        setCheckListTexts({
          ...checkListTexts,
          [`${id}`]: e.target.value,
        });
      };

      const itemPriority = (checklistId, isFinished, item, priority) => {
            const index = checklistItems.findIndex(
              (item) => item.id == checklistId
            );
            let updatedChecklist = checklistItems[index];
            updatedChecklist.priority = priority;
            setChecklistItems(
              Object.values({ ...checklistItems, [index]: updatedChecklist })
            );        
      };

      const handleDeleteChecklist = (id) => {
        dispatch(
          showConfirmation(
            _l("l_are_you_sure"),
            _l("l_you_want_to_delete_this_checklist"),
            _l("l_delete"),
            _l("l_cancel"),
            undefined,
            () => {
              removeChecklist(id);
            }
          )
        );
      };

      const removeChecklist = (id) => {
            setChecklistItems(checklistItems.filter((item) => item.id !== id));
            setAddChecklistItem("");
            dispatch(toastCloseAction());        
      };

      const handleTaskStatus = (
        checklistId,
        description,
        finished,
        checkUnCheck,
        index_id,
        priority,
        status
      ) => {
        const index = checklistItems.findIndex((item) => item.id == checklistId);
        let updatedChecklist = checklistItems[index];
        updatedChecklist.status = status;
        setChecklistItems(
          Object.values({ ...checklistItems, [index]: updatedChecklist })
        );
      };
      
      const itemFinished = (checklistData, checked, index) => {
        checklistData.finished = checked ? "1" : "0";
        let updatedChecklist = checklistItems[index];
        updatedChecklist.finished = checked;
        setChecklistItems(
          Object.values({ ...checklistItems, [index]: updatedChecklist })
        );
        
      };

      const handleChecklistAssignee = (user, checklist_id) => {
        let assigneeId = 0;
    
        if (user) {
          user.profile_image_url = user.user_image || user.profile_image;
          assigneeId = user.assigneeid || user.id;
        }            const index = checklistItems.findIndex(
              (item) => item.id === checklist_id
            );
            let updatedChecklist = checklistItems[index];
            let updatedChecklistAssigneeIds = checklistItems[index];
            updatedChecklist.assigned_details = user;
            updatedChecklistAssigneeIds.assignee_id = user.id
            if (user) {
              if (localStorage.getItem("user_type") == "contact") {
                updatedChecklist.assigned_details["id"] = user.id;
              } else {
                updatedChecklist.assigned_details["staffid"] = user.id;
              }
              updatedChecklist.assigned_details.full_name = user.name;
            }
    
            if (assigneeId) {
              updatedChecklist.assigned = 1;
            } else {
              updatedChecklist.assigned = 0;
            }
            setChecklistItems(
              Object.values({ ...checklistItems, [index]: updatedChecklist ,[index] : updatedChecklistAssigneeIds})
            );
      };

      useEffect(() => {
        if (taskAssignee) {
          setChecklistAssigneeList();
        }
      }, [JSON.stringify(taskAssignee), JSON.stringify(userTeams)]);

      useEffect(() => {
        jquery("#filter-show-list").on("click", function (event) {
          jquery("#show-filter-list").toggleClass("block");
        });
        setInitialMount(false);
      }, []);

      const setChecklistAssigneeList = async () => {
        let virtualSpaces = localStorage.getItem("selectedVirtualSpace");
        let teamIds = [];
        let taskMembers = [];
        taskAssignee &&
        taskAssignee.forEach((member) => {
            if (member.id > 0 && virtualSpaces) {
              teamIds.push(member.id);
            } else if (member.id < 0 && !virtualSpaces) {
              teamIds.push(Math.abs(member.id).toString());
            } else {
              taskMembers.push(member);
            }
          });
    
        if (teamIds.length) {
          try {
            if (userTeams) {
              userTeams.forEach((team) => {
                if (teamIds.includes(team.id) && team.manager_id) {
                  taskMembers.push({
                    id: team.manager_id,
                    name: team.manager_name,
                    profile_image: team.manager_profile_image,
                  });
                }
                if (teamIds.includes(team.id) && team.team.length) {
                  team.team.forEach((team_member) => {
                    taskMembers.push({
                      id: virtualSpaces
                        ? `-${team_member.member_id}`
                        : team_member.member_id,
                      name: `${team_member.firstname} ${team_member.lastname}`,
                      profile_image: team_member.profile_image,
                    });
                  });
                }
              });
            }
          } catch (e) {}
        }
        setChecklistItemAssigneeList(removeDuplicateObjects(taskMembers));
      };

      const updateAttchmentChecklistCount = (taskid, imageCount = 0) => {
        setImageCount(imageCount ? imageCount : 0);
        if (selectedTask) {
          setIsAttachmentCountUpdate(true);
        }
      };

  useEffect(() => {
    setLeftActivePanelFilters(taskFilters)
  }, [JSON.stringify(taskFilters)]);
  useEffect(() => {
    if (selectedTask) {
      localStorage.setItem("selectedOffice", selectedTask.rel_id)
      dispatch(getDefaultTopics(selectedTask.rel_id ? selectedTask.rel_id : 0,"","","",[]));

      setTask_type(selectedTask.is_requested && selectedTask.is_requested == 1 ? 'request-task' : 'task')
      setCommonModalData(selectedTask)
      setSelectedTaskId(selectedTask.task_id || selectedTask.id)
      if (selectedTask.is_requested == 1) {
        handleShow();
        setShowTaskModal(true);
      }else{
        setShowTaskModal(true);
      }
    }
    jquery(document).ready(function () {
      jquery("input").change(function () {
      });
    });

    return () => {
      jquery(document).unbind("ready");
    };
  }, [JSON.stringify(selectedTask),selectedSpace,taskTitle]);

  useEffect(() => {
    dispatch(SetSelectedTask(""))
  }, [selectedSpace,taskTitle])

  // useEffect(() => {
  //   if (!initialMount || getSelectedCalendarDate ) {
  //     if (!showTaskModal) {
  //       setShowTaskModal(false);
  //     }
  //     if (localStorage.getItem('is_task_delete')) {
  //       localStorage.removeItem('is_task_delete')
  //     } else {
        
  //       dispatch(
  //         getTaskList(
  //           client_id,
  //           contact_id,
  //           0,
  //           moment(getSelectedCalendarDate).format("YYYY-MM-DD"),
  //           1,
  //           activeTaskStatusFilters,
  //           taskFilters,
  //           ""
  //         )
  //       );
  //     }
  //     // dispatch(getTopicTasks(projectId,"",0,localStorage.getItem('selectedDate'),taskFilters));
  //   }
  // }, [getSelectedCalendarDate, JSON.stringify(taskFilters),projectId]); 
  const scroll = (status) => {
    const savedDate = localStorage.getItem("selectedDate");
    let page;
    let taskStaus = status;
    if (status == 0) {
      page = taskPage + 1;
      setTaskPage(page);
    } 
    taskStaus = [status];
    var isDateActive = 1;
    if (!jquery("#active_week_date").hasClass("c-gradinet-active-border")) {
      isDateActive = 0;
    }
    if (status == 0) {
      setviewMoreLoader({ ...loder, ["requestLoder"]: false });
    }
  }; 
      
// locak screen header code finish

// floor view start

const [checklistMode, setChecklistMode] = useState("classic");
  const [showFloorList, setShowFloorList] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState({ id: -1, title: "" });
  const [newChecklistItemDescription, setNewChecklistItemDescription] =  useState("");
  const [selectedTypeOfSpace, setSelectedTypeOfSpace] = useState({id: -1});


let spaceSelected = false;



  useEffect(() => {
    if (selectedFloor.id > 0) {
      floorDetails.type_of_space.map((spaceItem) => {
        return spaceItem.details.length > 0 && spaceItem.details.filter((check) => check.floor_id == selectedFloor.id).map((details, index) => {
            if (index == 0) {
              setSelectedTypeOfSpace(details);
              jquery("#checklistCreateRequest").focus();
            }
        })
      })
    }
  },[JSON.stringify(selectedFloor),floorDetails])

  const getTypeOfSpaceCount = (id) => {
    let spaceItemsArray = [];
    floorDetails && floorDetails.type_of_space.map((e) =>{
      return e.details && e.details.length > 0 && e.details.map((s) => {
        if (s.floor_id == id) {
          spaceItemsArray.push(s);
        }
      })
    })
    return spaceItemsArray.length;
  }

  const getFloorwiseChecklistCount = (id) => {
    return checklistItems.filter((w) => w.floor_id == id).length;
  }

  const applyFloorbaseFilter = (floor) => {
    setSelectedFloor(floor);
    setShowFloorList(false);
    // setChecklistItems(checklistStateData.filter((w) => w.floor_id == floor.id));
  };
  const switchToClassicView = (e) => {
    setChecklistMode(e.target.value); 
    setShowFloorList(false); 
    setSelectedFloor({...selectedFloor, id: -1, title: ""})
  }  
  
  
  const clearFloorFilter = () => {
    setSelectedFloor({ id: -1, title: "" });
    setShowFloorList(true);
    setSelectedTypeOfSpace({ id: -1, title: "" });
    setChecklistItems(checklistItems);
  }

  // floor view end
  return (
    <>


     
      <>
        <div className="ai-chat-main daily-schedule-main h-100 overflow-hiiden-auto d-none">
          <AiChatListing />
        </div>
        <div className="h-100">
        <div className={`lock-cards-wrapper h-100 lock_div d-flex flex-column ${showTaskModal || showReqestedTaskModal ? "w-auto" : ""
              }`} id="lock-cards-wrapper" ref={inputRef}>
          <LockScreenHeader setShowLoader={setShowLoader} setDisplayAttendance={setDisplayAttendance} displayAttendance={displayAttendance} get_attendance={get_attendance} setSelectedTab={setSelectedTab} lockCountTask={taskList} taskCount={taskCount} pageName= {pageName} taskPinned ={taskPinned} setTaskPinned={setTaskPinned}/>
         
          {
            // displayAttendance ? 
            displayAttendance == true && (get_attendance !== undefined && Object.keys(get_attendance) && Object.keys(get_attendance).length > 0) ?
              <div className="schedule-content-wrapper comman_vertical_btn_h vertical_scroll135 d-flex flex-column p-0 overflow-auto flex-grow-1">
                {
                  taskList && taskList
                    .map((provider, index) => {
                      return (provider.tasks ? <LockTasks provider={provider} /> : <RequestCard provider={provider} />)
                    })
                }
              </div>
              // : displayAttendance == false && (get_attendance !== undefined && Object.keys(get_attendance) && Object.keys(get_attendance).length == 0) ?
              : displayAttendance == false ?
                <>
                <div id="list_view" className={`schedule-content-wrapper overflow-auto d-flex flex-column px-0 pt-0 pb-0 radius_3 mt-2  d-flex flex-column ${checklistItems.length > 0 ? "" : ""}  ${showTaskModal || showReqestedTaskModal ? "list-view-toggle w-auto" : ""
                  // ${showTaskList ? "" : "comman-table-thead-bg"} 
              }`}>
                  <div id="list_view" className={` flex-grow-0 daily-schedule-main my-0`}>
                  <CreateTaskOffCanvasNew 
                    taskTitle={taskTitle}
                    setTaskTitle={setTaskTitle}
                    setshowTaskList={setshowTaskList}
                    pageName={pageName}
                    isFromLiteVersion={true}
                    isRequest={false}
                    />
                    {taskTitle.length > 0 ?
                      <>
                        {(taskAssigneePills && taskAssigneePills.length > 0)  || (taskAssignee && taskAssignee.length > 0) ?
                          <>
                            <div className="px-3 mb-1 d-flex flex-wrap">
                              {taskAssigneePills &&
                                taskAssigneePills.map((assignee, index) => {
                                  return (
                                    <div
                                      className="badge rounded-pill text-nowrap list-toggle-view-none me-1 mb-1"
                                      key={index}
                                    >
                                      <span className={` p-0 `}>
                                        {assignee.main_key == "my_task" ? "#" : ""}{_l(assignee.name)}
                                      </span>
                                      <ReactTooltip />
                                    </div>
                                  );
                                })
                              }
                              { taskAssignee.map((assignee, index) => {
                            return (
                              <a href="#/"
                                data-bs-toggle="tooltip"
                                className="comman-image-box multi-wrapper-border h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding me-1 mb-1"
                                data-tip={
                                  assignee.name ||
                                  assignee.full_name
                                }
                                data-place="right"
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                                key={index}
                              >
                                <div
                                  className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                  style={{
                                    backgroundImage: `url('${assignee.user_image ||
                                      assignee.profile_image
                                      }')`,
                                  }}
                                ></div>
                                <ReactTooltip />
                              </a>
                            );
                          })}
                            </div>
                          </> : <></>}
                      </>

                      : <></>}
                    {/* lockscreenheder finish */}
                    {isLoadingData("task_list") ? <><CommanLoader /></> : <>
                      <div className="kpi-main-wrapper shortcut-wrapper p-0 mt-2 d-none">
                        <div className="d-flex align-items-center justify-content-between">
                          {defaultTaskStatus.map((status, index) => {
                            var statusTitle =
                              status == 1
                                ? _l("l_todo_kpi")
                                : status == 4
                                  ? _l("l_inprogress_kpi")
                                  : status == 5 ? _l("l_done_kpi") : _l('l_request');
                            var statusColor =
                              status == 1
                                ? "color-white-80"
                                : status == 4
                                  ? "color-blue"
                                  : status == 5 ? "color-green" : 'color-orange';
                            return (
                              <a href="#/" className={`kpi-box bg-white-05  flex-row min-height-auto p-2`}key={index}>
                                <div className="kpi-upper-box flex-grow-1">
                                  <div className="d-flex align-items-center">
                                    <div className="kpi-upper-box-text c-font f-11">
                                      <span className={statusColor}>{statusTitle}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="kpi-statatics">50</div>
                                </div>
                              </a>
                            );
                          })}
                        </div>
                      </div>
                    <div className="mt-0 overflow-hidden padding-bottom-80px pe-0">
                    {selectedFloor.id >= 0 ? (
            <React.Fragment>
              <div className="d-flex justify-content-between w-100 border-bottom pb-2 ps-2 p-2 bg-white-03-solid position-sticky top-0 z-index-2 mt-3">
                <div className="title-fonts fw-semibold">
                  {selectedFloor.title}
                </div>
                <div className="d-flex align-items-center">
                  <div className="with_separator_10 d-flex align-items-center pe-0">
                    {
                      selectedFloor.id > 0
                        ?
                        <div class="d-flex align-items-center pe-3">
                          <div class="c-font color-white-80 f-12">{_l("l_type_of_space_label")}:</div>
                          <div class="c-font f-14 fw-semibold title-fonts ps-2">{getTypeOfSpaceCount(selectedFloor.id)}</div>
                        </div>
                        :
                        <React.Fragment></React.Fragment>
                    }
                    <div class="d-flex align-items-center pe-3">
                      <div class="c-font color-white-80 f-12">{_l("l_checklist")}:</div>
                      <div class="c-font f-14 fw-semibold title-fonts ps-2">{getFloorwiseChecklistCount(selectedFloor.id)}</div>
                    </div>
                  </div>
                  <a href="#/"
                    onClick={clearFloorFilter}
                    className="with_separator_10 before-dnone"
                  >
                    <X />
                  </a>
                </div>
              </div>

            </React.Fragment>
          ) : (
            <></>
          )}

        <div className={`${selectedFloor.id >= 0 ? "d-flex flex-grow-1 overflow-auto" : "overflow-auto"} pt-2`}>
          <div className={`left-content-part w-240 p-0 res-width-100 d-flex flex-column bg-transparent ${selectedFloor.id > 0 ? "" : "d-none"}`}>
            <div className="comman-content-scroll-wrapper overflow-hiiden-web">
              <div className="comman-content-scroll comman-verticle-tab border-right">
                {floorDetails.type_of_space.map((spaceItem) => {
                  return (
                    <React.Fragment>
                      {
                        spaceItem.details.length
                          ?
                          spaceItem.details.map((item, index) => {
                            let checklistCount = 0
                            checklistCount = checklistItems.filter((ch) => ch.type_of_space_item_id == item.id).length
                            if (item.floor_id != selectedFloor.id) {
                              return <React.Fragment></React.Fragment>
                            }
                            else {
                              if (!spaceSelected && selectedTypeOfSpace.id == -1) {
                                setSelectedTypeOfSpace(item);
                                spaceSelected = true;
                              }
                            }
                            return (
                              <li key={index} className={`comman-list p-0 w-100`}>
                                <a href="#/"
                                  className={`w-100 position-relative tab_link p13px h_45 ${selectedTypeOfSpace.id == item.id ? "active" : ""} `}
                                  onClick={() => {
                                    setSelectedTypeOfSpace(item)
                                    jquery("#checklistCreate").focus();
                                  }}
                                >
                                  <div className="d-flex align-items-center text-truncate">
                                    <div className="comman-list-left text-truncate flex-grow-1">
                                      <div className="upload-image-preview">
                                        <div className="d-flex align-items-center justify-content-start">
                                          <div class="image-text w100minus30 text-truncate">
                                            <div class="text-truncate c-font f-13">
                                              {item.title}
                                            </div>
                                            <div class="text-truncate c-font f-11 color-white-60 "></div>
                                            <ReactTooltip />
                                          </div>
                                          {checklistCount > 0 ?
                                            <div className="badge rounded-pill text-nowrap list-toggle-view-none me-1">
                                              <span className={` p-0 `}>
                                                {checklistCount > 99 ? `99+` : checklistCount}
                                              </span>
                                            </div>
                                            :
                                            <></>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div className="comman-list-right">
                                      <CaretRight />
                                    </div>
                                  </div>
                                </a>
                              </li>
                            )
                          })
                          :
                          <React.Fragment></React.Fragment>
                      }
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
          </div>
          {
            showFloorList
              ?
              <React.Fragment>
                <div className="">
                  <FloorPlanSummary
                    showGeneralAreaAsFloor={true}
                    hideFloorDetails={true}
                    kpiData={[[{ field: "l_type_of_space_label", value: getTypeOfSpaceCount }, { field: "l_checklist", value: getFloorwiseChecklistCount }]]}
                    onFloorSelect={(floor) => {
                      applyFloorbaseFilter(floor);
                    }}
                  />
                </div>
              </React.Fragment>
              :
              <>
                <div className="d-flex flex-column w-100">
                 <div className={`checklist-listing-wrapper p-15 py-0 pb-0 px-0 ${selectedFloor.id >= 0 ? "right-content-part width-calc-240 ps-0 overflow-hiiden-auto checklist-with-content-part" : "w-100"}`}>
                    { checklistItems.length > 0 ? <div className=''>
                        {checklistItems && checklistItems.length > 0 && checklistItems.map((item, index) => {
                          return (
                            <div key={index} className="task-checklist">
                              <div className="d-flex justify-content-between">
                                <div className="left-checklist">
                                  <div className="d-flex align-items-start">
                                    <div
                                      className="task-creator-icon"
                                      data-tip={`${localStorage.getItem("full_name")}`}
                                      data-effect="solid"
                                      data-delay-show='1000'
                                      data-class="tooltip-main"
                                      data-place="top"
                                      data-bs-toggle="tooltip"
                                    >
                                      <div
                                        className="h25w25 comman-round-box with-bg rounded-circle bg-white-05"
                                        data-bs-toggle="creator"
                                      >
                                        <div
                                          className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                        style={{
                                          backgroundImage: `url(${localStorage.getItem("contact_image")})`,
                                        }}
                                        ></div>
                                      </div>
                                    </div>
                                    <div className="checklist-list">
                                      <div className="form-check">
                                        <div
                                          className={`d-flex align-items-start `}
                                        >
                                          <input
                                            className="form-check-input mt-1"
                                            type="checkbox"
                                            checked={item.finished != 0}
                                            onChange={(e) => {
                                              itemFinished(
                                                item,
                                                e.target.checked,
                                                index
                                              );
                                            }}
                                            id={`taskdetail_checkbox_${item.id}`}
                                          //   disabled={isSystemTask ? true : false}
                                          />
                                          <textarea
                                            id={`checklist_input_${item.id}`}
                                            rows={1}
                                            className="w-100 bg-transparent border-0 checklist_textarea pt-1 ps-2 pe-0"
                                            value={checkListTexts[item.id]}
                                            onInput={(e) => { }}
                                            onChange={(e) =>
                                              handleEditCheckList(e, item.id)
                                            }
                                            onBlur={(e) => {
                                              if (e.target.value.length < 1) {
                                                let checklisttxt =
                                                  checklistItems.find(
                                                    (p) => p.id === item.id
                                                  ).description;
                                                dispatch(
                                                  showMessage(
                                                    "unsucess",
                                                    _l("l_error"),
                                                    _l("l_checklist_empty")
                                                  )
                                                );
                                                setCheckListTexts({
                                                  ...checkListTexts,
                                                  [`${item.id}`]: checklisttxt,
                                                });
                                              } else {
                                                checklistItems.find(
                                                  (p) =>
                                                    p.id === item.id &&
                                                    ((p.description = e.target.value),
                                                      true)
                                                );
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <ReactTooltip />
                                <div className={`right-checklist-action dropend`}>
                                  <div className="d-flex align-items-center">
                                    <div className={`bottom-left-checklist `}>
                                      <div className="d-flex align-items-center table-list-attechment">
                                        <div
                                          className={`bottom-right-checklist comman_action_icon me-2 for-accessible`}
                                        >
                                          <div className="d-flex align-items-center">
                                            {item.assigned_details &&
                                              (item.assigned_details.full_name ||
                                                (item.assigned_details &&
                                                  item.assigned_details.name)) ? (
                                              <div className="assignee-img-with-remove pe-3">
                                                <div
                                                  className="h20w20 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle"
                                                  data-tip={
                                                    item.assigned_details.full_name ||
                                                    (item.assigned_details &&
                                                      item.assigned_details.name)
                                                  }
                                                  data-place="left"
                                                  data-effect="solid"
                                                  data-delay-show='1000'
                                                  data-class="tooltip-main"
                                                >
                                                  <div
                                                    className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                                    style={{
                                                      backgroundImage: `url('${item.assigned_details
                                                        .profile_image_url ||
                                                        item.assigned_details
                                                          .profile_image
                                                        }')`,
                                                    }}
                                                  ></div>
                                                  <a href="#/"
                                                    className="remove-img"
                                                    onClick={() => {
                                                      handleChecklistAssignee(
                                                        "",
                                                        item.id
                                                      );
                                                    }}
                                                  >
                                                    <X
                                                      size={10}
                                                      weight="light"
                                                      className="c-icons"
                                                    />
                                                  </a>
                                                </div>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                            <div
                                              className={`dropdown me-2 `}
                                            >
                                              <a href="#/"
                                                className="action_icon with_bg justify-content-center align-items-center"
                                                type="button"
                                                data-tip={`${_l("l_add_assignee")}`}
                                                data-effect="solid"
                                                data-delay-show='1000'
                                                data-class="tooltip-main"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                onClick={() => {
                                                  setOpenFollowersDropdown(true);
                                                }}
                                              >
                                                <div className="on-hover-active-toggle-img text-on-hover-active-green">
                                                  {item.assigned > 0 ? (
                                                    <UserCircleGear
                                                      size={16}
                                                      weight="light"
                                                      className="c-icons active"
                                                    />
                                                  ) : (
                                                    <>
                                                      <UserCircleGear
                                                        size={16}
                                                        weight="light"
                                                        className="c-icons"
                                                      />
                                                    </>
                                                  )}
                                                </div>
                                              </a>
                                              <FollowersDropdown
                                                openFollowersDropdown={
                                                  openFollowersDropdown
                                                }
                                                setOpenFollowersDropdown={
                                                  setOpenFollowersDropdown
                                                }
                                                //   task_id={task_id}
                                                checklistId={item.id}
                                                handler={handleChecklistAssignee}
                                                checklistAssigneesList={checklistItemAssigneeList}
                                                is_task_checklist={1}
                                                selectedId={
                                                  item.assigned_details != ""
                                                    ? localStorage.getItem(
                                                      "user_type"
                                                    ) == "contact"
                                                      ? item.assigned_details &&
                                                      item.assigned_details.id
                                                      : item.assigned_details &&
                                                      item.assigned_details.staffid
                                                    : 0
                                                }
                                              />
                                            </div>
                                            <div
                                              data-effect="solid"
                                              data-delay-show='1000'
                                              data-class="tooltip-main"
                                              data-tip={_l(
                                                item.finished == 0
                                                  ? item.status == 0
                                                    ? "l_todo"
                                                    : "l_inprogress"
                                                  : "l_done"
                                              )}
                                              className={``}
                                            >
                                              <a href="#/"
                                                className={`white-border-box color-white-60 action_icon with_bg `}
                                                id="TaskListStatusDropdown"
                                                aria-expanded="false"
                                                onClick={() => {
                                                  setChecklistStatus(
                                                    item.status == 0 ? 2 : 0
                                                  );
                                                  handleTaskStatus(
                                                    item.id,
                                                    item.description,
                                                    item.finished,
                                                    "",
                                                    index,
                                                    item.priority,
                                                    item.status == 0 ? 2 : 0
                                                  );

                                                }}
                                              >
                                                <span
                                                  className={`c-dots c-10 ${item.finished == 0
                                                    ? item.status == 0
                                                      ? "in-progress-white"
                                                      : "in-progress-blue"
                                                    : "done"
                                                    } rounded-circle`}
                                                ></span>
                                              </a>{" "}
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          className={`comman_action_icon me-2`}
                                          data-tip={`${_l("l_priority")}`}
                                          data-effect="solid"
                                          data-delay-show='1000'
                                          data-class="tooltip-main"
                                        >
                                          <div
                                            className={` white-border-box d-flex align-items-center h25w25 action_icon with_bg border-0 `}
                                          >
                                            <div className="priority-checkbox text_wrap position-relative">
                                              <input
                                                type="checkbox"
                                                className="absolute-input"
                                                id="priority"
                                                onClick={(e) => {
                                                  itemPriority(
                                                    item.id,
                                                    item.finished,
                                                    item.description,
                                                    item.priority == 1 ? 0 : 1
                                                  );
                                                }}
                                                checked={item.priority == 1}
                                              />
                                              <div
                                                className="check_box"
                                                onChange={() => {
                                                  // handleChecklistAssignee(0, item.id, 'priority')
                                                }}
                                              >
                                                <HighPriorityActiveIcon className="HW18 check_span_img active" />
                                                <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          className={`dropend d-flex align-items-center me-2`}
                                          data-tip={_l("l_comments")}
                                          data-effect="solid"
                                          data-delay-show='1000'
                                          data-class="tooltip-main"
                                        >
                                          <UncontrolledDropdown
                                            className={`w-100 d-flex align-items-center `}
                                            direction="right"
                                          >
                                            <DropdownToggle
                                              data-toggle="dropdown d-flex align-items-center"
                                              tag="div"
                                            >
                                              <a href="#/"
                                                className="comman_action_icon d-flex align-items-center"
                                                type="button"
                                                id="writeComMentDropdown"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                data-bs-auto-close="outside"
                                                onClick={(e) =>
                                                  setOpenCommentTab(true)
                                                }
                                              >
                                                <div className="d-flex align-items-center">
                                                  <div className="action_icon with_bg position-relative on-hover-active-toggle-img">
                                                    <ChatCircle
                                                      size={16}
                                                      weight="light"
                                                      className="c-icons"
                                                    />
                                                  </div>
                                                  <span
                                                    className={`rounded-pill badge-custom p-0 ${item.unread_comment_count > 0
                                                      ? ""
                                                      : "d-none"
                                                      }`}
                                                    data-unread-cmt-id={item.id}
                                                  >
                                                    {item.unread_comment_count}
                                                  </span>

                                                </div>
                                              </a>
                                            </DropdownToggle>
                                            <DropdownItem
                                              toggle={false}
                                              className="p-0"
                                            >
                                              {
                                                <CommentDropdown
                                                  docType={"imageview"}
                                                  // data={selectedTask}
                                                  key={index}
                                                  openCommentTab={openCommentTab}
                                                  setOpenCommentTab={
                                                    setOpenCommentTab
                                                  }
                                                  projectId={selectedSpace.project_id}
                                                  // task_id={task_id}
                                                  updatedCommentCount={(count) => {
                                                    setCommentCount({
                                                      ...commentCount,
                                                      [item.id]: count,
                                                    });
                                                  }}
                                                  checklistId={item.id}
                                                  allComment={1}
                                                  unContolList={true}
                                                  is_liteversion={true}
                                                  checklistItems={checklistItems}
                                                  setChecklistItems={setChecklistItems}
                                                  CommentFile={checklistFiles}
                                                  setCommentFile={setchecklistFiles}
                                                />
                                              }
                                            </DropdownItem>
                                          </UncontrolledDropdown>
                                        </div>
                                      </div>
                                      <ReactTooltip />
                                    </div>

                                    <div
                                      className={`dropdown dropdown-center d-flex align-items-center px-0 ${item.is_allowed_to_edit == 1
                                        ? ""
                                        : "for-disabled"
                                        } `}
                                    >
                                      <a href="#/"
                                        className={`line-height-0 `}
                                        type="button"
                                        id="deLeteDropdown"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <DotsThreeVertical
                                          size={18}
                                          weight="light"
                                          className="c-icons"
                                        />
                                      </a>
                                      <ReactTooltip />

                                      <DeleteDropdown
                                        handleDelete={() => {
                                          handleDeleteChecklist(item.id);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );

                      })}
                      </div> : <></>}
                    
                  </div>
                </div>
              </>
          }
        </div>
                      { showFloorList || checklistMode === "floor" ? <> </> :
                      <div className={`d-flex ${checklistItems.length > 0 ? "mt-3" :""}`}>
                        {taskList.filter((tittle)=>tittle.name.toLowerCase().includes(taskSearchString && taskSearchString.length > 0 ? taskSearchString.toLocaleLowerCase() : "")).length > 0 ?<div className={`w_80px after-separator position-relative h100minus20px d-none ${commanoffcanvasIsOpen ? "d-none" : ""}`}>
                          <div className='c-font color-white-80 f-12 pb-2 text-center title-fonts d-none'>
                            02:00 PM
                          </div>
                        </div> : <></>}
                        <div className={`Litecardwrapper radius_3 d-flex flex-column mb-0 position-relative pt-0 ${commanoffcanvasIsOpen ? "" : "w_80calc"}`}>
                        {/* ${isBackdrop ? "5" : ""} */}
                          {isBackdrop ? 
                          <div className={`${taskList.filter((tittle)=>tittle.name.toLowerCase().includes(taskSearchString && taskSearchString.length > 0 ? taskSearchString.toLocaleLowerCase() : "")).length > 0 ? "modal-overlay-box-shadow position-absolute z-index-7" :""} `}></div>
                          : <></>}
                          {taskList.filter((tittle)=>tittle.name.toLowerCase().includes(taskSearchString && taskSearchString.length > 0 ? taskSearchString.toLocaleLowerCase() : "")).length > 0 ?
                            taskList 
                            
                            .slice(
                              0,
                              taskPage > 1 ? (taskPage - 1) * 10 + 10 : taskPage * 10
                            ).filter((tittle)=>tittle.name.toLowerCase().includes(taskSearchString && taskSearchString.length > 0 ? taskSearchString.toLocaleLowerCase() : ""))
                              .map((task, index) => {
                                // return (provider.tasks ? <LockTasks provider={provider} /> : <RequestCard provider={provider} />)
                                return (
                                  <TaskCard
                                    key={task.id}
                                    task={task}
                                    active={selectedTaskId == task.id}
                                    project_id={projectId}
                                    handler={handleSelectedTask}
                                    pageName='LiteVersion'
                                    taskCheckUncheck={taskCheckUncheck}
                                    checkedTask={checkedTask}
                                    taskPinned={taskPinned}
                                    setTaskPinned={setTaskPinned}
                                    contacts={contacts}
                                    setTaskId={setTaskId}
                                    setCallUserList={setCallUserList}

                                  // getTaskDetails = {handleTask}
                                  />
                                )
                              }) : 
                            <>
                              <CommanPlaceholder imgType="NoDoneTask-task" mainWidth="m-auto" placeholderText={_l("l_no_task_found")} />
                            </>
                          }
                          {(taskList.filter((tittle)=>tittle.name.toLowerCase().includes(taskSearchString.length > 0 ? taskSearchString.toLocaleLowerCase() : "")).length >= (taskPage - 1) * 10 + 10 &&
                          taskList.filter((tittle)=>tittle.name.toLowerCase().includes(taskSearchString.length > 0 ? taskSearchString.toLocaleLowerCase() : "")).length != (taskPage - 1) * 10 + 10) ||
                          viewMoreLoader.requestLoder ? (
                          <a href="#/"
                            className="btn btn-white-03 w-100 view-more-btn"
                            onClick={() => {
                              setviewMoreLoader({ ...loder, ["requestLoder"]: true });
                              scroll(0);
                            }}
                          >
                            <div className="d-flex align-item-center justify-content-center">
                              <span className=" title-fonts c-font f-12 ms-2">
                                {_l("l_view_more_btn")} (
                                {taskPage > 1
                                  ? taskList.filter((tittle)=>tittle.name.toLowerCase().includes(taskSearchString.length > 0 ? taskSearchString.toLocaleLowerCase() : "")).length - ((taskPage - 1) * 10 + 10)
                                  : taskList.filter((tittle)=>tittle.name.toLowerCase().includes(taskSearchString.length > 0 ? taskSearchString.toLocaleLowerCase() : "")).length - 10}
                                )
                              </span>
                              <CaretDoubleDown size={12} className="c-icons mx-2" weight="light" />
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                className={`ms-2 ${loder.requestLoder ? "opacity-1" : "opacity-0"
                                  }`}
                                aria-hidden="true"
                              />
                            </div>
                          </a>
                        ) : (
                          <></>
                        )}
                        </div>
                      </div> } 
 
                    </div> 
                    </>}

                  </div>
                </div>
                </>
                :
                <div className="bg-white-03 d-flex flex-column pt-4 flex-grow-1 overflow-auto">
                 
                </div>
          }
       

        </div>
        </div>
      </>
      {/* } */}
      {showTaskModal ? <CommanOffCanvas
                show={showTaskModal}
                handleClose={() => {
                  setShowTaskModal(false);
                  // setIsRequestTask()
                  handleClose();
                  setCommonModalData();
                  setSelectedTaskId(0);
                  setTask_type('')
                }}
                pinHandler={(id, type, flag) => {
                  // handlePinUnPin(id, type, flag);
                }}
                data={commonModalData}
                docType={task_type}
                setSelectedId={setSelectedTaskId}
                selectedTaskId={selectedTaskId}
                IsKanbanView={IsKanbanView}
                meetingUrl={meetingUrl}
                handleParentComponentAction={(type, taskData) => {
                  if (task_type == 'task') {
                    if (type == 'attachmentCountUpdate') {
                      updateAttchmentChecklistCount(taskData.task_id, taskData.flag)
                      return;
                    } else if (type == 'handleSelectedTask') {
                      handleSelectedTask(taskData.task_id, taskData.flag)
                      return;
                    } else if (type == 'convertedTaskAction') {
                      handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
                      return;
                    }
                  } else if (task_type == 'request-task') {

                    if (type == 'convertedTaskAction') {
                      handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
                      return;
                    }
                  }

                }}
                pageName={"Liteversion"}
                contacts={contacts}
                callUserList={callUserList}
              /> : <></>
            }

      {showLoader ? <CommanLoader /> : <></>}
    </>
  )
}

export default LockscreenMain