import React from 'react';
// import CSS from '../css/icon_main.css';

function NoComments(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>


			<svg width="75" height="76" viewBox="0 0 75 76" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons h-100 w-100 ${CommanHW}`}>
				<path d="M53.4885 61.3877C52.598 61.455 52.0717 61.5254 51.5423 61.5254C45.5265 61.5254 39.5107 61.4856 33.4888 61.5774C32.4535 61.612 31.4539 61.9634 30.6247 62.5841C26.096 66.1459 21.6499 69.8086 17.1946 73.4622C16.243 74.2425 15.273 74.8851 14.0185 74.2823C12.8128 73.7009 12.7364 72.5809 12.7517 71.4059C12.7823 68.753 12.7302 66.1031 12.773 63.4501C12.7945 62.1037 12.2774 61.4642 10.882 61.5193C9.4867 61.5744 8.02711 61.5468 6.59812 61.5193C3.2475 61.4703 1.04131 59.4355 1.03213 56.1277C0.98929 41.44 0.98929 26.7524 1.03213 12.0647C1.03213 8.76915 3.13425 6.73431 6.41144 6.68229C8.59929 6.65169 11.084 6.10703 12.8924 6.95463C14.5693 7.73797 15.5546 10.0451 16.7969 11.7159C28.6041 27.6418 40.4053 43.5728 52.2003 59.5089C52.5889 60.0352 52.9346 60.5707 53.4885 61.3877Z" strokeMiterlimit="10" />
				<path d="M74.1281 34.1327C74.1281 41.2684 74.1771 48.4072 74.0853 55.5522C74.0381 56.8578 73.7027 58.1368 73.103 59.2975C72.4115 60.6592 71.8209 60.5919 70.8846 59.3281C64.9687 51.3478 59.0529 43.3614 53.137 35.3689C46.5275 26.44 39.9242 17.5071 33.327 8.57003C33.021 8.14776 32.8833 7.59696 32.666 7.10737C33.174 6.96968 33.6789 6.71263 34.196 6.71263C45.6197 6.69019 57.0435 6.69019 68.4672 6.71263C72.0993 6.71263 74.122 8.7628 74.1342 12.4317C74.1464 19.6439 74.1281 26.8929 74.1281 34.1327Z" strokeMiterlimit="10" />
				<path d="M19.043 1C19.3918 1.10404 19.6182 1.64259 19.8814 1.99754C36.2357 24.0964 52.588 46.1962 68.9382 68.2971" strokeMiterlimit="10" />
			</svg>


		</React.Fragment>
	)
}

export default NoComments