import React from 'react';
// import CSS from '../css/icon_main.css';

function UploadDragDroIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="42" height="57" viewBox="0 0 42 57" fill="none" xmlns="http://www.w3.org/2000/svg"  className={`comman-icons ${CommanHW}`}>
<rect x="15.9863" y="0.543945" width="23.7397" height="23.7397" rx="3" fill="#1D3648"/>
<rect x="16.4863" y="1.04395" width="22.7397" height="22.7397" rx="2.5"  stroke-opacity="0.8" stroke-dasharray="5 5"/>
<rect x="0.962891" y="16.5439" width="21" height="21" rx="3" fill="#1D3648"/>
<rect x="1.46289" y="17.0439" width="20" height="20" rx="2.5"  stroke-opacity="0.8" stroke-linecap="round"/>
<path d="M27.8594 8.64746V16.1803"  stroke-opacity="0.8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24.7773 13.0986L27.8589 16.1802L30.9405 13.0986"  stroke-opacity="0.8" stroke-linecap="round" stroke-linejoin="round"/>
<g clip-path="url(#clip0_10405_408047)">
<path d="M12.6563 43.2096C12.1363 42.9047 11.7579 42.4066 11.6038 41.8238C11.4498 41.2411 11.5324 40.621 11.8338 40.099C12.1352 39.577 12.6308 39.1953 13.2125 39.0374C13.7942 38.8794 14.4148 38.958 14.9388 39.2559L18.4972 41.1552L12.334 30.4802C12.0313 29.9559 11.9493 29.3329 12.106 28.7481C12.2627 28.1633 12.6452 27.6647 13.1695 27.362C13.6938 27.0593 14.3169 26.9773 14.9017 27.134C15.4864 27.2907 15.985 27.6733 16.2877 28.1975L19.4834 33.7327C19.1807 33.2084 19.0987 32.5854 19.2554 32.0006C19.4121 31.4158 19.7947 30.9172 20.319 30.6145C20.8432 30.3118 21.4663 30.2298 22.0511 30.3865C22.6359 30.5432 23.1344 30.9258 23.4371 31.45L24.3502 33.0315C24.0475 32.5072 23.9655 31.8842 24.1222 31.2994C24.2788 30.7146 24.6614 30.216 25.1857 29.9133C25.71 29.6106 26.3331 29.5286 26.9178 29.6853C27.5026 29.842 28.0012 30.2246 28.3039 30.7489L30.3583 34.3072C30.9578 35.3456 31.3469 36.4919 31.5035 37.6807C31.66 38.8695 31.5808 40.0775 31.2704 41.2357C30.9601 42.3939 30.4247 43.4796 29.6947 44.4309C28.9648 45.3822 28.0546 46.1804 27.0162 46.7799C22.6494 49.3011 20.1302 47.5926 12.6563 43.2096Z" fill="#1D3648"  stroke-opacity="0.8" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_10405_408047">
<rect width="29.2181" height="29.2181" fill="white" transform="translate(1.125 31.1533) rotate(-30)"/>
</clipPath>
</defs>
</svg>


		</React.Fragment>
	)
}

export default UploadDragDroIcon