import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { dayGridDay, dayGridMonth, dayGridWeek, leftPanelFilters, setCalendarView, setDashBoardView, setDaterangeFilterApplied, setSelectedDateView, setSelectedGloablView } from '../../actions/customer';
import { Buildings, ListBullets } from 'phosphor-react';
import { _l } from '../../hooks/utilities';
import moment from 'moment';
import CalendarBlankIcon from '../../assets/icons/CalendarBlankIcon';
import WeekViewIcon from '../../assets/icons/WeekViewIcon';
import DayViewIcon from '../../assets/icons/DayViewIcon';
const ToggleViewDropdown = ({
  pageName
}) => {
  const {
    globalView,
    isDayGridWeek,
    isDayGridMonth,
    isDayGridDay,
    dateView,
    taskFilters,
    dashBoradView
  } = useSelector((state) => state.customer);
    const dispatch = useDispatch();
    let favView = localStorage.getItem('fav_dashboard')


    // ----------------------------------- this code is commented beacuse now the view of calendar is based on daterange selection this code impact dates into local storage when applied different filters from daterange selection ----------------------------------- //

    // useEffect(() => {
    //   if (dateView) {
    //     switch (dateView) {
    //       case "dayView":
    //         if(taskFilters.filter(filter => filter.filter_key == "date_range_filter").length > 1){
    //           dispatch(leftPanelFilters("", "", "all"));
    //         }
    //         // localStorage.setItem("selectedDate", new Date());
    //         localStorage.setItem("tasklistView", globalView == "listView" ? "liteVersionView" : globalView)
    //         dispatch(setSelectedGloablView(globalView == "listView" ? "liteVersionView" : globalView))
    //         break;
    //       case "monthView":
    //         const todayDate = new Date();
    //         const firstDayOfMonth = moment(todayDate).startOf('month');
    //         const lastDayOfMonth = moment(todayDate).endOf('month');
    //         const monthDatesArray = [];
  
    //         let currentDate = firstDayOfMonth;
    //         while (currentDate.isSameOrBefore(lastDayOfMonth)) {
    //           monthDatesArray.push({ date: currentDate.format('YYYY-MM-DD') });
    //           currentDate.add(1, 'day');
    //         }
    //         const fromDateMonth = monthDatesArray[0].date;
    //         const toDateMonth = monthDatesArray[monthDatesArray.length - 1].date;
    //         var title = fromDateMonth + " - " + toDateMonth;
    //         if (pageName != "dashboardnew") {
    //           localStorage.setItem("fromDate", fromDateMonth)
    //           localStorage.setItem("toDate", toDateMonth)
    //         }
    //         /**Commented as on 21st Nov as it is no more required */
    //         // dispatch(
    //         //   leftPanelFilters(
    //         //     {
    //         //       main_key: "date_range_filter",
    //         //       filter_key: "date_range_filter",
    //         //       title: title,
    //         //     },
    //         //     "add"
    //         //   )
    //         // );
    //         break;
    //       case "weekView":
    //         var fromDate = moment(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1))).format("YYYY-MM-DD");
    //         var toDate = moment(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 7))).format("YYYY-MM-DD");
    //         var selectedDate = moment(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 4))).format("YYYY-MM-DD");
    //         var title = fromDate + " - " + toDate;
    //         localStorage.setItem("selectedDate", selectedDate);
    //         localStorage.setItem("fromDate", fromDate);
    //         localStorage.setItem("toDate", toDate);
    //         dispatch(
    //           leftPanelFilters(
    //             {
    //               main_key: "date_range_filter",
    //               filter_key: "date_range_filter",
    //               title: title,
    //             },
    //             "add"
    //           )
    //         );
    //         break;
  
    //       default:
  
    //         break;
    //     }
    //   }
    // }, [dateView])
  return (
    <>
      <ul className="dropdown-menu w-170" aria-labelledby="ToggleViewDropdown">
        {/* {globalView != "calendarView" ? <>
          {/* <li className='hr_sepretr'>
            <a href="#/" className={`dropdown-item ${globalView == "listView" || globalView == "kanbanView" ? "active with-tick" : ""} `}
              onClick={() => {
                localStorage.setItem("tasklistView", "listview")
                dispatch(setSelectedGloablView("listView"));
              }}>
              <div className="d-flex align-items-center justify-content-between" >
                <div className='c-list-icon'>
                  <div className='h25w25 d-flex align-items-center'>
                    <ListBullets
                      size={16}
                      className="c-icons"
                      weight="light"
                    />
                  </div>
                </div>
                <div className="ps-2 w100minus30 text_wrap pe-3">
                  <div className='c-list-detail text-truncate '>{_l("l_list_view")}</div>
                </div>
              </div>
            </a>
          </li>
          <li className='hr_sepretr'>
            <a href="#/" className={`dropdown-item  ${globalView == "liteVersionView" ? "active with-tick" : ""}`}
              onClick={() => { 
                localStorage.setItem("tasklistView", "liteVersionView")
                dispatch(setSelectedGloablView("liteVersionView")) }}>
              <div className="d-flex align-items-center justify-content-between" >
                <div className='c-list-icon'>
                  <div className='h25w25 d-flex align-items-center'>
                    <ThreeStarIcon
                      size={16}
                      className="c-icons h15w15"
                      weight="light"
                    />
                  </div>
                </div>
                <div className="ps-2 w100minus30 text_wrap pe-3">
                  <div className='c-list-detail text-truncate '>{_l("l_liteVersion_view")}</div>
                </div>
              </div>
            </a>
          </li> * /}
          <li className='hr_sepretr'>
            <a href="#/" className={`dropdown-item ${globalView == "liteVersionView" ? "active with-tick" : ""}`} onClick={() => {
              // dispatch(dayGridDay(true));
              // dispatch(dayGridMonth(false));
              // dispatch(dayGridWeek(false));
              // dispatch(setDaterangeFilterApplied(false));
              dispatch(setCalendarView("day_view"));
              localStorage.setItem("tasklistView", "liteVersionView")
              dispatch(setSelectedGloablView("liteVersionView"))
              dispatch(setSelectedDateView("dayView"));
            }}>
              <div className="d-flex align-items-center justify-content-between" >
                <div className='c-list-icon'>
                  <div className='h25w25 d-flex align-items-center'>
                    <DayViewIcon
                      size={16}
                      className="c-icons"
                      weight="light"
                    />
                  </div>
                </div>
                <div className="ps-2 w100minus30 text_wrap pe-3">
                  <div className='c-list-detail text-truncate '>{_l("l_day_view")}</div>
                </div>
              </div>
            </a>
          </li>
          <li className='hr_sepretr'>
            <a href="#/" className={`dropdown-item ${globalView == "listView" && dateView == "weekView" ? "active with-tick" : ""}`}
              onClick={() => {
                localStorage.setItem("tasklistView", "listview")
                dispatch(setSelectedGloablView("listView"));
                dispatch(setSelectedDateView("weekView"));
                // dispatch(dayGridWeek(true));
                // dispatch(dayGridMonth(false));
                // dispatch(dayGridDay(false));
                // dispatch(setDaterangeFilterApplied(false));
                // dispatch(setCalendarView("week_view"));
              }}>
              <div className="d-flex align-items-center justify-content-between" >
                <div className='c-list-icon'>
                  <div className='h25w25 d-flex align-items-center'>
                    <WeekViewIcon
                      size={16}
                      className="c-icons"
                      weight="light"
                    />
                  </div>
                </div>
                <div className="ps-2 w100minus30 text_wrap pe-3">
                  <div className='c-list-detail text-truncate '>{_l("l_week_view")}</div>
                </div>
              </div>
            </a>
          </li>
          <li className='hr_sepretr'>
            <a href="#/" className={`dropdown-item ${globalView == "listView" && dateView == "monthView" ? "active with-tick" : ""}`}
              onClick={() => {
                dispatch(setSelectedGloablView("listView"));
                dispatch(setSelectedDateView("monthView"));
                // dispatch(dayGridMonth(true));
                // dispatch(dayGridWeek(false));
                // dispatch(dayGridDay(false));
                // dispatch(setDaterangeFilterApplied(false));
                // dispatch(setCalendarView("month_view"));
              }}>
              <div className="d-flex align-items-center justify-content-between" >
                <div className='c-list-icon'>
                  <div className='h25w25 d-flex align-items-center'>
                    <CalendarBlankIcon
                      size={16}
                      className="c-icons"
                      weight="light"
                    />
                  </div>
                </div>
                <div className="ps-2 w100minus30 text_wrap pe-3">
                  <div className='c-list-detail text-truncate '>{_l("l_month_view")}</div>
                </div>
              </div>
            </a>
          </li>
        </> : <></>} */}
        {/* {globalView == "calendarView" ? <> */}
         {pageName == "dashboardnew" ?
         <>
          <li className='hr_sepretr'>
            <a href="#/" className={`dropdown-item ${dashBoradView == "spaceView" ? "active with-tick" : ""}`} onClick={()=>{
              dispatch(setDashBoardView("spaceView"))
            }}>
              <div className="d-flex align-items-center justify-content-between" >
                <div className='c-list-icon'>
                  <div className='h25w25 d-flex align-items-center'>
                    <Buildings
                      size={16}
                      className="c-icons"
                      weight="light"
                    />
                  </div>
                </div>
                <div className="ps-2 w100minus30 text_wrap pe-3">
                  <div className='c-list-detail text-truncate '>{_l("l_space_view")}</div>
                </div>
              </div>
            </a>
          </li>
          <li className='hr_sepretr'>
            <a href="#/" className={`dropdown-item ${dashBoradView == "taskListView" || dashBoradView == "calendarView" ? "active with-tick" : ""}`} onClick={()=>{
              dispatch(setDashBoardView("taskListView"))
              localStorage.removeItem("selectedOffice")
            }}>
              <div className="d-flex align-items-center justify-content-between" >
                <div className='c-list-icon'>
                  <div className='h25w25 d-flex align-items-center'>
                    <ListBullets
                      size={16}
                      className="c-icons"
                      weight="light"
                    />
                  </div>
                </div>
                <div className="ps-2 w100minus30 text_wrap pe-3">
                  <div className='c-list-detail text-truncate '>{_l("l_task_list")}</div>
                </div>
              </div>
            </a>
          </li>
         </>
         :
         <> 
         <li className='hr_sepretr'>
            <a href="#/" className={`dropdown-item ${globalView != "calendarView" && dateView == "dayView"  ? "active with-tick" : ""} ${globalView == "calendarView" && isDayGridDay ? "active with-tick" : ""}`} onClick={() => {
              if (globalView == "calendarView") {
              dispatch(dayGridDay(true));
              dispatch(dayGridMonth(false));
              dispatch(dayGridWeek(false));
              dispatch(setDaterangeFilterApplied(false));
              dispatch(setCalendarView("day_view"));
              // dispatch(setSelectedDateView("dayView"));
              // dispatch(setSelectedGloablView("calendarView"))
              }else{
              localStorage.setItem("tasklistView", globalView == "listView" ? "liteVersionView" : globalView)
              dispatch(setSelectedGloablView(globalView == "listView" ? "liteVersionView" : globalView))
              dispatch(setSelectedDateView("dayView"));
              }
            }}>
              <div className="d-flex align-items-center justify-content-between" >
                <div className='c-list-icon'>
                  <div className='h25w25 d-flex align-items-center'>
                    <DayViewIcon
                      size={16}
                      className="c-icons"
                      weight="light"
                    />
                  </div>
                </div>
                <div className="ps-2 w100minus30 text_wrap pe-3">
                  <div className='c-list-detail text-truncate '>{_l("l_day_view")}</div>
                </div>
              </div>
            </a>
          </li>
          <li className='hr_sepretr'>
            <a href="#/" className={`dropdown-item ${globalView == "calendarView" && isDayGridWeek ? "active with-tick" : ""} ${globalView != "calendarView" && dateView == "weekView" ? "active with-tick" : ""}`}
              onClick={() => {
                if (globalView == "calendarView") {
                dispatch(dayGridWeek(true));
                dispatch(dayGridMonth(false));
                dispatch(dayGridDay(false));
                dispatch(setDaterangeFilterApplied(false));
                dispatch(setCalendarView("week_view"));
                dispatch(setDaterangeFilterApplied(true));
                }else{
                  localStorage.setItem("tasklistView",  "liteVersionView" )
                  // localStorage.setItem("tasklistView", globalView == "liteVersionView" ? "listView" : globalView)
                  // dispatch(setSelectedGloablView(globalView == "liteVersionView" ? "listView" : globalView));
                  dispatch(setSelectedGloablView( "liteVersionView" ));
                  dispatch(setSelectedDateView("weekView"));
                }
              }}>
              <div className="d-flex align-items-center justify-content-between" >
                <div className='c-list-icon'>
                  <div className='h25w25 d-flex align-items-center'>
                    <WeekViewIcon
                      size={16}
                      className="c-icons"
                      weight="light"
                    />
                  </div>
                </div>
                <div className="ps-2 w100minus30 text_wrap pe-3">
                  <div className='c-list-detail text-truncate '>{_l("l_week_view")}</div>
                </div>
              </div>
            </a>
          </li>
          <li className='hr_sepretr'>
            <a href="#/" className={`dropdown-item ${globalView == "calendarView" && isDayGridMonth ? "active with-tick" : ""} ${globalView != "calendarView" && dateView == "monthView" ? "active with-tick" : ""}`}
              onClick={() => {
                if (globalView == "calendarView") {
                dispatch(dayGridMonth(true));
                dispatch(dayGridWeek(false));
                dispatch(dayGridDay(false));
                dispatch(setDaterangeFilterApplied(false));
                dispatch(setCalendarView("month_view"));
                dispatch(setDaterangeFilterApplied(true));

              } else {
                dispatch(setSelectedGloablView( "liteVersionView" ));
                localStorage.setItem("tasklistView", "liteVersionView" )
                // dispatch(setSelectedGloablView(globalView == "liteVersionView" ? "listView" : globalView));
                // localStorage.setItem("tasklistView", globalView == "liteVersionView" ? "listView" : globalView)
                dispatch(setSelectedDateView("monthView"));
              }
              }}>
              <div className="d-flex align-items-center justify-content-between" >
                <div className='c-list-icon'>
                  <div className='h25w25 d-flex align-items-center'>
                    <CalendarBlankIcon
                      size={16}
                      className="c-icons"
                      weight="light"
                    />
                  </div>
                </div>
                <div className="ps-2 w100minus30 text_wrap pe-3">
                  <div className='c-list-detail text-truncate '>{_l("l_month_view")}</div>
                </div>
              </div>
            </a>
          </li>
          </>}
        {/* </> : <></>} */}
      </ul>
    </>
  )
}

export default ToggleViewDropdown