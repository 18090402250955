import React, { useEffect, useState } from "react";
import { _l } from "../../../hooks/utilities";
import CustomWhatsappIcon from "../../../assets/icons/CustomWhatsappIcon";
import CustomGmailIcons from "../../../assets/icons/CustomGmailIcons";
import CustomSlackIcon from "../../../assets/icons/CustomSlackIcon";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import documentsServices from "../../../services/documents-services";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";
import Spinner from "react-bootstrap/Spinner";
import { showMessage } from "../../../actions/messages";
const PilotOnboardingSummary = ({ data, projectId, setSelectedTab }) => {
  const onBoardProviderList = useSelector((state) => state.documentsReducer.onBoardProviderList);
  const companyEmployeeList = useSelector((state) => state.customer.getCompanyEmployee);
  const [people, setPeople] = useState([])
  const [finalData, setFinalData] = useState([])
  const [loder, setLoder] = useState(false);
  const userType = localStorage.getItem("user_type");
  const dispatch = useDispatch();
  const dawnloadPDF = () => {
    setLoder(true)
    documentsServices.getOnboardPDF(projectId, data.document_id).then((res) => {
      setLoder(false)
      if (res.status == 1) {
        window.open(res.data.pdf, "_blank")
      }else{
        // window.open("https://file-examples.com/storage/fe9278ad7f642dbd39ac5c9/2017/10/file-sample_150kB.pdf", "_blank")
        dispatch(showMessage("unsucess", _l("l_error"), res.message));
      }
    })
  }
  const getPeople = () => {
    if (companyEmployeeList && finalData) {
      let arr = people
      for (let i = 0; i < finalData.length; i++) {
        for (let j = 0; j < companyEmployeeList.length; j++) {
          if (finalData[i] == companyEmployeeList[j].id) {
            arr.push({
              id: companyEmployeeList[j].id,
              people_name: companyEmployeeList[j].full_name,
              people_role: companyEmployeeList[j].role_detail.role_name,
            })
          }
        }
      }
      setPeople(arr);
    }
  }
  useEffect(() => {
    if (data) {
      setFinalData(data)
    }
  },[data]) 
  useEffect(()=> {
      getPeople();
  },[finalData])
  // const documentAPI = () => {
  //   setLoder(true)
  //   return new Promise(resolve => {
  //     documentsServices.getOnboardPDF(projectId, data.document_id).then((res) => {
  //       if (res.status == 0) {
  //         setLoder(false)
  //         setTimeout(() => {
  //           resolve(
  //             "https://file-examples.com/storage/fe9278ad7f642dbd39ac5c9/2017/10/file-sample_150kB.pdf"
  //           );
  //         }, 500);     
  //       }
  //     })
  //   });
  // };

  // const getFile = async () => {
  //   const file = await documentAPI();
  //   const a = document.createElement("a");
  //   a.href = file; //url: use in case of blob
  //   a.download = file.split("/").pop();
  //   document.body.appendChild(a);
  //   a.target="_blank"
  //   a.click(window.open(file, "_self"));
  //   document.body.removeChild(a);
  // };

  return (
    <>
    <div className="flex-grow-1 overflow-hiiden-auto">
      <div>
        <div className="comman-data-table GeneralItemTable bg-white-03 p-15 mb-3 pb-1 ">
          <div className="c-font f-12 fw-semibold title-fonts  border-bottom pb-2">{_l("l_tenants")}</div>
          <table className="dataTable comman-table smaller-table">
            <tbody>
              <tr>
                <td className="color-white-60 fw-light border-0" style={{ width: "30%" }}>{_l("l_company_name")}:</td>
                <td className="border-0">{data.company_name}</td>
              </tr>
              <tr>
                <td className="color-white-60 fw-light border-0" style={{ width: "30%" }}>{_l("l_area")}:</td>
                <td className="border-0">{data.total_area ? data.total_area + " M2" : ""}</td>
              </tr>
              <tr>
                <td className="color-white-60 fw-light border-0" style={{ width: "30%" }}>{_l("l_tenants")}:</td>
                <td className="border-0">{data.tenants}</td>
              </tr>
                {
                  data.facility_manager ?
                    <></>
                    :
                    <tr>
                      <td className="color-white-60 fw-light border-0" style={{ width: "30%" }}>{_l("l_saving")}:</td>
                      <td className="border-0">{data.saving}</td>
                    </tr>
                }
              <tr>
                <td className="color-white-60 fw-light border-0" style={{ width: "30%" }}>{_l("l_tools")}:</td>
                <td className="border-0">{
                  data.tools && data.tools.map((t) => {
                  return(
                    <>
                    { t == "WhatsApp" ?  <CustomWhatsappIcon className="h25w25 mx-1" /> : <></> }
                    { t == "Gmail" ?  <CustomGmailIcons className="h25w25 mx-1" /> : <></> }
                    { t == "Slack" ?  <CustomSlackIcon className="h20w20 mx-1" /> : <></> }
                    </>)
                  })
                }</td>
              </tr>
              <tr>
                <td className="color-white-60 fw-light border-0" style={{ width: "25%" }}>{_l("l_people")}:</td>
                <td>
                  <table className="dataTable comman-table smaller-table">
                    <tbody>
                      {
                        people && people.map((p) => {
                          return (
                            <tr>
                              <td className="border-0" style={{ width: "20%" }}>{p.people_name}</td>
                              <td className="border-0 color-white-60 ">{p.people_role}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {
         data.facility_manager && Object.keys(data.facility_manager).length > 0 && data.document_type == "external" ?
        <div className="comman-data-table GeneralItemTable bg-white-03 p-15 mb-3 pb-1">
          <div className="c-font f-12 fw-semibold title-fonts border-bottom pb-2">{_l("l_facility_manager")}</div>
          <table className="dataTable comman-table smaller-table">
            <tbody>
              <tr>
                <td className="color-white-60 fw-light border-0" style={{ width: "30%" }}>{_l("l_company_name")}:</td>
                <td className="border-0">{data.facility_manager.company_name}</td>
              </tr>
              <tr>
                <td className="color-white-60 fw-light border-0" style={{ width: "30%" }}>{_l("l_cost")}:</td>
                <td className="border-0">{data.facility_manager.cost}</td>
              </tr>
              <tr>
                <td className="color-white-60 fw-light border-0" style={{ width: "30%" }}>{_l("l_meeting")}:</td>
                <td className="border-0">{data.facility_manager.meeting}</td>
              </tr>
              <tr>
                <td className="color-white-60 fw-light border-0" style={{ width: "30%" }}>{_l("l_saving")}:</td>
                <td className="border-0">{data.saving}</td>
              </tr>
              <tr>
                <td className="color-white-60 fw-light border-0" style={{ width: "30%" }}>{_l("l_tools")}:</td>
                <td className="border-0">{
                  data.facility_manager.tools && data.facility_manager.tools.map((t) => {
                  return(
                    <>
                    { t == "WhatsApp" || t.label == "WhatsApp" ?  <CustomWhatsappIcon className="h25w25 mx-1" /> : <></> }
                    { t == "Gmail" || t.label == "Gmail" ?  <CustomGmailIcons className="h25w25 mx-1" /> : <></> }
                    { t == "Slack" || t.label == "Slack" ?  <CustomSlackIcon className="h20w20 mx-1" /> : <></> }
                    </>)
                  })
                }</td>
              </tr>
              <tr>
                <td className="color-white-60 fw-light border-0" style={{ width: "25%" }}>{_l("l_people")}:</td>
                <td>
                  <table className="dataTable comman-table smaller-table">
                    <tbody>
                    {
                        data.facility_manager.people && data.facility_manager.people.length > 0 ? data.facility_manager.people.map((p) => {
                          return (
                            <tr>
                              <td className="border-0" style={{ width: "20%" }}>{p.full_name}</td>
                              <td className="border-0 color-white-60 ">{p.role}</td>
                            </tr>
                          )
                        }) :
                        <div className="">{_l("l_no_people")}</div>
                      }
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
          :
         <></>
        }
        <div className="bg-white-03 p-15 pb-1">
          <div className="c-font f-12 fw-semibold title-fonts border-bottom pb-2">{_l("l_provider")}</div>
          <ul className="w-100 mt-3">
          {
            onBoardProviderList && onBoardProviderList.length > 0 ? onBoardProviderList.map((p, k) => {
              return (
                <li className="w-100">
                  <a href="#/" className="task-list-card list-view w-100 p-2 d-flex align-items-center border">
                    <div className="d-flex flex-grow-1 w100minus100per">
                      <div className="flex-grow-1 fw-semibold text-truncate title-fonts w100minus100per">{p.provider_type}</div>
                    </div>
                    <div className="flex-grow-1 maxw100minus180 text-truncate d-flex">
                      <div className="with_separator_10"><span className="c-font color-white-60 f-12 fw-light title-fonts">{_l('l_cost')} </span><span className="c-font f-12 title-fonts">: {p.cost}</span></div>
                      <div className="with_separator_10"><span className="c-font color-white-60 f-12 fw-light title-fonts">{_l('l_people')} </span><span className="c-font f-12 title-fonts">: {p.people}</span></div>
                      <div className="with_separator_10"><span className="c-font color-white-60 f-12 fw-light title-fonts">{_l("l_meeting")} </span><span className="c-font f-12 title-fonts">: {p.meeting}</span></div>
                      <div className="with_separator_10"><span className="c-font color-white-60 f-12 fw-light title-fonts">{_l("l_saving")} </span><span className="c-font f-12 title-fonts">: {p.saving}</span></div>
                      <div className="with_separator_10"><span className="c-font color-white-60 f-12 fw-light title-fonts">{_l("l_tools")} </span><span className="c-font f-12 title-fonts">:
                        {
                          p.tools.map((t) => {
                            return (
                              t == "WhatsApp" ? <CustomWhatsappIcon className="h25w25 mx-1" /> : t == "Slack" ? <CustomSlackIcon className="h20w20 mx-1" /> : t == "Gmail" ? <CustomGmailIcons className="h25w25 mx-1" /> : <></>
                            )
                          })
                        }
                      </span></div>
                    </div>
                  </a>
                </li>
              )
            })
              :
              <CommanPlaceholder imgType = "request"   />
          }
          </ul>
          </div>
      </div>
      </div>
      <div className="d-flex justify-content-between pt-2">
        {userType == "staff" ?
        <Button variant="secondary" size="sm" onClick={() => setSelectedTab("providers")}>{_l("l_previous")}</Button>
      :<></>}
        <Button className={`${loder ? "for-disabled" :""}`} variant="primary" size="sm" onClick={() => {
          if (!loder) {
            dawnloadPDF()
          }
        }
        }>
          {/* {_l("l_download_pdf")} */}
          {loder
            ? _l('l_please_wait')
            : _l('l_download_pdf')}
          {loder ? <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            className={`ms-2 `}
            aria-hidden="true"
          />
            : <></>
          }
        </Button>
      </div>
      </> 

  );
};


export default PilotOnboardingSummary;

