import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { ArrowLeft, Paperclip, Plus } from "phosphor-react";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Button, Form, Modal } from "react-bootstrap";
import {
  _l,
  createCSVFile,
  csvOnLoadHandler,
  csvRowSplit,
  showError,
} from "../../../../hooks/utilities";
import GeneralItemTable from "../../../Offcanvas/NewSchedule/GeneralItemTable";
import { WEEK_DAYS } from "../../../../constants/constants";
import Select from "react-select";
import { showMessage } from "../../../../actions/messages";
import { useDispatch } from "react-redux";
import moment from "moment";

const ChecklistScreen = ({
  checklists,
  bulkChecklistDelete,
  data,
  kpiCounts,
  readOnly,
  setChecklists,
  singleChecklistDelete,
  stepper,
  handleStepChange,
}) => {
  const [bulkActionsCheckboxes, setBulkActionsCheckboxes] = useState([]);
  const [showImportItemsModal, setShowImportItemsModal] = useState(false);
  const [csvFile, setCsvFile] = useState("");
  const [processedCSV, setProcessedCSV] = useState("");

  const dispatch = useDispatch();

  const handleCsvImport = async () => {
    setShowImportItemsModal(false);
    setCsvFile("");
    let checkListTemplateArray = [];
    try {
      if (processedCSV) {
        const rows = csvRowSplit(processedCSV, 15);

        const spreadsheetData = rows.map((row) => {
          return row.map((column) => column.replace(/(^"|"$)/g, ""));
        });

        /**Continue Import process after new spaces added */
        spreadsheetData.forEach((row, rowIndex) => {
          if (
            row.length == 15 &&
            rowIndex > 0 &&
            row[0] &&
            row[0].trim().length
          ) {
            checkListTemplateArray.push({
              id: 0,
              description: row[0],
              slot:
                row[2] && new Date(`01-01-2000 ${row[2]}`) instanceof Date
                  ? new Date(`01-01-2000 ${row[2]}`)
                  : "",
              priority: Number(row[3]) ? 1 : 0,
              photo_required: Number(row[4]) ? 1 : 0,
              days: {
                mon: Number(row[5]) ? true : false,
                tue: Number(row[6]) ? true : false,
                wed: Number(row[7]) ? true : false,
                thu: Number(row[8]) ? true : false,
                fri: Number(row[9]) ? true : false,
                sat: Number(row[10]) ? true : false,
                sun: Number(row[11]) ? true : false,
                monthly: Number(row[12]) ? true : false,
                quarterly: Number(row[13]) ? true : false,
                half_yearly: Number(row[14]) ? true : false,
                yearly: Number(row[15]) ? true : false,
              },
              item_comments: [],
            });
          }
        });

        checkListTemplateArray.forEach((checklist) => {
          if (checklist.days && Object.keys(checklist.days).length) {
            Object.keys(checklist.days).forEach((day) => {
              if (WEEK_DAYS.includes(day) && checklist.days[day]) {
                checklist.days = {
                  ...checklist.days,
                  monthly: false,
                  quarterly: false,
                  half_yearly: false,
                  yearly: false,
                };
              }
            });
          }
        });

        let allCurrentChecklists = checklists
          .concat(checkListTemplateArray);
        allCurrentChecklists = allCurrentChecklists.filter(
          (obj, index, self) =>
            index ===
            self.findIndex(
              (o) =>
                (obj.room_id == o.room_id &&
                  obj.type_of_space_item_id == o.type_of_space_item_id &&
                  obj.description == o.description) ||
                (obj.description == "" && o.description == "")
            )
        );
        allCurrentChecklists.sort((a, b) =>
          !a.description && b.description
            ? 1
            : a.description === b.description
            ? 0
            : -1
        );
        setChecklists(allCurrentChecklists);
      } else {
        showError("l_failed_to_process_file");
      }
    } catch (e) {
      console.error(e);
      showError("l_something_went_wrong");
    }
  };

  const downloadCSV = () => {
    let nonEmptyChecklists = checklists.filter((x) => x.description != "");
    nonEmptyChecklists = nonEmptyChecklists.filter(
      (obj, index, self) =>
        index ===
        self.findIndex(
          (o) =>
            obj.description == o.description ||
            (obj.description == "" && o.description == "")
        )
    );
    if (nonEmptyChecklists.length) {
      let csvToArray = [];
      nonEmptyChecklists.forEach((checklist) => {
        csvToArray.push([
          checklist.description,
          moment(checklist.slot).isValid()
            ? moment(checklist.slot).format("hh:mm A")
            : "",
          checklist.priority ? 1 : 0,
          checklist.photo_required > 0 ? 1 : 0,
          checklist.days.mon ? 1 : 0,
          checklist.days.tue ? 1 : 0,
          checklist.days.wed ? 1 : 0,
          checklist.days.thu ? 1 : 0,
          checklist.days.fri ? 1 : 0,
          checklist.days.sat ? 1 : 0,
          checklist.days.sun ? 1 : 0,
          checklist.days.monthly ? 1 : 0,
          checklist.days.quarterly ? 1 : 0,
          checklist.days.half_yearly ? 1 : 0,
          checklist.days.yearly ? 1 : 0,
        ]);
      });
      createCSVFile(
        csvToArray,
        `${data.title}_${data.id}_${_l("l_template")}.csv`
      );
    } else {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_no_checklists_to_export"))
      );
    }
  };

  return (
    <div className="bg-white-05 h-100 radius_3 d-flex flex-column checklist-creation-abs pb-0">
      <div className="mb-32px d-flex align-items-center w-100 justify-content-between">
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="c-font f-14 fw-semibold pe-1 text-truncate title-fonts">
            {data.title}
          </div>
          <div className="d-flex align-items-center w100minus100per">
            <div className="schedule-count-box-main d-flex align-items-center w-100 overflow-hidden justify-content-end">
              <div className="d-flex align-items-center overflow-auto w100minus150 flex-grow-1">
                <div className="d-flex ms-auto">
                  {kpiCounts.map((kpiData, key) => {
                    return (
                      <div
                        key={key}
                        className="schedule-count-box with_separator_10 c-font f-10"
                      >
                        <div className=" fw-semibold schedule-number title-fonts c-font f-14">
                          {kpiData.count}
                        </div>
                        <div className=" color-white-80 schedule-title text-truncate c-font f-12">
                          {_l(kpiData.title)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <a><X size={16} className="c-icons" weight="light" /></a> */}
      </div>
      <div className="h100minus70px bg-white-03-solid p-20">
      <div className="d-flex align-items-center justify-content-between dropup mb-32px">
        <div className="min-width-125">
          <a
            className="border-0 btn btn-sm c-font color-white f-15 title-fonts ps-0"
            onClick={() => {
              handleStepChange(0);
            }}
          >
            <ArrowLeft size={18} weight="light" className="c-icons" />
            <span className="ms-2">{_l("l_back")}</span>
          </a>
        </div>
        <div className="d-flex right-side-button ms-auto">
          <Button
            className="ms-10px btn-sm-2"
            variant="white-05 border-0"
            size="sm"
            onClick={() => {
              setShowImportItemsModal(true);
            }}
          >
            {`${_l("l_import")} ${_l("l_checklist")}`}
          </Button>
          <Button
            className="ms-10px btn-sm-2"
            variant="white-05 border-0"
            size="sm"
            onClick={downloadCSV}
          >
            {_l("l_export_checklist")}
          </Button>
          <Button
            variant={"primary"}
            className="border-0 ms-10px btn-sm"
            size="sm"
            id="footer-primary-button"
            onClick={() => {
              handleStepChange(1);
            }}
          >
            {_l("l_next")}
          </Button>
        </div>
      </div>
      <div className="custom-accordian-main with-left-header-arrow flex-grow-1 overflow-auto">
        <Accordion defaultActiveKey="0">
          <Accordion.Item
            eventKey="0"
            className={`mainTopicTaskFilter for-accessible bg-white-03-solid-imp`}
          >
            <Accordion.Body className="childTopicTaskFilter">
              <GeneralItemTable
                bulkActionsCheckboxes={bulkActionsCheckboxes}
                checklists={checklists}
                isReadOnly={readOnly}
                readOnlyMode={readOnly}
                setBulkActionsCheckboxes={setBulkActionsCheckboxes}
                setChecklists={setChecklists}
                deleteChecklistItem={singleChecklistDelete}
                bulkChecklistDelete={bulkChecklistDelete}
                nonStickey={true}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      </div>
      <div className="position-sticky-bottom z-index-3 border-top w-100 mt-auto custom-footer d-flex align-items-center justify-content-between p-15 ps-0 ">
        <div className="d-flex align-items-center d-none">
          <UncontrolledDropdown className="with_separator_10">
            <DropdownToggle
              tag="a"
              className=" on-hover-active-toggle-img z-index-3 before-dnone border-0"
              type="button"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
            >
              <Button variant="white-05 border-0">
                <Plus size={16} className="c-icons me-2" weight="light" />
                {_l("l_add_room")}
              </Button>
            </DropdownToggle>
            <DropdownItem toggle={false} className="p-0">
             
            </DropdownItem>
          </UncontrolledDropdown>
        </div>
        <div className="d-flex align-items-center d-none">
          <Button className="mx-1" variant="white-05 border-0">{`${_l(
            "l_import"
          )} ${_l("l_checklist")}`}</Button>
          <Button className="mx-1" variant="white-05 border-0">
            {_l("l_export_checklist")}
          </Button>
          <Button className="mx-1" variant="white-05 border-0">
            {_l("l_save_as_template")}
          </Button>
          <Button className="mx-1" variant="white-05 border-0">
            {_l("l_select_template")}
          </Button>
        </div>
        <div
          className={`onboarding-footer onboarding-footer-before-none position-sticky-bottom px-0 mt-auto position-static`}
        >
          <div className="d-flex align-items-start justify-content-between">
            {/* <div className="min-width-125">
              <a
                className="border-0 btn btn-sm c-font color-white f-15 title-fonts"
                onClick={() => {
                  handleStepChange(0);
                }}
              >
                <ArrowLeft size={18} weight="light" className="c-icons" />
                <span className="ms-2">{_l("l_back")}</span>
              </a>
            </div> */}
            <div className="d-flex align-items-center justify-content-center onboarding-footer-tab m-auto">
              {stepper ? (
                <div className="d-flex align-items-center justify-content-center onboarding-footer-tab m-auto">
                  {Array.from({ length: stepper.totalSteps }).map(
                    (step, index) => {
                      return (
                        <span
                          key={index}
                          className={`footer-tab ${
                            stepper.currentStep == index + 1 ? "active" : ""
                          }`}
                        ></span>
                      );
                    }
                  )}
                </div>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </div>
            {/* <div className="d-flex right-side-button ms-auto">
              <Button
                className="mx-1"
                variant="white-05 border-0"
                size="sm"
                onClick={() => {
                  setShowImportItemsModal(true);
                }}
              >
                {`${_l("l_import")} ${_l("l_checklist")}`}
              </Button>
              <Button
                className="mx-1"
                variant="white-05 border-0"
                size="sm"
                onClick={downloadCSV}
              >
                {_l("l_export_checklist")}
              </Button>
              <Button
                variant={"primary"}
                className="mx-auto ms-1"
                size="sm"
                id="footer-primary-button"
                onClick={() => {
                  handleStepChange(1);
                }}
              >
                {_l("l_next")}
              </Button>
            </div> */}
          </div>
        </div>
      </div>
      {showImportItemsModal ? (
        <ImportItems
          show={showImportItemsModal}
          handleClose={() => {
            setShowImportItemsModal(false);
          }}
          setImportFile={(e) =>
            csvOnLoadHandler(e, setCsvFile, setProcessedCSV)
          }
          importFile={csvFile}
          handleImport={handleCsvImport}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </div>
  );
};

const ImportItems = ({
  show,
  handleClose,
  setImportFile,
  handleImport,
  importFile,
}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l("l_import_items")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          <div className="form-wrapper-main">
            <div className="row pb-3">
              <ol start={1} className="ps-4">
                <li className="with-listing-count pb-1">
                  {_l("l_you_csv_data_string")}
                  <b> UTF-8 </b> {_l("l_to_avoid_unnecessary")}{" "}
                  <b>{_l("l_encoding_problems")}</b>
                </li>
              </ol>
              <div className="comman-data-table">
                <table className="dataTable GeneralItemTable">
                  <thead className="WithHeaderbg ">
                    <tr>
                      <th className="c-font f-13 text-nowrap">
                        {_l("l_checklist")}
                      </th>
                      <th className="c-font f-13 text-center text-nowrap">
                        {_l("l_time")}
                      </th>
                      <th className="c-font f-13 text-center text-nowrap">
                        {_l("l_priority")}
                      </th>
                      <th className="c-font f-13 text-center text-nowrap">
                        {_l("l_photo")}
                      </th>
                      <th className="fw-normal py-2 align-top text-center">
                        <th className="d-flex flex-column p-0">
                          <tr>{_l("l_week_single_letter_monday")}</tr>
                        </th>
                      </th>
                      <th className="fw-normal py-2 align-top text-center">
                        <th className="d-flex flex-column p-0">
                          <tr>{_l("l_week_single_letter_tuesday")}</tr>
                        </th>
                      </th>
                      <th className="fw-normal py-2 align-top text-center">
                        <th className="d-flex flex-column p-0">
                          <tr>{_l("l_week_single_letter_wednesday")}</tr>
                        </th>
                      </th>
                      <th className="fw-normal py-2 align-top text-center">
                        <th className="d-flex flex-column p-0">
                          <tr>{_l("l_week_single_letter_thrusday")}</tr>
                        </th>
                      </th>
                      <th className="fw-normal py-2 align-top text-center">
                        <th className="d-flex flex-column p-0">
                          <tr>{_l("l_week_single_letter_friday")}</tr>
                        </th>
                      </th>
                      <th className="fw-normal py-2 align-top text-center">
                        <th className="d-flex flex-column p-0">
                          <tr>{_l("l_week_single_letter_saturday")}</tr>
                        </th>
                      </th>
                      <th className="fw-normal py-2 align-top text-center">
                        <th className="d-flex flex-column p-0">
                          <tr>{_l("l_week_single_letter_sunday")}</tr>
                        </th>
                      </th>
                      <th className="fw-normal py-2 align-top text-center">
                        <th className="d-flex flex-column p-0">
                          <tr>{_l("l_single_letter_monthly")}</tr>
                        </th>
                      </th>
                      <th className="fw-normal py-2 align-top text-center">
                        <th className="d-flex flex-column p-0">
                          <tr>{_l("l_single_letter_quaterly")}</tr>
                        </th>
                      </th>
                      <th className="fw-normal py-2 align-top text-center">
                        <th className="d-flex flex-column p-0">
                          <tr>{_l("l_single_letter_semester")}</tr>
                        </th>
                      </th>
                      <th className="fw-normal py-2 align-top text-center">
                        <th className="d-flex flex-column p-0">
                          <tr>{_l("l_single_letter_yearly")}</tr>
                        </th>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="max-width-100 text-truncate border-bottom">
                        <div className="c-font text-truncate f-12">
                          Clean Table
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center text-nowrap">
                        <div className="c-font f-12">07:30 AM</div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {0}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {0}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {0}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {0}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="max-width-100 text-truncate border-bottom">
                        <div className="c-font text-truncate f-12">
                          Clean Table
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center text-nowrap">
                        <div className="c-font f-12">07:30 AM</div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row align-items-end pb-3">
              <Form.Group className="col-12  c-input-box  position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_choose_csv_file")}
                </Form.Label>
                <div className="d-flex align-items-center">
                  <div className="comment-input-box flex-grow-1 me-2">
                    <div
                      className="input_box_main with_file_upload"
                      data-tip={`${_l("l_upload_file")}`}
                      data-effect="solid"
                      data-delay-show='1000'
                      data-class="tooltip-main"
                    >
                      <label className="upload_inputbox w-100 upload_relative form-control height-45 border-0 p-0 d-flex align-items-center ">
                        <Form.Control
                          className="  hidden-input"
                          type="file"
                          onInput={setImportFile}
                          accept=".csv"
                        />
                        <div className="custom_file">
                          <Paperclip
                            size={18}
                            className="upload_icon c-icons"
                            weight="light"
                          />
                          <div className="btn btn-sm btn-white-10 border-0">
                            <span className="c-font f-10 color-white-60">
                              {" "}
                              {_l("l_upload_file")}
                            </span>
                          </div>
                          <span className="px-1 c-font f-13 color-white-60">
                            {importFile && importFile[0]
                              ? importFile[0].name
                              : ""}
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </Form.Group>
            </div>
            <div className="row d-none">
              <Form.Group className="col-6 pb-3 c-input-box  position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_status")}
                </Form.Label>
                <Select
                  placeholder={`${_l("l_nothing_selected")}`}
                  className="custom-select-menu w-100"
                  classNamePrefix="react-select"
                  closeMenuOnSelect={true}
                />
              </Form.Group>
            </div>
            <div className="row d-none">
              <Form.Group className="col-6 pb-3 c-input-box  position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_source")}
                </Form.Label>
                <Select
                  placeholder={`${_l("l_nothing_selected")}`}
                  className="custom-select-menu w-100"
                  classNamePrefix="react-select"
                  closeMenuOnSelect={true}
                />
              </Form.Group>
            </div>
            <div className="row d-none">
              <Form.Group className="col-6 pb-3 c-input-box  position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_responsible")} ({_l("l_assignee")})
                </Form.Label>
                <Select
                  placeholder={`${_l("l_nothing_selected")}`}
                  className="custom-select-menu w-100"
                  classNamePrefix="react-select"
                  closeMenuOnSelect={true}
                />
              </Form.Group>
            </div>
            <Form.Group className="col-12 pb-3 c-input-box  position-relative d-none">
              <Button
                variant="secondary me-2 mt-2"
                size="sm"
                className="position-relative"
                onClick={handleImport}
              >
                {_l("l_import_items")}
              </Button>
            </Form.Group>
            {/* </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l("l_close")}
          </Button>
          <Button variant="primary" size="sm" onClick={handleImport}>
            {_l("l_import")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChecklistScreen;
