import React from 'react';
// import CSS from '../css/icon_main.css';

function AddBenchmarkIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons `}>
<path d="M31.9162 52.499C26.7155 52.5018 21.5132 52.4988 16.3093 52.4901C13.133 52.4901 11.2537 50.205 11.25 46.3949C11.2512 40.9047 11.2543 35.413 11.2592 29.9198V13.66C11.2592 9.77966 13.133 7.51868 16.3661 7.50769C24.2096 7.49744 32.0532 7.49744 39.8968 7.50769C43.1316 7.50769 44.9982 9.77087 45 13.66V32.6982M18.6787 16.875H37.2504M18.6787 24.375H33.536M18.6787 31.875H27.9645M45.1373 41.25V52.8781M50.8925 47.0622H39.375" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round"/>
</svg>

			{/* <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" >
				<path d="M31.9162 52.499C26.7155 52.5018 21.5132 52.4988 16.3093 52.4901C13.133 52.4901 11.2537 50.205 11.25 46.3949C11.2512 40.9047 11.2543 35.413 11.2592 29.9198V13.66C11.2592 9.77966 13.133 7.51868 16.3661 7.50769C24.2096 7.49744 32.0532 7.49744 39.8968 7.50769C43.1316 7.50769 44.9982 9.77087 45 13.66V32.6982M18.6787 16.875H37.2504M18.6787 24.375H33.536M18.6787 31.875H27.9645M45.1373 41.25V52.8781M50.8925 47.0622H39.375" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" />
			</svg> */}
		</React.Fragment>
	)
}

export default AddBenchmarkIcon