import React, { useEffect, useState } from "react";
import { CalendarBlank, Phone, PushPin, DotsThreeVertical, CaretDown, Plus, ChatCircle, EnvelopeSimple, Hash, DotsNine, Trash, UserCircleGear } from "phosphor-react";
import HighPriorityIcon from "../../assets/icons/HighPriorityIcon";
import HighPriorityActiveIcon from "../../assets/icons/HighPriorityActiveIcon";
import DatePicker from "react-datepicker";
import DeleteDropdown from "../Dropdowns/DeleteDropdown";
import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
import ReactTooltip from "react-tooltip";
import StatusDropdown from "../Dropdowns/StatusDropdown";
import AddEmailTemplatesModal from "../Modals/AddEmailTemplatesModal";
import { LeftPanelResponsiveToggle, _l  , formateDate, getDatePickerLocale } from "../../hooks/utilities";
import {
  addLeadComment,
  getLeadActivities,
  handleLeadPinUnpin,
  hotLeadSetUnset,
  leadStatusChange} from "../../actions/leads-actions/leads-actions";
import { useDispatch, useSelector } from "react-redux";
import CommentMinified from "../Comments/CommentMinified";
import { showMessage } from "../../actions/messages";
import ConvertLeadToCustomer from "../Modals/ConvertLeadToCustomer";
import AdminServices from "../../services/admin-services";
import DotsNineIcon from "../../assets/icons/DotsNineIcon";
import { UncontrolledDropdown, DropdownToggle } from "reactstrap";
let showComments = false;

const LeadCard = ({ data, hideShow, selectedLeadId, leadCheckUncheck }) => {
  const [lead, setLead] = useState({
    id: 0,
    name: "",
    company: "",
    assigned: "0",
    status: "16",
    email: "",
    company_id: "0",
    phonenumber: "",
    comments_count: "0",
    hot_leads: "0",
    appointment_date: null,
    status_text: "",
    assigned_details: {
      full_name: "",
      profile_image: "",
    },
  });
  const [leadComments, setLeadComments] = useState([]);
  const [commentsFetched, setCommentsFetched] = useState(0);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showConvertLeadModal, setShowConvertLeadModal] = useState(false);
  const modalhandleleadClose = () => setShow(false);

  const modalhandleleadShow = () => setShow(true);
  const [leadType, setLeadType] = useState("");
  const dispatch = useDispatch();
  const { leadDetails, leadCompanyContactToggle } = useSelector((state) => state.leadsReducer);
  const [showIcons, setShowIcons] = useState(false);
  const [openAssigneeDropdown, setOpenAssigneeDropdown] = useState(false);

  const leadStatusArray = [
    {
      id: 16,
      name: "l_todo",
      className: "in-progress-white",
    },
    {
      id: 19,
      name: "l_inprogress",
      className: "in-progress-blue",
    },
    {
      id: 1,
      name: _l("l_convert_as_client"),
      className: "done",
      type: 'contact'
    }, {
      id: 1,
      name: " Convert as operator",
      className: "done",
      type: 'operator'
    }
    ,
    {
      id: 0,
      name: "l_close",
      className: "in-progress-red",
    },
  ];
  useEffect(() => {
    if (data) {
      setLead(data);
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if (leadDetails.id > 0 && leadDetails.id == lead.id) {
      setLeadComments(leadDetails.lead_activities);
      setLoading(false);
    }
  }, [leadDetails.lead_activities]);

  const handlePinUnpin = () => {
    dispatch(handleLeadPinUnpin(lead.id));
  };

  const setUnsetHotLead = () => {
    dispatch(hotLeadSetUnset(lead.id));
  };

  const handleStatusChange = (status, type) => {
    if (status == lead.status) {
      return
    }
    if (status == 1) {
      setShowConvertLeadModal(true);
      setLeadType(type)
    }
    else {
      dispatch(leadStatusChange(lead.id, status));
    }
  };

  const showLeadComments = () => {
    showComments = true;
    setLoading(true);
    dispatch(getLeadActivities(lead.id));
  };

  const handleAddComment = (comment) => {
    if (comment && comment.trim().length) {
      dispatch(addLeadComment(lead.id, comment.trim()));
    } else {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_enter_valid_comment")
        )
      );
    }
  };

  const convertLead = (data, spaceData, is_add_operator) => {
    AdminServices.convertLead(data, '', spaceData, is_add_operator).then((res) => {
      if (res.status) {
        dispatch(leadStatusChange(lead.id, 1));
      }
      else {
        dispatch(showMessage("unsucess", _l("l_error"), _l('l_something_went_wrong')));
      }
      setShowConvertLeadModal(false);
    })
  }

  return (
    <React.Fragment>
      <div
        className={`task-list-card list-view ${data && data.id && data.id == selectedLeadId ? "active" : ""
          }`}
      >
        <div className="task-list-wrapper">
          <div className="d-flex w-100 align-items-start">
            <div className="d-flex task-list-detail">
              <div className="task-left flex-grow-1 width100minus160">
                <div className="d-flex align-items-center w-100 task-left-part-toggle text-truncate">
                  <div className="d-flex align-items-center w-100">
                    <div className="task-creator-img">
                      <div className="h20w20 ">
                        <Form.Check type="checkbox" onChange={(e) => { leadCheckUncheck(lead.id) }} />
                      </div>
                    </div>
                    <a href="#/"
                      className="d-flex flex-column task-list-name text-truncate w100minus100per"
                      data-tip={
                        "#" +
                        lead.id +
                        `${lead.company != null && lead.company != "" ? " - " : ""
                        }` +
                        lead.company
                      }
                      data-effect="solid"
                      data-delay-show='1000'
                      data-class="tooltip-main"
                      onClick={() => {
                        hideShow();
                        LeftPanelResponsiveToggle();
                      }}
                    >
                      <div className="text-truncate w-100 mb-1">
                        {lead.company}
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="c-font color-white-60 f-10 lh-1 location  fw-normal me-10px">
                          {lead.name}
                        </div>
                        {/* companyID start */}
                        <div className="c-font color-white-60 f-10 lh-1 location  fw-normal title-fonts d-flex align-items-center">
                          <span className="ps-0">
                            {" "}
                            <Hash size={12} className="c-icons me-1" weight="light" />
                          </span>
                          <span className=" c-font f-12 ps-0">
                            {leadCompanyContactToggle == "lead_company" ? (
                              <div style={{ width: "30px" }}>Tag 1</div>
                            )
                              : leadCompanyContactToggle == "lead_contacts" ? (
                                <>Position</>) : (
                                <>
                                  {lead.company_id}
                                </>
                              )}
                          </span>
                        </div>
                        {/* companyID end */}
                      </div>
                     
                    </a>
                  </div>
                </div>
                <div className="d-flex align-items-center task-bottom-part-full">
                  <div className="task-right-static-options">
                    <div className="d-flex align-items-center comman_action_icon">
                      <div className="align-items-center d-flex  with-abs-position-bg-taskcard ">

                        {/* progressBar start */}

                        {showIcons ? 
                            <div className="dropdown progress-wrapper-minus d-flex flex-grow-1  me-10px d-none">
                              <a href="#/" className="progress-wrapper-link flex-grow-1 w-120"
                                data-tip={_l("l_add_checklist")}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main">
                                <div className="progress-slider with_separator_10 before-dnone card-view-less-padding px-0">
                                  <div className="d-flex align-items-center">
                                    <div className="progress ">
                                      <div
                                        className="progress-bar"
                                        style={{
                                          width: "50%",
                                        }}
                                      ></div>
                                    </div>
                                    <div className="progress-text c-font f-12 title-fonts">
                                      50%
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                         : <></>}
                        {/* progressBar end */}
                        {/* status icon start */}
                       {showIcons ? <>
                        {leadCompanyContactToggle == "lead_contacts" ? (
                          <>
                            <div className="comman_action_icon ms-2 card-view-less-padding">
                              <div className="dropdown-center white-border-box color-white-60 border-0">
                                <div
                                  className={`  action_icon with_bg dropdown close-toast w-auto px-2`}
                                  type="button"
                                  id="StatusDropdown"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  data-tip={`${_l("l_task_status")}`}
                                  data-effect="solid"
                                  data-delay-show='1000'
                                  data-class="tooltip-main"
                                >
                                  <div className="d-flex align-items-center m-auto">
                                    <span
                                      className={`c-dots c-10 me-2 ${leadStatusArray.find((x) => x.id == lead.status)
                                        .className
                                        } rounded-circle`}
                                    ></span>
                                    <div style={{ width: "100px" }}>decision maker</div>
                                    <StatusDropdown
                                      currentStatus={lead.status}
                                      statuses={leadStatusArray}
                                      handler={(status, type) => {
                                        handleStatusChange(status, type);
                                      }}
                                      page='lead'
                                    />
                                    <span className="d-none">{lead.status_text}</span>
                                    <CaretDown size={14} className="c-icons d-none" weight="light" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="comman_action_icon mx-2 card-view-less-padding">
                              <div className="dropdown-center white-border-box color-white-60 border-0">
                                <div
                                  className={`  action_icon with_bg dropdown close-toast w-auto px-2`}
                                  type="button"
                                  id="StatusDropdown"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  data-tip={`${_l("l_task_status")}`}
                                  data-effect="solid"
                                  data-delay-show='1000'
                                  data-class="tooltip-main"
                                >
                                  <div className="d-flex align-items-center m-auto">
                                    <span
                                      className={`c-dots c-10 me-2 ${leadStatusArray.find((x) => x.id == lead.status)
                                        .className
                                        } rounded-circle`}
                                    ></span>
                                    <div style={{ width: "110px" }}>marketing status</div>
                                    <StatusDropdown
                                      currentStatus={lead.status}
                                      statuses={leadStatusArray}
                                      handler={(status, type) => {
                                        handleStatusChange(status, type);
                                      }}
                                      page='lead'
                                    />
                                    <span className="d-none">{lead.status_text}</span>
                                    <CaretDown size={14} className="c-icons d-none" weight="light" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="comman_action_icon card-view-less-padding ms-10">
                              <div className="dropdown-center white-border-box color-white-60 border-0">
                                <div
                                  className={`  action_icon with_bg dropdown close-toast w-auto px-2`}
                                  type="button"
                                  id="StatusDropdown"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  data-tip={`${_l("l_task_status")}`}
                                  data-effect="solid"
                                  data-delay-show='1000'
                                  data-class="tooltip-main"
                                >
                                  <div className="d-flex align-items-center m-auto">
                                    <span
                                      className={`c-dots c-10 me-2 ${leadStatusArray.find((x) => x.id == lead.status)
                                        .className
                                        } rounded-circle`}
                                    ></span>
                                    <div style={{ width: "75px" }}>expectation</div>
                                    <StatusDropdown
                                      currentStatus={lead.status}
                                      statuses={leadStatusArray}
                                      handler={(status, type) => {
                                        handleStatusChange(status, type);
                                      }}
                                      page='lead'
                                    />
                                    <span className="d-none">{lead.status_text}</span>
                                    <CaretDown size={14} className="c-icons d-none" weight="light" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="comman_action_icon with_separator_10 before-dnone card-view-less-padding ps-0">
                            <div className="dropdown-center white-border-box color-white-60 border-0">
                              <div
                                className={`  action_icon with_bg h32w32  dropdown close-toast`}
                                type="button"
                                id="StatusDropdown"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                data-tip={`${_l("l_task_status")}`}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                              >
                                <div className="d-flex align-items-center m-auto">
                                  <span
                                    className={`c-dots c-10 ${leadStatusArray.find((x) => x.id == lead.status)
                                      .className
                                      } rounded-circle`}
                                  ></span>
                                  <StatusDropdown
                                    currentStatus={lead.status}
                                    statuses={leadStatusArray}
                                    handler={(status, type) => {
                                      handleStatusChange(status, type);
                                    }}
                                    page='lead'
                                  />
                                  <span className="d-none">{lead.status_text}</span>
                                  <CaretDown size={18} className="c-icons d-none" weight="light" />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        </> : <></>}
                        {/* status icon end */}

                        {/* priority icon start */}
                        {showIcons || lead.hot_leads > 0 ? <div className="comman_action_icon with_separator_10 before-dnone card-view-less-padding ps-0">
                          <a href="#/" className="priority-checkbox span-13 action_icon with_bg h32w32 close-toast ps-0"
                            data-tip={_l("l_priority")
                            }
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main">
                            <input
                              type="checkbox"
                              id="priority"
                              checked={lead.hot_leads > 0}
                              onChange={() => {
                                setUnsetHotLead();
                              }}
                              className="absolute-input"
                            />
                            <div className="check_box">
                             { lead.hot_leads > 0 ? <HighPriorityActiveIcon className="HW18 check_span_img active" />
                             :
                              <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />}
                            </div>
                          </a>
                        </div> : <></>}
                        {/* priority icon end */}

                        {/* pin icon start */}
                        { showIcons || data.pinned > 0 ? <div className="before-dnone comman_action_icon with_separator_10 ps-0 before-dnone ps-0 before-dnone ">
                          <a href="#/"
                            className="action_icon with_bg h32w32"
                            onClick={() => {
                              handlePinUnpin();
                            }}
                            data-tip={_l("l_pinned")}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                          >
                            {data.pinned && data.pinned > 0 ? (
                              <PushPin size={18} weight="fill" className="c-icons Push-Pin-fill" />
                            ) : (
                              <PushPin size={18} weight="light" className="c-icons" />
                            )}
                          </a>
                        </div> : <></>}
                        {/* pin icon end */}
                        
                        {/* comments icon start */}
                       {showIcons || lead.comments_count > 0 ?  <div className="dropdown-center dropdown with_separator_10 ps-0 before-dnone d-flex align-items-center card-view-less-padding"
                          data-tip={_l("l_comments")}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                        >
                          <a href="#/"
                            key={lead.id}
                            className="comman_action_icon w-100 d-flex"
                            type="button"
                            id={`minified_comment_${lead.id}`}
                            data-bs-toggle="dropdown" 
                            aria-expanded="false"
                            data-bs-auto-close="outside"
                            onClick={(e) => {
                              showLeadComments();
                            }}
                          >
                            <div className="d-flex align-items-center w-100">
                              <div className="action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img">
                                <ChatCircle size={18} className="c-icons" weight="light" />
                              </div>
                              <span className="c-font f-12 d-flex justify-content-center flex-grow-1 text-on-hover-active-green number-counter-toggle">
                                {lead.comments_count ? lead.comments_count : 0}
                              </span>
                            </div>
                          </a>
                          <ReactTooltip />
                          {
                            <CommentMinified
                              id={lead.id}
                              loading={loading}
                              comments={leadComments}
                              mentionList={[]}
                              handleAddComment={handleAddComment}
                              showComments={showComments}
                            />
                          }
                        </div> : <></>}
                        {/* comments icon end */}

                         {/* email icon start */}
                         {showIcons ? <div className="dropdown-center dropdown with_separator_10 ps-0 before-dnone d-flex align-items-center card-view-less-padding close-toast">
                          <a href="#/" className="comman_action_icon w-100"
                            onClick={() => {
                              setShow(true);
                            }}
                            data-tip={_l("l_add_email_templates")}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                          >
                            <div className="d-flex align-items-center w-100">
                              <div className="action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img">
                                <EnvelopeSimple size={18} weight="light" className="c-icons" />
                              </div>
                              <span className="c-font f-12 d-flex justify-content-center flex-grow-1 text-on-hover-active-green number-counter-toggle">
                                0
                              </span>
                            </div>
                          </a>
                        </div>:<></>}
                        {/* email icon end */}

                        {/* Call icon start */}
                        {showIcons ? <div className="with_separator_10 card-view-less-padding ps-0 before-dnone ">
                          <a href="#/" className="comman_action_icon w-100 d-flex"
                            data-tip={_l("l_phone_label")}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main">
                            <div className="action_icon with_bg h32w32 position-relative on-hover-active-toggle-img light-theme-img">
                              <Phone size={18} weight="light" className="c-icons" />
                            </div>
                          </a>
                        </div> : <></>}
                        {/* call icon end */}

                        {/* Delete icon start */}

                        {showIcons ? <div className="dropdown action_icon with_bg h32w32 d-flex align-items-center justify-content-center z-index-0imp me-10px">
                          <a href="#/" type="button" className="line-height-0 " data-tip={_l("l_delete")}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                            id="deLeteDropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <Trash size={18} className="c-icons" weight="light" />
                          </a>
                          {/* <DeleteDropdown /> */}
                        </div> : <></>}
                        {/* Delete icon end */}
                        {/* assignee start */}
                        
                        {leadCompanyContactToggle == "lead_company" ? (
                             <div className="position-relative assignee-list-wrapper with_separator_10 ">
                             <div className="multi_inner_wrapper d-flex">
                               <a href="#/"
                                 className="comman-image-box  h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                                 data-effect="solid"
                                 data-delay-show='1000'
                                 data-class="tooltip-main"
                                 data-tip={_l("l_Service_provider")}
                               >
                                 <div
                                   className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                   style={{
                                     backgroundImage: `url('https://images.unsplash.com/photo-1685443930058-877ab44b526c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDF8Ym84alFLVGFFMFl8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60')`,
                                   }}
                                 ></div>
                               </a>
                             </div>
                           </div>
                            )
                            : leadCompanyContactToggle == "lead_contacts" ? (
                              <div className="position-relative assignee-list-wrapper with_separator_10 ">
                                <div className="multi_inner_wrapper d-flex">
                                  <a href="#/"
                                    className="comman-image-box  h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                                    data-effect="solid"
                                    data-delay-show='1000'
                                    data-class="tooltip-main"
                                    data-tip={_l("l_Service_provider")}
                                  >
                                    <div
                                      className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                      style={{
                                        backgroundImage: `url('https://images.unsplash.com/photo-1685443930058-877ab44b526c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDF8Ym84alFLVGFFMFl8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60')`,
                                      }}
                                    ></div>
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <>
                        <div className="position-relative assignee-list-wrapper with_separator_10 before-dnone ps-0">
                          <div className="multi_inner_wrapper d-flex">
                            <a href="#/"
                              className="comman-image-box  h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                              data-effect="solid"
                              data-delay-show='1000'
                              data-class="tooltip-main"
                              data-tip={_l("l_Service_provider")}
                            >
                              <div
                                className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                style={{
                                  backgroundImage: `url('')`,
                                }}
                              ></div>
                            </a>
                          </div>
                        </div>
                        <div className="position-relative assignee-list-wrapper with_separator_10 before-dnone ps-0">
                          <div className="multi_inner_wrapper d-flex">
                            <a href="#/"
                              className="comman-image-box  h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                              data-effect="solid"
                              data-delay-show='1000'
                              data-class="tooltip-main"
                              data-tip={`${_l("l_FM")} - ${
                                lead.assigned_details &&
                                  lead.assigned_details.full_name
                                  ? lead.assigned_details.full_name
                                  : ""
                              }`}
                            >
                              <div
                                className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                style={{
                                  backgroundImage: `url('')`,
                                }}
                              ></div>
                            </a>
                          </div>
                        </div>
                        <div className="position-relative me-10px assignee-list-wrapper card-view-less-padding">
                          <div className="multi_inner_wrapper with-min-width d-flex with_minus">
                            <a href="#/"
                              className="comman-image-box multi-wrapper-border h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                              data-effect="solid"
                              data-delay-show='1000'
                              data-class="tooltip-main"
                              data-tip={
                                lead.assigned_details &&
                                  lead.assigned_details.full_name
                                  ? lead.assigned_details.full_name
                                  : ""
                              }
                            >
                              <div
                                className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                style={{
                                  backgroundImage: `url('${lead.assigned_details &&
                                    lead.assigned_details.profile_image
                                    ? lead.assigned_details.profile_image
                                    : ""
                                    }')`,
                                }}
                              ></div>
                            </a>
                            <a href="#/" className="comman-image-box h20w20 with_overlay d-flex rounded-circle d-none">
                              <div className="unread_count m-auto">+2</div>
                            </a>
                          </div>
                        </div>
                        </>
                        )}
                        {/* assignee end */}
                        <div class="px-0 with_separator2_10 me-10px"></div>
                        {/* calendar icon start */}

                        {leadCompanyContactToggle == "lead_company" ? (
                          <>
                            <div className="border radius_3 d-flex align-items-center ps-5px pe-5px color-white-60 list-toggle-view-box h32wauto me-2">
                              <a href="#/" className="custom-datepicker datepicker-input w-90 mx-1">
                                <div className="d-flex align-items-center"
                                  data-tip={`${_l('l_appointment_date')}`}
                                  data-effect="solid"
                                  data-delay-show='1000'
                                  data-class="tooltip-main">
                                  <CalendarBlank size={16} weight="light" className="c-icons" />
                                  <DatePicker
                                    locale={getDatePickerLocale()}
                                    showTimeSelect
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText={`${_l(
                                      "l_appointment_date"
                                    )}`}
                                    calendarStartDay={1}
                                  ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                                </div>
                              </a>
                            </div>
                            <div className="border radius_3 d-flex align-items-center ps-5px pe-5px color-white-60 list-toggle-view-box h32wauto ">
                              <a href="#/" className="custom-datepicker datepicker-input w-90 mx-1">
                                <div className="d-flex align-items-center"
                                  data-tip={`${_l('l_signature_date')}`}
                                  data-effect="solid"
                                  data-delay-show='1000'
                                  data-class="tooltip-main">
                                  <CalendarBlank size={16} weight="light" className="c-icons" />
                                  <DatePicker
                                    locale={getDatePickerLocale()}
                                    showTimeSelect
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText={`${_l(
                                      "l_signature_date"
                                    )}`}
                                    readOnly={true}
                                    calendarStartDay={1}
                                  ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                                </div>
                              </a>
                            </div>
                          </>
                        ) : leadCompanyContactToggle == "lead_contacts" ? (
                          <>
                            <div className="comman_action_icon custom-datepicker w-330px withinputabsolute me-10px">
                              <a href="#/" className="action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img position-relative">
                                <div className="d-flex align-items-center w-100"
                                  data-tip={`${_l('l_appointment_date')}`}
                                  data-effect="solid"
                                  data-delay-show='1000'
                                  data-class="tooltip-main">
                                  <CalendarBlank size={18} weight="light" className="c-icons" />
                                  <DatePicker
                                    locale={getDatePickerLocale()}
                                    showTimeSelect
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText={`${_l(
                                      "l_appointment_date"
                                    )}`}
                                    readOnly={true}
                                    calendarStartDay={1}
                                  ></DatePicker>
                                </div>
                              </a>
                            </div>
                            <div className="comman_action_icon custom-datepicker w-330px withinputabsolute me-10px">
                              <a href="#/" className="action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img position-relative">
                                <div className="d-flex align-items-center w-100"
                                  data-tip={`${_l('l_signature_date')}`}
                                  data-effect="solid"
                                  data-delay-show='1000'
                                  data-class="tooltip-main">
                                  <CalendarBlank size={18} weight="light" className="c-icons" />
                                  <DatePicker
                                    locale={getDatePickerLocale()}
                                    showTimeSelect
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText={`${_l(
                                      "l_signature_date"
                                    )}`}
                                    readOnly={true}
                                    calendarStartDay={1}
                                  ></DatePicker>
                                </div>
                              </a>
                            </div>
                          </>
                        ) : (
                          <div className="comman_action_icon custom-datepicker w-330px withinputabsolute me-10px">
                            <a href="#/" className="action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img position-relative">
                              <div className="d-flex align-items-center w-100"
                                data-tip={`${lead.appointment_date
                                  ? formateDate(lead.appointment_date)
                                  : _l('l_appointment_date')}`}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main">
                                <CalendarBlank size={18} weight="light" className="c-icons" />
                                <DatePicker
                                  selected={
                                    lead.appointment_date
                                      ? new Date(lead.appointment_date)
                                      : ""
                                  }
                                  locale={getDatePickerLocale()}
                                  showTimeSelect
                                  dateFormat="dd/MM/yyyy"
                                  // placeholderText={` lead.appointment_date
                                  // ? new Date(lead.appointment_date)
                                  // : ${_l(
                                  //   "l_appointment_date"
                                  // )}`}
                                  readOnly={true}
                                  calendarStartDay={1}
                                ></DatePicker>
                              </div>
                            </a>
                          </div>
                        )}
                        {/* calendar icon end */}

                         {/* assigne to btn start */}
                         <div className={`comman_action_icon card-view-none me-10px `} >
                          <div className="d-flex align-items-center">
                            <div className="dropup dropup-center">
                              <UncontrolledDropdown setActiveFromChild={false} isOpen={openAssigneeDropdown} direction="end" className="w-200"
                                data-tip={`${_l("l_add_assignee")}`}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                                data-place="top">
                                <DropdownToggle id={`AddAssigneeMainDropdownNew_${lead.id}`} className="border-0  p-0 d-flex align-items-center" onClick={() =>
                                  setOpenAssigneeDropdown(!openAssigneeDropdown)
                                }>
                                  <div className="action_icon with_bg on-hover-active-toggle-img text-on-hover-active-green light-theme-img h32wauto  color-white-80 calendarpadding d-flex align-items-center" id={`AssigneeMainDropdownNew_${lead.id}`}>
                                    <UserCircleGear
                                      size={18}
                                      weight="light"
                                      className="c-icons"
                                    />
                                    <span className="c-font f-12 ps-2 text-uppercase no-display-in-responsive text-on-hover-active-green">{_l('l_assign_to')}</span>
                                  </div>
                                </DropdownToggle>
                                {/* { openAssigneeDropdown ? */}
                                {/* {openAssigneeDropdown == true ? <AddAssigneeMainDropdownNew
                                  key={lead.id}
                                  projectId={project_id}
                                  taskId={lead.id}
                                  openAssigneeDropdown={
                                    openAssigneeDropdown
                                  }
                                  setOpenAssigneeDropdown={
                                    setOpenAssigneeDropdown
                                  }
                                  fromTaskcard="1"
                                  selectedTagId={selectedTagId}
                                  pageName="task"
                                  selectedId={
                                    selectedTaskAssigneesId
                                  }
                                  isRequest={task.is_requested == 1}
                                  saveBtnToggle={true}
                                  selectedAssignee={selectedAssigneeNew}
                                  setSelectedAssignee={setSelectedAssigneeNew}
                                /> : <></>} */}
                              </UncontrolledDropdown>
                              
                            </div>
                          </div>
                        </div>
                        {/* assigne to btn end */}

                        <div className="dropdown  d-flex align-items-center justify-content-center z-index-0imp" onClick={()=>{
                          setShowIcons(!showIcons)
                        }}>
                          <a href="#/" type="button" className="line-height-0 action_icon with_bg h32w32" data-tip={_l("l_options")}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                            id="deLeteDropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <DotsNine size={18} className="c-icons" weight="light" />
                          </a>
                        </div>


                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="task-assign-section comman_action_icon">
                <div className="d-flex align-items-center justify-content-end">
                  

                  
                </div>
              </div>
            </div>
          </div>
          <ReactTooltip />
          <AddEmailTemplatesModal
            show={show}
            handleClose={modalhandleleadClose}
            handleShow={modalhandleleadShow}
          />
        </div>
      </div>
      {
        showConvertLeadModal ?
          <ConvertLeadToCustomer handleClose={() => { setShowConvertLeadModal(false); setLeadType('') }} handleSave={(data, spaceData, is_add_operator) => { convertLead(data, spaceData, is_add_operator) }} show={showConvertLeadModal} lead={lead} type={leadType} />
          :
          <React.Fragment></React.Fragment>
      }
    </React.Fragment>
  );
};

export default LeadCard;
