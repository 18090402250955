import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CommanLoader from '../Loader/CommanLoader';
import { CUSTOM_CLIENT_IDS, CUSTOM_HOMEPAGE_URLS, CUSTOM_ROLES } from '../../constants/constants';
import { leftPanelFilters, setSelectedGloablView } from '../../actions/customer';
import { useDispatch } from 'react-redux';

const Redirect = ({ handler }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { param } = useParams();
    const client_id = localStorage.getItem("client_id");
    const userType = localStorage.getItem("user_type");
    const contact_role = localStorage.getItem("contact_role");;
    let homepage = state ?  state.url : "/";

    useEffect(() => {
        if (param) {
            handler(param);
        }
        else if(state && state.url){
            if(CUSTOM_CLIENT_IDS.includes(client_id) && CUSTOM_HOMEPAGE_URLS[client_id])
            // if (userType == "contact" && CUSTOM_ROLES.includes(contact_role))
            {
              homepage = "/"
            //   dispatch(setSelectedGloablView("calendarView"))
            }
            setTimeout(() => {
                navigate(homepage);
            }, 2500);
        }else{
            setTimeout(() => {
                navigate("/");
            }, 2500);
        }
        dispatch(leftPanelFilters("", "", "topicFilterClear"));
    }, []);


    return (
        <div>
            <CommanLoader className="main-loader-wrapper full-screen" />
        </div>
    )
}

export default Redirect