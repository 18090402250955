import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l } from "../../hooks/utilities";
import customerServices from "../../services/customer-services";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../actions/messages";
import { updatedFloorList } from "../../actions/customer";
import { Spinner } from "reactstrap";
import Select from "react-select";
import CustomWhatsappIcon from "../../assets/icons/CustomWhatsappIcon";
import CustomGmailIcons from "../../assets/icons/CustomGmailIcons";
import CustomSlackIcon from "../../assets/icons/CustomSlackIcon";
import { XCircle } from "phosphor-react";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress } from "react-places-autocomplete";

const AddProvider = ({ show, handleClose, categoryList, selectedCategory }) => {
  const dispatch = useDispatch();
  const regex = /^[0-9\b]+$/;
  const optionsStrict = {
    componentRestrictions: {
      country: ["nl", "fr", "uk", "de", "esp", "ita", "bel", "che"],
    },
    fields: ["address_components", "geometry", "icon", "name"],
    strictBounds: false,
    types: ["address"],
  };
  const project_id = localStorage.getItem("selectedOffice");
  const [name, setName] = useState("");
  const [cost, setCost] = useState("");
  const [email, setEmail] = useState("");
  const [people, setPeople] = useState("");
  const [postalAddress, setPostalAddress] = useState("");
  const [countryCode, setCountryCode] = useState({
    value: "+33",
    label: "+33",
  });
  const [mobileNumber, setMobileNumber] = useState("");
  const [category, setCategory] = useState(selectedCategory);
  const [tools, setTools] = useState([]);
  const [categoryListing, setCategoryListing] = useState([]);
  const [loder, setLoder] = useState(false);
  const countryCodes = [
    {
      value: 1,
      label: "+32",
    },
    {
      value: 2,
      label: "+33",
    },
    {
      value: 3,
      label: "+49",
    },
    {
      value: 4,
      label: "+325",
    },
    {
      value: 5,
      label: "+34",
    },
    {
      value: 6,
      label: "+41",
    },
    {
      value: 7,
      label: "+44",
    },
  ];
  const addNewProvider = () => {
    if (category < 0 || category == '') {
      dispatch(showMessage('unsucess', _l('l_error'), _l('l_select_provider_category')));
    } else if (name == '') {
      dispatch(showMessage('unsucess', _l('l_error'), _l("l_please_enter_name")));
    } else if (countryCode.value.trim() == "") {
      dispatch(showMessage('unsucess', _l('l_error'),  _l('l_please_enter') + " " + _l('l_please_select_the_country_code')));
    } else if (mobileNumber.trim() == "") {
      dispatch(showMessage('unsucess', _l('l_error'),  _l('l_please_enter') + " " + _l('l_mobile_number')));
    } else if (email.trim() == "") {
      dispatch(showMessage('unsucess', _l('l_error'), _l("l_please_enter_email")));
    } else {
      setLoder(true);
      let mobileTemp = countryCode.value + " " + mobileNumber.trim();
      customerServices.addProviderToCategory(email, category, project_id, name, mobileTemp, cost, people, postalAddress, tools).then((res) => {
        setLoder(false);
        if (res.status == 1) {
          handleClose();
          clearForm();
          dispatch(showMessage('sucess', _l('l_success'), res.message));
        } else {
          dispatch(showMessage('unsucess', _l('l_error'), res.message));
        }
      });
    }
  }
  const deleteTools = (id) => {
    setTools(tools.filter(t => t.value != id))
  }

  useEffect(() => {
    if (categoryList && categoryList.length > 0) {
      setCategoryListing(categoryList)
    }
  }, [categoryList]);

  const handleSelect = async (value) => {
    setPostalAddress(value);
    geocodeByAddress(value)
      .then((results) => {
        for (var i = 0; i < results[0].address_components.length; i++) {
          var addressType = results[0].address_components[i].types[0];
          // for the country, get the country code (the "short name") also
          if (addressType === "country") {
            // setCountryCode(results[0].address_components[i].short_name);
          }
        }
        // getZipCode(results[0], false)
      })
      .catch((error) => console.error("Error", error));
  };

  const autosearchPlaces = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }) => {
    return (
      <div>
        <Form.Control
          placeholder={`${_l("l_address_placeholder")}`}
          type="text"
          name="address_3dplan"
          {...getInputProps({
            placeholder: "Avenue de France, Paris, France",
          })}
        />
        <div className="custom-map-suggestion border-0">
          {loading && <div>{_l("l_loading")}</div>}
          {suggestions.map((suggestion, index) => {
            return (
              <div {...getSuggestionItemProps(suggestion)} key={index}>
                <a>{suggestion.description}</a>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const clearForm = () => {
    setName('');
    setCategory('');
    setCost('');
    setEmail('');
    setPeople('');
    setPostalAddress('');
  }
  const toolsList = [
    {
      value: "1",
      label: "WhatsApp",
    },
    {
      value: "2",
      label: "Gmail",
    },
    {
      value: "3",
      label: "Slack",
    }
  ];
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleClose()
          clearForm()
        }}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l("l_add_provider")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          <div className="form-wrapper-main">
            <div className="row">
              <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                <Form.Label className="input-label">
                  {_l('l_select_category')}
                </Form.Label>
                <div className="d-flex flex-grow-1 align-items-center">
                  <Select
                    className="custom-select-menu w-100"
                    classNamePrefix="react-select"
                    placeholder={_l('l_please_select')}
                    options={categoryListing}
                    closeMenuOnSelect={true}
                    value={categoryListing.filter(f => f.value == category)[0]}
                    onChange={(e) => setCategory(e.value)}
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
                <Form.Label className="input-label ">
                  {_l("l_name")}
                </Form.Label>
                <Form.Control
                  placeholder={`${_l("l_name")}`}
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}

                />
              </Form.Group>
              
              <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
              <Form.Label className="input-label">{_l('l_phone_number')}</Form.Label>
              <div className="row">
                <div className="c_input_border drop_image col-5  position-relative">
                  <Select
                    className="custom-select-menu"
                    classNamePrefix="react-select"
                    placeholder={`${_l('l_country_code_placeholder')}`}
                    options={countryCodes}
                    closeMenuOnSelect={true}
                    value={countryCode}
                    onChange={(e) => {
                      setCountryCode(e);
                    }}
                  />
                </div>
                <div className="col-7  ps-md-0">
                  <Form.Control
                    placeholder={`${_l('l_phone_number_placeholder')}`}
                    type="Number"
                    name="phonenumber"
                    value={mobileNumber}
                    onChange={(e) => {
                      const reg = new RegExp("^[0-9+ ]+$");
                      const inputValue = e.target.value;
                      if (reg.test(inputValue) || inputValue == "")
                        setMobileNumber(inputValue);
                    }}
                  />
                </div>
              </div>
            </Form.Group>
            <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                <Form.Label className="input-label">
                  {_l("l_email")}
                </Form.Label>
                <Form.Control
                  placeholder={`${_l("l_email")}`}
                  type="text"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}

                />
              </Form.Group>
              <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_cost")}
                </Form.Label>
                <Form.Control
                  placeholder={`${_l("l_cost")}`}
                  type="number"
                  pattern="[0-9]*"
                  onChange={(e) => setCost(regex.test(e.target.value) ? e.target.value : 0)}
                  value={cost}

                />
              </Form.Group>
              <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_people")}
                </Form.Label>
                <Form.Control
                  placeholder={`${_l("l_people")}`}
                  type="text"
                  pattern="[0-9]*"
                  onChange={(e) => setPeople(regex.test(e.target.value) ? e.target.value : 0)}
                  value={people}

                />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_postal_address")}
                </Form.Label>
                <PlacesAutocomplete
                  value={postalAddress}
                  onChange={setPostalAddress}
                  onSelect={handleSelect}
                  searchOptions={optionsStrict}
                >
                  {autosearchPlaces}
                </PlacesAutocomplete>
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_select_tool")}
                </Form.Label>
                <Select
                  value={tools}
                  className="custom-select-menu max-h95 multi-select-with-scroll"
                  classNamePrefix="react-select"
                  options={toolsList}
                  isMulti
                  closeMenuOnSelect={false}
                  placeholder={`${_l('l_select_tool')}`}
                  onChange={(value) => {
                    setTools(value);
                  }}
                />
              </Form.Group>
              <div className="d-flex align-items-center">
                {
                  tools && tools.map((t, k) => {
                    return (
                      t != "" ?
                        <div className="badge rounded-pill text-nowrap d-flex align-items-center me-2" key={k}>
                          {t == "WhatsApp" || t.label == "WhatsApp" ?
                            <CustomWhatsappIcon className="h25w25" />
                            : t == "Gmail" || t.label == "Gmail" ?
                              <CustomGmailIcons className="h25w25" />
                              : t == "Slack" || t.label == "Slack" ?
                                <CustomSlackIcon className="h20w20" />
                                :
                                <></>
                          }
                          <span className="c-font color-white-80 f-12 flex-grow-1 px-2 text-truncate w100minus100per">{t.label}</span> <a href="#/" onClick={() => deleteTools(t.value)}><XCircle size={18} className="c-icons m-0 opacity-50" weight="light" /></a>
                        </div>
                        :
                        <></>
                    );
                  })
                }
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={() => {
            handleClose()
            clearForm()
          }}>
            {_l('l_close')}
          </Button>
          <Button variant="primary" size="sm" onClick={() => addNewProvider()}
            disabled={loder ? true : false}>
            {loder ? _l('l_please_wait') : _l('l_add')}
            {loder ? <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              className={`ms-2`}
              aria-hidden="true"
            />
              : <></>
            }</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddProvider;
