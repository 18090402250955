import React, { useRef, useEffect, useState } from "react";
import { COUNTRY_CODE_OPTIONS } from "../../../constants/constants";
import { _l, showError } from "../../../hooks/utilities";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { Eye, EyeSlash, Plus, X } from "phosphor-react";
import customerServices from "../../../services/customer-services";
import authServices from "../../../services/auth-services";



const RegisterClient = ({ contactDetail, setContactDetail ,countryCodes, fromRegister = false, companyListOptions, readOnlyMode, hideCompanyNameField, showTaxField}) => {
  const addressRef = useRef();
  const autoCompleteRef = useRef();
  const [passwordIcons, setPasswordIcons] = useState(false);
  const [showInputField, setshowInputField] = useState(true);
  const [allCompany, setallCompany] = useState({});
  const [customTax, setCustomTax] = useState(false);

  const taxes = [
    {value : '0',label: _l('l_no_tax')},
    {value : '20',label: _l('20%')},
    // {value : '30',label: _l('30%')}
  ];

  const addressOptions = {
    // componentRestrictions: { country: "fr" },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["address"],
  };
  const updateFormData = (e) => {
    let value = e.target.value;
    const fieldName = e.target.name;
    switch (fieldName) {
      case "first_name":
      case "last_name":
        value = value.substring(0, 30);
        break;
      case "mobile_number":
        value = value.replace(/[^0-9]/g, "").substring(0, 10);
        break;
      case "password":
      case "company_name":
      case "email":
        value = value.substring(0, 50);
        break;
      case "address":
        value = value.substring(0, 100);
        break;
      default:
        break;
    }
    setContactDetail({ ...contactDetail, [fieldName]: value });
  };

  const handleEmailCheck = (email) => {
    authServices.checkEmailforSetPassword(email).then((res)=>{
      if (res.status == 1) { 
        showError(_l(res.message))
      }
    })
  };

  useEffect(() => {
    if (!fromRegister) { 
    let buttonRef = document.getElementById("footer-primary-button");
    let buttonText = "";

    if (buttonRef) {
      buttonText = buttonRef.textContent;
      buttonRef.textContent = _l("l_setup_profile");
    }

    return () => {
      if (buttonRef && buttonText) {
        buttonRef.textContent = buttonText;
      }
    };
    }
  });

  useEffect(() => {
    try {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        addressRef.current,
        addressOptions
      ).addListener("place_changed", () => {
        updateFormData({target: {name: "address", value: addressRef.current.value}});
      });
    } catch (e) {
      console.log(e);
    }
  }, [JSON.stringify(contactDetail)]);

  useEffect(() => {
    if(contactDetail.user_type == 3){
      // let userType = 3
      let userType = contactDetail.user_type
      customerServices.getAllCompany(userType).then((res)=>{
        if(res.status){
          let tempCompanies = []
          res.data.forEach((com)=>{
            tempCompanies.push({
              value : com.userid,
              label :com.company
            })
            
          })
          setallCompany(tempCompanies)
        }
      })
    }
    
  }, [contactDetail.user_type])
  

  return (
    <div className="row justify-content-center">
      <div className="col-xl-12">
        <div className="row">
          <div className="col-xl-7 flex-grow-1">
            <div className="row">
              {
                hideCompanyNameField
                ?
                <React.Fragment></React.Fragment>
                :
                <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk no-asterisk">
                    {_l("l_company_name")}
                  </Form.Label>
                  <div className="d-flex align-items-center justify-content-between w-100 ">
                    {
                      companyListOptions && companyListOptions.length
                      ?
                      <React.Fragment>
                        <div className="d-flex align-items-center justify-content-between flex-grow-1">
                          <Select
                            className="custom-select-menu w-100 "
                            classNamePrefix="react-select"
                            placeholder={`${_l("l_add_type_of_space")}`}
                            options={companyListOptions}
                            isDisabled={readOnlyMode}
                            defaultValue={companyListOptions.find((w) => w.value == contactDetail.company_id)}
                            onChange={(e)=>{
                              setContactDetail({...contactDetail, company_id : e.value , company_name: e.label})
                            }}
                            isSearchable={true}
                          />
                        </div>
                      </React.Fragment>
                      :
                      <React.Fragment>
                        <div className="d-flex align-items-center justify-content-between flex-grow-1">
                        {showInputField ?
                          <input
                            className="form-control"
                            placeholder="Tesla"
                            type="text"
                            name="company_name"
                            value={contactDetail.company_name}
                            onChange={updateFormData}
                          /> :
                          <Select
                          className="custom-select-menu w-100 "
                          classNamePrefix="react-select"
                          placeholder={`${_l("l_add_type_of_space")}`}
                          options={allCompany}
                          onChange={(e)=>{
                            setContactDetail({...contactDetail, selectedCompanyId : e.value , company_name: e.label})
                          }}
                          isSearchable={true}

                        />}
                        </div>
                        {/* <a href="#/" className="align-items-center d-flex form-control h-100 h45w45 justify-content-center ms-2 px-2 on-hover-bg-white-05 " onClick={()=>{
                          if (contactDetail.user_type == 3) {                      
                            setshowInputField(!showInputField)
                          } 
                          }}>
                          <div className="h-100">
                              {showInputField ? 
                              <X size={18} className="c-icons" weight="light"/>                      
                              :  <Plus size={18} className="c-icons " weight="light" /> }
                          </div>
                        </a> */}
                      </React.Fragment>
                    }
                  </div>
                </Form.Group>
              }
              <Form.Group className={`${hideCompanyNameField ? "col-xl-12" : "col-xl-6"} c-input-box pb-3 position-relative`}>
                <Form.Label className="input-label no-asterisk no-asterisk">
                  {_l("l_contact_name")}
                </Form.Label>
                <input
                  className="form-control "
                  placeholder={_l("l_full_name_placeholder")}
                  type="text"
                  name="first_name"
                  value={contactDetail.first_name}
                  onChange={updateFormData}
                />
              </Form.Group>
              <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk no-asterisk">
                  {_l("l_phone_number")}
                </Form.Label>
                <div className="row g-0">
                  <div className="c_input_border drop_image col-3 position-relative pe-2">
                    <Select
                      className="custom-select-menu h53 border-03  scroll-height-30vh"
                      classNamePrefix="react-select"
                      placeholder={`${_l("l_country_code_placeholder")}`}
                      options={fromRegister ? countryCodes : COUNTRY_CODE_OPTIONS}
                      onChange={(e) =>
                        setContactDetail({
                          ...contactDetail,
                          country_code: e.value,
                        })
                      }
                      value={fromRegister ?
                        countryCodes.find(
                          (w) => w.label == contactDetail.country_code
                        )
                         :
                          COUNTRY_CODE_OPTIONS.find(
                        (w) => w.label == contactDetail.country_code
                      )}
                    />
                  </div>
                  <div className="col-9">
                    <Form.Control
                      className=""
                      placeholder={_l("l_phone_number_placeholder")}
                      type="text"
                      name="mobile_number"
                      value={contactDetail.mobile_number}
                      onChange={updateFormData}
                    />
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk no-asterisk">
                  {_l("l_email")}
                </Form.Label>
                <input
                  className="form-control "
                  type="email"
                  name="email"
                  disabled={readOnlyMode}
                  placeholder={_l("l_email_placeholder")}
                  value={contactDetail.email}
                  onChange={updateFormData}
                  onBlur={() => handleEmailCheck(contactDetail.email)}
                />
              </Form.Group>
              <Form.Group className={`${showTaxField ? "col-xl-6" : "col-xl-12"} c-input-box pb-3 position-relative`}>
                <Form.Label className="input-label no-asterisk no-asterisk">
                  {_l("l_postal_address")}
                </Form.Label>
                <input
                  className="form-control "
                  placeholder={_l("l_address_placeholder")}
                  type="text"
                  name="address"
                  ref={addressRef}
                  value={contactDetail.address}
                  onChange={updateFormData}
                />
              </Form.Group>
              {
                showTaxField
                ?
                <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk no-asterisk">
                    {_l("l_invoices_tax")}
                  </Form.Label>
                  <div className="comman_action_icon g-0 row">
                    <div className="col-11 pe-3">
                    {
                      !customTax
                      ?
                      <Select
                        className="custom-select-menu"
                        classNamePrefix="react-select"
                        placeholder={`${_l("l_tax")}`}
                        options={taxes}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        closeMenuOnSelect={true}
                        value={contactDetail.invoice_tax.value != '' ? contactDetail.invoice_tax : taxes[0]}
                        onChange={(value) => {
                          setContactDetail({...contactDetail, invoice_tax: value});
                        }}
                      />
                      :
                      <input
                        className="form-control "
                        placeholder={_l("l_tax")}
                        type="number"
                        value={contactDetail.invoice_tax.value}
                        onChange={(e) => {
                          setContactDetail({ ...contactDetail, invoice_tax: {value: e.target.value}});
                        }}
                      />
                    }
                    </div>
                   
                    <a className="bg-white-05 col-1 d-flex" 
                    onClick={() => {
                      setCustomTax(!customTax);
                      setContactDetail({ ...contactDetail, invoice_tax: {value: 0}});
                    }}>
                    {!customTax ? 
                      <Plus size={18} className="c-icons" weight="light" />
                      :
                      <X size={18} className="c-icons" weight="light" />
                    }
                    </a>
                    
                  </div>
                </Form.Group>
                :
                <React.Fragment></React.Fragment>
              }
              <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk no-asterisk">
                  {_l("l_password")}
                </Form.Label>
                <div className="position-relative">
                  <div className="d-flex align-items-center justify-content-between">
                <input
                  className="form-control "
                  placeholder="********"
                  type={passwordIcons ? "text" : "password"}
                  name="password"
                  value={contactDetail.password}
                  onChange={updateFormData}
                />
                    <div className="end-0 pe-3 position-absolute">
                      {!passwordIcons ? (
                        <a href="#/" className="img-width-18 "
                          onClick={() => {
                            setPasswordIcons(!passwordIcons);
                          }}>
                          <Eye size={18} className="c-icons" weight="light" />
                        </a>
                      ) : (
                        <a href="#/" className="img-width-18"
                          onClick={() => {
                            setPasswordIcons(!passwordIcons);
                          }}>
                          <EyeSlash size={18} className="c-icons" weight="light" />
                        </a>
                      )}
                    </div>
                  </div>
              </div>
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                <div className="d-flex align-items-center">
                  <Form.Check
                    className="pe-2"
                    type={"checkbox"}
                    checked={contactDetail.termsAndConditions}
                    onChange={(e) =>
                      setContactDetail({
                        ...contactDetail,
                        termsAndConditions: e.target.checked,
                      })
                    }
                  />
                  {_l("l_i_accept_terms_and_conditions")}
                  <a 
                    className="color-green ms-1"
                    href={"https://myr.ai/cgsc.html"}
                    target="_blank"
                  >
                    CGSC
                  </a>
                  <a 
                    className="color-green ms-1"
                    href={"https://myr.ai/cgsp.html"}
                    target="_blank"
                  >
                    CGSP
                  </a>
                  <span className="m-1">&</span>
                  <a
                    className="color-green ms-1"
                    href={"https://myr.ai/privacy-policy.html"}
                    target="_blank"
                  >
                    RGPD
                  </a>
                </div>
              </Form.Group>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterClient;
