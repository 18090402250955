import React from "react";
import { _l } from "../../../../hooks/utilities";
import {Form} from "react-bootstrap";

const HeaderWithInput = ({ inputs }) => {
  return (
    <div className="col-xl-12 position-sticky top-0 bg-white-03-solid z-index-2 light-theme-overall-scroll-white-shadow">
      <div
            className={` row`}
          >
            {inputs.map((input, key) => {
              return (
                <div key={key} className="col-4 mb-32px">
                  <Form.Label className="input-label no-asterisk no-asterisk">
                    {_l(input.label)}
                  </Form.Label>
                    <div className={`d-flex align-items-center justify-content-between form-control comman_action_icon h_53px  ${input.noBorder ? "d-flex align-items-center justify-content-between form-control comman_action_icon h_53px p-0 border-0" : ""}`}>
                      {input.jsxElement}
                    </div>
                  {/* <div className="c-font color-white-60 f-12 py-1">
                    {_l(input.label)}
                  </div> */}
                </div>
              );
            })}
          </div>
    </div>
  );
};

export default HeaderWithInput;
