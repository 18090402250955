import React, { useEffect, useRef, useState } from "react";
import {
  TaskModalClose,
  _l,
  setDefaultValueOfCallVariables,
  showLeftPanelNew,
} from "../../hooks/utilities";
import {
  Microphone,
  MicrophoneSlash,
  PhoneSlash,
  Record,
} from "phosphor-react";
import {
  callAction,
  endCall,
  muteCall,
  sendMessage,
  unMuteCall,
} from "../../actions/chat";
import { useDispatch, useSelector } from "react-redux";
import {
  setCallDisconnect,
  setCallFullscreen,
  setCallRecording,
  setCallRecordingAction,
} from "../../actions/customer";
import {
  ATTACH_BASE_URL,
  USR_IMG_PLACEHOLDER,
} from "../../actions/chat-action-type";
import jquery from "jquery";
import chatServices from "../../services/chat-services";
import { showMessage } from "../../actions/messages";

const FeedchatInTaskModalButton = ({}) => {
  const { callStatus, myStream, remoteStream } = useSelector(
    (state) => state.chat
  );
  const { callRecordingActive } = useSelector((state) => state.customer);

  const dispatch = useDispatch();
  const mimeType = "audio/wav";
  const mediaRecorder = useRef(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [callName, setCallName] = useState("");
  const [isMute, setIsMute] = useState(false);

  const disconnectCall = () => {
    if (callStatus.status == "initCall" && callStatus.callRole == "caller") {
      // autoEndTheCall(false);
    } else {
      dispatch(
        endCall({
          userId: callStatus.callerInfo._id,
          receiverId: callStatus.receiverInfo._id,
          callRole: callStatus.callRole,
        })
      );
    }
    // if(isRecording){
    // recordAction("stop");
    // }
    // if(isOtherRecording){
    // setIsOtherRecording(false);
    // }
  };

  const muteTheCall = async () => {
    myStream.getAudioTracks()[0].enabled = false;
    setIsMute(true);
    dispatch(
      muteCall({
        userId: callStatus.callerInfo._id,
        receiverId: callStatus.receiverInfo._id,
        callRole: callStatus.callRole,
      })
    );
  };
  const unMuteTheCall = () => {
    myStream.getAudioTracks()[0].enabled = true;
    setIsMute(false);
    dispatch(
      unMuteCall({
        userId: callStatus.callerInfo._id,
        receiverId: callStatus.receiverInfo._id,
        callRole: callStatus.callRole,
      })
    );
  };

  const doCallAction = (actionKey, actionVal) => {};

  useEffect(() => {
    if (!jquery.isEmptyObject(callStatus)) {
      if (callStatus.status == "notResponding") {
      }         
    }else{
        if(callStatus.callRole == "caller"){
          if(callStatus.receiverInfo){
              setCallName(callStatus.receiverInfo.fname+" "+callStatus.receiverInfo.lname);
          }
      }else if(callStatus.callRole == "receiver"){
          if(callStatus.callerInfo){
          //     setCallName(callStatus.callerInfo.fname+" "+callStatus.callerInfo.lname);
          setCallName(callStatus.receiverInfo.fname+" "+callStatus.receiverInfo.lname);
          }
      }
    
    }
  }, [JSON.stringify(callStatus)]);

  return (
    <>
      <div className="position-sticky bottom-0 start-0 file-parent-box">
        <div className="border-top"></div>
        <div className="p-10">
          <div className="d-flex align-items-center justify-content-center">
            {isMute ? (
              <a
                className="btn btn-white-05 w_100px rounded-5"
                onClick={() => {
                  unMuteTheCall();
                }}
              >
                <MicrophoneSlash size={32} weight="light" className="c-icons" />
              </a>
            ) : (
              <a
                className="btn btn-white-05 w_100px rounded-5"
                onClick={() => {
                  muteTheCall();
                }}
              >
                <Microphone size={32} weight="light" className="c-icons " />
              </a>
            )}

            <div className="p-15 py-0"></div>
            <a
              className="btn btn-white-05 w_100px rounded-5"
              onClick={() => {
                dispatch(
                  setCallRecordingAction(callRecordingActive ? "stop" : "start")
                );
              }}
            >
              {
                <Record
                  size={32}
                  weight="fill"
                  className={`c-icons ${
                    callRecordingActive ? "color-green" : ""
                  }`}
                />
              }
            </a>
            <div className="p-15 py-0"></div>
            <a
              className="btn btn-warning w_100px rounded-5"
              onClick={() => {
                dispatch(setCallDisconnect(true));
                dispatch(setCallRecording(false));
                // disconnectCall();
                TaskModalClose();
                dispatch(setCallFullscreen(false));
                showLeftPanelNew();
              }}
            >
              <PhoneSlash
                size={32}
                weight="light"
                className="c-icons black-l-white"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedchatInTaskModalButton;
