import React from 'react'
import Form from "react-bootstrap/Form";
import { _l } from "../../hooks/utilities";
import Select from "react-select";
import { X,UploadSimple } from "phosphor-react";

function AddOperatorStepTwo({ imagePreviews, setImagePreviews, joinText, setJoinText, sourceList, source, setSource }) {
    return (
        <>
            <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk ">
                {_l("l_profile_image_upload")}  
                </Form.Label>
                <div className="form-control file-upload position-relative">
                    <div className="d-flex align-items-center justify-content-center">
                        <input
                            className="  hidden-input"
                            type="file"
                            onChange={(e) => {
                                let previewArray = [];
                                Array.from(e.target.files).map((img) => {
                                    previewArray.push({ name: img.name, url: URL.createObjectURL(img) });
                                });
                                setImagePreviews({ previewArray: previewArray, file: e.target.files[0] })
                            }}
                        />
                        <span>
                        <UploadSimple size={18} className="c-icons" weight="light" />
                            <span className="color-white-60 ps-2">
                            {_l("l_drag_and_drop_or_browse")}
                            </span>
                        </span>
                    </div>
                </div>
            </Form.Group>
            <div className="col-xl-12">
                <div className=" comman-content-scroll-wrapper ">


                    <div className="comman-content-scroll pe-2 ">
                        {imagePreviews && imagePreviews.previewArray ? (
                            imagePreviews.previewArray.map((preview, index) => {
                                return (
                                    <div key={index} className="comman-list pe-0 w-100  comman-contnet-wrapper">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="comman-list-left w100minus100per flex-grow-1">
                                                <div className="d-flex align-items-center">
                                                    <div className="h30w30 comman-round-box m-auto">
                                                        <img
                                                            className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                                                            src={preview['url']}
                                                            alt="preview"
                                                        />
                                                    </div>
                                                    <div className="c-list-detail w100minus40 text-truncate ps-2 color-white-80">
                                                        {preview['name']}{" "}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="comman-list-right">
                                                <div className="d-flex align-items-center">
                                                    <div className="with_separator_10  d-flex align-items-center">
                                                        <a href="#/"
                                                            className="comman_action_icon"
                                                            onClick={() => setImagePreviews([])}
                                                        >
                                                            <div className="action_icon">
                                                            <X size={20} className="c-icons" weight="light" />
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
            <Form.Group className="col-xl-12 c-input-box pb-4 position-relative ">
                <Form.Label className="input-label ">

                </Form.Label>
                <Form.Control
                    placeholder={_l("l_why_do_you_want_to_join_us")}
                    type="text"
                    name=""
                    value={joinText}
                    onChange={(e) => {
                        setJoinText(e.target.value);
                    }}
                />
            </Form.Group>
            <Form.Group className="col-xl-12 c-input-box pb-4 position-relative ">
                <Form.Label className="input-label ">
                {_l("l_source")}
                </Form.Label>
                <Select
                    className="custom-select-menu"
                    classNamePrefix="react-select"
                    placeholder={_l("l_source")}
                    options={sourceList}
                    closeMenuOnSelect={true}
                    value={source}
                    onChange={(value) => { setSource(value) }}
                />
            </Form.Group>
        </>
    )
}

export default AddOperatorStepTwo