import React, { useEffect, useState } from "react";
import { _l } from "../../hooks/utilities";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { CaretRight, Hash, MagnifyingGlass } from "phosphor-react";
import CommanLoader from "../Loader/CommanLoader";
import store from "../../store";

const CategoryStaffList = ({ handler, item, type, list, subCategory, loader, openAssigneeDropdown, taskId}) => {

  const [myrStaff, setmyrStaff] = useState([]);
  const [selectedmyrStaff, setselectedmyrStaff] = useState([]);
  const [searchAssignees, setSearchAssignees] = useState("");
  const [searchmyrStaff, setSearchmyrStaff] = useState("");
  const [isParentMenuLoaded, setIsParentMenuLoaded] = useState(false);

  const { taskList } = store.getState().customer;
  const task = taskList.find((w) => w.id == taskId);

  const selectProvider = (index) => {
    if(selectedmyrStaff.length)
    {
      setselectedmyrStaff(selectedmyrStaff.map((w, i) => w = w == true ? false : i == index))
    }
  }

  const getAssignedCompanyCount = (company) => {
    let count = 0;

    if(!company)
    {
      return count;
    }

    if(item.type === "providers")
    {
      if(task && task.schedule_assignees && task.schedule_assignees.find((w) => w.userid == company.userid))
      {
        count++;
      }
    }
    return count;
  };

  const isOptionActive = (user) => {
    let flag = false;
    if(item.type === "providers")
    {
      if(task && task.schedule_assignees && task.schedule_assignees.find((w) => w.staffid == user.staffid))
      {
        flag = true;
      }
    }
    return flag;
  };

  const ItemSearchContent =
    <div
      className="comman-search-dropdown"
      data-bs-toggle="dropdown"
      data-bs-auto-close="outside"
      aria-expanded="false"
    >
      <div className="d-flex  w-100">
        <div className="comment-input-box flex-grow-1">
          <input
            id="contactSearch"
            type="text"
            className="form-control bg-transparent with-base-bg border-0 flex-grow-1 p-2 f-11 c-font"
            placeholder={`${_l("l_search")}`}
            value={searchAssignees}
            onChange={(e) => { setSearchAssignees(e.target.value) }
            }
          />
        </div>
        <a href="#/" className="comman_action_icon">
          <div className="d-flex h-100">
            <div className="action_icon h25w25 h-100">
              <MagnifyingGlass size={14} weight="light" className="c-icons" />
            </div>
          </div>
        </a>
      </div>
    </div>;

  const ItemSearchContentUser =
    <div
      className="comman-search-dropdown"
      data-bs-toggle="dropdown"
      data-bs-auto-close="outside"
      aria-expanded="false"
    >
      <div className="d-flex  w-100">
        <div className="comment-input-box flex-grow-1">
          <input
            id="contactSearch"
            type="text"
            className="form-control bg-transparent with-base-bg border-0 flex-grow-1 p-2 f-11 c-font"
            placeholder={`${_l("l_search")}`}
            value={searchmyrStaff}
            onChange={(e) => { setSearchmyrStaff(e.target.value) }
            }
          />
        </div>
        <a href="#/" className="comman_action_icon">
          <div className="d-flex h-100">
            <div className="action_icon h25w25 h-100">
              <MagnifyingGlass size={14} weight="light" className="c-icons" />
            </div>
          </div>
        </a>
      </div>
    </div>;

  const EquipmentsSubMenuContain = (
    <>
      {
        myrStaff && myrStaff.filter((user) => user.company && user.company.toLowerCase().includes(searchAssignees.toLowerCase())).length > 0 ?
          myrStaff.filter((user) => user.company && user.company.toLowerCase().includes(searchAssignees.toLowerCase())).map((team, key) => {
            return <UncontrolledDropdown isOpen={openAssigneeDropdown && selectedmyrStaff[key]} setActiveFromChild={true} direction="left" className="w-200">
              <DropdownToggle toggle="false" className="w-100 custom-btn-dropdown-items" onClick={() => selectProvider(key)}>
                <a href="#/" className="dropdown-item custom-assignees custom-class-active hr_sepretr w-100" onClick={() => { selectProvider(key) }}>
                  <div className="d-flex align-items-center">
                    <div className="c-list-icon">
                      <Hash size={20} weight="light" className="c-icons" />
                    </div>
                    <div className="ps-2 w100minus40 text_wrap">{team.company}</div>
                    {
                      getAssignedCompanyCount(team) > 0
                      ?
                      <span className="rounded-circle green-text badge with-bg d-flex h20w20 p-0"> <span className="m-auto p-0">{getAssignedCompanyCount(team)}</span></span>
                      :
                      <React.Fragment></React.Fragment>
                    }
                    {subCategory && subCategory.filter((sc) => sc.company == team.company).length > 0 ?
                      <span className="rounded-circle green-text badge with-bg d-flex h20w20 p-0"> <span className="m-auto p-0">{subCategory.filter((sc) => sc.company == team.company).length}</span></span>
                      :
                      ''
                    }
                    <div className="c-list-icon">
                      <CaretRight size={14} weight="light" className="c-icons" />
                    </div>
                  </div>
                </a>
              </DropdownToggle>
              <DropdownMenu container='body' className={`w-200 overflowscroll AssigneeDropdownCloseOutside${taskId}`}>
                <DropdownItem className="p-0" toggle={false}>
                  {ItemSearchContentUser}
                </DropdownItem>
                {team.members && team.members.filter((member) => member.fullname && member.fullname.toLowerCase().includes(searchmyrStaff.toLowerCase())).length > 0 ?
                  team.members.filter((member) => member.fullname && member.fullname.toLowerCase().includes(searchmyrStaff.toLowerCase())).map((user) => {
                    let Category = subCategory.filter((sc) => sc.company == team.company);
                    return (<>
                      {/* <UncontrolledDropdown setActiveFromChild={true} direction="end"> */}
                      <li toggle="false" className="w-100 hr_sepretr p-0">
                        <a href="#/" className={`dropdown-item custom-assignees custom-class-active p-0 ${isOptionActive(user) ? "active with-tick" : ""}`} onClick={() => {
                          if(!isOptionActive(user))
                          {
                            handler(item.type, item.id, item, "tag", type == "myrProvider" ? { ...user, is_existing: 2 } : user);
                          }
                          // if (selectedmyrStaff == user.id) {
                          //   setselectedmyrStaff(0)
                          // } else {
                          //   setselectedmyrStaff(user.id || user.staffid)
                          // }
                        }}>
                          <div className="w-100 dropdown dropend">
                            <div className={`d-flex align-items-center text-truncate p-2 dropdown-item custom-assigneesMain ${Category.filter((sc) => sc.staffid == user.staffid).length > 0 ? "active with-tick" : ""}`}
                              type="button"
                              id="EquipmentsSubMenu"
                              data-bs-toggle="dropdown">
                              <div className="c-list-icon">
                                <div
                                  className="h25w25 comman-round-box with-bg rounded-circle bg-white-05">
                                  <div
                                    className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                    style={{
                                      backgroundImage: `url('${user.profile_image_url}')`,
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <div className="ps-2 w100minus40 text_wrap">
                                <div
                                  className={"c-list-detail text-truncate"}
                                >
                                  {user.fullname}
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                      {/* </UncontrolledDropdown> */}
                    </>)
                  })
                  :
                  <CommanPlaceholder imgWidth="h60w60" placeholderText={_l("l_no_data")} />
                }
              </DropdownMenu>
            </UncontrolledDropdown>
          })
          :
          <CommanPlaceholder imgWidth="h60w60" placeholderText={_l("l_no_data")} />
      }
    </>

  );
  
  useEffect(() => {
    // if (list && list.length > 0 && type == "myrProvider") {
    //   setmyrStaff(list)
    // }
    let providerlist = [];
    let companylist = [];
    if (list && list.length > 0) {
      list.map((p, key) => {
        if (companylist.includes(p.userid)) {
          let index = companylist.indexOf(p.userid);
          providerlist[index].members.push({ ...p, id: p.staffid });
        } else {
          companylist.push(p.userid);
          providerlist.push({ userid: p.userid, company: p.company, members: [{ ...p, id: p.staffid }] });
        }
      })
      setmyrStaff(providerlist)
      if (selectedmyrStaff.length == 0) {
        setselectedmyrStaff(Array.from({ length: providerlist.length }).fill(false))
      }
    }
  }, [JSON.stringify(list)]);

  useEffect(() => {
    if (!openAssigneeDropdown) {
      setselectedmyrStaff(Array.from({ length: myrStaff.length }).fill(false));
    }
    else {
      //Timeout to get the parent menu load in order to avoid 
      setTimeout(() => {
        setIsParentMenuLoaded(true);
        if(list.length  && type === "past")
        {
          selectProvider(0);
        }
      });
    }
  }, [myrStaff, openAssigneeDropdown]);

  return (
    <>
    {
      isParentMenuLoaded
      ?
        <DropdownMenu direction="right" container='body' className={`w-200 overflowscroll AssigneeDropdownCloseOutside${taskId}`} aria-labelledby="">
          <DropdownItem className="hr_sepretr p-0" toggle={false}>
            {ItemSearchContent}
          </DropdownItem>
          {loader ? (
            <CommanLoader className="position-relative dropdown-loader comman-main-loader-wrapper" />
          ) :
            EquipmentsSubMenuContain
          }
        </DropdownMenu>
        :
        <React.Fragment></React.Fragment>
    }
    </>

  );
};

export default CategoryStaffList;
