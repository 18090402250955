import React, { useEffect, useState } from "react";
import EmojiPicker from "emoji-picker-react";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import "react-datepicker/dist/react-datepicker.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import { MentionsInput, Mention } from "react-mentions";
import ReactTooltip from "react-tooltip";
import {
  _l,
  CloseCommentModal,
  tabindexFix,
  getImagePreviewIcon,
  getPreviewFromURL,
  stringReplacer,
  TaskModalClose,
  showSuccess,
  showError,
  hideLeftPanelNew,
  showLeftPanelNew,
  removeDuplicateObjects,
  getFileDetails,
  removeDuplicates,
  isSolarHubPlatform,
  getFileType
} from "../../hooks/utilities";
import { showMessage } from "../../actions/messages";
import ReactHtmlParser from "react-html-parser";
import { useDispatch,useSelector } from "react-redux";
import CommanLoader from "../Loader/CommanLoader";
import jQuery from "jquery";
import Lightbox from "yet-another-react-lightbox";
import { MagnifyingGlass, UploadSimple, VideoCamera, PushPin, ArrowRight, Paperclip, DotsThreeVertical, At, Hash, X, Info , ArrowBendDownLeft, DownloadSimple, Check, Checks, Smiley, CornersIn, CornersOut, Star, Cpu, Robot, ArrowBendUpLeft, PlayCircle, FilePdf} from "phosphor-react";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import {
  UsersThree,
} from "phosphor-react";
import TaskShareLinkModal from "../Modals/TaskShareLinkModal";
import MeetingProviderModal from "../Modals/MeetingProviderModal";
import { SET_NEW_MEETING } from "../../actions/action-type";
import Button from 'react-bootstrap/Button';
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { isLoadingData } from "../../hooks/loader_helper";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import {
  initCall
} from "../../actions/chat";
import { NO_EXTERNAL_URLS } from "../../constants/constants";
import { getTaskAttechments, setCallFullscreen, setImageViewerisOPen, setTaskAttchmentsForCreate } from "../../actions/customer";
import { mentionStyle } from "../../assets/mention/mentionStyle";
import jquery from "jquery";
import { leadModalToggle } from "../../actions/customer";
import { commanoffcanvasisOpen, commanoffcanvasisOpenfromCalendar,updateHideLoader } from "../../actions/customer";
import moment from "moment";
import StarRatingDropdown from "../TaskModalComponents/StarRatingDropdown";
import AIContextModal from "../Modals/AIContextModal";
import { useNavigate } from "react-router-dom";
import CustomerServices from "../../services/customer-services";
import Typing from "../Typing/Typing";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { mentionInputStyle } from "../../assets/mention/mentionInputStyle";
let fileUplods = [];
const CommentSection = ({
  show,
  handleClose,
  commentList,
  mentionList,
  handleAddOrEditComment,
  loading,
  handleDeleteComment,
  handleSearchComment,
  isReadOnly = false,
  docType = "",
  external = false,
  data,
  comment,
  meetingUrl,
  contacts = [],
  ImageViewerComments=false,
  commanCommentSection=false,
  guestcommentCount = 0,
  callUserList = [],
  showFullscreen=false,
  setSelectedAttachment = () => {},
  setShowTaskImageDropDown = () => {},
  showCloseIcon,
  isRelativeView=false,
  IsKanbanView = false,
  disableFullScreenToggle=false,
  isExternal,
  setShowFullscreen,
  handleAction,
  setShowCommentModal,
  setActiveTaskID=()=>{},
  onCloseShowLeftPanelDefault,
  type,
  handleTaskRating =()=>{},
  taskRating = 0,
  getTaskComments,
  selectedAttachment,
  iscallModal = false,
}) => {
  const [showSearch, setShowSearch] = useState(false);
  const [searchComment, setSearchComment] = useState("");
  const [commentInput, setCommentInput] = useState("");
  const [showloader, setShowloader] = useState(loading);
  const [commentId, setCommentId] = useState(0);
  const [isMentionListShow, setIsMentionListShow] = useState(false);
  const [comments, setComments] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [previewSrc, setPreviewSrc] = useState([]);
  const [openGallery, setOpenGallery] = useState(false);
  const [showTaskImage, setShowTaskImage] = useState(false);
  const [attachmentDetails, setAttachmentDetails] = useState({});
  const [selectedAttachmentId, setSelectedAttachmentId] = useState(0);
  const [allAttachments, setAllAttachments] = useState([]);
  const [showLink, setShowLink] = useState(false);
  const handleCloseShareLink = () => {setShowLink(false); setMailForComment({})};
  const handleShowShareLink = () => setShowLink(true);
  const [meetingModal, setMeetingModal] = useState(false);
  const [task, setTask] = useState(data ? data : []);
  const [isTaskOwner, setIsTaskOwner] = useState(false);
  const [showExternalImage, setshowExternalImage] = useState(false);
  const [mailForComment, setMailForComment] = useState({})
  const [viewAIContext, setViewAIContext] = useState(false);
  const [waitingForAIResponse, setWaitingForAIResponse] = useState(false);
  const guestUsrerCommentsCount = JSON.parse(
    localStorage.getItem("guest_comment")
  );
  const projectId = localStorage.getItem("selectedOffice")
  // const [contacts, setContacts] = useState([]);
  const navigate = useNavigate();
  const options = {
    decodeEntities: true,
  };
  const {calendarCanvasIsOpen, globalView,isCallFullscreen,callComments, leftPanelTab  } = useSelector((state) => state.customer);
  const {callStatus, isCallRecived } = useSelector((state) => state.chat);
  const [showEmoji, setShowEmoji] = useState(false);
  const adminAsStaff = localStorage.getItem('adminArchieve') && localStorage.getItem('adminArchieve') ? true : false;

  let contextRelType = ""
  let contact_role  = localStorage.getItem("contact_role")
  const onClick = (emojiData) => {
    setCommentInput((commentInputCallback)=>commentInputCallback + emojiData.emoji)
  }
  useEffect(() => {
    setShowloader(loading);
  }, [loading]);

  useEffect(() => {
    if (commentList && commentList.length) {
      setComments(commentList);
      scrollToBottom();
      commentList.map((item) => {
        if (item.attachments && item.attachments.length > 0) {
          item.attachments.map((item2) => {
            setAllAttachments((allAttachments) => [
              ...allAttachments,
              {
                id: item2.id,
                task_id: data ? data.id : 0,
                task_comment_id: item.id,
                image_url: item2.file_name,
                dateadded: item.dateadded,
              },
            ]);
          });
        }
      });
    } else {
      setComments(comment);
    }
  }, [JSON.stringify(commentList)]);

  // useEffect(() => {
  //   jQuery(".office-list-icon, #openCommentModal,#left-office-list,.office-box").on(
  //     "click",
  //     function () {
  //       if (show) {
  //         handleClose();
  //         CloseCommentModal();
  //       }
  //     }
  //   );
  // }, []);

  useEffect(() => {
    if (msgListDiv && msgListDiv.scrollHeight) {
      scrollToBottom();
    }
  }, [msgListDiv]);

  const contact_id = localStorage.getItem("contact_id");
  const staff_id = localStorage.getItem("staff_id");
  const userType = localStorage.getItem("user_type");
  const contact_image = localStorage.getItem("contact_image");
  const contact_full_name = localStorage.getItem("full_name");
  const msgListDiv = document.getElementById("comment-list");

  useEffect(() => {
    setIsTaskOwner(
      !external &&
        ["imageview", "task", "request-task"].includes(docType) &&
        data.addedfrom > 0 &&
        data.addedfrom == (userType == "contact" ? contact_id : staff_id) &&
        data.is_added_from_contact == (userType == "contact" ? "1" : "0")
        ? true
        : false
    );
  }, [data]);

  const preparePreview = (files) => {
    let previrewArray = [];
    Array.from(files).map((img) => {
      let preview_url = getImagePreviewIcon(img);
      previrewArray.push({ name: img.name, url: preview_url });
    });
    fileUplods = files;
    setImagePreviews(previrewArray);
  };
  const removeImageSelection = (name) => {
    imagePreviews.forEach((thumbnail) => {
      URL.revokeObjectURL(thumbnail.url);
    });
    const updatedFileList = Array.from(fileUplods).filter(
      (img) => img.name != name
    );

    // setImageBuffer(updatedFileList);
    preparePreview(updatedFileList);
  };

  const dispatch = useDispatch();
  const editComment = (id) => {
    const targetComment = comments.filter((comment) =>
      docType == "proposal" ? comment.comment_id == id : comment.id === id
    );
    if (targetComment.length) {
      setCommentId(id);
      setCommentInput(
        targetComment[0].content ||
          targetComment[0].comment ||
          targetComment[0].description
      );
    }
  };
  const mentionListToggle = () => {
    if (!isMentionListShow) {
      setCommentInput("@");
      jQuery("#comment_input").focus();
    } else {
      setCommentInput("");
    }
    setIsMentionListShow(!isMentionListShow);
  };
  const scrollToBottom = () => {
    if (commentList.length) {
      try {
        if (msgListDiv && msgListDiv.scrollHeight && msgListDiv.scrollHeight != null)
          msgListDiv.scrollTop = msgListDiv.scrollHeight
            ? msgListDiv.scrollHeight
            : 0;
      } catch (err) {
        console.log(err);
      }
    }
  };
  // useEffect(() => {
  //     $('.chat-wrapper').scrollTop(0)
  // }, [comments])
  const setAttchmentOnTop = (id) => {
    const targetAttchement = allAttachments.filter(
      (attachment) => attachment.id === id
    );
    const otherAttachments = allAttachments.filter(
      (attachment) => attachment.id != id
    );
    setAllAttachments(targetAttchement.concat(otherAttachments));
  };
  const handleImagePreview = (comment, image, index) => {
    if (!external && ["imageview", "task", "request-task"].includes(docType)) {
      let preview = {};
      preview.url = getPreviewFromURL(image.file_url || image.file_name);
      if (typeof preview.url !== "string") {
        const link = document.createElement("a");
        link.href = image.file_url;
        link.download = image.file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setShowTaskImage(true);
        let attachmentForTask = {
          id: image.id,
          task_id: data ? data.id : 0,
          task_comment_id: comment.id,
          image_url: image.file_name,
          dateadded: comment.dateadded,
          latitude: comment.latitude,
          longitude: comment.longitude,
        };
        setAttachmentDetails(attachmentForTask);
        setSelectedAttachment(attachmentForTask);
        dispatch(setTaskAttchmentsForCreate(attachmentForTask));
      }
    } else {
      // setOpenGallery(true);
      let selectAttachment = comment.attachments.filter(
        (file, innerIndex) => innerIndex == index
      );
      let otherAttachment = comment.attachments.filter(
        (file, innerIndex) => innerIndex != index
      );
      setPreviewSrc(
        selectAttachment.concat(otherAttachment).map((src) => {
          return {
            src: src.file_name,
          };
        })
      );
    }
  };
  let previrewArray = [];
  let preview_url = " ";
  const setCopiedFiles = (e) => {
    if (e.clipboardData.files.length <= 5) {
      e.preventDefault();
      Array.from(e.clipboardData.files).map((img) => {
        preview_url = getImagePreviewIcon(img);
        previrewArray.push({ name: img.name, url: preview_url });
        setImagePreviews(previrewArray);
        fileUplods = e.clipboardData.files;
      });
    } else {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_maximum_5_files_are_allowed_at_a_time")
        )
      );
      return;
    }
  };

  const makeCall = (myrUserId, email, myrUserType) => {
    dispatch(
      initCall({
        userId: localStorage.getItem("orgChatUserId"),
        myrUserId: myrUserId,
        email: email,
        myrUserType: myrUserType == "contact" ? "client" : "staff",
        extraData: {
          taskId: data.id,
          taskName: data.name,
        },
      })
    );
  };

  useEffect(() => {
    if (msgListDiv && msgListDiv.scrollHeight) {
      scrollToBottom();
    }
  }, [comments, msgListDiv]);

  const hideCommentSection = () => {
    let flag = false;
    let selectedProposal = localStorage.getItem("proposalId");

    if (data.proposal_id && data.proposal_status == 2) {
      flag = true;
    } else if (
      data.proposal_list &&
      data.proposal_list.length &&
      selectedProposal
    ) {
      let list = data.proposal_list[0].proposals;
      let proposalDetails = list.find((x) => x.proposal_id == selectedProposal);
      if (proposalDetails && proposalDetails.status == 2) {
        flag = true;
      }
    }
    return flag;
  };

  const commentSectionContain = (
    <div className="task-detail-right-main h-100 d-flex flex-column res-width-100 task-detail-right-main position-relative w-100">
      {isLoadingData("get_comments") ? (
        <div className="">
          <CommanLoader
            className={`dropdown-loader comman-main-loader-wrapper h-100`}
          />
        </div>
      ) : (
        <>
          <div
            className={`border-bottom Comment-header h54px p-10 ${
              showCloseIcon ? "pb22px" : ""
            }`}
          >
            <div className="d-flex align-items-center">
              <div className="right-task-header flex-grow-1 p-0">
                <div className="d-flex align-items-center justify-content-between">
                  <div className={`task-header-left-options ${isRelativeView ? "":"d-none"}`}>
                    <div className="d-flex align-items-center justify-content-between">
                      {isRelativeView ? (
                        <div className="d-flex align-items-center title-fonts  color-white">
                          <div className="h32w32 comman-image-box with-bg rounded-circle bg-white-05">
                            <div id="chat-screen-recipient-image" className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{backgroundImage:`url('')`}}>

                            </div>
                          </div>
                          <div id="chat-screen-recipient-name" className="w100minus30 ps-2 text-truncate"></div>
                        </div>
                      ) : (
                        <div className="title-fonts fw-semibold color-white d-none">
                          {_l("l_comments")}
                        </div>
                      )}
                      <div className="dropend d-none">
                        <a href="#/" className="comman_action_icon with_separator_10 before-dnone">
                          <div className="action_icon with_bg">
                            <PushPin
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="task-header-right-option flex-grow-1">
                    <div className="d-flex align-items-center justify-content-between">
                      <a href="#/" className="action_icon d-none">
                        <MagnifyingGlass
                          size={18}
                          weight="light"
                          className="c-icons"
                        />
                      </a>
                      <a href="#/"
                        className="action_icon ml-10 on-hover-active-toggle-img d-none"
                        data-tip={_l("l_upload_file")}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                      >
                        <UploadSimple
                          size={18}
                          weight="light"
                          className="c-icons"
                        />
                      </a>
                      {NO_EXTERNAL_URLS.includes(docType) || (data && data.taskDetails && data.taskDetails.id  && data.taskDetails.id == "CreateTask") ? (
                        <React.Fragment></React.Fragment>
                      ) : (
                        <a href="#/"
                          id="commentOptionDropdown"
                          // className={`${external ? "d-none" : ""}`}
                          data-bs-toggle="dropdown"
                          onClick={() => {}}
                        >
                          <div className="btn btn-primary  text-uppercase  action_icon">
                            {_l("l_share")}
                          </div>
                        </a>
                      )}
                      { external ? <ActionDropdown
                        actions={[
                          {
                            actionName: _l("l_copy_link"),
                            actionHandler: () => {
                              navigator.clipboard.writeText(
                                `${process.env.REACT_APP_MYR_WEB_URL}/${data.taskDetails ? 'task' : docType}/${data.hash ? data.hash : data.taskDetails.hash ? data.taskDetails.hash : ""}`
                              );
                              dispatch(
                                showMessage(
                                  "sucess",
                                  _l("success"),
                                  _l("l_link_copied")
                                )
                              );
                            },
                            actionIcon: "CopyIcon",
                          }
                        ]}
                      /> : <ActionDropdown
                        actions={[
                          {
                            actionName: _l("l_copy_link"),
                            actionHandler: () => {
                              navigator.clipboard.writeText(
                                `${process.env.REACT_APP_MYR_WEB_URL}/${data.taskDetails ? 'task' : docType}/${data.hash ? data.hash : data.taskDetails.hash ? data.taskDetails.hash : ""}`
                              );
                              dispatch(
                                showMessage(
                                  "sucess",
                                  _l("success"),
                                  _l("l_link_copied")
                                )
                              );
                            },
                            actionIcon: "CopyIcon",
                          },
                          {
                            actionName: _l("l_send_to_email"),
                            actionHandler: () => {
                              setShowLink(true);
                            },
                            actionIcon: "EnvelopeSimpleIcon",
                          },
                          {
                            actionName: _l("l_open_link"),
                            actionHandler: () => {
                              window.open(
                                `${process.env.REACT_APP_MYR_WEB_URL}/${data.taskDetails ? 'task' : docType}/${data.hash ? data.hash : data.taskDetails.hash ? data.taskDetails.hash : ""}`
                              );
                            },
                            actionIcon: "LinkSimpleIcon",
                          },
                        ]}
                      />
                      }
                      {
                        isRelativeView
                        ?
                        <React.Fragment></React.Fragment>
                        :
                        <>
                        <a href="#/"
                          className="action_icon ml-10 on-hover-active-toggle-img d-none h32w32"
                          data-tip={_l("l_video_camera")}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                          onClick={() => {
                            if (meetingUrl && meetingUrl != "") {
                              let data = {
                                link: meetingUrl,
                                newTaskFlag: 0,
                                newRequestFlag: 0,
                                task_id: task ? task.id : 0,
                              };
                              dispatch({
                                type: SET_NEW_MEETING,
                                payload: { data },
                              });
                            } else {
                              setMeetingModal(true);
                            }
                          }}
                        >
                          <VideoCamera
                            size={18}
                            weight="light"
                            className="c-icons"
                          />
                        </a>
                        <div className="d-flex align-items-center ms-auto comman_action_icon">
                        {!external &&
                      data.addedfrom > 0 &&
                      ["imageview", "task", "request-task"].includes(
                        docType
                      ) ? (
                        <></>
                      ) : (
                        <></>
                      )}
                          <div className={`before-dnone with_separator_10 ${IsKanbanView || disableFullScreenToggle || ImageViewerComments ? "d-none" : ""}`}>
                            {showFullscreen || isCallFullscreen ? (
                              <a href="#/"
                                className={`action_icon with_bg h32w32 d-flex  ${isExternal ? "d-none" : ""}`}
                                onClick={() => {
                                  if (iscallModal) {
                                    handleAction("modalClose");
                                    TaskModalClose();
                                    dispatch(setCallFullscreen(false));                                    
                                    showLeftPanelNew()
                                  } else { 
                                    setShowFullscreen(false);
                                    handleAction("showFullscreen", false);
                                    setShowCommentModal(true);
                                  }

                                }}
                                // id="modal_zoom_in"
                                data-tip={`${_l("l_reset_screen")}`}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                              >
                                <CornersIn
                                  size={18}
                                  className="c-icons zoom_in"
                                  weight="light"
                                />
                              </a>
                            ) : (
                              <a href="#/"
                                className={`action_icon with_bg h32w32 d-flex ${isExternal ? "d-none" : ""}`}
                                onClick={() => {
                                  if (iscallModal) {
                                    dispatch(setCallFullscreen(false));
                                    TaskModalClose()
                                  }else{
                                  setShowFullscreen(true);
                                  handleAction("showFullscreen", true);
                                  setShowCommentModal(true);
                                  }

                                }}
                                // id="modal_zoom_out"
                                data-tip={`${_l("l_full_screen")}`}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                              >
                                <CornersOut
                                  size={18}
                                  className="c-icons zoom_out"
                                  weight="light"
                                />
                              </a>
                            )}
                          </div>
                         {!isCallFullscreen? <a href="#/"
                            type="button"
                            className={`modal-close action_icon with_bg h32w32 d-flex position-relaive modal-close-button z-index-5 ${isExternal ? "d-none" : ""
                              }`}
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            onClick={() => {
                              if (isSolarHubPlatform() && userType == "operator" && window.location.pathname.includes("/subscription")) {
                                navigate("/?page=proposals#/");
                                return
                              }
                              dispatch(commanoffcanvasisOpenfromCalendar(false))
                              handleAction("modalClose");
                              if(docType ==="imageview"){
                                dispatch(setImageViewerisOPen(false));   
                                if(jquery('#commanCanvasImageBody').length == 1){
                                  if(jquery('#commonCanvasBody').length == 0){
                                    if(jquery(".modal-full-left-panel").length > 1){
                                      if(jquery('body').hasClass('with-right-penel')){
                                      }else{
                                        jquery('body').addClass('with-right-penel');
                                      }
                                      jquery('body').addClass('task-modal-offcanvas-open');
                                      }else{
                                      jquery('body').removeClass('with-right-penel');
                                      jquery('body').removeClass('task-modal-offcanvas-open');
                                    }
                                      jquery('body').addClass('with-left-penel');
                                  }
                                  if((jquery('.comman-comments').length == 0)&& jquery("#commonCanvasBody").length ==1){
                                      jquery('body').removeClass('with-right-penel');
                                      jquery('body').addClass('with-left-penel');
                                  }
                              }
                              dispatch(setTaskAttchmentsForCreate())
                              }else{
                              dispatch(updateHideLoader(false));
                              dispatch(commanoffcanvasisOpen(false))
                              dispatch(commanoffcanvasisOpenfromCalendar(false))
                              if (calendarCanvasIsOpen) {
                                setActiveTaskID(0)
                              }

                              if (onCloseShowLeftPanelDefault) {
                                TaskModalClose(onCloseShowLeftPanelDefault);
                              } else {
                                TaskModalClose();
                              }
                              if (type == "lead") {
                                dispatch(leadModalToggle(false));
                              }
                              if ( leftPanelTab == "homePage") {
                                showLeftPanelNew();
                                
                              } else if ((projectId == 0 || projectId == null || projectId == undefined) && (window.location.pathname != "/dashboard") && leftPanelTab !== "my-drive") {
                                hideLeftPanelNew();
                              }else if ((window.location.pathname == "/dashboard") || leftPanelTab == "homePage"){
                                showLeftPanelNew();
                              }
                            }
                            }}
                            data-tip={`${_l("l_close")}`}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                          >
                            <X size={16} className="c-icons" weight="light" />
                          </a> :<></>}
                        </div>  
                        </>
                      }
                      {meetingModal ? (
                        <MeetingProviderModal
                          meetingProvider={meetingModal}
                          HandleClose={() => {
                            setMeetingModal(false);
                          }}
                          selectedTask={data}
                          commentSection={1}
                        />
                      ) : (
                        <></>
                      )}
                      
                    </div>
                  </div>
                </div>
              </div>
              {external ? (
                <></>
              ) : (
                <a href="#/"
                  type="button"
                  data-tip={_l("l_close")}
                  data-effect="solid"
                  data-delay-show='1000'
                  data-class="tooltip-main"
                  className={`modal-close with_separator_10 pe-0 position-relaive  z-index-5 ${
                    showCloseIcon ? "" : "d-none"
                  }`}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <X size={18} weight="light" className="c-icons" />
                </a>
              )}
            </div>
          </div>
          <div className="comman-tab-wrapper pb-0">
            <div className="d-flex align-items-center w-100">
              <div className="tab-wrapper d-flex align-items-center justify-content-center flex-grow-1 d-none">
                <a href="#/" className="tab-name">{_l("l_comments")}</a>
                <a href="#/" className="info-icon">
                  <Info
                    size={18}
                    weight="light"
                    className="c-icons opacity-50"
                  />
                </a>
              </div>
              <div className="active d-flex align-items-center justify-content-center flex-grow-1 d-none">
                <a href="#/" className="tab-name">{_l("l_internal_rating")}</a>
                <a href="#/" className="info-icon">
                  <Info
                    size={18}
                    weight="light"
                    className="c-icons opacity-50"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="right-chat-wrapper position-relative d-flex flex-column">
            {showSearch ? (
              <div className="search-with-close p-0 m-0 border-bottom">
                <div className="d-flex align-items-center ">
                  <div className="comment-input-box bg-base-header-color pb-0 radius_3  w-100">
                    <input
                      className="bg-base-header-color border-0 c-font f-12 form-control p-2 radius_3"
                      placeholder={`${_l("l_search")}`}
                      value={searchComment}
                      onChange={(e) => {
                        setSearchComment(e.target.value);
                        handleSearchComment(e.target.value);
                      }}
                    />
                  </div>
                  <a href="#/"
                    className="bg-base-header-color border-left d-flex h35w35 line-height-0"
                    onClick={() => {
                      setShowSearch(false);
                      setSearchComment("");
                      handleSearchComment("");
                    }}
                  >
                    <div className="img-width-18 m-auto">
                      <X size={16} weight="light" className="c-icons" />
                    </div>
                  </a>
                </div>
                <div className="pill-wrapper d-none">
                  <a href="#/"
                    className="comman_action_icon filter-list-view d-none"
                    id="filter-show-list"
                  >
                    <div className="action_icon with_bg_03 h35w35">
                      <div className="img-width-18 m-auto">
                        <MagnifyingGlass
                          size={16}
                          weight="light"
                          className="c-icons"
                        />
                      </div>
                    </div>
                  </a>
                  <div id="show-filter-list" className="filter-list-toggle">
                    <div className="d-flex align-items-center filter-lists-menu min-width-190 justify-content-end">
                      <div className="d-flex commn-search radius_3 w-100 justify-content-between">
                        <div className="comment-input-box">
                          <input
                            type="text"
                            id="task-search-string"
                            className="form-control bg-transparent with-base-bg border-0 py-1 px-2 f-11 c-font"
                            placeholder={`${_l("l_search")}`}
                            data-bs-toggle="tooltip"
                          />
                        </div>
                        <a href="#/" className="comman_action_icon">
                          <div className="d-flex align-items-center ">
                            <div className="action_icon h25w25">
                              <MagnifyingGlass
                                size={14}
                                weight="light"
                                className="c-icons"
                              />
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="chat-main-box flex-grow-1 comman_vertical_btn_h">
              <div className="d-flex flex-column h-100">
                <div className="c-font f-12 py-1 px-2 title-fonts color-white-60 border-bottom d-none">
                  {_l("l_comments")}
                </div>

                <div className="chat-wrapper WithBorderLeftRight position-relative" id="comment-list">
                  {comments && comments.length ? (
                    comments.map((comment, index) => {

                      const replacements = [
                        { keyword: '{username}', replacement: comment.mention_staff_name },
                        { keyword: '{mention_id}', replacement: comment.mention_id },
                        { keyword: '{mention_type}', replacement: comment.mention_type },
                        { keyword: '{sender_name}', replacement: comment.sender_name },
                      ];
                      comment.content = stringReplacer(replacements,_l(comment.content));
                      const commentContent =
                        comment.content ||
                        comment.comment ||
                        comment.description;
                      const dummyDiv = document.createElement("div");
                      dummyDiv.innerHTML = commentContent;
                      return (
                        <div
                          className={`chat-box ${
                            (userType == "contact" &&
                              contact_id === comment.contact_id) ||
                            (userType == "staff" && staff_id == comment.staffid)
                              ? ""
                              : ""
                          } ${
                            comment.is_allowed_to_edit == 1 || (isCallRecived && comment.staffid == localStorage.getItem("staff_id"))
                              ? "right-side-msg"
                              : ""
                          }`}
                          key={index}
                        >
                          <div className="d-flex">
                            <div
                              className="h32w32 comman-image-box with-bg rounded-circle bg-white-05"
                              data-tip={
                                comment.full_name ||
                                comment.contact_full_name ||
                                comment.guest_user_name ||
                                comment.staff_full_name
                              }
                              data-effect="solid"
                              data-delay-show='1000'
                              data-class="tooltip-main"
                            >
                              <div
                                className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                style={{
                                  backgroundImage: `url('${comment.profile_url}')`,
                                }}
                              ></div>
                            </div>
                            <div className={`chat-msg`}>
                              <div className="chat-text-box">
                                {/* Reply Image start */}
                                <div
                                  className={`p-0 w-100 ${
                                    comment && comment.parent_comment_id > 0
                                      ? ""
                                      : "d-none"
                                  }`}
                                >
                                  <div className="bg-transparent  p-0 w-100">
                                    <div>
                                      <ArrowBendDownLeft
                                        size={14}
                                        className="c-icons m-auto"
                                        weight="light"
                                      />
                                    </div>
                                    <div className="border-bottom image-upload-wrapper mb-2 w-100">
                                      <div className="d-flex align-items-center flex-wrap base-body-color radius_3">
                                        {
                                          comment && comment.parent_comment_details && !comment.parent_comment_details.file_url
                                          ?
                                          <span className="p-1">{ReactHtmlParser(
                                            comment && comment.parent_comment_details && comment.parent_comment_details.content.replace(
                                              /(?:\r\n|\r|\n)/g,
                                              "<br>"
                                            )
                                          )}</span>
                                          :
                                          <a href="#/" class="h40w40 comman-round-box with-bg d-flex align-items-center bg-white-05 p-5px radius_3  mb-2">
                                            <div
                                              className="comman-bg-img h-100 w-100 bg-style-cover"
                                              style={{
                                                backgroundImage: `url(${
                                                  comment &&
                                                  comment.parent_comment_details
                                                    ? `${comment.parent_comment_details.file_url}`
                                                    : ""
                                                })`,
                                              }}
                                            ></div>
                                          </a>
                                        }
                                      </div>
                                    </div>
                                    {/* <div className="chat-text c-font f-12">image</div> */}
                                  </div>
                                </div>
                                {/* Reply Image over */}
                                <div className="image-upload-wrapper">
                                  {comment.attachments &&
                                  comment.attachments.length ? (
                                    <div className="d-flex align-items-center flex-wrap ">
                                      {comment.attachments.map(
                                        (image, index) => {
                                          if (index < 2) {
                                            let preview = {};
                                            preview.url = getPreviewFromURL(
                                              image.file_name
                                            );

                                            return (
                                              <>
                                              {["wav","mp3"].includes(getFileDetails(image.file_url || image.file_name))
                                              ? <>
                                                    <div className="chat-audio withf12 small">
                                                      <AudioPlayer
                                                        style={{ width: '200px', margin: "0px" }}
                                                        layout="horizontal"
                                                        // autoPlay
                                                        className="audio_player p-0 bg-transparent box-shadow-none "
                                                        showJumpControls={false}
                                                        showSkipControls={false}
                                                        customAdditionalControls={[]}
                                                        customVolumeControls={[]}
                                                        src={image.file_url || image.file_name}
                                                        autoPlay={false}
                                                        autoPlayAfterSrcChange={false}
                                                      />
                                                    </div>
                                              </> :
                                              <a href="#/"
                                                key={index}
                                                className="h60w60 comman-round-box  with-bg d-flex align-items-center bg-white-05 p-5px radius_3  m-1  with-top-overlay"
                                              >
                                                  <div
                                                    className="absolute-download-icon"
                                                    onClick={() => {
                                                      window.open(
                                                        image.file_url ||
                                                        image.file_name,
                                                        "_self"
                                                      );
                                                    } }
                                                  >
                                                    <DownloadSimple
                                                      size={14}
                                                      color="#ffffff"
                                                      weight="light" />
                                                  </div>
                                                  {typeof preview.url ===
                                                    "string" ? (
                                                    <div
                                                      className="comman-bg-img h-100 w-100 bg-style-cover"
                                                      onClick={() => {
                                                        if (external) {
                                                          setshowExternalImage(
                                                            true
                                                          );
                                                          setAttchmentOnTop(
                                                            image.id
                                                          );
                                                        } else {
                                                          handleImagePreview(
                                                            comment,
                                                            image,
                                                            index
                                                          );
                                                        }
                                                      } }
                                                      style={{
                                                        backgroundImage: `url('${image.file_url || image.file_name}')`,
                                                      }}
                                                    ></div>
                                                  ) : (
                                                    <>
                                                      <div
                                                        className="h-100 w-100 d-flex"
                                                        style={{
                                                          alignContent: "center",
                                                          flexWrap: "wrap",
                                                        }}
                                                      >
                                                        <preview.url
                                                          size={72}
                                                          weight="light"
                                                          className="c-icons" />
                                                      </div>
                                                      <div className="max-w-130px pt-2 d-none">
                                                        <div className="c-font text-truncate f-12 color-white-60 ">
                                                          {decodeURIComponent(
                                                            image.file_name.substring(
                                                              image.file_name.lastIndexOf(
                                                                "/"
                                                              ) + 1
                                                            )
                                                          )}
                                                        </div>
                                                      </div>
                                                    </>
                                                  )}
                                                </a>}</>
                                            );
                                          } else if (index === 2) {
                                            return (
                                              <a href="#/"
                                                className="h60w60 comman-round-box  with-bg d-flex align-items-center bg-white-05 p-5px radius_3 m-1 with_overlay"
                                                onClick={() => {
                                                  if (external) {
                                                    setshowExternalImage(true);
                                                    setAttchmentOnTop(image.id);
                                                  } else {
                                                    handleImagePreview(
                                                      comment,
                                                      image,
                                                      index
                                                    );
                                                  }
                                                }}
                                              >
                                                <div class="unread_count m-auto">
                                                  +
                                                  {comment.attachments.length -
                                                    2}
                                                </div>
                                              </a>
                                            );
                                          }
                                        }
                                      )}
                                    </div>
                                  ) : (
                                    <>
                                      {comment.description === undefined ? (
                                        <>
                                          <div>
                                            {ReactHtmlParser(
                                              comment.content && comment.content.replace(
                                                /(?:\r\n|\r|\n)/g,
                                                "<br>"
                                              )
                                            )}
                                          </div>
                                        </>
                                      ) : (
                                        " "
                                      )}
                                    </>
                                  )}
                                </div>
                                <div className="chat-text">
                                  {" "}
                                  {comment.description
                                    ? ReactHtmlParser(
                                        comment.description.replace(
                                          /(?:\r\n|\r|\n)/g,
                                          "<br>"
                                        )
                                      )
                                    : ""}
                                </div>
                                <div
                                  className={`dropdown dropdown-center chat-action-option ${
                                    comment.is_allowed_to_edit == "1" || (isCallRecived && comment.staffid == localStorage.getItem("staff_id"))
                                      ? ""
                                      : "d-none"
                                  }`}
                                >
                                  <a href="#/"
                                    className="line-height-0 "
                                    type="button"
                                    id="commentOptionDropdown"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    data-tip={_l("l_options")}
                                    data-effect="solid"
                                    data-delay-show='1000'
                                    data-class="tooltip-main"
                                  >
                                    <DotsThreeVertical
                                      size={18}
                                      weight="light"
                                      className="c-icons"
                                    />
                                  </a>
                                  <ActionDropdown
                                    actions={[
                                      {
                                        actionName: _l("l_edit"),
                                        actionHandler: () => {
                                          editComment(
                                            docType == "proposal"
                                              ? comment.comment_id
                                              : comment.id
                                          );
                                        },
                                        actionIcon: "PencilSimpleLineIcon",
                                      },
                                      {
                                        actionName: _l("l_send_to_email"),
                                        actionHandler: () => {
                                          setMailForComment(comment);
                                          setShowLink(true);
                                        },
                                        actionIcon: "EnvelopeSimpleIcon",
                                      },
                                      {
                                        actionName: _l("l_delete"),
                                        actionHandler: () => {
                                          docType == "proposal"
                                            ? handleDeleteComment(
                                                comment.comment_id,
                                                comment.comment
                                              )
                                            : handleDeleteComment(comment.id);
                                        },
                                        actionIcon: "TrashIcon",
                                      },
                                    ]}
                                  />
                                </div>
                              </div>
                              <div className="chat-send-name c-font f-10 opacity-50 d-flex justify-content-start align-items-center">
                                <span className="me-2">{moment(comment.dateadded).format("DD/MM/YYYY hh:mm A")}</span>
                                {/* <span className="me-2">{comment.time}</span> */}
                                <span>{comment.date}</span>
                                <span className="delivered-status-icon message-read d-none">
                                  <Check size={14}  weight="light" className="c-icons msg-status ms-2 read-msg color-green" />
                                  <Checks size={14}  weight="light" className="c-icons msg-status ms-2 msg-delivered-double" />
                                  <Check size={14}  weight="light" className="c-icons msg-status ms-2 msg-delivered-single" />
                                </span>
                              </div>
                            </div>
                            <ReactTooltip />
                          </div>
                          <ReactTooltip />
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <CommanPlaceholder
                        mainSpacing="h-100"
                        imgType="no-comment"
                        placeholderText={_l("l_no_comments")}
                      />
                    </>
                  )}
                  
                </div>
                {
                    waitingForAIResponse
                    ?
                      <div className="ps-20pximp"> 
                        <Typing />
                      </div>
                      :
                      <></>
                  }
                <React.Fragment>
                  {external ? (
                    <div className="align-items-center bg bg-white-05 box-shadow-2 d-flex justify-content-between p-2 color-white">
                      <span className="f-12 c-font">
                        {_l("l_login_as_user")}
                      </span>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => {
                          navigate("/login");
                        }}
                      >
                        {_l("l_login")}
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                  {external &&
                  (guestcommentCount > 7 ||
                    (guestUsrerCommentsCount &&
                      guestUsrerCommentsCount[0].comment_count >= 7)) ? (
                    <div className="align-items-center bg bg-white-05 box-shadow-2 d-flex justify-content-between p-2 btn btn-outline-danger for-disabled">
                      <span className="f-12 c-font">
                        {_l("l_you_have_reached_your_maximum_limit")}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
                <React.Fragment>
                  {imagePreviews && imagePreviews.length ? (
                    <div className="upload-image-preview p-2">
                      <div className="d-flex align-items-center justify-content-start">
                        {imagePreviews.map((preview, index) => {
                          if (typeof preview.url === "string") {
                            return (
                              <div
                                key={index}
                                className="comman-image-box h60w60 radius_3 upload-image with-margin mt-2"
                              >
                                <div
                                  className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                                  style={{
                                    backgroundImage: `url(
                                                '${preview.url}'
                                              )`,
                                  }}
                                ></div>
                                <a href="#/"
                                  className="upload-close"
                                  onClick={() => {
                                    // handleAction('removePreview', preview.name)
                                    removeImageSelection(preview.name);
                                  }}
                                >
                                  <X
                                    size={10}
                                    weight="light"
                                    className="c-icons"
                                  />
                                </a>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                key={index}
                                className="comman-image-box h60w60 radius_3 upload-image with-margin d-flex"
                              >
                                <preview.url
                                  size={40}
                                  weight="light"
                                  className="c-icons"
                                />
                                <a href="#/"
                                  className="upload-close"
                                  onClick={() => {
                                    // handleAction('removePreview', preview.name)
                                    removeImageSelection(preview.name);
                                  }}
                                >
                                  <X
                                    size={10}
                                    weight="light"
                                    className="c-icons"
                                  />
                                </a>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
                {hideCommentSection() ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <>
                  {showEmoji ?
                    <EmojiPicker
                      onEmojiClick={onClick}
                      autoFocusSearch={false}
                      lazyLoadEmojis= {true}
                      previewConfig={{
                        // defaultEmoji: string; // defaults to: "1f60a"
                        // defaultCaption: string; // defaults to: "What's your mood?"
                        showPreview: false // defaults to: true
                      }}
                    />
                    : <></>
                  }
                  <div
                    className={`chat-comment-box  border-top ${
                      isReadOnly ||
                      guestcommentCount > 7 ||
                      (guestUsrerCommentsCount &&
                        guestUsrerCommentsCount[0].comment_count >= 7)
                        ? "for-disabled"
                        : ""
                    }`}
                  >
                    {/* Reply section for attachment view - Start */}
                    {
                      selectedAttachment && Object.keys(selectedAttachment).length
                      ?
                      <div className="aicommentbox-reply p-2" id="aicommentbox-reply">
                        <div className="d-flex flex-wrap align-items-center">
                          <ArrowBendUpLeft weight="light" size="18" className="c-icons" />
                          <div className="overflowscroll w100minus30">
                              <div className="d-flex align-items-center justify-content-start base-body-color radius_3 overflow-hidden p-1 ">
                                {/* <div className="color-white-60">
                                  {replyMsgTask.type == "text" ?
                                    <>
                                      {ReactHtmlParser(urlify(replyMsgTask.message.replace(/(?:\r\n|\r|\n)/g, '<br>')))}
                                    </>
                                    : <></>}
                                  {replyMsgTask.caption != "" ?
                                    <>
                                      {ReactHtmlParser(urlify(replyMsgTask.caption.replace(/(?:\r\n|\r|\n)/g, '<br>')))}
                                    </>
                                    : <></>}
                                </div> */}
                                  <div className="h50w50 with-bg d-flex align-items-center bg-white-05">
                                  {getFileType(selectedAttachment.image_url) == "pdf" ?
                                    <FilePdf size={32} className="comman-bg-img h-100 w-100 bg-style-cover"/>
                                    :
                                    <div className="comman-bg-img h-100 w-100 bg-style-cover " style={{ backgroundImage: `url('${selectedAttachment.image_url}')` }}></div>
                                  }
                                  </div>
                              </div>
                          </div>
                        </div>
                      </div> 
                      :
                      <React.Fragment></React.Fragment>
                    }
                    {/* Reply section for attachment view - End */}
                    <div className="d-flex m-auto flex-column chat-main-box">
                    <div className={`comment-input-box border-bottom pb-10px ${waitingForAIResponse ? 'for-disabled' :''}`}>
                      <div className="d-flex align-items-center position-relative ">
                        <div
                          className="h32w32 comman-image-box rounded-circle"
                          data-tip={contact_full_name}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                        >
                          <div
                            className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                            style={{
                              backgroundImage: `url(${contact_image})`,
                            }}
                          ></div>
                        </div>
                        <div
                          className="mension-box mension-box--multiLine withpl7px"
                          style={{
                            position: "relative",
                            overflowY: "visible",
                          }}
                        >
                          {/* <textarea placeholder="Write comment" className="border-0 bg-transparent"></textarea> */}
                          <MentionsInput
                            value={commentInput}
                            className="mension-box"
                            placeholder={`${_l("l_write_comment_placeholder")}`}
                            alwaysOpen={true}
                            style={mentionInputStyle}
                            onChange={(e) => {
                              setCommentInput(e.target.value);
                            }}
                            // onKeyUp={(e) => {
                            //   if (e.keyCode === 13 && !e.ctrlKey && !e.shiftKey) {
                            //   }
                            // }}
                            onKeyDown={(e) => {
                              if (
                                e.keyCode === 13 &&
                                !e.ctrlKey &&
                                !e.shiftKey
                              ) {
                                e.preventDefault();
                                aiMentionAddOrEditComment()
                                // handleAddOrEditComment({
                                //   content: commentInput,
                                //   files: fileUplods,
                                //   editCommentId: commentId,
                                // });
                                // setShowEmoji(false)
                                // setCommentId(0);
                                // setCommentInput("");
                                // setImagePreviews([]);
                                // fileUplods = [];
                              }
                            }}
                            id="comment_input"
                          >
                            <Mention
                              markup='<span style="font-weight: 600;"  contenteditable="false" data-mention-id="__id__"  data-mce-style="font-weight: 600;"  data-mce-selected="1">@__display__</span>'
                              trigger="@"
                              data={mentionList}
                              displayTransform={(id,display) => `@${display}`}
                              style={mentionStyle}
                              renderSuggestion={(
                                suggestion,
                                search,
                                highlightedDisplay
                              ) => {
                                return (
                                  <div className="user d-flex align-items-center">
                                    <div className="comman-image-box h32w32 rounded-circle mx-2">
                                      {suggestion.isteam == 1 ? (
                                        <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle d-flex">
                                          {" "}
                                          <UsersThree className="m-auto opacity-60" />{" "}
                                        </div>
                                      ) : (
                                        <div
                                          className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                          style={{
                                            backgroundImage: `url('${suggestion.image}')`,
                                          }}
                                        ></div>
                                      )}
                                    </div>
                                    {highlightedDisplay}
                                  </div>
                                );
                              }}
                            />
                          </MentionsInput>
                        </div>
                           
                        <a href="#/"
                          className="send-arrow-btn with_position_right_zero"
                          data-tip={_l("l_send")}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                          onClick={() => {
                            aiMentionAddOrEditComment();
                            // handleAddOrEditComment({
                            //   content: commentInput,
                            //   files: fileUplods,
                            //   editCommentId: commentId,
                            // });
                            // setShowEmoji(false)
                            // setCommentId(0);
                            // setCommentInput("");
                            // setImagePreviews([]);
                            // fileUplods = [];
                            // scrollToBottom();
                          }}
                        >
                          <ArrowRight
                            size={18}
                            weight="light"
                            className="c-icons"
                          />
                        </a>
                      </div>
                    </div>
                    </div>
                    <div className="comment-bottom-option">
                      <div className="d-flex align-items-center">
                        <div
                          className={`comman_action_icon mr-10 ${
                            docType == "proposal" ? "pe-none" : ""
                          }`}
                        >
                          <div className="d-flex align-items-center">
                            <a href="#/"
                              className="action_icon with_bg h32w32"
                              data-tip={_l("l_mention")}
                              data-effect="solid"
                              data-delay-show='1000'
                              data-class="tooltip-main"
                              onClick={() => {
                                mentionListToggle();
                                // handleAddComment({ content: commentInput, files: fileUplods });
                                // setCommentInput("");
                              }}
                            >
                              <At
                                size={18}
                                weight="light"
                                className="c-icons"
                              />
                            </a>
                          </div>
                        </div>
                        <div
                          className={`comman_action_icon mr-10 Forbeforednone  ${
                            external ? "for-disabled d-none" : ""
                          } `}
                        >
                          <div className="d-flex align-items-center">
                            <a href="#/"
                              className="action_icon with_bg h32w32 position-relative"
                              data-tip={_l("l_choose_file")}
                              data-effect="solid"
                              data-delay-show='1000'
                              data-class="tooltip-main"
                            >
                              <input
                                type="file"
                                multiple="multiple"
                                className="absolute-input"
                                accept=".png,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.zip,.rar,.txt,.jpeg,.mp4,.m4r,.m4a,.mp3,.glb"
                                onChange={(event) => {
                                  if (event.target.files.length > 4) {
                                    dispatch(
                                      showMessage(
                                        "unsucess",
                                        _l("l_error"),
                                        _l(
                                          "l_maximum_4_files_are_allowed_at_a_time"
                                        )
                                      )
                                    );
                                    return;
                                  }
                                  preparePreview(event.target.files);
                                }}
                                onClick={(e) => {
                                  e.target.value = "";
                                }}
                              />
                              <Paperclip
                                size={18}
                                weight="light"
                                className="c-icons"
                              />
                            </a>
                          </div>
                        </div>
                        {docType == "task" || docType == "request-task" ? 
                        <UncontrolledDropdown className={`w-100 h-100 ${userType == "contact" && JSON.parse(contact_role) != 3 ? "" : "for-disabled" }`}>
                            <DropdownToggle className="p-0" data-toggle="dropdown" tag="span">
                                <a href="#/" className="comman_action_icon "
                                type="button"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                aria-expanded="false">
                                        <div className="action_icon h32w32 with_bg position-relative on-hover-active-toggle-img d-flex align-items-center">
                                            <Star size={18} weight="light" className="c-icons" />
                                        </div>
                                </a>
                            </DropdownToggle>
                            <DropdownItem toggle={false} className="p-0">
                            <StarRatingDropdown 
                            handler={(starValue)=>{
                              handleTaskRating(starValue)
                            }}
                            rating={taskRating}
                            />
                            </DropdownItem>
                        </UncontrolledDropdown>
                      :
                      <></>}
                      {
                        showAIContextModal() && adminAsStaff ? 
                        <>
                          <div className={`comman_action_icon mr-10 ${waitingForAIResponse ? 'disabledElenent' :''}`}>
                            <a href="#/"
                              className="action_icon with_bg h32w32 position-relative"
                              data-tip={_l("l_ai_context")}
                              data-effect="solid"
                              data-delay-show='1000'
                              data-class="tooltip-main"
                              onClick={() => {setViewAIContext(true)}}
                            >
                            <Cpu
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                            </a>
                          </div>
                          <div className="comman_action_icon mr-10">
                            <a href="#/"
                              className="action_icon with_bg h32w32 position-relative"
                              data-tip={_l("l_save_task_context")}
                              data-effect="solid"
                              data-delay-show='1000'
                              data-class="tooltip-main"
                              onClick={() => autoUpdateAIContext()}
                            >
                            <Robot
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                            </a>
                          </div>
                        </>
                          :
                          <></>
                      }
                        <div className="comman_action_icon mr-10">
                          <div className="d-flex align-items-center">
                            <a href="#/"
                              className={`action_icon with_bg h32w32 with-border-active ${showEmoji ? "active":""}`}
                              onClick={() => { setShowEmoji(!showEmoji) }}
                            >
                              <Smiley size={18} className="c-icons" weight="light" />

                            </a>
                            </div>
                        </div>
                        
                        <div className="comman_action_icon with_separator_10 d-none">
                          <div className="d-flex align-items-center">
                            <a href="#/" className="action_icon with_bg h32w32">
                              <Hash
                                size={18}
                                weight="light"
                                className="c-icons"
                              />
                            </a>
                          </div>
                        </div>
                        {
                          isRelativeView
                          ?
                          <React.Fragment></React.Fragment>
                          :
                          <div className="comman_action_icon  for-accessible before-dnone ms-auto">
                            <div className="d-flex align-items-center">
                              <a href="#/"
                                className="action_icon with_bg h32w32"
                                data-tip={`${_l("l_search")}`}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                                onClick={() => {
                                  setShowSearch(!showSearch);
                                }}
                              >
                                <MagnifyingGlass
                                  size={18}
                                  weight="light"
                                  className="c-icons"
                                />
                              </a>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );

  const fetchAIContextForTask = async() => {
    let contextDescription = "";
    let taskContext = "";
    let attachmentId = 0;
    let parentCommentId = 0;
    let projectId = data.rel_id || data.space_project_id;
    let taskId = data.id;

    if(docType === "imageview")
    {
      taskId = selectedAttachment.task_id;
      attachmentId = selectedAttachment.id;
      parentCommentId = selectedAttachment.task_comment_id;
      projectId = data.projectId || data.space_project_id;
    }

    try {
      const response = await CustomerServices.fetchTaskAIContext(taskId, projectId, attachmentId, parentCommentId);
      if(response.status)
      {
        contextDescription = response.data;
        taskContext = response.extra && response.extra.task_context ? response.extra.task_context : "";
      }
    }
    catch(e)
    {
      console.error(e);
    }
    finally {
      return {contextDescription, taskContext};
    }
  };

  const saveTaskAIContext = async(content, message, save_and_send, is_refresh_context, new_comment) => {
    try {

      let taskId = data.id;
      let attachmentId = 0;
      let parentCommentId = 0;
      let projectId = data.rel_id || data.space_project_id;
      let imgurl = '';
      if(docType === "imageview")
      {
        taskId = selectedAttachment.task_id;
        attachmentId = selectedAttachment.id;
        parentCommentId = selectedAttachment.task_comment_id;
        projectId = data.projectId || data.space_project_id;
        imgurl = selectedAttachment.image_url;
      }

      const response = await CustomerServices.saveTaskAIContext(taskId, projectId, content, message, save_and_send, is_refresh_context, attachmentId, parentCommentId, imgurl, new_comment);
      if(response.status)
      {
        // showSuccess("l_content_updated");
      }
      else if(response.message) {
        showError(response.message);
      }
    }
    catch(e)
    {
      showError("l_something_went_wrong");
      console.error(e);
    }
    finally {
      if(!is_refresh_context)
      {
        getTaskComments(0, 0, docType === "imageview" ? 1 : 1);
        setViewAIContext(false);
      }
    }
  };

  const autoUpdateAIContext = async() => {
    try {
      let commentInputSet = commentInput
      setCommentInput("");
      setWaitingForAIResponse(true);
      document.getElementById("comment_input").blur();
      const {contextDescription, taskContext} = await fetchAIContextForTask();
      let contextValue = "";
      let newComment = "";
      if (docType === "task" || docType === "request-task") {
        newComment = commentInputSet.trim();
      }
      if(docType === "imageview")
      {
        newComment = commentInputSet.trim();
        contextValue = contextDescription;
      }
      else {
        contextValue = contextDescription + taskContext;
      }
      await saveTaskAIContext(contextValue, "", "", "", newComment);
      if(newComment.length)
      {
        setCommentInput("");
      }
    }
    catch(e)
    {
      console.error(e);
      showError("l_something_went_wrong");
    }
    finally {
      setWaitingForAIResponse(false);
      document.getElementById("comment_input").focus();
    }
  };

  const aiMentionAddOrEditComment = () => {
    
    // find contact role proccess --- Start ---
    let mentionIdArry = [];
    let contact_roleArray = [];
    let regex = /data-mention-id="([^"]*)"/g;
    const mentionedId = [...commentInput.matchAll(regex)];
    for (const match of mentionedId) {
        mentionIdArry.push(match[1]);
    }
    mentionList && mentionList.forEach((item) => {
      if (item.id && mentionIdArry && mentionIdArry.includes(item.id)){
          contact_roleArray.push(item.contact_role)
      }
    })
    // Find contact role proccess --- End ---
    if (contact_roleArray && contact_roleArray.includes('4')) {
      autoUpdateAIContext();
    }else{
      handleAddOrEditComment({
        content: commentInput,
        files: fileUplods,
        editCommentId: commentId,
      });
      setShowEmoji(false)
      setCommentId(0);
      setCommentInput("");
      setImagePreviews([]);
      fileUplods = [];
      scrollToBottom();
    }
  }
  function showAIContextModal () {
    if(((["task", "request-task"].includes(docType) && data.rel_type === "project" && data.rel_id > 0) ||( data.rel_type === "equipment" && data.space_project_id > 0)) && userType === "contact"  && Number(contact_role) === 1)
    {
      contextRelType = "task";
      return true;
    }
    else if(docType === "imageview" && userType === "contact" && data.taskId > 0 && (data.projectId > 0 || data.space_project_id > 0) )
    {
      contextRelType = "attachment";
      return true;
    }
    return false;
  }
  useEffect(() => {
    if (!jquery.isEmptyObject(callStatus)) {
      // dispatch(getTaskAttechments(data.id,projectId, '', false));

      if (callStatus.commentsList && callStatus.commentsList.length > 0) {
        setComments(removeDuplicates(callStatus.commentsList))
      }
      
    }
  }, [callStatus])  
  useEffect(() => {
    if (callComments) {
        setComments(removeDuplicates(callComments))  
      // dispatch(getTaskAttechments(data.id, projectId, '', false));

    }
  }, [callComments])  
  return (
    <React.Fragment>
      {
        <div
          className={`${isRelativeView ? "h-100" : ""}`}
          onPaste={(e) => setCopiedFiles(e)}
        >
          <Lightbox
            open={openGallery}
            close={() => setOpenGallery(false)}
            slides={previewSrc}
          />
          {isRelativeView ? (
            <div className="offcanvas offcanvas-end taskDetailModal position-relative w-100 show z-index-5imp onboarding-floor-bg">
              <div className="p-0  offcanvas-body">{commentSectionContain}</div>
            </div>
          ) : (
            <Offcanvas
              className={`taskDetailModal custom-offcanvas-modal custom-modal-style stand-alone-comments ${
                ImageViewerComments ? "image-view-comment" : ""
              }${commanCommentSection ? "" : "comman-comments"} ${
                showFullscreen && !ImageViewerComments ? "full-comment-width400" : ""
              }`}
              id={`${
                docType === "imageview"
                  ? "commonCanvasImageComments"
                  : "comment_close"
              } `}
              placement="end"
              scroll={false}
              backdrop={false}
              show={show}
              keyboard={false}
              onHide={() => {
                handleClose();
                CloseCommentModal();
              }}
              onShow={() => {
                tabindexFix();
              }}
            >
              {
                // showloader ? (
                //   <div>
                //     <CommanLoader className={`dropdown-loader comman-main-loader-wrapper`} />
                //   </div>
                // ) :
                <Offcanvas.Body className={`p-0 `}>
                  {commentSectionContain}
                  <ReactTooltip />
                </Offcanvas.Body>
              }
            </Offcanvas>
          )}
          {showTaskImage && Object.keys(attachmentDetails).length > 0 ? (
            <CommanOffCanvas
              show={showTaskImage}
              handleClose={() => {
                setShowTaskImage(false);
              }}
              data={{
                id: attachmentDetails.id,
                taskId: docType == "imageview" ? data.taskDetails.id : data.id,
                projectId: localStorage.getItem("selectedOffice"),
                attachmentDetails: attachmentDetails,
                taskDetails: data.taskDetails ? data.taskDetails : data,
                allAttachments: allAttachments,
                parent_comment_details:
                  attachmentDetails.parent_comment_details,
              }}
              docType={"imageview"}
              setSelectedId={setSelectedAttachmentId}
              selectedTaskId={data.id}
              commentList={commentList}
              handleParentComponentAction={() => {}}
              CloseCommentModal={CloseCommentModal}
              isChat={true}
              isCommentsection={true}
            />
          ) : (
            <></>
          )}
          {show ? (
            <TaskShareLinkModal
              show={showLink}
              handleClose={handleCloseShareLink}
              handleShow={handleShowShareLink}
              link={`${process.env.REACT_APP_MYR_WEB_URL}/${data.taskDetails ? 'task': docType}/${ data && data.hash ? data.hash : data.taskDetails && data.taskDetails.hash ? data.taskDetails.hash : ""}`}
              data={{...data, contacts: contacts }}
              docType={docType}
              mailForComment={mailForComment}
            />
          ) : (
            <></>
          )}
          {showExternalImage ? (
            <>
              <Lightbox
                open={showExternalImage}
                close={() => setshowExternalImage(false)}
                slides={allAttachments.map((img) => {
                  return { src: getPreviewFromURL(img.image_url) };
                })}
              />
            </>
          ) : (
            <></>
          )}
          {
            viewAIContext ?
              <AIContextModal relType={contextRelType} show={viewAIContext} handleClose={()=> {setViewAIContext(false)}} loadDescription={fetchAIContextForTask} handelSave={saveTaskAIContext} />
            :
              <></>
          }
        </div>
      }
    </React.Fragment>
  );
};

export default CommentSection;
