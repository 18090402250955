import React from "react";
import { _l } from "../../hooks/utilities";
import Solarhublogo from "../../assets/images/blazlogoblack.svg";

const Loader = ({className = ""}) => {
  return (
    <>
      <div className={`solarloader d-flex align-items-center justify-content-center flex-column ${className}`}>
        <div className="logohead">
          <img className="h50px" src={Solarhublogo} />
        </div>
        <div className="w600 d-flex align-items-center justify-content-center flex-column pt-lg-4 pt-3">
          <div className="c-font f-22 fw-semibold title-fonts subloadertitle text-center">
            {_l("l_please_wait_we_are_grouping_your_entries")}
          </div>
          <div className="c-font f-14 pt-10px  sub-header text-center  fw-semibold">
          {_l("l_we_are_connecting_your_inputs_to_feed_ai_on_future_steps")}
          </div>
          <div className="pt-40px d-flex w-100">
            <div class="loading-bar">
              <div class="progress-bar"></div>
            </div>
          </div>
        </div>
      </div> 
    </>
  );
};

export default Loader;
