import React from 'react'
import { Modal } from 'react-bootstrap'
import { _l} from '../../hooks/utilities';
import AddNewProvider from '../Offcanvas/NewSchedule/AddNewProvider';

const InviteCustomer = ({show, handleInviteCustomer, projectId}) => {
    return (
        <Modal
            show={show}
            size="lg"
            scrollable={false}
            onHide={handleInviteCustomer}
            className="custom-modal-style"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{_l("l_invite_customer")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className=" py-0" id="premium-space-scroll">
                <AddNewProvider handleStep={handleInviteCustomer} selectedTab={"new"} setSelectedTab={() => {}} inviteCustomerOnly={true} projectId={projectId} />
            </Modal.Body>
            <Modal.Footer className="border-0 justify-content-between"></Modal.Footer>
        </Modal>
    )
}

export default InviteCustomer