import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { _l, getHoursBetweenDates } from "../../hooks/utilities";
import Form from "react-bootstrap/Form";
import { X } from "phosphor-react";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import customerServices from "../../services/customer-services";
import { useDispatch } from "react-redux";
import { showMessage } from "../../actions/messages";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";

const SetupAvilability = forwardRef(({ show, handleClose, userId, userProfileData, withinModal }, ref) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [hoursLoop, setHoursLoop] = useState({
    mon: 0,
    tue: 0,
    wed: 0,
    thu: 0,
    fri: 0,
    sat: 0,
    sun: 0,
  });
  const [totalHours, setTotalHours] = useState(0);
  const [loader, setLoader] = useState(false);
  const [durationData, setDurationData] = useState([
    {
      fromDate: new Date(),
      toDate: new Date(),
      from: moment(new Date()).format("HH:mm:ss"),
      to: moment(new Date()).format("HH:mm:ss"),
      days: {
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false,
      },
      total_hours: "0",
      hours: 0,
    },
  ]);
  const [disableAvailability, setDisableAvailability] = useState(false);

  const addRemoveDuration = (action = "add", key) => {
    let tempDuration = durationData;
    if (action == "remove") {
      tempDuration = tempDuration.filter((du, index) => index != key);
    } else {
      tempDuration.push({
        fromDate: new Date(),
        toDate: new Date(),
        from: moment(new Date()).format("HH:mm:ss"),
        to: moment(new Date()).format("HH:mm:ss"),
        days: {
          mon: false,
          tue: false,
          wed: false,
          thu: false,
          fri: false,
          sat: false,
          sun: false,
        },
        total_hours: "0",
        hours: 0,
      });
    }
    setDurationData(tempDuration.slice());
  };
  
  const handleChange = (name, value, key) => {
    let Arr = durationData;
    Arr[key] = {
      ...Arr[key],
      [name]: value,
    };
    if (name == "fromDate") {
      Arr[key] = {
        ...Arr[key],
        ["from"]: moment(value).format("HH:mm:ss"),
      };
    }
    if (name == "toDate") {
      Arr[key] = {
        ...Arr[key],
        ["to"]: moment(value).format("HH:mm:ss"),
      };
    }
    let tempArr = Arr.filter((d, index) => index == key)[0];
    let totalhours = getHoursBetweenDates(tempArr.fromDate, tempArr.toDate);
    let totalDays = Object.values(tempArr.days).filter((s) => s == true).length;
    Arr[key] = {
      ...Arr[key],
      ["total_hours"]: totalhours * totalDays,
      ["hours"]: totalhours,
    };
    setDurationData(Arr.slice());
  };

  const handleSave = async() => {
    try {
      setLoader(true);
      const res = await customerServices.addOperatorAvaibility(
        durationData,
        disableAvailability ? 0 : 1,
        userId == null || userId == undefined ? 0 : userId
      );
      if (res.status == 1) {
        handleClose();
        dispatch(showMessage("sucess", _l("l_success"), _l(res.message)));
        if(localStorage.getItem("is_registered") == 1){
          navigate("/myprofile?step=document_upload");
        }
      }
    }
    catch(e)
    {
      console.error(e);
    }
    finally {
      setLoader(false);
    }
  };

  const HeaderContent = () => {
    return (
      <div className="d-flex align-items-center justify-content-between w-100 ">
        <div className="d-flex align-items-center justify-content-between flex-grow-1 list-view">
          <Modal.Title>{withinModal ?  "" : _l("l_setup_avilability")}</Modal.Title>
          <Form className="d-flex align-items-center">
            <Form.Label className="me-2 mb-0 color-white-60">
              {_l("l_available")}
            </Form.Label>
            <div className="">
              <Form.Check
                type="switch"
                checked={!disableAvailability}
                onChange={(e) => setDisableAvailability(!disableAvailability)}
              />
            </div>
          </Form>
        </div>
        {
          withinModal
          ?
          <React.Fragment></React.Fragment>
          :
          <a
            href="#/"
            className=""
            onClick={() => {
              handleClose();
            }}
          >
            <X size={22} className="c-icons" weight="light" />
          </a>
        }
      </div>
    );
  };

  const BodyContent = () => {
    return (
      <div className={`onboarding-wrapper-main w-100 mt-0 p-0 flex-grow-1 d-flex flex-column overflow-auto  ${disableAvailability ? "for-disabled" : ""}`}>
        <div className="d-flex left-content-part py-1 px-0 bg-white-03-solid-imp">
            <div className="bg-transparent left-content-part w-400">
              <div className="d-flex align-items-center h-100">
                <div className="comman-image-box h25w25 rounded-circle">
                  <div
                    className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                    style={{
                      backgroundImage: `url('${
                        userProfileData
                          ? userProfileData.profile_image_url
                          : localStorage.getItem("contact_image")
                      }')`,
                    }}
                  ></div>
                </div>
                <div className="c-font f-14 fw-semibold title-fonts ps-3 w100minus35 text-truncate">
                  {userProfileData
                    ? userProfileData.firstname + " " + userProfileData.lastname
                    : localStorage.getItem("full_name")}
                </div>
              </div>
            </div>
            <div className="right-content-part width-calc-400">
              <table className="dataTable  title-fonts text-center w-100 h-100 ps-10px">
                <thead className="table-th-px-26px">
                  <tr className="bg-transparent">
                    {Object.keys(hoursLoop).map((item) => {
                      return (
                        <th className="p-2 border-0" style={{ width: "30px" }}>
                          <div className="c-font f-13 fw-semibold title-fonts">
                            {hoursLoop[item]}H
                          </div>
                          <div className="body-fonts c-font f-12 fw-normal color-white-80 pt-1">
                            {_l(`l_${item}`)}
                          </div>
                        </th>
                      );
                    })}
                    <th className="p-2 d-flex border-0" style={{ width: "100px" }} colSpan={2}>
                      <div className="px-1 text-center ">
                        <div className="c-font f-13 fw-semibold title-fonts">
                          {totalHours}H
                        </div>
                        <div className="body-fonts c-font f-12 fw-normal color-white-80 pt-1">
                          {_l("l_total_hours")}
                        </div>
                      </div>
                    </th>
                    <th className="p-2 border-0" colSpan={2}></th>
                  </tr>
                </thead>
              </table>
            </div>
        </div>
        <div className="d-flex mt-3 overflow-auto flex-grow-1 ">
          <div className="left-content-part bg-transparent w-400 left-sticky radius-bottom-0 p-0 comman-data-table bg-white-03-solid-imp">
            <table className="dataTable">
              <thead className="bg-white-03-solid-imp table-th-px-26px left-content-part p-0 radius-bottom-0 h-100">
                <tr className="bg-transparent">
                  <th className="border-0 bg-transparent d-flex border-bottom">
                    <div className="fw-bold text-truncate c-font f-13">
                      {_l("l_set_hours")}
                    </div>
                  </th>
                </tr>
              </thead>
              <div className=" radius-bottom-0 radius-top-0 bg-white-03-solid-imp">
                {durationData.length > 0 &&
                  durationData.map((item, key) => {
                    return (
                      <Row
                        className="custom-datepicker with-fixed-pos align-items-center d-flex g-0 p-15 py-0 border-bottom"
                        style={{ height: "50px" }}
                      >
                        <Col className="flex-grow-1" xs={5}>
                          <div className="border p-1 w-100 radius_5 custom-timepicker">
                            <DatePicker
                              selected={item.fromDate}
                              onChange={(date) =>
                                handleChange("fromDate", date, key)
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={60}
                              dateFormat="h:mm aa"
                              calendarStartDay={1}
                            />
                          </div>
                        </Col>
                        <Col xs={1} className="px-2">
                          <div className="border w-100"></div>
                        </Col>
                        <Col className="flex-grow-1" xs={5}>
                          <div className="border p-1 w-100 radius_5 custom-timepicker">
                            <DatePicker
                              selected={item.toDate}
                              onChange={(date) =>
                                handleChange("toDate", date, key)
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={60}
                              dateFormat="h:mm aa"
                              calendarStartDay={1}
                            />
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
              </div>
            </table>
          </div>
          <div className="right-content-part width-calc-400 comman-data-table">
            <table className="dataTable  title-fonts text-center w-100 ps-10px">
              <thead className="bg-white-03-solid-imp table-th-px-26px">
                <tr className="bg-transparent">
                  {Object.keys(hoursLoop).map((item) => {
                    return (
                      <th className="border-top-0 border-start-0 border-end-0 border-bottom px-2" style={{ width: "30px" }}>
                        <div className="body-fonts c-font f-13 fw-normal">
                          {_l(`l_${item}`)}
                        </div>
                      </th>
                    );
                  })}
                  <th className="border-top-0 border-start-0 border-end-0 border-bottom px-2 text-start" style={{ width: "100px" }}>
                    <div className="body-fonts c-font f-13 fw-normal">
                      {_l("l_potential_hours")}
                    </div>
                  </th>
                  <th className="border-top-0 border-start-0 border-end-0 border-bottom px-2 d-flex">
                    <div className="ms-auto text-center">
                      <div className="body-fonts c-font f-13 fw-normal">
                        {_l("l_action")}
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {durationData.length > 0 &&
                  durationData.map((item, key) => {
                    return (
                      <tr
                        className="bg-transparent align-middle"
                        style={{ height: "50px" }}
                      >
                        <td
                          className="p-2 border-bottom"
                          style={{ width: "30px" }}
                        >
                          <Form.Check
                            type="checkbox"
                            checked={item.days.mon}
                            onChange={(e) =>
                              handleChange(
                                "days",
                                { ...item.days, mon: e.target.checked },
                                key
                              )
                            }
                          />
                        </td>
                        <td
                          className="p-2 border-bottom"
                          style={{ width: "30px" }}
                        >
                          <Form.Check
                            type="checkbox"
                            checked={item.days.tue}
                            onChange={(e) =>
                              handleChange(
                                "days",
                                { ...item.days, tue: e.target.checked },
                                key
                              )
                            }
                          />
                        </td>
                        <td
                          className="p-2 border-bottom"
                          style={{ width: "30px" }}
                        >
                          <Form.Check
                            type="checkbox"
                            checked={item.days.wed}
                            onChange={(e) =>
                              handleChange(
                                "days",
                                { ...item.days, wed: e.target.checked },
                                key
                              )
                            }
                          />
                        </td>
                        <td
                          className="p-2 border-bottom"
                          style={{ width: "30px" }}
                        >
                          <Form.Check
                            type="checkbox"
                            checked={item.days.thu}
                            onChange={(e) =>
                              handleChange(
                                "days",
                                { ...item.days, thu: e.target.checked },
                                key
                              )
                            }
                          />
                        </td>
                        <td
                          className="p-2 border-bottom"
                          style={{ width: "30px" }}
                        >
                          <Form.Check
                            type="checkbox"
                            checked={item.days.fri}
                            onChange={(e) =>
                              handleChange(
                                "days",
                                { ...item.days, fri: e.target.checked },
                                key
                              )
                            }
                          />
                        </td>
                        <td
                          className="p-2 border-bottom"
                          style={{ width: "30px" }}
                        >
                          <Form.Check
                            type="checkbox"
                            checked={item.days.sat}
                            onChange={(e) =>
                              handleChange(
                                "days",
                                { ...item.days, sat: e.target.checked },
                                key
                              )
                            }
                          />
                        </td>
                        <td
                          className="p-2 border-bottom"
                          style={{ width: "30px" }}
                        >
                          <Form.Check
                            type="checkbox"
                            checked={item.days.sun}
                            onChange={(e) =>
                              handleChange(
                                "days",
                                { ...item.days, sun: e.target.checked },
                                key
                              )
                            }
                          />
                        </td>
                        <td className="p-2 border-bottom">
                          <div className="c-font f-14 title-fonts">
                            <span className="bg-white-03-solid-imp p-10 py-2 radius_3">
                            {item.total_hours}H
                            </span>
                          </div>
                        </td>
                        <td className="p-2 border-bottom">
                          <a
                            href="#/"
                            className="d-flex justify-content-end"
                            onClick={() => {
                              if (durationData.length > 1) {
                                addRemoveDuration("remove", key);
                              }
                            }}
                          >
                            <div className="h32w32 me-3">
                            <X size={18} weight="light" className="c-icons m-0" />
                            </div>
                          </a>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  };

  const FooterContent = () => {
    return (
      <div className="d-flex w-100 m-0">
        <div
          className={`left-content-part w-400 z-index-2imp left-sticky radius-top-0 pt-2 bg-white-03-solid-imp ${
            disableAvailability ? "for-disabled" : ""
          }`}
        >
          <Button
            className="flex-grow-1 w-100 border-0 p-10"
            size="sm"
            variant="white-05"
            onClick={() => {
              addRemoveDuration("add");
            }}
          >
            {_l("l_add_new_duration")}
          </Button>
        </div>
        {
          withinModal
          ?
          <React.Fragment></React.Fragment>
          :
          <div className="d-flex justify-content-end p-15 right-content-part width-calc-250 py-2 pe-0">
            <div className="d-flex align-items-center my-auto">
              <Button
                variant="primary"
                size="sm"
                className="px-4"
                onClick={() => handleSave()}
              >
                {loader ? _l("l_please_wait") : _l("l_save")}
                {loader ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    className={`ms-2 `}
                    aria-hidden="true"
                  />
                ) : (
                  <></>
                )}
              </Button>
            </div>
          </div>
        }
      </div>
    )
  };

  useImperativeHandle(ref, () => ({
    handleSave: handleSave
  }));

  useEffect(() => {
    if (durationData) {
      let total = 0;
      let weekCount = {
        mon: 0,
        tue: 0,
        wed: 0,
        thu: 0,
        fri: 0,
        sat: 0,
        sun: 0,
      };
      durationData.map((ar) => {
        total = parseInt(total) + parseInt(ar.total_hours);
        Object.keys(ar.days).map((weekDay) => {
          if (ar.days[weekDay] == true) {
            weekCount[weekDay] = weekCount[weekDay] + ar.hours;
          }
        });
      });
      setHoursLoop(weekCount);
      setTotalHours(total);
    }
  }, [durationData]);

  useEffect(() => {
    customerServices
      .getOperatorAvaibility(userId == null || userId == undefined ? 0 : userId)
      .then((res) => {
        if (res.status == 1 && res.data) {
          setDisableAvailability(res.data.is_active == 0);
          if (
            res.data.operator_avaibility &&
            res.data.operator_avaibility.length > 0
          ) {
            setDurationData(
              res.data.operator_avaibility.map((week) => {
                return {
                  ...week,
                  hours:
                    week.total_hours /
                    Object.values(week.days).filter((s) => s == true).length,
                  fromDate: week.from ? new Date(`01-01-2000 ${week.from}`) : "",
                  toDate: week.to ? new Date(`01-01-2000 ${week.to}`) : "",
                };
              })
            );
          }
        }
      });
  }, [userId]);

  return (
    <>
    {
      withinModal
      ?
        <React.Fragment>
          <div className="custom-modal-style">   
            <HeaderContent />
            <BodyContent />
            <FooterContent />
          </div>
        </React.Fragment>
      :
        <Modal
          show={show}
          onHide={handleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
          scrollable={true}
        >
          <Modal.Header>
            <HeaderContent />
          </Modal.Header>
          <Modal.Body
            className={`d-flex flex-column overflow-hidden pb-0 ${
              disableAvailability ? "for-disabled" : ""
            }`}
          >
            <BodyContent />
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0 p-15">
            <FooterContent />
          </Modal.Footer>
        </Modal>      
    }
    </>
  );
});

export default SetupAvilability;
