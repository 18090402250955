import outlookCalendar from "../services/outlook-calendar";
import googleCalendar from "../services/google-calendar";
import { OUTLOOK_CALENDAR_CONNECTIVITY, OUTLOOK_CALENDAR_TOPICS, GET_REFRESH_REQUEST_DATA, SET_CONTACT_DATA, GET_CONTACT_DATA , IS_ASSIGNED_USER} from "./action-type";
export const is_calendar_connected = (project_id) => (dispatch) => {
    outlookCalendar
        .is_calendar_connected(project_id)
        .then((data) => {
            dispatch({
                type: OUTLOOK_CALENDAR_CONNECTIVITY,
                payload: data
            });
        });
}

export const outlook_calendar_topics = (data) => (dispatch) => {
    dispatch({
        type: OUTLOOK_CALENDAR_TOPICS,
        payload: data
    });
}

export const get_refresh_request_data =
(startDate, endDate, calendar_id, project_id, calendar_type, request_type) =>
  (dispatch) => {
    if (calendar_type === 'outlook_calendar') {

      return outlookCalendar.get_events(startDate, endDate, calendar_id, project_id, request_type).then((data) => {
        dispatch({
          type: GET_REFRESH_REQUEST_DATA,
          payload: { data },
        });
      });
    } else if (calendar_type === 'google_calendar') {

      return googleCalendar.refresh_request(startDate, endDate, calendar_id, project_id, request_type).then((data) => {
        dispatch({
          type: GET_REFRESH_REQUEST_DATA,
          payload: { data },
        });
      });

    }
  };

  export const set_contact_data =
  (taskId, name, email, project_id, calendar_id, calendar_type) =>
    (dispatch) => {
      if (calendar_type === 'outlook_calendar') {

        return outlookCalendar.setAssignee(taskId, name, email, calendar_id, project_id).then((data) => {
          dispatch({
            type: SET_CONTACT_DATA,
            payload: { data },
          });
        });
      } 
      // else if (calendar_type === 'google_calendar') {

      //   return googleCalendar.setAssignee(taskId, name, email, calendar_id, project_id).then((data) => {
      //     dispatch({
      //       type: SET_CONTACT_DATA,
      //       payload: { data },
      //     });
      //   });

      // }
    };

    export const get_contact_data =
    (taskId, project_id, calendar_id, calendar_type) =>
      (dispatch) => {
        if (calendar_type === 'outlook_calendar') {
  
          return outlookCalendar.getAssigneeCal(taskId, calendar_id, project_id).then((data) => {
            dispatch({
              type: GET_CONTACT_DATA,
              payload: { data },
            });
          });
        }
        //  else if (calendar_type === 'google_calendar') {
  
        //   return googleCalendar.setAssignee(taskId, name, email, calendar_id, project_id).then((data) => {
        //     dispatch({
        //       type: SET_CONTACT_DATA,
        //       payload: { data },
        //     });
        //   });
  
        // }
      };
      export const checkIsAssigned =
    (taskId) =>
      (dispatch) => {
          return outlookCalendar.checkIsAssigned(taskId).then((data) => {
            dispatch({
              type: IS_ASSIGNED_USER,
              payload: { data },
            });
          });
        };