import React, { useEffect, useState } from "react";
import { _l } from "../../hooks/utilities";

const EmailDetailViewListing = ({ }) => {
  const [UserList, setUserList] = useState(["1", "2", "3"]);
  return (
    <ul
      className="dropdown-menu w-250 overflowscroll"
      aria-labelledby="EmailDetailViewListing"
    >
      {UserList.length > 0 && UserList.map((item) => {
        return (
          <li className="comman-list c-font f-12 text-truncate">
            <div className="title-fonts text-truncate">Kathryn Murphy</div>
            <div className="color-white-60 pt-1 ">
              <span className="with_separator_10 ps-0">30 min read</span>
              <span className="with_separator_10 ">1 Times open</span>
            </div>
          </li>
        )
      })
      }

    </ul>
  );
};

export default EmailDetailViewListing;
