import React, { useEffect } from "react";
import { _l } from "../hooks/utilities";
import { CheckCircle } from "phosphor-react";

const SolarAcknowledgeScreen = ({steps, delay, afterDismissAction, title, description, isSolar}) => {

  useEffect(() => {
    setTimeout(() => {
      afterDismissAction();
    }, delay);
  }, []);

  return (
    <React.Fragment>
      <div className="d-flex h-100 w-100">
        <div className="mx-auto col-12 col-md-10 col-lg-6 col-xl-4">
          <div className="p-20">
            {/* <div className="c-font f-18 fw-semibold text-center title-fonts py-2">
              {_l(title)}
            </div>
            <div className="c-font color-white-80 text-center f-13">
            {_l(description)}
            </div> */}
            <ul className="comman-list position-relative pt-0">
              {steps.titles.map((step, key) => {
                return (
                  <li key={key} className="steps-green-line">
                    <a href="#/" className="check-image absolute-content">
                      {key === steps.titles.length - 1 ? (
                        <>
                        <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm h20w20"
                        ></span>
                        <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border-static h20w20"
                        ></span>
                        </>
                      ) : (
                        <CheckCircle
                          size={26}
                          weight="fill"
                          className="c-icons color-green"
                        />
                      )}
                    </a>
                    <div className="c-font f-16 fw-semibold title-fonts">
                      {_l(step)}
                    </div>
                    <div className={`c-font f-14 title-fonts ${isSolar ? "color-white-60" : "fw-semibold "}`}>
                      {_l(steps.descriptions[key])}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SolarAcknowledgeScreen;
