import React from "react";
import { _l } from "../../hooks/utilities";
import Button from "react-bootstrap/Button";
import { ArrowLeft, CaretLeft, CaretRight } from "phosphor-react";
import { Spinner } from "react-bootstrap";
const OnboardingHeader = ({
  title,
  description,
  status,
  statusClass,
  titlePrefix = "",
  nextButtonText,
  backButtonText,
  backBtnHandler,
  nextBtnHandler,
  skipButtonHandler,
  hideBackButton,
  skipButtonText,
  loadingNextStep,
  loader,
  hideNextButton = false,
  alignHeaderContentLeft
}) => {
  return (
    <>
     {title === "l_edit_profile" ?
        <div className="bgpatternCommanImg comman_top_header  position-sticky top-0 z-index-4 p-0"> 
          <div className="d-flex justify-content-between align-items-center">
            <div className="comman-tab-wrapper pb-0 d-flex justify-content-between">
              <div className="tab-wrapper d-inline-flex align-items-center">
                <div className="tab-name title-fonts fw-semibold m-0 color-white-80 me-1">
                  {`${_l(title)} ${titlePrefix}`}
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center pe-10px">
            <Button
              disabled={loadingNextStep || loader}
              variant={"primary btn-sm"}
              className={`text-nowrap ${!backButtonText ? "mx-auto text-nowrap" : ""}`}
              onClick={nextBtnHandler}
              id="footer-primary-button"
            >
              {loadingNextStep || loader ? (
                <span>
                  {_l("l_please_wait")} 
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    className={`ms-2 `}
                    aria-hidden="true"
                  /> 
                </span>
              ) : (
                <span>
                  {_l(nextButtonText)}
                </span>
              )}
            </Button>
            </div>
          </div>
        </div> 
      :
      <div className={`onboarding-header text-center p-20 position-relative ${title === "l_edit_profile" ? "px-0 pt-20pximp" : ""}`}>
        <div className="d-flex align-items-center justify-content-between">
          <div className={alignHeaderContentLeft ? "d-none " : ""}>
            <div
              className={` d-flex ${!backButtonText ? "d-none" : ""}`}
              // style={{ top: "40%" }}
            >
              {backButtonText && !hideBackButton ? (
                <a href="#/"
                  className="border-0 btn c-font color-white f-15 title-fonts btn-sm text-nowrap ps-0"
                  onClick={backBtnHandler}
                >
                  <ArrowLeft size={18} weight="light" className="c-icons" />
                  <span className="ms-2">{_l(backButtonText)}</span>
                </a>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </div>
          </div>
          {/* <Button variant="secondary" size="" className="position-absolute start-0 d-flex h40w40 p-0" style={{top:"40%"}}><CaretLeft size={16} className="c-icons" /></Button> */}
          {/* <Button variant="secondary" size="" className="position-absolute end-0 mb-3 d-flex h40w40 p-0" style={{top:"40%"}}><CaretRight size={16} className="c-icons" /></Button> */}
          <div className={` ${alignHeaderContentLeft ? "p-0" : "px-3" } `}>
            <div className={`c-font fw-semibold title-fonts ${alignHeaderContentLeft ? "f-14 pt-10px" : "py-2 f-20"}`}>
              {`${_l(title)} ${titlePrefix}`}
              <span className={`badge rounded-pill ms-2 ${statusClass}`}>
                {status}
              </span>
            </div>
            <div className={`c-font f-16 sub-header py-1 text-center ${alignHeaderContentLeft ? "d-none" : ""} `}>
              <span>{_l(description)}</span>
            </div>
          </div>
          <div>
            <div
              className={`d-flex ${!nextButtonText ? "d-none" : ""}`}
              // style={{ top: "40%" }}
            >
              {hideNextButton ? (
                <></>
              ) : (
                <div className="d-flex right-side-button ms-auto">
                  <Button
                    disabled={loadingNextStep || loader}
                    variant={"primary btn-sm"}
                    className={`text-nowrap ${!backButtonText ? "mx-auto text-nowrap" : ""}`}
                    onClick={nextBtnHandler}
                    id="footer-primary-button"
                  >
                    {loadingNextStep || loader ? (
                      <span>{_l("l_please_wait")}</span>
                    ) : (
                      <span>
                        {_l(nextButtonText)}
                      </span>
                    )}
                  </Button>
                </div>
              )}
                 

            </div>
            {skipButtonText ? (
              <Button variant="secondary btn-sm"
                className={` color-white-60  title-fonts mt-2 text-nowrap`}
                onClick={skipButtonHandler}
              >
                <span className="">{_l(skipButtonText)}</span>
              </Button>
              ) : (
              <React.Fragment></React.Fragment>
            )}
          </div>
        </div>
      </div>
    }
    </>
  );
};

export default OnboardingHeader;
