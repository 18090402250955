import { ArrowRight, CalendarBlank, CaretDoubleDown, ChatCircle, DotsNine, PhoneCall, Trash } from 'phosphor-react';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
  } from "reactstrap";
import { _l, addExpandedClasstoIntroOfficeList, hideLeftPanelNew, removeExpandedClassFromIntroOfficeList, showError, showSuccess } from '../../../hooks/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultTopics, getHomeDashboardTasks, getTaskList, projectTaskCounts } from '../../../actions/customer';
import jquery from "jquery";
import outlookCalendar from '../../../services/outlook-calendar';
import customerServices from '../../../services/customer-services';
import TaskCard from '../../TaskCard';
import CommanPlaceholder from '../../Placeholder/CommanPlaceholder';
import TaskListSkeleton from '../../Skeleton/TaskListSkeleton';
import HighPriorityActiveIcon from '../../../assets/icons/HighPriorityActiveIcon';
import HighPriorityIcon from '../../../assets/icons/HighPriorityIcon';
import ActionDropdown from '../../Dropdowns/ActionDropdown';
import { Button } from 'react-bootstrap';
import CommanOffCanvas from '../../Offcanvas/CommanOffCanvas';

const HomePages = () => {
  const {
    taskList,
    selectedTask,
    commanoffcanvasIsOpen,
    homepageTaskList
  } = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const [settingOptions, setSettingOptions] = useState({ label: "l_setup_my_account", icon: "PhoneIcon" })
  const profileUrl = localStorage.getItem("contact_image") ? localStorage.getItem("contact_image") : ""
  const spaces = localStorage.getItem("spaces") ? JSON.parse(localStorage.getItem("spaces")) : [];
  let contact_role = localStorage.getItem("contact_role")
  const spaceState = useSelector((state) => state.customer.spaces);
  const messagesStates = useSelector((state) => state.messages);



  const [selectedTaskId, setSelectedTaskId] = useState(0);
  const [taskPinned, setTaskPinned] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [taskId, setTaskId] = useState()
  const [callUserList, setCallUserList] = useState([]);
  const [selectedTaskIdforIconToggle, setSelectedTaskIdforIconToggle] = useState(0);
  const [showDefaultIcons, setShowDefaultIcons] = useState([]);
  const [meetingUrl, setMeetingUrl] = useState("");
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [commonModalData, setCommonModalData] = useState();
  const [showReqestedTaskModal, setShowReqestedTaskModal] = useState(false);
  const [task_type, setTask_type] = useState('');
  const [taskPage, setTaskPage] = useState(1);
  const [topics, setTopics] = useState([]);
  const [isDataAvailable, setIsDataAvailable] = useState(true);
  const [isSkeltonShow, setIsSkeltonShow] = useState(true);
  const [homepageTaskInput, setHomepageTaskInput] = useState("");
  const [checkedTask, setCheckedTask] = useState([]);

  const taskCheckListInputHeight = useRef();

  const [loder, setLoder] = useState(false);

  const handleClose = () => setShowReqestedTaskModal(false);
  const handleShow = () => setShowReqestedTaskModal(true);

  

  const handleSelectedTask = (
    taskId = 0,
    show = "",
    isRequestedTask = null,
    convertRequestTaskId = 0
  ) => {
    getMeetingLink(taskId);
    setShowTaskModal(false);
    setSelectedTaskId(taskId);
    localStorage.setItem("SelectedTask", taskId);
    localStorage.setItem(
      "TaskType",
      isRequestedTask && isRequestedTask != null ? isRequestedTask : 0
    );
    if (isRequestedTask || taskId) {

      setShowTaskModal(show);
      hideLeftPanelNew();
      removeExpandedClassFromIntroOfficeList();
    }
  };

  const getMeetingLink = (TaskId, calendar_id = "") => {

    outlookCalendar.getMeetingLink(TaskId, 0, calendar_id).then((res) => {
      if (res.status == 1) {
        setMeetingUrl(res.data.meetingUrl);
      } else {
        setMeetingUrl("");
      }
    });
  };

  const taskCheckUncheck = (task_id) => {
    const taskPosition = checkedTask.findIndex(x => x == task_id)
    if (taskPosition === -1) {
      // checkedTask.push(task_id);
      setCheckedTask([...checkedTask, task_id])
    }
    else {
      setCheckedTask(checkedTask.filter((taskId) => taskId != task_id))
    }
  }

  const handleToggleIcons = (isTaskPinned, taskAssignee, taskAssigneePills, taskPriority, comments_count, rating, setTaskCardIconToggle, taskCardIconToggle) => {
    if (taskCardIconToggle) {
      let tempDefaultIcons = []

      if (isTaskPinned) {
        tempDefaultIcons.push("pin")
      }
      if (taskAssignee.length > 0) {
        tempDefaultIcons.push("peopels")
      }
      if (taskAssigneePills.length > 0) {
        tempDefaultIcons.push("pills")
      }
      if (taskPriority == 3) {
        tempDefaultIcons.push("priority")
      }
      if (comments_count > 0) {
        tempDefaultIcons.push("comment")
      }
      if (rating && rating > 0) {
        tempDefaultIcons.push("star")
      }

      setShowDefaultIcons(tempDefaultIcons)
      setTaskCardIconToggle(false)
    } else {
      let tempDefaultIcons = ["calendar", "assigneTo", "pin", "peopels", "pills", "priority", "cube", "map", "status", "comment", "star", , "delete", "share", "call", "template"]
      setShowDefaultIcons(tempDefaultIcons)
      setTaskCardIconToggle(true)
    }

  }

  const updateAttchmentChecklistCount = (taskid, imageCount = 0) => {
    
  };

  const taskListLocalData = useMemo(() => taskList ? taskList.slice() : [], 
  
  [taskList]);

  useEffect(() => {
    if (selectedTask) {

      setTask_type(selectedTask.is_requested && selectedTask.is_requested == 1 ? 'request-task' : 'task')
      setCommonModalData(selectedTask)
      setSelectedTaskId(selectedTask.task_id || selectedTask.id)
      
      if (selectedTask.is_requested == 1) {
        handleShow();
      }
      if (selectedTask.task_id || selectedTask.id) {
        setShowTaskModal(true);
      }
    } else {
      setShowTaskModal(false)
      setSelectedTaskId(0)
    }
    jquery(document).ready(function () {
      jquery("input").change(function () {
       
      });
    });

    return () => {
      jquery(document).unbind("ready");
    };
  }, [selectedTask]);

  useEffect(() => {
    dispatch(getDefaultTopics(0, "", "", "homepage"));
  }, [JSON.stringify(spaces), JSON.stringify(spaceState)])

  useEffect(() => {
    let taskId = localStorage.getItem("externalTaskHash");
    let externalTaskDetails = localStorage.getItem("externalTaskData") ? JSON.parse(localStorage.getItem("externalTaskData")) : {}
    if (taskId && externalTaskDetails && messagesStates.idbDataLoader == false) {
      setShowTaskModal(true); 
      setCommonModalData(externalTaskDetails)
    }
  }, [messagesStates])
  
  const CreateHomepageTasks = () => {    
    if(homepageTaskInput != ""){
      setLoder(true);
      customerServices.CreateHomepageTasksFunction(
        homepageTaskInput,
      ).then((res) => {
        setLoder(false);
        if(res && res.status) {
          try {
            customerServices.getDashboardTasks().then((res) => {
              if (res.status) {
                showSuccess(_l("l_task_created"))
                if (res && res.status && res.data) {
                }
              } else {
                showError("l_something_went_wrong");
              }
            })
          } catch (error) {
            console.log(error);
            showError("l_something_went_wrong");
          }
        } else {
          showError("l_something_went_wrong");
        }
      })
      .catch(() => {
        showError("l_something_went_wrong");
      })
      .finally(() => {
        setHomepageTaskInput("");

        if (taskCheckListInputHeight.current) {
          taskCheckListInputHeight.current.style.height = "auto";
        }
      })
    }
  }
  
  useEffect(() => {
    try {
      dispatch(getHomeDashboardTasks()).then((res) => {
        setIsSkeltonShow(false);
      })
    } catch (error) {
      console.error(error);
    }
  }, [])
 
  return (
    <>
         <div className="schedule-content-wrapper h-100 d-flex flex-column px-0 pb-0 radius_3 pt-10px  d-flex flex-column">
        <div className="comman-main-box custom-accordian-main res-padding-right0 overflow-auto flex-grow-1 h-100 task-list-accordian   ">
          <div className={`d-flex flex-column flex-grow-1 backdroplighttheme w-800px canimation  ${commanoffcanvasIsOpen ? '' : 'daily-schedule-main'}`}>
            <div className="position-sticky top-0 start-0 z-index-8 bg-solar">

            <div className='d-flex justify-content-between '>
                <div className='c-font f-24 fw-semibold'>Hello, {localStorage.getItem("full_name")}</div>
                <div className='d-flex align-items-center gap-2 comman_action_icon'>
                  {contact_role != 3 ? <div
                    className="comman_action_icon card-view-less-padding p-0  dropdown"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                    data-popper-placement="left-start"
                  >
                    <a
                      href="#/"
                      className={` action_icon h32wauto btn btn-primary  gap10 text-nowrap`}
                    >
                      {/* {
                        settingOptions.label == "l_setup_my_account"
                          ? <></>
                          : <IconComponent size={14} className="c-icons" weight="light" />
                      } */}
                      <di1v className='text-uppercase text-on-hover-active-green'>{_l(settingOptions.label)}</di1v>
                    </a>
                    <ActionDropdown textUppercase="text-uppercase" widthClass="w-auto"
                      actions={[
                        {
                          actionName: _l("l_setup_my_phone"),
                          actionHandler: () => { 
                            setSettingOptions({ label: "l_setup_my_phone", icon: "PhoneIcon" })
                           
                           },
                          actionIcon: "PhoneIcon",
                        },
                        {
                          actionName: _l("l_setup_my_email"),
                          actionHandler: () => { 
                            setSettingOptions({ label: "l_setup_my_email", icon: "EnvelopeSimpleIcon" })
                           
                           },
                          actionIcon: "EnvelopeSimpleIcon",
                        },
                        {
                          actionName: _l("l_setup_my_calendar"),
                          actionHandler: () => { 
                            setSettingOptions({ label: "l_setup_my_calendar", icon: "CalendarBlankIcon" })
                           
                           },
                          actionIcon: "CalendarBlankIcon",
                        },
                        {
                          actionName: _l("l_setup_my_drive"),
                          actionHandler: () => { 
                            setSettingOptions({ label: "l_setup_my_drive", icon: "FIleXicon" })
                          
                           },
                          actionIcon: "FIleXicon",
                        },
                      ]}
                    />
                  </div> : <></>}

                </div>
              </div>

              <div className="border radius10 bg-white-03 comman-white-box-shadow shadow-none my-4">
                <div className="align-items-center btnbluebg chat-comment-box comment-input-box d-flex flex-grow-1 p-10  position-sticky top-0 start-0 z-2 lightthemebgtranspaent light-theme-border">
                  <div className="h32w32 comman-image-box with-bg rounded-circle">
                    <div
                      className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                      style={{
                        backgroundImage: `url(${localStorage.getItem('contact_image')})`,
                      }}
                    ></div>
                  </div>
                  <input
                    className="border-0 bg-transparent p-0 pe-2 ps-3"
                    name=""
                    id=""
                  />
                  <div className="d-flex align-items-center comman_action_icon">
                    <a href="" className="action_icon with_bg  h32w32">
                      <ArrowRight
                        size={18}
                        weight="light"
                        className="c-icons"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-list-box dashboardlist">
              <div className="mb-15px">
                <div className="c-font color-white-60 f-12  pb-1 position-sticky top160px z-1 bg-solar">
                  Today
                </div>




                <div id="list_view" className={`schedule-content-wrapper h-100 d-flex flex-column px-0 pb-0 radius_3 pt-10px  d-flex flex-column ${showTaskModal || showReqestedTaskModal ? "list-view-toggle w-auto" : ""}`}>
                      {isSkeltonShow  ? 
                      <>
                      {["0","1","2"].map((s)=>{return  <TaskListSkeleton />})}
                      </> :
                      taskListLocalData && taskListLocalData.length > 0 ?
                        taskListLocalData.sort((a, b) => {
                          if (a.id > b.id){
                            return -1;
                          }
                        }).slice(0, taskPage * 10).map((task, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div
                                key={index}
                                className=""

                              >
                                <TaskCard
                                  key={task.id}
                                  task={task}
                                  active={selectedTaskId == task.id}
                                  project_id={task.space_project_id}
                                  handler={handleSelectedTask}
                                  pageName='LiteVersion'
                                  taskPinned={taskPinned}
                                  setTaskPinned={setTaskPinned}
                                  contacts={contacts}
                                  setTaskId={setTaskId}
                                  setCallUserList={setCallUserList}
                                  setSelectedTaskIdforIconToggle={setSelectedTaskIdforIconToggle}
                                  selectedTaskIdforIconToggle={selectedTaskIdforIconToggle}
                                  showDefaultIcons={showDefaultIcons}
                                  setShowDefaultIcons={setShowDefaultIcons}
                                  handleToggleIcons={handleToggleIcons}
                                  isFrom="homePage"
                                  taskCheckUncheck={taskCheckUncheck}
                                  checkedTask={checkedTask}
                                />
                              </div>
                            </React.Fragment>
                          )
                        }) :
                        <CommanPlaceholder placeholderText={_l("l_no_task_found")} />
                      }
                      {isSkeltonShow || ( isDataAvailable && taskListLocalData.length == 0) ? 
                        <>
                        </> 
                        :
                        (taskListLocalData.length >= (taskPage - 1) * 10 + 10 && 
                        taskListLocalData.length != (taskPage - 1) * 10 + 10) ? (
                        <a href="#/"
                          className="btn btn-white-03 w-100 view-more-btn"
                          onClick={() => {
                            setTaskPage(taskPage + 1)
                          }}
                        >
                          <div className="d-flex align-item-center justify-content-center">
                            <span className=" title-fonts c-font f-12 ms-2">
                              {_l("l_view_more_btn")} (
                              {taskPage > 1
                                ? taskListLocalData.length - ((taskPage - 1) * 10 + 10)
                                : taskListLocalData.length - 10}
                              )
                            </span>
                            <CaretDoubleDown  className="c-icons mx-2" size={12} weight='light' />
                          
                          </div>
                        </a>
                      ) : (
                        <></>
                      )}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {showTaskModal ? <CommanOffCanvas
        show={showTaskModal}
        handleClose={() => {
          setShowTaskModal(false);
          handleClose();
          addExpandedClasstoIntroOfficeList();
          setCommonModalData();
          setSelectedTaskId(0);
          setTask_type('')
        }}
        pinHandler={(id, type, flag) => {
        }}
        data={commonModalData}
        docType={task_type}
        setSelectedId={setSelectedTaskId}
        selectedTaskId={selectedTaskId}
        IsKanbanView={false}
        meetingUrl={meetingUrl}
        handleParentComponentAction={(type, taskData) => {
          if (task_type == 'task') {
            if (type == 'attachmentCountUpdate') {
              updateAttchmentChecklistCount(taskData.task_id, taskData.flag)
              return;
            } else if (type == 'handleSelectedTask') {
              handleSelectedTask(taskData.task_id, taskData.flag)
              return;
            } else if (type == 'convertedTaskAction') {
              handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
              return;
            }
          } else if (task_type == 'request-task') {

            if (type == 'convertedTaskAction') {
              handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
              return;
            }
          }

        }}
        pageName={"Liteversion"}
        contacts={contacts}
        callUserList={callUserList}
        isFullScreen={localStorage.getItem("externalTaskHash") ? true : false}
      /> : <></>
      }

    </>
  )
}

export default HomePages