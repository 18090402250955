import { Export } from "phosphor-react";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const FETCH_SPACES = "FETCH_SPACES";
export const FETCH_CALENDAR_TASKS = "FETCH_CALENDAR_TASKS";
export const FETCH_TASK_LIST = "FETCH_TASK_LIST";
export const UPDATE_TASK_STATUS = "UPDATE_TASK_STATUS";
export const CHANGE_PRIORITY = "CHANGE_PRIORITY";
export const GET_TOPICS_TASK = "GET_TOPICS_TASK";
export const GET_DEFAULT_TOPICS = "GET_DEFAULT_TOPICS";
export const UPDATE_TASK_PROJECT = "UPDATE_TASK_PROJECT";
export const SET_TASK_DATA = "SET_TASK_DATA";
export const GET_TASK_DEFAULT_ASSIGNEE = "GET_TASK_DEFAULT_ASSIGNEE";
export const GET_SELECTED_TASK = "GET_SELECTED_TASK";
export const SET_SELECTED_PROJECT = "SET_SELECTED_PROJECT";
export const UPDATE_TASK_ASSIGNEE = "UPDATE_TASK_ASSIGNEE";
export const TASK_LIST_PAGINATION = "TASK_LIST_PAGINATION";
export const CLIENT_TEAM_DATA = "CLIENT_TEAM_DATA";
export const LEFT_PANEL_TASK_FILTERS = "LEFT_PANEL_TASK_FILTERS";
export const SET_TASK_COUNTS = "SET_TASK_COUNTS";
export const TASK_SEARCH_STRING = "TASK_SEARCH_STRING";
export const SET_SELECTED_TASK = "SET_SELECTED_TASK";
export const GET_TASK_CHECKLISTITEMS = "GET_TASK_CHECKLISTITEMS";
export const UPDATE_CHECKLISTITEMS = "UPDATE_CHECKLISTITEMS";
export const GET_BENCHMARK_DETAIL = "GET_BENCHMARK_DETAIL";
export const GET_BENCHMARK_SCHEDULE = "GET_BENCHMARK_SCHEDULE";
export const APPLY_ALL_TASKS_FILTERS = "APPLY_ALL_TASKS_FILTERS";
export const APPLY_TASKS_STATUS_FILTERS = "APPLY_TASKS_STATUS_FILTERS";
export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
export const GET_MENTION_LIST = "GET_MENTION_LIST";
export const VIEW_ALL_TASKS = "VIEW_ALL_TASKS";
export const ACTIVE_TASK_STATUS_FILTERS = "ACTIVE_TASK_STATUS_FILTERS";
export const GET_CONTACT_PERMISSIONS = "GET_CONTACT_PERMISSIONS";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const SHOW_CONFIRMATION = "SHOW_CONFIRMATION";
export const DISMISS_MESSAGE = "DISMISS_MESSAGE";
export const WALLET_DETAILS = "WALLET_DETAILS";
export const UNREAD_COMMENT_TASKS = "UNREAD_COMMENT_TASKS";
export const IS_TOAST_CLOSE = "IS_TOAST_CLOSE";
export const UPDATE_PROJECT_TOPICS = "UPDATE_PROJECT_TOPICS";
export const TASK_UNREAD_CMT_CNT_ALL = "TASK_UNREAD_CMT_CNT_ALL";
export const UPDATE_PIN = "UPDATE_PIN";
export const UNREAD_COMMENT_COUNT_UPDATE = "UNREAD_COMMENT_COUNT_UPDATE";
export const LOGIN_AS_STAFF_CONTACT = "LOGIN_AS_STAFF_CONTACT";
export const IS_PREMUIM_ESTIMATE_DISABLE = "IS_PREMUIM_ESTIMATE_DISABLE";
export const IS_IBAN_MODAL_OPEN = "IS_IBAN_MODAL_OPEN";
export const UPDATE_SPACE_NOTIFICATION_COUNT =
  "UPDATE_SPACE_NOTIFICATION_COUNT";
export const SHOW_FULL_SCREEN_LOADER = "SHOW_FULL_SCREEN_LOADER";
export const HIDE_FULL_SCREEN_LOADER = "HIDE_FULL_SCREEN_LOADER";
export const CREATE_SCHDULE_DETAILS = "CREATE_SCHDULE_DETAILS";
export const GET_BENCHMARK_LIST = "GET_BENCHMARK_LIST";
export const SET_FILTER_BENCHMARK_CATEGORY = "SET_FILTER_BENCHMARK_CATEGORY";
export const UPDATE_PROVIDER_ASSIGNEE = "UPDATE_PROVIDER_ASSIGNEE";
export const GOOGLE_OAUTH = "GOOGLE_OAUTH";
export const MODAL_DETAILS = "MODAL_DETAILS";
export const CALENDARS_ASSIGNEE = "CALENDARS_ASSIGNEE";
export const CHECK_LIST_UPDATE = "CHECK_LIST_UPDATE";
export const CALENDARS_DETAILS = "CALENDARS_DETAILS";
export const FETCH_MAILS = "FETCH_MAILS";
export const TASK_TYPE_FILTER = "TASK_TYPE_FILTER";
export const UPDATE_ALL_PROJ_NOTI_COUNT = "UPDATE_ALL_PROJ_NOTI_COUNT";
export const UPDATE_REQUEST_TASK_ASSIGNEE = "UPDATE_REQUEST_TASK_ASSIGNEE";
export const REVOKE_DOCS = "REVOKE_DOCS";
export const SYN_MYR_TASK_ASSIGNEE = "SYN_MYR_TASK_ASSIGNEE";
export const UPDATE_TASK_ASSIGNEE_NEW = "UPDATE_TASK_ASSIGNEE_NEW";
export const UPDATE_BENCHMARK_PROPOSALS_COMMENTS = "UPDATE_BENCHMARK_PROPOSALS_COMMENTS";
export const UPDATE_MAIL_CALENDAR_COUNTER = "UPDATE_MAIL_CALENDAR_COUNTER";
export const FETCH_PROPOSAL_COMMENTS = "FETCH_PROPOSAL_COMMENTS";
export const UPDATE_TASK_ASSIGNEE_LIST = "UPDATE_TASK_ASSIGNEE_LIST";
export const CHECK_DISCORD_CONNECTIVITY = "CHECK_DISCORD_CONNECTIVITY";
export const CHECK_GOOGLE_DRIVE_CONNECTIVITY = "CHECK_GOOGLE_DRIVE_CONNECTIVITY";
export const DISCORD_TOPICS = "DISCORD_TOPICS";
export const UPDATE_MAIL_CC_ASSIGNEE = "UPDATE_MAIL_CC_ASSIGNEE";
export const CHECK_CALENDAR_CONNECTIVITY = "CHECK_CALENDAR_CONNECTIVITY";
export const CALENDAR_TOPICS = "CALENDAR_TOPICS";
export const CHECK_SLACK_CONNECTIVITY = "CHECK_SLACK_CONNECTIVITY";
export const SLACK_TOPICS = "SLACK_TOPICS";
export const OUTLOOK_CALENDAR_CONNECTIVITY = "OUTLOOK_CALENDAR_CONNECTIVITY";
export const OUTLOOK_CALENDAR_TOPICS = "OUTLOOK_CALENDAR_TOPICS";
export const DRIVE_TOPICS = "DRIVE_TOPICS";
export const GET_REFRESH_REQUEST_DATA = "GET_REFRESH_REQUEST_DATA";
export const SET_CONTACT_DATA = "SET_CONTACT_DATA";
export const GET_CONTACT_DATA = "GET_CONTACT_DATA";
export const UPDATE_DOCUMENT_STATUS = "UPDATE_DOCUMENT_STATUS";
export const FETCH_DOCUMENT_LIST = "FETCH_DOCUMENT_LIST";
export const IS_ASSIGNED_USER = "IS_ASSIGNED_USER";
export const CHANGE_DOCUMENT_PRIORITY = "CHANGE_DOCUMENT_PRIORITY";
export const UPDATE_DOCUMENT_PIN = "UPDATE_DOCUMENT_PIN";
export const PRODUCT_TOUR = "PRODUCT_TOUR";
export const GET_TASK_CHAT_ASSIGNEE = "GET_TASK_CHAT_ASSIGNEE";
export const GET_MEETING_LINK = "GET_MEETING_LINK";
export const DEFAULT_PLACEHOLDER = "https://myr.ai/orb/assets/images/default-image-placeholder.svg";
export const OUTLOOK_CALENDAR_CONTACTS = "OUTLOOK_CALENDAR_CONTACTS";
export const SET_DATE_CLICK = "SET_DATE_CLICK";
export const GET_3RD_PARTY_CONTACTS = "GET_3RD_PARTY_CONTACTS";
export const GET_GOOGLE_CONTACTS = "GET_GOOGLE_CONTACTS";
export const VIRTUAL_SPACE_LIST = "VIRTUAL_SPACE_LIST";
export const UPDATE_VIRTUAL_SPACE_NOTI_COUNT = "UPDATE_VIRTUAL_SPACE_NOTI_COUNT";
export const TASK_BULK_ACTION = "TASK_BULK_ACTION";
export const GET_FLOOR_DOCUMENT = "GET_FLOOR_DOCUMENT";
export const REFRESH_EVENTS_OF_CALENDAR = "REFRESH_EVENTS_OF_CALENDAR";
export const SELECTED_FLOOR_ID = "SELECTED_FLOOR_ID";
export const GET_FLOOR_LIST = "GET_FLOOR_LIST";
export const GET_MAIL_ASSIGNEE = "GET_MAIL_ASSIGNEE";
export const CHECK_ONEDRIVE_CONNECTIVITY = "CHECK_ONEDRIVE_CONNECTIVITY";
export const LEAD_MODAL_TOGGLE = "LEAD_MODAL_TOGGLE";
export const SET_GLOBAL_SEARCH_STRING = "SET_GLOBAL_SEARCH_STRING";
export const SET_GET_LANGUAGE = "SET_GET_LANGUAGE";
export const SET_NEW_MEETING = "SET_NEW_MEETING";
export const INVOICE_COUNTER = "INVOICE_COUNTER";
export const ESTIMATE_COUNTER = "ESTIMATE_COUNTER";
export const ESTIMATE_TOPICS = "ESTIMATE_TOPICS";
export const GET_COMPANY_EMPLOYEE = "GET_COMPANY_EMPLOYEE";
export const FETCH_LOCK_TASK_LIST = "FETCH_LOCK_TASK_LIST";
export const COUNT_LOCK_TASKS = "COUNT_LOCK_TASKS";
export const UPDATE_HIDE_LOADER = "UPDATE_HIDE_LOADER";
export const GET_DEFAULT_TOPIC_LIST = "GET_DEFAULT_TOPIC_LIST";
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_SPACE_WORK_SCORE = "GET_SPACE_WORK_SCORE";
export const GET_USER_ID_GLOBAL = "GET_USER_ID_GLOBAL";
export const GET_CALENDAR_TASK_LIST = "GET_CALENDAR_TASK_LIST";
export const SET_CATEGORY_NOTIFICATION_COUNTS = "SET_CATEGORY_NOTIFICATION_COUNTS";
export const PROVIDER_REPORTING_DATA = "PROVIDER_REPORTING_DATA";
export const BENCHMARKS_COUNTER = "BENCHMARKS_COUNTER";
export const GET_ATTENDANCE = "GET_ATTENDANCE";
export const GET_ATTENDANCE_LIST = "GET_ATTENDANCE_LIST";
export const GET_ATTENDANCE_COUNT_WEEK_CALENDAR = "GET_ATTENDANCE_COUNT_WEEK_CALENDAR";
export const GET_PROJECT_ATTENDANCE_COUNT = "GET_PROJECT_ATTENDANCE_COUNT";
export const STAFF_COUNTER = "STAFF_COUNTER";
export const STAFF_DETAIL = "STAFF_DETAIL";
export const STAFF_TOPICS = "STAFF_TOPICS";
export const SET_TEAMS = "SET_TEAMS";
export const GET_TASK_LISTS = "GET_TASK_LISTS";
export const GET_TASK_ATTACHMENTS = "GET_TASK_ATTACHMENTS";
export const SET_EQUIPMENT_CATEGORY_ID = "SET_EQUIPMENT_CATEGORY_ID";
export const GET_DETAILS_FOR_LOGIN_AS_USER = "GET_DETAILS_FOR_LOGIN_AS_USER";
export const SUBSCRIPTION_COUNTER = "SUBSCRIPTION_COUNTER";
export const EQUIPMENTS_CATEGORY_TOPICS = "EQUIPMENTS_CATEGORY_TOPICS";
export const EQUIPMENT_DETAIL_DATA = "EQUIPMENT_DETAIL_DATA";
export const SELECTED_SUB_FLOOR_ID = "SELECTED_SUB_FLOOR_ID";
export const SET_POI_LAT_LNG = "SET_POI_LAT_LNG";
export const GET_POIS_LIST = "GET_POIS_LIST";
export const GET_ACTIVE_POIS_LIST = "GET_ACTIVE_POIS_LIST";
export const SET_BENCHMARK_SCHEDULE = "SET_BENCHMARK_SCHEDULE";
export const CLIENT_TAB_DATA = "CLIENT_TAB_DATA";
export const GET_AR_LOCAL_ANCHORS = "GET_AR_LOCAL_ANCHORS";
export const CLOSE_OUT_SIDE_CLICK = "CLOSE_OUT_SIDE_CLICK";
export const MODEL_VIEWER_POINT = "MODEL_VIEWER_POINT";
export const EDIT_ACCESS_SPACE = "EDIT_ACCESS_SPACE";
export const COMMANOFFCANVAS_IS_OPEN = "COMMANOFFCANVAS_IS_OPEN";
export const OPEN_TASK_DETAILS_CALENDAR = "OPEN_TASK_DETAILS_CALENDAR";
export const UPDATE_SPACE_CONTENT = "UPDATE_SPACE_CONTENT";
export const TASK_ATTACHMENTS_FOR_CREATE_TASK = "TASK_ATTACHMENTS_FOR_CREATE_TASK";
export const UPDATE_UNREAD_CHAT_COUNT_TASKLIST = "UPDATE_UNREAD_CHAT_COUNT_TASKLIST";
export const GET_PROJECT_THEME = "GET_PROJECT_THEME";
export const SET_FAVORITE_DASHBOARD = "SET_FAVORITE_DASHBOARD";
export const _NEW_CLIENT_DATA = "_NEW_CLIENT_DATA";
export const SUCCESS_FLAG = "SUCCESS_FLAG";
export const GET_OPERATOR_BY_CATEGORY = "GET_OPERATOR_BY_CATEGORY";
export const COUNTRY_CODE = "COUNTRY_CODE";
export const GET_ALL_USER_LIST_FOR_STAFF = "GET_ALL_USER_LIST_FOR_STAFF";
export const SELECTED_CHAT_USER = "SELECTED_CHAT_USER";
export const IS_BACKDROP = "IS_BACKDROP";
export const IS_ALL_CHAT_USERLIST_SHOW = "IS_ALL_CHAT_USERLIST_SHOW";
export const GENERAL_UNREAD_COUNT = "GENERAL_UNREAD_COUNT";
export const SEARCH_STRING_CHAT_ALL_USER = "SEARCH_STRING_CHAT_ALL_USER";
export const DAY_GRID_MONTH = "DAY_GRID_MONTH";
export const DAY_GRID_WEEK = "DAY_GRID_WEEK";
export const DAY_GRID_DAY = "DAY_GRID_DAY";
export const IS_TASKLIST_VIEW ="IS_TASKLIST_VIEW"
export const SET_SELECTED_CALENDAR_DATE ="SET_SELECTED_CALENDAR_DATE";
export const IS_AI_CHAT = "IS_AI_CHAT";
export const CREATE_TASK_REQ_INPUT_ICONS = "CREATE_TASK_REQ_INPUT_ICONS";
export const DAY_DIFFERENCE = "DAY_DIFFERENCE";
export const APPLIED_DATE_RANGE_FILTER = "APPLIED_DATE_RANGE_FILTER";
export const SELECTED_VIEW = "SELECTED_VIEW";
export const DATERANGE_FILTER_APPLIED = "DATERANGE_FILTER_APPLIED";
export const CALENDAR_VIEW ="CALENDAR_VIEW";
export const BENCHMARK_CREATE_OR_NOT = "BENCHMARK_CREATE_OR_NOT";
export const SELECTED_DATERANGE_FILTER = "SELECTED_DATERANGE_FILTER";
export const PINNED_DELAY_FILTER = "PINNED_DELAY_FILTER";
export const GET_CHECKED_TASK = "GET_CHECKED_TASK";
export const INV_HEADER_CAL_COUNTER = "INV_HEADER_CAL_COUNTER";
export const IMAGEVIEWER_IS_OPEN = "IMAGEVIEWER_IS_OPEN";
export const TASKLIST_LOADER = "TASKLIST_LOADER";
export const OPEN_CHATAI_INPUTBOX = "OPEN_CHATAI_INPUTBOX";
export const SELECTED_DATE_VIEW = "SELECTED_DATE_VIEW";
export const GET_EQUIPMENTS_LIST = "GET_EQUIPMENTS_LIST";
export const SET_EQUIPMENTS_QUANTITY = "SET_EQUIPMENTS_QUANTITY";
export const SET_EQUIPMENTS_QUANTITY_LIST = "SET_EQUIPMENTS_QUANTITY_LIST";
export const SET_NEW_EQUIPMENT = "SET_NEW_EQUIPMENT";
export const LAST_CHAT_SESSION = "LAST_CHAT_SESSION";
export const METRICS_DETAILS = "METRICS_DETAILS";
export const SELECTED_TYPE_OF_SPACE_ID = "SELECTED_TYPE_OF_SPACE_ID";
export const FILTER_FOR_3D_MODEL = "FILTER_FOR_3D_MODEL";
export const UPDATE_TASK_LIST = "UPDATE_TASK_LIST";
export const SELECTED_DATE_CHATAI = "SELECTED_DATE_CHATAI";
export const CREATE_TASK_MODAL_IS_OPEN = "CREATE_TASK_MODAL_IS_OPEN";
export const DASHBOARD_DETAILS_WITH_ALL_SPACE = "DASHBOARD_DETAILS_WITH_ALL_SPACE";
export const DASHBOARD_VIEW = "DASHBOARD_VIEW";
export const DASHBOARD_FILTER = "DASHBOARD_FILTER";
export const TASK_RATING = "TASK_RATING";
export const UPDATE_WEEK_CALENDAR = "UPDATE_WEEK_CALENDAR";
export const TASK_AND_MESSAGES = "TASK_AND_MESSAGES";
export const FIRST_TASK_DATE = "FIRST_TASK_DATE_DATE";
export const LAST_TASK_DATE = "LAST_TASK_DATE_DATE";
export const FIRST_MESSAGE_DATE = "FIRST_MESSAGE_DATE";
export const LOADING_BENCHMARK_DETAILS = "LOADING_BENCHMARK_DETAILS";
export const SET_SPACE_LIST = "SET_SPACE_LIST";
export const DASHBOARD_DETAILS_WITH_TOTAL = "DASHBOARD_DETAILS_WITH_TOTAL";
export const SHOW_THREE_DAYS_WEEK = "SHOW_THREE_DAYS_WEEK";
export const CREDITNOTES_DETAILS = "CREDITNOTES_DETAILS";
export const USER_LOGOUT = "USER_LOGOUT";
export const MISSED_CALL = "MISSED_CALL";
export const CHAT_UNREADMESSAGE_COUNT = "CHAT_UNREADMESSAGE_COUNT";
export const CHANGE_WEEK_CAL_NAVI = "CHANGE_WEEK_CAL_NAVI";
export const UNREADTASK_COMMENT = "UNREADTASK_COMMENT";
export const UPDATE_SELECTED_TASK_DETAIL = "UPDATE_SELECTED_TASK_DETAIL";
export const GENERATE_AI_THREAD_ID = "GENERATE_AI_THREAD_ID";
export const IS_TOPIC_LIST_LOADING = "IS_TOPIC_LIST_LOADING";
export const CLEAR_DEFAULT_TOPICS = "CLEAR_DEFAULT_TOPICS";
export const SELECTED_DATE_INDEX_FOR_CALENDAR = "SELECTED_DATE_INDEX_FOR_CALENDAR";
export const SHOW_IDB_DATA_FETCHING_LOADER = "SHOW_IDB_DATA_FETCHING_LOADER";
export const TASK_LIST_FOR_CHAT = "TASK_LIST_FOR_CHAT";
export const FIRST_MESSAGE_ID = " FIRST_MESSAGE_ID";
export const CALL_MESSAGE_PAGINATIONS = "CALL_MESSAGE_PAGINATIONS";
export const LAST_FILTER_DETAILS = "LAST_FILTER_DETAILS";
export const LAST_MESSAGE_SPACE = "LAST_MESSAGE_SPACE";
export const DISPLAY_CALL_FULLSCREEN = "DISPLAY_CALL_FULLSCREEN";
export const IS_CALL_RECORDING = "IS_CALL_RECORDING";
export const CREATE_TASK_FOR_AUDIO_CALL = "CREATE_TASK_FOR_AUDIO_CALL";
export const GET_CALL_TASK_DETAILS = "GET_CALL_TASK_DETAILS";
export const GET_COMMENTS_DURING_AUDIO_CALL = "GET_COMMENTS_DURING_AUDIO_CALL";
export const CALL_RECORDING_ACTION_DETAILS = "CALL_RECORDING_ACTION_DETAILS";
export const SHOW_MUTE_MIC = "SHOW_MUTE_MIC";
export const CALL_FUNCTION_OF_CALL_DISCONNECT = "CALL_FUNCTION_OF_CALL_DISCONNECT"
export const FULL_SCREEN_LOADER_CHANGE_SPACE = "FULL_SCREEN_LOADER_CHANGE_SPACE";
export const SELECTED_PLAN_FROM_CHAT = "SELECTED_PLAN_FROM_CHAT";
export const ADDRESS_FOR_SOLAR_PROVIDER = "ADDRESS_FOR_SOLAR_PROVIDER";
export const SOLARHUB_FILTERS = "SOLARHUB_FILTERS";
export const SELECTED_TAB_IN_LEFT_PANLE = "SELECTED_TAB_IN_LEFT_PANLE"
export const REMINDER_LIST = "REMINDER_LIST";
export const PROPOSAL_FILTER = "PROPOSAL_FILTER"  
export const UPDATE_BENCHMARK_LIST = "UPDATE_BENCHMARK_LIST";
export const SET_ADMINISTRATIVE_LIST = "SET_ADMINISTRATIVE_LIST";
export const UPDATE_ADMINISTRATIVE_LIST = "UPDATE_ADMINISTRATIVE_LIST";
export const SET_INSTALLATION_LIST = "SET_INSTALLATION_LIST";
export const UPDATE_INSTALLATION_LIST = "UPDATE_INSTALLATION_LIST";
export const INSTALLATION_FILTER = "INSTALLATION_FILTER";
export const GET_CALENDAR_TASK_DATE = "GET_CALENDAR_TASK_DATE";
export const CHAT_MESSAGES_LOADER = "CHAT_MESSAGES_LOADER";
export const GET_OPERATOR_PROPOSAL_LIST = "GET_OPERATOR_PROPOSAL_LIST";
export const FILTER_SPACE_PROJECT_ID = "FILTER_SPACE_PROJECT_ID";
export const SET_LOCAL_DOC_DATA = "SET_LOCAL_DOC_DATA";
export const SET_LEASE_DOCUMENT_LIST = "SET_LEASE_DOCUMENT_LIST";
export const MY_DRIVE_TOPIC = "MY_DRIVE_TOPIC";
export const SET_TASK_PROPOSAL_TOGGLE = "SET_TASK_PROPOSAL_TOGGLE";
export const SET_MANDATED_DOCUMENT = "SET_MANDATED_DOCUMENT";
export const SET_MANDATED_DOCUMENT_COORDINATE = "SET_MANDATED_DOCUMENT_COORDINATE";
export const UPDATE_DOCUMENT_TEMPLATE = "UPDATE_DOCUMENT_TEMPLATE";
