import React from 'react';
// import CSS from '../css/icon_main.css';

function EstimateIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<g clipPath="url(#clip0_14078_217757)">
<path d="M1.52381 11.0474C1.82691 11.0474 2.11761 10.9271 2.33193 10.7127C2.54625 10.4984 2.66667 10.2077 2.66667 9.90458V1.33315C2.66667 1.1816 2.72687 1.03625 2.83403 0.929086C2.94119 0.821925 3.08654 0.761719 3.23809 0.761719H14.6667C14.8182 0.761719 14.9636 0.821925 15.0707 0.929086C15.1779 1.03625 15.2381 1.1816 15.2381 1.33315V9.90458C15.2381 10.2077 15.1177 10.4984 14.9034 10.7127C14.6891 10.9271 14.3983 11.0474 14.0952 11.0474H1.52381ZM1.52381 11.0474C1.22071 11.0474 0.93001 10.9271 0.715686 10.7127C0.501359 10.4984 0.380951 10.2077 0.380951 9.90458V3.04743M9.17112 2.85696V3.58219M9.17112 8.41726V9.14244M10.8642 4.79076C10.8642 4.63202 10.833 4.47485 10.7723 4.3282C10.7115 4.18154 10.6225 4.0483 10.5102 3.93606C10.398 3.82381 10.2648 3.73479 10.1181 3.67404C9.97143 3.61329 9.81425 3.58203 9.65554 3.58203H8.56769C8.24708 3.58203 7.93966 3.70938 7.71299 3.93606C7.48631 4.16274 7.35896 4.47018 7.35896 4.79076C7.35896 5.11133 7.48631 5.41877 7.71299 5.64545C7.93966 5.87213 8.24708 5.99948 8.56769 5.99948H9.89729C10.2178 5.99948 10.5253 6.12682 10.752 6.3535C10.9787 6.58018 11.106 6.88762 11.106 7.2082C11.106 7.52877 10.9787 7.83623 10.752 8.0629C10.5253 8.28957 10.2178 8.41696 9.89729 8.41696H8.44678C8.12625 8.41696 7.81882 8.28957 7.59212 8.0629C7.36544 7.83623 7.23809 7.52877 7.23809 7.2082" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_14078_217757">
<rect width="16" height="11.4286"/>
</clipPath>
</defs>
</svg>

		</React.Fragment>
	)
}

export default EstimateIcon