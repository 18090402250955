import React, { useState, useEffect, useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import BulkActionsModal from "../Modals/BulkActionsModal";
import LeadCard from "./LeadCard";
import { X, CaretDoubleDown, MagnifyingGlass, FadersHorizontal } from "phosphor-react";
import { useDispatch, useSelector } from "react-redux";
import { jquery } from "jquery";
import {
  leadModalToggle,
  leftPanelFilters,
  projectTaskCounts,
  setTaskTypeFilter,
} from "../../actions/customer";
import {
  getLeadDetail,
  getLeadList,
  getLeadNotes,
  setMyrLeadSources,
  setMyrStaffList,
} from "../../actions/leads-actions/leads-actions";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import GeneralListing from "../TaskModalComponents/GeneralListing";
import { _l, showLeftPanelNew } from "../../hooks/utilities";
import LeadIcon from "../../assets/icons/LeadIcon";
import AdminServices from "../../services/admin-services";
import { isLoadingData } from "../../hooks/loader_helper";
import CommanLoader from "../Loader/CommanLoader";

const MyLeadsList = ({ projectId }) => {
  const [show, setShow] = useState(false);
  const [leads, setLeads] = useState([]);
  const [pageNumber, setPageNumber] = useState({
    0: 1,
    1: 1,
    2: 1,
  });
  const [showDocumentCanvas, setShowDocumentCanvas] = useState(false);
  const [selectedLead, setSelectedLead] = useState();
  const [selectedLeadId, setSelectedLeadId] = useState(0);
  const [leadDetails, setLeadDetails] = useState({
    tabChangeHandler: handleLeadCanvasTabChange,
    lead_id: 0,
    lead_profile: {},
    lead_contact: {},
    lead_attachments: {},
    lead_call_logs: {},
    lead_tracked_emails: {},
    lead_views_tracking: {},
  });
  const [latestSelectedTab, setLatestSelectedTab] = useState("lead_profile");
  const [taskType, setTaskType] = useState("2");
  const [leadCounts, setLeadCounts] = useState({
    0: 0,
    1: 0,
    2: 0,
  });
  const [leftPanelActiveFilters, setLeftActivePanelFilters] = useState([]);
  const [pageLoadFlag, setPageLoadFlag] = useState(false);
  const [leadSearchString, setleadSearchString] = useState("");
  const [creatorDetails, setcreatorDetails] = useState({});
  const [myrStaff, setMyrStaff] = useState([]);
  const [leadSources, setLeadSources] = useState([]);

  const dispatch = useDispatch();
  const {
    calendarData,
    extraCountTask,
    taskList,
    taskFilters,
    // taskSearchString,
    activeTaskStatusFilters,
    isAppliedUnreadComment,
    taskTypeFilter,
    globalSearchString
  } = useSelector((state) => state.customer);
  const {
    leads: leadsData,
    leadDetails: leadDetailsData,
    totalCounts,
    myrStaffList,
    myrLeadSources
  } = useSelector((state) => state.leadsReducer);

  const leadStatuses = [
    {
      id: "16",
      statusText: _l("l_todo"),
      eventKey: "1",
      statusClass: "to-do",
      statusdots: "in-progress-white",
    },
    {
      id: "19",
      statusText: _l("l_inprogress"),
      eventKey: "2",
      statusClass: "in-progress",
      statusdots: "in-progress-blue",
    },
    {
      id: "1",
      statusText: _l("l_done"),
      eventKey: "3",
      statusClass: "done",
      statusdots: "done",
    },
  ];

  let checkedLeads = useRef([]);
  const [defaultAccordianOpened, setDefaultAccordianOpened] = useState([]);

  useEffect(() => {
    /**Inital leads loading */
    dispatch(getLeadList(0, 1, taskFilters));
    setPageLoadFlag(true);
    dispatch(setMyrLeadSources());
    dispatch(setMyrStaffList());
  }, []);

  useEffect(() => {
    leadListFilter();
  }, [JSON.stringify(taskFilters), JSON.stringify(activeTaskStatusFilters)]);

  useEffect(() => {
    leadListFilter();
  }, [localStorage.getItem("selectedDate")]);

  useEffect(() => {
    setLeads([]);
    setLeadCounts({
      2: 0,
      1: 0,
      0: 0,
    });
    if (leadsData && leadsData.length) {
      setLeads(
        leadsData.filter(
          (lbs) =>
            lbs.name.toLowerCase().includes(globalSearchString.toLowerCase()) ||
            lbs.company
              .toLowerCase()
              .includes(globalSearchString.toLowerCase()) ||
            (lbs.company_id != null ? lbs.company_id : "")
              .toLowerCase()
              .includes(globalSearchString.toLowerCase()) ||
            (lbs.appointment_date != null ? lbs.appointment_date : "")
              .toLowerCase()
              .includes(globalSearchString.toLowerCase())
        )
      );
      setLeadCounts({
        2: totalCounts.status1,
        1: totalCounts.status19,
        0: totalCounts.status16,
      });

      if(totalCounts.status16 > 0) {
        setDefaultAccordianOpened(defaultAccordianOpened => [...defaultAccordianOpened, "1"]);
      }

      if(totalCounts.status19 > 0) {
        setDefaultAccordianOpened(defaultAccordianOpened => [...defaultAccordianOpened, "2"]);
      }

      if(totalCounts.status1 > 0) {
        setDefaultAccordianOpened(defaultAccordianOpened => [...defaultAccordianOpened, "3"]);
      }
    }
  }, [JSON.stringify(leadsData), globalSearchString]);

  useEffect(() => {
    if (leadDetailsData && leadDetailsData.lead_id > 0) {
      setLeadDetails({ ...leadDetails, ...leadDetailsData });
      setSelectedLeadId(leadDetailsData.lead_id);
      if (!showDocumentCanvas) {
        setShowDocumentCanvas(true);
      }
    }
  }, [JSON.stringify(leadDetailsData)]);

  useEffect(() => {
    let creator = leads.filter((l) => l.id == leadDetails.lead_id);
    setcreatorDetails(
      creator.length && creator[0].creator_details
        ? creator[0].creator_details
        : {}
    );
  }, [leadDetails]);

  useEffect(() => {
    if (myrStaffList.length) {
      const formattedResponse = myrStaffList.map((staff) => {
        return {
          value: staff.staffid,
          label: staff.fullname,
          role: 1,
          profile_image: staff.profile_image_url,
        };
      });
      setMyrStaff(formattedResponse);
    }
    if (myrLeadSources.length) {
      const formattedResponse = myrLeadSources.map((source) => {
        return {
          value: source.id,
          label: source.name,
        };
      });
      setLeadSources(formattedResponse);
    }
  }, [myrLeadSources, myrStaffList])


  const handleLeadCanvasToggle = (lead) => {
    if (selectedLead) {
      if (selectedLead.id == lead.id) {
        setShowDocumentCanvas(false);
        setSelectedLead();
        return;
      }
    }
    setSelectedLead(lead);
    setShowDocumentCanvas(true);
    dispatch(getLeadDetail(lead.id, latestSelectedTab));
  };

  function handleLeadCanvasTabChange(leadId, tab) {
    if (tab) {
      if (tab == "notesandreminders") {
        dispatch(getLeadNotes(leadId));
      } else {
        dispatch(getLeadDetail(leadId, tab));
      }
      setLatestSelectedTab(tab);
    }
  }

  const leadListFilter = () => {
    if (taskFilters && taskFilters.length && pageLoadFlag) {
      setLeftActivePanelFilters(taskFilters);
      if (projectId > 0) {
        setPageNumber({
          0: 1,
          1: 1,
          2: 1,
        });
        dispatch(
          getLeadList(
            activeTaskStatusFilters ? activeTaskStatusFilters : [],
            1,
            taskFilters
          )
        );
      }
    } else if (taskFilters && !taskFilters.length && pageLoadFlag) {
      setPageNumber({
        0: 1,
        1: 1,
        2: 1,
      });
      setLeftActivePanelFilters([]);
      dispatch(
        getLeadList(
          activeTaskStatusFilters ? activeTaskStatusFilters : [],
          1,
          taskFilters
        )
      );
    }
  };

  const handleViewMore = (status) => {
    setPageNumber({ ...pageNumber, [status]: pageNumber[status] + 1 });
    //lead status convert to task status
    dispatch(
      getLeadList(
        [
          leadStatuses[status].id == 16
            ? 1
            : leadStatuses[status].id == 19
              ? 4
              : leadStatuses[status].id == 1
                ? 5
                : 0,
        ],
        pageNumber[status] + 1,
        taskFilters
      )
    );
  };

  const leadCheckUncheck = (leadId) => {
    const leadPosition = checkedLeads.current.findIndex(x => x == leadId)
    if (leadPosition === -1) {
      checkedLeads.current.push(leadId);
    }
    else {
      checkedLeads.current.splice(leadPosition, 1);
    }
  }

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleAccordianExpansion = (key) => {
    let activeKeysArray = defaultAccordianOpened.slice();

    if(activeKeysArray.includes(key))
    {
      activeKeysArray = activeKeysArray.filter((x) => x!= key)
    }
    else {
      activeKeysArray = activeKeysArray.concat(key);
    }
    setDefaultAccordianOpened(activeKeysArray.slice());
  }

  useEffect(() => {
    setShowDocumentCanvas(false);
    showLeftPanelNew();
  }, []);

  return (
    <>
      {
      isLoadingData("lead_list")
        ?
        <div className={`comman-main-box custom-accordian-main res-padding-right0 h-100 task-list-accordian daily-schedule-main mt-3 w-800px`}>
        <CommanLoader className="position-relative start-0" />  
         </div>
        
        :
        <React.Fragment>
          <div className="pt-10px  "></div>
           <div className={`daily-schedule-main w-800px my-0 overflow-scroll`}>
          <div
            className={`comman-main-box custom-accordian-main res-padding-right0 mt-0 h-100 task-list-accordian ${showDocumentCanvas ? "list-view-toggle" : ""
              } `}
          >
            <Accordion
              activeKey={defaultAccordianOpened}
              onSelect={(eventKey) => {
                handleAccordianExpansion(eventKey);
              }}
              className="task-list-accordian-main mt-0"
            >
              <div className="pill-wrapper top_8px">
              <div className="pill-wrappe-modal-overlay"></div>
                <div id="" className="">
                  <div className="d-flex align-items-center filter-lists-menu min-width-190 justify-content-end d-none">
                    {leftPanelActiveFilters.length ? (
                      leftPanelActiveFilters.map((value, index) => {
                        if (value.main_key == "date_range_filter") {
                          let displayFilterKey = value.title;
                          return (
                            <div
                              key={index}
                              className="badge  c-font f-12 w-auto color-white-80 bg-white-05  radius_3 me-2 comman-round-box active d-none"
                            >
                              <div className="d-flex align-items-center">
                                <span className="color-white">
                                  {displayFilterKey}
                                </span>
                                <a href="#/"
                                  className="ps-1"
                                  onClick={() => {
                                    dispatch(
                                      leftPanelFilters({
                                        main_key: value.main_key,
                                        filter_key: value.filter_key,
                                        title: value.title,
                                        main_sub_key: value.main_sub_key,
                                      })
                                    );
                                    dispatch(
                                      projectTaskCounts(
                                        projectId,
                                        taskFilters,
                                        {
                                          main_key: value.main_key,
                                          filter_key: value.filter_key,
                                          title: value.title,
                                        },
                                        1
                                      )
                                    );
                                    // jquery("body").addClass("body-toggle");
                                    // jquery(".after-login-header").addClass(
                                    //   "body-toggle"
                                    // );
                                    // jquery("footer").addClass("body-toggle");
                                  }}
                                >
                                  <X size={14} weight="light" className="c-icons" />
                                </a>
                              </div>
                            </div>
                          );
                        }
                      })
                    ) : (
                      <></>
                    )}
                    <a href="#/"
                      className="btn btn-white-10 btn-sm me-2 border-0"
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      {_l("l_bulk_action")}
                    </a>
                    {/* <div className="badge  c-font f-12 color-white-80 bg-white-05  radius_3 me-2 d-none">
                      <div className="d-flex align-items-center">
                        <span className="color-white"></span>
                        <a href="#/" className="ps-1">
                          <X size={14} weight="light" className="c-icons" />
                        </a>
                      </div>
                    </div>
                    <div className="d-flex radius_3 justify-content-between commn-search">
                      <div className="comment-input-box">
                        <input
                          type="text"
                          placeholder={`${_l("l_search")}`}
                          className="form-control bg-transparent with-base-bg border-0 py-1 px-2 f-11 c-font"
                          onChange={(e) => {
                            setleadSearchString(e.target.value);
                          }}
                          value={leadSearchString}
                        />
                      </div>
                      <a href="#/" className="comman_action_icon">
                        <div className="d-flex align-items-center ">
                          <div className="action_icon h25w25 d-none">
                            <X size={14} weight="light" className="c-icons filter-img" />
                          </div>
                          <div className="action_icon h25w25">
                            <MagnifyingGlass size={16} className="c-icons MagnifyingGlassIcon" weight="light" />
                          </div>
                          <a href="#/"
                            className="action_icon h25w25 light-theme-img"
                            type="button"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                            data-tip={`${_l("l_filters")}`}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                          >
                            <FadersHorizontal size={16} className="c-icons" weight="light" />
                          </a>
                          <GeneralListing
                            list={[
                              { title: "All", id: "2" },
                              { title: "Task", id: "0" },
                              { title: "Request Task", id: "1" },
                            ].map((filter) => {
                              return {
                                name: filter.title,

                                id: filter.id,
                              };
                            })}
                            handler={(details) => {
                              dispatch(
                                setTaskTypeFilter(
                                  details.id
                                    ? details.id == 1
                                      ? "requestTask"
                                      : details.id == 0
                                        ? "Task"
                                        : "all"
                                    : "all"
                                )
                              );
                            }}
                            selectedIds={[taskType]}
                          />
                        </div>
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
              {leadStatuses.map((status, index) => {
                const leadByStatus = leads.filter((x) => x.status == status.id);
                return (
                  <Accordion.Item
                    eventKey={status.eventKey}
                    key={index}
                    className="with-pb-15"
                  >
                     <div
                      className={`${status.statusClass}    accordian-header-box d-flex align-items-center justify-content-between `}
                    >
                      <div className="checkbox_accordian">
                        <Accordion.Header className={`${status.statusClass} active  commanTaskCount w-auto accordion-header`}>
                        <div className="task-title left-task-header fw-semibold title-fonts c-font f-13">
                        <span className={`${status.statusdots}  c-dots  c-7 rounded-circle me-2`}></span>
                        <span className="me-2">{status.statusText}</span>
                        <span>
                          {" "}
                          {leadCounts ? "[" + leadCounts[index] + "]" : "[0]"}
                        </span>
                      </div>
                        </Accordion.Header>
                      </div>
                      
                    </div>
                    <Accordion.Body>
                      {leadByStatus.length > 0 ? (
                        <React.Fragment>
                          {leadByStatus.map((lead, index2) => {
                            return (
                              <LeadCard
                                key={lead.id}
                                data={lead}
                                hideShow={() => {
                                  handleLeadCanvasToggle(lead);
                                  dispatch(leadModalToggle(true));
                                }}
                                selectedLeadId={selectedLeadId}
                                leadCheckUncheck={leadCheckUncheck}
                              />
                            );
                          })}
                          {leadCounts[index] > leadByStatus.length ? (
                            <a href="#/"
                              className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn mb-2"
                              onClick={() => {
                                handleViewMore(index);
                              }}
                            >
                              <span className="title-fonts d-flex align-item-center justify-content-center">
                                <div className="d-flex align-item-center justify-content-center">
                                  {_l("l_view_more")} (
                                  {leadCounts[index] - leadByStatus.length})
                                  <CaretDoubleDown size={14} className="c-icons ms-2" weight="light" />
                                  <span
                                    role="status"
                                    aria-hidden="true"
                                    className="ms-2 opacity-0 spinner-border spinner-border-sm"
                                  ></span>
                                </div>
                              </span>
                            </a>
                          ) : (
                            <></>
                          )}
                        </React.Fragment>
                      ) : (
                        <div className="comman-placeholder-wrapper mt-4 w-100">
                          <div className="d-flex flex-column align-items-center m-auto">
                            <LeadIcon className="HW50 opacity-50" />
                            <span className="color-white-80 py-2 title-fonts">
                              {_l("l_no_leads")}
                            </span>
                          </div>
                        </div>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
          </div>
          <BulkActionsModal
            show={show}
            leads={checkedLeads.current}
            staffList={myrStaff}
            leadSources={leadSources}
            handleClose={handleClose}
            handleShow={handleShow}
          />
          {showDocumentCanvas ? (
            <CommanOffCanvas
              show={showDocumentCanvas}
              handleClose={() => {
                setShowDocumentCanvas(false);
                dispatch(getLeadDetail("", "", 1));
                dispatch(leadModalToggle(false));
              }}
              data={leadDetails}
              docType={"lead"}
              setSelectedId={() => { }}
              creatorDetails={creatorDetails}
            />
          ) : (
            <></>
          )}
        </React.Fragment>
      }
    </>
  );
};
export default MyLeadsList;
