import React,{useEffect, useState} from 'react'
import moment from "moment";
import Form from "react-bootstrap/Form";
import user_7 from "../../assets/images/users/user_7.jpg";
import { UserCircleGear, ChatCircle, Hash, CaretDoubleDown } from "phosphor-react";
import HighPriorityIcon from "../../assets/icons/HighPriorityIcon";
import HighPriorityActiveIcon from "../../assets/icons/HighPriorityActiveIcon";
import UncontrolledGeneralListing from "../TaskModalComponents/UncontrolledGeneralListing";
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import Button from "react-bootstrap/Button";
import TaskListStatusDropdown from '../Dropdowns/TaskListStatusDropdown';
import Spinner from "react-bootstrap/Spinner";
import { _l } from "../../hooks/utilities";
import CommentDropdown from '../Dropdowns/CommentDropdown';
import ImageUploadDropdown from '../Dropdowns/ImageUploadDropdown';
import CommanOffCanvas from '../Offcanvas/CommanOffCanvas';
import TaskCard from '../TaskCard';
import LockTaskCard from './LockTaskCard';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { DEFAULT_PLACEHOLDER } from "../../actions/action-type";

const LockTasks = ({provider}) => {
    const [showDocumentCanvas, setShowDocumentCanvas] = useState(false);
    const [categoryName, setCategoryName] = useState('No Topic');

    const { defaultTopics, lockTaskList, taskFilters, globalSearchString } = useSelector((state) => state.customer);
    const [taskSearchString, setTaskSearchString] = useState("");

    const [taskList, setTaskList] = useState(provider.tasks);
    const [taskListPage, setTaskListPage] = useState(1);
    const [section, setSection] = useState(provider ? provider : []);

    const navigate = useNavigate();

    useEffect(() => {
        if(defaultTopics)
        {
            let filtered_topic = defaultTopics.filter((topic) => (topic.main_key).toLowerCase().includes(provider.assginee_type && provider.assginee_type !== null ? provider.assginee_type: ""));
            if(filtered_topic.length != 0) {
                let category = filtered_topic[0].categories.filter((category) => category.filter_key.includes(provider.assginee_id && provider.assginee_id !== null ? provider.assginee_id : ""));
                if(category.lengh != 0) {
                    setCategoryName(category && category[0] &&  category[0].title ? category[0].title : "");
                }
            }
        }
    }, [defaultTopics, taskList, provider]);

    useEffect(() => {
        setTaskList(provider ? provider.tasks : []);
        setTaskListPage(1);
      }, [provider]);

    useEffect(() => {
        if(typeof globalSearchString !== 'undefined') {
        setTaskSearchString(globalSearchString !== undefined ? globalSearchString : "");
        }
    }, [globalSearchString]);

    return (
        <div className='p-10 px-0'>
            <div className='d-flex h-100'>
                <div className='w_80px h-100 after-separator position-relative'>
                    <div className='c-font color-white-80 f-12 pb-2 text-center title-fonts'>
                        {provider && provider['time'] !== false ? moment(provider['time']).format('h:mm a') : ''}
                    </div>
                </div>
                <div className="bg-white-03 p-15 radius_3 flex-grow-1 w_80calc">
                    <div className='align-items-end d-flex justify-content-between border-bottom p-15 px-0 pt-0 ps-1'>
                        <a href="#/" className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 mb-1"
                        data-tip={provider.boss_operator ? (provider.boss_operator['name']) : ''}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                        >
                            <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${ provider.boss_operator ? ((provider.boss_operator['profile_image'])) : DEFAULT_PLACEHOLDER}')` }}></div>
                        </a>
                        <div className='ps-2  w100minus20'>
                            <div className=''>
                                <Hash size={18} weight="light" className="c-icons" />
                                <span className='f-13 c-font'>{_l(categoryName)}</span>
                            </div>
                            <div className='bottom-options'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className='align-items-center d-flex w100minus40'>
                                        <div class="badge rounded-pill text-nowrap list-toggle-view-none"><span class=" p-0 ">{_l("l_task")}</span></div>
                                        <div className='before-dnone card-view-none comman_action_icon with_separator_10'>
                                            <div className='multi_inner_wrapper with-min-width d-flex with_minus '>
                                                <a href="#/" className="comman-image-box multi-wrapper-border h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding"
                                                >
                                                    <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url('${DEFAULT_PLACEHOLDER}')` }} >

                                                    </div>
                                                </a>
                                                <a href="#/" className="comman-image-box h20w20 with_overlay d-flex rounded-circle">
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle tag="a" className=" dropdown team-list-dropdown" >
                                                            <a href="#/" className="comman-image-box h20w20 with_overlay rounded-circle d-flex radius_3">
                                                                <div className="unread_count m-auto" >
                                                                    {provider.total_providers.length > 0 ?provider.total_providers.length: 0}
                                                                </div>
                                                            </a>
                                                            <UncontrolledGeneralListing list={provider.total_providers.map((res,index) => {
                                                                return {
                                                                    name: res.name,
                                                                    image: res.profile_image,
                                                                    id: index,
                                                                };
                                                            })} />
                                                        </DropdownToggle>
                                                    </UncontrolledDropdown>
                                                </a>
                                            </div>
                                        </div>
                                        <div className='before-dnone comman_action_icon with_separator_10 card-view-none flex-grow-1 '>
                                            <div className="progress-slider flex-grow-1">
                                                <div className="d-flex align-items-center">
                                                    <div className="progress me-3">
                                                        <div className="progress-bar" style={{ width: `${provider.task_progress}%` }}></div>
                                                    </div>
                                                    <div className="c-font f-12 ms-2 title-fonts">{provider.task_progress}%</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a href={window.location.origin+'/chat'} target='_blank' ><Button variant="primary" size="sm">{_l("l_chat_modal")}</Button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='pb-2 pt-3 ps-30'>
                        <ul className=''>
                        {
                            taskList && taskList.length ? (
                            taskList
                            .filter((task) => (task.name || "").toLowerCase().includes(taskSearchString.toLowerCase()))
                            .slice(0,
                                taskListPage > 1
                                    ? (taskListPage - 1) * 5 + 3
                                    : taskListPage * 3
                                )
                            .map((task, index) => {
                                return ( <LockTaskCard key={index} task={task} section={section}/>)
                            })) : (<></>)
                        }
                            
                            {/* <li className='align-items-center d-flex justify-content-between p-10 px-0 pt-0'>
                                <div className='d-flex flex-grow-1 w100minus100per'>
                                    <div className="h20w20 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 ">
                                        <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('https://source.unsplash.com/random')` }}></div>
                                    </div>
                                    <a href="#/" className='c-font color-white-80 f-12 flex-grow-1 ms-2 text-truncate w100minus100per pe-2'>Complete the Compensation Plan Quiz</a>
                                </div>
                                <div className='d-flex'>
                                    <div className='d-flex pe-2'>
                                        <div className="multi_image_wrapper dropdown">
                                            <div className="d-flex multi_inner_wrapper pe-2 position-relative">
                                                <a href="#/" className="comman-image-box h20w20 radius_3 multi-wrapper-border">
                                                    <div className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3" style={{ backgroundImage: `url('${DEFAULT_PLACEHOLDER}')` }} >

                                                    </div>
                                                </a>
                                                <a href="#/" className="comman-image-box h20w20 radius_3 multi-wrapper-border">
                                                    <div className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3" style={{ backgroundImage: `url('${DEFAULT_PLACEHOLDER}')` }} >

                                                    </div>
                                                </a>
                                                <div className='comman-image-box h20w20 with_overlay d-flex radius_3 me-1 dropstart'>
                                                    <a href="#/" className="m-auto" type="button"
                                                        id="imageLstAndUploadDropdown"
                                                        data-bs-toggle="dropdown"
                                                        data-bs-offset="5,5"
                                                        aria-expanded="false"
                                                        data-bs-auto-close="outside"
                                                        data-tip={_l("l_attachments")}
                                                        data-effect="solid"
                                                        data-delay-show='1000'
                                                        data-class="tooltip-main">+2
                                                    </a>
                                                    <ImageUploadDropdown/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='comman_action_icon px-1 align-items-center d-flex'>
                                        <a href="#/" className='action_icon with_bg dropdown' id="TaskListStatusDropdown" data-bs-toggle="dropdown">
                                            <div className="d-flex align-items-center m-auto">
                                                <span className="c-dots c-10 in-progress-white rounded-circle c-dots c-10 in-progress-red rounded-circle"></span>
                                                <TaskListStatusDropdown />
                                            </div>
                                        </a>
                                    </div>
                                    <div className='comman_action_icon px-1 align-items-center d-flex'>
                                        <a href="#/" className='action_icon with_bg'>
                                            <div className="check_box m-auto">
                                                <HighPriorityActiveIcon className="HW18 check_span_img active" />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="dropdown comman_action_icon px-1 align-items-center d-flex">
                                        <a href="#/"
                                            className="comman_action_icon w-100 d-flex"
                                            type="button"
                                            id="writeComMentDropdown"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            data-bs-auto-close="outside"
                                            data-tip={_l("l_comments")}
                                            data-effect="solid"
                                            data-delay-show='1000'
                                            data-class="tooltip-main"

                                        >
                                            <div className="d-flex align-items-center w-100">
                                                <div className="action_icon with_bg position-relative on-hover-active-toggle-img light-theme-img">
                                                    <ChatCircle
                                                        size={16}
                                                        weight="light"
                                                        className="c-icons"
                                                    />
                                                    <span className="rounded-pill badge-custom p-0 d-none">5</span>
                                                </div>
                                                <span className="c-font f-12 d-flex justify-content-center flex-grow-1 text-on-hover-active-green number-counter-toggle">10</span>
                                            </div>
                                        </a>
                                        {
                                            <CommentDropdown />
                                        }
                                    </div>
                                </div>
                            </li> */}
                            {/* <li className='align-items-center d-flex justify-content-between p-10 px-0 pt-0'>
                                <div className='d-flex flex-grow-1 w100minus100per'>
                                    <div className="h20w20 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 ">
                                        <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('https://source.unsplash.com/random')` }}></div>
                                    </div>
                                    <a href="#/" className='c-font color-white-80 f-12 flex-grow-1 ms-2 text-truncate w100minus100per pe-2'>Complete the Compensation Plan Quiz</a>
                                </div>
                                <div className='d-flex'>
                                    <div className='d-flex pe-2'>
                                        <div className="multi_image_wrapper dropdown">
                                            <div className="d-flex multi_inner_wrapper pe-2 position-relative">
                                                <a href="#/" className="comman-image-box h20w20 radius_3 multi-wrapper-border">
                                                    <div className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3" style={{ backgroundImage: `url('${DEFAULT_PLACEHOLDER}')` }} >

                                                    </div>
                                                </a>
                                                <a href="#/" className="comman-image-box h20w20 radius_3 multi-wrapper-border">
                                                    <div className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3" style={{ backgroundImage: `url('${DEFAULT_PLACEHOLDER}')` }} >

                                                    </div>
                                                </a>
                                                <div className='comman-image-box h20w20 with_overlay d-flex radius_3 me-1 dropstart'>
                                                    <a href="#/" className="m-auto" type="button"
                                                        id="imageLstAndUploadDropdown"
                                                        data-bs-toggle="dropdown"
                                                        data-bs-offset="5,5"
                                                        aria-expanded="false"
                                                        data-bs-auto-close="outside"
                                                        data-tip={_l("l_attachments")}
                                                        data-effect="solid"
                                                        data-delay-show='1000'
                                                        data-class="tooltip-main">+2
                                                    </a>
                                                    <ImageUploadDropdown/>
                                                </div>   
                                            </div>
                                        </div>
                                    </div>
                                    <div className='comman_action_icon px-1 align-items-center d-flex'>
                                        <a href="#/" className='action_icon with_bg dropdown' id="TaskListStatusDropdown" data-bs-toggle="dropdown">
                                            <div className="d-flex align-items-center m-auto">
                                                <span className="c-dots c-10 in-progress-white rounded-circle c-dots c-10 in-progress-red rounded-circle"></span>
                                                <TaskListStatusDropdown />
                                            </div>
                                        </a>
                                    </div>
                                    <div className='comman_action_icon px-1 align-items-center d-flex'>
                                        <a href="#/" className='action_icon with_bg'>
                                            <div className="check_box m-auto">
                                                <HighPriorityActiveIcon className="HW18 check_span_img active" />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="dropdown comman_action_icon px-1 align-items-center d-flex">
                                        <a href="#/"
                                            className="comman_action_icon w-100 d-flex"
                                            type="button"
                                            id="writeComMentDropdown"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            data-bs-auto-close="outside"
                                            data-tip={_l("l_comments")}
                                            data-effect="solid"
                                            data-delay-show='1000'
                                            data-class="tooltip-main"

                                        >
                                            <div className="d-flex align-items-center w-100">
                                                <div className="action_icon with_bg position-relative on-hover-active-toggle-img light-theme-img">
                                                    <ChatCircle
                                                        size={16}
                                                        weight="light"
                                                        className="c-icons"
                                                    />
                                                    <span className="rounded-pill badge-custom p-0 d-none">5</span>
                                                </div>
                                                <span className="c-font f-12 d-flex justify-content-center flex-grow-1 text-on-hover-active-green number-counter-toggle">10</span>
                                            </div>
                                        </a>
                                        {
                                            <CommentDropdown />
                                        }
                                    </div>
                                </div>
                            </li> */}
                        </ul>
                        {
                            taskList.length >= (taskListPage - 1) * 5 + 3 &&
                            taskList.length != (taskListPage - 1) * 5 + 3 ? (
                                <a href="#/" className="btn btn-white-03 w-100 view-more-btn" onClick={() => {  setTaskListPage(taskListPage + 1); }}>
                                    <div className="d-flex align-item-center justify-content-center">
                                        <span className=" title-fonts c-font f-12 ms-2">
                                            {_l("l_view_more_btn")} (
                                                {taskListPage > 1
                                                ? taskList.length -
                                                    ((taskListPage - 1) * 5 + 3)
                                                : taskList.length - 3}
                                                )
                                        </span>
                                        <CaretDoubleDown size={12} className="c-icons mx-2" weight="light" />
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            className="d-none"
                                        />
                                    </div>
                                </a>
                            ) : (<></>) 
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LockTasks