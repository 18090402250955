import { STATIC_PLACE_ID_FOR_DEV } from "../constants/constants";
import { archieveIDBCollections, clearDatabase, restoreIDBSession } from "../hooks/indexed_db_helpers";
import { isLocalEnvironment, isSolarHubPlatform } from "../hooks/utilities";
import apiService from "./api-service/api-service";
const CryptoJS = require("crypto-js");

const API_URL = process.env.REACT_APP_API_URL;

const login = async (email, password) => {
  try {
    // await clearDatabase();
    // await restoreIDBSession(email);
  }
  catch(e)
  {
    console.error(e);
  }
  finally {
    const endPoint = API_URL + "mod=login&act=do_login";
    let form = new FormData();
    form.append("email", email);
    form.append("password", password);
    return apiService(endPoint, "POST", true, form);
  }
};

const loginAsUser = async (email, client_id, projectId,filter_key) => {
  const adminEmail = localStorage.getItem("email");
  await archieveIDBCollections(adminEmail);
  await clearDatabase();
  await restoreIDBSession(email);
  const endPoint = API_URL + "mod=login&act=do_login";
  let form = new FormData();
  const enc_token = localStorage.getItem("accessToken");
  const token = CryptoJS.AES.decrypt(
    enc_token,
    process.env.REACT_APP_JWT_ENC_KEY
  ).toString(CryptoJS.enc.Utf8);
  let staff_id = localStorage.getItem("staff_id");
  form.append("staff_id", staff_id);
  form.append("switch_user", 1);
  form.append("client_id", client_id);
  if (filter_key) {
    form.append("switch_to_staff_id", filter_key);    
  } else {
    form.append("email", email);
  }
  form.append("project_id", projectId);
  form.append("token", token);

  return apiService(endPoint, "POST", false, form, 1, true);
};

const autoLogin = async (loginKey) => {
  const endPoint = API_URL + "mod=login&act=auto_login";
  let form = new FormData();
  form.append("login_key", loginKey);
  localStorage.clear();
  localStorage.setItem("version", process.env.REACT_APP_VERSION);
  return apiService(endPoint, "POST", false, form, undefined, true);
};

const createGuestProfile = () => {
  const endPoint = API_URL + "mod=task&act=create_guest_user";
  let form = new FormData();
  form.append("is_external", 1);
  localStorage.clear();
  localStorage.setItem("version", process.env.REACT_APP_VERSION);

  return apiService(endPoint, "POST", false, form, undefined, true);
};

const getUserRecentSessions = () => {
  const endPoint = API_URL + "mod=general&act=get_login_details";
  let form = new FormData();
  const userType = localStorage.getItem("user_type");
  let staffOrContactId = 0;
  if(userType == "contact" || userType == "operator")
  {
    let adminArchieve = JSON.parse(localStorage.getItem("adminArchieve"));
    let adminStaffId = adminArchieve.find((x) => x.key === "staff_id");
    staffOrContactId = adminStaffId.value;
  }
  else {
    staffOrContactId = localStorage.getItem("staff_id");
  }
  form.append("staff_id", staffOrContactId);
  return apiService(endPoint, "POST", true, form, 1, true);
};

const getUserInfo = async (type, id, from_admin_side = 0) => {
  const endPoint = API_URL + "mod=login&act=get_user_details_for_reset_password";
  let form = new FormData();
  form.append("type", type);
  form.append("id", id);
  if (from_admin_side == 1) {
    form.append("from_admin_side", from_admin_side);
  }
  return apiService(endPoint, "POST", true, form);
};

const setUserPassword = async (id, type, password) => {
  const endPoint = API_URL + "mod=login&act=set_user_password";
  let ids = id
  if (isSolarHubPlatform()) {
   ids = type == "contact" ? localStorage.getItem("contact_id") : localStorage.getItem("staff_id")
  }
  let form = new FormData();
  form.append("password", password);
  form.append("type", type);
  form.append("id", ids);
  return apiService(endPoint, "POST", true, form);
};

const sendLogsToServer = (logs) => {
  const endPoint = API_URL + "mod=general&act=send_log_to_server";
  let form = new FormData();
  form.append("logs", logs);
  return apiService(endPoint, "POST", false, form, undefined, true);
};

const checkEmailforSetPassword = async (email) => {
  const endPoint = API_URL + "mod=login&act=check_email_for_set_password";
  let form = new FormData();
  form.append("email", email);
  return apiService(endPoint, "POST", true, form);
};

const getCustomerInviteeDetails = (hash) => {
  const endPoint = API_URL + "mod=space&act=get_client_details";
  let form = new FormData();
  form.append("hash", hash);
  return apiService(endPoint, "POST", false, form, undefined, true);
};

const acceptProviderInvite = (customerData) => {
  const endPoint = API_URL + "mod=space&act=invited_after_add_client";
  let form = new FormData();
  form.append("first_name", customerData.first_name);
  form.append("last_name", customerData.last_name);
  form.append("email", customerData.email);
  form.append("phonenumber", customerData.phonenumber);
  form.append("password", customerData.password);
  form.append("profile_image", customerData.profile_image);
  form.append("contact_name", customerData.contact_name);
  form.append("company", customerData.company);
  form.append("project_id", customerData.project_id);
  form.append("staff_id", customerData.staff_id);
  // form.append("input_client_id", customerData.input_client_id);

  return apiService(endPoint, "POST", false, form, undefined, true);
};

const registerAsGuestContact = (fingerprint) => {
  const endPoint = API_URL + "mod=user&act=add_external_user";
  let form = new FormData();
  form.append("hash", fingerprint);
  form.append("is_external", 1);
  return apiService(endPoint, "POST", false, form, undefined, true);
};

const createContactProfile = ({first_name, last_name, email, mobile_number, password, company_name, address, project_id}) => {
  const endPoint = API_URL + "mod=user&act=update_external_user_profile";
  let form = new FormData();

  const contact_id = localStorage.getItem("contact_id");
  const client_id = localStorage.getItem("client_id");

  form.append("contact_id", contact_id);
  form.append("client_id", client_id);
  form.append("first_name", first_name);
  form.append("last_name", last_name);
  form.append("email", email);
  form.append("mobile_number", mobile_number);
  form.append("password", password);
  form.append("company_name", company_name);
  form.append("address", address);
  form.append("project_id", project_id);
  form.append("is_external", 1);
  return apiService(endPoint, "POST", false, form, undefined, true);
}

const userRegistration = (customerData, selectedCategorys = [], newCategories = []) => {
  const endPoint = API_URL + "mod=registration&act=registration";
  let form = new FormData();
  form.append("company", customerData.company_name);
  form.append("contact_name", customerData.first_name);
  form.append("email", customerData.email);
  if(customerData.mobile_number)
  {
    form.append("phonenumber", customerData.country_code ? `${customerData.country_code}${customerData.mobile_number}` : `+33${customerData.mobile_number}` );
  }
  form.append("password", customerData.password);
  form.append("company_address", customerData.address);
  if(customerData.user_type != 1 && selectedCategorys.length){ 
    newCategories.forEach((id,index)=>{
      form.append(`custom_type_of_job[${index}]`, id);
    })
    selectedCategorys.forEach((id,index)=>{
      if(id > 0)
      {
        form.append(`type_of_job[${index}]`, id);
      }
    })
  }
  form.append("user_type", customerData.user_type);
  form.append("region_name", customerData.region_name);
  form.append("company_id", parseInt(customerData.selectedCompanyId) > 0 ? customerData.selectedCompanyId : 0);
  return apiService(endPoint, "POST", false, form, undefined, true);
};

const externalUserRegistratoin = (customerData, selectedCategorys = [], newCategories = [], package_id, operator_availibility, contracts, mission_region_id, mission_region_name, agentSlots) => {
  const endPoint = API_URL + "mod=registration&&act=registration_new_version";
  let form = new FormData();
  const { companyLogo, companyId, companyRGE, companyQC, companyInsurance, companymandate, companyPI } = customerData.company_documents
  if (companyLogo) {
      Array.from(companyLogo).map((file, index) => {
        form.append(`logo`, file);
      });
  }
  if (companyId) {
    Array.from(companyId).map((file, index) => {
      form.append(`company_id`, file);
    });
  } 
  if (companyQC) {
    Array.from(companyQC).map((file, index) => {
      form.append(`skill`, file);
    });
  }
  if (companyRGE) {
    Array.from(companyRGE).map((file, index) => {
      form.append(`rge_certificate`, file);
    });
  }
  if (companyInsurance) {
    Array.from(companyInsurance).map((file, index) => {
      form.append(`insurance`, file);
    });
  }
  if (companymandate) {
    Array.from(companymandate).map((file, index) => {
      form.append(`mandate`, file);
    });
  }
  if (companyPI) {
    Array.from(companyPI).map((file, index) => {
      form.append(`pi`, file);
    });
  }
  form.append("company", customerData.company_name);
  form.append("contact_name", customerData.first_name);
  form.append("email", customerData.email);

  if(isLocalEnvironment())
  {
    customerData = {...customerData, address: "Avenue De France, Paris, France", region_id: "158", region_name: "Île-de-France", place_id: STATIC_PLACE_ID_FOR_DEV};
  }

  if(customerData.mobile_number)
  {
    form.append("phonenumber", customerData.country_code ? `${customerData.country_code} ${customerData.mobile_number}` : `+33${customerData.mobile_number}` );
  }
  else if(customerData.phonenumber)
  {
    form.append("phonenumber", customerData.phonenumber);
  }
  form.append("password", customerData.password);
  form.append("company_address", customerData.address);
  form.append("package_id", package_id);
  
  if(customerData.invoice_tax)
  {
    form.append("invoice_tax", customerData.invoice_tax);
  }

  if(mission_region_name)
  {
    form.append("address", customerData.mission_address);
    form.append("region", mission_region_id);
    form.append("region_name", mission_region_name);
  }
  form.append("is_external", 1);

  if(operator_availibility)
  {
    form.append("operator_requirment", JSON.stringify(operator_availibility));
  }

  if(contracts)
  {
    Array.from(contracts).forEach((contract, key) => {
      form.append(`contract_file[${key}]`, contract);
    });
  }

  if(customerData.user_type != 1 && selectedCategorys.length){ 
    newCategories.forEach((id,index)=>{
      form.append(`custom_type_of_job[${index}]`, id);
    })
    selectedCategorys.forEach((id,index)=>{
      if(id > 0)
      {
        form.append(`type_of_job[${index}]`, id);
      }
    })
  }

  if(customerData.agent_documents)
  {
    Object.keys(customerData.agent_documents).forEach((documentType) => {
      if(customerData.agent_documents[documentType].attachments)
      {
        Array.from(customerData.agent_documents[documentType].attachments).forEach((file, index) => {
          if(documentType == "profile_image")
          {
            form.append(`${documentType}`, file);
          }
          else {
            form.append(`${documentType}[${index}]`, file);
          }
        });
      }
    });
  }

  if(customerData.region_id > 0)
  {
    form.append("region_id", customerData.region_id);
  }

  form.append("user_type", customerData.user_type);
  form.append("region_name", customerData.region_name);

  if(customerData.company_id > 0)
  {
    form.append("company_id", parseInt(customerData.company_id));
  }
  else {
    form.append("company_id", parseInt(customerData.selectedCompanyId) > 0 ? customerData.selectedCompanyId : 0);
  }

  if(agentSlots && agentSlots.length)
  {
    form.append("agent_slots", JSON.stringify(agentSlots));
  }
  
  if(customerData.provider_address)
  {
    form.append("provider_address", JSON.stringify(customerData.provider_address));
  }

  return apiService(endPoint, "POST", false, form, undefined, true);
};

const userRegestartionNewFlow = (fullName, phoneNumber, email, password, profilePic) => {
  const endPoint = API_URL + "mod=registration&act=registration";  
  let form = new FormData();
  form.append("contact_name", fullName);
  form.append("phonenumber", phoneNumber);
  form.append("email", email);
  form.append("password", password);
 
  if(profilePic)
  {
      form.append("file", profilePic);
  }

  return apiService(endPoint, "POST", true, form, undefined, true);
};

const userRegestartionNewFlowStep2 = (client_id, contact_id, staff_id, companyname, companyaddress, region_id, user_type, vat, companyprofilepic,selectedCategorys) => {
  const endPoint = API_URL + "mod=registration&act=registration";  
  let form = new FormData();
  form.append("registered_as_guest", 1);
  form.append("client_id", client_id);
  form.append("contact_id", contact_id);
  form.append("staff_id", staff_id);
  form.append("company", companyname);
  form.append("company_address", companyaddress);
  form.append("region_id", region_id);
  form.append("user_type", user_type);
  form.append("invoice_tax", vat);

  if(companyprofilepic)
  {
      form.append(`logo`, companyprofilepic);
  }
  selectedCategorys.forEach((id,index)=>{
    if(id.client_id > 0)
    {
      form.append(`type_of_job[${index}]`, id.value);
    }
  })
  
  return apiService(endPoint, "POST", true, form, undefined, true);
};
const getJobApplications = () => {
  const endPoint = API_URL + "mod=registration&act=get_application";  
  let form = new FormData();
  const client_id = localStorage.getItem("client_id");

  form.append("client_id", client_id);

  return apiService(endPoint, "POST", false, form, undefined, true);
};

const jobApplicationAction = (employee_staff_id, actionType) => { //actionType => 1 for accept, 2 for reject
  const endPoint = API_URL + "mod=registration&act=job_application_action";  
  let form = new FormData();
  const client_id = localStorage.getItem("client_id");
  const contact_id = localStorage.getItem("contact_id");
  const staff_id = localStorage.getItem("staff_id");

  form.append("client_id", client_id);
  form.append("contact_id", contact_id);
  form.append("staff_id", staff_id);
  form.append("employee_staff_id", employee_staff_id);
  form.append("is_accept", actionType);

  return apiService(endPoint, "POST", false, form, undefined, true);
};

const requestPasswordResetLink = (email) => {
  const endPoint = API_URL + "mod=user&act=request_password_reset_link";
  let form = new FormData();
  form.append("email", email);
  form.append("is_external", 1);
  return apiService(endPoint, "POST", false, form, undefined, true);
};

const validatePasswordResetLink = (link) => {
  const endPoint = API_URL + "mod=user&act=validate_password_reset_link";
  let form = new FormData();
  form.append("user_key", link);
  form.append("is_external", 1);
  return apiService(endPoint, "POST", false, form, undefined, true);
};

const resetUserPassword = (link, password) => {
  const endPoint = API_URL + "mod=user&act=reset_user_password";
  let form = new FormData();
  form.append("key", link);
  form.append("password", password);
  form.append("is_external", 1);
  return apiService(endPoint, "POST", false, form, undefined, true);
};

export default {
  autoLogin,
  createGuestProfile,
  login,
  loginAsUser,
  getUserInfo,
  setUserPassword,
  getUserRecentSessions,
  sendLogsToServer,
  checkEmailforSetPassword,
  getCustomerInviteeDetails,
  acceptProviderInvite,
  registerAsGuestContact,
  createContactProfile,
  userRegistration,
  acceptProviderInvite,
  externalUserRegistratoin,
  userRegestartionNewFlow,
  userRegestartionNewFlowStep2,
  getJobApplications,
  jobApplicationAction,
  requestPasswordResetLink,
  validatePasswordResetLink,
  resetUserPassword
};
