import React from 'react';
// import CSS from '../css/icon_main.css';

function UserGearIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>


<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M6.75 10C8.82106 10 10.5 8.32107 10.5 6.25C10.5 4.17893 8.82106 2.5 6.75 2.5C4.67893 2.5 3 4.17893 3 6.25C3 8.32107 4.67893 10 6.75 10ZM6.75 10C5.72778 10 4.71797 10.2232 3.79154 10.6552C2.8651 11.0872 2.04453 11.7169 1.3875 12.5M6.75 10C7.77221 10 8.78203 10.2232 9.70846 10.6552C10.6349 11.0872 11.4555 11.7169 12.1125 12.5M13.75 9.5C14.3023 9.5 14.75 9.05228 14.75 8.5C14.75 7.94772 14.3023 7.5 13.75 7.5M13.75 9.5C13.1977 9.5 12.75 9.05228 12.75 8.5C12.75 7.94772 13.1977 7.5 13.75 7.5M13.75 9.5V10.25M13.75 7.5V6.75M12.8812 8L12.2375 7.625M12.8812 9L12.2375 9.375M14.6187 9L15.2625 9.375M14.6187 8L15.2625 7.625"/>
</svg>

		</React.Fragment>
	)
}

export default UserGearIcon