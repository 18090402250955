import React from 'react';
// import CSS from '../css/icon_main.css';

function FileSettingIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons`}>
<path d="M46.875 29.0625V20.625L33.75 7.5H11.25C10.7527 7.5 10.2758 7.69754 9.92418 8.04918C9.57254 8.40081 9.375 8.87772 9.375 9.375V50.625C9.375 51.1223 9.57254 51.5992 9.92418 51.9508C10.2758 52.3025 10.7527 52.5 11.25 52.5H27.1875" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M33.75 7.5V20.625H46.875" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.75 30H33.75" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.75 37.5H33.75" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M45.0008 48.7723C46.913 48.7723 48.4632 47.2221 48.4632 45.3099C48.4632 43.3976 46.913 41.8474 45.0008 41.8474C43.0885 41.8474 41.5383 43.3976 41.5383 45.3099C41.5383 47.2221 43.0885 48.7723 45.0008 48.7723Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M45.1783 52.0712C45.0623 52.0712 44.9455 52.0712 44.8321 52.0712L42.0578 53.6197C40.9778 53.2565 39.976 52.6926 39.1052 51.9578L39.0948 48.8416C39.0333 48.7446 38.9753 48.6468 38.9217 48.5464L36.163 46.9753C35.9457 45.8762 35.9457 44.7452 36.163 43.6462L38.9191 42.0794C38.9753 41.9799 39.0333 41.8812 39.0922 41.7842L39.106 38.668C39.9761 37.9311 40.9776 37.3652 42.0578 37L44.8277 38.5486C44.9437 38.5486 45.0606 38.5486 45.174 38.5486L47.9439 37C49.0239 37.3633 50.0257 37.9271 50.8965 38.662L50.9069 41.7782C50.9684 41.8751 51.0264 41.9729 51.0801 42.0734L53.837 43.6436C54.0543 44.7426 54.0543 45.8736 53.837 46.9727L51.0809 48.5395C51.0247 48.639 50.9667 48.7377 50.9078 48.8346L50.894 51.9508C50.0245 52.6879 49.0236 53.2541 47.9439 53.6197L45.1783 52.0712Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

			{/* <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M46.875 29.0625V20.625L33.75 7.5H11.25C10.7527 7.5 10.2758 7.69754 9.92418 8.04918C9.57254 8.40081 9.375 8.87772 9.375 9.375V50.625C9.375 51.1223 9.57254 51.5992 9.92418 51.9508C10.2758 52.3025 10.7527 52.5 11.25 52.5H27.1875" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M33.75 7.5V20.625H46.875" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.75 30H33.75" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.75 37.5H33.75" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M45.0006 48.7723C46.9128 48.7723 48.463 47.2221 48.463 45.3099C48.463 43.3976 46.9128 41.8474 45.0006 41.8474C43.0883 41.8474 41.5381 43.3976 41.5381 45.3099C41.5381 47.2221 43.0883 48.7723 45.0006 48.7723Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M45.1783 52.0712C45.0623 52.0712 44.9455 52.0712 44.8321 52.0712L42.0578 53.6197C40.9778 53.2565 39.976 52.6926 39.1052 51.9578L39.0948 48.8416C39.0333 48.7446 38.9753 48.6468 38.9217 48.5464L36.163 46.9753C35.9457 45.8762 35.9457 44.7452 36.163 43.6462L38.9191 42.0794C38.9753 41.9799 39.0333 41.8812 39.0922 41.7842L39.106 38.668C39.9761 37.9311 40.9776 37.3652 42.0578 37L44.8277 38.5486C44.9437 38.5486 45.0606 38.5486 45.174 38.5486L47.9439 37C49.0239 37.3633 50.0257 37.9271 50.8965 38.662L50.9069 41.7782C50.9684 41.8751 51.0264 41.9729 51.0801 42.0734L53.837 43.6436C54.0543 44.7426 54.0543 45.8736 53.837 46.9727L51.0809 48.5395C51.0247 48.639 50.9667 48.7377 50.9078 48.8346L50.894 51.9508C50.0245 52.6879 49.0236 53.2541 47.9439 53.6197L45.1783 52.0712Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
</svg> */}
		

		</React.Fragment>
	)
}

export default FileSettingIcon