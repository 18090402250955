import React, { useEffect, useState } from "react";
import { _l } from "../../../hooks/utilities";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarBlank,DotsThreeVertical, ChatCircle} from "phosphor-react";
import TaskListStatusDropdown from "../../Dropdowns/TaskListStatusDropdown";
import DeleteDropdown from "../../Dropdowns/DeleteDropdown";
import UncontrolledGeneralListing from "../../TaskModalComponents/UncontrolledGeneralListing";
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';

const CreditNoteWalletCard = () => {
    const [startDate, setStartDate] = useState(new Date());
  return (
    <>
      <div className="task-list-card list-view invoice-wallet-card">
                    <div className="task-list-wrapper">
                      <div className="d-flex w-100 align-items-start">
                        <div className="d-flex task-list-detail">
                          <div className="task-left flex-grow-1 width100minus160">
                            <div className="d-flex align-items-center flex-grow-1 text-truncate">
                              <div className="task-top-part text-truncate flex-grow-1">
                                <div className="d-flex align-items-center w-100">
                                  <div className="task-creator-img">
                                    <div className="comman-image-box h20w20 rounded-circle">
                                      <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{
                                            backgroundImage: `url('https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80')`,
                                         }}></div>
                                    </div>
                                  </div>
                                  <a href="#/" className="max-w300 task-list-name text-truncate">Cleaning - bfsfsbff</a>
                                </div>
                              </div>
                              <div className="task-mid-part  comman_action_icon ">
                                <a href="#/" class="badge  badge-white rounded-pill me-2"><span class="color-white p-0">{_l('l_one_time')}</span></a>
                              </div>
                            </div>
                            <div className="d-flex align-items-center task-bottom-part-full">
                              <div className="task-right-static-options">
                                <div className="d-flex align-items-center">
                                  <div className="d-flex align-items-center justify-content-end w-120 w-auto pe-2">
                                    <div className="position-relative  assignee-list-wrapper">
                                      <div className="multi_inner_wrapper with-min-width d-flex with_minus">
                                        <a href="#/" className="comman-image-box multi-wrapper-border after-dnone h20w20 rounded-circle">
                                          <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{
                                            backgroundImage: `url('https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80')`,
                                         }}>
                                          </div>
                                        </a>
                                        <UncontrolledDropdown>
                          <DropdownToggle tag="a" className="comman-image-box h20w20 with_overlay d-flex rounded-circle dropdown team-list-dropdown" >
                          <div
                                className="comman-image-box h20w20 with_overlay d-flex rounded-circle"
                                type="button"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                aria-expanded="false"
                              >
                                <div className="unread_count m-auto c-font f-12">+5</div>
                              </div>
                              <UncontrolledGeneralListing list={[1, 2, 3].map((index) => {
                            return {
                                name: "User name",
                                image: 'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
                                id: index,
                            };
                        })} />
                          </DropdownToggle>
                        </UncontrolledDropdown>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="white-border-box p-0 d-flex calendar-input-small">
                                    <a href="#/" className="custom-datepicker datepicker-input w-90 m-auto ms-1 c-font ">
                                      <div className="d-flex align-items-center">
                                        <div className="h20w20"><CalendarBlank size={18} weight="light" className="c-icons" /></div>
                                        <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                selected={startDate}
                                                onChange={(date) => {
                                                  setStartDate(date);
                                                }}
                                                placeholderText={`${_l(
                                                  "l_select_date"
                                                )}`}
                                                calendarStartDay={1}
                                              ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                                        </div>
                                      </a>
                                    </div>
                                    <div className="comman_action_icon with_separator_10">
                                      <div className="white-border-box me-0 color-white-60 action_icon with_bg dropdown d-flex close-toast" type="button"
                                          id="TaskListStatusDropdown"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          data-tip={`${_l("l_task_status")}`}
                                          data-effect="solid"
                                          data-delay-show='1000'
                                          data-class="tooltip-main">
                                        <div className="d-flex align-items-center m-auto">
                                          <span className="c-dots c-10 in-progress-blue rounded-circle"></span>
                                            <TaskListStatusDropdown/>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="dropdown with_separator_10 before-dnone d-flex align-items-center close-toast">
                                    <a href="#/"
                                      className="comman_action_icon w-100 d-flex"
                                      type="button"
                                      id="writeComMentDropdown"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      data-bs-auto-close="outside"
                                      data-tip={_l("l_comments")}
                                      data-effect="solid"
                                      data-delay-show='1000'
                                      data-class="tooltip-main"

                                    >
                                      <div className="d-flex align-items-center w-100">
                                        <div className="action_icon with_bg position-relative on-hover-active-toggle-img light-theme-img">
                                          <ChatCircle size={18} weight="light" className="c-icons" />
                                          <span className={`rounded-pill badge-custom p-0 `}>5</span>
                                      </div>
                                      <span className="c-font f-12 d-flex justify-content-center flex-grow-1 text-on-hover-active-green number-counter-toggle">2</span>
                                    </div>
                                  </a>
                                    </div>
                                    <div className="d-flex align-items-center radius_3 badge-white invoice-amount">
                                      <span className=" color-green c-font f-14 ps-1 fw-semibold text-center title-fonts">€</span>
                                      <input className="bg-transparent m_w50 color-green c-font f-14 fw-semibold text-center title-fonts border-0 "  type="text"
                                        data-tip={`${_l("l_amount")}`}
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        value ="500"
                                        data-class="tooltip-main" />
                                    </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                          <div className="task-assign-section">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="dropdown h20w20  ">
                                <a href="#/" type="button" className="line-height-0 light-theme-img"
                                id="ActiveOfficeOptionsDropdown"
                                data-bs-toggle="dropdown"
                                aria-expanded="false">
                                <DotsThreeVertical size={18} weight="light" className="c-icons" />
                                </a>
                                <DeleteDropdown
                                            edit={false}
                                            handleDelete={() => {}}
                                        />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
      </div>
    </>
  )
}

export default CreditNoteWalletCard