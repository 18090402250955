import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getBenchmarkDetail, setProject } from "../actions/customer";
import { readCollectionFromDB } from "../hooks/indexed_db_helpers";
import { _l, showError } from "../hooks/utilities";
import OnboardingHeader from "../components/SpaceOnBoarding/OnboardingHeader";
import OnboardingFooter from "../components/SpaceOnBoarding/OnboardingFooter";
import { Form } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import BenchmarkServices from "../services/benchmark-services";
import { setOnboardingStepData } from "../actions/onboarding-actions/onboarding-actions";

const CreateDPGFBenchmark = () => {
  const contactRole = localStorage.getItem("contact_role");
  const userType = localStorage.getItem("user_type");

  const [spaceList, setSpaceList] = useState([]);
  const [selectedSpaceList, setSelectedSpaceList] = useState([]);
  const [nextButtonText, setNextButtonText] = useState("l_next");
  const [backButtonText, setBackButtonText] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState("SpaceSelection");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const dashboardBenchmarkId = searchParams.get("dashboard-benchmark-id");

  const setGroupInfo = async (groupId) => {
    let flag = 0;
    try {
      const response = await BenchmarkServices.setBenchmarkGroup(
        selectedSpaceList.filter((w) => w.checked).map((w) => w.project_id),
        groupId
      );
      if (response.status && response.data.group_id > 0) {
        flag = response.data.group_id;
      } else {
        showError(response.message);
      }
    } catch (e) {
      showError("l_something_went_wrong");
      console.error(e);
    } finally {
      return flag;
    }
  };

  const isStep1DataValid = () => {
    let selectedSpaces = spaceList.filter((space) => space.checked);
    if (!selectedSpaces.length) {
      showError("l_please_select_space");
      return false;
    }
    return true;
  };

  const createBenchmark = async (data) => {
    const groupId = await setGroupInfo(dashboardBenchmarkId);

    if (!groupId) {
      return false;
    }

    if (
      dashboardBenchmarkId &&
      !isNaN(Number(dashboardBenchmarkId)) &&
      Number(dashboardBenchmarkId) > 0
    ) {
      navigate(
        `/connect-provider?space=${data.project_id}&provider-type=benchmark&dashboard-benchmark-id=${dashboardBenchmarkId}&id=${data.group_schedule_id}`
      );
    } else {
      searchParams.set("dashboard-benchmark-id", groupId);
      setSearchParams(searchParams);
      navigate(
        `/connect-provider?space=${data.project_id}&provider-type=benchmark&dashboard-benchmark-id=${groupId}`
      );
    }
  };

  const updateSpaceSelection = (e, space) => {
    if (e.target.checked) {
      setSelectedSpaceList(
        selectedSpaceList.concat([{ ...space, checked: true }])
      );
    } else {
      setSelectedSpaceList(
        selectedSpaceList.filter((w) => w.project_id != space.project_id)
      );
    }
    setSpaceList(
      spaceList.map((s) => {
        if (s.id == space.id) {
          s.checked = e.target.checked;
        }
        return s;
      })
    );
  };

  const getGroupInfo = async (id) => {
    try {
      const response = await BenchmarkServices.getBenchmarkGroupInfo(id);
      if (response && response.status && response.data.group_id > 0) {
        let projects = await readCollectionFromDB("spaces", "project_id");

        if (
          response.data.schedules &&
          response.data.schedules.length &&
          projects.length
        ) {
          projects = projects.map((space) => {
            const spaceSchedule = response.data.schedules.find(
              (w) => w.project_id == space.project_id
            );
            if (spaceSchedule) {
              space.checked = true;
              space.is_disabled = spaceSchedule.schedule_id > 0;
              space.group_benchmark_status = spaceSchedule.status;
              space.group_schedule_id = spaceSchedule.schedule_id;
              space.proposal_count = spaceSchedule.proposal_count;
              space.is_opened = spaceSchedule.is_opened;
            }
            return space;
          });
          if(projects.some((w) => w.proposal_count > 0))
          {
            setNextButtonText("");
          }
          else if (response.extra && response.extra.schedule_generate > 0) {
            setNextButtonText("l_generate");
          }
        }
        setSelectedSpaceList(projects);
        setSpaceList(projects);
        setBackButtonText("l_back");
        setCurrentStep("BenchmarkCreation");
      } else {
        showError("l_benchmark_not_found");
        navigate(-1);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const generateBenchmark = async () => {
    try {
      setLoading(true);
      let scheduleIds = spaceList
        .map((x) => x.group_schedule_id)
        .filter((w) => w > 0);
      const response = await BenchmarkServices.generateDashboardBenchmark(
        scheduleIds
      );
      if (response.status) {
        navigate("/dashboard");
      } else {
        showError("l_something_went_wrong");
      }
    } catch (e) {
      showError("l_something_went_wrong");
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getViewBenchmarkText = (benchmark) => {

    let buttonTxt = '';

    if(benchmark.is_opened > 0 && benchmark.proposal_count > 0)
    {
      buttonTxt = "l_view_proposals";
    }
    else if(benchmark.group_schedule_id == 0 || !benchmark.group_schedule_id) {
      buttonTxt = "l_create_benchmark";
    }
    else {
      buttonTxt = "l_view_benchmark";
    }
    return buttonTxt;
  };

  const SpaceCard = ({ space, is_create_benchmark }) => {
    return (
      <div class="task-list-card list-view radius_3 no-hover">
        <div class="d-flex justify-content-between align-items-start  w-100">
          <div class="w-100">
            <div class="d-flex align-items-center justify-content-between res-flex-wrap-wrap ">
              <div className="task-creator-img">
                <div className={`h20w20 d-flex align-items-center me-10px`}>
                  <Form.Check
                    type="checkbox"
                    className="m-auto"
                    checked={space.checked}
                    onChange={(e) => updateSpaceSelection(e, space)}
                    disabled={space.is_disabled || is_create_benchmark || space.group_schedule_id > 0}
                  />
                </div>
              </div>
              <div class="left-premium-title text-truncate w100minus100per flex-grow-1">
                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center ">
                    <div class="title-fonts text-truncate task-list-name ps-0">
                      {space.office_title}
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center c-font f-10 title-fonts pt-1">
                    <div class="d-flex align-items-center">
                      <div
                        class="color-white-60 text-truncate with_separator_10 ps-0 m-w150"
                        data-tip=""
                        data-effect="solid"
                        data-delay-show="1000"
                        data-class="tooltip-main"
                        data-place="bottom"
                      >
                        {space.postal_address}
                      </div>
                      <div class="color-white-60  text-truncate with_separator_10">
                        {_l("l_superficy")} :{" "}
                        <span class="color-white text-truncate">
                          {space.superficies}
                        </span>
                      </div>
                      <div class="color-white-60 text-truncate with_separator_10">
                        {_l("l_desks")} :{" "}
                        <span class="color-white text-truncate">
                          {space.number_of_desk}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {is_create_benchmark ? (
                <div class="right-premium-title">
                  <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center comman_action_icon">
                      {
                        space.proposal_count > 0
                        ?
                        <div
                          className={`badge h32wauto c-font f-12 rounded-pill c-text me-10px text-truncate  done`}
                        >
                          {`${_l("l_proposals")}: ${_l(space.proposal_count)}`}
                        </div>
                        :
                        <React.Fragment></React.Fragment>
                      }
                      <div
                        className={`badge h32wauto c-font f-12 rounded-pill c-text me-10px text-truncate  done`}
                      >
                        {_l(space.group_benchmark_status)}
                      </div>
                      <a
                        href="#/"
                        class="btn btn-white-05 btn-sm-2 me-10px"
                        onClick={() => createBenchmark(space)}
                      >
                        {_l(getViewBenchmarkText(space))}
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const updateBulkSpaceSelection = (e) => {
    let updatedSpaceList = [];

    if (e.target.checked) {
      updatedSpaceList = spaceList.map((space) => {
        space.checked = true;
        return space;
      });
    } else {
      updatedSpaceList = spaceList.map((space) => {
        if (!space.group_schedule_id || space.group_schedule_id <= 0) {
          space.checked = false;
        }
        return space;
      });
    }
    setSpaceList(updatedSpaceList);
    setSelectedSpaceList(updatedSpaceList);
  };

  const Step1JSX = () => {
    return (
      <div>
        <div className="d-flex p-10-15 ps-20pximp task-creator-img align-items-center">
          <div className={`h20w20 d-flex align-items-center me-10px`}>
            <Form.Check
              type="checkbox"
              className="m-auto"
              checked={spaceList.every((space) => space.checked)}
              onChange={updateBulkSpaceSelection}
            />
          </div>
          <Form.Label className="input-label no-asterisk mb-0 ml-0">
            {_l("l_select_all")}
          </Form.Label>
        </div>
        {spaceList.map((space, i) => {
          return <SpaceCard key={i} space={space} />;
        })}
      </div>
    );
  };

  const Step2JSX = () => {
    return spaceList
      .filter((space) => space.checked)
      .map((space, i) => {
        return <SpaceCard key={i} space={space} is_create_benchmark={true} />;
      });
  };

  const getStepJSX = () => {
    switch (currentStep) {
      case "SpaceSelection":
        return Step1JSX();
      default:
        return Step2JSX();
    }
  };

  const handleStepChange = (direction) => {
    if (direction === "next") {
      switch (currentStep) {
        case "SpaceSelection":
          if (isStep1DataValid()) {
            setCurrentStep("BenchmarkCreation");
            setBackButtonText("l_back");
            const selectedSpaces = spaceList.filter((space) => space.checked);
            if(spaceList.some((w) => w.proposal_count > 0))
            {
              setNextButtonText("");
            }
            else if (
              selectedSpaces.length &&
              selectedSpaces.every((space) => space.group_schedule_id > 0)
            ) {
              setNextButtonText("l_generate");
            } else {
              setNextButtonText("");
            }
          }
          break;
        default:
          generateBenchmark();
          break;
      }
    } else {
      switch (currentStep) {
        case "BenchmarkCreation":
          setBackButtonText("");
          setNextButtonText("l_next");
          setCurrentStep("SpaceSelection");
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    let selectedSpace = localStorage.getItem("selectedOffice");

    if (userType !== "contact" || ![1].includes(Number(contactRole))) {
      navigate(-1);
    }

    const getSpaces = async () => {
      try {
        const projects = await readCollectionFromDB("spaces", "project_id");
        if (projects && projects.length) {
          setSpaceList(projects);
        }
      } catch (e) {
        console.error(e);
      }
    };

    localStorage.setItem("selectedOffice", 0);
    dispatch(setProject(0));
    dispatch(
      setOnboardingStepData({
        step: "space-onboarding",
      })
    );
    dispatch(getBenchmarkDetail(0, 0, "all"));

    if (
      dashboardBenchmarkId &&
      !isNaN(Number(dashboardBenchmarkId)) &&
      Number(dashboardBenchmarkId) > 0
    ) {
      getGroupInfo(dashboardBenchmarkId);
    } else {
      getSpaces();
    }

    return () => {
      localStorage.setItem("selectedOffice", selectedSpace);
      dispatch(setProject(selectedSpace));
    };
  }, []);

  return (
    <div
      className={`onboarding-wrapper-main d-flex m-auto h-100 flex-column onboarding-with-bg`}
    >
      <OnboardingHeader
        title={"l_dpgf_benchmark_title"}
        description={"l_dpgf_benchmark_description"}
        nextButtonText={nextButtonText}
        // nextBtnHandler={() => generateBenchmark()}
        nextBtnHandler={() => handleStepChange("next")}
        backButtonText={backButtonText}
        backBtnHandler={() => handleStepChange("back")}
        loadingNextStep={loading}
      />
      {getStepJSX()}
      <OnboardingFooter />
    </div>
  );
};

export default CreateDPGFBenchmark;
