import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import { _l } from "../../../hooks/utilities";
import SubscriptionList from "../../SubscriptionList";
const ClientSubscriptionTab = ({data}) => {
    return (
        <div>
            <SubscriptionList pageName="customer" clientid={data.id}/>
        </div>
    )
}
export default ClientSubscriptionTab;