import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { _l, generateDefaultSignature } from "../../hooks/utilities";
import Form from "react-bootstrap/Form";
import solarpanelsimg from "../../assets/images/solarpanelsimg.svg";
import SignatureCanvas from "react-signature-canvas";
import { ArrowBendDoubleUpLeft, CaretLeft, Eraser } from "phosphor-react";
import { useSelector } from "react-redux";
import customerServices from "../../services/customer-services";

const TaskSignatureModal = ({
  show,
  handleClose,
  step = "installation-complete",
  isExternal = true,
}) => {
  const signatureContent = localStorage.getItem("full_name");
  const selectedTask = useSelector((state) => state.customer.selectedTask);

  const signaturepadRef = useRef();
  const [showSignature, setShowSignature] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");


  const clearSignaturePad = () => {
    if (signaturepadRef.current && signaturepadRef.current.clear) {
      signaturepadRef.current.clear();
    }
  };

  const undoSignature = () => {
    if (signaturepadRef.current && signaturepadRef.current.toData()) {
      let signaturePadData = signaturepadRef.current.toData();
      signaturePadData.pop();
      signaturepadRef.current.fromData(signaturePadData);
    }
  };

  const generateSignature = () => {
    if (signaturepadRef.current) {
      generateDefaultSignature(signaturepadRef, signatureContent);
    }
  };

  const handleSave = () => {
    try {
      customerServices
        .signatureOftask(
          selectedTask.hash,
          selectedTask.id,
          signaturepadRef.current.getTrimmedCanvas().toDataURL("image/png")
        )
        .then((res) => {
          if (res && res.status) {
          } else {
          }
          handleClose();
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="">
        <Modal
          show={show}
          size="lg"
          onHide={handleClose}
          className="custom-modal-style solarcommanmodal h90vh modal-content100per overflow-hidden"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header className="p-3" closeButton>
            <Modal.Title> {"Signer la preuve d'installation"}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            <div className=" registration-content flex-grow-1 mx-auto  h-100 ">
              <div className="align-items-center  d-flex mx-auto flex-column h-100">
                <div className="border radius_3 bg-white-03 comman-white-box-shadow w-100">
                  {/* loop start */}
                  <div className="align-items-center border-bottom border-last-none d-flex flex-wrap p-20 justify-content-between ">
                    <div className="d-flex align-items-center flex-grow-1">
                      {showSignature ? (
                        <a
                          href="#/"
                          className="action_icon align-items-center border d-flex h32wauto gap10px justify-content-center me-20px on-hover-active-toggle-img radius_3 with_bg"
                          onClick={() => {
                            setShowSignature(false);
                          }}
                        >
                          <CaretLeft size={18} weight="light" />
                          <span className="c-font f-12 me-10px">RETOUR</span>
                        </a>
                      ) : (
                        <></>
                      )}
                      <div className="h35w35 comman-round-box with-bg bg-white-05 me-10px">
                        <div
                          className="comman-bg-img h-100 w-100 bg-style-cover"
                          style={{
                            backgroundImage: `url('${solarpanelsimg}')`,
                          }}
                        ></div>
                      </div>
                      <div className="w-100">
                        <div className="c-font f-14 fw-semibold title-fonts d-flex align-items-center  w100minus100">
                          {"MANDAT D’ASSISTANCE ADMINISTRATIVE"}
                        </div>
                      </div>
                    </div>
                    <div className=" d-flex align-items-center comman_action_icon gap-3">
                      {showSignature ? (
                        <></>
                      ) : (
                        <Button
                          variant={"success"}
                          className="btn btn-primary position-relative  d-flex align-items-center"
                          onClick={() => {
                            setShowSignature(true);
                          }}
                        >
                          <span className="">{_l("l_sign_online")}</span>
                        </Button>
                      )}
                    </div>
                  </div>
                  {/* loop end */}
                </div>
                {showSignature ? (
                  <></>
                ) : (
                  <div className="bg-white border h-100 w-100 bordertop0imp position-relative uploadabsimg mb-70">
                    <div>
                      <img
                        src={
                          "https://images.unsplash.com/photo-1719937075949-83b1f1ee3e42?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHwyfHx8ZW58MHx8fHx8"
                        }
                        className="h-100 w-100 object-fit-fill"
                      />
                      {/* <div className="position-absolute top-0 start-0 w-100">              
                      {/* <img src={ContractImg} className="h-100 w-100" /> * /}
                      <iframe src={solarPdf} frameborder="0" width={"100%"} height={"600px"}></iframe>
                      </div> */}
                    </div>
                  </div>
                )}
                {showSignature ? (
                  <div className="bg-white border h-100 w-100 bordertop0imp position-relative uploadabsimg">
                    <div className="position-relative p-20px d-flex flex-column">
                      <Form.Group
                        className={`col-xl-12 c-input-box pb-20px position-relative d-flex flex-column flex-grow-1`}
                      >
                        <Form.Label className="input-label no-asterisk ">
                          {_l("l_signature")}
                        </Form.Label>
                        <SignatureCanvas
                          penColor="black"
                          ref={signaturepadRef}
                          canvasProps={{
                            className:
                              "custom-canvas-style form-control flex-grow-1 p-0 w-100 border",
                          }}
                        />
                      </Form.Group>
                      <div className="col-xl-12 c-input-box pb-20px position-relative">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex">
                            <a
                              href="#/"
                              className="h40w40 d-flex  form-control p-0  me-3"
                              onClick={undoSignature}
                              data-tip={_l("l_undo")}
                              data-delay-show="1000"
                              data-class="tooltip-main"
                            >
                              <ArrowBendDoubleUpLeft
                                size={18}
                                className="c-icons"
                                weight="light"
                              />
                            </a>
                            <a
                              href="#/"
                              className="h40w40 d-flex  form-control p-0"
                              onClick={clearSignaturePad}
                              data-tip={_l("l_clear")}
                              data-effect="solid"
                              data-delay-show="1000"
                              data-class="tooltip-main"
                            >
                              <Eraser
                                size={18}
                                className="c-icons"
                                weight="light"
                              />
                            </a>
                          </div>
                          <Button
                            variant=""
                            className="btn-white-05 fw-semibold"
                            onClick={generateSignature}
                          >
                            {_l("l_signature_by_default")}
                          </Button>
                        </div>
                      </div>
                      <div className="d-flex flex-column py-3">
                        <div className="c-font color-black fw-semibold f-14 mb-10">
                          You have successfully completed the installation
                        </div>
                        <div className="c-font f-10 color-white-50">
                          For a record, please sign on the dialogue box given
                          above
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            <Button variant="secondary" size="sm" onClick={handleClose}>
              {_l("l_close")}
            </Button>
            <Button variant="primary" size="sm" onClick={handleSave}>
              {_l("l_save")}{" "}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default TaskSignatureModal;
