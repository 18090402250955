import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import LogoSolarHub from "../../assets/images/blazlogo.svg";

import { CloudArrowDown, FilePdf } from "phosphor-react";

const RenderCustomDocument = ({ slide }) => {
  if (slide.type === "video") {
    return (
      <video controls>
        <source src={slide.src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  } else if (slide.type === "pdf") {
    return (
      <iframe src={slide.src} width="100%" height="80%" title="PDF Preview" />
    );
  } else {
    return (
      <div className="h-100 w-100" style={{maxHeight:"90%",maxWidth:"90%"}}>
        <div className="h-100 w-100 bg-style-contain" style={{backgroundImage: `url(${slide.src})`}}></div>
      </div>
      // <img src={slide.src} alt="" />
    );
  }
};

const SolarDocumentPreview = ({ slides, open, setOpen }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <>
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={slides}
        index={currentIndex}
        on={{
          view: ({ index: currentIndex }) => setCurrentIndex(currentIndex),
        }}
        render={{
          slide: ({ slide }) => (
            <>
              <div
                className={`header-logo position-absolute top-0 start-0 z-index-2 w-100 bg-black`}
              >
                <div className="d-flex align-items-center justify-content-between position-relative">
                  <div></div>
                  <img src={LogoSolarHub} className="h60px" alt="" />
                  {slide.download_url ? (
                    <a
                      href="#/"
                      className="downloadarrow position-absolute right50 h32w32"
                      onClick={() => window.open(slide.download_url, "_blank")}
                    >
                      <CloudArrowDown
                        size={28}
                        className="c-icons"
                        weight="light"
                      />
                    </a>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  <div></div>
                </div>
              </div>
              <div className="thumbnailimages bg-white">
                <div className="align-items-center d-flex h-100 justify-content-center px-4">
                  {slides.map((slide, index) => {
                    return (
                      <a
                        key={index}
                        href="#/"
                        className={`align-items-center comman-round-box d-flex h75w75 me-10px radius_3 with-bg ${index === currentIndex ? "border" : ""}`}
                        onClick={() => setCurrentIndex(index)}
                      >
                        {slide.type === "pdf"?
                        <FilePdf size={32} className="bg-style-cover comman-bg-img h-100 radius_3 w-100"/>
                        :
                        <div
                          className="bg-style-cover comman-bg-img h-100 radius_3 w-100"
                          style={{ backgroundImage: `url(${slide.src})` }}
                        ></div> }
                      </a>
                    );
                  })}
                </div>
              </div>
              <RenderCustomDocument slide={slide} />
            </>
          ),
          buttonNext: () => null,
          buttonPrev: () => null,
          // slideHeader: <DocumentPreviewerHeader />
        }}
        carousel={{ preload: 1, padding: 0 }}
      />
    </>
  );
};

export default SolarDocumentPreview;
