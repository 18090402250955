import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { _l, getDatePickerLocale, showError, tabindexFix } from "../../hooks/utilities";
import Form from "react-bootstrap/Form";
import { CalendarBlank, ChatCircle, CloudArrowUp, CornersOut, GearSix, Image, Minus, Plus, PushPin, X } from "phosphor-react";
import Select from "react-select";
import HighPriorityActiveIcon from "../../assets/icons/HighPriorityActiveIcon";
import HighPriorityIcon from "../../assets/icons/HighPriorityIcon";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import ImageUploadDropdown from "../Dropdowns/ImageUploadDropdown";
import CommentMinified from "../Comments/CommentMinified";
import equipmentsServices from "../../services/equipments-services";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { showConfirmation, showMessage, toastCloseAction } from "../../actions/messages";
import EditEquipmentsQuantity from './EditEquipmentsQuantity'
import { closeOutsideClick, setEquipmentQuantity, setEquipmentQuantityList, setNewEquipment } from "../../actions/customer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import Autocomplete from "../Dropdowns/Autocomplete";
import $ from "jquery";

const EquipmentModalHTML = ({ show, handleClose,selectedEquipment }) => {
    const dispatch = useDispatch();
    const projectId = localStorage.getItem("selectedOffice")
    const topics = useSelector((state) => state.customer.defaultTopics);
    const quantityList = useSelector((state) => state.customer.set_equipments_quantity_list);
    const set_equipments_quantity = useSelector((state) => state.customer.set_equipments_quantity);
    const newEuipment = useSelector((state) => state.customer.set_new_equipment);
    const { setPOILatLng, getSelectedFloorId, get_equipments_list } = useSelector((state) => state.customer);
    const [openAttachmentsDropdown, setOpenAttachmentsDropdown] = useState(false);
    const [commentsList, setCommentsList] = useState(newEuipment && newEuipment.commentsList ? newEuipment.commentsList : []);
    const [file, setFile] = useState(newEuipment && newEuipment.file ? newEuipment.file : []);
    const [previewArray, setPreviewArray] = useState(newEuipment && newEuipment.previewArray ? newEuipment.previewArray : []);
    const [loder, setLoder] = useState(false);
    const [showQuantity, setShowQuantity] = useState(false);
    const [modalToggleHandler, setModalToggleHandler] = useState(false);
    const [selectedQuantity, setSelectedQuantity] = useState({});
    const [equipmentList, setEquipmentList] = useState([]);
    const [equipmentData, setEquipmentData] = useState(newEuipment || {
        title: "",
        id: "",
        quantity: 1,
        priority: 0,
        file:[],
        defalutLifeTime: new Date(),
        previewArray:[],
        commentsList:[],
        equipmentItems: [],
        provider_assignee: 0,
        contact_assignee: 0
    });
    const [quantityData, setQuantityData] = useState(quantityList || [{
        id:1,
        name: "default quantity",
        attachments: [],
        preview: [],
        lifetime: new Date(),
        web_x: setPOILatLng && setPOILatLng.x,
        web_y: setPOILatLng && setPOILatLng.y,
        web_z: setPOILatLng && setPOILatLng.z,
        device_id:""
    }]);

    let operatorData = topics
        ? topics.find((w) => w.main_key === "my_operator")
        : [];
    operatorData = operatorData && operatorData.categories
        ? operatorData.categories.filter((w) => w.main_sub_key !== "team").map((w) => {
            return { label: w.title, value: w.filter_key };
        })
        : [];

    let contactData = topics
        ? topics.find((w) => w.main_key === "my_contact")
        : [];
    contactData = contactData && contactData.categories
        ? contactData.categories.filter((w) => w.main_sub_key !== "team").map((w) => {
            return { label: w.title, value: w.filter_key };
        })
        : [];

    const handleChange = (name, value) => {
        let qtyArr = quantityData
        if (qtyArr.length == 1 && name == "title") {
            qtyArr[0] = { ...qtyArr[0], name: value+ " "+1 }
        }
        if (qtyArr.length == 1 && name == "defalutLifeTime") {
            qtyArr[0] = { ...qtyArr[0], lifetime : qtyArr[0].lifetime != "" ? qtyArr[0].lifetime : value }
        }
        let Arr = equipmentData
        Arr = {
            ...Arr,
            [name]: value
        }
        setEquipmentData(Arr);
        setQuantityData(qtyArr.slice());
    }
    const handleChangeQuantity = (quinatity, action) => {
        let qtyArr = quantityData
        if (action == "add") {
            qtyArr[0] = { ...qtyArr[0], name: equipmentData.title + " " + 1 }
            qtyArr.push({ name: equipmentData.title + " " + quinatity,id: quinatity, file: [], preview: [], lifetime: equipmentData.defalutLifeTime, device_id:"",web_x:0,web_y:0,web_z:0 })
        } else {
            qtyArr = qtyArr.filter((dd, key) => key != qtyArr.length - 1)
        }
        setQuantityData(qtyArr.slice());
    }

    const handleFileUpload = (files) => {
        if (files) {
            let thumbnails = [];
            let newFiles = [];
            Array.from(files).map((img) => {
                thumbnails.push({ file_name: img.name, image_url: URL.createObjectURL(img) });
                newFiles.push(img);
            });

            if (file && Array.from(file).length) {
                /**Remove duplicate attachments from array */
                newFiles = Array.from(newFiles).filter(
                    (x) => Array.from(file).findIndex((w) => w.name == x.name) === -1
                );
                thumbnails = thumbnails.filter(
                    (x) => previewArray.findIndex((w) => w.file_name == x.file_name) === -1
                );

                setFile(Array.from(file).concat(newFiles));
                setPreviewArray(previewArray.concat(thumbnails));
            } else {
                setFile(Array.from(file).concat(newFiles));
                setPreviewArray(previewArray.concat(thumbnails));
            }
        }
    };
    const handleFileUploadQuantity = (files, key) => {
        if (files) {
            let thumbnails = [];
            let newFiles = [];
            let tempData = quantityData
            Array.from(files).map((img) => {
                thumbnails.push({ file_name: img.name, image_url: URL.createObjectURL(img) });
                newFiles.push(img);
            });

            if (tempData[key].attachments && Array.from(tempData[key].attachments).length) {
                /**Remove duplicate attachments from array */
                newFiles = Array.from(newFiles).filter(
                    (x) => Array.from(tempData[key].attachments).findIndex((w) => w.name == x.name) === -1
                );
                thumbnails = thumbnails.filter(
                    (x) => tempData[key].preview.findIndex((w) => w.file_name == x.file_name) === -1
                );
                tempData[key].attachments = Array.from(tempData[key].attachments).concat(newFiles)
                tempData[key].preview = tempData[key].preview.concat(thumbnails)
                setQuantityData(tempData.slice());
            } else {
                tempData[key].attachments = Array.from(tempData[key].attachments).concat(newFiles)
                tempData[key].preview = tempData[key].preview.concat(thumbnails)
                setQuantityData(tempData.slice());
            }
        }
    };

    const handleAddComment = (comment) => {
        let arrComment = commentsList
        arrComment.push({
            contact_id: "3065",
            content: comment,
            date: new Date(),
            dateadded: new Date(),
            full_name: localStorage.getItem("full_name"),
            id: arrComment.length + 1,
            is_allowed_to_edit: "1",
            profile_url: "https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg",
        })
        setCommentsList(arrComment.slice())
        // setEquipmentData(arrComment);
    }
    useEffect(() => {
        if (quantityData.length == 1 && setPOILatLng) {
            setQuantityData([{...quantityData[0],
                web_x: setPOILatLng.x,
                web_y: setPOILatLng.y,
                web_z: setPOILatLng.z
            }])
        }
    },[JSON.stringify(setPOILatLng)])

    useEffect(() => {
        $("#client_3d_optionfirst").css({ 'display': 'none' });
        $(".dropdown-menu").removeClass("show");
        // if ($('.blank_anchor').length) {
        //   $('.blank_anchor').remove();
        // }
        dispatch(closeOutsideClick(false));
      }, [])

      useEffect(() => {
        if (selectedEquipment && equipmentList.length) {
          setEquipmentData({
            ...equipmentData,
            title:selectedEquipment.equipmentName
          });
          selectedEquipmentsQuantity(selectedEquipment.equipmentId);
        }
        },[selectedEquipment,equipmentList])

    useEffect(() => {
        let element = document.body;
        if (show) {
          if (element) {
            element.classList.remove("modal-open");
          }
        }
        return () => {
          if (element) {
            element.classList.remove("modal-open");
          }
        };
      }, [show]);
    const handleRemoveQuantityFile = (quantityIndex, fileIndex) => {
        if (quantityIndex == 'equipment') {
            // let equipment = equipmentData
            // equipment.file = equipment.file.filter((f,k) => k != fileIndex).slice()
            // equipment.previewArray = equipment.previewArray.filter((f,k) => k != fileIndex).slice()
            // console.log(equipment,"equipment");
            // setEquipmentData(equipment);
            setFile(file.filter((f,k) => k != fileIndex))
            setPreviewArray(previewArray.filter((f,k) => k != fileIndex))
        }else{
            let newData = quantityData
            if (newData[quantityIndex]) {
                newData[quantityIndex].attachments = newData[quantityIndex].file.filter((d, k) => k != fileIndex)
                newData[quantityIndex].preview = newData[quantityIndex].preview.filter((d, k) => k != fileIndex)
            }
            setQuantityData(newData.slice());
        }
    }
    const clearData = (action) => {
        dispatch(setNewEquipment(undefined));
        dispatch(setEquipmentQuantityList(action == "save" ? undefined : [{
        id:1,
        name: "default quantity",
        file: [],
        preview: [],
        lifetime: 0,
        web_x: 0,
        web_y: 0,
        web_z: 0,
        device_id:""
    }]));
        dispatch(setEquipmentQuantity(undefined));
        handleClose();
    }
    const addEquipment = () => {
        if (equipmentData.title == "") {
            showError("l_equipment_name_required");
        } else {
            setLoder(true);
            equipmentsServices.addNewEquipment(projectId, equipmentData.title, { ...equipmentData, file: file }, quantityData, commentsList, getSelectedFloorId).then((res) => {
                setLoder(false);
                if (res.status == 1) {
                    dispatch(showMessage("sucess", _l("l_success"), _l(res.message)));
                    clearData("save")
                } else {
                    showError(res.message);
                }
            })
        }
    }
    useEffect(() => {
        if (quantityData && quantityData[0].name != "default quantity") {
            dispatch(setEquipmentQuantityList(quantityData))
        }
    }, [JSON.stringify(quantityData)])
    useEffect(() => {
        if (equipmentData && equipmentData.title != "") {
            dispatch(setNewEquipment({ ...equipmentData, file: file || [], previewArray: previewArray || [], commentsList: commentsList || [] }))
        }
    }, [JSON.stringify(equipmentData),JSON.stringify(file), JSON.stringify(previewArray), JSON.stringify(commentsList)])

    const handleCloseModal = () => {
        if (equipmentData.title != "" || equipmentData.priority > 0 || equipmentData.provider_assignee > 0 || equipmentData.contact_assignee > 0 || previewArray.length > 0 || file.length > 0 || commentsList.length > 0) {
            dispatch(showConfirmation(_l('l_are_you_sure'), _l('l_you_want_to_close_this_modal'), _l('l_close'), _l('l_cancel'), () => { }, () => { clearData(); dispatch(toastCloseAction()) }));
        }else{
            clearData();
        }
    }
    useEffect(() => {
        if (get_equipments_list && get_equipments_list.length > 0) {
          setEquipmentList(get_equipments_list);
        }
      }, [get_equipments_list])

    const selectedEquipmentsQuantity = (equipmentId) =>{
        let quantityArr = []
        let equipmentsData = equipmentList
        let index = equipmentsData.findIndex((eq) => eq.id == equipmentId);
        if (index > -1) {
            // setEquipmentData({...equipmentData,quantity:equipmentsData[index].quantity})
            setEquipmentData({
                ...equipmentData,
                title: equipmentsData[index].equipment_name,
                id:equipmentsData[index].id,
                quantity:equipmentsData[index].quantity,
                priority: Number(equipmentsData[index].task_priority)
              });
            if(equipmentsData[index].quantity_details && equipmentsData[index].quantity_details.length > 0){
                equipmentsData[index].quantity_details.forEach((ss) => {
                    quantityArr.push({...ss,preview:ss.attachments,name:ss.quantity_name}) 
                })
            }else{
                quantityArr.push({
                    id:1,
                    name: equipmentsData[index].equipment_name + " "+1,
                    attachments: [],
                    preview: [],
                    lifetime: new Date(),
                    web_x: setPOILatLng && setPOILatLng.x,
                    web_y: setPOILatLng && setPOILatLng.y,
                    web_z: setPOILatLng && setPOILatLng.z,
                    device_id:""
                })
            }
        }
        setQuantityData(quantityArr.slice());
    }

    const getSpaceOptionList = () => {
        let equipmentdOptions = equipmentList
          .map((space) => {
            return {
              label: space.equipment_name,
              value: space.id,
            };
          });
        equipmentdOptions = equipmentdOptions.filter(
          (obj, index, self) =>
            index === self.findIndex((o) => obj.label == o.label)
        );
        return equipmentdOptions;
      };

    return (
        <>
            <Modal
                show={show}
                onHide={handleCloseModal}
                className={`custom-modal-style modal-style-modified`}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={false}
                keyboard={false}
                size="lg"
                onShow={() => {
                    tabindexFix();
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="flex-grow-1">
                        {_l("l_add_equipment")}
                    </Modal.Title>
                    {/* {
                        modalToggleHandler ? 
                        <a href="#/" className="with_separator_10" onClick={()=>{setModalToggleHandler(false);}}><CornersOut size={20}  weight="light" className="c-icons" /> </a>
                        : <></>
                    } */}
                </Modal.Header>
                <Modal.Body className={`overflow-hiiden-auto with-custom-height`}>
                    <div className="d-flex align-items-center">
                        {/* <div className="h32w32 comman-round-box with-bg rounded-circle bg-white-05">
                        <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{
                            backgroundImage: `url('https://source.unsplash.com/random')`,
                        }}></div>
                    </div> */}
                    <div className="with_separator_10 flex-grow-1 ps-0 c-input-box position-relative border-bottom">
                          <Autocomplete
                            inputClass="bg-transparent border-0"
                            dropdownClass="max-height-20vh"
                            value={equipmentData.title}
                            options={getSpaceOptionList()}
                            onChange={(e) => {
                                handleChange("title", e.target.value)
                            }}
                            onSelect={(value) => {
                                
                              selectedEquipmentsQuantity(value.value);
                            }}
                          />
                        </div>
                        {/* <div className="w100minus35 ps-3">
                            <Form.Group className="c-input-box position-relative border-bottom">
                                <Form.Control
                                    type="text"
                                    placeholder={`${_l("l_equipment_name")}`}
                                    value={equipmentData.title}
                                    className="bg-transparent border-0 ps-0"
                                    onChange={(e) => handleChange("title", e.target.value)}
                                />
                            </Form.Group>
                        </div> */}
                    </div>
                    <div className="radius_5 w-100 c-box-shadow-box my-3 task-list-card  w-100 p-4 border-0 no-hover">
                        <div className="align-items-center d-flex justify-content-center">
                            <div className={`w-100 align-items-center d-flex justify-content-between flex-wrap`}>
                                <div className="align-items-center d-flex justify-content-between">
                                    <div className="text-center d-flex flex-column w-33 pe-3">
                                        <div className="w-100 mx-auto py-1">
                                            <div className="d-flex align-items-center justify-content-between form-control bg-transparent p-2 comman_action_icon">
                                                <a href="#/" className={`c-font f-22 line-height-0`}
                                                    onClick={() => {
                                                        if (equipmentData.quantity > 1 && equipmentData.title != "") {
                                                            handleChange("quantity", Number(equipmentData.quantity) - 1)
                                                            handleChangeQuantity(Number(equipmentData.quantity) - 1, "minus")
                                                        }
                                                    }}
                                                >
                                                    <Minus size={18} className="c-icons" weight="light" />
                                                </a>
                                                <input
                                                    type="number"
                                                    value={equipmentData.quantity}
                                                    className="bg-transparent border-0 text-center line-height-1"
                                                />
                                                <a href="#/" className="c-font f-22 line-height-0"
                                                    onClick={() => {
                                                        if (equipmentData.quantity > 0 && equipmentData.title != "") {
                                                            handleChange("quantity", Number(equipmentData.quantity) + 1)
                                                            handleChangeQuantity(Number(equipmentData.quantity) + 1, "add")
                                                        }
                                                    }}
                                                >
                                                    <div className="action_icon h32w32">
                                                    <Plus size={18} className="c-icons" weight="light" />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center d-flex flex-column w-33 pe-3">
                                        <div className="w-100 mx-auto py-1"
                                        data-bs-toggle="tooltip"
                                        data-tip={_l("l_manager_assignee")}
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main">
                                            <div className="d-flex align-items-center justify-content-between form-control bg-transparent p-0 border-0">
                                                <Select
                                                    placeholder={_l("l_manager_assignee")}
                                                    className="custom-select-menu drop-up drop-right min-h-33 w-200 text-truncate with-filter-select bg-white-05 m-0 min-w-100imp"
                                                    classNamePrefix="react-select"
                                                    options={contactData}
                                                    onChange={(e) => handleChange("contact_assignee", e.value)}
                                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                    menuPortalTarget={document.body}
                                                    value={contactData.find(
                                                        (w) => w.value == equipmentData.contact_assignee
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center d-flex flex-column w-33 pe-3">
                                        <div className="w-100 mx-auto py-1"
                                        data-bs-toggle="tooltip"
                                        data-tip={_l("l_provider_assignee")}
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main"
                                        >
                                            <div className="d-flex align-items-center justify-content-between form-control bg-transparent p-0 border-0">
                                                <Select
                                                    placeholder={_l("l_provider_assignee")}
                                                    className="custom-select-menu custom-select-menu drop-up drop-right min-h-33 w-200 text-truncate with-filter-select bg-white-05 m-0 min-w-100imp text-start"
                                                    classNamePrefix="react-select"
                                                    options={operatorData}
                                                    onChange={(e) => handleChange("provider_assignee", e.value)}
                                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                    menuPortalTarget={document.body}
                                                    value={operatorData.find(
                                                        (w) => w.value == equipmentData.provider_assignee
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`align-items-center d-flex justify-content-between mt-2`}>
                                    <div className=" custom-datepicker w-330px withinputabsolute me-3" data-bs-toggle="tooltip"
                                        data-tip={equipmentData.defalutLifeTime ? moment(equipmentData.defalutLifeTime).format("DD/MM/yyyy") : _l("l_defalutLifeTime")}
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main">
                                        <div className="comman_action_icon h32w32 position-relative ">
                                            <div className="action_icon with_bg on-hover-active-toggle-img light-theme-img h32w32">
                                                <CalendarBlank
                                                    size={18}
                                                    weight="light"
                                                    className="c-icons"
                                                />
                                            </div>
                                            <DatePicker
                                                selected={equipmentData.defalutLifeTime}
                                                onChange={(date) => handleChange("defalutLifeTime", date)}
                                                disabledKeyboardNavigation
                                                placeholderText={_l('l_select_start_date')}
                                                locale={getDatePickerLocale()}
                                                dateFormat="dd/MM/yyyy"
                                                portalId="custom_datepicker"
                                                calendarClassName="custom-datepicker pt-0"
                                                selectsStart
                                                defaultMenuIsOpen
                                                calendarStartDay={1}
                                            />
                                        </div>
                                    </div>
                                    <div className="h32w32 comman_action_icon me-3">
                                        <a href="#/" className="priority-checkbox action_icon with_bg h-100 w-100"
                                            data-tip={_l("l_priority")
                                            }
                                            data-effect="solid"
                                            data-delay-show='1000'
                                            data-class="tooltip-main">
                                            <input
                                                type="checkbox"
                                                id="priority"
                                                className="absolute-input"
                                                checked={equipmentData.priority == 1}
                                                onChange={(e) => handleChange("priority", e.target.checked ? 1 : 0)}
                                            />
                                            <div className="check_box">
                                                <HighPriorityActiveIcon className="HW18 check_span_img active" />
                                                <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                                            </div>
                                        </a>
                                    </div>
                                    <div className=" dropdown-center dropdown d-flex align-items-center me-3">
                                        <UncontrolledDropdown className="w-100">
                                            <DropdownToggle data-toggle="dropdown" tag="span" className=" comman_action_icon w-100 d-flex close-toast border-0">
                                                <a href="#/"
                                                    data-tip={_l("l_attachments")}
                                                    data-effect="solid"
                                                    data-delay-show='1000'
                                                    data-class="tooltip-main"
                                                    onClick={(e) => {
                                                        // if (parseInt(task.request_task_id) == 0) {
                                                        setOpenAttachmentsDropdown(true);
                                                        // }
                                                    }}
                                                >
                                                    <div className="d-flex align-items-center w-100">
                                                        <div className="action_icon with_bg on-hover-active-toggle-img light-theme-img h32w32">
                                                            <Image
                                                                size={18}
                                                                weight="light"
                                                                className="c-icons"
                                                            />
                                                        </div>
                                                        {file.length > 0 ?
                                                            <span className="c-font f-12 d-flex justify-content-center flex-grow-1 text-on-hover-active-green number-counter-toggle">
                                                                {file.length}
                                                            </span>
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </a>
                                            </DropdownToggle>
                                            <ImageUploadDropdown
                                                setOpenAttachmentsDropdown={
                                                    setOpenAttachmentsDropdown
                                                }
                                                openAttachmentsDropdown={
                                                    openAttachmentsDropdown
                                                }
                                                handleFileUpload={handleFileUpload}
                                                handleRemoveQuantityFile={handleRemoveQuantityFile}
                                                previewArray={previewArray}
                                                task_id={""}
                                                project_id={""}
                                                updateImageCount={""}
                                                pageName="equipment"
                                                taskDetails={""}
                                                unContolList={true}
                                                from="addequipments"
                                            />
                                        </UncontrolledDropdown>
                                    </div>
                                    <div className={`dropdown-center dropdown d-flex align-items-center me-3`}>
                                        <UncontrolledDropdown className="w-100">
                                            <DropdownToggle data-toggle="dropdown" tag="span" className="comman_action_icon d-flex border-0">
                                                <a href="#/"
                                                    // className="comman_action_icon w-100 d-flex"
                                                    // type="button"
                                                    // id="writeComMentDropdown"
                                                    // data-bs-toggle="dropdown"
                                                    // aria-expanded="false"
                                                    data-bs-auto-close="false"
                                                    data-tip={_l("l_comments")}
                                                    data-effect="solid"
                                                    data-delay-show='1000'
                                                    data-class="tooltip-main"
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <div className="action_icon with_bg position-relative on-hover-active-toggle-img light-theme-img h32w32">
                                                            <ChatCircle
                                                                size={16}
                                                                weight="light"
                                                                className="c-icons"
                                                            />
                                                        </div>
                                                        {commentsList.length > 0 ? commentsList.length : ''}
                                                    </div>
                                                </a>
                                            </DropdownToggle>
                                            <DropdownItem toggle={false} className="p-0">
                                                {
                                                    <CommentMinified
                                                        comments={commentsList}
                                                        handleAddComment={handleAddComment}
                                                        deleteComment={""}
                                                        id={""}
                                                        task={""}
                                                        loading={""}
                                                        from={"addequipments"}
                                                        handleImagePreview={""}
                                                        unContolList={true}
                                                        showComments={true}
                                                    />
                                                }
                                            </DropdownItem>

                                        </UncontrolledDropdown>
                                    </div>
                                    <div className="h32w32 comman_action_icon">
                                        <a href="#/" className=" action_icon with_bg h-100 w-100 d-flex">
                                            <GearSix size={18} weight="light" className="c-icons" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ReactTooltip />
                    </div>
                    <div className="list-view-toggle position-relative">
                    {/* {set_equipments_quantity !=undefined?
                    <div className={`modal-overlay1 withzindex position-relative`}></div>:<></>} */}
                    {quantityData.length > 0 && quantityData.map((item, key) => {
                        return (
                            <div className={`task-list-card  w-100 mt-0 no-hover ${set_equipments_quantity && set_equipments_quantity.id == item.id ? "active z-index-9" : ""}`}>
                                <div className="align-content-center d-flex p-15 title-fonts">
                                    <div className="d-flex align-items-center justify-content-between w-100 line-height-1">
                                        <div className="text-truncate flex-grow-1"
                                        data-tip={item.name}
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main"
                                        >
                                            <div className="c-font f-14 fw-semibold text-truncate title-fonts pt-2">
                                                {item.name}
                                            </div>
                                        </div>
                                        <div className="text-center d-flex align-items-center c-font f-10 ">
                                            <a href="#/" className="bg-white-03 d-flex flex-column h60w60 justify-content-center ms-3"
                                                onClick={() => {
                                                    setShowQuantity(true)
                                                    setSelectedQuantity({ ...item, index: key })
                                                }}
                                            >
                                                <GearSix size={18} weight="light" className="c-icons my-0" />
                                                <span className="mt-1 mx-auto opacity-50">{_l("l_setting")}</span>
                                            </a>
                                            <a href="#/" className="bg-white-03 d-flex flex-column h60w60 w-auto justify-content-center px-3 ms-3 position-relative">
                                                <input type="file" class="absolute-input" multiple onChange={(e) => handleFileUploadQuantity(e.target.files, key)} />
                                                <CloudArrowUp size={18} weight="light" className="c-icons my-0" />
                                                <span className="mt-1 mx-auto opacity-50">{_l("l_upload_image")}</span>
                                            </a>
                                            <a href="#/" className="bg-white-03 d-flex flex-column h60w60 w-auto  justify-content-center px-3 ms-3"
                                            onClick={() => {
                                                dispatch(setEquipmentQuantity(item))
                                                setModalToggleHandler(true);
                                            }}
                                            >
                                                <PushPin size={18} weight={item.web_x != 0 || item.web_y != 0 || item.web_z != 0 ? "fill" : "light"} className="c-icons my-0" />
                                                <span className="mt-1 mx-auto opacity-50">Place on space</span>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                                {item.preview && item.preview.length > 0 ? 
                                <div className="upload-image-preview p-2">
                                    <div className="d-flex align-items-center justify-content-start">
                                        {
                                            item.preview.map((attachment, index) => {
                                                return <div className="comman-image-box h60w60 radius_3 upload-image with-margin mt-2">
                                                    <div className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 " style={{
                                                        backgroundImage: `url('${attachment.image_url}')`,
                                                    }}>

                                                    </div>
                                                    <a href="#/" className="upload-close"
                                                        onClick={() => handleRemoveQuantityFile(key, index)}
                                                    >
                                                        <X size={14} weight="light" className="c-icons" />
                                                    </a>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                :<></>}
                            </div>
                        )
                    })}
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end">
                    <Button variant="secondary" size="sm" onClick={handleCloseModal}>
                        {_l('l_close')}
                    </Button>
                    <Button variant="primary" disabled={loder} size="sm" onClick={() => addEquipment()}>
                        {loder ? _l('l_please_wait') : _l('l_add_equipment')}
                        {loder ? (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                className={`ms-2 `}
                                aria-hidden="true"
                            />
                        ) : (
                            <></>
                        )}</Button>
                </Modal.Footer>
            </Modal>
            {showQuantity ?
                <EditEquipmentsQuantity
                    show={showQuantity}
                    handleClose={() => setShowQuantity(false)}
                    data={selectedQuantity}
                    setData={setQuantityData}
                    quantityData={quantityData}
                />
                :
                <></>
            }
        </>
    );
};

export default EquipmentModalHTML;
