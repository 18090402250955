import React from 'react';
// import CSS from '../css/icon_main.css';

function PencilSimpleLineIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"  className={`comman-icons ${CommanHW}`}>
<path d="M13.8906 13.4999H6.39063M6.39063 13.4999L3.39063 13.4999C3.25802 13.4999 3.13084 13.4473 3.03707 13.3535C2.94331 13.2597 2.89063 13.1326 2.89063 12.9999V10.2062C2.8904 10.1413 2.90298 10.0769 2.92765 10.0169C2.95231 9.95682 2.98858 9.90222 3.03438 9.85619L10.5344 2.3562C10.5809 2.30895 10.6364 2.27144 10.6975 2.24583C10.7587 2.22022 10.8243 2.20703 10.8906 2.20703C10.9569 2.20703 11.0226 2.22022 11.0837 2.24583C11.1449 2.27144 11.2004 2.30895 11.2469 2.3562L14.0344 5.1437C14.0816 5.19022 14.1191 5.24568 14.1447 5.30684C14.1704 5.368 14.1835 5.43364 14.1835 5.49994C14.1835 5.56625 14.1704 5.63189 14.1447 5.69305C14.1191 5.75421 14.0816 5.80967 14.0344 5.85619L6.39063 13.4999ZM8.89063 3.99995L12.3906 7.49995"/>
</svg>



		</React.Fragment>
	)
}

export default PencilSimpleLineIcon