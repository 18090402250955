import React from 'react';
// import CSS from '../css/icon_main.css';

function ImageMapPinIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

			<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<path d="M22.25 9H27C27.5523 9 28 9.44772 28 10V28C28 28.5523 27.5523 29 27 29H5C4.44772 29 4 28.5523 4 28V10C4 9.44772 4.44772 9 5 9H6.75" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M4 24.7063L13.2159 19.3048C13.3193 19.2082 13.4427 19.1316 13.5787 19.0793C13.7147 19.0269 13.8607 19 14.0082 19C14.1556 19 14.3016 19.0269 14.4377 19.0793C14.5737 19.1316 14.697 19.2082 14.8005 19.3048L21 25" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M20 21.8531L24.3369 19.1524C24.3856 19.1041 24.4436 19.0658 24.5076 19.0396C24.5716 19.0135 24.6403 19 24.7097 19C24.7791 19 24.8478 19.0135 24.9118 19.0396C24.9758 19.0658 25.0339 19.1041 25.0826 19.1524L28 22" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M14.5002 9.99994C15.6048 9.99994 16.5002 9.10451 16.5002 7.99994C16.5002 6.89537 15.6048 5.99994 14.5002 5.99994C13.3957 5.99994 12.5002 6.89537 12.5002 7.99994C12.5002 9.10451 13.3957 9.99994 14.5002 9.99994Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M19.5 8C19.5 12.5 14.5 16 14.5 16C14.5 16 9.5 12.5 9.5 8C9.5 6.67392 10.0268 5.40215 10.9645 4.46447C11.9021 3.52678 13.1739 3 14.5 3C15.8261 3 17.0979 3.52678 18.0355 4.46447C18.9732 5.40215 19.5 6.67392 19.5 8V8Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
			</svg>



		</React.Fragment>
	)
}

export default ImageMapPinIcon