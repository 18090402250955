import React from 'react'
const ChatBoxSkeleton = () => {
    return (
        <>
            <div className='chat-box d-flex skeleton-bg skeleton-main SkeletonMain mb-10px justify-content-evenly'>
                <div className='h32w32 rounded-5 comman-image-box skeleton-animation'></div>
                <div className='chat-msg d-flex w-75'>
                    <div className='chat-text-box flex-grow-1'>
                        <div className='skeleton-animation radius_3 mb-2' style={{ width: '100%', height: '12px' }}></div>
                        <div className='skeleton-animation radius_3 mb-2' style={{ width: '80%', height: '12px' }}></div>
                        <div className='skeleton-animation radius_3 mb-2' style={{ width: '55%', height: '12px' }}></div>
                    </div>
                </div>
            </div>
            <div className='chat-box d-flex right-side-msg skeleton-bg justify-content-evenly'>
                <div className='chat-msg d-flex w-75'>
                    <div className='chat-text-box flex-grow-1'>
                    <div className='skeleton-animation radius_3 mb-2 ms-auto' style={{ width: '100%', height: '12px' }}></div>
                        <div className='skeleton-animation radius_3 mb-2 ms-auto' style={{ width: '80%', height: '12px' }}></div>
                        <div className='skeleton-animation radius_3 mb-2 ms-auto' style={{ width: '55%', height: '12px' }}></div>
                    </div>
                </div>
                <div className='h32w32 rounded-5 comman-image-box skeleton-animation'></div>
            </div>
        </>
    )
}
export default ChatBoxSkeleton
