import React from 'react';
// import CSS from '../css/icon_main.css';

function HolidayIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<g clipPath="url(#clip0_15203_255287)">
<g clipPath="url(#clip1_15203_255287)">
<path d="M9.08197 2L8.88525 2.95238M8.88525 2.95238C10.5902 3.20635 14 4.81905 14 9.2381C13.4754 8.4127 11.9934 7.18095 10.2623 8.85714M8.88525 2.95238C4.32131 2.49524 2.39344 5.55556 2 7.14286C3.73115 5.92381 4.95082 7.33333 5.34426 8.09524M8.88525 2.95238C8.16393 2.88889 6.4459 3.98095 5.34426 8.09524M8.88525 2.95238C9.60656 3.14286 10.8918 4.59048 10.2623 8.85714M10.2623 8.85714C9.9878 8.1737 9.29588 7.25865 8.17813 7.05707M5.34426 8.09524C6.52459 6.95238 7.42677 6.92157 8.17813 7.05707M8.17813 7.05707L6.97995 13.1613M11.8361 14C10.8711 13.6406 9.05736 13.1999 6.97995 13.1613M2 14C3.6392 13.3387 5.38709 13.1318 6.97995 13.1613"  strokeLinecap="round" strokeLinejoin="round"/>
</g>
</g>
<defs>
<clipPath id="clip0_15203_255287">
<rect width="16" height="16" />
</clipPath>
<clipPath id="clip1_15203_255287">
<rect width="16" height="16" />
</clipPath>
</defs>
</svg>


		</React.Fragment>
	)
}

export default HolidayIcon