import React, { useState } from "react";
import AddClient from "../Modals/AddClient";
import { LeftPanelResponsiveToggle, _l, showError } from "../../hooks/utilities";
import { useDispatch } from "react-redux";
import CustomerServices from "../../services/customer-services";
import { showConfirmation, toastCloseAction, showMessage } from "../../actions/messages";
import ReactTooltip from 'react-tooltip';
import store from "../../store";
import { Trash , PencilLine } from "phosphor-react";
import { clientTabData, setProject } from "../../actions/customer";
import InviteCustomer from "./InviteCustomer";
import { useNavigate } from "react-router-dom";
import documentsServices from "../../services/documents-services";

const PremiumSpaceCard = ({ space, handlerDeleteOrCancelSpace, estimateId, handleClient, premiumPlanInfo, hideShow }) => {
  const [showAddClient, setShowAddClient] = useState(false);
  const [showInviteCustomerForm, setShowInviteCustomerForm] = useState(false);
  const [showInviteCustomerButton, setShowInviteCustomerButton] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const prepareToast = (flag = '') => {
    let buttonText = flag == 'delete' ? _l("l_delete") : _l("l_cancel") + " " + _l("l_space")
    dispatch(showConfirmation("Confirm", _l("l_are_you_sure_you_want_to") + flag + _l("l_space") + "?", buttonText, "Cancel", undefined, () => { deleteOrCancelSpace(space.id, space.project_id, flag) }));
  }

  const deleteOrCancelSpace = (id, project_id, flag) => {
    CustomerServices.deleteOrCancelSpace(id, project_id, estimateId, flag == 'delete' ? 3 : 4).then((res) => {
      if (res.status == 1) {
        handlerDeleteOrCancelSpace(id, flag);
        store.dispatch(toastCloseAction());
        dispatch(showMessage("sucess", _l('l_success'), res.message ? res.message : _l('l_space') + flag + _l('l_successfully')));
      } else {
        dispatch(showMessage("unsucess", _l('l_error'), res.message ? res.message : _l('l_something_went_wrong')));
      }
    });
  }

  const handleInviteCustomer = (customerData) => {
    setShowInviteCustomerForm(false);
    if(customerData)
    {
      let spaces = JSON.parse(localStorage.getItem('spaces'));
      let currentSpaceIndex = spaces.findIndex(w => w.project_id == space.project_id);
      if(currentSpaceIndex > -1)
      {
        spaces[currentSpaceIndex].client_id = customerData.clientId;
        spaces[currentSpaceIndex].contact_id = customerData.contactId;
        localStorage.setItem('spaces', JSON.stringify(spaces));
        setShowInviteCustomerButton(false);
        dispatch(showMessage("sucess", _l('l_success'), _l('l_invite_sent')));
      }
    }
  }

  const premiumSpace = premiumPlanInfo && premiumPlanInfo.length ? premiumPlanInfo.find((w) => w.office_id == space.id) : {};
  const cardOnClickHandler = async () => {
    let responseEstimateId = await documentsServices.getPremiumEstimateDetail(space.project_id);
    if (responseEstimateId && responseEstimateId.status == 1) {
        hideShow();
        LeftPanelResponsiveToggle();
    } else {
      showError(responseEstimateId.message);
    }
    
  };

  return (
    <>
      <div className="task-list-card list-view radius_3 no-hover cursor-pointer"
          onClick={(e) => {cardOnClickHandler()}}
      >
        <div className="d-flex justify-content-between align-items-start  w-100">
          <div class="w-100">
            <div class="d-flex align-items-center justify-content-between res-flex-wrap-wrap ">
              <div class="left-premium-title text-truncate w100minus100per flex-grow-1">
                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center ">
                    <div class="title-fonts text-truncate task-list-name ps-0">
                      {space.office_title}
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center c-font f-10 title-fonts">
                    <div className="d-flex align-items-center lh-sm">
                      <div
                        class="color-white-60 text-truncate with_separator_10 ps-0 m-w150"
                        data-tip={space.postal_address ? space.postal_address : ""}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                        data-place="bottom"
                      >
                        {space.postal_address ? space.postal_address : ""}
                      </div>
                      <div class="color-white-60  text-truncate with_separator_10">
                        {_l("l_superficy")} :{" "}
                        <span class="color-white text-truncate">
                          {space.superficies ? space.superficies : ""}m²
                        </span>
                      </div>
                      <div class="color-white-60 text-truncate with_separator_10">
                        {_l("l_desks")} :{" "}
                        <span class="color-white text-truncate">
                          {" "}
                          {space.number_of_desk ? space.number_of_desk : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="right-premium-title">
                <div className="d-flex align-items-center">
                  <div class="d-flex align-items-center comman_action_icon">
                    <div
                      class={`badge h32wauto c-font f-12 rounded-pill c-text  text-truncate me-10px  ${space.status != 0 ? "done" : "text-warning"
                        }`}
                    >
                      { space.estimate_detail && space.estimate_detail.status != 4 && space.estimate_detail.status_text 
                          ? _l(space.estimate_detail.status_text)
                          : space.status != 1 
                            ? space.onboarding_days_count > 0
                              ? `${space.onboarding_days_count} days`
                              : _l("l_expired")
                            :(space.status == 1 ? _l("l_active") : "")
                      }
                    </div>
                    { space.estimate_detail && space.estimate_detail.status != 4 && space.estimate_detail.estimate_name && space.estimate_detail.estimate_name != "" ? 
                        <div
                          class={`badge h32wauto  c-font f-12 rounded-pill c-text  text-truncate me-10px`}
                          data-tip={_l("l_estimate_number")}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                        >
                        {space.estimate_detail.estimate_name}
                      </div>
                    : space.estimate_number && space.estimate_number != "" && (
                      <div
                        class={`badge h32wauto  c-font f-12 rounded-pill c-text  text-truncate me-10px`}
                        data-tip={_l("l_estimate_number")}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                      >
                        {space.estimate_number}
                      </div>
                    )
                    }
                    <a href="#/"
                      class={`btn btn-white-05 btn-sm-2 me-10px`}
                      onClick={() => {
                        dispatch(setProject(space.project_id));
                        localStorage.setItem("selectedOffice", space.project_id);
                        navigate(`/explore-features?space=${space.project_id}`);
                      }}
                    >
                      {_l("l_explore_feature")}{" "}
                    </a>
                    <a href="#/"
                      class={`action_icon h32w32 with_bg ${space.status == 0 ? "" : "d-none"
                        }`}
                      onClick={() => {
                        if (space.status == 0) {
                          prepareToast("delete");
                        }
                      }}
                      data-tip={`${_l("l_delete")}`}
                      data-effect="solid"
                      data-delay-show='1000'
                      data-class="tooltip-main"
                    >
                      <Trash size={14} weight="light" className="c-icons" />
                    </a>
                  </div>
                  {/* <div className="w-140 text-end color-green title-fonts"><span>{premiumSpace ? premiumSpace.rate : ""}</span></div> */}
                  <div className="w-140 text-end color-green title-fonts">
                    <span>
                      {space.estimate_detail && space.estimate_detail.subtotal && space.estimate_detail.subtotal != "" ? space.estimate_detail.subtotal : premiumSpace ? premiumSpace.rate : ""}
                    </span>
                  </div> 
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <ReactTooltip />
      {showInviteCustomerForm ? (
        <InviteCustomer
          show={showInviteCustomerForm}
          handleInviteCustomer={handleInviteCustomer}
          projectId={space.project_id ? space.project_id : 0}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {showAddClient ? (
        <AddClient
          handleClose={() => {
            setShowAddClient(false);
          }}
          selectedRecord={""}
          show={showAddClient}
          projectId={space.project_id ? space.project_id : 0}
          handleClient={handleClient}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default PremiumSpaceCard;
