import React from 'react';
// import CSS from '../css/icon_main.css';

function FadersHorizontalIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<g clip-path="url(#clip0_15822_264372)">
					<g clip-path="url(#clip1_15822_264372)">
						<path d="M8.5 10.75H2.5" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M13.5 10.75H10.5" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M4.5 5.25H2.5" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M13.5 5.25H6.5" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M6.5 3.75V6.75" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M10.5 12.25V9.25" strokeLinecap="round" strokeLinejoin="round" />
					</g>
				</g>
				<defs>
					<clipPath id="clip0_15822_264372">
						<rect width="16" height="16" />
					</clipPath>
					<clipPath id="clip1_15822_264372">
						<rect width="16" height="16" />
					</clipPath>
				</defs>
			</svg>



		</React.Fragment>
	)
}

export default FadersHorizontalIcon