import React from 'react';
// import CSS from '../css/icon_main.css';

function ArrowSquareUpIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"  className={`comman-icons ${CommanHW}`}>
<g clipPath="url(#clip0_15362_259074)">
<g clipPath="url(#clip1_15362_259074)">
<path d="M13 2.5H3C2.72386 2.5 2.5 2.72386 2.5 3V13C2.5 13.2761 2.72386 13.5 3 13.5H13C13.2761 13.5 13.5 13.2761 13.5 13V3C13.5 2.72386 13.2761 2.5 13 2.5Z"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.88126 7.61875L8.00001 5.5L10.1188 7.61875"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8 10.5V5.5"  strokeLinecap="round" strokeLinejoin="round"/>
</g>
</g>
<defs>
<clipPath id="clip0_15362_259074">
<rect width="16" height="16" />
</clipPath>
<clipPath id="clip1_15362_259074">
<rect width="16" height="16" />
</clipPath>
</defs>
</svg>



		</React.Fragment>
	)
}

export default ArrowSquareUpIcon