import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { _l } from "../../../hooks/utilities";
import Select from "react-select";
import React, { useEffect, useRef, useState } from "react";

 const Address = ({ projectId, clientId, location, zoomLevel }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  const [startDate, setStartDate] = useState(new Date());
  const numberlisting = [
    {
      value: "1",
      label: _l('l_india'),
    },
    {
      value: "2",
      label: _l('l_france'),
    },
    {
      value: "3",
      label: _l('l_american'),
    },
    {
      value: "4",
      label: _l('l_german'),
    },
    {
      value: "5",
      label: _l('l_australia'),
    },
  ];
 return (
    <div>
        <div className="row">
            <Form.Group className="col-12 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                {_l("l_country")}
                </Form.Label>
                <Select
                  className="custom-select-menu"
                  classNamePrefix="react-select"
                  options={numberlisting}
                  closeMenuOnSelect={false}
                  placeholder={`${_l("l_country_placeholder")}`}
                />
            </Form.Group>
            <Form.Group className="col-12 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                {_l("l_address_line")}
                </Form.Label>
                <textarea placeholder="Street Address" className="form-control"/>
            </Form.Group>
            <Form.Group className="col-12 c-input-box position-relative mb-3">
                <div className="color-white-60 c-font f-12 ">
                {_l("l_address_line_placeholder")}
                </div>
            </Form.Group>
        </div>
    </div>
  );
 };
 export default Address;