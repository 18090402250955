import React from 'react';
// import CSS from '../css/icon_main.css';

function DocumentplaceholderIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="61" height="80" viewBox="0 0 61 80" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<path d="M60.2136 47.2847C60.2136 55.2272 60.2136 63.1677 60.2136 71.1062C60.2136 76.389 57.5752 79.0001 52.2619 79.0001H8.71176C3.72948 79.0001 1.00304 76.3101 1 71.3308C1 50.5435 1 29.7561 1 8.9688C1 3.97437 3.73251 1.27222 8.6814 1.26919C18.7006 1.26919 28.7198 1.29042 38.7239 1.24487C39.3484 1.22287 39.9702 1.33631 40.5467 1.57739C41.1232 1.81848 41.6407 2.18151 42.0636 2.64152C47.646 8.28467 53.2608 13.8995 58.908 19.4859C59.3466 19.8977 59.6914 20.399 59.9187 20.956C60.1461 21.5129 60.2508 22.1123 60.2257 22.7133C60.1953 30.8987 60.2136 39.0932 60.2136 47.2847Z" strokeMiterlimit="10" />
				<path d="M37.4305 5.81738H8.83328C6.26168 5.81738 5.56641 6.48534 5.56641 9.01443C5.56641 29.7512 5.56641 50.4879 5.56641 71.2247C5.56641 73.7568 6.26472 74.4277 8.83024 74.4277H52.3774C54.9915 74.4277 55.6443 73.7628 55.6443 71.088C55.6443 55.9661 55.6443 40.8432 55.6443 25.7192C55.6443 25.1909 55.5866 24.6596 55.5532 24.0433H45.534C39.9566 24.0433 37.4214 21.4899 37.4184 15.8639C37.4275 12.5758 37.4305 9.29375 37.4305 5.81738Z" strokeMiterlimit="10" />
				<path d="M51.616 19.4801L42.0249 9.89502C42.0249 12.1751 41.9794 14.7528 42.0462 17.3123C42.0796 18.5965 42.8902 19.4193 44.1715 19.4588C46.7734 19.5195 49.3845 19.4801 51.616 19.4801Z" strokeMiterlimit="10" />
				<path d="M30.6058 33.4458C34.9535 33.4458 39.3053 33.4458 43.6612 33.4458C45.5253 33.4458 46.5789 34.3293 46.5303 35.7988C46.4848 37.1954 45.4616 38 43.6612 38C34.9576 38 26.2621 38 17.5748 38C15.7106 38 14.657 37.1165 14.7026 35.647C14.7481 34.2504 15.7743 33.4488 17.5717 33.4458C21.9154 33.4458 26.2601 33.4458 30.6058 33.4458Z" strokeMiterlimit="10" />
				<path d="M30.6057 47.1084C26.2579 47.1084 21.9062 47.1084 17.5503 47.1084C15.759 47.1084 14.7328 46.2917 14.6964 44.892C14.6569 43.4256 15.7135 42.5542 17.5837 42.5542C26.2873 42.5542 34.9828 42.5542 43.6701 42.5542C45.4645 42.5542 46.4907 43.3709 46.5271 44.7706C46.5636 46.237 45.51 47.1054 43.6398 47.1084C39.288 47.1084 34.9433 47.1084 30.6057 47.1084Z" strokeMiterlimit="10" />
				<path d="M30.6206 51.6626C34.9684 51.6626 39.3202 51.6626 43.676 51.6626C45.4673 51.6626 46.4965 52.4793 46.533 53.879C46.5694 55.3424 45.5159 56.2168 43.6456 56.2168C34.9421 56.2168 26.2466 56.2168 17.5592 56.2168C15.7679 56.2168 14.7387 55.4001 14.7022 54.0004C14.6628 52.534 15.7193 51.6656 17.5866 51.6626C21.9262 51.6626 26.2709 51.6626 30.6206 51.6626Z" strokeMiterlimit="10" />
				<path d="M25.9754 65.3252C23.1457 65.3252 20.316 65.3252 17.4742 65.3252C15.7102 65.3252 14.6749 64.4569 14.6719 63.0481C14.6688 61.6393 15.7285 60.771 17.4833 60.771C23.1953 60.771 28.9062 60.771 34.6162 60.771C36.3771 60.771 37.4155 61.6393 37.4155 63.0451C37.4155 64.4508 36.3741 65.3252 34.6192 65.3252C31.7379 65.3252 28.8567 65.3252 25.9754 65.3252Z" strokeMiterlimit="10" />
			</svg>


		</React.Fragment>
	)
}

export default DocumentplaceholderIcon