import React from 'react'

const ActivityLogSkeleton = ({is_Email_tracking = false}) => {
  return (
    <>
            {
                [0, 1, 2].map((s, i) => (
                    <div className="pt-10px border-bottom SkeletonMain">
                        <div className="d-flex justify-content-between flex-column">
                            <div className="d-flex">
                                <div className="d-flex align-items-center gap10px flex-grow-1">
                                    <div className='flex-grow-1'>
                                        <div className='mb-1 skeleton-animation radius_3' style={{ width: '14%', height: '10px' }}></div>
                                        {/* <div className='skeleton-animation radius_3' style={{ width: '10%', height: '8px' }}></div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center gap10px flex-grow-1">
                                {is_Email_tracking ? <></> 
                                :
                                    <div className='h32w32 rounded-5 skeleton-animation'></div>
                                }
                                <div className='skeleton-animation radius_3' style={{ width: '85%', height: '15px' }}></div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </>
  )
}

export default ActivityLogSkeleton