import React from 'react';
// import CSS from '../css/icon_main.css';

function NoDoneTask(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>


			<svg width="55" height="78" viewBox="0 0 55 78" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons h-100 w-100 ${CommanHW}`}>
				<path d="M16.3457 34.2979L38.2525 55.9809" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M38.2525 34.2979L16.3457 55.9809" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M53.5621 68.421C53.5621 70.3446 53.5909 72.2682 53.5621 74.1954C53.545 74.9472 53.2324 75.662 52.692 76.1849C52.1515 76.7077 51.4268 76.9966 50.6749 76.9888C47.9537 77.014 45.2288 76.9888 42.5076 76.9888H16.2917C15.4544 76.9888 14.6171 76.9238 13.7654 76.9274C11.8995 76.9274 10.0372 76.9743 8.17136 76.9888C6.83963 76.9888 5.50427 76.9563 4.17254 76.9888C3.75063 77.0095 3.32908 76.9416 2.93502 76.7894C2.54096 76.6373 2.18317 76.4042 1.88465 76.1054C1.58613 75.8065 1.35349 75.4484 1.20179 75.0542C1.05008 74.66 0.982627 74.2384 1.0038 73.8165C1.05433 70.8138 1.00382 67.8111 1.02186 64.7939V62.6682V40.054V38.2495C1.00743 30.276 1.00743 22.3048 1.02186 14.3361C1.02186 12.4775 2.29584 11.2107 4.16531 11.2107H13.5127C13.5127 11.0374 13.538 10.8931 13.538 10.7451C13.538 9.55056 13.538 8.35957 13.538 7.16498C13.538 6.80408 13.6174 6.6561 14.0216 6.66332C15.6348 6.68858 17.2697 6.66332 18.8649 6.66332C19.1067 6.66332 19.2691 6.66333 19.3702 6.35656C20.3663 3.5343 22.4234 1.85249 25.2781 1.24617C28.6742 0.524366 31.6517 1.36889 33.9831 4.05762C34.5503 4.71099 34.9722 5.47736 35.221 6.30603C35.2306 6.36319 35.2514 6.41791 35.2822 6.46701C35.313 6.51611 35.3532 6.55864 35.4005 6.59217C35.4477 6.6257 35.5012 6.64956 35.5577 6.6624C35.6143 6.67523 35.6728 6.67676 35.7299 6.66693C37.3576 6.66693 38.978 6.66693 40.6129 6.66693C40.9738 6.66693 41.0748 6.75715 41.0676 7.11084C41.0676 8.30542 41.0676 9.49642 41.0676 10.691V11.2287H50.5125C50.9196 11.2135 51.3254 11.2826 51.7045 11.4316C52.0836 11.5807 52.4279 11.8065 52.7156 12.0949C53.0033 12.3833 53.2283 12.728 53.3764 13.1075C53.5246 13.487 53.5927 13.893 53.5765 14.3V18.7175C53.5765 25.2859 53.5765 31.8543 53.5765 38.4227V68.4065L53.5621 68.421Z" strokeMiterlimit="10" />
				<path d="M37.4045 15.4082C37.5633 15.4082 37.7258 15.4082 37.8918 15.3865V9.87193H37.3071C35.8202 9.87193 34.3333 9.87193 32.8464 9.87193C32.5577 9.87193 32.4493 9.78533 32.4493 9.48939C32.4493 9.08518 32.4061 8.67736 32.3844 8.27315C32.3014 6.70684 31.4316 5.64938 30.136 4.92397C29.3453 4.46391 28.4549 4.20227 27.541 4.16141C26.6272 4.12056 25.717 4.3017 24.8885 4.68937C23.7733 5.17659 22.8313 5.93811 22.4632 7.14353C22.276 7.90016 22.1683 8.67426 22.1419 9.45327C22.1131 9.7853 22.012 9.87554 21.6908 9.87193C20.1895 9.87193 18.6881 9.87193 17.1868 9.87193C17.0244 9.87193 16.8655 9.89357 16.7031 9.9044V15.4262H28.6743L37.4045 15.4082Z" strokeMiterlimit="10" />
			</svg>

		</React.Fragment>
	)
}

export default NoDoneTask