import { CaretLeft, CaretRight } from "phosphor-react";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { _l, getPreviewFromURL } from "../../hooks/utilities";
import moment from "moment";

const DocumentGalleryView = ({
  documents,
  clickHandler,
  previewHeightClass,
  imagesPerPage,
  activeImageId,
  showImageSelectionButton,
  previewPreset //Preview is setup from parent
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);

  useEffect(() => {
    if (documents.length) {
      setIsLastPage(
        !(
          documents.length > 0 &&
          documents.length - currentPage * imagesPerPage > 0
        )
      );
    }
  }, [currentPage]);

  const handlePageChange = (direction) => {
    if (direction > -1) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const startIndex = (currentPage - 1) * imagesPerPage;

  return (
    <div className="comman-list with-after-40 d-flex">
      <div className="d-flex w-100">
        {currentPage === 1 ? (
          <React.Fragment></React.Fragment>
        ) : (
          <a href="#/"
            className={`ms-2 bg-silder-btn h18w18 with_overlay d-flex rounded-circle position-absolute top-50 starts-0 translate-middle-y z-index-2`}
            onClick={() => {
              handlePageChange(-1);
            }}
          >
            <CaretLeft size={14} weight="light" className="c-icons " />
          </a>
        )}
        <div className="d-flex  upload-image-preview">
          {documents && documents.length ? (
            documents
              .slice(startIndex, imagesPerPage * currentPage)
              .map((document, key) => {

                const preview = {url: document.image_url}; 
                
                preview.url = previewPreset ? document.image_url : getPreviewFromURL(document.image_url);

                return (
                  <React.Fragment>
                    <a href="#/"
                      key={key}
                      className={`comman-image-box radius_3 upload-image me-3 d-flex ${previewHeightClass} ${activeImageId == document.id} ${
                        isLastPage ? "for-disabled" : ""
                      }`}
                      data-tip={`${_l("l_uploaded_on")} ${moment(
                        document.dateadded
                      ).format("lll")}`}
                      data-effect="solid"
                      data-delay-show='1000'
                      data-class="tooltip-main"
                      onClick={() => clickHandler(document, documents.findIndex((w) => w.id == document.id))}
                    >
                      {
                        typeof preview.url === "string"
                        ?
                        <div
                          className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3"
                          style={{
                            backgroundImage: `url(${document.image_url})`,
                          }}
                        ></div>
                        :
                        <preview.url size={50} weight="light" className="c-icons " />
                      }
                    </a>
                    <ReactTooltip />
                  </React.Fragment>
                );
              })
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </div>
        {isLastPage ? (
          <React.Fragment></React.Fragment>
        ) : (
          <a href="#/"
            className={`me-2 bg-silder-btn h18w18 with_overlay d-flex  rounded-circle position-absolute top-50 end-0 translate-middle-y z-index-2`}
            onClick={() => handlePageChange(1)}
          >
            <CaretRight size={14} weight="light" className="c-icons " />
          </a>
        )}
      </div>
    </div>
  );
};

export default DocumentGalleryView;
