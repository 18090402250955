import React from 'react';
// import CSS from '../css/icon_main.css';

function ArrowBendDoubleLeftIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M5 9.5L2 6.5L5 3.5M8 9.5L5 6.5M5 6.5L8 3.5M5 6.5H8C9.5913 6.5 11.1174 7.13214 12.2426 8.25736C13.3679 9.38258 14 10.9087 14 12.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>



		</React.Fragment>
	)
}

export default ArrowBendDoubleLeftIcon