import React, { useState } from "react";
import { CaretDown, CornersIn, CornersOut, Info, X } from "phosphor-react";
import OnboardingAssigned from "../../Myspace/MyspaceComponent/OnboardingAssigned";

const Scan3dPreview = ({ url, setShow }) => {

    const [fullScreenMode, setFullScreenMode] = useState(false);

  return (
    <>
      {url ? (
        <div className={`p-0 onboarding-3d-viewer-main ${fullScreenMode ? "fullscreen-3d-viewer" : ""}`}>
          <div className="p-15 onboarding-3d-viewer position-relative">
            <model-viewer
              class="h-100"
              id="placeholder_demo"
              alt="Space"
              src={url}
              camera-controls
              shadow-intensity="1"
              disable-tap
              ar
              environment-image=""
              poster=""
              touch-action="none"
              min-camera-orbit="auto auto 100%"
              max-camera-orbit="auto auto 100%"
            ></model-viewer>
            <div className="align-items-center comman_action_icon d-flex end-0 position-absolute top-0 w-auto mt-2">
              <a href="#/" className="action_icon with_bg me-2">
                <Info size={16} className="c-icons" weight="light" />
              </a>
              <a href="#/" className="action_icon with_bg me-2" onClick={() => {setFullScreenMode(!fullScreenMode)}}>
                <CornersIn
                  size={16}
                  className="c-icons d-none"
                  weight="light"
                />
                <CornersOut size={16} className="c-icons" weight="light" />
              </a>
              <a href="#/" className="action_icon with_bg me-2" onClick={() => setShow(false)}>
                <X size={16} className="c-icons" weight="light" />
              </a>
            </div>

            <div className="bottom-0 dropup dropup-center p-10 position-absolute start-0">
              <a href="#/"
                className="btn btn-white-05 border-0"
                type="button"
                id="OnboardingAssigned"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
              >
                <div className="d-flex">
                  <span className="pe-2">Rooms</span>
                  <CaretDown size={16} className="c-icons" weight="light" />
                </div>
              </a>
              <OnboardingAssigned />
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </>
  );
};

export default Scan3dPreview;
