import React from 'react';
// import CSS from '../css/icon_main.css';

function CornersOutIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"  className={`comman-icons ${CommanHW}`}>
<path d="M10.5 3H13V5.5M5.5 13H3V10.5M13 10.5V13H10.5M3 5.5V3H5.5" />
</svg>

		</React.Fragment>
	)
}

export default CornersOutIcon