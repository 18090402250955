import React, { useState } from 'react'
import User_9 from "../../assets/images/users/user_9.jpg";
import { Info} from "phosphor-react";
import { _l } from '../../hooks/utilities';
import EquipmentListing from '../TaskModalComponents/EquipmentListing';

const EquipmentDayTable = () => {
  const [EmpList, setEmpList] = useState(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30"]);
  const [getTaskList, setTaskList] = useState([])
  const [show, setshow] = useState(false)
  const employeestatusdata = () => {
    return (
      <div className="table-text">
        <span className="color-white-80 c-font f-14">45</span><span className="color-white-60 c-font f-12 px-1">[15 : 00 Hrs]</span>
        <a href="#/" className="" type="button" id="CategoryFilterDropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" 
        onClick={() => setshow(!show)}
        >
          <Info size={16} color="#fca21f" weight="light" />
        </a>
        {
          show ? 
          <EquipmentListing 
          list={getTaskList}
          showLoader = {false} 
          getTaskList={getTaskList}
          setTaskList={setTaskList}
        />
        :
        <></>
      }
        <div className="d-flex pt-1">
          <div className="with_separator_10 ps-0">
            <span className="c-dots  c-7 in-progress-white rounded-circle me-2"></span><span className="color-white-80 c-font f-12 title-fonts">5</span>
          </div>
          <div className="with_separator_10">
            <span className="c-dots c-7 rounded-circle in-progress-blue me-2"></span><span className="color-white-80">10</span>
          </div>
          <div className="with_separator_10">
            <span className="c-dots  c-7 done rounded-circle me-2"></span><span className="color-white-80">2</span>
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <div className="overflow-auto comman_vertical_btn_h">
        <table>
          <tr className="timesheet-head">
            <td className="time-sheet-box name-box">{_l('l_name_and_progress')}</td>
            <td className="time-sheet-box hour-box">{_l('l_total_hours')}</td>
            {EmpList.length > 0 && EmpList.map((item) => {
              return (
                <>
                  <td className="time-sheet-box">{item} Mon</td>
                </>)
            })
            }
          </tr>
          {EmpList.length > 0 && EmpList.map((item) => {
            return (
              <>
                <tr className="">
                  <td className="time-sheet-box name-box">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className=" h-100 d-flex align-items-center justify-content-center office-list-icon">
                        <a href="#/" className="h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-5px">
                          <div
                            className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                            style={{ backgroundImage: `url('${User_9}')` }}
                          ></div>
                        </a>
                      </div>
                      <div className="comman-list-wrapper-main ps-2 w100minus70 left-toggle-none flex-grow-1">
                        <div className="list-left-part">
                          <div className="d-flex flex-column">
                            <a href="#/" className="title-fonts text-truncate">
                              Devon Lane
                            </a>
                            <div className="progress-slider">
                              <div className="d-flex align-items-center">
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    style={{
                                      width: `60%`,
                                    }}
                                  ></div>
                                </div>
                                <div className="progress-text c-font f-12 title-fonts">
                                  60%
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="time-sheet-box hour-box">15 : 00 Hrs</td>
                  {EmpList.length > 0 && EmpList.map((item) => {
                    return (
                      <>
                        <td className="time-sheet-box">
                          {employeestatusdata()}
                        </td>
                      </>)
                  })
                  }
                </tr>
              </>)
          })
          }
          <tr className="timesheet-foot">
            <td className="time-sheet-box name-box">{_l('l_total_count')}</td>
            <td className="time-sheet-box hour-box">60 :00 Hrs</td>
            {EmpList.length > 0 && EmpList.map((item) => {
              return (
                <>
                  <td className="time-sheet-box"> {employeestatusdata()}</td>
                </>)
            })
            }
          </tr>
        </table>
      </div>
    </>
  )
}

export default EquipmentDayTable