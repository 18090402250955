import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { DropdownMenu, DropdownItem } from "reactstrap";
import {_l } from "../../hooks/utilities";
import Select from "react-select";
import { Trash, X } from "phosphor-react";
import { useDispatch, useSelector } from "react-redux";
import { showConfirmation, showMessage, toastCloseAction } from "../../actions/messages";
import { updateTypeOfSpaceList } from "../../actions/benchmark-actions/benchmark-actions";
import ReactTooltip from "react-tooltip";
import jquery from "jquery";

const TypeOfSpaceAllotment = ({ setInputs, inputs = [], floors = [], space, deleteSpaceItem, isChecklistCreation, checklists = [], setChecklists,setDropdownKey, readOnlyMode }) => {


    let spaceArray = inputs.slice();
    let spaceIndex = spaceArray.findIndex(x => x.title == space.title);

    const { filteredFloorIds } = useSelector((state) => state.benchmarkReducer);

    const [overflowToggle, setoverflowToggle] = useState(true);

    const dispatch = useDispatch();

    const addNewSpaceItem = () => {
        setInputs(Object.values({ ...inputs, [spaceIndex]: { ...space, quantity: Number(space.quantity) + 1, details: space.details.concat({ title: `${space.title} ${space.details.length + 1}` }) } }));
    }

    const confirmDeleteSpaceItem = (index) => {
        dispatch(showConfirmation(_l('l_are_you_sure'), _l('l_delete_type_of_space_label'), _l('l_delete'), _l('l_cancel'), () => { }, () => { deleteSpaceItem(space, index); dispatch(toastCloseAction()) }));
    }

    const updateSpaceItem = (index, key, value, validateAll = false) => {
        if (spaceIndex > -1 && space.details) {
            if (validateAll && key == "title") {
                let flag = true;
                space.details.forEach((item, itemIndex) => {
                    if (item.title == value && itemIndex != index) {
                        flag = false;
                    }
                });
                if (!flag) {
                    value = `${space.title} ${(index == space.details.length - 1) ? space.details.length : index + 1}`;
                    dispatch(showMessage('unsucess', _l('l_error'), `${_l("l_item_already_exists")}`));
                }
            }
            setInputs(Object.values({ ...inputs, [spaceIndex]: { ...space, details: Object.values({ ...space.details, [index]: { ...space.details[index], [key]: value } }) } }));
        }
    }

    const isScheduledSpaceItem = (id) => {
        let flag = false;
        let currentTypeOfSpaceChecklist = checklists.find(x => x.room_id == space.id);
        if (currentTypeOfSpaceChecklist && currentTypeOfSpaceChecklist.type_of_space_items && currentTypeOfSpaceChecklist.type_of_space_items.findIndex((x) => x == id) !== -1) {
            flag = true;
        }
        return flag;
    }

    const setSpaceAsScheduled = (id, checked) => {
        let currentChecklists = checklists.slice();
        currentChecklists.forEach((checklist) => {
            if (checklist.room_id == space.id) {
                if (checked) {
                    checklist.type_of_space_items.push(id);
                }
                else {
                    if (checklist.type_of_space_items.length > 1) {
                        checklist.type_of_space_items = checklist.type_of_space_items.filter((x) => x != id);
                    }
                    else {
                        dispatch(showConfirmation(_l('l_remove_space_from_schedule'), _l('l_remove_space_from_schedule_confirmation'), _l('l_remove'), _l('l_cancel'), () => { }, () => { removeTypeOfSpaceFromSchedule(); dispatch(toastCloseAction()) }));
                    }
                }
            }
        });
        setChecklists(currentChecklists);
    }

    const removeTypeOfSpaceFromSchedule = () => {
        dispatch(updateTypeOfSpaceList([space.id], false));
        setChecklists(checklists.map(x => {
            if (x.room_id == space.id) {
                return {
                    ...x,
                    type_of_space_items: []
                }
            }
            else {
                return x;
            }
        }))
    }
    
    useEffect(() => {
        if(isChecklistCreation){}else{
            jquery(document).mouseup(function (e) {
                var container = jquery(".dropdown-menu,.react-select__menu-portal");
                if (!container.is(e.target) && container.has(e.target).length === 0) {
                    setDropdownKey(-1);
                }
            });
        }
    }, [isChecklistCreation])

    useEffect(() => {
        if (space && floors.length && !space.details) {
            spaceArray[spaceIndex] = { ...spaceArray[spaceIndex], details: [] };
            Array.from({ length: space.quantity }).forEach((spaceItem, index) => {
                spaceArray[spaceIndex].details.push({ title: `${space.title} ${index + 1}`, id: 0 });
            });
            setInputs(spaceArray);
        }
    }, [floors])

    return (
        <DropdownMenu container="body" className={`${isChecklistCreation ? "min-w-320px" : "w-500"}`} toggle={false}>
            <DropdownItem
                aria-readonly
                header
                tag="div"
                className="dropdown-header-sticky hr_1"
                toggle={false}
            >
                <div className="d-flex align-items-center justify-content-between">
                <div className="c-list-detail title-fonts text-truncate color-white c-font f-13" data-tip={space.title}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main">
                    {space.title}
                </div>
                
                <a href="#/" className="color-white" onClick={()=>{setDropdownKey(-1)}}>
                    <X  size={16} weight="light" className="m-auto"/>
                </a>
                </div>
            </DropdownItem>
            <DropdownItem aria-readonly tag="div" toggle={false} className={`no-hover z-index-8 ${isChecklistCreation ? "max-h40vh w-100 mt-2" : ""} ${overflowToggle ? "max-h20vh overflow-hiiden-auto py-0" : "py-0 max-h20vh overflow-hiiden-auto"}`}>
                {Array.from({ length: space.quantity }).map((input, index) => {
                    let isSpaceScheduled = isScheduledSpaceItem(space.details[index].id);

                    if (isChecklistCreation && filteredFloorIds.length && !filteredFloorIds.includes(space.details[index].floor_id)) {
                        return <React.Fragment></React.Fragment>
                    }

                    if(readOnlyMode && !isSpaceScheduled)
                    {
                        return <React.Fragment></React.Fragment>
                    }

                    return (
                        <Form.Group
                            key={index}
                            className={`align-items-center col-12 d-flex position-relative ${isChecklistCreation ? " bg-white-03 mb-2 py-0 radius_3" : " c-input-box py-2   "}  ${isSpaceScheduled ? 'bg-base-header-color' : ''}`}
                        >
                            {
                                isChecklistCreation
                                    ?
                                    <React.Fragment>
                                        {
                                            readOnlyMode
                                            ?
                                                <React.Fragment></React.Fragment>
                                            :

                                                <input
                                                    className="form-check-input mx-2"
                                                    type="checkbox"
                                                    checked={isSpaceScheduled}
                                                    onChange={(e) => { setSpaceAsScheduled(space.details[index].id, e.target.checked) }}
                                                />
                                        }
                                        <div className="align-item-center d-flex py-1 w100minus40">
                                            <span className="w-auto m-auto text-truncate flex-grow-1" data-tip={space.details[index].title} data-effect="solid" data-delay-show='1000' data-class="tooltip-main" >{space.details[index].title}
                                            </span>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <Form.Control
                                            placeholder={`${_l("l_meeting_room")}`}
                                            type="text"
                                            name=""
                                            value={space.details && space.details[index] ? space.details[index].title : `${space.title} ${index + 1}`}
                                            onChange={(e) => { updateSpaceItem(index, "title", e.target.value.slice(0, 30)) }}
                                            onBlur={(e) => { updateSpaceItem(index, "title", e.target.value.slice(0, 30) || `${space.title} ${index + 1}`, true) }}
                                            className="w-auto p-2 me-2 flex-grow-1"
                                            maxLength={30}
                                        />
                                        <Select
                                            placeholder={`${_l("l_select_floor")}`}
                                            onMenuClose={() => { setoverflowToggle(true) }}
                                            onMenuOpen={() => { setoverflowToggle(false) }}
                                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                            menuPortalTarget={document.body}
                                            // menuIsOpen={true}
                                            menuPlacement="top"
                                            className="custom-select-menu w-25"
                                            classNamePrefix="react-select"
                                            closeMenuOnSelect={true}
                                            options={floors}
                                            onChange={(e) => { updateSpaceItem(index, "floorIndex", e.value) }}
                                            value={floors.length && space.details && space.details[index] ? floors.find(x => x.value == space.details[index].floorIndex) : []}
                                        />
                                        {
                                            space.quantity > 1
                                                ?
                                                <a href="#/" className={`comman_action_icon h40w40 ms-2`} onClick={() => { confirmDeleteSpaceItem(index) }}>
                                                    <div className="action_icon with_bg w-100 h-100 d-flex">
                                                        <Trash size={16} weight="light" className="c-icons m-auto" />
                                                    </div>
                                                </a>
                                                :
                                                <React.Fragment></React.Fragment>
                                        }
                                    </React.Fragment>
                            }
                        </Form.Group>
                    );
                })}
            </DropdownItem>
            {
                isChecklistCreation
                    ?
                    <React.Fragment></React.Fragment>
                    :
                    <DropdownItem
                        tag="div"
                        className="dropdown-footer-sticky d-flex align-items-center justify-content-end no-hover"
                        toggle={false}
                        aria-readonly
                    >
                        <Button variant="secondary" class size="sm" onClick={addNewSpaceItem}>
                            {_l("l_add_new")}
                        </Button>
                    </DropdownItem>
            }
            <ReactTooltip />
        </DropdownMenu>
    );
};

export default TypeOfSpaceAllotment;
