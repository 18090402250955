import React from 'react'
const FullScreenChatBoxSkeleton = () => {
    return (
        <>
        {["1", "2", "3", "4",].map((item) => {
            return(
            <div>
            <div className='chat-box d-flex skeleton-bg skeleton-main SkeletonMain d-flex chat-box with-padding width-custom'>
                <div className='h32w32 rounded-5 comman-image-box skeleton-animation bg-black-op5 mt-auto me-10px'></div>
                <div className='chat-msg'>
                    <div className='skeleton-animation radius_3 mb-2' style={{ width: '70px', height: '6px' }}></div>
                    <div className='chat-text-box flex-grow-1 bg-white w-100'>
                        <div className='skeleton-animation radius_3 mb-2' style={{ width: '100%', height: '12px' }}></div>
                        <div className='skeleton-animation radius_3 mb-2' style={{ width: '80%', height: '12px' }}></div>
                        <div className='skeleton-animation radius_3 mb-2' style={{ width: '55%', height: '12px' }}></div>
                    </div>
                    <div className='skeleton-animation radius_3 mt-2' style={{ width: '150px', height: '7px' }}></div>
                </div>
            </div>
            <div className='chat-box d-flex right-side-msg skeleton-bg d-flex chat-box with-padding width-custom'>
                {/* <div className='h32w32 rounded-5 comman-image-box skeleton-animation bg-black-op5 mt-auto ms-10px'></div> */}
                <div className='chat-msg d-flex'>
                    <div className='skeleton-animation radius_3 mb-2 ms-auto' style={{ width: '70px', height: '6px' }}></div>
                    <div className='chat-text-box flex-grow-1 bg-white'>
                        <div className='skeleton-animation radius_3 mb-2 ms-auto' style={{ width: '100%', height: '12px' }}></div>
                        <div className='skeleton-animation radius_3 mb-2 ms-auto' style={{ width: '80%', height: '12px' }}></div>
                        <div className='skeleton-animation radius_3 mb-2 ms-auto' style={{ width: '55%', height: '12px' }}></div>
                    </div>
                    <div className='skeleton-animation radius_3 mt-2 ms-auto' style={{ width: '150px', height: '7px' }}></div>
                </div>
            </div>
            </div>
            )
        })
            }
        </>
    )
}
export default FullScreenChatBoxSkeleton