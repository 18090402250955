import { ArrowBendDoubleUpLeft, ArrowBendUpLeft, CalendarBlank, DotsNine, DotsThreeVertical, Envelope, Eye, Image, LinkSimple, Paperclip, PencilSimpleLine, PushPin, Smiley, Trash, X } from 'phosphor-react'
import React from 'react'
import ReactTooltip from 'react-tooltip'
import { _l } from '../../hooks/utilities'
import { Accordion, Form } from 'react-bootstrap'
import { Button, Spinner } from "react-bootstrap";
import MyrAiIcon from '../../assets/icons/MyrAiIcon'
import DatePicker from "react-datepicker";

const EmailsInboxReply = () => {
    return (
        <div className=' d-flex flex-column flex-grow-1'>
            <div className='p-10 d-flex justify-content-between border-bottom align-items-center'>
                <div className='task-list-wrappe w-100'>
                    <div className='d-flex w-100 align-items-start'>
                        <div className='d-flex task-list-detail w-100'>
                            <div className='task-left flex-grow-1 width100minus160 '>
                                <div className='d-flex align-items-center w-100 task-left-part-toggle text-truncate'>
                                    <div className='task-top-part text-truncate flex-grow-1 d-flex main-calc-width-task'>
                                        <div className='flex-wrap d-flex  w-100 align-items-center comman_action_icon'>
                                            <div className='task-creator-img h32w32 task-creator-img m-auto rounded-5'>
                                                <div className='comman-image-box h-100 w-100 rounded-circle m-auto  '>
                                                    <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{
                                                        backgroundImage: `url('https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`
                                                    }}></div>
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column w100minus100per task-list-name text-truncate ms-10px'>
                                                <div className='text-truncate w-100  fw-semibold'>Microsoft email Invoice for @James </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='c-font color-white-60 f-10 mt-1 location me-2 fw-normal '>
                                                        <span className=' p-0 '>Date : </span>
                                                        <span className='p-0'>27/05/24 11:16 AM</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center task-bottom-part-full '>
                                <div className='task-right-static-options'>
                                    <div className='d-flex align-items-center gap10px comman_action_icon'>

                                        {/* task Envelope start */}
                                        <div className="   text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding ">

                                            <a href="#/" className="comman_action_icon w-auto d-flex align-items-center close-toast border-0" data-tip={_l("l_star_rating")}
                                                data-effect="solid"
                                                data-delay-show='1000'
                                                data-class="tooltip-main">

                                                <div className="action_icon with_bg h32w32 position-relative light-theme-img">

                                                    <Envelope size={18} className="c-icons" />
                                                    <span className={`rounded-pill badge-custom p-0`}>1</span>
                                                </div>
                                                <span className="c-font f-12 d-flex justify-content-center flex-grow-1">1</span>

                                            </a>
                                            <ReactTooltip />
                                        </div>
                                        {/* task Envelope end */}
                                        {/* Inbox badge start */}
                                        <div className="with_separator_10 pe-0">
                                            <span className="badge rounded-pill h32wauto c-font f-12 bg-black-op5 color-white-imp">
                                                Inbox
                                            </span>
                                        </div>
                                        {/* Inbox badge end */}
                                        {/* task Envelope start */}
                                        <div className="   text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding comman_action_icon">

                                            <a href="#/" className="w-auto h32w32 action_icon with_bg  d-flex align-items-center close-toast p-10" data-tip={_l("l_star_rating")}
                                                data-effect="solid"
                                                data-delay-show='1000'
                                                data-class="tooltip-main">

                                                <div className=" d-flex align-items-center">

                                                    <ArrowBendUpLeft size={18} className="c-icons" />
                                                </div>
                                                <span className="c-font f-12 d-flex justify-content-center flex-grow-1 ps-2 color-white-imp">REPLY</span>

                                            </a>
                                            <ReactTooltip />
                                        </div>
                                        {/* task Envelope end */}
                                        {/* nine dots start */}

                                        <div className={`comman_action_icon `}>
                                            <a href="#/" className={`action_icon h32w32 with_bg with-border-active`} >
                                                <DotsNine size={18} className="c-icons" />
                                            </a>
                                        </div>

                                        {/* Nine dots over */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='comman_vertical_btn_h d-flex flex-column p-10 h54px flex-grow-1 overflow-auto'>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex align-items-center'>
                        <div className='comman-image-box h32w32 rounded-circle'>
                            <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{ backgroundImage: `url('https://images.unsplash.com/photo-1678286742832-26543bb49959?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D')` }}></div>
                        </div>
                        <div className='c-font f-13 title-fonts w100minus50 flex-grow-1 ps-10px'>Goolge</div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className='c-font f-12 color-white-60 pe-2'>04-07-2024 02:50 pm(an hour ago)</div>
                        <a href="#/" className="comman_action_icon w-auto d-flex align-items-center close-toast border-0">

                            <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                                <Eye size={18} className="c-icons" />
                            </div>

                        </a>
                    </div>
                </div>
                <div className='d-flex justify-content-center align-items-center flex-grow-1'>
                    <div className='w600px mx-auto'>
                        <div className='w600px border p-20 pt-5 pb-5 rounded-3 mx-auto'>
                            <div className='text-center c-font f-24 pb-10px'>SolarOffice was granted access to your Google Account</div>
                            <div className='d-flex align-items-center justify-content-center pb-4 border-bottom'>
                                <div className='comman-image-box h20w20 rounded-circle'>
                                    <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{ backgroundImage: `url('https://images.unsplash.com/photo-1678286742832-26543bb49959?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D')` }}></div>
                                </div>
                                <div className='c-font f-14 title-fonts ps-10px'>atest9722@gmail.com</div>
                            </div>
                            <div className='c-font f-14 pt-4'>If you did not grant access, you should check this activity and secure your account.
                                <div className=' d-flex justify-content-center pt-4 pb-3'>
                                    <a href='#/' className='p-10 px-4 bg-solar rounded-2'>Check activity</a>
                                </div>
                                <div className='c-font f-12 text-center'>You can also see security activity at</div>
                                <a href='#/' className='c-font f-12 text-center w-100'>https://myaccount.google.com/notifications</a>
                            </div>

                        </div>
                        <div className='c-font f-11 text-center w-100 pt-2'>You received this email to let you know about important changes to your Google Account and services.</div>
                        <div className='c-font f-11 text-center w-100 pt-2'>© 2024 Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA</div>
                    </div>
                </div>
            </div>
            <div className='replying-box-main d-flex flex-column  mt-auto ms-auto '>
                <Button className="btn btn-primary btn-sm">
                    Reply
                </Button>
            </div>
            <div className='p-10 bg-solar flex-grow-1 d-flex flex-column d-none'>
                <div className='d-flex justify-content-between align-items-center pb-15px'>
                    <div className='d-flex align-items-center'>
                        <div className='d-flex align-items-center justify-content-center'>
                            <ArrowBendDoubleUpLeft size={18} className="c-icons" />
                        </div>
                        <div className='c-font f-12 text-nowrap ps-10px'>no-reply@accounts.google.com</div>
                        <a href='#/' className='p-10'><X size={16} className="c-icons" /></a>
                        <input className="form-control p-0 border-bottom border-0 shadow-none rounded-0 ms-10px bg-transparent" type="text" name="" placeholder={_l("l_please_enter_email")} />
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className='c-font f-12 color-white-60 pe-2'>Thu Jul 04 2024 16:48:29 GMT+0530 (India Standard Time)</div>
                        <a href="#/" className="comman_action_icon w-auto d-flex align-items-center close-toast border-0">

                            <div className="action_icon h32w32 position-relative light-theme-img">
                                <Trash size={18} className="c-icons" />
                            </div>

                        </a>
                    </div>
                </div>
                <div className="d-flex position-relative flex-grow-1 flex-column">
                    <div className="d-flex h-100">
                        <div className="h30w30 comman-image-box rounded-circle">
                            <div
                                className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                style={{
                                    backgroundImage: `url('https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D')`,
                                }}
                            ></div>
                        </div>
                        <Form.Group className="w100minus30 d-flex flex-column flex-grow-1">

                            <div className="c-font f-12 text-nowrap ps-10px">
                                {_l('l_content')} :
                            </div>
                            <Form.Control
                                className="bg-transparentimg border-0 h-100 pt-0 flex-grow-1 ps-10px shadow-none fw-normal"
                                as="textarea"
                                placeholder={_l('l_write_description_placeholder')}
                                rows={3}
                            />
                        </Form.Group>
                    </div>
                    <React.Fragment>
                        <div className="upload-image-preview ">
                            <div className="d-flex align-items-center justify-content-start">
                                <div
                                    className="comman-image-box h70w70 radius_3 upload-image with-margin"
                                >
                                    <div
                                        className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                                        style={{
                                            backgroundImage: `url('https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D')`,
                                        }}
                                    ></div>
                                    <a href="#/"
                                        className="upload-close"
                                    >
                                        <X size={10} className="c-icons" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                </div>
            </div>
            <div
                className={`d-flex align-items-center justify-content-between flex-wrap bg-solar p-10 d-none`}
            >

                <div className="d-flex align-items-center flex-wrap comman_action_icon">
                    <a href="#/" className="me-2 action_icon with_bg h32w32">
                        <input
                            type="file"
                            multiple
                            className="d-none"
                            id="reply_files"
                        />
                        <Paperclip size={18} className="c-icons" />
                    </a>
                    <a href="#/" className="me-2 action_icon h32w32 me-2 with_bg">
                        <LinkSimple size={16} className="c-icons" />
                    </a>
                    <a href="#/" className="me-2 action_icon h32w32 me-2 with_bg">
                        <Smiley size={16} className="c-icons" />
                    </a>
                    <a href="#/" className="me-2 action_icon h32w32 me-2 with_bg">
                        <Image size={16} className="c-icons" />
                    </a>
                    <a href="#/" className="me-2 action_icon h32w32 me-2 with_bg">
                        <PencilSimpleLine
                            size={18}
                            className="c-icons"

                        />
                    </a>
                    <a href="#/" className="me-2 action_icon with_bg h32w32">
                        <DotsThreeVertical
                            size={18}
                            className="c-icons"

                        />
                    </a>
                    <a href="#/" className="me-2 action_icon with_bg h32w32 justify-content-center align-items-center">
                        <MyrAiIcon className="HW18" />
                    </a>
                </div>

                <div className={`Reply-button d-flex text-end`}>
                    <div
                        className={`comman_action_icon custom-datepicker  me-10px on-hover-bg-white-05 withinputabsolute h32w32`}
                        data-tip={_l("l_schedule_replay")}
                        data-effect="solid"
                        data-delay-show="1000"
                        data-class="tooltip-main"
                    >
                        <div
                            className={`action_icon align-items-center d-flex h-100 justify-content-center light-theme-img on-hover-active-toggle-img on-hover-color-green position-relative text-truncate w-100 with_bg  `}
                        >
                            <CalendarBlank
                                size={18}

                                className="c-icons m-0"
                            />
                            <DatePicker
                                portalId="custom_datepicker"
                                calendarClassName="custom-datepicker"
                                className="close-toast"
                                showTimeSelect
                                selectsStart
                                dateFormat="dd/MM/yyyy hh:mm a"
                                calendarStartDay={1}
                            >
                            </DatePicker>
                        </div>
                        <ReactTooltip />
                    </div>
                    <Button className="btn btn-primary btn-sm">
                        Send
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default EmailsInboxReply