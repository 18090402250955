import React from 'react';
// import CSS from '../css/icon_main.css';

function WaitingForProposalplaceholderIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<g opacity="0.68">
					<path d="M64.1714 4.62695H15.8281" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M15.8281 75.373H64.1714" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M57.0976 21.6205C55.3471 22.006 53.2407 22.3135 50.9744 22.3135C44.8967 22.3135 39.9698 20.1027 39.9698 20.1027C39.9698 20.1027 35.0428 17.8918 28.9652 17.8918C26.0496 17.8918 23.3988 18.4006 21.4297 18.93" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M24.7202 75.373C21.9405 73.0359 20.25 69.9498 20.25 66.5665C20.25 49.9606 59.75 30.186 59.75 13.5801C59.75 10.1968 58.0597 6.9639 55.2799 4.62678" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M55.3286 75.373C54.2598 67.9565 44.4241 62.1081 40.0002 62.1081C35.5763 62.1081 25.7407 67.9565 24.6719 75.373" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M24.7202 4.62678C21.9405 6.9639 20.25 10.1968 20.25 13.5801C20.25 30.186 59.75 49.9606 59.75 66.5665C59.75 69.9498 58.0597 73.0359 55.2799 75.373" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M40 40.0005V48.8438" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M40 57.6868V62.1084" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
				</g>
			</svg>

		</React.Fragment>
	)
}

export default WaitingForProposalplaceholderIcon