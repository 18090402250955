import {
  Buildings,
  CalendarBlank,
  CaretDoubleDown,
  CurrencyEur,
  DotsThreeVertical,
  Envelope,
  EnvelopeSimple,
  File,
  FileText,
  Gauge,
  GearSix,
  Handshake,
  HardDrives,
  Headset,
  MagnifyingGlass,
  Phone,
  PhoneCall,
  PhoneDisconnect,
  UserGear,
  Users,
  UsersThree,
  Wrench,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SetSelectedTask,
  commanoffcanvasisOpen,
  dayGridDay,
  dayGridMonth,
  dayGridWeek,
  getDefaultTopics,
  leftPanelFilters,
  setDaterangeFilter,
  setDaterangeFilterApplied,
  setSelectedGloablView,
  setSelectedTabLeftPanel,
} from "../../../actions/customer";
import { useNavigate, useSearchParams } from "react-router-dom";
import UserProfileDropdown from "../UserProfileDropdown";
import {
  TaskModalClose,
  _l,
  addExpandedClasstoIntroOfficeList,
  formateDate,
  hideLeftPanelNew,
  hideRightPanelNew,
  showLeftPanelNew,
} from "../../../hooks/utilities";
import SolaireLogo from "../../../assets/images/blezlogo.svg";
import Solarlogo from "../../../assets/images/blzalogoicon.svg";
import Blazlogo from "../../../assets/images/blzalogoicon.svg";
import BlazlogoText from "../../../assets/images/blazlogotext.svg";
import jQuery from "jquery";
import {
  setShowAccordionVisibility,
  setShowIsChatScreenDisplay,
} from "../../../actions/chat";
import Autocomplete from "../../../components/Dropdowns/Autocomplete";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import ActionDropdown from "../../Dropdowns/ActionDropdown";
import jquery from "jquery";
import ReactTooltip from "react-tooltip";
import ReactHtmlParser from "react-html-parser";
import LeftPanelCategorySkeleton from "../../Skeleton/LeftPanelCategorySkeleton";

const projectStatusArray = [
  {
    id: 1,
    name: _l("l_todo"),
    class: "color-gray",
  },
  {
    id: 2,
    name: _l("l_free_trial"),
    class: "color-blue",
  },
  {
    id: 6,
    name: _l("l_active"),
    class: "color-green",
  },
  {
    id: 7,
    name: _l("l_inactive"),
    class: "color-white",
  },
];

const LeftMainMenu = ({
  setSettingTopicName = () => {},
  setTopicMainKey = () => {},
  getMentionUsersForFilter = () => {},
  setShowBackDropClass = () => {},
  setSpaceSearchOnGoing = () => {},
  isSolarHubPlatform = () => {},
  applyStaffUserFilter = () => {},
  mentionStaffArray = [],
  handleUserForSolarStaff = () => {},
  loginHandlerforsolarStaff = () => {},
  loginHandler = () => {},
  contactDirLoader= false,
  counts,
  totalCounts,
  supportChatCounts
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userType = localStorage.getItem("user_type");
  let scheduleData = localStorage.getItem("schedule_data");
  let customerScheduleData = localStorage.getItem("customer_schedule_data");
  const accepted_proposal = localStorage.getItem("accepted_proposal")
    ? localStorage.getItem("accepted_proposal")
    : "";
  const isProviderHasPlan =
    localStorage.getItem("is_solar_provider_plan_purchased") || "";
  const contact_role = localStorage.getItem("contact_role");

  const { leftPanelTab, commanoffcanvasIsOpen } = useSelector((state) => state.customer);
  const { groupListExternal } = useSelector((state) => state.chat);

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchString, setSearchString] = useState("");
  const [activeOfficePage, setActiveOfficePage] = useState(1);
  const [showProjectStatusList, setshowProjectStatusList] = useState(false);
  const [userList, setUserList] = useState([]);
  const [isLoginAsUserDataLoaded, setIsLoginAsUserDataLoaded] = useState([]);
  const [loder, setLoder] = useState(false);
  const [selectedspaceStatus, setSelectedspaceStatus] = useState(0);
  const [randomID, setRandomID] = useState(String(Math.random()));


  let activeGroupId = localStorage.getItem("activeGroupId");
  let SPACE_LIST_RESULTS_PER_PAGE = 10;

  const proposalId = searchParams.get("proposal_id");
  const id = searchParams.get("id");
  const page = searchParams.get("page");

  useEffect(() => {
    jQuery("body").addClass("with-left-penel");
  }, []);

  useEffect(() => {
    if (userType == "contact" || userType == "operator") {
      if (!["adminstrative-list" , "installation-list"].includes(leftPanelTab)) {
        // dispatch(setSelectedTabLeftPanel("proposal-list"));
      }
      showLeftPanelNew();
      if (userType === "operator") {
        // navigate("/?page=proposals");
      } else if (userType === "contact") {
        let tempScheduleData = customerScheduleData
          ? JSON.parse(customerScheduleData)
          : {};
        if (Object.keys(tempScheduleData).length) {
          navigate(
            `/?page=create-benchmark&space=${tempScheduleData.project_id}&type=benchmark&id=${tempScheduleData.benchmark_id}`
          );
          return;
        }
      };
    }
  }, []);

  return (
    <>
      <div
        id="intro-office-list"
        className="expanded  fixed-left-panel office-list intro-office-list new"
      >
        <div className="fixed-left-panel-wrapper h-100 d-flex flex-column">
          <div className="upper-part office-list-main overflow-visible flex-grow-0 mt-0 ">
            <div className="company-logo-box position-relative w-100 h70w70 p-0">
              <div
                className="d-flex align-items-center justify-content-between h-100 ps-20pximp mt-1"
                onClick={() => {
                  // dispatch(setSelectedTabLeftPanel("dashboard"));
                }}
              >
                <a className="d-flex align-items-center justify-content-between w100minus50  flex-grow-1">
                  <div
                    className={`w-100 h-100 d-flex align-items-center  office-list-icon  position-relative ${
                      leftPanelTab == "dashboard" ? "" : ""
                    }`}
                  >
                    {commanoffcanvasIsOpen || ["adminstrative-list", "installation-list","proposal-list"].includes(leftPanelTab) ?
                    <>
                    <div className="h50w50 w-auto comman-round-box radius_3 title-fonts d-flex flex-column square_box "
                      onClick={()=>{
                        localStorage.setItem("leftPanelTab", "homePage");
                        dispatch(leftPanelFilters("", "", "all"));
                        dispatch(setSelectedTabLeftPanel("homePage"));
                        addExpandedClasstoIntroOfficeList();
                        dispatch(commanoffcanvasisOpen(false));
                        TaskModalClose();
                        navigate("/")
                        showLeftPanelNew();
                      }}
                    >
                          <span className="m-auto c-font f-13 office-subtitle text-truncate hover-span">
                    <img className="w-auto" src={Solarlogo} alt="" />
                    </span>
                    </div>
                    <div className="align-item-center d-flex ms-4">
                      <a
                            href="#/"
                            className="c-font f-18 text-uppercase fw-semibold  text-truncate title-fonts w-100" 
                          >
                          {leftPanelTab == "adminstrative-list" 
                              ?  "Démarches"
                              : leftPanelTab == "installation-list" 
                              ? "Poses"
                              : leftPanelTab == "proposal-list" 
                              ? "CRM"
                              : "Blaz"}  
                      </a>
                    </div>
                    </>
                    :
                    <div className="h-100 d-flex align-items-center justify-content-center office-list-icon position-relative text-truncate">
                      { "support" == leftPanelTab 
                        ? <div className="h50w50 w-auto comman-round-box radius_3 title-fonts d-flex flex-column square_box me-4">
                            <span className="m-auto c-font f-13 office-subtitle text-truncate hover-span">
                              <img className="h30w30 w-auto" src={Solarlogo} alt="" />
                            </span>
                          </div>
                        : leftPanelTab == "homePage" && userType != "staff" ?
                        <div className="h50w50 w-auto comman-round-box radius_3 title-fonts d-flex flex-column square_box me-4"
                          onClick={()=>{
                            localStorage.setItem("leftPanelTab", "homePage");
                            dispatch(leftPanelFilters("", "", "all"));
                            addExpandedClasstoIntroOfficeList();
                            dispatch(setSelectedTabLeftPanel("homePage"));
                            dispatch(commanoffcanvasisOpen(false));
                            TaskModalClose();
                            navigate("/")
                            showLeftPanelNew();
                          }}
                          >
                            <span className="m-auto c-font f-13 office-subtitle text-truncate hover-span">
                              <img className="h30w30 w-auto" src={Solarlogo} alt="" />
                            </span>
                          </div> 
                        :
                        <></> 
                      }
                      <a
                          href="#/"
                          className="c-font f-18 text-uppercase fw-semibold  text-truncate title-fonts w-100" 
                        >
                          {leftPanelTab == "proposal-list"
                            ? "CRM"
                            : leftPanelTab == "my-clients"
                            ? userType == "staff" ? <img src={SolaireLogo} alt="" className="h45w45 w-auto" /> : _l("l_clients")
                            : leftPanelTab == "my-spaces"
                            ? _l("l_my_space")
                            : leftPanelTab == "support"
                            ? _l("l_intro_header_support")
                            : leftPanelTab == "adminstrative-list" 
                            ?  "Démarches"
                            : leftPanelTab == "installation-list" 
                            ? "Poses"
                            : leftPanelTab == "dashboard" 
                            ? <img src={SolaireLogo} alt="" className="h45w45 w-auto" />
                            : leftPanelTab == "homePage" 
                            ? <>{userType == "staff" ? <img src={SolaireLogo} alt="" className="h45w45 w-auto" /> : localStorage.getItem("company_name") }</>
                            : leftPanelTab == "my-task" 
                            ? _l("my_task")
                            : "" }
                        </a>
                    </div>
                    }
                  </div>
                </a>
                <a className="list-right-part d-none">
                  <div className="d-flex align-items-center comman_action_icon flex-grow-1 pe-20pximp">
                    <div className=" action_icon h25w25 ">
                      <DotsThreeVertical
                        size={16}
                        weight="light"
                        className="c-icons"
                      />
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
         
          <ul className="office-list-main  mt-0 pt-10px" id="left-office-list">
          <li
                className={`office-box p-0 ${
                  leftPanelTab == "my-request" ? "active" : ""
                }`}
              >
                  <a
                    className="w-100 d-flex align-items-center justify-content-between p-20px px-0 h70w70 px-0"
                    onClick={() => {
                      localStorage.setItem("leftPanelTab", "my-request");
                      dispatch(setSelectedTabLeftPanel("my-request"));
                      navigate("/");
                      dispatch(SetSelectedTask({}));
                      dispatch(leftPanelFilters("","","applyForMewithAll"));
                      dispatch(setSelectedGloablView("listView"));
                      dispatch(setDaterangeFilter("all"))
                      dispatch(setDaterangeFilterApplied(true));
                      dispatch(setShowAccordionVisibility(true));
                        dispatch(commanoffcanvasisOpen(false));
                        addExpandedClasstoIntroOfficeList();
                        TaskModalClose();
                    }}
                  >
                    <div className="d-flex align-items-center comman_action_icon flex-grow-1 pe-20pximp">
                      <div
                        className={`d-flex flex-column justify-content-center align-items-center  w-70px`}
                      >
                        <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                          <div className="action_icon h30w30 bg-transparent">
                            <Gauge
                              size={28}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                          {commanoffcanvasIsOpen ? 
                       <span className="color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">{_l("l_my_task")}</span> 
                      : <React.Fragment></React.Fragment>}
                        </div>
                      </div>
                      <div className="c-font f-15 ">{_l("l_my_task")}</div>
                    </div>
                  </a>
              </li>

            {userType === "operator" ||
            (userType === "contact" ) ? (
              <li
                className={`office-box p-0 ${
                  leftPanelTab == "proposal-list" ? "active" : ""
                }`}
              >
                  <a
                    className="w-100 d-flex align-items-center justify-content-between p-20px px-0 h70w70 px-0"
                    onClick={() => {
                      if (leftPanelTab !== "proposal-list") {
                        localStorage.setItem("leftPanelTab", "proposal-list");
                        dispatch(leftPanelFilters("", "", "all"));
                        dispatch(setShowAccordionVisibility(true));
                        dispatch(commanoffcanvasisOpen(false));
                        addExpandedClasstoIntroOfficeList();
                        dispatch(setSelectedTabLeftPanel("proposal-list"));
                        TaskModalClose();
                        
                        if (userType === "operator") {
                          navigate("/?page=proposals");
                        } else if (userType === "contact") {
                          let tempScheduleData = customerScheduleData
                            ? JSON.parse(customerScheduleData)
                            : {};
                          if (Object.keys(tempScheduleData).length) {
                            navigate(
                              `/?page=create-benchmark&space=${tempScheduleData.project_id}&type=benchmark&id=${tempScheduleData.benchmark_id}`
                            );
                            return;
                          }
                        }
                      }
                    }}
                  >
                    <div className="d-flex align-items-center comman_action_icon flex-grow-1 pe-20pximp">
                      <div
                        className={`d-flex flex-column justify-content-center align-items-center  w-70px`}
                      >
                        <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                          <div className="action_icon h30w30 bg-transparent">
                            {" "}
                            <Handshake
                              size={28}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                          {commanoffcanvasIsOpen ? 
                       <span className="color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">{userType == "contact" ? _l("l_my_offers") : _l("CRM")}</span> 
                      : <React.Fragment></React.Fragment>}
                        </div>
                      </div>
                      <div className="c-font f-15 ">{userType == "contact" ? _l("l_my_offers") : _l("CRM")}</div>
                    </div>
                  </a>
              </li>
            ) : (
              userType == "staff" ? 
              <>
              <li
              className={`office-box p-0 ${
                leftPanelTab == "my-clients" ? "active" : ""
              }`}
            >
                <a
                  className="w-100 d-flex align-items-center justify-content-between p-20px px-0 h70w70 px-0"
                  onClick={() => {
                    localStorage.setItem("leftPanelTab", "my-clients");
                    dispatch(leftPanelFilters("", "", "all"));
                    navigate("/")
                    dispatch(commanoffcanvasisOpen(false));
                    TaskModalClose();
                    dispatch(setSelectedTabLeftPanel("my-clients"));
                  }}
                >
                  <div className="d-flex align-items-center comman_action_icon flex-grow-1 pe-20pximp">
                    <div
                      className={`d-flex flex-column justify-content-center align-items-center  w-70px`}
                    >
                      <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                        <div className="action_icon h30w30 bg-transparent">
                          <UsersThree
                            size={28}
                            weight="light"
                            className="c-icons"
                          />
                        </div>
                      {commanoffcanvasIsOpen ? 
                      <span className="color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">Clients</span>
                      : <React.Fragment></React.Fragment>}
                      </div>
                    </div>
                    <div className="office-list-detail w100minus50 left-toggle-none ps-0">
                      <div className="office-list-desc-left w-100">
                        <div className="align-items-center d-flex justify-content-between">
                          <div className="d-flex align-items-center text-truncate justify-content-between w-100">
                            <div className="c-font f-15 ">Clients</div>
                            {totalCounts > 0 &&
                              <div className="badge-custom badge-green d-flex justify-content-center position-static rounded-circle">
                                <span className={`m-auto ps-0`}>
                                  {totalCounts}
                                </span>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
            </li>
              <li
              className={`office-box p-0 ${
                leftPanelTab == "my-staff" ? "active" : ""
              }`}
            >
                <a
                  className="w-100 d-flex align-items-center justify-content-between p-20px px-0 h70w70 px-0"
                  onClick={() => {
                    if (leftPanelTab != "my-staff") {
                      TaskModalClose();
                      localStorage.setItem("leftPanelTab", "my-staff");
                      dispatch(getDefaultTopics(0, "", "", "homepage", '', true));
                      dispatch(setSelectedTabLeftPanel("my-staff"));
                      dispatch(leftPanelFilters("", "", "all"));
                      navigate("/");
                      hideRightPanelNew();
                      showLeftPanelNew();
                      dispatch(commanoffcanvasisOpen(false));
                    }
                  }}
                >
                  <div className="d-flex align-items-center comman_action_icon flex-grow-1 pe-20pximp">
                    <div
                      className={`d-flex flex-column justify-content-center align-items-center  w-70px`}
                    >
                      <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                        <div className="action_icon h30w30 bg-transparent">
                          <Users
                            size={28}
                            weight="light"
                            className="c-icons"
                          />
                        </div>
                      {commanoffcanvasIsOpen ? 
                      <span className="color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">{_l("l_staff")}</span>
                      : <React.Fragment></React.Fragment>}
                      </div>
                    </div>
                    <div className="office-list-detail w100minus50 left-toggle-none ps-0">
                      <div className="office-list-desc-left w-100">
                        <div className="align-items-center d-flex justify-content-between">
                          <div className="d-flex align-items-center text-truncate justify-content-between w-100">
                            <div className="c-font f-15 ">{_l("l_staff")}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
            </li>
            <li
              className={`office-box p-0 ${
                leftPanelTab == "" ? "active" : ""
              }`}
            >
                <a
                  className="w-100 d-flex align-items-center justify-content-between p-20px px-0 h70w70 px-0"
                  onClick={() => {
                  
                  }}
                >
                  <div className="d-flex align-items-center comman_action_icon flex-grow-1 pe-20pximp">
                    <div
                      className={`d-flex flex-column justify-content-center align-items-center  w-70px`}
                    >
                      <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                        <div className="action_icon h30w30 bg-transparent">
                          <PhoneDisconnect
                            size={28}
                            weight="light"
                            className="c-icons"
                          />
                        </div>
                      {commanoffcanvasIsOpen ? 
                      <span className="color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">{_l("l_leads")}</span>
                      : <React.Fragment></React.Fragment>}
                      </div>
                    </div>
                    <div className="office-list-detail w100minus50 left-toggle-none ps-0">
                      <div className="office-list-desc-left w-100">
                        <div className="align-items-center d-flex justify-content-between">
                          <div className="d-flex align-items-center text-truncate justify-content-between w-100">
                            <div className="c-font f-15 ">{_l("l_leads")}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
            </li>
              </>
              :<></>
            )}

            {(userType === "contact" && accepted_proposal > 0) ? (
              <>
                <li
                  className={`office-box p-0 ${
                    leftPanelTab == "my-spaces" ? "active" : ""
                  }`}
                >
                    <a
                      className="w-100 d-flex align-items-center justify-content-between p-20px px-0 h70w70 px-0"
                      onClick={() => {
                        localStorage.setItem("leftPanelTab", "my-spaces");
                        dispatch(leftPanelFilters("", "", "all"));
                        dispatch(setShowAccordionVisibility(true));
                        dispatch(commanoffcanvasisOpen(false));
                        addExpandedClasstoIntroOfficeList();
                        dispatch(setSelectedTabLeftPanel("my-spaces"));
                        TaskModalClose();
                        // navigate("/");
                      }}
                    >
                      <div className="d-flex align-items-center comman_action_icon flex-grow-1 pe-20pximp">
                          <div className="d-flex flex-column justify-content-center align-items-center  w-70px">
                            <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                              <div className="action_icon h30w30 bg-transparent">
                                  {" "}
                                  <Buildings
                                    size={28}
                                    weight="light"
                                    className="c-icons"
                                  />
                              </div>
                            </div>
                            {commanoffcanvasIsOpen ? 
                          <span className="color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">spaces</span>
                          : <React.Fragment></React.Fragment>}
                          </div>
                        <div className="office-list-detail w100minus50 left-toggle-none ps-0">
                          <div className="office-list-desc-left w-100">
                            <div className="align-items-center d-flex justify-content-between">
                              <div className="d-flex align-items-center text-truncate justify-content-between w-100">
                                <div className="c-font f-15 ">My spaces</div>
                                {/* {totalNotificationCounts > 0 ? <span class="rounded-pill badge-custom  d-flex p-2 light-pill position-relative top-0 end-0"> {totalNotificationCounts}</span> : <></>} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                </li>
              </>
            ) : (
              <></>
            )}
            
              {userType === "operator" ? <>
              <li
                className={`office-box p-0 ${
                  leftPanelTab == "adminstrative-list" ? "active" : ""
                }`}
              >
                  <a className="w-100 d-flex align-items-center justify-content-between p-20px px-0 h70w70 px-0" href="#/" onClick={()=>{
                    localStorage.setItem("leftPanelTab", "adminstrative-list");
                    dispatch(leftPanelFilters("", "", "all"));
                    dispatch(commanoffcanvasisOpen(false));
                    dispatch(setSelectedTabLeftPanel("adminstrative-list"));
                    TaskModalClose();
                    addExpandedClasstoIntroOfficeList();
                    if (userType === "operator") {
                      navigate("/?page=proposals");
                    } else if (userType === "contact") {
                      let tempScheduleData = customerScheduleData
                        ? JSON.parse(customerScheduleData)
                        : {};
                      if (Object.keys(tempScheduleData).length) {
                        navigate(
                          `/?page=create-benchmark&space=${tempScheduleData.project_id}&type=benchmark&id=${tempScheduleData.benchmark_id}`
                        );
                        return;
                      }
                    }
                  }}>
                    <div className="d-flex align-items-center comman_action_icon flex-grow-1 pe-20pximp">
                      <div
                        className={`d-flex flex-column justify-content-center align-items-center  w-70px`}
                      >
                        <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                          <div className="action_icon h30w30 bg-transparent">
                            <UserGear
                              size={28}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                          {commanoffcanvasIsOpen ? 
                       <span className="color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">Démarches</span> 
                      : <React.Fragment></React.Fragment>}
                        </div>
                      </div>
                      <div className="c-font f-15 ">Démarches</div>
                    </div>
                  </a>
              </li>
              <li
                className={`office-box p-0  ${
                  leftPanelTab == "installation-list" ? "active" : ""
                }`}
              >
                  <a className="w-100 d-flex align-items-center justify-content-between p-20px px-0 h70w70 px-0" href="#/" onClick={()=>{
                    localStorage.setItem("leftPanelTab", "installation-list");
                    dispatch(commanoffcanvasisOpen(false));
                    dispatch(leftPanelFilters("", "", "all"));
                    dispatch(setSelectedTabLeftPanel("installation-list"));
                    TaskModalClose();
                    addExpandedClasstoIntroOfficeList();
                    if (userType === "operator") {
                      navigate("/?page=proposals");
                    } else if (userType === "contact") {
                      let tempScheduleData = customerScheduleData
                        ? JSON.parse(customerScheduleData)
                        : {};
                      if (Object.keys(tempScheduleData).length) {
                        navigate(
                          `/?page=create-benchmark&space=${tempScheduleData.project_id}&type=benchmark&id=${tempScheduleData.benchmark_id}`
                        );
                        return;
                      }
                    }
                  }}>
                    <div className="d-flex align-items-center comman_action_icon flex-grow-1 pe-20pximp">
                      <div
                        className={`d-flex flex-column justify-content-center align-items-center  w-70px`}
                      >
                        <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                          <div className="action_icon h30w30 bg-transparent">
                            <Wrench
                              size={28}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                          {commanoffcanvasIsOpen ? 
                       <span className="color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">Poses</span> 
                      : <React.Fragment></React.Fragment>}
                        </div>
                      </div>
                      <div className="c-font f-15 ">Poses</div>
                    </div>
                  </a>
              </li> </> : <></>}
              {!["contact", "staff"].includes(userType) ?
            <li
              className={`office-box p-0 ${
                leftPanelTab == "my-clients" ? "active" : ""
              }`}
            >
                <a
                  className="w-100 d-flex align-items-center justify-content-between p-20px px-0 h70w70 px-0"
                  onClick={() => {
                    localStorage.setItem("leftPanelTab", "my-clients");
                    dispatch(leftPanelFilters("", "", "all"));
                    dispatch(setShowAccordionVisibility(true));
                    dispatch(commanoffcanvasisOpen(false));
                    addExpandedClasstoIntroOfficeList();
                    localStorage.setItem("currentView", "AichatView");
                    dispatch(setSelectedGloablView("AichatView"));
                    dispatch(setSelectedTabLeftPanel("my-clients"));
                    TaskModalClose();
                    navigate("/");
                  }}
                >
                  <div className="d-flex align-items-center comman_action_icon flex-grow-1 pe-20pximp">
                    <div
                      className={`d-flex flex-column justify-content-center align-items-center  w-70px`}
                    >
                      <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                        <div className="action_icon h30w30 bg-transparent">
                          <UsersThree
                            size={28}
                            weight="light"
                            className="c-icons"
                          />
                        </div>
                      {commanoffcanvasIsOpen ? 
                      <span className="color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">Clients</span>
                      : <React.Fragment></React.Fragment>}
                      </div>
                    </div>
                    <div className="office-list-detail w100minus50 left-toggle-none ps-0">
                      <div className="office-list-desc-left w-100">
                        <div className="align-items-center d-flex justify-content-between">
                          <div className="d-flex align-items-center text-truncate justify-content-between w-100">
                            <div className="c-font f-15 ">Clients</div>
                            {counts > 0 ? (
                              <span class="rounded-pill badge-custom  d-flex p-2 light-pill position-relative top-0 end-0">
                                {" "}
                                {counts}
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
            </li> : <></>
            }

            
          </ul>
          <div className={`lower-part mt-0 pt-0 ${userType == "staff" ? "pt-0" : ""} `}>
          <ul className="office-list-main" id="left-office-list">
            <li
              className={`office-box p-0`}
            >
                <a
                  className="w-100 d-flex align-items-center justify-content-between p-20px px-0 h70w70 px-0"
                >
                  <div className="d-flex align-items-center comman_action_icon flex-grow-1 pe-20pximp">
                    <div
                      className={`d-flex flex-column justify-content-center align-items-center  w-70px`}
                    >
                      <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                        <div className="action_icon h30w30 bg-transparent">
                          <Envelope
                            size={28}
                            weight="light"
                            className="c-icons"
                          />
                        </div>
                      {commanoffcanvasIsOpen ? 
                      <span className="color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">{_l("l_email")}</span>
                      : <React.Fragment></React.Fragment>}
                      </div>
                    </div>
                    <div className="office-list-detail w100minus50 left-toggle-none ps-0">
                      <div className="office-list-desc-left w-100">
                        <div className="align-items-center d-flex justify-content-between">
                          <div className="d-flex align-items-center text-truncate justify-content-between w-100">
                            <div className="c-font f-15 ">{_l("l_email")}</div>
                            {/* <span class="rounded-pill badge-custom  d-flex p-2 light-pill position-relative top-0 end-0">5</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
            </li>
          {(userType === "operator" || userType === "staff"  )||
            (userType == "contact" && accepted_proposal > 0) ? (
              <li
                className={`office-box p-0 ${
                  leftPanelTab == "my-drive" ? "active" : ""
                }`}
              >
                  <a
                    className="w-100 d-flex align-items-center justify-content-between p-20px px-0 h70w70 px-0"
                    onClick={() => {
                      // if (userType === "operator") {
                      //   dispatch(setSelectedTabLeftPanel("my-documents"));
                      //   navigate("/?page=document");
                      //   hideLeftPanelNew();
                      // } else if(userType == "contact" && accepted_proposal > 0){
                      // setSettingTopicName("l_drive");
                      // setTopicMainKey("documents");
                      // dispatch(setShowIsChatScreenDisplay(true));
                      // dispatch(setShowAccordionVisibility(false));
                      // }
                      if (leftPanelTab != "my-drive") {
                        TaskModalClose();
                        localStorage.setItem("leftPanelTab", "my-drive");
                        dispatch(leftPanelFilters("", "", "all"));
                        dispatch(setSelectedTabLeftPanel("my-drive"));
                        navigate("/my-drive");
                        hideRightPanelNew();
                        showLeftPanelNew();
                        dispatch(commanoffcanvasisOpen(false));
                        dispatch(setDaterangeFilter("all"))
                        dispatch(setDaterangeFilterApplied(true));
                      }
    
                    }}
                  >
                    <div className="d-flex align-items-center comman_action_icon flex-grow-1 pe-20pximp">
                      <div
                        className={`d-flex flex-column justify-content-center align-items-center  w-70px ${
                          leftPanelTab == "my-drive" ? "active" : ""
                        }`}
                      >
                        <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                          <div className="action_icon h30w30 bg-transparent">
                            {" "}
                            <HardDrives
                              size={28}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        {commanoffcanvasIsOpen ? 
                        <span className="color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">Drive</span>
                        : <React.Fragment></React.Fragment>}
                        </div>
                      </div>
                      <div className="office-list-detail w100minus50 left-toggle-none ps-0">
                        <div className="office-list-desc-left w-100">
                          <div className="align-items-center d-flex justify-content-between">
                            <div className="d-flex align-items-center text-truncate justify-content-between w-100">
                              <div className="c-font f-15 ">Drive</div>
                              {/* <span class="rounded-pill badge-custom  d-flex p-2 light-pill position-relative top-0 end-0">5</span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
              </li>
            ) : (
              <></>
            )}
            <li
            className={`office-box p-0 ${
                leftPanelTab == "my-calendar" ? "active" : ""
              }`}
            >
                <a
                  className="w-100 d-flex align-items-center justify-content-between p-20px px-0 h70w70 px-0"
                  onClick={() => {
                    if (leftPanelTab != "my-calendar") {
                      TaskModalClose();
                      localStorage.setItem("leftPanelTab", "my-calendar");;
                      dispatch(setSelectedTabLeftPanel("my-calendar"));
                      navigate("/");
                      hideRightPanelNew();
                      showLeftPanelNew();
                      dispatch(commanoffcanvasisOpen(false));
                      dispatch(setSelectedGloablView("calendarView"));
                      dispatch(dayGridDay(false));
                      dispatch(dayGridWeek(false));
                      dispatch(dayGridMonth(true));
                      dispatch(setDaterangeFilterApplied(true));
                      dispatch(leftPanelFilters("","","applyForMeAndMonth"));
                      localStorage.setItem("fromDate",formateDate(moment(new Date()).startOf('month')));
                      localStorage.setItem("toDate", formateDate(moment(new Date()).endOf('month')));

                    }
  
                  }}
                >
                  <div className="d-flex align-items-center comman_action_icon flex-grow-1 pe-20pximp">
                    <div
                      className={`d-flex flex-column justify-content-center align-items-center  w-70px`}
                    >
                      <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                        <div className="action_icon h30w30 bg-transparent">
                          <CalendarBlank
                            size={28}
                            weight="light"
                            className="c-icons"
                          />
                        </div>
                      {commanoffcanvasIsOpen ? 
                      <span className="color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">{_l("l_calendar")}</span>
                      : <React.Fragment></React.Fragment>}
                      </div>
                    </div>
                    <div className="office-list-detail w100minus50 left-toggle-none ps-0">
                      <div className="office-list-desc-left w-100">
                        <div className="align-items-center d-flex justify-content-between">
                          <div className="d-flex align-items-center text-truncate justify-content-between w-100">
                            <div className="c-font f-15 ">{_l("l_calendar")}</div>
                            {/* <span class="rounded-pill badge-custom  d-flex p-2 light-pill position-relative top-0 end-0">5</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
            </li>
           {userType === "staff"  ? <></>  :
           <li
              className={`office-box p-0 ${
                leftPanelTab == "support" ? "active" : ""
              }`}
            >
                <a
                  className="w-100 d-flex align-items-center justify-content-between p-20px px-0 h70w70 px-0"
                  onClick={() => {
                    localStorage.setItem("leftPanelTab", "support");
                    TaskModalClose();
                    dispatch(leftPanelFilters("", "", "all"));
                    dispatch(commanoffcanvasisOpen(false));
                    dispatch(setSelectedTabLeftPanel("support"));
                    navigate(
                      proposalId
                        ? `/aichat?proposal_id=${proposalId}`
                        : `/aichat?proposal_id=${0}`
                    );
                  }}
                >
                  <div className="d-flex align-items-center comman_action_icon flex-grow-1 pe-20pximp">
                    <div
                      className={`d-flex flex-column justify-content-center align-items-center  w-70px ${
                        leftPanelTab == "support" ? "active" : ""
                      }`}
                    >
                      <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                        <div className="action_icon h30w30 bg-transparent">
                          {" "}
                          <Headset
                            size={28}
                            weight="light"
                            className="c-icons"
                          />
                        </div>
                      { commanoffcanvasIsOpen ? 
                      <span className="color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8">Support</span>
                      : <React.Fragment></React.Fragment>}
                      </div>
                    </div>
                    <div className="office-list-detail w100minus50 left-toggle-none ps-0">
                      <div className="office-list-desc-left w-100">
                        <div className="align-items-center d-flex justify-content-between">
                          <div className="d-flex align-items-center text-truncate justify-content-between w-100">
                            <div className="c-font f-15 ">Support</div>
                            {supportChatCounts > 0 &&  
                              <div className="badge-custom badge-green d-flex justify-content-center position-static rounded-circle">
                                <span className={`m-auto ps-0`}>
                                  {supportChatCounts}
                                </span>
                              </div>
                            }
                            {/* <span class="rounded-pill badge-custom  d-flex p-2 light-pill position-relative top-0 end-0">5</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
            </li>
            }

            {/* {parseInt(contact_role) != 1 || !isProviderHasPlan.length ? (
              <></>
            ) : (
              <li className={`office-box p-0`}>
                  <a
                    className="w-100 d-flex align-items-center justify-content-between p-20px px-0 h70w70 px-0"
                    onClick={() => {
                      navigate("/premiumsubscription");
                    }}
                  >
                    <div className="d-flex align-items-center comman_action_icon flex-grow-1 pe-20pximp">
                      <div
                        className={`d-flex flex-column justify-content-center align-items-center  w-70px `}
                      >
                        <div className="p-20 py-0 text-center d-flex flex-column align-items-center">
                          <div className="action_icon h30w30 bg-transparent">
                            {" "}
                            <CurrencyEur
                              size={28}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        {commanoffcanvasIsOpen ? 
                        <span className="color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span hover-span line-height-1 px-1 text-truncate text-center w-70px line-height-1-8"> {_l("l_my_plan")}</span>
                      :<React.Fragment></React.Fragment>}
                        </div>
                      </div>
                      <div className="office-list-detail w100minus50 left-toggle-none ps-0">
                        <div className="office-list-desc-left w-100">
                          <div className="align-items-center d-flex justify-content-between">
                            <div className="d-flex align-items-center text-truncate justify-content-between w-100">
                              <div className="c-font f-15 "> {_l("l_my_plan")}</div>
                             </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
              </li>
            )} */}
          </ul>
            <div className={`position-relative left-option-box d-flex m-0 office-box`}>
              <a
                href="#/"
                className={`d-flex align-items-center w-100 h70w70`}
                onClick={() => {}}
              >
                <div className="comman-bg-img w-100 d-flex on-hover-active-toggle-img">
                  <UserProfileDropdown
                    selectLiteVersion={() => {}}
                    setSelectedProject={() => {}}
                  />
                </div>
              </a>
              {/* {userType == "staff" &&<div class="flex-grow-1 align-items-center d-flex radius_3 title-fonts color-white-80  left-toggle-none cursor-pointer">
                <div class="d-flex w-85  align-items-center">
                  <div class="d-flex align-items-center justify-content-between w100minus100 flex-grow-1 pe-1">
                    <div class="comman-list-wrapper-main flex-grow-1 left-toggle-none w100minus120">
                      <div class="list-left-part text-truncate">
                        <a
                          href="#/"
                          class="text-truncate fw-semibold c-font f-14 title-fonts w-100 lh-1"
                        >
                          {localStorage.getItem("full_name")}
                        </a>
                        <div
                          href="#/"
                          class="text-truncate c-font f-10 w-100 color-white-80 lh-1"
                        >
                          My profile
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="comman_action_icon ms-10px me-10px">
                  <a href="#/" className="action_icon  h32w32">
                    <DotsThreeVertical size={24} className="c-icons" weight="light" />
                  </a>
                </div>
              </div>} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftMainMenu;
