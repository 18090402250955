import React from 'react';
// import CSS from '../css/icon_main.css';

function SelectionAll(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
			
			<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="" viewBox="0 0 256 256" className={`comman-icons with-fill ${CommanHW}`}><path d="M106,40a6,6,0,0,1,6-6h32a6,6,0,0,1,0,12H112A6,6,0,0,1,106,40Zm38,170H112a6,6,0,0,0,0,12h32a6,6,0,0,0,0-12ZM208,34H184a6,6,0,0,0,0,12h24a2,2,0,0,1,2,2V72a6,6,0,0,0,12,0V48A14,14,0,0,0,208,34Zm8,72a6,6,0,0,0-6,6v32a6,6,0,0,0,12,0V112A6,6,0,0,0,216,106Zm0,72a6,6,0,0,0-6,6v24a2,2,0,0,1-2,2H184a6,6,0,0,0,0,12h24a14,14,0,0,0,14-14V184A6,6,0,0,0,216,178ZM40,150a6,6,0,0,0,6-6V112a6,6,0,0,0-12,0v32A6,6,0,0,0,40,150Zm32,60H48a2,2,0,0,1-2-2V184a6,6,0,0,0-12,0v24a14,14,0,0,0,14,14H72a6,6,0,0,0,0-12ZM40,78a6,6,0,0,0,6-6V48a2,2,0,0,1,2-2H72a6,6,0,0,0,0-12H48A14,14,0,0,0,34,48V72A6,6,0,0,0,40,78ZM176,182H80a6,6,0,0,1-6-6V80a6,6,0,0,1,6-6h96a6,6,0,0,1,6,6v96A6,6,0,0,1,176,182Zm-6-96H86v84h84Z"></path></svg>
		</React.Fragment>
	)
}

export default SelectionAll