import React from 'react';
// import CSS from '../css/icon_main.css';

function AddScheduleIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="12" height="14" viewBox="0 0 12 14" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<path d="M7 1H1C0.867392 1 0.740215 1.05268 0.646447 1.14645C0.552679 1.24021 0.5 1.36739 0.5 1.5V12.5C0.5 12.6326 0.552679 12.7598 0.646447 12.8536C0.740215 12.9473 0.867392 13 1 13H5.25M7 1L10.5 4.5M7 1V4.5H10.5M10.5 4.5V6.75M3 7H7M3 9H7M9.39136 9.21439L10.786 10.6091M8.3125 13H7.19924C7.1464 13 7.09572 12.979 7.05836 12.9417C7.02099 12.9043 7 12.8536 7 12.8008V11.6875C6.99991 11.6616 7.00492 11.636 7.01475 11.6121C7.02458 11.5881 7.03903 11.5664 7.05728 11.548L10.0459 8.55944C10.0644 8.54061 10.0865 8.52566 10.1109 8.51546C10.1353 8.50526 10.1614 8.5 10.1878 8.5C10.2143 8.5 10.2404 8.50526 10.2648 8.51546C10.2892 8.52566 10.3113 8.54061 10.3298 8.55944L11.4406 9.6702C11.4594 9.68874 11.4743 9.71084 11.4846 9.73521C11.4948 9.75958 11.5 9.78574 11.5 9.81216C11.5 9.83858 11.4948 9.86474 11.4846 9.88911C11.4743 9.91348 11.4594 9.93558 11.4406 9.95412L8.45197 12.9427C8.43363 12.961 8.41187 12.9754 8.38794 12.9853C8.364 12.9951 8.33837 13.0001 8.3125 13Z" strokeLinecap="round" strokeLinejoin="round" fill="none" />
			</svg>

		</React.Fragment>
	)
}

export default AddScheduleIcon