import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CardDetails from "./EditcardDetailsModalTabs/CardDetails";
import Address from "./EditcardDetailsModalTabs/Address";
import { _l } from "../../hooks/utilities";
const EditcardDetailsModal = ({
show,
handleClose,
handleShow,
}) => {
const [selectedTab, setSelectedTab] =  useState("carddetails");
return (
<>
<Modal
    show={show}
    onHide={handleClose}
    className="custom-modal-style"
    aria-labelledby="contained-modal-title-vcenter"
    size="md"
    centered
    >
    <Modal.Header closeButton>
        <Modal.Title>{_l('l_edit_card_details')}</Modal.Title>
    </Modal.Header>
    <Modal.Body className=" pt-0">
        <div className="form-wrapper-main pt-0">
            <div className="tab-wrapper_main pb-3 res-overflow-auto">
                <div className="d-flex align-items-center justify-content-between border-bottom">
                    <div className="comman-tab-wrapper pb-0">
                        <div className="d-flex align-items-center">
                            <div className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === "carddetails" ? "active" : ""
                            }`}>
                            <a href="#/" className="tab-name m-0"
                                onClick={() => {
                            setSelectedTab("carddetails");
                            }}
                            >
                            {_l('l_card_details')}
                            </a>
                        </div>
                        <div className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === "address" ? "active" : ""
                        }`}>
                        <a href="#/" className="tab-name"
                            onClick={() => {
                        setSelectedTab("address");
                        }}
                        >
                        {_l('l_address')}
                        </a>
                    </div>
            </div>
        </div>
        </div>
        </div>
            <div className="">
            {selectedTab === "carddetails" ? (
            <CardDetails />
            ) : (
            <Address />
        )}
            </div>
        </div>
    </Modal.Body>
    <Modal.Footer className="border-0 d-flex justify-content-between">
        <Button variant="secondary" size="sm" onClick={handleClose}>
        {_l('l_close')}
        </Button>
        <Button variant="primary" size="sm">{_l('l_save')}</Button>
    </Modal.Footer>
</Modal>
</>
);
};
export default EditcardDetailsModal;