import React from 'react';
// import CSS from '../css/icon_main.css';

function NoSpace(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

			<svg width="167" height="167" viewBox="0 0 167 167" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons w-100 h-100 ${CommanHW}`}>
				<path d="M10.4375 140.906H156.562" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M93.9375 140.906V26.0938C93.9375 24.7097 93.3877 23.3822 92.409 22.4035C91.4303 21.4248 90.1028 20.875 88.7188 20.875H26.0938C24.7097 20.875 23.3822 21.4248 22.4035 22.4035C21.4248 23.3822 20.875 24.7097 20.875 26.0938V140.906" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M146.125 140.906V67.8438C146.125 66.4597 145.575 65.1322 144.596 64.1535C143.618 63.1748 142.29 62.625 140.906 62.625H93.9375" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M41.75 46.9688H62.625" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M52.1875 88.7188H73.0625" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M41.75 114.812H62.625" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M114.812 114.812H125.25" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M114.812 88.7188H125.25" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
			</svg>


		</React.Fragment>
	)
}

export default NoSpace