import React from 'react';
// import CSS from '../css/icon_main.css';

function InfoIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(	
		// className={`comman-icons ${CommanHW}`}	
		<React.Fragment>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"   className={`comman-icons ${CommanHW}`}>
<path d="M7.5 7.35C7.41716 7.35 7.35 7.41716 7.35 7.5C7.35 7.58284 7.41716 7.65 7.5 7.65V7.35ZM8 7.5H8.15C8.15 7.41716 8.08284 7.35 8 7.35V7.5ZM8 11H7.85C7.85 11.0828 7.91716 11.15 8 11.15V11ZM8.5 11.15C8.58284 11.15 8.65 11.0828 8.65 11C8.65 10.9172 8.58284 10.85 8.5 10.85V11.15ZM7.85 5.3C7.85 5.38284 7.91716 5.45 8 5.45C8.08284 5.45 8.15 5.38284 8.15 5.3H7.85ZM8.15 5C8.15 4.91716 8.08284 4.85 8 4.85C7.91716 4.85 7.85 4.91716 7.85 5H8.15ZM13.85 8C13.85 11.2309 11.2309 13.85 8 13.85V14.15C11.3966 14.15 14.15 11.3966 14.15 8H13.85ZM8 13.85C4.76913 13.85 2.15 11.2309 2.15 8H1.85C1.85 11.3966 4.60345 14.15 8 14.15V13.85ZM2.15 8C2.15 4.76913 4.76913 2.15 8 2.15V1.85C4.60345 1.85 1.85 4.60345 1.85 8H2.15ZM8 2.15C11.2309 2.15 13.85 4.76913 13.85 8H14.15C14.15 4.60345 11.3966 1.85 8 1.85V2.15ZM7.5 7.65H8V7.35H7.5V7.65ZM7.85 7.5V11H8.15V7.5H7.85ZM8 11.15H8.5V10.85H8V11.15ZM8.15 5.3V5H7.85V5.3H8.15Z" fill="white"/>
</svg>





		</React.Fragment>
	)
}

export default InfoIcon