import React from 'react'
const PriceCatalogDetailsProposalSkeleton = () => {
  return (
    <>
        {
          [0, 1, 2].map((s, index) => {
            return (<>
              <div className="d-flex justify-content-between align-items-center p-20 border-last-none border-bottom SkeletonMain ">
                <div className='d-flex align-items-center flex-grow-1'>
                <div className='h35w35 skeleton-animation radius_3 me-20px'></div>
                <div className='skeleton-animation radius_3' style={{ width: '200px', height: '15px' }}></div>
                </div>
                <div className='d-flex align-items-center gap10px'>
                <div className='radius_3 skeleton-animation' style={{ width: '100px', height: '55px' }}></div>
                  <div className='radius_3 skeleton-animation' style={{ width: '55px', height: '55px' }}></div>
                  <div className='radius_3 skeleton-animation' style={{ width: '55px', height: '55px' }}></div>
                  <div className='radius_3 skeleton-animation' style={{ width: '55px', height: '55px' }}></div>
                </div>
              </div>
            </>)
          })
        }
    </>
  )
}
export default PriceCatalogDetailsProposalSkeleton