import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l, showError, showSuccessMessage, validateEmail } from "../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import benchmarkServices from "../../services/benchmark-services";
import { showMessage } from "../../actions/messages";
import { Spinner } from "react-bootstrap";

const EditClientDataModal = ({ show, handleClose}) => {

  const { benchmarkDetail } = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const customerInfoWrapper  = {
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    phone: ""
  }
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [customerInfo, setCustomerInfo] = useState(customerInfoWrapper)
  const [infoLoader, setInfoLoader] = useState(false)

  useEffect(() => {
    const getContextData = async () => {
      try {
        const response = await benchmarkServices.getInvitedCustomerDetail(benchmarkDetail.id, benchmarkDetail.project_id);
        if (response.status) {
          const { firstname, lastname, company, email, phonenumber } = response.data
          setCustomerInfo({...customerInfo, firstName: firstname, lastName: lastname, company: company, email: email, phone: phonenumber })
        }
      } catch (e) {
        showError("l_something_went_wrong");
        console.error(e);
      } finally {
      }
    };
    getContextData();
  }, [])
  

  const validateInputFields = () => {
    let validationSuccess = true;

    const { firstName, lastName, company, email, phone } = customerInfo;
    let errMessage; 
    
    if (!firstName.trim()) {
      errMessage = 'Please Enter First Name';
      validationSuccess = false 
    }
    if (!lastName.trim()) {
      errMessage = 'Please Enter Last Name';
      validationSuccess = false 
    }
    if (!email.trim()) {
      errMessage = 'Email is required';
      validationSuccess = false 
    } 
    if (!pattern.test(email)) {
      errMessage = 'Enter valid Email';
      validationSuccess = false 
    }
    if (!phone.trim()) {
      errMessage = 'Phone Number is required';
      validationSuccess = false 
    }
    if (!validationSuccess) {
      dispatch(
        showMessage("unsucess", _l("l_error"), `${errMessage}`)
      );
    }
    return validationSuccess;
  };

  const handleEditCustomerDetails = () => {
    if (validateInputFields()) {
      setInfoLoader(true);
      try {
      const { firstName, lastName, company, email, phone } = customerInfo;
       benchmarkServices.editInvitedCustomerDetail(
        benchmarkDetail.id,
        benchmarkDetail.project_id,
        firstName,
        lastName,
        phone,
        email,
      )
       .then((response) => {
         if (response.status) {
          showSuccessMessage("l_customer_details_updated_succsessfully")
          handleClose()
         } else {
          showError(_l(response.message));
         }
         setInfoLoader(false);
       }
       )
      } catch (e) {
        showError("l_something_went_wrong");
        console.error(e);
        setInfoLoader(false);
      }
    }
  }
  
  // useEffect(() => {
  //   console.log(customerInfo, "customerInfo");
  // }, [customerInfo])
  
  
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop={"static"}
        className="custom-modal-style solarcommanmodal overflow-hidden"
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        scrollable={true}
      >
        <Modal.Header className="p-3" closeButton>
          <Modal.Title>{`${_l("l_customer")} ${_l("l_information")}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          <div className="form-wrapper-main pt-0">
            <div className=" c-input-box position-relative  pt-3">
              {/* <label className="input-label no-asterisk ">
              {_l("l_yes")}
              </label> */}
                <div className="row">

                  <div className="col-xl-6 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                    {_l("l_first_name")}
                    </Form.Label>
                    <Form.Group className="comment-input-box">
                      <Form.Control
                        className="form-control height-45 "
                        type="text"
                        placeholder={`${_l("John")}`}
                        aria-label="default input example"
                        value={customerInfo.firstName}
                        onChange={(e) => setCustomerInfo({...customerInfo, firstName: e.target.value})}
                      />
                    </Form.Group>
                  </div>  

                  <div className="col-xl-6 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                    {_l("l_last_name")}
                    </Form.Label>
                    <Form.Group className="comment-input-box">
                      <Form.Control
                        className="form-control height-45 "
                        type="text"
                        placeholder={`${_l("Doe")}`}
                        aria-label="default input example"
                        value={customerInfo.lastName}
                        onChange={(e) => setCustomerInfo({...customerInfo, lastName: e.target.value})}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-xl-6 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                    {_l("l_email")}
                    </Form.Label>
                    <Form.Group className="comment-input-box">
                      <Form.Control
                        className="form-control height-45 "
                        type="text"
                        placeholder={`${_l("l_email")}`}
                        aria-label="default input example"
                        value={customerInfo.email}
                        onChange={(e) => setCustomerInfo({...customerInfo, email: e.target.value})}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-xl-6 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                    {_l("l_phone")}
                    </Form.Label>
                    <Form.Group className="comment-input-box">
                      <Form.Control
                        className="form-control height-45 "
                        type="text"
                        placeholder={`${_l("l_phone")}`}
                        aria-label="default input example"
                        value={customerInfo.phone}
                        onChange={(e) => setCustomerInfo({...customerInfo, phone: e.target.value})}
                      />
                    </Form.Group>
                  </div>

                  {/* <div className="col-xl-6 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                    {_l("l_company_name")}
                    </Form.Label>
                    <Form.Group className="comment-input-box">
                      <Form.Control
                        className="form-control height-45 "
                        type="text"
                        placeholder={`${_l("l_company_name")}`}
                        aria-label="default input example"
                        value={customerInfo.company}
                        onChange={(e) => setCustomerInfo({...customerInfo, company: e.target.value})}
                      />
                    </Form.Group>
                  </div> */}
                  {/* <div className="col-xl-12 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                    {_l("l_role")}
                    </Form.Label>
                    <Form.Group className="comment-input-box">
                      <Form.Control
                        className="form-control height-45 border-0"
                        type="text"
                        placeholder={`${_l("l_role_title_placeholder")}`}
                        aria-label="default input example"
                      />
                    </Form.Group>
                  </div> */}
                </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
          {_l('l_close')}
          </Button>
          <Button variant="primary" size="sm" onClick={() => handleEditCustomerDetails()} disabled={infoLoader}>
            { infoLoader ? _l('l_please_wait') : _l("l_save") }
            { infoLoader 
              ? <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
              : <></>
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditClientDataModal;
