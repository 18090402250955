import React from "react";
import { useSearchParams } from "react-router-dom";
import SolarExternalBenchmark from "./SolarExternalBenchmark";
import Dashboard from "./Dashboard";
import SolarProviderRegistration from "./SolarProviderRegistration";
import ProviderDocuments from "./ProviderDocuments";

const InternalPageConnector = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");

  switch (page) {
    case "create-benchmark":
    case "invite-customer-for-benchmark":
      return <SolarExternalBenchmark />;
    case "register-provider":
    case "proposals":
      return <SolarProviderRegistration />;
      case "document" :
        return <ProviderDocuments />
    default:
      return <Dashboard />;
  }
};

export default InternalPageConnector;
