import React, { useEffect } from "react";
import { CheckCircle } from "phosphor-react";
import { _l } from "../../../hooks/utilities";


const ProposalSendingLoader = ({setStep}) => {

  useEffect(() => {
    setTimeout(() => {
      setStep("congratulation-screen");
    }, 7000);
  }, [])
  

  return (
    <>
      <div className="d-flex h-100 w-100">
        <div className="mx-auto p-20 rounded-3">
          <div className="p-20">
            <div className="c-font f-16 fw-semibold text-center title-fonts py-2">
              {_l("l_sending_final_proposal")}
            </div>
            <div className="c-font color-white-80 text-center f-14">
              This feature allows you to experience an entirely new way of
              interacting with the world around you.
            </div>
            <ul className="comman-list position-relative mt-2">
              <li className="steps-green-line">
                <a href="#/" className="check-image absolute-content">
                  <CheckCircle
                    size={26}
                    weight="fill"
                    className="c-icons color-green"
                  />
                </a>
                <div className="c-font f-16 fw-semibold title-fonts">
                  {_l("l_adding_provider")}
                </div>
                <div className="c-font color-white-80 text-center f-14">
                This feature allows you to experience an entirely new way of interacting with the world around you.
                </div>
              </li>
              <li className="steps-green-line">
                <a href="#/" className="check-image absolute-content">
                  <CheckCircle
                    size={26}
                    weight="fill"
                    className="c-icons color-green"
                  />
                </a>
                <div className="c-font f-16 fw-semibold title-fonts">
                  {_l("l_creating_schedule")}
                </div>
                <div className="c-font color-white-80 text-center f-14">
                This feature allows you to experience an entirely new way of interacting with the world around you.
                </div>
              </li>
              <li className="steps-green-line">
                <a href="#/" className="check-image absolute-content">
                  <span
                    role="status"
                    aria-hidden="true"
                    className="spinner-border spinner-border-sm"
                  ></span>
                </a>
                <div className="c-font f-16 fw-semibold title-fonts">
                  {_l("l_sending_proposal_to_provider")}
                </div>
                <div className="c-font color-white-80 text-center f-14">
                This feature allows you to experience an entirely new way of interacting with the world around you.
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProposalSendingLoader;
