import React, { useEffect, useRef, useState } from "react";
import PremiumEstimateIconCrown from "../../assets/icons/PremiumEstimateIconCrown";
import { _l } from "../../hooks/utilities";
import { ChatCircle, DotsThreeVertical, Phone } from "phosphor-react";
import { initCall } from "../../actions/chat";
import chatServices from "../../services/chat-services";
import { selectedChatUser } from "../../actions/customer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import moment from "moment";
import ReactTooltip from "react-tooltip";

const Autocomplete = ({
  options,
  onSelect,
  value,
  onChange,
  mainWidth="",
  inputClass = "",
  dropdownClass = "",
  dropdownListClass = "",
  filterFunctionForOptionString,
  onFocus,
  onBlur,
  actualWidth,
  placeholder = "",
  isCallChatEnable = false
}) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const userEmail = localStorage.getItem("email");
  const staffId = localStorage.getItem("staff_id");
  const contactId = localStorage.getItem("contact_id");
  const orgChatUserId = localStorage.getItem("orgChatUserId");
  const lang = localStorage.getItem('language');
  const inputRef = useRef(null);

  const handleInputChange = (event) => {
    const inputValue = (event.target.value).replace('@','');
    onChange(event);
    const filtered = options.filter((option) =>
      (option.full_name || option.label).toLowerCase().trim().includes(inputValue.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const handleOptionClick = (option) => {
    // setFilteredOptions([]);
    onSelect(option);
    setShowSuggestion(true);
  };

  const setOptions = () => {
    let newVal = value.replace('@','')
    setShowSuggestion(true);
    if (!value || !value.trim().length || value.trim() === "@") {
      setFilteredOptions(options);
    } else {
      const filtered = options.filter((option) =>
        (option.full_name || option.label).toLowerCase().includes(newVal.toLowerCase())
      );
      setFilteredOptions(filtered);
    }
  };

  const makeCall = (myrUserId, email, myrUserType) => {
    dispatch(initCall({
      userId: localStorage.getItem("orgChatUserId"),
      myrUserId: myrUserId,
      email: email,
      myrUserType: myrUserType == "contact" ? "client" : "staff",
      projectId:0,
      extraData: {}
    }));
  };

  const chatWithUser = (userData) => {
    chatServices.createMyrUserGroup(userType == "contact" ? contactId : staffId, userEmail, userType == "contact" ? "client" : "staff", userData.user_type == "contact" ? userData.contactid : userData.staffid, userData.email, userData.user_type == "contact" ? "client" : "staff", 0).then((res) => {
      if (res.success) {
        let chatuser = res.result.members.filter((user) => user.user != orgChatUserId)[0]
        dispatch(selectedChatUser({ ...userData, groupId: res.result._id, chatUserId: chatuser.user, generalChat: true }));
        localStorage.setItem("generalChatForLiteVersion",true);
        localStorage.setItem("isLiteVersion", true);
        navigate("/chat");
      }
    })
  }

  const PopoverContain = (
    <Popover
      className="w-25"
    >
      <Popover.Header as="h3" className="d-none"></Popover.Header >
      <Popover.Body className="p-0 position-relative">
        <div className="p-0 popover-detail-wrapper">
          <ul>
            <li className={`office-box p-15 hr_1 pb-2 d-flex align-items-center justify-content-between`}>
              <a href="#/" className="w-100 text-truncate">
                <div className="d-flex align-items-center">
                  <div className="c-list-icon">
                    <div className="h40w40 with-bg d-flex align-items-center bg-white-05 radius_5 rounded-circle comman-round-box">
                      <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{
                        backgroundImage: `url(${selectedUser.profile_image_url})`,
                      }}></div>
                      {/* <span class="m-auto c-font f-10 p-1 office-subtitle text-truncate hover-span">{ item.full_name != "" ? item.full_name : item.email }</span> */}
                    </div>
                  </div>
                  <div className="ps-2 w100minus40 title-fonts">
                    <div className=" d-flex align-items-center w-100">
                      <div className="w100minus100per">
                        <div className="c-list-detail text-truncate color-white c-font f-12">
                          {selectedUser.full_name != "" ? selectedUser.full_name : selectedUser.email}
                        </div>
                        <div className="c-font f-10 title-fonts color-white-60 text-truncate">
                          {selectedUser.user_type}
                        </div>
                      </div>
                      <div className="d-flex align-items-center comman_action_icon">
                        <a href="#/" className="action_icon with_bg me-2 position-relative"
                          onClick={() => chatWithUser(selectedUser)}
                        >
                          <ChatCircle size={16} weight="light" className="c-icons" />
                          {
                            selectedUser.unreadCount && selectedUser.unreadCount > 0 ?
                              <div className="with-bg h18w18 justify-content-center rounded-pill badge-custom h18w18 ms-0 badge-green rounded-circle d-flex right-accordian-header-pill ">
                                <span class="m-auto ps-0">{selectedUser.unreadCount > 99 ? "99+" : selectedUser.unreadCount}</span>
                              </div>
                              :
                              <></>
                          }
                        </a>
                        <a href="#/" className="action_icon with_bg position-relative"
                          onClick={() => makeCall(selectedUser.user_type == "contact" ? selectedUser.contactid : selectedUser.staffid, selectedUser.email, selectedUser.user_type)}
                        >
                          <Phone size={16} weight="light" className="c-icons" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </li>
            <li className={`${selectedUser.projects && selectedUser.projects.length > 0 ? "hr_1" : ""}`}>
              <div className="p-15 py-2">
                <div className="text-truncate c-font f-13 py-1">
                  <span>{_l('l_position')} : </span><span className="color-white-60 c-font f-11">{selectedUser.position}</span>
                </div>
                <div className="text-truncate c-font f-13 py-1">
                  <span> {_l('l_join_date')} : </span><span className="color-white-60 c-font f-11">{moment(selectedUser.datecreated).locale(lang == "french" ? "fr" : "en").format("MMMM Do YYYY")}</span>
                </div>
              </div>
            </li>
          </ul>
          <div className="h-100 overflowscroll">
            <ul className="p-15  py-2">
              {
                selectedUser.projects && selectedUser.projects.length > 0 ?
                  selectedUser.projects.map((space) => {
                    return (
                      <li className="py-2 d-flex align-items-center justify-content-between hr_sepretr r_0">
                        <div className="d-flex align-items-center text-truncate"
                          data-tip={space.space_name}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                        >
                          <div className="c-list-icon ">
                            <div className="h30w30 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
                              <span class="m-auto c-font f-9 p-1 office-subtitle text-truncate hover-span">{space.space_name}</span>
                            </div>
                          </div>
                          <div className="ps-2 text_wrap">
                            <div className="c-list-detail text-truncate color-white-60">{space.title}</div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center comman_action_icon">
                          <a href="#/" className="action_icon with_bg me-2"
                            // onClick={() => chatWithUser(space)}
                          >
                            <ChatCircle size={16} weight="light" className="c-icons" />
                            {
                              selectedUser.unreadCount > 0 ?
                                <div className="with-bg h18w18 justify-content-center rounded-pill badge-custom h18w18 ms-0 badge-green rounded-circle d-flex right-accordian-header-pill ">
                                  <span class="m-auto ps-0">{selectedUser.unreadCount > 99 ? "99+" : selectedUser.unreadCount}</span>
                                </div>
                                :
                                <></>
                            }
                          </a>
                          <a href="#/" className="action_icon with_bg"
                            // onClick={() => makeCall(selectedUser.chatUserId)}
                          >
                            <Phone size={16} weight="light" className="c-icons" />
                            <div className="with-bg h18w18 justify-content-center rounded-pill badge-custom h18w18 ms-0 badge-green rounded-circle d-flex right-accordian-header-pill d-none">
                              <span class="m-auto ps-0">1</span>
                            </div>
                          </a>
                        </div>
                        <ReactTooltip />
                      </li>
                    )
                  })
                  :
                  <></>
              }
            </ul>
          </div>
        </div>

      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowSuggestion(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setOptions();
  }, [value]);
  
  return (
    <div ref={inputRef} className={mainWidth}>
      <input
        className={inputClass}
        type="text"
        value={value}
        onChange={handleInputChange}
        onFocus={() => {
          setOptions();
          if (onFocus) {
            onFocus();
          }
        }}
        onBlur={onBlur}
        placeholder={placeholder != "" ? placeholder : _l("l_search")}
      />
      {options.length > 0 && filteredOptions.length > 0 && showSuggestion ? (
        <ul
          className={`dropdown-menu ${actualWidth ? "" : "w-100"
            } show overflowscroll ${dropdownClass}`}
        >
          {filteredOptions.map((option) => (
            <li key={option}>
              <a href="#/"
                className={`d-flex dropdown-item hr_sepretr l_10px ${dropdownListClass}`}
              >
                <div className="d-flex align-items-center flex-grow-1 text-truncate"
                  onClick={() => handleOptionClick(option)}
                >
                  {option.profile_image_url ? (
                    <div className="c-list-icon">
                      <div className="h25w25 comman-round-box with-bg rounded-circle bg-white-05">
                        {option.showCrown ? (
                          <span className="bg-transparent border-0 rounded-pill badge-custom d-flex p-2 light-pill bg-none">
                            <PremiumEstimateIconCrown className="stroke-transparent path-warning HW14 position-absolute" />
                          </span>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                        <div
                          className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                          style={{
                            backgroundImage: `url("${option.profile_image_url}")`,
                          }}
                        ></div>
                      </div>
                    </div>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  <div className="align-items-center d-flex ps-2 text_wrap w100minus30">
                    <div className="flex-grow-1 text-truncate">
                      {typeof filterFunctionForOptionString === "function"
                        ? filterFunctionForOptionString(option.label)
                        : option.label}
                    </div>
                    {option.pillContent ? (
                      <span
                        className={`badge rounded-pill text-nowrap list-toggle-view-none`}
                      >
                        {option.pillContent}
                      </span>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </div>
                {
                  isCallChatEnable ? 
                <div className="comman_action_icon d-flex align-items-center">
                  <a href="#/" className="h25w25 d-flex position-relative me-1" data-tip={_l("l_support")} data-effect="solid"
                  data-delay-show='1000'
                    data-class="tooltip-main"
                    onClick={() => chatWithUser(option)}
                  >
                    <ChatCircle size={16} weight="light" className="c-icons active-svg" />
                    {
                      option.unreadCount && option.unreadCount > 0 ?
                        <div className="with-bg h18w18 justify-content-center rounded-pill badge-custom h18w18 ms-0 badge-green rounded-circle d-flex right-accordian-header-pill ">
                          <span class="m-auto ps-0">{option.unreadCount > 99 ? "99+" : option.unreadCount}</span>
                        </div>
                        :
                        <></>
                    }
                  </a>
                  <a href="#/" className="h25w25 d-flex position-relative" data-tip={_l("l_call")} data-effect="solid"
                  data-delay-show='1000'
                    data-class="tooltip-main"
                    onClick={() => makeCall(option.user_type == "contact" ? option.contactid : option.staffid, option.email, option.user_type)}
                  >
                    <Phone size={16} weight="light" className="c-icons active-svg" />
                  </a>
                  <div className="right-options transparent-bg dropstart">
                  <OverlayTrigger trigger="click" placement="right" overlay={PopoverContain} rootClose>
                    <a href="#/" type="button" className="line-height-0 light-theme-img"
                      onClick={() => {
                        setSelectedUser(option);
                      }}>
                      <DotsThreeVertical size={16} weight="light" className="c-icons active-svg" />
                    </a>
                  </OverlayTrigger>
                  </div>
                </div>
                :<></>
                }
              </a>
            </li>
          ))}
        </ul>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </div>
  );
};

export default Autocomplete;
