import React from 'react';
// import CSS from '../css/icon_main.css';

function NoToDoTask(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>


			<svg width="81" height="83" viewBox="0 0 81 83" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons w-100 h-100 ${CommanHW}`}>
				<path d="M64.0277 45.6258V40.67C64.0697 30.9235 64.0504 21.1737 64.0504 11.4272V9.53928H57.585C57.585 8.5921 57.6076 7.8033 57.585 7.0113C57.4815 3.77861 55.0828 1.22805 51.9536 1.02439C50.4062 0.885053 48.8646 1.34647 47.6483 2.31304C46.4319 3.27962 45.6341 4.67721 45.4203 6.21607C45.2619 7.2182 45.2878 8.24941 45.22 9.39379H38.4313C38.4313 8.63734 38.4507 7.85181 38.4313 7.0695C38.3246 3.63962 35.6997 1.03084 32.3474 1.00821C30.739 0.982988 29.1858 1.59369 28.0253 2.70754C26.8648 3.8214 26.191 5.34835 26.1503 6.95636C26.1148 7.74837 26.1503 8.54685 26.1503 9.42614H19.5944C19.5944 8.69556 19.6138 8.01666 19.5944 7.3378C19.4781 3.63637 16.9145 1.00821 13.4329 1.00821C9.9513 1.00821 7.39425 3.62344 7.2908 7.3378C7.2714 7.98434 7.2908 8.63091 7.2908 9.42938H1V81.903C1.5237 81.9289 1.99568 81.9774 2.47089 81.9774C22.1795 81.9774 41.8903 81.9774 61.6032 81.9774L64.0245 82V78.5087" strokeMiterlimit="10" />
				<path d="M26.1624 9.32896C26.1624 10.1953 26.1398 10.9453 26.1624 11.6856C26.2885 15.206 28.936 17.7598 32.4208 17.7339C35.7537 17.7113 38.3626 15.0637 38.4401 11.6177C38.4401 10.8386 38.4401 10.0596 38.4401 9.21582" strokeMiterlimit="10" />
				<path d="M45.2366 9.32896C45.2366 10.1953 45.214 10.9453 45.2366 11.6856C45.3627 15.206 48.0102 17.7598 51.4951 17.7339C54.828 17.7113 57.4368 15.0637 57.5144 11.6177C57.5144 10.8386 57.5144 10.0596 57.5144 9.21582" strokeMiterlimit="10" />
				<path d="M7.30469 9.31006C7.30469 10.0956 7.30469 10.7874 7.30469 11.476C7.3855 15.0837 9.91667 17.7054 13.3369 17.7345C16.757 17.7636 19.3982 15.2356 19.5792 11.6538C19.618 10.8779 19.5792 10.0956 19.5792 9.32947" strokeMiterlimit="10" />
				<path d="M54.684 29.9927H10.1602" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M48.8576 49H10.1816" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M40.0035 68H10.2402" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M63.8462 45.6259C68.2076 45.6156 72.3954 47.3338 75.4927 50.4044C78.59 53.475 80.3444 57.6479 80.3717 62.0092C80.4267 71.0155 73.0174 78.4959 63.9853 78.5767C61.771 78.6676 59.5611 78.3112 57.4877 77.5287C55.4143 76.7462 53.5199 75.5537 51.9178 74.0225C50.3157 72.4913 49.0388 70.6528 48.1633 68.6169C47.2878 66.5811 46.8318 64.3895 46.8224 62.1734C46.8131 59.9573 47.2506 57.7621 48.1089 55.7189C48.9671 53.6757 50.2286 51.8265 51.8177 50.2819C53.4068 48.7372 55.2911 47.5287 57.3578 46.7287C59.4245 45.9288 61.6312 45.5537 63.8462 45.6259V45.6259Z" strokeMiterlimit="10" />
				<path d="M56.9531 55.23L70.8375 68.9721" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M70.8375 55.23L56.9531 68.9721" strokeLinecap="round" strokeLinejoin="round" />
			</svg>

		</React.Fragment>
	)
}

export default NoToDoTask