import React from 'react'
const PriceCatalogTabsSkeleton = () => {
  return (
    <>
    <div className="comman-content-scroll comman-verticle-tab SkeletonMain">
        {
          [0, 1, 2, 3, 4].map((s, index) => {
            return (<>
              <li className='comman-list p-0'>
                <a className='w-100 position-relative tab_link title-fonts'><div className='skeleton-animation radius_3' style={{ width: '100px', height: '15px' }}></div></a>
              </li>
            </>)
          })
        }
        </div>
    </>
  )
}
export default PriceCatalogTabsSkeleton