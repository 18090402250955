import moment from "moment";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Accordion from "react-bootstrap/Accordion";
import { _l } from "../../hooks/utilities";
import GeneralItemTable from "./NewSchedule/GeneralItemTable";
import NotePencilIcon from "../../assets/icons/NotePencilIcon";
import GearSixIcon from "../../assets/icons/GearSixIcon";
import PlusIcon from "../../assets/icons/PlusIcon";
import { Info,Plus } from "phosphor-react";



const CreateScheduleOffcanvas = ({ show ,handleClose }) => {



  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        size="lg"
        placement="bottom"
        backdropClassName="offcanvas-backdrop"
        className="bottom-offcanvas with-max-h-85vh w-950"
      >
        <Offcanvas.Header closeButton closeVariant="white">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <Offcanvas.Title>Create Offcanvas</Offcanvas.Title>
            <div className="d-flex align-items-center">
              <div className="schedule-count-box-main pe-3">
                <div className="d-flex align-items-center">
                  <div className="schedule-count-box with_separator_10 px-3">
                    <div className="c-font f-12 fw-semibold schedule-number title-fonts">241</div>
                    <div className="c-font color-white-80 f-10 schedule-title">All Spaces</div>
                  </div>
                  <div className="schedule-count-box with_separator_10  px-3">
                    <div className="c-font f-12 fw-semibold schedule-number title-fonts">241</div>
                    <div className="c-font color-white-80 f-10 schedule-title">Meeting Room</div>
                  </div>
                  <div className="schedule-count-box with_separator_10  px-3">
                    <div className="c-font f-12 fw-semibold schedule-number title-fonts">241</div>
                    <div className="c-font color-white-80 f-10 schedule-title">Sanitary </div>
                  </div>
                </div>
              </div>
              <div className="task-header-right pe-2">
                <input
                    className="badge-white color-green c-font f-14 ms-2 fw-semibold p-1 radius_3 text-center title-fonts border-0"
                    // placeholder="$300"
                    value="$300"
                    type="text"
                    data-tip={`${_l("l_amount")}`}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main"
                    disabled
                  />
              </div>
            </div>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-15 pb-0">
          <div className="custom-accordian-main with-left-header-arrow">
            <Accordion defaultActiveKey="0" alwaysOpen>
            <Accordion.Item className={`mainTopicTaskFilter`} eventKey="0" >
              <div className="accordian-header-box d-flex align-content-center accordian-main-header title-fonts custom-accordian-Header-sticky">
                <Accordion.Header>
                  <div className="d-flex align-items-center justify-content-between w-100 line-height-1">
                    <div className="title-font c-font f-12 fw-semibold text-truncate w100minus70">General Area</div>
                  </div>
                </Accordion.Header>
              </div>
              <Accordion.Body className="mt-1 childTopicTaskFilter">
                  <GeneralItemTable />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className={`mainTopicTaskFilter`} eventKey="0">
              <div className="accordian-header-box d-flex align-items-center accordian-main-header title-fonts custom-accordian-Header-sticky">
                <Accordion.Header className="w-auto">
                  <div className="d-flex align-items-center line-height-1">
                    <div className="title-font c-font f-12 fw-semibold text-truncate">Meeting Room 
                      <span className="c-font f-12 fw-semibold title-fonts color-green ps-2">[ 4 ]</span> 
                    </div>
                    
                  </div>
                </Accordion.Header>
                <div className="schedule-icon-wrapper">
                      <div className="d-flex align-items-center">
                        <a href="#/" className="with_separator_10 on-hover-active-toggle-img z-index-3" 
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main">
                            <NotePencilIcon className=" HW18 m-auto" />
                        </a>
                        <a href="#/" className="with_separator_10 on-hover-active-toggle-img z-index-3 before-dnone"  
                          type="button"
                          data-bs-toggle="dropdown"
                          id="MeetingRoomsDropdown"
                          aria-expanded="false"
                              data-bs-auto-close="outside">
                            <GearSixIcon className="HW18 m-auto" />
                        </a>
                        {/* <MeetingRoomsDropdown /> */}
                      </div>
                    </div>
              </div>
              <Accordion.Body className="mt-1 childTopicTaskFilter">
                  <GeneralItemTable />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className={`mainTopicTaskFilter`} eventKey="0">
              <div className="accordian-header-box d-flex align-items-center accordian-main-header title-fonts custom-accordian-Header-sticky">
                <Accordion.Header className="w-auto">
                  <div className="d-flex align-items-center line-height-1">
                    <div className="title-font c-font f-12 fw-semibold text-truncate">Sanitary 
                      <span className="c-font f-12 fw-semibold title-fonts color-green ps-2">[ 5 ]</span> 
                    </div>
                    
                  </div>
                </Accordion.Header>
                <div className="schedule-icon-wrapper">
                      <div className="d-flex align-items-center">
                        <a href="#/" className="with_separator_10 on-hover-active-toggle-img z-index-3" 
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main">
                            <NotePencilIcon className=" HW18 m-auto" />
                        </a>
                        <a href="#/" className="with_separator_10 on-hover-active-toggle-img z-index-3 before-dnone"  
                          type="button"
                          data-bs-toggle="dropdown"
                          id="MeetingRoomsDropdown"
                          aria-expanded="false"
                              data-bs-auto-close="outside">
                            <GearSixIcon className="HW18 m-auto" />
                        </a>
                        {/* <MeetingRoomsDropdown /> */}
                      </div>
                    </div>
              </div>
              <Accordion.Body className="mt-1 childTopicTaskFilter">
                  <GeneralItemTable />
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
          </div>
        </Offcanvas.Body>
        <div className="p-15">
          <div className="schedule-button-wrapper p-15 px-0 border-bottom">
            <div className="d-flex align-items-center justify-content-between">
            <Button variant="white-05" size="sm">
                <Plus size={14} weight="light" className="c-icons" />
                <span className="ps-1">Add Room</span>
            </Button>
              <div className="warning-text">
                <div className="d-flex align-items-center">
                  <Info size={18} color="#fca120" weight="light" />
                  <span className="text-warning fw-light ps-1">You need to add room. Because you edited meeting room.</span>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <Button variant="white-05" className="me-3">Save as template</Button>
                <Button variant="white-05">Select Template</Button>
              </div>
            </div>
          </div>
          <div className="schedule-action-btn p-15 px-0 pb-0">
            <div className="d-flex align-items-center justify-content-between">
              <Button variant="white-05">Previous</Button>
              <Button variant="primary">Next</Button>
            </div>
          </div>
        </div>
      </Offcanvas>
    </>
  );
};

export default CreateScheduleOffcanvas;
