import {
  ArrowBendDownLeft,
  ArrowBendUpLeft,
  ArrowRight,
  At,
  Check,
  Checks,
  DotsThreeVertical,
  DownloadSimple,
  Hash,
  MagnifyingGlass,
  Paperclip,
  Smiley,
  Star,
  UsersThree,
  X,
} from "phosphor-react";
import React, { useState } from "react";
import {
  _l,
  getFileDetails,
  getImagePreviewIcon,
  getPreviewFromURL,
  showError,
} from "../../../hooks/utilities";
import { mentionInputStyle } from "../../../assets/mention/mentionInputStyle";
import { mentionStyle } from "../../../assets/mention/mentionStyle";
import { MentionsInput, Mention } from "react-mentions";
import ReactHtmlParser from "react-html-parser";
import ActionDropdown from "../../Dropdowns/ActionDropdown";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import AudioPlayer from "react-h5-audio-player";
import jQuery from "jquery";

import { showConfirmation, showMessage, toastCloseAction } from "../../../actions/messages";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import StarRatingDropdown from "../../TaskModalComponents/StarRatingDropdown";
import customerServices from "../../../services/customer-services";
import EmojiPicker from "emoji-picker-react";
import { updateTaskAttechments } from "../../../actions/customer";
import Lightbox from "yet-another-react-lightbox";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";


let fileUplods = [];
let attchmentFiles = [];

const ExternalComment = ({
  commentsList,
  setCommentsList = () => {},
  external = true,
  isRelativeView = false,
  isReadOnly = false,
  guestcommentCount = 0,
  selectedAttachment,
  mentionList,
  docType = "task",
}) => {
  const dispatch = useDispatch();

  const contact_id = localStorage.getItem("contact_id");
  const staff_id = localStorage.getItem("staff_id");
  const userType = localStorage.getItem("user_type");
  const contact_image = localStorage.getItem("contact_image");
  const contact_full_name = localStorage.getItem("full_name");
  const msgListDiv = document.getElementById("comment-list");
  let contact_role = localStorage.getItem("contact_role");

  const guestUsrerCommentsCount = JSON.parse(
    localStorage.getItem("guest_comment")
  );

  const { callStatus, isCallRecived } = useSelector((state) => state.chat);
  const selectedTask = useSelector((state) => state.customer.selectedTask);
  const task_attachments = useSelector(
    (state) => state.customer.task_attachments
  );

  const [commentInput, setCommentInput] = useState("");
  const [waitingForAIResponse, setWaitingForAIResponse] = useState(false);
  const [isMentionListShow, setIsMentionListShow] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [commentId, setCommentId] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [openGallery, setOpenGallery] = useState(false);
  const [previewSrc, setPreviewSrc] = useState([]);



  const onClick = (emojiData) => {
    setCommentInput(
      (commentInputCallback) => commentInputCallback + emojiData.emoji
    );
  };

  const aiMentionAddOrEditComment = () => {
    // find contact role proccess --- Start ---
    let mentionIdArry = [];
    let contact_roleArray = [];
    let regex = /data-mention-id="([^"]*)"/g;
    const mentionedId = [...commentInput.matchAll(regex)];
    for (const match of mentionedId) {
      mentionIdArry.push(match[1]);
    }
    mentionList &&
      mentionList.forEach((item) => {
        if (item.id && mentionIdArry && mentionIdArry.includes(item.id)) {
          contact_roleArray.push(item.contact_role);
        }
      });
    // Find contact role proccess --- End ---

    handleAddComment({
      content: commentInput,
      files: fileUplods,
      editCommentId: commentId,
    });
    setShowEmoji(false);
    setCommentInput("");
    fileUplods = [];
  };

  const mentionListToggle = () => {
    if (!isMentionListShow) {
      setCommentInput("@");
      jQuery("#comment_input").focus();
    } else {
      setCommentInput("");
    }
    setIsMentionListShow(!isMentionListShow);
  };
  const preparePreview = (files) => {
    let previrewArray = [];
    Array.from(files).map((img) => {
      let preview_url = getImagePreviewIcon(img);
      previrewArray.push({ name: img.name, url: preview_url });
    });
    fileUplods = files;
    setImagePreviews(previrewArray);
  };

  const addNewCommentTask = (
    content = "",
    attachments = "",
    editcommentId = 0
  ) => {
    setShowLoader(true)
    if (editcommentId > 0) {
      customerServices
        .editLinkComment(
          selectedTask.id,
          selectedTask.project_id,
          content,
          "",
          editcommentId,
          0,
          0,
          attachments,
          selectedTask.id,
          "task",
          selectedTask.hash,
          0
        )
        .then((res) => {
          if (res && res.status) {
            let commentUpdate = commentsList.map((comment) => {
              if (comment.id == editcommentId) {
                if (attachments.length) {
                  let tempImage = comment.attachments.length
                    ? comment.attachments
                    : [];
                  if (res.data.files_list && res.data.files_list.length) {
                    res.data.files_list.map((img) => {
                      tempImage.push({
                        id: img.file_id,
                        file_name: img.image_url,
                      });
                    });
                  }
                  comment.attachments = tempImage;
                }
                comment["content"] = content != "" ? content : "";
                comment["description"] = content != "" ? content : "";
                return comment;
              }
              return comment;
            });
            setCommentsList(commentUpdate);
            setShowLoader(false);
          } else {
            showError(res.message)
            setShowLoader(false);
          }
        });
    } else {
      const comment = content.trim();
      customerServices
        .addLinkComment(
          // data.provider === 'one_drive' && data.task_id != undefined ? data.task_id : docType == "imageview" && data.taskId != undefined ? data.taskId : data.id,
          selectedTask.id,
          selectedTask.project_id,
          content != "" ? content : comment,
          undefined,
          undefined,
          0,
          undefined,
          attachments != "" ? attachments : attchmentFiles,
          0,
          "",
          selectedTask.hash,
          0,
          "",
          "",
          0
        )

        .then((res) => {
          if (res.status) {
            let taskAttachments = task_attachments || [];
            res.data.files_list.forEach((d) => {
              return taskAttachments.unshift({
                attachment_key: "",
                comment_file_id: res.data.comment_id,
                contact_full_name: localStorage.getItem("full_name"),
                contact_id: contact_id,
                creator_image_url: localStorage.getItem("contact_image"),
                dateadded: new Date().toISOString(),
                file_name: d.file_name,
                file_type: d.file_type,
                filetype: attachments[0].type,
                id: d.file_id,
                image_url: d.image_url,
                is_allowed_to_edit: "1",
                is_map: "1",
                task_comment_id: res.data.comment_id,
                task_id: selectedTask.id,
              });
            });
            // setShowCommentModal(true);
            let newArr = [
              ...commentsList,
              {
                id: res.data.comment_id,
                contact_id,
                dateadded: new Date().toISOString(),
                contact_full_name: localStorage.getItem("full_name"),
                content: res.data.content
                  ? res.data.content
                  : content != ""
                  ? content
                  : comment,
                description: res.data.content
                  ? res.data.content
                  : content != ""
                  ? content
                  : comment,
                time: res.data.time,
                addedfrom_details: {
                  profile_url: localStorage.getItem("contact_image"),
                },
                staffid: localStorage.getItem("staff_id"),
                attachments: res.data.files_list.map((d) => {
                  return {
                    ...d,
                    id: d.file_id,
                    file_name: d.image_url,
                    file_url: d.image_url,
                  };
                }),
                is_allowed_to_edit: 1,
                profile_url: localStorage.getItem("contact_image"),
                parent_comment_details: res.data.parent_comment_details
                  ? res.data.parent_comment_details
                  : "",
                parent_comment_id: res.data.parent_comment_id,
              },
            ];
            setCommentsList(newArr);
            attchmentFiles = [];
            setCommentCount(commentCount + 1);
            setImagePreviews([])
            setShowLoader(false);
          }else{
            showError(res.message)
            setShowLoader(false);
          }
        });
    }
  };

  const handleAddComment = (value) => {
    let content = value && value.content ? value.content : "";
    let attachments = value && value.files;
    let editId = value && value.editCommentId;
    if ((content && content.trim().length) || attachments.length) {
      addNewCommentTask(content, attachments, editId);
    }
  };

  const removeImageSelection = (name) => {
    imagePreviews.forEach((thumbnail) => {
      URL.revokeObjectURL(thumbnail.url);
    });
    const updatedFileList = Array.from(fileUplods).filter(
      (img) => img.name != name
    );

    // setImageBuffer(updatedFileList);
    preparePreview(updatedFileList);
  };

  const deleteComment = (id, comment) => {  
    dispatch(toastCloseAction());
    customerServices.deleteExternalTaskDocumentAndComment(id).then((res) => {
      if (res && res.status) {
        setCommentsList(commentsList.filter((comment) => comment.id != id))
        dispatch(updateTaskAttechments(task_attachments && task_attachments.filter((data)=>data.task_comment_id != id)))
      } else {
        showError(res.message)
      }
    })
  };

  const editComment = (id) => {
    const targetComment = commentsList.filter((comment) =>
      docType == "proposal" ? comment.comment_id == id : comment.id === id
    );
    if (targetComment.length) {
      setCommentId(id);
      setCommentInput(
        targetComment[0].content ||
          targetComment[0].comment ||
          targetComment[0].description
      );
    }
  };

  const confirmAlert = (type, data, comment) => {
    switch (type) {
      case "delete":
        dispatch(
          showConfirmation(
            _l("l_confirm"),
            _l("l_you_want_to_delete_this_comment"),
            _l("yes"),
            _l("no"),
            () => {},
            () => {
              deleteComment(data, comment);
              dispatch(toastCloseAction());
            }
          )
        );
        break;
    }
  };

  const handleDeleteComment = (id, comment) =>{
    confirmAlert("delete", id, comment);
  }

  const handleImagePreview = (comment, image, index) => {
    let selectAttachment = comment.attachments.filter(
      (file, innerIndex) => innerIndex == index
    );
    let otherAttachment = comment.attachments.filter(
      (file, innerIndex) => innerIndex != index
    );
    setPreviewSrc(
      selectAttachment.concat(otherAttachment).map((src) => {
        return {
          src: src.file_name,
        };
      })
    );
      setOpenGallery(true);

  }


  return (
    <>
      <Lightbox
            open={openGallery}
            close={() => setOpenGallery(false)}
            slides={previewSrc}
          />
      <div className="w-300 bg-white h-100 d-flex flex-column">
        <div className="chat-wrapper overflow-auto flex-grow-1 p-10">
          {commentsList &&
            commentsList.length > 0 &&
            commentsList.map((comment, index) => {
              return (
                <div
                  className={`chat-box ${
                    comment.is_allowed_to_edit == 1 ||
                    comment.contact_id ==
                    localStorage.getItem("contact_id")
                      ? "right-side-msg"
                      : ""
                  }`}
                  key={index}
                >
                  <div className="d-flex">
                    <div
                      className="h32w32 comman-image-box with-bg rounded-circle bg-white-05"
                      data-tip={
                        comment.full_name ||
                        comment.contact_full_name ||
                        comment.guest_user_name ||
                        comment.staff_full_name
                      }
                      data-effect="solid"
                      data-delay-show="1000"
                      data-class="tooltip-main"
                    >
                      <div
                        className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                        style={{
                          backgroundImage: `url('${comment.profile_url}')`,
                        }}
                      ></div>
                    </div>
                    <div className={`chat-msg`}>
                      <div className="chat-text-box  bg-solar ">
                        {/* Reply Image start */}
                        <div
                          className={`p-0 w-100 ${
                            comment && comment.parent_comment_id > 0
                              ? ""
                              : "d-none"
                          }`}
                        >
                          <div className="bg-transparent  p-0 w-100">
                            <div>
                              <ArrowBendDownLeft
                                size={14}
                                className="c-icons m-auto"
                                weight="light"
                              />
                            </div>
                            <div className="border-bottom image-upload-wrapper mb-2 w-100">
                              <div className="d-flex align-items-center flex-wrap base-body-color radius_3">
                                {comment &&
                                comment.parent_comment_details &&
                                !comment.parent_comment_details.file_url ? (
                                  <span className="p-1">
                                    {ReactHtmlParser(
                                      comment &&
                                        comment.parent_comment_details &&
                                        comment.parent_comment_details.content.replace(
                                          /(?:\r\n|\r|\n)/g,
                                          "<br>"
                                        )
                                    )}
                                  </span>
                                ) : (
                                  <a
                                    href="#/"
                                    class="h40w40 comman-round-box with-bg d-flex align-items-center bg-white-05 p-5px radius_3  mb-2"
                                  >
                                    <div
                                      className="comman-bg-img h-100 w-100 bg-style-cover"
                                      style={{
                                        backgroundImage: `url(${
                                          comment &&
                                          comment.parent_comment_details
                                            ? `${comment.parent_comment_details.file_url}`
                                            : ""
                                        })`,
                                      }}
                                    ></div>
                                  </a>
                                )}
                              </div>
                            </div>
                            {/* <div className="chat-text c-font f-12">image</div> */}
                          </div>
                        </div>
                        {/* Reply Image over */}
                        <div className="image-upload-wrapper">
                          {comment.attachments && comment.attachments.length ? (
                            <div className="d-flex align-items-center flex-wrap ">
                              {comment.attachments.map((image, index) => {
                                if (index < 2) {
                                  let preview = {};
                                  preview.url = getPreviewFromURL(
                                    image.file_name
                                  );

                                  return (
                                    <>
                                      {["wav", "mp3"].includes(
                                        getFileDetails(
                                          image.file_url || image.file_name
                                        )
                                      ) ? (
                                        <>
                                          <div className="chat-audio withf12 small">
                                            <AudioPlayer
                                              style={{
                                                width: "200px",
                                                margin: "0px",
                                              }}
                                              layout="horizontal"
                                              // autoPlay
                                              className="audio_player p-0 bg-transparent box-shadow-none "
                                              showJumpControls={false}
                                              showSkipControls={false}
                                              customAdditionalControls={[]}
                                              customVolumeControls={[]}
                                              src={
                                                image.file_url ||
                                                image.file_name
                                              }
                                              autoPlay={false}
                                              autoPlayAfterSrcChange={false}
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <a
                                          href="#/"
                                          key={index}
                                          className="h60w60 comman-round-box  with-bg d-flex align-items-center bg-white-05 p-5px radius_3  m-1  with-top-overlay"
                                        >
                                          <div
                                            className="absolute-download-icon"
                                            onClick={() => {
                                              window.open(
                                                image.file_url ||
                                                  image.file_name,
                                                "_self"
                                              );
                                            }}
                                          >
                                            <DownloadSimple
                                              size={14}
                                              color="#ffffff"
                                              weight="light"
                                            />
                                          </div>
                                          {typeof preview.url === "string" ? (
                                            <div
                                              className="comman-bg-img h-100 w-100 bg-style-cover"
                                              onClick={() => {
                                                // if (external) {
                                                //   setshowExternalImage(
                                                //     true
                                                //   );
                                                //   setAttchmentOnTop(
                                                //     image.id
                                                //   );
                                                // } else {
                                                  handleImagePreview(
                                                    comment,
                                                    image,
                                                    index
                                                  );
                                                // }
                                              }}
                                              style={{
                                                backgroundImage: `url('${
                                                  image.file_url ||
                                                  image.file_name
                                                }')`,
                                              }}
                                            ></div>
                                          ) : (
                                            <>
                                              <div
                                                className="h-100 w-100 d-flex"
                                                style={{
                                                  alignContent: "center",
                                                  flexWrap: "wrap",
                                                }}
                                              >
                                                <preview.url
                                                  size={72}
                                                  weight="light"
                                                  className="c-icons"
                                                />
                                              </div>
                                              <div className="max-w-130px pt-2 d-none">
                                                <div className="c-font text-truncate f-12 color-white-60 ">
                                                  {decodeURIComponent(
                                                    image.file_name.substring(
                                                      image.file_name.lastIndexOf(
                                                        "/"
                                                      ) + 1
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </a>
                                      )}
                                    </>
                                  );
                                } else if (index === 2) {
                                  return (
                                    <a
                                      href="#/"
                                      className="h60w60 comman-round-box  with-bg d-flex align-items-center bg-white-05 p-5px radius_3 m-1 with_overlay"
                                      onClick={() => {
                                        // if (external) {
                                        //   setshowExternalImage(true);
                                        //   setAttchmentOnTop(image.id);
                                        // } else {
                                          handleImagePreview(
                                            comment,
                                            image,
                                            index
                                          );
                                        // }
                                      }}
                                    >
                                      <div class="unread_count m-auto">
                                        +{comment.attachments.length - 2}
                                      </div>
                                    </a>
                                  );
                                }
                              })}
                            </div>
                          ) : (
                            <>
                              {comment.description === undefined ? (
                                <>
                                  <div>
                                    {ReactHtmlParser(
                                      comment.content &&
                                        comment.content.replace(
                                          /(?:\r\n|\r|\n)/g,
                                          "<br>"
                                        )
                                    )}
                                  </div>
                                </>
                              ) : (
                                " "
                              )}
                            </>
                          )}
                        </div>
                        <div className="chat-text">
                          {" "}
                          {comment.description
                            ? ReactHtmlParser(
                                comment.description.replace(
                                  /(?:\r\n|\r|\n)/g,
                                  "<br>"
                                )
                              )
                            : ""}
                        </div>
                        <div
                          className={`dropdown dropdown-center chat-action-option ${
                            comment.is_allowed_to_edit == "1" ||
                            (
                              comment.contact_id ==
                                localStorage.getItem("contact_id"))
                              ? ""
                              : "d-none"
                          }`}
                        >
                          <a
                            href="#/"
                            className="line-height-0 "
                            type="button"
                            id="commentOptionDropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-tip={_l("l_options")}
                            data-effect="solid"
                            data-delay-show="1000"
                            data-class="tooltip-main"
                          >
                            <DotsThreeVertical
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </a>
                          <ActionDropdown
                            actions={[
                              {
                                actionName: _l("l_edit"),
                                actionHandler: () => {
                                  editComment(comment.id);
                                },
                                actionIcon: "PencilSimpleLineIcon",
                              },
                              {
                                actionName: _l("l_send_to_email"),
                                actionHandler: () => {
                                  // setMailForComment(comment);
                                  // setShowLink(true);
                                },
                                actionIcon: "EnvelopeSimpleIcon",
                              },
                              {
                                actionName: _l("l_delete"),
                                actionHandler: () => {
                                  handleDeleteComment(comment.id , comment);
                                },
                                actionIcon: "TrashIcon",
                              },
                            ]}
                          />
                        </div>
                      </div>
                      <div className="chat-send-name c-font f-10 opacity-50 d-flex justify-content-start align-items-center">
                        <span className="me-2">
                          {moment(comment.dateadded).format(
                            "DD/MM/YYYY hh:mm A"
                          )}
                        </span>
                        {/* <span className="me-2">{comment.time}</span> */}
                        <span>{comment.date}</span>
                        <span className="delivered-status-icon message-read d-none">
                          <Check
                            size={14}
                            weight="light"
                            className="c-icons msg-status ms-2 read-msg color-green"
                          />
                          <Checks
                            size={14}
                            weight="light"
                            className="c-icons msg-status ms-2 msg-delivered-double"
                          />
                          <Check
                            size={14}
                            weight="light"
                            className="c-icons msg-status ms-2 msg-delivered-single"
                          />
                        </span>
                      </div>
                    </div>
                    <ReactTooltip />
                  </div>
                  <ReactTooltip />
                </div>
              );
            })}
            {
              commentsList && commentsList.length == 0 ? 
              <>
               <CommanPlaceholder mainSpacing="h-100" imgType="no-comment" placeholderText={_l("l_no_comments")}/>
              </> : <></>
            }
        </div>
        {imagePreviews && imagePreviews.length ? (
          <div className="upload-image-preview p-2">
            <div className="d-flex align-items-center justify-content-start">
              {imagePreviews.map((preview, index) => {
                if (typeof preview.url === "string") {
                  return (
                    <div
                      key={index}
                      className="comman-image-box h60w60 radius_3 upload-image with-margin mt-2"
                    >
                      <div
                        className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                        style={{
                          backgroundImage: `url(
                                                '${preview.url}'
                                              )`,
                        }}
                      ></div>
                      <a
                        href="#/"
                        className="upload-close"
                        onClick={() => {
                          // handleAction('removePreview', preview.name)
                          removeImageSelection(preview.name);
                        }}
                      >
                        <X size={10} weight="light" className="c-icons" />
                      </a>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={index}
                      className="comman-image-box h60w60 radius_3 upload-image with-margin d-flex"
                    >
                      <preview.url
                        size={40}
                        weight="light"
                        className="c-icons"
                      />
                      <a
                        href="#/"
                        className="upload-close"
                        onClick={() => {
                          // handleAction('removePreview', preview.name)
                          removeImageSelection(preview.name);
                        }}
                      >
                        <X size={10} weight="light" className="c-icons" />
                      </a>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        ) : (
          <></>
        )}
        {showEmoji ? (
          <EmojiPicker
            onEmojiClick={onClick}
            autoFocusSearch={false}
            lazyLoadEmojis={true}
            previewConfig={{
              // defaultEmoji: string; // defaults to: "1f60a"
              // defaultCaption: string; // defaults to: "What's your mood?"
              showPreview: false, // defaults to: true
            }}
          />
        ) : (
          <></>
        )}
        <div
          className={`chat-comment-box  border-top  ${
            isReadOnly ||
            guestcommentCount > 7 ||
            (guestUsrerCommentsCount &&
              guestUsrerCommentsCount[0].comment_count >= 7)
              ? "for-disabled"
              : ""
          }`}
        >
          {/* Reply section for attachment view - Start */}
          {selectedAttachment && Object.keys(selectedAttachment).length ? (
            <div className="aicommentbox-reply p-2" id="aicommentbox-reply">
              <div className="d-flex flex-wrap align-items-center">
                <ArrowBendUpLeft weight="light" size="18" className="c-icons" />
                <div className="overflowscroll w100minus30">
                  <div className="d-flex align-items-center justify-content-start base-body-color radius_3 overflow-hidden p-1 ">
                    {/* <div className="color-white-60">
                                  {replyMsgTask.type == "text" ?
                                    <>
                                      {ReactHtmlParser(urlify(replyMsgTask.message.replace(/(?:\r\n|\r|\n)/g, '<br>')))}
                                    </>
                                    : <></>}
                                  {replyMsgTask.caption != "" ?
                                    <>
                                      {ReactHtmlParser(urlify(replyMsgTask.caption.replace(/(?:\r\n|\r|\n)/g, '<br>')))}
                                    </>
                                    : <></>}
                                </div> */}
                    <div className="h50w50 with-bg d-flex align-items-center bg-white-05">
                      <div
                        className="comman-bg-img h-100 w-100 bg-style-cover "
                        style={{
                          backgroundImage: `url('${selectedAttachment.image_url}')`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          {/* Reply section for attachment view - End */}
          <div className="d-flex m-auto flex-column chat-main-box p-10">
            <div
              className={`comment-input-box border-bottom pb-10px ${
                waitingForAIResponse ? "for-disabled" : ""
              }`}
            >
              <div className="d-flex align-items-center position-relative ">
                <div
                  className="h32w32 comman-image-box rounded-circle bg-black-op5"
                  data-tip={contact_full_name}
                  data-effect="solid"
                  data-delay-show="1000"
                  data-class="tooltip-main"
                >
                  <div
                    className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                    style={{
                      backgroundImage: `url(${contact_image})`,
                    }}
                  ></div>
                </div>
                <div
                  className="mension-box mension-box--multiLine withpl7px"
                  style={{
                    position: "relative",
                    overflowY: "visible",
                  }}
                >
                  {/* <textarea placeholder="Write comment" className="border-0 bg-transparent"></textarea> */}
                  <MentionsInput
                    value={commentInput}
                    className="mension-box"
                    placeholder={`${_l("l_write_comment_placeholder")}`}
                    alwaysOpen={true}
                    style={mentionInputStyle}
                    onChange={(e) => {
                      setCommentInput(e.target.value);
                    }}
                    // onKeyUp={(e) => {
                    //   if (e.keyCode === 13 && !e.ctrlKey && !e.shiftKey) {
                    //   }
                    // }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 && !e.ctrlKey && !e.shiftKey) {
                        e.preventDefault();
                        aiMentionAddOrEditComment();
                        // handleAddOrEditComment({
                        //   content: commentInput,
                        //   files: fileUplods,
                        //   editCommentId: commentId,
                        // });
                        // setShowEmoji(false)
                        // setCommentId(0);
                        // setCommentInput("");
                        // setImagePreviews([]);
                        // fileUplods = [];
                      }
                    }}
                    id="comment_input"
                  >
                    <Mention
                      markup='<span style="font-weight: 600;"  contenteditable="false" data-mention-id="__id__"  data-mce-style="font-weight: 600;"  data-mce-selected="1">@__display__</span>'
                      trigger="@"
                      data={mentionList}
                      displayTransform={(id, display) => `@${display}`}
                      style={mentionStyle}
                      renderSuggestion={(
                        suggestion,
                        search,
                        highlightedDisplay
                      ) => {
                        return (
                          <div className="user d-flex align-items-center">
                            <div className="comman-image-box h32w32 rounded-circle mx-2">
                              {suggestion.isteam == 1 ? (
                                <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle d-flex">
                                  {" "}
                                  <UsersThree className="m-auto opacity-60" />{" "}
                                </div>
                              ) : (
                                <div
                                  className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                  style={{
                                    backgroundImage: `url('${suggestion.image}')`,
                                  }}
                                ></div>
                              )}
                            </div>
                            {highlightedDisplay}
                          </div>
                        );
                      }}
                    />
                  </MentionsInput>
                </div>

                <a
                  href="#/"
                  className={`send-arrow-btn with_position_right_zero ${showLoader ? "for-disabled" : ""}`}
                  data-tip={_l("l_send")}
                  data-effect="solid"
                  data-delay-show="1000"
                  data-class="tooltip-main"
                  onClick={() => {
                    if (!showLoader) {
                      aiMentionAddOrEditComment();
                    }
                    // handleAddComment({
                    //   content: commentInput,
                    //   files: fileUplods,
                    //   editCommentId: commentId,
                    // });
                    // setShowEmoji(false)
                    // setCommentId(0);
                    // setCommentInput("");
                    // setImagePreviews([]);
                    // fileUplods = [];
                    // scrollToBottom();
                  }}
                >
                  <ArrowRight size={18} weight="light" className="c-icons" />
                </a>
              </div>
            </div>
          </div>
          <div className="comment-bottom-option p-10">
            <div className="d-flex align-items-center">
              <div
                className={`comman_action_icon mr-10 ${
                  docType == "proposal" ? "pe-none" : ""
                }`}
              >
                <div className="d-flex align-items-center">
                  <a
                    href="#/"
                    className="action_icon with_bg h32w32"
                    data-tip={_l("l_mention")}
                    data-effect="solid"
                    data-delay-show="1000"
                    data-class="tooltip-main"
                    onClick={() => {
                      mentionListToggle();
                      handleAddComment({
                        content: commentInput,
                        files: fileUplods,
                      });
                      // setCommentInput("");
                    }}
                  >
                    <At size={18} weight="light" className="c-icons" />
                  </a>
                </div>
              </div>
              <div className={`comman_action_icon mr-10 Forbeforednone`}>
                <div className="d-flex align-items-center">
                  <a
                    href="#/"
                    className="action_icon with_bg h32w32 position-relative"
                    data-tip={_l("l_choose_file")}
                    data-effect="solid"
                    data-delay-show="1000"
                    data-class="tooltip-main"
                  >
                    <input
                      type="file"
                      multiple="multiple"
                      className="absolute-input"
                      accept=".png,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.zip,.rar,.txt,.jpeg,.mp4,.m4r,.m4a,.mp3,.glb"
                      onChange={(event) => {
                        if (event.target.files.length > 4) {
                          dispatch(
                            showMessage(
                              "unsucess",
                              _l("l_error"),
                              _l("l_maximum_4_files_are_allowed_at_a_time")
                            )
                          );
                          return;
                        }
                        preparePreview(event.target.files);
                      }}
                      onClick={(e) => {
                        e.target.value = "";
                      }}
                    />
                    <Paperclip size={18} weight="light" className="c-icons" />
                  </a>
                </div>
              </div>
              {docType == "task" || docType == "request-task" ? (
                <UncontrolledDropdown
                  className={`w-100 h-100 ${
                    userType == "contact" && JSON.parse(contact_role) != 3
                      ? ""
                      : "for-disabled"
                  }`}
                >
                  <DropdownToggle
                    className="p-0"
                    data-toggle="dropdown"
                    tag="span"
                  >
                    <a
                      href="#/"
                      className="comman_action_icon "
                      type="button"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                    >
                      <div className="action_icon h32w32 with_bg position-relative on-hover-active-toggle-img d-flex align-items-center">
                        <Star size={18} weight="light" className="c-icons" />
                      </div>
                    </a>
                  </DropdownToggle>
                  <DropdownItem toggle={false} className="p-0">
                    <StarRatingDropdown
                      handler={(starValue) => {
                        // handleTaskRating(starValue)
                      }}
                      rating={5}
                    />
                  </DropdownItem>
                </UncontrolledDropdown>
              ) : (
                <></>
              )}
              <div className="comman_action_icon mr-10">
                <div className="d-flex align-items-center">
                  <a
                    href="#/"
                    className={`action_icon with_bg h32w32 with-border-active ${
                      showEmoji ? "active" : ""
                    }`}
                    onClick={() => {
                      setShowEmoji(!showEmoji);
                    }}
                  >
                    <Smiley size={18} className="c-icons" weight="light" />
                  </a>
                </div>
              </div>

              <div className="comman_action_icon with_separator_10 d-none">
                <div className="d-flex align-items-center">
                  <a href="#/" className="action_icon with_bg h32w32">
                    <Hash size={18} weight="light" className="c-icons" />
                  </a>
                </div>
              </div>
              {isRelativeView ? (
                <React.Fragment></React.Fragment>
              ) : (
                <div className="comman_action_icon  for-accessible before-dnone ms-auto">
                  <div className="d-flex align-items-center">
                    <a
                      href="#/"
                      className="action_icon with_bg h32w32"
                      data-tip={`${_l("l_search")}`}
                      data-effect="solid"
                      data-delay-show="1000"
                      data-class="tooltip-main"
                      onClick={() => {
                        setShowSearch(!showSearch);
                      }}
                    >
                      <MagnifyingGlass
                        size={18}
                        weight="light"
                        className="c-icons"
                      />
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExternalComment;
