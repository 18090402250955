import jquery, {  } from "jquery";
import { getCustomerSpaces, getDefaultTopics, getOperatorsProposalList, getTaskCount, setBulkTaskRating, setSelectedGloablView, updateTaskStatus } from "../actions/customer";
import AuthServices from "../services/auth-services";
import CustomerServices from "../services/customer-services";
import addDays from "date-fns/addDays";
import store from "../store";
import { createScheduleDetails, getSpaceRooms, getSpaceRoomsItem } from "../actions/benchmark-actions/benchmark-actions";
import { FilePdf, FileVideo, FileZip, FileDoc, FileCsv, File, FileAudio } from "phosphor-react";
import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-US";
import moment from "moment";
import { setFloorFilters } from "../actions/benchmark-actions/benchmark-actions";
import { toggleFeatureRestrictionModal } from "../actions/general-actions/general-actions";
import { showIDBDataFetchingLoader, showMessage } from "../actions/messages";
import { CUSTOM_ASSIGNEE_CHANGE, DEFAULT_CITY_NAME, DEFAULT_COUNTRY_NAME_LONG, DEFAULT_COUNTRY_NAME_SHORT, DEFAULT_POSTAL_CODE, VALID_IBAN_LENGTHS } from "../constants/constants";
import Geocode from 'react-geocode';
import { getCollectionKeyNames, insertCollectionToDB, readCollectionFromDB, readFromDB, writeToDB } from "./indexed_db_helpers";
import { FETCH_TASK_LIST, TASKLIST_LOADER } from "../actions/action-type";
import { Form } from "react-bootstrap";
import customerServices from "../services/customer-services";
import chatServices from "../services/chat-services";
import { fetchMessages, setChatGroupsList, setChatGroupsListDetails, setClearmessage, setactiveChatScreenId } from "../actions/chat";
import documentsServices from "../services/documents-services";

const CONNECT_PAGES = [
  "page=create-benchmark",
  "page=register-provider",
];

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_SECRET);
const MAX_DIGIT_SIZE = 999999;
const contact_id = localStorage.getItem("contact_id");
const client_id = localStorage.getItem("client_id");
const userType = localStorage.getItem("user_type");
const contact_role = localStorage.getItem("contact_role");
const selectedDate =
  localStorage.getItem("calendarData") &&
    localStorage.getItem("calendarData") != "undefined" &&
    JSON.parse(localStorage.getItem("calendarData")).length
    ? JSON.parse(localStorage.getItem("calendarData"))[3].date
    : new Date();
const staffId = localStorage.getItem("staff_id");

export const handleNumericInput = (e, previousValue) => {
  const number = e.target.value;
  if (
    number &&
    parseFloat(number) !== NaN &&
    parseFloat(number) < MAX_DIGIT_SIZE
  ) {
    return parseFloat(number);
  } else if (!number || parseFloat(number) === NaN) {
    return 0;
  } else if (parseFloat(number) > MAX_DIGIT_SIZE) {
    return parseFloat(previousValue);
  }
};

export const removeDuplicateObjectsFromArray = (array, key) => {
  const uniqueValues = {};

  const filteredArray = array.filter(obj => {
    const value = obj[key];
    
    if (uniqueValues.hasOwnProperty(value)) {
      return false;
    }
    
    uniqueValues[value] = true;
    return true;
  });

  return filteredArray;
};

export const removeDuplicateObjects = (array) => {
  const ids = array.map((o) => o.id);
  array = array.filter(({ id ,_id }, index) => !ids.includes(id, index + 1) || !ids.includes(_id, index + 1));
  return array;
};

export const validateEmail = (email) => {
  // const re = /\S+@\S+\.\S+/;
  const re = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
  return re.test(email.trim());
};

export const validatePhone = (phoneNumber) => {
  const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

  return re.test(phoneNumber);
};

export const dataURItoBlob = (dataURI) => {
  let byteString = atob(dataURI.split(",")[1]);

  let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};
export const getYearsBetweenDates = (date1, date2 = new Date()) => {
  return Math.abs(
    Math.floor((Date.parse(date2) - Date.parse(date1)) / 31557600000)
  );
};
export const getDaysBetweenDates = (date1, date2 = new Date()) => {
  return Math.abs(
    Math.floor((Date.parse(date2) - Date.parse(date1)) / 86400000)
  );
};
export const getHoursBetweenDates = (date1, date2 = new Date()) => {
  let Difference_In_Time =
    new Date(date2).getTime() - new Date(date1).getTime();
  const hours = Math.floor((Difference_In_Time / 1000 / 60 / 60) % 24);
  return hours;
};
export const getMintuesBetweenDates = (date1, date2 = new Date()) => {
  let Difference_In_Time =
    new Date(date2).getTime() - new Date(date1).getTime();
  var minutes = Math.floor((Difference_In_Time / 1000 / 60) % 60);

  return minutes;
};

export const stringReplacer = (replacements,content) => {
  let newString = content ? content : "";
  replacements.forEach((item) => {
    const regex = new RegExp(item.keyword, 'gi');
    newString = newString.replace(regex, item.replacement === undefined ? '' : item.replacement);
  });
  return newString;
}

export const _l = (key) => {
  const language = localStorage.getItem("language");
  if (language === "english") {
    if(window.location.host === "app.solairehub.com" || window.location.hostname === "localhost" || window.location.host === "app.blaz.app")
    {
      const wordData = require("../languages/french.json");
      return wordData[key] || key;
    }
    else {
      const wordData = require("../languages/english.json");
      return wordData[key] || key;
    }
  } else {
    const wordData = require("../languages/french.json");
    return wordData[key] || key;
  }
};

export const searchObjects = (obj, searchStr) => {
  if (typeof obj === 'string') {
    // If the object is a string, perform a case-insensitive search
    return obj.toLowerCase().includes(searchStr.toLowerCase());
  } else if (Array.isArray(obj)) {
    // If the object is an array, check each element recursively
    for (const element of obj) {
      if (searchObjects(element, searchStr)) {
        return true; // If a match is found in the array, return true
      }
    }
  } else if (typeof obj === 'object' && obj !== null) {
    // If the object is another object, check its properties recursively
    for (const key in obj) {
      if (key != "team_details") {
        if (searchObjects(obj[key], searchStr)) {
          return true; // If a match is found in the object, return true
        }
      }
    }
  }

  return false; // If no match is found, return false
}

/**
 *
 * @param {*} status = true if left panel is to be expanded, false for collapse
 */
export const showLeftPanel = (status, listype) => {
  if (status) {
    hideRightPanelNew();
    showLeftPanelNew();
    if (listype) {
      addExpandedClasstoOfficeList();
      addExpandedClasstoProviderList();
    } else {
      addexpandedClass()
      removeExpandedClasstoProviderList();
      
    }
  } else {
  }
};

/**
 * default Laft panel collapsed
 */
export const DefaultLeftPanel = (withNoClassRightPenel=false) => {
  hideLeftPanelNew();
  if(!withNoClassRightPenel){
    hideRightPanelNew();
  }
  removeExpandedClasstoOfficeList();
  removeExpandedClasstoixedleftpanel();
};
// export const DefaultLeftPanelWithClass = (e) => {
//   jquery("body").addClass("with-left-penel no-left-padding");
// };

/**
 * MouseUp Handler for Left Panel toggle (close on outside click)
 */
export const leftPanelMouseUpEvent = (e) => {
  // const container = jquery(".fixed-left-panel , .loginuserdropdown");
  // if (!container.is(e.target) && container.has(e.target).length === 0) {
  //   removeExpandedClasstoixedleftpanel();
  //   hideLeftPanelNew();
  //   // store.dispatch(setAllChatUserList(false));
  // }
};

/**
 * Left Panel view toggle filter list and office view By default, filter view
 */
export const leftPanelCategoryListToggle = (e) => {
  const container = jquery(".fixed-left-panel");
  if (!container.is(e.target) && container.has(e.target).length === 0) {
    if (window.matchMedia("(max-width: 1500px)").matches) {
      removeExpandedClasstoProviderList();
      removeExpandedClasstoOfficeList();
    } else {
      removeExpandedClasstoProviderList();
      removeExpandedClasstoOfficeList();
    }
  }
};
/**
 * Left Panel toggle on card when detail modal open
 */
export const LeftPanelResponsiveToggle = () => {
  if (window.matchMedia("(max-width: 1500px)").matches) {
    hideLeftPanelNew();
    removeExpandedClasstoixedleftpanel()
  } else {
  }
};
/**
 * task comment modal open + left panel close
 */
export const TaskCommentModalOpen = () => {
  showRightPanelNew()
  jquery("body").addClass("task-modal-offcanvas-open");
  hideLeftPanelNew();
  removeExpandedClasstoixedleftpanel();
};
/**
 * task comment modal close + left panel (close on responsive)
 */
export const TaskCommentModalToggle = () => {
  hideRightPanelNew();
  if (window.matchMedia("(max-width: 1500px)").matches) {
  } else {
    showLeftPanelNew();
  }
};
/**
 * task modal close
 */
export const TaskModalClose = (onCloseShowLeftPanelDefault) => {
  jquery("body").removeClass("task-modal-offcanvas-open");
  jquery("body").removeClass("full-comment-width400");
  if (onCloseShowLeftPanelDefault) {
    hideRightPanelNew();
  } else {
    hideRightPanelNew()
    showLeftPanelNew();
  }
};
/**
 * task comment close + left panel add
 */
export const CloseCommentModal = (isChat) => {
  hideRightPanelNew();
  if (window.matchMedia("(max-width: 1500px)").matches) {
  } else {
    showLeftPanelNew();
  }
  if (isChat) {
    showRightPanelNew()
    hideLeftPanelNew();
  }
};
/**
 * remove tab index from modal when open
 */
export const tabindexFix = () => {
  jquery(".taskDetailModal").removeAttr("tabindex");
  jquery(".custom-modal-style").removeAttr("tabindex");
  jquery(".offcanvas").removeAttr("tabindex");
};
/**
 * with footer + add bottom space on content
 */
export const CardListScroll = () => {
  removeModifiedClass();
};
/**
 * no footer + remove bottom space on content
 */
export const CardListFullHeight = () => {
  jquery(".comman-body-wrapper").addClass("modified-height");
};
/**
 * card view default task  view on page change
 */
export const DefaultListView = () => {
  jquery(".task-list-accordian").removeClass("list-view-toggle");
};
export const HideDropdown = () => {
  jquery(".dropdown-menu").removeClass("show");
  jquery(".dropdown-item").removeClass("show");
};
export const changeTaskStatusFromChecklistItems = (
  checklists,
  taskId,
  currentStatus,
  projectId,
  scheduled = false
) => {
  let completedItems = 0;
  checklists.forEach((checklistItem) => {
    if (checklistItem) {
      completedItems++;
    }
  });
  if (completedItems > 0) {
    if (completedItems !== checklists.length && currentStatus != 4) {
      store.dispatch(updateTaskStatus(taskId, projectId, 4));
      store.dispatch(
        getTaskCount(client_id, contact_id, projectId, selectedDate)
      );
    } else if (completedItems == checklists.length && currentStatus != 5) {
      if (!scheduled) {
        store.dispatch(updateTaskStatus(taskId, projectId, 5));
        store.dispatch(
          getTaskCount(client_id, contact_id, projectId, selectedDate)
        );
      } else {
        CustomerServices.getAttachments(taskId, projectId).then((res) => {
          if (
            res.status == 1 &&
            res.data &&
            res.data.filter((x) => x.staffid == staffId).length > 2
          ) {
            store.dispatch(updateTaskStatus(taskId, projectId, 5));
            store.dispatch(
              getTaskCount(client_id, contact_id, projectId, selectedDate)
            );
          }
        });
      }
    }
  } else if (completedItems == 0 && currentStatus != 1) {
    store.dispatch(updateTaskStatus(taskId, projectId, 1));
    store.dispatch(
      getTaskCount(client_id, contact_id, projectId, selectedDate)
    );
  }
};
export const adjustDynamicInputHeight = (ref,event) => {
  if(ref.current){
    ref.current.style.height = "auto";
    ref.current.style.height = `${event.target.scrollHeight}px`;
  }
}
export const getAssigneeTypesForRequest = () => {
  const userType = localStorage.getItem("user_type");
  let types = [];

  switch (userType) {
    case "contact":
      types = ["providers", 'my_contact', "equipments"];
      break;
    case "operator":
      types = [ "my_operator", "equipments"];
      break;
    case "staff":
      types = ["providers", "my_staff", "my_contact", "myr_support"];
      break;
    default:
      break;
  }
  if (
    localStorage.getItem("selectedVirtualSpace") &&
    Object.keys(localStorage.getItem("selectedVirtualSpace")).length
  ) {
    types.push("task_topic");
  }
  return types;
};

export const dropDownActiveClass = (add = true, selector) => {
  if (add) {
    jquery(`.custom-class-add`).removeClass("active with-tick");
    jquery(`a[data-id='${selector}']`).addClass("active with-tick");
  } else {
    jquery(`a[data-id='${selector}']`).removeClass("active with-tick");
  }
};

export const createGuestProfile = async () => {
  try {
    const response = await AuthServices.createGuestProfile();
    if (response.status) {
      return response.data;
    } else {
      return {};
    }
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const arrayToCSV = (data) => {
  const csvRows = [];
  for (const row of data) {
    const csvColumns = [];
    for (const column of row) {
      csvColumns.push(`"${column}"`);
    }
    csvRows.push(csvColumns.join(','));
  }
  return csvRows.join('\n');
}

export const createCSVFile = (data, filename) => {
  const csvContent = '\uFEFF' + arrayToCSV(data);
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

const setCSVContent = (event, setProcessedCSV) => {
  const content = event.target.result;
  setProcessedCSV(content);
};

export const csvOnLoadHandler = (e, setCsvFile, setProcessedCSV) => {
  const file = e.target.files[0];
  setCsvFile(e.target.files);

  const reader = new FileReader();

  reader.onload = (event) =>  setCSVContent(event, setProcessedCSV);
  reader.readAsText(file, 'utf-8');
};

export const refreshCacheAndReload = () => {
  if (caches) {
    caches.keys().then((names) => {
      for (const name of names) {
        caches.delete(name);
      }
    });
  }
  const openDatabases = indexedDB.databases();
  openDatabases.then(databases => {
    databases.forEach(db => {
      indexedDB.deleteDatabase(db.name);
    });
  });
  window.location.reload(true);
};

export const clearCacheForWhiteScreen = () => {
  const sessionKey = sessionStorage.getItem("current-page-key");
  if(sessionKey !== window.location.pathname)
  {
    sessionStorage.setItem("current-page-key", window.location.pathname);
    refreshCacheAndReload();
  }
}

export const csvRowSplit = (content, columsPerRow = 16) => {
  const result = [];

  let currentLine = '';
  let quoteCount = 0;

  for (let i = 0; i < content.length; i++) {
    const char = content[i];

    if (char === '"') {
      quoteCount++;
    }

    if (char === ',' && quoteCount % 2 === 0) {
      currentLine += char;
    } else if (char === '\n' && quoteCount % 2 === 0) {
      const words = currentLine.trim().split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/);
      if (words.length === columsPerRow) {
        result.push(words);
      }
      currentLine = '';
    } else {
      currentLine += char;
    }
  }

  if (currentLine) {
    const words = currentLine.trim().split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/);
    if (words.length === columsPerRow) {
      result.push(words);
    }
  }

  return result;
}

export const clearPreviousURL = () => {
  if (window.history && window.history.replaceState) {
    window.history.replaceState(null, '', document.referrer);
  }
}

export const  csvValidations = (spreadsheetData) => {
  let validCSV = true;
  spreadsheetData.forEach((row, rowIndex) => {
    if (rowIndex > 0 && row[1] && row[1].trim().length) {
      let spaceTitle = row[0];
      if (spaceTitle == "") {
        validCSV = false;
      }
      let numericFields = row.slice(4, 15);
      numericFields = numericFields.filter((w) =>
        isNaN(w.replace(/\\(.)/g, "$1"))
      );
      if (numericFields.length) {
        validCSV = false;
      }
    }
  });

  return validCSV;
};

/**Variables */
export const leadStatuses = [
  {
    value: "16",
    label: _l("l_todo"),
  },
  {
    value: "19",
    label: _l("l_inprogress"),
  },
  {
    value: "1",
    label: _l("l_done"),
  },
];

export const checklistItem = {
  id: 0,
  description: "",
  slot: "",
  slot_title: "",
  days: {
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
    monthly: false,
    quarterly: false,
    half_yearly: false,
    yearly: false
  },
  frequency: [],
  room_id: 0,
  item_comments: [],
  priority: 0,
};
export const checklistItemFormat = {
  id: 0,
  slot: "",
  slot_title: "",
  room_id: 0,
  checklist: [],
  days: {
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
    monthly: false,
    quarterly: false,
    half_yearly: false,
    yearly: false
  },
};
export const convertTime12To24 = (time) => {
  var hours = Number(time.match(/^(\d+)/)[1]);
  var minutes = Number(time.match(/:(\d+)/)[1]);
  var AMPM = time.match(/\s(.*)$/)[1];
  if ((AMPM == "PM" || AMPM == "pm") && hours < 12) hours = hours + 12;
  if ((AMPM == "AM" || AMPM == "am") && hours == 12) hours = hours - 12;
  var sHours = hours.toString();
  var sMinutes = minutes.toString();
  if (hours < 10) sHours = "0" + sHours;
  if (minutes < 10) sMinutes = "0" + sMinutes;
  return sHours + ":" + sMinutes;
};
export const compareDates = (d1, d2) => {
  let date1 = new Date(d1).getTime();
  let date2 = new Date(d2).getTime();

  if (date1 < date2) {
    return -1;
  } else if (date1 > date2) {
    // ${d1} is greater than ${d2}`
    return 1;
  } else {
    // `Both dates are equal`
    return 0;
  }
};

export const handleImageUploadPreviews = (
  currentPreviews,
  setterState,
  files,
  remove = false
) => {
  let allPreviews = currentPreviews;
  if (!remove) {
    Array.from(files).map((img) => {
      allPreviews.push({ name: img.name, url: URL.createObjectURL(img) });
    });
    setterState(allPreviews);
  } else {
    allPreviews = allPreviews.filter((x) => x.url != files.url);
    setterState(allPreviews);
  }
};

export const countCheckedValues = (checklistArray, slotIndex, days) => {
  let count = 0;
  checklistArray.forEach((checklist) => {
    if (checklist.assigned_days) {
      Object.keys(checklist.assigned_days).forEach((day_name) => {
        if (days[day_name] && checklist.days[day_name] && checklist.assigned_days[day_name] == slotIndex) {
          count += 1;
        }
      });
    }
  });
  return count;
};

export const updateActionCounts = (operatorArray, checklistArray) => {
  //Travarse through all slots selected
  operatorArray.forEach((slot, slotIndex) => {
    slot.action_count = 0;
    //Traverse through all the checklist tasks
    checklistArray.forEach((checklist) => {
      //Compare 7 days and 4 frequencies
      Object.keys(slot.days).forEach((day_name) => {
        if (
          slot.days[day_name] &&
          checklist.days[day_name] &&
          (!checklist.assigned_days ||
            checklist.assigned_days[day_name] == undefined ||
            checklist.assigned_days[day_name] == -1 ||
            checklist.assigned_days[day_name] == slotIndex)
        ) {
          slot.action_count += 1;
        }
      });
    });
  });
}

export const isSameArrays = (array1, array2) => {
  return array1.length === array2.length && array1.every((value, index) => value === array2[index])
}

export const matchSlots = (mainSlot, subSlot) => {
  let mainSlotDays = Object.keys(mainSlot).filter(x => mainSlot[x]);
  let subSlotDays = Object.keys(subSlot).filter(x => subSlot[x]);
  if (!subSlotDays.length) {
    return false;
  }
  mainSlotDays = mainSlotDays.filter(x => subSlotDays.includes(x));
  return mainSlotDays.length == subSlotDays.length
}

export const getItemId = (benchmarkId, typeofSPaceId) => {
  if (benchmarkId > 0) {
    const allTypeOfSpaces = store.getState().benchmarkReducer.spaceRooms;
    let item_id = 0;
    const rel_types = ["benchmark", "schedule"];
    allTypeOfSpaces.forEach((space) => {
      if (space.item_id == typeofSPaceId && rel_types.includes(space.rel_type) && benchmarkId == space.rel_id) {
        item_id = space.id;
      }
    });
    return item_id;
  }
  return 0;
}

export const getTypeOfSpaceQuantity = (typeOfSpace, schedule_id) => {
  const spaceRooms = store.getState().benchmarkReducer.spaceRooms;
  let spaceQuantity = 0;
  if (spaceRooms && spaceRooms.length) {
    const scheduleSpaceIndex = spaceRooms.findIndex(w => (w.rel_type === "schedule" && w.rel_id == schedule_id && w.item_id == typeOfSpace.id));
    if (scheduleSpaceIndex > -1) {
      spaceQuantity = spaceRooms[scheduleSpaceIndex].quantity;
    }
    else {
      spaceQuantity = typeOfSpace.quantity;
    }
  }
  return spaceQuantity;
}

export const isTypeOfSpaceSelected = (scheduleId, typeOfSpaceId, allTypeOfSpaces) => {
  let flag = false;
  if (allTypeOfSpaces && allTypeOfSpaces.length) {
    allTypeOfSpaces.forEach((space) => {
      if (space.item_id == typeOfSpaceId && space.rel_type == "schedule" && space.rel_id == scheduleId && space.checked) {
        flag = true;
      }
    });
  }
  return flag;
};

export const getScheduleTypeOfSpaceId = (spaceId, scheduleId) => {
  const allTypeOfSpaces = store.getState().benchmarkReducer.spaceRooms;
  let id = spaceId;
  allTypeOfSpaces.forEach((w) => {
    if (w.item_id == spaceId && w.rel_type == "schedule" && w.rel_id == scheduleId) {
      id = w.id;
    }
  });
  return id;
}

export const getFileType = (url) => {
  let imageExtentions = [".png", ".jpg", ".jpeg", ".svg", ".gif"];
  let videoExtentions = [".mp4", ".m4r", ".m4a"];
  let pdfExtenstions = ["pdf", "pdf"];

  if(!url)
  {
    return "image"
  }

  let type = "";

  imageExtentions.forEach((w) => {
    if (url.includes(w)) {
      type = "image";
    }
  });

  videoExtentions.forEach((w) => {
    if (url.includes(w)) {
      type = "video";
    }
  });

  pdfExtenstions.forEach((w) => {
    if (url.includes(w)) {
      type = "pdf";
    }
  });

  return type;
};

export const getImagePreviewIcon = (img) => {
  let preview_url = "";
  if (img.type.includes('image')) {
    preview_url = URL.createObjectURL(img);
  }
  else if (img.type.includes('pdf')) {
    preview_url = FilePdf;
  }
  else if (img.type.includes('video')) {
    preview_url = FileVideo;
  }
  else if (img.type.includes('audio')) {
    preview_url = FileAudio;
  }
  else if (img.type.includes('zip')) {
    preview_url = FileZip;
  }
  else if (img.name.includes('.xls')) {
    preview_url = FileCsv;
  }
  else if (img.name.includes('.doc')) {
    preview_url = FileDoc;
  }
  else if (img.name.includes('.txt')) {
    preview_url = File;
  }
  else if (img.name.includes('.rar')) {
    preview_url = FileZip;
  }
  else if (img.name.includes('.glb')) {
    preview_url = File;
  }
  return preview_url;
}

export const getPreviewFromURL = (url) => {
  let imageExtentions = ['png', 'jpg', 'jpeg', 'svg', 'gif'];
  let videoExtentions = ['.mp4', '.m4r', '.m4a', 'mp4', 'm4r', 'm4a'];
  let audioExtentions = ['.mp3', '.wav'];

  let fileExtenstion = url.split(".");
  fileExtenstion = fileExtenstion[fileExtenstion.length - 1];

  if (imageExtentions.includes(fileExtenstion)) {
    return url;
  }
  else if (videoExtentions.includes(fileExtenstion)) {
    return FileVideo;
  }
  else if (audioExtentions.includes(fileExtenstion)) {
    return FileAudio;
  }
  else if (['.pdf', 'pdf'].includes(fileExtenstion)) {
    return FilePdf;
  }
  else if (['.doc', '.docx', 'txt', 'doc', 'docx'].includes(fileExtenstion)) {
    return FileDoc;
  }
  else if (['.xls', '.xlsx', '.csv', 'xls', 'xlsx', 'csv'].includes(fileExtenstion)) {
    return FileCsv;
  }
  else if (['.zip', '.tar', '.rar', 'zip', 'tar', 'rar'].includes(fileExtenstion)) {
    return FileZip;
  }
  else {
    return File;
  }
}

export const updateScheduleSlotKPICounts = (masterChecklistItem) => {
  let headerCounts = {
    mon: 0,
    tue: 0,
    wed: 0,
    thu: 0,
    fri: 0,
    sat: 0,
    sun: 0,
    monthly: 0,
    quarterly: 0,
    yearly: 0,
    half_yearly: 0,
  };
  if (masterChecklistItem && masterChecklistItem.length) {
    masterChecklistItem.forEach((checklist) => {
      if (checklist.days) {
        Object.keys(checklist.days).forEach((day_name) => {
          if (checklist.days[day_name] && (checklist.assigned_days[day_name] == -1)) {
            headerCounts[day_name] += 1;
          }
        });
      }
    });
  }
  return { headerCounts };
}

export const getBenchmarkProjectId = (benchmarkId) => {
  let defaultProjectId = store.getState().customer.selectedProject;
  let benchmarkList = store.getState().customer.benchmarkList;
  if (benchmarkList && benchmarkList.length) {
    let currentBenchmarkIndex = benchmarkList.findIndex((w) => w.id == benchmarkId);
    if (currentBenchmarkIndex > -1) {
      return benchmarkList[currentBenchmarkIndex].project_id;
    }
  }
  return defaultProjectId;
}

export const copyToClipboard = (text) => {
  try {
    navigator.clipboard.writeText(text);
    store.dispatch(showMessage("success", _l("l_success"), _l("l_copied_to_clipboard")))
  }
  catch(e)
  {
    console.error(e);
    showError("l_something_went_wrong");
  }
}

export const utf8CompareString = (string1, string2) => {
  let flag = true;
  const collator = new Intl.Collator(undefined, { sensitivity: 'base', ignorePunctuation: true });
  flag = collator.compare(string1, string2) === 0;
  return flag;
}

export const getClientIdByProject = (projectId = 0) => {
  const spaces = JSON.parse(localStorage.getItem("spaces"));
  const userType = localStorage.getItem("user_type");
  const client_id = localStorage.getItem("client_id");

  if (userType == "contact") {
    return client_id;
  }

  if (!projectId) {
    projectId = store.getState().customer.selectedProject;
  }

  let currentSpace = spaces.find(w => w.project_id == projectId);
  if (currentSpace) {
    return currentSpace.client_id;
  }
  else {
    return 0;
  }
}

export const getContactIdBySpace = (projectId) => {
  const spaces = JSON.parse(localStorage.getItem("spaces"));
  const userType = localStorage.getItem("user_type");

  if (userType != "operator") {
    return 0;
  }

  if (!projectId) {
    projectId = store.getState().customer.selectedProject;
  }

  let currentSpace = spaces.find(w => w.project_id == projectId);
  if (currentSpace) {
    return currentSpace.contact_id;
  }
  else {
    return 0;
  }
}


const getValueFromObject = (data, key) => {
  return data && data[key] ? data[key] : "";
}

export const setBenchmarkModalData = (benchmarkDetail, benchmarkSchedule, spaceRooms) => {
  let tempStartDate =
    benchmarkDetail.startdate && benchmarkDetail.startdate != "0000-00-00"
      ? new Date(benchmarkDetail.startdate)
      : addDays(new Date(), 10);
  let editDetails = {
    providerCategory: benchmarkDetail.provider_id,
    clientId: getValueFromObject(benchmarkDetail, 'client_id'),
    isSchedule: getValueFromObject(benchmarkDetail, 'is_schedule'),
    categoryTitle: getValueFromObject(benchmarkDetail, 'provider_name'),
    regionId: getValueFromObject(benchmarkDetail, 'region_id'),
    benchmarkId: getValueFromObject(benchmarkDetail, 'id'),
    startDate: tempStartDate,
    endDate: benchmarkDetail.contract_end_date &&
      benchmarkDetail.contract_end_date != "0000-00-00"
      ? new Date(benchmarkDetail.contract_end_date)
      : benchmarkDetail.id && benchmarkDetail.id > 0
        ? ""
        : addDays(tempStartDate, 30),
    amount: getValueFromObject(benchmarkDetail, 'max_budget'),
    basicInfo: getValueFromObject(benchmarkDetail, 'basic_information'),
    needToHire: getValueFromObject(benchmarkDetail, 'need_to_hire_people') > 0,
    isUnKnownStartDate: getValueFromObject(benchmarkDetail, 'start_date_is_empty') == 1,
    isUnKnownBudget: getValueFromObject(benchmarkDetail, 'budget_is_empty') == 1,
    furnished: getValueFromObject(benchmarkDetail, 'is_furnished') > 0,
    selectedLinks: getValueFromObject(benchmarkDetail, 'links'),
    benchmarkStatus: (benchmarkDetail && benchmarkDetail.is_schedule == "1" && benchmarkDetail.status != "2" && benchmarkDetail.status != "5") || benchmarkDetail.status == 1 ? false : true,
    scheduleStatus: benchmarkDetail && benchmarkDetail.status ? benchmarkDetail.status : 1,
    statusText: getValueFromObject(benchmarkDetail, 'status_text'),
    selectedSchedule: getValueFromObject(benchmarkDetail, 'is_schedule') == 1 && getValueFromObject(benchmarkDetail, 'operator_details') != '' ? getValueFromObject(benchmarkDetail, 'operator_details')[0] : {},
    selectedBenchmarkOperator: getValueFromObject(benchmarkDetail, 'is_schedule') == 0 || benchmarkDetail.master_id && getValueFromObject(benchmarkDetail, 'operator_details') != '' ? getValueFromObject(benchmarkDetail, 'operator_details').map((operator) => operator.email) : [],
    operators: getValueFromObject(benchmarkDetail, 'operator_details') != '' ? getValueFromObject(benchmarkDetail, 'operator_details') : [],
    is_allow_to_edit: benchmarkDetail &&
      (benchmarkDetail.status == "3" ||
        benchmarkDetail.status == "4")
      ? true
      : false,
    accessories: getValueFromObject(benchmarkDetail, 'accessories'),
    schedule_assignee_details:
      // benchmarkSchedule && Object.keys(benchmarkSchedule).length && benchmarkSchedule.schedule_assignee_details ? benchmarkSchedule.schedule_assignee_details.map((assignee) => {
      schedule_assignee_details ? schedule_assignee_details.map((assignee) => {
        assignee['name'] = assignee.user_details.full_name
        assignee['url'] = assignee.user_details.image_url
        assignee['available_from'] = new Date(`01-01-2000 ${assignee.available_from}`)
        assignee['uniqueID'] = assignee.id
        let checklistItem = [];
        let spaceRoom = []
        let spaceRoomWithChecklistItem = []
        assignee['item_id'].map((item) => {
          // let isAssigneesChecklistExist = benchmarkSchedule.week_schedule_details.filter((week) => item == week.id)
          let isAssigneesChecklistExist = week_schedule_details.filter((week) => item == week.id)
          if (isAssigneesChecklistExist.length) {
            isAssigneesChecklistExist.map((checklist) => {
              checklist['is_checked'] = 1
              checklistItem.push(checklist)
              if (spaceRoom.filter((space) => space.room_id == checklist.room_id).length == 0) {
                spaceRoom.push({ room_id: checklist.room_id, room_name: checklist.room_name })
              }
            })

          }
        })
        spaceRoom.map((space) => {
          let checklist = checklistItem.filter((item) => item.room_id == space.room_id)
          space['checklistItem'] = checklist
          spaceRoomWithChecklistItem.push(space)
        })
        assignee['spaceRoom'] = spaceRoom
        assignee['checklistItem'] = checklistItem
        assignee['spaceRoomWithChecklistItem'] = spaceRoomWithChecklistItem

        return assignee
      }) : [],
    schedule_total_count: benchmarkSchedule && Object.keys(benchmarkSchedule).length && benchmarkSchedule.total_count ? benchmarkSchedule.total_count : {},
    week_schedule_details: benchmarkSchedule && Object.keys(benchmarkSchedule).length && benchmarkSchedule.week_schedule_details ? benchmarkSchedule.week_schedule_details : [],
    master_id: benchmarkDetail.master_id || 0,
    step1_selectedTab: benchmarkDetail.master_id ? 'existing' : 'new'
  }
  const provider_name = benchmarkDetail.provider_name;
  if (
    provider_name &&
    (provider_name.toLowerCase().trim().includes("cleaning") ||
      provider_name.toLowerCase().trim().includes("nettoyage")) &&
    benchmarkDetail.is_schedule == "0"
  ) {
    editDetails['includeAccessoriesTab'] = true
  }
  if (
    getValueFromObject(benchmarkDetail, 'creator_details') &&
    Object.keys(getValueFromObject(benchmarkDetail, 'creator_details')).length > 0
  ) {
    editDetails['creatorFullName'] = getValueFromObject(benchmarkDetail, 'creator_details').full_name
    editDetails['creatorProfileUrl'] = getValueFromObject(benchmarkDetail, 'creator_details').profile_image_url
  }
  if (getValueFromObject(benchmarkDetail, 'proposal_list') && getValueFromObject(benchmarkDetail, 'proposal_list').length
  ) {
    editDetails['benchmarkProposal'] = getValueFromObject(benchmarkDetail, 'proposal_list')
  }
  //Check if the benchmark is already active but budget and start date are unknown, user can update it in active status (But only once)
  if (
    getValueFromObject(benchmarkDetail, 'status') == 3 || getValueFromObject(benchmarkDetail, 'status') == 4) {
    if (getValueFromObject(benchmarkDetail, 'start_date_is_empty') == "1") {
      editDetails['canUpdateStartDate'] = true
    }

    if (getValueFromObject(benchmarkDetail, 'budget_is_empty') == "1") {
      editDetails['canUpdateBudget'] = true
    }
  }

  let weekScheduleSpace = benchmarkSchedule && Object.keys(benchmarkSchedule).length ? benchmarkSchedule : {}
  let typeOfSpaces = spaceRooms;
  if (typeOfSpaces && typeOfSpaces.length) {
    let currentScheduleSpaces = getValueFromObject(benchmarkDetail, 'type_of_spaces') || [];
    typeOfSpaces.forEach((space) => {
      if (currentScheduleSpaces.findIndex((x) => x.is_schedule == 1 && x.id == space.id) !== -1) {
        space['checked'] = true;
      }
    });
  }
  if (typeOfSpaces.length) {
    if (weekScheduleSpace && weekScheduleSpace.daily && weekScheduleSpace['week_schedule_details'].length) {
      let prepareFormatChecklist = []
      weekScheduleSpace['week_schedule_details'].forEach(item => {
        let tempChecklist = {
          ...checklistItem, ['slot']: new Date(`01-01-2000 ${item.item_slot}`), ['id']: item.id, ['slot_title']: item.item_name, ['quantity']: item.quantity, slot_row_id: item.slot_row_id,
          ['days']: {
            mon: item.days.mon,
            tue: item.days.tue,
            wed: item.days.wed,
            thu: item.days.thu,
            fri: item.days.fri,
            sat: item.days.sat,
            sun: item.days.sun,
            monthly: item.days.monthly,
            quarterly: item.days.quarterly,
            half_yearly: item.days.half_yearly,
            yearly: item.days.yearly
          },
          ['description']: item.item_name,
          ['unique_id']: item['checklist'].length ? item['checklist'][0].unique_id : '',
          ['frequency']: item.frequency && item.frequency != null ? item.frequency : [],
          ['room_id']: item.room_id,
          ['checked']: true,
          ['priority']: item.priority,
          ['item_comments']: item.item_comments
        };
        prepareFormatChecklist.push(tempChecklist)
      });
      store.dispatch(getSpaceRoomsItem(prepareFormatChecklist));
    } else {
      store.dispatch(getSpaceRoomsItem([]));
    }


    store.dispatch(getSpaceRooms(typeOfSpaces));
    store.dispatch(createScheduleDetails(editDetails));
  }
  return editDetails;
}

export const getScheduleTitle = (details) => {
  let titleString = "";
  if (details.parent_id > 0) {
    titleString = `#${details.parent_id} - ${details.list_order} - ${details.category}  ${details.parent_detail ? `(Created From ${details.parent_detail})` : ""}`;
  }
  else {
    titleString = `#${details.id} - ${details.list_order} - ${details.category}`;
  }
  return titleString;
}

export const getDatePickerLocale = () => {
  const language = localStorage.getItem("language");
  switch (language) {
    case "english":
      return en;
    case "french":
      return fr;
    default:
      return fr;
  }
}

export const getDatePickerTimeCaption = () => {
  const language = localStorage.getItem("language");
  switch (language) {
    case "english":
      return "Time";
    case "french":
      return "Temps";
    default:
      return "Time";
  }
}

export const generateButtonText = (status) => {
  if (status == 4) {
    return ("l_regenerate");
  }
  else {
    return ("l_generate");
  }
}

export const getCyclicDate = (startDate, cycleDays) => {
  let maxDate = moment();
  switch (cycleDays) {
    case 30:
      maxDate = startDate.clone().add(1, 'month').subtract(1, 'day');
      return maxDate;
    case 90:
      maxDate = startDate.clone().add(3, 'months').subtract(1, 'day');
      return maxDate;
    case 180:
      maxDate = startDate.clone().add(6, 'months').subtract(1, 'day');
      return maxDate;
    case 365:
      maxDate = startDate.clone().add(1, 'year').subtract(1, 'day');
      return maxDate;
    default:
      return maxDate;
  }
}

export const getDateRanges = (scheduleStartDate, cycleDays) => {

  let minDate = "";
  let maxDate = getCyclicDate(scheduleStartDate, cycleDays);

  let currentDate = moment();
  minDate = currentDate;

  if (scheduleStartDate.isAfter(currentDate)) {
    minDate = scheduleStartDate;
  }
  else {
    maxDate = moment(maxDate);
    if (currentDate.isAfter(maxDate)) {
      let scheduleDateValue = new Date(scheduleStartDate.format("YYYY-MM-DD")).getDate();
      let currentMonthScheduleDate = new Date().setDate(scheduleDateValue);
      currentMonthScheduleDate = moment(currentMonthScheduleDate);

      if (currentMonthScheduleDate.isAfter(currentDate)) {
        minDate = currentMonthScheduleDate;
      }
      else {
        minDate = currentDate;
      }
      maxDate = getCyclicDate(currentMonthScheduleDate, cycleDays);
    }
  }
  return { minDate: minDate.format("YYYY-MM-DD"), maxDate: maxDate.format("YYYY-MM-DD") }
}

export const updateFloorFilters = (id) => {
  let activeFilters = store.getState().benchmarkReducer.filteredFloorIds.slice();
  let selectedFloorIds = store.getState().benchmarkReducer.selectedFloorIds.slice();
  const floorIds = selectedFloorIds && selectedFloorIds.length ? selectedFloorIds : store.getState().benchmarkReducer.floorDetails.floor.map((x) => x.id);

  if (id != -1) {
    if (activeFilters.findIndex((x) => x == id) === -1) {
      activeFilters.push(id);
    }
    else {
      activeFilters = activeFilters.filter((x) => x != id);
    }
    store.dispatch(setFloorFilters(activeFilters));
  }
  else {
    store.dispatch(setFloorFilters(activeFilters.length > 0 && activeFilters.length == floorIds.length ? [] : floorIds));
  }
}

export const getFloorsListForFilter = () => {
  let floorList = store.getState().benchmarkReducer.floorDetails.floor.slice();
  let options = [];
  let selectedFloors = store.getState().benchmarkReducer.selectedFloorIds.slice();
  if (selectedFloors.length) {
    let filteredList = floorList.filter((x) => selectedFloors.includes(x.id));
    if (selectedFloors.length == 1) {
      options = filteredList.map((x) => { return { id: x.id, name: x.title, image: "" } });
    }
    else {
      options = [{ id: -1, name: _l("l_all"), image: "" }].concat(filteredList.map((x) => { return { id: x.id, name: x.title, image: "" } }));
    }
  }
  else {
    options = [{ id: -1, name: _l("l_all"), image: "" }].concat(floorList.map((x) => { return { id: x.id, name: x.title, image: "" } }));
  }
  return options;
}

export const setPageMetaDataDescription = (description) => {
  const descriptionMetaTag = document.querySelector('meta[name="description"]');
  if (descriptionMetaTag) {
    descriptionMetaTag.setAttribute('content', description);
  }
}

export const getActiveAccordionKeys = (listOfDataArrays) => {
  let activeKeys = listOfDataArrays.map((x, i) => {
    if (x > 0) {
      return (i + 1).toString();
    }
    else {
      return ""
    }
  });
  return activeKeys.filter((x) => x);
}

export const isSpaceAddedByProvider = (spaceId) => {
  const spaces = JSON.parse(localStorage.getItem("spaces"));
  let flag = false;

  if (spaces) {
    let currentSpace = spaces.find((x) => x.id == spaceId);
    const client_id = localStorage.getItem("client_id");
    if (currentSpace && currentSpace.is_added_from_operator != 0 && (currentSpace.client_id == client_id)) {
      flag = true;
    }
  }
  return flag;
}

/**
 * 
 * @param {*} spaces List of the spaces
 * After the concept of light version get introduced, now we have to check if the user has at least one space with onboarding completed in order to access the light version page
 */
export const isUserHaveOnboardingSpace = (spaces) => {
  let flag = false;
  const userType = localStorage.getItem("user_type");
  if(userType === "staff")
  {
    return true;
  }

  spaces.forEach((space) => {
    if(userType === "contact")
    {
      if(space.is_onboard_completed_contact)
      {
        flag = true;
      }
    }
    else {
      if(space.is_onboard_completed_operator)
      {
        flag = true;
      }
    }
  });
  return flag;
}

/**
 * Function to check if current customer is allow to access a paid feature or not in case customer is invited by the provider and haven't paid for a plan
 * @param {*} projectId 
 * @returns 
 */

export const isAllowedToAccessPremiumFeatures = (projectId) => {
  const spaces = JSON.parse(localStorage.getItem("spaces"));
  const userType = localStorage.getItem("user_type");

  let flag = true;

  //As per the discussion with client, temporary removed this restriction

  // if (userType != "contact") {
  //   return true;
  // }

  // if (spaces) {
  //   let currentSpace = spaces.find((x) => x.project_id == projectId);
  //   if (currentSpace && !Number(currentSpace.is_customer_invited) && currentSpace.is_paymented == 1) {
  //     flag = true;
  //   }
  // }
  return flag;
}

/**
 * Function to check user have at least one premium space
 */
export const isCustomerHavePaidSpace = () => {
  const spaces = JSON.parse(localStorage.getItem("spaces"));
  const userType = localStorage.getItem("user_type");

  let flag = false;

  if (userType != "contact") {
    return true;
  }

  if (spaces) {
    spaces.forEach((space) => {
      if (!Number(space.is_customer_invited)) {
        flag = true;
      }
    });
  }
  return flag;
}

/**
 * 
 * @param {*} projectId  : Current Space - Prpject Id
 * @param {*} continueAccessingFeature : flow / line of code that will be executed if user own's the feature
 * @param {*} minimumFeature : If the feature required to have only one space with upgraded plan
 * This function will first verify that current logged in customer with selected space has premium plan or not, if space has premium plan, user can access the feature. otherwise user will be asked to upgrade space plan in order to access the feature.
 */

export const accessPremiumFeature = (projectId, continueAccessingFeature, minimumFeature) => {
  if (isAllowedToAccessPremiumFeatures(projectId) || (minimumFeature && isCustomerHavePaidSpace())) {
    continueAccessingFeature();
  }
  else {
    store.dispatch(toggleFeatureRestrictionModal(true));
  }
}

export const showError = (messageKey) => {
  store.dispatch(showMessage("unsucess", _l("l_error"), _l(messageKey)));
}

export const showSuccessMessage = (messageKey) => {
  store.dispatch(showMessage("success", _l("l_success"), _l(messageKey)));
}

export const showSuccess = (messageKey) => {
  store.dispatch(showMessage("sucess", _l("l_success"), _l(messageKey)));
}

export const updateSpaceDetailInLocalStorage = (projectId, spaceDetails) => {
  let spaces = localStorage.getItem("spaces");
  spaces = spaces && spaces.length ? JSON.parse(spaces) : [];
  if(spaces.length)
  {
    spaces = spaces.map((space) => {
      if(space.project_id == projectId)
      {
        space = {...space, ...spaceDetails};
      }
      return space;
    });
    localStorage.setItem("spaces", JSON.stringify(spaces));
    insertCollectionToDB("spaces", spaces, "project_id");
  }
}

/***
 * Generate Device Fingerprint using library
 */

export const getDeviceFingerprintUsingLibrary = async () => {
   try {
    const { default: FingerprintJS } = await import('@fingerprintjs/fingerprintjs');  
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    return result.visitorId;
   }
   catch (e)
   {
      console.error(e);
      return "";
   }
}

/***
 * Incase fingerprint didn't get generated by the 3rd party library, create finger print using native parameter
 */

export const generateNativeFingerPrint = async() => {
  const userAgent = window.navigator.userAgent;
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const timezoneOffset = new Date().getTimezoneOffset();
  const language = window.navigator.language;
  const colorDepth = window.screen.colorDepth;

  const fingerprint =
    userAgent + screenWidth + screenHeight + timezoneOffset + language + colorDepth;

  const encoder = new TextEncoder();
  const data = encoder.encode(fingerprint);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);

  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");

  return hashHex;
}

/**
 * Block 29, 30 and 31 dates for react date picker
 */

export const isBlocked = date => {
  const day = date.getDate();
  return ![29, 30, 31].includes(day);
}; 

/**
 * Validate IBAN number
 */

export function validateIBAN(iban) {

  iban = iban.replace(/\s+/g, '').toUpperCase();

  const country = iban.slice(0, 2);
  
  if (!VALID_IBAN_LENGTHS[country] || iban.length !== VALID_IBAN_LENGTHS[country]) {
    return false;
  }

  const rearrangedIBAN = iban.slice(4) + iban.slice(0, 4);

  const numericIBAN = rearrangedIBAN.replace(/[A-Z]/g, (match) => {
    return (match.charCodeAt(0) - 55).toString();
  });

  let remainder = 0;
  for (let i = 0; i < numericIBAN.length; i += 7) {
    const chunk = parseInt(remainder + numericIBAN.substr(i, 7), 10);
    remainder = chunk % 97;
  }

  return remainder === 1;
};

export const countryOptionsForIBAN = Object.keys(VALID_IBAN_LENGTHS).map(countryCode => {
  //
  return { value: countryCode, label: `${countryCode}`};
});

/**
 * Check if the payment for the space has been done or not
 */

export const paymentDone = (projectId) => {
  let spaces = localStorage.getItem("spaces");
  spaces = JSON.parse(spaces) || [];
  let project = spaces.find((w) => w.project_id == projectId);
  if(!project)
  {
    return false;
  }
  else {
    return project.is_paymented !== 0;
  }
}

export const scheduleAvailabilityOperator = {
  assignee_id: 0,
  assignee_detail: "",
  available_from: "",
  task_title: "",
  days: {
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
    monthly: false,
    quarterly: false,
    half_yearly: false,
    yearly: false
  },
  frequency: [],
  uniqueID: 0,
};

export const schedule_assignee_details = [
  {
    id: "1",
    assignee_id: "1648",
    available_from: "10:00:00",
    task_title: "Test Task 1",
    user_details: {
      full_name: "Test op",
      image_url:
        "http://192.168.1.119/work/myrai-web/web/orb/assets/images/default-image-placeholder.svg",
    },
    days: {
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: false,
      sat: false,
      sun: true,
    },
    item_id: ["1", "4", "5"],
  },
  {
    id: "2",
    assignee_id: "1651",
    available_from: "10:00:00",
    task_title: "Test Task 2",
    user_details: {
      full_name: "Test user 32",
      image_url:
        "http://192.168.1.119/work/myrai-web/web/orb/assets/images/default-image-placeholder.svg",
    },
    days: {
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: true,
      sat: true,
      sun: true,
    },
    item_id: ["2"],
  },
  {
    id: "3",
    assignee_id: "1652",
    available_from: "10:00:00",
    task_title: "Test Task 2",
    user_details: {
      full_name: "Test emp 1",
      image_url:
        "http://192.168.1.119/work/myrai-web/web/orb/assets/images/default-image-placeholder.svg",
    },
    days: {
      mon: false,
      tue: false,
      wed: true,
      thu: true,
      fri: false,
      sat: false,
      sun: false,
    },
    item_id: ["3"],
  },
];
export const week_schedule_details = [
  {
    id: "1",
    room_id: "1",
    room_name: "General Area",
    item_name: "Item Name 1",
    item_slot: "12:00:00",
    days: {
      mon: true,
      tue: true,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
    },
    checklist: [
      {
        name: "Nettoyage Frigo & Micro-ondes",
        selected: 1,
        photo: "1",
        tasks_per_month: "1",
        unique_id: 754,
      },
    ],
  },
  {
    id: "2",
    room_id: "1",
    room_name: "General Area",
    item_name: "Item Name 2",
    item_slot: "11:00:00",
    days: {
      mon: false,
      tue: false,
      wed: true,
      thu: true,
      fri: false,
      sat: false,
      sun: false,
    },
    checklist: [
      {
        name: "Mettre en marche le lave vaisselle dès votre arrivée (en programme express) et le vider en partant et ranger les tasses à coté de la machine à café dans chaque bureau",
        selected: 1,
        photo: "0",
        tasks_per_month: "1",
        unique_id: 288,
      },
    ],
  },
  {
    id: "3",
    room_id: "1",
    room_name: "General Area",
    item_name: "Item Name 3",
    item_slot: "12:00:00",
    days: {
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: true,
      sat: true,
      sun: false,
    },
    checklist: [],
  },
  {
    id: "4",
    room_id: "2",
    room_name: "Space new 1",
    item_name: "Item Name 4",
    item_slot: "01:00:00",
    days: {
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: true,
    },
    checklist: [],
  },
  {
    id: "5",
    room_id: "2",
    room_name: "Space new 1",
    item_name: "Item Name 5",
    item_slot: "01:00:00",
    days: {
      mon: false,
      tue: true,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
    },
    checklist: [],
  },
];

export const schedule_checklist_assignee = {
  assignee_id: 0,
  items: [],
};

export const dashboardFilterByDefault = [
  {
    "filter_key": "created_by_me",
    "main_key": "my_task",
    "title": "l_by_me",
    "image": ""
  },
  {
    "filter_key": "assigned_to_me",
    "main_key": "my_task",
    "title": "l_to_me",
    "image": ""
  },
  {
    "filter_key": "pinned",
    "main_key": "my_task",
    "title": "l_pinned",
    "image": ""
  }
]

export const selectedAssigneeForTaskAndRequest = (selectedAssigneeNew, setTaskAssigneePills, setTaskAssignee, defaultTopics) => {
    let task_assignee = [];
    let task_assignee_pills = [];
    if (selectedAssigneeNew.length > 0) {
      selectedAssigneeNew.map((ta) => {
        ta.categories && ta.categories.map((taskAssign) => {
          if (
            ta.main_key == "myr_staff_task" ||
            ta.main_key == "my_people" ||
            ta.main_key == "my_contact" ||
            ta.main_key == "my_operator" ||
            ta.main_key == "my_staff"

          ) {
            defaultTopics &&
              defaultTopics.forEach((topic) => {
                if (topic.main_key == ta.main_key) {
                  topic.categories.forEach((provider) => {
                    if (taskAssign.filter_key == provider.filter_key) {
                      task_assignee.push({
                        name: provider.title,
                        profile_image: provider.image,
                        id: provider.filter_key,
                        main_key :topic.main_key
                      });
                    }
                  });
                }
              });
          } else {
            defaultTopics &&
              defaultTopics.forEach((topic) => {
                if (topic.main_key == ta.main_key) {
                  topic.categories.forEach((provider) => {
                    if (taskAssign.filter_key == provider.filter_key) {
                      // if (topic.main_key != "equipments" && !(topic.main_key == "provider_task" && CUSTOM_CLIENT_IDS.includes(client_id) && contact_role != 3)) {
                      if (topic.main_key != "equipments" && topic.main_key != "providers")  {
                        task_assignee_pills.push({
                          name: provider.title,
                          id: provider.filter_key,
                          main_key: topic.main_key
                        });
                      }
                      if (topic.main_key == "providers" && ((userType == "contact" && taskAssign.filter_key != "myrai_support") || userType == "staff" || userType == "operator")) {
                        if(taskAssign.sub_category && taskAssign.sub_category.length > 0){
                          task_assignee_pills.push({
                            name: provider.title,
                            id: provider.filter_key,
                            main_key: topic.main_key
                          });
                        }
                        else if(topic.main_key == "providers" && (!taskAssign.sub_category || !taskAssign.sub_category.length)) {
                          task_assignee_pills.push({
                            name: provider.title,
                            id: provider.filter_key,
                            main_key: topic.main_key
                          });
                        }
                        taskAssign.sub_category && taskAssign.sub_category.length > 0 && taskAssign.sub_category.forEach((dd) => {
                          if (dd) {
                            task_assignee.push({
                              name: dd.fullname,
                              id: dd.id,
                              profile_image: dd.profile_image_url,
                              main_key: topic.main_key
                            });
                          }
                        })
                      }else if(topic.main_key == "providers" && taskAssign.filter_key == "myrai_support" ){
                        task_assignee_pills.push({
                          name: provider.title,
                          id: provider.filter_key,
                          main_key: topic.main_key
                        });
                      } else if (topic.main_key == "equipments") {
                        provider.sub_category && provider.sub_category.forEach((sc) => {
                          taskAssign.sub_category && taskAssign.sub_category.length > 0 && taskAssign.sub_category.forEach((dd) => {
                            if (sc.id == dd) {
                              task_assignee_pills.push({
                                name: sc.name,
                                id: sc.id,
                                main_key: topic.main_key
                              });
                            }
                          })
                        });
                      }
                    }
                  });
                }
              });
          }
        });
      })
    }
    setTaskAssigneePills(removeDuplicates(task_assignee_pills.slice()));
    setTaskAssignee(removeDuplicates(task_assignee.filter((assignee)=>assignee.id != undefined && assignee.id != null).slice()));
}

export const newFilterFormate = (filters) => {
  let newFilter = []
  filters.map((fi) => {
    let indexMain = newFilter.findIndex((d) => d.main_key == fi.main_key)
    if (indexMain > -1) {
      let indexSub = newFilter[indexMain].categories.findIndex((de) => de.filter_key == fi.filter_key)
      if (indexSub > -1) {
        newFilter[indexMain].categories = newFilter[indexMain].categories.filter((f) => f.filter_key != fi.filter_key)
      } else {
        newFilter[indexMain].categories.push({ "filter_key": fi.filter_key, "main_sub_key": fi.main_sub_key, "sub_category": fi.sub_category && fi.sub_category.length ? fi.sub_category.map((dd) => dd.id) : [] })
      }
    } else {
      newFilter.push({ "main_key": fi.main_key, "categories": [{ "filter_key": fi.filter_key, "main_sub_key": fi.main_sub_key, "sub_category": fi.sub_category && fi.sub_category.length ? fi.sub_category.map((dd) => dd.id) : []  }] })
    }
  })
  return newFilter
}

export const isMinimumUpdateVersion = () => {
  let versionToCheckArr = localStorage.getItem("version");
  const targetVersionArr = process.env.REACT_APP_VERSION.split('.').map(Number);
  if(!versionToCheckArr)
  {
    localStorage.setItem("version", process.env.REACT_APP_VERSION);
    return true;
  }
  versionToCheckArr = versionToCheckArr.split('.').map(Number);

  for (let i = 0; i < Math.max(versionToCheckArr.length, targetVersionArr.length); i++) {
      const numToCheck = versionToCheckArr[i] || 0;
      const numTarget = targetVersionArr[i] || 0;

      if (numToCheck < numTarget) {
          localStorage.setItem("version", process.env.REACT_APP_VERSION);
          return true;
      } else if (numToCheck > numTarget) {
          return false;
      }
  }
  return false;
}

export const getAddressComponents = async(addressValue) => {
  let city = DEFAULT_CITY_NAME;
  let country = DEFAULT_COUNTRY_NAME_SHORT;
  let postalCode = DEFAULT_POSTAL_CODE;
  let countryLongname = DEFAULT_COUNTRY_NAME_LONG;

  try{
    const response = await Geocode.fromAddress(addressValue);
    const { results } = response;
    if (results && results.length > 0) {
      const { address_components } = results[0];

      address_components.forEach((component) => {
        if (component.types.includes("postal_code")) {
          postalCode = component.short_name;
        } else if (component.types.includes("locality")) {
          city = component.long_name;
        } else if (component.types.includes("country")) {
          country = component.short_name;
          countryLongname = component.long_name;
        }
      });
    }
  }
  catch(e)
  {
    console.error(e);
  }
  finally {
    return {city, country, postalCode, countryLongname}
  }
}

export const isArrayofObjects = arr => arr.every(item => typeof item === 'object' && item !== null);

export const deepCopyArray = obj => {
  if (Array.isArray(obj)) {
    return obj.map(deepCopyArray);
} else if (typeof obj === 'object') {
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, deepCopyArray(value)]));
} else {
    return obj;
}
};

export const removeSearchParamFromURL = (paramName) => {
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(paramName);
  if (window.history.replaceState) {
    let searchString = searchParams.toString().length > 0 ? '?' + searchParams.toString() : '';
    let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname +  searchString + window.location.hash;
    window.history.replaceState(null, '', newUrl);
  }
}

export const favoriteDdashboardSelected = (value) => {
  let favDashboard = "AichatView";
  if (value == "calendar") {
    favDashboard = "calendarView"
  } else if (value == "my_spaces") {
    favDashboard = "spaceView"
  } else if (value == "kanban_view") {
    favDashboard = "kanbanView"
  } else if (value == "my_tasks") {
    favDashboard = "liteVersionView"
  }
  store.dispatch(setSelectedGloablView(favDashboard))
}

export const formateDate = (date) => {
  return(moment(date).format("YYYY-MM-DD"))
}

export const removeDuplicates = (arr) => {
  const uniqueIds = new Set(); // Create a Set to store unique ids
  return arr.filter((obj) => {
    if (!uniqueIds.has((obj && obj.id) || (obj && obj._id))) {
      uniqueIds.add((obj && obj.id) || (obj && obj._id)); // Add the id to the Set if it's not already there
      return true; // Keep the object in the filtered array
    }
    return false; // Discard the object (it's a duplicate)
  });
};
export const removeDuplicateImgs = (arr) => {
  const uniqueIds = new Set(); // Create a Set to store unique ids
  return arr.filter((obj) => {
    if (!uniqueIds.has(obj.name)) {
      uniqueIds.add(obj.name); // Add the id to the Set if it's not already there
      return true; // Keep the object in the filtered array
    }
    return false; // Discard the object (it's a duplicate)
  });
};

export const customOnPasteEventHandler = (e, ref) => {
  e.preventDefault();
  const { value, selectionStart, selectionEnd } = ref.current;
  const clipboardData = e.clipboardData.getData('Text');
  
  // Insert the pasted text at the current cursor position
  const newValue = value.substring(0, selectionStart) + clipboardData + value.substring(selectionEnd);

  // Update the text area value
  ref.current.value = newValue;

  // Move the cursor to the end of the pasted text
  ref.current.setSelectionRange(selectionStart + clipboardData.length, selectionStart + clipboardData.length);
};

export const countryCodeSetNumber = (code) => {
  let maxLength = 0;
  let minLength = 0;
        switch (code) {
            case "+32":
            case "+33":
            case "+34":
            case "+41":
            case "+48":
            case "+325":
                maxLength = 9;
                minLength = 9;
                break;
            case "+44":
                maxLength = 10;
                minLength = 10;
                break;
            case "+49":
                maxLength = 11;
                minLength = 10;
                break;
            default:
                maxLength = 10;
                minLength = 10;
                break;
      }
    return {maxLength, minLength};
};

export const countryCodedigitLength = (code) => {
  let max = 0;
  let min = 0;
        switch (code) {
            case "+33":
              min = 9 + code.length - 1;
              max = 9 + code.length - 1;
              break;
            case "+32":
            case "+34":
            case "+41":
            case "+48":
            case "+325":
                min = 9 + code.length - 1;
                max = 10 + code.length - 1;
                break;
            case "+44":
            case "+49":
                min = 11 + code.length - 1;
                max = 10 + code.length - 1;
                break;
            default:
                min = 10 + code.length;
                max = 10 + code.length;
                break;
      }
    return {min, max};
};


export const showLeftPanelNew = () => {
  // with-left-panel class add 

  //  <<-- opnn this line to show from where this function called-->>
  // console.trace("showLeftPanelNew")
  // jquery("body").removeClass("with-right-penel");
  if (localStorage.getItem("contact_role") != 3 && !CONNECT_PAGES.find(x => window.location.search.toLowerCase().includes(x)) && localStorage.getItem("user_type") == "staff")  {
    jquery("body").addClass("with-left-penel");
  }else{
    jquery("body").addClass("with-left-penel");

    // hideLeftPanelNew()
    removeExpandedClasstoOfficeList();
  }           
}
export const showSolairHubleftPanel = () =>{
  // console.trace("hideLeftPanelNew") 
}
export const showSolairHubleftPanelForSearch = () =>{
  // console.trace("showLeftPanelNew") 

  jquery("body").addClass("with-left-penel");
}
export const hideSolairHubleftPanel = () =>{
  hideLeftPanelNew()
}
export const showRightPanelNew = () => {
  // with-right-panel class add 

  // <<-- open  his line to show from where this function called-->>
  // console.trace("showRightPanelNew")           
  jquery("body").addClass("with-right-penel");
}

export const hideLeftPanelNew = () => {
  // "with-left-panel" class remove

  //  <<-- opnn this line to show from where this function called-->>
  // console.trace("hideLeftPanelNew") 
  jquery("body").removeClass("with-left-penel");
}

export const hideRightPanelNew = () => {
  // "with-right-panel" class remove

  // <<-- open  his line to show from where this function called-->> 
  jquery("body").removeClass("with-right-penel");
}

export const addModifiedClass = () =>{
    // <<-- open  his line to show from where this function called-->> 
  // console.trace("addModifiedClass")
  jquery(".App").addClass("modified-height");
}
export const removeModifiedClass = () =>{
  // <<-- open  his line to show from where this function called-->> 
  // console.trace("removeModifiedClass")
  jquery(".App").removeClass("modified-height");
}
export const scrollToTag = (tagId = "", behavior = "smooth", milliSec = 100) => {
  // console.trace("scrollToTag")
  if(tagId != ""){
    setTimeout(() => {
      let el = document.getElementById(tagId);
      if(el){
        el.scrollIntoView({ behavior: behavior, block: "start", inline: "start" });
      }
    }, milliSec);
  }
}

export const showLeftpanelonSearchHover = () => {
  showLeftPanelNew();
  hideRightPanelNew();
  if (Number(localStorage.getItem("contact_role")) != 3) {
    // jquery("body").addClass("hover-left-penal");
  }else{
    jquery("body").removeClass("hover-left-penal");
  }
  jquery(".task-comment-modal").removeClass("show-comments");
 
}

export const addActiveBorderforWeekCalendar = () => {
    // <<-- open  his line to show from where this function called-->> 
  // console.trace("c-gradinet-active-border")
  jquery("#active_week_date").addClass("c-gradinet-active-border");
}

export const averageTaskChecklistRatings = () => {
  let averageRating = 0;
  let averageCoeffcientValue = 0;
  let averageTaskRating = 0;
  const clientId = localStorage.getItem("client_id");

  const checklistStateData = store.getState().customer && store.getState().customer.taskChecklist ? JSON.parse(JSON.stringify(store.getState().customer.taskChecklist.filter((w) => !isNaN(Number(w.rating)) && Number(w.rating) > 0).slice())) : [];
  const selectedTask = store.getState().customer.selectedTask;
  
  if(clientId == 1080 || selectedTask && selectedTask.user_client_id == 1080)
  {
    //Hard coded value from DB
    const taskRating = selectedTask && selectedTask.average_rating && !isNaN(selectedTask.average_rating) ? Number(selectedTask.average_rating) : 0;
    return Number(taskRating.toFixed(1));
  }
  else {
    const floorDetails = store.getState().benchmarkReducer.floorDetails;
    floorDetails.type_of_space.forEach((space) => {
      let spaceAverageRating = 0;
      let spaceChecklistItems = [];
  
      if(space.is_default == 1)
      {
        spaceChecklistItems = spaceChecklistItems.concat(checklistStateData.filter((checklist) => checklist.space_item_id == 0));
      }
      else {
        space.details.forEach((spaceItem) => {
          spaceChecklistItems = spaceChecklistItems.concat(checklistStateData.filter((checklist) => checklist.space_item_id == spaceItem.id));
        });
      }
      if(spaceChecklistItems.length)
      {
        spaceAverageRating = spaceChecklistItems.length == 0 ? 0 : spaceChecklistItems.length == 1 && !isNaN(Number(spaceChecklistItems[0].rating)) ? Number(spaceChecklistItems[0].rating) : spaceChecklistItems.reduce((total, next) => total + parseInt(next.rating), 0) / spaceChecklistItems.filter((w) => w.rating > 0).length ;
        if(!isNaN(space.coeffcient_value) && Number(space.coeffcient_value) > 0)
        {
          spaceAverageRating *= Number(space.coeffcient_value);
          averageCoeffcientValue += (Number(space.coeffcient_value) * 5);
        }
        if(!isNaN(Number(spaceAverageRating)))
        {
          averageRating += spaceAverageRating;
        }
      }
      if(averageCoeffcientValue > 0 && !isNaN(Number(averageCoeffcientValue)))
      {
        averageTaskRating = ((averageRating) / (averageCoeffcientValue)) * 5;
      }
    });
  
    return Number(averageTaskRating.toFixed(1));
  }
};

export const updateExistingList = (existingList, newList) => {
  const existingMap = new Map(existingList.map((item) => [item.id, item]));

  newList.forEach((newItem) => {
    const existingItem = existingMap.get(newItem.id);

    if (existingItem) {
      Object.assign(existingItem, newItem);
    } else {
      existingList.push(newItem);
    }
  });

  existingList = existingList.filter((existingItem) =>
    newList.some((newItem) => newItem.id === existingItem.id)
  );

  return existingList;
};

export const fetchTasksFromIDB = async (project_id, filters, weekCalendarDate, status, dispatch) => {
  try {
    let key = "";
    let startDate, endDate;
    let ignoreWeekCaledarDate = false; // If the range is specified, ignore the selected date

    const dateFilter = filters ? filters.find(
      (w) => w.main_key === "date_range_filter"
    ) : "";

    if (
      filters &&
      filters.length &&
      filters.find((filter) => filter.filter_key === "all")
    ) {
      let keys = await getCollectionKeyNames("tasks", "idb_key");
      
      keys = keys.filter((w) => w.includes(`project_${project_id}`));
      if (keys && keys.length) {
        const promises = keys.map(async (key) => {
          const taskList = await readFromDB("tasks", key, "idb_key");
          return taskList;
        });
        let filteredTaskList = await Promise.all(promises);
        if (filteredTaskList && filteredTaskList.length) {
          filteredTaskList = removeDuplicateObjectsFromArray(filteredTaskList.map((w) => w.tasks).flat(), "id");
          if (dispatch) {
            if(status && status.length)
            {
              filteredTaskList = filteredTaskList.filter((w) => status.find((z) => Number(z) == Number(w.status)));
            }
            dispatch({
              type: TASKLIST_LOADER,
              payload: false,
            });
            dispatch({
              type: FETCH_TASK_LIST,
              payload: { data: { data: removeDuplicateObjectsFromArray(filteredTaskList, "id") } },
            });
          }
          return filteredTaskList;
        }
        else {
          dispatch({
            type: TASKLIST_LOADER,
            payload: false,
          });
          return [];
        }
      }
      else if(dispatch) {
        dispatch({
          type: TASKLIST_LOADER,
          payload: false,
        });
        return [];
      }
    } 
    else if (dateFilter && dateFilter.title) {
      const range = dateFilter.title.split("-");
      if (range && range.length) {
        startDate = localStorage.getItem("fromDate");
        endDate = localStorage.getItem("toDate");
        ignoreWeekCaledarDate = true;
      }
    }
    
    if(!dateFilter)
    {
      startDate = weekCalendarDate;
      endDate = weekCalendarDate;
    }

    let tasks = [];

    if(filters && filters.find((w) => w.main_key == "my_task"))
    {

      let keys = await getCollectionKeyNames("tasks", "idb_key");
    
      keys = keys.filter((w) => w.includes(`project_${project_id}`));

      const promises = keys.map(async (key) => {
        const taskList = await readFromDB("tasks", key, "idb_key");
        return taskList;
      });

      let allTasks = await Promise.all(promises);
      allTasks = allTasks.map((list) => list.tasks).flat();

      let assignedToMeTasks = [];
      let pinnedTasks = [];
      let overdueTasks = [];
      let createdByMe = [];

      const currentStaffId = localStorage.getItem("staff_id");
      const currentUserContactId = localStorage.getItem("contact_id");
      const currentUserType = localStorage.getItem("user_type");

      if(filters.find((w) => w.filter_key == "assigned_to_me"))
      {
        assignedToMeTasks = allTasks.filter((task) => {
          if(task.assignee_list_DEV && task.assignee_list_DEV.length)
          {
            let flag = false;
            task.assignee_list_DEV.forEach((assignee) => {
              if(assignee.main_key === "my_operator" || assignee.main_key === "my_contact")
              {
                assignee.categories.forEach((w) => {
                  if(w.filter_key == currentStaffId)
                  {
                    flag = true;
                  }
                })
              }
            });
            if(flag)
            {
              return task;
            }
          }
        });
      }

      if(filters.find((w) => w.filter_key == "pinned"))
      {
        pinnedTasks = allTasks.filter((task) => task.pinned == 1);
      }

      if(filters.find((w) => w.filter_key == "overdue"))
      {
        overdueTasks = allTasks.filter((task) => task.duedate && task.schedule_id > 0 && moment(task.duedate).isAfter(moment()));
      }

      if(filters.find((w) => w.filter_key == "created_by_me"))
      {
        createdByMe = allTasks.filter((task) => {
          if((currentUserType != "contact" && task.addedfrom_staff_id == currentStaffId) || (currentUserType == "contact" && task.addedfrom == currentUserContactId))
          {
            return task;
          }
        });
      }

      tasks = pinnedTasks.concat(overdueTasks, assignedToMeTasks, createdByMe);

    }
    else if(filters){
      filters.forEach(async (filter) => {
        key = `project_${project_id}_${filter.main_key}_${filter.main_sub_key}_${filter.filter_key}`;
      });
  
      const response = await readFromDB("tasks", key, "idb_key");
      tasks = response && response.tasks ? response.tasks : [];
      tasks = removeDuplicateObjectsFromArray(tasks, "id");
    }

    if (startDate && endDate) {
      tasks = tasks.filter((task) => {
        if (
          task._startdate &&
          moment(task._startdate).isBetween(
            moment(startDate),
            moment(endDate),
            null,
            "[]"
          )
        ) {
          return task;
        } else if (
          !task._startdate && (task._duedate &&
          moment(task._duedate).isBetween(
            moment(startDate),
            moment(endDate),
            null,
            "[]"
          ))
        ) {
          return task;
        } else if (
          !task._startdate && !task._duedate &&
          (task._dateadded &&
          (moment(task._dateadded).isBetween(
            moment(startDate),
            moment(endDate),
            null,
            "[]"
          ) || (!ignoreWeekCaledarDate && moment(task._dateadded).isSameOrBefore(startDate))))
        ) {
          return task;
        }
      });
    } else if (startDate && !endDate) {
      tasks = tasks.filter((task) => {
        if (
          task._startdate &&
          moment(task._startdate).isSameOrAfter(startDate)
        ) {
          return task;
        } else if (
          task._duedate &&
          moment(task._duedate).isSameOrAfter(startDate)
        ) {
          return task;
        } else if (
          task._dateadded &&
          moment(task._dateadded).isSameOrAfter(startDate)
        ) {
          return task;
        }
      });
    } else if (endDate && !startDate) {
      tasks = tasks.filter((task) => {
        if (task._startdate && moment(task._startdate).isSameOrAfter(endDate)) {
          return task;
        } else if (
          task._duedate &&
          moment(task._duedate).isSameOrAfter(endDate)
        ) {
          return task;
        } else if (
          task._dateadded &&
          moment(task._dateadded).isSameOrAfter(endDate)
        ) {
          return task;
        }
      });
    }

    if(filters && filters.find((w) => w.main_key == "unread_comments"))
    {
      tasks = tasks.filter((w) => w.unread_checklist_comment_count > 0 || w.unread_comment_count > 0 || w.unread_delay_comment_count > 0);
    }

    if (tasks && tasks.length) {
      if (dispatch) {
        if(status && status.length)
        {
          tasks = tasks.filter((w) => status.find((z) => Number(z) == Number(w.status)));
        }
        dispatch({
          type: TASKLIST_LOADER,
          payload: false,
        });

        dispatch({
          type: FETCH_TASK_LIST,
          payload: { data: { data: removeDuplicateObjectsFromArray(tasks, "id") } },
        });
      }
      return tasks;
    }
    else {
      if(dispatch)
      {
        dispatch({
          type: TASKLIST_LOADER,
          payload: false,
        });
      }
      return [];
    }
  } catch (e) {
    console.error(e);
  }
};

//save all the tasks in IDB from API response
export const updateTaskListInIDB = async (project_id, tasks, filters, weekCalendarDate, status) => {
  try {
    let idbTaskList = JSON.parse(JSON.stringify(tasks)); //Deep copy of array to avoid mutation in the state
    if (idbTaskList && idbTaskList.length) {
      let taskCategoryObject = {};
      idbTaskList.forEach((task) => {
        let key = project_id ? `project_${project_id}` : "project_0";
        if (task.assignee_list_DEV && task.assignee_list_DEV.length) {
          task.assignee_list_DEV.forEach((assignee) => {
            let main_key = key + `_${assignee.main_key}`;
            let main_sub_key = main_key;
            if (assignee.categories && assignee.categories.length) {
              assignee.categories.forEach((category) => {
                if (category.main_sub_key) {
                  main_sub_key += `_${category.main_sub_key}`;
                }
                if (category.sub_category && category.sub_category.length) {
                  category.sub_category.forEach((sub_category) => {
                    let idb_key;
                    if (
                      typeof sub_category === "number" ||
                      typeof sub_category === "string"
                    ) {
                      idb_key = main_sub_key + `_${sub_category}`;
                    } else if (sub_category && sub_category.id) {
                      idb_key = main_sub_key + `_${sub_category.id}`;
                    } else {
                      idb_key = main_sub_key;
                    }
                    taskCategoryObject = {
                      ...taskCategoryObject,
                      [idb_key]: taskCategoryObject[idb_key]
                        ? taskCategoryObject[idb_key].concat(task)
                        : [task],
                    };
                  });
                } else {
                  let idb_key = main_sub_key + `_${category.filter_key}`;
                  taskCategoryObject = {
                    ...taskCategoryObject,
                    [idb_key]: taskCategoryObject[idb_key]
                      ? taskCategoryObject[idb_key].concat(task)
                      : [task],
                  };
                }
                main_sub_key = main_key;
              });
            }
          });
        }

        if (task.provider_id > 0) {
          let idb_key = `${key}_providers_providers_${task.provider_id}`;
          taskCategoryObject = {
            ...taskCategoryObject,
            [idb_key]: taskCategoryObject[idb_key]
              ? taskCategoryObject[idb_key].concat(task)
              : [task],
          };
        }

        if (task.rel_type === "equipment" && task.rel_id > 0) {
          const defaultTopics = store.getState().customer.defaultTopics;
          if (defaultTopics && defaultTopics.length) {
            defaultTopics.forEach((topic) => {
              if (topic.main_key === "equipments") {
                if (topic.categories && topic.categories.length) {
                  topic.categories.forEach((category) => {
                    if (
                      category &&
                      category.sub_category &&
                      category.sub_category.length
                    ) {
                      category.sub_category.forEach((sub_category) => {
                        if (task.rel_id == sub_category.id) {
                          let idb_key = `${key}_${topic.main_key}_${category.main_sub_key}_${category.filter_key}_${sub_category.id}`;
                          taskCategoryObject = {
                            ...taskCategoryObject,
                            [idb_key]: taskCategoryObject[idb_key]
                              ? taskCategoryObject[idb_key].concat(task)
                              : [task],
                          };
                        }
                      });
                    }
                  });
                }
              }
            });
          }
        }

        if(task.addedfrom_staff_id)
        {
          if(task.is_added_from_contact == 1)
          {
            let idb_key = `${key}_my_contact_people_${task.addedfrom_staff_id}`;
            taskCategoryObject = {
              ...taskCategoryObject,
              [idb_key]: taskCategoryObject[idb_key]
                ? taskCategoryObject[idb_key].concat(task)
                : [task],
            };
          }
          else {
            let idb_key = `${key}_my_operator_people_${task.addedfrom_staff_id}`;
            taskCategoryObject = {
              ...taskCategoryObject,
              [idb_key]: taskCategoryObject[idb_key]
                ? taskCategoryObject[idb_key].concat(task)
                : [task],
            };
          }
        }
        task._startdate = task.startdate ? moment(task.startdate).format("YYYY-MM-DD") : "";
        task._duedate = task.duedate ? moment(task.duedate).format("YYYY-MM-DD") : "";
        task._dateadded = task.dateadded ? moment(task.dateadded).format("YYYY-MM-DD") : "";
      });
      if (Object.keys(taskCategoryObject).length) {
        const promises = Object.keys(taskCategoryObject).map(
          async (idb_key) => {
            let currentTaskList = await readFromDB("tasks", idb_key, "idb_key");
            if (currentTaskList && currentTaskList.tasks && currentTaskList.tasks.length) {
              currentTaskList.tasks.forEach((w) => {
                if (
                  !taskCategoryObject[idb_key].find((task) => task.id == w.id)
                ) {
                  taskCategoryObject[idb_key].push(w);
                }
              });
            }
            //Before inserting to DB, apply the same filter which is applied to view the task in order to prevent overwrite / delete actions in list
            let existingList = await fetchTasksFromIDB(project_id, filters, weekCalendarDate, status);
            if (existingList && existingList.length) {
              existingList = updateExistingList(
                existingList,
                taskCategoryObject[idb_key]
              );
            } else {
              existingList = taskCategoryObject[idb_key];
            }
            await insertCollectionToDB(
              "tasks",
              [{ idb_key, tasks: removeDuplicateObjectsFromArray(existingList, "id") }],
              "idb_key"
            );
          }
        );
        await Promise.all(promises);
      }
    }
  } catch (e) {
    console.error(e);
  }
};

export const readJSONByStream = async(body) => {
  const reader = body.getReader();
  const decoder = new TextDecoder();
  const chunks = [];

  async function read() {
    try {
      const { done, value } = await reader.read();
  
      // all chunks have been read?
      if (done) {
        return JSON.parse(chunks.join(''));
      }
  
      const chunk = decoder.decode(value, { stream: true });
      chunks.push(chunk);
      return read(); // read the next chunk
    }
    catch(e)
    {
      console.error(e);
      return [];
    }
  }

  return read();
};

export const saveDocumentDataToIDB = (documents, dispatch ,refetch, rel_type) => {
  if(documents)
  {
    setMydriveDataIDB(documents, rel_type)
    Object.keys(documents).forEach((key) => {
      insertCollectionToDB(key, documents[key], "id");
    });
  }
  if (refetch) {
    // dispatch(getIDBDocData(true));
  }
};

export const setMydriveDataIDB = (doc, rel_type) => {
  if (doc) {
    Object.keys(doc).forEach((d)=>{
      let key = d;
      let data = doc[d]
      if (rel_type && rel_type == key && rel_type !== "other_documents") {
        writeToDB("myDrive",`${key}`, {key: `${key}`, data: data && data.length ? data : []}, "key"); 
      }else if(rel_type && rel_type == "other_documents" && ["mandate","topic_document","space", "reference", "dp"].includes(key)){
        writeToDB("myDrive",`${key}`, {key: `${key}`, data: data && data.length ? data : []}, "key"); 
      }else if (!rel_type){
        writeToDB("myDrive",`${key}`, {key: `${key}`, data: data && data.length ? data : []}, "key"); 
      }
    })
  }

}



export const saveLoginAsUsersList = (usersList) => {
  try {
    if(usersList && Object.keys(usersList).length)
    {
      Object.keys(usersList).forEach((project_id) => {
        if(usersList[project_id] && usersList[project_id].length)
        {
          insertCollectionToDB("login_as_user_list", [{data: usersList[project_id][0], project_id}], "project_id");
        }
      });
    }
  }
  catch(e)
  {
    console.error(e);
  }
};

export const saveTopicListToIDB = (topicList) => {
  if(topicList && Object.keys(topicList).length)
  {
    Object.keys(topicList).forEach((project_id) => {
      insertCollectionToDB("topics", [{project_id, topics: topicList[project_id].topics}], "project_id");
    });
  }
};

export const loadDataToIDB = async(staff_id) => {

  let taskPath;
  let schedulePath;
  let proposalPath;
  let documentsPath;
  let loginAsUsersPath;
  let topicMenuPath;
  let peopleAndTeamsPath;

  try {

    store.dispatch(showIDBDataFetchingLoader(true));

    const response = await CustomerServices.getInitialIDBData(staff_id);

    const clientId = localStorage.getItem("client_id");
    const contactId = localStorage.getItem("contact_id");
    const staffId = localStorage.getItem("staff_id");
    const userType = localStorage.getItem("user_type");
    const email = localStorage.getItem("email");
    const userId = userType === "contact" ? contactId : staffId;

    store.dispatch(getCustomerSpaces(clientId, contactId));

    if(response && response.data)
    {      
      // taskPath = `${response.data.file}?q=${moment().format("YYYY-MM-DD")}`;
      // schedulePath = `${response.data.schedule_file}?q=${moment().format("YYYY-MM-DD")}`;
      // proposalPath = `${response.data.proposal_file}?q=${moment().format("YYYY-MM-DD")}`;
      // documentsPath = `${response.data.document_file}?q=${moment().format("YYYY-MM-DD")}`;
      // loginAsUsersPath = `${response.data.login_detail_file}?q=${moment().format("YYYY-MM-DD")}`;
      // topicMenuPath = `${response.data.burger_menu_file}?q=${moment().format("YYYY-MM-DD")}`;
      // peopleAndTeamsPath = `${response.data.user_file}?q=${moment().format("YYYY-MM-DD")}`;

      taskPath = `${response.data.file}`;
      schedulePath = `${response.data.schedule_file}`;
      proposalPath = `${response.data.proposal_file}`;
      documentsPath = `${response.data.document_file}`;
      loginAsUsersPath = `${response.data.login_detail_file}`;
      topicMenuPath = `${response.data.burger_menu_file}`;
      peopleAndTeamsPath = `${response.data.user_file}`;

      //Load Topic Menu Data start
      fetch(topicMenuPath).then((topicMenuData) => {
        if(topicMenuData.ok)
        {
          readJSONByStream(topicMenuData.body).then((topics) => {
            saveTopicListToIDB(topics);
          }).catch((e) => {
            console.error("Error while parsing task JSON", e);
          });
        }
      }).catch((e) => {
        console.error("Error while loading Topic Menu Data", e);
      });
      //Load Topic Menu Data end

      //Load task list start
      fetch(taskPath).then((taskJsonData) => {
        if(taskJsonData.ok)
        {
          readJSONByStream(taskJsonData.body).then((taskList) => {
            let projectList = [... new Set(taskList.map((w) => w.space_project_id))];
            let taskByProjects = {};
  
            projectList.forEach((project) => {
              taskByProjects = {...taskByProjects, [project]: taskList.filter((w) => w.space_project_id == project)};
            });
            Object.keys(taskByProjects).map((project) => {
              updateTaskListInIDB(project, taskByProjects[project]);
              return project
            });
          }).catch((e) => {
            console.error("Error while parsing task JSON", e);
          });
        }
      }).catch((e) => {
        console.error("Error while loading task list", e);
      });
      //Load task list end

      //Load Schedules/Benchmark Start
    
      fetch(schedulePath).then((scheduleJsonData) => {
        if(scheduleJsonData.ok)
        {
          readJSONByStream(scheduleJsonData.body).then((schedules) => {
            saveBenchmarkSchedulesToIDB(schedules);
          }).catch((e) => {
            console.error("Error while parsing schedules/benchmark JSON", e);
          });
        }
      }).catch((e) => {
        console.error("Error while loading Schedules/Benchmark", e);
      });
      //Load Schedules/Benchmark End

      //Load Proposals Start
      fetch(proposalPath).then((proposalJsonData) => {
        if(proposalJsonData.ok)
        {
          readJSONByStream(proposalJsonData.body).then((proposals) => {
            saveBenchmarkSchedulesToIDB(proposals);
          }).catch((e) => {
            console.error("Error while parsing Proposals JSON", e);
          });
        }
      }).catch((e) => {
        console.error("Error while loading Proposals", e);
      });
      //Load Proposals End

      //Load Documents Start
      fetch(documentsPath).then((documentsJsonData) => {
        if(documentsJsonData.ok)
        {
          readJSONByStream(documentsJsonData.body).then((documents) => {
            saveDocumentDataToIDB(documents);
          }).catch((e) => {
            console.error("Error while parsing Documents JSON", e);
          });
        }
      }).catch((e) => {
        console.error("Error while loading Documents", e);
      });
      //Load Documents End

      //Load Login as user Start
      fetch(loginAsUsersPath).then((loginAsUserJsonData) => {
        if(loginAsUserJsonData.ok)
        {
          readJSONByStream(loginAsUserJsonData.body).then((loginAsUserList) => {
            saveLoginAsUsersList(loginAsUserList);
          }).catch((e) => {
            console.error("Error while parsing Login as user list JSON", e);
          });
        }
      }).catch((e) => {
        console.error("Error while loading Login as users list", e);
      });
      //Load Login as user End

      //Load Chat Messages Start
      // ChatServices.getInitialChatJSON(userId, email, userType === "contact" ? "client" : "staff", response.extra.idb_project_list).then((chatJsonData) => {
      //   if(chatJsonData.result && chatJsonData.result.length)
      //   {
      //     saveChatMessagesToIDB(chatJsonData.result);
      //   }
      // }).catch((e) => {
      //   console.error("Error while loading chat data", e)
      // });
      //Load Chat Messages End

      //Show the initial loader upto 7 seconds, after that let the loading done in background and user will start using the platform
      setTimeout(() => {
        store.dispatch(showIDBDataFetchingLoader(false));
      }, (7000));
    }
  }
  catch(e)
  {
    console.error("Error: Initial Loading Failed", e);
  }
};

export const saveBenchmarkSchedulesToIDB = (benchmarks) => {

  const userType = localStorage.getItem("user_type");

  if(benchmarks && benchmarks.length)
  {
    let benchmarksByCategory = {};
    let schedulesByCategory = {};
    let proposalsByCategory = {};

    benchmarks.forEach((benchmark) => {
      let idbKey;
      if(benchmark.provider_id > 0)
      {
        idbKey = `project_${benchmark.project_id}_providers_all`;
      }
      else if(benchmark.equipment_id > 0)
      {
        idbKey = `project_${benchmark.project_id}_equipments_all`;
      }
      else if(benchmark.group_id > 0)
      {
        idbKey = `dpgf_all`;
      }

      const isSchedule = benchmark.is_schedule > 0;

      if(isSchedule)
      {
        schedulesByCategory = {...schedulesByCategory, [`${idbKey}`]: schedulesByCategory[`${idbKey}`] && schedulesByCategory[`${idbKey}`].length ? schedulesByCategory[`${idbKey}`].concat([benchmark]) : [benchmark]};
      }
      else {
        if(userType === "operator")
        {
          proposalsByCategory = {...proposalsByCategory, [`all_proposals`]: proposalsByCategory[`all_proposals`] && proposalsByCategory[`all_proposals`].length ? proposalsByCategory[`all_proposals`].concat([benchmark]) : [benchmark]};
        }
        else {
          benchmarksByCategory = {...benchmarksByCategory, [`${idbKey}`]: benchmarksByCategory[`${idbKey}`] && benchmarksByCategory[`${idbKey}`].length ? benchmarksByCategory[`${idbKey}`].concat([benchmark]) : [benchmark]};
        }
      }
    });

    //Save Benchmarks to IDB:
    Object.keys(benchmarksByCategory).forEach((idbKey) => {
      writeToDB("benchmarks", idbKey, {filter_key: idbKey, data: benchmarksByCategory[idbKey]}, "filter_key");
    });

    //Save Schedules to IDB:
    Object.keys(schedulesByCategory).forEach((idbKey) => {
      writeToDB("schedules", idbKey, {filter_key: idbKey, data: schedulesByCategory[idbKey]}, "filter_key");
    });

    //Save Proposals to IDB:
    Object.keys(proposalsByCategory).forEach((idbKey) => {
      writeToDB("proposals", idbKey, {filter_key: idbKey, data: proposalsByCategory[idbKey]}, "filter_key");
    });
    
  }
};

export const compareArraysOfObjects = (array1, array2, primaryKey, callback) => {
  const deepObjectComparison = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      callback();
      return false;
    }

    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];

      if (typeof val1 === 'object' && typeof val2 === 'object') {
        if (!deepObjectComparison(val1, val2)) {
          callback();
          return false;
        }
      } else {
        if (val1 !== val2) {
          callback();
          return false;
        }
      }
    }

    return true;
  };

  if (array1.length !== array2.length) {
    return false;
  }

  const findObjectById = (arr, id) => arr.find(obj => obj[primaryKey] === id);

  for (const item1 of array1) {
    const item2 = findObjectById(array2, item1[primaryKey]);

    if (!item2) {
      return false;
    }

    if (!deepObjectComparison(item1, item2)) {
      return false;
    }
  }

  return true;
};

export const addexpandedClass = () => {
  // console.trace("adding expanded class")
  addExpandedClasstoOfficeList();
};

export const convertArrayToMap = (arrayOfObjects, key) =>
{
  console.log(arrayOfObjects);
  return arrayOfObjects.reduce((resultMap, obj) => (obj.hasOwnProperty(key) ? resultMap.set(obj[key], obj) : resultMap), new Map());
}
// Audiocall function start
   export var startTime = 0;
   export var elapsedTime = 0;
   export let timer;
   export let second = 0;
   export let minute = 0;
   export let hour = 0;
   export let isTimerRunning = false;
   export const updateSeconds = () =>{
     return second++
   }
   export const updateminutes = () =>{
    return minute++
   }
   export const updatehours = () =>{
    return hour++
   }
   export const updatestartTime = (data)=>{
    startTime = data
   }
   export const updateelapsedTime = (data)=>{
    elapsedTime = data
   }

  //  export const setDefaultValueOfCallVariables = () => {
  //   startTime = 0;
  //   elapsedTime = 0;
  //   second = 0;
  //   minute = 0;
  //   hour = 0;
  //   isTimerRunning = false; 
  //   timer = null
  //  }
   export const setSecondsToZero = () => { second = 0 }
   export const setMinutesToZero = () => { minute = 0 }

   export const setCallTimer = () => {
    if (isTimerRunning) {
        clearInterval(timer);
        isTimerRunning = false;
    } else {
        timer = setInterval(updateTimer, 1000);
        isTimerRunning = true;
    }
};
   function updateTimer() {
    second++;
    if (second === 60) {
        second = 0;
        minute++;
        if (minute === 60) {
            minute = 0;
            hour++;
        }
    }
    updateDisplay();
}
export function pad(value) {
    return value < 10 ? '0' + value : value;
}
function updateDisplay() {
//     const formattedTime = (hour > 0 ? pad(hour) + ':' : '') + pad(minute) + ':' + pad(second);
//     if(document.getElementById('call-timer-full')){
//         document.getElementById('call-timer-full').innerText = formattedTime;
//     }else{
//         setCallTimer();
//     }
//     if(document.getElementById('call-timer')){
//         document.getElementById('call-timer').innerText = formattedTime;
//     }else{
//         setCallTimer();
//     }
}
     // Call timer functions
    

// Audiocall function End

export const getFileDetails = (fileName) => {
  const lastIndex = fileName.lastIndexOf('.');
  
  if (lastIndex === -1) {
    // File has no extension
    return { type: 'Unknown', extension: 'None' };
  }
  const fileExtension = fileName.slice(lastIndex + 1);

  return fileExtension;
};

export const isLocalEnvironment = () => {
  // return window.location.hostname === "localhost";
  return false
}

export const isSolarHubPlatform = () => {
  // return window.location.host === "app.solairehub.com" || isLocalEnvironment();
  return true;
}

export const generateDefaultSignature = (ref, signatureContent) => {
  // Define font face
  const font = new FontFace(
    "Kristi",
    `url("https://fonts.gstatic.com/s/kristi/v21/uK_y4ricdeU6zwdhDRcSEP2UXg.woff2")`
  );

  // Add font face to document
  document.fonts.add(font);

  // Load font face
  font
    .load()
    .then(() => {
      const canvas = ref.current.getCanvas();
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas before drawing
      const fontSize = 64; // Font size in pixels
      ctx.font = `${fontSize}px 'Kristi', cursive`; // Setting font style to 'Kristi'

      const textMetrics = ctx.measureText(signatureContent);
      const textWidth = textMetrics.width;
      const textHeight = fontSize; // Assuming the text height is the same as the font size

      const x = (canvas.width - textWidth) / 2;
      const y = (canvas.height) / 2;

      ctx.fillText(signatureContent, x, y);
    })
    .catch((e) => {
      console.error(e);
    });
};

export const roundOffPowerValue = (number) => {

  if(number < 1000)
  {
    return number
  }
  else if (isNaN(Number(number))) {
    return number;
  } else {
    return parseInt(Number(number) / 1000);
  }
};

export const generateRandomString = (length = 8) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=[]{}|;:,.<>?';

  let randomString = '';
  for (let i = 0; i < length; i++) {
      randomString += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return randomString;
}

export const addExpandedClasstoixedleftpanel = () =>{
  // console.trace("addExpandedClasstoixedleft")
  jquery(".fixed-left-panel").addClass("expanded"); 
}

export const addExpandedClasstoOfficeList = () =>{
  if (localStorage.getItem("user_type") != "staff") {
    jquery(".office-list").addClass("expanded");
  }
}

export const addExpandedClasstoProviderList = () =>{
  // console.trace("addExpandedClasstoProviderList")
  jquery(".provider-list").addClass("expanded");
}
export const addExpandedClasstoIntroOfficeList = (withsearch = false) =>{
  // console.trace("addExpandedClasstoIntroOfficeList")
  jquery('#intro-office-list').addClass('expanded');
  if (withsearch) {
    jquery("#intro-office-list").addClass("search-backdrop");
  }
}

export const removeExpandedClasstoIntroOfficeList = () =>{
  // console.trace("removeExpandedClasstoIntroOfficeList")
  // jquery("#intro-office-list").removeClass("expanded search-backdrop");
}
export const removeExpandedClassFromIntroOfficeList = () =>{
  // console.trace("removeExpandedClasstoIntroOfficeList")
  jquery("#intro-office-list").removeClass("expanded");
}

export const removeExpandedClasstoixedleftpanel = () =>{
  // console.trace("removeExpandedClasstoixedleftpanel")
  // jquery(".fixed-left-panel").removeClass("expanded");

}

export const removeExpandedClasstoOfficeList = () =>{
  // console.trace("removeExpandedClasstoOfficeList")
  // jquery(".office-list").removeClass("expanded");
}

export const removeExpandedClasstoProviderList = () =>{
  // console.trace("removeExpandedClasstoProviderList")
  // jquery(".provider-list").removeClass("expanded");
}
export const openNewChatChannel = (proposal_id) =>{
  let groupListExternal = store.getState().chat.groupListExternal
  let index = groupListExternal.findIndex((gd)=> gd && gd.extraData && gd.extraData && gd.extraData.proposalId == proposal_id);
  if (index > -1 ) {
    let groupID = groupListExternal[index].groupId
    let chatGroups = store.getState().chat.chatGroups
    if (chatGroups.includes(groupID)) {
      
    } else {
      store.dispatch(setClearmessage());
      store.dispatch(setactiveChatScreenId(groupID));
      store.dispatch(setChatGroupsList(groupID));
      store.dispatch(setChatGroupsListDetails({...groupListExternal[index], groupId :groupID}));
    }

    chatServices.getGroupDetails( localStorage.getItem("chatUserId"), groupID).then((res) => {
      if (res.success) {
        store.dispatch(fetchMessages( 
        localStorage.getItem("chatUserId"), 
        groupID,
        0,
        ()=>{},
        1,
    ));    
      }
    })
    
  } else {
    customerServices.createChatGroup( 0,"contact_directory","people",localStorage.getItem("staff_id"),proposal_id,true).then((res)=>{
      if(res.status && res.data){
        let groupID = res.data._id
        let chatGroups = store.getState().chat.chatGroups
        if (chatGroups.includes(groupID)) {
          
        } else {
          store.dispatch(setClearmessage());
          store.dispatch(setactiveChatScreenId(groupID));
          store.dispatch(setChatGroupsList(groupID));
          store.dispatch(setChatGroupsListDetails({...res.data, groupId :groupID}));
        }
  
        chatServices.getGroupDetails( localStorage.getItem("chatUserId"), groupID).then((res) => {
          if (res.success) {
            store.dispatch(fetchMessages( 
            localStorage.getItem("chatUserId"), 
            groupID,
            0,
            ()=>{},
            1,
        ));    
          }
        })
      }
    })
  }
}

export const getBenchmarkScheduleList = async(main_key) => {
  try {
    let idbList;

    switch(main_key)
    {
      case "proposals":
        idbList = await readFromDB("proposals", `all_proposals`, "filter_key");
        if(!idbList || !idbList.data)
        {
          const allBenchmarksResponse = await CustomerServices.getBenchmarks(0, 0, "", 0, 0, 0, 1, undefined, 1, [], undefined, false);
          if(allBenchmarksResponse.status)
          {
            writeToDB("proposals", `all_proposals`, {filter_key: `all_proposals`, data: allBenchmarksResponse.data}, "filter_key");
            return {data: allBenchmarksResponse.data, status: 1};
          }
        }
        else {
          CustomerServices.getBenchmarks(0, 0, "", 0, 0, 0, 1, undefined, 1, [], undefined, false).then((allBenchmarksResponse) => {
            if(allBenchmarksResponse.status)
            {
              writeToDB("proposals", `all_proposals`, {filter_key: `all_proposals`, data: allBenchmarksResponse.data}, "filter_key");
              store.dispatch(getOperatorsProposalList(allBenchmarksResponse.data));
            }
          }).catch((e) => console.error(`Failed to fetch & save data`, e));
        
          return {data: idbList.data, status: 1};
        }
        break;
      default :
      return {data: [], status: 1};
    }
  }
  catch(e)
  {
    console.error("Can not get list", e);
    return {data: [], status: 0};
  }
};

export const getMonthsBetweenDates = (date1, date2 = new Date()) => {
  const year1 = date1.getFullYear();
  const month1 = date1.getMonth();
  const day1 = date1.getDate();
  
  const year2 = date2.getFullYear();
  const month2 = date2.getMonth();
  const day2 = date2.getDate();

  // Calculate the difference in years and months
  let months = (year2 - year1) * 12 + (month2 - month1);

  // Adjust if the day of date2 is less than date1
  if (day2 < day1) {
    months -= 1;
  }

  return months;
};

export const generateWeekArray = (startDate = moment() , start = -3 , end = 4) => {
  const weekData = [];

  // Generate data for 7 days starting from the startDate
  for (let i = start; i < end; i++) {
    const date = moment(startDate).add(i, 'days').format('YYYY-MM-DD');
    weekData.push({
      date,
      todo_count: 0,
      inprogress_count: 0,
      completed_count: 0,
      total: 0,
      request_total: 0
    });
  }

  return weekData;
};

export const generateWeekFromMondayToSunday = (givenDate = moment()) => {
  const weekData = [];

  // Find the Monday of the given date's week
  const monday = moment(givenDate).startOf('week').add(1, 'days'); // Adjust to Monday (as startOf('week') in Moment defaults to Sunday)

  // Generate data for each day from Monday to Sunday
  for (let i = 0; i < 7; i++) {
    const date = moment(monday).add(i, 'days').format('YYYY-MM-DD');
    weekData.push({
      date,
      todo_count: 0,
      inprogress_count: 0,
      completed_count: 0,
      total: 0,
      request_total: 0
    });
  }

  return weekData;
};

// All Drive page documents edit function.
export const EditDriveDocuments = async(doc, obj) => {
  const getDriveData = await readFromDB("myDrive", obj, "key");
  const filterData = getDriveData && getDriveData.data && getDriveData.data.filter((items) => items.id != doc.id);
  const updatedData = [...filterData, doc];
  
  writeToDB("myDrive", "key", {key: obj, data: updatedData}, "key");
}


// All Drive page document Add function.
export const AddDriveDocument = async(doc, obj) => {
  const getDriveData = await readFromDB("myDrive", obj, "key");
  const newData = [...getDriveData.data, doc];

  insertCollectionToDB("myDrive", [{key: obj, data: newData}], "key");
}


export const fetchMydriveData = async (rel_type) =>{
  let keys = await getCollectionKeyNames("myDrive", "idb_key");

  keys = keys.filter((k) => k.toLowerCase() == rel_type.toLowerCase())
  const promises = keys.map(async (key) => {
    const documentList = await readFromDB("myDrive", key, "idb_key");
    return documentList;
  });
  let docList = await Promise.all(promises);

  if (docList && docList.length > 0 && docList[0] && docList[0].data) {
    docList = docList && docList.map((list) => list.data).flat();
  }else{
    docList = [];
  }
  return docList;
}

export const getDocumentsFromIDB = async(project_id, filters, rel_type, document_rel_id) => {
  const isExternal = localStorage.getItem("guest_id") ? true : false
  const userHashId = isExternal ? localStorage.getItem("searchParamUserId") : localStorage.getItem("user_hash_id")
  try {
    const idbDocuments = await readCollectionFromDB(rel_type, "id");
    const documentKeys = [
      "subscription",
      "space",
      "estimate",
      "invoice",
      "credit_notes",
      "google_drive",
      "lease_document"
    ];
    let formattedResponse = {};

    documentKeys.forEach(key => {
      formattedResponse[key] = [];
    });

    if(!idbDocuments || !idbDocuments.length)
    {
        const newDocumentsResponse = await documentsServices.getDocuments(
          project_id,
          filters,
          rel_type !=="documents" ? rel_type : undefined,
          document_rel_id
        );
        if(newDocumentsResponse.status)
        {
          insertCollectionToDB(rel_type, newDocumentsResponse.data[rel_type], "id");
        }
        if (rel_type ==="documents") {
          documentKeys.forEach(key => {
            formattedResponse[key] = newDocumentsResponse.data[key === "google_drive" ? "topic_document" : key]
          });
        } else {
          formattedResponse[rel_type] = newDocumentsResponse.data[rel_type];
        }
        return {status: 1, data: formattedResponse};
      }
    else {
      documentsServices.getDocuments(
        project_id,
        filters,
        rel_type !=="documents" ? rel_type : undefined,
        document_rel_id
      ).then((response) => {
        insertCollectionToDB(rel_type, response.data[rel_type], "id");
      });
      formattedResponse[rel_type] = idbDocuments;
      return {status: 1, data: formattedResponse};
    }
  }
  catch(e)
  {
    console.error(e);
    return {status: 0, data: []};
  }
};
