import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Form from "react-bootstrap/Form";
import Toasts from "../Toasts/Toasts";
import CustomerServices from "../../services/customer-services";
import moment from "moment";
import { _l, compareDates , getDatePickerLocale } from "../../hooks/utilities";
import { CalendarBlank } from "phosphor-react";
import { useDispatch } from "react-redux";
import { showMessage } from "../../actions/messages";

const StopBenchnark = ({
  projectId,
  show,
  handleClose,
  callback,
  benchmarkId,
  status, startDate = ''
}) => {
  const [endDate, setEndDate] = useState();

  const dispatch = useDispatch();

  const handleStopBenchmark = () => {
    if (endDate) {
      CustomerServices.stopBenchmark(
        projectId,
        benchmarkId,
        endDate,
        status == "4" ? "yes" : "no"
      ).then((res) => {
        if (res.status) {
          callback(endDate);
          dispatch(
            showMessage(
              "sucess",
              _l("l_success"),
              _l("l_benchmark_stop_sucessfully")
            )
          );
          setEndDate(undefined);
        }
      });
    } else {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_missing_required_input_field"),
          _l("l_please_enter_end_date")
        )
      );
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l("l_stop_benchmark")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          <div className="form-wrapper-main">
            <div className="row">
              <Form.Group className="col-12 c-input-box position-relative">
                <Form.Label className="title-fonts color-white-60">
                  {_l("l_end_date")}
                </Form.Label>
                <div
                  className="custom-datepicker"
                  data-bs-toggle="tooltip"
                  title=""
                >
                  <div className="d-flex align-items-center form-control  color-white-60 dropdown react-datepicker-wrapper-w-100">
                    <CalendarBlank
                      size={18}
                      className="c-icons"
                      weight="light"
                    />
                    <DatePicker
                      selected={endDate}
                      locale={getDatePickerLocale()}
                      onChange={(date) => setEndDate(date)}
                      placeholderText={`${_l("l_end_date_placeholder")}`}
                      dateFormat="dd/MM/yyyy"
                      selectsStart
                      minDate={startDate != '' ? compareDates(moment().format("DD/MM/YYYY"), moment(startDate).format("DD/MM/YYYY")) == 1 ? moment(startDate).format("DD/MM/YYYY") : moment().toDate() : moment().toDate()}
                      calendarStartDay={1}
                    // minDate={new Date()}
                    ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                  </div>
                </div>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l("l_close")}
          </Button>
          <Button variant="primary" size="sm" onClick={handleStopBenchmark}>
            {_l("l_save")}{" "}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StopBenchnark;
