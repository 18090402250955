import React, { useEffect, useState } from "react";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { validateEmail, _l } from "../../hooks/utilities";
import PlacesAutocomplete from "react-places-autocomplete";
import Spinner from "react-bootstrap/Spinner";
import "react-datepicker/dist/react-datepicker.css";
import { Minus, Plus } from "phosphor-react";
import CustomerServices from "../../services/customer-services";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../actions/messages";
import { SetSelectedTask } from "../../actions/customer";
import AddSpaceLead from "./AddSpaceLead";
import LeadContactProviderForm from "./LeadContactProviderForm";

const AutosearchPlaces = ({
  getInputProps,
  suggestions,
  getSuggestionItemProps,
  loading,
}) => {
  return (
    <div>
      <Form.Control
        placeholder={`${_l("l_address_placeholder")}`}
        type="text"
        name="address_3dplan"
        {...getInputProps({
          placeholder: "Avenue de France, Paris, France",
        })}
      />
      <div className="custom-map-suggestion border-0">
        {loading && <div>{_l("l_loading")}</div>}
        {suggestions.map((suggestion, index) => {
          return (
            <div {...getSuggestionItemProps(suggestion)} key={index}>
              <a>{suggestion.description}</a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ConvertLeadToCustomer = ({ show, handleClose, handleSave, lead, isModal = 0, type = '', loder = false }) => {
  const leadName = !isModal ? lead.name.split(" ") : '';
  const userType = localStorage.getItem("user_type");
  const customerInitialData = {
    firstName: !isModal ? leadName[0] : '',
    lastName: !isModal ? leadName.length > 1 ? lead.name.replace(leadName[0], "") : "" : '',
    position: "",
    email: lead.email,
    company: lead.company,
    phone: lead.phonenumber,
    website: "",
    leadAddress: "",
    city: "",
    state: "",
    leadCountry: "",
    leadZipCode: "",
    leadPassword: '',
    setSendPasswordEmail: false,
    spaceTitle: "",
    spaceAddress: "",
    spaceType: "",
    spaceRegion: "",
    superficy: 0,
    numberOfDesk: 0,
    region: '',
    typeJob: '',
    phone_code: { value: '+33', label: '+33' }
  };
  const space_details = {
    title: '',
    address: '',
    space_type: '',
    superfice: 0,
    region_id: '',
    number_of_desk: 0,
    startDate: '',
    endDate: '',
    typeOfSpace: [],
    country_id: '',
    space_type: ''
  }

  const [customerData, setCustomerData] = useState(customerInitialData);
  const [spaceDetails, setSpacDetails] = useState(space_details);

  const [countryList, setCountryList] = useState([]);
  const [regionsList, setRegionsList] = useState([]);
  const { defaultTopics } = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const [categoryListing, setCategoryListing] = useState([]);
  const [selectedTab, setSelectedTab] = useState('step-1');
  const optionsStrict = {
    componentRestrictions: {
      country: ["fr", "uk", "de", "esp", "ita", "bel", "che"],
    },
    fields: ["address_components", "geometry", "icon", "name"],
    strictBounds: false,
    types: ["address"],
  };



  const handleSelect = (value) => {
    setCustomerData({ ...customerData, spaceAddress: value });
  };

  const validationSuccessForm = () => {
    let success = true;
    let requiredFields = ['firstName', 'lastName', 'email', 'company', 'leadPassword'];
    if (type == 'operator') {
      requiredFields.push('region', 'typeJob')
    }
    Object.keys(customerInitialData)
      .reverse()
      .forEach((key, value) => {
        if (
          requiredFields.includes(key) &&
          (!customerData[key] ||
            customerData[key] == "" ||
            customerData[key] == 0)
        ) {
          success = false;
          dispatch(
            showMessage("unsucess", _l("l_error"), _l(`l_${key}_required`))
          );
        }
      });
    if (!validateEmail(customerData.email)) {
      success = false;
      dispatch(showMessage("unsucess", _l("l_error"), _l(`l_invalid_email`)));
    }

    return success;
  };
  const validationSuccessSpaceForm = () => {
    let success = true;
    if (spaceDetails.title == '') {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l(`l_please_add_space_title`))
      );
      success = false
    } else if (spaceDetails.address == "" || spaceDetails.country_id == "") {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l(`l_please_add_space_address`))
      );
      success = false
    } else if (spaceDetails.region_id == '' || spaceDetails.region_id < 1) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l(`l_please_add_space_region`))
      );
      success = false
    } else if (spaceDetails.superfice == '' || spaceDetails.superfice < 1) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l(`l_please_enter_superficy`))
      );
      success = false
    } else if (spaceDetails.number_of_desk == '' || spaceDetails.number_of_desk < 1) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l(`l_please_enter_number_of_desk`))
      );
      success = false
    }
    return success;
  };
  const validateAndSave = () => {
    if (validationSuccessForm() && validationSuccessSpaceForm()) {
      const formattedData = {
        first_name: customerData.firstName,
        last_name: customerData.lastName,
        position: customerData.position,
        email: customerData.email,
        company_name: customerData.company,
        phone_number: customerData.phone != '' ? customerData.phone_code.value + ' ' + customerData.phone : '',
        website: customerData.website,
        address: customerData.leadAddress,
        city: customerData.city,
        state: customerData.state,
        country_id: customerData.leadCountry.value,
        zip_code: customerData.leadZipCode,
        lead_id: lead.id,
        password: customerData.leadPassword,
        // space_title: customerData.spaceTitle,
        // region_id: customerData.spaceRegion.value,
        // space_address: customerData.spaceAddress,
        // number_of_desk: customerData.numberOfDesk,
        // superfice: customerData.superficy,
        // space_type: customerData.spaceType.value,
        send_set_password_email: customerData.setSendPasswordEmail ? 1 : 0,
        region_id: customerData.region != '' ? customerData.region.value ? customerData.region.value : '' : '',
        type_of_job: customerData.typeJob != '' ? customerData.typeJob.value ? customerData.typeJob.value : '' : '',
      };
      handleSave(formattedData, spaceDetails, type == 'contact' ? 0 : 1);
    }
  };

  useEffect(() => {
    CustomerServices.getCountryCodes(true).then((res) => {
      if (res.status == "1") {
        setCountryList(
          res.data.map((code) => {
            return {
              value: code.country_id,
              label: code.short_name,
              short_code: code.iso2,
            };
          })
        );
      }
    });
    CustomerServices.getRegionList().then((res) => {
      if (res.status) {
        setRegionsList(
          res.data.map((x) => {
            return {
              label: x.name,
              value: parseFloat(x.id),
            };
          })
        );
      }
    });
    CustomerServices.getTypeOfJobs().then((res) => {
      if (res.status) {
        const formattedCategoryListing = res.data.map((item1) => {
          return {
            value: parseInt(item1.id),
            label: item1.name,
          };
        })
        setCategoryListing(
          formattedCategoryListing
        );

      }
    });
  }, []);
  useEffect(() => {
    if (isModal) {
      setCustomerData({
        ...customerInitialData,
        firstName: lead.name,
        position: lead.position ? lead.position : '',
        email: lead.email ? lead.email : '',
        company: lead.company ? lead.company : '',
        phone: lead.phonenumber ? lead.phonenumber : '',
        website: "",
        leadAddress: lead.address ? lead.address : '',
        city: lead.city ? lead.city : '',
        state: lead.state ? lead.state : '',
        leadCountry: "",
        leadZipCode: "",
        leadPassword: "",
        setSendPasswordEmail: false,
        spaceTitle: "",
        spaceAddress: "",
        spaceType: "",
        spaceRegion: "",
        superficy: 0,
        numberOfDesk: 0,
      })
    }
  }, [lead])

  return (
    <>
      <div className="">
        <Modal
          show={show}
          size="xl"
          onHide={handleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
        // scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>{type == 'contact' ? _l("l_convert_to_customer") : 'Convert to provider'}</Modal.Title>

          </Modal.Header>

          <Modal.Body className=" py-0">
            <div className="comman-tab-wrapper">
              <div className="d-flex align-items-center border-bottom">

                <div

                  className={`tab-wrapper ${selectedTab == 'step-1' ? "active"
                    : ""
                    } d-flex align-items-center `}
                  onClick={() => {
                    setSelectedTab('step-1')
                  }}
                >
                  <a href="#/" className="tab-name">Convert Lead as {type == 'contact' ? 'Client' : 'Provider'} (Primary  {type == 'contact' ? 'Contact' : 'Operator'} Setup) </a>
                </div>
                <div

                  className={`tab-wrapper ${selectedTab == 'step-2' ? "active"
                    : ""
                    } d-flex align-items-center `}
                  onClick={() => {
                    if (validationSuccessForm()) {
                      setSelectedTab('step-2')
                    }
                  }}
                >
                  <a href="#/" className="tab-name">Convert Lead as {type == 'contact' ? 'Client' : 'Provider'} (First Space Setup) </a>
                </div>
              </div>
            </div>
            {
              selectedTab == 'step-1' ?
                <LeadContactProviderForm setCustomerData={setCustomerData} customerData={customerData} countryList={countryList} regionsList={regionsList} type={type} categoryListing={categoryListing} />
                // <div className="form-wrapper-main">
                //   <div className="row align-items-center">
                //     <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                //       <Form.Label className="input-label no-asterisk ">
                //         {_l("l_first_name")}
                //       </Form.Label>
                //       <Form.Control
                //         placeholder={`${_l("l_first_name_placeholder")}`}
                //         type="text"
                //         name="first_name"
                //         value={customerData.firstName}
                //         onChange={(e) => {
                //           setCustomerData({
                //             ...customerData,
                //             firstName: e.target.value,
                //           });
                //         }}
                //       />
                //     </Form.Group>
                //     <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                //       <Form.Label className="input-label no-asterisk ">
                //         {_l("l_last_name")}
                //       </Form.Label>
                //       <Form.Control
                //         placeholder={`${_l("l_last_name_placeholder")}`}
                //         type="text"
                //         name="last_name"
                //         value={customerData.lastName}
                //         onChange={(e) => {
                //           setCustomerData({
                //             ...customerData,
                //             lastName: e.target.value,
                //           });
                //         }}
                //       />
                //     </Form.Group>
                //     {
                //       type == 'contact' ?
                //         <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                //           <Form.Label className="input-label no-asterisk ">
                //             {_l("l_position")}
                //           </Form.Label>
                //           <Form.Control
                //             placeholder={`${_l("l_position_placeholder")}`}
                //             type="text"
                //             name="position"
                //             value={customerData.position}
                //             onChange={(e) => {
                //               setCustomerData({
                //                 ...customerData,
                //                 position: e.target.value,
                //               });
                //             }}
                //           />
                //         </Form.Group> : <></>
                //     }

                //     <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                //       <Form.Label className="input-label no-asterisk ">
                //         {_l("l_email")}
                //       </Form.Label>
                //       <Form.Control
                //         placeholder={`${_l("l_email_placeholder")}`}
                //         type="text"
                //         name="email"
                //         value={customerData.email}
                //         onChange={(e) => {
                //           setCustomerData({
                //             ...customerData,
                //             email: e.target.value,
                //           });
                //         }}
                //       />
                //     </Form.Group>
                //     <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                //       <Form.Label className="input-label no-asterisk ">
                //         {_l("l_company")}
                //       </Form.Label>
                //       <Form.Control
                //         placeholder={`${_l("l_company_placeholder")}`}
                //         type="text"
                //         name="company"
                //         value={customerData.company}
                //         onChange={(e) => {
                //           setCustomerData({
                //             ...customerData,
                //             company: e.target.value,
                //           });
                //         }}
                //       />
                //     </Form.Group>
                //     <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                //       <Form.Label className="input-label no-asterisk ">
                //         {_l("l_phone")}
                //       </Form.Label>
                //       <Form.Control
                //         placeholder={`${_l("l_phone_placeholder")}`}
                //         type="text"
                //         name="phone"
                //         value={customerData.phone}
                //         onChange={(e) => {
                //           setCustomerData({
                //             ...customerData,
                //             phone: e.target.value,
                //           });
                //         }}
                //       />
                //     </Form.Group>
                //     <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                //       <Form.Label className="input-label no-asterisk ">
                //         {_l("l_website")}
                //       </Form.Label>
                //       <Form.Control
                //         placeholder={`${_l("l_website_placeholder")}`}
                //         type="text"
                //         name="website"
                //         value={customerData.website}
                //         onChange={(e) => {
                //           setCustomerData({
                //             ...customerData,
                //             website: e.target.value,
                //           });
                //         }}
                //       />
                //     </Form.Group>
                //     <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                //       <Form.Label className="input-label no-asterisk ">
                //         {_l("l_address")}
                //       </Form.Label>
                //       <PlacesAutocomplete
                //         value={customerData.leadAddress}
                //         onChange={(value) => {
                //           setCustomerData({ ...customerData, leadAddress: value });
                //         }}
                //         onSelect={(value) => {
                //           setCustomerData({ ...customerData, leadAddress: value });
                //         }}
                //         searchOptions={optionsStrict}
                //       >
                //         {AutosearchPlaces}
                //       </PlacesAutocomplete>
                //     </Form.Group>
                //     <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                //       <Form.Label className="input-label no-asterisk ">
                //         {_l("l_city")}
                //       </Form.Label>
                //       <Form.Control
                //         placeholder={`${_l("l_city_placeholder")}`}
                //         type="text"
                //         name="city"
                //         value={customerData.city}
                //         onChange={(e) => {
                //           setCustomerData({
                //             ...customerData,
                //             city: e.target.value,
                //           });
                //         }}
                //       />
                //     </Form.Group>
                //     <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                //       <Form.Label className="input-label no-asterisk ">
                //         {_l("l_state")}
                //       </Form.Label>
                //       <Form.Control
                //         placeholder={`${_l("l_state_placeholder")}`}
                //         type="text"
                //         name="state"
                //         value={customerData.state}
                //         onChange={(e) => {
                //           setCustomerData({
                //             ...customerData,
                //             state: e.target.value,
                //           });
                //         }}
                //       />
                //     </Form.Group>
                //     <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                //       <Form.Label className="input-label no-asterisk ">
                //         {_l("l_country")}
                //       </Form.Label>
                //       <Select
                //         className="custom-select-menu"
                //         classNamePrefix="react-select"
                //         placeholder={_l("l_country")}
                //         options={countryList}
                //         closeMenuOnSelect={true}
                //         onChange={(value) => {
                //           setCustomerData({ ...customerData, leadCountry: value });
                //         }}
                //         value={customerData.leadCountry}
                //       ></Select>
                //     </Form.Group>
                //     <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                //       <Form.Label className="input-label no-asterisk ">
                //         {_l("l_zip_code")}
                //       </Form.Label>
                //       <Form.Control
                //         placeholder={`${_l("l_zip_code_placeholder")}`}
                //         type="text"
                //         name="zip_code"
                //         value={customerData.leadZipCode}
                //         onChange={(e) => {
                //           setCustomerData({
                //             ...customerData,
                //             leadZipCode: e.target.value,
                //           });
                //         }}
                //       />
                //     </Form.Group>
                //     <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                //       <Form.Label className="input-label no-asterisk ">
                //         {_l("l_password")}
                //       </Form.Label>
                //       <Form.Control
                //         placeholder={`${_l("l_password_placeholder")}`}
                //         type="password"
                //         name="password"
                //         value={customerData.leadPassword}
                //         onChange={(e) => {
                //           setCustomerData({
                //             ...customerData,
                //             leadPassword: e.target.value,
                //           });
                //         }}
                //       />
                //     </Form.Group>
                //     {
                //       type == 'operator' ?
                //         <>
                //           <Form.Group className="col-xl-4 c-input-box pb-4 position-relative ">
                //             <Form.Label className="input-label no-asterisk no-asterisk">
                //               {_l('l_region_label')}
                //             </Form.Label>
                //             <div className="d-flex flex-grow-1 align-items-center">
                //               <Select
                //                 placeholder={_l('l_select_region')}
                //                 className="custom-select-menu w-100"
                //                 classNamePrefix="react-select"
                //                 options={regionsList}
                //                 closeMenuOnSelect={true}
                //                 value={customerData.region}
                //                 onChange={(value) => {
                //                   setCustomerData({
                //                     ...customerData,
                //                     region: value,
                //                   });
                //                 }}

                //               />
                //             </div>
                //           </Form.Group>
                //           <Form.Group className="col-xl-4 c-input-box pb-4 position-relative ">
                //             <Form.Label className="input-label no-asterisk">
                //               {_l('l_type_of_job')}
                //             </Form.Label>
                //             <Select
                //               className="custom-select-menu"
                //               classNamePrefix="react-select"
                //               placeholder={_l('l_type_of_job_placeholder')}
                //               options={categoryListing}
                //               closeMenuOnSelect={true}
                //               value={customerData.typeJob}
                //               onChange={(value) => {
                //                 setCustomerData({
                //                   ...customerData,
                //                   typeJob: value,
                //                 });
                //               }}
                //             />
                //           </Form.Group>
                //         </>
                //         : <></>
                //     }
                //     <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                //       <Form.Label className="input-label no-asterisk opacity-0">
                //         {_l("l_send_password_email")}
                //       </Form.Label>
                //       <div className="form-check with-span">
                //         <div className="d-flex align-items-center">
                //           <Form.Label className="form-check-label text_wrap w-auto">
                //             <input className="form-check-input"
                //               placeholder={`${_l("l_send_password_email_placeholder")}`}
                //               type="checkbox"
                //               name="send_password_email"
                //               checked={customerData.setSendPasswordEmail}
                //               onChange={(e) => {
                //                 setCustomerData({
                //                   ...customerData,
                //                   setSendPasswordEmail: e.target.checked,
                //                 });
                //               }}
                //             />
                //             <span> {_l("l_send_password_email")}</span>
                //           </Form.Label>
                //         </div>
                //       </div>
                //     </Form.Group>
                //     {/* Space Form */}
                //     <Form.Group className="col-xl-4 c-input-box pb-3 position-relative d-none">
                //       <Form.Label className="input-label no-asterisk ">
                //         {_l("l_space_title")}
                //       </Form.Label>
                //       <Form.Control
                //         placeholder={`${_l("l_space_title_placeholder")}`}
                //         type="text"
                //         name="space_title"
                //         value={customerData.spaceTitle}
                //         onChange={(e) => {
                //           setCustomerData({
                //             ...customerData,
                //             spaceTitle: e.target.value,
                //           });
                //         }}
                //       />
                //     </Form.Group>
                //     <Form.Group className="col-xl-4 c-input-box pb-3 position-relative d-none">
                //       <Form.Label className="input-label no-asterisk ">
                //         {_l("l_space_address")}
                //       </Form.Label>
                //       <PlacesAutocomplete
                //         value={customerData.spaceAddress}
                //         onChange={(value) => {
                //           setCustomerData({ ...customerData, spaceAddress: value });
                //         }}
                //         onSelect={handleSelect}
                //         searchOptions={optionsStrict}
                //       >
                //         {AutosearchPlaces}
                //       </PlacesAutocomplete>
                //     </Form.Group>
                //     <Form.Group className="col-xl-4 c-input-box pb-3 position-relative d-none">
                //       <Form.Label className="input-label no-asterisk ">
                //         {_l("l_space_type")}
                //       </Form.Label>
                //       <Select
                //         className="custom-select-menu"
                //         classNamePrefix="react-select"
                //         placeholder={_l("l_select_space_type")}
                //         options={spaceTypeOptions}
                //         closeMenuOnSelect={true}
                //         onChange={(value) => {
                //           setCustomerData({ ...customerData, spaceType: value });
                //         }}
                //         value={customerData.spaceType}
                //       ></Select>
                //     </Form.Group>
                //     <Form.Group className="col-xl-4 c-input-box position-relative d-none">
                //       <Form.Label className="input-label no-asterisk ">
                //         {_l("l_select_region")}
                //       </Form.Label>
                //       <Select
                //         className="custom-select-menu"
                //         classNamePrefix="react-select"
                //         placeholder={_l("l_select_region")}
                //         options={regionsList}
                //         closeMenuOnSelect={true}
                //         onChange={(value) => {
                //           setCustomerData({ ...customerData, spaceRegion: value });
                //         }}
                //         value={customerData.spaceRegion}
                //       ></Select>
                //     </Form.Group>
                //     <Form.Group className="col-xl-4 c-input-box pb-3 position-relative d-none">
                //       <Form.Label className="input-label no-asterisk title-fonts color-white-60">
                //         {_l("l_superficy")} (m²)
                //       </Form.Label>
                //       <div className="d-flex align-items-center justify-content-between form-control">
                //         <a href="#/"
                //           className="c-font f-22 line-height-0"
                //           onClick={() => {
                //             if (customerData.superficy >= 50) {
                //               setCustomerData({
                //                 ...customerData,
                //                 superficy: customerData.superficy - 50,
                //               });
                //             }
                //           }}
                //         >
                //           <Minus size={18} className="c-icons" weight="light" />
                //         </a>
                //         <input
                //           className="form-check-input bg-transparent w-100 text-center"
                //           placeholder="0"
                //           type="number"
                //           value={customerData.superficy}
                //           onChange={(e) => {
                //             setCustomerData({
                //               ...customerData,
                //               superficy: e.target.value,
                //             });
                //           }}
                //           name=""
                //         />
                //         <a href="#/"
                //           className="c-font f-22 line-height-0"
                //           onClick={() => {
                //             setCustomerData({
                //               ...customerData,
                //               superficy: customerData.superficy + 50,
                //             });
                //           }}
                //         >
                //           <Plus size={18} className="c-icons" weight="light" />
                //         </a>
                //       </div>
                //     </Form.Group>
                //     <Form.Group className="col-xl-4 c-input-box pb-3 position-relative d-none">
                //       <Form.Label className="input-label no-asterisk title-fonts color-white-60">
                //         {_l("l_number_of_desk")}
                //       </Form.Label>
                //       <div className="d-flex align-items-center justify-content-between form-control">
                //         <a href="#/"
                //           className="c-font f-22 line-height-0"
                //           onClick={() => {
                //             if (customerData.numberOfDesk > 0) {
                //               setCustomerData({
                //                 ...customerData,
                //                 numberOfDesk: customerData.numberOfDesk - 1,
                //               });
                //             }
                //           }}
                //         >
                //           <Minus size={18} className="c-icons" weight="light" />
                //         </a>
                //         <input
                //           className="form-check-input bg-transparent w-100 text-center"
                //           placeholder="0"
                //           type="number"
                //           name=""
                //           value={customerData.numberOfDesk}
                //           onChange={(e) => {
                //             setCustomerData({
                //               ...customerData,
                //               numberOfDesk: e.target.value,
                //             });
                //           }}
                //         />
                //         <a href="#/"
                //           className="c-font f-22 line-height-0"
                //           onClick={() => {
                //             setCustomerData({
                //               ...customerData,
                //               numberOfDesk: customerData.numberOfDesk + 1,
                //             });
                //           }}
                //         >
                //           <Plus size={18} className="c-icons" weight="light" />
                //         </a>
                //       </div>
                //     </Form.Group>
                //   </div>
                // </div>
                : <AddSpaceLead spaceDetails={spaceDetails} setSpaceDetails={setSpacDetails} regionsList={regionsList} />
            }

          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-between">
            <Button variant="secondary d-none" onClick={handleClose}>
              {_l("l_close")}
            </Button>
            <Button variant="secondary" className={`${selectedTab == 'step-2' ? '' : 'd-none'}`} onClick={() => {
              if (selectedTab == 'step-2') {
                // let tempStep = parseInt(selectedTab.split('-')[1])
                setSelectedTab('step-1')
              }
            }}>
              {_l('l_previous')}
            </Button>
            <Button variant="primary ms-auto" onClick={() => {
              if (selectedTab == 'step-1') {
                if (validationSuccessForm()) {
                  setSelectedTab('step-2')
                }
              } else if (selectedTab == 'step-2') {
                if (validationSuccessSpaceForm()) {
                  validateAndSave()
                }
              }

            }}>
              {loder ? _l("l_please_wait") : selectedTab == 'step-1' ? _l("l_next") : _l("l_save")}
              {loder ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
              ) : (
                <></>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ConvertLeadToCustomer;
