
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useRef, useState } from "react";
import { Trash,NotePencil } from "phosphor-react";

const Refunds = ({ projectId, clientId, location, zoomLevel }) => {
    const [selectedTab, setSelectedTab] = useState("documents");


    const [startDate, setStartDate] = useState(new Date());
    return (
            <div>
                <div className="">
                    <div className=" d-flex flex-column pt-3">
                        <div className="comman-content-scroll-wrapper">
                            <div className="comman-content-scroll">
                                <div className="comman-data-table">
                                    <table className="dataTable">
                                        <thead className="bg-transparent">
                                            <tr className="bg-white-03">
                                                <th style={{ width: "10%" }}>Date</th>
                                                <th style={{ width: "20%" }}>Refunds Amount</th>
                                                <th style={{ width: "10%" }}>Payment Mode</th>
                                                <th style={{ width: "8%" }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="c-font f-12">
                                                <td className="border-bottom align-top ">11/10/2022</td>
                                                <td className="max-width-100 text-truncate border-bottom">
                                                    <div className="color-white-60 pt-2">
                                                    €60,00
                                                    </div>
                                                </td>
                                                <td className="border-bottom align-top">FAC-001783/10/2022</td>
                                                <td className="border-bottom align-top ">
                                                <div className="d-flex">
                                                    <a href="#/" className="comman_action_icon">
                                                        <div className="action_icon with_bg mx-1">
                                                            <Trash size={16}  weight="light" className="c-icons" />
                                                        </div>
                                                    </a>
                                                    <a href="#/" className="comman_action_icon">
                                                        <div className="action_icon with_bg mx-1">
                                                            <NotePencil size={16}  weight="light" className="c-icons" />
                                                        </div>
                                                    </a>
                                                </div>
                                                    
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};
export default Refunds;