import React from 'react'
import BlackLogoicon from '../../../assets/images/blzalogoiconblack.svg'
import BlackLogotext from '../../../assets/images/blazlogotextblack.svg'
import Qharelogo from '../../../assets/images/qharelogo.png'
import Colllogo from '../../../assets/images/colllogo.png'
import Pixellogo from '../../../assets/images/pixellogo.svg'
import { PuzzlePiece } from 'phosphor-react'

const SolaireDashabord = () => {
  return (
    <>
      <div className='row pt-40px'>
        <div className='col-lg-1'></div>
        <div className='col-lg-10'>
            <div className='d-flex flex-column'>
              <div className='d-flex flex-column gap10px'>
                <div className='fw-semibold'>Je veux utiliser Blaz CRM</div>
                <div className='d-flex bg-white radius_3 h250px'>
                  <div className='d-flex flex-column justify-content-between flex-grow-1 p30px'>
                    <div className='d-flex'>
                      <div className='pe-30px'><img src={BlackLogoicon} /></div>
                      <div><img src={BlackLogotext} /></div>
                    </div>
                   <div>
                    <a href="#/" className="btn btn-primary position-relative   propostion_btn h32wauto lh-sm  onhoverimginv">
                      <PuzzlePiece size={18} className="c-icons" />
                      <span className="ms-10px c-font f-12">Crée ton premier Blaz avec le CRM module Blaz</span>
                    </a>
                    </div>
                  </div>
                  <div>
                    <div className='w-300 p-5px h-100'>
                    <img class="h-100 w-100 object-fit-cover radius_3 " src="https://api.solairehub.com/web/orb/download/preview_image?path=uploads/solar_benchmark/411/map.jpeg" alt="Solar Map"/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-column gap10px mt-5'>
                <div className='fw-semibold'>Je veux utiliser mon CRM </div>
                <div className='row'>
                  <div className='col-lg-4'>
                    <div className='d-flex flex-column bg-white radius_3 justify-content-between flex-grow-1 p30px h250px'>
                      <div className='d-flex'><div><img src={Pixellogo} /></div></div>
                      <div>
                        <a href="#/" className="btn btn-primary position-relative   propostion_btn h32wauto lh-sm  onhoverimginv">
                          <PuzzlePiece size={18} className="c-icons" />
                          <span className="ms-10px c-font f-12">TELECHARGE L’EXTENSION</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className='d-flex flex-column bg-white radius_3 justify-content-between flex-grow-1 p30px h250px'>
                      <div className='d-flex'><div><img src={Qharelogo} /></div></div>
                      <div>
                        <a href="#/" className="btn btn-primary position-relative   propostion_btn h32wauto lh-sm  onhoverimginv">
                          <PuzzlePiece size={18} className="c-icons" />
                          <span className="ms-10px c-font f-12">TELECHARGE L’EXTENSION</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className='d-flex flex-column bg-white radius_3 justify-content-between flex-grow-1 p30px h250px'>
                      <div className='d-flex'><div><img src={Colllogo} /></div></div>
                      <div>
                        <a href="#/" className="btn btn-primary position-relative   propostion_btn h32wauto lh-sm  onhoverimginv">
                          <PuzzlePiece size={18} className="c-icons" />
                          <span className="ms-10px c-font f-12">TELECHARGE L’EXTENSION</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div className='col-lg-1'></div>
      </div>
    </>
  )
}

export default SolaireDashabord