import React from 'react';
// import CSS from '../css/icon_main.css';

function LinkSimpleIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M5.88124 10.1186L10.1187 5.87481M9.06249 11.1811L7.29374 12.9498C7.01509 13.2285 6.68429 13.4495 6.32022 13.6003C5.95614 13.7511 5.56593 13.8287 5.17187 13.8287C4.37601 13.8287 3.61275 13.5126 3.04999 12.9498C2.48723 12.3871 2.17108 11.6238 2.17108 10.8279C2.17108 10.0321 2.48723 9.26881 3.04999 8.70606L4.81874 6.93731M11.1812 9.06231L12.95 7.29356C13.5127 6.7308 13.8289 5.96754 13.8289 5.17168C13.8289 4.37583 13.5127 3.61256 12.95 3.04981C12.3872 2.48705 11.624 2.1709 10.8281 2.1709C10.0323 2.1709 9.269 2.48705 8.70624 3.04981L6.93749 4.81856" strokeLinecap="round" strokeLinejoin="round"/>
</svg>



		</React.Fragment>
	)
}

export default LinkSimpleIcon