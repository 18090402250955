import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { _l } from "../../hooks/utilities";
import { Paperclip } from "phosphor-react";

const ImportItems = ({ show, handleClose, setImportFile, handleImport, importFile, downloadSample }) => {

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l("l_import_items")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          <div className="form-wrapper-main">
            <div className="row pb-3">
              <ol start={1} className="ps-4">
                <li className="with-listing-count pb-1">{_l("l_you_csv_data_string")}<b> UTF-8 </b> {_l("l_to_avoid_unnecessary")} <b>{_l("l_encoding_problems")}</b></li>
              </ol>
              <div className="comman-data-table">
                <table className="dataTable GeneralItemTable">
                  <thead className="WithHeaderbg ">
                    <tr>
                      <th className="c-font f-13 text-nowrap">
                        {_l("l_space_title")}
                      </th>
                      <th className="c-font f-13 text-nowrap">
                        {_l("l_checklist")}
                      </th>
                      <th className="c-font f-13 text-center text-nowrap">
                        {_l("l_time")}
                      </th>
                      <th className="c-font f-13 text-center text-nowrap">
                        {_l("l_priority")}
                      </th>
                      <th className="c-font f-13 text-center text-nowrap">
                        {_l("l_photo")}
                      </th>
                      <th className="fw-normal py-2 align-top text-center" >
                        <th className="d-flex flex-column p-0" ><tr>{_l('l_week_single_letter_monday')}</tr>

                        </th>
                      </th>
                      <th className="fw-normal py-2 align-top text-center" >
                        <th className="d-flex flex-column p-0" ><tr>{_l('l_week_single_letter_tuesday')}</tr>

                        </th>
                      </th>
                      <th className="fw-normal py-2 align-top text-center" >
                        <th className="d-flex flex-column p-0" ><tr>{_l('l_week_single_letter_wednesday')}</tr>

                        </th>
                      </th>
                      <th className="fw-normal py-2 align-top text-center" >
                        <th className="d-flex flex-column p-0" ><tr>{_l('l_week_single_letter_thrusday')}</tr>

                        </th>
                      </th>
                      <th className="fw-normal py-2 align-top text-center" >
                        <th className="d-flex flex-column p-0" ><tr>{_l('l_week_single_letter_friday')}</tr>

                        </th>
                      </th>
                      <th className="fw-normal py-2 align-top text-center" >
                        <th className="d-flex flex-column p-0" ><tr>{_l('l_week_single_letter_saturday')}</tr>

                        </th>
                      </th>
                      <th className="fw-normal py-2 align-top text-center" >
                        <th className="d-flex flex-column p-0" ><tr>{_l('l_week_single_letter_sunday')}</tr>

                        </th>
                      </th>
                      <th className="fw-normal py-2 align-top text-center" >
                        <th className="d-flex flex-column p-0" ><tr>{_l('l_single_letter_monthly')}</tr>

                        </th>
                      </th>
                      <th className="fw-normal py-2 align-top text-center" >
                        <th className="d-flex flex-column p-0" ><tr>{_l('l_single_letter_quaterly')}</tr>

                        </th>
                      </th>
                      <th className="fw-normal py-2 align-top text-center" >
                        <th className="d-flex flex-column p-0" ><tr>{_l('l_single_letter_semester')}</tr>

                        </th>
                      </th>
                      <th className="fw-normal py-2 align-top text-center" >
                        <th className="d-flex flex-column p-0" ><tr>{_l('l_single_letter_yearly')}</tr>

                        </th>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="max-width-100 text-truncate border-bottom">
                        <div className="c-font text-truncate f-12">
                          {_l("l_general_area")}
                        </div>
                      </td>
                      <td className="max-width-100 text-truncate border-bottom">
                        <div className="c-font text-truncate f-12">
                          Clean Table
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center text-nowrap">
                        <div className="c-font f-12">
                          07:30 AM
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {0}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {0}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {0}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {0}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="max-width-100 text-truncate border-bottom">
                        <div className="c-font text-truncate f-12">
                          {_l("l_general_area")}
                        </div>
                      </td>
                      <td className="max-width-100 text-truncate border-bottom">
                        <div className="c-font text-truncate f-12">
                          Clean Table
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center text-nowrap">
                        <div className="c-font f-12">
                          07:30 AM
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center">
                        <div className="c-font text-truncate f-12 text-center">
                          {1}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row align-items-end pb-3">
              <Form.Group className="col-12  c-input-box  position-relative">
                <Form.Label className="input-label no-asterisk ">
                {_l("l_choose_csv_file")}
                </Form.Label>
                {/* <Form.Control className="" type="file" onChange={(e) => setImportFile(e.target.files)} accept=".csv ,application/vnd.ms-excel" /> */}
              <div className="d-flex align-items-center">
                <div className="comment-input-box flex-grow-1 me-2">
                  <div className="input_box_main with_file_upload" data-tip={`${_l("l_upload_file")}`}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main">
                    <label className="upload_inputbox w-100 upload_relative form-control height-45 border-0 p-0 d-flex align-items-center ">
                      <Form.Control
                        className="  hidden-input"
                        type="file"
                        onInput={setImportFile}
                        accept=".csv"
                      />
                      <div className="custom_file">
                        <Paperclip size={18} className="upload_icon c-icons" weight="light" />
                        <div className="btn btn-sm btn-white-10 border-0">
                          <span className="c-font f-10 color-white-60"> {_l('l_upload_file')}</span>
                        </div>
                        <span className="px-1 c-font f-13 color-white-60">{importFile && importFile[0] ? importFile[0].name : ""}</span>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="text-nowrap h_45">
                  <Button variant="primary" className="h-100" size="sm" onClick={downloadSample}>{`${_l("l_download")} ${_l("l_template")}`}</Button>
                </div>
              </div>
              </Form.Group>
              {/* <div className="col-2">
                <Button variant="primary" className="mb-2" size="sm" onClick={downloadSample}>{`${_l("l_download")} ${_l("l_template")}`}</Button>
              </div> */}
            </div>
            <div className="row d-none">
              <Form.Group className="col-6 pb-3 c-input-box  position-relative">
                <Form.Label className="input-label no-asterisk ">
                {_l("l_status")}
                </Form.Label>
                <Select
                  placeholder={`${_l("l_nothing_selected")}`}
                  className="custom-select-menu w-100"
                  classNamePrefix="react-select"
                  closeMenuOnSelect={true}
                // options={providersList.map((provider) => {
                //   return { value: provider.filter_key, label: provider.title }
                // })}
                // onChange={(e) => {
                //   appendCategoryChecklistsToRoom(e.value)
                // }}
                />
              </Form.Group>
            </div>
            <div className="row d-none">
              <Form.Group className="col-6 pb-3 c-input-box  position-relative">
                <Form.Label className="input-label no-asterisk ">
                {_l("l_source")}
                </Form.Label>
                <Select
                  placeholder={`${_l("l_nothing_selected")}`}
                  className="custom-select-menu w-100"
                  classNamePrefix="react-select"
                  closeMenuOnSelect={true}
                // options={providersList.map((provider) => {
                //   return { value: provider.filter_key, label: provider.title }
                // })}
                // onChange={(e) => {
                //   appendCategoryChecklistsToRoom(e.value)
                // }}
                />
              </Form.Group>
            </div>
            <div className="row d-none">
              <Form.Group className="col-6 pb-3 c-input-box  position-relative">
                <Form.Label className="input-label no-asterisk ">
                {_l("l_responsible")}  ({_l("l_assignee")})
                </Form.Label>
                <Select
                  placeholder={`${_l("l_nothing_selected")}`}
                  className="custom-select-menu w-100"
                  classNamePrefix="react-select"
                  closeMenuOnSelect={true}
                // options={providersList.map((provider) => {
                //   return { value: provider.filter_key, label: provider.title }
                // })}
                // onChange={(e) => {
                //   appendCategoryChecklistsToRoom(e.value)
                // }}
                />
              </Form.Group>
            </div>
            <Form.Group className="col-12 pb-3 c-input-box  position-relative d-none">
              <Button variant="secondary me-2 mt-2" size="sm" className="position-relative" onClick={handleImport}>{_l("l_import_items")} 
              </Button>
            </Form.Group>
            {/* </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
          {_l("l_close")}
          </Button>
          <Button variant="primary" size="sm" onClick={handleImport}>{_l("l_import")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImportItems;
