import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import CustomerServices from "../../services/customer-services";
import CommanLoader from "../Loader/CommanLoader";
import PremiumEstimateIconCrown from "../../assets/icons/PremiumEstimateIconCrown";
import { _l } from "../../hooks/utilities";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { getTaskList, leftPanelFilters, projectTaskCounts, getTaskCount, getTopicTasks, getDefaultTopics } from "../../actions/customer";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
const OperatorListTag = ({ provider, handler }) => {
  const isChecked = provider.is_active == 1 ? true : false;
  const dispatch = useDispatch();
  const projectId = useSelector((state) => state.customer.selectedProject);
  const pageName = window.location.pathname.replace('/', '');
  return (
    <li className="hr_sepretr">
      <a href="#/" className="dropdown-item w-100 py-3">
        <div className="d-flex align-items-center">
          <div className="c-list-icon position-relative">
            <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
              <div
                className={`comman-bg-img h-100 w-100 bg-style-cover rounded-circle`}
                style={{ backgroundImage: `url('${provider.profile_url}')` }}
              ></div>
            </div>
            {provider.is_boss_operator === 1 ? (
              <span className="bg-base-header-color border-0 rounded-pill badge-custom d-flex p-2 light-pill bg-none">
                <PremiumEstimateIconCrown className="stroke-transparent path-warning HW14 position-absolute" />
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="ps-2 w100minus20 text_wrap">
            <div className="d-flex align-items-center justify-content-between">
              <div className="c-list-detail text-truncate w100minus100 pe-2">
                {provider.full_name}
              </div>
              <div className="align-items-center d-flex flex-grow-1 justify-content-end">
                
                {provider.is_boss_operator === 1 ||
              provider.is_current_operator === 0 ? (
              ""
            ) : (
              <a href="#/" className="align-items-center c-font d-flex f-13 left-toggle-none pe-2">
                <div className="progress-slider w50">
                  <div className="d-flex align-items-center">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        style={{ width: `${provider.task_progress}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between  ms-2">
                  {provider.task_progress}%
                </div>
              </a>
            )}
                
                <Form.Check
                type="switch"
                  // disabled={activeTopicKey == 'my_task' && ['created_by_me', 'assigned_to_me', 'pinned'].indexOf(category.filter_key) > -1 ? true : false}
                  defaultChecked={isChecked}
                  onChange={(e) => {
                    // task list API here
                    handler(provider.staffid, e.target.checked);
                    if (localStorage.getItem("email_filter_email") && !isNaN(+localStorage.getItem("email_filter_key"))) {
                      var filterdMail = JSON.parse(localStorage.getItem("email_filter_email"));
                      if (e.target.checked) {
                        filterdMail.push(provider.email);
                      } else {
                        filterdMail = filterdMail.filter((mail) => mail != provider.email);
                      }
                      localStorage.setItem("email_filter_email", JSON.stringify(filterdMail));
                    }

                    window.dispatchEvent(new Event("addEditCategoryModalClose"));
                    dispatch(getDefaultTopics(projectId, "", "", pageName));
                    dispatch(getTopicTasks(projectId, pageName));
                  }}
                />
              </div>
            </div>
            {/* {provider.is_boss_operator === 1 ||
              provider.is_current_operator === 0 ? (
              ""
            ) : (
              <a href="#/" className="left-toggle-none c-font f-13 d-flex pt-2">
                <div className="progress-slider w-100 pt-2 pb-2">
                  <div className="d-flex align-items-center">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        style={{ width: `${provider.task_progress}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between  ms-3">
                  {provider.task_progress}%
                </div>
              </a>
            )} */}
          </div>
        </div>
      </a>
    </li>
  );
};

const ProviderDropdownWithprogressBar = ({ staff, show, modal1handleClose }) => {
  const project_id = useSelector((state) => state.customer.selectedProject);
  const [categoryProvider, setCategoryProvider] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const taskStatusFilter = useSelector(
    (state) => state.customer.activeTaskStatusFilters
  );
  const filterData = useSelector((state) => state.customer.taskFilters);

  const getTaskIfCategorySet = () => {
    let checkCategorySet = filterData
      ? filterData.filter(
        (taskFilter) =>
          taskFilter.main_key == "provider_task" &&
          taskFilter.filter_key == parseFloat(staff.filter_key)
      )
      : [];
    if (checkCategorySet.length > 0) {
      dispatch(
        getTaskList(
          localStorage.getItem("client_id"),
          localStorage.getItem("contact_id"),
          localStorage.getItem("selectedOffice"),
          moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD"),
          1,
          taskStatusFilter ? taskStatusFilter : [],
          filterData ? filterData : []
        )
      );
    } else {
      dispatch(
        leftPanelFilters({
          main_key: 'provider_task',
          filter_key: parseFloat(staff.filter_key),
          title: staff.title ? staff.title : '',
          main_sub_key: staff.main_sub_key ? staff.main_sub_key : "",
        })
      );
      dispatch(
        projectTaskCounts(
          localStorage.getItem('selectedOffice'),
          filterData ? filterData : [],
          {
            main_key: 'provider_task',
            filter_key: parseFloat(staff.filter_key),
            title: staff.title ? staff.title : '',
          }
        )
      );
      dispatch(getTaskCount(0, 0, localStorage.getItem('selectedOffice'), moment(localStorage.getItem('selectedDate')).format('YYYY-MM-DD'),
        filterData ? filterData : [],
        {
          main_key: 'provider_task',
          filter_key: parseFloat(staff.filter_key),
          title: staff.title ? staff.title : '',
        }, taskStatusFilter ? taskStatusFilter : []));
    }
  };
  useEffect(() => {
    setShowLoader(true);
    CustomerServices.getOperatorsByCategory(
      project_id,
      parseFloat(staff.filter_key),
      1
    ).then((res) => {
      setShowLoader(false);
      if (res.status && res.data) {
        setCategoryProvider(res.data);
      }
    });
  }, [staff]);

  const categoryToggle = (filter_key, checked) => {
    CustomerServices.changeMainTopicStatus(
      project_id,
      "category_provider",
      filter_key,
      checked,
      staff.filter_key
    );
  };

  return (
    <>

      <div className="testing_modal">
        <Modal
          show={show}
          onHide={modal1handleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // size="lg"
          scrollable={true}
        >
          <Modal.Header closeButton className="mb-1">
            <Modal.Title>
              {_l("l_provider_list")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0 mt-3">
            <ul
              // className="dropdown-menu w-280 overflowscroll with-transform-outlside max-height-50vh time-slot-dropdown"
              aria-labelledby="ProviderDropdownWithprogressBarHandle"
              id="ProviderDropdownWithprogressBarDropDown"
            >
              {/* <div className="testing">bfvfhuvbfdhvb</div> */}

              {showLoader ? (
                <div>
                  <CommanLoader className={`dropdown-loader comman-main-loader-wrapper`} />
                </div>
              ) : (
                <div className="dropdown-checklist-wrapper">
                  <div className="timeslot-dropdown-header p-2 custom-accordian-Header-sticky">
                    <div className="title-font f13 color-white-80 text-truncate">
                      {_l("l_current_provider")}
                    </div>
                  </div>
                  {categoryProvider &&
                    categoryProvider.filter(
                      (active) => active.is_current_operator === 1
                    ).length > 0 ? (
                    categoryProvider
                      .filter((active) => active.is_current_operator === 1)
                      .map((provider, index) => {
                        return (
                          <React.Fragment>
                            <OperatorListTag
                              key={index}
                              handler={(staffid, active) => {
                                categoryToggle(staffid, active);
                                getTaskIfCategorySet(staffid);
                              }}
                              provider={provider}
                            />
                            {provider.employees && provider.employees.length ? (
                              provider.employees.map((employee, employeeIndex) => {
                                return (
                                  <OperatorListTag
                                    key={employeeIndex}
                                    handler={(staffid, active) => {
                                      categoryToggle(staffid, active);
                                      getTaskIfCategorySet(staffid);
                                    }}
                                    provider={employee}
                                  />
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </React.Fragment>
                        );
                      })
                  ) : (
                    <CommanPlaceholder imgType="no-provider" placeholderText = {_l("l_no_current_provider")} />
                  )}
                  <div className="timeslot-dropdown-header p-2 custom-accordian-Header-sticky">
                    <div className="title-font f13 color-white-80 text-truncate ">
                      {_l("l_old_provider")}
                    </div>
                  </div>
                  {categoryProvider &&
                    categoryProvider.filter(
                      (active) => active.is_current_operator === 0
                    ).length > 0 ? (
                    categoryProvider
                      .filter((active) => active.is_current_operator === 0)
                      .map((provider, index) => {
                        return (
                          <React.Fragment>
                            <OperatorListTag
                              key={index}
                              handler={(staffid, active) => {
                                categoryToggle(staffid, active);
                                getTaskIfCategorySet();
                              }}
                              provider={provider}
                            />
                            {provider.employees && provider.employees.length ? (
                              provider.employees.map((employee, employeeIndex) => {
                                return (
                                  <OperatorListTag
                                    key={employeeIndex}
                                    handler={(staffid, active) => {
                                      categoryToggle(staffid, active);
                                      getTaskIfCategorySet();
                                    }}
                                    provider={employee}
                                  />
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </React.Fragment>
                        );
                      })
                  ) : (
                    <CommanPlaceholder imgType="no-provider" placeholderText = {_l("l_no_old_provider")} />
                  )}
                </div>
              )}
            </ul>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ProviderDropdownWithprogressBar;
