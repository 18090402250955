import React, { useEffect, useState } from "react";
import CustomerServices from "../../services/customer-services";
import { useDispatch, useSelector } from "react-redux";
import { getTaskChecklist, updateedTaskChecklist } from "../../actions/customer";
import moment from "moment";
import { X , MagnifyingGlass, ArrowRight  } from "phosphor-react";
import { changeTaskStatusFromChecklistItems, _l } from "../../hooks/utilities";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { DropdownMenu } from "reactstrap";
const CheckedDropdown = ({
  task_id,
  task_status,
  setOpenCheckList,
  openCheckList,
  project_id,
  isSystemTask = null,
  isRequestTask = "",
  tasks,
  taskDetails,
  handleTask,
  isChecklistPermission,
  isFromCalendarDropdown = null,
  updateChecklistFromCalendarDropdown,
  isRequestType = false,
  unContolList=false,
  pageName = ""
}) => {
  // const [checkListTexts, setCheckListTexts] = useState([]);
  const [checklistItems, setChecklistItems] = useState([]);
  const [addChecklistItem, setAddChecklistItem] = useState("");
  const [checkListValues, setCheckListValues] = useState([]);
  const [checkListUpdate, setCheckListUpdate] = useState(false);
  const [searchChecklist, setSearchChecklist] = useState("");
  const [defaulChecklistData, setDefaultChecklistData] = useState([])
  const contact_image = localStorage.getItem("contact_image");
  const contact_id = localStorage.getItem("contact_id");
  const dispatch = useDispatch();
  const checklistStateData = useSelector(
    (state) => state.customer.taskChecklist
  );
  const selectedTask = taskDetails ? undefined : useSelector((state) => state.customer.selectedTask);
  useEffect(() => {
    if (searchChecklist != "") {
      let tempChecklistData = defaulChecklistData.filter(
        (list) => list.name &&
          list.name.toLowerCase().includes(searchChecklist.toLowerCase())
      )
      setChecklistItems(tempChecklistData);
    } else {
      setChecklistItems(defaulChecklistData);
    }
  }, [searchChecklist]);

  const handleAddChecklist = () => {
    if (addChecklistItem.trim()) {
      CustomerServices.addChecklistItem(
        task_id,
        project_id,
        [{description: addChecklistItem.trim(), finished:0}]
      ).then((res) => {
        if (res.status) {
          for (let j = 0; j < res.data.checklist_data.length; j++) {
          setChecklistItems((checklistItems) => [
            ...checklistItems,
            {
              addedfrom: contact_id,
              description: addChecklistItem.trim(),
              finished: "0",
              id: res.data.checklist_data[j].id,
              is_added_from_contact: "1",
              list_order: "0",
              req_photo: "0",
              addedfrom_details: { profile_url: contact_image },
              comments_count: 0,
              links_count: 0,
              attachments_count: 0,
              is_allowed_to_edit: 1,
            },
          ]);
        }
          setAddChecklistItem("");
          if (checklistItems && checklistItems.length) {
            let latestChecklistItems = [
              ...checklistItems,
              {
                addedfrom: contact_id,
                description: addChecklistItem.trim(),
                finished: "0",
                id: res.data.checklist_id,
                is_added_from_contact: "1",
                list_order: "0",
                req_photo: "0",
                addedfrom_details: { profile_url: contact_image },
                comments_count: 0,
                links_count: 0,
                attachments_count: 0,
                is_allowed_to_edit: 1,
              },
            ];
            changeTaskStatusFromChecklistItems(
              latestChecklistItems.map((x) => x.finished != 0),
              task_id,
              task_status,
              project_id
            );
          }
        }
      });
    }
  };

  useEffect(() => {

    if (task_id && openCheckList && isRequestTask == "" && (!selectedTask || isFromCalendarDropdown == 1) && isFromCalendarDropdown != "fromCalendar") {
      setChecklistItems([]);
      if(pageName != "calendar") {
      dispatch(getTaskChecklist(task_id, project_id)); 
      }
      setCheckListUpdate(true)
    }

    if (openCheckList && selectedTask && Object.keys(selectedTask).length && isFromCalendarDropdown && isFromCalendarDropdown != "fromCalendar") {
      CustomerServices.getCheckList(task_id, project_id).then((res) => {
        if (res.status) {
          setChecklistItems(res.data);
          setDefaultChecklistData(res.data);
        }
      })
    }
    return () => {
    };
  }, [openCheckList, task_id]);
  useEffect(() => {
    if (isRequestTask != "" || isFromCalendarDropdown == "fromCalendar") {
      let tempTasks = [];
      tasks.forEach((item) => {
        tempTasks.push(item);
      });
      setChecklistItems(tempTasks);
      setDefaultChecklistData(tempTasks);
    }
  }, [tasks]);

  useEffect(() => {
    if ((checklistStateData || checkListUpdate) && (!selectedTask || isFromCalendarDropdown == 1)) {
      setChecklistItems([]);
      const checklists = checklistItems.length > 0 ? checklistItems : checklistStateData;
      let checkBoxes = [];
      if (checklists) {
        checklists.forEach((item) => {
          checkBoxes[item.id] = item.finished === "0" ? false : true;
          setChecklistItems((checklistItems) => [...checklistItems, item]);
        });
        setCheckListValues(checkBoxes);
        setCheckListUpdate(false)
      }

      //To do:
      // Order the checklist item based on key list_order
    }
    if(pageName == "calendar" && checklistStateData != null && checklistStateData != undefined) {
      setChecklistItems(checklistStateData)
    }
  }, [JSON.stringify(checklistStateData), checkListUpdate]);

  const itemFinished = (checklistData, checked, index) => {
    checklistData.finished = checkListValues[checklistData.id] ? "0" : "1";
    // checklistData.finished = checked ;
    handleCheckListUpdate(checklistData);
    if (isFromCalendarDropdown == "1") {
      updateChecklistFromCalendarDropdown(checklistData.id, checklistData.finished)
    }
    // setChecklistItems(
    //   Object.values({ ...checklistItems, [index]: checklistData })
    // );
    dispatch(updateedTaskChecklist(Object.values({ ...checklistItems, [index]: checklistData })));
    const element = document.getElementById(
      `taskcard_checkbox_${checklistData.id}`
    );
    if (element) {
      const currentValue = element.checked;
      element.checked = !currentValue;
    }
    if ((selectedTask || taskDetails) && checklistItems) {
      let latestCheckboxValues = checklistItems.map((x) => x.finished);
      latestCheckboxValues[index] = checked ? "1" : "0";
      const isScheduled = taskDetails ?
        taskDetails.schedule_id != null && taskDetails.schedule_id != 0 : selectedTask.schedule_id != null && selectedTask.schedule_id != 0;
      changeTaskStatusFromChecklistItems(
        latestCheckboxValues.map((checkboxValue) => {
          return checkboxValue == 1;
        }),
        task_id,
        task_status || selectedTask.status,
        project_id,
        isScheduled
      );
    }
  };

  const handleCheckListUpdate = (checklistData, id) => {
    if (checklistData) {
      CustomerServices.updateChecklistItem(
        project_id,
        task_id,
        checklistData.id,
        checklistData.finished,
        checklistData.description
      ).then((res) => {
        if (res.status) {
          setCheckListValues({
            ...checkListValues,
            [`${checklistData.id}`]: !checkListValues[`${checklistData.id}`],
          });
          const element = document.getElementById(
            `taskdetail_checkbox_${checklistData.id}`
          );
          if (element) {
            const currentValue = element.checked;
            element.checked = !currentValue;
          }
        }
      });
    }
  };
  const checkedDropdownContain = (
    <>
    <ul className="dropdown-head">
        <li>
          <div className="dropdown-header">
            <div className="d-flex align-items-center justify-content-between">
              <div className="c-list-detail title-fonts text-truncate color-white c-font f-13">
                {isRequestTask != "" ? _l('l_upcoming_tasks') : _l('l_checklist_items')}
              </div>
              <a href="#/" className="right-image d-none">
                <X size={18} className="c-icons" weight="light" />
              </a>
            </div>
          </div>
        </li>
        <li>
          <hr className="dropdown-divider my-0" />
        </li>
        <li>
          <div className={`comman-search-dropdown ${isRequestType ? "" : "d-none"}`}>
            <div className="d-flex align-items-center w-100">
              <div className="comment-input-box flex-grow-1">
                <input type="text" 
                className="form-control bg-transparent with-base-bg border-0 flex-grow-1 p-2 f-11 c-font" 
                placeholder="Search"
                value={searchChecklist}
                onChange={(e) => setSearchChecklist(e.target.value)}
                />
              </div>
              <a href="#/" className="comman_action_icon">
                <div className="d-flex align-items-center ">
                  <div className="action_icon h25w25">
                  <MagnifyingGlass size={14} className="c-icons" weight="light" />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </li>
      </ul>
      <ul className="dropdown-contain overflowscroll text-break">
        {checklistItems.length ? (
          checklistItems.map((item, index) => {
            return (
              <li key={index} className="hr_sepretr">
                <label
                  className="dropdown-item d-flex p-2"
                  htmlFor={`inlineCheckbox_${item.id}`}
                  data-bs-toggle="tooltip"
                  title={"l_checklist_detail"}
                >
                  <div
                    className={`form-check form-check-inline ${isRequestTask != "" ? "d-none" : ""
                      }`}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={`taskcard_checkbox_${item.id}`}
                      checked={checkListValues[item.id]}
                      onChange={(e) => {
                        itemFinished(item, e.target.checked, index);
                      }}
                      disabled={item.is_allowed_to_edit && item.is_allowed_to_edit == 1 ? false : true}
                    />
                  </div>
                  <div
                    className="text-wrap  c-font f-13 color-white-60"
                    aria-expanded="false"
                    data-tip={
                      isRequestTask != "" || isFromCalendarDropdown == "fromCalendar"
                        ? "#" +
                        item.id +
                        "-" +
                        moment(item.startdate).format("DD/MM/YYYY hh:mm a")
                        : ""
                    }
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main"
                    onClick={() => {
                      if (isRequestTask != "" || isFromCalendarDropdown == "fromCalendar") {
                        handleTask(item.id, isRequestTask);
                      }
                    }}
                  >
                    {isRequestTask != "" ? item.name : item.description}
                  </div>
                </label>
              </li>
            );
          })
        ) : (
          <CommanPlaceholder imgType="no-checklist" placeholderText = {`${_l("l_no")} ${isRequestTask != '' ? _l('l_task') : _l('l_checklist')}`} />
        )}
      </ul>
      <ul className="dropdown-footer">
        <li>
          <hr className="dropdown-divider my-0" />
        </li>
        <li
          className={`${isSystemTask || isRequestTask != '' ? "d-none" : ""}   ${isRequestTask != "" || (isChecklistPermission && isChecklistPermission == 1) ? "for-disabled" : ""
            }`}
        >
          <div className="comment-input-box bg-white-03">
            <input
              className="form-control height-45 border-0 w100minus40"
              style={{ backgroundColor: "transparent" }}
              type="text"
              placeholder={`${_l("l_write_checklist_placeholder")}`}
              aria-label="default input example"
              // required pattern="[a-zA-Z0-9 ]+"
              value={addChecklistItem}
              onChange={(e) => {
                setAddChecklistItem(
                  e.target.value
                  // e.target.value.replace(/[^a-zA-Z0-9^a-zA-Z0-9!@#$%^&*()?\ \,.;]/ig,"")
                );
              }}
              onKeyUp={(e) => {
                setTimeout(() => {
                  if (e.key === "Enter") {
                    handleAddChecklist();
                  }
                }, 100);
              }}
            />
            <a href="#/" className="send-arrow-btn" onClick={handleAddChecklist}>
              <ArrowRight size={14} className="c-icons" weight="light" />
            </a>
          </div>
        </li>
      </ul>
    </>
  )

  return (
    <>
    {
      unContolList ? (
        <DropdownMenu container="body" direction="down" id="checklistDropdownHead" className={`h-auto`}>{checkedDropdownContain}</DropdownMenu> 
      ) : (
        <ul className="dropdown-menu h-auto" id="checklistDropdownHead" aria-labelledby="checkedDropdown">{checkedDropdownContain}</ul>
      )
    }
    </>
  );
};

export default CheckedDropdown;
