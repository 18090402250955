import React, { useEffect, useState } from "react";
import LoaderVideo from "../../assets/images/myr.ai-loader.gif";
import { _l } from "../../hooks/utilities";
const CommanSmallLoader = ({}) => {
  return (
    <>
      <div className="main-loader-wrapper comman-main-loader-wrapper full-screen with-opacity">
          <div className="w-100 h-100 d-flex">
            <div className="loader-video">
              <img src={LoaderVideo} alt={LoaderVideo} />
            {/* <video  autostart="true" autoPlay="true" loop="true" src={LoaderVideo} type={LoaderVideo}/> */}
              <div className="loader-text mt-2">{_l("l_please_wait")}</div>
            </div>
          </div>
      </div>
    </>
  );
};

export default CommanSmallLoader;