import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import { _l } from "../../../hooks/utilities";
import EquipmentListing from "../../../components/EquipmentListing";
const ClientContactsTab = ({data}) => {
    return (
        <div>
            <EquipmentListing pageName="customer" clientid={data.id}/>
        </div>
    )
}
export default ClientContactsTab;