import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l, saveDocumentDataToIDB, showError,} from "../../hooks/utilities";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { CloudArrowUp, X } from "phosphor-react";
import customerServices from "../../services/customer-services";
import { updateDocumentTemplates } from "../../actions/customer";


const DocumentTemplateModal = ({ show, handleClose = () => {}, docType, data }) => {

  const dispatch = useDispatch();

  const { benchmarkDetail } = useSelector((state) => state.customer);
  
  const [type, setType] = useState();
  const [loader, setLoader] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [imagePreview, setimagePreview] = useState([])

  const typeOption = [
    { value: "mandate", label: "Mandate" },
    { value: "dp", label: "DP" },
    { value: "reference", label: "Reference" },
  ];

  const handleSave = async() => {
    setLoader(true);
    if (type && type.value && attachments && attachments.length > 0) {
        try {
            const res =  await customerServices.uploadeTempDocument(type.value, attachments[0].files);
          if (res.status) {
            
            setLoader(false);
            const updateData = {
              [type.value] : [res.data],
              key : type.value
            }
              dispatch(updateDocumentTemplates(updateData));
              saveDocumentDataToIDB(updateData,()=>{}, false, "other_documents");
              handleClose();
          } else {
            setLoader(false);
            handleClose();
            showError("l_something_went_wrong")
          }
        } catch (error) {
            console.log(error);
        }
    }else{
        if (type && !type.value) {
            showError("l_select_type");
        }else if (attachments || attachments.length == 0) {
            showError("l_attachments_is_required");
        }
        setLoader(false);
    }
  };

  const uploadImage = (files) => {
    let  id = Math.random(5)
    let previewImage = {
      name: files.name,
      url: URL.createObjectURL(files),
      id
    }
    setAttachments([{files, id}].concat(attachments));

    setimagePreview([previewImage].concat(imagePreview));
  }

  const removeImage = (id) => {
    setAttachments(attachments.filter(item => item.id !== id));
    setimagePreview(imagePreview.filter(item => item.id !== id));
  }

  useEffect(() => {
    let element = document.body;
    if (show) {
      if (element) {
        element.classList.remove("modal-open");
      }
    }
    return () => {
      if (element) {
        element.classList.remove("modal-open");
      }
    };
  }, [show]);

  useEffect(() => {
    if (docType == "edit_doc_template") {
      setType(typeOption.find(item => item.value == data.key));
    }
  }, [docType])

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop={"static"}
      keyboard={false}
      centered
      className="custom-modal-style solarcommanmodal "
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header className="p-3" closeButton>
        <Modal.Title>{_l("l_add_template")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-wrapper-main p-0">
            <div className="col-12 mb-3">
                <Form.Group className="c-input-box  position-relative">
                    <Form.Label className="input-label no-asterisk">
                    {_l("l_select_type")}
                    </Form.Label>
                 
                    <Select
                   placeholder={`${_l("l_select_type")}`}
                    className="custom-select-menu w-100"
                    classNamePrefix="react-select"
                    isDisabled= {docType == "edit_doc_template"}
                    closeMenuOnSelect={true}
                        value={type}
                        isClearable={false}
                        options={typeOption}
                        onChange={(value) => { setType(value)}}
                    />
                </Form.Group>
            </div>
            <Form.Group className="col-12 c-input-box d-flex flex-column position-relative">
                <Form.Label className="input-label no-asterisk ">
                {_l("l_file_upload")}
                </Form.Label>
              <div className="d-flex flex-grow-1 gap10px">
                <div className="form-control file-upload bgblackop002 shadow-none position-relative p-0 flex-grow-1">
                    <div className="d-flex align-items-center justify-content-between p-15">
                    <input 
                        className="z-index-3 hidden-input" 
                        type="file"
                        multiple
                        onChange={(e) => {
                        uploadImage(
                            e.target.files[0]
                        );
                        }}
                    />
                    <div className="m-auto">
                        <CloudArrowUp size={18} className="c-icons"/>
                        <span className="c-font color-white-60 f-10 fw-medium ps-2"> {_l("l_click_to_upload")}</span>
                    </div>
                    </div>
                </div>
                <div className="upload-image-preview  d-flex flex-wrap">
                    {imagePreview && imagePreview.length > 0 && 
                        imagePreview.map((image) => {
                            return(
                        <div className="h60w60">
                        <div className="comman-image-box h50w50 radius_3 upload-image with-margin">
                            
                            <div
                                className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 cursor-pointer"
                                style={{
                                backgroundImage: `url(${image.url})`,
                                }}
                                data-tip={_l("l_preview_attachment")}
                                data-effect="solid"
                                data-delay-show="1000"
                                data-class="tooltip-main"
                            ></div>
                            
                            <a
                            href="#/"
                            className="upload-close"
                            onClick={() => {
                                removeImage(image.id);
                            }}
                            >
                            <X
                                size={10}
                                className="c-icons"
                            />
                            </a>
                        </div>
                        <div className="c-font color-white-60 f-10 line-clamp-3">
                            {image.name}
                        </div>
                </div>)})}
            </div>
            </div>
            </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-end">
        <Button variant="secondary" size="sm" onClick={handleClose}>
          {_l("l_close")}
        </Button>
        <Button
          variant="primary"
          size="sm"
          onClick={() => {
            if (!loader && ["add_doc_template", "edit_doc_template"].includes(docType)) {
              handleSave();
            }
          }}
          disabled={loader}
        >
          {loader ? _l("l_please_wait") : _l("l_save")}
          {loader ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              className={`ms-2 `}
              aria-hidden="true"
            />
          ) : (
            <></>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DocumentTemplateModal;
