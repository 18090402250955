import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button, Form } from "react-bootstrap";
import { _l, showError, showSuccess } from "../hooks/utilities";
import CustomerServices from "../services/customer-services";
import CommanLoader from "./Loader/CommanLoader";
import Select from "react-select";
import { CalendarBlank } from "phosphor-react";
import DatePicker from "react-datepicker";

const AITopicContext = forwardRef(
  (
    {
      category,
      projectId,
      setCategory,
      setLoadingNextStep,
      is_general_context,
      ai_assistant_id,
      main_key,
    },
    ref
  ) => {
    const [providerContextDescription, setProviderContextDescription] =
      useState("");
    const [generalContextDescription, setGeneralContextDescription] =
      useState("");
    const [attachmentContextDescription, setAttachmentContextDescription] =
      useState("");
    const [loadingContent, setLoadingContent] = useState(false);
    const [isGeneralContextActive, setIsGeneralContextActive] = useState(false);
    const [taskMergeFields, setTaskMergeFields] = useState([]);
    const [generalMergeFields, setGeneralMergeFields] = useState([]);
    const [startDate, setStartDate] = useState(new Date());

    const generalContextRef = useRef();
    const proviederContextRef = useRef();
    const attachmentContextRef = useRef();

    const updateContextValue = async () => {
      const generalContextValue =
        generalContextRef && generalContextRef.current
          ? generalContextRef.current.value
          : "";
      const providerContextValue =
        proviederContextRef && proviederContextRef.current
          ? proviederContextRef.current.value
          : "";
      const attachmentContextValue = attachmentContextRef.current
        ? attachmentContextRef.current.value
        : "";

      setLoadingNextStep(true);

      try {
        const response = await CustomerServices.setSpaceContextData(
          projectId,
          category.id > 0 ? category.id : 0,
          generalContextValue,
          providerContextValue,
          is_general_context,
          ai_assistant_id,
          attachmentContextValue
        );
        if (response.status == 0) {
          showError(response.message);
        } else if (response.status > 0) {
          setCategory({ ...category, ai_context_disabled: false });
          if (generalContextValue.length || providerContextValue.length) {
            setCategory({
              ...category,
              ai_context_active: 1,
              ai_context_disabled: false,
            });
            setIsGeneralContextActive(true);
          }
          if (response.message) {
            showSuccess(response.message);
          }
        }
      } catch (e) {
        console.error(e);
        showError("l_something_went_wrong");
      } finally {
        setLoadingNextStep(false);
      }
    };

    const toggleContextData = async (e) => {
      try {
        const status = e.target.checked ? 1 : 0;
        const response = await CustomerServices.toggleProviderOpenAIStatus(
          0,
          0,
          status,
          1,
          ai_assistant_id
        );
        if (response.status > 0) {
          setIsGeneralContextActive(status > 0);
        } else {
          showError(response.message);
        }
      } catch (e) {
        console.error(e);
        showError("l_something_went_wrong");
      }
    };

    const fetchMergeFields = async (e) => {
      try {
        const response = await CustomerServices.getAIMergeFields();
        if (response.status) {
          if (response.data.general_mergeFields) {
            setGeneralMergeFields(response.data.general_mergeFields);
          }

          if (response.data.task_mergeFields) {
            setTaskMergeFields(response.data.task_mergeFields);
          }
        }
      } catch (e) {
        showError("l_something_went_wrong");
        console.error(e);
      }
    };

    const selectMergeField = (textAreaRef, valueToAppend) => {
      const textArea = textAreaRef.current;

      const selectionStart = textArea.selectionStart;
      const selectionEnd = textArea.selectionEnd;

      const currentText = textArea.value;

      const newText =
        currentText.substring(0, selectionStart) +
        valueToAppend +
        currentText.substring(selectionEnd);

      textArea.value = newText;

      const newCursorPosition = selectionStart + valueToAppend.length;
      textArea.setSelectionRange(newCursorPosition, newCursorPosition);

      textArea.focus();
    };

    const toggleAIContextForProvider = async (e) => {
      try {
        const status = e.target.checked ? 1 : 0;
        const response = await CustomerServices.toggleProviderOpenAIStatus(
          category.id,
          projectId,
          status
        );
        if (response.status) {
          setCategory({ ...category, ai_context_active: status });
        }
      } catch (e) {
        console.error(e);
      }
    };

    const options = [
      { value: "for me", label: "For me" },
      { value: "services", label: "Services" },
      { value: "people", label: "People" },
      { value: "equipments", label: "Equipments" },
    ];
    const options2 = [
      { value: "daily", label: "Daily" },
      { value: "weekly", label: "Weekly" },
      { value: "monthly", label: "Monthly" },
      { value: "yearly", label: "Yearly" },
    ];

    useImperativeHandle(ref, () => ({
      updateContextValue: updateContextValue,
    }));

    useEffect(() => {
      const getContextData = async () => {
        try {
          setLoadingContent(true);
          const response = await CustomerServices.getSpaceContextData(
            projectId,
            category.id,
            is_general_context,
            ai_assistant_id
          );
          if (response.status) {
            const providerContext = response.data.find(
              (w) => w.is_general == 0
            );
            const generalContext = response.data.find((w) => w.is_general > 0);
            if (providerContext) {
              setProviderContextDescription(providerContext.content);
            } else {
              setProviderContextDescription("");
            }
            if (generalContext) {
              setGeneralContextDescription(generalContext.content);
            } else {
              setGeneralContextDescription("");
            }
            if (response.extra) {
              setCategory({
                ...category,
                ai_context_active: Number(response.extra.ai_context_active),
                ai_context_disabled: !providerContext && !generalContext,
              });
              setIsGeneralContextActive(response.extra.ai_context_active > 0);
              if(response.extra.attachment_result)
              {
                setAttachmentContextDescription(response.extra.attachment_result.content);
              }
            }
          }
        } catch (e) {
          showError("l_something_went_wrong");
          console.error(e);
        } finally {
          setLoadingContent(false);
        }
      };
      getContextData();
      fetchMergeFields();
    }, [category.id, projectId]);

    return (
      <div className="form-wrapper-main  pb-2">
        {loadingContent ? (
          <CommanLoader />
        ) : (
          <>
            <div className="row pe10per">
              {is_general_context > 0 ? (
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <div className="d-flex align-items-center pb-3">
                    <Form.Label className="input-label no-asterisk mb-0">
                      {_l("l_general_context")}
                    </Form.Label>
                    <div className="form-check form-check-inline m-0 ps-2">
                      <Form.Check
                        className=" with-no-label-space"
                        type="switch"
                        role="switch"
                        checked={isGeneralContextActive}
                        onChange={toggleContextData}
                        disabled={
                          !generalContextRef.current ||
                          !generalContextRef.current.value ||
                          !generalContextRef.current.value.length
                        }
                      />
                    </div>
                  </div>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder={`${_l("l_type_description_here")}`}
                    type="text"
                    name=""
                    defaultValue={generalContextDescription}
                    ref={generalContextRef}
                  ></Form.Control>
                </Form.Group>
              ) : (
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <Form.Label className="input-label no-asterisk mb-0">
                      {_l("l_provider_context")}
                    </Form.Label>
                    <div className="d-flex align-items-center ">
                      <label class="input-label no-asterisk no-asterisk form-label pe-0 mb-0">
                        {_l("l_off_ai_instruction")}
                      </label>
                      <div className="form-check form-check-inline m-0 pe-2">
                        <Form.Check
                          className=" with-no-label-space"
                          type="switch"
                          role="switch"
                          checked={category.ai_context_active > 0}
                          onChange={toggleAIContextForProvider}
                          disabled={category.ai_context_disabled}
                        />
                      </div>

                      <label class="input-label no-asterisk no-asterisk form-label mb-0">
                        {_l("l_on_ai_instruction")}
                      </label>
                    </div>
                  </div>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder={`${_l("l_type_description_here")}`}
                    type="text"
                    name=""
                    defaultValue={providerContextDescription}
                    ref={proviederContextRef}
                  ></Form.Control>
                </Form.Group>
              )}
              {category && category.id > -1 ? <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                <div className="d-flex align-items-center pb-3">
                  <Form.Label className="input-label no-asterisk mb-0">
                    {_l("l_attachment_context")}
                  </Form.Label>
                </div>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder={`${_l("l_type_description_here")}`}
                  type="text"
                  name=""
                  defaultValue={attachmentContextDescription}
                  ref={attachmentContextRef}
                ></Form.Control>
              </Form.Group> : <></> }
            </div>
            <div className="pe10per d-none">
              <div className="border-top mb-3 mt-2"></div>
              <div className="formCustomContext">
                <div className="c-font f-16 fw-semibold title-fonts mb-3">
                  Custom Context
                </div>
                <div className="d-flex bg-white-03 p-20">
                  <div className="row w-100">
                    <Form.Group
                      className={`col-12 c-input-box position-relative pb-4`}
                    >
                      <Form.Label className="input-label no-asterisk no-asterisk">
                        {_l("Content ")}
                      </Form.Label>
                      <div className="c-font f-14 mailto:title-fonts">
                        <Form.Control
                          placeholder="enter description"
                          as="textarea"
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="col-lg-6 c-input-box position-relative pb-4 z-index-3">
                      <Form.Label className="input-label no-asterisk  form-label">
                        {_l("Select Assignee ")}
                      </Form.Label>
                      <div className="d-flex align-items-center justify-content-between w-100 ">
                        <Select
                          className="custom-select-menu w-100 "
                          classNamePrefix="react-select"
                          placeholder="Select assignee"
                          options={options}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="col-lg-6 c-input-box position-relative pb-4">
                      <Form.Label className="input-label no-asterisk  form-label">
                        {_l("Status ")}
                      </Form.Label>

                      <div className="d-flex align-items-center form-control ">
                        <Form.Label className="form-check-label text_wrap  mb-0 ps-0">
                          <Form.Check
                            inline
                            name="1"
                            label={_l("Enable")}
                            type="radio"
                          />
                        </Form.Label>
                        <Form.Label className="form-check-label text_wrap mb-0">
                          <Form.Check
                            inline
                            name="1"
                            label={_l("Disable")}
                            type="radio"
                          />
                        </Form.Label>
                      </div>
                    </Form.Group>
                    <Form.Group className="col-lg-6 c-input-box position-relative pb-4">
                      <Form.Label className="input-label no-asterisk  form-label">
                        {_l("Select Period ")}
                      </Form.Label>
                      <div className="d-flex align-items-center justify-content-between w-100 ">
                        <Select
                          className="custom-select-menu w-100 "
                          classNamePrefix="react-select"
                          placeholder="Select period"
                          options={options2}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="col-xl-6  c-input-box mb-32px position-relative">
                      <div className="custom_input_box with_before">
                        <Form.Label className="input-label   form-label">
                          {_l("Select time")}
                        </Form.Label>
                        <div className="custom-datepicker w-100">
                          <div className="d-flex align-items-center form-control color-white-60 dropdown-center dropdown w-100 react-datepicker-wrapper-w-100 comman_action_icon h_53px">
                            <div className="action_icon h32w32 with_bg">
                              <CalendarBlank
                                size={18}
                                weight="light"
                                className="c-icons opacity-60"
                              />
                            </div>
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              portalId="custom_datepicker"
                              placeholderText={`${_l(
                                "l_select_date_placeholder"
                              )}`}
                              calendarClassName="custom-datepicker pt-0"
                              calendarStartDay={1}
                            ></DatePicker>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                    <div class="col-xl-12 c-input-box pb-4 position-relative">
                      <div class="d-flex align-items-center">
                        <div class="pe-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={true}
                          />
                        </div>
                        {_l("l_i_accept_terms_and_conditions")}
                        <a
                          href="https://myr.ai/cgsc.html"
                          class="color-green ms-1"
                          target="_blank"
                        >
                          CGSC
                        </a>
                        <a
                          href="https://myr.ai/cgsp.html"
                          class="color-green ms-1"
                          target="_blank"
                        >
                          CGSP
                        </a>
                        <span class="m-1">&amp;</span>
                        <a
                          href="https://myr.ai/privacy-policy.html"
                          class="color-green ms-1"
                          target="_blank"
                        >
                          RGPD
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <Button variant={"primary"} className="" size="sm">
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row pe10per">
              <div className="col-xl-6 pe-5">
                <div className="bg-white-05 c-font f-12 color-white radius_3 px-2 py-1 leadProfileTitle">
                  {_l("l_general_merge_fields")}
                </div>
                <ul className="p-10 px-0">
                  {generalMergeFields.map((field, index) => {
                    return (
                      <li
                        key={index}
                        className="lead-items d-flex p-1 cursor-pointer"
                        onClick={() => {
                          selectMergeField(
                            is_general_context === 1
                              ? generalContextRef
                              : proviederContextRef,
                            field.key
                          );
                        }}
                      >
                        <div className="c-font f-13 color-white-60 flex-grow-1 max-w-130px text-truncate">
                          {field.name}
                        </div>
                        <div className="c-font f-13 w100minus120 color-white-80 text-truncate text-end">
                          {field.key}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="col-xl-6">
                <div className="bg-white-05 c-font f-12 color-white radius_3 px-2 py-1 leadProfileTitle">
                  {_l("l_task_merge_fields")}
                </div>
                <ul className="p-10 px-0">
                  {taskMergeFields.map((field, index) => {
                    return (
                      <li
                        key={index}
                        className="lead-items cursor-pointer d-flex p-1"
                        onClick={() => {
                          selectMergeField(
                            is_general_context === 1
                              ? generalContextRef
                              : proviederContextRef,
                            field.key
                          );
                        }}
                      >
                        <div className="c-font f-13 color-white-60 flex-grow-1 max-w-130px text-truncate">
                          {field.name}
                        </div>
                        <div className="c-font f-13 w100minus120 color-white-80 text-truncate text-end">
                          {field.key}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div> */}
          </>
        )}
      </div>
    );
  }
);

export default AITopicContext;
