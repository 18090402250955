import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { CalendarBlank} from "phosphor-react";
import { _l , getDatePickerLocale } from '../../hooks/utilities';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AdminServices from "../../services/admin-services";
import moment from "moment";
import { addLeadReminder, getLeadDetail } from "../../actions/leads-actions/leads-actions";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../actions/messages";
import { GET_SPECIFIC_LEAD_DETAIL } from "../../actions/leads-actions/leads-action-types";

const LeadReminderModal = ({ reminderData, show, handleClose, isEdit = false, slug = "lead_appointments" }) => {
  const { lead_appointments } = useSelector((state) => state.leadsReducer.leadDetails);
  const dispatch = useDispatch();
  const [date, setDate] = useState(isEdit ? new Date(reminderData.date) : "");
  const [description, setDescription] = useState(isEdit ? reminderData.description : "");
  const [reminderId, setReminderId] = useState(isEdit ? reminderData.id : undefined);
  const [selectedStaffId, setSelectedStaffId] = useState(isEdit ? reminderData.staff : "");
  const [selectedStaffDetail, setSelectedStaffDetail] = useState("");
  const [notifyByEmail, setNotifyByEmail] = useState(isEdit ? reminderData.notify_by_email == 1 ? true : false : false);

  const [leadContactList, setLeadContactList] = useState([]);

  useEffect(() => {
    AdminServices.getMyRStaffList().then((res) => {
      if (res.data && res.status) {
        const formattedResponse = res.data.map((staff) => {
          return {
            value: staff.staffid,
            label: staff.fullname,
            role: 1,
            profile_image: staff.profile_image_url,
          };
        });
        setLeadContactList(formattedResponse);
      }
    });
  }, [])

  const formValidations = () => {
    if (date == "") {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_select_date"))
      );
      return false;
    } else if (selectedStaffId == "") {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_select_staff"))
      );
      return false;
    } else if (description.trim() == "") {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_description"))
      );
      return false;
    }
    return true;
  };

  const addReminder = () => {
    if (formValidations()) {
      AdminServices.addEditReminder(
        reminderId,
        reminderData.lead_id,
        selectedStaffId,
        description,
        moment(date).format("YYYY-MM-DD HH:mm:ss"),
        notifyByEmail ? "1" : "0"
      ).then((data) => {
        if (data.status == 1) {
          let leadDataList1 = lead_appointments || []
          if (isEdit) {
            leadDataList1 && leadDataList1.find(p => p.id === reminderId && (
              p.date = moment(date).format("YYYY-MM-DD HH:mm:ss"),
              p.id = reminderId,
              p.invitee = reminderData.invitee,
              p.isnotified = reminderData.isnotified,
              p.notify_by_email = notifyByEmail ? "1" : "0",
              p.description = description,
              p.staff = selectedStaffDetail.value || reminderData.staff,
              p.staff_full_name = selectedStaffDetail.label || reminderData.staff_full_name,
              p.staff_profile_url = selectedStaffDetail.profile_image || reminderData.staff_profile_url,
              true))
            dispatch({
              type: GET_SPECIFIC_LEAD_DETAIL,
              payload: { data: leadDataList1, id: reminderData.lead_id, slug },
            });
          } else {
            leadDataList1.push(data.data[0])
            dispatch({
              type: GET_SPECIFIC_LEAD_DETAIL,
              payload: { data: leadDataList1, id: reminderData.lead_id, slug },
            });
          }
          handleClose()
        }
      })
    }
  }
  const clearFields = () => {
    setDate();
    setDescription();
    setSelectedStaffId();
    setNotifyByEmail();
  }

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleClose();
          clearFields();
        }}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      // size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_l('l_set_lead_reminder')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          <div className="form-wrapper-main">
            <div className="row ">
              <Form.Group className="col-12 c-input-box pb-4 position-relative">
                <Form.Label className="input-label no-asterisk">
                  {_l("l_date_to_be_notified")}
                </Form.Label>
                <div
                  className="custom-datepicker react-datepicker-wrapper-w-100"
                  data-bs-toggle="tooltip"
                  title="Aug, 8 2022"
                >
                  <div className="d-flex align-items-center form-control  color-white-60 dropdown">
                  <CalendarBlank size={18} className="c-icons mx-0" weight="light" />
                    <DatePicker
                      className="w-100"
                      selected={date}
                      onChange={(date) => setDate(date)}
                      disabledKeyboardNavigation
                      placeholderText={_l('l_select_start_date')}
                      locale={getDatePickerLocale()}
                      dateFormat="dd/MM/yyyy HH:mm:ss"
                      selectsStart
                      showTimeSelect
                      defaultMenuIsOpen
                      calendarStartDay={1}
                    ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="col-12 c-input-box pb-4 position-relative">
                <Form.Label className="input-label no-asterisk no-asterisk">
                  {_l("l_set_reminder_to")}
                </Form.Label>
                <div className="d-flex flex-grow-1 align-items-center">
                  <Select
                    placeholder={_l('l_select_emoployee_position')}
                    className="custom-select-menu w-100"
                    classNamePrefix="react-select"
                    options={leadContactList}
                    onChange={(e) => {
                      setSelectedStaffId(e.value)
                      setSelectedStaffDetail(e)
                    }}
                    value={leadContactList.filter(s => s.value == selectedStaffId)}
                    closeMenuOnSelect={true}
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-12 c-input-box pb-4 position-relative ">
                <Form.Label className="input-label no-asterisk">
                  {_l("l_description")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder={`${_l("l_enter_details_placeholder")}`}
                  type="text"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box">
                <div className="form-check with-span">
                  <div className="d-flex align-items-center">
                    <Form.Label className="form-check-label text_wrap w-auto">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={notifyByEmail}
                        onChange={(e) => setNotifyByEmail(e.target.checked)}
                      />
                      <span>{_l('l_send_also_an_email_for_this_reminder')}</span>
                    </Form.Label>
                  </div>
                </div>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm"
            onClick={() => {
              handleClose()
              clearFields()
            }}>
            {_l('l_close')}
          </Button>
          <Button variant="primary" size="sm"
            onClick={() => addReminder()} >
            {_l('l_save')}{" "}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default LeadReminderModal