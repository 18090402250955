import React from 'react';
// import CSS from '../css/icon_main.css';

function BellIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M5.99999 12.0001V12.5001C5.99999 13.0305 6.21071 13.5392 6.58578 13.9143C6.96085 14.2894 7.46956 14.5001 7.99999 14.5001C8.53043 14.5001 9.03913 14.2894 9.41421 13.9143C9.78928 13.5392 9.99999 13.0305 9.99999 12.5001V12.0001M3.51249 6.50007C3.51166 5.90731 3.62811 5.32024 3.85513 4.77267C4.08216 4.2251 4.41527 3.72786 4.83529 3.30959C5.25531 2.89131 5.75393 2.56027 6.30243 2.33552C6.85094 2.11078 7.43849 1.99677 8.03124 2.00007C10.5062 2.01882 12.4875 4.07507 12.4875 6.55632V7.00007C12.4875 9.23757 12.9562 10.5376 13.3687 11.2501C13.4126 11.3259 13.4357 11.412 13.4357 11.4996C13.4358 11.5872 13.4129 11.6734 13.3692 11.7493C13.3255 11.8253 13.2626 11.8884 13.1869 11.9324C13.1111 11.9764 13.0251 11.9998 12.9375 12.0001H3.06249C2.97487 11.9998 2.88888 11.9764 2.81311 11.9324C2.73735 11.8884 2.67448 11.8253 2.6308 11.7493C2.58713 11.6734 2.56418 11.5872 2.56425 11.4996C2.56433 11.412 2.58743 11.3259 2.63124 11.2501C3.04374 10.5376 3.51249 9.23757 3.51249 7.00007V6.50007Z" />
</svg>


		</React.Fragment>
	)
}

export default BellIcon