import React, { useEffect, useState } from "react";
import { X } from "phosphor-react";
import CustomerServices from "../../services/customer-services";
import { _l } from "../../hooks/utilities";
import TaskTrackingListing from "../Offcanvas/TaskTrackingListing";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { mapStyle } from "../../assets/map/mapStyle";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import { DropdownItem, DropdownMenu } from "reactstrap";

const TrackingDropdown = ({data, projectId, openCheckListTracking, setOpenCheckListTracking}) => {

  const [checklistItems, setChecklistItems] = useState([]);
  const [selectedAttachment, setSelectedAttachment] = useState({});
  const [showTaskImage, setShowTaskImage] = useState(false);
  const [selectedAttachmentId, setSelectedAttachmentId] = useState(0);
  const [allAttachments, setAllAttachments] = useState([]);
  const containerStyle = {
    width: "100%",
    height: "100%",
  };
  const [markers, setMarkers] = useState([]);
  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach((position) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  useEffect(() => {
    if(openCheckListTracking){
      CustomerServices.getCheckList(data.id, projectId, false, true).then((res) => {
        if (res.status) {
          setChecklistItems(res.data);
          let latlngArr = []
          if(res.data && res.data.length){
            res.data.map((item) => {
              if(item.attachments && item.attachments.length > 0){
                setAllAttachments((allAttachments) => [...allAttachments, ...item.attachments]);
              }
              item.attachments.forEach((latlng) => {
                if (latlng.latitude && latlng.longitude) { 
                  latlngArr.push({ lat: +latlng.latitude, lng: +latlng.longitude })
                }
              })
            });
          }
          setMarkers(latlngArr);
          if (latlngArr.length > 0) { 
          }
        }
      });
    }
  }, [openCheckListTracking,JSON.stringify(data)]);

  return (
    <>
      <DropdownMenu className="w-370" container="body" aria-labelledby="TrackingDropdown">
        <div className="dropdown-header">
          <div className="d-flex align-items-center justify-content-between">
            <DropdownItem tag="div" className="no-hover p-0" toggle={true}>
              <div className="color-white-60 c-font f-12 pb-2">
              {_l("l_task")} #{data.id}
              </div>
              <div className="title-font c-font f-14 text-truncate">{data.name}</div>
            </DropdownItem>
            <DropdownItem className="p-0 no-hover w-auto" tag="div" toggle={false}>
            <a href="#/" className="right-image" data-bs-dismiss="dropdown" onClick={() => setOpenCheckListTracking(false)}>
              <X size={18} className="c-icons" weight="light" />
            </a>
            </DropdownItem>
          </div>
        </div>
        <DropdownItem tag="div" className="no-hover">
          <div className="comman-list with-after-40 p-0">
            <div className=" map-box radius_5" style={{ height: "120px" }}>
              {
                openCheckListTracking &&
                <GoogleMap
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_SECRET}
                onLoad={handleOnLoad}
                options={{
                  Zoom: true ,
                  zoomControl: true,
                  streetViewControl: false,
                  scrollwheel: false,
                  styles: mapStyle,
                }}
                mapContainerStyle={containerStyle}
                zoom={8}
              >
                {markers && markers.map((position, k) => (
                  <Marker
                    key={k}
                    position={position}
                  >
                  </Marker>
                ))}
              </GoogleMap>
              }
            </div>
          </div>
      </DropdownItem>
      <DropdownItem tag="div" className="no-hover">
          <div className="overflowscroll">
          <TaskTrackingListing checklistItems={checklistItems} selectedAttachment={selectedAttachment} setSelectedAttachment={setSelectedAttachment} setShowTaskImage={setShowTaskImage} />
          </div>
      </DropdownItem>
      </DropdownMenu>
        {showTaskImage && Object.keys(selectedAttachment).length > 0 ? 
        <CommanOffCanvas
          show={showTaskImage}
          handleClose={() => {
            setShowTaskImage(false);
          }}
          data={{
            id: selectedAttachment.id,
            taskId: data.id,
            projectId: projectId,
            attachmentDetails: selectedAttachment,
            taskDetails: data,
            allAttachments: allAttachments
          }}
          docType={"imageview"}
          setSelectedId={setSelectedAttachmentId}
          selectedTaskId={data.id}
          handleParentComponentAction={() => {
            
          }}
        /> : <></>
      }
    </>
  );
};

export default TrackingDropdown;
