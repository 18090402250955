import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import Form from 'react-bootstrap/Form';
import { Plus } from "phosphor-react";
import Button from 'react-bootstrap/Button';
import { _l } from "../../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import CategoryProviderList from "../../Modals/CategoryProviderList";
import CustomerServices from "../../../services/customer-services";
import {
    getDefaultTopics,
} from "../../../actions/customer";
import ScheduleFooter from "./ScheduleFooter";
import { showConfirmation, showMessage } from "../../../actions/messages";
import { createScheduleDetails } from "../../../actions/benchmark-actions/benchmark-actions";
import AddNewProvider from "./AddNewProvider";
import ExistingPrivoderList from "./ExistingProviderList"
import ReactTooltip from "react-tooltip";

const SelectScheduleCategory = ({ handleStep, footerHide = false, isCreateBenchmark, isEdit = false, readOnlyMode }) => {
    
    const [categoryListing, setCategoryListing] = useState([]);
    const userType = localStorage.getItem("user_type");
    const { defaultTopics, selectedProject } = useSelector((state) => state.customer);
    const benchmarkReducer = useSelector((state) => state.benchmarkReducer);
    const projectId = localStorage.getItem('selectedOffice')
    const [showAddProviderCategory, setShowAddProviderCategory] = useState(false);
    const [providerCategory, setProviderCategory] = useState(0);
    const [categoryTitle, setCategoryTitle] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [operators, setOperators] = useState([]);
    const [selectedTab, setSelectedTab] = useState(userType == "contact" ? "existing" : "new");
    const [disableExistingTab, setDisableExistingTab] = useState(false);
    const [defaultCategory, setDefaultCategory] = useState(0);
    const [selectedBenchmarkOperator, setSelectedBenchmarkOperator] = useState([]);
    const dispatch = useDispatch();

    const spaces = JSON.parse(localStorage.getItem("spaces"));
    const client_id = localStorage.getItem("client_id");
    
    useEffect(() => {
        if (defaultTopics && defaultTopics.length > 0) {
            var main_key = userType == "contact" ? "provider_task" : "proposals";
            defaultTopics.map((item) => {
                if (item.main_key == main_key) {
                    setCategoryListing(
                        item.categories.map((item1) => {
                            return {
                                value: parseInt(item1.filter_key),
                                label: item1.title,
                                is_default: item1.is_default,
                            };
                        }).filter((x) => !isNaN(x.value))
                    );
                }
            });
        }
        else if(defaultTopics == undefined)
        {
            dispatch(getDefaultTopics(selectedProject));
        }
    }, [defaultTopics]);

    useEffect(() => {
        if (benchmarkReducer.createScheduleDetail && Object.keys(benchmarkReducer.createScheduleDetail).length) {
            setProviderCategory(benchmarkReducer.createScheduleDetail.providerCategory);
            setCategoryTitle(benchmarkReducer.createScheduleDetail.categoryTitle)
            setOperators(benchmarkReducer.createScheduleDetail.operators)
            if (userType != "contact") {
                const currentSpace = spaces.find((w) => w.project_id == selectedProject);
                if(currentSpace && currentSpace.is_added_from_operator != 0 && (currentSpace.client_id == client_id))
                {
                    setSelectedTab('new')
                }
                else {
                    setSelectedTab('existing')
                }
            }
            else if (!isEdit) {
                setSelectedTab(benchmarkReducer.createScheduleDetail.step1_selectedTab);
                setDisableExistingTab(benchmarkReducer.createScheduleDetail.step1_selectedTab_disabled)
            } else {
                setSelectedTab('existing')
            }

        }
    }, [JSON.stringify(benchmarkReducer.createScheduleDetail)])

    const ScheduleFilter = [
        { value: 0, label: "Test 1" },
        { value: "", label: "Test 2" },
        { value: "", label: "Test 3" },
    ];

    const getOperatorsList = (categoryId, categoryName) => {
        if(userType == "contact")
        {
            CustomerServices.getOperatorsByCategory(projectId, categoryId, 1, 1, 1).then(
                (res) => {
                    setShowLoader(false)
    
                    if (res.status && res.data && res.data.length) {
                        setOperators(res.data);
                        setSelectedTab("existing")
    
                    } else if (res.status) {
                        // if (!isCreateBenchmark) {
                        setSelectedTab("new");
                        setDisableExistingTab(true);
                        // }
                        setOperators([]);
    
                    }
    
                    let isExisting = res.status && res.data && res.data.length
                    let scheduleDetails = {
                        providerCategory: categoryId,
                        categoryTitle: categoryName,
                        operators: res.data,
                        step1_selectedTab: isExisting ? "existing" : 'new',
                        step1_selectedTab_disabled: isExisting ? false : true
                    }
                    dispatch(createScheduleDetails(scheduleDetails))
    
                }
            );
        }
        else {
            let scheduleDetails = {
                providerCategory: categoryId,
                categoryTitle: categoryName,
                operators: [],
                step1_selectedTab: 'new',
                step1_selectedTab_disabled: false
            }
            dispatch(createScheduleDetails(scheduleDetails))
        }
    };

    const handleSave = (Tab = '') => {
        if (providerCategory == 0) {
            dispatch(
                showMessage("unsucess", _l("l_error"), _l("l_please_select_category"))
            );
            return;
        }
        // let scheduleDetails = {
        //     providerCategory: providerCategory,
        //     categoryTitle: categoryTitle,
        //     operators: operators,
        //     step1_selectedTab: selectedTab,
        //     step1_selectedTab_disabled: disableExistingTab
        // }
        // dispatch(createScheduleDetails(scheduleDetails))
        handleStep('create-schedule-type');
    }
    const getCreateScheduleDetail = (key) => {
        return benchmarkReducer && benchmarkReducer.createScheduleDetail && benchmarkReducer.createScheduleDetail[`${key}`] ? benchmarkReducer.createScheduleDetail[`${key}`] : ''
    }

    return (
        <>
            <div className={`d-flex align-items-center pb-3 ${readOnlyMode || (benchmarkReducer.createScheduleDetail && benchmarkReducer.createScheduleDetail.master_id > 0) ? 'for-disabled' : ''}`}>
                <Select
                    className="custom-select-menu flex-grow-1" classNamePrefix="react-select"
                    options={categoryListing}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    // menuPlacement="top"
                    placeholder={`${_l("l_select_category")}`}
                    onChange={(value) => {
                        if (!isEdit) {
                            if (providerCategory != value.value) {
                                setOperators([]);
                            }
                            setProviderCategory(value.value);
                            setCategoryTitle(value.label);
                            // if (userType == "operator") {
                            //     setDefaultCategory(1);
                            //     setSelectedTab("new");
                            //     if (isClientExist == 1 && localStorage.getItem('spaceClientDetails') && JSON.parse(localStorage.getItem('spaceClientDetails'))) {
                            //         setOperators(JSON.parse(localStorage.getItem('spaceClientDetails')));
                            //         setSelectedBenchmarkOperator()
                            //         setAddedClientId(JSON.parse(localStorage.getItem('spaceClientDetails'))[0]['cp_id'] ? JSON.parse(localStorage.getItem('spaceClientDetails'))[0]['cp_id'] : 0)
                            //         setSelectedBenchmarkOperator(JSON.parse(localStorage.getItem('spaceClientDetails')))
                            //         setSpaceClientAdded(true)
                            //     } else {
                            //         setSelectedBenchmarkOperator([])
                            //         setAddedClientId(0)
                            //         setSpaceClientAdded(false)
                            //     }

                            // } else {

                            setDefaultCategory(value.is_default);
                            if (providerCategory != value.value) {
                                setShowLoader(true)
                                getOperatorsList(value.value, value.label);
                            }

                            setDisableExistingTab(false);
                            setSelectedBenchmarkOperator([])
                            // }

                            // setSelectedSchedule({});

                            // setIntialProviderEmail((pre) => false);
                            // setAddInviteProvider("");
                            // setMobileNumber("");
                            // setName("");
                            // setCountryCode({ value: "+33", label: "+33" });  
                        }

                    }}
                    value={
                        providerCategory != ""
                            ? {
                                value: providerCategory,
                                label: categoryTitle,
                            }
                            : ""
                    }
                    placeHolder={`${_l('l_select_provider_category')}`}
                    isDisabled={isEdit}
                />
                {
                    userType == 'contact'
                        ?
                        <Form className="d-flex align-items-center ms-3">
                            <Form.Check
                                type="radio"
                                id={`default`}
                                label={_l('l_existing')}
                                name="schedule_radio"
                                className="pe-2 mx-2"
                                checked={selectedTab == "existing" ? true : false}
                                onChange={() => {
                                    if (!isEdit) {
                                        setSelectedTab("existing");
                                        dispatch(createScheduleDetails({
                                            ...benchmarkReducer.createScheduleDetail, ['providerCategory']: providerCategory,
                                            ['categoryTitle']: categoryTitle,
                                            ['operators']: operators,
                                            ['step1_selectedTab']: 'existing',
                                            ['step1_selectedTab_disabled']: disableExistingTab
                                        }))
                                    }

                                    // setSelectedBenchmarkOperator([])
                                    // setSelectedSchedule({});
                                }}
                                disabled={disableExistingTab || isEdit}
                            />
                            <Form.Check
                                type="radio"
                                id={`default`}
                                label={_l('l_add_new')}
                                name="schedule_radio"
                                onChange={() => {
                                    if (!isEdit) {
                                        setSelectedTab("new");
                                        setSelectedBenchmarkOperator([])
                                        dispatch(createScheduleDetails({
                                            ...benchmarkReducer.createScheduleDetail, ['providerCategory']: providerCategory,
                                            ['categoryTitle']: categoryTitle,
                                            ['operators']: operators,
                                            ['step1_selectedTab']: 'new',
                                            ['step1_selectedTab_disabled']: disableExistingTab
                                        }))
                                    }
                                    // setSelectedSchedule({});
                                }}
                                className="pe-2"
                                checked={selectedTab == "new" ? true : false}
                                disabled={isEdit}
                            />
                        </Form>
                        :
                        <React.Fragment></React.Fragment>
                }
                <Button variant="white-05" className={`${isEdit ? 'd-none' : ''} ms-3`} onClick={() => {
                    if (!isEdit) {
                        setShowAddProviderCategory(true);
                    }
                }} data-tip={`${_l("l_add_category")}`}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main">
                    <Plus size={14} weight="light" className="c-icons" />

                </Button>
                <ReactTooltip />

                {
                    showAddProviderCategory ? (
                        <CategoryProviderList
                            handleClose={() => {
                                setShowAddProviderCategory(false);
                            }}
                            projectId={projectId}
                            show={showAddProviderCategory}
                            handleAddCategory={(category) => {
                                // dispatch(updateProjectTopics({ filter_key: category.provider_id, title: category.name, custom_keys: [] }, 'provider_task'))

                                setProviderCategory(category.provider_id);
                                setCategoryTitle(category.name);
                                setCategoryListing([
                                    ...categoryListing,
                                    { value: category.provider_id, label: category.name },
                                ]);
                                dispatch(createScheduleDetails({
                                    ...benchmarkReducer.createScheduleDetail, ['providerCategory']: category.provider_id,
                                    ['categoryTitle']: category.name, ['operators']: []
                                }))

                                getOperatorsList(category.provider_id, category.name);
                                dispatch(getDefaultTopics(projectId));
                            }}
                        />

                    ) : (
                        <></>
                    )
                }

            </div>
            {
                selectedTab == 'new' && providerCategory > 0 && !isEdit ? <AddNewProvider handleStep={handleSave} isCreateBenchmark={isCreateBenchmark} setSelectedTab={setSelectedTab} selectedTab={selectedTab} isEdit={isEdit} /> : <></>
            }
            {
                selectedTab == 'existing' && providerCategory > 0 ? <ExistingPrivoderList handleStep={handleSave} isCreateBenchmark={isCreateBenchmark} isEdit={isEdit} /> : <></>

            }
            {
                providerCategory == 0 && !readOnlyMode? <ScheduleFooter selectedTab={`SelectCategory`} handleStep={handleSave} currentStep={1} isEdit={isEdit} isCreateBenchmark={isCreateBenchmark} /> : <></>
            }

        </>
    );
};

export default SelectScheduleCategory;