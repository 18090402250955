import moment from "moment";
import { dataURItoBlob, getItemId, isLocalEnvironment } from "../hooks/utilities";
import store from "../store";
import apiService from "./api-service/api-service";
import { STATIC_PLACE_ID_FOR_DEV } from "../constants/constants";

const API_URL = process.env.REACT_APP_API_URL;
const user_type = localStorage.getItem("user_type");
const selectedProject = store.getState().customer.selectedProject;
const getSpaceRooms = (schedule_id, project_id = 0) => {
  const endPoint = API_URL + "mod=space&act=get_type_of_space_per_space";
  let form = new FormData();
  if(schedule_id > 0)
  {
    form.append("schedule_id", schedule_id);
  }
  form.append("rel_id", project_id > 0 ? project_id : localStorage.getItem('selectedOffice'));
  form.append("rel_type", "project");
  return apiService(endPoint, "POST", true, form);
};

const addEditSpaceRoom = (rooms, schedule_id) => {
  const endPoint = API_URL + "mod=space&act=add_edit_type_of_space";
  let form = new FormData();
  form.append("rel_id", localStorage.getItem('selectedOffice'));
  form.append("rel_type", "project");
  form.append("project_id", localStorage.getItem('selectedOffice'));

  rooms.forEach((attr, index) => {
    if (!attr.is_default || attr.is_default == 0) {
      if (attr.title != '') {
        form.append(`type_of_space[${index}][title]`, attr.title);
        form.append(`type_of_space[${index}][quantity]`, attr.quantity);
        if (attr.id) {
          form.append(`type_of_space[${index}][id]`, attr.id);
          form.append(`type_of_space[${index}][item_id]`, attr.item_id);
        }
      }
    }
  });
  if (schedule_id) {
    form.append("schedule_id", schedule_id);
  }
  return apiService(endPoint, "POST", true, form);
}

const deleteTypeOfSpace = (space_id, schedule_id = 0) => {
  const endPoint = API_URL + "mod=space&act=remove_type_of_space";
  let form = new FormData();
  form.append("project_id", localStorage.getItem('selectedOffice'));
  form.append("space_id", space_id);
  if(schedule_id > 0)
  {
    form.append("schedule_id", schedule_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const acceptProposal = (proposal_id, benchmark_id, signature, firstName, lastName, emailAddress, startdate, is_added_by_myr, account_iban) => {
  const endPoint = API_URL + "mod=proposal&act=accept";
  let form = new FormData();
  const file = dataURItoBlob(signature);
  form.append("proposal_id", proposal_id);
  form.append("benchmark_id", benchmark_id);
  form.append("firstname", firstName);
  form.append("lastname", lastName);
  form.append("email", emailAddress);
  form.append("project_id", selectedProject);
  if(account_iban)
  {
    form.append("account_iban", account_iban);
  }
  form.append("signature", file, "signature.png");
  if(is_added_by_myr > 0)
  {
    form.append("is_added_by_myr", is_added_by_myr);
  }
  if(startdate)
  {
    form.append("startdate", startdate);
  }

  return apiService(endPoint, "POST", true, form);
};

const rejectProposal = (proposal_id, benchmark_id, is_added_by_myr) => {
  const endPoint = API_URL + "mod=proposal&act=reject";
  let form = new FormData();
  form.append("proposal_id", proposal_id);
  form.append("benchmark_id", benchmark_id);
  form.append("project_id", selectedProject);
  if(is_added_by_myr > 0)
  {
    form.append("is_added_by_myr", is_added_by_myr);
  }
  return apiService(endPoint, "POST", true, form);
};

const getEstimateUsingProposal = (proposal_id) => {
  const endPoint = API_URL + "mod=estimate&act=get_estimate_by_proposal";
  let form = new FormData();
  form.append("proposal_id", proposal_id);
  return apiService(endPoint, "POST", true, form);
};

const sendExternalProposal = (benchmark_id, invite_email) => {
  const endPoint = API_URL + "mod=benchmark&act=external_provider_invite";
  let form = new FormData();
  form.append("benchmark_id", benchmark_id);
  form.append("invite_email", invite_email);
  form.append("project_id", selectedProject);
  return apiService(endPoint, "POST", true, form);
}

const cancelExternalProposal = (benchmark_id, proposal_id) => {
  const endPoint = API_URL + "mod=benchmark&act=external_provider_remove";
  let form = new FormData();
  form.append("benchmark_id", benchmark_id);
  form.append("proposal_id", proposal_id);
  form.append("project_id", selectedProject);
  return apiService(endPoint, "POST", true, form);
}

const deleteBenchmark = (benchmark_id, project_id) => {
  const endPoint = API_URL + "mod=benchmark&act=delete_benchmark";
  let form = new FormData();
  form.append("benchmark_id", benchmark_id);
  form.append("project_id", project_id || selectedProject);
  return apiService(endPoint, "POST", true, form);
}

const updateTypeOfSpace = (schedule_id, title, quantity, id, item_id) => {
  const endPoint = API_URL + "mod=space&act=add_edit_type_of_space";
  let form = new FormData();
  form.append("rel_id", localStorage.getItem('selectedOffice'));
  form.append("rel_type", "project");
  form.append("project_id", localStorage.getItem('selectedOffice'));
  form.append(`type_of_space[0][title]`, title);
  form.append(`type_of_space[0][quantity]`, quantity);
  form.append(`type_of_space[0][id]`, id);
  form.append(`type_of_space[0][item_id]`, item_id);
  form.append("schedule_id", schedule_id);
  return apiService(endPoint, "POST", true, form);
}

const getProposalComments = (proposal_id) => {
  const endPoint = API_URL + "mod=task&act=get_proposal_comments";
  let form = new FormData();
  form.append("proposal_id", proposal_id);
  form.append("project_id", selectedProject);
  form.append("all", 1);
  return apiService(endPoint, "POST", true, form);
}

const setScheduleSlotAssign = (benchmark_id, availability, assignee, assigned_items) => {
  const endPoint = API_URL + "mod=benchmark&act=set_schedule_slot_assign";
  let form = new FormData();

  form.append("benchmark_id", benchmark_id);
  form.append("availability", JSON.stringify(availability));
  form.append("assignee", JSON.stringify(assignee));
  form.append("assigned_items", JSON.stringify(assigned_items));
  return apiService(endPoint, "POST", true, form);
}

const addEditChecklistComment = (comment, files, checklist_id, comment_id) => {
  const endPoint = API_URL + "mod=comment&act=add_comment";
  let form = new FormData();
  form.append("content", comment);
  if(files)
  {
    Array.from(files).forEach((file, file_index) => {
      form.append(`file[${file_index}]`, file);
    });
  }
  form.append("rel_id", checklist_id);
  form.append("rel_type", "schedule_item");
  if(comment_id > 0)
  {
    form.append("comment_id", comment_id);
  }
  return apiService(endPoint, "POST", true, form, 0, false, -1);
}

const deleteChecklistComment = (checklist_id, comment_id, rel_type = "schedule_item") => {
  const endPoint = API_URL + "mod=comment&act=remove_comment";
  let form = new FormData();
  form.append("rel_id", checklist_id);
  form.append("rel_type", rel_type);
  form.append("comment_id", comment_id);
  if (localStorage.getItem("is_external") && localStorage.getItem("is_external") == 1) {
    form.append(`is_external`, 1);
  }
  return apiService(endPoint, "POST", true, form, 0, false, -1);
}
const copyBenchmarkSchedule = (benchmark_id) => {
  const endPoint = API_URL + "mod=benchmark&act=copy_benchmark_schedule";
  let form = new FormData();
  form.append("benchmark_id", benchmark_id);
  return apiService(endPoint, "POST", true, form);
}

const updateTypeOfSpaceData = (room, schedule_id) => {
  const endPoint = API_URL + "mod=space&act=update_type_of_space_schedule";
  let form = new FormData();
  form.append("schedule_id", schedule_id);
  form.append("project_id", room.rel_id);
  form.append("id", getItemId(schedule_id, room.id));
  form.append("title", room.title);
  form.append("quantity", room.quantity);
  return apiService(endPoint, "POST", true, form);
}

const getFloorDetails = (project_id, all_type_of_space) => {
  const endPoint = API_URL + "mod=space&act=get_floor_details";
  let form = new FormData();
  form.append("project_id", project_id);
  if(all_type_of_space)
  {
    form.append("all_type_of_space", all_type_of_space);
  }
  return apiService(endPoint, "POST", true, form);
}

const addBulkProvidersToCategory = (project_id, category_id, provider_data,return_invitee_list, benchmark_id, proposal_send) => {
  const endPoint = API_URL + "mod=benchmark&act=add_bluk_provider_to_category";
  let form = new FormData();
  const userType = localStorage.getItem("user_type");
  
  if(userType === "staff")
  {
    const spaces = JSON.parse(localStorage.getItem("spaces"));
    let currentSpace = spaces.find(w => w.project_id == project_id);
    if (currentSpace) {
      form.append("space_client_id", currentSpace.client_id);
      form.append("space_contact_id", currentSpace.contact_id);
    }
  }

  form.append("category_id", category_id);
  form.append("project_id", project_id);

  if(benchmark_id > 0)
  {
    form.append("benchmark_id", benchmark_id);
  }
  if(proposal_send > 0)
  {
    form.append("proposal_send", proposal_send);
  }

  provider_data.forEach((provider, index) => {
    form.append(`provider_data[${index}][type_name]`, provider.name);
    form.append(`provider_data[${index}][phonenumber]`, provider.phonenumber);
    form.append(`provider_data[${index}][invitee_email]`, provider.email);
    form.append(`provider_data[${index}][company_name]`, provider.company_name);
  });
  form.append("return_invitee_list",return_invitee_list);
  return apiService(endPoint, "POST", true, form);
}

const addBenchmarkChecklists = (benchmark_id, project_id, list, floor_id, is_update, equipment_id) => {
  const endPoint = API_URL + "mod=benchmark&act=add_benchmark_checklist";

  let formattedChecklist = [];
  let file_counts = 0;
  let form = new FormData();

  list.forEach((item) => {
    
    const itemObj = {
      slot_id: isNaN(item.id) ? 0 : item.id,
      item_name: item.checklist[0].name,
      item_slot: moment(item.slot).format('HH:mm:ss'),
      room_id: item.room_id,
      days: item.days,
      frequency: item.frequency,
      priority: item.priority,
      comments: [],
      floor_id: item.floor_id,
      type_of_space_items: item.type_of_space_items,
      type_of_space_item_id: item.type_of_space_item_id,
      photo_required: item.photo_required
    };
    if(item.comments && item.comments.length)
    {
      let comments = [];
      item.comments.forEach((comment) => {
        let commentMetaData = {...comment, attachments: []};
        if(comment.attachments)
        {
          Array.from(comment.attachments).forEach((attachment) => {
            form.append(`file[${file_counts}]`, attachment);
            file_counts += 1;
            commentMetaData.attachments.push(attachment.name);
          });
        }
        comments.push(commentMetaData);
      });
      itemObj.comments = comments;
    }
    formattedChecklist.push(itemObj);
  });

  form.append("is_update", is_update);
  form.append("benchmark_id", benchmark_id);
  form.append("project_id", project_id);
  form.append("floor_id", floor_id);
  form.append("new_list", JSON.stringify(formattedChecklist));
  if(equipment_id)
  {
    form.append("equipment_id", equipment_id);
  }

  if(["/connect"].includes(window.location.pathname))
  {
    form.append("is_external", 1)
  }
  
  return apiService(endPoint, "POST", true, form);
}

const getOperatorsByCategory = (project_id, category_id, all_myr_provider,include_manager = 0, for_task_assignment) => {
  const endPoint = API_URL + "mod=benchmark&act=get_existing_and_invitee_operator_by_customer";
  let form = new FormData();

  form.append("project_id", project_id);
  if (user_type == "staff") {  
    const client_id = localStorage.getItem("virtualSpaceClientId")
    form.append("client_id", client_id);
  }
  form.append("category_id", category_id);
  form.append("include_manager", include_manager);  
  form.append("all_myr_provider", all_myr_provider);
  if(for_task_assignment > 0)
  {
    form.append("for_task_assignment", for_task_assignment);
  }
  return apiService(endPoint, "POST", true, form);
}
  
const updateTypeOfSpaceTitle = (project_id, slug, space_id, item_id, title, poiSet, floorId, skillRequireForBenchmark = 0) => {
  const endPoint = API_URL + "mod=space&act=edit_type_of_space";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("slug", slug);
  form.append("space_id", space_id);
  if (poiSet && poiSet.length > 0) { 
    form.append("poi_set", JSON.stringify(poiSet));
  }
  form.append("floor_id", floorId);
  if(item_id > 0)
  {
    form.append("item_id", item_id);
  }
  form.append("title", title);
  form.append("skill_require_for_benchmark", skillRequireForBenchmark);
  return apiService(endPoint, "POST", true, form);
}

const removeScheduleChecklistItem = (schedule_id, project_id, item_ids) => {
  const endPoint = API_URL + "mod=benchmark&act=remove_schedule_item";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("schedule_id", schedule_id);
  item_ids.forEach((w, index) => {
    form.append(`item_id[${index}]`, w);
  })
  return apiService(endPoint, "POST", true, form);
}

const getChecklistTemplate = (project_id, category_id, is_task, is_all) => {
  const endPoint = API_URL + "mod=benchmark&act=get_schedule_checklist_templates_list";
  let form = new FormData();
  if (project_id > 0) {
    form.append("project_id", project_id);
  }
  form.append("category_id", category_id);
  form.append("is_task", is_task);
  if(is_all > 0)
  {
    form.append("is_all", is_all);
  }

  return apiService(endPoint, "POST", true, form);
};

const createChecklistTemplate = (project_id, category_id, template_title, list, is_task) => {
  const endPoint = API_URL + "mod=benchmark&act=add_schedule_checklist_as_template";
  let formattedChecklist = [];
  let form = new FormData();
  list.forEach((item) => {
    const itemObj = {
      slot_id: isNaN(item.id) ? 0 : item.id,
      item_name: item.checklist[0].name,
      item_slot: moment(item.slot).format('HH:mm:ss'),
      room_id: item.room_id,
      days: item.days,
      frequency: item.frequency,
      priority: item.priority,
      comments: [],
      floor_id: item.floor_id,
      type_of_space_items: item.type_of_space_items,
      type_of_space_item_id: item.type_of_space_item_id,
      photo_required: item.photo_required
    };
    formattedChecklist.push(itemObj);
  });

  form.append("project_id", project_id);
  form.append("category_id", category_id);
  form.append("template_title", template_title);
  form.append("is_task", is_task);
  form.append("new_list", JSON.stringify(formattedChecklist));
  return apiService(endPoint, "POST", true, form);
};

const getTemplateChecklistItems = (project_id, category_id, template_id, is_task, is_all) => {
  const endPoint = API_URL + "mod=benchmark&act=get_schedule_checklist_template_detail";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("category_id", category_id);
  form.append("template_id", template_id);
  form.append("is_task", is_task);
  if(is_all > 0)
  {
    form.append("is_all", is_all);
  }
  return apiService(endPoint, "POST", true, form);
};

const getInvitedCustomerList = (project_id) => {
  const endPoint = API_URL + "mod=space&act=get_invite_client_list";
  let form = new FormData();
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const deleteCustomerInvitee = (project_id, invitee_id) => {
  const endPoint = API_URL + "mod=space&act=delete_invite_client";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("invitee_id", invitee_id);
  return apiService(endPoint, "POST", true, form);
};

const getOperatorsListByProject = (project_id) => {
  const endPoint = API_URL + "mod=benchmark&act=get_project_provider";
  let form = new FormData();
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
}

const getCategoryProviderList = (project_id, category_ids) => {
  const endPoint = API_URL + "mod=benchmark&act=get_provider_list";
  let form = new FormData();
  form.append("project_id", project_id);
  category_ids.forEach((category, index) => {
    form.append(`category_ids[${index}]`, category);
  })
  return apiService(endPoint, "POST", true, form);
}

const upgradeOperatorPlan = (
  firstname,
  lastname,
  email,
  signature,
  package_id
) => {
  const endPoint = API_URL + "mod=estimate&act=upgrade_membership_plan";
  const file = dataURItoBlob(signature);
  let form = new FormData();
  form.append("firstname", firstname);
  form.append("last_name", lastname);
  form.append("email", email);
  form.append("signature", file, "signature.png");
  form.append("package_id", package_id);
  return apiService(endPoint, "POST", true, form);
};

const removeProvider = (project_id, provider_staff_id, provider_client_id) => {
  const endPoint = API_URL + "mod=benchmark&act=remove_provider";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("provider_staff_id", provider_staff_id);
  form.append("provider_client_id", provider_client_id);
  return apiService(endPoint, "POST", true, form);
};

const inviteProvidersWithCategories = (project_id, provider_data) => {
  const endPoint = API_URL + "mod=benchmark&act=invite_provider_by_category";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("provider_data", JSON.stringify(provider_data));
  return apiService(endPoint, "POST", true, form);
};

const removeProviderCategory = (project_id, category_id) => {
  const endPoint = API_URL + "mod=benchmark&act=remove_provider_category";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("category_id", category_id);
  return apiService(endPoint, "POST", true, form);
};

const discardExternalBenchmark = (hash) => {
  const endPoint = API_URL + "mod=user&act=delete_external_user";
  let form = new FormData();
  form.append("hash", hash);
  form.append("is_external", 1);
  return apiService(endPoint, "POST", true, form);
};

const updateTaskChecklistTemplate = (list, category_id, template_title, template_id, is_task, project_id) => {
  const endPoint =
    API_URL + "mod=benchmark&act=edit_checklist_template";
  let formattedChecklist = [];
  let form = new FormData();
  list.forEach((item) => {
    const itemObj = {
      slot_id: isNaN(item.id) ? 0 : item.id,
      item_name: item.checklist[0].name,
      item_slot: moment(item.slot).format("HH:mm:ss"),
      room_id: item.room_id,
      days: item.days,
      frequency: item.frequency,
      priority: item.priority,
      comments: [],
      floor_id: item.floor_id,
      type_of_space_items: item.type_of_space_items,
      type_of_space_item_id: item.type_of_space_item_id,
      photo_required: item.photo_required,
    };
    formattedChecklist.push(itemObj);
  });

  form.append("category_id", category_id);
  form.append("template_title", template_title);
  if(template_id > 0)
  {
    form.append("template_id", template_id);
  }
  if(project_id > 0)
  {
    form.append("project_id", project_id);
  }
  form.append("is_task", is_task);
  form.append("new_list", JSON.stringify(formattedChecklist));
  return apiService(endPoint, "POST", true, form);
};

const deleteChecklistTemplate = (template_id) => {
  const endPoint = API_URL + "mod=benchmark&act=delete_checklist_template";
  let form = new FormData();
  form.append("template_id", template_id);
  return apiService(endPoint, "POST", true, form);
};

const getBasicDetailsCustomFields = (type) => {
  const endPoint = API_URL + "mod=general&act=get_custom_fields";
  let form = new FormData();
  form.append("type", type);
  return apiService(endPoint, "POST", true, form);
};

const deleteBenchmarkUnitItem = (project_id, benchmark_id, unit_task_id) => {
  const endPoint = API_URL + "mod=benchmark&act=delete_benchmark_unit_task";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("benchmark_id", benchmark_id);
  form.append("unit_task_id", unit_task_id);
  return apiService(endPoint, "POST", true, form);
};

const addBenchmarkUnitItems = (project_id, benchmark_id, unit_tasks) => {
  const endPoint = API_URL + "mod=benchmark&act=add_edit_benchmark_unit_task";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("benchmark_id", benchmark_id);
  form.append("unit_task", JSON.stringify(unit_tasks.map((unit) => {
    const frequencyArray = ["monthly", "quarterly", "half_yearly", "yearly"];
    unit.name = unit.description;
    Object.keys(unit).forEach((key) => {
      if(frequencyArray.includes(key) && !unit[key])
      {
        delete unit[key];
      }
    })
    return unit;
  })));
  return apiService(endPoint, "POST", true, form);
};

const downloadPDP = (schedule_id, project_id, doc_type) => {
  const endPoint = API_URL + "mod=document&act=get_schedule_pdf";
  let form = new FormData();
  form.append("schedule_id", schedule_id);
  form.append("project_id", project_id);
  form.append("doc_type", doc_type);
  return apiService(endPoint, "POST", true, form);
};

const setBenchmarkGroup = (project_ids, group_id) => {
  const endPoint = API_URL + "mod=dashboard&act=add_edit_benchmark_group";
  let form = new FormData();
  if(project_ids && project_ids.length)
  {
    project_ids.forEach((project, index) => {
      form.append(`project_ids[${index}]`, project);
    });
  }
  if(!isNaN(Number(group_id)) && Number(group_id))
  {
    form.append("group_id", group_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const getBenchmarkGroupInfo = (group_id) => {
  const endPoint = API_URL + "mod=dashboard&act=get_group_list";
  let form = new FormData();
  if(group_id && group_id > 0)
  {
    form.append("group_id", group_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const generateDashboardBenchmark = (schedule_ids) => {
  const endPoint = API_URL + "mod=dashboard&act=send_proposal";
  let form = new FormData();
  if(schedule_ids && schedule_ids.length)
  {
    schedule_ids.forEach((schedule, index) => {
      form.append(`schedule_ids[${index}]`, schedule);
    });
  }
  return apiService(endPoint, "POST", true, form);
};

const createSolarBenchmark = (benchmarkData) => {
  const endPoint = API_URL + "mod=user&act=add_user_with_hirihng_benchmark";
  let form = new FormData();
  if(benchmarkData.image)
  {
    const file = dataURItoBlob(benchmarkData.image);
    form.append('file', file, "map.jpeg");
  }
  if(isLocalEnvironment())
  {
    benchmarkData = {...benchmarkData, address: "Avenue De France, Paris, France", region_id: "158", region_name: "Île-de-France", place_id: STATIC_PLACE_ID_FOR_DEV, no_of_plates: 20, plate_capacity: 375, electricity_production: 6500, total_savings: 12000, aids_financial: 5500};
  }
  if (benchmarkData.superficy == "") {
    benchmarkData = {...benchmarkData, superficy: 1000}
  }
  if (benchmarkData.number_of_people == "") {
    benchmarkData = {...benchmarkData, number_of_people: 1}
  }

  if(benchmarkData.is_invite && benchmarkData.is_invite > 0)
  {
    form.append(`is_invite`, 1);
  }
  else {
    form.append(`password`, benchmarkData.password);
  }

  if(benchmarkData.houseImages && Array.from(benchmarkData.houseImages).length)
  {
    Array.from(benchmarkData.houseImages).forEach((attachment, i) => {
      form.append(`benchmark_attachments[${i}]`, attachment);
    });
  }

  form.append(`monthly_bill`, benchmarkData.monthly_bill);
  form.append(`number_of_batteries`, benchmarkData.number_of_batteries);
  form.append(`address`, benchmarkData.address);
  form.append(`region_id`, benchmarkData.region_id);
  form.append(`region_name`, benchmarkData.region_name);
  form.append(`place_id`, benchmarkData.place_id);
  form.append(`no_of_plates`, benchmarkData.no_of_plates);
  form.append(`plate_capacity`, benchmarkData.plate_capacity);
  form.append(`electricity_production`, parseInt(benchmarkData.electricity_production));
  form.append(`savings`, benchmarkData.total_savings);
  form.append(`aids_financial`, benchmarkData.aids_financial);
  form.append(`lat`, "");
  form.append(`lng`, "");
  form.append(`superfice`, benchmarkData.superficy);
  form.append(`no_of_hiring`, benchmarkData.number_of_people);
  form.append(`start_date`, moment(benchmarkData.startDate ? benchmarkData.startDate :  new Date() ).format("YYYY-MM-DD HH:mm:ss"));
  form.append(`budget`, benchmarkData.budget);
  form.append(`name`, benchmarkData.fullName);
  form.append(`phonenumber`, benchmarkData.phonenumber);
  form.append(`email`, benchmarkData.email);
  form.append(`is_external`, 1);
  form.append(`air_conditioner`, benchmarkData.air_conditioner);
  form.append(`garage`, benchmarkData.garage);
  form.append(`back_yard`, benchmarkData.back_yard);
  form.append(`electronic_gadgets`, benchmarkData.electronic_gadgets);

  return apiService(endPoint, "POST", true, form);
};

const rejectSolarProposal = (project_id, benchmark_id, proposal_id) => {
  const endPoint = API_URL + "mod=proposal&act=reject";
  let form = new FormData();
  form.append(`project_id`, project_id);
  form.append(`benchmark_id`, benchmark_id);
  form.append(`proposal_id`, proposal_id);
  return apiService(endPoint, "POST", true, form);
};

const sendStripeTokenToServer = (stripe_token, amount) => {
  const endPoint = API_URL + "mod=solar&act=stripe_payment";
  let form = new FormData();
  form.append(`stripe_token`, stripe_token);
  form.append(`amount`, amount);
  if (localStorage.getItem("is_external") && localStorage.getItem("is_external") == 1) {
    form.append(`is_external`, 1);
    
  }
  return apiService(endPoint, "POST", true, form);
};

const createSolarEstimate = (project_id, benchmark_id, proposal_id, acceptance_firstname, acceptance_lastname, acceptance_email, amount, startdate, signature) => {
  const endPoint = API_URL + "mod=solar&act=create_estimate";
  let form = new FormData();
  form.append(`project_id`, project_id);
  form.append(`benchmark_id`, benchmark_id);
  form.append(`proposal_id`, proposal_id);
  form.append(`acceptance_firstname`, acceptance_firstname);
  form.append(`acceptance_lastname`, acceptance_lastname);
  form.append(`acceptance_email`, acceptance_email);
  // form.append(`payment_intent`, JSON.stringify(payment_intent));
  form.append(`amount`, amount);
  form.append(`startdate`, moment(startdate).format("YYYY-MM-DD"));
  const file = dataURItoBlob(signature);
  form.append("signature", file, "signature.png");
  form.append("signature_solar", file, "signature_solar.png");
  if (localStorage.getItem("is_external") && localStorage.getItem("is_external") == 1) {
    form.append(`is_external`, 1);
    
  }
  return apiService(endPoint, "POST", true, form);
};

const updateSolarProposalAmount = (proposal_items, garantie, maintenance, terms, schedule_id, proposal_id, project_id, amount, no_of_plates, files, screenshot, panelCapacity, operatorPaymentMethod, document_value =[]) => {
  const endPoint = API_URL + "mod=proposal&act=update_solar_proposal";
  let form = new FormData();
  form.append(`garantie`, garantie);
  form.append(`terms`, terms);
  form.append(`maintenance`, maintenance);
  form.append(`schedule_id`, schedule_id);
  form.append(`project_id`, project_id);
  form.append(`amount`, amount);
  form.append(`no_of_plates`, no_of_plates);
  form.append(`proposal_id`, proposal_id);
  form.append(`preferred_payment_mode`, operatorPaymentMethod);
  if(panelCapacity > 0)
  {
    form.append(`plate_capacity`, panelCapacity);
  }
  proposal_items.forEach((item) => {
    item.name = item.title;
  })
  form.append(`proposal_items`, JSON.stringify(proposal_items));
  if(files && Object.keys(files))
  {
    Object.keys(files).forEach((key) => {
      if(key === "contract")
      {
        form.append("file", files[key].file);
      }
      else {
        form.append(key, files[key].file);
      }
    });
  }
  
  if(screenshot)
  {
    const file = dataURItoBlob(screenshot);
    form.append("screenshot", file, "screenshot.jpeg");
    form.append("is_preview", 1);
  }
  if (document_value && document_value.length > 0) {
    form.append("document_value", JSON.stringify(document_value));
  }
  
  return apiService(endPoint, "POST", true, form);
};

const saveSolarProviderTermsTemplate = (proposal_id, garantie, maintenance, terms, project_id) => {
  const endPoint = API_URL + "mod=proposal&act=proposal_detail_save_as_template";
  let form = new FormData();
  form.append(`proposal_id`, proposal_id);
  form.append(`garantie`, garantie);
  form.append(`maintenance`, maintenance);
  form.append(`terms`, terms);
  form.append(`project_id`, project_id);
  return apiService(endPoint, "POST", true, form);
};

const deleteSolarProposalItem = (proposal_id, proposal_item_id, project_id, benchmark_id) => {
  const endPoint = API_URL + "mod=proposal&act=delete_proposal_items";
  let form = new FormData();
  form.append(`proposal_id`, proposal_id);
  form.append(`proposal_item_id`, proposal_item_id);
  form.append(`project_id`, project_id);
  form.append("benchmark_id", benchmark_id);
  return apiService(endPoint, "POST", true, form);
};

const externalProposalList = (hash_id) => {
  const endPoint = API_URL + "mod=solar&act=get_external_proposal";
  let form = new FormData();
  form.append(`hash_id`, hash_id);
  form.append(`is_external`, 1);
  return apiService(endPoint, "POST", true, form);
};

const saveOperatorTermsAndConditions = (proposal_id, garantie, maintenance, terms, preferred_payment_mode) => {
  const endPoint = API_URL + "mod=proposal&act=proposal_detail_save_as_template";
  let form = new FormData();
  form.append(`proposal_id`, proposal_id);
  form.append(`garantie`, garantie);
  form.append(`maintenance`, maintenance);
  form.append(`terms`, terms);
  form.append(`preferred_payment_mode`,  preferred_payment_mode)
  return apiService(endPoint, "POST", true, form);
};

const purchaseSolarProviderPlan = (stripeToken) => {
  const endPoint = API_URL + "mod=solar&act=create_provider_estimate";
  let form = new FormData();
  form.append(`stripe_token`, stripeToken);
  return apiService(endPoint, "POST", true, form);
};

const signatureAfterPayment = (project_id, contact_role, staff_id, contact_id, client_id, benchmark_id, customer_contract, signature) => {
  const endPoint = API_URL + "mod=solar&act=add_signature_after_payment";
  let form = new FormData();
  form.append(`project_id`, project_id);
  form.append(`contact_role`, contact_role);
  form.append(`staff_id`, staff_id);
  form.append(`contact_id`, contact_id);
  form.append(`client_id`, client_id);
  form.append(`benchmark_id`, benchmark_id);
  if(customer_contract && Object.keys(customer_contract))
  {
    Object.keys(customer_contract).forEach((key) => { 
 
        form.append("customer_contract", customer_contract[0]);
     
    });
  }  
  if (signature && signature != "") {
    const file = dataURItoBlob(signature);
    form.append("signature", file, "signatureAfterPayment.png");
  }
  if (localStorage.getItem("is_external") && localStorage.getItem("is_external") == 1) {
    form.append(`is_external`, 1);
    
  }
  return apiService(endPoint, "POST", true, form);
};

const getProposalPDF = (proposal_id) => {
  const endPoint = API_URL + "mod=document&act=proposal_contract_pdf";
  let form = new FormData();
  form.append(`proposal_id`, proposal_id);
  if (localStorage.getItem("is_external") && localStorage.getItem("is_external") == 1) {
    form.append(`is_external`, 1);
    
  }
  return apiService(endPoint, "POST", true, form);
};

const getProviderCountFromRegion = (region_id, region_name) => {
  const endPoint = API_URL + "mod=user&act=get_total_provider_by_region";
  let form = new FormData();
  form.append(`region_id`, region_id);
  form.append(`region_name`, region_name);
  form.append(`is_external`, 1);
  return apiService(endPoint, "POST", true, form);
};

const getProviderPricingTemplate = () => {
  const endPoint = API_URL + "mod=solar&act=get_catlog_default_kw";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
};

const getProviderCatalogTemplate = () => {
  const endPoint = API_URL + "mod=solar&act=get_catlog_default_items";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
};

const getCatalogDetail = (catalogId) => {
  const endPoint = API_URL + "mod=solar&act=get_provider_catlog";
  let form = new FormData();
  form.append(`k_w_id`, catalogId);
  return apiService(endPoint, "POST", true, form);
};

const updatePriceCatalog = (priceData, kwId) => {
  const endPoint = API_URL + "mod=solar&act=add_catlog_item";
  let form = new FormData();
  priceData.forEach((d, index) => {
    d.k_w_id = kwId;
    d.item_name = d.name;
    d.tax = !isNaN(Number(d.tax)) ? Number(d.tax) : 0;
    if(d.id <= 0 && d.attachment && d.attachment.file)
    {
      form.append(`file[${index}]`, d.attachment.file);
    }
  })
  form.append(`catlog_items`, JSON.stringify(priceData));
  return apiService(endPoint, "POST", true, form);
};

const saveCustomPriceCatalog = (kwId, powerCapacity) => {
  const endPoint = API_URL + "mod=solar&act=add_custom_catlog";
  let form = new FormData();
  form.append(`k_w_id`, kwId);
  form.append(`k_w`, powerCapacity);
  return apiService(endPoint, "POST", true, form);
};

const deleteCustomPriceCatalog = (kwId) => {
  const endPoint = API_URL + "mod=solar&act=delete_provider_catalog";
  let form = new FormData();
  form.append(`k_w_id`, kwId);
  return apiService(endPoint, "POST", true, form);
};

const saveCatalogAttachment = (item_id, file) => {
  const endPoint = API_URL + "mod=solar&act=add_catalog_item_attachment";
  let form = new FormData();
  form.append(`item_id`, item_id);
  form.append(`file`, file);
  return apiService(endPoint, "POST", true, form);
};

const updateSolarBenchmarkDetails = (benchmark_id, project_id , start_date = "", title = "", proposal_id , is_plate_detail = false,  benchmarkData = {}) => {
  const endPoint = API_URL + "mod=benchmark&act=edit_solar_benchmark";
  let form = new FormData();
  form.append(`benchmark_id`, benchmark_id);
  form.append(`proposal_id`, proposal_id);
  form.append(`project_id`, project_id);

  if (start_date && start_date !== "") {
    form.append(`start_date`, start_date);
  }

  if (title && title.trim() !== "") {
    form.append(`title`, title);
  }
  if (is_plate_detail && is_plate_detail == true ) {

    form.append(`is_plate_detail`, 1);
    form.append(`address`, benchmarkData.address);
    form.append(`region_id`, benchmarkData.region_id);
    form.append(`region_name`, benchmarkData.region_name);
    form.append(`place_id`, benchmarkData.place_id);
    form.append(`lat`, benchmarkData.lat);
    form.append(`lng`, benchmarkData.lng);
    form.append(`no_of_plates`, benchmarkData.no_of_plates);
    form.append(`electricity_production`, parseInt(benchmarkData.electricity_production));
    form.append(`savings`, benchmarkData.savings);
    form.append(`monthly_bill`, benchmarkData.monthly_bill);
    form.append(`aids_financial`, benchmarkData.aids_financial);
    form.append(`plate_capacity`, benchmarkData.plate_capacity);
    form.append(`number_of_batteries`, benchmarkData.number_of_batteries);
    form.append(`air_conditioner`, benchmarkData.air_conditioner);
    form.append(`garage`, benchmarkData.garage);
    form.append(`back_yard`, benchmarkData.back_yard);
    form.append(`electronic_gadgets`, benchmarkData.electronic_gadgets);  
    if(benchmarkData.image)
      {
        const file = dataURItoBlob(benchmarkData.image);
        form.append('file', file, "map.jpeg");
      }  
  }
  return apiService(endPoint, "POST", true, form);
};

const addSolarReminders = (rel_type,rel_id, notify_by_email , notify_staff_id = "", description = "",reminder_date, schedule_id) => {
  const endPoint = API_URL + "mod=benchmark&act=add_solar_reminder";
  let form = new FormData();
  form.append(`rel_type`, rel_type);
  form.append(`rel_id`, rel_id);
  form.append(`notify_by_email`, notify_by_email);
  form.append(`notify_staff_id`, notify_staff_id);
  form.append(`schedule_id`, schedule_id);
  form.append(`description`, description);
  form.append(`reminder_date`, moment(reminder_date).format('YYYY-MM-DD HH:mm:ss'));
  return apiService(endPoint, "POST", true, form);
};

const getSolarReminders = (rel_type,rel_id) => {
  const endPoint = API_URL + "mod=benchmark&act=get_solar_reminder";
  let form = new FormData();
  form.append(`rel_type`, rel_type);
  form.append(`rel_id`, rel_id);
  return apiService(endPoint, "POST", true, form);
};

const readSolarReminders = (rel_type,rel_id = 0,reminder_id = 0) => {
  const endPoint = API_URL + "mod=benchmark&act=read_solar_reminder";
  let form = new FormData();
  form.append(`rel_type`, rel_type);
  if (rel_id > 0) {
    form.append(`rel_id`, rel_id);
  }
  if (reminder_id > 0) {
    form.append(`reminder_id`, reminder_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const getInvitedCustomerDetail = (benchmark_id, project_id) => {
  const endPoint = API_URL + "mod=benchmark&act=get_invited_customer_detail";
  let form = new FormData();

  form.append(`benchmark_id`, benchmark_id);
  form.append(`project_id`, project_id);

  return apiService(endPoint, "POST", true, form);
};

const editInvitedCustomerDetail = (benchmark_id, project_id, first_name, last_name, phonenumber, email) => {
  const endPoint = API_URL + "mod=benchmark&act=edit_invited_customer_detail";
  let form = new FormData();

  form.append(`benchmark_id`, benchmark_id);
  form.append(`project_id`, project_id);
  form.append(`first_name`, first_name);
  form.append(`last_name`, last_name);
  form.append(`phonenumber`, phonenumber);
  form.append(`email`, email);

  return apiService(endPoint, "POST", true, form);
};

const getBechmarkDetailsAndLogin = (hash) => {
  const endPoint = API_URL + "mod=benchmark&act=get_benchmark_details_and_login";
  let form = new FormData();
  form.append(`hash`, hash);
  form.append(`is_external`, 1);
  return apiService(endPoint, "POST", true, form);
};

const sentGeneralMailOFSolar = (invite_emails = [], project_id, schedule_id,subject,content, proposal_id) => {
  const endPoint = API_URL + "mod=solar&act=solar_general_mail";
  let form = new FormData();

  form.append(`project_id`, project_id);
  form.append(`schedule_id`, schedule_id);
  form.append(`invite_emails`, JSON.stringify(invite_emails));
  form.append(`subject`, subject);
  form.append(`proposal_id`, proposal_id);
  form.append(`description`, JSON.stringify(content));
  return apiService(endPoint, "POST", true, form);
};

const getExternalBenchMarkDetail = (hash) => {
  const endPoint = API_URL + "mod=benchmark&act=get_benchmark_details";
  let form = new FormData();
  form.append(`hash`, hash);
  form.append(`is_external`, 1);
  return apiService(endPoint, "POST", true, form);
}

const addSolarProposalBrands = (name, description, tax, catalog_type, rate, files, brand_id) => {
  const endPoint = API_URL + "mod=solar&act=add_edit_solar_proposal_brands";
  let form = new FormData();

  form.append(`name`, name);
  form.append(`description`, description);
  form.append(`tax`, tax);
  form.append(`catalog_type`, catalog_type);
  form.append(`rate`, rate);
  files.forEach((file, i) => { 
    form.append(`file[${i}]`,file.image);
  });
  if (brand_id > 0) {
    form.append(`brand_id`, brand_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const getSolarProposalBrands = (catalog_type) => {
  const endPoint = API_URL + "mod=solar&act=get_solar_proposal_brands";
  let form = new FormData();

  form.append(`catalog_type`, catalog_type);
  
  return apiService(endPoint, "POST", true, form);
};

const deleteCatelogItemBrand = (brand_id) => {
  const endPoint = API_URL + "mod=solar&act=delete_solar_proposal_brands";
  let form = new FormData();

  form.append(`brand_id`, brand_id);
  
  return apiService(endPoint, "POST", true, form);
};

const deleteCatelogItemDocument = (brand_id, file_id) => {
  const endPoint = API_URL + "mod=solar&act=delete_brand_document";
  let form = new FormData();

  form.append(`brand_id`, brand_id);
  form.append(`file_id`, file_id);
  
  return apiService(endPoint, "POST", true, form);
};

const setInstallationDateForMandate = (benchmark_id , date) => {
  const endPoint = API_URL + "mod=benchmark&act=update_installation_date";
  let form = new FormData();

  form.append(`benchmark_id`, benchmark_id);
  form.append(`is_external`, 1);
  form.append(`date`, moment(date).format("YYYY-MM-DD HH:mm:ss"));
  
  
  return apiService(endPoint, "POST", true, form);
};

const getNoteProposal = (note_rel_type , note_rel_id) => {
  const endPoint = API_URL + "mod=solar&act=get_note";
  let form = new FormData();

  form.append(`note_rel_type`, note_rel_type);
  form.append(`note_rel_id`, note_rel_id);
  
  return apiService(endPoint, "POST", true, form);
};

const addEditNoteProposal = (description, note_rel_type , note_rel_id, note_id) => {
  const endPoint = API_URL + "mod=solar&act=add_notes";
  let form = new FormData();

  form.append(`note_rel_type`, note_rel_type);
  form.append(`note_rel_id`, note_rel_id);
  form.append(`description`, description);
  if (note_id > 0) {
    form.append(`note_id`, note_id);
  }
  
  return apiService(endPoint, "POST", true, form);
};

const deleteNoteProposal = (note_id) => {
  const endPoint = API_URL + "mod=solar&act=delete_notes";
  let form = new FormData();
  form.append(`note_id`, note_id);
  
  return apiService(endPoint, "POST", true, form);
};

const activityLogProposal = (proposal_id, benchmark_id) => {
  const endPoint = API_URL + "mod=proposal&act=get_benchmark_proposal_log";
  let form = new FormData();
  form.append(`proposal_id`, proposal_id);
  form.append(`benchmark_id`, benchmark_id);
  
  return apiService(endPoint, "POST", true, form);
};

const emailTrackingProposal = (proposal_id, id) => {
  const endPoint = API_URL + "mod=solar&act=get_view_tracking";
  let form = new FormData();
  form.append(`proposal_id`, proposal_id);
  form.append(`id`, id);
  
  return apiService(endPoint, "POST", true, form);
};

const emailSentProposal = (proposal_id, benchmark_id) => {
  const endPoint = API_URL + "mod=solar&act=get_track_email_list";
  let form = new FormData();
  form.append(`proposal_id`, proposal_id);
  form.append(`benchmark_id`, benchmark_id);
  
  return apiService(endPoint, "POST", true, form);
};

export default {
  addEditSpaceRoom,
  getSpaceRooms,
  deleteTypeOfSpace,
  acceptProposal,
  getEstimateUsingProposal,
  rejectProposal,
  sendExternalProposal,
  cancelExternalProposal,
  deleteBenchmark,
  updateTypeOfSpace,
  getProposalComments,
  setScheduleSlotAssign,
  addEditChecklistComment,
  deleteChecklistComment,
  copyBenchmarkSchedule,
  updateTypeOfSpaceData,
  getFloorDetails,
  addBulkProvidersToCategory,
  addBenchmarkChecklists,
  getOperatorsByCategory,
  updateTypeOfSpaceTitle,
  removeScheduleChecklistItem,
  getChecklistTemplate,
  createChecklistTemplate,
  getTemplateChecklistItems,
  getInvitedCustomerList,
  deleteCustomerInvitee,
  getOperatorsListByProject,
  getCategoryProviderList,
  upgradeOperatorPlan,
  removeProvider,
  inviteProvidersWithCategories,
  removeProviderCategory,
  discardExternalBenchmark,
  updateTaskChecklistTemplate,
  deleteChecklistTemplate,
  getBasicDetailsCustomFields,
  deleteBenchmarkUnitItem,
  addBenchmarkUnitItems,
  downloadPDP,
  setBenchmarkGroup,
  getBenchmarkGroupInfo,
  generateDashboardBenchmark,
  createSolarBenchmark,
  rejectSolarProposal,
  sendStripeTokenToServer,
  createSolarEstimate,
  updateSolarProposalAmount,
  saveSolarProviderTermsTemplate,
  deleteSolarProposalItem,
  externalProposalList,
  saveOperatorTermsAndConditions,
  purchaseSolarProviderPlan,
  signatureAfterPayment,
  getProposalPDF,
  getProviderCountFromRegion,
  getProviderPricingTemplate,
  getProviderCatalogTemplate,
  getCatalogDetail,
  updatePriceCatalog,
  saveCustomPriceCatalog,
  deleteCustomPriceCatalog,
  saveCatalogAttachment,
  updateSolarBenchmarkDetails,
  addSolarReminders,
  getSolarReminders,
  readSolarReminders,
  getInvitedCustomerDetail,
  editInvitedCustomerDetail,
  getBechmarkDetailsAndLogin,
  sentGeneralMailOFSolar,
  getExternalBenchMarkDetail,
  addSolarProposalBrands,
  getSolarProposalBrands,
  deleteCatelogItemBrand,
  deleteCatelogItemDocument,
  setInstallationDateForMandate,
  getNoteProposal,
  addEditNoteProposal,
  deleteNoteProposal,
  activityLogProposal,
  emailTrackingProposal,
  emailSentProposal
}