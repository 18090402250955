import React from 'react';
// import CSS from '../css/icon_main.css';

function SetCoordinates(props) {
    const height = (props.height) ? props.height : "13";
    const width = (props.width) ? props.width : "16";
    const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
    const CommanHW = (props.className) ? props.className : "";
    // const fill 		= (props.fill) ? props.fill : "#ffffff";

    return (
        <React.Fragment>


<svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<g clip-path="url(#clip0_5210_12765)">
<path d="M10 11.4783H4.52174M10 11.4783L8.43478 9.91304M10 11.4783L8.43478 13.0435M4.52174 11.4783V6M4.52174 11.4783L1 15M4.52174 6L2.95652 7.56522M4.52174 6L6.08696 7.56522M1 15V12.6522M1 15H3.34783" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 14H14C14.1326 14 14.2598 13.9473 14.3536 13.8536C14.4473 13.7598 14.5 13.6326 14.5 13.5V5.5L11 2H5C4.86739 2 4.74021 2.05268 4.64645 2.14645C4.55268 2.24021 4.5 2.36739 4.5 2.5V4" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11 2V5.5H14.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_5210_12765">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>


            

        </React.Fragment>
    )
}


export default SetCoordinates