import React from 'react';
// import CSS from '../css/icon_main.css';

function NoActivityLog(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

			<svg width="78" height="74" viewBox="0 0 78 74" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons h-100 w-100 ${CommanHW}`}>
				<path d="M6.81345 48.5687C8.23845 52.5884 10.3191 56.3446 12.9711 59.6847C24.1275 73.7987 45.1368 77.2346 60.3085 67.375C77.8953 55.9423 82.2441 32.8749 70.0401 15.7326C58.5872 -0.328322 35.2604 -3.84836 19.4824 8.10304C13.2675 12.8189 8.25855 19.8186 6.74609 26.0907" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M19.2287 21.7621C16.4171 23.2757 13.5842 24.7488 10.73 26.1816C8.44613 27.3268 6.13532 26.5857 5.06414 24.2985C3.66285 21.3039 2.36933 18.2656 1.08594 15.2036" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M51.5084 46.8439C48.1971 46.8809 44.8859 46.9146 41.5781 46.8439C36.3468 46.7192 33.0558 43.3339 32.9715 38.0689C32.9345 35.5998 32.9715 33.1307 32.9715 30.6583C32.9715 28.1319 32.948 25.6055 32.9715 23.0825" strokeLinecap="round" strokeLinejoin="round" />
			</svg>


		</React.Fragment>
	)
}

export default NoActivityLog