import React from 'react';
// import CSS from '../css/icon_main.css';

function UserIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
				<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
					<path d="M8.39062 10C10.5998 10 12.3906 8.20914 12.3906 6C12.3906 3.79086 10.5998 2 8.39062 2C6.18149 2 4.39062 3.79086 4.39062 6C4.39062 8.20914 6.18149 10 8.39062 10ZM8.39062 10C7.16176 10 5.95455 10.3229 4.89035 10.9374C3.82615 11.5519 2.94246 12.4357 2.32812 13.5M8.39062 10C9.61949 10 10.8267 10.3229 11.8909 10.9374C12.9551 11.5519 13.8388 12.4357 14.4531 13.5" />
				</svg>


		</React.Fragment>
	)
}

export default UserIcon