import React from 'react';
// import CSS from '../css/icon_main.css';

function GraphIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"  className={`comman-icons ${CommanHW}`}>
<path d="M7.39375 6.63125L6.60625 4.86875M11.075 6.975L9.425 7.525M11.3188 10.5813L9.18125 8.91875M6.88125 8.99375L4.61875 11.0063M9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8ZM7.5 3.5C7.5 4.32843 6.82843 5 6 5C5.17157 5 4.5 4.32843 4.5 3.5C4.5 2.67157 5.17157 2 6 2C6.82843 2 7.5 2.67157 7.5 3.5ZM14 6.5C14 7.32843 13.3284 8 12.5 8C11.6716 8 11 7.32843 11 6.5C11 5.67157 11.6716 5 12.5 5C13.3284 5 14 5.67157 14 6.5ZM14 11.5C14 12.3284 13.3284 13 12.5 13C11.6716 13 11 12.3284 11 11.5C11 10.6716 11.6716 10 12.5 10C13.3284 10 14 10.6716 14 11.5ZM5 12C5 12.8284 4.32843 13.5 3.5 13.5C2.67157 13.5 2 12.8284 2 12C2 11.1716 2.67157 10.5 3.5 10.5C4.32843 10.5 5 11.1716 5 12Z" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

		</React.Fragment>
	)
}

export default GraphIcon