import React from 'react';
// import CSS from '../css/icon_main.css';

function CoworKingFlexOfficeIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.8" clip-path="url(#clip0_10550_414738)">
<path d="M5.84561 6.23774V8.78813M16.2296 6.23774V8.78813M7.0447 16.9369V21.7066M8.36977 21.7309H5.71963M14.9823 16.9125V21.6823M16.3074 21.7066H13.6572M1.77392 11.5936V21.7309M20.2531 11.5936V21.7309M0.660156 8.78813H21.3414V11.5837H0.660156V8.78813ZM2.06433 1.01855H9.62689V6.23598H2.06433V1.01855ZM12.4483 1.01855H20.0108V6.23598H12.4483V1.01855ZM9.12544 16.9356H4.96392C4.64965 16.9356 4.39487 16.6808 4.39487 16.3666V15.5693C4.39487 14.7835 5.03186 14.1465 5.81767 14.1465H8.27169C9.05749 14.1465 9.69449 14.7835 9.69449 15.5693V16.3665C9.69449 16.6808 9.43975 16.9356 9.12544 16.9356ZM17.063 16.9113H12.9015C12.5873 16.9113 12.3325 16.6565 12.3325 16.3422V15.545C12.3325 14.7592 12.9695 14.1222 13.7553 14.1222H16.2093C16.9951 14.1222 17.6321 14.7592 17.6321 15.545V16.3422C17.6321 16.6565 17.3774 16.9113 17.063 16.9113Z" stroke="white" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_10550_414738">
<rect width="22" height="22" fill="white" transform="translate(0 0.375)"/>
</clipPath>
</defs>
</svg>


		</React.Fragment>
	)
}

export default CoworKingFlexOfficeIcon