import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { _l } from "../../../hooks/utilities";
import React, { useEffect, useRef, useState } from "react";

 const CardDetails = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  const [startDate, setStartDate] = useState(new Date());
 return (
    <div>
        <div className="row">
            <Form.Group className="col-12 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                {_l("l_iban_label")}
                </Form.Label>
                <Form.Control
                    placeholder={`${_l("l_iban_placeholder")}`}
                    type="text"
                    name=""
                    />
            </Form.Group>
            <Form.Group className="col-12 c-input-box position-relative  mb-3">
                <Form.Label className="input-label no-asterisk ">
                {_l("l_email")} 
                </Form.Label>
                <Form.Control
                    placeholder={`${_l("l_email_placeholder")}`}
                    type="text"
                    name=""
                    />
            </Form.Group>
            <Form.Group className="col-12 c-input-box position-relative">
                <Form.Label className="input-label no-asterisk ">
                {_l("l_full_name_label")} 
                </Form.Label>
                <Form.Control
                    placeholder={`${_l("l_full_name_placeholder")}`}
                    type="text"
                    name=""
                    />
            </Form.Group>
        </div>
    </div>
  );
 };
 export default CardDetails;