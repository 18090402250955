import AdminServices from "../../services/admin-services";
import CustomerServices from "../../services/customer-services";
import {
  GET_LEAD_LIST,
  GET_SPECIFIC_LEAD_DETAIL,
  HOT_LEAD_SET_UNSET,
  CHANGE_LEAD_STATUS,
  PIN_UNPIN_LEAD,
  ADD_LEAD_ATTACHMENTS,
  REMOVE_LEAD_ATTACHMENTS,
  ADD_LEAD_REMINDER,
  ADD_EDIT_LEAD_NOTE,
  ADD_LEAD_COMMENT,
  DELETE_LEAD_NOTE,
  GET_LEAD_ACTIVITIES,
  LEAD_BULK_ACTION,
  GET_LEAD_NOTES_LIST,
  SET_LEAD_SOURCES,
  SET_MYR_STAFF,
  EDIT_LEAD_ACTIVITY,
  DELETE_LEAD_ACTIVITY,
  LEAD_COMPANY_DATA
} from "./leads-action-types";

export const getLeadList =
  (activeTaskStatusFilters, page, taskFilters) => (dispatch) => {
    return AdminServices.getLeads(
      page,
      activeTaskStatusFilters,
      taskFilters
    ).then((data) => {
      dispatch({
        type: GET_LEAD_LIST,
        payload: {
          data: data.data,
          extra:
            data.extra && data.extra.total_count ? data.extra.total_count : [],
          page,
        },
      });
    });
  };

export const getLeadDetail =
  (lead_id, slug, cleanLeadDetail = 0) =>
    (dispatch) => {
      if (cleanLeadDetail) {
        dispatch({
          type: GET_SPECIFIC_LEAD_DETAIL,
          payload: { data: {}, cleanLeadDetail: 1 },
        });
      } else {
        return AdminServices.getLeadDetail(lead_id, slug).then((data) => {
          dispatch({
            type: GET_SPECIFIC_LEAD_DETAIL,
            payload: { data: data.data, id: lead_id, slug },
          });
        });
      }
    };

export const handleLeadPinUnpin = (lead_id) => (dispatch) => {
  return CustomerServices.pinDocuments(lead_id, "lead").then((data) => {
    dispatch({
      type: PIN_UNPIN_LEAD,
      payload: { id: lead_id },
    });
  });
};

export const hotLeadSetUnset = (lead_id) => (dispatch) => {
  return AdminServices.setUnsetHotLead(lead_id).then(() => {
    dispatch({
      type: HOT_LEAD_SET_UNSET,
      payload: { id: lead_id },
    });
  });
};

export const leadStatusChange = (lead_id, status, project_id = 0) => (dispatch) => {
  return AdminServices.changeLeadStatus(lead_id, status).then(() => {
    dispatch({
      type: CHANGE_LEAD_STATUS,
      payload: { id: lead_id, status, project_id },
    });
  });
};

export const addAttachmentsToLead = (lead_id, files) => (dispatch) => {
  return AdminServices.uploadLeadAttachment(lead_id, files).then((data) => {
    dispatch({
      type: ADD_LEAD_ATTACHMENTS,
      payload: { id: lead_id, data: data.data },
    });
  });
};

export const removeLeadAttachment = (lead_id, file_id) => (dispatch) => {
  return AdminServices.removeLeadAttachment(lead_id, file_id).then((data) => {
    dispatch({
      type: REMOVE_LEAD_ATTACHMENTS,
      payload: { id: lead_id, data: data.data, file_id },
    });
  });
};

export const addLeadReminder =
  (reminderId, lead_id, selected_staff_id, description, date, notifyByEmail) =>
    (dispatch) => {
      return AdminServices.addEditReminder(
        reminderId,
        lead_id,
        selected_staff_id,
        description,
        date,
        notifyByEmail
      ).then((data) => {
        dispatch(getLeadDetail(lead_id, "lead_appointments"));
        dispatch({
          type: ADD_LEAD_REMINDER,
          payload: { id: lead_id, data: data.data },
        });
      });
    };

export const getLeadActivities = (lead_id) => (dispatch) => {
  return AdminServices.getLeadActivities(lead_id).then((data) => {
    dispatch({
      type: GET_LEAD_ACTIVITIES,
      payload: { id: lead_id, data: data.data },
    });
  });
};

export const addLeadComment = (lead_id, comment) => (dispatch) => {
  return AdminServices.addLeadComment(lead_id, comment).then((data) => {
    dispatch({
      type: ADD_LEAD_COMMENT,
      payload: { id: lead_id, data: data.data, comment },
    });
  });
};

export const getLeadNotes = (lead_id) => (dispatch) => {
  return AdminServices.getLeadNoteList(lead_id).then((data) => {
    dispatch({
      type: GET_LEAD_NOTES_LIST,
      payload: { id: lead_id, data: data.data },
    });
  });
};

export const addEditLeadNote = (note) => (dispatch) => {
  return AdminServices.addEditLeadNote(note).then((data) => {
    dispatch({
      type: ADD_EDIT_LEAD_NOTE,
      payload: { id: note.lead_id, data: data.data, note },
    });
  });
};

export const deleteLeadNote = (lead_id, note_id) => (dispatch) => {
  return AdminServices.deleteNote(lead_id, note_id).then((data) => {
    dispatch({
      type: DELETE_LEAD_NOTE,
      payload: { id: lead_id, data: data.data, note_id },
    });
  });
};

export const leadBulkAction = (data) => (dispatch) => {
  return AdminServices.leadBulkActions(data).then((data) => {
    dispatch({
      type: LEAD_BULK_ACTION,
      payload: { data },
    });
  });
};

export const setMyrStaffList = (project_id) => (dispatch) => {
  return AdminServices.getMyRStaffList(project_id).then((data) => {
    dispatch({
      type: SET_MYR_STAFF,
      payload: { data: data.data },
    });
  });
};

export const updateMyrStaffList = (list) => (dispatch) => {
    dispatch({
      type: SET_MYR_STAFF,
      payload: { data: list },
    });
};

export const setMyrLeadSources = () => (dispatch) => {
  return AdminServices.getLeadSources().then((data) => {
    dispatch({
      type: SET_LEAD_SOURCES,
      payload: { data: data.data },
    });
  });
};

export const editLeadActivity = (lead_id, activity_id, activity) => (dispatch) => {
  return AdminServices.addLeadComment(lead_id, activity, activity_id).then((data) => {
    dispatch({
      type: EDIT_LEAD_ACTIVITY,
      payload: { id: activity_id, data: data.data, activity },
    });
  });
};

export const deleteLeadActivity = (lead_id, activity_id) => (dispatch) => {
  return AdminServices.deleteLeadActivity(lead_id, activity_id).then(() => {
    dispatch({
      type: DELETE_LEAD_ACTIVITY,
      payload: { id: activity_id },
    });
  });
};
export const leadCompanyDataToggle = (type) => (dispatch) => {
    dispatch({
      type: LEAD_COMPANY_DATA,
      payload: type,
    });
};