import React from 'react';
// import CSS from '../css/icon_main.css';

function ThreeStarIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<g clip-path="url(#clip0_1031_5282)">
<path fill-rule="evenodd" clip-rule="evenodd" fill="currentColor" d="M338.334 319.737C244.752 319.737 169.167 243.852 169.167 150.57C169.167 243.852 93.2818 319.737 0 319.737C93.2818 319.737 169.167 395.323 169.167 488.604C169.167 395.323 244.752 319.737 338.334 319.737Z" />
<path fill-rule="evenodd" clip-rule="evenodd" fill="currentColor" d="M512 407.021C460.71 407.021 419.318 365.329 419.318 314.339C419.318 365.329 377.626 407.021 326.636 407.021C377.626 407.021 419.318 448.413 419.318 499.703C419.318 448.413 460.71 407.021 512 407.021ZM458.61 125.076C396.223 125.076 345.832 74.6857 345.832 12.2979C345.832 74.6857 295.442 125.076 233.054 125.076C295.442 125.076 345.832 175.466 345.832 237.854C345.832 175.466 396.223 125.076 458.61 125.076Z" />
</g>
<defs>
<clipPath id="clip0_1031_5282">
<rect width="512" height="512" fill="none"/>
</clipPath>
</defs>
</svg>


		</React.Fragment>
	)
}

export default ThreeStarIcon