import React from 'react';
// import CSS from '../css/icon_main.css';

function LockIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>


<svg width="26" height="31" viewBox="0 0 26 31" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path fillRule="evenodd" clipRule="evenodd" d="M8.51239 5.94731V11.2637H17.1453V5.94731C17.1453 4.80252 16.6905 3.70461 15.881 2.89512C15.0715 2.08563 13.9736 1.63086 12.8288 1.63086C11.684 1.63086 10.5861 2.08563 9.77665 2.89512C8.96716 3.70461 8.51239 4.80252 8.51239 5.94731ZM7.51239 5.94731V11.2637H1.18143C0.528946 11.2637 0 11.7926 0 12.4451V28.9852C0 29.6377 0.528946 30.1666 1.18143 30.1666H24.8101C25.4626 30.1666 25.9915 29.6377 25.9915 28.9852V12.4451C25.9915 11.7926 25.4626 11.2637 24.8101 11.2637H18.1453V5.94731C18.1453 4.5373 17.5852 3.18504 16.5881 2.18801C15.5911 1.19098 14.2388 0.630859 12.8288 0.630859C11.4188 0.630859 10.0666 1.19098 9.06954 2.18801C8.07251 3.18504 7.51239 4.5373 7.51239 5.94731ZM24.8101 12.2637H1.18143C1.08123 12.2637 1 12.3449 1 12.4451V28.9852C1 29.0854 1.08123 29.1666 1.18143 29.1666H24.8101C24.9103 29.1666 24.9915 29.0854 24.9915 28.9852V12.4451C24.9915 12.3449 24.9103 12.2637 24.8101 12.2637ZM13 19.5C12.4477 19.5 12 19.9477 12 20.5C12 21.0523 12.4477 21.5 13 21.5C13.5523 21.5 14 21.0523 14 20.5C14 19.9477 13.5523 19.5 13 19.5Z" fill="none"/>
</svg>







		</React.Fragment>
	)
}

export default LockIcon