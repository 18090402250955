import React, { useEffect } from "react";
import { _l } from "../../../hooks/utilities";
import { CheckCircle } from "phosphor-react";

const AcknowledgeScreen = ({steps, delay, afterDismissAction, title, description, isSolar}) => {

  useEffect(() => {
    setTimeout(() => {
      afterDismissAction();
    }, delay);
  }, []);

  return (
    <React.Fragment>
      <div className="d-flex h-100 w-100">
        <div className="calendar-modal-color m-auto p-20 rounded-3 w_350">
          <div className="p-20">
            <div className="c-font f-18 fw-semibold text-center title-fonts py-2">
              {_l(title)}
            </div>
            <div className="c-font color-white-80 text-center f-13">
            {_l(description)}
            </div>
            <ul className="comman-list position-relative mt-2">
              {steps.titles.map((step, key) => {
                return (
                  <li key={key} className="steps-green-line">
                    <a href="#/" className="check-image absolute-content">
                      {key === steps.titles.length - 1 ? (
                        <>
                        <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm h20w20"
                        ></span>
                        <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border-static h20w20"
                        ></span>
                        </>
                      ) : (
                        <CheckCircle
                          size={22}
                          weight="fill"
                          className="c-icons color-green"
                        />
                      )}
                    </a>
                    <div className="c-font f-13 fw-semibold title-fonts">
                      {_l(step)}
                    </div>
                    <div className={`c-font f-13 title-fonts ${isSolar ? "color-white-60" : "fw-semibold "}`}>
                      {_l(steps.descriptions[key])}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AcknowledgeScreen;
