import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import GroupLogo from "../../../../assets/images/users/Group.png";
import React, { useEffect, useRef, useState } from "react";

 const SubscriptionPaytm = ({ projectId, clientId, location, zoomLevel }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  const [startDate, setStartDate] = useState(new Date());
  const [selectedTab, setSelectedTab] = useState("basicdetails");
 return (
            
        <div className="">
            <div className="row">
                <div className="col-xl-6 py-2">
                    <div className="bg-white-05 radius_5 p-2 border ">
                        <div className="d-flex align-items-center justify-content-between w-100 pb-2">
                            <a href="#/" className="comman-image-box multi-wrapper-border  one assignee-list-wrapper card-view-less-padding" style={{ width: `30px`, height: `20px` }}>
                                <div className="comman-image-box-bg bg-style-cover h-100 w-100 " style={{ backgroundImage: `url('${GroupLogo}')` }}></div>
                            </a>
                        </div>
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <div className="color-white-80 text-truncate  c-font f-14">
                                SEPA Debit
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 py-2">
                    <div className="bg-white-05 radius_5 p-2 border ">
                        <div className="d-flex align-items-center justify-content-between w-100 pb-2">
                            <a href="#/" className="comman-image-box multi-wrapper-border  one assignee-list-wrapper card-view-less-padding" style={{ width: `30px`, height: `20px` }}>
                                <div className="comman-image-box-bg bg-style-cover h-100 w-100 " style={{ backgroundImage: `url('${GroupLogo}')` }}></div>
                            </a>
                        </div>
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <div className="color-white-80 text-truncate  c-font f-14">
                                SEPA Debit
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
 };
 export default SubscriptionPaytm;