import React from 'react'
const CalendarMonthboxSkeleton = () => {
  return (
    <div className='MonthEventbox p-5px d-flex align-items-center justify-content-between radius_3 m-1 cursor-pointer bg-white-05 SkeletonMain'>
        <div className='d-flex align-items-center flex-grow-1'>
                    <div className='flex-grow-1 me-10px radius_3 skeleton-animation' style={{height:'8px'}}></div>
                <div className='rounded-5 skeleton-animation h20w20'></div>
        </div>
    </div>
  )
}
export default CalendarMonthboxSkeleton