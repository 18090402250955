import React from 'react';
// import CSS from '../css/icon_main.css';

function EyeSlashIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M3 2.5L13 13.5M9.68125 9.85C9.22246 10.2703 8.6222 10.5024 8 10.5C7.49541 10.4999 7.00266 10.3472 6.5865 10.0619C6.17035 9.77651 5.85028 9.37192 5.66837 8.90126C5.48647 8.43061 5.45124 7.91592 5.56732 7.42487C5.6834 6.93382 5.94536 6.48939 6.31875 6.15M4.625 4.2875C2.075 5.575 1 8 1 8C1 8 3 12.5 8 12.5C9.17161 12.5095 10.3286 12.2396 11.375 11.7125M13.0375 10.5688C14.4 9.35 15 8 15 8C15 8 13 3.5 8 3.5C7.56654 3.49914 7.13377 3.53469 6.70625 3.60625M8.46875 5.54375C9.0004 5.64448 9.48487 5.91541 9.84903 6.31565C10.2132 6.71589 10.4373 7.22372 10.4875 7.7625"/>
</svg>


		</React.Fragment>
	)
}

export default EyeSlashIcon