import React from 'react'
const AddItemGridSkeleton = () => {
  return (
    <>
      <div className="row SkeletonMain">
        {
          [0,1,2].map((s, index)=>{
            return(<>
              <div className="col-lg-4 mb-20 " key={index}>
                <div className='border radius_3 p-10 space-select-box select-input-cox w-100 bgbodyhover position-relative d-flex flex-column' style={{ height: '285px' }}>
                  <div className='max-h-150 w-100 skeleton-animation radius_3' style={{ height: '160px' }}></div>
                  <div className='d-flex flex-column mt-10px flex-grow-1'>
                    <div className='mb-2 skeleton-animation radius_3' style={{ width: '70%', height: '15px' }}></div>
                    <div className='mb-2 skeleton-animation radius_3' style={{ width: '40%', height: '12px' }}></div>
                    <div className='mb-2 skeleton-animation radius_3' style={{ width: '20%', height: '10px' }}></div>
                    <div className='skeleton-animation radius_3 mt-auto' style={{ width: '20%', height: '15px' }}></div>
                  </div>
                </div>
              </div>
            </>)
          })
        }
      </div>
    </>
  )
}
export default AddItemGridSkeleton