import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/auth";
import { useNavigate } from "react-router-dom";
import CommanLoader from "../components/Loader/CommanLoader";
import EyeIcon from "../assets/icons/EyeIcon";
import EyeSlashIcon from "../assets/icons/EyeSlashIcon";
import LogoGreenDark from "../assets/icons/LogoGreenDark";
import LogoGreenLight from "../assets/icons/LogoGreenLight";
import LogoSolarHub from "../assets/images/blazlogoblack.svg";
import {
  _l,
  favoriteDdashboardSelected,
  isSolarHubPlatform,
  loadDataToIDB,
} from "../hooks/utilities";
import {
  isPremuimEstimateDisable,
  setSelectedGloablView,
} from "../actions/customer";
import jquery from "jquery";
import { showMessage } from "../actions/messages";
import authServices from "../services/auth-services";
import Spinner from "react-bootstrap/esm/Spinner";
import {
  CUSTOM_CLIENT_IDS,
  CUSTOM_HOMEPAGE_URLS,
} from "../constants/constants";
import Alerts from "../components/StandAlonePages/Alerts";
const Login = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [validEmail, setvalidEmail] = useState(false);
  const [loder, setLoder] = useState(false);
  const emailFieldRef = useRef();
  const passwordFieldRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { themeColor } = useSelector((state) => state.customer);
  const [LightTheme, setLightTheme] = useState(
    localStorage.getItem("selectedTheme") == "light" ? true : false
  );

  // const user_id = localStorage.getItem("contact_id") != "undefined" ? localStorage.getItem("contact_id") : localStorage.getItem("staff_id");
  useEffect(() => {
    // if (userDataGlobal != undefined && userDataGlobal == user_id) {
    if (localStorage.getItem("accessToken")) {
      if (localStorage.getItem("membership_package_id") == 1) {
        // navigate("/premiumsubscription");
        navigate("/myprofile");
        dispatch(isPremuimEstimateDisable());
      } else {
        navigate("/");
        dispatch(setSelectedGloablView("AichatView"));
      }
    }
    // }
  }, []);

  useEffect(() => {
    passwordFieldRef.current.focus();
  }, [validEmail]);

  useEffect(() => {
    if(!isSolarHubPlatform())
    {
      if (themeColor == "light") {
        jquery("html").addClass("light-theme");
        setLightTheme(true);
      } else {
        jquery("html").removeClass("light-theme");
        setLightTheme(false);
      }
    }
  }, [themeColor]);

  const isValidEmail = (email) => {
    if (!email.trim()) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_valid_email"))
      );
      return false;
    }
    return true;
  };

  const isValidPassword = (passwordInp) => {
    if (!passwordInp.trim()) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_password"))
      );
      return false;
    } else return true;
  };
  const handleCheckEmail = () => {
    setLoder(true);
    const emailPattern = emailFieldRef.current.value.match(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    );
    if (emailPattern) {
      authServices
        .checkEmailforSetPassword(emailFieldRef.current.value)
        .then((res) => {
          setLoder(false);
          if (res.status == 1) {
            if (res.data.registration_confirmed == 0) {
              localStorage.setItem("redirectfrom", "loginpage");
              navigate("/set-password/" + res.data.id + "/" + res.data.type);
              setvalidEmail(false);
            } else {
              setvalidEmail(true);
            }
          } else {
            // console.log( _l(res.message));
            dispatch(
              showMessage("unsucess", _l("l_error"), _l(res.message.trim()))
            );
          }
        });
    } else {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_enter_valid_email_address")
        )
      );
      setLoder(false);
      setvalidEmail(false);
    }
  };

  const handleLogin = () => {
    if (
      isValidEmail(emailFieldRef.current.value) &&
      isValidPassword(passwordFieldRef.current.value)
    ) {
      // setShowLoader(true);
      setLoder(true);
      localStorage.removeItem("guest_id");
      dispatch(
        login(emailFieldRef.current.value, passwordFieldRef.current.value)
      ).then(async (res) => {
        if (res && res.status) {
        const staff_id = localStorage.getItem("staff_id");
        const user_hash = localStorage.getItem("user_hash");
        let user_type = localStorage.getItem("user_type");

        // setShowLoader(false);
        setLoder(false);

        // await loadDataToIDB(staff_id, user_hash);

        const activeSpace = localStorage.getItem("is_active_space");

        if(isSolarHubPlatform())
        {
          if(user_type === "operator" && isSolarHubPlatform())
          {
            const accepted_proposal = localStorage.getItem("accepted_proposal");
            if(!accepted_proposal)
            {
              navigate("/?page=proposals");
            }
          }
          else if(user_type === "contact") {
            let scheduleData = localStorage.getItem("schedule_data");
            scheduleData = scheduleData ? JSON.parse(scheduleData) : {};
            if(Object.keys(scheduleData).length)
            {
              navigate(`/?page=create-benchmark&space=${scheduleData.project_id}&type=benchmark&id=${scheduleData.benchmark_id}`);
              return;
            }
          }
        }


        if (
          !localStorage.getItem("accessToken") ||
          (res && res.status && res.status != 1)
        ) {
          // swal("Oops!", "Invalid Credentials", "error");
          dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
        } else {
          // localStorage.setItem("password", passwordFieldRef.current.value);
          const client_id = localStorage.getItem("client_id");
          const scheduleId = sessionStorage.getItem("schedule_id");
          const projectId = sessionStorage.getItem("project_id");
          let contact_role = localStorage.getItem("contact_role");
          sessionStorage.removeItem("schedule_id");
          sessionStorage.removeItem("project_id");

          let homepage = "";

          if (user_type != "staff" && contact_role != 3) {
            homepage = "/";
            localStorage.setItem("showintro", true);
          } else {
            homepage = "/";
            localStorage.setItem("showintro", true);
          }

          if (
            CUSTOM_CLIENT_IDS.includes(client_id) &&
            CUSTOM_HOMEPAGE_URLS[client_id]
          ) {
            homepage = CUSTOM_HOMEPAGE_URLS[client_id];
          }
          if (scheduleId > 0) {
            localStorage.setItem("selectedOffice", projectId);
            navigate("/");
            dispatch(setSelectedGloablView("AichatView"));
          }
          if (
            isNaN(activeSpace) ||
            Number(activeSpace) <= 0 ||
            localStorage.getItem("membership_package_id") == 1
          ) {
            if (localStorage.getItem("user_type") == "operator") {
              navigate("/?page=proposals")              
            } else if(user_type == "staff"){
              navigate("/");
              dispatch(setSelectedGloablView("AichatView"));
            }
            
          } else {
            favoriteDdashboardSelected(localStorage.getItem("fav_dashboard"));
            navigate(homepage);
            // dispatch(setSelectedGloablView(favDashboard));
          }
        }
      }else{
        // setShowLoader(false);
        setLoder(false);
        dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
      }
      });
    }
  };

  const handleReset = () => {
    emailFieldRef.current.value = "";
    passwordFieldRef.current.value = "";
  };

  // const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
  // if (darkThemeMq.matches || localStorage.getItem("selectedTheme") == "dark") {
  //   jquery("html").removeClass("light-theme");
  // } else {
  //   jquery("html").addClass("light-theme");
  // }
  // const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");

  return (
    <div>
      <Alerts />
      <div className="login_page">
        <div className="login_box">
          <div className="login-logo">
            {isSolarHubPlatform() ? (
              <>
                <img src={LogoSolarHub} className="h60px" alt="" />
              </>
            ) : (
              <>
                {LightTheme ? (
                  <LogoGreenLight className="header-logo-img " />
                ) : (
                  <LogoGreenDark className="header-logo-img" />
                )}
              </>
            )}
            {/* <LogoGreenBlack className="header-logo-img logo-light" /> */}
          </div>
          <div className="comman_title_white">{_l("l_login")}</div>
          <div className="custom_input_box_main">
            <div className={`custom_input_box with_before`}>
              <label className="input_box_label d-flex align-items-center justify-content-between">
                <div className="title_text color-white-60">
                  {_l("l_email_address_label")}
                  <span className="star_img">*</span>
                </div>
                <a
                  href="#/"
                  className={`forger_link ${validEmail ? "" : "d-none"}`}
                  onClick={() => setvalidEmail(false)}
                >
                  {_l("l_change_email")}
                </a>
              </label>
              <input
                type="text"
                // name="fakeusernameremembered"
                id="email"
                className="input_box  autofill_off autosuggest-off autofill-off autoComplete-off-box-shadow"
                autocomplete="off"
                placeholder={`${_l("l_email_placeholder")}`}
                autoComplete="false"
                ref={emailFieldRef}
                disabled={validEmail ? true : false}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    // handleLogin();
                    handleCheckEmail();
                  }
                }}
              />
            </div>
            <div
              className={`custom_input_box with_before ${
                validEmail ? "" : "d-none"
              }`}
            >
              <div
                className={` ${passwordShown ? "active" : ""} `}
                id="password_class"
              >
                <div className="d-flex align-items-center justify-content-between">
                  <input
                    type={passwordShown ? "text" : "password"}
                    className="input_box  autofill_off autoComplete-off-box-shadow"
                    placeholder="*****"
                    autoComplete="new-password"
                    ref={passwordFieldRef}
                    id="password"
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        handleLogin();
                      }
                    }}
                  />
                  <a href="#/" className="">
                    <div
                      className={`img-width-18 ${
                        passwordShown ? "" : "d-none"
                      }`}
                      data-bs-toggle="tooltip"
                      data-bs-title="Hide password"
                      onClick={() => {
                        setPasswordShown((pre) => !passwordShown);
                      }}
                    >
                      <EyeSlashIcon className="HW18" />
                    </div>
                    <div
                      className={`img-width-18 ${
                        passwordShown ? "d-none" : ""
                      }`}
                      data-bs-toggle="tooltip"
                      data-bs-title="Show password"
                      onClick={() => {
                        setPasswordShown((pre) => !passwordShown);
                      }}
                    >
                      <EyeIcon className="HW18" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="mtop20"></div>
            <div className="login_btn d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <button
                  className={`btn btn-primary me-4 d-flex align-items-center ${
                    loder ? "for-disabled" : ""
                  }`}
                  onClick={() => {
                    if (validEmail == true) {
                      handleLogin();
                    } else {
                      handleCheckEmail();
                    }
                  }}
                >
                  {loder
                    ? _l("l_please_wait")
                    : validEmail == false
                    ? _l("l_continue")
                    : _l("l_login")}
                  {loder ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      className={`ms-2 `}
                      aria-hidden="true"
                    />
                  ) : (
                    <></>
                  )}
                </button>
                {isSolarHubPlatform() ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <React.Fragment>
                    <button className="btn btn-secondary" onClick={handleReset}>
                      {_l("l_reset")}
                    </button>
                  </React.Fragment>
                )}
              </div>
              {isSolarHubPlatform() ? (
                <></>
              ) : (
                <>
                  <button
                    className="btn btn-white-10"
                    onClick={() => navigate("/?page=forgot-password")}
                  >
                    {_l("l_forgot_password")}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {showLoader ? (
        <CommanLoader className="main-loader-wrapper full-screen" />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Login;
