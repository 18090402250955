import React, { useEffect, useState } from "react";
import { _l } from "../../hooks/utilities";
import { DotsNine, GearSix, Image, PushPin } from "phosphor-react";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import ImageUploadDropdown from "../Dropdowns/ImageUploadDropdown";
import { useDispatch, useSelector } from "react-redux";
import { showConfirmation, showMessage, toastCloseAction } from "../../actions/messages";
import { closeOutsideClick, setEquipmentQuantity, setEquipmentQuantityList, setNewEquipment } from "../../actions/customer";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";
import EditEquipmentsQuantity from "../Modals/EditEquipmentsQuantity";

const SpaceEquipment = ({ show, handleClose,selectedEquipment, equipmentData,
    setEquipmentData,
    quantityData,
    setQuantityData,selectedEquipmentsQuantity,commentsList,handleRemoveQuantityFile,previewArray,file }) => {
    const dispatch = useDispatch();
    const projectId = localStorage.getItem("selectedOffice")
    const topics = useSelector((state) => state.customer.defaultTopics);
    const quantityList = useSelector((state) => state.customer.set_equipments_quantity_list);
    const set_equipments_quantity = useSelector((state) => state.customer.set_equipments_quantity);
    const newEuipment = useSelector((state) => state.customer.set_new_equipment);
    const { setPOILatLng, getSelectedFloorId, get_equipments_list } = useSelector((state) => state.customer);
    const [openAttachmentsDropdown, setOpenAttachmentsDropdown] = useState(false);
    const [loder, setLoder] = useState(false);
    const [showQuantity, setShowQuantity] = useState(false);
    const [modalToggleHandler, setModalToggleHandler] = useState(false);
    const [selectedQuantity, setSelectedQuantity] = useState({});
    const [equipmentList, setEquipmentList] = useState([]);
    const [iconToggle, setIconToggle] = useState(0);
    let operatorData = topics
        ? topics.find((w) => w.main_key === "my_operator")
        : [];
    operatorData = operatorData && operatorData.categories
        ? operatorData.categories.filter((w) => w.main_sub_key !== "team").map((w) => {
            return { label: w.title, value: w.filter_key };
        })
        : [];

    let contactData = topics
        ? topics.find((w) => w.main_key === "my_contact")
        : [];
    contactData = contactData && contactData.categories
        ? contactData.categories.filter((w) => w.main_sub_key !== "team").map((w) => {
            return { label: w.title, value: w.filter_key };
        })
        : [];
    const handleFileUploadQuantity = (files, key) => {
        if (files) {
            let thumbnails = [];
            let newFiles = [];
            let tempData = quantityData
            Array.from(files).map((img) => {
                thumbnails.push({ file_name: img.name, image_url: URL.createObjectURL(img) });
                newFiles.push(img);
            });

            if (tempData[key].attachments && Array.from(tempData[key].attachments).length) {
                /**Remove duplicate attachments from array */
                newFiles = Array.from(newFiles).filter(
                    (x) => Array.from(tempData[key].attachments).findIndex((w) => w.name == x.name) === -1
                );
                thumbnails = thumbnails.filter(
                    (x) => tempData[key].preview.findIndex((w) => w.file_name == x.file_name) === -1
                );
                tempData[key].attachments = Array.from(tempData[key].attachments).concat(newFiles)
                tempData[key].preview = tempData[key].preview.concat(thumbnails)
                setQuantityData(tempData.slice());
            } else {
                tempData[key].attachments = tempData[key].attachments.concat(newFiles)
                tempData[key].preview = tempData[key].preview.concat(thumbnails)
                setQuantityData(tempData.slice());
            }
        }
    };
   
    useEffect(() => {
        if (quantityData.length == 1 && setPOILatLng) {
            setQuantityData([{...quantityData[0],
                web_x: setPOILatLng.x,
                web_y: setPOILatLng.y,
                web_z: setPOILatLng.z
            }])
        }
    },[JSON.stringify(setPOILatLng)])

    useEffect(() => {
        $("#client_3d_optionfirst").css({ 'display': 'none' });
        $(".dropdown-menu").removeClass("show");
        dispatch(closeOutsideClick(false));
      }, [])

    useEffect(() => {
        let element = document.body;
        if (show) {
          if (element) {
            element.classList.remove("modal-open");
          }
        }
        return () => {
          if (element) {
            element.classList.remove("modal-open");
          }
        };
      }, [show]);
    const clearData = (action) => {
        dispatch(setNewEquipment(undefined));
        dispatch(setEquipmentQuantityList(action == "save" ? undefined : [{
        id:1,
        name: "default quantity",
        file: [],
        preview: [],
        lifetime: 0,
        web_x: 0,
        web_y: 0,
        web_z: 0,
        device_id:""
    }]));
        dispatch(setEquipmentQuantity(undefined));
        handleClose();
    }
    useEffect(() => {
        if (quantityData && quantityData[0] && quantityData[0].name != "default quantity") {
            dispatch(setEquipmentQuantityList(quantityData))
        }
    }, [JSON.stringify(quantityData)])
    useEffect(() => {
        if (equipmentData && equipmentData.title != "") {
            dispatch(setNewEquipment({ ...equipmentData, file: file || [], previewArray: previewArray || [], commentsList: commentsList || [] }))
        }
    }, [JSON.stringify(equipmentData),JSON.stringify(file), JSON.stringify(previewArray), JSON.stringify(commentsList)])

    const handleCloseModal = () => {
        if (equipmentData.title != "" || equipmentData.priority > 0 || equipmentData.provider_assignee > 0 || equipmentData.contact_assignee > 0 || previewArray.length > 0 || file.length > 0 || commentsList.length > 0) {
            dispatch(showConfirmation(_l('l_are_you_sure'), _l('l_you_want_to_close_this_modal'), _l('l_close'), _l('l_cancel'), () => { }, () => { clearData(); dispatch(toastCloseAction()) }));
        }else{
            clearData();
        }
    }
    return (
        <>
            <div className={`overflow-hiiden-auto with-custom-height py-0 px-3`}>
                    
                    <div className="list-view-toggle position-relative">
                    {/* {set_equipments_quantity !=undefined?
                    <div className={`modal-overlay1 withzindex position-relative`}></div>:<></>} */}
                    {quantityData.length > 0 && quantityData.map((item, key) => {
                        return (
                            <div className={`task-checklist p-2 ${set_equipments_quantity && (set_equipments_quantity.id == item.id || set_equipments_quantity.quantityId == item.id) ? "activegreenborder z-index-9" : ""}`}>
                                <div className="align-content-center d-flex">
                                    <div className="d-flex align-items-center justify-content-between w-100 line-height-1">
                                        <div className="text-truncate flex-grow-1"
                                        data-tip={item.name}
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main"
                                        >
                                            <div className="c-font f-14 text-truncate">
                                                {item.name}
                                            </div>
                                        </div>
                                        <div className="text-center d-flex align-items-center c-font f-10 comman_action_icon">
                                            {iconToggle == item.id ? 
                                            <><a href="#/" className="d-flex flex-column justify-content-center action_icon with_bg h32w32 me-10px"
                                                onClick={() => {
                                                    setShowQuantity(true)
                                                    setSelectedQuantity({ ...item, index: key,lifetime: new Date(item.lifetime) })
                                                }}
                                            >
                                                <GearSix size={18} weight="light" className="c-icons my-0" />
                                            </a>
                                            <div className=" dropdown-center dropdown d-flex align-items-center me-10px">
                                                <UncontrolledDropdown className="w-100">
                                                    <DropdownToggle data-toggle="dropdown" tag="span" className=" comman_action_icon w-100 d-flex close-toast border-0 ps-0">
                                                        <a href="#/"
                                                            data-tip={_l("l_attachments")}
                                                            data-effect="solid"
                                                            data-delay-show='1000'
                                                            data-class="tooltip-main"
                                                            onClick={(e) => {
                                                                // if (parseInt(task.request_task_id) == 0) {
                                                                setOpenAttachmentsDropdown(true);
                                                                // }
                                                            }}
                                                        >
                                                            <div className="d-flex align-items-center w-100">
                                                                <div className="action_icon with_bg on-hover-active-toggle-img light-theme-img h32w32">
                                                                    <Image
                                                                        size={18}
                                                                        weight="light"
                                                                        className="c-icons"
                                                                    />
                                                                </div>
                                                                {item.preview.length > 0 ?
                                                                    <span className="c-font f-12 d-flex justify-content-center flex-grow-1 text-on-hover-active-green number-counter-toggle">
                                                                        {item.preview.length}
                                                                    </span>
                                                                    :
                                                                    <></>
                                                                }
                                                            </div>
                                                        </a>
                                                    </DropdownToggle>
                                                    <ImageUploadDropdown
                                                        setOpenAttachmentsDropdown={
                                                            setOpenAttachmentsDropdown
                                                        }
                                                        openAttachmentsDropdown={
                                                            openAttachmentsDropdown
                                                        }
                                                        handleFileUpload={(files) => {
                                                            handleFileUploadQuantity(files, key)
                                                        }}
                                                        handleRemoveQuantityFile={(fileIndex,file_id) => {
                                                            handleRemoveQuantityFile("quantity",0 ,file_id, fileIndex,item.id,key);
                                                        }}
                                                        previewArray={item.preview}
                                                        task_id={""}
                                                        project_id={""}
                                                        updateImageCount={""}
                                                        pageName="equipment"
                                                        taskDetails={""}
                                                        unContolList={true}
                                                        from="addequipments"
                                                    />
                                                </UncontrolledDropdown>
                                            </div>
                                            <a href="#/" className="d-flex flex-column justify-content-center action_icon with_bg h32w32 "
                                                onClick={() => {
                                                    dispatch(setEquipmentQuantity(item))
                                                    setModalToggleHandler(true);
                                                }}
                                            >
                                                <PushPin size={18} weight={item.web_x != 0 || item.web_y != 0 || item.web_z != 0 ? "fill" : "light"} className="c-icons my-0" />
                                            </a>
                                            </> 
                                            : <></>}
                                                <a href="#/"
                                                    className={`d-flex action_icon with_bg with-border-active ms-10px h32w32 ${iconToggle == item.id ? "active" : ""}`}
                                                    onClick={() => {
                                                          setIconToggle(iconToggle == item.id ? 0 : item.id);
                                                    }}
                                                >
                                                    <DotsNine size={18} className="c-icons" weight="light" />
                                                </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    </div>
                </div>
                {showQuantity ?
                <EditEquipmentsQuantity
                    show={showQuantity}
                    handleClose={() => setShowQuantity(false)}
                    data={selectedQuantity}
                    setData={setQuantityData}
                    quantityData={quantityData}
                />
                :
                <></>
            }
        </>
    )
}

export default SpaceEquipment;