import { CaretRight, FilePdf, FileText, Files, House, Medal, Paperclip, Pencil, ShieldCheck, UserFocus, Wrench, X } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { _l, getFileType, getImagePreviewIcon, getPreviewFromURL } from "../../hooks/utilities";
import { Button } from "react-bootstrap";
import Lightbox from "yet-another-react-lightbox";
import { showMessage } from "../../actions/messages";
import { useDispatch } from "react-redux";
import PreviewNotAvailable from "../../assets/images/preview-not-available.jpg";
import SetCoordinates from "../../assets/icons/SetCoordinates";
import customerServices from "../../services/customer-services";


const ProviderUploadDocument = ({ onChange, handleNextStep, isDocumentPage, previewObject ,loader = false, isDocumentPageRegistration,setDocuments}) => {
  const [imagePreviews, setImagePreviews] = useState({
    companyLogoPreview: "", 
    companyIdPreview: "", 
    companyRGEPreview: "", 
    companyQCPreview: "", 
    companyInsurancePreview: "",
    companymandatePreview: "",
    companyPIPreview: "",
  });
const staffId = localStorage.getItem("staff_id");

  const [userProfileUrl, setUserProfileUrl] = useState('');
  const [data, setData] = useState({
    companyLogo: "", 
    companyId: "", 
    companyRGE: "", 
    companyQC: "", 
    companyInsurance: "", 
    companymandate: "",
    companyPI: "",
  });
  const [openGallery, setOpenGallery] = useState(false);
  const [attachmentUrl, setAttachmentUrl] = useState([]);
  const [loadedImages, setLoadedImages] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isCoordinateSet, setIsCoordinateSet] = useState(false)

  const dispatch = useDispatch();

  const handlesetPDFFields = async(url,document_type,document_id) =>{
    setisLoading(true);
    let document_details = [{
      "document_id" : document_id,
      "document_type" : "mandate",
      "staff_id" : localStorage.getItem("staff_id"),
      "userType" : localStorage.getItem("user_type"),
      "cordinates" : previewObject.cordinates ? JSON.parse(previewObject.cordinates) : []
  }]
    try {
      const form = new FormData();
      form.append("call_back_url", "http://localhost/myrai-web/web/orb/Custom_actions/update_coordinates");
      // form.append("call_back_url", "https://api.blaz.app/web/orb/custom_actions/update_coordinates");
      form.append("document_url", url);
      form.append("document_details", JSON.stringify(document_details));
      form.append("document_type", document_type);

      const res = await fetch('http://localhost/pdf-module-api/index.php?mod=general&act=upload_document', {
      // const res = await fetch('https://pdf-module-api.ciphercraft.co/index.php?mod=general&act=upload_document', {
        method: 'POST',
        body: form,
    });
    if (!res.ok) {
      // If the response is not OK, throw an error
      setisLoading(false);
      throw new Error(`HTTP error! status: ${res.status}`);

    }
      const data = await res.json();
      // console.log("data",data)
      openPDFModule(data.data, document_type)
   } catch (error) {
      console.log(error);
      throw error; // Rethrow the error for further handling if necessary

    }
  }

  const openPDFModule = (url, document_type) => {
    setisLoading(false);
    const popupOptions = 'width=1200,height=600,left=200,top=100';
    const popupWindow = window.open(
      `https://pdf-module.allisting.com/?pdfUrl=${encodeURIComponent(`${url}`)}&source=blaz&type=${document_type}`,
      '_blank',
      popupOptions
    );
    // const popupWindow = window.open(
    //   `http://192.168.1.22:5173/?pdfUrl=${encodeURIComponent(`${url}`)}&source=blaz&type=${document_type}`,
    //   '_blank',
    //   popupOptions
    // );
  
    // Check if the popup is closed every 500ms
    const checkPopupClosed = setInterval(() => {
      if (popupWindow.closed) {
        clearInterval(checkPopupClosed);
        // Trigger your event or logic here when the popup is closed
        console.log('Popup closed!');
        loadDocuments();
        // You can add any additional logic here
      }
    }, 500);
  };

  const loadDocuments = async () => {
    try {
        const response = await customerServices.getSelectedEmployeeDetails(
          staffId
        );
        if (response && response.data) {
          setDocuments({
            companyLogoPreview: response.data.company_logo,
            companyIdPreview: response.data.company_id || "",
            companyRGEPreview: response.data.rge_certificate || "",
            companyQCPreview: response.data.skill || "",
            companyInsurancePreview: response.data.insurance || "",
            companymandatePreview: response.data.mandate || "",
            companyPIPreview: response.data.pi || "", 
            mandateID : response.data.mandate_id,
            cordinates : response.data.cordinates
 
          });
        } else {
          return [];
        }
      
    } catch (e) {
      console.error(e);
      return [];
    } finally {
    }
  };

  useEffect(() => {
    onChange(data)
    const { companyId, companyRGE, companyQC, companyInsurance, companymandate, companyPI } = data
    if ( companyId != "" && companyRGE != "" && companyQC != "" && companyInsurance != "" && companymandate != "" && companyPI != "") {
      localStorage.setItem("isCompanyDocumentsUploaded", true)
    }
  }, [data])

  useEffect(() => {
    if(isDocumentPage)
    {
      setImagePreviews(previewObject);
      if(previewObject.companyLogoPreview)
      {
        setUserProfileUrl(previewObject.companyLogoPreview);
      }
    }
  }, [])

  // useEffect(() => {
  //   if (previewObject && previewObject.cordinates && previewObject.cordinates.length == 0) {
  //     setIsCoordinateSet(false);
  //   } else if (previewObject && previewObject.cordinates) {
  //     setIsCoordinateSet(JSON.parse(previewObject.cordinates).length);
  //   }else{
  //     setIsCoordinateSet(false);
  //   }
  // }, [previewObject])
  
  

  return (
    <>
          <Lightbox
        open={openGallery}
        close={() => setOpenGallery(false)}
        slides={attachmentUrl.map((img)=>{
          return{src : img}
        })}
      />
      <div className={`d-flex flex-column  ${isDocumentPage ? "bg-white" : " h-100"}`}>
    <div className={`d-flex flex-column RegNew ${isDocumentPage ? "pt-5" : " h-100"}`}>
      <div className="row">
          <div className="col-lg-4 m-auto">
              <Form.Group className={`c-input-box position-relative text-center `}>
                  <div className="form-control  file-upload with-bg position-relative comman-round-box d-flex flex-column p-0 file-upload-main rounded-3">
                      <div className="d-flex align-items-center justify-content-center h-100 flex-column">
                          <input
                              className="z-index-3 hidden-input m-auto"
                              type="file"
                              accept="image/*"
                              onChange={(event) => {
                                  setUserProfileUrl(URL.createObjectURL(event.target.files[0]))
                                  setData({ ...data, companyLogo: event.target.files });
                                  Array.from(event.target.files).map((img) => {
                                    let preview_url = getImagePreviewIcon(img);
                                    setImagePreviews({...imagePreviews,companyLogoPreview : preview_url })
                                  });
                              }}
                          />
                          {!loadedImages && (
                            <img
                              src={PreviewNotAvailable}  // Your placeholder image
                              alt="placeholder"
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                              }}
                            />
                          )}
                          <img className="m-auto text-center comman-bg-img h-100 w-100 bg-style-contain d-flex flex-column position-relative rounded-3 p-1" style={{display: loadedImages ? 'block' : 'none'}}
                            src={userProfileUrl == "" ? "https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg" : userProfileUrl}
                            onLoad={() => setLoadedImages(true)}  
                            onError={() => setLoadedImages(true)}
                          >
                          </img> 
                              <a href="#/" className="remove-img large abspotion square-logo">
                                  <Pencil size={14} weight="light" className="c-icons m-auto" />
                              </a>
                      </div>
                      <div className="bg-base-header-color bottom-0 c end-0 h-100 position-absolute start-0 top-0 w-100 d-none">
                          <div className="bg-style-cover comman-bg-img h-100 w-100" style={{backgroundImage: `url('${encodeURI(userProfileUrl)}')`}}></div>
                      </div>
                  </div>
              </Form.Group>
          </div>
      </div>
    </div>
    <div className={`col-12 col-xl-9 col-xxl-8 registration-content flex-grow-1 mx-auto row ${isDocumentPage ? "mb-5 mt-4" : "mb-70"}`}>
      <div className="col-lg-2"></div>
      <div className="align-items-center col-lg-7 d-flex mx-auto flex-column">
        <div className="border radius10 bg-white-03 comman-white-box-shadow w-100">
          {/* loop start */}
          <div className="align-items-center border-bottom border-last-none d-flex p-20 justify-content-between ">
            <div className="d-flex align-items-center flex-grow-1">
              <div className="h35w35 comman-round-box  me-20px">
                <div className="comman-bg-img h-100 w-100 bg-style-cover d-flex">
                <UserFocus
                  size={34}
                  className="c-icons"
                />
                </div>
              </div>
              <div className="c-font w-100 f-14 fw-semibold title-fonts d-flex align-items-center  w100minus100">{_l("l_company_id")} {"(KBSI)"}</div>
            </div>
            <div className=" d-flex align-items-center comman_action_icon gap-3">
           {imagePreviews.companyIdPreview != "" && <div className="h40w40 comman-round-box with-bg bg-white-05  upload-image-preview radius_3">
            <a href="#/" class="upload-close" 
            onClick={()=>{
              setData({...data,companyId : ""})
              setImagePreviews({...imagePreviews,companyIdPreview : "" })  
            }}>
              <X
                  size={12}
                  weight="light"
                  className="c-icons"
                />
              </a>
              {getFileType(imagePreviews.companyIdPreview) === "pdf" ?
                <a href={imagePreviews.companyIdPreview} target="_blank" className="h-100 w-100"> 
                  <FilePdf  weight="light" className="h-100 w-100 "/>
                </a>
                :
                <div className="comman-bg-img h-100 w-100 bg-style-cover radius_3" style={{ backgroundImage: `url('${imagePreviews.companyIdPreview}')` }}
                onClick={()=>{
                  setOpenGallery(true);
                  setAttachmentUrl([imagePreviews.companyIdPreview])
                }}></div>
              }
              </div>}
              <a
                href="#/"
                className="action_icon min-width55 with_bg position-relative h32w32"
              >
                <input 
                  type="file" 
                  className="absolute-input" 
                  onChange={(event) => {
                    setData({ ...data, companyId: event.target.files });
                    const file = event.target.files[0];

                    if (file) {
                      const fileType = file.type;
                      if (fileType === 'image/png' || fileType === 'image/jpeg') {
                        Array.from(event.target.files).map((img) => {
                          let preview_url = getImagePreviewIcon(img);
                          setImagePreviews({...imagePreviews,companyIdPreview : preview_url })
                        });
                      } else if (fileType === 'application/pdf') {
                        const pdfBytes =  URL.createObjectURL(event.target.files[0]);
                          setImagePreviews({...imagePreviews,companyIdPreview : pdfBytes.concat(".pdf")})
                      } 
                    }
                  }}
                />
                <Paperclip
                  size={16}
                  weight="light"
                  className="c-icons"
                />
              </a>
            </div>
              
          </div>
          <div className="align-items-center border-bottom border-last-none d-flex flex-wrap p-20 justify-content-between ">
            <div className="d-flex align-items-center flex-grow-1">
              <div className="h35w35 comman-round-box  me-20px">
                <div className="comman-bg-img h-100 w-100 bg-style-cover d-flex">
                <Files
                  size={34}
                  className="c-icons"
                />
                </div>
              </div>
              <div className="c-font f-14 fw-semibold title-fonts d-flex align-items-center  w100minus100">Mandate</div>
              {/* {isCoordinateSet ? <> <div className="c-font f-14 fw-semibold title-fonts d-flex align-items-center  w100minus100">Mandate</div></> : <></>              } */}
            </div>
            <div className=" d-flex align-items-center comman_action_icon gap-3" 
            >
            {imagePreviews.companymandatePreview != "" && 
            <>
            <div className="d-flex align-items-center gap-3">
           
            <div className="h40w40 comman-round-box with-bg bg-white-05  upload-image-preview radius_3">
            <a href="#/" class="upload-close" onClick={()=>{
              setData({...data,companymandate : ""})
              setImagePreviews({...imagePreviews,companymandatePreview : "" })  
            }}>
              <X
                  size={12}
                  weight="light"
                  className="c-icons"
                />
              </a>
              {getFileType(imagePreviews.companymandatePreview) === "pdf" ? 
                <a href={imagePreviews.companymandatePreview} target="_blank" className="h-100 w-100">
                  <FilePdf  weight="light" className="h-100 w-100 " />
                </a>
                :
                <div className="comman-bg-img h-100 w-100 bg-style-cover" style={{ backgroundImage: `url('${imagePreviews.companymandatePreview}')` }}
                onClick={()=>{
                  setOpenGallery(true);
                  setAttachmentUrl([imagePreviews.companymandatePreview])
                }}></div>
              }
              </div>

                    <div className="" title="set Fields">
                      {/* handlesetPDFFields(imagePreviews.companymandatePreview, "mandate" ); */}
                      <a href="#/" className={`action_icon min-width55 with_bg position-relative h32w32 d-flex m-auto ${isLoading ? "for-disabled" : ""}`} 
                      onClick={(e) => {
                        e.stopPropagation()
                        if (isLoading == false) {
                          handlesetPDFFields(imagePreviews.companymandatePreview, "mandate",previewObject.mandateID);
                        }
                      }}>
                        {isLoading ?
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            className={`m-auto`}
                            aria-hidden="true"
                          /> : 
                          <SetCoordinates className="c-icons" />}
                      </a>
                    </div>
            </div>
            </>
              }
              <a
                href="#/"
                className="action_icon min-width55 with_bg position-relative h32w32"
              >
                <input 
                  type="file" 
                  accept="application/pdf"
                  className="absolute-input" 
                  onChange={(event) => {
                    const file = event.target.files[0];
                    
                    if (file) {
                      const fileType = file.type;
                      // if (fileType === 'image/png' || fileType === 'image/jpeg') {
                        //   Array.from(event.target.files).map((img) => {
                          //     let preview_url = getImagePreviewIcon(img);
                          //     setImagePreviews({...imagePreviews,companymandatePreview : preview_url })
                          //   });
                          // } else 
                      if (fileType === 'application/pdf') {
                        const pdfBytes =  URL.createObjectURL(event.target.files[0]);
                        setImagePreviews({...imagePreviews,companymandatePreview : pdfBytes.concat(".pdf")})
                        setData({ ...data, companymandate: event.target.files });
                      } else{
                        dispatch(showMessage("unsucess", _l("l_error"), _l("l_only_for_pdf_is_selected")));
                      }
                    }
                  }}
                />
                <Paperclip
                  size={16}
                  weight="light"
                  className="c-icons"
                />
              </a>
            </div>
            
          </div>
          <div className="align-items-center border-bottom border-last-none d-flex flex-wrap p-20 justify-content-between ">
            <div className="d-flex align-items-center flex-grow-1">
              <div className="h35w35 comman-round-box  me-20px">
                <div className="comman-bg-img h-100 w-100 bg-style-cover d-flex">
                <Wrench size={32} className="c-icons"/>
                </div>
              </div>
              <div className="c-font f-14 fw-semibold title-fonts d-flex align-items-center  w100minus100">PI</div>
            </div>
            <div className=" d-flex align-items-center comman_action_icon gap-3" 
            >
            {imagePreviews.companyPIPreview != "" && <div className="h40w40 comman-round-box with-bg bg-white-05  upload-image-preview radius_3">
            <a href="#/" class="upload-close" onClick={()=>{
              setData({...data,companyPI : ""})
              setImagePreviews({...imagePreviews,companyPIPreview : "" })  
            }}>
              <X
                  size={12}
                  weight="light"
                  className="c-icons"
                />
              </a>
              {getFileType(imagePreviews.companyPIPreview) === "pdf" ? 
                <a href={imagePreviews.companyPIPreview} target="_blank" className="h-100 w-100">
                  <FilePdf  weight="light" className="h-100 w-100 " />
                </a>
                :
                <div className="comman-bg-img h-100 w-100 bg-style-cover" style={{ backgroundImage: `url('${imagePreviews.companyPIPreview}')` }}
                onClick={()=>{
                  setOpenGallery(true);
                  setAttachmentUrl([imagePreviews.companyPIPreview])
                }}></div>
              }
              </div>}
              <a
                href="#/"
                className="action_icon min-width55 with_bg position-relative h32w32"
              >
                <input 
                  type="file" 
                  accept="application/pdf"
                  className="absolute-input" 
                  onChange={(event) => {
                    const file = event.target.files[0];
                    
                    if (file) {
                      const fileType = file.type;
                      // if (fileType === 'image/png' || fileType === 'image/jpeg') {
                        //   Array.from(event.target.files).map((img) => {
                          //     let preview_url = getImagePreviewIcon(img);
                          //     setImagePreviews({...imagePreviews,companyPIPreview : preview_url })
                          //   });
                          // } else 
                      if (fileType === 'application/pdf') {
                        const pdfBytes =  URL.createObjectURL(event.target.files[0]);
                        setImagePreviews({...imagePreviews,companyPIPreview : pdfBytes.concat(".pdf")})
                        setData({ ...data, companyPI: event.target.files });
                      }else{
                          dispatch(showMessage("unsucess", _l("l_error"), _l("l_only_for_pdf_is_selected")));
                        }
                    }
                  }}
                />
                <Paperclip
                  size={16}
                  weight="light"
                  className="c-icons"
                />
              </a>
            </div>
            
          </div>
          {/* <div className="align-items-center border-bottom border-last-none d-flex flex-wrap p-20 justify-content-between ">
            <div className="d-flex align-items-center flex-grow-1">
              <div className="h35w35 comman-round-box with-bg bg-white-05 me-20px">
                <div className="comman-bg-img h-100 w-100 bg-style-cover" style={{ backgroundImage: `url('${battery}')` }}></div>
              </div>
              <div className="c-font f-14 fw-semibold title-fonts d-flex align-items-center  w100minus100">Exemple de Devis</div>
            </div>
            <div className=" d-flex align-items-center comman_action_icon gap-3">
            {imagePreviews.companyRGEPreview != "" && <div className="h40w40 comman-round-box with-bg bg-white-05  upload-image-preview radius_3">
            <a href="#/" class="upload-close"
             onClick={()=>{
              setData({...data,companyRGE : ""})
              setImagePreviews({...imagePreviews,companyRGEPreview : "" })  
            }}>
              <X
                  size={12}
                  weight="light"
                  className="c-icons"
                />
              </a>
                {getFileType(imagePreviews.companyRGEPreview) === "pdf" ? 
                <a href={imagePreviews.companyRGEPreview} target="_blank" className="h-100 w-100">
                  <FilePdf  weight="light" className="h-100 w-100 " />
                </a>
                :
                <div className="comman-bg-img h-100 w-100 bg-style-cover" style={{ backgroundImage: `url('${imagePreviews.companyRGEPreview}')` }}
                 onClick={()=>{
                  setOpenGallery(true);
                  setAttachmentUrl([imagePreviews.companyRGEPreview])
                }}></div>
               }
              </div>}
              <a
                href="#/"
                className="action_icon min-width55 with_bg position-relative h32w32"
              >
                <input 
                  type="file" 
                  className="absolute-input" 
                  onChange={(event) => {
                    setData({ ...data, companyRGE: event.target.files });
                    const file = event.target.files[0];

                    if (file) {
                      const fileType = file.type;
                      if (fileType === 'image/png' || fileType === 'image/jpeg') {
                        Array.from(event.target.files).map((img) => {
                          let preview_url = getImagePreviewIcon(img);
                          setImagePreviews({...imagePreviews,companyRGEPreview : preview_url })
                        });
                      } else if (fileType === 'application/pdf') {
                        const pdfBytes =  URL.createObjectURL(event.target.files[0]);
                          setImagePreviews({...imagePreviews,companyRGEPreview : pdfBytes.concat(".pdf")})
                      } 
                    }
                  }}
                />
                <Paperclip
                  size={16}
                  weight="light"
                  className="c-icons"
                />
              </a>
            </div>
            
          </div> */}
          <div className="align-items-center border-bottom border-last-none d-flex p-20 justify-content-between ">
            <div className="d-flex align-items-center flex-grow-1">
              <div className="h35w35 comman-round-box  me-20px">
                <div className="comman-bg-img h-100 w-100 bg-style-cover d-flex">
                <Medal
                  size={34}
                  className="c-icons"
                />
                </div>
              </div>
              <div className="c-font f-14 fw-semibold title-fonts d-flex align-items-center  w100minus100">{_l("l_qualification_certificate")}</div>
            </div>
            <div className=" d-flex align-items-center comman_action_icon gap-3"
            >
           {imagePreviews.companyQCPreview != "" && <div className="h40w40 comman-round-box with-bg bg-white-05  upload-image-preview radius_3">
            <a href="#/" class="upload-close" onClick={()=>{
              setData({...data,companyQC : ""})
              setImagePreviews({...imagePreviews,companyQCPreview : "" })  
            }}>
              <X
                  size={12}
                  weight="light"
                  className="c-icons"
                />
              </a>
              {getFileType(imagePreviews.companyQCPreview) === "pdf" ? 
                <a href={imagePreviews.companyQCPreview} target="_blank" className="h-100 w-100">
                  <FilePdf  weight="light" className="h-100 w-100 "/>
                </a>
                :
                <div className="comman-bg-img h-100 w-100 bg-style-cover" style={{ backgroundImage: `url('${imagePreviews.companyQCPreview}')` }} 
                 onClick={()=>{
                  setOpenGallery(true);
                  setAttachmentUrl([imagePreviews.companyQCPreview])
                }}></div>
              }
              </div>
              }
              <a
                href="#/"
                className="action_icon min-width55 with_bg position-relative h32w32"
              >
                <input 
                  type="file" 
                  className="absolute-input" 
                  onChange={(event) => {
                    setData({ ...data, companyQC: event.target.files });
                    const file = event.target.files[0];

                    if (file) {
                      const fileType = file.type;
                      if (fileType === 'image/png' || fileType === 'image/jpeg') {
                        Array.from(event.target.files).map((img) => {
                          let preview_url = getImagePreviewIcon(img);
                          setImagePreviews({...imagePreviews,companyQCPreview : preview_url })
                        });
                      } else if (fileType === 'application/pdf') {
                        const pdfBytes =  URL.createObjectURL(event.target.files[0]);
                          setImagePreviews({...imagePreviews,companyQCPreview : pdfBytes.concat(".pdf")})
                      } 
                    }
                  }}
                />
                <Paperclip
                  size={16}
                  weight="light"
                  className="c-icons"
                />
              </a>
            </div>
            
          </div>
          <div className="align-items-center border-bottom border-last-none d-flex flex-wrap p-20 justify-content-between ">
            <div className="d-flex align-items-center flex-grow-1">
              <div className="h35w35 comman-round-box  me-20px">
                <div className="comman-bg-img h-100 w-100 bg-style-cover d-flex" >
                <ShieldCheck
                  size={34}
                  className="c-icons"
                />
                </div>
              </div>
              <div className="c-font f-14 fw-semibold title-fonts d-flex align-items-center  w100minus100">{_l("l_insurance_upload")}s</div>
            </div>
            <div className=" d-flex align-items-center comman_action_icon gap-3" 
            >
            {imagePreviews.companyInsurancePreview != "" && <div className="h40w40 comman-round-box with-bg bg-white-05  upload-image-preview radius_3">
            <a href="#/" class="upload-close" onClick={()=>{
              setData({...data,companyInsurance : ""})
              setImagePreviews({...imagePreviews,companyInsurancePreview : "" })  
            }}>
              <X
                  size={12}
                  weight="light"
                  className="c-icons"
                />
              </a>
              {getFileType(imagePreviews.companyInsurancePreview) === "pdf" ? 
                <a href={imagePreviews.companyInsurancePreview} target="_blank" className="h-100 w-100">
                  <FilePdf  weight="light" className="h-100 w-100 " />
                </a>
                :
                <div className="comman-bg-img h-100 w-100 bg-style-cover" style={{ backgroundImage: `url('${imagePreviews.companyInsurancePreview}')` }}
                onClick={()=>{
                  setOpenGallery(true);
                  setAttachmentUrl([imagePreviews.companyInsurancePreview])
                }}></div>
              }
              </div>}
              <a
                href="#/"
                className="action_icon min-width55 with_bg position-relative h32w32"
              >
                <input 
                  type="file" 
                  className="absolute-input" 
                  onChange={(event) => {
                    setData({ ...data, companyInsurance: event.target.files });
                    const file = event.target.files[0];

                    if (file) {
                      const fileType = file.type;
                      if (fileType === 'image/png' || fileType === 'image/jpeg') {
                        Array.from(event.target.files).map((img) => {
                          let preview_url = getImagePreviewIcon(img);
                          setImagePreviews({...imagePreviews,companyInsurancePreview : preview_url })
                        });
                      } else if (fileType === 'application/pdf') {
                        const pdfBytes =  URL.createObjectURL(event.target.files[0]);
                          setImagePreviews({...imagePreviews,companyInsurancePreview : pdfBytes.concat(".pdf")})
                      } 
                    }
                  }}
                />
                <Paperclip
                  size={16}
                  weight="light"
                  className="c-icons"
                />
              </a>
            </div>
            
          </div>
          {/* loop end */}
        </div>
        <div className={`pt-70px ${isDocumentPage || isDocumentPageRegistration ? "d-none" : ''}`}>
            <Button className={`h45w45 w-auto px-4 py-0 ${isDocumentPage && loader ? "for-disabled" : ""}`}onClick={handleNextStep}>
              {isDocumentPage ? loader ?      
              <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              className={`ms-2 `}
              aria-hidden="true"
            /> : <></> : <></>}
              {isDocumentPage ? loader ? _l("l_please_wait") : _l("l_save") : _l("l_access_my_provider_account")}
            </Button>
        </div>
      </div>
      <div className="col-lg-2"></div>
    </div>
    </div>
    </>
  );
};

export default ProviderUploadDocument;
