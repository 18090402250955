import React from 'react';
// import CSS from '../css/icon_main.css';

function ImageIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>


<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<g clipPath="url(#clip0_2363_185672)">
<path fillRule="evenodd" clipRule="evenodd" d="M5.0001 1.1001C5.22101 1.1001 5.4001 1.27918 5.4001 1.5001V2.1001H10.6001V1.5001C10.6001 1.27918 10.7792 1.1001 11.0001 1.1001C11.221 1.1001 11.4001 1.27918 11.4001 1.5001V2.1001H13.0001C13.4972 2.1001 13.9001 2.50304 13.9001 3.0001V5.5001V13.0001C13.9001 13.4972 13.4972 13.9001 13.0001 13.9001H3.0001C2.50304 13.9001 2.1001 13.4972 2.1001 13.0001V5.5001V3.0001C2.1001 2.50304 2.50304 2.1001 3.0001 2.1001H4.6001V1.5001C4.6001 1.27918 4.77918 1.1001 5.0001 1.1001ZM4.6001 2.9001H3.0001C2.94487 2.9001 2.9001 2.94487 2.9001 3.0001V5.1001H13.1001V3.0001C13.1001 2.94487 13.0553 2.9001 13.0001 2.9001H11.4001V3.5001C11.4001 3.72101 11.221 3.9001 11.0001 3.9001C10.7792 3.9001 10.6001 3.72101 10.6001 3.5001V2.9001H5.4001V3.5001C5.4001 3.72101 5.22101 3.9001 5.0001 3.9001C4.77918 3.9001 4.6001 3.72101 4.6001 3.5001V2.9001ZM13.1001 13.0001V5.9001H2.9001V13.0001C2.9001 13.0553 2.94487 13.1001 3.0001 13.1001H13.0001C13.0553 13.1001 13.1001 13.0553 13.1001 13.0001Z"/>
</g>

</svg>






	


		</React.Fragment>
	)
}

export default ImageIcon