import React from 'react';
// import CSS from '../css/icon_main.css';

function HighPriorityIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>



<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"  className={`comman-icons ${CommanHW}`}>
<g clipPath="url(#clip0_14294_217816)">
<path d="M14.4008 9.25904C14.4008 10.3061 13.5815 15.9999 7.91406 15.9999C8.48176 15.9999 10.6938 15.3768 10.6938 13.2008C10.6938 11.5858 9.80664 11.1205 9.80664 11.1205C9.80664 11.1205 9.3964 11.4937 9.00112 11.4937C8.41392 11.4937 7.91406 11.0928 7.91406 10.0665C7.91406 9.38224 8.3048 8.63008 8.3048 8.63008C8.3048 8.63008 8.15192 8.65192 7.91406 8.7268V0.26609C8.47024 0.0483799 8.85056 0 8.85056 0C8.85056 0 8.38976 1.37653 8.38976 2.59754C8.38976 3.81856 8.892 6.00487 10.3973 6.00487C11.9026 6.00487 12.1784 4.45557 12.1784 4.45557C12.7829 5.28724 13.377 6.32395 13.377 7.54497C13.377 8.766 12.9311 9.94208 12.9311 9.94208C13.8584 9.94208 14.4008 9.25904 14.4008 9.25904Z" />
<path d="M5.10941 12.941C5.10941 14.8704 6.59521 16.0004 7.91325 16.0004C2.24696 16.0004 1.60001 10.5739 1.60001 9.65004V8.00052C2.1332 8.87596 3.58683 9.54748 3.58683 9.54748V8.1514C3.58683 2.68676 6.40101 0.856379 7.91325 0.266602V8.72732C7.06061 8.9946 5.10941 9.93452 5.10941 12.941Z" />
<path d="M7.91321 8.72656V15.9997C6.59518 15.9997 5.10938 14.8697 5.10938 12.9402C5.10938 9.93376 7.06057 8.99384 7.91321 8.72656Z" />
<path d="M8.3048 8.63086C8.3048 8.63086 7.91406 9.38302 7.91406 10.0673V8.72758C8.15192 8.6527 8.3048 8.63086 8.3048 8.63086Z" />
<path d="M10.6938 13.2007C10.6938 15.3766 8.48176 15.9998 7.91406 15.9998V10.0664C7.91406 11.0927 8.41392 11.4936 9.00112 11.4936C9.3964 11.4936 9.80664 11.1204 9.80664 11.1204C9.80664 11.1204 10.6938 11.5858 10.6938 13.2007Z" />
</g>
<defs>
<clipPath id="clip0_14294_217816">
<rect width="16" height="16" />
</clipPath>
</defs>
</svg>

		</React.Fragment>
	)
}

export default HighPriorityIcon