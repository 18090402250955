import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import withClearCache from "./ClearCache";
import CommanLoader from "./components/Loader/CommanLoader";
import { LoadScript } from '@react-google-maps/api';
import { isSolarHubPlatform } from "./hooks/utilities";
import Loader from "./components/SolarExternalPages/Loader";


const root = ReactDOM.createRoot(document.getElementById("root"));

const MainApp = () => {
  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_SECRET}
      libraries={["geometry", "places", "maps"]}
      loadingElement={ <Loader /> }
    >
      <Provider store={store}>
        <App />
      </Provider>
    </LoadScript>
  );
};

const ClearCacheComponent = withClearCache(MainApp);

root.render(<ClearCacheComponent />);
