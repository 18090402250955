import React, { useEffect, useState, useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import MyrAiIcon from "../../../assets/icons/MyrAiIcon";
import EmailDetailViewListing from "../../TaskModalComponents/EmailDetailViewListing";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ReactTooltip from "react-tooltip";
import { _l } from "../../../hooks/utilities";
import ActionDropdown from "../../Dropdowns/ActionDropdown";
import CustomerServices from "../../../services/customer-services";
import { useDispatch } from "react-redux";
import { showMessage } from "../../../actions/messages";
import {
  X,
  DotsThreeVertical,
  Image,
  Paperclip,
  PencilSimpleLine,
  LinkSimple,
  Smiley,
  Trash,
  Eye,
  ArrowBendDoubleUpLeft,
  CaretUp,
  CaretDown,
} from "phosphor-react";
import { decode } from "url-safe-base64";

import { setMailCcAssignee, getDefaultTopics } from "../../../actions/customer";
import AddScheduleEmailModal from "../../Modals/AddScheduleEmailModal";
import moment from "moment";

const EmailDetailViewAccordians = ({ mailToView, setShowLoader, id }) => {
  const [ReplySection, setReplySection] = useState(false);
  const [emailReply, setEmailReply] = useState("");
  const dispatch = useDispatch();
  const [imageBuffer, setImageBuffer] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const fileUpload = useRef(null);
  const [mailDetails, setMailDetails] = useState(mailToView);
  const threadId = mailToView["thread"]["id"];
  const forceUpdate = React.useReducer((bool) => !bool, true)[1];
  const [messagesExpanded, setMessagesExpanded] = useState(false);
  const [topMessage, setTopMessage] = useState(false);
  const [bottomMessage, setBottomMessage] = useState(false);
  const [mailCc, setMailCc] = useState("");
  const [openAssigneeDropdown, setOpenAssigneeDropdown] = useState("");
  const [selectedId, setSelectedId] = useState([]);
  const projectId = localStorage.getItem("selectedOffice");
  const [showScheduleMailModule, setShowScheduleMailModule] = useState(false);

  useEffect(() => {
    dispatch(getDefaultTopics(projectId, "", "", "MyEmail"));
    setShowLoader(false);
    collapseMessages();
  }, []);

  var attachmentIds = [];

  useEffect(() => {
    mailDetails["conversation"]["messages"].map((message) => {
      (message["payload"]["parts"] || []).map((part) => {
        if (
          part["body"]["attachmentId"] != null &&
          part["body"]["data"] == null
        ) {
          attachmentIds.push(part["body"]["attachmentId"]);
        }
      });
      return message;
    });
    if (attachmentIds.length > 0) {
      CustomerServices.getMailAttachments(
        threadId,
        JSON.stringify(attachmentIds)
      ).then((res) => {
        if (res.status) {
          var updatedMail = mailDetails;
          updatedMail["conversation"]["messages"] = updatedMail["conversation"][
            "messages"
          ].map((message) => {
            (message["payload"]["parts"] || []).map((part) => {
              if (part["body"]["attachmentId"] != null) {
                part["body"]["data"] =
                  res.data[part["body"]["attachmentId"]]["data"];
                part["body"]["updated"] = 1;
                return part;
              }
            });
            return message;
          });

          setMailDetails(updatedMail);
          collapseMessages();
          forceUpdate();
          attachmentIds = [];
        }
      });
    }
  }, []);
  const extractEmails = (text) => {
    return text.match(/([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
  };
  const htmlDecode = (content) => {
    let e = document.createElement("div");
    e.innerHTML = content;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  };
  const handleMailReply = (messageId) => {
    if (!emailReply && imagePreviews.length == 0) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_valid_topic"))
      );
    } else {
      setShowLoader(true);
      CustomerServices.sendMailReply(
        emailReply,
        messageId,
        imageBuffer,
        mailCc,
        selectedId
      ).then((res) => {
        if (res.status == 1) {
          if (res.data) {
            mailDetails["conversation"]["messages"].push(res.data);
          }
          setMailDetails(mailDetails);
          setReplySection(false);
          setEmailReply("");
          setShowLoader(false);
          setImagePreviews([]);
          setImageBuffer([]);
          collapseMessages();
          setMailCc("");
        }
      });
    }
  };
  const preparePreview = (files) => {
    let previrewArray = [];
    Array.from(files).map((img) => {
      previrewArray.push({ name: img.name, url: URL.createObjectURL(img) });
    });
    setImagePreviews(previrewArray);
  };
  const removeImageSelection = (name) => {
    imagePreviews.forEach((thumbnail) => {
      URL.revokeObjectURL(thumbnail.url);
    });
    const updatedFileList = Array.from(imageBuffer).filter(
      (img) => img.name !== name
    );
    setImageBuffer(updatedFileList);
    preparePreview(updatedFileList);
  };
  const toggleMessages = () => {
    setMessagesExpanded(!messagesExpanded);
    setTopMessage([]);
    setBottomMessage(mailDetails["conversation"]["messages"]);
  };
  const collapseMessages = () => {
    setMessagesExpanded(mailDetails["conversation"]["messages"].length > 2);
    if (mailDetails["conversation"]["messages"].length > 2) {
      setTopMessage([mailDetails["conversation"]["messages"][0]]);
      setBottomMessage([
        mailDetails["conversation"]["messages"][
          mailDetails["conversation"]["messages"].length - 1
        ],
      ]);
    } else {
      setTopMessage([]);
      setBottomMessage(mailDetails["conversation"]["messages"]);
    }
  };
  const handleAction = (action, data) => {
    var action_type = "set";
    if (selectedId.indexOf(data.details.id) > -1) {
      action_type = "remove";
    } else {
      var temp = selectedId;
      temp.push(data.details.id);
      setSelectedId(temp);
    }
    dispatch(setMailCcAssignee(data, action_type));
    if (action_type == "remove") {
      var sid = selectedId.filter((id) => {
        if (id != data.details.id) {
          return id;
        }
      });
      setSelectedId(sid);
    }
  };
  var isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
  const handleScheduleMailReply = (messageId) => {
    if (!emailReply && imagePreviews.length == 0) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_valid_topic"))
      );
    } else {
      setShowLoader(true);
      CustomerServices.sendMailReply(
        emailReply,
        messageId,
        imageBuffer,
        mailCc,
        selectedId
      ).then((res) => {
        if (res.status == 1) {
          mailDetails["conversation"]["messages"].push(res.data);
          setMailDetails(mailDetails);
          setReplySection(false);
          setEmailReply("");
          setShowLoader(false);
          setImagePreviews([]);
          setImageBuffer([]);
          collapseMessages();
          setMailCc("");
        }
      });
    }
  };
  const scheduleMail = (date) => {
    // handleMailReply
    if (!emailReply && imagePreviews.length == 0) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_valid_topic"))
      );
    } else {
      setShowLoader(true);
      CustomerServices.scheduleMail(
        emailReply,
        threadId,
        imageBuffer,
        mailCc,
        selectedId,
        date
      ).then((res) => {
        setShowScheduleMailModule(false);

        if (res.status == 1) {
          // mailDetails['conversation']['messages'].push(res.data);
          // setMailDetails(mailDetails);
          setReplySection(false);
          setEmailReply("");
          setShowLoader(false);
          setImagePreviews([]);
          setImageBuffer([]);
          collapseMessages();
          setMailCc("");
        } else {
          dispatch(showMessage("unsucess", _l("l_error"), res.message));
        }
      });
    }
  };
  return (
    <>
      <div className="custom-accordian-main overflow-hiiden-auto flex-grow-1 with-left-header-arrow emaildetailviewaccordians d-flex flex-column bg-white-03 px-2">
        <Accordion defaultActiveKey={["0"]} alwaysOpen>
          {topMessage.length > 0 &&
            topMessage.map((message) => {
              return (
                <Accordion.Item
                  eventKey={message["id"]}
                  className="comman-list p-0"
                >
                  <div className="d-flex justify-content-between align-items-center w-100 email-tab-main">
                    <div className="d-flex align-items-center maxw100minus180 ">
                      <Accordion.Header className="p-0 flex-grow-1 text-truncate w-auto">
                        <div className="d-flex align-items-center email-tab-left w-100">
                          <div className="comman-image-box h25w25 rounded-circle">
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                              style={{
                                backgroundImage: `url('${mailDetails["thread"]["profile"]}')`,
                              }}
                            ></div>
                          </div>
                          <div
                            className="c-font f-13 title-fonts px-2 w100minus50 flex-grow-1"
                            data-tip={extractEmails(
                              message["payload"]["headers"].filter(
                                (header) => header["name"] == "From"
                              )[0]["value"]
                            )}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                          >
                            <div className="text-truncate w-100">
                              <div className="text-truncate">
                                {htmlDecode(
                                  message["payload"]["headers"].filter(
                                    (header) => header["name"] == "From"
                                  )[0]["value"]
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <span className="c-font f-13">To -</span> */}
                        </div>
                      </Accordion.Header>
                    </div>
                    <div className="d-flex align-items-center email-tab-right text-nowrap">
                      <span className=" c-font f-12 color-white-60 pe-2">
                        {
                          message["payload"]["headers"].filter(
                            (header) => header["name"] == "Date"
                          )[0]["value"]
                        }
                      </span>
                      <div className="dropdown">
                        <a href="#/"
                          className="h20w20 d-flex align-items-center mx-1"
                          type="button"
                          id="EmailDetailViewListing"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <Eye size={16} className="c-icons" weight="light" />
                        </a>
                        <EmailDetailViewListing />
                      </div>
                      <div className="dropdown">
                        <a href="#/"
                          className="h20w20 d-flex align-items-center mx-1"
                          type="button"
                          id="GeneralListing"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          aria-expanded="false"
                        >
                          <DotsThreeVertical
                            size={16}
                            className="c-icons"
                            weight="light"
                          />
                        </a>
                        <ActionDropdown
                          actions={[
                            {
                              actionName: _l("l_edit"),
                              actionHandler: () => {},
                              actionIcon: "PencilSimpleLineIcon",
                            },
                            {
                              actionName: _l("l_reply"),
                              actionHandler: () => {},
                              actionIcon: "ArrowBendDoubleLeftIcon",
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  <Accordion.Body className="c-font f-14 color-white-60 pb-2">
                    <div
                      className={
                        isHTML(
                          (message["payload"]["body"]["data"] ||
                            (message["payload"]["parts"].filter(
                              (part) => part["mimeType"] == "text/html"
                            ).length > 0
                              ? message["payload"]["parts"].filter(
                                  (part) => part["mimeType"] == "text/html"
                                )
                              : message["payload"]["parts"][0]["parts"])[0][
                              "body"
                            ]["data"] ||
                            "",
                          "base64").toString("utf8")
                        )
                          ? "email-body-content"
                          : ""
                      }
                      dangerouslySetInnerHTML={{
                        __html: Buffer.from(
                          message["payload"]["body"]["data"] ||
                            (message["payload"]["parts"].filter(
                              (part) => part["mimeType"] == "text/html"
                            ).length > 0
                              ? message["payload"]["parts"].filter(
                                  (part) => part["mimeType"] == "text/html"
                                )
                              : message["payload"]["parts"][0]["parts"])[0][
                              "body"
                            ]["data"] ||
                            "",
                          "base64"
                        ).toString("utf8"),
                      }}
                    ></div>
                    {(message["payload"]["parts"] || []).filter(
                      (part) =>
                        part["body"]["attachmentId"] != null &&
                        part["body"]["data"] != null
                    ).length > 0 ? (
                      <div className="mt-4 pt-2 px-3 radius_3">
                        <div className="row flex-wrap">
                          {message["payload"]["parts"]
                            .filter(
                              (part) =>
                                part["body"]["attachmentId"] != null &&
                                part["body"]["data"] != null
                            )
                            .map((part) => {
                              return (
                                <>
                                  <div className="col-xl-2 radius_3 px-1 pb-2">
                                    <div className="c-list-icon w-100">
                                      <div className="h130w130 w-100 comman-round-box with-bg radius_3 with-react-fancybox">
                                        <div
                                          className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                                          style={{
                                            backgroundImage: `url(data:image/png;base64,${decode(
                                              part["body"]["data"]
                                            )})`,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          <a href="#/" className="list-view-toggle w-100 d-none">
            <div className=" d-flex justify-content-between list-view p-1 px-2 task-list-card">
              <span className="c-font f-10 title-fonts color-white-80 d-flex align-items-center">
                <span className="me-1 fw-bold">10+</span>
                <span className="">View More</span>
              </span>
              <div className="d-flex flex-column justify-content-between opacity-60">
                <span className="line-height-0">
                  <CaretUp size={10} className="c-icons" weight="fill" />
                </span>
                <span className="line-height-0">
                  <CaretDown size={10} className="c-icons" weight="fill" />
                </span>
              </div>
            </div>
          </a>
          {messagesExpanded ? (
            <div
              className="chat-main"
              onClick={() => {
                toggleMessages();
              }}
            >
              <div class="chat-date-seperator px-0">
                <span class="seperator-line pt-1 border-start-0 border-end-0"></span>
                <a href="#/" class="date-text color-white-80 c-font f-10 title-fonts text-nowrap px-0">
                  <div className="border d-flex h30w30 rounded-circle box-shadow-3">
                    <span className="m-auto letter_spacing05">
                      {mailDetails["conversation"]["messages"].length - 2}
                    </span>
                  </div>
                </a>
                <span class="seperator-line pt-1 border-start-0 border-end-0"></span>
              </div>
            </div>
          ) : (
            <></>
          )}
          {bottomMessage.length > 0 &&
            bottomMessage.map((message, i) => {
              return (
                <Accordion.Item
                  eventKey={
                    i == bottomMessage.length - 1 || bottomMessage.length == 1
                      ? "0"
                      : message["id"]
                  }
                  className="comman-list p-0"
                >
                  <div className="d-flex justify-content-between align-items-center w-100 email-tab-main">
                    <div className="d-flex align-items-center maxw100minus180 ">
                      <Accordion.Header className="p-0 flex-grow-1 text-truncate w-auto">
                        <div className="d-flex align-items-center email-tab-left w-100">
                          <div className="comman-image-box h25w25 rounded-circle">
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                              style={{
                                backgroundImage: `url('${mailDetails["thread"]["profile"]}')`,
                              }}
                            ></div>
                          </div>
                          <div
                            className="c-font f-13 title-fonts px-2 w100minus50 flex-grow-1"
                            data-tip={extractEmails(
                              message["payload"]["headers"].filter(
                                (header) => header["name"] == "From"
                              )[0]["value"]
                            )}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                          >
                            <div className="text-truncate w-100">
                              <div className="text-truncate">
                                {htmlDecode(
                                  message["payload"]["headers"].filter(
                                    (header) => header["name"] == "From"
                                  )[0]["value"]
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <span className="c-font f-13">To -</span> */}
                        </div>
                      </Accordion.Header>
                    </div>
                    <div className="d-flex align-items-center email-tab-right text-nowrap">
                      <span className=" c-font f-12 color-white-60 pe-2">
                        {
                         moment( message["payload"]["headers"].filter(
                            (header) => header["name"] == "Date"
                          )[0]["value"]).format("DD-MM-YYYY hh:mm a")
                        } " "
                        (
                          {moment( message["payload"]["headers"].filter(
                            (header) => header["name"] == "Date"
                          )[0]["value"]).fromNow()}
                        )
                      </span>
                      <div className="dropdown">
                        <a href="#/"
                          className="h20w20 d-flex align-items-center mx-1"
                          type="button"
                          id="GeneralListing"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          aria-expanded="false"
                        >
                          <DotsThreeVertical
                            size={16}
                            className="c-icons"
                            weight="light"
                          />
                        </a>
                        <ActionDropdown
                          actions={[
                            {
                              actionName: _l("l_edit"),
                              actionHandler: () => {},
                              actionIcon: "PencilSimpleLineIcon",
                            },
                            {
                              actionName: _l("l_reply"),
                              actionHandler: () => {},
                              actionIcon: "ArrowBendDoubleLeftIcon",
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  <Accordion.Body className="c-font f-14 color-white-60 pb-2">
                    <div
                      className={
                        isHTML(
                          (message["payload"]["body"]["data"] ||
                            (message["payload"]["parts"].filter(
                              (part) => part["mimeType"] == "text/html"
                            ).length > 0
                              ? message["payload"]["parts"].filter(
                                  (part) => part["mimeType"] == "text/html"
                                )
                              : message["payload"]["parts"][0]["parts"])[0][
                              "body"
                            ]["data"] ||
                            "",
                          "base64").toString("utf8")
                        )
                          ? "email-body-content"
                          : ""
                      }
                      dangerouslySetInnerHTML={{
                        __html: Buffer.from(
                          message["payload"]["body"]["data"] ||
                            (message["payload"]["parts"].filter(
                              (part) => part["mimeType"] == "text/html"
                            ).length > 0
                              ? message["payload"]["parts"].filter(
                                  (part) => part["mimeType"] == "text/html"
                                )
                              : message["payload"]["parts"][0]["parts"])[0][
                              "body"
                            ]["data"] ||
                            "",
                          "base64"
                        ).toString("utf8"),
                      }}
                    ></div>
                    {(message["payload"]["parts"] || []).filter(
                      (part) =>
                        part["body"]["attachmentId"] != null &&
                        part["body"]["data"] != null
                    ).length > 0 ? (
                      <div className="mt-4 pt-2 px-3 radius_3">
                        <div className="row flex-wrap">
                          {message["payload"]["parts"]
                            .filter(
                              (part) =>
                                part["body"]["attachmentId"] != null &&
                                part["body"]["data"] != null
                            )
                            .map((part) => {
                              return (
                                <>
                                  <div className="col-xl-2 radius_3 px-1 pb-2">
                                    <div className="c-list-icon w-100">
                                      <div className="h130w130 w-100 comman-round-box with-bg radius_3 with-react-fancybox">
                                        <div
                                          className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                                          style={{
                                            backgroundImage: `url(data:image/png;base64,${decode(
                                              part["body"]["data"]
                                            )})`,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
        </Accordion>
      </div>
      {/* <div className="file-list-wrapper">
        <div className="d-flex flex-wrap">
          <div className="file-image-list pb-4">
            <div className="c-list-icon w-100 position-relative with-overlay">
              <div className="h130w130 comman-round-box with-bg radius_3 with-react-fancybox">
                <div className="comman-bg-img h-100 w-100 bg-style-cover radius_3"style={{ backgroundImage: `url('${User_2}')` }}></div>
              </div>
              <a href="#/" className="line-height-0 file-action-option"
                type="button"
                id="GeneralListing"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false">
                <DotsThreeVertical size={18} className="c-icons" weight="light" />
              </a>
              <ActionDropdown
                actions={[
                  {
                    actionName: _l("l_delete"),
                    actionHandler: () => {
                    },
                    actionIcon: "TrashIcon",
                  }
                ]}
              />
            </div>
            <div class="max-w-130px pt-2">
              <div class="c-font text-truncate f-12 color-white-60 ">aiony-haust-3TLl_97HNJo-unsplash.jpg</div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        className={`replying-box-main d-flex flex-column  ${
          ReplySection
            ? "bg-white-03 flex-grow-1 custom-accordian-Header-sticky with-bg-color-white with-min-height"
            : "mt-auto"
        } `}
      >
        {ReplySection ? (
          <div className="reply-box d-flex flex-column flex-grow-1">
            <div className="d-flex justify-content-between pb-3">
              <div className="reply-left d-flex">
                <a href="#/" className="h20w20 d-flex align-items-center mx-1">
                  <ArrowBendDoubleUpLeft
                    size={16}
                    className="c-icons"
                    weight="light"
                  />
                </a>
                <div className="position-relative px-2 d-flex align-items-center comman_action_icon">
                  <Form.Group className="w100minus30">
                    <Form.Control
                      className="bg-transparent mx-1 p-2 h-100"
                      value={mailCc}
                      onChange={(e) => {
                        setMailCc(e.target.value);
                      }}
                      placeholder="Enter text"
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="d-flex align-items-center email-tab-right text-nowrap reply-right">
                <span className=" c-font f-12 color-white-60 pe-2">
                  {Date()}
                </span>
                <a href="#/"
                  className="h20w20 d-flex align-items-center mx-1"
                  onClick={() => {
                    setReplySection(false);
                    collapseMessages();
                  }}
                >
                  <Trash size={16} className="c-icons" weight="light" />
                </a>
              </div>
            </div>
            <div className="d-flex position-relative flex-grow-1">
              <div className="h30w30 comman-image-box rounded-circle">
                <div
                  className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                  style={{
                    backgroundImage: `url('${mailDetails["thread"]["profile"]}')`,
                  }}
                ></div>
              </div>
              <Form.Group className="w100minus30">
                <Form.Control
                  className="bg-transparent border-0 h-100 pt-0"
                  as="textarea"
                  value={emailReply}
                  onChange={(e) => setEmailReply(e.target.value)}
                  placeholder="Enter text"
                  rows={3}
                />
              </Form.Group>
              <React.Fragment>
                {imagePreviews.length ? (
                  <div className="upload-image-preview ">
                    <div className="d-flex align-items-center justify-content-start">
                      {imagePreviews.map((preview, index) => {
                        return (
                          <div
                            key={index}
                            className="comman-image-box h25w25 radius_3 upload-image with-margin"
                          >
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                              style={{
                                backgroundImage: `url(
                                  '${preview.url}'
                                )`,
                              }}
                            ></div>
                            <a href="#/"
                              className="upload-close"
                              onClick={() => {
                                removeImageSelection(preview.name);
                              }}
                            >
                              <X size={10} className="c-icons" weight="light" />
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </React.Fragment>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          className={`${
            ReplySection
              ? "d-flex align-items-center justify-content-between flex-wrap pt-2"
              : ""
          }`}
        >
          {ReplySection ? (
            <div className="d-flex align-items-center flex-wrap">
              <a href="#/" className="me-2" onClick={() => fileUpload.current.click()}>
                <input
                  type="file"
                  className="d-none"
                  id="reply_files"
                  multiple
                  accept="image/*"
                  onChange={(event) => {
                    setImageBuffer(event.target.files);
                    preparePreview(event.target.files);
                  }}
                  ref={fileUpload}
                  onClick={(e) => {
                    e.target.value = "";
                  }}
                />
                <Paperclip size={16} className="c-icons" weight="light" />
              </a>
              <a href="#/" className="me-2">
                <LinkSimple size={16} className="c-icons" weight="light" />
              </a>
              <a href="#/" className="me-2">
                <Smiley size={16} className="c-icons" weight="light" />
              </a>
              <a href="#/" className="me-2">
                <Image size={16} className="c-icons" weight="light" />
              </a>
              <a href="#/" className="me-2">
                <PencilSimpleLine
                  size={16}
                  className="c-icons"
                  weight="light"
                />
              </a>
              <a href="#/" className="me-2">
                <DotsThreeVertical
                  size={16}
                  className="c-icons"
                  weight="light"
                />
              </a>
              <a href="#/" className="me-2">
                <MyrAiIcon className="HW16" />
              </a>
            </div>
          ) : (
            <></>
          )}
          <div className={`Reply-button ${ReplySection ? "" : "text-end"}`}>
            <Button
              variant="secondary"
              size="sm"
              className="ms-auto mx-3"
              onClick={() => {
                if (ReplySection) {
                  setShowScheduleMailModule(true);
                }
              }}
            >
              {_l("l_schedule_replay")}
            </Button>
            <Button
              variant="primary"
              size="sm"
              className=""
              onClick={() => {
                setReplySection(true);
                collapseMessages();
                if (ReplySection) {
                  handleMailReply(mailDetails["thread"]["id"]);
                }
                // jqury(".taskDetailModal .accordion-collapse").removeClass("show");
              }}
            >
              {_l("l_replay")}
            </Button>
          </div>
        </div>
      </div>

      <ReactTooltip />
      <AddScheduleEmailModal
        show={showScheduleMailModule}
        handleClose={() => {
          setShowScheduleMailModule(false);
        }}
        handleSubmit={scheduleMail}
      />
    </>
  );
};

export default EmailDetailViewAccordians;
{
}
