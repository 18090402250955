import React, { useState, useEffect } from "react";
import CommanLoader from "../../components/Loader/CommanLoader";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import CustomerServices from "../../services/customer-services";
import { Equals,Info } from "phosphor-react";
import { _l } from "../../hooks/utilities";
import NoUser from "../../assets/icons/placeholder/NoUser";
import EquipmentListing from "../TaskModalComponents/EquipmentListing";
import EquipmentCard from "./EquipmentCard";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";

const EquipmentStatusTable = ({
  projectId,
  getEquipment,
  taskTotalCount,
  showLoader,
  selectedEquipmentId = []
}) => {
  const { isAppliedUnreadComment, taskFilters } = useSelector((state) => state.customer);
  const { defaultTopics,} = useSelector((state) => state.customer);
  const [getTaskList, setTaskList] = useState([])
  const [totalTime, setTotalTime] = useState("");
  const [totalToDOCount, setToDOCount] = useState("");
  const [totalInProgressCount, setTotalInProgressCount] = useState("");
  const [totalDoneCount, setTotalDoneCount] = useState("");
  const [totalToDoTime, setTotalToDoTime] = useState("00:00");
  const [totalInProgressTime, setTotalInProgressTime] = useState("00:00");
  const [totalDoneTime, setTotalDoneTime] = useState("00:00");
  const [showListLoader, setShowListLoader] = useState(false);
  const [showEmpolyeeList, setShowEmpolyeeList] = useState(false);
  const { globalSearchString  } = useSelector((state) => state.customer);
  const [equipmentSearchString, setEquipmentSearchString] = useState("")
  const [newEquipmentList, setnewEquipmentList] = useState([])


  const [taskId, setTaskId] = useState(0)
  const [getEquipments, setGetEquipments] = useState([])
  const timeConvert = (n) => {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    // return num + " minutes = " + rhours + " hour(s) and " + rminutes + " minute(s).";
    return rhours + ":" + rminutes;
  };

  useEffect(() => {
    if (globalSearchString !== "" || globalSearchString !== undefined) {
      setEquipmentSearchString(globalSearchString);
    }
}, [globalSearchString])

  useEffect(() => {
    if (selectedEquipmentId.length == 0) {
      setGetEquipments(getEquipments)
      setnewEquipmentList(getEquipments)
    }
    let todoCount = 0;
    let inProgressCount = 0;
    let doneCount = 0;
    let tempTotalTime = "00:00";
    let todoTime = "00:00";
    let inProgresTime = "00:00";
    let doneTime = "00:00";
    let inprogress_minute = 0;
    let inprogress_hour = 0;
    let completed_minute = 0;
    let completed_hour = 0;
    let todo_minute = 0;
    let todo_hour = 0;
    let total_time_minute = 0;
    let total_time_hour = 0;
    if (getEquipment.length) {
      // for (let i = 0; i < getEquipment.length; i++) {
      //   inprogress_hour += parseInt(
      //     getEquipment[i].ongoing_task_total_time.split(":")[0]
      //   )
      //     ? parseInt(getEquipment[i].ongoing_task_total_time.split(":")[0]) * 60
      //     : 0;
      //   inprogress_minute += parseInt(
      //     getEquipment[i].ongoing_task_total_time.split(":")[1]
      //   )
      //     ? parseInt(getEquipment[i].ongoing_task_total_time.split(":")[1])
      //     : 0;

      //   todo_hour += parseInt(
      //     getEquipment[i].to_do_task_total_time.split(":")[0]
      //   )
      //     ? parseInt(getEquipment[i].to_do_task_total_time.split(":")[0]) * 60
      //     : 0;
      //   todo_minute += parseInt(
      //     getEquipment[i].to_do_task_total_time.split(":")[1]
      //   )
      //     ? parseInt(getEquipment[i].to_do_task_total_time.split(":")[1])
      //     : 0;

      //   completed_hour += parseInt(
      //     getEquipment[i].completed_task_total_time.split(":")[0]
      //   )
      //     ? parseInt(getEquipment[i].completed_task_total_time.split(":")[0]) *
      //     60
      //     : 0;
      //   completed_minute += parseInt(
      //     getEquipment[i].completed_task_total_time.split(":")[1]
      //   )
      //     ? parseInt(getEquipment[i].completed_task_total_time.split(":")[1])
      //     : 0;

      //   total_time_hour += parseInt(getEquipment[i].total_time.split(":")[0])
      //     ? parseInt(getEquipment[i].total_time.split(":")[0]) * 60
      //     : 0;
      //   total_time_minute += parseInt(getEquipment[i].total_time.split(":")[1])
      //     ? parseInt(getEquipment[i].total_time.split(":")[1])
      //     : 0;
      // }

      getEquipment.map((Employee) => {
        todoCount += parseInt(Employee.todo_count);
        inProgressCount += parseInt(Employee.ongoing_count);
        doneCount += parseInt(Employee.completed_count);
        // totalTimeCount(tempTotalTime, Employee.to_do_task_total_time);
        // totalTimeCount(inProgresTime, Employee.ongoing_task_total_time);
        // totalTimeCount(doneTime, Employee.completed_task_total_time);
      });
    }
    setToDOCount(todoCount);
    setTotalInProgressCount(inProgressCount);
    setTotalDoneCount(doneCount);
    setTotalDoneTime(timeConvert(completed_hour + completed_minute));
    setTotalToDoTime(timeConvert(todo_hour + todo_minute));
    setTotalInProgressTime(timeConvert(inprogress_hour + inprogress_minute));
    setTotalTime(timeConvert(total_time_hour + total_time_minute));
  }, [getEquipment]);

  useEffect(() => {
    let getEquipmentsData = getEquipments
    defaultTopics && defaultTopics.map((data)=>{
      if (data.main_key == "equipments") {
        setGetEquipments(data.categories)
        getEquipmentsData = data.categories
      }
    })
    if (selectedEquipmentId.length > 0) {
      let tempEmployees = getEquipmentsData.filter((employee) => selectedEquipmentId.includes(employee.filter_key))
      setnewEquipmentList(tempEmployees)
    } else {
      setnewEquipmentList(getEquipmentsData)
    }
  }, [JSON.stringify(defaultTopics), JSON.stringify(getEquipment), taskFilters, selectedEquipmentId])
  const totalTimeCount = (time1, time2) => {
    var hour = 0;
    var minute = 0;
    var second = 0;

    var splitTime1 = time1.split(":");
    var splitTime2 = time2.split(":");

    hour = parseInt(splitTime1[0]) + parseInt(splitTime2[0]);
    minute = parseInt(splitTime1[1]) + parseInt(splitTime2[1]);
    hour = hour + minute / 60;
    minute = minute % 60;
    second = parseInt(splitTime1[2]) + parseInt(splitTime2[2]);
    minute = minute + second / 60;
    second = second % 60;
    return hour + ":" + minute;
  };

  const taskList = (status, staffid) => {
    setShowListLoader(true)
    let unReadFilter = isAppliedUnreadComment ? isAppliedUnreadComment : false
    let fromDate = localStorage.getItem('selectedDate') ? moment(localStorage.getItem('selectedDate')).format("YYYY-MM-DD") : new Date()

    let toDate = ''
    let dateRangeFilters = taskFilters && taskFilters.length ? taskFilters.filter((dateRangeFilter) => dateRangeFilter.main_key == "date_range_filter") : []


    if (dateRangeFilters && dateRangeFilters.length) {
      fromDate = localStorage.getItem('fromDate')
      toDate = localStorage.getItem('toDate')
    }
    CustomerServices.getEmployeeTask(status, staffid, unReadFilter, fromDate != '' ? fromDate : '', toDate).then((res) => {
      setShowListLoader(false)
      if (res.status) {
        setTaskList(res.data);
        setShowEmpolyeeList(true)
      }
    });
  }
  const updatedCommentCount = (id) => {
    let taskTemp = getTaskList.map((task) => {
      if (task.id == id) {
        task['comments_count'] = parseInt(task['comments_count']) + 1
        return task
      }
      return task;
    })

    setTaskList(taskTemp)
  }

  // useEffect(() => {
  //   if (selectedEquipmentId.length > 0) {
  //     let tempEmployees = getEquipments.filter((employee) => selectedEquipmentId.includes(employee.filter_key))
  //     setnewEquipmentList(tempEmployees)
  //   } else {
  //     setnewEquipmentList(getEquipments)
  //   }
  // }, [taskFilters, selectedEquipmentId, JSON.stringify(getEquipment), defaultTopics])
  return (
    <>
      {/* <div className="h_calc77 d-flex flex-column pt-2"> */}
      <div className="comman_vertical_btn_h vertical_scroll120 comman-white-box-shadow light-theme-white-bg">
          <div className="comman-data-table employee-table small-data-table overflow-auto comman-content-scroll-wrapper flex-grow-1">
            <table className="dataTable text-center">
              <thead className="comman-white-box-shadow">
                <tr>
                  <th style={{ width: "20%" }} className="text-start">
                  {_l('l_name_and_progress')}
                  </th>
                  <th style={{ width: "10%" }}>{_l('l_duration')} (hrs)</th>
                  <th style={{ width: "10%" }}>{_l('l_surface')} (m<sup>2</sup>)</th>
                  <th style={{ width: "15%" }}>
                    <span className="c-dots  c-7 bg-warning rounded-circle me-2"></span>
                    <span>{_l('l_request')}</span>
                  </th>
                  <th style={{ width: "15%" }}>
                    <span className="c-dots  c-7 in-progress-white rounded-circle me-2"></span>
                    <span>{_l('l_todo_kpi_name')}</span>
                  </th>
                  <th style={{ width: "15%" }}>
                    <span className="c-dots c-7 rounded-circle in-progress-blue me-2"></span>
                    <span>{_l('l_ongoing_kpi')}</span>
                  </th>
                  <th style={{ width: "15%" }}>
                    <span className="c-dots  c-7 done rounded-circle me-2"></span>
                    <span>{_l('l_done')}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {showLoader ? (
                  <>
                    <CommanLoader className="comman-main-loader-wrapper" />
                  </>
                ) : newEquipmentList && newEquipmentList.length ? (
                  newEquipmentList.filter((data)=>data && data.title.toLowerCase().includes( equipmentSearchString.toLocaleLowerCase())).map((Equ, index) => {
                    return (
                      <EquipmentCard Equipment={Equ} projectId={projectId} taskList={taskList} updatedCommentCount={updatedCommentCount} setTaskList={setTaskList} getEquipment={getEquipment} index={index} />
                    );
                  })
                ) : (
                  <tr className="">
                    <td className="border-0" colSpan={7}>
                      <div className="comman-placeholder-wrapper mt-4 w-100 justify-content-center">
                        <div className="d-flex flex-column align-items-center">
                          <div
                            alt="NoTodoTask"
                            className="big-image"
                          >
                            <NoUser className="opacity-25" />
                            </div>
                          <div className="color-white-80 py-2 title-fonts text-center w-100">
                          {_l('l_no_equipment_found')}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot className="position-sticky-bottom bottom_51px">
                <tr
                  className={` c-font f-15 color-white-80 ${getEquipment && getEquipment.length ? "" : ""
                    }`}
                >
                  <td className="tfoot-rounded text-start" style={{ width: "20%" }}>
                    <div className="tfoot-rounded me-3">
                      <div className="d-flex align-items-center">
                        <a href="#/" className="me-2">
                          <Equals size={20} className="c-icons" weight="light" />
                        </a>
                        <span className="">
                          {_l('l_total_count')}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="rounded-0" style={{ width: "10%" }}>
                    <div className="border-right -d-flex ">
                      <span className="">
                        {Object.keys(taskTotalCount).length && taskTotalCount.total_duration
                          ? taskTotalCount.total_duration
                          : 0}
                      </span>
                    </div>
                  </td>
                  <td className="rounded-0" style={{ width: "10%" }}>
                    <div className="border-right -d-flex ">
                      <span className="">
                        {Object.keys(taskTotalCount).length && taskTotalCount.total_surface
                          ? taskTotalCount.total_surface
                          : 0}
                      </span>
                    </div>
                  </td>
                  <td className="rounded-0" style={{ width: "15%" }}>
                    <div className="border-right -d-flex ">
                      <span className="">
                        {Object.keys(taskTotalCount).length && taskTotalCount.request_task_count
                          ? taskTotalCount.request_task_count
                          : 0}
                      </span>
                      <span
                        className={` c-font f-15 color-white-60 ps-2 ${totalInProgressTime == "0:0" ? "d-none" : ""
                          }`}
                      >
                        {totalInProgressTime != "0:0"
                          ? totalInProgressTime
                          : "00:00"}
                      </span>
                    </div>
                  </td>
                  <td className="rounded-0" style={{ width: "15%" }}>
                    <div className="border-right ">
                      {Object.keys(taskTotalCount).length
                        ? taskTotalCount.to_do_task_count
                        : 0}
                      <span
                        className={` c-font f-15 color-white-60  ps-2 ${totalToDoTime == "0:0" ? "d-none" : ""
                          }`}
                      >
                        {totalToDoTime != "0:0" ? totalToDoTime : "00:00"}
                      </span>
                    </div>
                  </td>
                  <td className="rounded-0" style={{ width: "15%" }}>
                    <div className="border-right -d-flex ">
                      <span className="">
                        {Object.keys(taskTotalCount).length && taskTotalCount.on_going_task_count
                          ? taskTotalCount.on_going_task_count
                          : 0}
                      </span>
                      <span
                        className={` c-font f-15 color-white-60 ps-2 ${totalInProgressTime == "0:0" ? "d-none" : ""
                          }`}
                      >
                        {totalInProgressTime != "0:0"
                          ? totalInProgressTime
                          : "00:00"}
                      </span>
                    </div>
                  </td>
                  <td className="tfoot-rounded-end" style={{ width: "15%" }}>
                    <div className="-d-flex ">
                      <span className="">
                        {Object.keys(taskTotalCount).length && taskTotalCount.completed_task_count
                          ? taskTotalCount.completed_task_count
                          : 0}
                      </span>
                      <span
                        className={` c-font f-15 color-white-60  ps-2 ${totalDoneTime == "0:0" ? "d-none" : ""
                          }`}
                      >
                        {totalDoneTime != "0:0" ? totalDoneTime : "00:00"}
                      </span>
                    </div>
                  </td>
                </tr>
              </tfoot>
              <tfoot className="position-sticky-bottom d-none">
                <tr
                  className={` ${getEquipment && getEquipment.length ? "" : " d-none"
                    }`}
                >
                  <td className="tfoot-rounded text-start">
                    <div className="tfoot-rounded me-3">
                      <div className="d-flex align-items-center">
                        <a href="#/" className="me-2">
                          <Equals size={20} className="c-icons" weight="light" />
                        </a>
                        <span className="">
                        {_l('l_total_count')}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="tfoot-rounded-start">
                    <div className="border-right ">
                      {totalTime != "0:0" ? totalTime : "00:00"}
                    </div>
                  </td>
                  <td className="rounded-0">
                    <div className="border-right ">
                      {/* {totalToDOCount ? totalToDOCount : '0'} */}
                      {Object.keys(taskTotalCount).length && taskTotalCount.total_duration
                        ? taskTotalCount.total_duration
                        : 0}
                      <span
                        className={` c-font f-15 color-white-60  ps-2 ${totalToDoTime == "0:0" ? "d-none" : ""
                          }`}
                      >
                        {totalToDoTime != "0:0" ? totalToDoTime : "00:00"}
                      </span>
                    </div>
                  </td>
                  <td className="rounded-0">
                    <div className="border-right -d-flex ">
                      <span className="">
                        {/* {totalInProgressCount ? totalInProgressCount : '0'} */}
                        {Object.keys(taskTotalCount).length && taskTotalCount.on_going_task_count
                          ? taskTotalCount.on_going_task_count
                          : 0}
                      </span>
                      <span
                        className={` c-font f-15 color-white-60 ps-2 ${totalInProgressTime == "0:0" ? "d-none" : ""
                          }`}
                      >
                        {totalInProgressTime != "0:0"
                          ? totalInProgressTime
                          : "00:00"}
                      </span>
                    </div>
                  </td>
                  <td className="tfoot-rounded-end">
                    <div className="-d-flex ">
                      <span className="">
                        {/* {totalDoneCount ? totalDoneCount : '0'} */}
                        {Object.keys(taskTotalCount).length && taskTotalCount.completed_task_count
                          ? taskTotalCount.completed_task_count
                          : 0}
                      </span>
                      <span
                        className={` c-font f-15 color-white-60  ps-2 ${totalDoneTime == "0:0" ? "d-none" : ""
                          }`}
                      >
                        {totalDoneTime != "0:0" ? totalDoneTime : "00:00"}
                      </span>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
            <table className="dataTable text-center mt-auto position-sticky-bottom comman-white-box-shadow">
             
            </table>
          </div>
        <div className="comman-content-bottom-btn d-none">
          <div className="comman-data-table">
            <table className="dataTable title-fonts small-padding">
              <tfoot>
                <tr className={``}>
                  <td className="me-3" style={{ width: "30%" }}>
                    <div className="name-with-progress max-w400">
                      <div className="d-flex align-items-center">
                        <a href="#/" className="me-2">
                        <Info size={20} className="c-icons" weight="light" />
                        </a>
                        <span className="">
                          {_l('l_total_count')}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="" style={{ width: "15%" }}>
                    Shipping cost
                  </td>
                  <td className="" style={{ width: "15%" }}>
                    Shipping cost
                  </td>
                  <td className="" style={{ width: "15%" }}>
                    Shipping cost
                  </td>
                  <td className="" style={{ width: "15%" }}>
                    Shipping cost
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      {/* {
        showEmpolyeeList ? */}
      <EquipmentListing list={getTaskList.map((task) => {
        return {
          task_name: task.task_name ? task.task_name : '',
          name: task.creator_details && task.creator_details.full_name ? task.creator_details.full_name : '',
          image: task.creator_details && task.creator_details.profile_image_url ? task.creator_details.profile_image_url : '',
          id: task.id ? task.id : '0',
          comments_count: task.comments_count ? task.comments_count : '0',
          unread_comment_count: task.unread_comment_count ? task.unread_comment_count : '0'
        };

      })} handler={() => { }} showLoader={showListLoader} getTaskList={getTaskList} setTaskList={setTaskList} />
      {/* : <></>
      } */}

      {/* </div> */}
    </>
  );
};

export default EquipmentStatusTable;
