import { Calendar, CalendarBlank, Check, FileArrowDown, Files } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  _l,
  roundOffPowerValue,
  showError,
  showSuccess,
} from "../../hooks/utilities";
import MairieLogo from "../../assets/images/mairie.svg";
import Consuellogo from "../../assets/images/consuel.svg";
import RaccordementLogo from "../../assets/images/raccordement.svg";
import Signature from "../../assets/icons/Signature.jsx";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import benchmarkServices from "../../services/benchmark-services";
import { getExternalBenchmarkDetails } from "../../actions/benchmark-actions/benchmark-actions";
import customerServices from "../../services/customer-services";
import { SetSelectedTask } from "../../actions/customer";

const AcceptProposalCustomer = ({
  proposal,
  handleNextStep = () => {},
  setShowTaskSignature = () => {},
}) => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const hash = searchParams.get("hash");
  const proposal_idSearchParams = searchParams.get("proposal_id");
  const { benchmarkDetail } = useSelector((state) => state.customer);

  const [proposalTasks, setProposalTasks] = useState({
    mairie: [],
    consuel: [],
    raccordement: [],
    installation: [],
  });
  const [installationDate, setInstallationDate] = useState("");
  const [compeletTaskDate, setCompeletTaskDate] = useState("");
  const [showInstallationDate, setShowInstallationDate] = useState(false);
  const [contractSignned, setContractSignned] = useState(false);
  const [fileUrl, setFileUrl] = useState('')

  const handleDateChange = (date, type) => {
    if (type === "installation") {
      try {
        benchmarkServices
          .setInstallationDateForMandate(benchmarkDetail.id, date)
          .then((res) => {
            if (res && res.status) {
              showSuccess(res.message);
              setInstallationDate(date);
              dispatch(getExternalBenchmarkDetails(hash));
            } else {
              showError(
                res && res.message ? res.message : "l_something_went_wrong"
              );
            }
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      setCompeletTaskDate(date);
    }
  };

  const handleTaskSelect = (task) =>{
    customerServices.getTaskData(
      localStorage.getItem("selectedOffice"),
      task.id,
      null,
      task.hash,
      ""
    ).then((res)=>{
      if (res &&  res.status) {
        dispatch(SetSelectedTask(res.data));
        handleNextStep("task-details");
      }else{
        showError("l_something_went_wrong");
      }
    })
  }

  useEffect(() => {
    if (benchmarkDetail) {
      let tasks = benchmarkDetail.solar_task_ids;
      let doneTasks = tasks.filter((t) => [1,2,3,"1","2","3"].includes(t.solar_task_type) && t.status == 5)
      setShowInstallationDate(doneTasks.length > 0)
      setProposalTasks({
        mairie:
          tasks && tasks.length
            ? tasks.filter((t) => t.solar_task_type == 1)
            : [],
        consuel:
          tasks && tasks.length
            ? tasks.filter((t) => t.solar_task_type == 2)
            : [],
        raccordement:
          tasks && tasks.length
            ? tasks.filter((t) => t.solar_task_type == 3)
            : [],
        installation:
          tasks && tasks.length
            ? tasks.filter((t) => t.solar_task_type == 4)
            : [],
      });

      if (
        benchmarkDetail &&
        benchmarkDetail.solar_task_ids &&
        benchmarkDetail.solar_task_ids.length
      ) {
        let task = benchmarkDetail.solar_task_ids.filter(
          (t) => t.solar_task_type == 4
        );
        if (task && task.length > 0) {
          setInstallationDate(
            task[0].startdate ? new Date(task[0].startdate ? task[0].startdate : new Date()) : ""
          );
        }
      }
    }
    setContractSignned(benchmarkDetail.benchmark_signed_docs.length > 0);
  }, [benchmarkDetail]);

  useEffect(() => {
        if (benchmarkDetail && benchmarkDetail.proposal_list && benchmarkDetail.proposal_list[0] && benchmarkDetail.proposal_list[0] ) {
          let currentProposal = benchmarkDetail.proposal_list[0].proposals.find(
            (w) => w.proposal_id == proposal_idSearchParams
          );
          if (currentProposal.contract_file && currentProposal.contract_file != '') {
            setFileUrl(currentProposal.contract_file);
          }
        }
  }, [benchmarkDetail]);

  return (
    <div className="col-12 col-xl-10 col-xxl-10 registration-content flex-grow-1 mx-auto row mb-7 pt-20pximp">
      <div className="col-lg-2"></div>
      <div className="align-items-center col-lg-8 d-flex mx-auto flex-column">
        <div className="border radius_3 bg-white-03 comman-white-box-shadow w-100">
          <div className="align-items-center border-bottom border-last-none d-flex flex-wrap flex-lg-nowrap p-20 justify-content-between ">
            <a
              href="#/"
              className="d-flex align-items-center flex-grow-1"
              onClick={() => {
                handleNextStep("proposal-detail");
              }}
            >
              <div className="h50w150 comman-round-box with-bg bg-white-05 me-10px">
                <div
                  className="comman-bg-img h-100 w-100 bg-style-cover"
                  style={{
                    backgroundImage: `url('${benchmarkDetail.solar_map}')`,
                  }}
                ></div>
              </div>
              <div className="w-100">
                <div className="c-font f-14 fw-semibold title-fonts d-flex align-items-center  w100minus100">
                  Installation de panneaux solaire
                  {benchmarkDetail.solar_items
                    ? ` ${Math.floor(
                        roundOffPowerValue(
                          benchmarkDetail.solar_items.electricity_production /
                            1000
                        )
                      )} Kwc`
                    : 0}
                </div>
              </div>
            </a>
            <div className=" d-flex align-items-center comman_action_icon gap-3">
              <div className="BigPill align-items-center c-font  d-flex f-12 fw-medium gap10px h32wauto min-w-100imp text-black text-uppercase">
                <span
                  className={`c-dots c-10 ${
                    proposal && proposal.status == 3 ? "done" : proposal && proposal.status == 2 ?  "bg-red"  : "bg-warning"
                  }`}
                ></span>
                <span className="ps-0">
                  {proposal && proposal.status == 3 ? _l("l_accepted") : proposal && proposal.status == 2 ?  _l("l_rejected") : _l("l_pending")}
                </span>
              </div>
            </div>
          </div>

          <a
            href="#/"
            className={`align-items-center border-bottom border-last-none d-flex flex-wrap  flex-lg-nowrap p-20 justify-content-between ${proposal && proposal.status == 3 ? "" : "for-disabled opacity-25"}`}
            onClick={() => {
              handleNextStep("assistance-mandate");
            }}
          >
            <div className="d-flex align-items-center flex-grow-1">
              <div className="h50w150 comman-round-box with-bg bg-white-05 me-10px">
                <div className="comman-bg-img h-100 w-100 bg-style-cover d-flex">
                  <Files size={34} className="c-icons" />
                </div>
              </div>
              <div className="w-100">
                <div className="c-font f-14 fw-semibold title-fonts d-flex align-items-center  w100minus100">
                  Administrative Mandate (External page)
                </div>
              </div>
            </div>
            <div className=" d-flex align-items-center comman_action_icon gap-3">
              {fileUrl ? 
                <div className="align-items-center bg-white-05 border d-flex h32w32 justify-content-center rounded-1"
                  onClick={(e) => {e.stopPropagation()
                    window.open(fileUrl, "_blank")}}
                >
                  <FileArrowDown size={18} />
                </div>
              : <></>}
              <Button
                variant="primary"
                className="btn  position-relative  d-flex align-items-center"
              >
                <span className="">Signer en ligne</span>
              </Button>
            </div>
          </a>

          <a href="#/" className={`align-items-center border-bottom border-last-none d-flex flex-wrap flex-lg-nowrap p-20 justify-content-between ${ proposal && proposal.status == 3 &&contractSignned && proposalTasks.mairie.length ? "" : "for-disabled opacity-25"}`}
          onClick={()=>{
            handleTaskSelect(proposalTasks.mairie[0])
          }}>
            <div className="d-flex align-items-center flex-grow-1">
              <div className="h50w150 comman-round-box with-bg bg-white-05 me-10px">
                <div
                  className="comman-bg-img h-100 w-100 bg-style-contain"
                  style={{ backgroundImage: `url(${MairieLogo})` }}
                ></div>
              </div>
              <div className="w-100">
                <div className="c-font f-14 fw-semibold title-fonts d-flex align-items-center  w100minus100">
                  Info & Documents for City Government Approval (Mairie)
                </div>
              </div>
            </div>
            <div className=" d-flex align-items-center comman_action_icon gap-3">
              <div className="BigPill align-items-center c-font  d-flex f-12 fw-medium gap10px h32wauto min-w-100imp text-black text-uppercase">
                <span className={`c-dots c-10 ${proposalTasks.consuel[0] && proposalTasks.mairie[0].status == 5 ? "done" : proposalTasks.consuel[0] && proposalTasks.mairie[0].status== 4 ? "in-progress-blue" : "bg-warning" }`}></span>
                <span className="ps-0">{proposalTasks.consuel[0] && proposalTasks.mairie[0].status == 5 ? _l("l_done"): proposalTasks.consuel[0] && proposalTasks.mairie[0].status== 4 ? _l("l_inprogress") :  _l("l_request")  }</span>
              </div>
            </div>
          </a>
          <a href="#/" className={`align-items-center border-bottom border-last-none d-flex flex-wrap p-20 flex-lg-nowrap justify-content-between  ${proposal && proposal.status == 3 &&contractSignned &&  proposalTasks.consuel.length ? "" : "for-disabled opacity-25"}`}
          onClick={()=>{
            handleTaskSelect(proposalTasks.consuel[0])
          }}>
            <div className="d-flex align-items-center flex-grow-1">
              <div className="h50w150 comman-round-box with-bg bg-white-05 me-10px">
                <div
                  className="comman-bg-img h-100 w-100 bg-style-contain"
                  style={{ backgroundImage: `url(${Consuellogo})` }}
                ></div>
              </div>
              <div className="w-100">
                <div className="c-font f-14 fw-semibold title-fonts d-flex align-items-center  w100minus100">
                  Informations & Documents for Tax Fees (Consuel)
                </div>
              </div>
            </div>
            <div className=" d-flex align-items-center comman_action_icon gap-3">
              <div className="BigPill align-items-center c-font  d-flex f-12 fw-medium gap10px h32wauto min-w-100imp text-black text-uppercase">
              <span className={`c-dots c-10 ${proposalTasks.consuel[0] && proposalTasks.consuel[0].status == 5 ? "done" : proposalTasks.consuel[0] && proposalTasks.consuel[0].status== 4 ? "in-progress-blue" : "bg-warning" }`}></span>
              <span className="ps-0">{proposalTasks.consuel[0] && proposalTasks.consuel[0].status == 5 ? _l("l_done"): proposalTasks.consuel[0] && proposalTasks.consuel[0].status== 4 ? _l("l_inprogress") :  _l("l_request")  }</span>
              </div>
            </div>
          </a>
          <a href="#/" className={`align-items-center border-bottom border-last-none d-flex flex-wrap p-20 flex-lg-nowrap justify-content-between  ${ proposal && proposal.status == 3 && contractSignned && proposalTasks.raccordement.length ? "" : "for-disabled opacity-25"}`}
          onClick={()=>{
            handleTaskSelect(proposalTasks.raccordement[0])
          }}>
            <div className="d-flex align-items-center flex-grow-1">
              <div className="h50w150 comman-round-box with-bg bg-white-05 me-10px">
                <div
                  className="comman-bg-img h-100 w-100 bg-style-contain"
                  style={{ backgroundImage: `url(${RaccordementLogo})` }}
                ></div>
              </div>
              <div className="w-100">
                <div className="c-font f-14 fw-semibold title-fonts d-flex align-items-center  w100minus100">
                  Informations & Documents for Energy Network (Raccordement)
                </div>
              </div>
            </div>
            <div className=" d-flex align-items-center comman_action_icon gap-3">
              <div className="BigPill align-items-center c-font  d-flex f-12 fw-medium gap10px h32wauto min-w-100imp text-black text-uppercase">
              <span className={`c-dots c-10 ${proposalTasks.raccordement[0] && proposalTasks.raccordement[0].status == 5 ? "done" : proposalTasks.raccordement[0] && proposalTasks.raccordement[0].status== 4 ? "in-progress-blue" : "bg-warning" }`}></span>
              <span className="ps-0">{proposalTasks.raccordement[0] && proposalTasks.raccordement[0].status == 5 ? _l("l_done"): proposalTasks.raccordement[0] && proposalTasks.raccordement[0].status== 4 ? _l("l_inprogress") :  _l("l_request")  }</span>
              </div>
            </div>
          </a>

          <div className={`align-items-center border-bottom border-last-none d-flex flex-wrap p-20 flex-lg-nowrap justify-content-between ${proposal && proposal.status == 3 && contractSignned && proposalTasks.installation.length && showInstallationDate ? "" : "for-disabled opacity-25"}`}>
            <div className="d-flex align-items-center flex-grow-1">
              <div className="h50w150 comman-round-box with-bg bg-white-05 me-10px">
                <div className="comman-bg-img h-100 w-100 bg-style-cover d-flex">
                  <Calendar size={34} className="c-icons" />
                </div>
              </div>
              <div className="w-100 d-flex align-items-center">
                <div className="c-font f-14 fw-semibold title-fonts d-flex align-items-center  w100minus100">
                  Choose Installation Date (Poses: Task on client house)
                </div>
                <div
                  className={` white-border-box p-0 d-flex list-toggle-view-box position-relative h32wauto bg-transparent`}
                >
                  <a
                    href="#/"
                    className={`custom-datepicker datepicker-input w110px m-auto ms-1 c-font title-fonts commanbgwithborder fw-medium border-0`}
                    data-bs-toggle="tooltip"
                    data-tip={"28-06-2024"}
                    data-effect="solid"
                    data-delay-show="1000"
                    data-class="tooltip-main"
                  >
                    <div className="d-flex align-items-center">
                      <div className="h20w20">
                        <CalendarBlank
                          size={18}
                          weight="light"
                          className="c-icons"
                        />
                      </div>
                      <DatePicker
                          portalId="custom_datepicker"
                          calendarClassName="custom-datepicker"
                          calendarStartDay={1}
                          className="close-toast"
                          placeholderText={`${_l("l_startdate")}`}
                          dateFormat="dd/MM/yyyy"
                          selected={installationDate}
                          onChange={(date) => {
                            handleDateChange(date, "installation")
                           }}
                        ></DatePicker>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className=" d-flex align-items-center comman_action_icon gap-3"></div>
          </div>

          <div className={`align-items-center border-bottom border-last-none d-flex flex-wrap flex-lg-nowrap p-20 justify-content-between ${proposal && proposal.status == 3 && contractSignned && showInstallationDate ? "" : "for-disabled opacity-25"} `}>
            <a href="#/" className="d-flex align-items-center flex-grow-1"
            onClick={()=>{
              handleTaskSelect(proposalTasks.installation[0])
            }}>
              <div className="h50w150 comman-round-box with-bg bg-white-05 me-10px">
                <div className="comman-bg-img h-100 w-100 bg-style-cover d-flex">
                  <Signature size={34} className="c-icons" />
                </div>
              </div>
              <div className="w100minus100per d-flex align-items-center">
                <div className="c-font f-14 fw-semibold title-fonts d-flex align-items-center">
                  Sign the Installation proof documents (Signature in task
                  completed)
                </div>
              </div>
            </a>
            <div className=" d-flex align-items-center comman_action_icon gap-3">
              {proposalTasks.installation[0] && proposalTasks.installation[0].status == 5  ?
              <Button
                variant="primary"
                className="btn  position-relative  d-flex align-items-center"
                onClick={()=>{
                  setShowTaskSignature(true);
                }}
              >
                <span className="">Signer en ligne</span>
              </Button> : <></>}
              <div className="BigPill align-items-center c-font  d-flex f-12 fw-medium gap10px h32wauto min-w-auto text-black text-uppercase">
              <span className={`c-dots c-10 ${proposalTasks.installation[0] && proposalTasks.installation[0].status == 5 ? "done" : proposalTasks.installation[0] &&  proposalTasks.installation[0].status== 4 ? "in-progress-blue" : "bg-warning" }`}></span>
              <span className="ps-0">{proposalTasks.installation[0] && proposalTasks.installation[0].status == 5 ? _l("l_done"): proposalTasks.installation[0] &&  proposalTasks.installation[0].status== 4 ? _l("l_inprogress") :  _l("l_request")  }</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-2"></div>
    </div>
  );
};

export default AcceptProposalCustomer;
