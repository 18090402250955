import moment from "moment";
import {
  GET_LEAD_LIST,
  GET_SPECIFIC_LEAD_DETAIL,
  HOT_LEAD_SET_UNSET,
  CHANGE_LEAD_STATUS,
  PIN_UNPIN_LEAD,
  ADD_LEAD_ATTACHMENTS,
  REMOVE_LEAD_ATTACHMENTS,
  ADD_LEAD_REMINDER,
  ADD_EDIT_LEAD_NOTE,
  ADD_LEAD_COMMENT,
  DELETE_LEAD_NOTE,
  GET_LEAD_ACTIVITIES,
  GET_LEAD_NOTES_LIST,
  LEAD_BULK_ACTION,
  SET_LEAD_SOURCES,
  SET_MYR_STAFF,
  DELETE_LEAD_ACTIVITY,
  EDIT_LEAD_ACTIVITY,
  LEAD_COMPANY_DATA
} from "../actions/leads-actions/leads-action-types";

const initialState = {
  leads: [],
  leadCompanyContactToggle:"leads",
  totalCounts: {
    status1: 0,
    status16: 0,
    status19: 0,
  },
  leadDetails: {
    id: 0,
    lead_profile: {},
    lead_contact: {},
    lead_attachments: {},
    lead_call_logs: {},
    lead_tracked_emails: {},
    lead_views_tracking: {},
    lead_activities: [],
    lead_notes: [],
  },
  myrStaffList: [],
  myrLeadSources: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  let { leadDetails, leads, totalCounts } = state;
  let { lead_activities } = leadDetails;
  const full_name = localStorage.getItem("full_name");
  const userProfileImage = localStorage.getItem("contact_image");
  const staffid = localStorage.getItem("staff_id");

  switch (type) {
    case GET_LEAD_LIST:
      const { page } = payload;

      if (page > 1) {
        leads = leads.concat(payload.data);
      } else {
        leads = payload.data;
      }

      return {
        ...state,
        leads,
        totalCounts: { ...state.totalCounts, ...payload.extra },
      };

    case GET_SPECIFIC_LEAD_DETAIL:
      if (payload && payload.cleanLeadDetail) {
        leadDetails = {};
      } else {
        const { id, slug, data } = payload;
        leadDetails.lead_id = id;
        leadDetails[slug] = data;
      }

      return { ...state, leadDetails };

    case PIN_UNPIN_LEAD:
      leads.forEach((x) => {
        if (x.id == payload.id) {
          x.pinned = x.pinned && x.pinned > 0 ? 0 : 1;
        }
      });
      if (leadDetails.lead_profile) {
        if (
          leadDetails.lead_profile.pinned &&
          leadDetails.lead_profile.pinned > 0
        ) {
          leadDetails.lead_profile.pinned = 0;
        } else {
          leadDetails.lead_profile.pinned = 1;
        }
      }
      return { ...state, leadDetails, leads };

    case HOT_LEAD_SET_UNSET:
      leads.forEach((x) => {
        if (x.id == payload.id) {
          x.hot_leads = x.hot_leads && x.hot_leads > 0 ? 0 : 1;
        }
      });
      if (leadDetails.lead_profile) {
        if (
          leadDetails.lead_profile.hot_leads &&
          leadDetails.lead_profile.hot_leads > 0
        ) {
          leadDetails.lead_profile.hot_leads = 0;
        } else {
          leadDetails.lead_profile.hot_leads = 1;
        }
      }

      return { ...state, leadDetails, leads };

    case CHANGE_LEAD_STATUS:
      let { status } = payload;
      let prevStatus;
      leads.forEach((x) => {
        if (x.id == payload.id) {
          prevStatus = x.status;
          x.status = status;
        }
      });
      if (leadDetails.lead_profile && leadDetails.lead_profile.status) {
        leadDetails.lead_profile.status = status;
      }
      totalCounts[`status${status}`] += 1;
      totalCounts[`status${prevStatus}`] -= 1;
      return { ...state, leadDetails, leads, totalCounts };

    case ADD_LEAD_ATTACHMENTS:
      let temAttchment = [];
      payload.data &&
        payload.data.files_list &&
        payload.data.files_list.forEach((file) => {
          temAttchment.push({
            file_url: file.image_url,
            file_name: file.file_name,
            file_size: file.file_size,
            file_id: file.file_id,
          });
        });

      leadDetails["lead_attachments"] = temAttchment.concat(
        leadDetails["lead_attachments"]
      );

      return { ...state, leadDetails };

    case REMOVE_LEAD_ATTACHMENTS:
      let { file_id } = payload;
      leadDetails["lead_attachments"] = leadDetails.lead_attachments.filter(
        (x) => x.file_id != file_id
      );
      return { ...state, leadDetails };

    case ADD_LEAD_REMINDER:
      return { ...state };

    case GET_LEAD_ACTIVITIES:
      leadDetails.lead_activities = payload.data;
      leadDetails.id = payload.id;
      return { ...state, leadDetails };

    case ADD_LEAD_COMMENT:
      lead_activities.push({
        id: payload.data[0].id,
        full_name: full_name,
        description: payload.comment,
        staffid: staffid,
        profile_url: userProfileImage,
        date: payload.data.time || moment().format("YYYY-MM-DD HH:MM:SS"),

      });
      leadDetails.lead_activities = lead_activities;
      leadDetails.lead_profile.comments_count =
        leadDetails.lead_profile.comments_count || 0 + 1;
      leads.forEach((lead) => {
        if (lead.id == payload.id) {
          lead.comments_count = lead.comments_count || 0 + 1;
        }
      });
      return { ...state, leadDetails };

    case DELETE_LEAD_ACTIVITY:
      return { ...state, leadDetails: { ...state.leadDetails, lead_activities: lead_activities.filter(x => x.id != payload.id) } };

    case EDIT_LEAD_ACTIVITY:
      let updatedActivity = payload.activity;
      lead_activities.forEach((activity) => {
        if (activity.id == payload.id) {
          activity.description = updatedActivity
        }
      })
      return { ...state, leadDetails: { ...state.leadDetails, lead_activities } };

    case GET_LEAD_NOTES_LIST:
      leadDetails.lead_notes = payload.data;
      return { ...state, leadDetails };

    case ADD_EDIT_LEAD_NOTE:
      const { note } = payload;
      if (note.note_id > 0) {
        leadDetails.lead_notes.forEach((noteItem) => {
          if (noteItem.id == note.note_id) {
            noteItem.description = note.description;
          }
        });
      } else {
        note.full_name = full_name;
        leadDetails.lead_notes.push(note);
      }
      return { ...state, leadDetails };

    case DELETE_LEAD_NOTE:
      let newNoteList = leadDetails.lead_notes.filter(
        (x) => x.id != payload.note_id
      );
      leadDetails.lead_notes = newNoteList;
      return { ...state, leadDetails };


    case LEAD_BULK_ACTION:
      return state;

    case SET_LEAD_SOURCES:
      return { ...state, myrLeadSources: payload.data }

    case SET_MYR_STAFF:
      return { ...state, myrStaffList: payload.data };

    case LEAD_COMPANY_DATA:
      return { ...state, leadCompanyContactToggle: payload };
    default:
      return state;
  }
}
