import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import AddChecklistTemplate from "../Modals/AddChecklistTemplate";
import ChecklistAccordians from "../Accordians/ManageChecklist";
import { useDispatch } from "react-redux";
import { showConfirmation, toastCloseAction } from "../../actions/messages";
import store from "../../store";
import { _l, checklistItem, showError } from "../../hooks/utilities";
import { Info, CaretRight } from "phosphor-react";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import BenchmarkServices from "../../services/benchmark-services";
import Select from "react-select";
import { getFloorDetails } from "../../actions/benchmark-actions/benchmark-actions";
import CommanLoader from "../Loader/CommanLoader";
import ChecklistCreation from "../ExploreFeatures/ConnectProvider/ChecklistCreation";
import moment from "moment";

export function ManageChecklistStep({ setChecklists, categoryList }) {
  const [selectedCategory] = useState({
    label: "all",
    value: "all",
  });
  const [selectedSection, setSelectedSection] = useState("task");
  const [templatesList, setTemplatesList] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [templateDetail, setTemplateDetail] = useState({});
  const [loadingChecklistData, setLoadingChecklistData] = useState(false);

  const dispatch = useDispatch();

  const mapChecklistsWithFloorAndSpace = (
    floorId,
    templateChecklist,
    floorDetails
  ) => {
    let typeOfSpaceData = floorDetails.type_of_space_items.filter(
      (w) => w.floor_id == floorId
    );
    let existingChecklistArray = [];

    const defaultSpace = floorDetails.type_of_space.find(
      (w) => w.is_default == 1
    );
    typeOfSpaceData = [
      {
        id: 0,
        room_id: defaultSpace.id,
        title: defaultSpace.title,
        room_title: defaultSpace.title,
        items: [],
        is_default: 1,
      },
    ].concat(typeOfSpaceData);

    if (templateChecklist && templateChecklist.length) {
      templateChecklist.forEach((checklist) => {
        let typeOfSpace = typeOfSpaceData.find(
          (w) =>
            w.room_id == checklist.type_of_space_id &&
            (w.is_default == 1 || w.id == checklist.type_of_space_item_id)
        );

        if (
          typeOfSpace &&
          (typeOfSpace.is_default == 1 ||
            typeOfSpace.id == checklist.type_of_space_item_id)
        ) {
          existingChecklistArray.push({
            ...checklist,
            spaceTitle: typeOfSpace.room_title,
            description: checklist.description,
            slot:
              moment(checklist.item_slot).isValid() ||
              checklist.item_slot instanceof Date
                ? checklist.item_slot
                : new Date(`01-01-2000 ${checklist.item_slot}`),
            type_of_space_items: checklist.type_of_space_items,
            type_of_space_item_id: checklist.type_of_space_item_id,
            type_of_space_id: checklist.type_of_space_id,
            room_id: checklist.type_of_space_id,
            is_default:
              defaultSpace.id == checklist.type_of_space_id ||
              checklist.type_of_space_item_id == 0
                ? 1
                : 0,
          });
        }
      });
    }

    existingChecklistArray = existingChecklistArray.filter(
      (obj, index, self) =>
        index ===
        self.findIndex(
          (o) =>
            obj.room_id == o.room_id &&
            obj.type_of_space_item_id == o.type_of_space_item_id &&
            obj.description == o.description
        )
    );

    typeOfSpaceData.forEach((type_of_space) => {
      existingChecklistArray.push({
        ...checklistItem,
        description: "",
        is_default: type_of_space.is_default || 0,
        room_id: type_of_space.room_id,
        spaceTitle: type_of_space.room_title,
        type_of_space_item_id: type_of_space.id,
        type_of_space_id: type_of_space.room_id,
        type_of_space_items: [],
      });
    });

    existingChecklistArray.sort((a, b) => b.is_default - a.is_default);

    return existingChecklistArray;
  };

  const handleEditChecklist = async (selectedTemplate) => {
    try {
      setLoadingChecklistData(true);
      const response = await BenchmarkServices.getTemplateChecklistItems(
        0,
        selectedTemplate.provider_id,
        selectedTemplate.id,
        selectedSection === "task",
        1
      );
      if (response) {
        const projectId =
          response.extra && response.extra.project_id
            ? response.extra.project_id
            : 0;
        if (projectId > 0) {
          dispatch(getFloorDetails(projectId)).then(() => {
            localStorage.setItem("selectedOffice", projectId);
            const floorDetails = store.getState().benchmarkReducer.floorDetails;
            const floorList = floorDetails.floor;
            const checklistFloorIds = response.data
              .map((w) => Number(w.floor_id))
              .filter((w) => w > 0);
            const floor = floorList.find((w) =>
              checklistFloorIds.includes(Number(w.id))
            );

            setTemplateDetail({
              ...selectedTemplate,
              checklists: mapChecklistsWithFloorAndSpace(
                floor.id,
                response.data,
                floorDetails
              ),
              floor,
              projectId
            });
          });
        } else if(selectedSection === "task") {
          setTemplateDetail({
            ...selectedTemplate,
            checklists: response.data,
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
    finally{
      setLoadingChecklistData(false);
    }
  };

  const handleDelete = (selectedTemplate) => {
    BenchmarkServices.deleteChecklistTemplate(selectedTemplate.id).then(
      (res) => {
        if (res && res.status) {
          store.dispatch(toastCloseAction());
          setTemplatesList(
            templatesList.filter((x) => x.id != selectedTemplate.id)
          );
        }
      }
    );
  };

  const deleteConfirmation = (selectedTemplate) => {
    dispatch(
      showConfirmation(
        _l("l_confirm"),
        _l("l_are_you_sure_you_want_to_delete_checklist_template"),
        _l("l_delete"),
        _l("l_cancel"),
        undefined,
        () => {
          handleDelete(selectedTemplate);
        }
      )
    );
  };

  const fetchCategoryChecklist = async () => {
    try {
      const response = await BenchmarkServices.getChecklistTemplate(0, 0, 0, 1);
      if (response.status) {
        setTemplatesList(response.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const filteredTemplistArray = () => {
    let templistArray = [];
    let categoryIds = categoryFilter.map((w) => Number(w.value));

    switch (selectedSection) {
      case "task":
        templistArray = templatesList.filter(
          (w) =>
            w.view === "classic" &&
            (!categoryIds.length || categoryIds.includes(Number(w.provider_id)))
        );
        break;
      case "schedule":
        templistArray = templatesList.filter(
          (w) =>
            w.view === "floor" &&
            (!categoryIds.length || categoryIds.includes(Number(w.provider_id)))
        );
        break;
      default:
        break;
    }

    return templistArray;
  };

  const closeScheduleChecklistTemplateModal = () => {
    setTemplateDetail({});
  };

  const saveBenchmarkChecklist = async(checklistItems) => {
    setLoadingChecklistData(true);
    try {
      const checklistArray = checklistItems.map((w) => {
        return {
          checklist: [{ name: w.item_name || w.description }],
          ...w
        };
      }).filter((w) => w.description && w.description.length);
      const response = await BenchmarkServices.updateTaskChecklistTemplate(
        checklistArray,
        templateDetail.provider_id,
        templateDetail.title,
        templateDetail.id,
        0,
        templateDetail.projectId
      );
      if (response.status) {
        setTemplateDetail({});
      }
      else {
        showError(response.message);
      }
    }
    catch(e)
    {
      console.error(e);
    }
    finally {
      setLoadingChecklistData(false);
    }
  };

  useEffect(() => {
    fetchCategoryChecklist();
  }, []);

  return (
    <>
      <div className="tab-contain-wrapper comman_vertical_btn_h ">
        <div className="comman_top_header position-sticky top-0 z-index-4 p-0 bgpatternCommanImg">
          <div className="comman-tab-wrapper pb-0 d-flex justify-content-between">
            <div className="tab-wrapper d-inline-flex align-items-center">
              <div className="tab-name title-fonts fw-semibold m-0 color-white-80 me-1">
                {_l("l_manage_checklist")}
              </div>
              <a href="#/" className="info-icon d-inline-block">
                <Info size={18} weight="light" className="c-icons m-auto" />
              </a>
            </div>
            <div className="d-flex align-items-center">
              <div className="comman-content-bottom-btn d-flex justify-content-end align-items-center m-0 pe-3">
                <Select
                  className="custom-select-menu h_30px"
                  classNamePrefix="react-select"
                  placeholder={`${_l("l_categories")}`}
                  options={categoryList
                    .filter((w) => !isNaN(w.provider_id))
                    .map((w) => {
                      return { label: w.name, value: w.provider_id };
                    })}
                  value={categoryFilter}
                  closeMenuOnSelect={true}
                  isMulti
                  onChange={(value) => {
                    setCategoryFilter(value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="comman-content-scroll-wrapper p-15">
          <div className="comman-content-scroll d-flex overflow-hidden">
            <div className="left-content-part w-280 p-0 res-width-100 h-100 bg-transparent">
              <ul className="h-100 comman-verticle-tab">
                <div className="comman-content-scroll-wrapper comman-contnet-wrapper comman_vertical_btn_h radius-top-0">
                  <div className="comman-content-scroll comman-verticle-tab">
                    <li className="comman-list p-0" key={0}>
                      <a
                        href="#/"
                        className={`w-100 position-relative tab_link ${
                          selectedSection === "task" ? "active" : ""
                        }`}
                        onClick={() => {
                          setSelectedSection("task");
                        }}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="comman-list-left w100minus20">
                            <div className="d-flex align-items-center">
                              <div className="c-list-detail w100minus20 text-truncate ps-2 fw-semibold">
                                {_l("l_task")}
                              </div>
                            </div>
                          </div>
                          <div className="comman-list-right">
                            <CaretRight
                              size={18}
                              weight="light"
                              className="c-icons active_img"
                            />
                          </div>
                        </div>
                      </a>
                    </li>
                    <li className="comman-list p-0" key={1}>
                      <a
                        href="#/"
                        className={`w-100 position-relative tab_link ${
                          selectedSection === "schedule" ? "active" : ""
                        }`}
                        onClick={() => {
                          setSelectedSection("schedule");
                        }}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="comman-list-left w100minus20">
                            <div className="d-flex align-items-center">
                              <div className="c-list-detail w100minus20 text-truncate ps-2 fw-semibold">
                                {_l("l_schedules")}
                              </div>
                            </div>
                          </div>
                          <div className="comman-list-right">
                            <CaretRight
                              size={18}
                              weight="light"
                              className="c-icons active_img"
                            />
                          </div>
                        </div>
                      </a>
                    </li>
                  </div>
                </div>
              </ul>
            </div>
            <div className="right-content-part width-calc-300 with-margin h-100 overflow-hiiden-auto d-flex flex-column">
              {templatesList.length ? (
                <ChecklistAccordians
                  checklists={filteredTemplistArray()}
                  handleEdit={handleEditChecklist}
                  handleDelete={deleteConfirmation}
                />
              ) : (
                <CommanPlaceholder
                  imgType="no-checklistt"
                  mainWidth="m-auto"
                  placeholderText={_l("l_no_checklist")}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {templateDetail && templateDetail.id > 0 ? (
        <>
          {selectedSection === "task" ? (
            <AddChecklistTemplate
              show={true}
              handleClose={() => {
                setTemplateDetail({});
              }}
              categories={
                categoryList
                  ? categoryList.map((x) => {
                      return { label: x.name, value: x.provider_id };
                    })
                  : []
              }
              setChecklists={setChecklists}
              checklists={templateDetail.checklists}
              template={templateDetail}
              selectedCategory={selectedCategory}
              isTask={selectedSection === "task" ? 1 : 0}
            />
          ) : (
            <React.Fragment>
              {loadingChecklistData ? (
                <CommanLoader />
              ) : (
                <ChecklistCreation
                  checklists={templateDetail.checklists}
                  floor={templateDetail.floor}
                  onClose={closeScheduleChecklistTemplateModal}
                  onSave={saveBenchmarkChecklist}
                  providerData={{}}
                  setTemplates={() => {}}
                  templates={[]}
                  setFloor={() => {}}
                  templateView={true}
                />
              )}
            </React.Fragment>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default ManageChecklistStep;
