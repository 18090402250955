import React from 'react';
// import CSS from '../css/icon_main.css';

function ArrowSquareUpRightIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
		
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M13.5 6.25V2.5M13.5 2.5H9.75M13.5 2.5L9 7M11.5 9V13C11.5 13.1326 11.4473 13.2598 11.3536 13.3536C11.2598 13.4473 11.1326 13.5 11 13.5H3C2.86739 13.5 2.74021 13.4473 2.64645 13.3536C2.55268 13.2598 2.5 13.1326 2.5 13V5C2.5 4.86739 2.55268 4.74021 2.64645 4.64645C2.74021 4.55268 2.86739 4.5 3 4.5H7" />
</svg>



		</React.Fragment>
	)
}

export default ArrowSquareUpRightIcon