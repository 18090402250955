import React, { useEffect, useState } from "react";
import { _l } from "../../../hooks/utilities";
import Accordion from "react-bootstrap/Accordion";
import WalletOffcanvasListCard from "./WalletOffcanvasListCard";

const WalletTaskCard = () => {
  return (
    <>
    <div className="wallet-task-title-sticky">
        <div className="d-flex align-items-center">
            <div className="c-font f-12 badge radius_3">
                <span className="color-white ">{_l('l_potential_credit_request')}  € 40.00</span>
            </div>
            <div className="progress-wrapper-link flex-grow-1 ps-4   ">
                <div className="progress-slider">
                    <div className="d-flex align-items-center">
                        <div className="progress w_100">
                            <div className="progress-bar" style={{
                                    width: `90%`,
                                  }}></div>
                        </div>
                        <div className="progress-text c-font f-12 title-fonts">80%</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="d-flex flex-column h-100">
        <div className="comman-main-box custom-accordian-main res-padding-right0 h-100 task-list-accordian ">
            <Accordion  className="task-list-accordian-main mt-0" defaultActiveKey="0">
                <Accordion.Item eventKey="0" className="with-pb-15">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border with-accordian-bg">
                        <Accordion.Header className="active to-do commanTaskCount">
                            <div className="accordion-header-wrapper w-100 ">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="left-task-header">
                                <div className="d-flex align-items-center  fw-semibold title-fonts c-font f-13">
                                    <span className="c-dots  c-7 in-progress-white rounded-circle me-2"></span>
                                    <span className="task-title me-2">{_l("l_todo")}</span>
                                    <span className="" id="todo_count_display">[05]</span>
                                </div>
                                </div>
                            </div>
                            </div>
                        </Accordion.Header>
                    </div>
                    <Accordion.Body className="taskCountToggle" >

                    <div>
                            <WalletOffcanvasListCard />
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="with-pb-15">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border with-accordian-bg">
                        <Accordion.Header className="active  in-progress commanTaskCount">
                            <div className="accordion-header-wrapper w-100 ">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="left-task-header">
                                <div className="d-flex align-items-center  fw-semibold title-fonts c-font f-13">
                                    <span className="c-dots c-7 rounded-circle in-progress-blue me-2"></span>
                                    <span className="task-title me-2">{_l("l_inprogress")}</span>
                                    <span className="" id="todo_count_display">[05]</span>
                                </div>
                                </div>
                            </div>
                            </div>
                        </Accordion.Header>
                    </div>
                    <Accordion.Body className="taskCountToggle" >
                        
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="with-pb-15">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border with-accordian-bg">
                        <Accordion.Header className="active  done">
                            <div className="accordion-header-wrapper w-100 ">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="left-task-header">
                                <div className="d-flex align-items-center  fw-semibold title-fonts c-font f-13">
                                    <span className="c-dots  c-7 done rounded-circle me-2"></span>
                                    <span className="task-title me-2">{_l("l_done")}</span>
                                    <span className="" id="todo_count_display">[05]</span>
                                </div>
                                </div>
                            </div>
                            </div>
                        </Accordion.Header>
                    </div>
                    <Accordion.Body className="taskCountToggle" ></Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    </div>
    </>
  )
}

export default WalletTaskCard