import { CalendarBlank, CloudArrowUp, X } from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  _l,
  getDatePickerLocale,
  getPreviewFromURL,
  isBlocked,
  showError,
} from "../../../hooks/utilities";
import { addDays } from "@fullcalendar/react";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../../actions/messages";
import moment from "moment";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";
import { useNavigate } from "react-router-dom";
import CustomerServices from "../../../services/customer-services";
import benchmarkServices from "../../../services/benchmark-services";
import CustomFields from "./CustomFields";

const BasicDetails = ({ providerData, setProviderData, customFields, setCustomFields, readOnlyMode }) => {
  const [formData, setFormData] = useState({
    budget: "",
    startDate: "",
    endDate: "",
    unKnownStartDate: false,
    unKnownBudget: false,
    documents: "",
    documentsPreviews: [],
    contracts: "",
    contractsPreview: [],
    description: "",
    needToHire: false,
    furnished: false,
    id: 0,
  });

  const dispatch = useDispatch();
  const inputRefs = useRef([]);

  const benchmarkDetail = useSelector(
    (state) => state.customer.benchmarkDetail
  );

  const navigate = useNavigate();

  let selectedOffice = localStorage.getItem("selectedOffice");
  const userType = localStorage.getItem("user_type");

  const preparePreview = (files, remove = false, flag = "") => {
    let previewArray = [];
    Array.from(files).map((img) => {
      previewArray.push({ name: img.name, url: URL.createObjectURL(img) });
    });

    if (remove) {
      if (flag != "") {
        setFormData({ ...formData, contractsPreview: previewArray });
      } else {
        setFormData({ ...formData, documentsPreviews: previewArray });
      }
    } else {
      if (providerData.id > 0) {
        CustomerServices.addNewAttachmentToBenchmark(
          selectedOffice,
          providerData.id,
          flag != "" ? "" : files,
          flag == "" ? "" : files
        )
          .then((response) => {
            if (response.status) {
              if (flag) {
                setFormData({
                  ...formData,
                  contractsPreview:
                    formData.contractsPreview.concat(previewArray),
                });
              } else {
                setFormData({
                  ...formData,
                  documentsPreviews:
                    formData.documentsPreviews.concat(previewArray),
                });
              }
            } else {
              showError(response.message);
            }
          })
          .catch((e) => {
            console.error(e);
            showError("l_something_went_wrong");
          });
      } else {
        if (flag != "") {
          setFormData({
            ...formData,
            contractsPreview: formData.contractsPreview.concat(previewArray),
            contracts: formData.contracts
              ? Array.from(formData.contracts).concat(Array.from(files))
              : files,
          });
        } else {
          setFormData({
            ...formData,
            documentsPreviews: formData.documentsPreviews.concat(previewArray),
            documents: formData.documents
              ? Array.from(formData.documents).concat(Array.from(files))
              : files,
          });
        }
      }
    }
  };

  const removeAttachment = (attachmentData, type) => {
    if (attachmentData.file_id && providerData.id) {
      CustomerServices.removeBenchmarkAttachment(
        selectedOffice,
        attachmentData.file_id,
        providerData.id
      )
        .then((response) => {
          if (response.status) {
            if (type === "contract") {
              setFormData({
                ...formData,
                contractsPreview: formData.contractsPreview.filter(
                  (w) => w.file_id != attachmentData.file_id
                ),
              });
            } else {
              setFormData({
                ...formData,
                documentsPreviews: formData.documentsPreviews.filter(
                  (w) => w.file_id != attachmentData.file_id
                ),
              });
            }
          } else {
            showError(response.message);
          }
        })
        .catch((e) => {
          console.error(e);
          showError("l_something_went_wrong");
        });
    } else {
      if (type == "contract") {
        setFormData({
          ...formData,
          contracts: Array.from(formData.contracts).filter(
            (w) => w.name != attachmentData.name
          ),
          contractsPreview: formData.contractsPreview.filter(
            (w) => w.name != attachmentData.name
          ),
        });
      } else {
        setFormData({
          ...formData,
          documents: Array.from(formData.contracts).filter(
            (w) => w.name != attachmentData.name
          ),
          documentsPreviews: formData.documentsPreviews.filter(
            (w) => w.name != attachmentData.name
          ),
        });
      }
    }
  };

  const selectFiles = (event, flag = "") => {
    const extensions = [
      "png",
      "jpg",
      "pdf",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "txt",
      "jpeg",
    ];
    if (event.target.files) {
      for (let file = 0; file < event.target.files.length; file++) {
        let fileExtension = event.target.files[file].name.split(".").pop();
        let fileSize = event.target.files[file].size;

        if (extensions.indexOf(fileExtension) == -1) {
          var message = flag != "" ? _l("l_upload_contract") : _l("l_document");
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_upload_valid") + " " + message
            )
          );
          return false;
        }
        //10mb => 10485760
        if (fileSize > 10485760) {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_upload_maximum_10mb")
            )
          );
          return false;
        }
      }
    }
    preparePreview(event.target.files, false, flag);
  };

  useEffect(() => {
    setProviderData({ ...providerData, ...formData });
  }, [formData]);

  useEffect(() => {
    if (benchmarkDetail && benchmarkDetail.id > 0) {
      let documentType = "";
      if (userType === "operator") {
        if (benchmarkDetail.proposal_id > 0) {
          documentType = "proposal";
        } else {
          documentType = "schedule";
        }
      } else if (userType === "contact") {
        if (
          benchmarkDetail.open_schedule_modal == 1 &&
          benchmarkDetail.master_id > 0
        ) {
          documentType = "schedule";
        } else if (
          benchmarkDetail.open_schedule_modal == 0 &&
          benchmarkDetail.master_id > 0
        ) {
          documentType = "benchmark";
        } else {
          documentType =
            benchmarkDetail.is_schedule > 0 ? "schedule" : "benchmark";
        }
      }
      setFormData({
        budget:
          Number(benchmarkDetail.max_budget) > 0
            ? Number(benchmarkDetail.max_budget)
            : "",
        contractsPreview: benchmarkDetail.attachments
          .filter((x) => x.is_contract_file == 1)
          .map((w) => {
            return {
              url: w.attachment_url,
              file_id: w.file_id,
              name: w.attachment_url.split("/").pop(),
            };
          }),
        description: benchmarkDetail.basic_information,
        documents: benchmarkDetail.attachments,
        documentsPreviews: benchmarkDetail.attachments
          .filter((x) => x.is_contract_file == 0)
          .map((w) => {
            return {
              url: w.attachment_url,
              file_id: w.file_id,
              name: w.attachment_url.split("/").pop(),
            };
          }),
        endDate: benchmarkDetail.contract_end_date,
        startDate: benchmarkDetail.startdate,
        unKnownBudget:
          benchmarkDetail.budget_is_empty > 0 ||
          !Number(benchmarkDetail.recursive),
        unKnownStartDate: benchmarkDetail.start_date_is_empty > 0,
        id: benchmarkDetail.id,
        categoryId: benchmarkDetail.provider_id,
        categoryName: benchmarkDetail.category,
        type: documentType,
        invited_providers: benchmarkDetail.operator_details,
        sources: ["choose_from_past"],
        needToHire: benchmarkDetail.need_to_hire_people > 0,
        furnished: benchmarkDetail.is_furnished > 0,
        invited_customers: [],
      });
    } else if (benchmarkDetail && benchmarkDetail.length == 0) {
      navigate("/");
    }
    if(benchmarkDetail.custom_fields && benchmarkDetail.custom_fields.length)
      {
        setCustomFields(benchmarkDetail.custom_fields.map((field) => {
          field.benchmark_value = field.value;
          return field;
        }));
      }
  }, [benchmarkDetail]);

  useEffect(() => {
    const getCustomFields = async () => {
      try {
        const response = await benchmarkServices.getBasicDetailsCustomFields(
          providerData.type
        );
        if ((response.status) && (!benchmarkDetail || !benchmarkDetail.custom_fields || !benchmarkDetail.custom_fields.length)) {
          setCustomFields(response.data);
        }
      } catch (e) {
        console.error(e);
      }
    };

    getCustomFields();
  }, []);

  return (
    <>
      <Row className="justify-content-center">
        <Col xl={12}>
          <Row>
            <Form.Group className="col-xl-4 flex-grow-1 c-input-box pb-3 position-relative">
              <Form.Label className="input-label no-asterisk no-asterisk">
                {`${_l("l_add")} ${_l("l_budget")}`}
              </Form.Label>
              <input
                className="form-control"
                placeholder="Є100"
                type="number"
                name="budget"
                value={formData.budget}
                onChange={(e) => {
                  setFormData({ ...formData, budget: e.target.value });
                }}
                disabled={formData.unKnownBudget || readOnlyMode}
              />
              {readOnlyMode ? (
                <></>
              ) : (
                <div className="form-check with-span py-0">
                  <div className="d-flex align-items-center">
                    <Form.Label className="form-check-label mt-2 mb-0 w-100">
                      <input
                        className="form-check-input h20w20"
                        type="checkbox"
                        checked={formData.unKnownBudget}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            unKnownBudget: e.target.checked,
                          })
                        }
                      />
                      <span className="c-font f-12 ps-1">
                        {_l("l_approximate_i_dont_know_my")} {_l("l_budget")}
                      </span>
                    </Form.Label>
                  </div>
                </div>
              )}
            </Form.Group>
            <Form.Group className="col-xl-4 flex-grow-1 c-input-box pb-4 position-relative">
              <Form.Label className="input-label no-asterisk">
                {`${_l("l_service_start_date")}`}
              </Form.Label>
              <div className="custom-datepicker w-100">
                <div className="d-flex align-items-center form-control color-white-60 dropdown-center dropdown w-100 react-datepicker-wrapper-w-100 comman_action_icon h_53px">
                  <div className="action_icon h32w32 with_bg">
                    <CalendarBlank
                      size={18}
                      weight="light"
                      className="c-icons opacity-60"
                    />
                  </div>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    placeholderText={`${_l("l_select_date_placeholder")}`}
                    filterDate={isBlocked}
                    className="ps-2 w-100"
                    selected={
                      formData.startDate ? new Date(formData.startDate) : ""
                    }
                    calendarStartDay={1}
                    minDate={addDays(
                      new Date(),
                      providerData.type === "benchmark" ? 10 : 1
                    )}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        startDate: moment(e).format("YYYY-MM-DD"),
                      });
                    }}
                    readOnly={formData.unKnownStartDate || readOnlyMode}
                    locale={getDatePickerLocale()}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="datepicker-label">
                      {_l("l_select_date_and_time")}
                    </div>
                  </DatePicker>
                </div>
              </div>
              {readOnlyMode ? (
                <></>
              ) : (
                <div className="form-check with-span py-0">
                  <div className="d-flex align-items-center">
                    <Form.Label className="form-check-label mt-2 mb-0 w-100">
                      <input
                        className="form-check-input h20w20"
                        type="checkbox"
                        checked={formData.unKnownStartDate}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            unKnownStartDate: e.target.checked,
                          })
                        }
                      />
                      <span className="c-font f-12 ps-1">
                        {_l("l_dont_know_my_service_start_date")}{" "}
                      </span>
                    </Form.Label>
                  </div>
                </div>
              )}
            </Form.Group>
            {providerData.type === "schedule" ? (
              <React.Fragment>
                <Form.Group className="col-xl-4 c-input-box pb-4 position-relative">
                  <Form.Label className="input-label no-asterisk">
                    {`${_l("l_service_end_date")}`}
                  </Form.Label>
                  <div className="custom-datepicker">
                    <div className="d-flex align-items-center form-control color-white-60 dropdown-center dropdown w-100 react-datepicker-wrapper-w-100 comman_action_icon h_53px">
                      <div className="action_icon h32w32 with_bg">
                        <CalendarBlank
                          size={18}
                          weight="light"
                          className="c-icons opacity-60"
                        />
                      </div>
                      <DatePicker
                        className="ps-2 w-100"
                        selected={
                          formData.endDate ? new Date(formData.endDate) : ""
                        }
                        onChange={(date) =>
                          setFormData({
                            ...formData,
                            endDate: moment(date).format("YYYY-MM-DD"),
                          })
                        }
                        locale={getDatePickerLocale()}
                        disabledKeyboardNavigation
                        placeholderText={_l("l_contract_reminder_end_date")}
                        dateFormat="dd/MM/yyyy"
                        selectsStart
                        defaultMenuIsOpen
                        minDate={
                          formData.startDate
                            ? addDays(new Date(formData.startDate), 30)
                            : ""
                        }
                        readOnly={readOnlyMode}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        calendarStartDay={1}
                      >
                        <div className="datepicker-label">
                          {_l("l_select_date_and_time")}
                        </div>
                      </DatePicker>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="col-xl-6 flex-grow-1 c-input-box pb-4 position-relative ">
                  <Form.Label className="input-label no-asterisk">
                    {_l("l_document_upload")}
                  </Form.Label>
                  {readOnlyMode ? (
                    <></>
                  ) : (
                    <div className="form-control file-upload with-bg position-relative p-0 flex-grow-1">
                      <div className="d-flex align-items-center justify-content-between p-15">
                        <input
                          className="z-index-3 hidden-input"
                          type="file"
                          onInput={(e) => selectFiles(e, "")}
                        />
                        <div className="m-auto">
                          <CloudArrowUp
                            size={18}
                            className="c-icons"
                            weight="light"
                          />
                          <span className="color-white-60 ps-2">
                            {_l("l_click_to_upload")}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="upload-image-preview pt-3 d-flex flex-wrap">
                    <div className="d-flex w-100">
                      {formData.documentsPreviews.map((x, index) => {
                        let preview = {};
                        preview.url = getPreviewFromURL(x.name);

                        return (
                          <div className="h60w60 me-2" key={index}>
                            <div className="comman-image-box h40w40 radius_3 upload-image with-margin">
                              {typeof preview.url === "string" ? (
                                <div
                                  className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 cursor-pointer"
                                  style={{
                                    backgroundImage: `url(${x.url})`,
                                  }}
                                  onClick={() => window.open(x.url, "_blank")}
                                  data-tip={_l("l_preview_attachment")}
                                  data-effect="solid"
                                  data-delay-show="1000"
                                  data-class="tooltip-main"
                                ></div>
                              ) : (
                                <div
                                  className="h-100 w-100 d-flex cursor-pointer"
                                  onClick={() => window.open(x.url, "_blank")}
                                  style={{
                                    alignContent: "center",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <preview.url
                                    size={72}
                                    weight="light"
                                    className="c-icons"
                                  />
                                </div>
                              )}
                              <a
                                href="#/"
                                className="upload-close"
                                onClick={() => removeAttachment(x, "document")}
                              >
                                <X
                                  size={10}
                                  weight="light"
                                  className="c-icons"
                                />
                              </a>
                            </div>
                            <div className="c-font color-white-60 f-12 text-truncate">
                              {x.name}
                            </div>
                          </div>
                        );
                      })}
                      {readOnlyMode && !formData.documentsPreviews.length ? (
                        <div className="form-control file-upload with-bg">
                          <CommanPlaceholder
                            mainSpacing="mt-0"
                            imgWidth="small-image"
                            imgType="no-images"
                            placeholderText={_l("l_no_attachment")}
                          />
                        </div>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </div>
                  </div>
                </Form.Group>
              </React.Fragment>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            <Form.Group className="col-xl-6 flex-grow-1 c-input-box pb-4 position-relative ">
              <Form.Label className="input-label no-asterisk">
                {_l("l_contract_upload")}
              </Form.Label>
              {readOnlyMode ? (
                <></>
              ) : (
                <div className="form-control file-upload with-bg position-relative p-0 flex-grow-1">
                  <div className="d-flex align-items-center justify-content-between p-15">
                    <input
                      className="z-index-3 hidden-input"
                      type="file"
                      onInput={(e) => selectFiles(e, "contract")}
                    />
                    <div className="m-auto">
                      <CloudArrowUp
                        size={18}
                        className="c-icons"
                        weight="light"
                      />
                      <span className="color-white-60 ps-2">
                        {_l("l_click_to_upload")}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className="upload-image-preview pt-3 d-flex flex-wrap">
                <div className="d-flex w-100">
                  {formData.contractsPreview.map((x, index) => {
                    let preview = {};
                    preview.url = getPreviewFromURL(x.name);
                    return (
                      <div className="h60w60 me-2" key={index}>
                        <div className="comman-image-box h40w40 radius_3 upload-image with-margin">
                          {typeof preview.url === "string" ? (
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 cursor-pointer"
                              style={{ backgroundImage: `url(${x.url})` }}
                              onClick={() => window.open(x.url, "_blank")}
                              data-tip={_l("l_preview_attachment")}
                              data-effect="solid"
                              data-delay-show="1000"
                              data-class="tooltip-main"
                            ></div>
                          ) : (
                            <div
                              className="h-100 w-100 d-flex cursor-pointer"
                              onClick={() => window.open(x.url, "_blank")}
                              style={{
                                alignContent: "center",
                                flexWrap: "wrap",
                              }}
                            >
                              <preview.url
                                size={72}
                                weight="light"
                                className="c-icons"
                              />
                            </div>
                          )}
                          <a
                            href="#/"
                            className="upload-close"
                            onClick={() => removeAttachment(x, "contract")}
                          >
                            <X size={10} weight="light" className="c-icons" />
                          </a>
                        </div>
                        <div className="c-font color-white-60 f-12 text-truncate">
                          {x.name}
                        </div>
                      </div>
                    );
                  })}
                  {readOnlyMode && !formData.contractsPreview.length ? (
                    <div className="form-control file-upload with-bg">
                      <CommanPlaceholder
                        mainSpacing="mt-0"
                        imgWidth="small-image"
                        imgType="no-images"
                        placeholderText={_l("l_no_attachment")}
                      />
                    </div>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </div>
              </div>
            </Form.Group>
            <Form.Group className="col-12 c-input-box position-relative mb-3">
              <Form.Label className="input-label no-asterisk title-fonts color-white-60">
                {_l("l_details")}
              </Form.Label>
              <textarea
                placeholder={_l("l_details")}
                className="form-control"
                value={formData.description}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    description: e.target.value.substring(0, 500),
                  });
                }}
                disabled={readOnlyMode}
              />
            </Form.Group>

            {providerData.type === "benchmark" && !readOnlyMode ? (
              <div className="row">
                <div className="col-xl-4 flex-grow-1">
                  <div className="d-flex align-items-center res-space-between py-2">
                    <Form.Label className="input_label pe-4">
                      {_l("l_need_to_hire_people")}
                    </Form.Label>
                    <div className="form-check form-switch d-flex justify-content-end align-items-center">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        checked={formData.needToHire}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            needToHire: e.target.checked,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 flex-grow-1">
                  <div className="d-flex align-items-center res-space-between py-2">
                    <Form.Label className="input_label pe-4">
                      {_l("l_is_furnished")}
                    </Form.Label>
                    <div className="form-check form-switch d-flex justify-content-end align-items-center">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        checked={formData.furnished}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            furnished: e.target.checked,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            {customFields.length ? (
              <div className="custom-accordian-main with-left-header-arrow flex-grow-1 d-flex flex-column h100minus50px h-100 p-15 py-0 px-0">
                <Accordion
                  className="flex-grow-1 overflow-auto"
                >
                  <Accordion.Item
                    eventKey="0"
                    className={`mainTopicTaskFilter for-accessible bg-white-03-solid-imp`}
                  >
                    <div className="accordian-header-box d-flex align-items-center accordian-main-header title-fonts w-100 p-5px px-0 bg-white-03 radius_3 light-theme-grey-light-bg">
                    <Accordion.Header className="text-truncate w-auto bg-transparent with_separator_10 ps-0">
                    <div className="d-flex align-items-center justify-content-between w-100 line-height-1">
                        <div className="title-font c-font f-12 fw-semibold text-truncate d-flex align-items-center">
                          <input
                            type="text"
                            disabled
                            className="bg-transparent p-0 form-control border-0 title-font c-font f-14 fw-semibold text-truncate"
                            value={_l("l_additional_information")}
                          />
                        </div>
                      </div>

                    </Accordion.Header>
                    </div>
                    <div className="accordion-collapse  ">
                    <Accordion.Body className="mt-1 childTopicTaskFilter">
                      <div className="row">
                        {customFields.map((field, index) => {
                          return (
                            <CustomFields
                              key={index}
                              setCustomFieldValues={setCustomFields}
                              customFieldValues={customFields}
                              fieldId={field.id}
                              customField={field}
                            />
                          );
                        })}
                      </div>
                    </Accordion.Body>
                    </div>
                  </Accordion.Item>
                </Accordion>
              </div>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default BasicDetails;
