import * as geotiff from "geotiff";
import * as geokeysToProj4 from "geotiff-geokeys-to-proj4";
import proj4 from "proj4";

// [START visualize_render_rgb]
/**
 * Renders an RGB GeoTiff image into an HTML canvas.
 *
 * The GeoTiff image must include 3 rasters (bands) which
 * correspond to [Red, Green, Blue] in that order.
 *
 * @param  {GeoTiff} rgb   GeoTiff with RGB values of the image.
 * @param  {GeoTiff} mask  Optional mask for transparency, defaults to opaque.
 * @return {HTMLCanvasElement}  Canvas element with the rendered image.
 */
export function renderRGB(rgb, mask) {
  const canvas = document.createElement("canvas");
  canvas.width = mask ? mask.width : rgb.width;
  canvas.height = mask ? mask.height : rgb.height;

  const dw = rgb.width / canvas.width;
  const dh = rgb.height / canvas.height;

  const ctx = canvas.getContext("2d");
  const img = ctx.getImageData(0, 0, canvas.width, canvas.height);

  for (let y = 0; y < canvas.height; y++) {
    for (let x = 0; x < canvas.width; x++) {
      const rgbIdx = Math.floor(y * dh) * rgb.width + Math.floor(x * dw);
      const maskIdx = y * canvas.width + x;

      const imgIdx = y * canvas.width * 4 + x * 4;
      img.data[imgIdx + 0] = rgb.rasters[0][rgbIdx];
      img.data[imgIdx + 1] = rgb.rasters[1][rgbIdx];
      img.data[imgIdx + 2] = rgb.rasters[2][rgbIdx];
      img.data[imgIdx + 3] = mask ? mask.rasters[0][maskIdx] * 255 : 255;
    }
  }

  ctx.putImageData(img, 0, 0);
  return canvas;
}
// [END visualize_render_rgb]

// [START visualize_render_palette]
/**
 * Renders a single value GeoTiff image into an HTML canvas.
 *
 * The GeoTiff image must include 1 raster (band) which contains
 * the values we want to display.
 *
 * @param  {Object} options  Options for rendering the palette.
 * @return {HTMLCanvasElement}  Canvas element with the rendered image.
 */
export function renderPalette({
  data,
  mask,
  colors = ["000000", "ffffff"],
  min = 0,
  max = 1,
  index = 0,
}) {
  const palette = createPalette(colors);
  const indices = data.rasters[index]
    .map((x) => normalize(x, max, min))
    .map((x) => Math.round(x * (palette.length - 1)));
  return renderRGB(
    {
      ...data,
      rasters: [
        indices.map((i) => palette[i].r),
        indices.map((i) => palette[i].g),
        indices.map((i) => palette[i].b),
      ],
    },
    mask
  );
}

/**
 * Creates an {r, g, b} color palette from a hex list of colors.
 *
 * @param  {string[]} hexColors  List of hex colors for the palette.
 * @return {{r, g, b}[]}         RGB values for the color palette.
 */
export function createPalette(hexColors) {
  const rgb = hexColors.map(colorToRGB);
  const size = 256;
  const step = (rgb.length - 1) / (size - 1);
  return Array(size)
    .fill(0)
    .map((_, i) => {
      const index = i * step;
      const lower = Math.floor(index);
      const upper = Math.ceil(index);
      return {
        r: lerp(rgb[lower].r, rgb[upper].r, index - lower),
        g: lerp(rgb[lower].g, rgb[upper].g, index - lower),
        b: lerp(rgb[lower].b, rgb[upper].b, index - lower),
      };
    });
}

/**
 * Convert a hex color into an {r, g, b} color.
 *
 * @param  {string} color  Hex color.
 * @return {{r, g, b}}     RGB values for that color.
 */
export function colorToRGB(color) {
  const hex = color.startsWith("#") ? color.slice(1) : color;
  return {
    r: parseInt(hex.substring(0, 2), 16),
    g: parseInt(hex.substring(2, 4), 16),
    b: parseInt(hex.substring(4, 6), 16),
  };
}

/**
 * Normalizes a number to a given data range.
 *
 * @param  {number} x    Value of interest.
 * @param  {number} max  Maximum value in data range.
 * @param  {number} min  Minimum value in data range.
 * @return {number}      Normalized value.
 */
export function normalize(x, max = 1, min = 0) {
  return clamp((x - min) / (max - min), 0, 1);
}

/**
 * Calculates the linear interpolation for a value within a range.
 *
 * @param  {number} x  Lower value in the range.
 * @param  {number} y  Upper value in the range.
 * @param  {number} t  "Time" between 0 and 1.
 * @return {number}    Inbetween value for that "time".
 */
export function lerp(x, y, t) {
  return x + t * (y - x);
}

/**
 * Clamps a value to always be within a range.
 *
 * @param  {number} x    Value to clamp.
 * @param  {number} min  Minimum value in the range.
 * @param  {number} max  Maximum value in the range.
 * @return {number}      Clamped value.
 */
export function clamp(x, min, max) {
  return Math.min(Math.max(x, min), max);
}

export function rgbToColor({ r, g, b }) {
  const f = (x) => {
    const hex = Math.round(x).toString(16);
    return hex.length == 1 ? `0${hex}` : hex;
  };
  return `#${f(r)}${f(g)}${f(b)}`;
}

export const findSolarConfig = (
  solarPanelConfigs,
  yearlyKwhEnergyConsumption,
  panelCapacityRatio,
  dcToAcDerate
) =>
  solarPanelConfigs.findIndex(
    (config) =>
      config.yearlyEnergyDcKwh * panelCapacityRatio * dcToAcDerate >=
      yearlyKwhEnergyConsumption
  );

export const getDataLayerUrls = async (location, radiusMeters, apiKey) => {
  const args = {
    "location.latitude": location.latitude.toFixed(5),
    "location.longitude": location.longitude.toFixed(5),
    radius_meters: radiusMeters.toString(),
  };
  // console.log("GET dataLayers\n", args);
  const params = new URLSearchParams({ ...args, key: apiKey });
  return fetch(`https://solar.googleapis.com/v1/dataLayers:get?${params}`).then(
    async (response) => {
      const content = await response.json();
      if (response.status != 200) {
        console.error("getDataLayerUrls\n", content);
        throw content;
      }
      // console.log("dataLayersResponse", content);
      return content;
    }
  );
};

export const getLayer = async (layerId, urls, googleMapsApiKey) => {
  const get = {
    mask: async () => {
      const mask = await downloadGeoTIFF(urls.maskUrl, googleMapsApiKey);
      const colors = binaryPalette;
      return {
        id: layerId,
        bounds: mask.bounds,
        palette: {
          colors: colors,
          min: "No roof",
          max: "Roof",
        },
        render: (showRoofOnly) => [
          renderPalette({
            data: mask,
            mask: showRoofOnly ? mask : undefined,
            colors: colors,
          }),
        ],
      };
    },
    dsm: async () => {
      const [mask, data] = await Promise.all([
        downloadGeoTIFF(urls.maskUrl, googleMapsApiKey),
        downloadGeoTIFF(urls.dsmUrl, googleMapsApiKey),
      ]);
      const sortedValues = Array.from(data.rasters[0]).sort((x, y) => x - y);
      const minValue = sortedValues[0];
      const maxValue = sortedValues.slice(-1)[0];
      const colors = rainbowPalette;
      return {
        id: layerId,
        bounds: mask.bounds,
        palette: {
          colors: colors,
          min: `${minValue.toFixed(1)} m`,
          max: `${maxValue.toFixed(1)} m`,
        },
        render: (showRoofOnly) => [
          renderPalette({
            data: data,
            mask: showRoofOnly ? mask : undefined,
            colors: colors,
            min: sortedValues[0],
            max: sortedValues.slice(-1)[0],
          }),
        ],
      };
    },
    rgb: async () => {
      const [mask, data] = await Promise.all([
        downloadGeoTIFF(urls.maskUrl, googleMapsApiKey),
        downloadGeoTIFF(urls.rgbUrl, googleMapsApiKey),
      ]);
      return {
        id: layerId,
        bounds: mask.bounds,
        render: (showRoofOnly) => [
          renderRGB(data, showRoofOnly ? mask : undefined),
        ],
      };
    },
    annualFlux: async () => {
      const [mask, data] = await Promise.all([
        downloadGeoTIFF(urls.maskUrl, googleMapsApiKey),
        downloadGeoTIFF(urls.annualFluxUrl, googleMapsApiKey),
      ]);
      const colors = ironPalette;
      return {
        id: layerId,
        bounds: mask.bounds,
        palette: {
          colors: colors,
          min: "Shady",
          max: "Sunny",
        },
        render: (showRoofOnly) => [
          renderPalette({
            data: data,
            mask: showRoofOnly ? mask : undefined,
            colors: colors,
            min: 0,
            max: 1800,
          }),
        ],
      };
    },
    monthlyFlux: async () => {
      const [mask, data] = await Promise.all([
        downloadGeoTIFF(urls.maskUrl, googleMapsApiKey),
        downloadGeoTIFF(urls.monthlyFluxUrl, googleMapsApiKey),
      ]);
      const colors = ironPalette;
      return {
        id: layerId,
        bounds: mask.bounds,
        palette: {
          colors: colors,
          min: "Shady",
          max: "Sunny",
        },
        render: (showRoofOnly) =>
          [...Array(12).keys()].map((month) =>
            renderPalette({
              data: data,
              mask: showRoofOnly ? mask : undefined,
              colors: colors,
              min: 0,
              max: 200,
              index: month,
            })
          ),
      };
    },
    hourlyShade: async () => {
      const [mask, ...months] = await Promise.all([
        downloadGeoTIFF(urls.maskUrl, googleMapsApiKey),
        ...urls.hourlyShadeUrls.map((url) =>
          downloadGeoTIFF(url, googleMapsApiKey)
        ),
      ]);
      const colors = sunlightPalette;
      return {
        id: layerId,
        bounds: mask.bounds,
        palette: {
          colors: colors,
          min: "Shade",
          max: "Sun",
        },
        render: (showRoofOnly, month, day) =>
          [...Array(24).keys()].map((hour) =>
            renderPalette({
              data: {
                ...months[month],
                rasters: months[month].rasters.map((values) =>
                  values.map((x) => x & (1 << (day - 1)))
                ),
              },
              mask: showRoofOnly ? mask : undefined,
              colors: colors,
              min: 0,
              max: 1,
              index: hour,
            })
          ),
      };
    },
  };
  try {
    return get[layerId]();
  } catch (e) {
    console.error(`Error getting layer: ${layerId}\n`, e);
    throw e;
  }
};

export const calculateLineAngle = (lat1, lon1, lat2, lon2) => {
  // Calculate differences in latitude and longitude
  var dLat = lat2 - lat1;
  var dLon = lon2 - lon1;

  // Calculate the angle using arctangent
  var angleRadians = Math.atan2(dLon, dLat);

  // Convert angle from radians to degrees
  var angleDegrees = angleRadians * (180 / Math.PI);

  // Ensure angle is between 0 and 360 degrees
  if (angleDegrees < 0) {
    angleDegrees += 360;
  }

  return angleDegrees;
};

const downloadGeoTIFF = async (url, apiKey) => {
  // console.log(`Downloading data layer: ${url}`);

  // Include your Google Cloud API key in the Data Layers URL.
  const solarUrl = url.includes("solar.googleapis.com")
    ? url + `&key=${apiKey}`
    : url;
  const response = await fetch(solarUrl);
  if (response.status != 200) {
    const error = await response.json();
    console.error(`downloadGeoTIFF failed: ${url}\n`, error);
    throw error;
  }

  // Get the GeoTIFF rasters, which are the pixel values for each band.
  const arrayBuffer = await response.arrayBuffer();
  const tiff = await geotiff.fromArrayBuffer(arrayBuffer);
  const image = await tiff.getImage();
  const rasters = await image.readRasters();

  // Reproject the bounding box into lat/lon coordinates.
  const geoKeys = image.getGeoKeys();
  const projObj = geokeysToProj4.toProj4(geoKeys);
  const projection = proj4(projObj.proj4, "WGS84");
  const box = image.getBoundingBox();
  const sw = projection.forward({
    x: box[0] * projObj.coordinatesConversionParameters.x,
    y: box[1] * projObj.coordinatesConversionParameters.y,
  });
  const ne = projection.forward({
    x: box[2] * projObj.coordinatesConversionParameters.x,
    y: box[3] * projObj.coordinatesConversionParameters.y,
  });

  return {
    // Width and height of the data layer image in pixels.
    // Used to know the row and column since Javascript
    // stores the values as flat arrays.
    width: rasters.width,
    height: rasters.height,
    // Each raster represents the pixel values of each band.
    // We convert them from TypedArrays to regular JavaScript arrays.
    rasters: rasters.map((typedArray) =>
      Array.prototype.slice.call(typedArray)
    ),
    // The bounding box as a lat/lon rectangle.
    bounds: {
      north: ne.y,
      south: sw.y,
      east: ne.x,
      west: sw.x,
    },
  };
};

export const binaryPalette = ["212121", "B3E5FC"];
export const rainbowPalette = [
  "3949AB",
  "81D4FA",
  "66BB6A",
  "FFE082",
  "E53935",
];
export const ironPalette = ["00000A", "91009C", "E64616", "FEB400", "FFFFF6"];
export const sunlightPalette = ["212121", "FFCA28"];
export const panelsPalette = ["E8EAF6", "1A237E"];
