import {
  CalendarBlank,
  CaretDown,
  Check,
  Clock,
  CloudArrowUp,
  CurrencyEur,
  FileText,
  Flag,
  Paperclip,
  PencilSimpleLine,
  Plus,
  SelectionPlus,
  Trash,
  X,
} from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  _l,
  getFileType,
  getImagePreviewIcon,
  removeExpandedClassFromIntroOfficeList,
  roundOffPowerValue,
  showError,
  showSuccess,
} from "../../hooks/utilities";
import {
  showConfirmation,
  showMessage,
  toastCloseAction,
} from "../../actions/messages";
import { commanoffcanvasisOpen, editBenchmarkDetail, getBenchmarkDetail, setProjectTheme } from "../../actions/customer";
import solarpanelsimg from "../../assets/images/solarpanelsimg.svg";
import accomptecalender from "../../assets/images/accomptecalender.svg";
import moment from "moment";
import html2canvas from "html2canvas";
import BenchmarkServices from "../../services/benchmark-services";
import SolarDocumentPreview from "./SolarDocumentPreview";
import MapIframe from "./MapIframe";
import Select from "react-select";
import EditProposalItem from "./Modals/EditProposalItem";
import benchmarkServices from "../../services/benchmark-services";
import ChooseCatalogItem from "./Modals/ChooseCatalogItem";
import ReactTooltip from "react-tooltip";
import NoMapPlaceholder from "../../assets/images/NoMapPlaceholder.svg";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import TaskListStatusDropdown from "../Dropdowns/TaskListStatusDropdown";

const ProviderProposalDetail = ({
  additionalWidth,
  handleNextStep = () => {},
  handler = () => {},
  isExternal = false,
  isUpdate = false,
  setIsUpdate = () => {},
  setShowLoaderHeader = () => {},
  changeTaskStatus = () => {},
  showTaskDetails = () => {},
}) => {
  const staff_id = localStorage.getItem("staff_id");

  const { benchmarkDetail } = useSelector((state) => state.customer);
 
  const { air_conditioner, back_yard, electronic_gadgets, garage } = benchmarkDetail && benchmarkDetail.solar_items;

  const document_filed = [
    {
      "id": "2",
      "name": "first_name",
      "type": "text",
      "is_required": "0",
      "key_value": "proposal",
      "title": "Proposal",
      "rel_id": "1",
      "default_value" : "",
      "value" : "",
    },
    {
      "id": "3",
      "name": "last_name",
      "type": "text",
      "is_required": "0",
      "key_value": "proposal",
      "title": "Proposal",
      "rel_id": "1",
      "default_value" : "",
      "value" : "",
    },
    {
      "id": "4",
      "name": "address",
      "type": "text",
      "is_required": "0",
      "key_value": "proposal",
      "title": "Proposal",
      "rel_id": "1",
      "default_value" : "",
      "value" : "",
    },
    {
      "id": "7",
      "name": "email",
      "type": "email",
      "is_required": "0",
      "key_value": "proposal",
      "title": "Proposal",
      "rel_id": "1",
      "default_value" : "",
      "value" : "",
    }
  ]
  const [customFieldValues, setCustomFieldValues] = useState([]);
  const [proposalCustomField, setProposalCustomField] = useState([]);

  let proposal =
    benchmarkDetail.proposal_list && benchmarkDetail.proposal_list.length
      ? benchmarkDetail.proposal_list[0].proposals.find(
          (w) => w.proposal_id == benchmarkDetail.proposal_id
        )
      : {};
  const [proposalAmount, setProposalAmount] = useState(
    proposal ? proposal.amount : ""
  );

  const [accessoriesList, setAccessoriesList] = useState(
    proposal && proposal.proposal_item && proposal.proposal_item.length
      ? proposal.proposal_item
          .map((w) => {
            w.title = w.name || "";
            w.one_time_price =
              !isNaN(Number(w.one_time_amount)) && Number(w.one_time_amount) > 0
                ? Number(w.one_time_amount)
                : "";
            w.previewURL = "";
            w.provider_tax = w.provider_tax ? Number(w.provider_tax) : 0;
            return w;
          })
          .sort((a, b) => (b.government_help == 1) - (a.government_help == 1))
      : [
          {
            is_goverment: 0,
            title: _l("l_installation_and_labour"),
            one_time_amount: 0,
            one_time_price: 0,
            attachment_key: _l("l_installation_and_labour")
              .toLowerCase()
              .replace(/\s/g, "_"),
            previewURL: "",
            provider_tax: 0,
          },
        ]
  );
  const [showLoader, setShowLoader] = useState(false);
  const [isMonthlyPriceSelected, setIsMonthlyPriceSelected] = useState(false);
  const [monthlyAmount, setMonthlyAmount] = useState("");
  const [numberOfMonths, setNumberOfMonths] = useState("");
  const [viewingOneTimePrice, setViewingOneTimePrice] = useState(true);
  const [numberOfPlates, setNumberOfPlates] = useState(
    roundOffPowerValue(benchmarkDetail.solar_items.electricity_production) > 0
      ? roundOffPowerValue(benchmarkDetail.solar_items.electricity_production)
      : ""
  );
  const [attachments, setAttachments] = useState({});
  const [termsAndConditionText, setTermsAndConditionText] = useState(
    proposal && proposal.proposal_template &&  proposal.proposal_template.terms
  );
  const [maintainance, setMaintainance] = useState(
    proposal && proposal.proposal_template &&  proposal.proposal_template.maintenance > 0 ? Number(proposal.proposal_template.maintenance) : ""
  );
  const [guarantee, setGuarantee] = useState(
    proposal && proposal.proposal_template &&  proposal.proposal_template.garantie > 0 ? Number(proposal.proposal_template.garantie) : ""
  );
  const [openLightBox, setOpenLightBox] = useState(false);
  const [isGeneratingPreview, setIsGeneratingPreview] = useState(false);
  const [financialAid, setFinancialAid] = useState(
    accessoriesList.length && accessoriesList[0].is_goverment > 0
      ? accessoriesList[0]
      : {}
  );
  const [panelCapacity, setPanelCapacity] = useState(
    isNaN(Number(proposal.plate_capacity)) ? Number(proposal.plate_capacity) : 0
  );
  const [isEditTitle, setIsEditTitle] = useState(false);
  const [isAccessoryEditOngoing, setIsAccessoryEditOngoing] = useState(-1);
  const [editSolarPanelCount, setEditSolarPanelCount] = useState(false);
  const [operatorPaymentMethod, setOperatorPaymentMethod] = useState(
    proposal && proposal.proposal_template && proposal.proposal_template.preferred_payment_mode
      ? proposal.proposal_template.preferred_payment_mode
      : "cheque"
  );
  const [monthalySaving, setMonthalySaving] = useState("00");
  const [currentTab, setCurrentTab] = useState(-1);
  const [showAccessoriesEditModal, setShowAccessoriesEditModal] =
    useState(false);
  const [editTittle, setEditTittle] = useState(false);  
  const [editTittleValue, setEditTittleValue] = useState(benchmarkDetail && benchmarkDetail.title ? benchmarkDetail.title  : "");  
  const [addCatalogItems, setAddCatalogItems] = useState(-1);
  const [rel_type, setrel_type] = useState('');
  const paymentMethodOptions = [
    { label: _l("l_both"), value: "both" },
    { label: _l("l_by_cheque"), value: "cheque" },
    { label: _l("l_by_bank_transfer"), value: "bank_transfer" },
  ];
  const VATselectionOption = [
    { label: "0%", value: "0" },
    { label: "5%", value: "5" },
    { label: "10%", value: "10" },
    { label: "20%", value: "20" },
  ];

  const lightBoxData = [
    {
      src: benchmarkDetail.solar_map,
      type: getFileType(benchmarkDetail.solar_map),
    },
  ];

  const [loadedImages, setLoadedImages] = useState(false);
  const [proposalListDetails, setProposalListDetails] = useState({});
  const [descriViewMore, setDescriViewMore] = useState(-1);
  const [statusChangeLoader, setStatusChangeLoader] = useState(false);
  const [currentProposalId, setCurrentProposalId] = useState(0);
  const dispatch = useDispatch();

  const invoiceRef = useRef();

  const validCustomeField = () => {
    let allVaild = true;
    customFieldValues.forEach((customField) => {
      if (customField.is_required == 1 && (!customField.value || customField.value.trim() == "")) {
        showError(customField.title);
        allVaild = false;
      }
    });
    return allVaild;
  };


  const updateAmount = async (isPreview, gotoNext = true) => {
    setIsUpdate(false);
    const totalAmount = getTotalPayableAmount();
    if (totalAmount <= 0) {
      showError("l_please_enter_amount_for_accessories");
      return false;
    }
    if (validCustomeField()) {
    let screenshot;

    if (isPreview) {
      if (invoiceRef.current) {
        setIsGeneratingPreview(true);
        const canvas = await html2canvas(invoiceRef.current, { useCORS: true });
        screenshot = canvas.toDataURL("image/jpeg");
      } 
    } else {
      setShowLoader(true);
      setShowLoaderHeader(gotoNext == false ? true : false);
    }

    const finalCustomeFields = customFieldValues.map(cu => {
      return ({
        field_id: cu.id,
        value: cu.value
      })
    })

    BenchmarkServices.updateSolarProposalAmount(
      accessoriesList.filter((w) => w.title && w.title.length),
      Number(guarantee),
      Number(maintainance),
      termsAndConditionText,
      benchmarkDetail.id,
      proposal.proposal_id,
      benchmarkDetail.project_id,
      accessoriesList.length
        ? Number(accessoriesList[0].one_time_amount) ||
            Number(accessoriesList[0].monthly_price)
        : 0,
      numberOfPlates,
      attachments,
      screenshot,
      panelCapacity,
      operatorPaymentMethod,
      finalCustomeFields
    )
      .then((response) => {
        if (response.status &&  proposalListDetails.status == 4 && Number(proposalListDetails.amount) <= 0) {
          changeTaskStatus("4", benchmarkDetail, true, totalAmount, setStatusChangeLoader);
        }
        if (isPreview) {
          window.open(response.data.file_preview, "_blank");
          dispatch(getBenchmarkDetail(benchmarkDetail.id));
          return;
        }
        if (!response.status) {
          dispatch(
            showMessage("unsucess", _l("l_error"), _l(response.message))
          );
        }
        if(gotoNext === true) {
          dispatch(commanoffcanvasisOpen(false));
        }
      })
      .catch((e) => {
        console.error(e);
        showError("l_something_went_wrong");
      })
      .finally(() => {
        setShowLoader(false);
        setShowLoaderHeader(false);
        setIsGeneratingPreview(false);
        if (gotoNext == false) {
          showSuccess("l_proposal_price_added")
          return
        } else 
        if (!isPreview) {
          handleNextStep();
        }
      });
    }
  };

  const addNewAccessoryItem = () => {
    if (!accessoriesList.some((acc) => acc.title === "")) {
      setAccessoriesList(
        accessoriesList.concat([
          {
            title: "",
            one_time_price: "",
            number_of_month: "",
            monthly_price: "",
            provider_tax: 0,
            is_default: 0,
            one_time_amount : "0.00",
            one_time_tax_amount : "0.00",
          },
        ])
      );
      setIsAccessoryEditOngoing(accessoriesList.length);
    }
  };

  const handleUpdateAttachments = (e, key, isFinancialDocument) => {
    setAttachments({
      ...attachments,
      [key]: {
        file: e.target.files[0],
        previewURL: getImagePreviewIcon(e.target.files[0]),
      },
    });
    if (isFinancialDocument) {
      setFinancialAid({
        ...financialAid,
        previewURL: getImagePreviewIcon(e.target.files[0]),
      });
    }
    e.target.value = "";
  };

  const deleteAttachment = (attachment, key) => {
    if (attachment.id) {
    } else {
      let updatedAttachments = { ...attachments };
      delete updatedAttachments[key];
      setAttachments(updatedAttachments);
    }
  };

  const getTotalPayableAmount = () => {
    if (viewingOneTimePrice) {
      let totalAmount = accessoriesList
        .filter((a) => a.is_goverment != 1)
        .reduce(
          (total, accessory) =>
            Number(total) + Number(accessory.one_time_price),
          0
        );
      if (totalAmount > 0) {
        return totalAmount;
      } else {
        return 0;
      }
    } else {
      let totalAmount = accessoriesList
        .filter((a) => a.is_goverment != 1)
        .reduce(
          (total, accessory) =>
            Number(total) +
            Number(accessory.number_of_month) * Number(accessory.monthly_price),
          0
        );
      if (totalAmount > 0) {
        return totalAmount;
      } else {
        return 0;
      }
    }
  };
  const getTotalPayableVATfees = () => {
    let totalAmount = 0;

    accessoriesList.map((accessory, i) => {
      if (
        Number(accessory.provider_tax) > -1 &&
        Number(accessory.one_time_price)
      ) {
        totalAmount =
          totalAmount +
          Number(accessory.provider_tax) *
            Number(accessory.one_time_price) *
            0.01;
      }
    });
    return totalAmount.toFixed(2);
  };

  const calculateSolarhubFees = () => { 
    let totalAmount = 0
    if (accessoriesList) {
      accessoriesList.forEach((accessory) => {
        if (
          accessory.is_goverment != 1 &&
          !isNaN(Number(accessory.one_time_price))
        ) {
          totalAmount += Number(accessory.one_time_price);
        }
      });
    }
    return Number(Number(totalAmount * 0.1).toFixed(2));
  };

  const removeAccessoryItem = async (accessory, index) => {
    dispatch(toastCloseAction());
    if (Number(accessory.id)) {
      try {
        const response = await BenchmarkServices.deleteSolarProposalItem(
          proposal.proposal_id,
          accessory.id,
          benchmarkDetail.project_id,
          proposal.id
        );
        if (response.status) {
          setAccessoriesList(accessoriesList.filter((acc, i) => i !== index));
        } else {
          showError(response.message);
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      setAccessoriesList(accessoriesList.filter((acc, i) => i !== index));
    }
  };

  const deleteProposalItem = (accessory, index) => {
    dispatch(
      showConfirmation(
        _l("l_are_you_sure"),
        _l("l_delete_accessory_confirmation"),
        _l("l_yes"),
        _l("l_no"),
        undefined,
        () => {
          removeAccessoryItem(accessory, index);
        }
      )
    );
  };

  const saveOperatorTermsAndConditions = async () => {
    try {
      const response = await BenchmarkServices.saveOperatorTermsAndConditions(
        proposal.proposal_id,
        guarantee,
        maintainance,
        termsAndConditionText,
        operatorPaymentMethod
      );
      if (response.status) {
        showMessage("l_template_saved");
      } else {
        showError(response.message);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateBenchmarkDetails = (data, flag) =>{
    let date = flag == "startdate" ? moment(data).format("YYYY-MM-DD") : ""
    let title = flag == "title" ? data.trim() : ""
    try {
      benchmarkServices.updateSolarBenchmarkDetails(benchmarkDetail.id,benchmarkDetail.project_id,date,title, benchmarkDetail.proposal_id).then((res)=>{
        if (res) {
          let updateBenchmark = benchmarkDetail
          if (flag == "title") {
            updateBenchmark.title = title
          }else if (flag == "startdate"){
            updateBenchmark.startdate = date
          }
          dispatch(editBenchmarkDetail(updateBenchmark));
          showSuccess(res.message)
        }else{
          showError(res.message)
        }
      })
    } catch (error) {
      console.log(error)
    }

  }

  useEffect(() => {
    localStorage.setItem("selectedTheme", "light");
    dispatch(setProjectTheme("light"));
    document.body.classList.remove("with-left-penel");

    const element = document.getElementById("left-panel-main");
    if (element) {
      // element.classList.add("d-none");
    }

    let attachmentPreviews = {};
    let financialAidPreview = "";

    if (proposal.proposal_file) {
      attachmentPreviews = {
        ...attachmentPreviews,
        contract: { previewURL: proposal.proposal_file },
      };
    }

    if (proposal.proposal_item && proposal.proposal_item.length) {
      proposal.proposal_item.forEach((item) => {
        if (item.attachment_url && item.attachment_url.length) {
          attachmentPreviews = {
            ...attachmentPreviews,
            [`${item.name.toLowerCase().replace(/\s/g, "_")}`]: {
              previewURL: item.attachment_url,
            },
          };
        }
        if (
          item.is_goverment > 0 &&
          item.attachment_url &&
          item.attachment_url.length
        ) {
          financialAidPreview = item.attachment_url;
          setFinancialAid({ ...financialAid, previewURL: item.attachment_url });
        }
      });
    }

    setAttachments(attachmentPreviews);

    return () => {
      if (element) {
        element.classList.remove("d-none");
      }
    };
  }, []);

  useEffect(() => {
    setProposalListDetails(
      benchmarkDetail && benchmarkDetail.proposal_list && benchmarkDetail.proposal_list[0] 
      && benchmarkDetail.proposal_list[0].proposals && benchmarkDetail.proposal_list[0].proposals.length > 0 ?  benchmarkDetail.proposal_list[0].proposals[0] : {} );
    let monthlyBills = benchmarkDetail.solar_items ? benchmarkDetail.solar_items.monthly_bill : 0
    let recommendation = benchmarkDetail.solar_items ? Math.floor(roundOffPowerValue(benchmarkDetail.solar_items.electricity_production)): 0
    let recommendations = Math.round((recommendation * 1000) / 12)
    let monthlyConsumptions = monthlyBills * 4
    const saveYearly = Math.round((monthlyBills * recommendations)/monthlyConsumptions)
    setMonthalySaving(saveYearly)
  }, [benchmarkDetail])

  useEffect(() => {
    setAccessoriesList(
      proposal && proposal.proposal_item && proposal.proposal_item.length
      ? proposal.proposal_item
          .map((w) => {
            w.title = w.name || "";
            w.one_time_price =
              !isNaN(Number(w.one_time_amount)) && Number(w.one_time_amount) > 0
                ? Number(w.one_time_amount)
                : "";
            w.previewURL = "";
            w.provider_tax = w.provider_tax ? Number(w.provider_tax) : 0;
            return w;
          })
          .sort((a, b) => (b.government_help == 1) - (a.government_help == 1))
      : [
          {
            is_goverment: 0,
            title: _l("l_installation_and_labour"),
            one_time_amount: 0,
            one_time_price: 0,
            attachment_key: _l("l_installation_and_labour")
              .toLowerCase()
              .replace(/\s/g, "_"),
            previewURL: "",
            provider_tax: 0,
          },
        ]
      )
      setTermsAndConditionText( 
        proposal && proposal.terms && proposal.terms != '' && proposal.terms != undefined 
          ? proposal.terms 
          : proposal && proposal.proposal_template &&  proposal.proposal_template.terms != undefined 
            ? proposal.proposal_template.terms 
            : ''
      );
      setMaintainance( 
        proposal && proposal.maintenance != '' && proposal.maintenance != undefined 
          ? Number(proposal.maintenance) 
          : proposal && proposal.proposal_template &&  proposal.proposal_template.maintenance > 0 
            ? Number(proposal.proposal_template.maintenance) 
            : ""
      );
      setGuarantee( 
        proposal && proposal.garantie != '' && proposal.garantie != undefined 
          ? Number(proposal.garantie) 
          : proposal && proposal.proposal_template &&  proposal.proposal_template.garantie > 0 
            ? Number(proposal.proposal_template.garantie) 
            : ""
      );
      setOperatorPaymentMethod( 
        proposal && proposal.preferred_payment_mode != '' && proposal.preferred_payment_mode != undefined 
          ? proposal.preferred_payment_mode 
          : proposal && proposal.proposal_template && proposal.proposal_template.preferred_payment_mode
            ? proposal.proposal_template.preferred_payment_mode
            : "cheque"
      );
  }, [benchmarkDetail])

  useEffect(() => {
    removeExpandedClassFromIntroOfficeList(); 
    document.body.classList.remove('with-left-penel');
  })

  useEffect(() => {
    if (isUpdate && isUpdate == true) {
      updateAmount(false , false);
    }
  }, [isUpdate])

  useEffect(() => {
    if (benchmarkDetail && benchmarkDetail.document_field && benchmarkDetail.document_field.length > 0) {
      const customProposalFilter =  benchmarkDetail.document_field.filter((item) => item.key_value == "proposal");
      if (customProposalFilter && customProposalFilter.length > 0) {
        setProposalCustomField(customProposalFilter);
        if (benchmarkDetail && benchmarkDetail.proposal_list && benchmarkDetail.proposal_list[0] && benchmarkDetail.proposal_list[0] ) {
          
          let currentProposal = benchmarkDetail.proposal_list[0].proposals.find(
            (w) => w.proposal_id == benchmarkDetail.proposal_id
          );
          
          if (currentProposal && currentProposal.field_value) {
            const currentProposalFields = currentProposal.field_value.map((fi) => {
              const matchedField = customProposalFilter.find((bcf) => bcf.id === fi.field_id);
            
              if (matchedField) {
                return {
                  ...fi,
                  ...matchedField,
                  value: fi.value,  // If you need to set `value` specifically from `fi`
                };
              }
            
              // Return `fi` as is if there's no matching field
              return fi;
            });
  
            const isID = currentProposalFields.map(item => item.field_id);
            let newFields = []
            if (currentProposalFields.length > 0) {
              customProposalFilter.map((bcf) => {
                if (!isID.includes(bcf.id)) {
                  newFields.push({...bcf, value : ''});
                }
              })
              setCustomFieldValues(currentProposalFields.concat(newFields));   
            }else {
              setCustomFieldValues(
                customProposalFilter.map((item) => {
                  return {
                    ...item,
                    value:'',
                  };
                })
              );
            }
          }
        }
      }
      
    }
  }, [benchmarkDetail]);
  

  return (
    <div className="flex-grow-1 d-flex flex-column proposal-detail-main h-100">
      <div className="row h-100">
        {/* <div className="col-lg-2"></div> */}
        <div
          className={` mx-auto h-100 ${
            additionalWidth
              ? "col-lg-12"
              : "col-lg-12 registration-footer-content"
          }`}
          ref={invoiceRef}
        >
          <div className="flex-grow-1 d-flex flex-column h_calc30">
            <div
              className={`row gx-5 mt-15px h-100  ${
                additionalWidth ? "" : "px-0 px-md-4"
              }`}
            >
              <div className="col-xl-8 ps-0 h-100 overflow-hiiden-auto">
                <div className="align-items-center d-flex justify-content-between p-10 shadow-none ps-0">
                  <div className="c-font color-white-60 light-theme-color-white-70 f-14 title-fonts">
                    {_l("l_quote_details")}
                  </div>
                </div>
                {/* Proposal Prestataire card detail top loop start  */}
                <div className="border radius_3 row g-0 comman-white-box-shadow overflow-hiiden-auto bg-white-03 shadow-none ">
                  <div className="col-lg-9 order-1">
                    <div className="d-flex h-100 flex-column pt-20px pb-20px ps-10px pe-10px p10pxresponsive">
                      <div className="d-flex justify-content-between ">
                        <div className="text-truncate mb20px with_separator_10 ps-20pximp pe-20pximp before-dnone flex-grow-1">
                          <div className="d-flex align-items-center comman_action_icon justify-content-between">
                            <div className={`c-font f-16 fw-semibold text-truncate title-fonts ${editTittle ? "flex-grow-1" : ""}`}>
                              {editTittle ?
                              <>
                              <Form.Control
                                placeholder={10}
                                className="color-white form-control fw-semibold h32wauto pb-0 pt-0 res-width-100 title-fonts w-100"
                                value={editTittleValue ? editTittleValue : "Installation of solar panels" + " " +roundOffPowerValue(
                                benchmarkDetail.solar_items.electricity_production
                              ) + " "+"Kwc" }
                              onChange={(e)=>{ setEditTittleValue(e.target.value)}}
                              onBlur={()=>{
                                updateBenchmarkDetails(editTittleValue, "title");
                                setEditTittle(false);
                              }}
                              />
                              </>
                              : benchmarkDetail.title ? benchmarkDetail.title :`${_l("l_solar_installation_title")} 
                              ${roundOffPowerValue(
                                benchmarkDetail.solar_items.electricity_production
                              )}  Kwc`}
                            </div>
                            <div className="d-flex gap-3">
                            <UncontrolledDropdown className=" position-relative light-theme-blue-bg radius_5" onClick={(e) => e.stopPropagation()}>
                              <DropdownToggle
                                disabled= {statusChangeLoader}
                                data-toggle="dropdown"
                                tag="a"
                                data-bs-auto-close="outside"
                                aria-expanded="false"
                                className="btn dropdown d-flex align-items-center gap-1 dropdown-center team-list-dropdown border"
                              >
                                <div className="d-flex align-items-center w-100 justify-content-center gap10px">
                                {statusChangeLoader && currentProposalId === proposalListDetails.proposal_id 
                                  ? <></> 
                                  :
                                  <span
                                      className={`c-dots c-10 ${ proposalListDetails.status == 4 && Number(proposalListDetails.amount) <= 0
                                        ? "bg-black"
                                        :  proposalListDetails.status == 4
                                          ? "bgyellow"
                                          :  proposalListDetails.status == 3
                                            ? "lightgreen"
                                            : "bg-red"
                                        }`}
                                    ></span>
                                    }
                                    <span className="ps-0">
                                      {statusChangeLoader && currentProposalId === proposalListDetails.proposal_id ? _l('l_please_wait')
                                        : (proposalListDetails.status == 4 && Number(proposalListDetails.amount) <= 0
                                          ? _l("l_new_proposal")
                                          : proposalListDetails.status == 4
                                            ? _l("l_pending")
                                            : proposalListDetails.status == 3 ?
                                              _l("l_accepted")
                                              : _l("l_declined"))}
                                      {statusChangeLoader && currentProposalId === proposalListDetails.proposal_id
                                        ? <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          className={`ms-2 `}
                                          aria-hidden="true"
                                        />
                                        : <></>
                                      }
                                    </span>
                                    <CaretDown
                                      size={14}
                                      className="c-icons ms-auto me-0"
                                    />
                                </div>
                              </DropdownToggle >
                                <TaskListStatusDropdown
                                  setTaskStatus={(statusId) => {
                                    changeTaskStatus(statusId, benchmarkDetail, true, 0, setStatusChangeLoader);
                                  }}
                                  is_task = {proposalListDetails.proposal_id}
                                  isFrom = {"crm_list"}
                                  isdisabled = {proposalListDetails.status == 4 && Number(proposalListDetails.amount) <= 0 
                                                  ? [4]
                                                  : proposalListDetails.status == 4
                                                    ? [4] 
                                                    : proposalListDetails.status == 3
                                                    ? [3]
                                                    : [2]
                                                }
                                  setCurrentProposalId = {setCurrentProposalId}
                                />
                            </UncontrolledDropdown>
                            <UncontrolledDropdown className=" position-relative light-theme-blue-bg radius_5" onClick={(e) => e.stopPropagation()}>
                              <DropdownToggle
                                data-toggle="dropdown"
                                tag="a"
                                data-bs-auto-close="outside"
                                aria-expanded="false"
                                className="btn dropdown d-flex align-items-center gap-1 dropdown-center team-list-dropdown border"
                              >
                                <Clock
                                  size={18}
                                  weight="light"
                                  className="c-icons"
                                />
                                Rappel {`(${benchmarkDetail && benchmarkDetail.reminder.length > 0 ? benchmarkDetail.reminder.length : 0})`}
                              </DropdownToggle>
                              <React.Fragment>
                              
                                <DropdownMenu
                                  container="body"
                                  direction="down"
                                  className={`dropdown-menu `}
                                >
                                  {benchmarkDetail && benchmarkDetail.reminder.length > 0  ?  benchmarkDetail.reminder.map((item) => {
                                    return(
                                      <li className="hr_sepretr l_50px"> 
                                      <DropdownItem className="text-truncate" toggle={false}>
                                        <div className="d-flex align-items-center" onClick={() => showTaskDetails(item.task_id)}>
                                          <div className="ps-2 w100minus20 text_wrap">
                                            <div className="c-list-detail text-truncate color-white">
                                            { item.description}
                                            </div>
                                          </div>
                                            <div className="align-items-center d-flex">
                                              {/* {moment(new Date())} */}
                                              {moment(item.date).format("DD/MM/YYYY")}
                                            </div>
                                        </div>
                                      </DropdownItem>
                                    </li>
                                    )
                                  }) 
                                  : <CommanPlaceholder imgType="NoDoneTask-task" mainWidth="m-auto" placeholderText={"Aucun rappel trouvé"} />}
                                </DropdownMenu>
                              </React.Fragment>
                            </UncontrolledDropdown>
                            </div>
                            {/* <a href="#/" className={`action_icon h32w32 with_bg position-relative shadow-none ${staff_id == benchmarkDetail.staff_id ? "" : "for-disabled"}` }
                            onClick={()=>{
                              if (staff_id == benchmarkDetail.staff_id) {
                                setEditTittle(true)
                              }
                            }}>
                              <PencilSimpleLine size={14} weight="light" className="c-icons"/>
                            </a> */}
                          </div>
                          <div className="c-font color-white-60 f-12 pt-1 text-truncate light-theme-color-white-70">
                            {benchmarkDetail.address}
                          </div>
                        </div>
                      </div>
                      <div className="align-items-start align-items-md-center d-flex flex-md-row gap-3 gap-md-0">
                        <div className="with_separator_10 flex-grow-1 ps-20pximp pe-20pximp">
                          <div className="d-flex align-items-center gap10px comman_action_icon">
                            <div className="c-font f-16 fw-semibold title-fonts">
                              {moment(benchmarkDetail.startdate).format(
                                "DD/MM/YYYY"
                              )}
                            </div>
                              {/* calendar start */}
                          {/* <div className="comman_action_icon custom-datepicker withoutlabel  withinputabsolute me-10px"
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main">
                          <div className={`action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img position-relative ${staff_id == benchmarkDetail.staff_id ? "" : "for-disabled"}`}>
                            <PencilSimpleLine
                              size={14}
                              weight="light"
                              className="c-icons"
                            />
                            <DatePicker
                              portalId="custom_datepicker"
                              calendarClassName="custom-datepicker"
                              calendarStartDay={1}
                              className="close-toast"
                              dateFormat="dd/MM/yyyy"
                              selected={new Date(benchmarkDetail.startdate)}
                              onChange={(e)=>{
                                if (staff_id == benchmarkDetail.staff_id ) {
                                  updateBenchmarkDetails(e ,"startdate");
                                }
                              }}
                            >
                            </DatePicker>
                          </div>
                        </div>  */}
                        {/* calendar end */}
                          </div>
                          <div className="c-font color-white-60 f-12 pt-1 text-wrap light-theme-color-white-70">
                            Date prévisionnelle
                          </div>
                        </div>
                        <div className="with_separator_10 flex-grow-1 ps-20pximp">
                          <div className="c-font f-16 fw-semibold title-fonts">
                            {benchmarkDetail.solar_items
                              ? benchmarkDetail.solar_items.monthly_bill
                              : 0}{" "}
                            €
                          </div>
                          <div className="c-font color-white-60 f-12 pt-1 text-wrap light-theme-color-white-70">
                            {_l("l_monthly_energy_bill")}
                          </div>
                        </div>
                        <div className="with_separator_10 flex-grow-1 ps-20pximp pe-20pximp before-dnone">
                          <div className="c-font f-16 fw-semibold title-fonts">
                            {benchmarkDetail.solar_items
                              ? benchmarkDetail.solar_items.no_of_plates
                              : 0}
                          </div>
                          <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                            {_l("l_solar_panels")}
                          </div>
                        </div>
                        <div className="with_separator_10 flex-grow-1 ps-20pximp pe-20pximp d-none">
                          <div className="c-font f-16 fw-semibold title-fonts">
                            {benchmarkDetail.superficy}m²
                          </div>
                          <div className="c-font color-white-60 f-12 pt-1 text-wrap light-theme-color-white-70">
                            {_l("l_superficy")}
                          </div>
                        </div>
                        <div className="with_separator_10 flex-grow-1 ps-20pximp pe-20pximp d-none">
                          <div className="c-font f-16 fw-semibold title-fonts align-items-center d-flex">
                            {benchmarkDetail.no_of_hiring}
                          </div>
                          <div className="c-font color-white-60 f-12 pt-1 text-wrap light-theme-color-white-70">
                            {_l("l_occupants")}
                          </div>
                        </div>
                      </div>
                      <div className="align-items-start align-items-md-center row flex-md-row gap-3 gap-md-0 ps-20pximp pt-20px">

                    {/* AC */}
                        <div className="col-lg-2">
                          <div class="d-flex form-check">
                            <div class="pe-2">
                              <input
                                disabled={true}
                                className="form-check-input light-theme-border light-theme-white-bg box-shadow-none"
                                type="checkbox"
                                checked={Number(air_conditioner)}
                                id="air_conditioner"
                              />
                            </div>
                            <label
                              class="c-font f-12 form-check-label fw-semibold"
                              for="air_conditioner"
                            >
                              {_l("l_air_conditioner")}
                            </label>
                          </div>
                        </div>

                    {/* BACK YARD */}
                        <div className="col-lg-3">
                          <div class="d-flex form-check">
                            <div class="pe-2">
                              <input
                                disabled={true}
                                className="form-check-input light-theme-border light-theme-white-bg box-shadow-none"
                                type="checkbox"
                                id="back_yard"
                                checked={Number(back_yard)}
                              />
                            </div>
                            <label class="c-font f-12 form-check-label fw-semibold" for="back_yard">
                              {_l("l_back_yard")}
                            </label>
                          </div>
                        </div>

                    {/* garage */}
                        <div className="col-lg-2">
                          <div class="d-flex form-check">
                            <div class="pe-2">
                              <input
                                disabled={true}
                                className="form-check-input light-theme-border light-theme-white-bg box-shadow-none"
                                type="checkbox"
                                checked ={Number(garage)}
                                id="garage"
                              />
                            </div>
                            <label
                              class="c-font f-12 form-check-label fw-semibold"
                              for="garage"
                            >
                              {_l("l_garage")}
                            </label>
                          </div>
                        </div>

                    {/*electronic_gadgets  */}
                        <div className="col-lg-5">
                          <div class="d-flex form-check">
                            <div class="pe-2">
                              <input
                                disabled={true}
                                className="form-check-input light-theme-border light-theme-white-bg box-shadow-none"
                                type="checkbox"
                                checked={Number(electronic_gadgets)}
                                id="electronic_gadgets"
                              />
                            </div>
                            <label
                              class="c-font f-12 form-check-label fw-semibold"
                              for="electronic_gadgets"
                            >
                              {_l("l_electronic_gadgets")}
                            </label>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 d-flex flex-column position-relative comman_action_icon">
                  <a href="#/" className={`btn btnyellow bottom-0 end-0  mb-10px me-10px position-absolute ${staff_id == benchmarkDetail.staff_id ? '' :'for-disabled'}`}
                   onClick={()=>{
                    if (staff_id == benchmarkDetail.staff_id) {
                      handler(benchmarkDetail.address);
                    }
                  }}
                  > Modifier
                            </a>
                    <div
                      className="border-start d-flex light-theme-grey-light-bg flex-grow-1 min-h-140"
                      onClick={() =>
                        benchmarkDetail.solar_map
                          ? setOpenLightBox(true)
                          : () => {}
                      }
                    >
                      {benchmarkDetail.solar_map ? (
                        <>
                        {!loadedImages && (
                          <img
                            src={NoMapPlaceholder}  // Your placeholder image
                            alt="placeholder"
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                          />
                        )} 
                        <img
                          className="h-100 w-100 object-fit-cover min-h-140"
                          src={benchmarkDetail.solar_map}
                          alt={"Solar Map"}
                          style={{ display: loadedImages ? 'block' : 'none' }}
                          onLoad={() => setLoadedImages(true)}  
                          onError={() => setLoadedImages(true)}  
                        />
                        </>
                      ) : (
                        <MapIframe
                          fullWidth
                          address={benchmarkDetail.address}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* Proposal Prestataire card detail top loop end  */}
                <div>
                  <div className="align-items-start align-items-sm-center d-flex justify-content-between p-10 shadow-none ps-0 mt-10 flex-column flex-sm-row">
                    <div className="c-font color-white-60 light-theme-color-white-70 f-14 title-fonts">
                      {_l("l_proposal_detail")}
                    </div>
                    <div className="form-check mt-2 mt-sm-0 d-none">
                      <input
                        className="form-check-input border light-theme-white-bg"
                        type="checkbox"
                        checked={isMonthlyPriceSelected}
                        onChange={(e) => {
                          setIsMonthlyPriceSelected(e.target.checked);
                        }}
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault"
                      >
                        {_l("l_send_monthly_price")}
                      </label>
                    </div>
                  </div>
                  <div className="border radius10 bg-white-03 comman-white-box-shadow shadow-none">
                    <div>
                      <div className=" d-flex justify-content-between align-items-center p-20 border-last-none border-bottom flex-wrap flex-xl-nowrap gap-3 gap-md-0 d-none">
                        <div className="d-flex align-items-center flex-grow-1">
                          <div className="h35w35 comman-round-box with-bg bg-white-05 me-20px">
                            <div
                              className="comman-bg-img h-100 w-100 bg-style-cover"
                              style={{
                                backgroundImage: `url('${solarpanelsimg}')`,
                              }}
                            ></div>
                          </div>
                          <div className="c-font f-14 fw-semibold title-fonts d-flex align-items-center  w100minus100">
                            <span>{_l("l_solar_panels_new")}</span>
                            {editSolarPanelCount ? (
                              <Form.Control
                                placeholder={10}
                                className="mx-2 color-white form-control fw-semibold h32wauto pe-1 pt-0 pb-0 title-fonts"
                                type="number"
                                value={numberOfPlates}
                                defaultValue={
                                  benchmarkDetail.solar_items.no_of_plates > 0
                                    ? benchmarkDetail.solar_items.no_of_plates
                                    : ""
                                }
                                onChange={(e) => {
                                  setNumberOfPlates(e.target.value);
                                }}
                              />
                            ) : (
                              <span className="c-font f-14 fw-semibold title-fonts mx-2">
                                {numberOfPlates}
                              </span>
                            )}
                            <div className="comman_action_icon ms-10px">
                              <a
                                className="action_icon h55w55 with_bg position-relative shadow-none"
                                onClick={() => {
                                  setEditSolarPanelCount(!editSolarPanelCount);
                                }}
                              >
                                {editSolarPanelCount ? (
                                  <Check
                                    size={18}
                                    weight="light"
                                    className="c-icons"
                                  />
                                ) : (
                                  <PencilSimpleLine
                                    size={18}
                                    weight="light"
                                    className="c-icons"
                                  />
                                )}
                              </a>
                            </div>
                            {/* <span>+ {_l("l_laid")}</span>
                            <Form.Control
                              placeholder={10}
                              className="mx-2 color-white form-control fw-semibold h32wauto max-w50px pe-1 pt-0 pb-0"
                              type="number"
                              value={panelCapacity}
                              onChange={(e) => {
                                setPanelCapacity(e.target.value);
                              }}
                            /> */}
                          </div>
                        </div>
                        <div className="d-flex gap-3">
                          <div className="align-items-center border d-flex ps15px radius_5">
                            <CurrencyEur
                              size={16}
                              weight="light"
                              className="c-icons"
                            />
                            <Form.Control
                              placeholder={_l("l_onetime_price")}
                              className=" fw-semibold color-white m-w100px ps-10px border-0 shadow-none text-center"
                              type="number"
                              value={proposalAmount}
                              min={0}
                              onChange={(e) => {
                                setProposalAmount(e.target.value);
                              }}
                            />
                          </div>
                          {isMonthlyPriceSelected ? (
                            <React.Fragment>
                              <div className="align-items-center border d-flex ps15px radius_5">
                                <CurrencyEur
                                  size={16}
                                  weight="light"
                                  className="c-icons"
                                />
                                <Form.Control
                                  placeholder={_l("l_amount_by_month")}
                                  className=" fw-semibold color-white m-w100px ps-10px border-0 shadow-none text-center"
                                  type="number"
                                  value={monthlyAmount}
                                  min={0}
                                  onChange={(e) => {
                                    setMonthlyAmount(e.target.value);
                                  }}
                                />
                              </div>
                              <Form.Control
                                placeholder={_l("l_number_of_months")}
                                className=" fw-semibold color-white m-w100px text-center"
                                type="text"
                                value={numberOfMonths}
                                min={0}
                                onChange={(e) => {
                                  setNumberOfMonths(e.target.value);
                                }}
                              />
                            </React.Fragment>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          <div className={`comman_action_icon`}>
                            <div className="d-flex h-100">
                              <a
                                href="#/"
                                className="action_icon min-width55 with_bg position-relative h-100 "
                                data-tip={_l("l_choose_file")}
                                data-effect="solid"
                                data-delay-show="1000"
                                data-class="tooltip-main"
                                onInput={(e) => {
                                  handleUpdateAttachments(e, "contract");
                                }}
                                accept="image/*,application/pdf"
                              >
                                <input type="file" className="absolute-input" />
                                <Paperclip
                                  size={16}
                                  weight="light"
                                  className="c-icons"
                                />
                              </a>
                            </div>
                          </div>
                          {attachments.contract ? (
                            <div className="upload-image-preview comman_action_icon ">
                              {typeof attachments.contract.previewURL ===
                              "string" ? (
                                <div className="comman-image-box h55w55 radius_3 upload-image action_icon">
                                  <div
                                    className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                                    style={{
                                      backgroundImage: `url('${attachments.contract.previewURL}')`,
                                    }}
                                  ></div>
                                  <a href="#/" className="upload-close">
                                    <X
                                      size={10}
                                      weight="light"
                                      className="c-icons"
                                    />
                                  </a>
                                </div>
                              ) : (
                                <div className="align-items-center comman-image-box d-flex h32w32 justify-content-center radius_3 upload-image">
                                  <attachments.contract.previewURL size={25} />
                                  <a
                                    href="#/"
                                    className="upload-close"
                                    onClick={() => {
                                      deleteAttachment(attachments, "contract");
                                    }}
                                  >
                                    <X
                                      size={10}
                                      weight="light"
                                      className="c-icons"
                                    />
                                  </a>
                                </div>
                              )}
                            </div>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                        </div>
                      </div>
                      {accessoriesList.map((accessory, index) => {
                        if (accessory.is_goverment == 1) {
                          return <React.Fragment></React.Fragment>;
                        }

                        let attachment =
                          attachments[
                            accessory.title.toLowerCase().replace(/\s/g, "_")
                          ];

                        return (
                          <div
                            key={index}
                            className={`d-flex justify-content-between align-items-center p-20 border-last-none border-bottom flex-wrap flex-xl-nowrap gap-3   ${
                              currentTab == index ? "z-index-21" : ""
                            }`}
                            onClick={() => {
                              setCurrentTab(index);
                            }}
                            // onMouseLeave={()=>{setCurrentTab(-1)}}
                          >
                            <div className="d-flex align-items-center flex-grow-1 w100minus100per">
                              <div className="h35w35 comman-round-box with-bg bg-transparent bg-white-05 me-20px">
                                <div
                                  className="comman-bg-img h-100 w-100 bg-style-cover"
                                  style={{
                                    backgroundImage: `url('${accessory.attachment_url ? accessory.attachment_url : attachment && attachment.previewURL ? attachment.previewURL : solarpanelsimg}')`,
                                  }}
                                >
                                  {/* <FileText
                                    size={34}
                                    weight="light"
                                    className="c-icons"
                                  /> */}
                                </div>
                              </div>
                              {/* {isAccessoryEditOngoing === index ? (
                                <textarea
                                  rows={1}
                                  placeholder={_l("l_accessories_title")}
                                  className="color-white form-control fw-semibold me-3 w100minus100per"
                                  type="text"
                                  value={accessory.title}
                                  onChange={(e) => {
                                    setAccessoriesList(
                                      accessoriesList.map((a, i) => {
                                        if (i === index) {
                                          a.title = e.target.value;
                                          a.name = e.target.value;
                                        }
                                        return a;
                                      })
                                    );
                                  }}
                                />
                              ) : (
                                <>
                                </>
                              )} */}
                              <div className="d-flex flex-column text-truncate w100minus100per">
                                <span className="c-font f-14 fw-semibold title-fonts text-truncate">
                                  {accessory.title}
                                </span>
                                <div className="c-font f-12 d-inline">
                                  <span className={`c-font f-12  ${descriViewMore == index ? 'text-wrap' : 'text-truncate'}`}>
                                  {accessory.description}
                                  </span>
                                  {accessory.description && accessory.description.length > 110 && (
                                    descriViewMore !== index ? (
                                      <a href="#/" className="fw-bolder ps10px" onClick={() => setDescriViewMore(index)}>
                                        Read More
                                      </a>
                                    ) : (
                                      <a href="#/" className="fw-bolder ps10px" onClick={() => setDescriViewMore(-1)}>
                                        Read Less
                                      </a>
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="comman_action_icon ms-auto d-flex gap-3">
                                {/* {isAccessoryEditOngoing === index ? (
                                  <a
                                    className="action_icon h55w55 with_bg position-relative shadow-none "
                                    onClick={() =>
                                      setIsAccessoryEditOngoing(-1)
                                    }
                                  >
                                    <Check
                                      size={18}
                                      weight="light"
                                      className="c-icons"
                                    />
                                  </a>
                                ) : (
                                )} */}
                                {/* {accessory.is_default > 0 
                                  ? <React.Fragment></React.Fragment> 
                                  :
                                <a
                                  className="action_icon h55w55 with_bg position-relative shadow-none  "
                                  onClick={() =>
                                    setIsAccessoryEditOngoing(index)
                                  }
                                >
                                  <PencilSimpleLine
                                    size={18}
                                    weight="light"
                                    className="c-icons"
                                  />
                                </a>} */}
                                {accessory.is_default > 0 ||
                                accessory.is_battery > 0 ? (
                                  <React.Fragment></React.Fragment>
                                ) : (
                                  <a
                                    className="action_icon h55w55 with_bg position-relative  shadow-none"
                                    onClick={() => {
                                      deleteProposalItem(accessory, index);
                                    }}
                                  >
                                    <Trash
                                      size={18}
                                      weight="light"
                                      className="c-icons"
                                    />
                                  </a>
                                )}
                              </div>
                            </div>
                            <div className="d-flex gap-3 h35px">
                              <Form.Control
                                placeholder={_l("l_onetime_price")}
                                className="fw-semibold color-white m-w100px"
                                type="number"
                                min={0}
                                value={accessory.one_time_price}
                                onChange={(e) =>
                                  setAccessoriesList(
                                    accessoriesList.map((a, i) => {
                                      if (i === index) {
                                        a.one_time_price = e.target.value;
                                        a.one_time_amount = e.target.value;
                                      }
                                      return a;
                                    })
                                  )
                                }
                              />

                              {isMonthlyPriceSelected ? (
                                <React.Fragment>
                                  <Form.Control
                                    placeholder={_l("l_amount_by_month")}
                                    className=" fw-semibold color-white m-w100px"
                                    type="number"
                                    min={0}
                                    value={accessory.monthly_price}
                                    onChange={(e) =>
                                      setAccessoriesList(
                                        accessoriesList.map((a, i) => {
                                          if (i === index) {
                                            a.monthly_price = e.target.value;
                                          }
                                          return a;
                                        })
                                      )
                                    }
                                  />
                                  <Form.Control
                                    placeholder={_l("l_number_of_months")}
                                    className=" fw-semibold color-white m-w100px"
                                    type="number"
                                    min={0}
                                    value={accessory.number_of_month}
                                    onChange={(e) =>
                                      setAccessoriesList(
                                        accessoriesList.map((a, i) => {
                                          if (i === index) {
                                            a.number_of_month = e.target.value;
                                          }
                                          return a;
                                        })
                                      )
                                    }
                                  />
                                </React.Fragment>
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                              <div className="d-flex align-items-center position-relative z-index-3 w-70px">
                                <Select
                                  // menuIsOpen={true}
                                  // placeholder={_l("l_payment_method")}
                                  className="custom-select-menu drop-down w70px f14 bg-white-05"
                                  classNamePrefix="react-select"
                                  options={VATselectionOption}
                                  onChange={(e) => {
                                    let updatedList = accessoriesList[index];
                                    updatedList.provider_tax = Number(e.value);
                                    updatedList.one_time_tax_amount =((Number(e.value) * updatedList.one_time_price) / 100).toFixed(2);
                                    setAccessoriesList(
                                      Object.values({
                                        ...accessoriesList,
                                        [index]: updatedList,
                                      })
                                    );
                                  }}
                                  value={VATselectionOption.find(
                                    (w) =>
                                      Number(w.value) ===
                                      Number(accessory.provider_tax)
                                  )}
                                />
                              </div>
                              <div className="comman_action_icon ms-auto d-flex">
                              <a
                                className="action_icon h55w55 justify-content-center p-15 position-relative shadow-none with_bg"
                                data-tip={"Ajouter des articles"}
                                data-effect="solid"
                                data-delay-show="1000"
                                data-class="tooltip-main"
                                onClick={() => {
                                  setAddCatalogItems(index) 
                                  setrel_type(
                                    index == 0 
                                      ? "electricity" 
                                      : index == 1 
                                        ? "panel" 
                                        : "custom")
                                }}
                              >
                                <SelectionPlus size={24} className="c-icons"/>
                              </a>
                            </div>
                              {/* {accessory.is_default > 0 ? <React.Fragment></React.Fragment> 
                              :
                              <div className={`comman_action_icon`}>
                                <div className="d-flex h-100">
                                  <a
                                    href="#/"
                                    className="action_icon min-width55 with_bg position-relative h-100 "
                                    data-tip={_l("l_choose_file")}
                                    data-effect="solid"
                                    data-delay-show="1000"
                                    data-class="tooltip-main"
                                    onInput={(e) => {
                                      handleUpdateAttachments(
                                        e,
                                        accessory.title
                                          .toLowerCase()
                                          .replace(/\s/g, "_")
                                      );
                                    }}
                                    //attachments.
                                  >
                                    <input
                                      type="file"
                                      className="absolute-input"
                                    />
                                    <Paperclip
                                      size={16}
                                      weight="light"
                                      className="c-icons"
                                    />
                                  </a>
                                </div>
                              </div>} */}
                               {/* {attachment && accessory.is_default == 0 ? (
                                <div className="upload-image-preview comman_action_icon ">
                                  {typeof attachment.previewURL === "string" ? (
                                    <div className="comman-image-box h55w55 radius_3 upload-image action_icon">
                                      <div
                                        className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                                        style={{
                                          backgroundImage: `url('${attachment.previewURL}')`,
                                        }}
                                      ></div>
                                      <a href="#/" className="upload-close" 
                                        onClick={() => {
                                          deleteAttachment(
                                            attachments,
                                            accessory.title
                                              .toLowerCase()
                                              .replace(/\s/g, "_")
                                          );
                                        }}>
                                        <X
                                          size={10}
                                          weight="light"
                                          className="c-icons"
                                        />
                                      </a>
                                    </div>
                                  ) : (
                                    <div className="align-items-center comman-image-box d-flex h32w32 justify-content-center radius_3 upload-image">
                                      <attachment.previewURL size={25} />
                                      <a
                                        href="#/"
                                        className="upload-close"
                                        onClick={() => {
                                          deleteAttachment(
                                            attachments,
                                            accessory.title
                                              .toLowerCase()
                                              .replace(/\s/g, "_")
                                          );
                                        }}
                                      >
                                        <X
                                          size={10}
                                          weight="light"
                                          className="c-icons"
                                        />
                                      </a>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}  */}
                            </div>
                            <ReactTooltip/>
                          </div>
                        );
                      })}
                      <div className=" d-flex justify-content-between align-items-center p-20 border-last-none border-bottom flex-wrap flex-xl-nowrap gap-3 gap-md-0 d-none">
                        <div className="d-flex align-items-center">
                          <div className="h35w35 comman-round-box with-bg bg-white-05 me-20px">
                            <div
                              className="comman-bg-img h-100 w-100 bg-style-cover"
                              style={{
                                backgroundImage: `url('${accomptecalender}')`,
                              }}
                            ></div>
                          </div>
                          <div className="c-font f-14 fw-semibold title-fonts d-none">
                            <span>{_l("l_solarHub_fees")}</span>
                          </div>
                        </div>
                        <div className="c-font f-16 fw-semibold title-fonts text-capitalize">
                          {calculateSolarhubFees()}
                        </div>
                      </div>
                    </div>
                    <a href="#/" className="align-items-center d-flex justify-content-center pb-20px pt-20px ohhoveropacity1 opacity-60"  
                    // onClick={() => addNewAccessoryItem()}
                    onClick={() => {
                      setAddCatalogItems(accessoriesList.length); 
                      setrel_type( "custom" );
                    }}
                    > 
                      <div className="btn  c-font f-14 fw-semibold  radius_5 text-capitalize text-center d-flex align-items-center gap10px">
                        <Plus size={16} weight="light" className="c-icons" />{" "}
                        {_l("l_add_a_new_item")}
                      </div>
                    </a>
                  </div>
                </div>
                <div className="">
                  <div className="align-items-center d-flex justify-content-between p-10 shadow-none ps-0 mt-10">
                    <div className="c-font color-white-60 light-theme-color-white-70 f-14 title-fonts">
                      {_l("l_simulation_audit_energetique")}
                    </div>
                  </div>
                  <div className="align-items-center border d-flex pt-20px pt-15 pb-15px ps-10px radius_5 bg-white-03 for-disabled">
                    <div className="with_separator_10 flex-grow-1 text-center ps-0 d-flex justify-content-center align-items-center ">
                      <div className="c-font f-24 fw-semibold title-fonts color-blue">
                          {benchmarkDetail.solar_items
                                ? ` ${(Math.floor(roundOffPowerValue(
                                  benchmarkDetail.solar_items.electricity_production)
                                ))} Kwc`
                                : 0}
                        {/* 00 Kwc */}
                      </div>
                      <div className="c-font color-white-60 f-14 pt-1 text-wrap ms-20px">
                        Production Electrique
                      </div>
                    </div>
                    <div className="with_separator_10 flex-grow-1 text-center d-flex justify-content-center align-items-center">
                      <div className="c-font f-24 fw-semibold title-fonts color-greenpill">
                        {/* {benchmarkDetail.solar_items.savings}€ */}
                        {monthalySaving || 0} €
                      </div>
                      <div className="c-font color-white-60 f-14 pt-1 text-wrap ms-20px">
                        <span>
                          {/* {_l("l_savings")}
                          {_l("l_on")} */}
                          Economies Mensuelles
                        </span>
                        <span className="fw-semibold fblue ps-1"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="align-items-center d-flex justify-content-between p-10 shadow-none ps-0 mt-10">
                    <div className="c-font color-white-60 light-theme-color-white-70 f-14 title-fonts">
                      {_l("l_terms_conditions")}
                    </div>
                  </div>
                  <div className="border radius10 bg-white-03 comman-white-box-shadow shadow-none">
                    <div className=" d-flex justify-content-between align-items-center p-20 border-last-none border-bottom flex-wrap flex-xl-nowrap gap-3">
                      <div className="d-flex align-items-center">
                        <div className="c-font f-14 fw-semibold title-fonts">
                          Garantie
                        </div>
                      </div>
                      <Form.Control
                        placeholder="25 années"
                        className=" fw-semibold color-white max-width-200px text-center h35px"
                        type="number"
                        value={guarantee}
                        onChange={(e) => setGuarantee(e.target.value)}
                      />
                    </div>
                    <div className=" d-flex justify-content-between align-items-center p-20 border-last-none border-bottom flex-wrap flex-xl-nowrap gap-3">
                      <div className="d-flex align-items-center">
                        <div className="c-font f-14 fw-semibold title-fonts">
                          Maintenance SAV
                        </div>
                      </div>
                      <Form.Control
                        placeholder="1 an"
                        className=" fw-semibold color-white max-width-200px text-center h35px"
                        type="number"
                        value={maintainance}
                        onChange={(e) => setMaintainance(e.target.value)}
                      />
                    </div>
                    <div className=" d-flex justify-content-between align-items-center p-20 border-last-none border-bottom flex-xl-nowrap gap-3">
                      <div className="d-flex align-items-center">
                        <div className="c-font f-14 fw-semibold title-fonts">
                          {_l("l_payment_method")}
                        </div>
                      </div>
                      <Select
                        placeholder={_l("l_payment_method")}
                        className="custom-select-menu w200px drop-up max-width-200px  f14 bg-white-05"
                        classNamePrefix="react-select"
                        options={paymentMethodOptions}
                        onChange={(e) => {
                          setOperatorPaymentMethod(e.value);
                        }}
                        value={paymentMethodOptions.find(
                          (w) => w.value === operatorPaymentMethod
                        )}
                      />
                    </div>
                    <div className="d-flex justify-content-between align-items-start p-20 border-last-none bg-white-05 flex-column flex-sm-row">
                      <div className="d-flex align-items-center flex-grow-1 flex-column">
                        <Form.Control
                          style={{ background: "transparent" }}
                          className="border-0 ps-0 py-0 shadow-none "
                          as="textarea"
                          placeholder={`${_l("l_type_description_here")}`}
                          // rows={3}
                          type="text"
                          value={termsAndConditionText}
                          onChange={(e) =>
                            setTermsAndConditionText(e.target.value)
                          }
                          defaultValue=""
                        ></Form.Control>
                      </div>
                      <div className="d-flex align-items-center border radius_5">
                        <a
                          onClick={saveOperatorTermsAndConditions}
                          href="#/"
                          className="btn btn-white-05 fw-semibold border-0 radius_5"
                        >
                          {_l("l_save_as_template")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  proposalCustomField && proposalCustomField.length > 0 ? 
                <div className="">
                  <div className="align-items-center d-flex justify-content-between p-10 shadow-none ps-0 mt-10">
                    <div className="c-font color-white-60 light-theme-color-white-70 f-14 title-fonts">
                      champs supplémentaires
                    </div>
                  </div>
                  <div className="align-items-center border d-flex p-10-15 radius_5 bg-white-03">
                    <div className="customFieldsWrapper flex-grow-1 row">
                      {proposalCustomField && proposalCustomField.map((item, i) => {
                        switch (item.type) {
                          case "url":
                            return (
                              <Form.Group className="-input-box pb-4 position-relative col-xl-12 comman_action_icon">
                                <Form.Label className={`input-label ${item.is_required == 1 ? '' : 'no-asterisk'}`}>
                                  {item.name}
                                </Form.Label>
                                <div className="align-items-center d-flex comman_action_icon">
                                <Form.Control
                                  placeholder={item.name}
                                  type="text"
                                  onChange={(e) => {
                                    setProposalCustomField(
                                      proposalCustomField.map((bc) => {
                                        if (bc.id == item.id) {
                                          bc.value = e.target.value;
                                        }
                                        return bc;
                                      })
                                    );
                                    setCustomFieldValues(
                                      customFieldValues.map((field) => {
                                        if (field.id == item.id) {
                                          field.value = e.target.value;
                                        }
                                        return field;
                                      })
                                    );
                                  }}
                                  value={customFieldValues && customFieldValues.find((field) => field.id == item.id) ? customFieldValues.find((field) => field.id == item.id).value : ''}
                                />
                                </div>
                              </Form.Group>
                            );
                          case "checkbox":
                            const checkOptions = item.default_value.split(",");
                            const  isCheck = customFieldValues &&  customFieldValues.find((field) => field.id == item.id) 
                                                ? customFieldValues.find((field) => field.id == item.id).value.split(",").filter(bl => bl != '')
                                                : [];
                            return (
                              <div className="d-flex">
                                <Form.Group className={`-input-box pb-4 position-relative col-xl-12 comman_action_icon`}>
                                  <Form.Label className={`input-label ${item.is_required == 1 ? '' : 'no-asterisk'}`}>
                                    {item.name}
                                  </Form.Label>
                                  <div className="align-items-center d-flex">
                                    <div className="d-flex form-control with-bg position-relative flex-grow-1" >
                                      {checkOptions && checkOptions.length > 0 &&
                                        checkOptions.map((che) => {
                                          return(
                                            <div className="form-check with-span me-5 py-0">
                                              <div className="d-flex align-items-center">
                                                <Form.Label className="form-check-label mt-2 mb-0 w-100">
                                                  <input
                                                    className="form-check-input h20w20 box-shadow-none"
                                                    type="checkbox"
                                                    checked={isCheck.includes(che)}
                                                    onChange={(e) =>{
                                                      setProposalCustomField(
                                                        proposalCustomField.map((bc) => {
                                                          if (bc.id == item.id) {
                                                            const setCheck = isCheck.includes(che) ? isCheck.filter(ch => ch != che) : [`${che}`].concat(isCheck);
                                                            bc.value = setCheck.join(',');
                                                          }
                                                          return bc;
                                                        })
                                                      )
                                                      setCustomFieldValues(
                                                        customFieldValues.map((field) => {
                                                          if (field.id == item.id) {
                                                            const setCheck = isCheck.includes(che) ? isCheck.filter(ch => ch != che) : [`${che}`].concat(isCheck);
                                                            field.value = setCheck.join(',');
                                                          }
                                                          return field;
                                                        })
                                                      )
                                                    }}
                                                  />
                                                  <span className="c-font f-12 ps-1">
                                                    {che}
                                                  </span>
                                                </Form.Label>
                                              </div>
                                            </div>
                                          )
                                        })
                                      }
                                    </div>
                                  </div>
                                </Form.Group>
                              </div>
                            );
                          case "file":
                          return (
                            <div className="d-flex">
                              <Form.Group className={`input-box pb-4 position-relative col-xl-12 comman_action_icon`}>
                                <Form.Label className={`input-label ${item.is_required == 1 ? '' : 'no-asterisk'}`}>
                                  {item.name}
                                </Form.Label>
                                <div className="align-items-center d-flex">
                                  <div className="form-control file-upload with-bg position-relative p-0 flex-grow-1 box-shadow-none">
                                    <div className="d-flex align-items-center justify-content-between p-15">
                                      <input
                                        className="z-index-3 hidden-input"
                                        type="file"
                                      />
                                      <div className="m-auto">
                                        <CloudArrowUp
                                          className="HW18"
                                        />
                                        <span className="color-white-60 ps-2">
                                          {_l("l_click_to_upload")}
                                        </span>
                                      </div>
                                    </div>
                                </div>
                                </div>
                              </Form.Group>
                            </div>
                          );
                          case "text":
                            case "input" :
                            return (
                              <div className="d-flex">
                                <Form.Group className={`-input-box pb-4 position-relative col-xl-12 comman_action_icon`}>
                                  <Form.Label className={`input-label ${item.is_required == 1 ? '' : 'no-asterisk'}`}>
                                    {item.name}
                                  </Form.Label>
                                  <div className="align-items-center d-flex">
                                  <Form.Control
                                    as="input"
                                    placeholder={item.name}
                                    type="text"
                                    name=""
                                    onChange={(e) => {
                                      setCustomFieldValues(
                                        customFieldValues.map((field) => {
                                          if (field.id == item.id) {
                                            field.value = e.target.value;
                                          }
                                          return field;
                                        })
                                      );
                                      setProposalCustomField(
                                        proposalCustomField.map((bc) => {
                                          if (bc.id == item.id) {
                                            bc.value = e.target.value;
                                          }
                                          return bc;
                                        })
                                      );
                                    }}
                                    value={customFieldValues && customFieldValues.find((field) => field.id == item.id) ? customFieldValues.find((field) => field.id == item.id).value : ''}
                                  ></Form.Control>
                                  </div>
                                </Form.Group>
                              </div>
                            );
                        case "email":
                          return (
                            <div className="d-flex">
                              <Form.Group className={`-input-box pb-4 position-relative col-xl-12 comman_action_icon`}>
                                <Form.Label className={`input-label ${item.is_required == 1 ? '' : 'no-asterisk'}`}>
                                  {item.name}
                                </Form.Label>
                                <div className="align-items-center d-flex">
                                <Form.Control
                                  as="input"
                                  placeholder={item.name}
                                  type="email"
                                  name=""
                                  onChange={(e) => {
                                    setCustomFieldValues(
                                      customFieldValues.map((field) => {
                                        if (field.id == item.id) {
                                          field.value = e.target.value;
                                        }
                                        return field;
                                      })
                                    );
                                    setProposalCustomField(
                                      proposalCustomField.map((bc) => {
                                        if (bc.id == item.id) {
                                          bc.value = e.target.value;
                                        }
                                        return bc;
                                      })
                                    );
                                  }}
                                  value={customFieldValues && customFieldValues.find((field) => field.id == item.id) ? customFieldValues.find((field) => field.id == item.id).value : ''}
                                ></Form.Control>
                                </div>
                              </Form.Group>
                            </div>
                          );
                          case "number":
                            return (
                              <Form.Group className={`-input-box pb-4 position-relative col-xl-12 comman_action_icon`}>
                                <Form.Label className={`input-label ${item.is_required == 1 ? '' : 'no-asterisk'}`}>
                                  {item.name}
                                </Form.Label>
                                <div className="align-items-center d-flex">
                                <Form.Control
                                  as="input"
                                  placeholder={item.name}
                                  type="number"
                                  name=""
                                  onChange={(e) => {
                                    setCustomFieldValues(
                                      customFieldValues.map((field) => {
                                        if (field.id == item.id) {
                                          field.value = e.target.value;
                                        }
                                        return field;
                                      })
                                    );
                                    setProposalCustomField(
                                      proposalCustomField.map((bc) => {
                                        if (bc.id == item.id) {
                                          bc.value = e.target.value;
                                        }
                                        return bc;
                                      })
                                    );
                                  }}
                                  value={customFieldValues && customFieldValues.find((field) => field.id == item.id) ? customFieldValues.find((field) => field.id == item.id).value : ''}
                                ></Form.Control>
                                </div>
                              </Form.Group>
                            );
                          case "textarea":
                            return (
                              <Form.Group className={`c-input-box pb-4 position-relative col-xl-12 comman_action_icon`}>
                                <Form.Label className={`input-label ${item.is_required == 1 ? '' : 'no-asterisk'}`}>
                                  {item.name}
                                </Form.Label>
                                <div className="align-items-center d-flex">
                                <Form.Control
                                  as="textarea"
                                  rows={5}
                                  placeholder={item.name}
                                  type="text"
                                  name=""
                                  onChange={(e) => {
                                    setCustomFieldValues(
                                      customFieldValues.map((field) => {
                                        if (field.id == item.id) {
                                          field.value = e.target.value;
                                        }
                                        return field;
                                      })
                                    );
                                    setProposalCustomField(
                                      proposalCustomField.map((bc) => {
                                        if (bc.id == item.id) {
                                          bc.value = e.target.value;
                                        }
                                        return bc;
                                      })
                                    );
                                  }}
                                  value={customFieldValues && customFieldValues.find((field) => field.id == item.id) ? customFieldValues.find((field) => field.id == item.id).value : ''}
                                ></Form.Control>
                                </div>
                              </Form.Group>
                            );
                          case "select":
                            const singleSelectMenuOptions = item.default_value
                            .split(",")
                            .map((option) => {
                              return { label: option, value: option };
                            });

                            const singleSelectcustomFieldValues = customFieldValues && customFieldValues.find((field) => field.id == item.id) 
                                                        ? [customFieldValues.find((field) => field.id == item.id).value]
                                                        : [{value : ''}]

                            const singleSelectValue = singleSelectMenuOptions.filter(
                              (w) =>
                              singleSelectcustomFieldValues.find(
                                  (z) => z == w.value
                                )
                            );
                            return (
                              <Form.Group className="-input-box pb-4 position-relative col-xl-12 comman_action_icon">
                                <Form.Label className={`input-label ${item.is_required == 1 ? '' : 'no-asterisk'}`}>{item.name}</Form.Label>
                                <div className="c_input_border drop_image col-12 col-md-12  col-xl-12 position-relative gap-2 align-items-center d-flex">
                                  <Select
                                    className="custom-select-menu bg-white-03 onboarding-map-input scroll-height-30vh flex-grow-1 css-b62m3t-container"
                                    classNamePrefix="react-select"
                                    placeholder={_l("l_select")}
                                    options={singleSelectMenuOptions}
                                    value={singleSelectValue}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 4 }) }}
                                    menuPortalTarget={document.body} 
                                    onChange={(e) => {
                                      setCustomFieldValues(
                                        customFieldValues.map((field) => {
                                          if (field.id == item.id) {
                                            field.value = e.value;
                                          }
                                          return field;
                                        })
                                      );
                                      setProposalCustomField(
                                        proposalCustomField.map((bca) => {
                                          if (bca.id == item.id) {
                                            bca.value = e.value;
                                          }
                                          return bca;
                                        })
                                      );
                                    }}
                                  />
                                </div>
                              </Form.Group>
                            );
                          case "multi_select":
                            const multipleSelectMenuOptions = item.default_value
                              .split(",")
                              .map((option) => {
                                return { label: option, value: option };
                              });

                              let formattedMultiSelectValue = customFieldValues.find((field) => field.id == item.id).value 
                                                                ? customFieldValues.find((field) => field.id == item.id).value.split(",") 
                                                                : [];

                              const multiSelectValue = multipleSelectMenuOptions.filter(
                                (w) =>
                                  formattedMultiSelectValue.find(
                                    (z) => z == w.value
                                  )
                              );
                            
                            return (
                              <Form.Group className="-input-box pb-4 position-relative col-xl-12 comman_action_icon">
                                <Form.Label className={`input-label ${item.is_required == 1 ? '' : 'no-asterisk'}`}>{item.name}</Form.Label>
                                <div className="c_input_border drop_image col-12 col-md-12  col-xl-12 position-relative gap-2 align-items-center d-flex">
                                  <Select
                                    className="custom-select-menu bg-white-03 onboarding-map-input scroll-height-30vh flex-grow-1"
                                    classNamePrefix="react-select"
                                    placeholder={_l("l_select")}
                                    isMulti
                                    value={multiSelectValue}
                                    options={multipleSelectMenuOptions}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 4 }) }}
                                    menuPortalTarget={document.body}
                                    onChange={(e) => {
                                      setCustomFieldValues(
                                        customFieldValues.map((field) => {
                                          if (field.id == item.id) {
                                            field.value = e.map((w) => w.value).join(",");
                                          }
                                          return field;
                                        })
                                      );
                                      setProposalCustomField(
                                        proposalCustomField.map((bce) => {
                                          if (bce.id == item.id) {
                                            bce.value = e.map((w) => w.value).join(",");
                                          }
                                          return bce;
                                        })
                                      );
                                    }}
                                  />
                                </div>
                              </Form.Group>
                            );
                          case "date":
                            return (
                              <>
                                {" "}
                                <Form.Group className="-input-box pb-4 position-relative col-xl-12 comman_action_icon">
                                  <Form.Label className={`input-label ${item.is_required == 1 ? '' : 'no-asterisk'}`}>
                                    {item.name}
                                  </Form.Label>
                                  <div className="align-items-center custom-datepicker d-flex">
                                    <div className="d-flex align-items-center form-control color-white-60 dropdown-center dropdown w-100 react-datepicker-wrapper-w-100 comman_action_icon h_53px gap-2 justify-content-between">
                                      <div className="action_icon h32w32 with_bg">
                                        <CalendarBlank
                                          className="c-icons opacity-60"
                                        />
                                      </div>
                                      <DatePicker
                                        className="ps-1 w-100 fw-normal"
                                        disabledKeyboardNavigation
                                        dateFormat="dd/MM/yyyy"
                                        selectsStart
                                        defaultMenuIsOpen
                                        placeholderText={_l("l_please_select_date")}
                                        calendarStartDay={1}
                                        portalId="custom_datepicker"
                                        calendarClassName="custom-datepicker"
                                        onChange={(date) => {
                                          setCustomFieldValues(
                                            customFieldValues.map((field) => {
                                              if (field.id == item.id) {
                                                field.value =
                                                  moment(date).format("YYYY-MM-DD");
                                              }
                                              return field;
                                            })
                                          );
                                          setProposalCustomField(
                                            proposalCustomField.map((bc) => {
                                              if (bc.id == item.id) {
                                                bc.value =
                                                  moment(date).format("YYYY-MM-DD");
                                              }
                                              return bc;
                                            })
                                          );
                                        }}
                                        selected={
                                          customFieldValues && customFieldValues.find((field) => field.id == item.id) && customFieldValues.find((field) => field.id == item.id).value 
                                            ? new Date(customFieldValues.find((field) => field.id == item.id).value)
                                            : ""
                                        }
                                      >
                                        <div className="datepicker-label">{_l("l_select_date_and_time")}</div>
                                      </DatePicker>
                                    </div>
                                  </div>
                                </Form.Group>{" "}
                              </>
                            );
                          default:
                            return <div></div>;
                        }
                        })}
                    </div> 
                  </div>
                </div>
                : <></>
                }
              </div>
              <div className="col-xl-4 pe-0 h-100 overflow-hiiden-auto">
                <div className="">
                  <div className="align-items-center d-flex justify-content-between p-10 shadow-none ps-0">
                    <div className="c-font color-white-60 light-theme-color-white-70 f-14 title-fonts text-capitalize ">
                      {_l("l_proposition_commerciale")}
                    </div>
                  </div>
                  <div className="border radius10 bg-white-03">
                    <div className=" d-flex flex-column">    {/* p-20 class remove/*}
                      {/* <div className="align-items-center btn-white-05 d-flex p-1 mb-20 radius_5 shadow-none"> */}
                        {/* <label
                          className={`pyment_btn row-btn-label w-100 flex-grow-1 border-bottom`}
                          for="flexRadioDefault1"
                        >
                          <input
                            type="radio"
                            className="hidden-input"
                            name="payment_mode"
                            value={"onetime"}
                            onChange={(e) =>
                              setViewingOneTimePrice(
                                e.target.value === "onetime"
                              )
                            }
                            checked={viewingOneTimePrice}
                          />
                          {/* <div className="row-btn-box c-font f-12 p-2 fw-semibold title-fonts cblue text-uppercase"> 
                          <div className="c-font f-14 p-2 bg-white-05 fw-semibold title-fonts cblue text-uppercase">
                            {_l("l_pay_once")}
                          </div>
                        </label>
                        <label
                          className={`pyment_btn row-btn-label w-50 flex-grow-1 d-none`}
                          for="flexRadioDefault2"
                        >
                          <input
                            type="radio"
                            className="hidden-input"
                            name="payment_mode"
                            value={"monthly"}
                            onChange={(e) =>
                              setViewingOneTimePrice(
                                e.target.value === "onetime"
                              )
                            }
                            checked={!viewingOneTimePrice}
                            disabled={!isMonthlyPriceSelected}
                          />
                          <div className="row-btn-box c-font f-12 p-2 fw-semibold title-fonts cblue text-uppercase">
                            {`${_l("l_payment")} ${_l("l_monthly")}`}
                          </div>
                        </label> */}
                      {/* </div> */}
                      <div className="p-20">
                        <div>
                          <div className="c-font f-15 fw-semibold title-fonts pb-10px color-white-60">
                            {_l("l_details")}
                          </div>
                          <div className="c-font f-15 pb-10px color-white-60 d-flex justify-content-between align-items-center d-none">
                            <div>
                              {" "}
                              {_l("l_solar_installation_title")}{" "}
                              {numberOfPlates ||
                                benchmarkDetail.solar_items.no_of_plates}{" "}
                              KW
                            </div>
                            <div>
                              €{" "}
                              {viewingOneTimePrice
                                ? Number(proposalAmount)
                                : Number(monthlyAmount) * Number(numberOfMonths)}
                            </div>
                          </div>
                         
                          <div className="c-font f-14 pb-10px color-white-60 d-flex justify-content-between align-items-center">
                            <div> {_l("l_total_without_vat")} </div>
                            <div>€ {accessoriesList.reduce((accumulator, currentItem) => {
                              return accumulator + Number(currentItem.one_time_amount);
                              }, 0).toFixed(2)}
                            </div>
                          </div>
                          <div className="c-font f-14 pb-10px color-white-60 d-flex justify-content-between align-items-center">
                            <div> {_l("l_vat")} </div>
                            <div>€ {accessoriesList.reduce((accumulator, currentItem) => {
                              return accumulator + Number(currentItem.one_time_tax_amount);
                              }, 0).toFixed(2)}</div>
                          </div>
                          <div className="c-font f-15 pb-10px color-white-60 d-flex justify-content-between align-items-center d-none">
                            <div> {_l("l_solarHub_fees")} </div>
                            <div>€ {calculateSolarhubFees()}</div>
                          </div>
                          {/* <div className="c-font f-15 pb-10px color-white-60 d-flex justify-content-between align-items-center">
                            <div>
                              {" "}
                              {financialAid.title || _l("l_installation_and_labour")}{" "}
                            </div>
                            <div>
                              - €{" "}
                              {!isNaN(Number(financialAid.one_time_amount))
                                ? Number(financialAid.one_time_amount)
                                : 0}
                            </div>
                          </div> */}
                          {/* <div className="c-font f-15 fw-semibold pb-10px d-flex justify-content-between align-items-center color-white">
                            <div>{_l("l_total")}</div>
                            <div>€ {getTotalPayableAmount()}</div>
                          </div> */}
                          <div className="c-font f-14 pb-10px d-flex justify-content-between align-items-center color-white fw-bold ">
                            <div>{_l("l_total_with_vat")}</div> 
                            <div>
                              €{" "}
                              {accessoriesList.reduce((accumulator, currentItem) => {
                                return accumulator + Number(currentItem.one_time_amount) + Number(currentItem.one_time_tax_amount);
                              }, 0).toFixed(2)}
                            </div>
                          </div>
                        </div>
                        <div className="pt-10px pb-20px">
                          <div className="border-bottom-dashed"></div>
                        </div>
                        <div>
                          <div>
                            <div class="c-font f-15 fw-semibold title-fonts pb-10px color-white-60">
                              Conditions
                            </div>
                            <div class="c-font f-15 pb-10px color-white-60 d-flex justify-content-between align-items-center">
                              <div>
                                {" "}
                                {_l("l_25_year_warranty")}{" "}
                                {guarantee ? `${guarantee} ans` : ""}{" "}
                              </div>
                              <div>
                                {guarantee
                                  ? _l("l_included")
                                  : _l("l_not_included")}{" "}
                              </div>
                            </div>
                            <div class="c-font f-15 pb-10px color-white-60 d-flex justify-content-between align-items-center">
                              <div>
                                {" "}
                                {_l("l_maintenance")}{" "}
                                {maintainance
                                  ? `& SAV ${maintainance} ans`
                                  : "& SAV"}{" "}
                              </div>
                              <div>
                                {maintainance
                                  ? _l("l_included")
                                  : _l("l_not_included")}{" "}
                              </div>
                            </div>
                          </div>
                          <div className="pt-10px pb-20px">
                            <div className="border-bottom-dashed"></div>
                          </div>
                        </div>
                        {/* <div className="c-font f-15 fw-semibold d-flex justify-content-between align-items-center color-white text-capitalize">
                          <div>{_l("l_total_VAT_fees_pay_now")}</div>
                          <div className="color-green text-nowrap">
                            € {getTotalPayableVATfees()}
                          </div>
                        </div>
                        <div className="c-font f-15 fw-semibold d-flex justify-content-between align-items-center color-white text-capitalize">
                          <div>{_l("l_total_to_pay_now")}</div>
                          <div className="color-green">
                            €{" "}
                            {Number(getTotalPayableAmount()) +
                              Number(getTotalPayableVATfees())}
                          </div>
                        </div> */}
                        <div className="c-font f-15 pb-10px color-white-60 d-flex justify-content-between align-items-center c-input-box">
                          <div>premier ca</div>
                          
                          <div>-1444</div>
                          <Form.Control
                                placeholder={10}
                                className="color-white form-control fw-semibold h32wauto pb-0 pt-0 res-width-100 title-fonts w-25 d-none"
                                type="number"
                              />
                        </div>
                        <div className="c-font f-15 pb-10px color-white-60 d-flex justify-content-between align-items-center c-input-box">
                          <div>autres</div>
                          <div>-1000</div>
                          <Form.Control
                                placeholder={10}
                                className="color-white form-control fw-semibold h32wauto pb-0 pt-0 res-width-100 title-fonts w-25 d-none"
                                type="number"
                              />
                        </div>
                        <div className="c-font f-15 fw-semibold d-flex justify-content-between align-items-center color-white text-capitalize">
                          <div>{_l("l_total_to_pay_now")}</div>
                          <div className=" color-greenpill c-font f-22 title-fonts">
                            {/* € {Number(calculateSolarhubFees())+ Number(getTotalPayableVATfees())} */}
                            € {calculateSolarhubFees()}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-20 bg-white-05">
                      <div class="d-flex form-check">
                        <div class="pe-2">
                          <input
                            className="form-check-input light-theme-border light-theme-white-bg"
                            type="checkbox"
                            value=""
                            checked
                            disabled
                          />
                        </div>
                        <label class="form-check-label" for="flexCheckDefault2">
                          {_l("l_terms_solar")}
                          <a href="#/" class="color-green ms-1 fw-semibold">
                            Blaz.com{" "}
                          </a>{" "}
                          {_l("l_terms_cgs_solar")}.
                        </label>
                      </div>
                      <div className="pt-20px pb-20px">
                        <div className="border-bottom"></div>
                      </div>
                      <div className="gap-3 d-flex flex-wrap flex-column">
                        {proposal && proposal.status == 4 ? (
                          <Button
                            variant={"primary"}
                            className="flex-grow-1 add_rate yellowbg"
                            disabled={showLoader}
                            onClick={() => updateAmount()}
                          >
                            <span className="ms-10px">
                              {showLoader ? (
                                <span>{_l("l_please_wait")}</span>
                              ) : (
                                <>
                                  <Check
                                    size={18}
                                    weight="light"
                                    className="c-icons"
                                  />
                                  <span className="ms-10px">
                                    {proposal.amount > 0
                                      ? _l("l_update_amount")
                                      : _l("l_send_to_customer")}
                                  </span>
                                </>
                              )}
                              {showLoader ? <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                className={`ms-2 `}
                                aria-hidden="true"
                              /> : <></>}
                            </span>
                          </Button>
                        ) : (
                          <></>
                        )}
                        <Button
                          variant={"white-05"}
                          className="flex-grow-1 add_rate"
                          onClick={() => updateAmount(1)}
                        >
                          {isGeneratingPreview ? (
                            <span>{_l("l_please_wait")}{isGeneratingPreview ? <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              className={`ms-2 `}
                              aria-hidden="true"
                            /> : <></>}</span>
                          ) : (
                            <React.Fragment>
                              <FileText
                                size={18}
                                weight="light"
                                className="c-icons"
                              />
                              <span className="ms-10px">
                                {_l("l_preview_as_pdf")}
                              </span>
                            </React.Fragment>
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-2"></div> */}
      </div>
      {openLightBox && lightBoxData.length ? (
        <SolarDocumentPreview
          open={openLightBox}
          setOpen={setOpenLightBox}
          slides={lightBoxData}
        />
      ) : (
        <></>
      )}
      {isAccessoryEditOngoing > -1 ? (
        <EditProposalItem
          fields={accessoriesList}
          index={isAccessoryEditOngoing}
          handleClose={() => {
            setIsAccessoryEditOngoing(-1);
            setAccessoriesList(accessoriesList.filter(item => item.title))
          }}
          setFields={(field, value, pos) => {
            setAccessoriesList(
              accessoriesList.map((acc, i) => {
                if (i == pos) {
                  if(field === "title")
                  {
                    acc["name"] = value;
                  }
                  acc[field] = value;
                }
                return acc;
              })
            );
          }}
        />
      ) : (
        <></>
      )}
      {addCatalogItems > -1 ? (
        <ChooseCatalogItem
          handleClose={() => {
            setAddCatalogItems(-1);
            setrel_type('');
          }}
          addCatalogItems = {addCatalogItems}
          rel_type = {rel_type}
          setPricingTemplates= {setAccessoriesList}
          pricingTemplates = {accessoriesList}
          isFrom = {"proposals"}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProviderProposalDetail;
