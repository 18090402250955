import React, { useEffect, useState } from "react";
import { _l, isSolarHubPlatform } from "../../hooks/utilities";
import Loader from "../SolarExternalPages/Loader";
const CommanLoader = (props) => {

  const LoaderClass 	= (props.className) ? props.className : "";

  if(isSolarHubPlatform())
  {
    return <Loader className={LoaderClass && LoaderClass.length && LoaderClass.includes("dropdown") ? "small" : ""} />
  }

  return (
    <>
      <div className={`main-loader-wrapper full-screen with-opacity up-left-panel ${LoaderClass}`}>
          <div className="w-100 h-100 d-flex">
            <div className="loader-video">
              {/* <img src={LoaderVideo} alt={LoaderVideo} /> */}
            {/* <video  autostart="true" autoPlay="true" loop="true" src={LoaderVideo} type={LoaderVideo}/> */}
              <div className="loader-text">{props.message ? _l(props.message) : _l("l_please_wait")}</div>
            </div>
          </div>
      </div>
    </>
  );
};

export default CommanLoader;