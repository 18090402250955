import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import {
  updateActionCounts,
  updateScheduleSlotKPICounts,
  _l,
  getDateRanges,
} from "../../../hooks/utilities";
import "react-datepicker/dist/react-datepicker.css";
import ScheduleActionChecklist from "../../Modals/ScheduleActionChecklist";
import ScheduleAssignmentOperator from "./ScheduleAssignmentOperator";
import ScheduleAssignmentOperatorSlot from "./ScheduleAssignmentOperatorSlot";
import CustomerServices from "../../../services/customer-services";
import { useDispatch, useSelector } from "react-redux";
import useScheduleSteps from "../../../hooks/useScheduleSteps";
import { showMessage } from "../../../actions/messages";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import ChecklistCountHeader from "./ChecklistCountHeader";
import AddEmployee from "../../Modals/AddEmployee";
import {Medal} from "phosphor-react";

const CreateScheduleNew = ({
  handleStep,
  handleClose,
  readOnlyMode,
  selectedTab,
  hideFooter,
  step = "",
}) => {
  const operator_availability = {
    action_count: 0,
    assignee_id: 0,
    assignee_name: "",
    assignee_image: "",
    assigned_items: [],
    available_from: "",
    available_items: [],
    comment: "",
    task_title: "",
    days: {
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
      monthly: false,
      quarterly: false,
      yearly: false,
      half_yearly: false,
    },
    assigned_days: {
      mon: -1,
      tue: -1,
      wed: -1,
      thu: -1,
      fri: -1,
      sat: -1,
      sun: -1,
      monthly: -1,
      quarterly: -1,
      yearly: -1,
      half_yearly: -1,
    },
    time_slot: {
      mon: "",
      tue: "",
      wed: "",
      thu: "",
      fri: "",
      sat: "",
      sun: "",
      monthly: "",
      quarterly: "",
      yearly: "",
      half_yearly: "",
    },
    frequency: [],
    selected_count: 0,
    id: 0,
  };

  const [scheduleActionChecklistModal, setScheduleActionChecklistModal] =
    useState(false);
  const [selectedEmployeeData, setSelectedEmployeeData] = useState("");
  const [employeeData, setEmployeeData] = useState([]);
  const [showCreateOperatorModal, setShowCreateOperatorModal] = useState(false);
  const dispatch = useDispatch();

  const { benchmarkSchedule, benchmarkDetail, selectedProject } = useSelector(
    (state) => state.customer
  );
  const { createScheduleDetail } = useSelector(
    (state) => state.benchmarkReducer
  );

  const {
    operatorAvailability,
    setOperatorAvailability,
    currentSlot,
    setCurrentSlot,
    setCalculationsCounts,
    assignedChecklistItems,
    setAssignedChecklistItems,
    calculationsCounts,
  } = useScheduleSteps();
  const remainingAssignmentItems =
    calculationsCounts && calculationsCounts.headerCounts
      ? Object.values(calculationsCounts.headerCounts).reduce((a, b) => a + b)
      : 0;

  const selectOperatorForSlot = (assignee, slot) => {
    setOperatorAvailability(
      Object.values({
        ...operatorAvailability,
        [slot]: {
          ...operatorAvailability[slot],
          assignee_id: assignee.value,
          assignee_name: assignee.name,
          assignee_image: assignee.url,
          is_skilled: assignee.is_skilled
        },
      })
    );
  };

  const selectOperatorTimeSlot = (time, slot) => {
    const time_slot = {
      mon: time,
      tue: time,
      wed: time,
      thu: time,
      fri: time,
      sat: time,
      sun: time,
      monthly: time,
      quarterly: time,
      half_yearly: time,
      yearly: time,
    };
    setOperatorAvailability(
      Object.values({
        ...operatorAvailability,
        [slot]: {
          ...operatorAvailability[slot],
          available_from: time,
          time_slot,
        },
      })
    );
  };

  const updateAssignedChecklists = (
    assignedItems,
    masterList,
    days,
    slotPosition
  ) => {
    masterList.forEach((item) => {
      const position = assignedItems.findIndex((w) => w.id == item.id);
      if (
        position !== -1 &&
        item.assigned_days &&
        (Object.values(item.assigned_days).findIndex(
          (w) => w == slotPosition
        ) != -1 ||
          operatorAvailability.length == 1)
      ) {
        Object.keys(days).forEach((day_name) => {
          if (
            days[day_name] &&
            item.days[day_name] &&
            (item.assigned_days[day_name] == undefined ||
              item.assigned_days[day_name] == -1)
          ) {
            item.assigned_days[day_name] = slotPosition;
            if (assignedItems[position].assigned_days) {
              assignedItems[position].assigned_days[day_name] = slotPosition;
            } else {
              assignedItems[position]["assigned_days"] = {};
              assignedItems[position].assigned_days[day_name] = slotPosition;
            }
          } else if (
            !days[day_name] &&
            item.assigned_days[day_name] == slotPosition
          ) {
            item.assigned_days[day_name] = -1;
            if (assignedItems[position].assigned_days) {
              assignedItems[position].assigned_days[day_name] = -1;
            } else {
              assignedItems[position]["assigned_days"] = {};
              assignedItems[position].assigned_days[day_name] = -1;
            }
          }
        });
      }
    });
    setAssignedChecklistItems(masterList);
    setCalculationsCounts(updateScheduleSlotKPICounts(masterList));
  };

  const selectOperatorDaySlot = (days, slot) => {
    let allOperatorSlots = operatorAvailability;
    let masterList = assignedChecklistItems.slice();

    allOperatorSlots[slot].days = days;
    Object.keys(allOperatorSlots[slot].days).forEach((day_name) => {
      if (!allOperatorSlots[slot].time_slot) {
        allOperatorSlots[slot].time_slot = {};
      }
      if (
        ["monthly", "quarterly", "yearly", "half_yearly"].includes(day_name)
      ) {
        let dateRange = { minDate: "", maxDate: "" };

        if (
          benchmarkDetail.startdate &&
          benchmarkSchedule &&
          benchmarkSchedule.total_count &&
          allOperatorSlots[slot].days[day_name]
        ) {
          let blockedDays = [];
          Object.keys(benchmarkSchedule.total_count).forEach((day_name) => {
            if (benchmarkSchedule.total_count[day_name] == 0) {
              switch (day_name) {
                case "sun":
                  blockedDays.push(0);
                  break;
                case "mon":
                  blockedDays.push(1);
                  break;
                case "tue":
                  blockedDays.push(2);
                  break;
                case "wed":
                  blockedDays.push(3);
                  break;
                case "thu":
                  blockedDays.push(4);
                  break;
                case "fri":
                  blockedDays.push(5);
                  break;
                case "sat":
                  blockedDays.push(6);
                  break;
                default:
                  break;
              }
            }
          });

          if (blockedDays.length == 7) {
            blockedDays = ["0", "6"];
          }

          switch (day_name) {
            case "monthly":
              dateRange = getDateRanges(moment(benchmarkDetail.startdate), 30);
              break;
            case "quarterly":
              dateRange = getDateRanges(moment(benchmarkDetail.startdate), 90);
              break;
            case "half_yearly":
              dateRange = getDateRanges(moment(benchmarkDetail.startdate), 180);
              break;
            case "yearly":
              dateRange = getDateRanges(moment(benchmarkDetail.startdate), 365);
              break;
            default:
              break;
          }

          let dayNumber = new Date(dateRange.maxDate).getDay();
          let dateValue = new Date(dateRange.maxDate).getDate();

          if (blockedDays.includes(dayNumber)) {
            let maxRangeDate = new Date(dateRange.maxDate);
            let validDays = [];
            for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
              if (!blockedDays.includes(dayOfWeek)) {
                validDays.push(dayOfWeek);
              }
            }
            validDays.forEach((day, index) => {
              validDays[index] =
                dayNumber - day < 0 ? dayNumber - day + 7 : dayNumber - day;
            });
            let offset = validDays.length ? Math.min(...validDays) : 0;
            maxRangeDate.setDate(dateValue - Math.abs(offset));
            allOperatorSlots[slot].time_slot[day_name] = maxRangeDate;
          } else {
            allOperatorSlots[slot].time_slot[day_name] = new Date(
              dateRange.maxDate
            );
          }
        } else {
          allOperatorSlots[slot].time_slot[day_name] = "";
        }
      }
    });
    let assigned_items = operatorAvailability[slot].assigned_items;
    if (assigned_items && assigned_items.length) {
      updateAssignedChecklists(assigned_items, masterList, days, slot);
    }
    allOperatorSlots[slot].assigned_items = assigned_items;
    updateActionCounts(allOperatorSlots, masterList);
    setOperatorAvailability(
      Object.values({
        ...allOperatorSlots,
        [slot]: { ...allOperatorSlots[slot], days },
      })
    );
  };

  const addNewOperatorSlot = () => {
    if (remainingAssignmentItems == 0) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_no_remaining_items"))
      );
      return;
    }
    let emptySlot = false;
    operatorAvailability.forEach((operator) => {
      if (
        !operator.frequency.length &&
        !Object.values(operator.days).filter((x) => x == true).length
      ) {
        emptySlot = true;
      }
    });
    if (!emptySlot) {
      setOperatorAvailability(
        operatorAvailability.concat(operator_availability)
      );
    } else {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_select_timeslot_to_add_the_checklist_item")
        )
      );
    }
  };

  const removeOperatorSlot = (index) => {
    if (operatorAvailability.length > 1) {
      let allItems = assignedChecklistItems;
      let operatorSlots = operatorAvailability.slice();
      allItems.forEach((item) => {
        Object.keys(operatorAvailability[index].days).forEach((day_name) => {
          if (
            operatorAvailability[index].days[day_name] &&
            item.assigned_days &&
            item.assigned_days[day_name] == index
          ) {
            item.assigned_days = { ...item.assigned_days, [day_name]: -1 };
          }
        });
        if (item.assigned_days) {
          Object.keys(item.assigned_days).forEach((day) => {
            if (
              item.assigned_days[day] != undefined &&
              item.assigned_days[day] > index
            ) {
              item.assigned_days = {
                ...item.assigned_days,
                [day]: item.assigned_days[day] - 1,
              };
            }
          });
        }
      });
      setAssignedChecklistItems(allItems);
      setCalculationsCounts(updateScheduleSlotKPICounts(allItems));
      operatorSlots = operatorAvailability.filter((x, pos) => pos != index);
      updateActionCounts(operatorSlots, allItems);
      setOperatorAvailability(operatorSlots);
    }
  };

  const openScheduleActiondetailModal = (slot) => {
    if (
      !isNaN(operatorAvailability[slot].assignee_id) &&
      Number(operatorAvailability[slot].assignee_id)
    ) {
      setCurrentSlot(slot);
      setScheduleActionChecklistModal(true);
    } else {
      dispatch(
        showMessage(
          "unsuccess",
          _l("l_error"),
          `${_l("l_please_select_assignees")} ${slot + 1}`
        )
      );
    }
  };

  const calculateActionsCount = (allChecklistItems, slotPosition, days) => {
    let checkedDays = [];
    let totalSlots = 0;
    let assignedToOthers = 0;

    if (days && Object.keys(days).length) {
      Object.keys(days).forEach((day_name) => {
        if (days[day_name]) {
          checkedDays.push(day_name);
        }
      });
    }

    allChecklistItems.forEach((checklist) => {
      if (checklist.days) {
        Object.keys(checklist.days).forEach((day_name) => {
          if (checklist.days[day_name] && checkedDays.includes(day_name)) {
            totalSlots += 1;
          }
        });
      }
      if (checklist.assigned_days) {
        Object.keys(checklist.assigned_days).forEach((day_name) => {
          if (
            checklist.assigned_days[day_name] != -1 &&
            checklist.assigned_days[day_name] != undefined &&
            checklist.assigned_days[day_name] != slotPosition &&
            checkedDays.includes(day_name)
          ) {
            assignedToOthers += 1;
          }
        });
      }
    });
    return Math.abs(totalSlots - assignedToOthers);
  };

  const updateOperatorList = (data) => {
    const newOperator = {
      value: data.id,
      label: (
        <div className="d-flex align-items-center">
          <div className="c-list-icon">
            <div className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-5px">
              <div
                data-tip={data.full_name}
                data-effect="solid"
                data-delay-show='1000'
                data-class="tooltip-main"
                className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                style={{
                  backgroundImage: `url('${data.profile_image}')`,
                }}
              ></div>
            </div>
          </div>
          <div className="ps-2 w100minus20">
            <div className="c-font f-14 color-white-80 text-truncate">
              {data.full_name}
            </div>
          </div>
          <ReactTooltip />
        </div>
      ),
      name: data.full_name,
      url: data.profile_image,
    };
    setEmployeeData(employeeData.concat(newOperator));
    setShowCreateOperatorModal(false);
  };

  useEffect(() => {
    CustomerServices.getCompanyEmployee(0, 1).then((res) => {
      if (res.status && res.data) {
        const formattedResponse = res.data.map((employee) => {
          return {
            value: employee.staff_id,
            label: (
              <div className="d-flex align-items-center">
                <div className="c-list-icon">
                  <div className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-5px">
                    {
                      employee.is_skilled > 0
                      ?
                        <span className="bg-transparent absolutecrownbadge  ps-0 border-0  badge-custom d-flex  rounded-pill bg-none">
                          <Medal size={16}  weight="light" className="c-icons opacity-60"  />
                        </span>
                      :
                      <React.Fragment></React.Fragment>
                    }
                    <div
                      data-tip={employee.full_name}
                      data-effect="solid"
                      data-delay-show='1000'
                      data-class="tooltip-main"
                      className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                      style={{
                        backgroundImage: `url('${employee.profile_image}')`,
                      }}
                    ></div>
                  </div>
                </div>
                <div className="ps-2 w100minus20">
                  <div className="c-font f-14 color-white-80 text-truncate">
                    {employee.full_name}
                  </div>
                </div>
                <ReactTooltip />
              </div>
            ),
            name: employee.full_name,
            url: employee.profile_image,
            is_skilled: employee.is_skilled
          };
        });
        setEmployeeData(formattedResponse);
        // setTableDataSource(formattedResponse);
      }
    });
    if (benchmarkSchedule) {
      if (benchmarkSchedule.week_schedule_details) {
        if (benchmarkSchedule.schedule_assignee_details) {
          let operatorSlotDetails = benchmarkSchedule.schedule_assignee_details;
          const updateAssignedSlots =
            benchmarkSchedule.week_schedule_details.map((checklist) => {
              checklist["assigned_days"] = {};
              Object.keys(checklist.days).forEach((day_name) => {
                if(checklist.days[day_name])
                {
                  checklist["assigned_days"][day_name] = -1;
                }
              });
              operatorSlotDetails.forEach((asssigned_slot, index) => {
                if (
                  asssigned_slot.item_id &&
                  asssigned_slot.item_id.includes(checklist.id)
                ) {
                  Object.keys(checklist.days).forEach((day_name) => {
                    if (
                      checklist.days[day_name] &&
                      asssigned_slot.days[day_name] &&
                      asssigned_slot.item_id &&
                      asssigned_slot.item_id.includes(checklist.id)
                    ) {
                      checklist["assigned_days"][day_name] = index;
                    }
                  });
                }
              });
              return checklist;
            });
          setOperatorAvailability(
            operatorSlotDetails.map((slot, index) => {
              Object.keys(slot.time_slot).forEach((day_name) => {
                if (!slot.time_slot[day_name]) {
                  slot.time_slot[day_name] = "";
                } else if (
                  !["monthly", "quarterly", "half_yearly", "yearly"].includes(
                    day_name
                  ) &&
                  slot.time_slot[day_name] != ""
                ) {
                  if (!(slot.time_slot[day_name] instanceof Date)) {
                    slot.time_slot[day_name] = new Date(
                      `01-01-2000 ${slot.time_slot[day_name]}`
                    );
                  }
                } else if (
                  ["monthly", "quarterly", "half_yearly", "yearly"].includes(
                    day_name
                  )
                ) {
                  if (
                    !(slot.time_slot[day_name] instanceof Date) &&
                    slot.time_slot[day_name] != ""
                  ) {
                    slot.time_slot[day_name] = new Date(
                      `${slot.time_slot[day_name]}`
                    );
                  }
                }
              });
              return {
                id: slot.id,
                action_count: calculateActionsCount(
                  updateAssignedSlots,
                  index,
                  slot.days
                ),
                assignee_id: slot.assignee_id,
                assignee_name: slot.user_details.full_name,
                assignee_image: slot.user_details.image_url,
                assigned_items: benchmarkSchedule.week_schedule_details.filter(
                  (x) => slot.item_id.includes(x.id)
                ),
                available_from: new Date(`01-01-2000 ${slot.available_from}`),
                available_items: [],
                comment: "",
                task_title: slot.task_title,
                days: slot.days,
                frequency: [],
                selected_count: slot.item_id ? slot.item_id.length : 0,
                time_slot: slot.time_slot,
              };
            })
          );
          setAssignedChecklistItems(updateAssignedSlots);
          setCalculationsCounts(
            updateScheduleSlotKPICounts(updateAssignedSlots)
          );
        } else {
          setOperatorAvailability([operator_availability]);
          setAssignedChecklistItems(benchmarkSchedule.week_schedule_details);
          setCalculationsCounts(
            updateScheduleSlotKPICounts(benchmarkSchedule.week_schedule_details)
          );
        }
      }
    }
  }, [benchmarkSchedule]);

  return (
    <>
      <div className="task-checklist-wrapper add-benchmark-wrapper comman-content-scroll-wrapper flex-grow-1 table-th-px-26px">
        <div className="h-100 position-relative w-100">
          <div className="h-100 d-flex comman-data-table overflow-auto">
            <div className="bg-transparent left-content-part pt-0 w-400 res-w-400 p-0 left-sticky d-flex flex-column z-index-12imp">
              <ChecklistCountHeader
                selectedTab={selectedTab}
                componentReender="leftpart"
              />
              <div className="benchmark-left left-content-part d-flex flex-column px-0 py-0 w-100 flex-grow-1 bg-white-03-solid-imp">
                <table className="dataTable left-content-part bg-transparent res-height-100 p-0 comman-table">
                  <thead
                    className="bg-white-03-solid-imp z-index-12imp"
                    style={{ top: "84px" }}
                  >
                    <tr className="">
                      <th className="border-bottom px-0 fw-semibold px-2">
                        {_l("l_operators")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {operatorAvailability && operatorAvailability.length ? (
                      operatorAvailability.map((detail, index) => (
                        <ScheduleAssignmentOperator
                          employeeData={employeeData}
                          key={index}
                          detail={detail}
                          handleOperatorDetail={(assignee) => {
                            selectOperatorForSlot(assignee, index);
                          }}
                          index={index}
                          selectOperatorSlot={(time) =>
                            selectOperatorTimeSlot(time, index)
                          }
                          selectAllOperatorSlots={(days) => {
                            selectOperatorDaySlot(days, index);
                          }}
                        />
                      ))
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
                <div className="d-flex flex-column mt-auto position-sticky-bottom bg-white-03-solid-imp pt-2 z-index-12imp">
                  <div className="benchmark-right-table">
                    <div className="benchmark-bottom-btn mt-auto mb-2 mx-2 d-flex align-items-center">
                      <Button
                        variant="white-05 flex-grow-1 w-100 border-0"
                        onClick={addNewOperatorSlot}
                      >
                        {_l("l_add_operator")}
                      </Button>
                      <div className="mx-1 user-select-none"></div>
                      <Button
                        variant="white-05 flex-grow-1 w-100 border-0"
                        onClick={() => {
                          setShowCreateOperatorModal(true);
                        }}
                      >
                        {_l("l_create_operator")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-content-part res-width-calc-400 width-calc-400 p-0">
              <ChecklistCountHeader
                selectedTab={selectedTab}
                componentReender="rightpart"
                step={step}
              />
              <div className="d-flex flex-column res-overflow-auto w-100">
                <table className="dataTable comman-table">
                  <thead
                    className="bg-white-03-solid-imp table-th-px-26px"
                    style={{ top: "84px" }}
                  >
                    <th
                      style={{ width: "5%" }}
                      className="text-center border-bottom"
                    >
                      {_l("l_week_single_letter_monday")}
                    </th>
                    <th
                      style={{ width: "5%" }}
                      className="text-center border-bottom"
                    >
                      {_l("l_week_single_letter_tuesday")}
                    </th>
                    <th
                      style={{ width: "5%" }}
                      className="text-center border-bottom"
                    >
                      {_l("l_week_single_letter_wednesday")}
                    </th>
                    <th
                      style={{ width: "5%" }}
                      className="text-center border-bottom"
                    >
                      {_l("l_week_single_letter_thrusday")}
                    </th>
                    <th
                      style={{ width: "5%" }}
                      className="text-center border-bottom"
                    >
                      {_l("l_week_single_letter_friday")}
                    </th>
                    <th
                      style={{ width: "5%" }}
                      className="text-center border-bottom"
                    >
                      {_l("l_week_single_letter_saturday")}
                    </th>
                    <th
                      style={{ width: "5%" }}
                      className="text-center border-bottom"
                    >
                      {_l("l_week_single_letter_sunday")}
                    </th>
                    <th
                      style={{ width: "1%" }}
                      className="text-center border-bottom user-select-none"
                    ></th>
                    <th
                      style={{ width: "5%" }}
                      className="text-center border-bottom"
                    >
                      {_l("l_single_letter_monthly")}
                    </th>
                    <th
                      style={{ width: "5%" }}
                      className="text-center border-bottom"
                    >
                      {_l("l_single_letter_quaterly")}
                    </th>
                    <th
                      style={{ width: "5%" }}
                      className="text-center border-bottom"
                    >
                      {_l("l_single_letter_semester")}
                    </th>
                    <th
                      style={{ width: "5%" }}
                      className="text-center border-bottom"
                    >
                      {_l("l_single_letter_yearly")}
                    </th>
                    <th
                      style={{ width: "10%" }}
                      className="text-center border-bottom"
                    >
                      {_l("l_action")}
                    </th>
                    <th
                      style={{ width: "10%" }}
                      className="text-center border-bottom"
                    >
                      {_l("l_selected")}
                    </th>
                    <th
                      style={{ width: "5%" }}
                      className="text-center border-bottom user-select-none"
                    ></th>
                  </thead>
                  <tbody>
                    {operatorAvailability && operatorAvailability.length ? (
                      operatorAvailability.map((detail, index) => (
                        <ScheduleAssignmentOperatorSlot
                          key={index}
                          index={index}
                          detail={detail}
                          handleSelectOperatorDaySlot={(days) => {
                            selectOperatorDaySlot(days, index);
                          }}
                          openScheduleActiondetailModal={() =>
                            openScheduleActiondetailModal(index)
                          }
                          removeSlot={() => removeOperatorSlot(index)}
                        />
                      ))
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {hideFooter ? (
        <React.Fragment></React.Fragment>
      ) : (
        <div
          className={`comman-content-bottom-btn d-flex justify-content-between align-items-center mt-auto border-top p-15 px-0 ${
            readOnlyMode ? "d-none" : ""
          }`}
        >
          <a href="#/"
            className="btn btn-secondary"
            onClick={() => {
              handleStep("create-schedule");
            }}
          >
            {_l("l_previous")}
          </a>
          <a href="#/"
            className="btn btn-primary"
            onClick={() => {
              handleStep("schedule-summary");
            }}
          >
            {_l("l_next")}
          </a>
        </div>
      )}
      {scheduleActionChecklistModal ? (
        <ScheduleActionChecklist
          show={scheduleActionChecklistModal}
          operator={{ ...operatorAvailability[currentSlot] }}
          handleClose={() => {
            setScheduleActionChecklistModal(false);
          }}
          slot={currentSlot}
        />
      ) : (
        <></>
      )}
      {showCreateOperatorModal ? (
        <AddEmployee
          show={showCreateOperatorModal}
          projectId={selectedProject}
          updateDataSource={updateOperatorList}
          selectedRecord={{}}
          handleClose={() => {
            setShowCreateOperatorModal(false);
          }}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </>
  );
};

export default CreateScheduleNew;
