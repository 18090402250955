import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { _l } from "../../hooks/utilities";

const PaymentModal = ({
  show,
  // handleClose,
  handleSave,
  loader,
  companyName,
  amount,
  address
}) => {
  return (
    <div>
      <Modal
        show={show}
        // onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="pb-0">
          {/* <MessagePlaceHolderScreen
            description={"l_payment_setup_message_for_provider"}
            image={{ name: Money }}
            title={""}
          /> */}
          <div className="c-font f-18 fw-semibold text-center title-fonts pb-3 mb-3 border-bottom">
          {_l("l_pay_subscription")}
          </div> 
          <div className="comman-data-table">
            <table className="dataTable title-fonts">
              <thead className="bg-transparent p-0">
                <tr className="p-0">
                  <th class="p-0" style={{"width":"10%"}}></th>
                  <th class="p-0" style={{"width":"10px"}}></th>
                  <th class="p-0"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-2 opacity-75">{_l("l_space")} </td>
                  <td className="p-2">:</td>
                  <td className="p-2 fw-semibold">{companyName}</td>
                </tr>
                <tr>
                  <td className="p-2 opacity-75">{_l("l_address")}</td>
                  <td className="p-2">:</td>
                  <td className="color-green fw-semibold p-2">{address}</td>
                </tr>
                <tr>
                  <td className="p-2 opacity-75">{_l("l_amount")}</td>
                  <td className="p-2">:</td>
                  <td className="color-green fw-semibold p-2">{amount}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="text-center opacity-75 mt-3 ">{`${_l("l_space_payment_completion_message")} ${companyName}`}</div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          {/* <Button variant="secondary" onClick={handleClose}>
            {_l("l_close")}
          </Button> */}
          <Button
            variant="primary"
            onClick={handleSave}
            className={`${loader ? "for-disabled" : ""}`}
            disabled={loader}
          >
            {loader ? _l("l_please_wait") : _l("l_pay")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PaymentModal;
