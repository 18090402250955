import { CaretRight } from "phosphor-react";
import React from "react";
import { Form } from "react-bootstrap";
import { _l } from "../../hooks/utilities";

const GenericInput = ({type, value, onChange, placeholder, handleNextStep, Icon, label}) => {
  return (
    <div className="col-12 col-xl-9 col-xxl-8 registration-content flex-grow-1 mx-auto row mb-70">
      <div className="col-lg-2"></div>
      <div className="align-items-center col-lg-7 d-flex mx-auto">
        <Form.Group className={`c-input-box position-relative w-100 `}>
          <label class="c-font f-11 fw-semibold text-uppercase title-fonts cblue70">{_l(label)}</label>
          <div className="inputWrapper d-flex  align-items-center">
            <div className="with_separator_10 pe-20pximp iconabsolute">
              <Icon.name size={24} weight="light" className="c-icons mx-0" />
            </div>
            <Form.Control
              placeholder={placeholder}
              className="regFormInput w-100 text-center px4pxpy55px"
              type={type}
              value={value}
              onChange={onChange}
              autoFocus
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  handleNextStep();
                }
              }}
            />
            <div className="comman_action_icon absolutebtn">
              <a className="action_icon d-flex h40w40  btn-primary shadow-none " onClick={handleNextStep}>
                <CaretRight size={18} weight="light" className="c-icons" />
              </a>
            </div>
          </div>
        </Form.Group>
      </div>
      <div className="col-lg-2"></div>
    </div>
  );
};

export default GenericInput;
